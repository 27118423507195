import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
//for datepicker starts
import { NgbDateStruct, NgbCalendar, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MemoService } from "src/app/dataService/memo-service";
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { DatePipe } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { HireslipService } from 'src/app/dataService/hireslip-service';
import *  as moment from 'moment';
import { color } from 'd3';
@Component({
    selector: 'app-barcode-scanning',
    templateUrl: './barcode-scanning.component.html',
    styleUrls: ['./barcode-scanning.component.css']
})
export class BarcodeScanningComponent implements OnInit {

    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;


    userDataDtoReturnSession: any;
    isLoggedIn = true;

    enteredBarCodeScanningNo: any = null;
    enteredArrivatDate: any = null;
    enteredArrivatTime: any = null;
    hireslipDto: HireSlipDto = new HireSlipDto();
    viewMessageOfBarcodeScanning = false;
    showSpinnerForAction=false;
    modelBarCodeScanningNo:any;
	pageId="bsc";
	showStatus : any;

    constructor(private masterReadService: MasterReadService, private http: HttpClient,
        private router: Router, private datePipe: DatePipe, private modalService: NgbModal,
        private memoService: MemoService, private hireslipService: HireslipService, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    ngOnInit() {
    }
   
    getBarcodeScanningDetails(event) {
        this.viewMessageOfBarcodeScanning = false;
        $("#"+this.pageId+"spanBarCodeScanMessage").text('');
        this.enteredBarCodeScanningNo = $("#"+this.pageId+"inputFieldBarcodeScanning").val();
        this.enteredArrivatDate = moment(new Date()).format("YYYY-MM-DD");
        this.enteredArrivatTime = moment(new Date()).format("HH:mm");
        console.log(this.enteredBarCodeScanningNo);
        console.log(this.modelBarCodeScanningNo);
        //console.log(this.enteredArrivatDate);
        //console.log(this.enteredArrivatTime);
        this.enteredBarCodeScanningNo = this.modelBarCodeScanningNo;

        this.hireslipDto = new HireSlipDto();
        this.hireslipDto.hireslipnumber = this.enteredBarCodeScanningNo;
        this.hireslipDto.branch = this.userDataDtoReturnSession.office;
        this.hireslipDto.arrivaldate = this.enteredArrivatDate;
        this.hireslipDto.arrivalTime = this.enteredArrivatTime;
        this.hireslipDto.status = "Scanned";
        this.hireslipDto.lastupdatedby = this.userDataDtoReturnSession.userId;
        this.hireslipDto.barcodeValue = this.enteredBarCodeScanningNo;
        this.hireslipDto.companyid = this.userDataDtoReturnSession.companyId;
        //console.log(this.hireslipDto);
        this.showSpinnerForAction = true;
        this.hireslipService.scanCodeApi(this.hireslipDto).subscribe(
            (response) => {
                //console.log(response);
                if (response.status == "alreadyScaned") {
                    this.viewMessageOfBarcodeScanning = true;
                    $("#"+this.pageId+"barCodeScanMessage").css({ "color": "black", "text-align": "center" });
                    $("#"+this.pageId+"barCodeScanMessage").text('This Invoice is already scanned');
                    this.showStatus = 'This Invoice is Already Scanned';
                    setTimeout(function () {
                        $("#"+this.pageId+"barCodeScanMessage").text('');
                        this.viewMessageOfBarcodeScanning = false;
                        this.showStatus = "";
                    }, 5000);
                } else if (response.status == "Failed") {
                    this.viewMessageOfBarcodeScanning = true;
                    $("#"+this.pageId+"barCodeScanMessage").text('Failed to scan this Invoice');
                    $("#"+this.pageId+"barCodeScanMessage").css({ "color": "red", "text-align": "center" });
                    this.showStatus = 'Failed to Scan this Invoice';
                    setTimeout(function () {
                        $("#"+this.pageId+"barCodeScanMessage").text('');
                        this.viewMessageOfBarcodeScanning = false;
                        this.showStatus = "";
                    }, 5000);
                } else if (response.status == "Sucess") {
                    this.viewMessageOfBarcodeScanning = true;
                    $("#"+this.pageId+"barCodeScanMessage").text('SucessFully Scanned');
                    $("#"+this.pageId+"barCodeScanMessage").css({ "color": "green", "text-align": "center" });
                    this.showStatus = 'Successfully scanned';
                    setTimeout(function () {
                        $("#"+this.pageId+"barCodeScanMessage").text('');
                        this.viewMessageOfBarcodeScanning = false;
                        this.showStatus = "";
                    }, 5000);
                } else if (response.status == "NotExist") {
                    this.viewMessageOfBarcodeScanning = true;
                    $("#"+this.pageId+"barCodeScanMessage").text('Not Exist this  Scanned');
                    $("#"+this.pageId+"barCodeScanMessage").css({ "color": "black", "text-align": "center" });
                    this.showStatus = 'Not Exist';
                    setTimeout(function () {
                        $("#"+this.pageId+"barCodeScanMessage").text('');
                        this.viewMessageOfBarcodeScanning = false;
                        this.showStatus = "";
                    }, 5000);
                }
                $("#"+this.pageId+"inputFieldBarcodeScanning").val('');
                $("#"+this.pageId+"inputFieldBarcodeScanning").focus();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  Scan Code Api", "warning");
                console.log(error.json())
            },
            () => console.log('done');

    }
}
