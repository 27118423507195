import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupplierModel } from "src/app/master/supplier/supplier-master/supplier-model";
import { PartyMasterDto } from '../dto/PartyMaster-dto';
import { serverUrl } from "src/environments/environment";
import { RateMasterDto } from '../dto/RateMaster-dto';
import { GeneralDto } from '../dto/General-dto';


@Injectable({
    providedIn: 'root'
})
export class ConsignorService {

    //private _url = "./assets/data/supplier-master.json";

    private _baseUrl = serverUrl.webConsignor;

    constructor(
        private http: HttpClient,
    ) {
    }

    getSupplierMasterData(): Observable<SupplierModel[]> {
        //return this.http.get<SupplierModel[]>(this._url);
        return this.http.get<SupplierModel[]>('./assets/data/supplier-master.json');


    }

    getSummaryData(): Observable<any[]> {
        //return this.http.get<SupplierModel[]>(this._url);
        return this.http.get<any[]>('./assets/data/lr-dispatch-booking-report.json');
    }

    /* consignor Masterstart */

    addConsignorDetails(partyMasterDto: Object): Observable<PartyMasterDto> {
        return this.http.post<PartyMasterDto>(`${this._baseUrl}/addConsignorDetailsApi`, partyMasterDto);
    }

    getConsignorDetailsMaster(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getConsignorDetailsMasterApi`, partyMasterDto);
    }
    /* consignor master end */
    saveConsignorAdminRateMaster(rateMasterDto: Object): Observable<GeneralDto> {
        return this.http.post<GeneralDto>(`${this._baseUrl}/saveConsignorAdminRateMasterApi`, rateMasterDto);
    }

    getConsignorAdminRateMasterDetails(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/getConsignorAdminRateMasterDetailsApi`, rateMasterDto);
    }

    updateConsignorAdminRateMaster(rateMasterDto: Object): Observable<GeneralDto> {
        return this.http.post<GeneralDto>(`${this._baseUrl}/updateConsignorAdminRateMasterApi`, rateMasterDto);
    }
    deleteConsignorAdminRateMaster(rateMasterDto: Object): Observable<GeneralDto> {
        return this.http.post<GeneralDto>(`${this._baseUrl}/deleteConsignorAdminRateMasterApi`, rateMasterDto);
    }

    getConsignorRepeatDetails(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getConsignorRepeatDetailsApi`, partyMasterDto);
    }

    consignorConfirmed(generalDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/consignorConfirmedApi`, generalDto);
    }

    undoCgnorMerge(generalDto: Object): Observable<PartyMasterDto> {
        return this.http.post<PartyMasterDto>(`${this._baseUrl}/undoCgnorMergeApi`, generalDto);
    }

    consignorMerge(generalDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/consignorMergeApi`, generalDto);
    }

    updateConsignorAdminRateAll(rateMasterDto: Object): Observable<GeneralDto> {
        return this.http.post<GeneralDto>(`${this._baseUrl}/updateConsignorAdminRateAllApi`, rateMasterDto);
    }

    addConsignorTopartyDetails(partyMasterDto: Object): Observable<PartyMasterDto> {
        return this.http.post<PartyMasterDto>(`${this._baseUrl}/addConsignorTopartyDetailsApi`, partyMasterDto);
    }

    getConsignorTopartyDetails(partyMasterDto: Object): Observable<PartyMasterDto[]> {
        return this.http.post<PartyMasterDto[]>(`${this._baseUrl}/getConsignorTopartyDetailsApi`, partyMasterDto);
    }

    updateConsignorAdminRateAllSpecficParty(rateMasterDto: Object): Observable<GeneralDto> {
        return this.http.post<GeneralDto>(`${this._baseUrl}/updateConsignorAdminRateAllSpecficPartyApi`, rateMasterDto);
    }
    updatePartyPerfMonitor(partyMasterDto: Object): Observable<PartyMasterDto> {
        return this.http.post<PartyMasterDto>(`${this._baseUrl}/updatePartyPerfMonitorApi`, partyMasterDto);
    }

}

