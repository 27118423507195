<h1 class="title_custom" mat-dialog-title>
	<h6 style="margin-bottom: 0px;">Multiple Article Size</h6>
</h1>
<div mat-dialog-content>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					
					<div class="row">
						<div class="box-body">
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsLrDetails"
								[dtTrigger]="dtTriggerLrDetails">

								<thead>
									<tr>
										<th>Article</th>
										<th>Length</th>
										<th>Breadth</th>
										<th>Height</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let articlesData of articlesDataList ">
										<td>{{ articlesData.articlesInMultiple }}</td>
										<td>{{ articlesData.length }}</td>
										<td>{{ articlesData.breadth }}</td>
										<td>{{ articlesData.height }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>

						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	
	<button class="btn btn-outline-success" mat-button
		[mat-dialog-close]="true" cdkFocusInitial>Close</button>

</div>
