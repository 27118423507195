<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showCashmemoInSRDStkRpt">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">O/S Cash Memo In SRD
						Collection Department</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Search By</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}searchBy"
															name="searchBy" #searchBy
															(change)="searchByMode(searchBy.value)">
															<option selected value="dateWise">Date Wise</option>
															<option value="partyWise">Party Wise</option>
															<option value="areaWise">Area Wise</option>
															<option value="collectionManWise">Collection Man
																Wise</option>
														</select>
													</div>
												</div>
											</div>
											<div *ngIf="viewDate" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>From Date</label> <input class="form-control"
															placeholder="yyyy-mm-dd" id="{{pageId}}fromDates" name="fromDates"
															ngbDatepicker #fromDates="ngbDatepicker"
															[(ngModel)]="fromDatesModal" autocomplete="off">
														<div class="input-group-append"
															(click)="fromDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>

											<div *ngIf="viewDate" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>To Date</label> <input class="form-control"
															placeholder="yyyy-mm-dd" id="{{pageId}}toDates" name="toDates"
															autocomplete="off" [(ngModel)]="toDatesModal"
															ngbDatepicker #toDates="ngbDatepicker">
														<div class="input-group-append" (click)="toDates.toggle()">
															<span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
											<!-- 											the first autocomplete starts -->
											<div *ngIf="searchByPartyWise" class="col-sm-12 col-md-12">

												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Party Name</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
															</div>
															<!--<input class="auto_selectOption input is-medium"
																placeholder="Select Party" [formControl]="control"
																[appAutocomplete]="autocomplete">-->
															<input #partyName id="{{pageId}}consigneePartyNameId" type="text"
																class="form-control"
																(selectItem)="clickListnerForConsigneePartyName($event)"
																[(ngModel)]="modelConsigneePartyName"
																[ngbTypeahead]="searchConsigneePartyName"
																[resultFormatter]="formatterConsigneePartyName"
																[inputFormatter]="formatterConsigneePartyName"
																(focus)="focusConsigneePartyNameTA$.next($any($event).target.value)" />
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="searchByAreaWise" class="col-sm-12 col-md-12">
												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Area Name</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-map-marker-alt"></i>
																</span>
															</div>
															<!--<input class="auto_selectOption input is-medium"
																placeholder="Select Area" [formControl]="control"
																[appAutocomplete]="autocomplete">-->
															<input #areaName id="{{pageId}}areaNameId" type="text"
																class="form-control"
																(selectItem)="clickListnerForAreaName($event)"
																[(ngModel)]="modelAreaName"
																[ngbTypeahead]="searchAreaName"
																[resultFormatter]="formatterAreaName"
																[inputFormatter]="formatterAreaName"
																(focus)="focusAreaNameTA$.next($any($event).target.value)" />
														</div>
													</div>
												</div>
											</div>
											<div *ngIf="searchByCollectionManWise"
												class="col-sm-12 col-md-12">

												<div class="control">
													<div class="form-group">
														<div class="input-group">
															<label>Collection Man</label>
															<div class="input-group-prepend">
																<span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
															</div>
															<!--<input class="auto_selectOption input is-medium"
																placeholder="Select Collection Man"
																[formControl]="control" [appAutocomplete]="autocomplete">-->
															<input #collectionMan id="{{pageId}}collectionManId" type="text"
																class="form-control"
																(selectItem)="clickListnerForCollectionMan($event)"
																[(ngModel)]="modelCollectionMan"
																[ngbTypeahead]="searchCollectionMan"
																[resultFormatter]="formatterCollectionMan"
																[inputFormatter]="formatterCollectionMan"
																(focus)="focusCollectionManTA$.next($any($event).target.value)" />
														</div>
													</div>
												</div>
											</div>
											<!-- 											the third autocomplete ends -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn" (click)="searchBtnForOSCashmemoCollection()">Search</button>
							<button type="submit" class="btn btn-dark" (click)="clearBtn()"
								id="{{pageId}}clearBtn">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<!-- spinner start-->
						<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<!-- spinner end-->
						<div class="box-body">
							<button type="submit" class="dt-button" style="margin-left: 92%;"
								(click)="customPrintCMSRDStk()" id="{{pageId}}printAllBtn">
								<span><i class="fas fa-print">Print</i></span>
							</button>

							<table datatable id="{{pageId}}srdCollectionStockDatataableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsSrdCollectionStockReport"
								[dtTrigger]="dtTriggerSrdCollectionStockReport">
								<thead>
									<tr>
										<th>Memo Number</th>
										<th>Memo Date</th>
										<th>Total Amount</th>
										<th>LR No</th>
										<th>Article</th>
										<th>Area</th>
										<th>Party Name</th>
										<th>Collection Man</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let srdCollectionStockReportData of srdCollectionStockReportDataList ">
										<td>{{ srdCollectionStockReportData.memoNumber }}</td>
										<td>{{ srdCollectionStockReportData.memoDateStr }}</td>
										<td>{{ srdCollectionStockReportData.grandTotal }}</td>
										<td>{{ srdCollectionStockReportData.lrNumber }}</td>
										<td>{{ srdCollectionStockReportData.articles }}</td>
										<td>{{ srdCollectionStockReportData.area }}</td>
										<td>{{ srdCollectionStockReportData.consigneeName }}</td>
										<td>{{ srdCollectionStockReportData.collectionMan }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>


						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()"
		id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>