<div class="page row system_responsive" id="{{pageId}}firstPrint">
	<div class="col-md-12">
		<div>
			<table width='100%' border='0' cellspacing='0' cellpadding='0'>
				<tr>
					<td style="padding-top: 10px;" width='40%' valign='top'><img
						src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
						<br> <br>{{address}}</td>

					<td width='60%' valign='top'>
						<table width='100%' border='0' cellspacing='0' cellpadding='0'>
							<tr>
								<td>
									<table width='100%' border='0' cellspacing='0' cellpadding='0'>
										<tr>
											<td width='15%'><strong>Truck No : </strong></td>
											<td width='25%' style='border-bottom: 1px solid #000;'>
												<strong>{{invoiceDtoForPrintFromPopup.vehicleNumber}}
											</strong>
											</td>
											<td width='15%'><strong>Supplier : </strong></td>
											<td width='45%' style='border-bottom: 1px solid #000;'>
												<strong>{{invoiceDtoForPrintFromPopup.supplierName}}
											</strong>
											</td>
										</tr>
									</table>
								</td>
							</tr>
							<br>
							<tr>
								<td>
									<table width='100%' border='0' cellspacing='0' cellpadding='0'>
										<tr>
											<td width='15%'><strong>Invoice No : </strong></td>
											<td width='25%' style='border-bottom: 1px solid #000;'>
												<strong>{{invoiceDtoForPrintFromPopup.hireSlipNumber}}
											</strong>
											</td>
											<td width='15%'><strong>Invoice Dt : </strong></td>
											<td width='45%' style='border-bottom: 1px solid #000;'>
												<strong>{{invoiceDate}} </strong>
											</td>
										</tr>
									</table>
								</td>
							</tr>
							<br>
							<tr>
								<td>
									<table width='100%' border='0' cellspacing='0' cellpadding='0'>
										<tr>
											<td width='15%'><strong>Driver : </strong></td>
											<td width='25%' style='border-bottom: 1px solid #000;'>
												<strong>{{invoiceDtoForPrintFromPopup.driverName}}
											</strong>
											</td>
											<td width='15%'><strong>Mobile : </strong></td>
											<td width='45%' style='border-bottom: 1px solid #000;'>
												<strong>{{invoiceDtoForPrintFromPopup.mobileNum}} </strong>
											</td>
										</tr>
									</table>
								</td>
							</tr>
							<br>
							<tr>
								<td>
									<table width='100%' border='0' cellspacing='0' cellpadding='0'>
										<tr>
											<td width='15%'><strong>Dated : </strong></td>
											<td width='25%' style='border-bottom: 1px solid #000;'>
												<strong>{{todayDate}} </strong>
											</td>
											<td width='15%'></td>
											<td width='45%'></td>
										</tr>
									</table>
								</td>
							</tr>

						</table>
					</td>
				</tr>
			</table>
		</div>
		<br>
		<div class="row">
			<div class="col-md-12">
				<h6
					style='text-align: center; font-size: 23px; text-transform: uppercase;'>
					<u><strong>UnLoading Report</strong></u>
				</h6>
			</div>
		</div>
		<br>

		<table width='100%' border='0' cellpadding='0' cellspacing='0'
			style='table-layout: fixed;'>
			<tr>
				<td width='20%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; border-left: 1px solid #000; font-size: 20px;'
					valign='top' type='text'><strong>LR Number</strong></td>
				<td width='15%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; font-size: 20px;'
					valign='top' type='number'><strong> Actual Article</strong></td>
				<td width='15%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; font-size: 20px;'
					valign='top' type='number'><strong> Entered Article</strong></td>
				<td width='30%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; font-size: 20px;'
					valign='top' type='number'><strong> Remarks</strong></td>
				<td width='20%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; font-size: 20px;'
					valign='top' type='text'><strong> Mentioned</strong></td>
			</tr>
			<ng-container *ngFor="let item of result">
			<tr>
				<td colspan="12" style='border: 1px solid #000; font-size: 20px;'><strong>{{item.status}}</strong></td>
			</tr>
			<tr *ngFor="let value of item.values">
				<td width='20%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000; font-size: 20px;'>
					<span>{{value.lrNumber}}</span>
				</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 20px;'>
					{{value.articles}}</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 20px;'>
					{{value.totalArticles}}</td>
				<td width='30%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 20px;'>
					<span>{{value.remarks}}</span>
				</td>
				<td width='20%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 20px;'>
					{{value.description}}</td>

			</tr>
			<tr>

				<td width='20%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000; font-size: 20px;'>
					<strong> Total </strong>
				</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 20px;'>
					<strong> {{item.values.length}}</strong>
				</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='30%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='20%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>

			</tr>
			</ng-container>
			<br>
			<br>
			<tr>
				<td width='20%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000; font-size: 20px;'>
					<strong> Total </strong>
				</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; font-size: 20px;'>
					<strong> {{totCounts}}</strong>
				</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='30%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='20%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>

			</tr>
		</table>
		<!--/div>-->
		<!--</div>
		</div>-->
		<!--<br>
		<div class="container border">
			<table>
				<tr>
					<td width='6%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
						<strong> Total </strong> </td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='4%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong> {{totArtNet}}</strong>
					</td>
					<td width='6%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='8%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong>{{totActWgtNet}}</strong></td>
					<td width='8%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong>{{totChgWgtNet}}</strong></td>
					<td width='8%' align='center'
						style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right: 1px solid #000; '>
						<strong>{{totGoodsValueNet}}</strong></td>
				</tr>
			</table>
		</div>-->


	</div>
</div>