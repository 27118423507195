<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showLcAndBcReport">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">LC And BC Report</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-3">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-12">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>View Type</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-question"></i>
														</span>
													</div>
													<select class="custom-select col-12" id="{{pageId}}viewType"
														name="viewType" #viewType
														(change)="viewTypeMode(viewType.value)">
														<option selected value="summaryWise">Summary Wise</option>
														<option value="detailsWise">Details Wise</option>
													</select>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>From Date</label> <input class="form-control"
														id="{{pageId}}fromDate" [(ngModel)]="fromDateModel"
														placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
														#fromDates="ngbDatepicker">
													<div class="input-group-append"
														(click)="fromDates.toggle()">
														<span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>

										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>To Date</label> <input id="{{pageId}}toDate"
														[(ngModel)]="toDateModel" class="form-control"
														placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker
														#toDates="ngbDatepicker">
													<div class="input-group-append" (click)="toDates.toggle()">
														<span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
										<div *ngIf="showSource" class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Source</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-road"></i>
														</span>
													</div>
													<input id="{{pageId}}source" type="text" class="form-control"
														[(ngModel)]="modelSource" [ngbTypeahead]="searchSource"
														[resultFormatter]="formatterSource"
														[inputFormatter]="formatterSource"
														(focus)="focusSourceTA$.next($any($event).target.value)"
														placeholder="Select Source" />


												</div>
											</div>
										</div>

										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Agent</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class=" fas fa-road"></i>
														</span>
													</div>
													<input id="{{pageId}}agentName" type="text" class="form-control"
														[(ngModel)]="modelAgentName"
														[ngbTypeahead]="searchAgentName"
														[resultFormatter]="formatterAgentName"
														[inputFormatter]="formatterAgentName"
														(focus)="focusAgentNameTA$.next($any($event).target.value)"
														placeholder="Select Agent" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr style="width: 80%; border-top: none; margin: 3px;">
					<div class="col-md-12" style="text-align: center;">
						<button type="submit" class="btn btn-success m-r-10"
							id="{{pageId}}searchBtn" (click)="searchMethod();">Search</button>
						<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
							(click)="clearMethod();">Clear</button>
					</div>
				</div>

				<div class="col-md-9 vl p-t-10">
					<div class="row">
						<!-- column -->
						<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<button type="submit" class="dt-button" style="margin-left: 74%;"
							(click)="customPrintLcAndBcSummary()" id="{{pageId}}printAllBtn"
							[hidden]="!lcAndBcSummaryTable">
							<span><i class="fas fa-print">Print</i></span>
						</button>


						<button type="submit" class="dt-button" style="margin-left: 74%;"
							(click)="customPrintLcAndBcDetailDetail()" id="{{pageId}}printAllBtn"
							[hidden]="!lcAndBcDetailsTable">
							<span><i class="fas fa-print">Print</i></span>
						</button>

						<div class="col-md-12">
							<div class="card">
								<div class="card-body">
									<div class="row p-t-10">
										<div class="col-md-8"></div>
									</div>
									<div class="row p-t-10">
										<div class="col-md-8">
											<h6 class="card-title" [hidden]="!lcAndBcSummaryTable">LC
												And BC Summary Report</h6>
											<h6 class="card-title" [hidden]="!lcAndBcDetailsTable">LC
												And BC Detailed Report</h6>

										</div>

									</div>

									<div class="row">
										<div class="col-md-12">
											<div class="box-body" [hidden]="!lcAndBcSummaryTable">
												<table datatable id="{{pageId}}lcbcSummaryRptId"
													class="table table-striped table-bordered row-border hover"
													[dtOptions]="dtOptionsLCBCSummaryReport"
													[dtTrigger]="dtTriggerLCBCSummaryReport">

													<thead>
														<tr>
															<th>Agent Name</th>
															<th>Total Lrs</th>
															<th>Articles</th>
															<th>Actual Wt</th>
															<th>Charged Wt</th>
															<th>LC</th>
															<th>BC</th>
														</tr>
													</thead>
													<tbody>
														<tr
															*ngFor="let lcbcSummaryReportData of lcbcSummaryReportDataList ">
															<td>{{ lcbcSummaryReportData.agentId }}</td>
															<td>{{ lcbcSummaryReportData.totalLrs }}</td>
															<td>{{ lcbcSummaryReportData.totalArticles }}</td>
															<td>{{ lcbcSummaryReportData.actualWeight }}</td>
															<td>{{ lcbcSummaryReportData.chargedWeight }}</td>
															<td>{{ lcbcSummaryReportData.lcChg }}</td>
															<td>{{ lcbcSummaryReportData.bcChg }}</td>
														</tr>
													</tbody>
													<tfoot>
														<tr>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
													</tfoot>
												</table>
											</div>

											<div class="box-body" [hidden]="!lcAndBcDetailsTable">
												<table datatable id="{{pageId}}lcbcDetailedRptId"
													class="table table-striped table-bordered row-border hover"
													[dtOptions]="dtOptionsLCBCDetailedReport"
													[dtTrigger]="dtTriggerLCBCDetailedReport">

													<thead>
														<tr>
															<th>Lr Number</th>
															<th>Consignee Name</th>
															<th>Consignor Name</th>
															<th>Articles</th>
															<th>Actual Wt</th>
															<th>Charged Wt</th>
															<th>LC</th>
															<th>BC</th>
															<th>Agent Name</th>

														</tr>
													</thead>
													<tbody>
														<tr
															*ngFor="let lcbcDetailedReportData of lcbcDetailedReportDataList ">
															<td>{{ lcbcDetailedReportData.lrNumber }}</td>
															<td>{{ lcbcDetailedReportData.consigneeName }}</td>
															<td>{{ lcbcDetailedReportData.consignorName }}</td>
															<td>{{ lcbcDetailedReportData.totalArticles }}</td>
															<td>{{ lcbcDetailedReportData.actualWeight }}</td>
															<td>{{ lcbcDetailedReportData.chargedWeight }}</td>
															<td>{{ lcbcDetailedReportData.lcChg }}</td>
															<td>{{ lcbcDetailedReportData.bcChg }}</td>
															<td>{{ lcbcDetailedReportData.agentId }}</td>
														</tr>
													</tbody>
													<tfoot>
														<tr>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
															<td></td>
														</tr>
													</tfoot>

												</table>
											</div>

										</div>
									</div>
								</div>


							</div>
						</div>

					</div>
				</div>


			</div>
			<!-- Row -->
		</div>
	</div>
</div>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()"
	id="{{pageId}}viewCustomPrintV1">
	<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>