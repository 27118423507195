<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
	<div class="form-group">
		<div class="input-group">
			<mat-progress-bar mode="indeterminate" style="color: green;">
			</mat-progress-bar>
			<br>
			<h6 class="card-title" align='center'
				style="color: green; margin: auto; font-size: 18px;">Please
				Wait Loading Details.....</h6>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
		<div class="col-md-10">
			<h6 class="card-title">
				<i class=" fas fa-file-alt"></i>&nbsp;Unpaid Booking Agent Statement
			</h6>
	
		</div>
		<div class="col-md-2">
		<i title="Reload" class="fas fa-sync-alt fa xs cli"
			style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
			(click)="getDetails()"></i>
		</div>
	</div>
</div>
<div class="col-md-12 col-sm-12">
	<div class="box-body">
		<table datatable id="{{pageId}}unpaidBkgAgentStmtTableId"
			class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsUnpaidBookingAgentStatement"
			[dtTrigger]="dtTriggerUnpaidBookingAgentStatement">

			<thead>
				<tr>
					<th>Agent</th>
					<th>Stmt No</th>
					<th>Stmt Dt</th>
					<th>Amount</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="let unpaidBookingAgentStatementData of unpaidBookingAgentStatementDataList ">
					<td
						(click)="goToAgentStatementPage(unpaidBookingAgentStatementData)"><u
						class="hyperLink_td">{{
							unpaidBookingAgentStatementData.agentName }}</u></td>
					<td>{{ unpaidBookingAgentStatementData.stmtId }}</td>
					<td>{{ unpaidBookingAgentStatementData.stmtDateStr }}</td>
					<td>{{ unpaidBookingAgentStatementData.toBePaidAmt }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
