
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras } from "@angular/router";
import { MemoService } from "src/app/dataService/memo-service";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { ActivatedRoute } from "@angular/router";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { InvoiceDto } from 'src/app/dto/Invoice-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import swal from 'sweetalert';
import *  as moment from 'moment';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
@Component({
    selector: 'app-gc-taken-grouping-rpt-print',
    templateUrl: './gc-taken-grouping-rpt-print.component.html',
    styleUrls: ['./gc-taken-grouping-rpt-print.component.css']
})
export class GcTakenGroupingRptPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;

    isLoggedIn = true;

    address: any;
    gcTakenByDataListToPrintGroupingByPartyName: any;
    dataList: any;

    result: any;
    totMemoNo: any = 0;
    totMemoAmt: any = 0;
    lrDto: LRDto[] = [];
    userName:string;
    dataForPrintGCTaken: any;
    totRows:0;
    collectionMan:any;
	pageId = "gctpc";
    
    constructor(private router: Router, private datePipe: DatePipe, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }
        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
            this.userName=this.userDataDtoReturnSession.name;
            this.gcTakenByDataListToPrintGroupingByPartyName = JSON.parse(localStorage.getItem('gcTakenGroupingRptPrintLoaclStorage'));
            this.dataForPrintGCTaken = JSON.parse( localStorage.getItem( 'printGCTakenFromCollectionManRpt' ) );
            this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
            console.log(this.todayDate);
            //console.log( moment(this.lrDto[0].invoiceDate).format('DD-MM-YYYY'));
//For GC Left to Party  from collection Man report 
if ( this.dataForPrintGCTaken != null ) {
    this.collectionMan = this.dataForPrintGCTaken.collectionMan;
} else{
    this.collectionMan='NA';
}
console.log(this.collectionMan);

            this.dataList = [];
            if (this.gcTakenByDataListToPrintGroupingByPartyName.length > 0) {
                this.dataList = this.gcTakenByDataListToPrintGroupingByPartyName;
                console.log(this.dataList);
                this.totMemoNo=this.gcTakenByDataListToPrintGroupingByPartyName.length;
                console.log(this.totMemoNo);
                for (let i = 0; i < this.gcTakenByDataListToPrintGroupingByPartyName.length; i++) {
                    
                    
                    
                    this.totMemoAmt = +this.totMemoAmt + + this.gcTakenByDataListToPrintGroupingByPartyName[i].amount;
                   
                }
                console.log(this.totMemoAmt+"<< >>"+this.totMemoNo);
                this.setGcTakenDetailsPrintData();
            }
            console.log("HAI-Invoice Details");
            window.setTimeout(function () {
                window.print();
            }, 1000);

            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();

            
            window.addEventListener('afterprint', (event) => {
                this.clearField();
            });
        }
    }

    ngOnInit() {

    }

    setGcTakenDetailsPrintData() {
        var groups = new Set(this.dataList.map(item => item.consigneeName));
        this.result = [];
        this.totRows=0;
        groups.forEach(g => {
            /* Each Summary Data Value */
            const memoNo = this.dataList
                .filter(i => i.consigneeName === g)
                .reduce((sum, i) => +sum + +i.memoNumber, 0)
            const memoDate = this.dataList
                .filter(i => i.consigneeName === g)
                .reduce((sum, i) => +sum + +i.memoDateStr, 0)
            const assignedDate = this.dataList
                .filter(i => i.consigneeName === g)
                .reduce((sum, i) => +sum + +i.assignedDateStr, 0)
            const amt = this.dataList
                .filter(i => i.consigneeName === g)
                .reduce((sum, i) => +sum + +i.amount, 0)
            const lrNo = this.dataList
                .filter(i => i.consigneeName === g)
                .reduce((sum, i) => +sum + +i.lrNumber, 0)
            const rows = this.dataList
                .filter(i => i.consigneeName === g)
                .reduce((sum, i) =>  i + +1, 0)
                console.log("rosInFor"+rows);
                this.totRows = rows;
                console.log("rosInFor 2 >> "+this.totRows);
            this.result.push({
                consigneeName: g,
                values: this.gcTakenByDataListToPrintGroupingByPartyName.filter(i => i.consigneeName === g),
                memoNumber: memoNo,
                memoDateStr: memoDate,
                assignedDateStr: assignedDate,
                amount: amt,
                lrNumber: lrNo,
                totRows:rows,
            })
        })
    }

    clearField() {
        
        this.lrDto = [];
        this.totMemoNo = 0;
            this.totMemoAmt= 0;
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
