<html>

<head>
</head>

<body>
    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showPartyWiseOsRpt">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Party Wise OS Report</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>View Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-question"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}viewType" name="viewType" #viewType (change)="viewTypeMode(viewType.value)">
															<option selected value="summaryView">Summary
																View</option>
															<option value="detailView">Detail View</option>
															<option value="overallSummaryView">Overall
																Summary View</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>As On </label> <input class="form-control" id="{{pageId}}asOnDate" [(ngModel)]="asOnDateModel" placeholder="yyyy-mm-dd" name="asOnDate" ngbDatepicker #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignee Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input #consigneeName id="{{pageId}}consigneeName" name="consigneeName" type="text" class="form-control" placeholder="Select Consignee Name" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="consigneeNameSearchTA" (selectItem)="rowSelectedConsignee($event)"
                                                            [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterConsigneeName" [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}">
                                                        <label>Area Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																	class="fas fa-map-marker-alt"></i>
															</span>
                                                        </div>
                                                        <input #areaName id="{{pageId}}areaName" name="areaName" type="text" class="form-control" placeholder="Select Area" [(ngModel)]="modelAreaName" [ngbTypeahead]="areaNameSearchTA" [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterAreaName"
                                                            [inputFormatter]="formatterAreaName" (focus)="focusAreaNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Collection Man</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input #collectionMan id="{{pageId}}collectionMan" name="collectionMan" type="text" class="form-control" placeholder="Select Collection Man" [(ngModel)]="modelCollectionMan" [ngbTypeahead]="collectionManSearchTA" [ngModelOptions]="{standalone: true}"
                                                            [resultFormatter]="formatterCollectionMan" [inputFormatter]="formatterCollectionMan" (focus)="focusCollectionManTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}" />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod();">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearMethod();">Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <div class="row">
                            <!-- column -->
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row p-t-10">
                                            <div class="col-md-8"></div>
                                        </div>
                                        <table width="100%">
                                            <tr>
                                                <td width=80%>
                                                    <h6 class="card-title" [hidden]="!summaryTable">Party Wise Os Summary Report</h6>
                                                    <h6 class="card-title" [hidden]="!detailTable">Party Wise Os Details Report</h6>
                                                    <h6 class="card-title" [hidden]="!overallSummaryTable">Over All Summary View</h6>
                                                </td>
                                                <td width="20%">
                                                    <button type="submit" class="dt-button" style="margin-left: 60%;" (click)="customPrintPartyWiseOsSumRpt()" [hidden]="!summaryTable" id="{{pageId}}printAllBtn">
														<span><i class="fas fa-print">Print</i></span>
													</button>
                                                    <button type="submit" class="dt-button" style="margin-left: 7%;" (click)="groupingPrintPartyWiseOsDetailRpt()" [hidden]="!detailTable" id="{{pageId}}printAllBtn">
														<span><i class="fas fa-print">PartyWise</i></span>
													</button>
                                                    <button type="submit" class="dt-button" style="margin-left: 0%;" (click)="customPrintPartyWiseOsDetailRpt()" [hidden]="!detailTable" id="{{pageId}}printAllBtn">
														<span><i class="fas fa-print">Print</i></span>
													</button>
                                                    <button type="submit" class="dt-button" style="margin-left: 60%;" (click)="customPrintPartyWiseOsOvrAllRpt()" [hidden]="!overallSummaryTable" id="{{pageId}}printAllBtn">
														<span><i class="fas fa-print">Print</i></span>
													</button>
                                                </td>
                                            </tr>
                                        </table>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="box-body" [hidden]="!summaryTable">
                                                    <table datatable id="{{pageId}}summaryTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsSummary" [dtTrigger]="dtTriggerSummary">
                                                        <thead>
                                                            <tr>
                                                                <th>Party Name</th>
                                                                <th>Total Memo</th>
                                                                <th>Total Amount</th>
                                                                <th>Article</th>
                                                                <th>Area</th>
                                                                <th>Collection Man</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let smryData of summaryDataList ">
                                                                <td>{{ smryData.consigneeName }}</td>
                                                                <td>{{ smryData.totalMemos }}</td>
                                                                <td>{{ smryData.grandTotal }}</td>
                                                                <td>{{ smryData.totalArticles }}</td>
                                                                <td>{{ smryData.area }}</td>
                                                                <td>{{ smryData.collectionMan }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                <div class="box-body" [hidden]="!detailTable">
                                                    <table datatable id="{{pageId}}detailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionDetail" [dtTrigger]="dtTriggerDetail">
                                                        <thead>
                                                            <tr>
                                                                <th>Party</th>
                                                                <th>Memo Number</th>
                                                                <th>LR No</th>
                                                                <th>Memo Amount</th>
                                                                <th>Booking Date</th>
                                                                <th>No Of Articles</th>
                                                                <th>Memo Date</th>
                                                                <th>Cur Date - Memo Date</th>
                                                                <th>Area</th>
                                                                <th>Collection Man</th>
                                                                <th>Status</th>
                                                                <th>Invoice Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let detailData of detailDataList ">
                                                                <td>{{ detailData.consignee }}</td>
                                                                <td>{{ detailData.memoNumber }}</td>
                                                                <td>{{ detailData.lrNumber }}</td>
                                                                <td>{{ detailData.amount }}</td>
                                                                <td>{{ detailData.bookingDateStr }}</td>
                                                                <td>{{ detailData.article }}</td>
                                                                <td>{{ detailData.memoDateStr }}</td>
                                                                <td>{{ detailData.days }}</td>
                                                                <td>{{ detailData.area }}</td>
                                                                <td>{{ detailData.collectionMan }}</td>
                                                                <td>{{ detailData.status }}</td>
                                                                <td>{{ detailData.date }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                <div class="box-body" [hidden]="!overallSummaryTable">
                                                    <table datatable id="{{pageId}}overallSummaryTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsOverallSummary" [dtTrigger]="dtTriggerOverallSummary">

                                                        <thead>
                                                            <tr>
                                                                <th>GC Not Rec(Memo)</th>
                                                                <th>GC Not Rec(Amt)</th>
                                                                <th>Transfer To Collection(Memo)</th>
                                                                <th>Transfer To Collection(Amt)</th>
                                                                <th *ngIf="hideshowForKondiTransToCol">Transfer To Collection-Madhavaram(Memo)
                                                                </th>
                                                                <th *ngIf="hideshowForKondiTransToCol">Transfer To Collection-Madhavaram(Amt)
                                                                </th>
                                                                <th>SRD Stocks(Memo)</th>
                                                                <th>SRD Stocks(Amt)</th>
                                                                <th>Collection Man Summary(Memo)</th>
                                                                <th>Collection Man Summary(Amt)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let overallSummaryDataL of overallSummaryDataList ">
                                                                <!-- 																<td>{{ overallSummaryDataL.billingMemos }}</td> -->
                                                                <!-- 																<td> -->
                                                                <!-- 													<a [routerLink]="" -->
                                                                <!-- 														(click)="gcnoteReceivedPopUpBtnLink(gcnoteReceivedPopUpTemplate,overallSummaryDataL)"> -->
                                                                <!-- 														{{ overallSummaryDataL.billingMemos }}</a></td> -->
                                                                <td>
                                                                    <a (click)="getGcNotRec(overallSummaryDataL);" class="pointer">
                                                                        <u>{{ overallSummaryDataL.billingMemos
																			}}</u></a>
                                                                </td>
                                                                <td>{{ overallSummaryDataL.billingTotal }}</td>
                                                                <td>{{ overallSummaryDataL.transToCollMemos}}</td>
                                                                <td>{{ overallSummaryDataL.transToCollTotal}}</td>
                                                                <td *ngIf="hideshowForKondiTransToCol">{{ overallSummaryDataL.transToCollFrmBinnyMemos}}
                                                                </td>
                                                                <td *ngIf="hideshowForKondiTransToCol">{{ overallSummaryDataL.transToCollFrmBinnyTotal}}
                                                                </td>
                                                                <!-- 																<td>{{ overallSummaryDataL.srdStockMemos }}</td> -->
                                                                <td>
                                                                    <a (click)="getOsRpt(overallSummaryDataL);" class="pointer">
                                                                        <u>{{ overallSummaryDataL.srdStockMemos
																			}}</u></a>
                                                                </td>
                                                                <td>{{ overallSummaryDataL.srdStockTotal }}</td>
                                                                <td>{{ overallSummaryDataL.collManStockMemos}}</td>
                                                                <td>{{ overallSummaryDataL.collManStockTotal}}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td *ngIf="hideshowForKondiTransToCol"></td>
                                                                <td *ngIf="hideshowForKondiTransToCol"></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
    <div *ngIf="viewGroupingPrintPartywiseOs" onafterprint="afterPrint()" id="{{pageId}}viewGroupingPrintPartywiseOs">
        <app-party-wise-os-grouping-rpt-print></app-party-wise-os-grouping-rpt-print>
    </div>
</body>

</html>