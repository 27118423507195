import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { MemoReport } from 'src/app/dataService/memo-report';
import { MemoService } from 'src/app/dataService/memo-service';

@Component({
	selector: 'app-bkg-left-to-party-statement-details',
	templateUrl: './bkg-left-to-party-statement-details.component.html',
	styleUrls: ['./bkg-left-to-party-statement-details.component.css']
})
export class BkgLeftToPartyStatementComponent implements OnInit {

	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerLeftToPartyStmtDts: Subject<any> = new Subject();
	dtOptionsLeftToPartyStmtDts: any;
	leftToPartyPoupDataList: any;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	leftToPartyGeneralData: any;
	toPartyNameHeader: string;
	stmtNoHeader: string;
	pageId = "blpstc";

	constructor(private router: Router, private memoService: MemoService, public changeDetectorRef: ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.leftToPartyGeneralData = JSON.parse(localStorage.getItem('leftToPartyGeneralDetailsLocalStorage'));
			console.log(this.leftToPartyGeneralData);
			localStorage.clear();
			if (this.leftToPartyGeneralData != null &&
				this.leftToPartyGeneralData != undefined &&
				this.leftToPartyGeneralData != "") {
				this.toPartyNameHeader = this.leftToPartyGeneralData.topartyName;
				this.stmtNoHeader = this.leftToPartyGeneralData.statementNo;
				this.gridReconfigureTpStmtDetails();
			}
		}
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	ngOnInit(): void {
		this.dtOptionsLeftToPartyStmtDts = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						return "Left To Party Statement Details - ";
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var memoAmt = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(5).footer()).html(memoAmt);
			},
		}
	}

	ngOnDestroy(): void {
		this.dtTriggerLeftToPartyStmtDts.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerLeftToPartyStmtDts.next();
	}

	gridReconfigureTpStmtDetails() {
		let cashMemoDto: CashMemoDto = new CashMemoDto();
		cashMemoDto.companyId = this.userDataDtoReturnSession.companyId;
		cashMemoDto.statementNo = this.leftToPartyGeneralData.statementNo;
		cashMemoDto.memoType = "StatementNo";
		this.showSpinnerForAction = true;
		this.memoService.getCashMemoUnAssgBkgService(cashMemoDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				this.leftToPartyPoupDataList = [];
				$("#" + this.pageId + "leftToPartyStmtTableId").DataTable().destroy();
				if (response.length > 0) {
					this.leftToPartyPoupDataList = response;
				} else {
					swal("No Result", "No records found", "warning");
				}
				this.dtTriggerLeftToPartyStmtDts.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Failed", "Server problem occurred while getting TP Stmt Details", "error");
			}, () => console.log('done');
	}

}

