<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}truckOwnDeclarationRptId">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Search By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-search"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}viewType" name="viewType" #viewType (change)="viewTypeMode(viewType.value)">
                                                            <option selected value="destination">Source and
                                                                Destination</option>
                                                            <option value="panNumber">Pan Number</option>
                                                            <option value="truckNumber">Truck Number</option>
                                                            <option value="supplierName">Supplier Name</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" id="{{pageId}}fromDates" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDates" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div *ngIf="searchByDestAndSrc" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
                                                            </span>
                                                        </div>
                                                        <input id="{{pageId}}sourceId" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" (selectItem)="sourceListener($event)" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                                            placeholder="Select Source" />
                                                    </div>

                                                </div>
                                            </div>

                                            <div *ngIf="searchByDestAndSrc" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <input id="{{pageId}}destinationId" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="clickListnerForDestination($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                                (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Station" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="searchByPanNo" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Pan Number</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-id-card"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}panNumber" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="searchByTruckNo" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Truck Number</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-truck"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}truckNumber" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="searchBySupplierName" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Supplier Name <span class="mandatoryField_Style"> *
                                                                </span>
                                                            </label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <input id="{{pageId}}supplierName" type="text" class="form-control" (selectItem)="clickListnerForSupplier($event)" [(ngModel)]="modelSupplierName" [ngbTypeahead]="searchSupplierName" [resultFormatter]="formatterSupplierName" [inputFormatter]="formatterSupplierName"
                                                                (focus)="focusSupplierNameTA$.next($any($event).target.value)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Upload By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-upload"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}uploadBy">
                                                            <option value="uploadByPanNumber" selected>Pan
                                                                Number</option>
                                                            <option value="uploadByTruckNumber">Truck Number</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateBtnSearch();">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearBtn();">Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <!-- spinner start-->
                        <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                    </mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <!-- spinner end-->
                        <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrinttruckOwnDecRpt()" id="{{pageId}}printAllBtn">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                        <div class="box-body">
                            <table datatable id="{{pageId}}truckOwnerDeclTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTruckOwnerDeclarationRpt" [dtTrigger]="dtTriggerTruckOwnerDeclarationRpt">

                                <thead>
                                    <tr>
                                        <th>Pan No.</th>
                                        <th>Payment Date</th>
                                        <th>Hireslip Date</th>
                                        <th>Owner Name</th>
                                        <th>Truck No.</th>
                                        <th>Supplier Name</th>
                                        <th>Source</th>
                                        <th>Destination</th>
                                        <th [hidden]="!showHireColumns">Hire Amount</th>
                                        <th>TDS (Y/N)</th>
                                        <th>TDS Amount</th>
                                        <th>TDS Percent</th>
                                        <th>View Image</th>
                                        <th>Re-Upload Image</th>
                                        <th>Remove Image</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let truckOwnerData of truckOwnerDeclarationRptDataList ">
                                        <td>{{ truckOwnerData.panNo }}</td>
                                        <td>{{ truckOwnerData.paymentDateStr }}</td>
                                        <td>{{ truckOwnerData.departuredateStr }}</td>
                                        <td>{{ truckOwnerData.companyName }}</td>
                                        <td>{{ truckOwnerData.vehicleNumber }}</td>
                                        <td>{{ truckOwnerData.suppliername }}</td>
                                        <td>{{ truckOwnerData.fromstation }}</td>
                                        <td>{{ truckOwnerData.tostation }}</td>
                                        <td [hidden]="!showHireColumns">{{ truckOwnerData.totalhire }}</td>
                                        <td>{{ truckOwnerData.tdsYesNo }}</td>
                                        <td>{{ truckOwnerData.tdsAmount }}</td>
                                        <td>{{ truckOwnerData.tdsPercent }}</td>
                                        <td style="cursor: pointer; color: blue;">
                                            <a (click)="getImg(truckOwnerData,truckOwnerDeclPopUpTemplate);">
                                                <u>{{ truckOwnerData.imgURLPan != null ? "View
                                                    Declaration" : "Upload" }}</u>
                                            </a>
                                        </td>
                                        <td style="cursor: pointer; color: blue;">
                                            <a (click)="getImgForReUpload(truckOwnerData,truckOwnerDeclPopUpTemplate);">
                                                <u>{{ truckOwnerData.imgURLPan != null ? "Re-Upload" :
                                                    "----" }}</u>
                                            </a>
                                        </td>
                                        <td style="cursor: pointer; color: blue;">
                                            <a (click)="getImgForRemove(truckOwnerData);"> <u>{{
                                                    truckOwnerData.imgURLPan != null ? "Remove Image" : "----"
                                                    }}</u></a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td [hidden]="!showHireColumns"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <ng-template #truckOwnerDeclPopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-header">
                        <h5>Declaration Upload</h5>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                                    </mat-progress-bar>
                                                    <br>
                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                        Please Wait Uploading Documnents....</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label> File-1:</label> <input type="file" id="{{pageId}}truckOwnerDeclFileUpload" (change)="onFileChangedTruckOwnerDecl($event)" #fileInputTruckOwnerDecl />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label> File-2:</label> <input type="file" id="{{pageId}}truckOwnerDeclFileUpload2" (change)="onFileChangedTruckOwnerDecl2($event)" #fileInputTruckOwnerDecl2 />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3">
                                            <button style="float: right;" type="button" class="btn btn-primary m-r-10" id="{{pageId}}uploadBtnId" (click)="uploadTruckOwnerDeclBtnPopUp()">Upload</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeTruckOwnerDeclBtnPopUp()">Cancel</button>
                                            <!--<button style="float: right;" type="button" class="btn btn-success m-r-10"
												id="{{pageId}}applyBtnId" (click)="applyAdvancePaymentBtnPopUp()">Apply</button>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>

</body>

</html>