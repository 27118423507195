import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { merge, Observable, Subject } from "rxjs";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from "rxjs/operators";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ReportService } from "src/app/dataService/report-service";
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { LRDto } from "src/app/dto/LR-dto";
import swal from "sweetalert";

@Component({
    selector: 'app-ranking',
    templateUrl: './ranking.component.html',
    styleUrls: ['./ranking.component.css']
})
export class RankingComponent implements OnInit {
    RankingDataList: any;
    dtTriggerRanking: Subject<any> = new Subject();
    dtOptionsRanking: any;
    agentNameOptions: AgentDetailsDto[];
    agentDtoForAgentName: AgentDetailsDto = new AgentDetailsDto();

    public modelAgentName: any;
    agentNameTA: Array<AgentDetailsDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    searchAgentName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusAgentNameTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ""
                    ? this.agentNameTA
                    : this.agentNameTA.filter(
                        (v) => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1
                    )
                ).slice(0, 200)
            )
        );
    };
    agentDto: AgentDetailsDto = new AgentDetailsDto();
    agentDtoAll: AgentDetailsDto = new AgentDetailsDto();
    formatterAgentName = (x: { subStation: string }) => x.subStation;
    userDataDtoReturnSession: any;
    superAdminview: boolean = false;
    showSpinnerForAction = false;
    isLoggedIn = true;

    //For Custom Print
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;

    //For Print Summary
    actWghtSummary: number;
    chgWghtSummary: number;
    netAmtSummary: number;
    additionSummary: number;
    detuctSummary: number;
    paidOnSummary: number;
    cashSummary: number;
    chequeSummary: number;
    pdcSummary: number;

    lrDtoForBtnSearch: LRDto = new LRDto();
    selectedAgent: any;
    selectedPrevious: any;
    pageId = "rnkrpt"

    constructor(private reportService: ReportService,
        private masterReadService: MasterReadService,
        private router: Router, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            });
        }

        // Rights For Agent
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                //    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
                for (
                    let i = 0;
                    i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length;
                    i++
                ) {
                    if (
                        this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
                        this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "AgtRankRpt ShowAll"
                    ) {
                        this.superAdminview = true;


                    }
                }
            }
        }
        this.getAgentDetailList();
    }
    logInPage() {
        this.router.navigate(["/authentication/login"]);
    }

    ngOnInit(): void {
        this.dtOptionsRanking = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                title: function () {
                    var returnPaidDest = null;
                    returnPaidDest = "Agent Ranking Report" + " Agent Name : " + $("#rnkrptagentName").val();
                    return returnPaidDest;
                }
            }],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },

            processing: true,

            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var actWgt = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chgWgt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var netamt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(actWgt);
                $(api.column(2).footer()).html(chgWgt);
                $(api.column(3).footer()).html(netamt);
            }
        }
    }
    ngOnDestroy(): void {
        this.dtTriggerRanking.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerRanking.next();
    }
    //For Agent Name Listener
    clickListnerForAgentName(e: NgbTypeaheadSelectItemEvent) {
        this.modelAgentName = e.item;
        $("#" + this.pageId + "agentName").val(this.modelAgentName.subStation);
    }
    getUserValuesForAgentDetailsList() {
        this.agentDto.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDto.column2 = "Working";
        this.agentDto.mode = "Sub";
        this.agentDto.status = "All";

        if (this.superAdminview == true) {
            this.agentDto.mode = "Sub";
            this.agentDto.status = "All";
        } else {
            this.agentDto.mode = "mainBranch";
            this.agentDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        }
    }

    getAgentDetailList() {
        this.getUserValuesForAgentDetailsList();
        this.showSpinnerForAction = true;
        this.masterReadService.getSourceListDetails(this.agentDto).subscribe(
            (response) => {
                this.agentNameTA = [];
                this.agentNameOptions = [];

                console.log(this.agentNameOptions);
                if (response.length > 0) {
                    this.agentNameOptions = response;
                    this.agentDtoAll.subStation = "All";
                    this.agentNameTA.push(this.agentDtoAll);
                    for (let i = 0; i < this.agentNameOptions.length; i++) {
                        this.agentNameTA.push(this.agentNameOptions[i]);
                    }
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            }, () => console.log('done');
    };
    //For Custom Print
    customPrintAgentRankingRpt() {
        if (this.RankingDataList.length == 0) {
            swal({
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }
        else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Agent Name", "Actual Weight", "Charged Weight", "Net Amount", "Addition", "Deduction", "Paid ON", "Cash", "Cheque", "Pdc", "Remarks"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [15, 8, 8, 8, 10, 8, 8, 9, 8, 8, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.actWghtSummary = this.chgWghtSummary = this.netAmtSummary = this.additionSummary = this.detuctSummary = this.cashSummary = this.chequeSummary = this.pdcSummary = 0;

            for (let i = 0; i < this.RankingDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.RankingDataList[i].agentName, this.RankingDataList[i].actualWeight,
                this.RankingDataList[i].chargedWeight, this.RankingDataList[i].toPay,
                this.RankingDataList[i].lcChg, this.RankingDataList[i].bcChg, this.RankingDataList[i].bookingDateStr, this.RankingDataList[i].cashAmt, this.RankingDataList[i].chqAmt,
                this.RankingDataList[i].penaltyAmt, this.RankingDataList[i].remarks];

                /////////

                this.actWghtSummary = this.actWghtSummary + this.RankingDataList[i].actualWeight;
                this.chgWghtSummary = this.chgWghtSummary + this.RankingDataList[i].chargedWeight;
                this.netAmtSummary = this.netAmtSummary + this.RankingDataList[i].toPay;
                this.additionSummary = this.additionSummary + this.RankingDataList[i].lcChg;
                this.detuctSummary = this.detuctSummary + this.RankingDataList[i].bcChg;
                this.cashSummary = this.cashSummary + this.RankingDataList[i].cashAmt;
                this.chequeSummary = this.chequeSummary + this.RankingDataList[i].chqAmt;
                this.pdcSummary = this.pdcSummary + this.RankingDataList[i].penaltyAmt;


                /////
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.RankingDataList.length, this.actWghtSummary, this.chgWghtSummary, this.netAmtSummary,
            this.additionSummary, this.detuctSummary, "", this.cashSummary, this.chequeSummary, this.pdcSummary, ""];

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printTitle', "Agent Ranking Report");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }

    searchMethod() {
        this.selectedAgent = $("#" + this.pageId + "agentName").val();
        this.selectedPrevious = $("#" + this.pageId + "previous").val();

        this.lrDtoForBtnSearch = new LRDto();
        this.lrDtoForBtnSearch.agentId = this.selectedAgent;

        if (this.selectedAgent != null && this.selectedAgent == "All") {
            this.lrDtoForBtnSearch.mode = "ALL";
        } else {
            this.lrDtoForBtnSearch.mode = "SPECIFIC";
        }
        if (this.superAdminview == false) {
            this.lrDtoForBtnSearch.mainstation = this.userDataDtoReturnSession.mainAdminStation;
        }
        this.lrDtoForBtnSearch.companyId = this.userDataDtoReturnSession.companyId;

        if (this.selectedPrevious != null && this.selectedPrevious != '' && this.selectedPrevious != 'currentStatement') {
            this.lrDtoForBtnSearch.id = this.selectedPrevious;
        } else {
            this.lrDtoForBtnSearch.id = 0;
        }
        console.log(this.lrDtoForBtnSearch);
        this.getSearchMethodDetails();
    }

    getSearchMethodDetails = () => {
        this.showSpinnerForAction = true;
        this.reportService.getAgentRankingReport(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "rankingTableId").DataTable().destroy();
                this.RankingDataList = [];
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.RankingDataList = response;

                }
                this.dtTriggerRanking.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Agent Ranking Report details", "info");
            }, () => console.log('done');
    };

    clearAll() {

        this.showSpinnerForAction = false;
        $("#" + this.pageId + "rankingTableId").DataTable().destroy();
        this.RankingDataList = [];
        this.lrDtoForBtnSearch = new LRDto();
        this.selectedAgent = '';
        this.selectedPrevious = '';
        $("#" + this.pageId + "previous").val('Select Previous');
        $("#" + this.pageId + "agentName").val('');
        this.modelAgentName = '';
        this.lrDtoForBtnSearch = new LRDto();
        //For Custom Print
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrintList = [];
        this.cashMemoDtoForCustomPrintListColumnNames = [];
        this.cashMemoDtoForCustomPrintListColumnWidths = [];
        this.cashMemoDtoForCustomPrintDataList = [];
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [];
        this.cashMemoDtoForCustomPrintDataSummaryList = [];
        this.viewCustomPrintV1 = false;
        this.dtTriggerRanking.next();
        this.showSpinnerForAction = false;
        localStorage.clear();

    }
}
