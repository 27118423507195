<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showTruckRpt">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Search By</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-search"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchByMode(searchBy.value)">
														<option selected value="Truck Details Rpt Wise">Truck Details
															Report</option>
														<option value="Lorry Hire Rpt Wise">Lorry Hire
															Report</option>
														<option value="Truck Invoice Rpt Wise">Truck
															Invoice Report</option>
													</select>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="viewDate" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" placeholder="dd-mm-yyyy" name="fromDate" [(ngModel)]="setTodayDateOnFromDate" ngbDatepicker #fromDate="ngbDatepicker">
                                                    <div class="input-group-append" (click)="fromDate.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="viewDate" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" [(ngModel)]="setTodayDateOnToDate" placeholder="dd-mm-yyyy" name="toDate" ngbDatepicker #toDate="ngbDatepicker">
                                                    <div class="input-group-append" (click)="toDate.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="viewSupplier" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Supplier Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <input id="{{pageId}}supplierName" type="text" class="form-control" [(ngModel)]="modelSupplierName" [ngbTypeahead]="searchSupplierName" [resultFormatter]="formatterSupplierName" [inputFormatter]="formatterSupplierName" (focus)="focusSupplierNameTA$.next($any($event).target.value)"
                                                        placeholder="Select Supplier" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Station Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
                                                        </div>

                                                        <input id="{{pageId}}toStationName" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination" (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                            placeholder="Select To Station Name" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="viewSource" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Source</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">
															<i class="ti-user"></i>
														</span>
                                                    </div>
                                                    <input id="{{pageId}}source" type="text" name="source" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                                        placeholder="Select Source" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <hr style="width: 80%; border-top: none; margin: 3px;">
                                    <div class="col-md-12" style="text-align: center;">
                                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="getSearchMethod()">Search</button>
                                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
                                    </div>
                                </div>

                                <div class="col-md-9 vl p-t-10">
                                    <div class="card-body">
                                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                                    </mat-progress-bar>
                                                    <br>
                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                        Please Wait Loading Details.....</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row p-t-10">

                                            <div class="col-md-8">
                                                <h6 class="card-title" [hidden]="!truckDetailsTable">Truck Details Report
                                                </h6>
                                                <h6 class="card-title" [hidden]="!lorryHireRptTable">Lorry Hire Report
                                                </h6>
                                                <h6 class="card-title" [hidden]="!truckInvoiceRptTable">Truck Invoice Report
                                                </h6>
                                            </div>
                                            <div class="col-md-4">
                                                <button type="submit" class="dt-button" style="margin-left:225px;" id="{{pageId}}printAllBtn" (click)="printRpt()">
													<span><i class="fas fa-print">Print</i></span>
												</button>
                                            </div>
                                        </div>
                                        <div class="box-body" [hidden]="!truckDetailsTable">
                                            <table datatable id="{{pageId}}truckDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTruckDetailsRpt" [dtTrigger]="dtTriggerTruckDetailsRpt">
                                                <thead>
                                                    <tr>
                                                        <th>Truck No</th>
                                                        <th>From Station</th>
                                                        <th>To Station</th>
                                                        <th>Dep Date</th>
                                                        <th>Dep Time</th>
                                                        <th>Supplier</th>
                                                        <th>G.Wgt</th>
                                                        <th>K.Wgt</th>
                                                        <th>Act Wgt</th>
                                                        <th>Chg Wgt</th>
                                                        <th>To Pay</th>
                                                        <th>Paid</th>
                                                        <th>Freight</th>
                                                        <th [hidden]="!showHireColumns">Tot Hire</th>
                                                        <th>Lorry Type</th>
                                                        <th>Truck Lgt</th>
                                                        <th>Loaded By</th>
                                                        <th>Hireslip Remarks Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let truckRptDetail of truckRptDetailDataList">
                                                        <td>{{ truckRptDetail.vehicleNumber }}</td>
                                                        <td>{{ truckRptDetail.fromStation }}</td>
                                                        <td>{{ truckRptDetail.toStation }}</td>
                                                        <td>{{ truckRptDetail.departuredateStr }}</td>
                                                        <td>{{ truckRptDetail.departureTime }}</td>
                                                        <td>{{ truckRptDetail.suppliername }}</td>
                                                        <td>{{ truckRptDetail.gurWeight }}</td>
                                                        <td>{{ truckRptDetail.kantaWeight }}</td>
                                                        <td>{{ truckRptDetail.actWeight }}</td>
                                                        <td>{{ truckRptDetail.chargedWt }}</td>
                                                        <td>{{ truckRptDetail.toPay }}</td>
                                                        <td>{{ truckRptDetail.paid }}</td>
                                                        <td>{{ truckRptDetail.totalAmt }}</td>
                                                        <td [hidden]="!showHireColumns">{{ truckRptDetail.totalhire }}</td>
                                                        <td>{{ truckRptDetail.truckType }}</td>
                                                        <td>{{ truckRptDetail.trkLgth }}</td>
                                                        <td>{{ truckRptDetail.loadedby }}</td>
                                                        <td>{{ truckRptDetail.remarks }}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td [hidden]="!showHireColumns"></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                        <div class="box-body" [hidden]="!lorryHireRptTable">
                                            <table datatable id="{{pageId}}lorryHireRptTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLorryHireRpt" [dtTrigger]="dtTriggerLorryHireRpt">

                                                <thead>
                                                    <tr>
                                                        <th>Station</th>
                                                        <th>Hireslip No.</th>
                                                        <th>Lorry No.</th>
                                                        <th>Supplier Name</th>
                                                        <th>G. Wgt</th>
                                                        <th>K. Wgt</th>
                                                        <th [hidden]="!showHireColumns">Total Hire</th>
                                                        <th [hidden]="!showHireColumns">Advance</th>
                                                        <th>Dep Date</th>
                                                        <th>Invoice No</th>
                                                        <th>Invoice Date</th>
                                                        <th>Lorry Type</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let lorryHireRpt of lorryHireRptDataList">
                                                        <td>{{ lorryHireRpt.toStation }}</td>
                                                        <td>{{ lorryHireRpt.hireslipnumber }}</td>
                                                        <td>{{ lorryHireRpt.vehicleNumber }}</td>
                                                        <td>{{ lorryHireRpt.suppliername }}</td>
                                                        <td>{{ lorryHireRpt.gurWeight }}</td>
                                                        <td>{{ lorryHireRpt.kantaWeight }}</td>
                                                        <td [hidden]="!showHireColumns">{{ lorryHireRpt.totalhire }}</td>
                                                        <td [hidden]="!showHireColumns">{{ lorryHireRpt.advance }}</td>
                                                        <td>{{ lorryHireRpt.departuredateStr }}</td>
                                                        <td>{{ lorryHireRpt.hireslipnumber }}</td>
                                                        <td>{{ lorryHireRpt.hireSlipDateStr }}</td>
                                                        <td>{{ lorryHireRpt.truckType }}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td [hidden]="!showHireColumns"></td>
                                                        <td [hidden]="!showHireColumns"></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                        <div class="box-body" [hidden]="!truckInvoiceRptTable">

                                            <table datatable id="{{pageId}}truckInvoiceRptTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTruckInvoiceRpt" [dtTrigger]="dtTriggerTruckInvoiceRpt">

                                                <thead>
                                                    <tr>
                                                        <th>Truck No</th>
                                                        <th>Invoice No</th>
                                                        <th>Article</th>
                                                        <th>Guarantee Weight</th>
                                                        <th>Actual Weight</th>
                                                        <th>Charged Weight</th>
                                                        <th>To Pay</th>
                                                        <th>Paid</th>
                                                        <th>Destination</th>
                                                        <th>Invoice Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let truckInvoiceRpt of truckInvoiceRptDataList">
                                                        <td>{{ truckInvoiceRpt.vehicleNumber }}</td>
                                                        <td>{{ truckInvoiceRpt.invoiceNumber }}</td>
                                                        <td>{{ truckInvoiceRpt.totalArticles }}</td>
                                                        <td>{{ truckInvoiceRpt.gurWeight }}</td>
                                                        <td>{{ truckInvoiceRpt.actWeight }}</td>
                                                        <td>{{ truckInvoiceRpt.chargedWt }}</td>
                                                        <td>{{ truckInvoiceRpt.toPay }}</td>
                                                        <td>{{ truckInvoiceRpt.paid }}</td>
                                                        <td>{{ truckInvoiceRpt.toStation }}</td>
                                                        <td>{{ truckInvoiceRpt.hireSlipDateStr }}</td>
                                                    </tr>

                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>

                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
    <div *ngIf="viewCustomPrintV2" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV2">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
    <div *ngIf="viewCustomPrintV3" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV3">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>