import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ConsignorService } from 'src/app/dataService/consignor-service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { DragulaService, dragula } from "ng2-dragula";
//for drag and drop ends
//for popup modal starts 
import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from "src/app/dataService/memo-service";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MemoReport } from 'src/app/dataService/memo-report';
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment  from 'moment';
import { ConsigneeService } from 'src/app/dataService/consignee-service';

@Component( {
    selector: 'app-lc-and-bc-report',
    templateUrl: './lc-and-bc-report.component.html',
    styleUrls: ['./lc-and-bc-report.component.css']
} )
export class LcAndBcReportComponent implements OnInit {
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends


    loadingIndicator = true;
    lcAndBcSummaryTable = true;
    lcAndBcDetailsTable = false;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    //for datepicker ends

    getDataFrmServiceFrLCBCSummaryTable: any;
    lcbcSummaryReportDataList: any;
    lcbcDetailedReportDataList:any;

    getDataFrmServiceFrLCBCDetailedTable: any;
    controlMainSource = new FormControl();
    controlMainAgent = new FormControl();

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLCBCSummaryReport: Subject<any> = new Subject();
    dtTriggerLCBCDetailedReport: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsLCBCSummaryReport: any;
    dtOptionsLCBCDetailedReport: any;
	summaryTable=true;
	detailTable=false;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	address:any;
	 showSource = false;
	 //For Source
	    sourceOptions: LRDto[];
	    lrDtoSource: LRDto = new LRDto();
	    public modelSource: any;
	    sourceTA: Array<LRDto> = [];
	  
	    focusSourceTA$ = new Subject<string>();
	    searchSource = (text$: Observable<string>) => {
	        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
	        const inputFocus$ = this.focusSourceTA$;

	        return merge(debouncedText$, inputFocus$).pipe(
	            map(term => (term === '' ? this.sourceTA
	                :  this.sourceTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
	            
	        );
	    }
	    formatterSource = (x: { subStations: string }) => x.subStations;
	  //sourceList
	    srcLists:any;
	    srcList: LRDto = new LRDto();
	    ///
	   
	    searchLrRptDto = new LRDto();
	    lrDtoSourceAllOption: LRDto = new LRDto();
	    lrDtoSourceAddOption: LRDto = new LRDto();
	    setSource: Array<any> = [];
	   // lrDtoSource: LRDto = new LRDto();
	    lrDtoSourceOptions: LRDto[];
	    srcSupAdmin = false;
	    srcBkgAdmin=false;
	    fromDateModel:any;
	    toDateModel:any;
	    //Agent
	      sourceAgentDto: AgentDetailsDto = new AgentDetailsDto();
    sourceAgentDtoAll: AgentDetailsDto = new AgentDetailsDto();
    sourceDetailsOptionsGet: AgentDetailsDto[];
	    public modelAgentName: any;
	    agentTA: Array<AgentDetailsDto> = [];
	    
	    focusAgentNameTA$ = new Subject<string>();
	    searchAgentName = (text$: Observable<string>) => {
	        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
	        const inputFocus$ = this.focusAgentNameTA$;

	        return merge(debouncedText$, inputFocus$).pipe(
	            map(term => (term === '' ? this.agentTA
	                :  this.agentTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
	            
	        );
	    }
	    formatterAgentName = (x: { subStation: string }) => x.subStation;
		 selectedFromDate:any;
         selectedToDate:any;
	    selectedAgentName:any;
		 selectedSource:any;
	     selectedViewType:any;
		  searchBySummaryLrdto: LRDto = new LRDto();
		   searchByDetailedLrdto: LRDto = new LRDto();
	    superAdminView=false;
		showSourceForBkgListLCBCRpt="Show Bkg Source For LCBC Rpt"
		showSourceForSupAdLCBCRpt="Show Source In LCBC Rpt For SupAdmin"
	    ///
	    
	    //For Custom Print
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;

	//Custom Print Footer
	totalLrsSummary: number;
	totArticleSummary: number;
	actWghtSummary: number;
	chagWghtSummary: number;
	artSummary: number;
	lcSummary: number;
	bcSummary: number;

	viewCustomPrintV1 = false;
	pageId="lcbcr";
	
      constructor(private memoLessRpt: ReportService, private router: Router,
                private memoReport: MemoReport, private masterReadService: MasterReadService, private modalService: NgbModal,private lrReportService: LrReportService, private consigneeService: ConsigneeService, public changeDetectorRef : ChangeDetectorRef) {

        	  if (sessionStorage.length == 0) {
                  this.isLoggedIn = false;
                  swal({
                      title: "Session Expired",
                      text: "Please relogin to access the application!",
                      icon: "error",
                      closeOnClickOutside: false,
                      closeOnEsc: false,
                  }).then(() => {
                      this.logInPage();
                  })
              }
              if (this.isLoggedIn) {
                  this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
                  this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
                  this.getAgentDetails();
                  this.setSource.push(this.userDataDtoReturnSession.mainStation);
				  
				   ///Rights for show Show Source List
                 //            if ( this.showSourceForBkgListLCBCRpt == "Show Bkg Source For LCBC Rpt" ) {
                 //                this.showSource = true;
                 //this.getSourceList();
               
                    //} 
					
					 ///Rights for show Show Source List For SubAdmin
                 //            if ( this.showSourceForSupAdLCBCRpt == "Show Source In LCBC Rpt For SupAdmin" ) {
                 //                this.showSource = true;
                 //this.getSourceDetails();
               
                    //} 
                  if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights != null ) {
                      //    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
                          for ( let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++ ) {
                            
                              if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                                  && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                                  "LcBcRpt SrcSupAdmin" ) {
                                this.showSource = true;
                                  this.srcSupAdmin = true;
                                this.getSourceDetails();
                              }else if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                                  && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                                  "LcBcRpt SrcBkgAdmin" ) {
                            	  this.showSource = true;
                            	  this.getSourceList();
                            	  this.srcBkgAdmin=true;				 
                              }else if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                                      && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                                      "LcBcRpt ShowAll" ) {
                                	  this.superAdminView=true;
                                  }
                          
                          }
                      }
                 
              }
            
        }
        logInPage() {
            this.router.navigate(['/authentication/login']);
        }
        
        getSourceList() {
	        //   this.srcList="";
	       this.srcLists = this.userDataDtoReturnSession.stationList;
	       this.srcList.subStations = this.srcLists;
	      // this.sourceDetailsListGet.subStation = this.srcLists;
	        console.log(this.srcLists);
	        console.log(this.srcList.subStations);
	        this.sourceTA = [];
            this.lrDtoSourceAllOption.subStations = 'All';
            this.sourceTA.push(this.lrDtoSourceAllOption);
	        for ( let i = 0; i < this.srcList.subStations.length; i++ ) {
	        	this.lrDtoSourceAddOption= new LRDto(); 
	        	this.lrDtoSourceAddOption.subStations = this.srcList.subStations[i];
                this.sourceTA.push(this.lrDtoSourceAddOption);
	        }
	        window.setTimeout( function() {
	        	$("#"+this.pageId+"source").val('All');
	        }, 200 );
			
	    }
	    

    rerender(): void {
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                dtElement.dtInstance.then((dtInstance: any) => {
                  
                    if (dtInstance.table().node().id == "lcbcSummaryRptId") {
                        console.log("a");
                        this.lcbcSummaryReportDataList = [];
                        dtInstance.destroy();
                        this.dtTriggerLCBCSummaryReport.next();
                    } else if (dtInstance.table().node().id == "lcbcDetailedRptId") {
                        console.log("b");
                        this.lcbcDetailedReportDataList = [];
                        dtInstance.destroy();
                        this.dtTriggerLCBCDetailedReport.next();
                    } 
                });
            });
        }
      
		clickTA(inp) {
            inp._elementRef.nativeElement.value = '';
            inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
            inp._elementRef.nativeElement.focus();
        }
    ngOnInit(): void {
			var companyAddressDetls = this.address;
             	var subSource=null
             	if( this.srcSupAdmin==true || this.srcBkgAdmin==true){
             		subSource=null;
             	}else{
             		subSource ="Source : " +this.userDataDtoReturnSession.office;
             	}

        this.dtOptionsLCBCSummaryReport = {
          dom: 'Bfrtip',                   
                    buttons: [
				{
					extend: 'print',
					text: '<i class="fas fa-print"> Print</i>',
					titleAttr: 'Print',
					customize : function(win) {
						$(win.document.body).css('font-size','10pt');
						$(win.document.body).find('th, td').
						css('font-family','Arial, Helvetica, sans-serif')
						.css('font-size','12px').css('text-align', 'center');
					},
					footer : true,					
					messageTop : function() {
						var returnVal=null;
						if (subSource != null) {
							returnVal = "<table style='table-layout: fixed;width: 100%;'><tr>"+
									"<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>"+
									"<u>LC And BC Report Summary</u><br><br>"+
									//"</td>"+
									//"<td style='word-wrap: break-word;'>"+
									"From Date : "+ moment($("#lcbcrfromDate").val()).format('DD-MM-YYYY') + " -  "+
									"To Date : " +  moment($("#lcbcrtoDate").val()).format('DD-MM-YYYY') + "<br><br>"+subSource+" Agent Name : "+$("#lcbcragentName").val()+
									"</td>"+
									"</tr></table><br>";
						} else {
							returnVal = "<table style='table-layout: fixed;width: 100%;'><tr>"+
									"<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>"+
									"<u>LC And BC Report Summary</u><br><br>"+
									//"</td>"+
									//"<td style='word-wrap: break-word;'>"+
									"From Date : "+ moment($("#lcbcrfromDate").val()).format('DD-MM-YYYY') + " -  "+
									"To Date : " +  moment($("#lcbcrtoDate").val()).format('DD-MM-YYYY') + "<br><br>Source : "+ $("#lcbcrsource").val()+" Agent Name : "+$("#lcbcragentName").val()+
									"</td>"+
									"</tr></table><br>";
						}
						return returnVal;
						
					},
					title : function() {
						return "<table style='table-layout: fixed;width: 100%;'><tr>"+
						"<td style='word-wrap: break-word;width: 50%;'>"+
						"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>"+
						"</td>"+
						"<td style='word-wrap: break-word;width: 50%;text-align: right;'>"+
						      companyAddressDetls +
						"</td>"+
						"</tr></table>";
					}
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer : true,
					title : function() {
					
					var returnSummaryExcel=null;
						if (subSource != null) {
							returnSummaryExcel = "LC And BC Report Summary"+
									"From Date : "+ moment($("#lcbcrfromDate").val()).format('DD-MM-YYYY') + " -  "+
									"To Date : " +  moment($("#lcbcrtoDate").val()).format('DD-MM-YYYY') + " "+subSource+" Agent Name : "+$("#lcbcragentName").val();
						} else {
							returnSummaryExcel = "LC And BC Report Summary"+
									"From Date : "+ moment($("#lcbcrfromDate").val()).format('DD-MM-YYYY') + " -  "+
									"To Date : " +  moment($("#lcbcrtoDate").val()).format('DD-MM-YYYY') + "  Source : "+ $("#lcbcrsource").val()+" Agent Name : "+$("#lcbcragentName").val();
						}
						return returnSummaryExcel;
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],  
                          language: {
                              search: "_INPUT_",
                              searchPlaceholder: "Search..."
                          },
                          processing: true,
              			//scroll in datatable starts
              			responsive: true,
              			"scrollX": true,
              			"scrollY": 300,
              			"scrollCollapse": true,
              			"paging": true,
              			"info": true,
              			"footerCallback": function ( row, data, start, end, display ) {
              				var api = this.api(), data;
              				// converting to interger to find total
              				var intVal = function ( i ) {
              					return typeof i === 'string' ?
              					+i.replace(/[\$,]/g, '') * 1:
              						typeof i === 'number' ?
              							i : 0;
              				};  
						var totalLrs = api.column( 1 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
						var articles = api.column( 2 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
							
						var actualWgt = api.column( 3 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
						var chargedWgt = api.column( 4 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
						var lc = api.column( 5 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
						var bc = api.column( 6 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
							
							$( api.column( 0 ).footer() ).html('Total : '+data.length);
							$( api.column( 1 ).footer() ).html(totalLrs);
							$( api.column( 2 ).footer() ).html(articles);
							$( api.column( 3 ).footer() ).html(actualWgt);
							$( api.column( 4 ).footer() ).html(chargedWgt);
							$( api.column( 5 ).footer() ).html(lc);
							$( api.column( 6 ).footer() ).html(bc);
	
                          }
                },
            this.dtOptionsLCBCDetailedReport = {
                dom: 'Bfrtip',                   
                    buttons: [
				{
					extend: 'print',
					text: '<i class="fas fa-print"> Print</i>',
					titleAttr: 'Print',
					customize : function(win) {
						$(win.document.body).css('font-size','10pt');
						$(win.document.body).find('th, td').
						css('font-family','Arial, Helvetica, sans-serif')
						.css('font-size','12px').css('text-align', 'center');
					},
					footer : true,					
					messageTop : function() {
						var returnVal=null;
						if (subSource != null) {
							returnVal = "<table style='table-layout: fixed;width: 100%;'><tr>"+
									"<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>"+
									"<u>LC And BC Report Detailed</u><br><br>"+
									//"</td>"+
									//"<td style='word-wrap: break-word;'>"+
									"From Date : "+ moment($("#lcbcrfromDate").val()).format('DD-MM-YYYY') + " -  "+
									"To Date : " +  moment($("#lcbcrtoDate").val()).format('DD-MM-YYYY') + "<br><br>"+subSource+" Agent Name : "+$("#lcbcragentName").val()+
									"</td>"+
									"</tr></table><br>";
						} else {
							returnVal = "<table style='table-layout: fixed;width: 100%;'><tr>"+
									"<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>"+
									"<u>LC And BC Report Detailed</u><br><br>"+
									//"</td>"+
									//"<td style='word-wrap: break-word;'>"+
									"From Date : "+ moment($("#lcbcrfromDate").val()).format('DD-MM-YYYY') + " -  "+
									"To Date : " +  moment($("#lcbcrtoDate").val()).format('DD-MM-YYYY') + "<br><br>Source : "+ $("#lcbcrsource").val()+" Agent Name : "+$("#lcbcragentName").val()+
									"</td>"+
									"</tr></table><br>";
						}
						return returnVal;
						
					},
					title : function() {
						return "<table style='table-layout: fixed;width: 100%;'><tr>"+
						"<td style='word-wrap: break-word;width: 50%;'>"+
						"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>"+
						"</td>"+
						"<td style='word-wrap: break-word;width: 50%;text-align: right;'>"+
						      companyAddressDetls +
						"</td>"+
						"</tr></table>";
					}
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer : true,
					title : function() {
					
					var returnSummaryExcel=null;
						if (subSource != null) {
							returnSummaryExcel = "LC And BC Report Detailed"+
									"From Date : "+ moment($("#lcbcrfromDate").val()).format('DD-MM-YYYY') + " -  "+
									"To Date : " +  moment($("#lcbcrtoDate").val()).format('DD-MM-YYYY') + " "+subSource+" Agent Name : "+$("#lcbcragentName").val();
						} else {
							returnSummaryExcel = "LC And BC Report Detailed"+
									"From Date : "+ moment($("#lcbcrfromDate").val()).format('DD-MM-YYYY') + " -  "+
									"To Date : " +  moment($("#lcbcrtoDate").val()).format('DD-MM-YYYY') + "  Source : "+ $("#lcbcrsource").val()+" Agent Name : "+$("#lcbcragentName").val();
						}
						return returnSummaryExcel;
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],  
                          language: {
                              search: "_INPUT_",
                              searchPlaceholder: "Search..."
                          },
                          processing: true,
              			//scroll in datatable starts
              			responsive: true,
              			"scrollX": true,
              			"scrollY": 300,
              			"scrollCollapse": true,
              			"paging": true,
              			"info": true,
              			"footerCallback": function ( row, data, start, end, display ) {
              				var api = this.api(), data;
              				// converting to interger to find total
              				var intVal = function ( i ) {
              					return typeof i === 'string' ?
              					+i.replace(/[\$,]/g, '') * 1:
              						typeof i === 'number' ?
              							i : 0;
              				};  
				
						var articles = api.column( 3 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
							
						var actualWgt = api.column( 4 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
						var chargedWgt = api.column( 5 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
						var lc = api.column( 6 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
						var bc = api.column( 7 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
							
							$( api.column( 0 ).footer() ).html('Total : '+data.length);
							$( api.column( 1 ).footer() ).html();
							$( api.column( 2 ).footer() ).html();
							$( api.column( 3 ).footer() ).html(articles);
							$( api.column( 4 ).footer() ).html(actualWgt);
							$( api.column( 5 ).footer() ).html(chargedWgt);
							$( api.column( 6 ).footer() ).html(lc);
							$( api.column( 7 ).footer() ).html(bc);
							$( api.column( 8 ).footer() ).html();
						
                          }

            }
    }

 

    viewTypeMode( viewType: string ) {
        if ( viewType === 'summaryWise' ) {
            this.lcAndBcSummaryTable = true;
            this.lcAndBcDetailsTable = false;
			$("#"+this.pageId+"lcbcSummaryRptId").DataTable().destroy();
            this.lcbcSummaryReportDataList=[];
            $("#"+this.pageId+"lcbcDetailedRptId").DataTable().destroy();
           this.lcbcDetailedReportDataList=[];
            this.dtTriggerLCBCSummaryReport.next();
             this.dtTriggerLCBCDetailedReport.next();
			  this.showSource = false;
			if( this.srcSupAdmin ==true ||   this.srcBkgAdmin==true){
			this.showSource = true;
			}
        } else if ( viewType === 'detailsWise' ) {

            this.lcAndBcSummaryTable = false;
            this.lcAndBcDetailsTable = true;
			$("#"+this.pageId+"lcbcSummaryRptId").DataTable().destroy();
            this.lcbcSummaryReportDataList=[];
            $("#"+this.pageId+"lcbcDetailedRptId").DataTable().destroy();
             this.lcbcDetailedReportDataList=[];
			 this.dtTriggerLCBCSummaryReport.next();
			 this.dtTriggerLCBCDetailedReport.next();
			  this.showSource = false;
			if( this.srcSupAdmin ==true ||   this.srcBkgAdmin==true){
			this.showSource = true;
			}
        } else {
            this.lcAndBcSummaryTable = false;
            this.lcAndBcDetailsTable = false;
	       $("#"+this.pageId+"lcbcSummaryRptId").DataTable().destroy();
            this.lcbcSummaryReportDataList=[];
			 $("#"+this.pageId+"lcbcDetailedRptId").DataTable().destroy();
			 this.lcbcDetailedReportDataList=[];
			 this.dtTriggerLCBCSummaryReport.next();
			 this.dtTriggerLCBCDetailedReport.next();
			this.showSource = false;
			if( this.srcSupAdmin ==true ||   this.srcBkgAdmin==true){
			this.showSource = true;
			}
        }
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker
		ngOnDestroy(): void {
            this.dtTriggerLCBCSummaryReport.unsubscribe();
            this.dtTriggerLCBCDetailedReport.unsubscribe();
          }
		  
		  ngAfterViewInit(): void {
			this.dtTriggerLCBCSummaryReport.next();
            this.dtTriggerLCBCDetailedReport.next();
        }
        
		  
		  getSourceDetailedList() {
			this.lrDtoSource.mode = 'Booking';
	        this.lrDtoSource.companyId = this.userDataDtoReturnSession.companyId;
	         console.log( this.lrDtoSource);
	        }

	        getSourceDetails() {
	            this.getSourceDetailedList();
	            this.masterReadService.getSubBookingStationDetailsService( this.lrDtoSource ).subscribe(
	                ( response ) => {
	                    if (response) {
	                        if (response.length > 0) {
	                        	this.lrDtoSourceOptions = response;
	                        	console.log(this.lrDtoSourceOptions);
	                            this.sourceTA = [];
	                            this.lrDtoSourceAllOption.subStations = "All";
	                            this.sourceTA.push(this.lrDtoSourceAllOption);
	                            for ( let i = 0; i < this.lrDtoSourceOptions.length; i++ ) {
	                                this.sourceTA.push( this.lrDtoSourceOptions[i] );
	                            }
	                            this.setSource=[];
	                            for ( let i = 0; i < this.lrDtoSourceOptions.length; i++ ) {
	                                this.setSource.push( this.lrDtoSourceOptions[i].subStations );
	                            }
	                           console.log(this.setSource);
								$("#"+this.pageId+"source").val('All');
								this.srcLists=this.sourceTA;
								console.log(this.srcLists);
	                        }
	                    }
						this.changeDetectorRef.detectChanges();
	                  }),(error) => {
	              			this.showSpinnerForAction = false;
	              		   swal("Error","Server Problem Occurred While getting the Source Details","info");
	              		},
	              		   () => console.log('done');
	                    
	        };
	        
	      /// Get Agent Names
    	    getAgentDetails() {
    	        this.sourceAgentDto.companyId = this.userDataDtoReturnSession.companyId;
    	        this.sourceAgentDto.column2 = "Working";
    	        this.sourceAgentDto.mode = "Sub";
    	        this.sourceAgentDto.status = "Working";
    	        this.getAgentMethod();
    	    }

    	    getAgentMethod = () => {
    	    	this.showSpinnerForAction = true;
    	        this.masterReadService.getSourceListDetails( this.sourceAgentDto ).subscribe(
    	            ( response ) => {
    	                if ( response.length == 0 ) {
    	                    swal( {
    	                        title: "Warning",
    	                        text: "No records found!",
    	                        icon: "warning",
    	                        closeOnClickOutside: false,
    	                        closeOnEsc: false,
    	                    } );

    	                } else {
    	                    this.sourceDetailsOptionsGet = response;
    	                    console.log(this.sourceDetailsOptionsGet);
                            this.agentTA = [];
                            this.sourceAgentDtoAll.subStation = "All";
                            this.agentTA.push(this.sourceAgentDtoAll);
                            for (let i = 0; i < this.sourceDetailsOptionsGet.length; i++) {
                                this.agentTA.push(this.sourceDetailsOptionsGet[i]);
                            }
						$("#"+this.pageId+"agentName").val('All');
						this.showSpinnerForAction = false;
    	                }
						this.changeDetectorRef.detectChanges();
    	            } ), (error) => {
  	                  this.showSpinnerForAction = false;
  	                 swal("Error", "Server Problem Occurred While getting the Agent details", "info");
  	             }, () => console.log('done');
    	    };
			
	searchMethod(){
		this.selectedFromDate=$("#"+this.pageId+"fromDate").val();
		this.selectedToDate=$("#"+this.pageId+"toDate").val();
		this.selectedAgentName=$("#"+this.pageId+"destination").val();
		this.selectedSource=$("#"+this.pageId+"source").val();
		this.selectedViewType=$("#"+this.pageId+"viewType").val();
		if(this.selectedFromDate == null || this. selectedFromDate =='' || this.selectedToDate == null || this.selectedToDate ==''){	
		 swal( {
			                title: "Mandatory Field",
			                text: "Please select Date",
			                icon: "warning",
			                closeOnClickOutside: false,
			                closeOnEsc: false,
			            } );
						return false;
		}else{
			if(this.selectedViewType =='summaryWise'){
			this.searchBySummaryMethod();
			}	else if(this.selectedViewType =='detailsWise'){
			this.searchByDetailedMethod();
			}
		
			}
		}
		
		searchBySummaryMethodDetails(){
		this.selectedFromDate=$("#"+this.pageId+"fromDate").val();
		this.selectedToDate=$("#"+this.pageId+"toDate").val();
		this.selectedAgentName=$("#"+this.pageId+"agentName").val();
		this.selectedSource=$("#"+this.pageId+"source").val();
		this.selectedViewType=$("#"+this.pageId+"viewType").val();
		
		this.searchBySummaryLrdto= new LRDto();
		this.searchBySummaryLrdto.fromDate=this.selectedFromDate;
		this.searchBySummaryLrdto.toDate=this.selectedToDate;
		this.searchBySummaryLrdto.companyId=this.userDataDtoReturnSession.companyId;
		this.searchBySummaryLrdto.agentId=this.selectedAgentName;
		if(this.selectedAgentName !=null && this.selectedAgentName !='' && this.selectedAgentName !='All'){
		this.searchBySummaryLrdto.mode='NotAll';
		}else{
		this.searchBySummaryLrdto.mode='All';
		}
		/*if (this.superAdminView == false) {
		if (this.userDataDtoReturnSession.role == 'Booking Administrator') {	
			this.searchByDetailedLrdto.mainstation=this.userDataDtoReturnSession.mainStation;
		} else {
			console.log(this.userDataDtoReturnSession.mainAdminStation);
		this.searchByDetailedLrdto.mainstation=this.userDataDtoReturnSession.mainAdminStation;
		}
	}*/
		this.searchByDetailedLrdto.mainstation=this.userDataDtoReturnSession.mainStation;
	  if(this.showSource==true){
		if(this.selectedSource != null && this.selectedSource !='' && this.selectedSource !='All' ){
			this.setSource=[];
			this.setSource.push( this.selectedSource);
		  this.searchBySummaryLrdto.list= this.setSource;
		}	else{
		if(this.srcBkgAdmin==true){
		this.searchBySummaryLrdto.list=this.srcLists;//session
		}else 	if(this.srcSupAdmin==true){
			this.setSource=[];
            for ( let i = 0; i < this.lrDtoSourceOptions.length; i++ ) {
                this.setSource.push( this.lrDtoSourceOptions[i].subStations );
            }
		this.searchBySummaryLrdto.list= this.setSource;// superadmin source
		}
		}		
	  }else{
		  this.setSource=[];
		  this.setSource.push(this.userDataDtoReturnSession.mainStation);
	  this.searchBySummaryLrdto.list=  this.setSource;//mainStation
	   }
		console.log( this.searchBySummaryLrdto);
		}
		
		searchBySummaryMethod = () => {
		  this.searchBySummaryMethodDetails();
    	         this.showSpinnerForAction = true;
    	         this.lrReportService.getLrDetailsLCandBCSummary(this.searchBySummaryLrdto).subscribe(
    	             (response) => {
    	            	 this.showSpinnerForAction=false;
    	            	$("#"+this.pageId+"lcbcSummaryRptId").DataTable().destroy();
						this.lcbcSummaryReportDataList=[];

    	                 if (response.length == 0) {
    	                     swal({
    	                         title: "Warning",
    	                         text: "No  Details found !",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else {
    	               	  this.lcbcSummaryReportDataList=response;
    	               	  console.log(this.lcbcSummaryReportDataList);
    	                }
    	                 this.dtTriggerLCBCSummaryReport.next();
						 this.changeDetectorRef.detectChanges();
    	             }), (error) => {
    	                  this.showSpinnerForAction = false;
    	                 swal("Error", "Server Problem Occurred While getting the Summary details", "info");
    	             }, () => console.log('done');
    	     };
			 
 searchByDetailedMethodDetails(){
		this.selectedFromDate=$("#"+this.pageId+"fromDate").val();
		this.selectedToDate=$("#"+this.pageId+"toDate").val();
		this.selectedAgentName=$("#"+this.pageId+"agentName").val();
		this.selectedSource=$("#"+this.pageId+"source").val();
		this.selectedViewType=$("#"+this.pageId+"viewType").val();
		
		this.searchByDetailedLrdto= new LRDto();
		this.searchByDetailedLrdto= new LRDto();
		this.searchByDetailedLrdto.fromDate=this.selectedFromDate;
		this.searchByDetailedLrdto.toDate=this.selectedToDate;
		this.searchByDetailedLrdto.companyId=this.userDataDtoReturnSession.companyId;
		this.searchByDetailedLrdto.agentId=this.selectedAgentName;
		if(this.selectedAgentName !=null && this.selectedAgentName !='' && this.selectedAgentName !='All'){
		this.searchByDetailedLrdto.mode='NotAll';
		}else{
		this.searchByDetailedLrdto.mode='All';
		}
		/*if (this.superAdminView == false) {
			if (this.userDataDtoReturnSession.role == 'Booking Administrator') {	
				this.searchByDetailedLrdto.mainstation=this.userDataDtoReturnSession.mainStation;
			} else {
				console.log(this.userDataDtoReturnSession.mainAdminStation);
			this.searchByDetailedLrdto.mainstation=this.userDataDtoReturnSession.mainAdminStation;
			}
		}*/
		this.searchByDetailedLrdto.mainstation=this.userDataDtoReturnSession.mainStation;
	  if(this.showSource==true){
		if(this.selectedSource != null && this.selectedSource !='' && this.selectedSource !='All' ){
			this.setSource=[];
			this.setSource.push( this.selectedSource);
		  this.searchByDetailedLrdto.list= this.setSource;
		}	else{
		if(this.srcBkgAdmin==true){
		this.searchByDetailedLrdto.list=this.srcLists;//session
		}else 	if(this.srcSupAdmin==true){
			this.setSource=[];
            for ( let i = 0; i < this.lrDtoSourceOptions.length; i++ ) {
                this.setSource.push( this.lrDtoSourceOptions[i].subStations );
            }
		this.searchByDetailedLrdto.list= this.setSource;// superadmin source
		}
		}		
	  }else{
		  this.setSource=[];
		  this.setSource.push(this.userDataDtoReturnSession.mainStation);
	  this.searchByDetailedLrdto.list=  this.setSource;//mainStation
	   }
		
		console.log(this.searchByDetailedLrdto);
		}
		
		searchByDetailedMethod = () => {
		  this.searchByDetailedMethodDetails();
    	         this.showSpinnerForAction = true;
    	         this.lrReportService.getLrDetailsLCandBCDetails(this.searchByDetailedLrdto).subscribe(
    	             (response) => {
    	            	 this.showSpinnerForAction=false;
    	            	 $("#"+this.pageId+"lcbcDetailedRptId").DataTable().destroy();
						this.lcbcDetailedReportDataList=[];
                
    	                 if (response.length == 0) {
    	                     swal({
    	                         title: "Warning",
    	                         text: "No  Details found !",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else {
    	               	  this.lcbcDetailedReportDataList=response;
    	               	  console.log(this.lcbcDetailedReportDataList);
    	                }
    	                 this.dtTriggerLCBCDetailedReport.next();
						 this.changeDetectorRef.detectChanges();
    	             }), (error) => {
    	                  this.showSpinnerForAction = false;
    	                 swal("Error", "Server Problem Occurred While getting the details", "info");
    	             }, () => console.log('done');
    	     };
    	     clearMethod(){
    			 this.selectedFromDate='';
    			this.selectedToDate='';
    			this.selectedAgentName='';
    			this.selectedSource='';
    			this.selectedViewType='';
    			this.searchBySummaryLrdto = new LRDto();
    			this.searchByDetailedLrdto = new LRDto();
    			this.showSpinnerForAction=false;
    			
    			  $("#"+this.pageId+"agentName").val('All');
    			  $("#"+this.pageId+"fromDate").val('');
    	 		  $("#"+this.pageId+"toDate").val('');
    	 		  this.fromDateModel=null;
				  this.toDateModel=null;
        	    $("#"+this.pageId+"lcbcDetailedRptId").DataTable().destroy();
    			this.lcbcDetailedReportDataList=[];
    			 this.dtTriggerLCBCSummaryReport.next();
    			$("#"+this.pageId+"lcbcSummaryRptId").DataTable().destroy();
    			this.lcbcSummaryReportDataList=[];
    			 this.dtTriggerLCBCDetailedReport.next();
    			$("#"+this.pageId+"viewType").val('summaryWise');
    			this.showSource = false;
    			if( this.srcSupAdmin ==true ||   this.srcBkgAdmin==true){
    			this.showSource = true;
    			  $("#"+this.pageId+"source").val('All');
    			}
    			this.lcAndBcSummaryTable = true;
                this.lcAndBcDetailsTable = false;
    			 }
    	     
    	     customPrintLcAndBcSummary() {
    	 		if (this.lcbcSummaryReportDataList.length == 0) {
    	 			swal({

    	 				title: "No records found to print",
    	 				icon: "warning",
    	 				closeOnClickOutside: false,
    	 				closeOnEsc: false,
    	 			});
    	 		} else {
    	 			localStorage.clear();
    	 			this.cashMemoDtoForCustomPrintList = [];
    	 			this.cashMemoDtoForCustomPrintListColumnNames = ["Agent Name", "Total Lrs", "Articles", "Actual Weight", "Charged Weight", "LC", "BC"];
    	 			this.cashMemoDtoForCustomPrintListColumnWidths = [20, 15, 13, 13, 13, 13, 13];
    	 			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
    	 				this.cashMemoDtoForCustomPrint = new CashMemoDto();
    	 				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
    	 				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
    	 				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
    	 			}

    	 			this.cashMemoDtoForCustomPrintDataList = [];

    	 			this.cashMemoDtoForCustomPrintDataSummaryList = [];
    	 			this.totalLrsSummary = this.artSummary = this.actWghtSummary = this.chagWghtSummary = this.lcSummary = this.bcSummary = 0;
    	 			for (let i = 0; i < this.lcbcSummaryReportDataList.length; i++) {

    	 				this.cashMemoDtoForCustomPrintListColumnValues = [this.lcbcSummaryReportDataList[i].agentId, this.lcbcSummaryReportDataList[i].totalLrs, this.lcbcSummaryReportDataList[i].totalArticles,
    	 				this.lcbcSummaryReportDataList[i].actualWeight, this.lcbcSummaryReportDataList[i].chargedWeight, this.lcbcSummaryReportDataList[i].lcChg, this.lcbcSummaryReportDataList[i].bcChg];




    	 				this.totalLrsSummary = this.totalLrsSummary + this.lcbcSummaryReportDataList[i].totalLrs;
    	 				this.artSummary = this.artSummary + this.lcbcSummaryReportDataList[i].totalArticles;
    	 				this.actWghtSummary = this.actWghtSummary + this.lcbcSummaryReportDataList[i].actualWeight;
    	 				this.chagWghtSummary = this.chagWghtSummary + this.lcbcSummaryReportDataList[i].chargedWeight;
    	 				this.lcSummary = this.lcSummary + this.lcbcSummaryReportDataList[i].lcChg;
    	 				this.bcSummary = this.bcSummary + this.lcbcSummaryReportDataList[i].bcChg;


    	 				this.cashMemoDtoForCustomPrintData = new CashMemoDto();


    	 				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
    	 				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
    	 				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lcbcSummaryReportDataList.length, this.totalLrsSummary, this.artSummary, this.actWghtSummary, this.chagWghtSummary, this.lcSummary, this.bcSummary];
    	 			}


    	 			//heading logics For Date
    	 			this.selectedFromDate = $("#"+this.pageId+"fromDate").val();
    	 			this.selectedToDate = $("#"+this.pageId+"toDate").val();

    	 			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
    	 			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];


    	 			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];

    	 			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
    	 				this.cashMemoDtoForCustomPrint = new CashMemoDto();
    	 				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
    	 				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
    	 				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

    	 			}


    	 			this.selectedAgentName = $("#"+this.pageId+"destination").val();
    	 			this.selectedSource = $("#"+this.pageId+"source").val();
    	 			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
    	 			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Source", "Agent Name"];
    	 			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSource, this.selectedAgentName];
    	 			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
    	 				this.cashMemoDtoForCustomPrint = new CashMemoDto();
    	 				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
    	 				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
    	 				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);

    	 			}



    	 			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
    	 			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
    	 			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
    	 			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
    	 			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
    	 			localStorage.setItem('printTitle', "Lc And Bc Report-Summary");
    	 			this.viewCustomPrintV1 = true;
    	 			window.addEventListener('afterprint', (onclick) => {
    	 				if (this.viewCustomPrintV1) {
    	 					this.viewCustomPrintV1 = false;
    	 					localStorage.clear();
    	 				}
    	 			});
    	 		}
    	 	}

    	 	customPrintLcAndBcDetailDetail() {
    	 		if (this.lcbcDetailedReportDataList.length == 0) {
    	 			swal({

    	 				title: "No records found to print",
    	 				icon: "warning",
    	 				closeOnClickOutside: false,
    	 				closeOnEsc: false,
    	 			});
    	 		} else {
    	 			localStorage.clear();
    	 			this.cashMemoDtoForCustomPrintList = [];
    	 			this.cashMemoDtoForCustomPrintListColumnNames = ["Lr Number", "Consignee Name", "Consignor Name", "Articles","Actual Wt", "Charged Wt", "LC", "BC","Agent Name"];
    	 			this.cashMemoDtoForCustomPrintListColumnWidths = [12, 15, 15, 10, 10, 10,8,8,12];
    	 			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
    	 				this.cashMemoDtoForCustomPrint = new CashMemoDto();
    	 				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
    	 				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
    	 				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
    	 			}

    	 			this.cashMemoDtoForCustomPrintDataList = [];

    	 			this.cashMemoDtoForCustomPrintDataSummaryList = [];
    	 			this.totalLrsSummary = this.artSummary = this.actWghtSummary = this.chagWghtSummary = this.lcSummary = this.bcSummary = 0;
    	 			for (let i = 0; i < this.lcbcDetailedReportDataList.length; i++) {

    	 				this.cashMemoDtoForCustomPrintListColumnValues = [this.lcbcDetailedReportDataList[i].lrNumber, this.lcbcDetailedReportDataList[i].consigneeName, this.lcbcDetailedReportDataList[i].consignorName,
    	 				this.lcbcDetailedReportDataList[i].totalArticles, this.lcbcDetailedReportDataList[i].actualWeight, this.lcbcDetailedReportDataList[i].chargedWeight, this.lcbcDetailedReportDataList[i].lcChg,this.lcbcDetailedReportDataList[i].bcChg,this.lcbcDetailedReportDataList[i].agentId];



    	 				this.artSummary = this.artSummary + this.lcbcDetailedReportDataList[i].totalArticles;
    	 				this.actWghtSummary = this.actWghtSummary + this.lcbcDetailedReportDataList[i].actualWeight;
    	 				this.chagWghtSummary = this.chagWghtSummary + this.lcbcDetailedReportDataList[i].chargedWeight;
    	 				this.lcSummary = this.lcSummary + this.lcbcDetailedReportDataList[i].lcChg;
    	 				this.bcSummary = this.bcSummary + this.lcbcDetailedReportDataList[i].bcChg;

    	 				
    	 				
    	 				

    	 				this.cashMemoDtoForCustomPrintData = new CashMemoDto();


    	 				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
    	 				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
    	 				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lcbcDetailedReportDataList.length, "","", this.artSummary, this.actWghtSummary, this.chagWghtSummary, this.lcSummary, this.bcSummary,""];
    	 			}


    	 			//heading logics For Date
    	 			this.selectedFromDate = $("#"+this.pageId+"fromDate").val();
    	 			this.selectedToDate = $("#"+this.pageId+"toDate").val();

    	 			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
    	 			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];


    	 			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];

    	 			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
    	 				this.cashMemoDtoForCustomPrint = new CashMemoDto();
    	 				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
    	 				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
    	 				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

    	 			}


    	 			this.selectedAgentName = $("#"+this.pageId+"destination").val();
    	 			this.selectedSource = $("#"+this.pageId+"source").val();
    	 			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
    	 			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Source", "Agent Name"];
    	 			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSource, this.selectedAgentName];
    	 			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
    	 				this.cashMemoDtoForCustomPrint = new CashMemoDto();
    	 				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
    	 				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
    	 				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);

    	 			}

    	 			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
    	 			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
    	 			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
    	 			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
    	 			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
    	 			localStorage.setItem('printTitle', "Lc And Bc Report-Destailed");
    	 			this.viewCustomPrintV1 = true;
    	 			window.addEventListener('afterprint', (onclick) => {
    	 				if (this.viewCustomPrintV1) {
    	 					this.viewCustomPrintV1 = false;
    	 					localStorage.clear();
    	 				}
    	 			});
    	 		}
    	 	}
}
