import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends

//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends

import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";



@Component( {
    selector: 'app-regular-consignee-report',
    templateUrl: './regular-consignee-report.component.html',
    styleUrls: ['./regular-consignee-report.component.css']
} )
export class RegularConsigneeReportComponent implements OnInit {



    getDataFrmServiceFrRegularConsigneeReportSummaryTable: any;
    getDataFrmServiceFrRegularConsigneeReportDetailsTable: any;

    regularConsigneeReportSummaryDataList: any;
    regularConsigneeReportDetailsDataList: any;

    onDestroyUnsubscribtionRegularConsigneeReportSummary: Subscription;
    onDestroyUnsubscribtionRegularConsigneeReportDetails: Subscription;


    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;
    regularConsigneeReportDetailsTable = false
    viewParty = true;
    viewCommodity = false;


    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends
    //for the select option with filter starts

    controlSource = new FormControl();
    controlDestination = new FormControl();
    controlCommodityList = new FormControl();

    sourceOptions = [
        { id: 1, label: 'All' },
        { id: 2, label: 'Balotra' },
        { id: 3, label: 'Bawana' }
    ];
    destinationOptions = [
        { id: 1, label: 'All' },
        { id: 2, label: 'Bangalore' },
        { id: 3, label: 'Chennai' }
    ];
    commodityListOptions = [
        { id: 1, label: 'All' },
        { id: 2, label: 'A.footwear(till 72)' },
        { id: 3, label: 'B.footwear(till 78)' }
    ];
    //for the select option with filter ends


    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerRegularConsigneeReportSummary: Subject<any> = new Subject();
    dtTriggerRegularConsigneeReportDetails: Subject<any> = new Subject();

    dataTable: any;


    dtOptionsRegularConsigneeReportSummary: any;
    dtOptionsRegularConsigneeReportDetails: any;
    pageId="rgcrc";

    constructor( private regularConsigneeRpt: ReportService, public changeDetectorRef : ChangeDetectorRef ) {


    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }







    ngOnInit(): void {


        this.dtOptionsRegularConsigneeReportSummary = {
            //};

            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"></i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"></i>',
                    titleAttr: 'Print',

                }
            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends  
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
            columnDefs: [

                { width: 400, targets: 0 },
                { width: 20, targets: 1 }
            ],
        }

        this.dtOptionsRegularConsigneeReportDetails = {
            //};

            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }
            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends  
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        }
        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerLorryHireBalance.next();
        //                } );
        this.getDataFrmServiceFrRegularConsigneeReportSummaryTable = this.regularConsigneeRpt.getSummaryData()
        this.onDestroyUnsubscribtionRegularConsigneeReportSummary = this.getDataFrmServiceFrRegularConsigneeReportSummaryTable.subscribe( data => {
            this.regularConsigneeReportSummaryDataList = data['data'];
            this.dtTriggerRegularConsigneeReportSummary.next();
        } );

        this.getDataFrmServiceFrRegularConsigneeReportDetailsTable = this.regularConsigneeRpt.getSummaryData()
        this.onDestroyUnsubscribtionRegularConsigneeReportDetails = this.getDataFrmServiceFrRegularConsigneeReportDetailsTable.subscribe( data => {
            this.regularConsigneeReportDetailsDataList = data['data'];
            this.dtTriggerRegularConsigneeReportDetails.next();
        } );

        //the below code is for the getting data through json ends



    }




    ngOnDestroy(): void {
        this.dtTriggerRegularConsigneeReportSummary.unsubscribe();
        this.dtTriggerRegularConsigneeReportDetails.unsubscribe();
        this.onDestroyUnsubscribtionRegularConsigneeReportSummary.unsubscribe();
        this.onDestroyUnsubscribtionRegularConsigneeReportDetails.unsubscribe();

    }



    searchByMode( searchBy: string ) {
        if ( searchBy === 'partyWise' ) {
            this.viewParty = true;
            this.viewCommodity = false;


        } else if ( searchBy === 'commodityWise' ) {
            this.viewParty = false;
            this.viewCommodity = true;


        } else {
            this.viewParty = false;
            this.viewCommodity = false;

        }
    }



    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

    //for the select option with filter ends
}

