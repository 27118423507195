<!-- Row -->
<div class="row">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Booking Memo Report</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}type">
                                                    <label>Type</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-question"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12">
														<option selected>Default</option>
														<option value="1">After Rate Change</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}source">
                                                    <label>Source</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-box"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12">
														<option selected>Select.....</option>
														<option value="delhi">Delhi</option>
														<option value="mumbai">Mumbai</option>
														<option value="agra">Agra</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}destination">
                                                    <label>Destination</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class=" fas fa-road"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12">
														<option selected>Select.....</option>
														<option value="bangalore">Bangalore</option>
														<option value="chennai">Chennai</option>
														<option value="kerela">Kerela</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}consigneeGstNo">
                                                    <label>Consignee GST No</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class=" fas fa-hashtag"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}consigneeName">
                                                    <label>Consignee Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12">
														<option selected>Select....</option>
														<option value="1">A B C Company</option>
														<option value="2">M K TEXTILE</option>
														<option value="3">S D FOOTWEAR</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}consignorTinNo">
                                                    <label>Consignor GST No</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class=" fas fa-hashtag"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}consignorIndex">
                                                    <label>Consignor Index</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12">
														<option selected>Select</option>
														<option value="1">A</option>
														<option value="2">B</option>
														<option value="3">C</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group" id="{{pageId}}consignorName">
                                                    <label>Consignor Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12">
														<option selected>Select....</option>
														<option value="1">A B C Company</option>
														<option value="2">M K TEXTILE</option>
														<option value="3">S D FOOTWEAR</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style="width: 80%; border-top: none; margin: 3px;">
                                    <div class="col-md-12" style="text-align: center;">
                                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn">Search</button>
                                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
                                    </div>
                                </div>

                                <div class="col-md-9 vl">
                                    <div class="box-body">
                                        <!-- 									<h6 class="card-title border_bottom">Paid Report 1</h6> -->
                                        <table datatable class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsBookingMemoRpt" [dtTrigger]="dtTriggerBookingMemoRpt">

                                            <thead>
                                                <tr>
                                                    <th>LR No</th>
                                                    <th>Memo No</th>
                                                    <th>Created Date</th>
                                                    <th>Grand Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let bookingMemoRptData of bookingMemoRptDataList ">
                                                    <td>{{ bookingMemoRptData.lrNo }}</td>
                                                    <td>{{ bookingMemoRptData.memoNo }}</td>
                                                    <td>{{ bookingMemoRptData.createdDate }}</td>
                                                    <td>{{ bookingMemoRptData.grandTotal }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>