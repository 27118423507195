import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable, merge } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { LrService } from 'src/app/dataService/lr-service';
import { LRDto } from 'src/app/dto/LR-dto';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';

@Component({
    selector: 'app-amount-receivable-from-agent',
    templateUrl: './amount-receivable-from-agent.component.html',
    styleUrls: ['./amount-receivable-from-agent.component.css']
})
export class AmountReceivableFromAgentComponent implements OnInit {

    onDestroyUnsubscribtionCheque: Subscription;
    onDestroyUnsubscribtionPdc: Subscription;
    cashView = true;
    chequeView = false;
    pdcView = false;
    model: NgbDateStruct;
    receivingDate: NgbDateStruct;
    chequeDate: NgbDateStruct;
    closeResult: string;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerAmountReceivableForAgentStmtCashChequePdc: Subject<any> = new Subject();
    dtOptionsAmountReceivableForAgentStmtCashChequePdc: any;
    amountReceivableForAgentStmtCashChequePdcDataList: any;
    dtOptionsPdc: any;
    control = new FormControl();

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;
    showSpinnerForAction = false;

    lrDtoDestination: LRDto = new LRDto();
    destinationOptions: LRDto[];
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    viewCashChequePdcAmtField: boolean = true;
    viewChequePdcNoField: boolean = false;
    viewChequeDueDateField: boolean = false;
    viewChequePdcBankNameField: boolean = false;

    modelChequeDueDates: any;
    modelReceviDates: any;

    private newAttributeTosetBank: any = {};
    editBankTableDetailsId: any;

    validateBalAmtToBePaid: any;
    validateBalAmtCash: any;
    validateBalAmtCheque: any;
    validateBalAmtPdc: any;

    validateBalAmtForSave: any;
    validateSummCashAmtForSave: any;
    validateSummChequeAmtForSave: any;
    validateSummPdcAmtForSave: any;
    validatePayingDateForSave: any;
    validateDestinationForSave: any;
    validateInitBalAmtForSave: any;
    resultDuplicate: any;

    fortNightStmtDtoForGetUserValue: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoForReturnGetUserValue: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoForMultipleValue: FortNightStmtDto = new FortNightStmtDto();

    fortNightStmtDtoForDest: FortNightStmtDto = new FortNightStmtDto();
    stmtDtoAmt: FortNightStmtDto = new FortNightStmtDto();
    validateSelectedDest: any;
    listOfDest: Array<String>;
    list: Array<String>;
    pageId = "arfac";
    amountReceivableData: any;
    showSaveBtn: boolean = true;
    showUpdateBtn: boolean = false;
    amtRecIdEdit: any;
    existingRecAmtEdit: any;
    showAmtFieldOnEdit: boolean = true;

    constructor(private router: Router, private mastereadService: MasterReadService,
        private lrService: LrService, public changeDetectorRef: ChangeDetectorRef, public destStmtReport: DestStmtReport) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.getDestinationDetails();
            this.amtRecIdEdit = 0;
            this.existingRecAmtEdit = 0;
            this.amountReceivableData = null;
            this.showSaveBtn = true;
            this.showUpdateBtn = false;
            this.showAmtFieldOnEdit = true;
            var editStmt = localStorage.getItem('BngStmtAmtRecEdit');
            if (editStmt != null && editStmt == 'Edit') {
                this.showSaveBtn = false;
                this.showUpdateBtn = true;
                this.showAmtFieldOnEdit = false;
                this.amountReceivableData = JSON.parse(localStorage.getItem('BngStmtAmtRecEditData'));
                localStorage.clear();
                this.amtRecIdEdit = this.amountReceivableData.amtRecId;
                this.existingRecAmtEdit = this.amountReceivableData.receivableAmt;
                this.getAmtRecFromAgentDetails(this.amountReceivableData);
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var groupColumn = 0;
        this.dtOptionsAmountReceivableForAgentStmtCashChequePdc = {
            dom: 'Bfrtip',
            // language: {
            //     search: "_INPUT_",
            //     searchPlaceholder: "Search..."
            // },
            buttons: [
            ],
            searching: false,
            pagingType: 'full_numbers',
            processing: true,
            "scrollX": true,
            "scrollY": 250,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalAmt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(totalAmt);
            },
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;

                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var totalBankAmt = 0;
                var count = 0;
                //var pendingQty = 0;
                api.column(groupColumn, {
                    page: 'current'
                }).data().each(function (group, i) {

                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            })).append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totalBankAmt
                                                })).append($("<td></td>",
                                                    {
                                                        "colspan": 1,
                                                        "text": ""
                                                    })).append($("<td></td>",
                                                        {
                                                            "colspan": 1,
                                                            "text": ""
                                                        })).append($("<td></td>",
                                                            {
                                                                "colspan": 1,
                                                                "text": ""
                                                            }))
                                        .prop('outerHTML'));
                            totalBankAmt = 0;
                            count = 0;
                            //pendingQty = 0;
                        }
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 6,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }
                    count++;
                    totalBankAmt += +val[2];
                    //pendingQty += val[6];
                    if (i == (rows.length - 1)) {

                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total : " + count

                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totalBankAmt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        })).append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                    .prop('outerHTML'));
                        totalBankAmt = 0;
                        count = 0;
                        //pendingQty = 0;
                    }
                });
            }
        }
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerAmountReceivableForAgentStmtCashChequePdc.unsubscribe();
        // this.onDestroyUnsubscribtionCheque.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerAmountReceivableForAgentStmtCashChequePdc.next();
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }


    clickListnerForDestinationList(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
        /* this.stmtDtoAmt = new FortNightStmtDto();
          this.validateDestinationForSave = $("#"+this.pageId+"destinationId").val();
          this.listOfDest = [];
          for (let i = 0; i < this.list.length; i++) {
              if (this.list[i] == $("#"+this.pageId+"destinationId").val()) {
                  this.listOfDest.push(this.list[i]);
                  break;
              }
          }
          console.log(this.listOfDest);*/
        if ($("#" + this.pageId + "destinationId").val() != null && $("#" + this.pageId + "destinationId").val() != undefined &&
            $("#" + this.pageId + "destinationId").val() != "") {
            this.setAgnetAmountBalanceForSelectedDest();
        }
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = "LrForm";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.mastereadService.getDestinationForLREntryService(this.lrDtoDestination)
            .subscribe((response) => {
                this.showSpinnerForAction = false;
                if (response.length > 0) {
                    this.destinationOptions = [];
                    this.list = [];
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                        this.list.push(this.destinationOptions[i].destination);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log("done");
    };

    /* set Based on selection of destination */

    setAgnetAmountBalanceForSelectedDest() {
        this.fortNightStmtDtoForDest = new FortNightStmtDto();
        this.validateSelectedDest = $("#" + this.pageId + "destinationId").val();
        this.fortNightStmtDtoForDest.destination = this.validateSelectedDest;
        this.fortNightStmtDtoForDest.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.lrService.getAgnetAmountBalance(this.fortNightStmtDtoForDest)
            .subscribe((response) => {
                this.showSpinnerForAction = false;
                if (response.balanceAmt != null && response.balanceAmt != undefined) {
                    $("#" + this.pageId + "totalAmountToBePaid").val(response.balanceAmt);
                    $("#" + this.pageId + "amount").val(response.balanceAmt);
                } else {
                    $("#" + this.pageId + "totalAmountToBePaid").val(0);
                    $("#" + this.pageId + "amount").val(0);
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Agent Amount Details", "info");
            },
            () => console.log("done");
    }

    /* Here is Cash Mode Selection start */
    payemntModeTypeListner(modeType: string) {
        if (modeType == "Cash") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = false;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = false;

            $("#" + this.pageId + "chequePdcNo").val('');
            $("#" + this.pageId + "chequeDueDates").val('');
            $("#" + this.pageId + "bankNameId").val('');
            $("#" + this.pageId + "cashChequePdcAmount").val('');

            this.modelChequeDueDates = null;

        } else if (modeType == "Cheque") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = true;

            $("#" + this.pageId + "chequePdcNo").val('');
            $("#" + this.pageId + "chequeDueDates").val('');
            $("#" + this.pageId + "bankNameId").val('');
            $("#" + this.pageId + "cashChequePdcAmount").val('');

            this.modelChequeDueDates = null;

        } else if (modeType == "PDC") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = true;
            this.viewChequePdcBankNameField = true;

            $("#" + this.pageId + "chequePdcNo").val('');
            $("#" + this.pageId + "chequeDueDates").val('');
            $("#" + this.pageId + "bankNameId").val('');
            $("#" + this.pageId + "cashChequePdcAmount").val('');

            this.modelChequeDueDates = null;

        }
    }
    /* Here is Cash Mode Selection end */

    /* Calculation Cash Cheque And Pdc */
    calculateCashAmtSum() {
        var cashSum = 0.0;
        for (let i = 0; i < this.amountReceivableForAgentStmtCashChequePdcDataList.length; i++) {
            if (this.amountReceivableForAgentStmtCashChequePdcDataList[i].paymentType == "Cash") {
                cashSum = cashSum + +this.amountReceivableForAgentStmtCashChequePdcDataList[i].amount;
            }
        }
        return cashSum;
    }

    calculateChequeAmtSum() {
        var chequeSum = 0.0;
        for (let i = 0; i < this.amountReceivableForAgentStmtCashChequePdcDataList.length; i++) {
            if (this.amountReceivableForAgentStmtCashChequePdcDataList[i].paymentType == "Cheque") {
                chequeSum = chequeSum + +this.amountReceivableForAgentStmtCashChequePdcDataList[i].amount;
            }
        }
        return chequeSum;
    }

    calculatePdcAmtSum() {
        var pdcSum = 0.0;
        for (let i = 0; i < this.amountReceivableForAgentStmtCashChequePdcDataList.length; i++) {
            if (this.amountReceivableForAgentStmtCashChequePdcDataList[i].paymentType == "PDC") {
                pdcSum = pdcSum + +this.amountReceivableForAgentStmtCashChequePdcDataList[i].amount;
            }
        }
        return pdcSum;
    }
    /* Calculation Cash Cheque And Pdc */

    /* ok key press listne */

    onKeyPressListnerForAmount(event) {
        if (event.charCode == 13) {
            $("#" + this.pageId + "addBankBtnId").focus();
            //this.balanceAmt();
        }
    }

    onKeyPressListnerForChequeNo(event) {
        if (event.charCode == 13) {
            if ($("#" + this.pageId + "payemntModeType").val() == "Cheque") {
                $("#" + this.pageId + "bankNameId").focus();
            } else {
                $("#" + this.pageId + "chequeDueDates").focus();
            }
            //this.balanceAmt();
        }
    }

    onKeyPressListnerForBankName(event) {
        if (event.charCode == 13) {
            $("#" + this.pageId + "cashChequePdcAmount").focus();
            //this.balanceAmt();
        }
    }
    /* ok key press listne */

    /* balance amt cal */
    balanceAmt() {
        var balanceAmt = 0.0;
        this.validateBalAmtToBePaid = 0;
        this.validateBalAmtCash = 0;
        this.validateBalAmtCheque = 0;
        this.validateBalAmtPdc = 0;

        this.validateBalAmtToBePaid = $("#" + this.pageId + "amount").val();
        this.validateBalAmtCash = $("#" + this.pageId + "summaryCashAmt").val();
        this.validateBalAmtCheque = $("#" + this.pageId + "summaryChequeAmt").val();
        this.validateBalAmtPdc = $("#" + this.pageId + "summaryPDCAmt").val();

        balanceAmt = +this.validateBalAmtToBePaid + -this.validateBalAmtCash + -this.validateBalAmtCheque + -this.validateBalAmtPdc;

        $("#" + this.pageId + "balanceToBePaid").val(balanceAmt);
    }

    /* balance amt cal */

    addBankDetailsInTable() {
        if (($("#" + this.pageId + "payemntModeType").val() == "Cash") &&
            ($("#" + this.pageId + "cashChequePdcAmount").val() == null || $("#" + this.pageId + "cashChequePdcAmount").val() == undefined ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == "")) {
            swal("Mandatory Field", "Please enter amount", "warning");
            return false;
        } else if (($("#" + this.pageId + "payemntModeType").val() == "Cheque") &&
            ($("#" + this.pageId + "chequePdcNo").val() == null || $("#" + this.pageId + "chequePdcNo").val() == undefined ||
                $("#" + this.pageId + "chequePdcNo").val() == "" || $("#" + this.pageId + "bankNameId").val() == null ||
                $("#" + this.pageId + "bankNameId").val() == undefined || $("#" + this.pageId + "bankNameId").val() == "" ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == null || $("#" + this.pageId + "cashChequePdcAmount").val() == undefined ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == "")) {
            swal("Mandatory Field", "Please enterthe  mandatory field", "warning");
            return false;
        } else if (($("#" + this.pageId + "payemntModeType").val() == "PDC") &&
            ($("#" + this.pageId + "chequePdcNo").val() == null || $("#" + this.pageId + "chequePdcNo").val() == undefined ||
                $("#" + this.pageId + "chequePdcNo").val() == "" || $("#" + this.pageId + "bankNameId").val() == null ||
                $("#" + this.pageId + "bankNameId").val() == undefined || $("#" + this.pageId + "bankNameId").val() == "" ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == null || $("#" + this.pageId + "cashChequePdcAmount").val() == undefined ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == "" || $("#" + this.pageId + "chequeDueDates").val() == null ||
                $("#" + this.pageId + "chequeDueDates").val() == undefined || $("#" + this.pageId + "chequeDueDates").val() == "")) {
            swal("Mandatory Field", "Please enterthe  mandatory field", "warning");
            return false;
        } else {
            this.addIntoTableOfBankDetails();
        }
    }

    addIntoTableOfBankDetails() {
        this.newAttributeTosetBank = {};
        this.newAttributeTosetBank.paymentType = $("#" + this.pageId + "payemntModeType").val();
        this.newAttributeTosetBank.chequeNumber = $("#" + this.pageId + "chequePdcNo").val();
        this.newAttributeTosetBank.amount = $("#" + this.pageId + "cashChequePdcAmount").val();
        this.newAttributeTosetBank.bankName = $("#" + this.pageId + "bankNameId").val();
        this.newAttributeTosetBank.pdcDueDate = $("#" + this.pageId + "chequeDueDates").val();
        //this.bkgAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);
        if (this.editBankTableDetailsId != null &&
            this.editBankTableDetailsId != undefined) {
            this.amountReceivableForAgentStmtCashChequePdcDataList.splice(this.editBankTableDetailsId, 1);
        }

        if (this.amountReceivableForAgentStmtCashChequePdcDataList == null ||
            this.amountReceivableForAgentStmtCashChequePdcDataList == undefined ||
            this.amountReceivableForAgentStmtCashChequePdcDataList.length == 0) {
            this.amountReceivableForAgentStmtCashChequePdcDataList = [];
        }

        if (this.amountReceivableForAgentStmtCashChequePdcDataList.length == 0) {
            this.amountReceivableForAgentStmtCashChequePdcDataList = [];
            $("#" + this.pageId + "amountReceivableForAgentStmtCashChequePdcTableId").DataTable().destroy();
            this.amountReceivableForAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);
            this.dtTriggerAmountReceivableForAgentStmtCashChequePdc.next();
            this.clearFieldBank();
            $("#" + this.pageId + "summaryCashAmt").val(this.calculateCashAmtSum());
            $("#" + this.pageId + "summaryChequeAmt").val(this.calculateChequeAmtSum());
            $("#" + this.pageId + "summaryPDCAmt").val(this.calculatePdcAmtSum());
            this.balanceAmt();
        } else {
            if ($("#" + this.pageId + "payemntModeType").val() == "Cash") {
                const result = this.amountReceivableForAgentStmtCashChequePdcDataList.filter(
                    amountReceivableForAgentStmtCashChequePdcData =>
                        amountReceivableForAgentStmtCashChequePdcData.paymentType === this.newAttributeTosetBank.paymentType);
                if (result.length > 0) {
                    swal("Duplicate Entry", "Cash Amount Already Available ,Please Edit the Cash Amount and then Add", "warning");
                } else {
                    $("#" + this.pageId + "amountReceivableForAgentStmtCashChequePdcTableId").DataTable().destroy();
                    this.amountReceivableForAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);
                    this.dtTriggerAmountReceivableForAgentStmtCashChequePdc.next();
                    this.clearFieldBank();
                    $("#" + this.pageId + "summaryCashAmt").val(this.calculateCashAmtSum());
                    $("#" + this.pageId + "summaryChequeAmt").val(this.calculateChequeAmtSum());
                    $("#" + this.pageId + "summaryPDCAmt").val(this.calculatePdcAmtSum());
                    this.balanceAmt();
                }
            } else {
                const result = this.amountReceivableForAgentStmtCashChequePdcDataList.filter(
                    amountReceivableForAgentStmtCashChequePdcData =>
                        amountReceivableForAgentStmtCashChequePdcData.paymentType === this.newAttributeTosetBank.paymentType &&
                        amountReceivableForAgentStmtCashChequePdcData.chequeNumber === this.newAttributeTosetBank.chequeNumber);
                if (result.length > 0) {
                    swal("Duplicate Entry", "Duplicate Value Entered..Please recheck", "warning");
                } else {
                    $("#" + this.pageId + "amountReceivableForAgentStmtCashChequePdcTableId").DataTable().destroy();
                    this.amountReceivableForAgentStmtCashChequePdcDataList.push(this.newAttributeTosetBank);
                    this.dtTriggerAmountReceivableForAgentStmtCashChequePdc.next();
                    this.clearFieldBank();
                    $("#" + this.pageId + "summaryCashAmt").val(this.calculateCashAmtSum());
                    $("#" + this.pageId + "summaryChequeAmt").val(this.calculateChequeAmtSum());
                    $("#" + this.pageId + "summaryPDCAmt").val(this.calculatePdcAmtSum());
                    this.balanceAmt();
                }
            }
        }
    }

    rowSelectedBankDetailsDataOnEdit(amountReceivableForAgentStmtCashChequePdcData, index) {
        this.editBankTableDetailsId = index;
        if (amountReceivableForAgentStmtCashChequePdcData.paymentType == "Cash") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = false;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = false;
            setTimeout(() => {
                $("#" + this.pageId + "payemntModeType").val("Cash");
                $("#" + this.pageId + "cashChequePdcAmount").val(amountReceivableForAgentStmtCashChequePdcData.amount);
            }, 1000);
        } else if (amountReceivableForAgentStmtCashChequePdcData.paymentType == "Cheque") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = true;
            setTimeout(() => {
                $("#" + this.pageId + "payemntModeType").val("Cheque");
                $("#" + this.pageId + "chequePdcNo").val(amountReceivableForAgentStmtCashChequePdcData.chequeNumber);
                $("#" + this.pageId + "bankNameId").val(amountReceivableForAgentStmtCashChequePdcData.bankName);
                $("#" + this.pageId + "cashChequePdcAmount").val(amountReceivableForAgentStmtCashChequePdcData.amount);
            }, 1000);
        } else if (amountReceivableForAgentStmtCashChequePdcData.paymentType == "PDC") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = true;
            this.viewChequePdcBankNameField = true;
            setTimeout(() => {
                $("#" + this.pageId + "payemntModeType").val("PDC");
                $("#" + this.pageId + "chequePdcNo").val(amountReceivableForAgentStmtCashChequePdcData.chequeNumber);
                $("#" + this.pageId + "bankNameId").val(amountReceivableForAgentStmtCashChequePdcData.bankName);
                $("#" + this.pageId + "chequeDueDates").val(amountReceivableForAgentStmtCashChequePdcData.pdcDueDate);
                $("#" + this.pageId + "cashChequePdcAmount").val(amountReceivableForAgentStmtCashChequePdcData.amount);
            }, 1000);
        }
    }

    deleteSelectedBankDetails(index) {
        $("#" + this.pageId + "amountReceivableForAgentStmtCashChequePdcTableId").DataTable().destroy();
        this.amountReceivableForAgentStmtCashChequePdcDataList.splice(index, 1);
        this.dtTriggerAmountReceivableForAgentStmtCashChequePdc.next();
        $("#" + this.pageId + "summaryCashAmt").val(this.calculateCashAmtSum());
        $("#" + this.pageId + "summaryChequeAmt").val(this.calculateChequeAmtSum());
        $("#" + this.pageId + "summaryPDCAmt").val(this.calculatePdcAmtSum());
        this.balanceAmt();
    }

    clearFieldBank() {
        this.editBankTableDetailsId = null;
        this.newAttributeTosetBank = {};
        $("#" + this.pageId + "chequePdcNo").val('');
        $("#" + this.pageId + "chequeDueDates").val('');
        $("#" + this.pageId + "bankNameId").val('');
        $("#" + this.pageId + "cashChequePdcAmount").val('');

        this.modelChequeDueDates = null;
    }

    clearBankDetailsInTable() {
        this.clearFieldBank();
        $("#" + this.pageId + "payemntModeType").val('Cash');
        this.viewCashChequePdcAmtField = true;
        this.viewChequePdcNoField = false;
        this.viewChequeDueDateField = false;
        this.viewChequePdcBankNameField = false;
    }

    /* save start here */
    validateSavePaymentBtn() {
        if ($("#" + this.pageId + "receivingDate").val() == null || $("#" + this.pageId + "receivingDate").val() == undefined ||
            $("#" + this.pageId + "receivingDate").val() == "") {
            swal("Mandatory Field", "Please Select the Receiving Date", "warning");
            return false;
        } else {
            swal({
                title: "Payment Confirmation",
                text: "Sure U Want To Submit the Amount Details",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.setAmountReceivableFromAgentPaymentDetails();
                }
            });
        }
    }

    setAmountReceivableFromAgentPaymentDetails() {
        this.fortNightStmtDtoForReturnGetUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoForReturnGetUserValue = this.getUserValues();
        console.log(this.fortNightStmtDtoForReturnGetUserValue);
        this.showSpinnerForAction = true;
        this.lrService.amountReceivedFromAgent(this.fortNightStmtDtoForReturnGetUserValue)
            .subscribe((response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                if (response.status == "success") {
                    swal("Alert", "Amount Received From Agent Save Successfully", "success");

                    this.stmtDtoAmt = new FortNightStmtDto();
                    this.validateDestinationForSave = $("#" + this.pageId + "destinationId").val();
                    this.listOfDest = [];
                    for (let i = 0; i < this.list.length; i++) {
                        if (this.list[i] == $("#" + this.pageId + "destinationId").val()) {
                            this.listOfDest.push(this.list[i]);
                            break;
                        }
                    }
                    console.log(this.listOfDest);
                    this.list = this.listOfDest;
                    this.stmtDtoAmt.list = this.list;
                    this.stmtDtoAmt.destination = this.validateDestinationForSave;
                    this.stmtDtoAmt.companyId = this.userDataDtoReturnSession.companyId;
                    console.log(this.stmtDtoAmt);

                    this.setAgtBalance(this.stmtDtoAmt);
                } else {
                    swal("Failed", "Failed to Save Amount Received From Agent", "warning");
                }
                this.changeDetectorRef.detectChanges();
                //this.updateDestStmtBalAmt(this.stmtDtoAmt);
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While saving Amount Received Details", "info");
            },
            () => console.log("done");
    }

    getUserValues() {
        this.fortNightStmtDtoForGetUserValue = new FortNightStmtDto();

        this.validateBalAmtForSave = 0.0;
        this.validateSummCashAmtForSave = 0.0;
        this.validateSummChequeAmtForSave = 0.0;
        this.validateSummPdcAmtForSave = 0.0;
        this.validatePayingDateForSave = 0.0;
        this.validateDestinationForSave = null;
        this.validateInitBalAmtForSave = 0.0;

        this.validateBalAmtForSave = $("#" + this.pageId + "balanceToBePaid").val();
        this.validateSummCashAmtForSave = $("#" + this.pageId + "summaryCashAmt").val();
        this.validateSummChequeAmtForSave = $("#" + this.pageId + "summaryChequeAmt").val();
        this.validateSummPdcAmtForSave = $("#" + this.pageId + "summaryPDCAmt").val();
        this.validatePayingDateForSave = $("#" + this.pageId + "receivingDate").val();
        this.validateDestinationForSave = $("#" + this.pageId + "destinationId").val();
        this.validateInitBalAmtForSave = $("#" + this.pageId + "amount").val();

        this.fortNightStmtDtoForGetUserValue = this.setMultipleValue();

        this.fortNightStmtDtoForGetUserValue.initialBalanceAmt = this.validateInitBalAmtForSave;
        this.fortNightStmtDtoForGetUserValue.receivedDate = this.validatePayingDateForSave;
        this.fortNightStmtDtoForGetUserValue.chequeAmt = this.validateSummChequeAmtForSave;
        this.fortNightStmtDtoForGetUserValue.pdcAmt = this.validateSummPdcAmtForSave;
        this.fortNightStmtDtoForGetUserValue.balanceAmt = this.validateBalAmtForSave;
        this.fortNightStmtDtoForGetUserValue.updatedBy = this.userDataDtoReturnSession.userId;
        var totAmtPaid = 0.0;
        totAmtPaid = +this.fortNightStmtDtoForGetUserValue.cashAmt +
            +this.fortNightStmtDtoForGetUserValue.chequeAmt +
            +this.fortNightStmtDtoForGetUserValue.pdcAmt;

        //this.fortNightStmtDtoForGetUserValue.totalPaid = totAmtPaid;
        this.fortNightStmtDtoForGetUserValue.destination = this.validateDestinationForSave;
        this.fortNightStmtDtoForGetUserValue.agentName = this.validateDestinationForSave;
        this.fortNightStmtDtoForGetUserValue.companyId = this.userDataDtoReturnSession.companyId;
        return this.fortNightStmtDtoForGetUserValue;
    }

    setMultipleValue() {
        this.fortNightStmtDtoForMultipleValue = new FortNightStmtDto();
        var cashAmtMultiple = null;
        var chqNumMultiple = null, chqAmtMultiple = null, cheqBankNameMultiple = null;
        var pdcNumMultiple = null, pdcAmtMultiple = null, pdcDueDateMultiple = null, pdcBankNameMultiple = null;


        for (let i = 0; i < this.amountReceivableForAgentStmtCashChequePdcDataList.length; i++) {
            if (this.amountReceivableForAgentStmtCashChequePdcDataList[i].paymentType == "Cheque") {
                chqNumMultiple = chqNumMultiple + "#"
                    + this.amountReceivableForAgentStmtCashChequePdcDataList[i].chequeNumber;
                chqAmtMultiple = chqAmtMultiple + "#"
                    + this.amountReceivableForAgentStmtCashChequePdcDataList[i].amount;
                cheqBankNameMultiple = cheqBankNameMultiple + "#"
                    + this.amountReceivableForAgentStmtCashChequePdcDataList[i].bankName;
            }
        }

        for (let i = 0; i < this.amountReceivableForAgentStmtCashChequePdcDataList.length; i++) {
            if (this.amountReceivableForAgentStmtCashChequePdcDataList[i].paymentType == "PDC") {
                pdcNumMultiple = pdcNumMultiple + "#"
                    + this.amountReceivableForAgentStmtCashChequePdcDataList[i].chequeNumber;
                pdcAmtMultiple = pdcAmtMultiple + "#"
                    + this.amountReceivableForAgentStmtCashChequePdcDataList[i].amount;
                pdcDueDateMultiple = pdcDueDateMultiple + "#"
                    + this.amountReceivableForAgentStmtCashChequePdcDataList[i].pdcDueDate;
                pdcBankNameMultiple = pdcBankNameMultiple + "#"
                    + this.amountReceivableForAgentStmtCashChequePdcDataList[i].bankName;
            }
        }

        for (let i = 0; i < this.amountReceivableForAgentStmtCashChequePdcDataList.length; i++) {
            if (this.amountReceivableForAgentStmtCashChequePdcDataList[i].paymentType == "Cash") {
                cashAmtMultiple = cashAmtMultiple + "#"
                    + this.amountReceivableForAgentStmtCashChequePdcDataList[i].amount;
            }
        }

        if (chqNumMultiple != null) {
            chqNumMultiple = chqNumMultiple.replace("null#", "");
        }
        if (chqAmtMultiple != null) {
            chqAmtMultiple = chqAmtMultiple.replace("null#", "");
        }
        if (pdcNumMultiple != null) {
            pdcNumMultiple = pdcNumMultiple.replace("null#", "");
        }
        if (pdcAmtMultiple != null) {
            pdcAmtMultiple = pdcAmtMultiple.replace("null#", "");
        }
        if (pdcDueDateMultiple != null) {
            pdcDueDateMultiple = pdcDueDateMultiple.replace("null#", "");
        }
        if (pdcBankNameMultiple != null) {
            pdcBankNameMultiple = pdcBankNameMultiple.replace("null#", "");
        }
        if (cheqBankNameMultiple != null) {
            cheqBankNameMultiple = cheqBankNameMultiple.replace("null#", "");
        }

        if (cashAmtMultiple != null) {
            cashAmtMultiple = cashAmtMultiple.replace("null#", "");
        }

        this.fortNightStmtDtoForMultipleValue.cashAmt = cashAmtMultiple;

        this.fortNightStmtDtoForMultipleValue.chequeNumber = chqNumMultiple;
        this.fortNightStmtDtoForMultipleValue.chequeAmtStr = chqAmtMultiple;
        this.fortNightStmtDtoForMultipleValue.cheqBankName = cheqBankNameMultiple;

        this.fortNightStmtDtoForMultipleValue.pdcNumber = pdcNumMultiple;
        this.fortNightStmtDtoForMultipleValue.pdcAmtStr = pdcAmtMultiple;
        this.fortNightStmtDtoForMultipleValue.pdcDueDateStr = pdcDueDateMultiple;
        this.fortNightStmtDtoForMultipleValue.pdcBankName = pdcBankNameMultiple;
        return this.fortNightStmtDtoForMultipleValue;
    }

    setAgtBalance(stmtDtoAmt) {
        this.showSpinnerForAction = true;
        this.lrService.setDestAgentBalAmt(stmtDtoAmt)
            .subscribe((response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                if (response.status == "success") {
                    swal("Alert", "Destination Agent Balance Amount Save Successfully", "success");
                    this.updateDestStmtBalAmt(stmtDtoAmt);
                } else {
                    swal("Failed", "Failed to save Destination Agent Balance Amount", "warning");
                    this.updateDestStmtBalAmt(stmtDtoAmt);
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While saving Dest Agent Balc Amt Details", "info");
            },
            () => console.log("done");
    }

    updateDestStmtBalAmt(stmtDtoAmt) {
        this.showSpinnerForAction = true;
        this.lrService.updateDestStmtBalAmt(stmtDtoAmt)
            .subscribe((response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                if (response.status == "success") {
                    swal("Alert", "Updated Destination Statement Balance Amount Save Successfully", "success");
                    this.clearOverAllField();
                } else {
                    swal("Failed", "Failed to Updated Destination Statement Balance Amount", "success");
                    this.clearOverAllField();
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While update  Dest Agent Stmt Bal Amt Details", "info");
            },
            () => console.log("done");
    }
    /* save start end */

    getAmtRecFromAgentDetails(amountReceivableData) {
        this.fortNightStmtDtoForDest = new FortNightStmtDto();
        this.fortNightStmtDtoForDest.amtRecId = amountReceivableData.amtRecId;
        this.showSpinnerForAction = true;
        console.log('inside');
        console.log(amountReceivableData);
        this.destStmtReport.getAmtRecFromAgentDetailsEdit(this.fortNightStmtDtoForDest)
            .subscribe((response) => {
                this.showSpinnerForAction = false;
                this.amountReceivableForAgentStmtCashChequePdcDataList = [];
                $("#" + this.pageId + "amountReceivableForAgentStmtCashChequePdcTableId").DataTable().destroy();
                if (response != null && response.stmtDtos.length > 0) {
                    this.amountReceivableForAgentStmtCashChequePdcDataList = response.stmtDtos;
                    this.dtTriggerAmountReceivableForAgentStmtCashChequePdc.next();

                    $("#" + this.pageId + "destinationId").val(amountReceivableData.agentName);
                    $("#" + this.pageId + "destinationId").attr("readonly", "true");
                    $("#" + this.pageId + "receivingDate").val(amountReceivableData.receivedDate);
                    console.log(amountReceivableData.cashAmt);
                    $("#" + this.pageId + "summaryCashAmt").val(amountReceivableData.cashAmt);
                    $("#" + this.pageId + "summaryChequeAmt").val(amountReceivableData.chequeAmt);
                    $("#" + this.pageId + "summaryPDCAmt").val(amountReceivableData.pdcAmt);
                } else {
                    this.dtTriggerAmountReceivableForAgentStmtCashChequePdc.next();
                    swal("No record", "No record found!", "info");
                }

                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Details", "info");
            },
            () => console.log("done");
    }

    validateUpdatePaymentBtn() {
        if ($("#" + this.pageId + "receivingDate").val() == null || $("#" + this.pageId + "receivingDate").val() == undefined ||
            $("#" + this.pageId + "receivingDate").val() == "") {
            swal("Mandatory Field", "Please Select the Receiving Date", "warning");
            return false;
        } else {
            swal({
                title: "Payment Confirmation",
                text: "Sure U Want To Update the Amount Details",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.updateAmountReceivableFromAgentPaymentDetails();
                }
            });
        }
    }

    updateAmountReceivableFromAgentPaymentDetails() {
        this.fortNightStmtDtoForReturnGetUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoForReturnGetUserValue = this.getUserValues();
        this.fortNightStmtDtoForReturnGetUserValue.amtRecId = this.amtRecIdEdit;
        this.fortNightStmtDtoForReturnGetUserValue.receivableAmt = this.existingRecAmtEdit;
        console.log(this.fortNightStmtDtoForReturnGetUserValue);
        this.showSpinnerForAction = true;
        this.lrService.updateAmountReceivedFromAgent(this.fortNightStmtDtoForReturnGetUserValue)
            .subscribe((response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                if (response.status == "success") {
                    swal("Alert", "Amount Received From Agent Updated Successfully", "success");

                    this.stmtDtoAmt = new FortNightStmtDto();
                    this.validateDestinationForSave = $("#" + this.pageId + "destinationId").val();
                    this.listOfDest = [];
                    for (let i = 0; i < this.list.length; i++) {
                        if (this.list[i] == $("#" + this.pageId + "destinationId").val()) {
                            this.listOfDest.push(this.list[i]);
                            break;
                        }
                    }
                    console.log(this.listOfDest);
                    this.list = this.listOfDest;
                    this.stmtDtoAmt.list = this.list;
                    this.stmtDtoAmt.destination = this.validateDestinationForSave;
                    this.stmtDtoAmt.companyId = this.userDataDtoReturnSession.companyId;
                    console.log(this.stmtDtoAmt);

                    this.setAgtBalance(this.stmtDtoAmt);
                } else {
                    swal("Failed", "Failed to Save Amount Received From Agent", "warning");
                }
                this.changeDetectorRef.detectChanges();
                //this.updateDestStmtBalAmt(this.stmtDtoAmt);
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While saving Amount Received Details", "info");
            },
            () => console.log("done");
    }

    overAllClearFieldBtn() {
        this.clearOverAllField();
    }


    clearOverAllField() {
        $("#" + this.pageId + "payemntModeType").val('Cash');
        this.viewCashChequePdcAmtField = true;
        this.viewChequePdcNoField = false;
        this.viewChequeDueDateField = false;
        this.viewChequePdcBankNameField = false;
        this.clearFieldBank();

        this.modelChequeDueDates = null;
        this.modelReceviDates = null;

        this.newAttributeTosetBank = {};
        this.editBankTableDetailsId = null;

        this.validateBalAmtToBePaid = null;
        this.validateBalAmtCash = null;
        this.validateBalAmtCheque = null;
        this.validateBalAmtPdc = null;

        this.validateBalAmtForSave = null;
        this.validateSummCashAmtForSave = null;
        this.validateSummChequeAmtForSave = null;
        this.validateSummPdcAmtForSave = null;
        this.validatePayingDateForSave = null;
        this.validateDestinationForSave = null;
        this.validateInitBalAmtForSave = null;
        this.resultDuplicate = null;

        this.fortNightStmtDtoForGetUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoForReturnGetUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoForMultipleValue = new FortNightStmtDto();

        $("#" + this.pageId + "totalAmountToBePaid").val('');
        $("#" + this.pageId + "balanceToBePaid").val('');
        $("#" + this.pageId + "summaryCashAmt").val('');
        $("#" + this.pageId + "summaryChequeAmt").val('');
        $("#" + this.pageId + "summaryPDCAmt").val('');
        $("#" + this.pageId + "payingDates").val('');
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "amount").val('');

        this.amountReceivableForAgentStmtCashChequePdcDataList = [];
        $("#" + this.pageId + "amountReceivableForAgentStmtCashChequePdcTableId").DataTable().destroy();
        this.dtTriggerAmountReceivableForAgentStmtCashChequePdc.next();
        this.modelDestination = null;
        this.existingRecAmtEdit = 0;
        this.amtRecIdEdit = 0;
    }
}
