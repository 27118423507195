<h1 class="title_custom" mat-dialog-title>
    <h6 style="margin-bottom: 0px;">POD Details
        <div mat-dialog-content>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">

                            <div class="row">
                                <div class="box-body">
                                    <img src='{{ signatureImg }}' />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <div class="input-group ">
                        <label>POD Done By : </label>
                        <label>{{podDoneBy}} </label>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="form-group">
                    <div class="input-group ">
                        <label>POD Done At : </label>
                        <label>{{podDoneAt}} </label>
                    </div>
                </div>
            </div>
        </div>
        <div mat-dialog-actions style="float: right;">

            <button class="btn btn-outline-success" mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>

        </div>