import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';

@Component({
    selector: 'app-cashmemo-bkg-print-autogst-popup',
    templateUrl: './cashmemo-bkg-print-autogst-popup.component.html',
    styleUrls: ['./cashmemo-bkg-print-autogst-popup.component.css']
})
export class CashmemoBkgPrintAutoGSTPopupComponent implements OnInit {

    data: any;
    todayDate: any;
    rateDtoForPrint: LRDto = new LRDto();
    example: string;
    userDataDtoReturnSession: any;
    srdGstNO = '';
    smsDetails = '';
    mainstation = '';
    showMain = true;
    dateConvert: any;
    dateConvertBkg: any;
    discount = 0;
    showDetails = '';
    setLrAddress = '';

    //    memoTime = '';
    roundOffView = false;
    roundAmt: any;
    bottomMsg1 = '';
    address: string;
    addressComaSplit: string[];
    addressLineOne: string = '';
    addressLineTwo: string = '';
    //    city: string;
    //    pinCode: string;
    phone: string;
    //    cityPin: string;
    faxNo: string;
    emailId: string;
    grandTotal: any;
    num: any;
    n: any;
    isLoggedIn = true;
    barcodeImg: any;
    dataForPrintAfterSave: any;
    viewBkgCashMemoPrintPage = true;
    empty = '';
    showFirstPrint = false;
    showSecondPrint = false;
    showThirdPrint = false;
    PrintBarcode = [];
    pageId = "cbapc";
    constructor(private router: Router, private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }


        if (this.isLoggedIn) {
            console.log('1111');
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            //            this.dataForPrint = localStorage.getItem( 'print' );
            this.dataForPrintAfterSave = JSON.parse(localStorage.getItem('printBkgCashmemoAutoGst'));
            //console.log('11112 : ' + this.dataForPrintAfterSave.cashMemoNumber);
            if (this.dataForPrintAfterSave != null) {
                this.rateDtoForPrint = this.dataForPrintAfterSave;
            }
            this.PrintBarcode = [{
                barcode: this.rateDtoForPrint.cashMemoNumber,
                Name: 'A'
            }];
            //            console.log( 'in print ' + this.rateDtoForPrint );
            //            console.log( this.rateDtoForPrint );

            //            this.todayDate = this.datePipe.transform( new Date(), "dd-MM-yyyy" );
            //Pending of Date formate
            //            if ( this.rateDtoForPrint.bookingDate == null ) {
            //                this.todayDate = 'NA';
            //            } else {
            //                this.todayDate = this.rateDtoForPrint.bookingDate;
            //            }



            this.mainstation = this.rateDtoForPrint.mainstation;
            //            console.log( this.mainstation );
            if (this.mainstation != null
                && ((this.mainstation.toLowerCase() == 'mumbai')
                    || (this.mainstation.toLowerCase() == 'delhi') || (this.mainstation.toLowerCase() == 'gujarat'))) {
                this.address = this.rateDtoForPrint.address;
                this.phone = this.rateDtoForPrint.mobileNum;
                this.emailId = this.rateDtoForPrint.emailId;
                this.faxNo = this.rateDtoForPrint.faxNo;

            } else {
                this.address = this.userDataDtoReturnSession.addressId == null ? "N/A" : this.userDataDtoReturnSession.addressId;
                this.phone = this.userDataDtoReturnSession.phoneNo == null ? "N/A" : this.userDataDtoReturnSession.phoneNo;

                this.emailId = this.userDataDtoReturnSession.cmpEmailId == null ? "N/A" : this.userDataDtoReturnSession.cmpEmailId;

                this.faxNo = this.userDataDtoReturnSession.faxNo == null ? "N/A" : this.userDataDtoReturnSession.faxNo;

            }

            //            if(this.setLrAddress=='Show Lr Address In Bkg Cashmemo'){
            //                this.address = this.rateDtoForPrint.address;
            //                this.phone = this.rateDtoForPrint.mobileNum;
            //                this.emailId = this.rateDtoForPrint.emailId;
            //                this.faxNo = this.rateDtoForPrint.faxNo;
            //
            //            } else {
            //                this.address = this.userDataDtoReturnSession.addressId == null ? "N/A" : this.userDataDtoReturnSession.addressId;
            //                this.phone = this.userDataDtoReturnSession.phoneNo == null ? "N/A" : this.userDataDtoReturnSession.phoneNo
            //                    .toString();
            //                this.emailId = this.userDataDtoReturnSession.cmpEmailId == null ? "N/A" : this.userDataDtoReturnSession.cmpEmailId
            //
            //                this.faxNo = this.userDataDtoReturnSession.faxNo == null ? "N/A" : this.userDataDtoReturnSession.faxNo;
            //
            //            }

            if (this.mainstation != null
                && ((this.mainstation.toLowerCase() == 'mumbai')
                    || (this.mainstation.toLowerCase() == 'delhi') || (this.mainstation.toLowerCase() == 'gujarat'))) {
                this.showMain = true;
                //                console.log('asdf1');
            } else {
                //                console.log('asdf2');
                this.showMain = false;
            }

            //            if (this.showDetails=='Show All details of Booking Cashmemo') {
            //                    this.showMain = true;
            //
            //                } else {
            //                    this.showMain = false;
            //                }


            this.validatBeforePrint();
            window.setTimeout(function () {
                window.print();
            }, 1000);
            window.onafterprint = function () {
                window.close();
            }
            localStorage.clear();

            window.addEventListener('afterprint', (event) => {

                if (this.viewBkgCashMemoPrintPage) {
                    this.viewBkgCashMemoPrintPage = false;
                    this.clearField();
                }
            });
        }
    }

    ngOnInit() {

    }

    validatBeforePrint() {
        //        alert( 'Hai Inside the validate' );
        this.srdGstNO = this.userDataDtoReturnSession.cmpGstNo;
        if (this.srdGstNO == null || this.srdGstNO == '') {
            this.srdGstNO = '';
        } else {
            this.srdGstNO = this.srdGstNO;
        }



        this.rateDtoForPrint.consignorState = this.rateDtoForPrint.consignorState == null ? "NA" : this.rateDtoForPrint.consignorState == '' ? "NA" : this.rateDtoForPrint.consignorState.toUpperCase();
        this.rateDtoForPrint.consignorStateCode = this.rateDtoForPrint.consignorStateCode == null ? "NA" : this.rateDtoForPrint.consignorStateCode == '' ? "NA" : this.rateDtoForPrint.consignorStateCode.toUpperCase();
        this.rateDtoForPrint.consigneeState = this.rateDtoForPrint.consigneeState == null ? "NA" : this.rateDtoForPrint.consigneeState == '' ? "NA" : this.rateDtoForPrint.consigneeState.toUpperCase();
        this.rateDtoForPrint.consigneeStateCode = this.rateDtoForPrint.consigneeStateCode == null ? "NA" : this.rateDtoForPrint.consigneeStateCode == '' ? "NA" : this.rateDtoForPrint.consigneeStateCode.toUpperCase();
        this.rateDtoForPrint.entryBy = this.rateDtoForPrint.entryBy == null ? "NA" : this.rateDtoForPrint.entryBy == '' ? "NA" : this.rateDtoForPrint.entryBy.toUpperCase();


        if (this.rateDtoForPrint.stationCode == null || this.rateDtoForPrint.stationCode == '') {
            this.rateDtoForPrint.stationCode = 'NA';
        } else {
            this.rateDtoForPrint.stationCode = this.rateDtoForPrint.stationCode;
        }

        if (this.rateDtoForPrint.consignorState == null || this.rateDtoForPrint.consignorState == '') {
            this.rateDtoForPrint.consignorState = 'NA';
        } else {
            this.rateDtoForPrint.consignorState = this.rateDtoForPrint.consignorState;
        }

        //console.log(this.rateDtoForPrint.bookingDate);
        //For Booking Date and Entered Date
        if (this.rateDtoForPrint.bookingDate == null) {
            this.dateConvert = 'NA';
            this.rateDtoForPrint.bookingDate = this.dateConvert;
        } else {
            this.dateConvert = this.datePipe.transform(this.rateDtoForPrint.bookingDate, "dd-MM-yyyy");
            this.rateDtoForPrint.bookingDate = this.dateConvert;
        }

        if (this.rateDtoForPrint.enteredDate == null) {
            this.dateConvertBkg = 'NA';
            this.rateDtoForPrint.enteredDate = this.dateConvertBkg;
        } else {
            this.dateConvertBkg = this.datePipe.transform(this.rateDtoForPrint.enteredDate, "dd-MM-yyyy");
            this.rateDtoForPrint.enteredDate = this.dateConvertBkg;
        }

        if (this.mainstation != null
            && ((this.mainstation.toLowerCase() == 'mumbai')
                || (this.mainstation.toLowerCase() == 'gujarat'))) {
            this.showFirstPrint = true;
            this.showSecondPrint = true;
            this.showThirdPrint = true;
            //console.log('asdf3');
        } else {
            this.showFirstPrint = true;

            //Pending Below Code need to be commented 
            //            this.showSecondPrint = true;
            //            this.showThirdPrint = true;
            this.showSecondPrint = false;
            this.showThirdPrint = false;
            //            console.log('asdf4');

        }
        //Lorry Freight
        if (this.rateDtoForPrint.frieghtAmt == null) {
            this.rateDtoForPrint.frieghtAmt = 0;
        } else {
            this.rateDtoForPrint.frieghtAmt = Math.round(this.rateDtoForPrint.frieghtAmt);
        }
        //For discount
        if (this.rateDtoForPrint.discountAmount == 0) {
            this.discount = 0;
        } else {
            this.discount = this.rateDtoForPrint.discountAmount;
        }
        this.empty = '';
        //        console.log( 'from here need to check the values' );
        //        console.log( '1' + this.rateDtoForPrint.frieghtAmt );
        //        console.log( '2' + this.rateDtoForPrint.hamaliGD );
        //        console.log( '3' + this.rateDtoForPrint.receiptCharge );
        //        console.log( '4' + this.rateDtoForPrint.serviceCharge );
        //        console.log( '5' + this.rateDtoForPrint.destHamali );
        //        console.log( '6' + this.rateDtoForPrint.subTotal );
        //        console.log( '7' + this.rateDtoForPrint.grandTotal );
        //        console.log( '8' + this.rateDtoForPrint.discountAmount );

        if (this.rateDtoForPrint.hamaliGD == null) {
            this.rateDtoForPrint.hamaliGD = 0;
        } else {
            this.rateDtoForPrint.hamaliGD = Math.round(this.rateDtoForPrint.hamaliGD);
        }

        if (this.rateDtoForPrint.receiptCharge == null) {
            this.rateDtoForPrint.receiptCharge = 0;
        } else {
            this.rateDtoForPrint.receiptCharge = Math.round(this.rateDtoForPrint.receiptCharge);
        }

        if (this.rateDtoForPrint.serviceCharge == null) {
            this.rateDtoForPrint.serviceCharge = 0;
        } else {
            this.rateDtoForPrint.serviceCharge = Math.round(this.rateDtoForPrint.serviceCharge);
        }

        if (this.rateDtoForPrint.destHamali == null) {
            this.rateDtoForPrint.destHamali = 0;
        } else {
            this.rateDtoForPrint.destHamali = Math.round(this.rateDtoForPrint.destHamali);
        }

        if (this.rateDtoForPrint.subTotal == null) {
            this.rateDtoForPrint.subTotal = 0;
        } else {
            this.rateDtoForPrint.subTotal = Math.round(this.rateDtoForPrint.subTotal);
        }



        if (this.rateDtoForPrint.cgstamt == null) {
            this.rateDtoForPrint.cgstamt = 0;
        } else {
            this.rateDtoForPrint.cgstamt = Math.round(this.rateDtoForPrint.cgstamt);
        }

        if (this.rateDtoForPrint.sgstamt == null) {
            this.rateDtoForPrint.sgstamt = 0;
        } else {
            this.rateDtoForPrint.sgstamt = Math.round(this.rateDtoForPrint.sgstamt);
        }

        if (this.rateDtoForPrint.igstamt == null) {
            this.rateDtoForPrint.igstamt = 0;
        } else {
            this.rateDtoForPrint.igstamt = Math.round(this.rateDtoForPrint.igstamt);
        }

        if (this.rateDtoForPrint.gstAmtTotal == null) {
            this.rateDtoForPrint.gstAmtTotal = 0;
        } else {
            this.rateDtoForPrint.gstAmtTotal = Math.round(this.rateDtoForPrint.gstAmtTotal);
        }

        //        console.log( '9' + this.rateDtoForPrint.cgstamt );
        //        console.log( '10' + this.rateDtoForPrint.sgstamt );
        //        console.log( '11' + this.rateDtoForPrint.igstamt );
        //        console.log( '12' + this.rateDtoForPrint.gstAmtTotal );




        //console.log(this.rateDtoForPrint.bookingDate);
        this.bottomMsg1 = "Track Your Consignment from Anywhere at Anytime. Just enter Your LR.NO at www.srdlogistics.com to get Status Of Your Consignment.";
        //console.log( this.bankDetails );
        this.smsDetails = 'To Receive SMS On Arrival Of Your Consignment, please update your Cell Number Or Contact 07899686234. Please download SRD Mobile APP in Google Play Store for Tracking Your Consignment.';

        //For Address
        this.addressComaSplit = this.address.split(",");
        //        console.log( this.address );
        this.addressLineOne = '';
        this.addressLineTwo = '';
        if (this.addressComaSplit.length > 0) {
            this.addressLineTwo = this.addressComaSplit[this.addressComaSplit.length - 1] + ",";
            //            console.log( this.addressLineTwo );
            for (let b = 0; b < this.addressComaSplit.length - 1; b++) {
                this.addressLineOne += this.addressComaSplit[b] + ",";
            }
        } else {
            this.addressLineOne = this.address;
        }




        if (this.rateDtoForPrint.grandTotal == 0.0 || this.rateDtoForPrint.grandTotal == 0) {
            this.grandTotal = "Zero";
        } else {
            this.grandTotal = this.inWords();
        }

        if (this.roundOffView == true) {
            this.roundAmt = ("Rounded Of " + this.rateDtoForPrint.roundOffAmt == null ? 0.0 : this.rateDtoForPrint.roundOffAmt);
        }
    }

    inWords() {
        var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ',
            'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ',
            'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ',
            'Seventeen ', 'Eighteen ', 'Nineteen '];
        var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty',
            'Seventy', 'Eighty', 'Ninety'];
        this.num = this.rateDtoForPrint.grandTotal;
        //                console.log( this.num );
        if ((this.num = this.num.toString()).length > 9)
            return 'overflow';
        this.n = ('000000000' + this.num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!this.n)
            return;
        var str = '';
        str += (this.n[1] != 0) ? (a[Number(this.n[1])] || b[this.n[1][0]] + ' '
            + a[this.n[1][1]])
            + 'Crore ' : '';
        str += (this.n[2] != 0) ? (a[Number(this.n[2])] || b[this.n[2][0]] + ' '
            + a[this.n[2][1]])
            + 'Lakh ' : '';
        str += (this.n[3] != 0) ? (a[Number(this.n[3])] || b[this.n[3][0]] + ' '
            + a[this.n[3][1]])
            + 'Thousand ' : '';
        str += (this.n[4] != 0) ? (a[Number(this.n[4])] || b[this.n[4][0]] + ' '
            + a[this.n[4][1]])
            + 'Hundred ' : '';
        str += (this.n[5] != 0) ? ((str != '') ? 'and ' : '')
            + (a[Number(this.n[5])] || b[this.n[5][0]] + ' ' + a[this.n[5][1]])
            + '' : '';

        str += ' Only ';
        return str;
    }

    clearField() {
        this.roundOffView = false;
        this.roundAmt = '';
        this.bottomMsg1 = '';
        this.address = "";
        this.addressComaSplit = [];
        this.addressLineOne = '';
        this.addressLineTwo = '';
        this.phone = '';
        this.faxNo = '';
        this.emailId = '';
        this.grandTotal = '';
        this.num = '';
        this.n = '';
        this.dateConvert = '';
        this.barcodeImg = '';
        this.rateDtoForPrint = new LRDto();
        this.rateDtoForPrint = new LRDto();
        this.srdGstNO = '';
        this.smsDetails = '';
        this.mainstation = '';
        this.showMain = true;
        this.dateConvert = '';
        this.dateConvertBkg = '';
        this.discount = 0;
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

}
