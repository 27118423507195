<html>

<head>
</head>

<body>
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}transferToColManId">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Billing To SRD Collection Stock</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Entry Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                class="fas fa-search"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}entryType" name="entryType" #entryType (keydown.enter)="entryTypeModeFocus($event)" (change)="entryTypeMode(entryType.value)">
                                                            <option value="manual">Manual</option>
                                                            <option value="automatic">Automatic</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="showStation" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Station</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                    class="fas fa-warehouse"></i>
                                                                </span>
                                                            </div>
                                                            <input #stations id="{{pageId}}stations" type="text" placeholder="Select Station" class="form-control" (selectItem)="getStationMemoCode($event)" [(ngModel)]="modelStationName" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="entryStationSearchTA"
                                                                (focus)="focusStationTA$.next($any($event).target.value)" (click)="clickTA(instanceStation)" #instanceStation="ngbTypeahead" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="manualView" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cash Memo :</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}cashMemoFormat" #cashMemoFormat aria-describedby="basic-addon11">
                                                        <input id="{{pageId}}memoNumber" type="text" class="form-control" (keyup)="getMemoNumber($event)" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="automaticView" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cash Memo :</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}cashMemoNumber" (keyup)="getMemoNumber($event)" #cashMemoNumber aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="manualView" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>LR Number :</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
														class="fas fa-file-alt"></i>
													</span>
                                                        </div>
                                                        <input type="text" class="form-control" #lrNumber id="{{pageId}}lrNumber" aria-describedby="basic-addon11" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="manualView" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Memo Amount :</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
														class="fas fa-rupee-sign"></i>
													</span>
                                                        </div>
                                                        <input type="number" class="form-control" id="{{pageId}}memoAmount" (keyup)="getValidateMemoNumber($event)" aria-describedby="basic-addon11" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="manualView" class="row">
                                            <div class="col-md-12">
                                                <button #transferBtn type="button" id="{{pageId}}transferBtn" class="btn btn-success m-r-10" (click)="validateMemoOnTransfer()" id="{{pageId}}transferBtn">Single Transfer</button>
                                                <button type="button" class="btn btn-success m-r-10" (click)="finishBtnTransfer();" #finishBtn id="{{pageId}}finishBtn">Finish</button>
                                            </div>
                                        </div>

                                        <div *ngIf="automaticView" class="row">
                                            <div class="col-md-12">
                                                <button #transferBtnBulk type="button" class="btn btn-success m-r-10" id="{{pageId}}transferBtnBulk" (click)="transferBtnValidation()">Bulk Transfer</button>
                                                <button type="button" class="btn btn-success m-r-10" (click)="finishBtnTransfer();" #finishBtnBulk id="{{pageId}}finishBtnBulk">Finish</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-8 vl p-t-10">
                                        <div class="row">
                                            <!-- column -->
                                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                                        </mat-progress-bar>
                                                        <br>
                                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                            Please Wait Loading Details.....</h6>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="card">
                                                    <div class="card-body">
                                                        <!-- <div class="row p-t-10">
															<div class="col-md-8"></div>
														</div> -->
                                                        <div class="row p-t-10">
                                                            <div class="col-md-12" style="text-align: right;">
                                                                <button type="submit" class="dt-button" (click)="customPrintTransToColMan()" id="{{pageId}}printAllBtn">
																	<span><i class="fas fa-print">Print</i></span>
																</button>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="box-body">
                                                                    <table datatable id="{{pageId}}transferToCollectionId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsTransfer" [dtTrigger]="dtTriggerTransfer">

                                                                        <thead>
                                                                            <tr>
                                                                                <th>Cash Memo</th>
                                                                                <th>LR No</th>
                                                                                <th>Tot Art</th>
                                                                                <th>Consignee</th>
                                                                                <th>Consignor</th>
                                                                                <th>Amount</th>
                                                                                <th>Collection Man</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let transferToCollectionData of transferToCollectionDataList; let i = index ">
                                                                                <td>{{ transferToCollectionData.memoNumber }}
                                                                                </td>
                                                                                <td>{{ transferToCollectionData.lrNumber }}
                                                                                </td>
                                                                                <td>{{ transferToCollectionData.totalArticles }}
                                                                                </td>
                                                                                <td>{{ transferToCollectionData.consigneeName }}
                                                                                </td>
                                                                                <td>{{ transferToCollectionData.consignor }}
                                                                                </td>
                                                                                <td>{{ transferToCollectionData.amount }}
                                                                                </td>
                                                                                <td>{{ transferToCollectionData.collectionMan }}
                                                                                </td>
                                                                                <td>

                                                                                    <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDeleteForTable(transferToCollectionData);">
																						<i title="Remove"
																							class="fas fa-trash"></i>
																					</button>
                                                                                </td>

                                                                            </tr>
                                                                        </tbody>
                                                                        <tfoot>
                                                                            <tr>
                                                                                <td style="text-align: left">Total : {{countOfLr}}
                                                                                </td>
                                                                                <td></td>
                                                                                <td style="text-align: left">
                                                                                    {{sumOfTotArt}}</td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td style="text-align: left">
                                                                                    {{sumOfTotAmt}}</td>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </tfoot>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>

</body>

</html>