<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showLorryHireBlnRpt">
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Search By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchByMode(searchBy.value)">
															<option selected value="hireslipWise">Search By
																Hireslip</option>
															<option value="dateWise">Search By Date</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="hireslipWise" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Hireslip Number</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}hireslipNumber" class="form-control" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="dateWise" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Payment Type</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}paymentType" name="paymentType" #paymentType (change)="searchByPaymentType(paymentType.value)">
															<option selected value="all">All</option>
															<option value="paid">Paid</option>
															<option value="unpaid">Unpaid</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="dateWise" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="dateWise" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="dateWise" class="col-sm-12 col-md-12">

                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input #source id="{{pageId}}source" name="source" type="text" class="form-control" placeholder="Select Source" [(ngModel)]="modelForMainSrc" [ngbTypeahead]="searchMainSrcAt" [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterMainSrc" [inputFormatter]="formatterMainSrc"
                                                            (focus)="focusMainSrcTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="viewDestination" class="col-sm-12 col-md-12">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Destination</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
																	class="fas fa-user"></i>
																</span>
                                                            </div>
                                                            <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination" (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                                placeholder="Select Destination" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod();">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearMethod();">Clear</button>
                        </div>
                    </div>

                    <div class="col-md-9 vl p-t-10">
                        <div class="row">
                            <!-- column -->
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row p-t-10">
                                            <div class="col-md-8"></div>
                                        </div>
                                        <div class="row p-t-10">
                                            <div class="col-md-8">
                                                <!-- 												<h6 class="card-title">Lorry Hire Balance Report</h6> -->
                                            </div>
                                            <div class="col-md-4">
                                                <button type="submit" class="dt-button" style="margin-left: 75%;" (click)="customPrintLorryHireBalRpt()" id="{{pageId}}printAllBtn" [hidden]="!showOthersLorryHireBalanceRpt">
													<span><i class="fas fa-print">Print</i></span>
												</button>
                                                <button type="submit" class="dt-button" style="margin-left: 75%;" (click)="customPrintPaidLorryHireBalRpt()" id="{{pageId}}printAllBtn" [hidden]="!showPaidLorryHireBalanceRpt">
													<span><i class="fas fa-print">Print</i></span>
												</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="box-body" [hidden]="!showOthersLorryHireBalanceRpt">
                                                    <table datatable id="{{pageId}}lorryHireBalanceRptId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLorryHireBalance" [dtTrigger]="dtTriggerLorryHireBalance">

                                                        <thead>
                                                            <tr>
                                                                <th>Action</th>
                                                                <th>Hire No</th>
                                                                <th>Truck No</th>
                                                                <th>Supplier Name</th>
                                                                <th>Driver</th>
                                                                <th [hidden]="!showHireColumns">Tot Hire</th>
                                                                <th [hidden]="!showHireColumns">Advance</th>
                                                                <th>Balance</th>
                                                                <th>Dep Dt</th>
                                                                <th>Dep Time</th>
                                                                <th>Arr Dt</th>
                                                                <th>Arr Time</th>
                                                                <th>Payment Dt</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let lorryHireBalanceData of lorryHireBalanceDataList ">
                                                                <td><button *ngIf="lorryHireBalanceData.hireSlipBalance == false" class="btn1" title="Confirm Payment" (click)="getConfirmPaymnent(confirmPaymentPopUpTemplate,lorryHireBalanceData);">
																		<i class="fa fa-file"></i>
																	</button>&nbsp;&nbsp;&nbsp;
                                                                    <button class="btn1" title="LorryHire Voucher" (click)="getVoucherPrint(lorryHireBalanceData);">
																		<i class="fa fa-print"></i>
																	</button></td>
                                                                <td>{{ lorryHireBalanceData.hireslipnumber }}</td>
                                                                <td>{{ lorryHireBalanceData.vehicleNumber }}</td>
                                                                <td>{{ lorryHireBalanceData.supplierName }}</td>
                                                                <td>{{ lorryHireBalanceData.drivername }}</td>
                                                                <td [hidden]="!showHireColumns">{{ lorryHireBalanceData.totalhire }}</td>
                                                                <td [hidden]="!showHireColumns">{{ lorryHireBalanceData.advance }}</td>
                                                                <td>{{ lorryHireBalanceData.balance }}</td>
                                                                <td>{{ lorryHireBalanceData.depatureDtStr }}</td>
                                                                <td>{{ lorryHireBalanceData.departureTime }}</td>
                                                                <td>{{ lorryHireBalanceData.arrivalDtStr }}</td>
                                                                <td>{{ lorryHireBalanceData.arrivalTime }}</td>
                                                                <td>{{ lorryHireBalanceData.paymentDtStr }}</td>

                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td [hidden]="!showHireColumns"></td>
                                                                <td [hidden]="!showHireColumns"></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                <div class="box-body" [hidden]="!showPaidLorryHireBalanceRpt">
                                                    <table datatable id="{{pageId}}lorryHireBalancePaidRptId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLorryHireBalancePaid" [dtTrigger]="dtTriggerLorryHireBalancePaid">

                                                        <thead>
                                                            <tr>
                                                                <th>Action</th>
                                                                <th>Hire No</th>
                                                                <th>Truck No</th>
                                                                <th>Supplier Name</th>
                                                                <th>Driver</th>
                                                                <th [hidden]="!showHireColumns">Tot Hire</th>
                                                                <th [hidden]="!showHireColumns">Advance</th>
                                                                <th>Balance</th>
                                                                <th>Dep Dt</th>
                                                                <th>Dep Time</th>
                                                                <th>Arr Dt</th>
                                                                <th>Arr Time</th>
                                                                <th>Payment Dt</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let lorryHireBalancePaidData of lorryHireBalancePaidDataList ">
                                                                <td><button class="btn1" title="LorryHire Voucher" (click)="getVoucherPrint(lorryHireBalancePaidData);">
																		<i class="fa fa-print"></i>
																	</button></td>
                                                                <td>{{ lorryHireBalancePaidData.hireslipnumber }}</td>
                                                                <td>{{ lorryHireBalancePaidData.vehicleNumber }}</td>
                                                                <td>{{ lorryHireBalancePaidData.supplierName }}</td>
                                                                <td>{{ lorryHireBalancePaidData.drivername }}</td>
                                                                <td [hidden]="!showHireColumns">{{ lorryHireBalancePaidData.totalhire }}</td>
                                                                <td [hidden]="!showHireColumns">{{ lorryHireBalancePaidData.advance }}</td>
                                                                <td>{{ lorryHireBalancePaidData.balance }}</td>
                                                                <td>{{ lorryHireBalancePaidData.depatureDtStr }}</td>
                                                                <td>{{ lorryHireBalancePaidData.departureTime }}</td>
                                                                <td>{{ lorryHireBalancePaidData.arrivalDtStr }}</td>
                                                                <td>{{ lorryHireBalancePaidData.arrivalTime }}</td>
                                                                <td>{{ lorryHireBalancePaidData.invoiceDtStr }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td [hidden]="!showHireColumns"></td>
                                                                <td [hidden]="!showHireColumns"></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <!--POP UP START HERE-->

    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}popupDetailsConfirmPayment">
                <ng-template #confirmPaymentPopUpTemplate let-ok="close" let-d="dismiss">


                    <div class="modal-header">
                        <h5>Set Payment Date :</h5>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
						<span aria-hidden="true">&times;</span>
					</button>
                    </div>

                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Payment Date : </label> <input class="form-control" id="{{pageId}}paymentDate" placeholder="yyyy-mm-dd" name="paymentDate" [(ngModel)]="enteredPaymentDate" ngbDatepicker #paymentDate="ngbDatepicker">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="paymentDate.toggle()" type="button"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-12 p-t-10" style="text-align: right;">
                                <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}confirmBtnId" (click)="confirmBtnPopUp(confirmPaymentPopUpTemplate)">Confirm
							</button>
                                <button type="button" class="btn btn-outline-secondary" (click)="ok('Cancel click');closeBtnPopup();">Close</button>


                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div *ngIf="viewVoucher " onafterprint="afterPrint()" id="{{pageId}}printVoucher">
        <app-lorry-hire-balance-rpt-print></app-lorry-hire-balance-rpt-print>
    </div>


    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>