import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from "angular-datatables";
import { ElementRef, ViewChild } from "@angular/core";
import swal from 'sweetalert';
import { LRDto } from 'src/app/dto/LR-dto';
import { LrService } from 'src/app/dataService/lr-service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-edit-lr-freight',
    templateUrl: './edit-lr-freight.component.html',
    styleUrls: ['./edit-lr-freight.component.css']
})

export class EditLrFreightComponent implements OnInit {

    userDataDtoReturnSession: any;
    pageId = "edlrfrgt";
    isLoggedIn = true;
    totalAmt = 0.0;
    baseFreight: any;
    hamali: any;
    gcCharge: any;
    lcCharge: any;
    bcCharge: any;
    aocCharge: any;
    others: any;
    ddAmt: any;
    enteredRiskChg: any;
    totalAmount: any;
    lrDto: LRDto = new LRDto();
    enteredLrNumber: any;
    showSpinnerForAction = false;
    lrtDtoRespForFreight: any;
    closeResult: string;
    lrDtoForUpdate: LRDto = new LRDto();
    lrDtoReturn: any;
    lrNumber: any;
    totalAmtNew: any;
    lrNumberModel: any;
    fovModel: any;
    ddAmtRateChg: any;
    isEditBlock: boolean = false;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    constructor(
        private router: Router, private lrService: LrService,
        public changeDetectorRef: ChangeDetectorRef, private modalService: NgbModal
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

    ngAfterViewInit(): void {
    }

    // //for Base Freight
    // baseFreightEvent(e) {
    //     if (e.keyCode == 13) {
    //         this.baseFreight = $("#" + this.pageId + "baseFreight").val();
    //         if (this.baseFreight == 0.0 || this.baseFreight == 0) {
    //             swal({
    //                 title: "Alert",
    //                 text: "Freight Amount can not be zero",
    //                 icon: "warning",
    //                 closeOnClickOutside: false,
    //                 closeOnEsc: false,
    //             });
    //         } else {
    //             this.sum();
    //             this.hamaliField.nativeElement.focus();
    //         }

    //     }
    // }

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }

    baseFreightBlur(e) {
        this.sum();
    }

    hamaliBlur(e) {
        this.sum();
    }

    gcChargeBlur(e) {
        this.sum();
    }

    lcChargeBlur(e) {
        this.sum();
    }

    bcChargeBlur(e) {
        this.sum();
    }

    fovBlur(e) {
        this.sum();
    }

    aocChargeBlur(e) {
        this.sum();
    }

    othersBlur(e) {
        this.sum();

    }

    ddAmtBlur(e) {
        this.sum();
    }

    //for popup modal starts 
    open2(content) {
        this.modalService.open(content, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    //for popup modal ends 

    sum() {
        this.baseFreight = $("#" + this.pageId + "baseFreight").val();
        this.hamali = $("#" + this.pageId + "hamali").val();
        this.gcCharge = $("#" + this.pageId + "gcCharge").val();
        this.lcCharge = $("#" + this.pageId + "lcCharge").val();
        this.bcCharge = $("#" + this.pageId + "bcCharge").val();
        this.aocCharge = $("#" + this.pageId + "aocCharge").val();
        this.others = $("#" + this.pageId + "others").val();
        this.ddAmt = $("#" + this.pageId + "ddAmt").val();
        this.enteredRiskChg = $("#" + this.pageId + "fov").val();

        this.totalAmt = (+(this.baseFreight == null ? 0.0 : this.baseFreight == '' ? 0.0 : this.baseFreight)
            + +(this.hamali == null ? 0.0 : this.hamali == '' ? 0.0 : this.hamali)
            + +(this.gcCharge == null ? 0.0 : this.gcCharge == '' ? 0.0 : this.gcCharge)
            + +(this.lcCharge == null ? 0.0 : this.lcCharge == '' ? 0.0 : this.lcCharge)
            + +(this.bcCharge == null ? 0.0 : this.bcCharge == '' ? 0.0 : this.bcCharge)
            + +(this.aocCharge == null ? 0.0 : this.aocCharge == '' ? 0.0 : this.aocCharge)
            + +(this.others == null ? 0.0 : this.others == '' ? 0.0 : this.others)
            + +(this.ddAmt == null ? 0.0 : this.ddAmt == '' ? 0.0 : this.ddAmt)
            + +(this.enteredRiskChg == null ? 0.0 : this.enteredRiskChg == '' ? 0.0 : this.enteredRiskChg));

        $("#" + this.pageId + "totalAmount").val(this.totalAmt);
    }

    setLrNumber() {
        this.lrDto = new LRDto();
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.lrDto.lrNumber = this.enteredLrNumber;
    }

    getLrDetailsByFreightMethod() {
        this.clearFields();
        this.setLrNumber();
        console.log(this.lrDto);
        this.showSpinnerForAction = true;
        this.lrService.getLrDetailsByFreight(this.lrDto).subscribe(
            (response) => {
                this.lrtDtoRespForFreight = [];
                this.lrtDtoRespForFreight = response;
                console.log(" this.lrtDtoRespForFreight" + this.lrtDtoRespForFreight);
                console.log(" this.userDataDtoReturnSession.role" + this.userDataDtoReturnSession.role);
                console.log(" this.userDataDtoReturnSession.office" + this.userDataDtoReturnSession.office);
                console.log(" response" + response);

                this.showSpinnerForAction = false;
                if (this.lrtDtoRespForFreight.chkStatus == "exists") {
                    
                    if (this.lrtDtoRespForFreight.bookingAgntStmt != null) {
                        this.isEditBlock = true;
                    }

                    if (this.lrtDtoRespForFreight.fortNightStmtNo != null) {
                        this.isEditBlock = true;
                    }

                    if (this.lrtDtoRespForFreight.cashMemoNumber != null) {
                        this.isEditBlock = true;
                    }

                    if (this.isEditBlock) {
                        this.blockMessage();
                    } else {
                        if (this.userDataDtoReturnSession.role == "Booking Administrator") {
                            if (this.userDataDtoReturnSession.office == this.lrtDtoRespForFreight.mainstation) {
                                this.setInFeilds();
                            } else {
                                this.blockMessage();
                            }
                        } else {
                            if (this.userDataDtoReturnSession.office == this.lrtDtoRespForFreight.agentId) {
                                this.setInFeilds();
                            } else {
                                this.blockMessage();
                            }
                        }
                    }

                } else {
                    swal({
                        title: "Warning",
                        text: "No details found for the given Lr Number!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearFields();
                    this.showSpinnerForAction = false;
                }


                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the details from the Lr Number", "warning");
            }, () => console.log('done');
    }

    blockMessage() {
        swal({
            title: "Warning",
            text: "Not allowed to view/edit this Lr Number!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
        });
    }

    validateForLrNumber(e) {
        if (e.keyCode == 13) {
            this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
            if (this.enteredLrNumber == null || this.enteredLrNumber == undefined || this.enteredLrNumber == '') {
                swal({
                    title: "Mandatory Fields",
                    text: "Please Enter LR Number!",
                    icon: "warning",
                    closeOnClickOutside: true,
                    closeOnEsc: true,
                })
            } else {
                this.getLrDetailsByFreightMethod();
            }
        }
    }

    setInFeilds() {
        $("#" + this.pageId + "baseFreight").val(this.lrtDtoRespForFreight.baseFreight);
        $("#" + this.pageId + "hamali").val(this.lrtDtoRespForFreight.hamaliLR);
        $("#" + this.pageId + "gcCharge").val(this.lrtDtoRespForFreight.gcCharge);
        $("#" + this.pageId + "lcCharge").val(this.lrtDtoRespForFreight.lcChg);
        $("#" + this.pageId + "bcCharge").val(this.lrtDtoRespForFreight.bcChg);
        $("#" + this.pageId + "aocCharge").val(this.lrtDtoRespForFreight.aoc);
        $("#" + this.pageId + "others").val(this.lrtDtoRespForFreight.others);
        $("#" + this.pageId + "fov").val(this.lrtDtoRespForFreight.riskCharge);
        $("#" + this.pageId + "totalAmount").val(this.lrtDtoRespForFreight.totalAmount);
        $("#" + this.pageId + "ddAmt").val(this.lrtDtoRespForFreight.doorDeliveryAmount);
    }

    setForUpdateLrFreight() {
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
        this.baseFreight = $("#" + this.pageId + "baseFreight").val();
        this.hamali = $("#" + this.pageId + "hamali").val();
        this.gcCharge = $("#" + this.pageId + "gcCharge").val();
        this.lcCharge = $("#" + this.pageId + "lcCharge").val();
        this.bcCharge = $("#" + this.pageId + "bcCharge").val();
        this.aocCharge = $("#" + this.pageId + "aocCharge").val();
        this.others = $("#" + this.pageId + "others").val();
        this.enteredRiskChg = $("#" + this.pageId + "fov").val();
        this.totalAmount = $("#" + this.pageId + "totalAmount").val();
        this.ddAmt = $("#" + this.pageId + "ddAmt").val();

        this.lrDtoForUpdate = new LRDto();
        this.lrDtoForUpdate.lrNumber = this.enteredLrNumber;
        this.lrDtoForUpdate.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoForUpdate.companyId = this.userDataDtoReturnSession.companyId;

        this.lrDtoForUpdate.baseFreight = this.baseFreight;
        this.lrDtoForUpdate.hamaliLR = this.hamali;
        this.lrDtoForUpdate.gcCharge = this.gcCharge;
        this.lrDtoForUpdate.aoc = this.aocCharge;
        this.lrDtoForUpdate.others = this.others;
        this.lrDtoForUpdate.lcChg = this.lcCharge;
        this.lrDtoForUpdate.bcChg = this.bcCharge;
        this.lrDtoForUpdate.riskCharge = this.enteredRiskChg;
        this.lrDtoForUpdate.doorDeliveryAmount = this.ddAmt;

        this.totalAmtNew = (+(this.baseFreight == null ? 0.0 : this.baseFreight == '' ? 0.0 : this.baseFreight)
            + +(this.hamali == null ? 0.0 : this.hamali == '' ? 0.0 : this.hamali)
            + +(this.gcCharge == null ? 0.0 : this.gcCharge == '' ? 0.0 : this.gcCharge)
            + +(this.lcCharge == null ? 0.0 : this.lcCharge == '' ? 0.0 : this.lcCharge)
            + +(this.bcCharge == null ? 0.0 : this.bcCharge == '' ? 0.0 : this.bcCharge)
            + +(this.aocCharge == null ? 0.0 : this.aocCharge == '' ? 0.0 : this.aocCharge)
            + +(this.others == null ? 0.0 : this.others == '' ? 0.0 : this.others)
            + +(this.ddAmt == null ? 0.0 : this.ddAmt == '' ? 0.0 : this.ddAmt)
            + +(this.enteredRiskChg == null ? 0.0 : this.enteredRiskChg == '' ? 0.0 : this.enteredRiskChg));
        this.lrDtoForUpdate.frieghtAmt = this.totalAmtNew;
        this.lrDtoForUpdate.totalAmount = this.totalAmtNew;
    }

    updateLrFreightDetailsMethod() {
        swal({
            title: "Confirm Update",
            text: "Sure U want to Update Edit Lr Freight Details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.setForUpdateLrFreight();
                console.log("lrDtoForUpdate");
                console.log(this.lrDtoForUpdate);
                this.lrService.updateLrFreightDetails(this.lrDtoForUpdate).
                    subscribe((data) => {
                        this.lrDtoReturn = data;

                        if (this.lrDtoReturn.status == "Success") {
                            swal("Success", "Edit Lr Freight Details saved/updated successfully!", "info");
                            this.clearAllFeilds();
                        } else {
                            swal("Failed", "Failed to update the Edit Lr Freight Details!", "error");
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => {
                        swal("Error", "Server problem occurred while updating the Edit Lr Freight Details!", "error");
                    },
                    () => console.log('done');
            }
        });
    }

    checkInvoiceNo() {
        if (this.lrtDtoRespForFreight.invoiceNumber != null) {
            swal("Warning", "Invoice Generated for this LR Please Contact Admin!", "info");
        } else {
            this.updateLrFreightDetailsMethod();
        }
    }

    clearFields() {
        $("#" + this.pageId + "baseFreight").val('');
        $("#" + this.pageId + "hamali").val('');
        $("#" + this.pageId + "gcCharge").val('');
        $("#" + this.pageId + "lcCharge").val('');
        $("#" + this.pageId + "bcCharge").val('');
        $("#" + this.pageId + "aocCharge").val('');
        $("#" + this.pageId + "others").val('');
        $("#" + this.pageId + "fov").val('');
        $("#" + this.pageId + "totalAmount").val('');
        $("#" + this.pageId + "ddAmt").val('');
        this.baseFreight = '';
        this.hamali = '';
        this.gcCharge = '';
        this.lcCharge = '';
        this.bcCharge = '';
        this.aocCharge = '';
        this.others = '';
        this.enteredRiskChg = '';
        this.totalAmount = '';
        this.ddAmt = '';
        this.isEditBlock = false;
    }

    clearAllFeilds() {
        $("#" + this.pageId + "lrNumber").val('');
        this.clearFields();
    }

}
