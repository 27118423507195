import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { Subject, Subscription, merge } from 'rxjs';
import { LRDto } from "src/app/dto/LR-dto";
import { Invoice } from 'src/app/dataService/invoice';

@Component({
	selector: 'app-invoice-lrdetails-mainsrc-popup',
	templateUrl: './invoice-lrdetails-mainsrc-popup.component.html',
	styleUrls: ['./invoice-lrdetails-mainsrc-popup.component.css']
})
export class InvoiceLrdetailsMainsrcPopupComponent implements OnInit {

	mainSrcLrDetailsDataPopupList: any;
	dtTriggerMainSrcLrDetailsPopupDetails: Subject<any> = new Subject();
	dtOptionsMainSrcLrDetailsPopupDetails:any;
	viewCustomPrintV1 = false;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;

	popupMainSrcLrDetailsPopupLocalStorage:any;
	lrDto: LRDto = new LRDto();
	reportModeSelected : any;
	pageId="ilmsp";

	constructor(private router: Router, private invoiceDetailsRpt: Invoice, public changeDetectorRef : ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.popupMainSrcLrDetailsPopupLocalStorage = JSON.parse(localStorage.getItem('popupMainSrcLrDetailsPopup'));
			localStorage.clear();
			this.reportModeSelected = "mainWise";
			this.getLrDetialsMainDetailsList(this.popupMainSrcLrDetailsPopupLocalStorage);
		}

	}

	logInPage() {
        this.router.navigate(['/authentication/login']);
    }
	

	ngOnInit(): void {
		this.dtOptionsMainSrcLrDetailsPopupDetails  = {
			dom: 'Bfrtip',
			buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                }
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			searching: false,
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				
				var totalArticles = api.column(2).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
					}, 0);
				var totTopay = api.column(6).data().reduce(
							function (a, b) {
								return intVal(a) + intVal(b);
					}, 0);
				var totPaid = api.column(7).data().reduce(
								function (a, b) {
									return intVal(a) + intVal(b);
					}, 0);
				var totFov = api.column(8).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
					}, 0);

				// Update footer by showing the total with the reference of the column index 
				
				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(2).footer()).html(totalArticles);
				$(api.column(6).footer()).html(totTopay);
				$(api.column(7).footer()).html(totPaid);
				$(api.column(8).footer()).html(totFov);
				
			}
		};
	}


	ngOnDestroy(): void {
		this.dtTriggerMainSrcLrDetailsPopupDetails.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerMainSrcLrDetailsPopupDetails.next();
	}

	getLrDetialsMainDetailsList(popupMainSrcLrDetailsPopupLocalStorage) {
		this.showSpinnerForAction = true;
		this.lrDto = new LRDto();
		this.lrDto.invoiceNumber = popupMainSrcLrDetailsPopupLocalStorage.invoiceNumber;
		this.lrDto.mode = this.reportModeSelected;
		this.lrDto.destination = popupMainSrcLrDetailsPopupLocalStorage.destination;
		this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
		
		this.invoiceDetailsRpt.getMainInvoiceLrDetails(this.lrDto).subscribe(
			(response) => {
				$("#"+this.pageId+"mainSrcLrDetailsPopupTableId").DataTable().destroy();
				this.mainSrcLrDetailsDataPopupList =[];
				if (response.length > 0) {
					this.mainSrcLrDetailsDataPopupList = response;
				} else {
					swal("No Records", "No Records found for this search", "info");
				}
				setTimeout(()=>{                           
					this.dtTriggerMainSrcLrDetailsPopupDetails.next();
			   }, 1000);
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Details", "info");
			},
			() => console.log('done');
	}
	
	reportTypeMode(reportType: string) {
        if (reportType == 'mainWise') {
        	this.reportModeSelected = "mainWise";
        } else if (reportType == 'subWise') {
        	this.reportModeSelected = "subWise";
        } 
    }
}

