import { Component, OnInit, QueryList, ViewChildren, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { FortNightStmtDto } from "src/app/dto/FortNightStmt-dto";
import swal from 'sweetalert';
import { ActivatedRoute, Router } from '@angular/router';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { LRDto } from "src/app/dto/LR-dto";
import { LrDetailsComponent } from "src/app/dashboards/dashboards-popup/lr-details/lr-details.component";
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { NgbModalRef, ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageDto } from 'src/app/dto/Message-dto';

@Component({
    selector: 'app-fortnight-statement-details.component',
    templateUrl: './fortnight-statement-details.component.html',
    styleUrls: ['./fortnight-statement-details.component.css']
})
export class FortnightStatementDetailsComponent implements OnInit {

    gettingDataFrmServiceFrLrDetailsTable: any;
    gettingDataFrmServiceFrAdditionDatatableTable: any;
    gettingDataFrmServiceFrDeductionDatatableTable: any;

    lrDetailsDataList: any;
    additionDatatableDataList: any;
    deductionDatatableDataList: any;

    onDestroyUnsubscribtionLrDetails: Subscription;
    onDestroyUnsubscribtionAdditionDatatable: Subscription;
    onDestroyUnsubscribtionDeductionDatatable: Subscription;

    //summaryTable:any;

    loadingIndicator = true;

    //@ViewChildren(DataTableDirective)
    //dtElements: QueryList<DataTableDirective>;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    //@ViewChild(DialogComponent) dialogComponent: DialogComponent;


    dtTriggerLrDetails: Subject<any> = new Subject();
    dtTriggerAdditionDatatable: Subject<any> = new Subject();
    dtTriggerDeductionDatatable: Subject<any> = new Subject();


    // @ViewChild('dataTable') table: ElementRef;
    dataTable: any;

    dtOptionsLrDetails: any;
    dtOptionAdditionDatatable: any;
    dtOptionDeductionDatatable: any;
    //firstDtOptions: DataTables.Settings = {};


    fortNightStmtDtoUserValues: FortNightStmtDto = new FortNightStmtDto();
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    showSpinnerForAction1 = false;
    fortnightStatementSmrySelectedData: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoUpdate: FortNightStmtDto = new FortNightStmtDto();
    deductionDatatableDataListUpdated: any;
    additionDatatableDataListUpdated: any;
    fortNightStmtDtoAddDed: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoReturn: FortNightStmtDto = new FortNightStmtDto();
    recAmt: any;
    dueAmt: any;
    descReadOnly = false;
    enteredDesc: any;
    enteredValue: any;
    descIsSelectedAdd = false;
    selectedIndexAdd: any;
    descIsSelectedDed = false;
    selectedIndexDed: any;
    totAddition = null;
    totDed = null;
    actDedAmt = null;

    lrdtoSendMultipltSMSMethodList: Array<LRDto> = [];
    lrdtoSendMultipltSMSMethod: LRDto = new LRDto();
    lrdtoSendMultipltSMSMethodSet: LRDto = new LRDto();
    msgContent: any;
    trxType: any;
    lrDtoForDetails: LRDto = new LRDto();
    selectedRowInvoiceNumber: any;
    lrDtoForRemMos: LRDto = new LRDto();
    lrDtoForPopup: LRDto = new LRDto();
    responseForRemMos: any;

    /* start 16062021*/
    /*print for summary lr*/
    viewStatementRptLRDetlsPrint: boolean = false;

    additionValueLRDto: LRDto = new LRDto();
    deductionValueLRDto: LRDto = new LRDto();

    statementReportLrAdditionDataListSumm: any;
    statementReportLrDeductionDataListSumm: any;

    statementReportLrAdditionDataListSummReturn: any;
    statementReportLrDeductionDataListSummReturn: any;

    totalAmount_Addition: any;
    totalAmount_Deduction: any;

    /* for print Details */
    viewCustomPrintV1 = false;


    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;


    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];

    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];

    //Custom Print Footer
    summary1: number;
    summary2: number;
    summary3: number;
    summary4: number;
    summary5: number;
    summary6: number;

    lrDtoForLRDetailsPrint: LRDto = new LRDto();
    fortnightStmtOfLrDetailsPrint: any;
    fromPriodForPrint: any;
    toPriodForPrint: any;

    lrDtoForLRDetailsExcel: LRDto = new LRDto();
    lrDtoForSeperateStation: LRDto = new LRDto();
    lrDtoServTaxValueForFileCreation: LRDto = new LRDto();
    lrDtoServTaxValueForFileCreationReturn: any;
    hireslipDtoServTaxValueForFileCreation: HireSlipDto = new HireSlipDto();
    hireslipDtoServTaxValueForFileCreationReturn: any;

    lrDtoEmail: LRDto = new LRDto();
    lrDtosEmail: Array<LRDto> = [];

    hireSlipDtoEmail: HireSlipDto = new HireSlipDto();
    hireSlipDtosEmail: Array<HireSlipDto> = [];

    lrDtoSubstationLrsForEmail: LRDto = new LRDto();
    lrDtosEmailSubstation: Array<LRDto> = [];

    fileServTax: any;

    modalRefferenceContent1PopUp: NgbModalRef;
    modalRefferenceContent1ClosePopUp: string;
    @ViewChild('content1PopUpTemplate') private content1PopUpTemplate;

    servTaxRptFileName: any;

    emailsTextValue: any;
    statementDocsHtmlReturn = null;

    messageDto: MessageDto = new MessageDto();
    emailToList: string[];
    emailCcList: string[];
    emailBccList: string[];
    lrDtoForGeneralEmail: LRDto = new LRDto();
    viewStatementRptLRDetlsDocx: boolean = false;

    result: any;
    result1: any;
    result3: any;
    finalResult: any;
    summaryTotalLrs: any;
    summaryTotalActWgt: any;
    summaryTotalAmt: any;
    summaryTotalPaid: any;
    summaryTotalServTax: any;

    private newAttribute: any = {};

    lrDtoForHashmap: LRDto = new LRDto();
    lrDtoForHashmapList: LRDto[];
    pageId = "fsdc";



    constructor(private router: Router, private destStmtReport: DestStmtReport,
        private hireslipService: HireslipService, public dialog: MatDialog,
        private route: ActivatedRoute, private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //sweet alert ends
        }
        if (this.isLoggedIn) {
            this.fortnightStatementSmrySelectedData = new FortNightStmtDto();
            this.fortnightStatementSmrySelectedData = JSON.parse(localStorage.getItem('fortnightStatementSmryData'));
            this.additionDatatableDataList = [];
            this.additionDatatableDataList = this.fortnightStatementSmrySelectedData.stmtDtos;
            this.deductionDatatableDataList = [];
            this.deductionDatatableDataList = this.fortnightStatementSmrySelectedData.stmtDtos1;
            this.recAmt = this.fortnightStatementSmrySelectedData.receivableAmt;
            this.dueAmt = +(this.fortnightStatementSmrySelectedData.balanceAmt) - (this.fortnightStatementSmrySelectedData.receivableAmt);
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            setTimeout(() => {
                this.getPendingStmtDetails();
            }, 500);
        }

    }

    ngOnInit(): void {
        //first datatable starts 
        this.dtOptionsLrDetails = {

            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                }, 
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //                }
            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalLrs = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalWgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalToPay = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalPaid = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total : ');
                $(api.column(1).footer()).html(data.length);
                $(api.column(2).footer()).html(totalLrs);
                $(api.column(3).footer()).html(totalWgt);
                $(api.column(4).footer()).html(totalToPay);
                $(api.column(5).footer()).html(totalPaid);
            }
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
        },
            //first datatable ends
            //second datatable starts 
            this.dtOptionAdditionDatatable = {
                dom: 'Bfrtip',
                /*buttons: [
                          'excel', 'print'
                      ],*/
                buttons: [
                    //                    {
                    //                        extend: 'excel',
                    //                        text: '<i class="fas fa-file-excel"> Excel</i>',
                    //                        titleAttr: 'Excel',
                    //                        exportOptions: {
                    //                            columns: ':visible'
                    //                        }
                    //                    },
                    //                    {
                    //                        extend: 'print',
                    //                        text: '<i class="fas fa-print"> Print</i>',
                    //                        titleAttr: 'Print',
                    //                    }
                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 120,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var total = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(total);
                }
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends
            },
            //second datatable ends
            //third datatable starts 
            this.dtOptionDeductionDatatable = {
                dom: 'Bfrtip',
                /*buttons: [
                          'excel', 'print'
                      ],*/
                buttons: [
                    //                    {
                    //                        extend: 'excel',
                    //                        text: '<i class="fas fa-file-excel"> Excel</i>',
                    //                        titleAttr: 'Excel',
                    //                        exportOptions: {
                    //                            columns: ':visible'
                    //                        }
                    //                    },
                    //                    {
                    //                        extend: 'print',
                    //                        text: '<i class="fas fa-print"> Print</i>',
                    //                        titleAttr: 'Print',
                    //                    }
                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 120,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var total = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(2).footer()).html(total);
                }
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends
            }

    }




    ngOnDestroy(): void {
        this.dtTriggerLrDetails.unsubscribe();
        this.dtTriggerAdditionDatatable.unsubscribe();
        this.dtTriggerDeductionDatatable.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerLrDetails.next();
        this.dtTriggerAdditionDatatable.next();
        this.dtTriggerDeductionDatatable.next();
    }

    getUserValues() {
        this.fortNightStmtDtoUserValues = new FortNightStmtDto();
        this.fortNightStmtDtoUserValues.stmtId = this.fortnightStatementSmrySelectedData.stmtId;
        this.fortNightStmtDtoUserValues.companyId = this.userDataDtoReturnSession.companyId;
    }
    getPendingStmtDetails = () => {
        this.showSpinnerForAction = true;
        this.getUserValues();
        this.destStmtReport.getFortNightInvoice(this.fortNightStmtDtoUserValues).subscribe(
            (response) => {
                $("#" + this.pageId + "destStmtDetailsId").DataTable().destroy();
                this.lrDetailsDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "No record",
                        text: "No Details found!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.lrDetailsDataList = response;
                }
                setTimeout(() => {
                    this.dtTriggerLrDetails.next();
                }, 500);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Statement Details", "info");
            }, () => console.log('done');
    };

    saveStmtDetails(mode, content1PopUpTemplate) {
        this.trxType = null;
        if (mode == "Finished") {
            this.trxType = "Sure U Want to Finish this statement?";
        } else {
            this.trxType = "Sure U Want to Update the changes?";
        }
        swal({
            title: "Confirm Changes",
            text: this.trxType,
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.saveUpdateStmtDetails(mode, content1PopUpTemplate);
            }
        });
    }

    updateStmtUserValues(mode) {
        this.fortNightStmtDtoUpdate = new FortNightStmtDto();
        this.fortNightStmtDtoUpdate.destination = this.fortnightStatementSmrySelectedData.destination;
        this.fortNightStmtDtoUpdate.stmtId = this.fortnightStatementSmrySelectedData.stmtId;
        this.fortNightStmtDtoUpdate.receivableAmt = this.recAmt;
        this.fortNightStmtDtoUpdate.prevRecAmt = this.fortnightStatementSmrySelectedData.receivableAmt;
        this.fortNightStmtDtoUpdate.mode = mode;
        this.fortNightStmtDtoUpdate.updatedBy = this.userDataDtoReturnSession.userId;
        this.fortNightStmtDtoUpdate.autoId = this.fortnightStatementSmrySelectedData.autoId;
        this.fortNightStmtDtoUpdate.stmtDtos = this.getAdditionValues();
        this.fortNightStmtDtoUpdate.stmtDtos1 = this.getDeductionValues()
        this.fortNightStmtDtoUpdate.companyId = this.userDataDtoReturnSession.companyId;
        this.fortNightStmtDtoUpdate.commReduced = this.actDedAmt;
    }

    getAdditionValues() {
        this.actDedAmt = 0;
        this.additionDatatableDataListUpdated = [];
        for (let i = 0; i < this.additionDatatableDataList.length; i++) {
            this.fortNightStmtDtoAddDed = new FortNightStmtDto();
            this.fortNightStmtDtoAddDed.description = this.additionDatatableDataList[i].description;
            this.fortNightStmtDtoAddDed.totalAmount = this.additionDatatableDataList[i].totalAmount;
            this.additionDatatableDataListUpdated.push(this.fortNightStmtDtoAddDed);

            // newly added for actprevCommDed
            if (this.additionDatatableDataList[i].description != null
                && this.additionDatatableDataList[i].description == "Commission Deduction") {
                this.actDedAmt = this.additionDatatableDataList[i].totalAmount;
            }
        }
        return this.additionDatatableDataListUpdated;
    }

    getDeductionValues() {
        this.deductionDatatableDataListUpdated = [];
        for (let i = 0; i < this.deductionDatatableDataList.length; i++) {
            this.fortNightStmtDtoAddDed = new FortNightStmtDto();
            this.fortNightStmtDtoAddDed.description = this.deductionDatatableDataList[i].description;
            this.fortNightStmtDtoAddDed.totalAmount = this.deductionDatatableDataList[i].totalAmount;
            this.deductionDatatableDataListUpdated.push(this.fortNightStmtDtoAddDed);
        }
        return this.deductionDatatableDataListUpdated;
    }


    saveUpdateStmtDetails = (mode, content1PopUpTemplate) => {
        this.updateStmtUserValues(mode);
        this.showSpinnerForAction = true;
        this.destStmtReport.updateFortNightStmt(this.fortNightStmtDtoUpdate).subscribe((data) => {
            this.showSpinnerForAction = false;
            this.fortNightStmtDtoReturn = data;
            if (this.fortNightStmtDtoReturn.status == "Success") {
                swal("Success", "Agent statement details Saved/Updated Successfully", "success");

                /* New Edit Code After Email & Print */
                swal({
                    title: "Print FortNight Statement Report",
                    text: "Sure U Want to Print the Fortnight Statement Of "
                        + this.fortNightStmtDtoUpdate.destination + " LR Summary",
                    icon: "info",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((sureYesBtn) => {
                    if (sureYesBtn) {
                        this.setFortnightStmtOfLRSummaryPrint(content1PopUpTemplate);
                    } else {
                        this.setFortnightStmtOfLRDetailsPrint(content1PopUpTemplate);
                    }
                });

                if (mode == "Finished") {
                    this.sendMultipltSMSMethod();
                }

                //delaying the redirection to execute the sms method
                /*setTimeout(() => {
                    this.showSpinnerForAction = false;
                    this.router.navigate(['/dashboard/classic']);
                }, 1000);*/
            } else {
                this.showSpinnerForAction = false;
                swal("Error", "Error While Saving the Agent statement details", "warning");
            }
            this.changeDetectorRef.detectChanges();
        }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While saving the Agent Statement Details", "info");
            }, () => console.log('done');
    };

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    rowSelectedEditAddition(additionDatatableData, index) {
        $("#" + this.pageId + "description").val(additionDatatableData.description);
        $("#" + this.pageId + "value").val(additionDatatableData.totalAmount);
        $("#" + this.pageId + "commonDescription").val("Addition");
        this.descReadOnly = true;
        this.descIsSelectedAdd = true;
        this.selectedIndexAdd = index;
    }
    rowSelectedEditDeduction(deductionDatatableData, index) {
        $("#" + this.pageId + "description").val(deductionDatatableData.description);
        $("#" + this.pageId + "value").val(deductionDatatableData.totalAmount);
        $("#" + this.pageId + "commonDescription").val("Deduction");
        this.descReadOnly = true;
        this.descIsSelectedDed = true;
        this.selectedIndexDed = index;
    }

    addInTable() {
        if (($("#" + this.pageId + "description").val() == null) || ($("#" + this.pageId + "description").val() == undefined) || ($("#" + this.pageId + "description").val() == "") ||
            ($("#" + this.pageId + "value").val() == null) || ($("#" + this.pageId + "value").val() == undefined) || ($("#" + this.pageId + "value").val() == "")) {
            swal("Mandatory Field", "Please enter the details to proceed!", "warning");
            return false;
        } else {

            if ($("#" + this.pageId + "commonDescription").val() == "Addition") {
                this.addInTableAddition();
            } else {
                this.addInTableDeduction();
            }
        }
    }

    addInTableAddition() {
        this.fortNightStmtDtoAddDed = new FortNightStmtDto();
        this.enteredDesc = $("#" + this.pageId + "description").val();
        this.enteredValue = $("#" + this.pageId + "value").val();
        if (this.descIsSelectedAdd == false) {
            for (let i = 0; i < this.additionDatatableDataList.length; i++) {
                if (this.enteredDesc.toUpperCase() == this.additionDatatableDataList[i].description.toUpperCase()) {
                    swal("Not Allowed", "Entered description already exists!", "info");
                    return;
                }
            }
            this.fortNightStmtDtoAddDed.description = this.enteredDesc;
            this.fortNightStmtDtoAddDed.totalAmount = this.enteredValue;
            this.additionDatatableDataList.push(this.fortNightStmtDtoAddDed);

            $("#" + this.pageId + "additionTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerAdditionDatatable.next();
                this.totalAmtToBePaid();
                this.clearAddDedFields();
            }, 500);
        } else {
            this.additionDatatableDataList.splice(this.selectedIndexAdd, 1);
            this.fortNightStmtDtoAddDed.description = this.enteredDesc;
            this.fortNightStmtDtoAddDed.totalAmount = this.enteredValue;
            this.additionDatatableDataList.push(this.fortNightStmtDtoAddDed);

            $("#" + this.pageId + "additionTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerAdditionDatatable.next();
                this.totalAmtToBePaid();
                this.clearAddDedFields();
            }, 500);
        }
    }

    addInTableDeduction() {
        this.fortNightStmtDtoAddDed = new FortNightStmtDto();
        this.enteredDesc = $("#" + this.pageId + "description").val();
        this.enteredValue = $("#" + this.pageId + "value").val();
        if (this.descIsSelectedDed == false) {
            for (let i = 0; i < this.deductionDatatableDataList.length; i++) {
                if (this.enteredDesc.toUpperCase() == this.deductionDatatableDataList[i].description.toUpperCase()) {
                    swal("Not Allowed", "Entered description already exists!", "info");
                    return;
                }
            }
            this.fortNightStmtDtoAddDed.description = this.enteredDesc;
            this.fortNightStmtDtoAddDed.totalAmount = this.enteredValue;
            this.deductionDatatableDataList.push(this.fortNightStmtDtoAddDed);

            $("#" + this.pageId + "deductionTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerDeductionDatatable.next();
                this.totalAmtToBePaid();
                this.clearAddDedFields();
            }, 500);
        } else {
            this.deductionDatatableDataList.splice(this.selectedIndexDed, 1);
            this.fortNightStmtDtoAddDed.description = this.enteredDesc;
            this.fortNightStmtDtoAddDed.totalAmount = this.enteredValue;
            this.deductionDatatableDataList.push(this.fortNightStmtDtoAddDed);

            $("#" + this.pageId + "deductionTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerDeductionDatatable.next();
                this.totalAmtToBePaid();
                this.clearAddDedFields();
            }, 500);
        }
    }

    rowSelectedDeleteAddition(additionDatatableData, index) {
        swal({
            title: "Confirm remove",
            text: "Sure U Want to remove the selected entry?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((btnConfirm) => {
            if (btnConfirm) {
                this.additionDatatableDataList.splice(index, 1);
                $("#" + this.pageId + "additionTableId").DataTable().destroy();
                setTimeout(() => {
                    this.dtTriggerAdditionDatatable.next();
                    this.totalAmtToBePaid();
                    this.clearAddDedFields();
                }, 500);
            }
        });
    }

    rowSelectedDeleteDed(deductionDatatableData, index) {
        swal({
            title: "Confirm remove",
            text: "Sure U Want to remove the selected entry?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((btnConfirm) => {
            if (btnConfirm) {
                this.deductionDatatableDataList.splice(index, 1);
                $("#" + this.pageId + "deductionTableId").DataTable().destroy();
                setTimeout(() => {
                    this.dtTriggerDeductionDatatable.next();
                    this.totalAmtToBePaid();
                    this.clearAddDedFields();
                }, 500);
            }
        });
    }

    totalAmtToBePaid() {
        this.totAddition = 0;
        this.totDed = 0;
        this.recAmt = 0;
        this.dueAmt = 0;
        for (let i = 0; i < this.additionDatatableDataList.length; i++) {
            this.totAddition += +this.additionDatatableDataList[i].totalAmount;
        }
        for (let i = 0; i < this.deductionDatatableDataList.length; i++) {
            this.totDed += +this.deductionDatatableDataList[i].totalAmount;
        }

        this.recAmt = +(this.totAddition) - (this.totDed);
        this.dueAmt = +(this.fortnightStatementSmrySelectedData.balanceAmt) - (this.recAmt);

    }

    clearAddDedFields() {
        this.enteredDesc = null;
        this.enteredValue = null;
        $("#" + this.pageId + "description").val("");
        $("#" + this.pageId + "value").val("");
        $("#" + this.pageId + "commonDescription").val("Addition");
        this.descReadOnly = false;
        this.descIsSelectedAdd = false;
        this.selectedIndexAdd = null;
        this.descIsSelectedDed = false;
        this.selectedIndexDed = null;
        this.totAddition = 0;
        this.totDed = 0;
        this.actDedAmt = 0;
        //this.recAmt=0;
        //this.dueAmt=0;
    }

    sendMultipltSMSMethod = () => {
        this.lrdtoSendMultipltSMSMethodList = [];
        this.lrdtoSendMultipltSMSMethod = new LRDto();
        this.msgContent = "Your Stmnt frm "
            + this.fortnightStatementSmrySelectedData.fromPeriodStr
            + " to "
            + this.fortnightStatementSmrySelectedData.toPeriodStr
            + " has been generated for Rs."
            + this.recAmt
            + ". Kindly make the payment on or before due date to avoid the commission deduction";
        this.lrdtoSendMultipltSMSMethod.message = this.msgContent;
        this.lrdtoSendMultipltSMSMethod.mobileNum = this.fortnightStatementSmrySelectedData.phoneNum;
        this.lrdtoSendMultipltSMSMethodList.push(this.lrdtoSendMultipltSMSMethod);

        this.lrdtoSendMultipltSMSMethodSet = new LRDto();
        this.lrdtoSendMultipltSMSMethodSet.lrDtos = this.lrdtoSendMultipltSMSMethodList;
        this.lrdtoSendMultipltSMSMethodSet.companyId = this.userDataDtoReturnSession.companyId;
        this.lrdtoSendMultipltSMSMethodSet.credentials = this.userDataDtoReturnSession.credentials;
        this.hireslipService.sendMultipltSMS(this.lrdtoSendMultipltSMSMethodSet).subscribe(
            (response) => {
                let sendMultipltSMSMethodRet = response;
                console.log(sendMultipltSMSMethodRet.status);
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting Send Multiple sms Status ", "info");
            }, () => console.log('done');
    };

    hireslipDialog(lrDetailsData): void {
        this.selectedRowInvoiceNumber = lrDetailsData.invoiceNumber;
        this.getLrDetails();
    }
    getLrDetails() {
        this.lrDtoForDetails = new LRDto();
        this.lrDtoForDetails.invoiceNumber = this.selectedRowInvoiceNumber;
        this.lrDtoForDetails.destination = this.fortnightStatementSmrySelectedData.destination;
        this.lrDtoForDetails.mode = 'SpecificStmt';
        this.lrDtoForDetails.companyId = this.userDataDtoReturnSession.companyId;
        this.getLrDetailsInfo();
    }
    getLrDetailsInfo = () => {
        this.showSpinnerForAction = true;
        this.destStmtReport.getStmtVerificationDetailsLrs(this.lrDtoForDetails).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {

                    this.lrDtoForRemMos = new LRDto();
                    this.responseForRemMos = response;
                    this.lrDtoForRemMos = this.responseForRemMos;
                    this.lrDtoForPopup = new LRDto();
                    this.lrDtoForPopup.invoiceNumber = this.selectedRowInvoiceNumber;
                    localStorage.clear();
                    localStorage.setItem('transitLrsPopup',
                        JSON.stringify(this.lrDtoForRemMos));
                    localStorage.setItem('transitLrsInvoiceNumberPopup',
                        JSON.stringify(this.lrDtoForPopup));

                    const dialogRef = this.dialog.open(LrDetailsComponent, {

                    });

                    // dialogRef.afterClosed().subscribe(result => {
                    //     this.router.navigate(['.'], { relativeTo: this.route });
                    // });

                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting LR Details", "info");
            }, () => console.log('done');
    };

    /* @Author Maaz 16/06/2021 */

    /* for print of lr details start*/
    printStmtDetails() {
        //this.setFortnightStmtOfLRDetailsPrint();
        //this.setFortnightStmtOfLRSummaryPrint();
    }

    setFortnightStmtOfLRDetailsPrint(content1PopUpTemplate) {
        swal({
            title: "Print FortNight Statement Report",
            text: "Sure U Want to Print the Fortnight Statement Of "
                + this.fortNightStmtDtoUpdate.destination + " LR Details",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            /* if yes start */
            if (sureYesBtn) {
                console.log(this.fortnightStatementSmrySelectedData);
                this.lrDtoForLRDetailsPrint = new LRDto();
                this.fromPriodForPrint = null;
                this.toPriodForPrint = null;

                this.lrDtoForLRDetailsPrint.fortNightStmtNo = this.fortnightStatementSmrySelectedData.stmtId;
                this.lrDtoForLRDetailsPrint.destination = this.fortnightStatementSmrySelectedData.destination;

                this.fromPriodForPrint = this.fortnightStatementSmrySelectedData.fromPeriod;
                this.toPriodForPrint = this.fortnightStatementSmrySelectedData.toPeriod;

                this.lrDtoForLRDetailsPrint.fromDate = this.fromPriodForPrint;
                this.lrDtoForLRDetailsPrint.toDate = this.toPriodForPrint;
                this.lrDtoForLRDetailsPrint.reportMode = "BookService";
                this.lrDtoForLRDetailsPrint.mode = "destination";
                this.lrDtoForLRDetailsPrint.companyId = this.userDataDtoReturnSession.companyId;
                this.showSpinnerForAction = true;
                this.destStmtReport.getBookingServiceTax(this.lrDtoForLRDetailsPrint).subscribe(
                    (response) => {
                        console.log(response);
                        this.fortnightStmtOfLrDetailsPrint = [];
                        if (response.length > 0) {
                            this.fortnightStmtOfLrDetailsPrint = response;
                            this.setPrintDataOfLRDetailsPopup(this.fortnightStmtOfLrDetailsPrint, content1PopUpTemplate);
                        }
                        this.showSpinnerForAction = false;
                        this.changeDetectorRef.detectChanges();
                    }), (error) => {
                        this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred  While Statement Report LR Details", "info");
                    }, () => console.log('done');
            }/* if yes end */else {
                console.log("No Print of LR Details");
                swal({
                    title: "Send Email",
                    text: "Do You Want to Send An Email of Statement & LR Details Report?",
                    icon: "info",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((btnYesConfirmEmail) => {
                    if (btnYesConfirmEmail) {
                        this.showEmailPopUp(content1PopUpTemplate);
                    } else {
                        setTimeout(() => {
                            this.router.navigate(['/dashboard/classic']);
                        }, 1000);
                    }
                });
            }
        });
    }

    setPrintDataOfLRDetailsPopup(fortnightStmtOfLrDetailsPrint, content1PopUpTemplate) {
        if (fortnightStmtOfLrDetailsPrint.length == 0) {
            swal({
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = [
                "LR No", "Destination", "Art No", "Consignee Name",
                "Invoice No", "Invoice Date", "To Pay", "Paid", "Act Wgt",
                "Chg Wgt", "Serv Tax"];

            this.cashMemoDtoForCustomPrintListColumnWidths = [8, 10, 8, 15, 12, 12, 7, 7, 7, 7, 7];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.summary1 = this.summary2 = this.summary3 = this.summary4 = this.summary5 = this.summary6 = 0;
            for (let i = 0; i < fortnightStmtOfLrDetailsPrint.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [
                    fortnightStmtOfLrDetailsPrint[i].lrNumber,
                    fortnightStmtOfLrDetailsPrint[i].destination,
                    fortnightStmtOfLrDetailsPrint[i].totalArticles,
                    fortnightStmtOfLrDetailsPrint[i].consigneeName,
                    fortnightStmtOfLrDetailsPrint[i].invoiceNumber,
                    fortnightStmtOfLrDetailsPrint[i].invoiceDateStr,
                    fortnightStmtOfLrDetailsPrint[i].toPay,
                    fortnightStmtOfLrDetailsPrint[i].paid,
                    fortnightStmtOfLrDetailsPrint[i].actualWeight,
                    fortnightStmtOfLrDetailsPrint[i].chargedWeight,
                    fortnightStmtOfLrDetailsPrint[i].servicetax,
                ];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.summary1 = this.summary1 + fortnightStmtOfLrDetailsPrint[i].totalArticles;
                this.summary2 = this.summary2 + fortnightStmtOfLrDetailsPrint[i].toPay;
                this.summary3 = this.summary3 + fortnightStmtOfLrDetailsPrint[i].paid;
                this.summary4 = this.summary4 + fortnightStmtOfLrDetailsPrint[i].actualWeight;
                this.summary5 = this.summary5 + fortnightStmtOfLrDetailsPrint[i].chargedWeight;
            }

            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " +
                fortnightStmtOfLrDetailsPrint.length, "",
            this.summary1, "", "", "", this.summary2, this.summary3, this.summary4, this.summary5, ""];
            //heading logics For Date
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Statement No", "Destination"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.fortnightStatementSmrySelectedData.stmtId, this.fortnightStatementSmrySelectedData.destination];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.fortnightStatementSmrySelectedData.fromPeriodStr, this.fortnightStatementSmrySelectedData.toPeriodStr];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Statement Report LR Details");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                    //this.getSeperateStmtStationsDetails(content1PopUpTemplate);
                    swal({
                        title: "Send Email",
                        text: "Do You Want to Send An Email of Statement & Service Tax Report?",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((btnYesConfirmEmail) => {
                        if (btnYesConfirmEmail) {
                            this.showEmailPopUp(content1PopUpTemplate);
                        }
                    });
                }
            });
        }
    }

    /* for print of lr details end*/

    /* for print of lr summary start */

    setFortnightStmtOfLRSummaryPrint(content1PopUpTemplate) {
        this.statementReportLrAdditionDataListSummReturn = [];
        this.statementReportLrAdditionDataListSummReturn = this.setStatementRptLrDetlsAddition();

        this.statementReportLrDeductionDataListSummReturn = [];
        this.statementReportLrDeductionDataListSummReturn = this.setStatementRptLrDetlsDeduction();

        localStorage.clear();
        localStorage.setItem('stmtRptLrDtlsAdditionPrintLocalStorage',
            JSON.stringify(this.statementReportLrAdditionDataListSummReturn));

        localStorage.setItem('stmtRptLrDtlsDeductionPrintLocalStorage',
            JSON.stringify(this.statementReportLrDeductionDataListSummReturn));

        localStorage.setItem('stmtRptLrDtlsGeneralPrintLocalStorage',
            JSON.stringify(this.fortnightStatementSmrySelectedData));

        this.showSpinnerForAction = true;
        this.viewStatementRptLRDetlsPrint = true;

        let top = document.getElementById('mainPageOfFortnightStmtDtlsId');
        if (top !== null) {
            top.scrollIntoView();
            top = null;
        }

        window.addEventListener('afterprint', (onclick) => {
            if (this.viewStatementRptLRDetlsPrint) {
                this.viewStatementRptLRDetlsPrint = false;
                this.showSpinnerForAction = false;
                localStorage.clear();
                this.setFortnightStmtOfLRDetailsPrint(content1PopUpTemplate);
            }
        });
    }

    setStatementRptLrDetlsAddition() {
        console.log(this.additionDatatableDataList);
        this.statementReportLrAdditionDataListSumm = [];
        if (this.additionDatatableDataList != null &&
            this.additionDatatableDataList != undefined &&
            this.additionDatatableDataList.length > 0) {
            for (let i = 0; i < this.additionDatatableDataList.length; i++) {
                this.additionValueLRDto = new LRDto();
                this.additionValueLRDto.description = this.additionDatatableDataList[i].description;
                this.additionValueLRDto.totalAmount = this.additionDatatableDataList[i].totalAmount;
                this.statementReportLrAdditionDataListSumm.push(this.additionValueLRDto);
            }
        }
        return this.statementReportLrAdditionDataListSumm;
    }

    setStatementRptLrDetlsDeduction() {
        this.statementReportLrDeductionDataListSumm = [];
        if (this.deductionDatatableDataList != null &&
            this.deductionDatatableDataList != undefined &&
            this.deductionDatatableDataList.length > 0) {
            for (let i = 0; i < this.deductionDatatableDataList.length; i++) {
                this.deductionValueLRDto = new LRDto();
                this.deductionValueLRDto.description = this.deductionDatatableDataList[i].description;
                this.deductionValueLRDto.totalAmount = this.deductionDatatableDataList[i].totalAmount;
                this.statementReportLrDeductionDataListSumm.push(this.deductionValueLRDto);
            }
        }
        return this.statementReportLrDeductionDataListSumm;
    }

    /* for print of lr summary end */

    /* for email logic start here */

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    showEmailPopUp(content1PopUpTemplate) {
        this.modalRefferenceContent1PopUp = this.modalService.open(content1PopUpTemplate,
            { centered: true, size: "sm" });
        this.modalRefferenceContent1PopUp.result.then((result) => {
            this.modalRefferenceContent1ClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceContent1ClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });

        /* set email id in filed on while popup open */
        //alert(this.fortnightStatementSmrySelectedData.emailId);
        $("#fsdcmultipleEmailsId").val(this.fortnightStatementSmrySelectedData.emailId);
        //$("#"+this.pageId+"multipleEmailsId").val('');

        this.getStatementDetailRptSummary();
    }

    closeFortnightStmtPopUpBtn() {
        this.modalRefferenceContent1PopUp.close();

        setTimeout(() => {
            this.router.navigate(['/dashboard/classic']);
        }, 1000);
    }

    sendFortnightStmtPopUpBtn() {
        if ($("#" + this.pageId + "multipleEmailsId").val() == null || $("#" + this.pageId + "multipleEmailsId").val() == undefined ||
            $("#" + this.pageId + "multipleEmailsId").val() == "") {
            swal("Mandatory Field", "Please enter the Email Id ", "warning");
            return false;
        } else {
            this.sendEmail();
        }
    }

    sendEmail() {
        this.fromPriodForPrint = null;
        this.toPriodForPrint = null;
        this.lrDtoForGeneralEmail = new LRDto();
        this.hireslipDtoServTaxValueForFileCreation = new HireSlipDto();

        /* Email Content Start */
        this.lrDtoForGeneralEmail.messageDto = this.getEmailContent();
        /* Email Content End */

        this.fromPriodForPrint = this.fortnightStatementSmrySelectedData.fromPeriod;
        this.toPriodForPrint = this.fortnightStatementSmrySelectedData.toPeriod;

        /* Hireslip & Rewarding File Creation Details Start*/
        this.hireslipDtoServTaxValueForFileCreation.fromDate = this.fromPriodForPrint;
        this.hireslipDtoServTaxValueForFileCreation.toDate = this.toPriodForPrint;
        this.hireslipDtoServTaxValueForFileCreation.fromStation = "ALL";
        this.hireslipDtoServTaxValueForFileCreation.toStation = this.fortnightStatementSmrySelectedData.destination;
        this.hireslipDtoServTaxValueForFileCreation.companyid = this.userDataDtoReturnSession.companyId

        this.lrDtoForGeneralEmail.hireSlipDto = this.hireslipDtoServTaxValueForFileCreation;
        /* Hireslip File Creation Details End*/

        /* General Details Start*/
        this.lrDtoForGeneralEmail.fortNightStmtNo = this.fortnightStatementSmrySelectedData.stmtId;
        this.lrDtoForGeneralEmail.destination = this.fortnightStatementSmrySelectedData.destination;
        this.lrDtoForGeneralEmail.fromDate = this.fromPriodForPrint;
        this.lrDtoForGeneralEmail.toDate = this.toPriodForPrint;
        this.lrDtoForGeneralEmail.reportMode = "BookService";
        this.lrDtoForGeneralEmail.mode = "destination";
        this.lrDtoForGeneralEmail.companyId = this.userDataDtoReturnSession.companyId;

        this.lrDtoForGeneralEmail.address = this.userDataDtoReturnSession.addressId;
        this.lrDtoForGeneralEmail.branchPhNo = this.userDataDtoReturnSession.phoneEditCompany;
        this.lrDtoForGeneralEmail.branchEmailId = this.userDataDtoReturnSession.cmpEmailId;
        this.lrDtoForGeneralEmail.faxNo = this.userDataDtoReturnSession.faxNo;
        this.lrDtoForGeneralEmail.stmtDateStr = this.fortnightStatementSmrySelectedData.stmtDateStr;
        this.lrDtoForGeneralEmail.stmtFromToPeriod =
            this.fortnightStatementSmrySelectedData.fromPeriodStr + " - " +
            this.fortnightStatementSmrySelectedData.toPeriodStr;
        this.lrDtoForGeneralEmail.totalLrs = this.summaryTotalLrs;
        this.lrDtoForGeneralEmail.imgURLTDS = this.userDataDtoReturnSession.ip;
        /* General Details End*/

        /* Statement Rpt Summary Start */
        this.lrDtoForGeneralEmail.lrDtos = this.lrDtoForHashmapList;
        /* Statement Rpt Summary End */

        /* Statement Rpt Addition & Deduction getting value of 
        while finish btn click getting Summary Start */
        this.lrDtoForGeneralEmail.fortNightStmtDto = this.fortNightStmtDtoUpdate;
        /* Statement Rpt Addition & Deduction getting value of 
        while finish btn click getting Summary End */

        this.showSpinnerForAction1 = true;
        console.log(this.lrDtoForGeneralEmail);
        this.destStmtReport.sendEmailDetails(this.lrDtoForGeneralEmail).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction1 = false;
                if (response[0].status != null && response[0].status == "sent") {
                    this.modalRefferenceContent1PopUp.close();
                    swal("Email", "Email Sent Successfully", "success");
                    setTimeout(() => {
                        this.router.navigate(['/dashboard/classic']);
                    }, 1000);
                } else if (response[0].status != null && response[0].status == "failed") {
                    swal("Email", "Email Failed To Sent", "warning");
                } else {
                    swal("Email", "Email Failed To Sent", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction1 = false;
                swal("Error", "Server Problem Occurred  While Send Email Details", "error");
            }, () => console.log('done');
    }

    getEmailContent() {
        this.messageDto = new MessageDto();
        this.emailToList = [];
        this.emailCcList = [];
        this.emailBccList = [];
        this.messageDto.fromEmailId = "accountsadmin@srdlogistics.com";
        this.messageDto.fromEmailPass = "Sahaba1985";
        this.messageDto.emailSubject = this.fortnightStatementSmrySelectedData.destination +
            " - Statement for the Period " + this.fortnightStatementSmrySelectedData.fromPeriodStr +
            " To " + this.fortnightStatementSmrySelectedData.toPeriodStr;
        this.messageDto.emailContent = "Kindly find the Statement enclosed with this mail. Please clear the statement on time to avoid reduce commission charges";
        this.messageDto.emailCcList = this.emailCcList;
        this.messageDto.emailBccList = this.emailBccList;
        var splitEmailId = [];
        this.emailsTextValue = $("#" + this.pageId + "multipleEmailsId").val();
        splitEmailId = this.emailsTextValue.split(",");
        for (let i = 0; i < splitEmailId.length; i++) {
            this.emailToList.push(splitEmailId[i]);
        }
        this.messageDto.emailToList = this.emailToList;
        return this.messageDto;
    }

    /* for email logic start end */


    getStatementDetailRptSummary() {
        this.lrDtoForLRDetailsPrint = new LRDto();
        this.fromPriodForPrint = null;
        this.toPriodForPrint = null;

        this.lrDtoForLRDetailsPrint.fortNightStmtNo = this.fortnightStatementSmrySelectedData.stmtId;
        this.lrDtoForLRDetailsPrint.destination = this.fortnightStatementSmrySelectedData.destination;

        this.fromPriodForPrint = this.fortnightStatementSmrySelectedData.fromPeriod;
        this.toPriodForPrint = this.fortnightStatementSmrySelectedData.toPeriod;

        this.lrDtoForLRDetailsPrint.fromDate = this.fromPriodForPrint;
        this.lrDtoForLRDetailsPrint.toDate = this.toPriodForPrint;
        this.lrDtoForLRDetailsPrint.reportMode = "BookService";
        this.lrDtoForLRDetailsPrint.mode = "destination";
        this.lrDtoForLRDetailsPrint.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction1 = true;
        this.destStmtReport.getBookingServiceTax(this.lrDtoForLRDetailsPrint).subscribe(
            (response) => {
                this.showSpinnerForAction1 = false;
                console.log(response);
                this.fortnightStmtOfLrDetailsPrint = [];
                this.fortnightStmtOfLrDetailsPrint = response;
                this.setHashMapValues();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction1 = false;
                swal("Error", "Server Problem Occurred  While Statement Report LR Details", "info");
            }, () => console.log('done');
    }

    setHashMapValues() {
        console.log(this.fortnightStmtOfLrDetailsPrint);
        this.summaryTotalLrs = 0;
        this.summaryTotalActWgt = 0;
        this.summaryTotalAmt = 0;
        this.summaryTotalPaid = 0;
        this.summaryTotalServTax = 0;
        for (let i = 0; i < this.fortnightStmtOfLrDetailsPrint.length; i++) {
            this.summaryTotalLrs = +this.summaryTotalLrs + +1;
            this.summaryTotalActWgt = +this.summaryTotalActWgt + +this.fortnightStmtOfLrDetailsPrint[i].actualWeight;
            this.summaryTotalAmt = +this.summaryTotalAmt + +this.fortnightStmtOfLrDetailsPrint[i].toPay;
            this.summaryTotalPaid = +this.summaryTotalPaid + +this.fortnightStmtOfLrDetailsPrint[i].paid;
            this.summaryTotalServTax = +this.summaryTotalServTax + +this.fortnightStmtOfLrDetailsPrint[i].servicetax;
        }

        var groups = new Set(this.fortnightStmtOfLrDetailsPrint.map(item => item.fromStation).sort());
        this.result = [];
        groups.forEach(g => {
            /* Each Summary Data Value */
            const totalActWgt = this.fortnightStmtOfLrDetailsPrint
                .filter(i => i.fromStation === g)
                .reduce((sum, i) => +sum + +i.actualWeight, 0)
            const totalAmount = this.fortnightStmtOfLrDetailsPrint
                .filter(i => i.fromStation === g)
                .reduce((sum, i) => +sum + +i.toPay, 0)
            const paid = this.fortnightStmtOfLrDetailsPrint
                .filter(i => i.fromStation === g)
                .reduce((sum, i) => +sum + +i.paid, 0)
            const servicetax = this.fortnightStmtOfLrDetailsPrint
                .filter(i => i.fromStation === g)
                .reduce((sum, i) => +sum + +i.servicetax, 0)

            this.result.push({
                fromStation: g,
                values: this.fortnightStmtOfLrDetailsPrint.filter(i => i.fromStation === g),
                actualWeight: totalActWgt,
                totalAmount: totalAmount,
                paid: paid,
                servicetax: servicetax,
            })
        })

        this.result1 = [];
        for (let i = 0; i < this.result.length; i++) {
            this.newAttribute = {};
            this.newAttribute.fromStation = this.result[i].fromStation;
            this.newAttribute.actualWeight = this.result[i].actualWeight;
            this.newAttribute.totalAmount = this.result[i].totalAmount;
            this.newAttribute.paid = this.result[i].paid;
            this.newAttribute.servicetax = this.result[i].servicetax;
            var inv_No = null;
            var newInv_no = null;

            for (let j = 0; j < this.result[i].values.length; j++) {
                if (j == 0) {
                    inv_No = this.result[i].values[j].invoiceNumber;
                } else {
                    inv_No += "," + this.result[i].values[j].invoiceNumber;
                }
            }
            this.newAttribute.invoiceNumber = inv_No;
            this.result1.push(this.newAttribute);
        }
        console.log(this.result1);

        var list = [];
        this.finalResult = [];
        this.lrDtoForHashmapList = [];
        for (let i = 0; i < this.result1.length; i++) {
            var invoicNoSplit = this.result1[i].invoiceNumber.split(",");
            list = invoicNoSplit.filter(function (x, i, a) {
                return a.indexOf(x) == i;
            });
            this.lrDtoForHashmap = new LRDto();
            //this.newAttribute = {};
            this.lrDtoForHashmap.fromStation = this.result1[i].fromStation;
            this.lrDtoForHashmap.actualWeight = this.result1[i].actualWeight;
            this.lrDtoForHashmap.totalAmount = this.result1[i].totalAmount;
            this.lrDtoForHashmap.paid = this.result1[i].paid;
            this.lrDtoForHashmap.servicetax = this.result1[i].servicetax;
            this.lrDtoForHashmap.list = list;
            this.lrDtoForHashmapList.push(this.lrDtoForHashmap);
        }
        console.log(list);
        //return this.lrDtoForHashmapList;
    }

}

