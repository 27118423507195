import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
} from "@angular/core";
import { NgModule, ViewChild } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription } from "rxjs";
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { DashboardService } from "src/app/dataService/dashboard-service";
//from the particular global folder ends
//for datatable ends

//for modal starts
import { Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import swal from "sweetalert";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";

export interface DialogData {
  animal: string;
  name: string;
}
//for modal ends
@Component({
  selector: "app-lr-details-of-remaining-cash-memos",
  templateUrl: "./lr-details-of-remaining-cash-memos.component.html",
  styleUrls: ["./lr-details-of-remaining-cash-memos.component.css"],
})
export class LrDetailsOfRemainingCashMemosComponent implements OnInit {
  gettingDataFrmServiceFrLrDetailsOfRemainingCashmemoTable: any;
  lrDetailsOfRemainingCashmemoDataList: any;
  onDestroyUnsubscribtionLrDetailsOfRemainingCashmemo: Subscription;
  //for datatable ends
  //summaryTable:any;
  loadingIndicator = true;
  //for datatable starts
  @ViewChildren(DataTableDirective)
  public dtElements: QueryList<DataTableDirective>;
  dtTriggerLrDetailsOfRemainingCashmemo: Subject<any> = new Subject();
  dataTable: any;
  dtOptionsLrDetailsOfRemainingCashmemo: any;
  isLoggedIn = true;
  userDataDtoReturnSession: any;
  address: any;
  showSpinnerForAction = false;
  showCol = false;
  dataForPopupFromCashmemoPendAndMissOtherDetails: any;
  dataForPopupFromCashmemoPendFromBinnyOtherDetails: any;
  showColumn = "CashmemoPendMissingAndBinny ShowTypeCol";

  //For Custom Print
  cashMemoDtoForCustomPrintListHeadingV1: any;
  cashMemoDtoForCustomPrintListHeadingV2: any;
  cashMemoDtoForCustomPrintListHeadingV3: any;
  cashMemoDtoForCustomPrintListHeadingV4: any;
  cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
  cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintList: any;
  cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
  cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
  cashMemoDtoForCustomPrintDataList: any;
  cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
  cashMemoDtoForCustomPrintDataSummaryList: any;
  viewCustomPrintV1: boolean;

  artSummary: number;
  memoAmtSummary: number;
  pageId = "lrdrc";

  constructor(
    /* for datatable starts */ private lrDetailsOfRemainingCashmemoScreen: DashboardService,
    private router: Router /* for datatable endss */,
    //for modal starts
    public dialogRef: MatDialogRef<LrDetailsOfRemainingCashMemosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public changeDetectorRef: ChangeDetectorRef
  ) //for modal ends
  {
    console.log(localStorage);

    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      });
    }
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(
        sessionStorage.getItem("SRDWeb")
      );
      this.address =
        this.userDataDtoReturnSession.addressId == null
          ? ""
          : this.userDataDtoReturnSession.addressId;
      this.dataForPopupFromCashmemoPendAndMissOtherDetails = JSON.parse(
        localStorage.getItem("cashmemoPendingAndMissingPopup")
      );
      this.dataForPopupFromCashmemoPendFromBinnyOtherDetails = JSON.parse(
        localStorage.getItem("cashmemoPendingFromBinnyPopup")
      );
      localStorage.clear();
      console.log("1");
      console.log(this.dataForPopupFromCashmemoPendAndMissOtherDetails);
      if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
        //    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
        for (
          let i = 0;
          i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length;
          i++
        ) {
          if (
            this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
            this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
              "CashmemoPendMissingAndBinny ShowTypeCol"
          ) {
            this.showCol = true;
          }
        }
      }
      /* if(this.showColumn=='CashmemoPendMissingAndBinny ShowTypeCol'){
				 this.showCol = true;
			 }else{
				 this.showCol = false;
			 }*/
      ///For CashmemoPendAndMiss
      if (this.dataForPopupFromCashmemoPendAndMissOtherDetails != null) {
        $("#" + this.pageId + "lrDetailsOfRemainingCashmemoId")
          .DataTable()
          .destroy();
        this.lrDetailsOfRemainingCashmemoDataList = [];
        this.lrDetailsOfRemainingCashmemoDataList =
          this.dataForPopupFromCashmemoPendAndMissOtherDetails;
        this.dtTriggerLrDetailsOfRemainingCashmemo.next();
        console.log(this.lrDetailsOfRemainingCashmemoDataList);
      }
      //For CashmemoPendFromBinny
      if (this.dataForPopupFromCashmemoPendFromBinnyOtherDetails != null) {
        $("#" + this.pageId + "lrDetailsOfRemainingCashmemoId")
          .DataTable()
          .destroy();
        this.lrDetailsOfRemainingCashmemoDataList = [];
        this.lrDetailsOfRemainingCashmemoDataList =
          this.dataForPopupFromCashmemoPendFromBinnyOtherDetails;
        this.dtTriggerLrDetailsOfRemainingCashmemo.next();
        console.log(this.lrDetailsOfRemainingCashmemoDataList);
      }

      this.dataForPopupFromCashmemoPendAndMissOtherDetails = [];
      this.dataForPopupFromCashmemoPendFromBinnyOtherDetails = [];
    }
  }
  /* for datatable starts */
  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Do your stuff
        dtInstance.destroy();
      });
    });
  }
  /* for datatable ends */
  ngOnInit(): void {
    var companyAddressDetls = this.address;
    this.dtOptionsLrDetailsOfRemainingCashmemo = {
      dom: "Bfrtip",
      buttons: [
        {
          extend: "print",
          text: '<i class="fas fa-print">Print</i>',
          titleAttr: "Print",
          customize: function (win) {
            $(win.document.body).css("font-size", "10pt");
            $(win.document.body)
              .find("th, td")
              .css("font-family", "Arial, Helvetica, sans-serif")
              .css("font-size", "13px")
              .css("text-align", "center");
          },
          footer: true,

          exportOptions: {
            columns: [0, 2, 3, 4, 5, 6, 9, 10],
          },

          title: function () {
            return (
              "<table style='table-layout: fixed;width: 100%;'><tr>" +
              "<td align='left' style='width: 20%;'>" +
              "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
              "</td>" +
              "<td align='left' style='width: 30%;'>" +
              "</td>" +
              "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
              companyAddressDetls +
              "</td>" +
              "</tr></table>"
            );
          },
        },
        {
          extend: "excel",
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: "Excel",
          footer: true,

          title: function () {
            var returnSummaryExcelCashmemoStockSum = null;
            returnSummaryExcelCashmemoStockSum =
              " Lr Details Of Remainin gCashmemo ";

            return returnSummaryExcelCashmemoStockSum;
          },
        },
      ],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      pagingType: "full_numbers",
      pageLength: 5,

      processing: true,
      scrollX: true,
      scrollCollapse: true,
      paging: false,
      info: false,

      footerCallback: function (row, data, start, end, display) {
        var api = this.api(),
          data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === "string"
            ? +i.replace(/[\$,]/g, "") * 1
            : typeof i === "number"
            ? i
            : 0;
        };

        var totArt = api
          .column(2)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var totalAmt = api
          .column(6)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var totalhamali = api
          .column(7)
          .data()
          .reduce(function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        $(api.column(0).footer()).html("Total : " + data.length);
        $(api.column(1).footer()).html();
        $(api.column(2).footer()).html(totArt);
        $(api.column(3).footer()).html();
        $(api.column(4).footer()).html();
        $(api.column(5).footer()).html();
        $(api.column(6).footer()).html(totalAmt);
        $(api.column(7).footer()).html(totalhamali);
        $(api.column(8).footer()).html();
        $(api.column(9).footer()).html();
        $(api.column(10).footer()).html();
        if (this.showCol == true) {
          $(api.column(11).footer()).html();
          $(api.column(12).footer()).html();
        }
      },
    };
  }

  ngOnDestroy(): void {
    this.dtTriggerLrDetailsOfRemainingCashmemo.unsubscribe();
    //  this.routeQueryParams$.unsubscribe();
    //for modal ends
  }
  ngAfterViewInit(): void {
    this.dtTriggerLrDetailsOfRemainingCashmemo.next();
  }
  //for modal starts
  onCancelClick(): void {
    this.dialogRef.close();
  }
  //for modal ends

  logInPage() {
    this.router.navigate(["/authentication/login"]);
  }

  customPrintConsolInvoiceRpt() {
    if (this.lrDetailsOfRemainingCashmemoDataList.length == 0) {
      swal({
        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnNames = [
        "Memo Number",
        "No Of Articles",
        "LR Number",
        "Consignee",
        "Memo Name",
        "Memo Amount",
      ];
      this.cashMemoDtoForCustomPrintListColumnWidths = [18, 12, 15, 20, 20, 15];
      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListColumnNames.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName =
          this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth =
          this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintDataList = [];
      this.cashMemoDtoForCustomPrintDataSummaryList = [];

      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();

        this.cashMemoDtoForCustomPrint.printHeadingName =
          this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue =
          this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

        this.cashMemoDtoForCustomPrintListHeadingV1.push(
          this.cashMemoDtoForCustomPrint
        );
      }

      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();

        this.cashMemoDtoForCustomPrint.printHeadingName =
          this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue =
          this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

        this.cashMemoDtoForCustomPrintListHeadingV2.push(
          this.cashMemoDtoForCustomPrint
        );
      }

      this.artSummary = this.memoAmtSummary = 0;

      for (
        let i = 0;
        i < this.lrDetailsOfRemainingCashmemoDataList.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrintListColumnValues = [
          this.lrDetailsOfRemainingCashmemoDataList[i].cashMemoNumber,
          this.lrDetailsOfRemainingCashmemoDataList[i].totalArticles,
          this.lrDetailsOfRemainingCashmemoDataList[i].lrNumber,
          this.lrDetailsOfRemainingCashmemoDataList[i].consigneeName,
          this.lrDetailsOfRemainingCashmemoDataList[i].memoName,
          this.lrDetailsOfRemainingCashmemoDataList[i].grandTotal,
        ];

        /////////
        this.artSummary =
          this.artSummary +
          this.lrDetailsOfRemainingCashmemoDataList[i].totalArticles;
        this.memoAmtSummary =
          this.memoAmtSummary +
          this.lrDetailsOfRemainingCashmemoDataList[i].grandTotal;

        /////
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues =
          this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(
          this.cashMemoDtoForCustomPrintData
        );
      }
      this.cashMemoDtoForCustomPrintDataSummaryList = [
        "Total : " + this.lrDetailsOfRemainingCashmemoDataList.length,
        this.artSummary,
        "",
        "",
        "",
        this.memoAmtSummary,
      ];

      for (
        let i = 0;
        i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length;
        i++
      ) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName =
          this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
        this.cashMemoDtoForCustomPrintListHeadingV3.push(
          this.cashMemoDtoForCustomPrint
        );
      }

      localStorage.setItem(
        "printCashMemoDtoForCustomPrintList",
        JSON.stringify(this.cashMemoDtoForCustomPrintList)
      );
      localStorage.setItem(
        "printCashMemoDtoForCustomPrintDataList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintDataSummaryList",
        JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintListHeadingV1",
        JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintListHeadingV2",
        JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintListHeadingV3",
        JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3)
      );
      localStorage.setItem(
        "printcashMemoDtoForCustomPrintListHeadingV4",
        JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4)
      );
      localStorage.setItem("printTitle", "LR Details Of Remaining Cashmemo");
      this.viewCustomPrintV1 = true;

      window.addEventListener("afterprint", (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }
}
