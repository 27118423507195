import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

@Component( {
    selector: 'app-door-delivery',
    templateUrl: './door-delivery.component.html',
    styleUrls: ['./door-delivery.component.css']
} )
export class DoorDeliveryComponent implements OnInit {

    //the destination wise datatable has been on hold so its has been commented 
    getDataPartyDetailsTableDataTable: any;
    getDataTripDetailsDataTable: any;
    getDataVehicleDetails: any;
    getDataLoaderDetails: any;
    getDataServiceFrHamaliDetailsTable: any;


    partyDetailsTableDataList: any;
    tripDetailsDataList: any;
    vehicleDetailsDataList: any;
    loaderDetailsDataList: any;
    hamaliDetailsDataList: any;


    onDestroyUnsubscribtionPartyDetailsTable: Subscription;
    onDestroyUnsubscribtionTripDetails: Subscription;
    onDestroyUnsubscribtionVehicleDetails: Subscription;
    onDestroyUnsubscribtionLoaderDetails: Subscription;
    onDestroyUnsubscribtionHamaliDetails: Subscription;




    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;


    //for the select option with filter starts

    control = new FormControl();

    partyOptions = [
        { id: 1, label: 'All' },
        { id: 2, label: 'K.G. Associates' },
        { id: 3, label: 'KK Enterprises' },
        { id: 4, label: 'KC Enterprises' }
    ];



    //for the select option with filter ends

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerPartyDetailsTable: Subject<any> = new Subject();
    dtTriggerTripDetails: Subject<any> = new Subject();
    dtTriggerVehicleDetails: Subject<any> = new Subject();
    dtTriggerLoaderDetails: Subject<any> = new Subject();
    dtTriggerHamaliDetails: Subject<any> = new Subject();


    dataTable: any;

    dtOptionsPartyDetailsTable: any;
    dtOptionsTripDetails: any;
    dtOptionsVehicleDetails: any;
    dtOptionsLoaderDetails: any;
    dtOptionsHamaliDetails: any;
	pageId="drdlc";
    vehicleDetailsTable = false
    loaderDetailsTable = false

    constructor( private doorDeliveryRpt: ReportService , public changeDetectorRef : ChangeDetectorRef) {


    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }







    ngOnInit(): void {


        //the first datatable starts
        this.dtOptionsPartyDetailsTable = {
            //};
            //columns is used to for export and others starts
            columns: [
                {
                    title: 'Party Name',
                    data: 'partyName'
                },
                {
                    title: 'Articles',
                    data: 'articles'
                },
                {
                    title: 'Amt To SRD',
                    data: 'amtToSrd'
                },
                {
                    title: 'Amt To Hamali',
                    data: 'amtToHamali'
                }
            ],


            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //            {
                //                extend: 'excel',
                //                text: '<i class="fas fa-file-excel"> Excel</i>',
                //                titleAttr: 'Excel',
                //                exportOptions: {
                //                    columns: ':visible'
                //                }
                //            },
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //
                //                }


            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 180,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends                          
        },
            //the first datatable ends
            //            the second datatable starts 
            this.dtOptionsTripDetails = {
                //};
                //columns is used to for export and others starts
                columns: [
                    {
                        title: 'Area',
                        data: 'area'
                    },
                    {
                        title: 'No. Of Articles',
                        data: 'noOfArticles'
                    },
                    {
                        title: 'DD Amount',
                        data: 'ddAmount'
                    }
                ],


                //columns is used to for export and others endss


                // the below code is for button export starts
                dom: 'Bfrtip',
                /*buttons: [
                          'excel', 'print'
                      ],*/
                buttons: [
                    //            {
                    //                extend: 'excel',
                    //                text: '<i class="fas fa-file-excel"> Excel</i>',
                    //                titleAttr: 'Excel',
                    //                exportOptions: {
                    //                    columns: ':visible'
                    //                }
                    //            },
                    //                    {
                    //                        extend: 'print',
                    //                        text: '<i class="fas fa-print"> Print</i>',
                    //                        titleAttr: 'Print',
                    //
                    //                    }


                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 180,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends                          
            },
            //the second datatable ends
            //the third datatable starts            
            this.dtOptionsVehicleDetails = {
                //};
                //columns is used to for export and others starts
                columns: [
                    {
                        title: 'Vehicle Name',
                        data: 'vehicleName'
                    },
                    {
                        title: 'Driver Name',
                        data: 'driverName'
                    },
                    {
                        title: 'No. Of Trips',
                        data: 'noOfTrips'
                    },
                    {
                        title: 'No. Of Articles',
                        data: 'noOfArticles'
                    }
                ],
                //columns is used to for export and others endss
                // the below code is for button export starts
                dom: 'Bfrtip',
                /*buttons: [
                          'excel', 'print'
                      ],*/
                buttons: [
                    //                    {
                    //                        extend: 'print',
                    //                        text: '<i class="fas fa-print"> Print</i>',
                    //                        titleAttr: 'Print',
                    //                    },
                    //                    {
                    //                        extend: 'excel',
                    //                        text: '<i class="fas fa-file-excel"> Excel</i>',
                    //                        titleAttr: 'Excel',
                    //                        exportOptions: {
                    //                            columns: ':visible'
                    //                        }
                    //                    }
                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 180,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends         
            },
            //the third datatable ends
            //the fourth datatable starts
            this.dtOptionsLoaderDetails = {
                //};
                //columns is used to for export and others starts
                columns: [
                    {
                        title: 'Delivery Man',
                        data: 'deliveryMan'
                    },
                    {
                        title: 'No. Of Trips',
                        data: 'noOfTrips'
                    },
                    {
                        title: 'Articles',
                        data: 'articles'
                    },
                    {
                        title: 'DD Amount',
                        data: 'ddAmount'
                    },
                    {
                        title: 'Labour Hamali',
                        data: 'labourHamali'
                    },
                    {
                        title: 'Action',
                        data: 'action'
                    }
                ],
                //columns is used to for export and others endss
                // the below code is for button export starts
                dom: 'Bfrtip',
                /*buttons: [
                          'excel', 'print'
                      ],*/
                buttons: [
                    //                    {
                    //                        extend: 'print',
                    //                        text: '<i class="fas fa-print"> Print</i>',
                    //                        titleAttr: 'Print',
                    //                    }, {
                    //                        extend: 'excel',
                    //                        text: '<i class="fas fa-file-excel"> Excel</i>',
                    //                        titleAttr: 'Excel',
                    //                        exportOptions: {
                    //                            columns: ':visible'
                    //                        }
                    //                    }

                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 180,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends
            },
            //the fourth datatable ends
            //the fifth datatable starts 
            this.dtOptionsHamaliDetails = {
                //};
                //columns is used to for export and others starts
                columns: [
                    {
                        title: 'Hamali Name',
                        data: 'hamaliName'
                    },
                    {
                        title: 'No. Of Trips',
                        data: 'noOfTrips'
                    },
                    {
                        title: 'Articles',
                        data: 'articles'
                    },
                    {
                        title: 'Labour Hamali',
                        data: 'labourHamali'
                    }

                ],
                //columns is used to for export and others endss                    
                // the below code is for button export starts
                dom: 'Bfrtip',
                /*buttons: [
                          'excel', 'print'
                      ],*/
                buttons: [
                    //                    {
                    //                        extend: 'print',
                    //                        text: '<i class="fas fa-print"> Print</i>',
                    //                        titleAttr: 'Print',
                    //
                    //                    }, {
                    //                        extend: 'excel',
                    //                        text: '<i class="fas fa-file-excel"> Excel</i>',
                    //                        titleAttr: 'Excel',
                    //                        exportOptions: {
                    //                            columns: ':visible'
                    //                        }
                    //                    }

                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 180,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends   
            }
        //the fifth datatable ends




        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        //first service starts 
        this.getDataPartyDetailsTableDataTable = this.doorDeliveryRpt.getSummaryData()
        this.onDestroyUnsubscribtionPartyDetailsTable = this.getDataPartyDetailsTableDataTable.subscribe( data => {
            this.partyDetailsTableDataList = data['data'];
            this.dtTriggerPartyDetailsTable.next();
        } );
        //first service ends
        //second service starts
        //first service starts 
        this.getDataTripDetailsDataTable = this.doorDeliveryRpt.getSummaryData()
        this.onDestroyUnsubscribtionTripDetails = this.getDataTripDetailsDataTable.subscribe( data => {
            this.tripDetailsDataList = data['data'];
            this.dtTriggerTripDetails.next();
        } );
        //second service ends
        //third service starts
        this.getDataVehicleDetails = this.doorDeliveryRpt.getSummaryData()
        this.onDestroyUnsubscribtionVehicleDetails = this.getDataVehicleDetails.subscribe( data => {
            this.vehicleDetailsDataList = data['data'];
            this.dtTriggerVehicleDetails.next();
        } );
        //third service ends
        //fourth service starts
        this.getDataLoaderDetails = this.doorDeliveryRpt.getSummaryData()
        this.onDestroyUnsubscribtionLoaderDetails = this.getDataLoaderDetails.subscribe( data => {
            this.loaderDetailsDataList = data['data'];
            this.dtTriggerLoaderDetails.next();
        } );
        //fourth service ends
        //fifth service starts
        this.getDataServiceFrHamaliDetailsTable = this.doorDeliveryRpt.getSummaryData()
        this.onDestroyUnsubscribtionHamaliDetails = this.getDataServiceFrHamaliDetailsTable.subscribe( data => {
            this.hamaliDetailsDataList = data['data'];
            this.dtTriggerHamaliDetails.next();
        } );
        //fifth service ends

    }




    ngOnDestroy(): void {
        this.dtTriggerPartyDetailsTable.unsubscribe();
        this.dtTriggerTripDetails.unsubscribe();
        this.dtTriggerVehicleDetails.unsubscribe();
        this.dtTriggerLoaderDetails.unsubscribe();
        this.dtTriggerHamaliDetails.unsubscribe();


        this.onDestroyUnsubscribtionPartyDetailsTable.unsubscribe();
        this.onDestroyUnsubscribtionTripDetails.unsubscribe();
        this.onDestroyUnsubscribtionVehicleDetails.unsubscribe();
        this.onDestroyUnsubscribtionLoaderDetails.unsubscribe();
        this.onDestroyUnsubscribtionHamaliDetails.unsubscribe();

    }




    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

    //for the select option with filter ends
}
