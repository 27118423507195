<div class="page row system_responsive" id="{{pageId}}stmtReptLrDetailsprintIds">
    <div class="col-md-12">
        <!--first row starts-->
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-4">
                <h6>
                    {{address}}
                </h6>
            </div>
            <div class="col-md-4">
                <h6>
                    Tel: {{phoneNo}} <br> Email:{{emailId}}
                </h6>
            </div>
            <div class="col-md-4">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
            </div>
        </div>
        <!--<br>-->
        <hr>
        <!--first row starts-->
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-sm-12 col-md-12">
                            <h6 style='margin-right: 23px; font-size: 23px; padding-top: 5px;text-align: center'>
                                <strong>Booking Agent Statement Report </strong>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-sm-12 col-md-12">
                            <h6 style='margin-right: 23px;  padding-top: 2px;'>
                                <span style='font-weight:bold;font-size:17px;'>Statement of Account : </span>
                                <!--<strong>{{todayDate}}</strong>-->
                                <span style='font-weight:bold;font-size:17px;'>{{destination}}</span>
                            </h6>
                            <h6 style='margin-right: 23px; padding-top: 2px;'>
                                <span style='font-weight:bold;font-size:17px;'>Period : </span>
                                <span style='font-size:17px;'>{{fromPeriod}} To {{toPeriod}}</span>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="col-md-4">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
							<h6 style='margin-right: 23px; font-size: 17px; padding-top: 30px;'>
								<strong>Statement Report </strong>
							</h6>
						</div>
					</div>
				</div>
			</div>-->
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-sm-12 col-md-12">
                            <h6 style='margin-right: 23px; padding-top: 2px;text-align: right;'>
                                <span style='font-weight:bold;font-size:17px;'>Statement No : </span>
                                <span style='font-size:17px;'>{{stmtNo}}</span>
                            </h6>
                            <h6 style='margin-right: 23px; padding-top: 2px;text-align: right;'>
                                <span style='font-weight:bold;font-size:17px;'>Statement Date : </span>
                                <!--<strong>{{date}}</strong>-->
                                <span style='font-size:17px;'>{{stmtDateStr}}</span>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--<div class="row">
			<div class="col-md-12">
				<h6 style='text-align: center;font-size: large;'>
					<strong><u>{{toStation}} Loading Sheet.</u></strong>
				</h6>
			</div>
		</div>-->

        <!--Second  row starts-->
        <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
            <tr>
                <td width='15%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;border-left: 1px solid black;
					border-top: 1px solid black;' valign='top' type='text'><span>To</span></td>
                <td width='25%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;border-top: 1px solid black;' valign='top' type='number'><span> Total Lrs</span></td>
                <td width='15%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;border-top: 1px solid black;' valign='top' type='number'><span> Total Act.Weight</span></td>
                <td width='15%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;border-top: 1px solid black;' valign='top' type='number'><span> Total Chg.Weight</span></td>
                <td width='15%' align='center' style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;border-top: 1px solid black;' valign='top' type='text'><span> Total LC</span></td>
                <td width='15%' align='center' style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;border-top: 1px solid black;' valign='top' type='text'><span>Total BC</span></td>
            </tr>
            <!--<tr *ngFor="let listOfLrDtoData of listOfLrDtoDataTable">-->
            <tr *ngFor="let item of result">
                <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;border-left: 1px solid black;' valign='top' type='text'>
                    <span> {{item.source}} </span></td>
                <!--<strong>{{listOfLrDtoData.bookingDateStr}}</strong></td>-->
                <td width='25%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;' valign='top' type='number'>
                    <span>{{item.totalLRS}}</span></td>
                <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;' valign='top' type='number'>
                    <span>{{item.actualWeight}} </span></td>
                <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;' valign='top' type='text'>
                    <span>{{item.chargedWeight}} </span></td>
                <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;' valign='top' type='text'>
                    <span> {{item.lcChg}} </span></td>
                <td width='15%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;' valign='top' type='text'>
                    <span>{{item.bcChg}} </span></td>
            </tr>
            <tr>
                <td width='15%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;border-left: 1px solid black;' valign='top' type='text'><span>Total</span></td>
                <td width='25%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;' valign='top' type='number'><span> LRs : {{summaryTotalLrs}}</span></td>
                <td width='15%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size:20px;' valign='top' type='number'><span>{{summaryTotalActWgt}}</span></td>
                <td width='15%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-size:20px;' valign='top' type='number'><span>{{summaryTotalChgWgt}}</span></td>
                <td width='15%' align='center' style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;font-size:20px;' valign='top' type='text'><span>{{summaryTotalLCChg}} </span></td>
                <td width='15%' align='center' style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;font-size:20px;' valign='top' type='text'><span>{{summaryTotalBCChg}} </span></td>
            </tr>
            <tr>
                <td width='100%' align='center' colspan='6' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;border-left: 1px solid black;' valign='top' type='text'><span>Amount Details</span></td>
            </tr>
            <tr>
                <td align='center' colspan='2' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;border-left: 1px solid black;' valign='top' type='text'><span>Description</span></td>
                <td align='center' colspan='2' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;' valign='top' type='number'><span>Credit </span></td>
                <td align='center' colspan='2' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;font-weight:bold;font-size:20px;' valign='top' type='number'><span>Debit</span></td>
            </tr>
            <tr>
                <td align='left' colspan='2' style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;font-weight:bold;font-size:20px;border-left: 1px solid black;' valign='top' type='text'><span style='margin-left: 10px;'><u>Addition:</u></span></td>
                <td align='right' colspan='2' style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;' valign='top' type='number'></td>
                <td align='right' colspan='2' style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;' valign='top' type='number'></td>
            </tr>

            <tr *ngFor="let additionData of additionDataDatailList" style='line-height: 30px;'>
                <td align='left' colspan='2' style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;font-size:20px;border-left: 1px solid black;font-weight: 600;' valign='top' type='text'><span style='margin-left: 30px;'>{{additionData.description}}&nbsp;</span>
                </td>
                <td align='right' colspan='2' style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;font-size:20px;font-weight: 600;' valign='top' type='number'><span style='margin-left: 30px;'>{{additionData.totalAmount}}&nbsp;</span></td>
                <td align='right' colspan='2' style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;' valign='top' type='number'><strong></strong></td>
            </tr>
            <tr>
                <td align='left' colspan='2' style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;font-weight:bold;font-size:20px;border-left: 1px solid black;' valign='top' type='text'><span style='margin-left: 10px;'><u>Deduction:</u></span></td>
                <td align='right' colspan='2' style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;' valign='top' type='number'><strong> </strong></td>
                <td align='right' colspan='2' style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;' valign='top' type='number'><strong> </strong></td>
            </tr>
            <tr *ngFor="let deductionData of deductionDataDetailList" style='line-height: 30px;'>
                <td align='left' colspan='2' style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;font-size:20px;border-left: 1px solid black;font-weight: 600;' valign='top' type='text'><span style='margin-left: 30px;'>{{deductionData.description}}&nbsp;</span>
                </td>
                <td align='right' colspan='2' style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;font-size:20px;font-weight:bold;' valign='top' type='number'></td>
                <td align='right' colspan='2' style='border-right: 1px solid #000; border-bottom: none; word-wrap: break-word;font-size:20px;font-weight: 600;' valign='top' type='number'><span style='margin-left: 30px;'>{{deductionData.totalAmount}}&nbsp;</span></td>
            </tr>
            <tr>
                <td align='left' colspan='2' style='border-right: 1px solid #000; border-bottom: 1px solid black;; word-wrap: break-word;font-size:20px;font-weight:bold;border-left: 1px solid black;' valign='top' type='text'><span style='margin-left: 30px;'>Total&nbsp;</span>
                </td>
                <td align='right' style='border-right:none; border-bottom: 1px solid black;; word-wrap: break-word;font-size:20px;font-weight:bold;' valign='top' type='number'>
                    <span>( A )&nbsp;</span>
                </td>
                <td align='right' style='border-right: 1px solid #000; border-bottom: 1px solid black;; word-wrap: break-word;font-size:20px;font-weight: 600;' valign='top' type='number'>
                    <span>{{summaryAdditionTotAmt}}&nbsp;</span>
                </td>
                <td align='right' style='border-right: none; border-bottom: 1px solid black;; word-wrap: break-word;font-size:20px;font-weight:bold;' valign='top' type='number'>
                    <span>( B )&nbsp;</span>
                </td>
                <td align='right' style='border-right: 1px solid #000; border-bottom: 1px solid black;; word-wrap: break-word;font-size:20px;font-weight: 600;' valign='top' type='number'>
                    <span>{{summaryDeductionTotAmt}}&nbsp;</span>
                </td>
            </tr>
            <tr>
                <td align='left' colspan='2' style='border-right: none; border-bottom: none; word-wrap: break-word;font-size:20px;font-weight:bold;border-left: none' valign='top' type='text'><span>Total Amount to be Paid (A - B)&nbsp;</span>
                </td>
                <td align='right' colspan='2' style='border-right:none; border-bottom: 1px solid black; word-wrap: break-word;font-size:20px;font-weight:bold;' valign='top' type='number'>
                    <span>{{summaryAdditionTotAmt - summaryDeductionTotAmt}}&nbsp;</span>
                </td>
                <td align='right' colspan='2' style='border-right:none; border-bottom: none; word-wrap: break-word;font-size:20px;font-weight:bold;' valign='top' type='number'>
                    <span></span>
                </td>
            </tr>
            <br>
            <tr>
                <td align='left' colspan='2' style='border-right: none; border-bottom: none; word-wrap: break-word;font-size:20px;font-weight:bold;border-left: none' valign='top' type='text'><span></span>
                </td>
                <td align='right' colspan='2' style='border-right:none; border-bottom: none; word-wrap: break-word;font-size:20px;font-weight:bold;' valign='top' type='number'>
                    <span></span>
                </td>
                <td align='right' colspan='2' style='border-right:none; border-bottom: none; word-wrap: break-word;font-size:20px;font-weight:bold;' valign='top' type='number'>
                    <span>For SRD Logistics Pvt.Ltd.&nbsp;&nbsp;</span>
                </td>
            </tr>
        </table>



        <!--
			
			<div class="row">
			<div class="col-md-5">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
							<h6 style='margin-left: 23px; font-size: 17px; padding-top: 30px;'>
								<strong>HC-HANDLE WITH CARE, NW PRTY-NEW PARTY, COD </strong>
							</h6>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-5">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
							<h6 style='margin-right: 23px; font-size: 17px; padding-top: 30px;'>
								<strong>Loading Clerk : </strong>
								<strong>
									<u style='border-bottom: 1px solid black;padding-left: 200px;'>
									</u>
								</strong>
							</h6>
							<h6 style='margin-right: 23px; font-size: 17px; padding-top: 30px;'>
								<strong>Incharge Sign : </strong>
								<strong>
									<u style='border-bottom: 1px solid black;padding-left: 200px;'>
									</u>
								</strong>
							</h6>
						</div>
					</div>
				</div>
			</div>
		</div>-->

    </div>
</div>