import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { LrService } from 'src/app/dataService/lr-service';

@Component({
    selector: 'app-agent-commission-master',
    templateUrl: './agent-commission-master.component.html',
    styleUrls: ['./agent-commission-master.component.css']
})
export class AgentCommissionMasterComponent implements OnInit {

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerCommodity: Subject<any> = new Subject();
    dtTriggerAgentCommission: Subject<any> = new Subject();
    dtTriggerIncentiveDays: Subject<any> = new Subject();
    dtTriggerPenaltyDays: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsCommodity: any;
    dtOptionsAgentCommission: any;
    dtOptionsIncentiveDays: any;
    dtOptionsPenaltyDays: any;

    commodityDataList: any;
    agentCommissionDataList: any;
    incentiveDaysDataList: any;
    penaltyDaysDataList: any;
    pageId = "acmc";
    controlAgent = new FormControl();
    controlCommodity = new FormControl();

    commodityOptions = [
        { id: 1, label: 'All' },
        { id: 2, label: 'IRON TOOLS' },
        { id: 3, label: 'PAPER GOODS' },
        { id: 3, label: 'CABLE WIRE' }
    ];

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;
    showSpinnerForAction: boolean = false;

    agentNameOptions: AgentDetailsDto[];
    agentDtoForAgentName: AgentDetailsDto = new AgentDetailsDto();
    public modelAgentName: any;
    agentNameTA: Array<AgentDetailsDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    searchAgentName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { subStation: string }) => x.subStation;

    rateMasterDto: RateMasterDto = new RateMasterDto();
    rateMasterDtoOptions: RateMasterDto[];
    rateMasterDtoCommodityListAllOption: RateMasterDto = new RateMasterDto();
    public modelCommodityList: any;
    commodityListTA: Array<RateMasterDto> = [];
    focusCommodityListTA$ = new Subject<string>();
    searchCommodityList = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityListTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commodityListTA
                : this.commodityListTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityList = (x: { commodityName: string }) => x.commodityName;

    /* Grid data for use */
    rateMasterDtoForGetCommDetails: RateMasterDto = new RateMasterDto();
    rateMasterDtoForPenealty: RateMasterDto = new RateMasterDto();
    private newAttributeTosetPenality: any = {};
    private newAttributeTosetIncentive: any = {};
    private newAttributeTosetCommodity: any = {};
    editPenalityTableDetailsId: any;
    editIncentiveTableDetailsId: any;
    editCommodityTableDetailsId: any;
    /* Save  data for use */
    rateMasterDtoForGetUserValues: RateMasterDto = new RateMasterDto();
    rateMasterDtoForGetUserValuesReturn: RateMasterDto = new RateMasterDto();

    validateAgentName: any;
    validateTDSCommChgs: any;
    validateMonthlyAmount: any;
    validateMonthlyAmountUnit: any;
    validateTempFrght: any;
    validateTempoFrghtUnit: any;
    validateTdsOnLdg: any;
    validateIncentiveUnit: any;
    validatePenaltyUnit: any;
    validateApplyTempo: any;

    //get specfic agent
    rateMasterDtoForGetSpecficAgentDetails: RateMasterDto = new RateMasterDto();
    selectSpecficAgentName: any;

    constructor(private agentCommodityScreen: MasterService, private modalService: NgbModal,
        private router: Router, private masterReadService: MasterReadService,
        private lrService: LrService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;

            this.getAgentDetailList();
            // this.setCommodityDetails();
            this.getCommodityMasterDetailsList();
            this.setBookingAgentCommDetailsList();
        }
    }


    ngOnInit(): void {
        var companyAddressDetls = this.address;
        var groupColumn = 0;
        this.dtOptionsCommodity = {
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 150,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            searching: false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;

                /* api.column(groupColumn, { page: 'current' })
                     .data().each(function (group, i) {
                         if (last !== group) {
                             $(rows).eq(i).before(
                                 '<tr class="group" style="color: green;background-color: #ddd !important; font-weight:bold;text-align: center;"><td colspan="4"><u>'
                                 + group
                                 + '</u></td></tr>');
                             last = group;
                         }
                     });*/


                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                //var totalQty = 0;
                var count = 0;
                //var pendingQty = 0;
                api.column(groupColumn, {
                    page: 'current'
                }).data().each(function (group, i) {

                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            })).append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                })).append($("<td></td>",
                                                    {
                                                        "colspan": 1,
                                                        "text": ""
                                                    })).append($("<td></td>",
                                                        {
                                                            "colspan": 1,
                                                            "text": ""
                                                        }))
                                        .prop('outerHTML'));
                            //totalQty = 0;
                            count = 0;
                            //pendingQty = 0;
                        }
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 5,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }
                    count++;
                    //totalQty += val[5];
                    //pendingQty += val[6];
                    if (i == (rows.length - 1)) {

                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total : " + count

                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .prop('outerHTML'));
                        //totalQty = 0;
                        count = 0;
                        //pendingQty = 0;
                    }
                });
            }
        },
            this.dtOptionsAgentCommission = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        exportOptions: {
                            columns: ':visible'
                        },
                        messageTop: function () {
                            return "Booking Agent Commission Details";
                        },
                        title: function () {
                            return " Booking Agent Commission Details - "
                        }
                    },
                    {
                        extend: 'print',
                        text: '<i class="fas fa-print"> Print</i>',
                        titleAttr: 'Print',
                        title: function () {
                            return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                "<td align='left' style='width: 20%;'>" +
                                "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                                "</td>" +
                                "<td align='left' style='width: 30%;'>" +
                                "</td>" +
                                "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                                companyAddressDetls +
                                "</td>" +
                                "</tr></table>";
                        }, messageTop: function () {
                            // return "Booking Agent Commission Details";
                            return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                "<td align='center'  style='width: 100%;font-weight:bold;'>" +
                                "Booking Agent Commission Details" +
                                "</td>" +
                                "</tr></table>";
                        },
                        exportOptions: {
                            columns: [0, 6, 7, 1, 8, 9, 10, 4, 5, 2, 3]
                        },
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
            }
        this.dtOptionsIncentiveDays = {
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 90,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            searching: false,
        },
            this.dtOptionsPenaltyDays = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 90,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                searching: false,
            }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngAfterViewInit(): void {
        this.dtTriggerCommodity.next();
        this.dtTriggerAgentCommission.next();
        this.dtTriggerIncentiveDays.next();
        this.dtTriggerPenaltyDays.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerCommodity.unsubscribe();
    }

    //for the select option with filter starts
    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }
    //for the select option with filter ends

    /* Drop Down Service start */

    clickListnerForAgentName(e: NgbTypeaheadSelectItemEvent) {
        this.modelAgentName = e.item;
        $("#" + this.pageId + "agentName").val(this.modelAgentName.subStation);
        $("#" + this.pageId + "agentName").focusout();
        setTimeout(() => {
            this.setSpecificAgntDetails();
        }, 500);
    }


    getUserValuesForAgentDetailsList() {
        this.agentDtoForAgentName = new AgentDetailsDto();
        this.agentDtoForAgentName.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.agentDtoForAgentName.column2 = 'Working';
        this.agentDtoForAgentName.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoForAgentName.mode = 'mainBranch';
    }

    getAgentDetailList() {
        this.getUserValuesForAgentDetailsList();
        this.showSpinnerForAction = true;
        this.masterReadService.getSourceListDetails(this.agentDtoForAgentName).subscribe(
            (response) => {
                this.agentNameTA = [];
                this.agentNameOptions = [];
                if (response.length > 0) {
                    this.agentNameOptions = response;
                    for (let i = 0; i < this.agentNameOptions.length; i++) {
                        this.agentNameTA.push(this.agentNameOptions[i]);
                    }
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            }, () => console.log('done');
    };

    clickListnerForCommodityList(e: NgbTypeaheadSelectItemEvent) {
        this.modelCommodityList = e.item;
        $("#" + this.pageId + "commodityName").val(this.modelCommodityList.commodityName);
    }

    /* Get Grid Table Data Service start */

    getBookingAgentCommDetailsList() {
        this.rateMasterDtoForGetCommDetails = new RateMasterDto();
        this.rateMasterDtoForGetCommDetails.mode = "mainBranch";
        this.rateMasterDtoForGetCommDetails.mainStation = this.userDataDtoReturnSession.mainStation;
        this.rateMasterDtoForGetCommDetails.companyId = this.userDataDtoReturnSession.companyId;
    }

    setBookingAgentCommDetailsList() {
        this.getBookingAgentCommDetailsList();
        this.showSpinnerForAction = true;
        this.lrService.getBkgAgtCommDetails(this.rateMasterDtoForGetCommDetails).subscribe(
            (response) => {
                console.log(response);
                $("#" + this.pageId + "agentCommissionTableId").DataTable().destroy();
                this.agentCommissionDataList = [];
                if (response.length > 0) {
                    this.agentCommissionDataList = response;
                }
                this.dtTriggerAgentCommission.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            }, () => console.log('done');
    }

    /* Get Grid Table Data Service End */

    /*  Grid Table Row Selected SetField  Start */

    rowSelectedAgnetCommisionDetails(agentCommissionData) {
        console.log(agentCommissionData);

        $("#" + this.pageId + "agentName").val(agentCommissionData.agentName);
        $("#" + this.pageId + "tdsOnCommission").val(agentCommissionData.tdsChg);
        $("#" + this.pageId + "monthlyAmount").val(agentCommissionData.mnthAmt);
        if (agentCommissionData.mnthAmtUnit == null) {
            $("#" + this.pageId + "unitOfReward").val("Select Unit");
        } else {
            $("#" + this.pageId + "unitOfReward").val(agentCommissionData.mnthAmtUnit);
        }

        $("#" + this.pageId + "tempoFreight").val(agentCommissionData.tempoFrgt);
        if (agentCommissionData.unitTempoFrgt == null) {
            $("#" + this.pageId + "unitOfTempFreight").val("Select Unit");
        } else {
            $("#" + this.pageId + "unitOfTempFreight").val(agentCommissionData.unitTempoFrgt);
        }

        $("#" + this.pageId + "tdsOnLoadingHamali").val(agentCommissionData.tdsOnLdg);
        if (agentCommissionData.incentiveUnit == null) {
            $("#" + this.pageId + "incentiveUnit").val("Select Unit");
        } else {
            $("#" + this.pageId + "incentiveUnit").val(agentCommissionData.incentiveUnit);
        }

        if (agentCommissionData.penaltyUnit == null) {
            $("#" + this.pageId + "penaltyUnit").val("Select Unit");
        } else {
            $("#" + this.pageId + "penaltyUnit").val(agentCommissionData.penaltyUnit);
        }

        if (agentCommissionData.tempoPenaltyApplicable) {
            $("#" + this.pageId + "tempoPenalty").val("Yes");
        } else {
            $("#" + this.pageId + "tempoPenalty").val("No");
        }

        // penealty
        var penaltyRange = agentCommissionData.penaltyRange == null ? "NA" : agentCommissionData.penaltyRange;
        if (penaltyRange != "NA") {
            var splitPena = [];
            splitPena = penaltyRange.split(",");
            console.log(splitPena);
            var nextSplitPenality = [];
            var setPenaltyList = [];
            for (let i = 0; i < splitPena.length; i++) {
                nextSplitPenality = splitPena[i].split("_");
                this.newAttributeTosetPenality = {};
                if (setPenaltyList.length == 0) {
                    this.newAttributeTosetPenality.from = nextSplitPenality[0];
                    this.newAttributeTosetPenality.to = nextSplitPenality[1];
                    this.newAttributeTosetPenality.comm = nextSplitPenality[2];
                    setPenaltyList.push(this.newAttributeTosetPenality);
                } else {
                    this.newAttributeTosetPenality.from = nextSplitPenality[0];
                    this.newAttributeTosetPenality.to = nextSplitPenality[1];
                    this.newAttributeTosetPenality.comm = nextSplitPenality[2];
                    setPenaltyList.push(this.newAttributeTosetPenality);
                }
            }
            this.penaltyDaysDataList = [];
            $("#" + this.pageId + "penaltyDaysTableId").DataTable().destroy();
            for (let key in setPenaltyList) {
                this.penaltyDaysDataList.push(setPenaltyList[key]);
            }
            this.dtTriggerPenaltyDays.next();
        }

        //Incentive 
        var incentiveRange = agentCommissionData.incentiveRange == null ? "NA" : agentCommissionData.incentiveRange;
        if (incentiveRange != "NA") {
            var splitInce = [];
            splitInce = incentiveRange.split(",");
            var nextSplitInce = [];
            var setInceDaysList = [];
            for (let i = 0; i < splitInce.length; i++) {
                nextSplitInce = splitInce[i].split("_");
                this.newAttributeTosetIncentive = {};
                if (nextSplitInce.length == 0) {
                    this.newAttributeTosetIncentive.from = nextSplitInce[0];
                    this.newAttributeTosetIncentive.to = nextSplitInce[1];
                    this.newAttributeTosetIncentive.comm = nextSplitInce[2];
                    setInceDaysList.push(this.newAttributeTosetIncentive);
                } else {
                    this.newAttributeTosetIncentive.from = nextSplitInce[0];
                    this.newAttributeTosetIncentive.to = nextSplitInce[1];
                    this.newAttributeTosetIncentive.comm = nextSplitInce[2];
                    setInceDaysList.push(this.newAttributeTosetIncentive);
                }
            }
            this.incentiveDaysDataList = [];
            $("#" + this.pageId + "incentiveDaysTableId").DataTable().destroy();
            for (let key in setInceDaysList) {
                this.incentiveDaysDataList.push(setInceDaysList[key]);
            }
            this.dtTriggerIncentiveDays.next();
        }

        // commodity name
        var commission = [], commsUnit = [], commsCommd = [], ldgComms = [], ldgCommsUnit = [];
        var ldgCommsCommd = [], unldgComms = [], unldgCommsUnit = [], unldgCommsCommd = [];

        // For Commission

        commission = agentCommissionData.multipleComms == null ? null : agentCommissionData.multipleComms.split("#");
        commsUnit = agentCommissionData.multipleCommsUnit == null ? null : agentCommissionData.multipleCommsUnit.split("#");
        commsCommd = agentCommissionData.multipleCommsCommd == null ? null : agentCommissionData.multipleCommsCommd.split("#");


        // For Loading Hamali
        ldgComms = agentCommissionData.multipleLdgHamComms == null ? null : agentCommissionData.multipleLdgHamComms.split("#");
        ldgCommsUnit = agentCommissionData.multipleLdgHamUnit == null ? null : agentCommissionData.multipleLdgHamUnit.split("#");
        ldgCommsCommd = agentCommissionData.multipleLdgHamCommd == null ? null : agentCommissionData.multipleLdgHamCommd.split("#");

        // For Unloading Hamali
        unldgComms = agentCommissionData.multipleUnldgHamComms == null ? null : agentCommissionData.multipleUnldgHamComms.split("#");
        unldgCommsUnit = agentCommissionData.multipleUnldgHamUnit == null ? null : agentCommissionData.multipleUnldgHamUnit.split("#");
        unldgCommsCommd = agentCommissionData.multipleUnldgHamCommd == null ? null : agentCommissionData.multipleUnldgHamCommd.split("#");

        /*console.log(commission);
        console.log(commsUnit);
        console.log(commsCommd);

        console.log(ldgComms);
        console.log(ldgCommsUnit);
        console.log(ldgCommsCommd);

        console.log(unldgComms);
        console.log(unldgCommsUnit);
        console.log(unldgCommsCommd);*/

        this.commodityDataList = [];

        if (commission != null) {
            for (let i = 0; i < commission.length; i++) {
                //console.log(ldgComms[i]);
                //console.log(ldgCommsUnit[i]);
                //console.log(ldgCommsCommd[i]);
                this.newAttributeTosetCommodity = {};
                this.newAttributeTosetCommodity.commissionUnit = "Commission";
                this.newAttributeTosetCommodity.commodityName = commsCommd[i];
                this.newAttributeTosetCommodity.commissionFieldWgt = commsUnit[i];
                this.newAttributeTosetCommodity.commissionValue = commission[i];

                this.commodityDataList.push(this.newAttributeTosetCommodity);

            }
        }
        if (ldgComms != null) {
            for (let i = 0; i < ldgComms.length; i++) {
                //console.log(ldgComms[i]);
                //console.log(ldgCommsUnit[i]);
                //console.log(ldgCommsCommd[i]);
                this.newAttributeTosetCommodity = {};
                this.newAttributeTosetCommodity.commissionUnit = "Loading Hamali";
                this.newAttributeTosetCommodity.commodityName = ldgCommsCommd[i];
                this.newAttributeTosetCommodity.commissionFieldWgt = ldgCommsUnit[i];
                this.newAttributeTosetCommodity.commissionValue = ldgComms[i];

                this.commodityDataList.push(this.newAttributeTosetCommodity);

            }
        }

        if (unldgComms != null) {
            for (let i = 0; i < unldgComms.length; i++) {
                //console.log(ldgComms[i]);
                //console.log(ldgCommsUnit[i]);
                //console.log(ldgCommsCommd[i]);
                this.newAttributeTosetCommodity = {};
                this.newAttributeTosetCommodity.commissionUnit = "Unloading Hamali";
                this.newAttributeTosetCommodity.commodityName = unldgCommsCommd[i];
                this.newAttributeTosetCommodity.commissionFieldWgt = unldgCommsUnit[i];
                this.newAttributeTosetCommodity.commissionValue = unldgComms[i];

                this.commodityDataList.push(this.newAttributeTosetCommodity);

            }
        }

        $("#" + this.pageId + "commodityTableId").DataTable().destroy();
        this.dtTriggerCommodity.next();
    }

    /*  Grid Table Row Selected SetField  End */

    /* Penalty ADD,Remove & Edit Flow Start Here  */

    onKeyPressListnerFrom(event) {
        if (event.charCode == 13) {
            $("#" + this.pageId + "penalityToId").focus();
        }
    }

    onKeyPressListnerTo(event) {
        if (event.charCode == 13) {
            $("#" + this.pageId + "penalityCommId").focus();
        }
    }

    onKeyPressListnerCommission(event) {
        if (event.charCode == 13) {
            if ($("#" + this.pageId + "penalityFromId").val() == null || $("#" + this.pageId + "penalityFromId").val() == undefined ||
                $("#" + this.pageId + "penalityFromId").val() == "" || $("#" + this.pageId + "penalityToId").val() == null ||
                $("#" + this.pageId + "penalityToId").val() == undefined || $("#" + this.pageId + "penalityToId").val() == "" ||
                $("#" + this.pageId + "penalityCommId").val() == null || $("#" + this.pageId + "penalityCommId").val() == undefined ||
                $("#" + this.pageId + "penalityCommId").val() == "") {
                swal("Mandatory Field", "Please enter the FROM,TO & Commission(%)", "warning");
                return false;
            } else {
                this.addInTableOfPenaltyDetailsTable();
            }
        }
    }

    addInTableOfPenaltyDetailsTable() {
        this.newAttributeTosetPenality = {};
        this.newAttributeTosetPenality.from = $("#" + this.pageId + "penalityFromId").val();
        this.newAttributeTosetPenality.to = $("#" + this.pageId + "penalityToId").val();
        this.newAttributeTosetPenality.comm = $("#" + this.pageId + "penalityCommId").val();

        if (this.editPenalityTableDetailsId != null &&
            this.editPenalityTableDetailsId != undefined) {
            this.penaltyDaysDataList.splice(this.editPenalityTableDetailsId, 1);
        }

        if (this.penaltyDaysDataList == null || this.penaltyDaysDataList == undefined ||
            this.penaltyDaysDataList.length == 0) {
            this.penaltyDaysDataList = [];
        }

        if (this.penaltyDaysDataList.length == 0) {
            this.penaltyDaysDataList = [];
            $("#" + this.pageId + "penaltyDaysTableId").DataTable().destroy();
            this.penaltyDaysDataList.push(this.newAttributeTosetPenality);
            this.dtTriggerPenaltyDays.next();
            this.clearFieldPenalty();
        } else {
            const result = this.penaltyDaysDataList.filter(penaltyDaysData =>
                penaltyDaysData.from === this.newAttributeTosetPenality.from &&
                penaltyDaysData.to === this.newAttributeTosetPenality.to &&
                penaltyDaysData.comm === this.newAttributeTosetPenality.comm);
            if (result.length > 0) {
                swal("Duplicate Entry", "Duplicate Value Entered..Please recheck", "warning");
            } else {
                $("#" + this.pageId + "penaltyDaysTableId").DataTable().destroy();
                this.penaltyDaysDataList.push(this.newAttributeTosetPenality);
                this.dtTriggerPenaltyDays.next();
                this.clearFieldPenalty();
            }
        }
        /*this.newAttributeTosetPenality = {};
        this.editPenalityTableDetailsId = null;
        $("#"+this.pageId+"penalityFromId").val('');
        $("#"+this.pageId+"penalityToId").val('');
        $("#"+this.pageId+"penalityCommId").val('');*/
    }

    rowSelectedPenalityDataOnEdit(editPenaltyDaysData, index) {
        $("#" + this.pageId + "penalityFromId").val(editPenaltyDaysData.from);
        $("#" + this.pageId + "penalityToId").val(editPenaltyDaysData.to);
        $("#" + this.pageId + "penalityCommId").val(editPenaltyDaysData.comm);
        this.editPenalityTableDetailsId = index;
    }

    deleteSelectedPenality(index) {
        $("#" + this.pageId + "penaltyDaysTableId").DataTable().destroy();
        this.penaltyDaysDataList.splice(index, 1);
        this.dtTriggerPenaltyDays.next();
    }

    clearBtnPenalty() {
        this.clearFieldPenalty();
    }

    clearFieldPenalty() {
        this.newAttributeTosetPenality = {};
        this.editPenalityTableDetailsId = null;
        $("#" + this.pageId + "penalityFromId").val('');
        $("#" + this.pageId + "penalityToId").val('');
        $("#" + this.pageId + "penalityCommId").val('');
        $("#" + this.pageId + "penalityFromId").focus();
    }

    /* Penalty ADD,Remove & Edit Flow End Here  */

    /* Incentive ADD,Remove & Edit Flow Start Here  */

    onKeyPressListnerIncentiveFrom(event) {
        if (event.charCode == 13) {
            $("#" + this.pageId + "incentiveToId").focus();
        }
    }

    onKeyPressListnerIncentiveTo(event) {
        if (event.charCode == 13) {
            $("#" + this.pageId + "incentiveCommId").focus();
        }
    }

    onKeyPressListnerIncentiveComm(event) {
        if (event.charCode == 13) {
            if ($("#" + this.pageId + "incentiveFromId").val() == null || $("#" + this.pageId + "incentiveFromId").val() == undefined ||
                $("#" + this.pageId + "incentiveFromId").val() == "" || $("#" + this.pageId + "incentiveToId").val() == null ||
                $("#" + this.pageId + "incentiveToId").val() == undefined || $("#" + this.pageId + "incentiveToId").val() == "" ||
                $("#" + this.pageId + "incentiveCommId").val() == null || $("#" + this.pageId + "incentiveCommId").val() == undefined ||
                $("#" + this.pageId + "incentiveCommId").val() == "") {
                swal("Mandatory Field", "Please enter the FROM,TO & Commission(%)", "warning");
                return false;
            } else {
                this.addInTableOfIncentiveDetailsTable();
            }
        }
    }

    addInTableOfIncentiveDetailsTable() {
        this.newAttributeTosetIncentive = {};
        this.newAttributeTosetIncentive.from = $("#" + this.pageId + "incentiveFromId").val();
        this.newAttributeTosetIncentive.to = $("#" + this.pageId + "incentiveToId").val();
        this.newAttributeTosetIncentive.comm = $("#" + this.pageId + "incentiveCommId").val();

        if (this.editIncentiveTableDetailsId != null &&
            this.editIncentiveTableDetailsId != undefined) {
            this.incentiveDaysDataList.splice(this.editIncentiveTableDetailsId, 1);
        }

        if (this.incentiveDaysDataList == null || this.incentiveDaysDataList == undefined ||
            this.incentiveDaysDataList.length == 0) {
            this.incentiveDaysDataList = [];
        }

        if (this.incentiveDaysDataList.length == 0) {
            this.incentiveDaysDataList = [];
            $("#" + this.pageId + "incentiveDaysTableId").DataTable().destroy();
            this.incentiveDaysDataList.push(this.newAttributeTosetIncentive);
            this.dtTriggerIncentiveDays.next();
            this.clearFieldIncentive();
        } else {
            const result = this.incentiveDaysDataList.filter(incentiveDaysData =>
                incentiveDaysData.from === this.newAttributeTosetIncentive.from &&
                incentiveDaysData.to === this.newAttributeTosetIncentive.to &&
                incentiveDaysData.comm === this.newAttributeTosetIncentive.comm);
            if (result.length > 0) {
                swal("Duplicate Entry", "Duplicate Value Entered..Please recheck", "warning");
            } else {
                $("#" + this.pageId + "incentiveDaysTableId").DataTable().destroy();
                this.incentiveDaysDataList.push(this.newAttributeTosetIncentive);
                this.dtTriggerIncentiveDays.next();
                this.clearFieldIncentive();
            }
        }
    }

    rowSelectedIncentiveDataOnEdit(incentiveDaysData, index) {
        $("#" + this.pageId + "incentiveFromId").val(incentiveDaysData.from);
        $("#" + this.pageId + "incentiveToId").val(incentiveDaysData.to);
        $("#" + this.pageId + "incentiveCommId").val(incentiveDaysData.comm);
        this.editIncentiveTableDetailsId = index;
    }

    deleteSelectedIncentive(index) {
        $("#" + this.pageId + "incentiveDaysTableId").DataTable().destroy();
        this.incentiveDaysDataList.splice(index, 1);
        this.dtTriggerIncentiveDays.next();
    }


    clearBtnIncentive() {
        this.clearFieldIncentive();
    }

    clearFieldIncentive() {
        this.newAttributeTosetIncentive = {};
        this.editIncentiveTableDetailsId = null;
        $("#" + this.pageId + "incentiveFromId").val('');
        $("#" + this.pageId + "incentiveToId").val('');
        $("#" + this.pageId + "incentiveCommId").val('');
        $("#" + this.pageId + "incentiveFromId").focus();
    }

    /* Incentive ADD,Remove & Edit Flow End Here  */

    /* Commodity ADD,Remove & Edit Flow Start Here  */

    onKeyPressListnerForCommissionValue(event) {
        if (event.charCode == 13) {
            $("#" + this.pageId + "addCommissionBtn").focus();
        }
    }

    addCommodityBtn() {
        if ($("#" + this.pageId + "commissionUnitId").val() == null || $("#" + this.pageId + "commissionUnitId").val() == undefined ||
            $("#" + this.pageId + "commissionUnitId").val() == "" || $("#" + this.pageId + "commodityName").val() == null ||
            $("#" + this.pageId + "commodityName").val() == undefined || $("#" + this.pageId + "commodityName").val() == "" ||
            $("#" + this.pageId + "commissionFieldWgt").val() == null || $("#" + this.pageId + "commissionFieldWgt").val() == undefined ||
            $("#" + this.pageId + "commissionFieldWgt").val() == "" || $("#" + this.pageId + "commissionFieldWgt").val() == "Select" ||
            $("#" + this.pageId + "commissionValueId").val() == null || $("#" + this.pageId + "commissionValueId").val() == null ||
            $("#" + this.pageId + "commissionValueId").val() == null) {
            swal("Mandatory Field", "Please enter the Mandatory Field", "warning");
            return false;
        } else {
            this.addInTableOfCommodityDetailsTable();
        }
    }

    addInTableOfCommodityDetailsTable() {
        this.newAttributeTosetCommodity = {};
        this.newAttributeTosetCommodity.commissionUnit = $("#" + this.pageId + "commissionUnitId").val();
        this.newAttributeTosetCommodity.commodityName = $("#" + this.pageId + "commodityName").val();
        this.newAttributeTosetCommodity.commissionFieldWgt = $("#" + this.pageId + "commissionFieldWgt").val();
        this.newAttributeTosetCommodity.commissionValue = $("#" + this.pageId + "commissionValueId").val();

        //alert(this.editCommodityTableDetailsId);
        if (this.editCommodityTableDetailsId != null &&
            this.editCommodityTableDetailsId != undefined) {
            this.commodityDataList.splice(this.editCommodityTableDetailsId, 1);
        }

        if (this.commodityDataList == null || this.commodityDataList == undefined ||
            this.commodityDataList.length == 0) {
            this.commodityDataList = [];
        }

        if (this.commodityDataList.length == 0) {
            this.commodityDataList = [];
            $("#" + this.pageId + "commodityTableId").DataTable().destroy();
            this.commodityDataList.push(this.newAttributeTosetCommodity);
            this.dtTriggerCommodity.next();
            this.clearFieldCommodity();
        } else {
            /* const result = this.commodityDataList.filter(commodityData =>
                 commodityData.commissionUnit === this.newAttributeTosetCommodity.commissionUnit &&
                 commodityData.commodityName === this.newAttributeTosetCommodity.commodityName &&
                 commodityData.commissionFieldWgt === this.newAttributeTosetCommodity.commissionFieldWgt &&
                 commodityData.commissionValue === this.newAttributeTosetCommodity.commissionValue);*/
            const result = this.commodityDataList.filter(commodityData =>
                commodityData.commissionUnit === this.newAttributeTosetCommodity.commissionUnit &&
                commodityData.commodityName === this.newAttributeTosetCommodity.commodityName);
            if (result.length > 0) {
                swal("Duplicate Entry", "Duplicate Value Entered..Please recheck", "warning");
            } else {
                $("#" + this.pageId + "commodityTableId").DataTable().destroy();
                this.commodityDataList.push(this.newAttributeTosetCommodity);
                this.dtTriggerCommodity.next();
                this.clearFieldCommodity();
            }
        }
    }

    rowSelectedCommodityDataOnEdit(commodityData, index) {
        $("#" + this.pageId + "commissionUnitId").val(commodityData.commissionUnit);
        $("#" + this.pageId + "commodityName").val(commodityData.commodityName);
        $("#" + this.pageId + "commissionFieldWgt").val(commodityData.commissionFieldWgt);
        $("#" + this.pageId + "commissionValueId").val(commodityData.commissionValue);
        this.editCommodityTableDetailsId = index;
    }

    deleteSelectedCommodity(index) {
        $("#" + this.pageId + "commodityTableId").DataTable().destroy();
        this.commodityDataList.splice(index, 1);
        this.dtTriggerCommodity.next();
    }

    removeBtnClickListner() {
        this.clearFieldCommodity();
    }

    clearFieldCommodity() {
        this.editCommodityTableDetailsId = null;
        this.newAttributeTosetCommodity = {};
        $("#" + this.pageId + "commissionUnitId").val('Commission');
        $("#" + this.pageId + "commodityName").val('');
        $("#" + this.pageId + "commissionFieldWgt").val('Select');
        $("#" + this.pageId + "commissionValueId").val('');
    }
    /* Commodity ADD,Remove & Edit Flow End Here  */

    /* Apply opertion start here */
    validateApplyBtnListner() {
        if ($("#" + this.pageId + "agentName").val() == null || $("#" + this.pageId + "agentName").val() == undefined ||
            $("#" + this.pageId + "agentName").val() == "") {
            swal("Mandatory Field", "Please select the agent name field", "warning");
            return false;
        } else if (this.commodityDataList == null || this.commodityDataList == undefined ||
            this.commodityDataList == "" || this.commodityDataList.length == 0) {
            swal("Mandatory Fields", "Please add Commission,Loading Hamali and Unloading Hamali", "warning");
            return false;
        } else {
            swal({
                title: "Confirm Changes",
                text: "Sure You Want to Apply the Changes",
                icon: "info",
                buttons: ["No", "Yes"]
            }).then((isConfirmYes) => {
                if (isConfirmYes) {
                    this.rateMasterDtoForGetUserValuesReturn = new RateMasterDto();
                    this.rateMasterDtoForGetUserValuesReturn = this.getUserValues();
                    console.log(this.rateMasterDtoForGetUserValuesReturn);
                    this.showSpinnerForAction = true;
                    this.lrService.setBkgAgtCommDetails(this.rateMasterDtoForGetUserValuesReturn).subscribe(
                        (response) => {
                            console.log(response);
                            if (response.status == "Success") {
                                swal("Booking Agent Commission Details", "Changes Are Applied Successfully", "success");
                                this.clearFiledAll();
                                this.setBookingAgentCommDetailsList();
                            } else {
                                swal("Failed", "Some Problem occured while saving the Details", "warning");
                            }
                            this.showSpinnerForAction = false;
                            this.changeDetectorRef.detectChanges();
                        }), (error) => {
                            this.showSpinnerForAction = false;
                            swal("Error", "Server Problem Occurred While setting Booking Agent Comm Details", "info");
                        }, () => console.log('done');
                }
            });
        }
    }

    getUserValues() {
        this.rateMasterDtoForGetUserValues = new RateMasterDto();

        this.validateAgentName = null;
        this.validateTDSCommChgs = null;
        this.validateMonthlyAmount = null;
        this.validateMonthlyAmountUnit = null;
        this.validateTempFrght = null;
        this.validateTempoFrghtUnit = null;
        this.validateTdsOnLdg = null;
        this.validateIncentiveUnit = null;
        this.validatePenaltyUnit = null;
        this.validateApplyTempo = null;

        this.validateAgentName = $("#" + this.pageId + "agentName").val();
        this.validateTDSCommChgs = $("#" + this.pageId + "tdsOnCommission").val();
        this.validateMonthlyAmount = $("#" + this.pageId + "monthlyAmount").val();
        this.validateMonthlyAmountUnit = $("#" + this.pageId + "unitOfReward").val();
        this.validateTempFrght = $("#" + this.pageId + "tempoFreight").val();
        this.validateTempoFrghtUnit = $("#" + this.pageId + "unitOfTempFreight").val();
        this.validateTdsOnLdg = $("#" + this.pageId + "tdsOnLoadingHamali").val();

        this.validateIncentiveUnit = $("#" + this.pageId + "incentiveUnit").val();
        this.validatePenaltyUnit = $("#" + this.pageId + "penaltyUnit").val();
        this.validateApplyTempo = $("#" + this.pageId + "tempoPenalty").val();

        this.rateMasterDtoForGetUserValues.agentName = this.validateAgentName;
        this.rateMasterDtoForGetUserValues.tdsChg = this.validateTDSCommChgs;
        this.rateMasterDtoForGetUserValues.mnthAmt = this.validateMonthlyAmount;
        this.rateMasterDtoForGetUserValues.mnthAmtUnit = this.validateMonthlyAmountUnit;
        this.rateMasterDtoForGetUserValues.tempoFrgt = this.validateTempFrght;
        this.rateMasterDtoForGetUserValues.unitTempoFrgt = this.validateTempoFrghtUnit;
        this.rateMasterDtoForGetUserValues.tdsOnLdg = this.validateTdsOnLdg;

        var commission = null, commsUnit = null, commsCommd = null;
        var ldgComms = null, ldgCommsUnit = null, ldgCommsCommd = null;
        var unldgComms = null, unldgCommsUnit = null, unldgCommsCommd = null;

        for (let i = 0; i < this.commodityDataList.length; i++) {
            var type = this.commodityDataList[i].commissionUnit;
            if (type != null && type != undefined && type == "Commission") {
                commission = commission + "#" + this.commodityDataList[i].commissionValue;
                commsUnit = commsUnit + "#" + this.commodityDataList[i].commissionFieldWgt;
                commsCommd = commsCommd + "#" + this.commodityDataList[i].commodityName;
            } else if (type != null && type != undefined && type == "Loading Hamali") {
                ldgComms = ldgComms + "#" + this.commodityDataList[i].commissionValue;
                ldgCommsUnit = ldgCommsUnit + "#" + this.commodityDataList[i].commissionFieldWgt;
                ldgCommsCommd = ldgCommsCommd + "#" + this.commodityDataList[i].commodityName;
            } else if (type != null && type != undefined && type == "Unloading Hamali") {
                unldgComms = unldgComms + "#" + this.commodityDataList[i].commissionValue;
                unldgCommsUnit = unldgCommsUnit + "#" + this.commodityDataList[i].commissionFieldWgt;
                unldgCommsCommd = unldgCommsCommd + "#" + this.commodityDataList[i].commodityName;
            }
        }

        commission = commission == null ? null : commission.replace("null#", "");
        commsUnit = commsUnit == null ? null : commsUnit.replace("null#", "");
        commsCommd = commsCommd == null ? null : commsCommd.replace("null#", "");

        ldgComms = ldgComms == null ? null : ldgComms.replace("null#", "");
        ldgCommsUnit = ldgCommsUnit == null ? null : ldgCommsUnit.replace("null#", "");
        ldgCommsCommd = ldgCommsCommd == null ? null : ldgCommsCommd.replace("null#", "");

        unldgComms = unldgComms == null ? null : unldgComms.replace("null#", "");
        unldgCommsUnit = unldgCommsUnit == null ? null : unldgCommsUnit.replace("null#", "");
        unldgCommsCommd = unldgCommsCommd == null ? null : unldgCommsCommd.replace("null#", "");

        this.rateMasterDtoForGetUserValues.multipleComms = commission;
        this.rateMasterDtoForGetUserValues.multipleCommsUnit = commsUnit;
        this.rateMasterDtoForGetUserValues.multipleCommsCommd = commsCommd;

        this.rateMasterDtoForGetUserValues.multipleLdgHamComms = ldgComms;
        this.rateMasterDtoForGetUserValues.multipleLdgHamUnit = ldgCommsUnit;
        this.rateMasterDtoForGetUserValues.multipleLdgHamCommd = ldgCommsCommd;

        this.rateMasterDtoForGetUserValues.multipleUnldgHamComms = unldgComms;
        this.rateMasterDtoForGetUserValues.multipleUnldgHamUnit = unldgCommsUnit;
        this.rateMasterDtoForGetUserValues.multipleUnldgHamCommd = unldgCommsCommd;

        //incentiveRange
        var incentiveRange = null;
        for (let i = 0; i < this.incentiveDaysDataList.length; i++) {
            if (i == 0) {
                incentiveRange = this.incentiveDaysDataList[i].from + "_" + this.incentiveDaysDataList[i].to + "_" +
                    this.incentiveDaysDataList[i].comm;
            } else {
                incentiveRange = incentiveRange + "," + this.incentiveDaysDataList[i].from + "_" + this.incentiveDaysDataList[i].to + "_" +
                    this.incentiveDaysDataList[i].comm;
            }

        }

        //penaltyRange
        var penaltyRange = null;
        for (let i = 0; i < this.penaltyDaysDataList.length; i++) {
            if (i == 0) {
                penaltyRange = this.penaltyDaysDataList[i].from + "_" + this.penaltyDaysDataList[i].to + "_" +
                    this.penaltyDaysDataList[i].comm;
            } else {
                penaltyRange = penaltyRange + "," + this.penaltyDaysDataList[i].from + "_" + this.penaltyDaysDataList[i].to + "_" +
                    this.penaltyDaysDataList[i].comm;
            }
        }

        if ($("#" + this.pageId + "incentiveUnit").val() == null || $("#" + this.pageId + "incentiveUnit").val() == undefined ||
            $("#" + this.pageId + "incentiveUnit").val() == "" || $("#" + this.pageId + "incentiveUnit").val() == "Select Unit") {
            this.rateMasterDtoForGetUserValues.incentiveUnit = null;
        } else {
            this.rateMasterDtoForGetUserValues.incentiveUnit = this.validateIncentiveUnit;
        }
        if ($("#" + this.pageId + "penaltyUnit").val() == null || $("#" + this.pageId + "penaltyUnit").val() == undefined ||
            $("#" + this.pageId + "penaltyUnit").val() == "" || $("#" + this.pageId + "penaltyUnit").val() == "Select Unit") {
            this.rateMasterDtoForGetUserValues.penaltyUnit = null;
        } else {
            this.rateMasterDtoForGetUserValues.penaltyUnit = this.validatePenaltyUnit;
        }

        if ($("#" + this.pageId + "tempoPenalty").val() != null && $("#" + this.pageId + "tempoPenalty").val() != undefined &&
            $("#" + this.pageId + "tempoPenalty").val() != "" && $("#" + this.pageId + "tempoPenalty").val() == "Yes") {
            this.rateMasterDtoForGetUserValues.tempoPenaltyApplicable = true;
        } else {
            this.rateMasterDtoForGetUserValues.tempoPenaltyApplicable = false;
        }

        this.rateMasterDtoForGetUserValues.incentiveRange = incentiveRange;
        this.rateMasterDtoForGetUserValues.penaltyRange = penaltyRange;

        this.rateMasterDtoForGetUserValues.fixedBy = this.userDataDtoReturnSession.userId;
        this.rateMasterDtoForGetUserValues.companyId = this.userDataDtoReturnSession.companyId;

        return this.rateMasterDtoForGetUserValues;
    }
    /* Apply operation end here */

    /* getSpecificAgntDetails start */
    getSpecificAgntDetails() {
        this.rateMasterDtoForGetSpecficAgentDetails = new RateMasterDto();
        this.selectSpecficAgentName = null;
        this.selectSpecficAgentName = $("#" + this.pageId + "agentName").val();

        this.rateMasterDtoForGetSpecficAgentDetails.mode = "SpecMainBranch";
        this.rateMasterDtoForGetSpecficAgentDetails.agentName = this.selectSpecficAgentName;
        this.rateMasterDtoForGetSpecficAgentDetails.mainStation = this.userDataDtoReturnSession.mainStation;
        this.rateMasterDtoForGetSpecficAgentDetails.companyId = this.userDataDtoReturnSession.companyId;
    }

    setSpecificAgntDetails() {
        this.getSpecificAgntDetails();
        this.showSpinnerForAction = true;
        this.lrService.getBkgAgtCommDetails(this.rateMasterDtoForGetSpecficAgentDetails).subscribe(
            (response) => {
                console.log(response);
                //this.clearFiledAll();
                this.rowSelectedAgnetCommisionDetails(response[0]);
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            }, () => console.log('done');
    }

    /* getSpecificAgntDetails end */

    clearFiledAll() {
        /* Grid data for use */
        this.rateMasterDtoForGetCommDetails = new RateMasterDto();
        this.rateMasterDtoForPenealty = new RateMasterDto();
        this.newAttributeTosetPenality = {};
        this.newAttributeTosetIncentive = {};
        this.newAttributeTosetCommodity = {};
        this.editPenalityTableDetailsId = null;
        this.editIncentiveTableDetailsId = null;
        this.editCommodityTableDetailsId = null;
        /* Save  data for use */
        this.rateMasterDtoForGetUserValues = new RateMasterDto();
        this.rateMasterDtoForGetUserValuesReturn = new RateMasterDto();

        //get specfic agent
        this.rateMasterDtoForGetSpecficAgentDetails = new RateMasterDto();
        this.selectSpecficAgentName = null;

        this.validateAgentName = null;
        this.validateTDSCommChgs = null;
        this.validateMonthlyAmount = null;
        this.validateMonthlyAmountUnit = null;
        this.validateTempFrght = null;
        this.validateTempoFrghtUnit = null;
        this.validateTdsOnLdg = null;
        this.validateIncentiveUnit = null;
        this.validatePenaltyUnit = null;
        this.validateApplyTempo = null;

        this.modelAgentName = null;
        this.modelCommodityList = null;

        $("#" + this.pageId + "agentName").val('');
        $("#" + this.pageId + "tdsOnCommission").val('');
        $("#" + this.pageId + "monthlyAmount").val('');
        $("#" + this.pageId + "unitOfReward").val('');
        $("#" + this.pageId + "tempoFreight").val('');
        $("#" + this.pageId + "unitOfTempFreight").val('');
        $("#" + this.pageId + "tdsOnLoadingHamali").val('');
        $("#" + this.pageId + "incentiveUnit").val('Select Unit');
        $("#" + this.pageId + "penaltyUnit").val('Select Unit');
        $("#" + this.pageId + "tempoPenalty").val('Yes');

        $("#" + this.pageId + "incentiveFromId").val('');
        $("#" + this.pageId + "incentiveToId").val('');
        $("#" + this.pageId + "incentiveCommId").val('');

        $("#" + this.pageId + "penalityFromId").val('');
        $("#" + this.pageId + "penalityToId").val('');
        $("#" + this.pageId + "penalityCommId").val('');

        $("#" + this.pageId + "commissionUnitId").val('Commission');
        $("#" + this.pageId + "commodityName").val('');
        $("#" + this.pageId + "commissionFieldWgt").val('Select');
        $("#" + this.pageId + "commissionValueId").val('');

        //table destroy
        this.incentiveDaysDataList = [];
        $("#" + this.pageId + "incentiveDaysTableId").DataTable().destroy();
        this.dtTriggerIncentiveDays.next();

        this.penaltyDaysDataList = [];
        $("#" + this.pageId + "penaltyDaysTableId").DataTable().destroy();
        this.dtTriggerPenaltyDays.next();

        this.commodityDataList = [];
        $("#" + this.pageId + "commodityTableId").DataTable().destroy();
        this.dtTriggerCommodity.next();

    }

    clearOverAllResetBtn() {
        // swal({
        //     title: "Confirm Clear",
        //     text: "Sure You Want to Clear All the Fields",
        //     icon: "info",
        //     buttons: ["No", "Yes"]
        // }).then((isConfirmYes) => {
        //     if (isConfirmYes) {
        //         this.clearFiledAll();
        //     }
        // });
        this.clearFiledAll();
    }

    getCommodityListRead() {
        this.rateMasterDto = new RateMasterDto();
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.mode = "mainConsigneeMaster";
    }

    getCommodityMasterDetailsList = () => {
        this.getCommodityListRead();
        this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                if (response) {
                    console.log(response);
                    if (response.length == 0) {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];
                    } else {
                        this.rateMasterDtoOptions = [];
                        this.commodityListTA = [];

                        this.rateMasterDtoOptions = response;
                        this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
                        this.rateMasterDtoCommodityListAllOption.commodityName = "All";
                        this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.commodityListTA.push(this.rateMasterDtoOptions[i]);
                        }
                        this.rateMasterDtoCommodityListAllOption = new RateMasterDto();
                        this.rateMasterDtoCommodityListAllOption.commodityName = "Others";
                        this.commodityListTA.push(this.rateMasterDtoCommodityListAllOption);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Error While Getting Commodity Details", "warning");
            },
            () => console.log('done');
    };

}
