import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ModalDismissReasons, NgbDateStruct, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import *  as moment from 'moment';
import { InvoiceDto } from "src/app/dto/Invoice-dto";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import { MasterService } from 'src/app/dataService/master-service';

@Component({
	selector: 'app-stocks-in-taken-report',
	templateUrl: './stocks-in-taken-report.component.html',
	styleUrls: ['./stocks-in-taken-report.component.css']
})

export class StocksInTakenReportComponent implements OnInit {

	model: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	stocksInTakenReportDataList: any;
	controlSource = new FormControl();
	controlDestination = new FormControl();
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerStocksInTakenReport: Subject<any> = new Subject();
	dtOptionsStocksInTakenReport: any;
	destSrcView = false;
	showSrcDest = false;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	address: any;
	office: any;
	fromDateModel: any;
	toDateModel: any;
	//For Destination
	destinationOptions: LRDto[];
	lrDtoDestination: LRDto = new LRDto();
	lrDtoDestinationAll: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDestinationTA$ = new Subject<string>();
	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { destination: string }) => x.destination;
	//For Source
	sourceOptions: LRDto[];
	lrDtoSource: LRDto = new LRDto();
	lrDtoSourceAll: LRDto = new LRDto();
	public modelSource: any;
	sourceTA: Array<LRDto> = [];
	focusSourceTA$ = new Subject<string>();
	searchSource = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusSourceTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.sourceTA
				: this.sourceTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterSource = (x: { destination: string }) => x.destination;

	bankNameOptions: TruckDataDto[];
	public modelBankName: any;
	bankNameTA: Array<TruckDataDto> = [];
	focusBankNameTA$ = new Subject<string>();
	searchBankName = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusBankNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.bankNameTA
				: this.bankNameTA.filter(v => v.acType.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterBankName = (x: { acType: string }) => x.acType;

	invoiceDtoSearch: InvoiceDto = new InvoiceDto();
	selectedSource: any;
	selectedDestination: any;
	selectedFromDate: any;
	selectedToDate: any;
	isRewardHAmtShow = false;
	showRewardHAmt = false;
	showHideSrcDest = 'Show SrcDest For StocksInTakenRpt';
	showHideRewardAmt = 'Show RewardAmt';
	//For Custom Print
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;

	viewCustomPrintV1 = false;
	fromDatePrint: any;
	toDatePrint: any;
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	totLrsSummary: number;
	totArtSummary: number;
	actWghtSummary: number;
	chdWtSummary: number;
	toPaySummary: number;
	paidSummary: number;
	rewardSummary: number;
	pageId = "sitrc";
	isSendToPayEnabled: boolean = false;
	showBankAcDetails: boolean = false;
	truckDataDto: TruckDataDto = new TruckDataDto();
	selectedHireslipNumberForPayment: string;
	closeResult: string;
	selectedTruckBankAcId = null;
	selectedAcNo = null;
	selectedAcName = null;
	selectedBankName = null;
	selectedIfsc = null;
	selectedBranch = null;
	selectedUploadFile: File;
	showSpinnerForActionForUpload = false;
	uploadedFileUrl = null;
	isFileUploaded: boolean = false;

	constructor(private router: Router, private masterReadService: MasterReadService, private hireslipService: HireslipService,
		public changeDetectorRef: ChangeDetectorRef, private masterService: MasterService, private modalService: NgbModal) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;
			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				//    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"dest stocks report dest view") {
						this.destSrcView = true;
					}
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"actRewardamtShow") {
						this.isRewardHAmtShow = true;
					}
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"Hire Bal SendToPay Enabled") {
						this.isSendToPayEnabled = true;
					}
				}
			}
			if (this.destSrcView == true) {
				this.showSrcDest = true;
				this.getDestinationDetails();
				this.getSourceDetails();
			} else {
				this.showSrcDest = false;
			}
			if (this.isRewardHAmtShow == true) {
				this.showRewardHAmt = true;
			} else {
				this.showRewardHAmt = false;
			}
		}
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}

	ngOnInit(): void {
		var showRewardAmt = this.isRewardHAmtShow;
		this.dtOptionsStocksInTakenReport = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					exportOptions: {
						columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 32]
					},
					title: function () {
						var returnSummaryExcel = null;
						returnSummaryExcel = "Stocks In Taken Report " +
							"From Date : " + moment($("#sitrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
							"To Date : " + moment($("#sitrctoDate").val()).format('DD-MM-YYYY');
						return returnSummaryExcel;
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var totalLrs = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var articles = api.column(7).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var actualWgt = api.column(8).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var chargedWgt = api.column(9).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var gurWgt = api.column(10).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var kantaWgt = api.column(11).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var toPay = api.column(12).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var paid = api.column(13).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var rewardHAmt = api.column(23).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				// if (showRewardAmt == true) {
				// 	var rewarAmt = api.column(24).data().reduce(
				// 		function (a, b) {
				// 			return intVal(a) + intVal(b);
				// 		}, 0);
				// }

				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(6).footer()).html(totalLrs);
				$(api.column(7).footer()).html(articles);
				$(api.column(8).footer()).html(actualWgt);
				$(api.column(9).footer()).html(chargedWgt);
				$(api.column(10).footer()).html(gurWgt);
				$(api.column(11).footer()).html(kantaWgt);
				$(api.column(12).footer()).html(toPay);
				$(api.column(13).footer()).html(paid);
				$(api.column(23).footer()).html(rewardHAmt);
				// if (showRewardAmt == true) {
				// 	$(api.column(24).footer()).html(rewarAmt);
				// }
			}
		}
	}

	ngOnDestroy(): void {
		this.dtTriggerStocksInTakenReport.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerStocksInTakenReport.next();
	}

	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}
	getSourceMethod() {
		this.lrDtoSource = new LRDto();
		this.lrDtoSource.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoSource.branch = this.userDataDtoReturnSession.office;
		this.lrDtoSource.mode = "logininfo";
	}
	getSourceDetails() {
		this.getSourceMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoSource).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					if (response.length > 0) {
						this.sourceOptions = response;
						this.sourceTA = [];
						this.lrDtoSourceAll.destination = 'All';
						this.sourceTA.push(this.lrDtoSourceAll);
						for (let i = 0; i < this.sourceOptions.length; i++) {
							this.sourceTA.push(this.sourceOptions[i]);
						}
						$("#" + this.pageId + "source").val('All');
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Source Details", "info");
			},
			() => console.log('done');
	}

	getDestinationMethod() {
		this.lrDtoDestination = new LRDto();
		this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
		this.lrDtoDestination.mode = "logininfo";
	}

	getDestinationDetails() {
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					if (response.length > 0) {
						this.destinationOptions = response;
						this.destinationTA = [];
						this.lrDtoDestinationAll.destination = 'All';
						this.destinationTA.push(this.lrDtoDestinationAll);
						for (let i = 0; i < this.destinationOptions.length; i++) {
							this.destinationTA.push(this.destinationOptions[i]);
						}
						$("#" + this.pageId + "destination").val('All');
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log('done');
	}

	searchMethod() {
		this.selectedSource = $("#" + this.pageId + "source").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.invoiceDtoSearch = new InvoiceDto();
		if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
			swal({
				title: "Mandatory Field",
				text: "Please select Date",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
			return false;
		} else {
			if (this.destSrcView == true) {
				this.invoiceDtoSearch.fromStation = this.selectedSource;
				this.invoiceDtoSearch.toStation = this.selectedDestination;
			} else {
				this.invoiceDtoSearch.toStation = this.userDataDtoReturnSession.mainStation;
			}
		}
		this.invoiceDtoSearch.fromdate = this.selectedFromDate;
		this.invoiceDtoSearch.todate = this.selectedToDate;
		this.invoiceDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.invoiceDtoSearch);
		this.searchMethodDetails();
	}

	searchMethodDetails = () => {
		this.showSpinnerForAction = true;
		this.hireslipService.getStocksInTakenReport(this.invoiceDtoSearch).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "stocksInTakenReportId").DataTable().destroy();
				this.stocksInTakenReportDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				} else {
					this.stocksInTakenReportDataList = response;
					console.log(this.stocksInTakenReportDataList);
				}
				this.dtTriggerStocksInTakenReport.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  Stocks In Taken Report", "info");
			}, () => console.log('done');
	}

	clearMethod() {
		this.showSpinnerForAction = false;
		$("#" + this.pageId + "stocksInTakenReportId").DataTable().destroy();
		this.stocksInTakenReportDataList = [];
		this.dtTriggerStocksInTakenReport.next();
		this.invoiceDtoSearch = new InvoiceDto();
		this.selectedSource = '';
		this.selectedDestination = '';
		this.selectedFromDate = '';
		this.selectedToDate = '';
		$("#" + this.pageId + "source").val('All');
		$("#" + this.pageId + "destination").val('All');
		$("#" + this.pageId + "fromDate").val('');
		$("#" + this.pageId + "toDate").val('');
		this.fromDateModel = null;
		this.toDateModel = null;
	}

	oktopayAmount() {
		if (this.isFileUploaded == false || $("#" + this.pageId + "bankAcProofFile1Upload").val() == null || $("#" + this.pageId + "bankAcProofFile1Upload").val() == "") {
			swal("Not Allowed", "Please upload the Ac Proof file to proceed!", "info");
		} else {
			swal({
				title: "Confirm Pay",
				text: "Sure you want to send this Hireslip amount for payment? Hireslip Number :" + this.selectedHireslipNumberForPayment + " ",
				icon: "info",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((sureYesBtn) => {
				if (sureYesBtn) {
					this.invoiceDtoSearch = new InvoiceDto();
					this.invoiceDtoSearch.invoiceNumber = this.selectedHireslipNumberForPayment;
					this.invoiceDtoSearch.userName = this.userDataDtoReturnSession.userName;
					this.invoiceDtoSearch.mode = 'Hire Balance';
					this.invoiceDtoSearch.driverId = this.selectedTruckBankAcId;
					this.invoiceDtoSearch.remarks = this.uploadedFileUrl;
					this.invoiceDtoSearch.status = "In Process";
					this.confirmOktopayMethod();
				}
			});
		}
	}

	confirmOktopayMethod() {
		this.showSpinnerForAction = true;
		this.hireslipService.updateSendToPayHirAmount(this.invoiceDtoSearch).subscribe(
			(response) => {
				if (response.status == 'Success') {
					swal("Success", "Details updated and request sent for payment", "info");
					this.selectedHireslipNumberForPayment = null;
					this.uploadedFileUrl = null;
					this.resetBankFields();
					this.modalService.dismissAll();
					this.searchMethod();
				} else if (response.status == 'Not Allowed') {
					swal("Not Allowed", "Payment request already sent for this Hireslip!", "warning");
				} else if (response.status == 'Failed') {
					swal("Failed", "Problem occurred while updating the details", "error");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server problem occurred while deleting the details", "error");
			}, () => console.log('done');
	}

	getDetailsForBankMasterRead(truckNumber) {
		this.truckDataDto = new TruckDataDto();
		this.truckDataDto.truckNumber = truckNumber;
		this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
		this.getBankDetailsList();
	}

	getBankDetailsList = () => {
		this.showSpinnerForAction = true;
		this.bankNameOptions = [];
		this.bankNameTA = [];
		this.showBankAcDetails = false;
		this.masterService.getTruckBankDetails(this.truckDataDto).subscribe(
			(response) => {
				if (response) {
					if (response.length > 0) {
						this.bankNameOptions = response;
						for (let i = 0; i < this.bankNameOptions.length; i++) {
							this.bankNameTA.push(this.bankNameOptions[i]);
						}
						this.showSpinnerForAction = false;
					} else {
						this.showSpinnerForAction = false;
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting the Bank Details", "error");
			},
			() => console.log('done');
	}

	openPaymentRequestPopUp(paymentReqPopup, stocksInTakenReportData) {
		if (stocksInTakenReportData.balPaymentStatus == 'In Process') {
			swal("Not Allowed", "Payment request already initiated and its in process!", "warning");
		} else if (stocksInTakenReportData.balPaymentStatus == 'Completed') {
			swal("Not Allowed", "Payment already completed!", "warning");
		} else if (stocksInTakenReportData.balPaymentStatus == 'Pending' || stocksInTakenReportData.balPaymentStatus == 'Rejected') {
			this.selectedHireslipNumberForPayment = null;
			this.selectedHireslipNumberForPayment = stocksInTakenReportData.invoiceNumber;
			this.getDetailsForBankMasterRead(stocksInTakenReportData.vehicleNumber);
			this.modalService.open(paymentReqPopup, { centered: true }).result.then(
				result => {
					this.closeResult = `Closed with: ${result}`;
				},
				reason => {
					this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
				}
			);
		}
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	clickListnerForBankName(e: NgbTypeaheadSelectItemEvent) {
		this.modelBankName = e.item;
		console.log('clickListnerForBankName');
		console.log(this.modelBankName.id);
		this.showBankAcDetails = true;
		this.selectedTruckBankAcId = this.modelBankName.id;
		this.selectedAcNo = this.modelBankName.acNumber;
		this.selectedAcName = this.modelBankName.acHolder;
		this.selectedBankName = this.modelBankName.bankName;
		this.selectedIfsc = this.modelBankName.ifscCode;
		this.selectedBranch = this.modelBankName.branchName;
		$("#" + this.pageId + "bankNameId").val(this.modelBankName.bankName);
		$("#" + this.pageId + "bankNameId").focusout();
		$("#" + this.pageId + "" + this.pageId + "cashChequePdcAmount").focus();
	}

	onFileChanged(event) {
		this.selectedUploadFile = event.target.files[0];
		console.log(this.selectedUploadFile);
	}

	uploadBankAcProofFile() {
		this.isFileUploaded = false;
		console.log($("#" + this.pageId + "bankAcProofFile1Upload").val());

		if ($("#" + this.pageId + "bankAcProofFile1Upload").val() != null && $("#" + this.pageId + "bankAcProofFile1Upload").val() != "") {
			this.showSpinnerForActionForUpload = true;
			if ((this.selectedUploadFile.type != null &&
				(this.selectedUploadFile.type == "image/jpeg" ||
					this.selectedUploadFile.type == "image/jpg" ||
					this.selectedUploadFile.type == "image/png"))) {
				var splitType = this.selectedUploadFile.type.split("/");
				var hireslipNumberChg = this.selectedHireslipNumberForPayment.replace("/", "-");
				hireslipNumberChg = hireslipNumberChg.replace("/", "-");
				hireslipNumberChg = hireslipNumberChg.replace("/", "-");

				const commonList = "vehicledriverdocs&&documents&&ASRAR&&" + splitType[1] + "&&" + hireslipNumberChg + "";
				console.log("commonListToPartyStmt1");
				console.log(commonList);
				const formData = new FormData();
				console.log("commonListToPartyStmt1, this.selectedUploadFile, this.selectedUploadFile.name >> 1");
				console.log(commonList, this.selectedUploadFile, this.selectedUploadFile.name);

				formData.append(commonList, commonList);
				formData.append('myfileToPartyStmt1', this.selectedUploadFile, this.selectedUploadFile.name);
				console.log("formData >> 1");
				console.log(formData);
				this.masterReadService.uploadFile(formData).subscribe(
					(response) => {
						if (response) {
							console.log(response);
							console.log(response[0].url);
							this.uploadedFileUrl = response[0].url;
							this.showSpinnerForActionForUpload = false;
							if (response[0] != "Error") {
								this.isFileUploaded = true;
								swal("File Upload", "File Uploaded Succcessfully", "success");
							} else {
								this.isFileUploaded = false;
								swal("ERROR", "Correct the File and Upload Again1", "error");
							}
						}
						this.changeDetectorRef.detectChanges();
					}), (error) => {
						this.showSpinnerForActionForUpload = false;
						swal("Error", "Server Error While Uploading File", "info");
					}, () => console.log('done');
			} else {
				this.showSpinnerForActionForUpload = false;
				swal("Mandatory Field", "Only jpg & png image format are allowed to upload", "warning");
			}
		}
	}

	resetBankFields() {
		this.modelBankName = null;
		this.selectedTruckBankAcId = null;
		this.selectedAcNo = null;
		this.selectedAcName = null;
		this.selectedBankName = null;
		this.selectedIfsc = null;
		this.selectedBranch = null;
		$("#" + this.pageId + "bankNameId").val('');
		this.showBankAcDetails = false;
		this.bankNameOptions = [];
		this.bankNameTA = [];
		this.isFileUploaded = false;
	}

	viewBankAcProofDoc(stocksInTakenReportData) {
		if (stocksInTakenReportData.hireamtbalbankacproofcopy == null || stocksInTakenReportData.hireamtbalbankacproofcopy == undefined || stocksInTakenReportData.hireamtbalbankacproofcopy == 'NA') {
		} else {
			window.open(stocksInTakenReportData.hireamtbalbankacproofcopy, '_blank');
		}
	}

	customPrintStocksInTkn() {
		if (this.stocksInTakenReportDataList.length == 0) {
			swal({
				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Invoice Number", "Truck No", "Supplier", "Tot Lrs", "Tot Ar", "Act Wht", "Chg Wht", "Gur. Wht", "Kanta Wht", "To Pay", "Paid", "Departure Date", "Dep TIme", "St Arrival Date", "St Arrival Time", "St Days Taken", "St Time Taken", "Scan Date", "Scan Time", "Scan Time Taken", "Rewarding H-Amt"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [8, 8, 4, 3, 3, 4, 4, 5, 5, 4, 4, 5, 4, 4, 6, 5, 5, 5, 5, 5, 4];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.totLrsSummary = this.totArtSummary = this.actWghtSummary = this.chdWtSummary = this.toPaySummary = this.paidSummary = this.rewardSummary = 0;
			for (let i = 0; i < this.stocksInTakenReportDataList.length; i++) {

				this.cashMemoDtoForCustomPrintListColumnValues = [this.stocksInTakenReportDataList[i].invoiceNumber, this.stocksInTakenReportDataList[i].vehicleNumber, this.stocksInTakenReportDataList[i].supplierName
					, this.stocksInTakenReportDataList[i].totalLRs, this.stocksInTakenReportDataList[i].totalArticles, this.stocksInTakenReportDataList[i].actWeight, this.stocksInTakenReportDataList[i].chargedwt,
				this.stocksInTakenReportDataList[i].gurWeight, this.stocksInTakenReportDataList[i].kantaWeight, this.stocksInTakenReportDataList[i].topay, this.stocksInTakenReportDataList[i].paid, this.stocksInTakenReportDataList[i].invoiceDateStr,
				this.stocksInTakenReportDataList[i].departureTime, this.stocksInTakenReportDataList[i].arrivalDateStr, this.stocksInTakenReportDataList[i].arrivalTime, this.stocksInTakenReportDataList[i].daysDiff, this.stocksInTakenReportDataList[i].todate,
				this.stocksInTakenReportDataList[i].scanDateStr, this.stocksInTakenReportDataList[i].scanTime, this.stocksInTakenReportDataList[i].timeTaken, this.stocksInTakenReportDataList[i].driverRewardAmt];
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
				this.totLrsSummary = this.totLrsSummary + this.stocksInTakenReportDataList[i].totalLRs;
				this.totArtSummary = this.totArtSummary + this.stocksInTakenReportDataList[i].totalArticles;
				this.actWghtSummary = this.actWghtSummary + this.stocksInTakenReportDataList[i].actWeight;
				this.chdWtSummary = this.chdWtSummary + this.stocksInTakenReportDataList[i].chargedwt;
				this.toPaySummary = this.toPaySummary + this.stocksInTakenReportDataList[i].topay;
				this.paidSummary = this.paidSummary + this.stocksInTakenReportDataList[i].paid;
				this.rewardSummary = this.rewardSummary + this.stocksInTakenReportDataList[i].driverRewardAmt;
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.stocksInTakenReportDataList.length, "", "", this.totLrsSummary, this.totArtSummary, this.actWghtSummary, this.chdWtSummary, "", "", this.toPaySummary, this.paidSummary, "", "", "", "", "", "", "", "", "", this.rewardSummary];
			}

			//heading logics For Date
			//this.todayDate=this.datePipe.transform(new Date(), "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];

			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.invoiceDtoSearch.fromdate, this.invoiceDtoSearch.todate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

			}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			//	localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "Stocks In Taken Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}
}