import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
//for datatable ends
//for modal starts
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert';
export interface DialogData {
    animal: string;
    name: string;
}
//for modal ends

@Component( {
    selector: 'app-pending-lr-details',
    templateUrl: './pending-lr-details.component.html',
    styleUrls: ['./pending-lr-details.component.css']
} )
export class PendingLrDetailsComponent implements OnInit {

    //for popup modal starts
    closeResultDatatable: string;
    //for popup modal ends
    //for datatable starts
    gettingDataFrmServiceFrPendingLrDetailsTable: any;


    pendingLrDetailsDataList: any;


    onDestroyUnsubscribtionPendingLrDetails: Subscription;

    //for datatable ends

    //summaryTable:any;

    loadingIndicator = true;

    //for datatable starts
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerPendingLrDetails: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsPendingLrDetails: any;
	dataForPopupFromLeftToPartyDetails:any;
	dataForPopupFromLeftToPartyOtherDetails:any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;
	getCollectionMan:any;
	getStmtNo:any;
	

    //for datatable ends
	pageId="pnldc";
    constructor(/* for datatable starts */private pendingLrDetailsScreen: DashboardService,  private router: Router,/* for datatable endss */
        //for modal starts
        public dialogRef: MatDialogRef<PendingLrDetailsComponent>,
        @Inject( MAT_DIALOG_DATA ) public data: DialogData, public changeDetectorRef : ChangeDetectorRef
        //for modal ends
    ) {
console.log(localStorage);
    	
	if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;	
				this.dataForPopupFromLeftToPartyDetails = JSON.parse( localStorage.getItem( 'leftToPartyForPopup' ) );
				this.dataForPopupFromLeftToPartyOtherDetails = JSON.parse( localStorage.getItem( 'leftToPartyForOtherPopup' ) );
				localStorage.clear();
				console.log('1');
				console.log(this.dataForPopupFromLeftToPartyOtherDetails,this.dataForPopupFromLeftToPartyDetails);
				 
				///For LeftToParty
				if(this.dataForPopupFromLeftToPartyOtherDetails !=null){
					$("#"+this.pageId+"pendingLrDetailsId").DataTable().destroy();
					this.pendingLrDetailsDataList = [];
					this.pendingLrDetailsDataList = this.dataForPopupFromLeftToPartyOtherDetails;
					this.dtTriggerPendingLrDetails.next();
					console.log(this.pendingLrDetailsDataList);
               
				}
				
				if(this.dataForPopupFromLeftToPartyDetails !=null){
					this.getCollectionMan=this.dataForPopupFromLeftToPartyDetails.collectionMan;
					this.getStmtNo=this.dataForPopupFromLeftToPartyDetails.statementNo;
				}
				
					this.dataForPopupFromLeftToPartyOtherDetails=[];
					this.dataForPopupFromLeftToPartyDetails='';
						
		}

    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
  
	  /* for datatable ends */
	 ngOnInit(): void {
		 var companyAddressDetls = this.address;
        this.dtOptionsPendingLrDetails = {
        		dom: 'Bfrtip',
        		  buttons: [
        					{
        						extend: 'print',
        						text: '<i class="fas fa-print">Print</i>',
        						titleAttr: 'Print',
        						customize : function(win) {
        							$(win.document.body).css('font-size','10pt');
        							$(win.document.body).find('th, td').
        							css('font-family','Arial, Helvetica, sans-serif')
        							.css('font-size','13px').css('text-align','center');
        						},
        						footer : true,
        						messageTop : function() {
        							var returnValOverAll=null;
        								returnValOverAll ="<br><table style='width: 100%;'>" +
        													"<tr>" +
        													"<td style='width: 20%;'>" +
        													"<table>" +
        													"<tr>" +
        													"<td width='8%' style='text-align:left !important;'>" +
        													"<strong style='font-size:15px;'></strong>" +
        													"</td>" +
        													"<td width='2%' style='text-align:left !important;'>" +
        													"<strong style='font-size:15px;'></strong>" +
        													"</td>" +
        													"<td width='10%' style='text-align:left !important;'>" +
        													"<strong style='font-size:15px;'></strong>" +
        													"</td>" +
        													"</tr>" +
        													"</table>" +
        													"</td>" +
        													"<td rowspan='2'style='width:60%;word-wrap: break-word;text-align:left !important;'align='left'>" +
        													"<strong style='font-size:23px;'><u>Pending Lr Details</u></strong><br>" +
        													"</td>" +
        													"<td  align='left' style='width:20%;'>" +
        													"</td>" +
        													"</tr>" +
        													"</table><br>";
        									return returnValOverAll;
        									
        							},
        						title: function () {
        							return "<table style='table-layout: fixed;width: 100%;'><tr>" +
        								"<td align='left' style='width: 20%;'>" +
        								"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
        								"</td>" +
        								"<td align='left' style='width: 30%;'>" +
        								"</td>" +
        								"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
        								companyAddressDetls +
        								"</td>" +
        								"</tr></table>";
        						}
        					},{
    							extend: 'excel',
    							text: '<i class="fas fa-file-excel"> Excel</i>',
    							titleAttr: 'Excel',
    							footer : true,
    							
    							title : function() {
    										
    							var returnSummaryExcelCashmemoStockSum=null;
    									returnSummaryExcelCashmemoStockSum =" Pending Lr Details ";
    									
    								return returnSummaryExcelCashmemoStockSum;
    							}
    						}
        				],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                pagingType: 'full_numbers',
                pageLength: 5,
                
                processing: true,
                "scrollX": true,
                "scrollCollapse": true,

      			
				"footerCallback": function ( row, data, start, end, display ) {
      				var api = this.api(), data;
      				// converting to interger to find total
      				var intVal = function ( i ) {
      					return typeof i === 'string' ?
      					+i.replace(/[\$,]/g, '') * 1:
      						typeof i === 'number' ?
      							i : 0;
      				};  
				
				var totAmt = api.column( 5 ).data().reduce( 
				function (a, b) {
							 return intVal(a) + intVal(b);
				}, 0 );	
				
				$( api.column( 0 ).footer() ).html('Total : '+data.length);
				$( api.column( 1 ).footer() ).html();
				$( api.column( 2 ).footer() ).html();
				$( api.column( 3 ).footer() ).html();
				$( api.column( 4 ).footer() ).html();
				$( api.column( 5 ).footer() ).html(totAmt);
				
             } 
        }
          
           
    }

    ngOnDestroy(): void {
        this.dtTriggerPendingLrDetails.unsubscribe();
     
    }
    ngAfterViewInit(): void {
        this.dtTriggerPendingLrDetails.next();

    }

    //for modal starts
    onCancelClick(): void {
        this.dialogRef.close();
    }
    //for modal ends
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

}
