import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef} from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';

import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ConsignorService } from 'src/app/dataService/consignor-service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { DragulaService, dragula } from "ng2-dragula";
//for drag and drop ends
//for popup modal starts 
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from "src/app/dataService/memo-service";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MemoReport } from 'src/app/dataService/memo-report';
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment from 'moment';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { MasterService } from "src/app/dataService/master-service";
import { InvoiceDto } from "src/app/dto/Invoice-dto";

@Component({
    selector: 'app-commodity-merge',
    templateUrl: './commodity-merge.component.html',
    styleUrls: ['./commodity-merge.component.css']
})
export class CommodityMergeComponent implements OnInit {

    //to insert value of selected row in table to input field starts 

    selectedUser: any;
    //to insert value of selected row in table to input field ends

    getDataFrmServiceFrRepeatedCommodityListTable: any;
    getDataFrmServiceFrMergeCommodityListTable: any;
    getDataFrmServiceFrConfirmedCommodityListTable: any;

    repeatedCommodityListDataList: any;
    mergeCommodityListDataList: any;
    confirmedCommodityListDataList: any;


    onDestroyUnsubscribtionRepeatedCommodityList: Subscription;
    onDestroyUnsubscribtionMergeCommodityList: Subscription;
    onDestroyUnsubscribtionConfirmedCommodityList: Subscription;
    //for the select option with filter starts


    controlDestination = new FormControl();
    controlConsignorIndex = new FormControl();

    destinationOptions = [
        { id: 1, label: 'Agra' },
        { id: 2, label: 'Bangalore' },
        { id: 3, label: 'Chennai' },
        { id: 4, label: 'Delhi' },
        { id: 5, label: 'Erode' },
    ];

    consignorIndexOptions = [
        { id: 1, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
    ];


    //for the select option with filter ends

    //for popup modal starts
    closeResultContent: string;
    //for popup modal ends




    //summaryTable:any;

    loadingIndicator = true;


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;



    dataTable: any;


    //

    dtTriggerRepeatedCommodityList: Subject<any> = new Subject();
    dtTriggerMergeCommodityList: Subject<any> = new Subject();
    dtTriggerConfirmedCommodityList: Subject<any> = new Subject();

    //


    // @ViewChild('dataTable') table: ElementRef;
    dtOptionsRepeatedCommodityList: any;
    dtOptionMergeCommodityList: any;
    dtOptionConfirmedCommodityList: any;
    mainStn: any;
    address: any;
    office: any;
    destView = false;
    commodityName: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction = false;
    public modelDestinationFotGet: any;
    lrDtoToGetRepeatedCommodityList: LRDto = new LRDto();
    lrDtoToGetConfirmedCommodityList: LRDto = new LRDto();
    lrDtoList: Array<LRDto> = [];
    oldCommodityName: any;
    private newAttributeForMerge: any = {};
    private newAttributeForRepeat: any = {};
    selectedCommodityName: any;
    lrDtoListForMerge: Array<LRDto> = [];
    lrDtoMergeBtnList: LRDto = new LRDto();
    lrDtoMergeBtnSingle: LRDto = new LRDto();
    pageId="cmmgc";



    constructor(private masterService: MasterService,private router: Router, private modalService: NgbModal, private masterReadService: MasterReadService, private datePipe: DatePipe, private consigneeService: ConsigneeService, private reportService: ReportService, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;
            this.mainStn = this.userDataDtoReturnSession.mainStation == null ? '' : this.userDataDtoReturnSession.mainStation;

            this.getCommodityConfirmedList();
            this.mergeCommodityListDataList = [];


        }

    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    ngOnInit(): void {

        //first datatable starts
        this.dtOptionsRepeatedCommodityList = {
            // the below code is for button export starts
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            "paging": false,
            "info": false,

        },//first datatable ends
            //second datatable starts 
            this.dtOptionMergeCommodityList = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 330,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
            },
            //second datatable ends
            //third datatable starts

            this.dtOptionConfirmedCommodityList = {
                // the below code is for button export starts
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 350,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
            }
    }


    ngOnDestroy(): void {
        this.dtTriggerRepeatedCommodityList.unsubscribe();
        this.dtTriggerMergeCommodityList.unsubscribe();
        this.dtTriggerConfirmedCommodityList.unsubscribe();
    }

    ngAfterViewInit(): void {
        // this.dtTriggerRepeatedCommodityList.next();
        this.dtTriggerMergeCommodityList.next();
        this.dtTriggerConfirmedCommodityList.next();

    }

    //for the select option with filter ends
    //to insert value of selected row in table to input field starts
    RowSelected(confirmedCommodityListData) {
        this.selectedUser = confirmedCommodityListData;
        this.commodityName = this.selectedUser.chequeNumber;
        console.log(this.selectedUser);
    }
    rowCheckBoxChecked(e, repeatedCommodityListData) {
        if (e.currentTarget.checked) {
            if (this.lrDtoList.length == 0) {
                this.lrDtoList = [];

                this.lrDtoList.push(repeatedCommodityListData);
            } else {
                this.lrDtoList.push(repeatedCommodityListData);
            }

        } else {
            let index = this.lrDtoList.indexOf(repeatedCommodityListData);
            if (index > -1) {
                this.lrDtoList.splice(index, 1);
            }

        }
        console.log(this.lrDtoList);
    }



    moveToMergeMethod() {
        if (this.lrDtoList.length == 0) {
            swal({
                title: "Empty Selection",
                text: "Please select commodity  to Move",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.addInMergeTable();

        }

    }

    addInMergeTable() {
        console.log(this.lrDtoList);
        console.log('inside of addInMergeTable')
        this.newAttributeForMerge = {};
        for (let i = 0; i < this.lrDtoList.length; i++) {
            console.log(this.lrDtoList[i].commodityName);
            this.newAttributeForMerge.commodityName = this.lrDtoList[i].commodityName;

            $("#"+this.pageId+"mergeCommodityId").DataTable().destroy();
            if (this.mergeCommodityListDataList == null) {
                this.mergeCommodityListDataList = [];
            }
            if (this.mergeCommodityListDataList.length == 0) {
                this.mergeCommodityListDataList.push(this.newAttributeForMerge);
            } else {
                this.mergeCommodityListDataList.push(this.newAttributeForMerge);
            }
            //this.dtTriggerMergeConsigneeList.next();
            console.log(this.mergeCommodityListDataList);
            //For Removing
            let index = -1;
            for (let j = 0; j < this.repeatedCommodityListDataList.length; j++) {

                if (this.repeatedCommodityListDataList[j].commodityName === this.lrDtoList[i].commodityName) {
                    console.log(this.repeatedCommodityListDataList[j].commodityName);
                    index = j;
                    break;
                }
            }
            if (index === -1) {
                alert("Something gone wrong");
            }
            this.repeatedCommodityListDataList.splice(index, 1);

            this.newAttributeForMerge = {};
        }
        this.lrDtoList = [];
        this.dtTriggerMergeCommodityList.next();
        $("#"+this.pageId+"repeatedCommodityId").DataTable().destroy();
        this.dtTriggerRepeatedCommodityList.next();



    }
    rowSelectedFromMerge(mergeCommodityListData) {
    }


    rowSelectedForUndo(confirmedCommodityListData) {
        // this.selectedCommodityName=confirmedCommodityListData.commodityName;
        this.commodityName = confirmedCommodityListData.commodityName;

    }

    // For Delete From Merge Table
    rowSelectedDeleteForMerge(mergeCommodityListData, index) {
        console.log(mergeCommodityListData);
        swal({
            title: "Confirm Remove",
            text: "Sure you want to remove the selected Row",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((remove) => {
            if (remove) {
                this.mergeCommodityListDataList.splice(index, 1);
                $("#"+this.pageId+"mergeCommodityId").DataTable().destroy();
                this.dtTriggerMergeCommodityList.next();
                $("#"+this.pageId+"commodityNameConfirmed").val('');
                this.oldCommodityName = '';

                this.addInRepeatedCommodityTable(mergeCommodityListData);

            }

        });
    }
    //to insert value of selected row in table to input field ends
    //for popup modal starts 

    openPopup(content) {
        this.modalService.open(content, { centered: true }).result.then(
            resultContent => {
                this.closeResultContent = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContent = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    //for popup modal ends

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        inp._elementRef.nativeElement.focus();
    }

    getCommodityRepeatedList() {
        this.lrDtoToGetRepeatedCommodityList = new LRDto();
        this.lrDtoToGetRepeatedCommodityList.mode = "repeatedCmty";
        this.lrDtoToGetRepeatedCommodityList.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoToGetRepeatedCommodityList);
        this.getCommodityRepeatedDetails();
    }

    getCommodityRepeatedDetails = () => {
        this.showSpinnerForAction = true;
        this.reportService.getRepeatedCommodity(this.lrDtoToGetRepeatedCommodityList).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#"+this.pageId+"repeatedCommodityId").DataTable().destroy();
                this.repeatedCommodityListDataList = [];

                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.repeatedCommodityListDataList = response;
                }
                this.dtTriggerRepeatedCommodityList.next();
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Repeated Commodity Details", "info");
            }, () => console.log('done');
    };

    getCommodityConfirmedList() {
        this.lrDtoToGetConfirmedCommodityList = new LRDto();
        this.lrDtoToGetConfirmedCommodityList.mode = "ConfirmCmty";
        this.lrDtoToGetConfirmedCommodityList.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoToGetConfirmedCommodityList);
        this.getCommodityConfirmedDetails();
    }

    getCommodityConfirmedDetails = () => {
        this.showSpinnerForAction = true;
        this.reportService.getRepeatedCommodity(this.lrDtoToGetConfirmedCommodityList).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#"+this.pageId+"confirmedCommodityId").DataTable().destroy();
                this.confirmedCommodityListDataList = [];

                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.confirmedCommodityListDataList = response;
                }
                this.dtTriggerConfirmedCommodityList.next();
                this.getCommodityRepeatedList();
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Repeated Commodity Details", "info");
            }, () => console.log('done');
    };

    addInRepeatedCommodityTable(mergeCommodityListData) {
        console.log(mergeCommodityListData);
        console.log('inside of addInRepeatedCommodityTable')
        this.newAttributeForRepeat = {};

        this.newAttributeForRepeat.commodityName = mergeCommodityListData.commodityName;

        $("#"+this.pageId+"repeatedCommodityId").DataTable().destroy();
        if (this.repeatedCommodityListDataList == null) {
            this.repeatedCommodityListDataList = [];
        }
        if (this.repeatedCommodityListDataList.length == 0) {
            this.repeatedCommodityListDataList.push(this.newAttributeForRepeat);
        } else {
            this.repeatedCommodityListDataList.push(this.newAttributeForRepeat);
        }
        //this.dtTriggerMergeConsigneeList.next();
        console.log(this.dtTriggerRepeatedCommodityList);

        this.newAttributeForRepeat = {};
        this.lrDtoList = [];
        this.dtTriggerRepeatedCommodityList.next();
    }

    mergeBtnMethod() {
        this.selectedCommodityName = $("#"+this.pageId+"commodityNameConfirmed").val();
        if (this.mergeCommodityListDataList.length == 0 || this.selectedCommodityName == null || this.selectedCommodityName == '' || this.selectedCommodityName == undefined) {

            swal({
                title: "Empty Selection",
                text: "Please select commodity name to merge",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Confirm Merge",
                text: "Sure you want to Merge the selected commodity,It will Take Some time to merge",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((update) => {
                if (update) {
                    this.getUserValuesForMerge();

                } else {
                    //do nothing
                }
            });

        }
    }

    getUserValuesForMerge() {
        this.lrDtoListForMerge = [];
        this.selectedCommodityName = $("#"+this.pageId+"commodityNameConfirmed").val();

        if (this.selectedCommodityName != null && this.selectedCommodityName != '') {
            this.selectedCommodityName = this.selectedCommodityName.trim().split(' ').filter(item => item.length > 0).join(' ');
        }
        for (let i = 0; i < this.mergeCommodityListDataList.length; i++) {
            this.lrDtoMergeBtnList = new LRDto();
            this.lrDtoMergeBtnList.commodityName = this.mergeCommodityListDataList[i].commodityName;
            this.lrDtoListForMerge.push(this.lrDtoMergeBtnList);
        }
        this.lrDtoMergeBtnSingle = new LRDto();
        this.lrDtoMergeBtnSingle.commodityName = this.selectedCommodityName;
        this.lrDtoMergeBtnSingle.userName = this.userDataDtoReturnSession.userId;
        // this.lrDtoMergeBtnSingle.oldCommodityName=this.oldCommodityName;
        this.lrDtoMergeBtnSingle.lrDtos = this.lrDtoListForMerge;
        this.lrDtoMergeBtnSingle.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoMergeBtnSingle);
        //Need to comment this below 
        this.doMerge();


    }

    doMerge = () => {
        this.showSpinnerForAction = true;
        console.log('Inside Do merge method');
        console.log(this.lrDtoMergeBtnSingle);
        this.reportService.commdityMerge(this.lrDtoMergeBtnSingle).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.status == 'Success') {
                    $("#"+this.pageId+"mergeCommodityId").DataTable().destroy();
                    this.mergeCommodityListDataList = [];
                    this.dtTriggerMergeCommodityList.next();
                    this.getCommodityConfirmedList();

                    $("#"+this.pageId+"commodityNameConfirmed").val('');
                    this.oldCommodityName = '';
                    this.commodityName = '';
                    this.lrDtoList = [];
                    this.lrDtoListForMerge = [];
                    this.selectedCommodityName = '';
                    this.lrDtoMergeBtnSingle = new LRDto();
                    this.lrDtoMergeBtnList = new LRDto();
                    this.newAttributeForRepeat = {};
                    this.newAttributeForMerge = {};
                    swal({
                        title: "Success",
                        text: "Merged Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });


                } else if (response.status == 'Failed') {
                    swal({
                        title: "Failed",
                        text: "Problem occur while confirm the Commodity",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,

                    });

                }

                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred while confirm the Commodity", "info");
            }, () => console.log('done');
    };

}
