import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
//for datepicker starts 
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
//for datepicker ends 
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
//for popup modal endss 
import { Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';

export interface DialogData {
    animal: string;
    name: string;
}
//for popup modal starts 
import { DataTableDirective } from "angular-datatables";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { ViewChildren, QueryList } from '@angular/core';
import { NgModule } from '@angular/core';
//for the select option with filter starts
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';

//for the select option with filter ends
//for service starts
import { MemoService } from "src/app/dataService/memo-service";
import { Router } from "@angular/router";
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
//for service ends
import { ElementRef } from "@angular/core";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { LrService } from 'src/app/dataService/lr-service';
@Component({
    selector: 'app-miss-agents',
    templateUrl: './miss-agents.component.html',
    styleUrls: ['./miss-agents.component.css']
})
export class MissAgentsComponent implements OnInit {
    closeResult: string;


    //added for dummy
    animal: string;
    name: string;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    pageId="msac";


    @ViewChild('content') content: TemplateRef<any>;
    constructor(private modalService: NgbModal,
        public dialog: MatDialog,
        private router: Router, public changeDetectorRef : ChangeDetectorRef

    ) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })

        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

        }
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(MissAgentsPopupComponent);

        // dialogRef.afterClosed().subscribe(result => {
            //            console.log( 'The dialog was closed' );
            //            this.animal = result;
        // });
    }

    ngOnInit() {
        //        this.modalService.open( this.content );
        setTimeout(() => {
            this.openDialog();
        }, 0);
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}


@Component({
    selector: 'app-miss-agents-popup',
    templateUrl: './miss-agents-popup.component.html',
    styleUrls: ['./miss-agents.component.css']
})
export class MissAgentsPopupComponent implements OnInit {

    closeResult: string;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    lRCountsValues: any = 0;
    showSpinnerForAction = false;
    //For Agent Name
    agentNameDtoForAll: AgentDetailsDto = new AgentDetailsDto();
    focusAgentNameTA$ = new Subject<string>();
    clickAgentNameTA$ = new Subject<string>();
    @ViewChild('instanceAgentName') instanceAgentName: NgbTypeahead;
    agentNameTA: Array<any> = [];
    agentNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { subStation: string }) => x.subStation;
    controlAgentName = new FormControl();
    agentDetailsOptionsGet: AgentDetailsDto[];
    agentDetailsOptionsSet: any;
    @ViewChild("agentName") agentNameField: ElementRef;
    agentNameList: Array<any> = [];
    agentDtoParam: AgentDetailsDto = new AgentDetailsDto();
    lrDtoForValidate: LRDto = new LRDto();
    selectedAgentName: any;
    lrDtoForLrCheck: LRDto = new LRDto();
    enteredLrNumber: any;
    lrDtoForLrRet = new LRDto();
    lrDtoForNextBtn: LRDto = new LRDto();
    lrDtoForNextBtnRet: LRDto = new LRDto();
    enteredRemarks: any;
    showSearchView = false;
    enteredLrNumberForSearchView: any;
    lrDtoForValidateForSearchView: LRDto = new LRDto();
    lrDtoForValidateForSearchViewRet: LRDto = new LRDto();
    lrDtoForValidateForBlockUnBlock: LRDto = new LRDto();
    lrDtoForValidateForBlockUnBlockRet: LRDto = new LRDto();
    pageId="msapc";
    constructor(
        //for modal starts
        public dialogRef: MatDialogRef<MissAgentsPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, 
        //for modal ends
        private router: Router, private memoService: MemoService, private masterReadService: MasterReadService, private lrService: LrService, public changeDetectorRef : ChangeDetectorRef
    ) {


        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

            this.getAgentDetailList();
            ///Rights


            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {

                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "LRSearch LrEntryMissAgt") {
                        this.showSearchView = true;
                    }

                }
            }

            /////

        }
    }


    ngOnInit() {
    }
    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }




    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    //for the select option with filter ends
    //For Agent Names
    getUserValuesForAgentDetailsList() {
        this.agentDtoParam.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        console.log(this.agentDtoParam.mainStation);
        this.agentDtoParam.column2 = 'Working';
        this.agentDtoParam.status = 'Working';

        this.agentDtoParam.companyId = this.userDataDtoReturnSession.companyId;
        this.agentDtoParam.mode = 'Sub';
    }
    getAgentDetailList() {
        this.getUserValuesForAgentDetailsList();

        this.masterReadService.getSourceListDetails(this.agentDtoParam).subscribe(
            (response) => {
                if (response.length == 0) {
                    console.log(response);
                    swal({
                        title: "Warning",
                        text: "No Agents  records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.agentDetailsOptionsGet = [];
                    this.agentNameTA = [];
                    this.agentDetailsOptionsGet = response;
                    this.agentDetailsOptionsSet = response;
                    //                   
                    console.log(this.agentDetailsOptionsGet);
                    this.agentNameList = [];
                    // this.agentNameDtoForAll.subStation = "ALL";
                    // this.agentNameTA.push(this.agentNameDtoForAll);

                    for (let i = 0; i < this.agentDetailsOptionsGet.length; i++) {
                        //console.log( this.agentDetailsOptionsGet[i].subStation );
                        this.agentNameTA.push(this.agentDetailsOptionsGet[i]);
                        this.agentNameList.push(this.agentDetailsOptionsGet[i].subStation);
                    }

                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    }


    validateLrNumber(e) {
        if (e.keyCode == 13) {
            this.enteredLrNumber = $("#"+this.pageId+"lrNumber").val();
            if (this.enteredLrNumber != null && this.enteredLrNumber != '') {
                this.lrEntryMissAgent();
            } else {
                swal({
                    title: "Mandetory",
                    text: "Please Enter the Lr Number !",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            }

        }
    }
    lrEntryMissAgent() {
        this.enteredLrNumber = $("#"+this.pageId+"lrNumber").val();
        this.lrDtoForValidate = new LRDto();
        this.lrDtoForValidate.lrNumber = this.enteredLrNumber;
        this.lrDtoForValidate.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoForValidate);
        this.lrEntryMissAgentDetails();
    }

    lrEntryMissAgentDetails = () => {
        this.showSpinnerForAction = true;
        this.lrService.lrEntryMissAgent(this.lrDtoForValidate).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.status === "exists") {
                    swal({
                        title: "Alert",
                        text: "This LR Number:  " + this.lrDtoForValidate.lrNumber
                            + " already exists",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        $("#"+this.pageId+"lrNumber").val('');
                        window.setTimeout(function () {
                            $('#'+this.pageId+'lrNumber').focus();
                        }, 100);
                    });
                } else if (response.status === "not exists") {
                    this.lrNumberChk();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Ooops..Problem occur while validating LR Details.", "info");
            }, () => console.log('done');
    };

    lrNumberChk() {
        this.enteredLrNumber = $("#"+this.pageId+"lrNumber").val();
        this.selectedAgentName = $("#"+this.pageId+"agentName").val();

        this.lrDtoForLrCheck = new LRDto();
        this.lrDtoForLrCheck.lrNumber = this.enteredLrNumber;
        this.lrDtoForLrCheck.fromLrNo = this.enteredLrNumber;
        this.lrDtoForLrCheck.agentName = this.selectedAgentName;
        this.lrDtoForLrCheck.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoForLrCheck);
        this.lrCheckDetails();

    }


    lrCheckDetails = () => {
        this.showSpinnerForAction = true;
        this.lrService.addLRNumber(this.lrDtoForLrCheck).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.lrDtoForLrRet = new LRDto();
                this.lrDtoForLrRet = response
                if (this.lrDtoForLrRet.status === "exists") {
                    swal({
                        title: "Alert",
                        text: "This LR Number:  " + this.lrDtoForLrCheck.lrNumber
                            + " already exists",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        $("#"+this.pageId+"lrNumber").val('');
                        window.setTimeout(function () {
                            $('#'+this.pageId+'lrNumber').focus();
                        }, 100);
                    });
                } else if (this.lrDtoForLrRet.status === "RangeNotExist") {
                    swal({
                        title: "Alert",
                        text: "This LR Number:  " + this.lrDtoForLrCheck.lrNumber
                            + " not exists in LRIssue",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        $("#"+this.pageId+"lrNumber").val('');
                        window.setTimeout(function () {
                            $('#'+this.pageId+'lrNumber').focus();
                        }, 100);
                    });
                } else if (this.lrDtoForLrRet.status === "Other'sLr") {
                    swal({
                        title: "Alert",
                        text: "This LR Number:  " + this.lrDtoForLrCheck.lrNumber
                            + " is belongs to " + this.lrDtoForLrRet.agentName,
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        $("#"+this.pageId+"lrNumber").val('');
                        window.setTimeout(function () {
                            $('#'+this.pageId+'lrNumber').focus();
                        }, 100);
                    });
                } else if (this.lrDtoForLrRet.status === "RangeExist") {
                    window.setTimeout(function () {
                        $('#'+this.pageId+'remarks').focus();
                    }, 100);

                } else {
                    $("#"+this.pageId+"lrNumber").val('');
                    window.setTimeout(function () {
                        $('#'+this.pageId+'lrNumber').focus();
                    }, 100);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  Checking the LR Issue details", "info");
            }, () => console.log('done');
    };

    btnNextValidation() {
        this.enteredLrNumber = $("#"+this.pageId+"lrNumber").val();
        this.selectedAgentName = $("#"+this.pageId+"agentName").val();
        this.enteredRemarks = $("#"+this.pageId+"remarks").val();
        if (this.enteredLrNumber != null && this.enteredLrNumber != '' && this.selectedAgentName != null && this.selectedAgentName != '') {

            this.lrDtoForNextBtn = new LRDto();
            this.lrDtoForNextBtn.lrNumber = this.enteredLrNumber;
            this.lrDtoForNextBtn.agentName = this.selectedAgentName;
            this.lrDtoForNextBtn.remarks = this.enteredRemarks;
            this.lrDtoForNextBtn.userName = this.userDataDtoReturnSession.userId;
            this.lrDtoForNextBtn.companyId = this.userDataDtoReturnSession.companyId;
            console.log(this.lrDtoForNextBtn);
            this.lrNextDetails();
        } else {
            swal({
                title: "Mandetory",
                text: "Please Enter the Lr Number !",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }
    }

    lrNextDetails = () => {
        this.showSpinnerForAction = true;
        this.lrService.addLREntryMissAgent(this.lrDtoForNextBtn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.lrDtoForNextBtnRet = new LRDto();
                this.lrDtoForNextBtnRet = response;
                if (this.lrDtoForNextBtnRet.status === "inLrDetails") {
                    swal({
                        title: "Warning",
                        text: "LR No is Already Exist,So Please Delete LR No to Block",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else if (this.lrDtoForNextBtnRet.status === "inLrEntryMissAgent") {
                    swal({
                        title: "Warning",
                        text: "LR No is Already Exist In Lr Entry Miss Agent",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else if (this.lrDtoForNextBtnRet.status === "success") {
                    this.enteredLrNumber = '';
                    $("#"+this.pageId+"lrNumber").val('');
                    this.enteredRemarks = '';
                    $("#"+this.pageId+"remarks").val('');
                    window.setTimeout(function () {
                        $('#'+this.pageId+'lrNumber').focus();
                    }, 100);
                    this.lRCountsValues = +this.lRCountsValues + 1;

                } else if (this.lrDtoForNextBtnRet.status === "Failed") {
                    swal({
                        title: "Error",
                        text: "Error While Saving LRDetails !",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();


            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Error While Saving LRDetails", "info");
            }, () => console.log('done');
    };

    btnFinishMethod() {

        this.enteredLrNumber = '';
        $("#"+this.pageId+"lrNumber").val('');
        this.enteredRemarks = '';
        $("#"+this.pageId+"remarks").val('');
        window.setTimeout(function () {
            $('#'+this.pageId+'lrNumber').focus();
        }, 100);
        this.lRCountsValues = 0;
        this.lrDtoForNextBtnRet = new LRDto();
        this.lrDtoForNextBtn = new LRDto();
        this.lrDtoForLrRet = new LRDto();
        this.lrDtoForValidate = new LRDto();
        this.lrDtoForLrCheck = new LRDto();
        this.lrDtoForValidateForSearchView = new LRDto();
        this.enteredLrNumberForSearchView = '';
        $("#"+this.pageId+"lrNumberForSearchView").val('');
        this.lrDtoForValidateForSearchViewRet = new LRDto();
        this.lrDtoForValidateForBlockUnBlock = new LRDto();
        this.lrDtoForValidateForBlockUnBlockRet= new LRDto();
    }

    onCloseClick(): void {
        this.dialogRef.close();
        this.enteredLrNumber = '';
        $("#"+this.pageId+"lrNumber").val('');
        this.enteredRemarks = '';
        $("#"+this.pageId+"remarks").val('');
        window.setTimeout(function () {
            $('#'+this.pageId+'lrNumber').focus();
        }, 100);
        this.lRCountsValues = 0;
        this.lrDtoForNextBtnRet = new LRDto();
        this.lrDtoForNextBtn = new LRDto();
        this.lrDtoForLrRet = new LRDto();
        this.lrDtoForValidate = new LRDto();
        this.lrDtoForLrCheck = new LRDto();
        this.lrDtoForValidateForSearchView = new LRDto();
        this.enteredLrNumberForSearchView = '';
        $("#"+this.pageId+"lrNumberForSearchView").val('');
        this.lrDtoForValidateForSearchViewRet = new LRDto();
        this.lrDtoForValidateForBlockUnBlock = new LRDto();
        this.lrDtoForValidateForBlockUnBlockRet= new LRDto();


    }

    validateLrNumberForSearchView(e) {
        if (e.keyCode == 13) {
            this.enteredLrNumberForSearchView = $("#"+this.pageId+"lrNumberForSearchView").val();
            if (this.enteredLrNumberForSearchView != null && this.enteredLrNumberForSearchView != '') {
                this.lrEntryMissAgentDetailsForSearchView();
            } else {
                swal({
                    title: "Mandetory",
                    text: "Please Enter the Lr Number !",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            }

        }
    }
    lrEntryMissAgentDetailsForSearchView() {
        this.enteredLrNumberForSearchView = $("#"+this.pageId+"lrNumberForSearchView").val();
        this.lrDtoForValidateForSearchView = new LRDto();
        this.lrDtoForValidateForSearchView.lrNumber = this.enteredLrNumberForSearchView;
        this.lrDtoForValidateForSearchView.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoForValidateForSearchView);
        this.lrEntryMissAgentDetailsForSearch();
    }

    lrEntryMissAgentDetailsForSearch = () => {
        this.showSpinnerForAction = true;
        this.lrService.getLREntryMissAgent(this.lrDtoForValidateForSearchView).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.lrDtoForValidateForSearchViewRet = new LRDto();
                this.lrDtoForValidateForSearchViewRet = response;
                if (this.lrDtoForValidateForSearchViewRet.status === "exists") {
                    $("#"+this.pageId+"remarks").val(this.lrDtoForValidateForSearchViewRet.remarks);
                    $("#"+this.pageId+"agentName").val(this.lrDtoForValidateForSearchViewRet.agentName);
                    if (this.lrDtoForValidateForSearchViewRet.blocked == true) {
                        $("#"+this.pageId+"blockBtn").prop("disabled", true);
                        $("#"+this.pageId+"unBlockBtn").prop("disabled", false);
                    } else {
                        $("#"+this.pageId+"blockBtn").prop("disabled", false);
                        $("#"+this.pageId+"unBlockBtn").prop("disabled", true);
                    }

                } else if (this.lrDtoForValidateForSearchViewRet.status === "notexists") {
                    swal({
                        title: "Result",
                        text: "No Datas Found",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    $("#"+this.pageId+"lrNumberForSearchView").val('');
                    $("#"+this.pageId+"remarks").val('');
                    $("#"+this.pageId+"agentName").val('');
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Ooops..Problem occur while Searching LR Details.", "info");
            }, () => console.log('done');
    };


    btnBlockMethod() {

        this.enteredLrNumberForSearchView = $("#"+this.pageId+"lrNumberForSearchView").val();
        if (this.enteredLrNumberForSearchView != null && this.enteredLrNumberForSearchView != '') {
            this.setLRinLrEntryMissAgt("Block");
        } else {
            swal({
                title: "Mandetory",
                text: "Please Enter the Lr Number !",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }


    }

    btnUnBlockMethod() {

        this.enteredLrNumberForSearchView = $("#"+this.pageId+"lrNumberForSearchView").val();
        if (this.enteredLrNumberForSearchView != null && this.enteredLrNumberForSearchView != '') {
            this.setLRinLrEntryMissAgt("UnBlock");
        } else {
            swal({
                title: "Mandetory",
                text: "Please Enter the Lr Number !",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }


    }

    setLRinLrEntryMissAgt(status) {
        this.enteredLrNumberForSearchView = $("#"+this.pageId+"lrNumberForSearchView").val();
        this.lrDtoForValidateForBlockUnBlock = new LRDto();
        this.lrDtoForValidateForBlockUnBlock.lrNumber = this.enteredLrNumberForSearchView;
        this.lrDtoForValidateForBlockUnBlock.mode = status;
        this.lrDtoForValidateForBlockUnBlock.userName = this.userDataDtoReturnSession.userId;
        this.lrDtoForValidateForBlockUnBlock.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoForValidateForBlockUnBlock);
        this.lrEntryMissAgentDetailsForBtnBlockUnBlock();
    }

    lrEntryMissAgentDetailsForBtnBlockUnBlock = () => {
        this.showSpinnerForAction = true;
        this.lrService.getLREntryMissAgent(this.lrDtoForValidateForBlockUnBlock).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.lrDtoForValidateForBlockUnBlockRet = new LRDto();
                this.lrDtoForValidateForBlockUnBlockRet = response;
                if (this.lrDtoForValidateForBlockUnBlockRet.status === "exists") {
                    $("#"+this.pageId+"lrNumberForSearchView").val('');
                    $("#"+this.pageId+"remarks").val('');
                    $("#"+this.pageId+"agentName").val('');

                    swal({
                        title: "success",
                        text: "Success",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else if (this.lrDtoForValidateForBlockUnBlockRet.status === "notexists") {
                    swal({
                        title: "Result",
                        text: "No Datas Found",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    $("#"+this.pageId+"lrNumberForSearchView").val('');
                    $("#"+this.pageId+"remarks").val('');
                    $("#"+this.pageId+"agentName").val('');
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Ooops..Problem occur while Searching LR Details.", "info");
            }, () => console.log('done');
    };

}

