import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";


//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { LRDto } from 'src/app/dto/LR-dto';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { MasterReadService } from "src/app/dataService/masterread-service";


@Component( {
    selector: 'app-consignments-claim-settlement-entry',
    templateUrl: './consignments-claim-settlement-entry.component.html',
    styleUrls: ['./consignments-claim-settlement-entry.component.css']
} )
export class ConsignmentsClaimSettlementEntryComponent implements OnInit {

    gettingDataFrmServiceFrConsignmentsClaimSettlementEntryTable: any;


    consignmentsClaimSettlementEntryDataList: any;


    //    onDestroyUnsubscribtionConsignmentsClaimSettlementEntry: Subscription;

    //for input field hide and show starts
    cheque = false;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    //for input field hide and show starts
    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;
    lrDto: LRDto = new LRDto();
    lrDtoSaveRet: any;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    consignmentId = 0;
    //for datepicker ends

    //for the select option with filter starts

    control = new FormControl();

    sourceOptions = [
        { id: 1, label: 'All' },
        { id: 2, label: 'Delhi' },
        { id: 3, label: 'Gujarat' },
        { id: 4, label: 'Mimbai' }
    ];



    //for the select option with filter ends



    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerConsignmentsClaimSettlementEntry: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsConsignmentsClaimSettlementEntry: any;
    pageId="ccsec";


    constructor(/* for datatable starts */private masterService: MasterService, public changeDetectorRef : ChangeDetectorRef, /* for datatable endss */

        //  service starts
        private http: HttpClient,
        private masterReadService: MasterReadService,
        private router: Router
        //            service ends
    ) {
        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            //            sweet alert starts
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
            //            sweet alert ends
        }
        if ( this.isLoggedIn ) {
            //          userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );

        }

    }
    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }



    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }







    ngOnInit(): void {

    }

    consignmentsCliamSettlementEntryDataTable() {
        this.dtOptionsConsignmentsClaimSettlementEntry = {
            //};


            // the below code is for button export starts
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }

            ],

            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 200,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

        }

        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        //        this.gettingDataFrmServiceFrConsignmentsClaimSettlementEntryTable = this.consignmentsClaimSettlementEntryScreen.getSummaryData()
        ////        this.onDestroyUnsubscribtionConsignmentsClaimSettlementEntry = this.gettingDataFrmServiceFrConsignmentsClaimSettlementEntryTable.subscribe( data => {
        //            this.consignmentsClaimSettlementEntryDataList = data['data'];
        //            this.dtTriggerConsignmentsClaimSettlementEntry.next();
        //        } );

        //the below code is for the getting data through json ends
    }
    ngOnDestroy(): void {
        this.dtTriggerConsignmentsClaimSettlementEntry.unsubscribe();

        //        this.onDestroyUnsubscribtionConsignmentsClaimSettlementEntry.unsubscribe();
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker
    //for input field hide and show starts
    paymentModeMethod( paymentMode: string ) {
        if ( paymentMode === 'Cheque' ) {
            this.cheque = true;
        } else {
            this.cheque = false;
        }
    }
    //for input field hide and show starts

    validateConsignmentClaim() {
        this.saveUpdateConsignmentClaimDetails();
    }
    saveEditConsignmentClaim() {
        this.lrDto.id = this.consignmentId;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.userName = this.userDataDtoReturnSession.userId;
        if ( this.lrDto.settlementAmt == null ) {
            this.lrDto.settlementAmt == 0.0;
        }
        this.lrDto.settlementDate = null;
        this.lrDto.chequeDate = null;

        //        var fromDate = $( "#"+this.pageId+"settlementDate" ).val();
        //        console.log( fromDate );
    }
    saveUpdateConsignmentClaimDetails = () => {
        this.saveEditConsignmentClaim();
        this.masterService.createConsignmentClaim( this.lrDto ).
            subscribe(( data ) => {
                this.lrDtoSaveRet = data;
                //                console.log( this.lrDtoSaveRet.status );
                if ( this.lrDtoSaveRet.status == "success" ) {
                    swal( {
                        title: "Success",
                        text: "Consignment Claim Settlement Details Saved/Updated Successfull",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.clearAll();
                } else {
                    swal( {
                        title: "Error",
                        text: "Error While Saving Consignment Claim Settlement Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Saving Consignment Claim Settlement Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };

    clearAll() {
        $( 'input[type="text"],[type="number"]' ).val( '' );
        this.consignmentId = 0;
        $( "#"+this.pageId+"paymentMode" ).val( '' );
        $( "#"+this.pageId+"remark" ).val( '' );
        $( "#"+this.pageId+"bankName" ).val( '' );
        $( "#"+this.pageId+"chequeNumber" ).val( '' );
        this.cheque = false;
        
       
    }

    fieldFocus( e, el ) {
        if ( e.keyCode == 13 ) { // press A
            el.focus();
        }
    }

    //to insert value of selected row in table to input field starts
    rowSelected( consignmentsEntryData ) {
//        console.log('from click ' +consignmentsEntryData.lrNumber);
        this.lrDto.lrNumber = consignmentsEntryData.lrNumber;
        this.lrDto.destination = consignmentsEntryData.destination;
        this.lrDto.totalArticles = consignmentsEntryData.totalArticles;
        this.lrDto.goodsValue = consignmentsEntryData.goodsValue;
        this.lrDto.settlementMode = consignmentsEntryData.settlementMode;
        this.lrDto.settlementDate = consignmentsEntryData.settlementDate;
        this.lrDto.settlementAmt = consignmentsEntryData.settlementAmt;
        this.lrDto.remarks = consignmentsEntryData.remarks;
        this.consignmentId = consignmentsEntryData.id;
//        console.log('from click ' + this.lrDto.lrNumber, this.lrDto.destination);
        if ( this.lrDto.settlementMode != null && this.lrDto.settlementMode == "Cheque" ) {
            $( "#"+this.pageId+"bankName" ).val( '' );
            $( "#"+this.pageId+"chequeNumber" ).val( '' );
            this.lrDto.chequeDate = consignmentsEntryData.chequeDate;
            this.lrDto.chequeNumber = consignmentsEntryData.chequeNumber;
            this.lrDto.bankName = consignmentsEntryData.bankName;
            this.cheque = true;
        } else {
            this.lrDto.chequeDate = null;
            this.lrDto.chequeNumber = "";
            this.lrDto.bankName = "";
            this.cheque = false;
            $( "#"+this.pageId+"bankName" ).val( '' );
            $( "#"+this.pageId+"chequeNumber" ).val( '' );
        }

        //        console.log( this.selectedName );
    }
    //to insert value of selected row in table to input field ends

    getConsignmentsClaimsSettlementEntryListRead() {
        this.lrDto.fromdate;
        this.lrDto.todate;
        this.lrDto.destination = this.userDataDtoReturnSession.mainStation;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.mode = "destination";
        this.lrDto.source;
    }


    getConsignmentsClaimsSettlementEntryList = () => {
        //        console.log( this.lrDto.companyId );
        this.getConsignmentsClaimsSettlementEntryListRead();
        this.masterReadService.getConsignmentsClaimsSettlementEntry( this.lrDto ).subscribe(
            ( response ) => {
                if ( response ) {
                    //                    console.log( response );
                    this.consignmentsClaimSettlementEntryDataList = response;
//                    console.log('from get ' +this.consignmentsClaimSettlementEntryDataList);
                    this.dtTriggerConsignmentsClaimSettlementEntry.next();
//                    console.log( this.consignmentsClaimSettlementEntryDataList[0] );
                }
                //                else {
                //                    swal( {
                //                        title: "Warning",
                //                        text: "No records found for the selected city!",
                //                        icon: "warning",
                //                        closeOnClickOutside: false,
                //                        closeOnEsc: false,
                //                    } );

                //                }
                this.changeDetectorRef.detectChanges();
            } ), ( error ) => swal( {
                title: "Error",
                text: "Server Error While Getting Nature Of Pack Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };

}