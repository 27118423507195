<!-- Row -->
<div class="row" id="{{pageId}}mainBookingAgentStmtPage">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <!-- <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Booking Agent Statement Report
                </h6>
            </div> -->
            <div class="row system_responsive" style="margin-bottom: 10px;">

                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Report Mode :</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-weight"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}reportModeType" #reportModeType (change)="reportModeTypeListner(reportModeType.value)">
														<option selected value="StatementNumber">Statement Number
														</option>
														<option value="DateWise">Date Wise</option>
													</select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12" *ngIf="viewStmtNoField">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Statement Number</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
														</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}statementNumber" aria-describedby="basic-addon11" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12" *ngIf="viewDateWiseField">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Agent Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}agentName" type="text" class="form-control" [(ngModel)]="modelAgentName" [ngbTypeahead]="searchAgentName" (selectItem)="clickListnerForAgentName($event)" [resultFormatter]="formatterAgentName" [inputFormatter]="formatterAgentName"
                                                            (focus)="focusAgentNameTA$.next($any($event).target.value)" placeholder="Select Agent Name" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12" *ngIf="viewDateWiseField">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>From Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker" id="{{pageId}}fromDates" [(ngModel)]="modelFromDate">
                                                    <div class="input-group-append" (click)="fromDates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12" *ngIf="viewDateWiseField">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>To Date</label> <input id="{{pageId}}toDates" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker" [(ngModel)]="modelToDate">
                                                    <div class="input-group-append" (click)="toDates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateSearchBtn();">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="validateClearBtn();">Clear</button>
                    </div>
                </div>


                <div class="col-md-9 vl p-t-10">
                    <div class="card-body">
                        <div class="box-body">
                            <div class='row p-t-10'>
                                <!-- spinner start-->
                                <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <mat-progress-bar mode="indeterminate" style="color: green;">
                                            </mat-progress-bar>
                                            <br>
                                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                Please Wait Loading Details.....</h6>
                                        </div>
                                    </div>
                                </div>
                                <!-- spinner end-->
                                <div class="col-sm-12 col-md-8">
                                    <h6 class="card-title border_bottom">Booking Agent Statement Report</h6>
                                </div>
                            </div>
                            <table datatable id="{{pageId}}bkgAgentStmtReportTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsBkgAgentStmtReport" [dtTrigger]="dtTriggerBkgAgentStmtReport">

                                <thead>
                                    <tr>
                                        <th [hidden]=true>Agent</th>
                                        <th>Action</th>
                                        <th>Statement No</th>
                                        <th>Stmt Date</th>
                                        <th>From Period</th>
                                        <th>To Period</th>
                                        <th>Tot LRs</th>
                                        <th>Tot Art</th>
                                        <th>Tot Act Wt</th>
                                        <th>Tot Chg Wt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>Payable Amt</th>
                                        <th>D.O.P</th>
                                        <th>Cash</th>
                                        <th>Cheque</th>
                                        <th>PDC</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let bkgAgentStmtReportData of bkgAgentStmtReportDataList ">
                                        <!--<td><button type="submit" class="btn btn-primary m-r-10" id="{{pageId}}paymentBtn"
												(click)="bookingAgentStmtPayment(agentStatementPaymentPopUpTemplate,bkgAgentStmtReportData);">Payment</button>
										</td>-->
                                        <td [hidden]=true>{{ bkgAgentStmtReportData.agentName }}</td>
                                        <td>
                                            <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}paymentBtn" (click)="bookingAgentStmtPayment(agentStatementPaymentPopUpTemplate,bkgAgentStmtReportData);">
												<i title="Confirm Payment" class="fa fa-credit-card"></i>
											</button>
                                        </td>
                                        <td style="color:blue;cursor: pointer;text-decoration: underline;" (click)="agentStatementReportPopUpBtnLink(agentStatementReportPopUpTemplate,bkgAgentStmtReportData)">
                                            {{ bkgAgentStmtReportData.stmtNo }}</td>
                                        <td>{{ bkgAgentStmtReportData.stmtDateStr }}</td>
                                        <td>{{ bkgAgentStmtReportData.fromDate }}</td>
                                        <td>{{ bkgAgentStmtReportData.toDate }}</td>
                                        <td>{{ bkgAgentStmtReportData.totalLrs }}</td>
                                        <td>{{ bkgAgentStmtReportData.totalArticles }}</td>
                                        <td>{{ bkgAgentStmtReportData.totalActWgt }}</td>
                                        <td>{{ bkgAgentStmtReportData.totalChgWgt }}</td>
                                        <td>{{ bkgAgentStmtReportData.toPay }}</td>
                                        <td>{{ bkgAgentStmtReportData.totalPaid }}</td>
                                        <td>{{ bkgAgentStmtReportData.amtToBeReceived }}</td>
                                        <td>{{ bkgAgentStmtReportData.pdcDueDateStr }}</td>
                                        <td>{{ bkgAgentStmtReportData.cashAmt }}</td>
                                        <td>{{ bkgAgentStmtReportData.totChequeAmt }}</td>
                                        <td>{{ bkgAgentStmtReportData.totPdcAmt }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td [hidden]=true></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>

                </div>

            </div>
            <!-- Row -->
        </div>
    </div>
</div>

<!--POP UP START HERE-->
<div class="col-md-12">
    <div class="form-group">
        <div class="input-group">
            <ng-template #agentStatementReportPopUpTemplate let-ok="close" let-d="dismiss">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <button style='float: right;' class="button dt-button" id="{{pageId}}printStmtRptLrBtnId" (click)="printBkgAgentStmt();">
								            <i class="fas fa-print"> &nbsp;Print</i>
                                        </button>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div *ngIf="viewStatementReportPopUp" onafterPopUp="afterPopUp()" id="{{pageId}}popupStatementRpt">
                                            <app-agent-statement-rpt-lr-details>
                                            </app-agent-statement-rpt-lr-details>
                                        </div>
                                    </div>
                                </div><br>
                                <div class="row">
                                    <div class="col-sm-12 col-md-4">
                                    </div>
                                    <div class="col-sm-12 col-md-2">
                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeAgentStatementReportPopUp()">Close
										</button>
                                    </div>
                                    <div class="col-sm-12 col-md-4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="col-md-12">
    <div class="form-group">
        <div class="input-group">
            <ng-template #agentStatementPaymentPopUpTemplate let-ok="close" let-d="dismiss">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <app-agent-statement-payment-details>
                                        </app-agent-statement-payment-details>
                                    </div>
                                </div><br>
                                <div class="row">
                                    <div class="col-sm-12 col-md-4">
                                    </div>
                                    <div class="col-sm-12 col-md-2">
                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeAgentStatementPaymentReportPopUp()">Close
										</button>
                                    </div>
                                    <div class="col-sm-12 col-md-4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<!--POP UP END HERE-->
<div *ngIf="viewStatementRptLRDetlsPrint" onafterprint="afterPrint()" id="{{pageId}}printStmtRptLRDtlsId">
    <app-agent-statement-rpt-lr-details-print></app-agent-statement-rpt-lr-details-print>
</div>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>