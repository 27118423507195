import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//for service starts
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
//for service ends
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
//for datatable starts
import { Subject, Subscription,merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
//for datatable ends
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";

@Component( {
    selector: 'app-lorry-hire-deduction-setup',
    templateUrl: './lorry-hire-deduction-setup.component.html',
    styleUrls: ['./lorry-hire-deduction-setup.component.css']
} )
export class LorryHireDeductionSetupComponent implements OnInit {

    hireSlipDto: HireSlipDto = new HireSlipDto();
    hireSlipDtoSaveRet: any;
hireSlipDtoDelete: HireSlipDto = new HireSlipDto();
hireSlipDtoDeleteRet: any;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    supAdmView: any;
    lorryHireDeductionDataList: any;
    splitTimeDetails: any;
    commaSplitTime: any;
    undersCodeSplitTime: any;
    //    lorryHireDeductionSetupDataList: any;
    lorryHireDeductionSetupDataList: Array<any> = [];
    private newAttribute: any = {};
    editTableId: null;
    //for the select option with filter starts

    controlSource = new FormControl();
    controlDestination = new FormControl();

    fieldFocus( e, el ) {
        if ( e.keyCode == 13 ) { // press A
            el.focus();
        }
    }

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLorryHireDeductionSetup: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLorryHireDeductionSetup: any;
    
    selectedFromStation : any;
    selectedToStation : any;
    
    lrDtoSearch: LRDto = new LRDto();
    lrDtoFromOptions: LRDto[];
    fromTA: Array<LRDto> = [];
    focusFromTA$ = new Subject<string>();
    searchFrom = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusFromTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.fromTA
                :  this.fromTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
            
        );
    }
    formatterFrom = (x: { mainstation: string }) => x.mainstation;
    
    lrDtoToOptions: LRDto[];
    toTA: Array<LRDto> = [];
    focusToTA$ = new Subject<string>();
    searchTo = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusToTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.toTA
                :  this.toTA.filter(v => v.mainstation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
            
        );
    }
    formatterTo = (x: { mainstation: string }) => x.mainstation;
    pageId="lhdsc";

    constructor( private masterReadService: MasterReadService, private http: HttpClient,
        private router: Router,
        private masterService: MasterService, public changeDetectorRef : ChangeDetectorRef

        //            service ends        
    ) {

        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
            //            sweet alert ends
        }

        if ( this.isLoggedIn ) {
            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );
            this.timeSettingDetailsTable();
            this.getBranchList();
        }
    }

    ngOnInit() {
    }

    //  service starts
    getUserDetailsForReadTime() {
    	this.selectedFromStation = $("#"+this.pageId+"source" ).val();
        this.selectedToStation = $("#"+this.pageId+"destination" ).val();
        
        this.hireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
        this.hireSlipDto.fromstation = this.selectedFromStation;
        this.hireSlipDto.tostation = this.selectedToStation;
    }


    getlorryHireDeductionList = () => {
        //        console.log( this.hireSlipDto.fromstation );
        this.getUserDetailsForReadTime();
        this.masterReadService.getLorryHireDeductionService( this.hireSlipDto ).subscribe(
            ( response ) => {
                if ( response ) {
                    //                    console.log( response );
                    this.lorryHireDeductionDataList = response;
                    this.hireSlipDto = this.lorryHireDeductionDataList;
                    this.hireSlipDto.buffTimeInMin = this.lorryHireDeductionDataList.buffTimeInMin.toFixed();
                    this.lorryHireDeductionSetupDataList = this.hireSlipDto.listTimeSettings;
                    //                  this.dtTriggerExpensesHeads.next();
//                    console.log( this.lorryHireDeductionDataList );
                }
                //                else {
                //                    swal( {
                //                        title: "Warning",
                //                        text: "No records found for the selected city!",
                //                        icon: "warning",
                //                        closeOnClickOutside: false,
                //                        closeOnEsc: false,
                //                    } );

                //                }
                this.changeDetectorRef.detectChanges();
            } ), ( error ) => swal( {
                title: "Error",
                text: "Server Error While Getting Time Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };

    //    service ends


    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }

    timeSettingDetailsTable() {

        //the first datatable starts
        this.dtOptionsLorryHireDeductionSetup = {
            //};
            //columns is used to for export and others starts
            //            columns: [
            //                {
            //                    title: 'Party Name',
            //                    data: 'partyName'
            //                },
            //                {
            //                    title: 'TDS Certf.No.',
            //                    data: 'tdsCertificateNumber'
            //                },
            //                {
            //                    title: 'TDS Amount',
            //                    data: 'tdsAmount'
            //                },
            //                {
            //                    title: 'From Period',
            //                    data: 'fromPeriod'
            //                },
            //                {
            //                    title: 'To Period',
            //                    data: 'toPeriod'
            //                },
            //                {
            //                    title: 'Payment Mode',
            //                    data: 'paymentMode'
            //                },
            //                {
            //                    title: 'Bank Name',
            //                    data: 'bankName'
            //                },
            //                {
            //                    title: 'Chq No.',
            //                    data: 'chequeNumber'
            //                },
            //                {
            //                    title: 'Chq.Date',
            //                    data: 'chequeDate'
            //                },
            //                {
            //                    title: 'Ref.No',
            //                    data: 'referenceNumber'
            //                },
            //                {
            //                    title: 'Setlm.Date',
            //                    data: 'settlementDate'
            //                },
            //                {
            //                    title: 'Remarks',
            //                    data: 'remarks'
            //                },
            //                {
            //                    title: 'TDS Certf. Copy',
            //                    data: 'tdsCertificateCopy'
            //                }
            //            ],


            //columns is used to for export and others endss
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            searching: false,
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 220,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": true,
            pagingType: 'full_numbers',
            pageLength: 9,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends                          
        }
        //        this.getTdsEntryMasterDetailsList();
        //the first datatable ends





        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        //first service starts 
        //            this.getDataLorryHireDeductionSetupDataTable = this.tdsDetailsEntryRpt.getSummaryData()
        //        this.onDestroyUnsubscribtionLorryHireDeductionSetup = this.getDataLorryHireDeductionSetupDataTable.subscribe( data => {
        //            this.lorryHireDeductionSetupDataList = data['data'];
        //            this.dtTriggerLorryHireDeductionSetup.next();
        //        } );
        //first service ends


    }

    ngOnDestroy(): void {
        this.dtTriggerLorryHireDeductionSetup.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerLorryHireDeductionSetup.next();
    }
    //to insert value of selected row in table to input field starts
    //    rowSelectedEdit( lorryHireDeductionSetupData ) {
    //        this.hireSlipDto.fromTime = lorryHireDeductionSetupData.fromTime;
    //        this.hireSlipDto.toTime = lorryHireDeductionSetupData.toTime;
    //        this.hireSlipDto.rewardAmt = lorryHireDeductionSetupData.rewardAmt;
    //    }
    //    rowSelectedDelete( lorryHireDeductionSetupData ) {
    //
    //        //        this.hireSlipDto.fromTime = LorryHireDeductionSetupData.fromTime;
    //        //        this.hireSlipDto.toTime = LorryHireDeductionSetupData.toTime;
    //        //        this.hireSlipDto.rewardAmt = LorryHireDeductionSetupData.rewardAmt;
    //        //        this.timeDataList.splice(index, 1); 
    //        //        console.log( this.hireSlipDto.fromTime + "," + this.hireSlipDto.toTime + "," + this.hireSlipDto.rewardAmt );
    //    }

    //    addRow( lorryHireDeductionSetupDataList ) {
    //        LorryHireDeductionSetupDataList.fromTime = this.hireSlipDto.fromTime;
    //        LorryHireDeductionSetupDataList.toTime = this.hireSlipDto.toTime;
    //        LorryHireDeductionSetupDataList.rewardAmt = this.hireSlipDto.rewardAmt;
    //        console.log( this.hireSlipDto.fromTime + "," + this.hireSlipDto.toTime + "," + this.hireSlipDto.rewardAmt );
    //    }
    //to insert value of selected row in table to input field ends


    addInTable() {
        this.newAttribute.fromTime = $("#"+this.pageId+"fromTime" ).val();
        this.newAttribute.toTime = $("#"+this.pageId+"toTime" ).val();
        this.newAttribute.rewardAmt = $("#"+this.pageId+"rewardingAmt" ).val();
        console.log( this.newAttribute.rewardAmt );

        if ( ( parseInt( this.newAttribute.toTime ) > parseInt( this.newAttribute.fromTime ) ) && ( this.newAttribute.rewardAmt !='' ) ) {


            if ( this.editTableId != null ) {
                this.lorryHireDeductionSetupDataList.splice( this.editTableId, 1 );
            }
            if ( this.lorryHireDeductionSetupDataList == null ) {
                this.lorryHireDeductionSetupDataList = [];
            }

            if ( this.lorryHireDeductionSetupDataList.length == 0 ) {
                this.lorryHireDeductionSetupDataList.push( this.newAttribute );
            } else {

                let array = this.lorryHireDeductionSetupDataList;
                this.lorryHireDeductionSetupDataList.push( this.newAttribute );

            }
            this.newAttribute = {};
            $("#"+this.pageId+"fromTime" ).val( '' );
            $("#"+this.pageId+"toTime" ).val( '' );
            $("#"+this.pageId+"rewardingAmt" ).val( '' );
            $("#"+this.pageId+"hiddenIndex" ).val( '' );

            this.editTableId = null;
        } else {
            swal( {
                title: "Warning",
                text: "To  Time  must been greater then From Time",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } );

        }


    }
    rowSelectedEdit( timeSettingEntryTableData, index ) {
        console.log( timeSettingEntryTableData.fromTime );
        $("#"+this.pageId+"fromTime" ).val( timeSettingEntryTableData.fromTime );
        $("#"+this.pageId+"toTime" ).val( timeSettingEntryTableData.toTime );
        $("#"+this.pageId+"rewardingAmt" ).val( timeSettingEntryTableData.rewardAmt );
        $("#"+this.pageId+"hiddenIndex" ).val( index );
        this.editTableId = index;
        //    console.log( timeSettingEntryTableData.fromTime, index );
    }
    rowSelectedDelete( index ) {
        this.lorryHireDeductionSetupDataList.splice( index, 1 );
        $("#"+this.pageId+"fromTime" ).val( '' );
        $("#"+this.pageId+"toTime" ).val( '' );
        $("#"+this.pageId+"rewardingAmt" ).val( '' );
        $("#"+this.pageId+"hiddenIndex" ).val( '' );
    }

    validateLorryHireDeductionDetails() {
        this.saveUpdateLorryHireDeductionDetails();
    }
    saveLorryHireDeductionDetails() {
        this.hireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
        this.hireSlipDto.lastupdatedby = this.userDataDtoReturnSession.userId;
        this.hireSlipDto.branch = this.userDataDtoReturnSession.mainStation;
        this.hireSlipDto.listTimeSettings = this.lorryHireDeductionSetupDataList;
        //        console.log( this.hireSlipDto.listTimeSettings );
    }
    saveUpdateLorryHireDeductionDetails = () => {
        this.saveLorryHireDeductionDetails();
        this.masterService.createLorryHireDeductionDetails( this.hireSlipDto ).
            subscribe(( data ) => {
                this.hireSlipDtoSaveRet = data;
                if ( this.hireSlipDtoSaveRet.status == "persisted" ) {
                    swal( {
                        title: "Success",
                        text: "Lorry Hire Deduction Details Details Saved/Updated Successfull",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.clearAll();
                } else {
                    swal( {
                        title: "Error",
                        text: "Error While Saving Lorry Hire Deduction Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Saving Lorry Hire Deduction Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };

    clearAll() {
        $( 'input[type="text"],[type="number"]' ).val( '' );
        this.lorryHireDeductionSetupDataList = [];
        $("#"+this.pageId+"source" ).val( '' );
        $("#"+this.pageId+"destination" ).val( '' );
        this.hireSlipDto.fromstation=null;
        this.hireSlipDto.tostation=null;
    }
    
    validateDeleteRow(){
        this.deleteLorryHireDeductionDetails();
    }
    
    getDeleteLorryHireDeductionDetails(){
        this.hireSlipDtoDelete.companyid=this.userDataDtoReturnSession.companyId;
        this.hireSlipDtoDelete.lastupdatedby = this.userDataDtoReturnSession.userId;
        this.hireSlipDtoDelete.fromstation =  this.hireSlipDto.fromstation;
        this.hireSlipDtoDelete.tostation =  this.hireSlipDto.tostation;
    }
    
    deleteLorryHireDeductionDetails = () => {
        this.getDeleteLorryHireDeductionDetails();
        this.masterService.deleteLorryHireDeduction( this.hireSlipDtoDelete ).
            subscribe(( data ) => {
                this.hireSlipDtoDeleteRet = data;
                if ( this.hireSlipDtoDeleteRet.status == "success" ) {
                    swal( {
                        title: "Success",
                        text: "Penalty Details Removed Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.clearAll();
                    //                    this.getlorryHireDeductionList();
                } else {
                    swal( {
                        title: "Error",
                        text: "Error While Deleting Penalty Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Deleting Penalty Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };
    
    
    getBranchList() {
     	 	this.lrDtoSearch = new LRDto();
        	this.lrDtoSearch.companyId = this.userDataDtoReturnSession.companyId;
           this.masterReadService.getDestinationForLREntryService(this.lrDtoSearch ).subscribe(
               ( response ) => {
                   if (response) {
                       if (response.length > 0) {
                       	 this.lrDtoFromOptions = response;
                           this.fromTA = [];
                           
                           this.lrDtoToOptions = response;
                           this.toTA = [];
                           
                           for ( let i = 0; i < this.lrDtoFromOptions.length; i++ ) {
                               this.fromTA.push( this.lrDtoFromOptions[i]);
                               this.toTA.push( this.lrDtoFromOptions[i]);
                           }
                       }
                   }
                   this.changeDetectorRef.detectChanges();
                 }),(error) => {
             		   swal("Error","Server Problem Occurred While getting the the branch details","info");
             		},
             		   () => console.log('done');
       }
    
    
}
