import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbModal, NgbTypeaheadSelectItemEvent, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { LRDto } from 'src/app/dto/LR-dto';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';

/*import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import pdfMake from 'pdfmake/build/pdfmake';

//import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

import HtmlToDocx from 'html-docx';

import { asBlob } from 'html-docx-js-typescript'
// if you want to save the docx file, you need import 'file-saver'
import { saveAs } from 'file-saver'*/

@Component({
    selector: 'app-statement-rpt',
    templateUrl: './statement-rpt.component.html',
    styleUrls: ['./statement-rpt.component.css']
})
export class StatementRptComponent implements OnInit {

    getDataFrmServiceFrTable: any;

    statementReportDataList: any;

    onDestroyUnsubscribtionStatementReport: Subscription;



    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerStatementReport: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsStatementReport: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;

    destinationTATemp: Array<LRDto> = [];
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        /* return merge(debouncedText$, inputFocus$).pipe(
             map(term => (term === '' ? this.destinationTA
                 : this.destinationTA.filter(v => v.destination)).slice(0, 200))
         );*/
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    showSpinnerForAction = false;
    fromDatesModal: any;
    toDatesModal: any;
    validateFromDate: any;
    validateToDate: any;
    validateDestination: any;
    validateStatementNumber: any = null;
    hideshowDestinationDropDown = false;
    hideshowStmtNoField = false;
    hideshowFromToDateField = false;
    viewStatementReportPopUp = false;

    rightsForStatementReport = "Right For Statement Report DestView";
    destView: boolean = false;

    modalRefferenceStatementRptPopUp: NgbModalRef;
    modalRefferenceStatementRptClosePopUp: string;
    @ViewChild('statementReportPopUpTemplate') private statementReportPopUpTemplate;

    fortNightStmtDto: FortNightStmtDto = new FortNightStmtDto();



    searchByAuthorityFortNightDto: FortNightStmtDto = new FortNightStmtDto();

    lrDtoAdditionList: Array<any> = [];
    lrDtoAdditionList1: Array<any> = [];
    lrDtoDeductionList: Array<LRDto> = [];
    lrDtoAddition: LRDto = new LRDto();
    lrDtoDeduction: LRDto = new LRDto();
    additionValueSplit: Array<String> = [];
    deductionValueSpilt: Array<String> = [];

    //
    viewStatementRptLRDetlsPrint: boolean = false;
    stmtReportDataForSummaryPrint: any;

    additionValueLRDto: LRDto = new LRDto();
    deductionValueLRDto: LRDto = new LRDto();

    statementReportLrAdditionDataListSumm: any;
    statementReportLrDeductionDataListSumm: any;

    statementReportLrAdditionDataListSummReturn: any;
    statementReportLrDeductionDataListSummReturn: any;

    totalAmount_Addition: any;
    totalAmount_Deduction: any;


    //print
    viewCustomPrintV1 = false;

    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;


    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];

    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];

    //Custom Print Footer
    summary1: number;
    summary2: number;
    summary3: number;
    summary4: number;
    summary5: number;
    summary6: number;

    stmtRptLrDetailsReturnPrintLocalStorage: any;
    lrDtoForLRDetailsPrint: LRDto = new LRDto();

    @ViewChild('content') content: ElementRef;
    pageId = "stmtrc";

    constructor(private memoLessRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
        private modalService: NgbModal, private destStmtReport: DestStmtReport, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.hideshowStmtNoField = true;
            //if(this.rightsForStatementReport == "Right For Statement Report DestView"){
            if (this.userDataDtoReturnSession.role != null &&
                this.userDataDtoReturnSession.role == "Super Administrator") {
                this.destView = true;
                //this.hideshowDestinationDropDown = true;
                //this.getDestinationDetails();
            } else {
                this.destView = false;
                //this.hideshowDestinationDropDown = false;
            }
            if (this.destView == true) {
                this.getDestinationDetails();
            }/*else{
this.hideshowDestinationDropDown = false;
            }*/

        }

    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        var booleanShowDestinationDropDown = this.destView;
        console.log(booleanShowDestinationDropDown);
        this.dtOptionsStatementReport = {
            dom: 'Bfrtip',
            //place holder in search/filter in datatable starts
            buttons: [
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
                    customize: function (win) {
                        $(win.document.body).css('font-size', '10pt');
                        $(win.document.body).find('th, td').
                            css('font-family', 'Arial, Helvetica, sans-serif')
                            .css('font-size', '12px').css('text-align', 'center');
                    },
                    footer: true,
                    messageTop: function () {
                        if (booleanShowDestinationDropDown == true) {
                            if ($("#stmtrcviewType").val() == "stmtNo") {
                                return "<br><table style='width: 100%;'>" +
                                    "<tr>" +
                                    "<td style='width: 22%;'>" +
                                    "<table>" +
                                    "<tr>" +
                                    "<td width='10%' style='text-align:left !important;'>" +
                                    "<strong style='font-size:15px;'>Statement No</strong>" +
                                    "</td>" +
                                    "<td width='2%' style='text-align:left !important;'>" +
                                    "<strong style='font-size:15px;'>:</strong>" +
                                    "</td>" +
                                    "<td width='10%' style='text-align:left !important;'>" +
                                    "<span style='font-size:15px;'>" + $("#stmtrcstatementNo").val() + "</span><br>" +
                                    "</td>" +
                                    "</tr>" +
                                    "</table>" +
                                    "</td>" +
                                    "<td rowspan='3'style='width:54%;word-wrap: break-word;text-align:left !important;'>" +
                                    "<strong style='font-size:24px;'><u>Statement Report</u></strong><br>" +
                                    "</td>" +
                                    "<td rowspan='3' align='left' style='width:22%;'>" +
                                    "</td>" +
                                    "</tr>" +
                                    "</table><br>";
                            } else if ($("#stmtrcviewType").val() == "datewise") {
                                return "<br><table style='width: 100%;'>" +
                                    "<tr>" +
                                    "<td style='width: 22%;'>" +
                                    "<table>" +
                                    "<tr>" +
                                    "<td width='10%' style='text-align:left !important;'>" +
                                    "<strong style='font-size:15px;'>Destination&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                                    "</td>" +
                                    "<td width='2%' style='text-align:left !important;'>" +
                                    "<strong style='font-size:15px;'>:</strong>" +
                                    "</td>" +
                                    "<td width='10%' style='text-align:left !important;'>" +
                                    "<span style='font-size:15px;'>" + $("#stmtrcdestinationId").val() + "</span><br>" +
                                    "</td>" +
                                    "</tr>" +
                                    "<tr>" +
                                    "<td width='10%' style='text-align:left !important;'>" +
                                    "<strong style='font-size:15px;'>From Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                                    "</td>" +
                                    "<td width='2%' style='text-align:left !important;'>" +
                                    "<strong style='font-size:15px;'>:</strong>" +
                                    "</td>" +
                                    "<td width='10%' style='text-align:left !important;'>" +
                                    "<span style='font-size:15px;'>" + moment($("#stmtrcfromDates").val()).format('DD-MM-YYYY') + "</span><br>" +
                                    "</td>" +
                                    "</tr>" +
                                    "<tr>" +
                                    "<td width='10%' style='text-align:left !important;'>" +
                                    "<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                                    "</td>" +
                                    "<td width='2%' style='text-align:left !important;'>" +
                                    "<strong style='font-size:15px;'>:</strong>" +
                                    "</td>" +
                                    "<td width='10%' style='text-align:left !important;'>" +
                                    "<span style='font-size:15px;'>" + moment($("#stmtrctoDates").val()).format('DD-MM-YYYY') + "</span><br>" +
                                    "</td>" +
                                    "</tr>" +
                                    "</table>" +
                                    "</td>" +
                                    "<td rowspan='3'style='width:54%;word-wrap: break-word;text-align:left !important;'>" +
                                    "<strong style='font-size:24px;'><u>Statement Report</u></strong><br>" +
                                    "</td>" +
                                    "<td rowspan='3' align='left' style='width:22%;'>" +
                                    "</td>" +
                                    "</tr>" +
                                    "</table><br>";
                            }
                        } else {
                            if ($("#stmtrcviewType").val() == "stmtNo") {
                                return "<br><table style='width: 100%;'>" +
                                    "<tr>" +
                                    "<td style='width: 22%;'>" +
                                    "<table>" +
                                    "<tr>" +
                                    "<td width='10%' style='text-align:left !important;'>" +
                                    "<strong style='font-size:15px;'>Statement No</strong>" +
                                    "</td>" +
                                    "<td width='2%' style='text-align:left !important;'>" +
                                    "<strong style='font-size:15px;'>:</strong>" +
                                    "</td>" +
                                    "<td width='10%' style='text-align:left !important;'>" +
                                    "<span style='font-size:15px;'>" + $("#stmtrcstatementNo").val() + "</span><br>" +
                                    "</td>" +
                                    "</tr>" +
                                    "</table>" +
                                    "</td>" +
                                    "<td rowspan='3'style='width:54%;word-wrap: break-word;text-align:left !important;'>" +
                                    "<strong style='font-size:24px;'><u>Statement Report</u></strong><br>" +
                                    "</td>" +
                                    "<td rowspan='3' align='left' style='width:22%;'>" +
                                    "</td>" +
                                    "</tr>" +
                                    "</table><br>";
                            } else if ($("#stmtrcviewType").val() == "datewise") {
                                return "<br><table style='width: 100%;'>" +
                                    "<tr>" +
                                    "<td style='width: 22%;'>" +
                                    "<table>" +
                                    "<tr>" +
                                    "<td width='10%' style='text-align:left !important;'>" +
                                    "<strong style='font-size:15px;'>From Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                                    "</td>" +
                                    "<td width='2%' style='text-align:left !important;'>" +
                                    "<strong style='font-size:15px;'>:</strong>" +
                                    "</td>" +
                                    "<td width='10%' style='text-align:left !important;'>" +
                                    "<span style='font-size:15px;'>" + moment($("#stmtrcfromDates").val()).format('DD-MM-YYYY') + "</span><br>" +
                                    "</td>" +
                                    "</tr>" +
                                    "<tr>" +
                                    "<td width='10%' style='text-align:left !important;'>" +
                                    "<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                                    "</td>" +
                                    "<td width='2%' style='text-align:left !important;'>" +
                                    "<strong style='font-size:15px;'>:</strong>" +
                                    "</td>" +
                                    "<td width='10%' style='text-align:left !important;'>" +
                                    "<span style='font-size:15px;'>" + moment($("#stmtrctoDates").val()).format('DD-MM-YYYY') + "</span><br>" +
                                    "</td>" +
                                    "</tr>" +
                                    "</table>" +
                                    "</td>" +
                                    "<td rowspan='3'style='width:54%;word-wrap: break-word;text-align:left !important;'>" +
                                    "<strong style='font-size:24px;'><u>Statement Report</u></strong><br>" +
                                    "</td>" +
                                    "<td rowspan='3' align='left' style='width:22%;'>" +
                                    "</td>" +
                                    "</tr>" +
                                    "</table><br>";
                            }
                        }
                    },
                    title: function () {
                        return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                            "<td align='left' style='width: 20%;'>" +
                            "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                            "</td>" +
                            "<td align='left' style='width: 30%;'>" +
                            "</td>" +
                            "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                            companyAddressDetls +
                            "</td>" +
                            "</tr></table>";
                    }
                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return " Statement Report - ";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 320,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": true,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var totLrs = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totInvoice = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totWgt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPayAmt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paidAmt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var receivableAmt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(4).footer()).html(totLrs);
                $(api.column(5).footer()).html(totInvoice);
                $(api.column(6).footer()).html(totWgt);
                $(api.column(7).footer()).html(toPayAmt);
                $(api.column(8).footer()).html(paidAmt);
                $(api.column(9).footer()).html(receivableAmt);
            }
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerStatementReport.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerStatementReport.unsubscribe();

        // this.onDestroyUnsubscribtionStatementReport.unsubscribe();
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.showSpinnerForAction = true;
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationOptions = response;
                    this.destinationTA = [];
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    this.destinationTATemp = this.destinationTA;
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };

    /* viewTypeMode(viewType: string) {
     
         console.log(viewType);
         if (viewType === 'stmtNo') {
             this.clearField();
             this.hideshowStmtNoField = true;
             this.hideshowFromToDateField = false;
             this.hideshowDestinationDropDown = false;
         } else if (viewType === 'datewise') {
             this.clearField();
             this.hideshowStmtNoField = false;
             this.hideshowFromToDateField = true;
             //if(this.rightsForStatementReport == "Right For Statement Report DestView"){
             if (this.userDataDtoReturnSession.role != null &&
                 this.userDataDtoReturnSession.role == "Super Administrator") {
                 this.hideshowDestinationDropDown = true;
                 this.destinationTA = this.destinationTATemp;
             } else {
                 this.hideshowDestinationDropDown = false;
             }
         }
     }*/
    viewTypeMode(viewType: string) {
        if (viewType === 'stmtNo') {
            this.hideshowStmtNoField = true;
            this.hideshowFromToDateField = false;
            this.hideshowDestinationDropDown = false;

            $("#" + this.pageId + "statementNo").val('');
            this.fromDatesModal = null;
            this.toDatesModal = null;
            this.modelDestination = null;
            this.statementReportDataList = [];
            $("#" + this.pageId + "statementRptTableId").DataTable().destroy();
            this.dtTriggerStatementReport.next();
        } else if (viewType === 'datewise') {
            this.hideshowStmtNoField = false;
            this.hideshowFromToDateField = true;
            //if(this.rightsForStatementReport == "Right For Statement Report DestView"){
            if (this.destView == true) {
                this.hideshowDestinationDropDown = true;
                this.destinationTA = this.destinationTATemp;
            } else {
                this.hideshowDestinationDropDown = false;
            }

            this.fromDatesModal = null;
            this.toDatesModal = null;
            this.modelDestination = null;
            $("#" + this.pageId + "statementNo").val('');
            this.statementReportDataList = [];
            $("#" + this.pageId + "statementRptTableId").DataTable().destroy();
            this.dtTriggerStatementReport.next();
        }
    }

    clearField() {
        this.fromDatesModal = null;
        this.toDatesModal = null;
        this.modelDestination = null;
        this.validateDestination = null;
        $("#" + this.pageId + "fromDates").val('');
        $("#" + this.pageId + "fromDates").val('');
        $("#" + this.pageId + "toDates").val('');
        $("#" + this.pageId + "statementNo").val('');
        this.validateFromDate = null;
        this.validateToDate = null;
        $("#" + this.pageId + "viewType").val('stmtNo');
    }

    clearBtn() {
        this.clearField();
        this.statementReportDataList = [];
        $("#" + this.pageId + "statementRptTableId").DataTable().destroy();
        this.dtTriggerStatementReport.next();


        this.hideshowStmtNoField = true;
        this.hideshowFromToDateField = false;
        this.hideshowDestinationDropDown = false;
    }

    validateSearchBtn() {
        if (this.destView == true) {
            if ($("#" + this.pageId + "viewType").val() == "stmtNo") {
                if (($("#" + this.pageId + "statementNo").val() == null) ||
                    ($("#" + this.pageId + "statementNo").val() == undefined) || ($("#" + this.pageId + "statementNo").val() == "")) {
                    swal("Mandatory Fields", "Please enter the statement no", "warning");
                    return false;
                } else {
                    this.setStatementReportDetailsReport();
                }
            } else if ($("#" + this.pageId + "viewType").val() == "datewise") {
                if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                    ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
                    ($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "") ||
                    ($("#" + this.pageId + "destinationId").val() == null) || ($("#" + this.pageId + "destinationId").val() == undefined) ||
                    ($("#" + this.pageId + "destinationId").val() == "")) {
                    swal("Mandatory Fields", "Please select the mandatory fields", "warning");
                    return false;
                } else {
                    this.setStatementReportDetailsReport();
                }
            }
        } else if (this.destView == false) {
            if ($("#" + this.pageId + "viewType").val() == "stmtNo") {
                if (($("#" + this.pageId + "statementNo").val() == null) ||
                    ($("#" + this.pageId + "statementNo").val() == undefined) || ($("#" + this.pageId + "statementNo").val() == "")) {
                    swal("Mandatory Fields", "Please enter the statement no", "warning");
                    return false;
                } else {
                    this.checkForAuthority();
                }
            } else if ($("#" + this.pageId + "viewType").val() == "datewise") {
                if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                    ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
                    ($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "")) {
                    swal("Mandatory Fields", "Please select the mandatory fields", "warning");
                    return false;
                } else {
                    this.setStatementReportDetailsReport();
                }
            }
        }
        /* if (this.hideshowDestinationDropDown == true) {
             if ($("#"+this.pageId+"statementNo").val() == null || $("#"+this.pageId+"statementNo").val() == undefined ||
                 $("#"+this.pageId+"statementNo").val() == "") {
                 swal("Mandatory Fields", "Please enter the statement no or datewise", "warning");
                 return false;
             } else if (($("#"+this.pageId+"fromDates").val() == null) || ($("#"+this.pageId+"fromDates").val() == undefined) ||
                 ($("#"+this.pageId+"fromDates").val() == "") || ($("#"+this.pageId+"toDates").val() == null) ||
                 ($("#"+this.pageId+"toDates").val() == undefined) || ($("#"+this.pageId+"toDates").val() == "") ||
                 ($("#"+this.pageId+"destinationId").val() == null) || ($("#"+this.pageId+"destinationId").val() == undefined) ||
                 ($("#"+this.pageId+"destinationId").val() == "")) {
                 swal("Mandatory Fields", "Please select the mandatory fields", "warning");
                 return false;
             } else {
                 this.setStatementReportDetailsReport();
             }
         } else {
             if ($("#"+this.pageId+"statementNo").val() == null || $("#"+this.pageId+"statementNo").val() == undefined ||
                 $("#"+this.pageId+"statementNo").val() == "") {
                 swal("Mandatory Fields", "Please enter the statement no or datewise", "warning");
                 return false;
             } else if (($("#"+this.pageId+"fromDates").val() == null) || ($("#"+this.pageId+"fromDates").val() == undefined) ||
                 ($("#"+this.pageId+"fromDates").val() == "") || ($("#"+this.pageId+"toDates").val() == null) ||
                 ($("#"+this.pageId+"toDates").val() == undefined) || ($("#"+this.pageId+"toDates").val() == "")) {
                 swal("Mandatory Fields", "Please enter the from date & to date", "warning");
                 return false;
             } else {
                 this.checkForAuthority();
             }
             
         }*/

    }

    checkForAuthority() {
        this.validateStatementNumber = $("#" + this.pageId + "statementNo").val();
        this.searchByAuthorityFortNightDto = new FortNightStmtDto();
        this.searchByAuthorityFortNightDto.stmtNo = this.validateStatementNumber;
        this.searchByAuthorityFortNightDto.branch = this.userDataDtoReturnSession.office;
        this.searchByAuthorityFortNightDto.status = 'StmtRpt';
        this.searchForAuthorization();
    }

    searchForAuthorization = () => {
        this.showSpinnerForAction = true;
        //console.log(this.searchByAuthorityFortNightDto);
        this.destStmtReport.chkAgentAutority(this.searchByAuthorityFortNightDto).subscribe(
            (response) => {
                if (response.status == 'notExist') {
                    swal({
                        title: "No Result Found",
                        text: "No Result Found " + $("#" + this.pageId + "statementNo").val(),
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.statementReportDataList = [];
                    $("#" + this.pageId + "statementRptTableId").DataTable().destroy();
                    this.dtTriggerStatementReport.next();
                    this.showSpinnerForAction = false;
                } else if (response.status == 'unauthorized') {
                    swal({
                        title: "UnAuthorized",
                        text: "U are not authorized to view this informations",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.statementReportDataList = [];
                    $("#" + this.pageId + "statementRptTableId").DataTable().destroy();
                    this.dtTriggerStatementReport.next();
                    this.showSpinnerForAction = false;
                } else {
                    this.setStatementReportDetailsReport();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  While Checking Authorization of Statement verfication Details", "info");
            }, () => console.log('done');
    };

    getStatementReportDetailsData() {
        this.fortNightStmtDto = new FortNightStmtDto();

        if (($("#" + this.pageId + "viewType").val() == "stmtNo") && ($("#" + this.pageId + "statementNo").val() != null) &&
            ($("#" + this.pageId + "statementNo").val() != undefined) && ($("#" + this.pageId + "statementNo").val() != "")) {
            this.fortNightStmtDto.mode = "STMT";
        } else {
            this.fortNightStmtDto.mode = "Date";
        }
        this.validateStatementNumber = $("#" + this.pageId + "statementNo").val();
        this.validateFromDate = $("#" + this.pageId + "fromDates").val();
        this.validateToDate = $("#" + this.pageId + "toDates").val();
        this.validateDestination = $("#" + this.pageId + "destinationId").val();

        this.fortNightStmtDto.branch = this.userDataDtoReturnSession.office;
        this.fortNightStmtDto.status = "StmtRpt";
        this.fortNightStmtDto.stmtNo = this.validateStatementNumber;
        if (($("#" + this.pageId + "fromDates").val() != null) && ($("#" + this.pageId + "fromDates").val() != "") &&
            ($("#" + this.pageId + "toDates").val() != null) && ($("#" + this.pageId + "toDates").val() != "")) {
            this.fortNightStmtDto.fromDate = this.validateFromDate;
            this.fortNightStmtDto.toDate = this.validateToDate;
        }
        //if(this.rightsForStatementReport == "Right For Statement Report DestView"){
        /*if (this.userDataDtoReturnSession.role != null &&
            this.userDataDtoReturnSession.role == "Super Administrator") {*/
        if (this.hideshowDestinationDropDown == true) {

            this.fortNightStmtDto.destination = this.validateDestination;
        } else {
            this.fortNightStmtDto.destination = this.userDataDtoReturnSession.mainStation;
        }
        this.fortNightStmtDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    setStatementReportDetailsReport() {
        this.getStatementReportDetailsData();
        this.showSpinnerForAction = true;
        this.destStmtReport.getStatementReportDetails(this.fortNightStmtDto).subscribe(
            (response) => {
                //console.log(response);
                this.statementReportDataList = [];
                $("#" + this.pageId + "statementRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.statementReportDataList = response;
                    this.dtTriggerStatementReport.next();
                } else {
                    this.dtTriggerStatementReport.next();
                    swal("Alert", "NO DATAS FOUND FOR THIS INFORMATION", "info");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  While Statement Report Details", "info");
            }, () => console.log('done');
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    statementReportPopUpBtnLink(statementReportPopUpTemplate, statementReportData) {
        this.modalRefferenceStatementRptPopUp = this.modalService.open(statementReportPopUpTemplate,
            { centered: true, windowClass: "myCustomModalClass" });
        this.modalRefferenceStatementRptPopUp.result.then((result) => {
            this.modalRefferenceStatementRptClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceStatementRptClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });

        this.stmtReportDataForSummaryPrint = null;
        this.stmtReportDataForSummaryPrint = statementReportData;

        var additionValue = null, deductionValue = null;
        //var additionValueSplit = null, deductionValueSpilt = null;
        additionValue = statementReportData.multipleAdd;
        deductionValue = statementReportData.multipleDed;
        //console.log(additionValue);
        //console.log(deductionValue);

        this.additionValueSplit = additionValue.split("#");
        this.deductionValueSpilt = deductionValue.split("#");

        localStorage.clear();
        localStorage.setItem('popupStmtRptLrDetls', JSON.stringify(statementReportData));
        localStorage.setItem('popupStmtRptLrDetlsAddition', JSON.stringify(this.additionValueSplit));
        localStorage.setItem('popupStmtRptLrDetlsDeduction', JSON.stringify(this.deductionValueSpilt));
        this.viewStatementReportPopUp = true;
        window.addEventListener('afterPopUp', (event) => {
            this.viewStatementReportPopUp = false;
        });
    }
    closeStatementReportPopUp() {
        this.modalRefferenceStatementRptPopUp.close();
    }

    clickListnerForPrintStmtRptLr() {
        this.modalRefferenceStatementRptPopUp.close();

        this.statementReportLrAdditionDataListSummReturn = [];
        this.statementReportLrAdditionDataListSummReturn = this.setStatementRptLrDetlsAddition();

        this.statementReportLrDeductionDataListSummReturn = [];
        this.statementReportLrDeductionDataListSummReturn = this.setStatementRptLrDetlsDeduction();

        localStorage.clear();
        localStorage.setItem('stmtRptLrDtlsAdditionPrintLocalStorage',
            JSON.stringify(this.statementReportLrAdditionDataListSummReturn));

        localStorage.setItem('stmtRptLrDtlsDeductionPrintLocalStorage',
            JSON.stringify(this.statementReportLrDeductionDataListSummReturn));

        localStorage.setItem('stmtRptLrDtlsGeneralPrintLocalStorage',
            JSON.stringify(this.stmtReportDataForSummaryPrint));

        // localStorage.setItem('stmtRptLrDtlsPrintLocalStorage',
        //   JSON.stringify(this.statementReportLrDetailsDataList));
        this.showSpinnerForAction = true;
        this.viewStatementRptLRDetlsPrint = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewStatementRptLRDetlsPrint) {
                this.viewStatementRptLRDetlsPrint = false;
                this.showSpinnerForAction = false;
                localStorage.clear();
            }
        });
    }

    /*public pdfMethod() {
        this.showSpinnerForAction = true;
        var pdf = new jsPDF('p', 'pt', 'a4');
      pdf.html($("#"+this.pageId+"pdfContent"), 15, 15, options, function() {
        pdf.save('Menu.pdf');
      });
    }*/

    /* pdfMethod(pdfTable) {
         this.modalRefferenceStatementRptPopUp.close();
 
         this.statementReportLrAdditionDataListSummReturn = [];
         this.statementReportLrAdditionDataListSummReturn = this.setStatementRptLrDetlsAddition();
 
         this.statementReportLrDeductionDataListSummReturn = [];
         this.statementReportLrDeductionDataListSummReturn = this.setStatementRptLrDetlsDeduction();
 
         localStorage.clear();
         localStorage.setItem('stmtRptLrDtlsAdditionPrintLocalStorage',
             JSON.stringify(this.statementReportLrAdditionDataListSummReturn));
 
         localStorage.setItem('stmtRptLrDtlsDeductionPrintLocalStorage',
             JSON.stringify(this.statementReportLrDeductionDataListSummReturn));
 
         localStorage.setItem('stmtRptLrDtlsGeneralPrintLocalStorage',
             JSON.stringify(this.stmtReportDataForSummaryPrint));
 
         this.showSpinnerForAction = true;
         this.viewStatementRptLRDetlsPrint = true;
         setTimeout(() => {
             let data = document.getElementById("pdfTable");
             console.log(data);
             html2canvas(data).then(canvas => {
                 const contentDataURL = canvas.toDataURL('image/jpeg', 1.0)
                 console.log(contentDataURL);
                 var pdf = new jsPDF("l", "px", "a4");
                 var width = pdf.internal.pageSize.getWidth();
                 var height = pdf.internal.pageSize.getHeight();
                 //pdf.addImage(contentDataURL, 'PNG',10,10,0,0,null,'FAST',0);
                 //pdf.setFontSize(10);
                 pdf.addImage(contentDataURL, 'JPEG', 0, 0, width, height);
                 //if want add one page 
                 //pdf.addPage();
                 pdf.save('Filename.pdf');
             });
             this.viewStatementRptLRDetlsPrint = false;
             this.showSpinnerForAction = false;
         }, 5000);
     }*/

    /* pdfMethod(pdfTable) {
         this.modalRefferenceStatementRptPopUp.close();
 
         this.statementReportLrAdditionDataListSummReturn = [];
         this.statementReportLrAdditionDataListSummReturn = this.setStatementRptLrDetlsAddition();
 
         this.statementReportLrDeductionDataListSummReturn = [];
         this.statementReportLrDeductionDataListSummReturn = this.setStatementRptLrDetlsDeduction();
 
         localStorage.clear();
         localStorage.setItem('stmtRptLrDtlsAdditionPrintLocalStorage',
             JSON.stringify(this.statementReportLrAdditionDataListSummReturn));
 
         localStorage.setItem('stmtRptLrDtlsDeductionPrintLocalStorage',
             JSON.stringify(this.statementReportLrDeductionDataListSummReturn));
 
         localStorage.setItem('stmtRptLrDtlsGeneralPrintLocalStorage',
             JSON.stringify(this.stmtReportDataForSummaryPrint));
 
         this.showSpinnerForAction = true;
         this.viewStatementRptLRDetlsPrint = true;
         setTimeout(() => {
             const doc = new jsPDF();
 
             //let data = document.getElementById("pdfTable");
             let data = document.getElementById("stmtDtlsPrint_LR").innerHTML;
             console.log(data);
 
             //const pdfTable = this.pdfTable.nativeElement;
 
             // var html = htmlToPdfmake(printForShowStmtRptId.innerHTML);
             // var html = htmlToPdfmake(document.getElementById("printForShowStmtRptId").innerHTML);
             var newData=' <div>' + data + ' </div>';
             console.log(newData);
 
             var html = htmlToPdfmake(newData);
             console.log(html);
 
             const documentDefinition = {
                 content: html,
                 pageSize: 'A4',
                 fit: [100, 100],
             };
 
             pdfMake.createPdf(documentDefinition);
             //pdfMake.createPdf(documentDefinition).open();
             pdfMake.createPdf(documentDefinition).download('Filename.pdf');
 
             this.viewStatementRptLRDetlsPrint = false;
             this.showSpinnerForAction = false;
         }, 5000);
     }
 
     
 */
    /* pdfMethod(pdfTable) {
         this.modalRefferenceStatementRptPopUp.close();
 
         this.statementReportLrAdditionDataListSummReturn = [];
         this.statementReportLrAdditionDataListSummReturn = this.setStatementRptLrDetlsAddition();
 
         this.statementReportLrDeductionDataListSummReturn = [];
         this.statementReportLrDeductionDataListSummReturn = this.setStatementRptLrDetlsDeduction();
 
         localStorage.clear();
         localStorage.setItem('stmtRptLrDtlsAdditionPrintLocalStorage',
             JSON.stringify(this.statementReportLrAdditionDataListSummReturn));
 
         localStorage.setItem('stmtRptLrDtlsDeductionPrintLocalStorage',
             JSON.stringify(this.statementReportLrDeductionDataListSummReturn));
 
         localStorage.setItem('stmtRptLrDtlsGeneralPrintLocalStorage',
             JSON.stringify(this.stmtReportDataForSummaryPrint));
 
         this.showSpinnerForAction = true;
         this.viewStatementRptLRDetlsPrint = true;
         setTimeout(() => {
             let data = document.getElementById("stmtDtlsPrint_LR").innerHTML;
             //console.log(data);
 
             var content = '<!DOCTYPE html>';
             content += "<html><body><div id='export-content'>";
             content += data;
             content += "</div>";
             content += "</body></html>";
             console.log(content);
             // var converted = HtmlToDocx.asBlob(content, { orientation: "portrait" });
             // console.log(converted);
             // HtmlToDocx.saveAs(converted, 'test.docx');
             asBlob(content, { orientation: "landscape" }).then(data => {
                 saveAs(data, 'file.docx') // save as docx file
             }) // asBlob() return Promise<Blob|Buffer>
 
             this.viewStatementRptLRDetlsPrint = false;
             this.showSpinnerForAction = false;
         }, 5000);
     }*/

    setStatementRptLrDetlsAddition() {
        this.statementReportLrAdditionDataListSumm = [];
        if (this.additionValueSplit != null &&
            this.additionValueSplit != undefined &&
            this.additionValueSplit.length > 0) {
            for (let i = 0; i < this.additionValueSplit.length; i++) {
                var additionValue = this.additionValueSplit[i];
                var additionValueSplit = additionValue.split("_");
                this.additionValueLRDto = new LRDto();
                this.totalAmount_Addition = 0;
                this.totalAmount_Addition = additionValueSplit[1];

                this.additionValueLRDto.description = additionValueSplit[0];
                this.additionValueLRDto.totalAmount = this.totalAmount_Addition;
                this.statementReportLrAdditionDataListSumm.push(this.additionValueLRDto);
            }
        }
        return this.statementReportLrAdditionDataListSumm;
    }

    setStatementRptLrDetlsDeduction() {
        this.statementReportLrDeductionDataListSumm = [];
        if (this.deductionValueSpilt != null &&
            this.deductionValueSpilt != undefined &&
            this.deductionValueSpilt.length > 0) {
            for (let i = 0; i < this.deductionValueSpilt.length; i++) {
                var deductionValue = this.deductionValueSpilt[i];
                var deductionValueSplit = deductionValue.split("_");
                this.deductionValueLRDto = new LRDto();
                this.totalAmount_Deduction = 0;
                this.totalAmount_Deduction = deductionValueSplit[1];

                this.deductionValueLRDto.description = deductionValueSplit[0];
                this.deductionValueLRDto.totalAmount = this.totalAmount_Deduction;
                this.statementReportLrDeductionDataListSumm.push(this.deductionValueLRDto);
            }
        }
        return this.statementReportLrDeductionDataListSumm;
    }

    /* Customize Print Of Pop Up Details */

    clickListnerForPrintStmtRptLrDetails() {
        this.modalRefferenceStatementRptPopUp.close();

        this.lrDtoForLRDetailsPrint = new LRDto();
        this.lrDtoForLRDetailsPrint.fortNightStmtNo = this.stmtReportDataForSummaryPrint.stmtId;
        this.lrDtoForLRDetailsPrint.destination = this.stmtReportDataForSummaryPrint.destination;
        this.lrDtoForLRDetailsPrint.fromDate = this.stmtReportDataForSummaryPrint.fromPeriod;
        this.lrDtoForLRDetailsPrint.toDate = this.stmtReportDataForSummaryPrint.toPeriod;
        this.lrDtoForLRDetailsPrint.reportMode = "BookService";
        this.lrDtoForLRDetailsPrint.mode = "destination";
        this.lrDtoForLRDetailsPrint.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.destStmtReport.getBookingServiceTax(this.lrDtoForLRDetailsPrint).subscribe(
            (response) => {
                this.stmtRptLrDetailsReturnPrintLocalStorage = [];
                if (response.length > 0) {
                    this.stmtRptLrDetailsReturnPrintLocalStorage = response;
                    this.setPrintDataOfLRDetailsPopup(this.stmtRptLrDetailsReturnPrintLocalStorage);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred  While Statement Report LR Details", "info");
            }, () => console.log('done');

    }

    setPrintDataOfLRDetailsPopup(stmtRptLrDetailsReturnPrintLocalStorage) {
        console.log(this.cashMemoDtoForCustomPrintListHeadingNamesV1);
        if (stmtRptLrDetailsReturnPrintLocalStorage.length == 0) {
            swal({
                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = [
                "LR No", "Destination", "Art No", "Consignee Name",
                "Invoice No", "Invoice Date", "To Pay", "Paid", "Act Wgt",
                "Chg Wgt", "Serv Tax"];

            this.cashMemoDtoForCustomPrintListColumnWidths = [8, 10, 8, 15, 12, 12, 7, 7, 7, 7, 7];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.summary1 = this.summary2 = this.summary3 = this.summary4 = this.summary5 = this.summary6 = 0;
            for (let i = 0; i < stmtRptLrDetailsReturnPrintLocalStorage.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [
                    stmtRptLrDetailsReturnPrintLocalStorage[i].lrNumber,
                    stmtRptLrDetailsReturnPrintLocalStorage[i].destination,
                    stmtRptLrDetailsReturnPrintLocalStorage[i].totalArticles,
                    stmtRptLrDetailsReturnPrintLocalStorage[i].consigneeName,
                    stmtRptLrDetailsReturnPrintLocalStorage[i].invoiceNumber,
                    stmtRptLrDetailsReturnPrintLocalStorage[i].invoiceDateStr,
                    stmtRptLrDetailsReturnPrintLocalStorage[i].toPay,
                    stmtRptLrDetailsReturnPrintLocalStorage[i].paid,
                    stmtRptLrDetailsReturnPrintLocalStorage[i].actualWeight,
                    stmtRptLrDetailsReturnPrintLocalStorage[i].chargedWeight,
                    stmtRptLrDetailsReturnPrintLocalStorage[i].servicetax,
                ];
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.summary1 = this.summary1 + stmtRptLrDetailsReturnPrintLocalStorage[i].totalArticles;
                this.summary2 = this.summary2 + stmtRptLrDetailsReturnPrintLocalStorage[i].toPay;
                this.summary3 = this.summary3 + stmtRptLrDetailsReturnPrintLocalStorage[i].paid;
                this.summary4 = this.summary4 + stmtRptLrDetailsReturnPrintLocalStorage[i].actualWeight;
                this.summary5 = this.summary5 + stmtRptLrDetailsReturnPrintLocalStorage[i].chargedWeight;
            }

            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " +
                stmtRptLrDetailsReturnPrintLocalStorage.length, "",
            this.summary1, "", "", "", this.summary2, this.summary3, this.summary4, this.summary5, ""];
            //heading logics For Date
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Stmt No:", "Stmt of Acc"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.stmtReportDataForSummaryPrint.stmtId, this.stmtReportDataForSummaryPrint.destination];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Stmt Date", "Period:"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.stmtReportDataForSummaryPrint.stmtDateStr,
            this.stmtReportDataForSummaryPrint.fromPeriodStr + "-" + this.stmtReportDataForSummaryPrint.toPeriodStr];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Statement Report LR Details");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

}
