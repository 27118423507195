<!-- Row -->
<div class="row" *ngIf="isLoggedIn">

	<div class="col-lg-12">
		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Stocks To CP</h6>
			</div>
			<hr style="margin: 0px;">
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-6">
					<!--Spinner used-->
					<div class="row">
						<div class="col-md-12">
							<div class="card">
								<div class="card-body">
									<div class="row">
										<div class="col-md-12">
											<div class="card">
												<div class="card-body">
													<div class="row">
														<!-- spinner start-->
														<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
															<div class="form-group">
																<div class="input-group">
																	<mat-progress-bar mode="indeterminate"
																		style="color: green;">
																	</mat-progress-bar>
																	<br>
																	<h6 class="card-title" align='center'
																		style="color: green; margin: auto; font-size: 18px;">
																		Please Wait Loading Details.....</h6>
																</div>
															</div>
														</div>
														<!-- spinner end-->
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!--<div class="col-md-6">
								<div class="card">
									<div class="card-body">
										<div class="row">
											<div class="col-sm-12 col-md-12" [hidden]="!viewAlreadyReward">
												<h5 class="cstm_efftect" id="{{pageId}}isAlreadyRewardedHeadingId">
												</h5>
											</div>
										</div>
									</div>
								</div>
							</div>-->
					</div>
					<!--Spinner End-->
					<div class="row">
						<div class="col-md-6">
							<div class="card">
								<div class="card-body">
									<h6 class="card-title">LR Info</h6>
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>LR No<span style="color: red;">*</span></label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
																class="fas fa-clipboard-list"></i>
														</span>
													</div>
													<input type="text" class="form-control"
														(keypress)="lrNumberKeyPressEventListner($event);"
														aria-describedby="basic-addon11" id="{{pageId}}lrNumber"
														autocomplete="off">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Booking Date</label> <input id="{{pageId}}bookingDates"
														class="form-control" placeholder="yyyy-mm-dd"
														name="bookingDates" ngbDatepicker #bookingDates="ngbDatepicker"
														autocomplete="off" [(ngModel)]="bookingDateModal"
														[readonly]="isReadonlyBookingDate">
													<div class="input-group-append" (click)="bookingDates.toggle()">
														<span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Invoice No</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-file-alt"></i>
														</span>
													</div>
													<input type="text" class="form-control" id="{{pageId}}invoiceNumber"
														aria-describedby="basic-addon11" autocomplete="off"
														[readonly]="isReadonlyInvoiceNo">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Destination</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class=" fas fa-road"></i>
														</span>
													</div>
													<input type="text" class="form-control" id="{{pageId}}destination"
														aria-describedby="basic-addon11" autocomplete="off"
														[readonly]="isReadonlyDestination">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Consignee Name</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class=" fas fa-user"></i>
														</span>
													</div>
													<input type="text" class="form-control"
														aria-describedby="basic-addon11" id="{{pageId}}consigneeName"
														autocomplete="off" [readonly]="isReadonlyConsigneeName">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Total Article</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-clone"></i>
														</span>
													</div>
													<input type="number" class="form-control"
														aria-describedby="basic-addon11" id="{{pageId}}totalArticle"
														autocomplete="off" [readonly]="isReadonlyArticle">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Vehicle No</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class=" fas fa-truck"></i>
														</span>
													</div>
													<input type="text" class="form-control"
														aria-describedby="basic-addon11" id="{{pageId}}vehicleNumber"
														autocomplete="off" [readonly]="isReadonlyVehicleNo">
												</div>
											</div>
										</div>

										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Driver Name</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class=" fas fa-user"></i>
														</span>
													</div>
													<input type="text" class="form-control"
														aria-describedby="basic-addon11" id="{{pageId}}driverName"
														autocomplete="off" [readonly]="isReadonlyDriverName">
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- <hr> -->
							</div>
						</div>

						<div class="col-md-6 vl">
							<div class="card">
								<div class="card-body">
									<h6 class="card-title">CP Info</h6>
									<div class="row">
										<div class="col-sm-12 col-md-12">

											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>Check Post
															<span style="color: red;">*</span>
														</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-map-marker-alt"></i>
															</span>
														</div>
														<input type="text" #checkPost class="form-control"
															id="{{pageId}}checkPost" placeholder="Select Check Post"
															(selectItem)="checkPostDropDownListner($event,popModelNewCheckPostContent)"
															[(ngModel)]="modelcheckPost"
															[ngbTypeahead]="checkPostSearchTA"
															[resultFormatter]="formatterCheckPost"
															[inputFormatter]="formatterCheckPost"
															(focus)="focusCheckPostTA$.next($any($event).target.value)" />
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Notice No
														<span style="color: red;">*</span>
													</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-hashtag"></i>
														</span>
													</div>
													<input type="number" class="form-control" id="{{pageId}}noticeNo"
														aria-describedby="basic-addon11" autocomplete="off">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Date
														<span style="color: red;">*</span>
													</label> <input id="{{pageId}}dates" class="form-control"
														placeholder="yyyy-mm-dd" name="dates" ngbDatepicker
														#dates="ngbDatepicker" autocomplete="off"
														[(ngModel)]="dateModal">
													<div class="input-group-append" (click)="dates.toggle()">
														<span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>


									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}toCpBtn"
								(click)="validateToCheckPostBtn();">To
								CP</button>
							<!--<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}toStockBtn">
								To Stock</button>-->
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
								(click)="overAllClearBtnListner();">Clear</button>
						</div>
					</div>
				</div>
				<div class="col-md-6 vl">
					<div class="card">
						<div class="card-body">
							<div class="box-body">
								<h6 style="border-bottom: 1px solid orange;" class="card-title">LRs
									In Check Post</h6>
								<table datatable id="{{pageId}}stocksToCPTableId"
									class="table table-striped table-bordered row-border hover"
									[dtOptions]="dtOptionsStocksToCp" [dtTrigger]="dtTriggerStocksToCp">

									<thead>
										<tr>
											<th>LR No</th>
											<th>Notice No</th>
											<th>Check Post Name</th>
											<th>Invoice No</th>
											<th>Date</th>
											<th>Vehicle</th>
											<th>Action</th>
											<th [hidden]="!showOnlyForPrint">Article</th>
											<th [hidden]="!showOnlyForPrint">Consignee</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let stocksToCpData of stocksToCpDataList ">
											<td>{{ stocksToCpData.lrNumber }}</td>
											<td>{{ stocksToCpData.noticeNumber }}</td>
											<td>{{ stocksToCpData.checkPostName }}</td>
											<td>{{ stocksToCpData.invoiceNumber }}</td>
											<td>{{ stocksToCpData.catchDateStr }}</td>
											<td>{{ stocksToCpData.vehicleNumber }}</td>
											<td>

												<button type="button"
													style="padding: 1px 4px; background-color: #ffffff00;"
													class="btn m-r-10" id="{{pageId}}tableStockToCpBtnId"
													(click)="selectedRowForStockToCp(stocksToCpData);">
													<i title="To Stock" class="fas fa-redo"></i>
												</button>
												<!--<button type="button"
													style="padding: 1px 4px; background-color: #ffffff00;"
													class="btn m-r-10" id="{{pageId}}tableStockToCpEditBtnId"
													(click)="editSelectedRowForStockToCp(stocksToCpData);">
													<i title="Edit" class="fas fa-edit"></i>
												</button>-->
											</td>
											<td [hidden]="!showOnlyForPrint">{{ stocksToCpData.totalArticles }}</td>
											<td [hidden]="!showOnlyForPrint">{{ stocksToCpData.consigneeName }}</td>
										</tr>
									</tbody>
									<tfoot>
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
											<td [hidden]="!showOnlyForPrint"></td>
											<td [hidden]="!showOnlyForPrint"></td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>

<div class="col-md-12">
	<div class="form-group">
		<div class="input-group">
			<ng-template #popModelNewCheckPostContent let-save="close" let-cancel="close" let-d="dismiss">
				<div class="modal-header">
					<h4>Add Check Post</h4>
					<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="row" style="text-align: center;">
						<div class="col-md-12">
							<div class="col-sm-12 col-md-12">
								<div class="form-group">
									<div class="input-group">
										<label>Check Post Name</label>
										<div class="input-group-prepend">
											<span class="input-group-text"> <i class="fas fa-map-marker-alt"></i>
											</span>
										</div>
										<input type="text" class="form-control" id="{{pageId}}newCheckPostName"
											aria-describedby="basic-addon11" autocomplete="off">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-outline-secondary"
						(click)="saveNewCheckPostNameBtn();">Save</button>
					<button type="button" class="btn btn-outline-danger"
						(click)="cancel('Cancel click')">Cancel</button>
				</div>
			</ng-template>


		</div>
	</div>
</div>