<div class="page row system_responsive" id="{{pageId}}firstPrint">
    <div class="col-md-12">
        <!--first row starts-->
        <div class="row" style="padding-top: 5px;" height='30px'>
            <div class="col-md-7">
                <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
            </div>
            <div class="col-md-5">
                <h6>
                    {{address}}<br>
                </h6>
            </div>
        </div>
        <!--first row starts-->
        <div class="row">
            <div class="col-md-12">
                <h6 style='margin-left: 20px; font-size: 22px;' align='center'>
                    <u><strong>Payment Voucher</strong></u>
                </h6>
            </div>

        </div>

        <div class="row">
            <div class="col-md-7">
                <h6 style='margin-left: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Debit To : </strong> <strong>{{mainBookingStation}}
						- </strong><strong>{{toStation}}</strong>
                </h6>
            </div>
            <div class="col-md-5" align='right'>
                <h6 style='margin-right: 20px; font-size: 19px; padding-top: 30px;'>
                    <strong>Date : </strong> <strong>{{todayDate}}</strong>
                </h6>
            </div>
        </div>
        <!--Second  row starts-->
        <div class="container border">
            <div>
                <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;'>
                    <div class="row">
                        <div class="col-md-12">
                            <h6 style='font-size: 19px; border-bottom: 1px solid #000;' align='center'>
                                <strong>PARTICULARS </strong>
                            </h6>
                        </div>

                    </div>


                    <table width='100%' border='0' cellspacing='0' cellpadding='1' style='table-layout: fixed;' height='180px;'>
                        <div class="row" style='border-bottom: 1px solid #000; flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-5" style='border-right: 1px solid #000;'>
                                <h6 style='font-size: 19px;' align='center'>
                                    <strong>Invoice / Lorry Details</strong>
                                </h6>
                            </div>
                            <div class="col-md-4" style='border-right: 1px solid #000;'>
                                <h6 style='font-size: 19px;' align='center'>
                                    <strong>Bank Details</strong>
                                </h6>
                            </div>
                            <div class="col-md-3">
                                <h6 style='font-size: 19px;' align='center'>
                                    <strong>Amount Details</strong>
                                </h6>
                            </div>
                        </div>
                        <div class="row" style='flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-5" style='border-right: 1px solid #000;padding-bottom: 5px;'>
                                <div class="row">
                                    <div class="col-md-4">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong>Invoice No </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-8">

                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>: {{invoiceNumber}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style='border-right: 1px solid #000;padding-bottom: 5px;'>
                                <div class="row">
                                    <div class="col-md-5">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong>Bank Name </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7">

                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>: {{bankName}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div [hidden]="!showHireColumns" class="col-md-3">
                                <div class="row">
                                    <div class="col-md-7">
                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>
                                            <strong>Hire Amt </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-5">
                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='right'>: {{lorryHireAmt}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" style='flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-5" style='border-right: 1px solid #000;padding-bottom: 5px;'>
                                <div class="row">
                                    <div class="col-md-4">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong>Invoice Date </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-8">

                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>: {{invoicedate}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style='border-right: 1px solid #000;padding-bottom: 5px;'>
                                <div class="row">
                                    <div class="col-md-5">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong>Cheque No </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7">

                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>: {{chqNo}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div [hidden]="!showHireColumns" class="col-md-3">
                                <div class="row">
                                    <div class="col-md-7">
                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>
                                            <strong>Less Adv </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-5">
                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='right'>: {{lessAdvance}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style='flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-5" style='border-right: 1px solid #000;padding-bottom: 5px;'>
                                <div class="row">
                                    <div class="col-md-4">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong>Hireslip No </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-8">

                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>: {{hireslipNumber}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style='border-right: 1px solid #000;padding-bottom: 5px;'>
                                <div class="row">
                                    <div class="col-md-5">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong>Cheque Date</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7">

                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>: {{chqDate}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="row">
                                    <div class="col-md-7">
                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>
                                            <strong>Less Late Arrival </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-5">
                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='right'>: {{lessLateArrival}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style='flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-5" style='border-right: 1px solid #000;padding-bottom: 5px;'>
                                <div class="row">
                                    <div class="col-md-4">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong>Supplier </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-8">

                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>: {{supplierName}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style='border-right: 1px solid #000;padding-bottom: 5px;'>
                                <div class="row">
                                    <div class="col-md-5">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong></strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong> </strong>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="row">
                                    <div class="col-md-7">
                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>
                                            <strong>Less Unload Hamali </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-5">
                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='right'>: {{lessUnloadHamali}}
                                        </h6>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row" style='flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-5" style='border-right: 1px solid #000;padding-bottom: 5px;'>
                                <div class="row">
                                    <div class="col-md-4">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong>Lorry No </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-8">

                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>: {{lorryNo}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style='border-right: 1px solid #000;padding-bottom: 5px;'>
                                <div class="row">
                                    <div class="col-md-5">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong></strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong> </strong>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="row">
                                    <div class="col-md-7">
                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>
                                            <strong>Less Munishiana </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-5">
                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='right'>: {{lessMunshi}}
                                        </h6>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row" style='flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-5" style='border-right: 1px solid #000;padding-bottom: 5px;'>
                                <div class="row">
                                    <div class="col-md-4">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong>Driver Name </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-8">

                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>: {{drivername}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style='border-right: 1px solid #000;padding-bottom: 5px;'>
                                <div class="row">
                                    <div class="col-md-5">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong></strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong> </strong>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="row">
                                    <div class="col-md-7">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong></strong>
                                        </h6>
                                    </div>

                                    <div class="col-md-5">

                                        <h6 style='font-size: 18px;'>
                                            <strong></strong>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style='flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-5" style='border-right: 1px solid #000;padding-bottom: 5px;'>
                                <div class="row">
                                    <div class="col-md-4">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong>Broker Name</strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-8">

                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>: {{brokerName}}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style='border-right: 1px solid #000;padding-bottom: 5px;'>
                                <div class="row">
                                    <div class="col-md-5">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong></strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-7">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong> </strong>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="row">
                                    <div class="col-md-7">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong></strong>
                                        </h6>
                                    </div>

                                    <div class="col-md-5">

                                        <h6 style='font-size: 18px;'>
                                            <strong></strong>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style='border-top: 1px solid #000; flex-wrap: wrap; margin-right: -1px; margin-left: -1px;'>
                            <div class="col-md-8" style='border-right: 1px solid #000;'>
                                <div class="row">
                                    <div class="col-md-3">

                                        <h6 style='font-size: 18px;' align='left'>
                                            <strong>In Words: </strong>
                                        </h6>
                                    </div>
                                    <div class="col-md-9">

                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>
                                            <strong>{{printAmt}} </strong>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="row">
                                    <div class="col-md-7">

                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='left'>
                                            <strong>Total&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong>
                                        </h6>
                                    </div>

                                    <div class="col-md-5">

                                        <h6 style='font-size: 18px; word-wrap: break-word;' align='right'>
                                            <strong>{{totalAmount}}</strong>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </table>




                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h6 style='font-size: 18px; margin-left: 20px;'>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <h6>
                    <strong></strong>
                </h6>
            </div>

        </div>
        <div class="row">
            <div class="col-md-6">
                <h6 style='font-size: 18px; padding-top: 22px;'>
                    <strong>Approved By</strong>
                </h6>
            </div>


            <div class="col-md-6" align='right'>
                <h6 style='font-size: 18px; padding-top: 22px;'>
                    <strong> Receiver's Signature&nbsp;&nbsp;&nbsp;&nbsp;</strong>
                </h6>
            </div>

        </div>

    </div>
</div>