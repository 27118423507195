import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { ElementRef } from "@angular/core";

import { ReportService } from 'src/app/dataService/report-service';

import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

const my = new Date();

////
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';



import { ConsignorService } from 'src/app/dataService/consignor-service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for drag and drop starts
import { DragulaService, dragula } from "ng2-dragula";
//for drag and drop ends
//for popup modal starts 
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from "src/app/dataService/memo-service";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MemoReport } from 'src/app/dataService/memo-report';
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import *  as moment from 'moment';
@Component({
	selector: 'app-collection-man-cashmemo-stock-check',
	templateUrl: './collection-man-cashmemo-stock-check.component.html',
	styleUrls: ['./collection-man-cashmemo-stock-check.component.css']
})
export class CollectionManCashmemoStockCheckComponent implements OnInit {
	gettingDataFrmServiceFrCollectionManCashmemoStockCheckTable: any;
	onDestroyUnsubscribtionCollectionManCashmemoStockCheck: Subscription;
	loadingIndicator = true;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerCollectionManCashmemoStockCheck: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsCollectionManCashmemoStockCheck: any;
	manualView = false;
	automaticView = true;

	address: any;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	modelStation: any;
	stationTA: Array<PartyMasterDto> = [];
	focusStationTA$ = new Subject<string>();
	selectedEntryType: any;
	memoFormat: any;
	modelStationName: any;
	selectedMemoNumberForManual: any;
	selectedMemoNumberForAutomatic: any;
	selectedCollectionMan: any;
	memoNum: any;
	searchCashmemoNumber: CashMemoDto = new CashMemoDto();
	stationForCode: any;
	selectedCodeForManual: any;
	collectionManCashmemoStockCheckDataList: Array<any> = [];
	private newAttribute: any = {};
	textMemoCount = '';
	memoCount = 0;
	lblMemoCount = false;
	stationSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusStationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.stationTA
				: this.stationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterStation = (x: { destination: string }) => x.destination;
	////
	lrDtoForStation: LRDto = new LRDto();
	stationOptions: any;
	userDtoMemoCode: UserDataDto = new UserDataDto();
	stationCodeDetails: any;
	checkMemoNumber: CashMemoDto = new CashMemoDto();
	checkMemoNumberForTable: CashMemoDto = new CashMemoDto();
	responseOfCheckMemoNumber: any;
	responseOfCheckMemoNumberForTable: any;
	showStatus: any;

	searchCashmemoNumberForTable: CashMemoDto = new CashMemoDto();
	updateCashmemoNumberForTable: CashMemoDto = new CashMemoDto();
	countOfLr = 0;
	sumOfTotAmt = 0;
	sumOfTotArt = 0;
	////
	//for collection Man
	collectionManAllOption: UserDataDto = new UserDataDto();
	collectionManTA: Array<UserDataDto> = [];
	focusCollectionManTA$ = new Subject<string>();
	collectionManSearchTA = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusCollectionManTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.collectionManTA
				: this.collectionManTA.filter(v => v.collectioMan.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterCollectionMan = (x: { collectioMan: string }) => x.collectioMan;
	collectionManDataList: any;

	////
	userDtoCollectionMan: UserDataDto = new UserDataDto();
	setStation: Array<any> = [];
	selectionStation: any;
	collectionManCashmemoStockCheckPrintPopUp: NgbModalRef;
	collectionManCashmemoStockCheckPrintClosePopUp: string;
	getPrintData: any;

	@ViewChild('collectionManCashmemoStockCheckPrintPopUpTemplate') private collectionManCashmemoStockCheckPrintPopUpTemplate;
	viewCollectionManCashmemoStockCheckPrintPopUp = false;
	cashmemoDtoForPrint: CashMemoDto = new CashMemoDto();
	todayDate: any;
	memoDateCheck: Date;
	isReadOnly: boolean;
	collectionManMemoEnblStatus: any;
	memoAssignEnabledObj: any;
	pageId = "cmcsc";

	constructor(private memoLessRpt: ReportService,
		private router: Router,
		private memoReport: MemoReport,
		private masterReadService: MasterReadService,
		private modalService: NgbModal,
		public changeDetectorRef: ChangeDetectorRef,
		private datePipe: DatePipe) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.getStationDtails();
			this.getCollectionManDetailsList();
			if (this.userDataDtoReturnSession.mainStation == "Chennai" && this.userDataDtoReturnSession.role != "Administrator") {
				// $("#mncmcentryType").prop("disabled", true);
				this.isReadOnly = true;
			} else {
				// $("#mncmcentryType").prop( "disabled", false );
				this.isReadOnly = false;
			}
		}
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}


	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		var mainStation = this.userDataDtoReturnSession.mainStation;

		this.dtOptionsCollectionManCashmemoStockCheck = {

			dom: 'Bfrtip',
			buttons: [

			],
			//place holder in search/filter in datatable starts
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"info": false,
            "paging": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var totalAmt = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totalArt = api.column(4).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				// Update footer by showing the total with the reference of the column index 
				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html();
				$(api.column(2).footer()).html(totalAmt);
				$(api.column(3).footer()).html();
				$(api.column(4).footer()).html(totalArt);
				$(api.column(5).footer()).html();
				$(api.column(6).footer()).html();
				$(api.column(7).footer()).html();

			}

		}
	}

	ngOnDestroy(): void {
		this.dtTriggerCollectionManCashmemoStockCheck.unsubscribe();

	}
	ngAfterViewInit(): void {
		this.dtTriggerCollectionManCashmemoStockCheck.next();

	}

	entryTypeMode(entryType: string) {
		if (entryType === 'manual') {
			this.manualView = true;
			this.automaticView = false;
		} else if
			(entryType === 'automatic') {
			this.manualView = false;
			this.automaticView = true;
		} else {
			this.manualView = false;
			this.automaticView = false;
		}
	}
	getDetailsForCollectionMasterRead() {
		this.userDtoCollectionMan = new UserDataDto();
		this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;
		this.userDtoCollectionMan.companyId = this.userDataDtoReturnSession.companyId;
		this.userDtoCollectionMan.status = "D";
	}

	getCollectionManDetailsList() {
		this.collectionManDataList = [];
		this.collectionManTA = [];
		this.getDetailsForCollectionMasterRead();
		this.masterReadService.getCollectionManMasterDetails(this.userDtoCollectionMan).subscribe(
			(response) => {
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Collection Man Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.collectionManDataList = [];
					this.collectionManTA = [];

				} else {
					console.log(response);
					this.collectionManDataList = response;
					console.log(this.collectionManDataList);
					this.collectionManTA = [];
					for (let i = 0; i < this.collectionManDataList.length; i++) {
						this.collectionManTA.push(this.collectionManDataList[i]);
					}

				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Collection Man details", "info");
			}, () => console.log('done');
	};
	getStationInfo() {
		this.lrDtoForStation = new LRDto();
		this.lrDtoForStation.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoForStation.mainstation = this.userDataDtoReturnSession.mainStation;
		this.lrDtoForStation.mode = "ALL";
	}
	getStationDtails = () => {
		this.getStationInfo();
		this.masterReadService.getDestinationForLREntryService(this.lrDtoForStation).subscribe(
			(response) => {
				if (response) {
					this.stationOptions = response;
					this.lrDtoForStation = new LRDto();
					this.stationTA = [];
					//                    console.log(this.stationOptions.length);
					for (let i = 0; i < this.stationOptions.length; i++) {
						this.stationTA.push(this.stationOptions[i]);
					}
					console.log(this.stationTA, this.stationOptions);
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				swal("Error", "Server Error While Getting Station Details", "info");
			}, () => console.log('done');
	};

	getCollectionManMemoEnableStatus(e: NgbTypeaheadSelectItemEvent) {
		// console.log("this.memoAssignEnabledObj >> before");
		// console.log(this.memoAssignEnabledObj);
		this.memoAssignEnabledObj = null;
		this.collectionManMemoEnblStatus = e.item;
		// console.log("this.collectionManMemoEnblStatus");
		// console.log(this.collectionManMemoEnblStatus);
		this.memoAssignEnabledObj = this.collectionManMemoEnblStatus.memoAssignEnabled;
		// console.log("this.memoAssignEnabledObj after");
		// console.log(this.memoAssignEnabledObj);

	}
	getMemoCode(e: NgbTypeaheadSelectItemEvent) {
		this.userDtoMemoCode = new UserDataDto();
		this.modelStation = e.item;
		this.userDtoMemoCode.companyId = this.userDataDtoReturnSession.companyId;
		this.stationForCode = this.modelStation.destination;
		this.userDtoMemoCode.office = this.stationForCode;
		console.log(this.userDtoMemoCode);
		this.getDetailsForMemoCode();
		$("#" + this.pageId + "checkBtn").prop("disabled", false);
		$("#" + this.pageId + "unCheckBtn").prop("disabled", false);
	}
	getDetailsForMemoCode() {
		this.masterReadService.getStationCodeService(this.userDtoMemoCode).subscribe(
			(response) => {
				if (response.length != 0) {
					this.stationCodeDetails = response;
					this.selectedEntryType = $("#" + this.pageId + "entryType").val();


					if (this.selectedEntryType == "automatic") {
						this.manualView = false;
						this.automaticView = true;
						$("#cmcsccashMemoAutomatic").focus();
					} else {

						if (this.stationCodeDetails.stationCode == null) {
							$("#" + this.pageId + "codeForManual").val('');
						} else {
							//Basha Pending
							this.memoFormat = this.stationCodeDetails.stationCode + "/" + this.userDataDtoReturnSession.financialYearSplit + "/";
							//                                this.memoFormat = 'BNG/1920/';
							$("#" + this.pageId + "codeForManual").val(this.memoFormat);
							$("#" + this.pageId + "cashMemoManual").val('');
							$("#" + this.pageId + "cashMemoAutomatic").val('');
							$("#cmcsccashMemoManual").focus();
						}
					}

					this.userDtoMemoCode = new UserDataDto();
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => swal({
				title: "Server Error",
				text: "While Getting Station",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}),
			() => console.log('done');
	};


	getMemoNumberForManual(e) {
		if (e.keyCode == 13) {
			this.selectedMemoNumberForManual = $("#" + this.pageId + "cashMemoManual").val();
			this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
			if (this.selectedMemoNumberForManual != null && this.selectedMemoNumberForManual != '') {

				if (this.selectedCollectionMan == null && this.selectedCollectionMan == '') {
					swal({
						title: "Mandatory Fields",
						text: "Please select Collection Man to proceed",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						swal.close();
						window.setTimeout(function () {
							$("#cmcsccollectionMan").focus();
						}, 100);
						return false;
					});
				} else {
					this.getManualCashMemoCheckMtd();
				}
			}
			else {
				swal({
					title: "Warning",
					text: "Memo Number Empty",
					icon: "error",
					closeOnClickOutside: false,
					closeOnEsc: false,
				}).then(() => {
					swal.close();

					window.setTimeout(function () {
						$("#cmcsccashMemoManual").focus();
					}, 100);
					return false;
				});
			}
		}

	}

	getMemoNumberForAutomatic(e) {
		/*setTimeout(() => {
			this.showforAutomatic();
		},
			500 );*/
		this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "cashMemoAutomatic").val();
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		console.log(this.selectedCollectionMan);

		if (e.keyCode == 13) {
			if (this.selectedMemoNumberForAutomatic != null && this.selectedMemoNumberForAutomatic != '') {

				if (this.selectedCollectionMan == null && this.selectedCollectionMan == '') {
					swal({
						title: "Mandatory Fields",
						text: "Please select Collection Man to proceed",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						swal.close();
						window.setTimeout(function () {
							$("#cmcsccollectionMan").focus();
						}, 100);
						return false;
					});
				} else {
					this.getManualCashMemoCheckMtd();
				}
			} else {
				swal({
					title: "Warning",
					text: "Memo Number Empty",
					icon: "error",
					closeOnClickOutside: false,
					closeOnEsc: false,
				}).then(() => {
					swal.close();
					window.setTimeout(function () {
						$("#cmcsccashMemoAutomatic").focus();
					}, 100);
					return false;
				});
			}
		}


	}
	/*  showforAutomatic() {
			this.selectedMemoNumberForAutomatic = $("#"+this.pageId+"cashMemoAutomatic" ).val();
			 this.selectedCollectionMan = $("#"+this.pageId+"collectionMan" ).val();
			 console.log( this.selectedCollectionMan);
			if ( this.selectedMemoNumberForAutomatic != null && this.selectedMemoNumberForAutomatic != '' ) {
			 
				if ( this.selectedCollectionMan == null && this.selectedCollectionMan == '' ) {
				swal({
					title: "Mandatory Fields",
					text: "Please select Collection Man to proceed",
					icon: "error",
					closeOnClickOutside: false,
					closeOnEsc: false,
				}).then(() => {
					swal.close();
				window.setTimeout(function () {
				   $("#cmcsccollectionMan" ).focus();
				}, 100);
				 return false;
				});
				}else{
				this.getManualCashMemoCheckMtd();
				}
			}else{
				swal({
						title: "Warning",
						text: "Memo Number Empty",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						swal.close();
						 window.setTimeout(function () {
							   $("#cmcsccashMemoAutomatic" ).focus();
						}, 100);
					 return false;
					});
			}
		}*/
	getManualCashMemoCheckMtd() {
		this.memoNum = '';
		this.selectedEntryType = $("#" + this.pageId + "entryType").val();
		this.selectedCodeForManual = $("#" + this.pageId + "codeForManual").val();
		this.selectedMemoNumberForManual = $("#" + this.pageId + "cashMemoManual").val();
		this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "cashMemoAutomatic").val();
		if (this.selectedEntryType == 'automatic') {
			this.memoNum = this.selectedMemoNumberForAutomatic;

		} else {
			this.memoNum = this.selectedCodeForManual + "" + this.selectedMemoNumberForManual;

		}
		this.searchCashmemoNumber = new CashMemoDto();
		this.searchCashmemoNumber.memoNumber = this.memoNum;
		this.getManualCashMemoCheckMtdDetails();
		this.showSpinnerForAction = true;
	}
	getManualCashMemoCheckMtdDetails() {
		this.memoReport.getManualCashMemoCheckFirst(this.searchCashmemoNumber).subscribe(
			(response) => {
				console.log(response);
				// console.log("response");
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "okBtn").prop("disabled", true);
				if (response.length > 0) {
					this.showSpinnerForAction = false;
					this.checkMemoNumber = new CashMemoDto();
					this.responseOfCheckMemoNumber = response;
					this.checkMemoNumber = this.responseOfCheckMemoNumber;
					console.log(this.checkMemoNumber);
					console.log(this.checkMemoNumber[0].lrNumber);
					if (this.checkMemoNumber[0].lrNumber != null && this.checkMemoNumber[0].lrNumber != '') {
						if (((this.checkMemoNumber[0].status == null) && (this.checkMemoNumber[0].column1 == null) && (this.checkMemoNumber[0].memoCheck == null)) || (this.checkMemoNumber[0].memoCheck == null)) {
							swal({
								title: "Empty",
								text: "Cashmemo number not found",
								icon: "error",
								closeOnClickOutside: false,
								closeOnEsc: false,
							}).then(() => {
								this.clearMethod();

							});
						} else {
							this.showStatus = '';
							if ((this.checkMemoNumber[0].memoCheck != null) && (this.checkMemoNumber[0].memoCheck == false)) {
								if (this.checkMemoNumber[0].collectionMan != this.selectedCollectionMan) {

									this.showStatus = ' This memo is in ' + this.checkMemoNumber[0].collectionMan + " stock, system can't allow to check in different collection man, please verify once!";
									swal({
										title: "Status",
										text: " " + this.showStatus,
										icon: "warning",
										closeOnClickOutside: false,
										closeOnEsc: false,
									}).then(() => {
										this.clearMethod();

									});
								} else if ((this.checkMemoNumber[0].status == 'cmstock') && (this.checkMemoNumber[0].column1 == 'Pending')) {
									// insert into grid @Dated :
									// 29/08/2018
									this.gridReconfigure();
									$("#" + this.pageId + "memoAmount").val(this.checkMemoNumber[0].grandTotal);
									$("#" + this.pageId + "lrNumber").val(this.checkMemoNumber[0].lrNumber);
									/*$("#"+this.pageId+"okBtn" ).prop( "disabled", false );
									$("#cmcscokBtn" ).focus();
									this.clearMethod1();*/
									this.selectedEntryType = $("#" + this.pageId + "entryType").val();

									/*if ( this.selectedEntryType == 'automatic' ) {
										   if((this.checkMemoNumber[0].cmstockMemoCheck != null) && (this.checkMemoNumber[0].cmstockMemoCheck == false)){
												this.lblMemoCount=true;
												this.memoCount= +this.memoCount + +1;
												this.textMemoCount=" No. of cashmemos: "+this.memoCount;
										   }
										this.updateMethod(this.memoNum);
										
												this.lblMemoCount=true;
												this.memoCount= +this.memoCount + +1;
												this.textMemoCount=" No. of cashmemos: "+this.memoCount;
										   
												
												this.clearMethod();
		   
								} */

								} else {
									this.showStatus = '';
									if (this.checkMemoNumber[0].status == 'billing') {
										this.showStatus = ' Cash Memo is in Billing Stock';
									} else if (this.checkMemoNumber[0].status == 'transfer') {
										this.showStatus = ' Cash Memo not in SRD Collection Stock';
									} else if ((this.checkMemoNumber[0].status == 'collection') && (this.checkMemoNumber[0].column1 == 'completed')) {
										this.showStatus = ' Collection Closed';
									} else if ((this.checkMemoNumber[0].status == 'collection') && (this.checkMemoNumber[0].column1 == 'Pending')) {
										this.showStatus = ' Cash Memo is in Collection Stock';
									} else if ((this.checkMemoNumber[0].status == 'cmstock') && (this.checkMemoNumber[0].column1 == 'Assigned')) {
										this.showStatus = ' Cash Memo assigned for Collection';
									} else if (this.checkMemoNumber[0].status == 'to party') {
										this.showStatus = ' Cash Memo Left To-Party';
									} else if (this.checkMemoNumber[0].status == 'completed') {
										this.showStatus = ' Collection Closed';
									} else if (this.checkMemoNumber[0].status == 'transferToGodown') {
										this.showStatus = ' Cash Memo not in SRD Collection Stock';
									} else if (this.checkMemoNumber[0].status == 'missing') {
										this.showStatus = ' Cash Memo is Missing';
									}
									swal({
										title: "Status",
										text: " " + this.showStatus,
										icon: "warning",
										closeOnClickOutside: false,
										closeOnEsc: false,
									}).then(() => {
										this.clearMethod();

									});
								}

							} else if ((this.checkMemoNumber[0].memoCheck != null) && (this.checkMemoNumber[0].memoCheck == true)) {
								swal({
									title: "Status",
									text: "Cash Memo Number Already Checked",
									icon: "warning",
									closeOnClickOutside: false,
									closeOnEsc: false,
								}).then(() => {
									this.clearMethod();

								});

							}
						}

					} else {
						swal({
							title: "Warning",
							text: "Cash memo number does not exist",
							icon: "error",
							closeOnClickOutside: false,
							closeOnEsc: false,
						}).then(() => {
							this.clearMethod();
						});
					}
				} else {
					swal({
						title: "Warning",
						text: "Cash memo number does not exist",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						this.clearMethod();
					});

				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While Getting Memo Number Details", "info");
			}, () => console.log('done');
	};

	clearMethod() {
		this.selectedEntryType = $("#" + this.pageId + "entryType").val();
		$("#" + this.pageId + "memoAmount").val('');
		$("#" + this.pageId + "lrNumber").val('');
		if (this.selectedEntryType == 'automatic') {
			$("#" + this.pageId + "cashMemoAutomatic").val('');
			//$("#cmcsccashMemoAutomatic" ).focus();
			window.setTimeout(function () {
				$("#cmcsccashMemoAutomatic").focus();
			}, 100);
		} else {
			$("#" + this.pageId + "cashMemoManual").val('');
			window.setTimeout(function () {
				$("#cmcsccashMemoManual").focus();
			}, 100);
			return false;

		}
	}
	gridReconfigure() {
		this.selectedEntryType = $("#" + this.pageId + "entryType").val();
		if (this.collectionManCashmemoStockCheckDataList.length > 0) {
			const checkMemoNumberInTable = this.collectionManCashmemoStockCheckDataList.find(collectionManCashmemoStockCheckData => collectionManCashmemoStockCheckData.memoNumber === this.memoNum);
			console.log(checkMemoNumberInTable);

			if (checkMemoNumberInTable == undefined) {

				this.checkingBeforeInsertInTableDetails();
				// if (this.selectedEntryType == 'automatic') {
				// 	this.updateMethod(this.memoNum);

				// 	this.lblMemoCount = true;
				// 	this.memoCount = +this.memoCount + +1;
				// 	this.textMemoCount = " No. of cashmemos: " + this.memoCount;
				// 	this.clearMethod();

				// }
			} else {

				swal({
					title: "Warning",
					text: "Duplicate Memo Number",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				}).then(() => {
					swal.close();
					this.clearMethod();
					return false;
				});
			}

		} else {
			console.log('5')

			this.checkingBeforeInsertInTableDetails();
			// if (this.selectedEntryType == 'automatic') {
			// 	this.updateMethod(this.memoNum);
			// 	this.lblMemoCount = true;
			// 	this.memoCount = +this.memoCount + +1;
			// 	this.textMemoCount = " No. of cashmemos: " + this.memoCount;
			// 	this.clearMethod();

			// }
		}

	}
	checkingBeforeInsertInTableDetails() {
		this.searchCashmemoNumberForTable = new CashMemoDto();
		this.searchCashmemoNumberForTable.memoNumber = this.memoNum;
		this.checkingBeforeInsertInTable();

	}
	checkingBeforeInsertInTable = () => {
		this.memoReport.getManualCashMemoCheckFirst(this.searchCashmemoNumberForTable).subscribe(
			(response) => {
				if (response.length == 0) {
					swal({
						title: "Alert",
						text: "No Datas Found For This Information",
						icon: "error",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						this.clearMethod();
					});
				} else {
					this.checkMemoNumberForTable = new CashMemoDto();
					this.responseOfCheckMemoNumberForTable = response;
					this.checkMemoNumberForTable = this.responseOfCheckMemoNumberForTable;
					// console.log("this.checkMemoNumberForTable");
					// console.log(this.checkMemoNumberForTable);

					//  this.addInTable();
					console.log('6');
					this.blockMemoAsPerMemoTimePeriod(this.checkMemoNumberForTable);

				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => console.log(error.json()),
			() => console.log('done');
	};

	blockMemoAsPerMemoTimePeriod(checkMemoNumberForTableArgument) {
		console.log("this.memoAssignEnabledObj inside of blockMemoAsPerMemoTimePeriod()");
		console.log(this.memoAssignEnabledObj)
		if (this.userDataDtoReturnSession.blockDestMemoTimePeriod == false) {
			if (this.selectedEntryType == 'automatic') {
				this.updateMethod(this.memoNum);
				this.lblMemoCount = true;
				this.memoCount = +this.memoCount + +1;
				this.textMemoCount = " No. of cashmemos: " + this.memoCount;
				this.clearMethod();
			}
			this.addInTable();
			// this.addInTable = true;
			console.log("am inside of if godowntable >> blockDestMemoTimePeriod is false");

		} else {
			// console.log("am inside of else godowntable >> blockDestMemoTimePeriod is true");
			if (this.memoAssignEnabledObj == true) {
				if (this.selectedEntryType == 'automatic') {
					this.updateMethod(this.memoNum);
					this.lblMemoCount = true;
					this.memoCount = +this.memoCount + +1;
					this.textMemoCount = " No. of cashmemos: " + this.memoCount;
					this.clearMethod();
				}
				this.addInTable();
				// this.addInTable = true;
				console.log("am inside of if collection man >> memoAssignEnabledObj is true");

			} else {
				this.todayDate = new Date();

				this.todayDate.setDate(this.todayDate.getDate());
				this.todayDate = this.datePipe.transform(this.todayDate, "yyyy-MM-dd");
				let enteredDateCheckStrChk = null;
				let addMemoTime = null;
				let consigneeDtlsMemoTime = null;
				let newDate = null;
				// memoTimePeriod
				consigneeDtlsMemoTime = checkMemoNumberForTableArgument[0].memoTimePeriod;
				if (consigneeDtlsMemoTime != null && consigneeDtlsMemoTime > 0) {
					addMemoTime = consigneeDtlsMemoTime;
				} else {
					addMemoTime = this.userDataDtoReturnSession.memoTimePeriod;
				}
				// console.log("checkMemoNumberForTableArgument");
				// console.log(checkMemoNumberForTableArgument);
				// console.log("this.memoDateCheck, this.todayDate, addMemoTime  >> 1");
				// console.log(this.memoDateCheck, this.todayDate, addMemoTime);
				//entered date
				this.memoDateCheck = checkMemoNumberForTableArgument[0].memoDate;
				newDate = new Date(this.memoDateCheck);
				this.memoDateCheck = newDate;
				this.memoDateCheck.setDate(this.memoDateCheck.getDate() + addMemoTime);
				enteredDateCheckStrChk = this.datePipe.transform(this.memoDateCheck, "yyyy-MM-dd");
				this.memoDateCheck = enteredDateCheckStrChk;

				// console.log("this.memoDateCheck, this.todayDate, addMemoTime  >> 2");
				// console.log(this.memoDateCheck, this.todayDate, addMemoTime);
				// console.log("this.tempStoreDup");
				// console.log(this.tempStoreDup);
				// console.log("this.memoDateCheck, this.todayDate");
				// console.log(this.memoDateCheck, this.todayDate);
				if (this.memoDateCheck < this.todayDate) {
					// this.addInTable = false;
					swal({
						title: "Cashmemo Block",
						text: "Cashmemo Assign is blocked due to memo stock period exceeds, please contact Admin. Cashmemo No:  " + checkMemoNumberForTableArgument[0].memoNumber + "",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					}).then(() => {
						this.clearMethod();
					});

				} else {
					// this.addInTable = true;
					if (this.selectedEntryType == 'automatic') {
						this.updateMethod(this.memoNum);
						this.lblMemoCount = true;
						this.memoCount = +this.memoCount + +1;
						this.textMemoCount = " No. of cashmemos: " + this.memoCount;
						this.clearMethod();
					}
					this.addInTable();
				}
			}

		}
	}
	addInTable() {
		console.log('8')
		this.newAttribute.memoNumber = this.checkMemoNumberForTable[0].memoNumber;
		this.newAttribute.memoDateStr = this.checkMemoNumberForTable[0].memoDateStr
		this.newAttribute.grandTotal = this.checkMemoNumberForTable[0].grandTotal
		this.newAttribute.lrNumber = this.checkMemoNumberForTable[0].lrNumber
		this.newAttribute.articles = this.checkMemoNumberForTable[0].articles
		this.newAttribute.area = this.checkMemoNumberForTable[0].area
		this.newAttribute.consigneeName = this.checkMemoNumberForTable[0].consigneeName
		this.newAttribute.collectionMan = this.checkMemoNumberForTable[0].collectionMan

		$("#" + this.pageId + "collectionManCashmemoStockCheckId").DataTable().destroy();
		if (this.collectionManCashmemoStockCheckDataList == null) {
			this.collectionManCashmemoStockCheckDataList = [];
		}


		if (this.collectionManCashmemoStockCheckDataList.length == 0) {
			this.collectionManCashmemoStockCheckDataList.push(this.newAttribute);
		} else {
			this.collectionManCashmemoStockCheckDataList.push(this.newAttribute);
		}
		this.dtTriggerCollectionManCashmemoStockCheck.next();
		// console.log( this.collectionManCashmemoStockCheckDataList );

		this.countOfLr = +this.countOfLr + 1;
		this.sumOfTotAmt = +this.sumOfTotAmt + +this.newAttribute.grandTotal;
		this.sumOfTotArt = +this.sumOfTotArt + +this.newAttribute.articles;
		this.newAttribute = {};
		$("#" + this.pageId + "okBtn").prop("disabled", false);
		$("#cmcscokBtn").focus();
		this.clearMethod1();

	}

	clearMethod1() {
		this.selectedEntryType = $("#" + this.pageId + "entryType").val();
		if (this.selectedEntryType == 'automatic') {
			$("#" + this.pageId + "cashMemoAutomatic").val('');
			$("#cmcsccashMemoAutomatic").focus();
		} else {
			console.log('81')
			$("#" + this.pageId + "okBtn").prop("disabled", false);
			$("#cmcscokBtn").focus();
		}
	}
	finishMethod() {
		this.memoNum = '';
		this.selectedEntryType = $("#" + this.pageId + "entryType").val();
		this.selectedCodeForManual = $("#" + this.pageId + "codeForManual").val();
		this.selectedMemoNumberForManual = $("#" + this.pageId + "cashMemoManual").val();
		this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "cashMemoAutomatic").val();
		if (this.selectedEntryType == 'automatic') {
			this.memoNum = this.selectedMemoNumberForAutomatic;

		} else {
			this.memoNum = this.selectedCodeForManual + "" + this.selectedMemoNumberForManual;

		}
		this.updateMethod(this.memoNum);
		this.lblMemoCount = true;
		this.memoCount = +this.memoCount + +1;
		this.textMemoCount = " No. of cashmemos: " + this.memoCount;
		this.clearMethod();
	}
	updateMethod(memoNum) {
		this.updateCashmemoNumberForTable = new CashMemoDto();
		this.updateCashmemoNumberForTable.memoNumber = memoNum;
		this.updateCashmemoNumberForTable.enteredBy = this.userDataDtoReturnSession.userId;
		this.updateCashmemoNumberForTable.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.updateCashmemoNumberForTable);
		this.updateMethodDetails();
	}

	updateMethodDetails = () => {
		this.memoReport.updateCMMemoStockCheck(this.updateCashmemoNumberForTable).subscribe(
			(response) => {
				if (response.status == 'updated') {

				} else {

					console.log('111');
				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => console.log(error.json()),
			() => console.log('done');
	};

	clearAllMethod() {
		this.showSpinnerForAction = false;
		this.memoFormat = '',
			this.selectedMemoNumberForManual = '';
		this.selectedMemoNumberForAutomatic = '';
		this.memoNum = '';
		this.textMemoCount = '';
		this.memoCount = 0;
		this.lblMemoCount = false;
		this.stationForCode = '';
		this.selectedCodeForManual = '';
		this.userDtoMemoCode = new UserDataDto();
		this.stationCodeDetails = '';
		this.checkMemoNumber = new CashMemoDto();
		this.checkMemoNumberForTable = new CashMemoDto();
		this.responseOfCheckMemoNumber = '';
		this.responseOfCheckMemoNumberForTable = '';
		this.showStatus = '';
		this.searchCashmemoNumberForTable = new CashMemoDto();
		this.updateCashmemoNumberForTable = new CashMemoDto();
		this.countOfLr = 0;
		this.sumOfTotAmt = 0;
		this.sumOfTotArt = 0;
		$("#" + this.pageId + "collectionManCashmemoStockCheckId").DataTable().destroy();
		this.collectionManCashmemoStockCheckDataList = [];
		this.dtTriggerCollectionManCashmemoStockCheck.next();
		this.modelStation = '';
		this.selectedCollectionMan = '';
		this.getPrintData = '';
		this.setStation = [];
		this.cashmemoDtoForPrint = new CashMemoDto();
		localStorage.clear();
		this.viewCollectionManCashmemoStockCheckPrintPopUp = false;
	}
	completeMethod() {
		this.textMemoCount = '';
		this.memoCount = 0;
		this.lblMemoCount = false;
		this.showStatus = '';
		this.searchCashmemoNumberForTable = new CashMemoDto();
		this.updateCashmemoNumberForTable = new CashMemoDto();
		this.countOfLr = 0;
		this.sumOfTotAmt = 0;
		this.sumOfTotArt = 0;
		$("#" + this.pageId + "collectionManCashmemoStockCheckId").DataTable().destroy();
		this.collectionManCashmemoStockCheckDataList = [];
		this.dtTriggerCollectionManCashmemoStockCheck.next();
		this.modelStation = '';
		$("#" + this.pageId + "okBtn").prop("disabled", true);
	}
	checkBtn(collectionManCashmemoStockCheckPrintPopUpTemplate) {
		this.getCollectionCheckPrintOut("NotAll", true, collectionManCashmemoStockCheckPrintPopUpTemplate);
	}
	checkAllBtn(collectionManCashmemoStockCheckPrintPopUpTemplate) {
		this.getCollectionCheckPrintOut("All", true, collectionManCashmemoStockCheckPrintPopUpTemplate);
	}
	unCheckBtn(collectionManCashmemoStockCheckPrintPopUpTemplate) {
		this.getCollectionCheckPrintOut("NotAll", false, collectionManCashmemoStockCheckPrintPopUpTemplate);
	}
	unCheckAllBtn(collectionManCashmemoStockCheckPrintPopUpTemplate) {
		this.getCollectionCheckPrintOut("All", false, collectionManCashmemoStockCheckPrintPopUpTemplate);
	}

	getCollectionCheckPrintOut(mode: string, memoChk: boolean, collectionManCashmemoStockCheckPrintPopUpTemplate) {
		this.selectionStation = $("#" + this.pageId + "station").val();
		this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
		this.setStation = [];
		for (let i = 0; i < this.stationOptions.length; i++) {
			this.setStation.push(this.stationOptions[i].destination);
		}
		console.log(this.setStation);
		this.cashmemoDtoForPrint = new CashMemoDto();
		this.cashmemoDtoForPrint.mode = mode;
		this.cashmemoDtoForPrint.destination = this.selectionStation;
		this.cashmemoDtoForPrint.status = "cmstock";
		this.cashmemoDtoForPrint.column1 = "pending";
		this.cashmemoDtoForPrint.memoCheck = memoChk;
		this.cashmemoDtoForPrint.list = this.setStation;
		this.cashmemoDtoForPrint.collectionMan = this.selectedCollectionMan;
		this.cashmemoDtoForPrint.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.cashmemoDtoForPrint);
		this.getCollectionCheckPrintOutDetails(collectionManCashmemoStockCheckPrintPopUpTemplate);
	}

	getCollectionCheckPrintOutDetails = (collectionManCashmemoStockCheckPrintPopUpTemplate) => {
		this.showSpinnerForAction = true;
		this.memoReport.getManualCashMemoCheckPrintOut(this.cashmemoDtoForPrint).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log(response);
				if (response.length == 0) {
					swal({
						title: "Empty",
						text: "No records found to print",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.getPrintData = response;
					this.collectionManCashmemoStockCheckPrintPopUp = this.modalService.open(collectionManCashmemoStockCheckPrintPopUpTemplate,
						{ centered: true, size: 'lg', windowClass: "modalClassForPopUpTruckOwner" });
					this.collectionManCashmemoStockCheckPrintPopUp.result.then((result) => {
						this.collectionManCashmemoStockCheckPrintClosePopUp = `Closed with: ${result}`;
					}, reason => {
						this.collectionManCashmemoStockCheckPrintClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
					});
					localStorage.clear();
					localStorage.setItem('popupCollectionManCashmemoStockCheckDetails', JSON.stringify(this.cashmemoDtoForPrint));
					localStorage.setItem('popupCollectionManCashmemoStockCheckPrintDetails', JSON.stringify(this.getPrintData));
					this.viewCollectionManCashmemoStockCheckPrintPopUp = true;
					window.addEventListener('afterPopUp', (event) => {
						this.viewCollectionManCashmemoStockCheckPrintPopUp = false;
					});
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	};

	closeCollectionManCashmemoStockCheckPrintPopUp() {
		this.collectionManCashmemoStockCheckPrintPopUp.close();
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

}



