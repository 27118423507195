import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';

//for datatable ends
//for modal starts
//import { OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LrDetailsComponent } from "src/app/dashboards/dashboards-popup/lr-details/lr-details.component";
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
//import {Subscription} from 'rxjs';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
//for modal ends
import swal from 'sweetalert';
import { LRDto } from "src/app/dto/LR-dto";

@Component( {
    selector: 'app-transit-lrs',
    templateUrl: './transit-lrs.component.html',
    styleUrls: ['./transit-lrs.component.css']
} )
export class TransitLrsComponent implements  OnInit {
    getDataTransitLrsDataTable: any;

    transitLrsDataList: any;

    onDestroyUnsubscribtionTransitLrs: Subscription;

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerTransitLrs: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsTransitLrs: any;
    //for modal starts
    animal: string;
    name: string;
    routeQueryParams$: Subscription;
    //for modal ends
	hireSlipDtoForReload:HireSlipDto=  new HireSlipDto();
	isLoggedIn = true;
    userDataDtoReturnSession: any;
	lrDtoForRemMos:LRDto= new LRDto();
	lrDtoForPopup:LRDto= new LRDto();
	responseForRemMos:any;
	
	
	showSpinnerForAction=false;
	lrDtoForDetails:LRDto=  new LRDto();
	selectedRowInvoiceNumnber:any;
	pageId="trlrc";
    constructor( private dashboardService: DashboardService,

        //for modal starts
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router, private destStmtReport: DestStmtReport, public changeDetectorRef : ChangeDetectorRef
        //for modal ends
    ) {
        if (sessionStorage.length == 0) {
              this.isLoggedIn = false;
              swal({
                  title: "Session Expired",
                  text: "Please relogin to access the application!",
                  icon: "error",
                  closeOnClickOutside: false,
                  closeOnEsc: false,
              }).then(() => {
                  this.logInPage();
              })
          }
          if (this.isLoggedIn) {
              this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
          }
    }
	 logInPage() {
        this.router.navigate(['/authentication/login']);
    }
	
	 ngOnInit(): void {

        this.dtOptionsTransitLrs = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
    }

    // ngOnDestroy(): void {
    //     this.dtTriggerTransitLrs.unsubscribe();
    // }
    ngAfterViewInit(): void {
        this.dtTriggerTransitLrs.next();
    }
	
	gridReconifgureOnReloadBtn(){
		
			this.hireSlipDtoForReload=  new HireSlipDto();
			this.hireSlipDtoForReload.mainStation=this.userDataDtoReturnSession.mainStation;
			this.hireSlipDtoForReload.branch=this.userDataDtoReturnSession.office;
			this.hireSlipDtoForReload.status="Transit";
			this.hireSlipDtoForReload.companyid=this.userDataDtoReturnSession.companyId;
			console.log(this.hireSlipDtoForReload);
			this.gridReconifgureDetails();
	}
	gridReconifgureDetails= () => {
    	        this.showSpinnerForAction = true;
    	         this.dashboardService.getPendingHireSlipFromOtherStations(this.hireSlipDtoForReload).subscribe(
    	             (response) => {
    	            	 this.showSpinnerForAction=false;
						  //$("#"+this.pageId+"transitLrsId").DataTable().destroy();
							this.transitLrsDataList=[];
    	                 if (response.length == 0) {
    	                     swal({
    	                         title: "Warning",
    	                         text: "No Details found !",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else {
    	               	  this.transitLrsDataList=response;
    	               	  console.log(this.transitLrsDataList);
    	                }
    	            //   this.dtTriggerTransitLrs.next();
					  this.changeDetectorRef.detectChanges();
    	             }), (error) => {
                         this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred While getting the Transit Lrs Details", "info");
                    }, () => console.log('done');
            };
            hireslipDialog(transitLrsData): void {
            	if(transitLrsData.hireslipnumber !=0){
            		this.selectedRowInvoiceNumnber=transitLrsData.invoiceNumber;
            		console.log(this.selectedRowInvoiceNumnber);
            		this.getLrDetails();
            		 
            	}
            }
        getLrDetails(){
    			this.lrDtoForDetails=  new LRDto();
    			this.lrDtoForDetails.invoiceNumber=this.selectedRowInvoiceNumnber;
				this.lrDtoForDetails.destination=this.userDataDtoReturnSession.mainStation;
    			this.lrDtoForDetails.mode='Specific';
    			this.lrDtoForDetails.companyId=this.userDataDtoReturnSession.companyId;
    			console.log(this.lrDtoForDetails);
    			this.getLrDetailsInfo();
    	}
    	getLrDetailsInfo= () => {
        	         this.showSpinnerForAction = true;
        	         this.destStmtReport.getStmtVerificationDetailsLrs(this.lrDtoForDetails).subscribe(
        	             (response) => {
        	            	 this.showSpinnerForAction=false;
        	                 if (response.length == 0) {
        	                     swal({
        	                         title: "Warning",
        	                         text: "No Details found !",
        	                         icon: "warning",
        	                         closeOnClickOutside: false,
        	                         closeOnEsc: false,
        	                     });
        	                     
        	                 } else {
    						 
    						 this.lrDtoForRemMos= new LRDto();
    						 this.responseForRemMos= response;
    						 this.lrDtoForRemMos=this.responseForRemMos;
    						 this.lrDtoForPopup= new LRDto();
    						 this.lrDtoForPopup.invoiceNumber =this.selectedRowInvoiceNumnber;
    						 console.log( this.lrDtoForRemMos,this.lrDtoForPopup);
    						 localStorage.clear();
    							localStorage.setItem('transitLrsPopup',
    										JSON.stringify(this.lrDtoForRemMos));
    							localStorage.setItem('transitLrsInvoiceNumberPopup',
										JSON.stringify(this.lrDtoForPopup));
        	               
    							  const dialogRef = this.dialog.open( LrDetailsComponent, {
    							
    							} );

    						dialogRef.afterClosed().subscribe( result => {
    							console.log( 'The dialog was closed' );
    							this.animal = result;
    							// this.router.navigate( ['.'], { relativeTo: this.route } );
    						} );
    							 
        	                }
							this.changeDetectorRef.detectChanges();
        	             }), (error) => {
                            this.showSpinnerForAction = false;
                            swal("Error", "Server Problem Occurred While getting  Transit LR Details", "info");
                        }, () => console.log('done');
                };
}
