<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}manualStockChkId">

    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <div class="row">
                    <div class="col-md-3">
                        <h6 class="card-title text-white">Manual Stock Check</h6>
                    </div>

                    <!-- <button type="submit" class="dt-button" style="margin-left: 93%;"
						(click)="customPrintManualStockCkh()" id="{{pageId}}printAllBtn">
						<span><i class="fas fa-print">Print</i></span>
					</button> -->

                    <div class="col-md-3">
                        <button type="submit" class="customCss btn btn-secondary" align='left' style="padding: 1px 4px;" id="{{pageId}}uncheckedLrsPrintBtn" (click)="unCheckedLrsPrintMethod();">UnChecked LRs</button>
                        <button type="submit" class="customCss btn btn-secondary" align='left' style="padding: 1px 4px;" id="{{pageId}}checkedLrsPrintBtn" (click)="checkedLrsPrintMethod();">Checked LRs</button>
                    </div>
                </div>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Entry Type</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
													class="fas fa-search"></i>
												</span>
                                            </div>
                                            <select class="custom-select col-12" id="{{pageId}}entryType" name="entryType" #entryType (change)="entryTypeMode(entryType.value)">
												<option value="manual" selected>Manual</option>
												<option value="scan">Scan</option>
											</select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>LR Number</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
													class="fas fa-clipboard-list"></i>
												</span>
                                            </div>
                                            <input #lrNumber type="text" class="form-control" id="{{pageId}}lrNumber" name="lrNumber" (keyup)="getLrDetails($event)" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>To Pay</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
													class=" fas fa-rupee-sign"></i>
												</span>
                                            </div>
                                            <input type="number" class="form-control" id="{{pageId}}toPay" aria-describedby="basic-addon11" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Paid</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
													class=" fas fa-rupee-sign"></i>
												</span>
                                            </div>
                                            <input type="number" class="form-control" id="{{pageId}}paid" aria-describedby="basic-addon11" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12" style='display: none;'>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Paid</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
													class=" fas fa-rupee-sign"></i>
												</span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}viewId" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12" style="margin-left: 50px;">
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}okBtn" (click)="okBtnMethod();" disabled>Ok</button>
                                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAllMethod();">Clear</button>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12" align="right">
                                    <h6 *ngIf="lblLrCount">{{textLrCount}}</h6>
                                </div>
                            </div>
                        </div>
                        <!-- <hr> -->
                    </div>

                </div>
                <div class="col-md-9 vl p-t-10">
                    <div class="row">
                        <!-- column -->
                        <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row p-t-10">
                                        <div class="col-md-8"></div>
                                    </div>
                                    <div class="row p-t-10">
                                        <div class="col-md-8">
                                            <h6 class="card-title">Manual Stock Check</h6>
                                        </div>
                                        <div class="col-md-4">
                                            <button type="submit" class="dt-button" style="float:right;" (click)="customPrintManualStockCkh()" id="{{pageId}}printAllBtn">
												<span><i class="fas fa-print">Print</i></span>
											</button>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="box-body">
                                                <table datatable id="{{pageId}}manualStockCheckId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsManualStockCheck" [dtTrigger]="dtTriggerManualStockCheck">
                                                    <thead>
                                                        <tr>
                                                            <th>LR Number</th>
                                                            <th>Booking Date</th>
                                                            <th>Articles</th>
                                                            <th>To Pay</th>
                                                            <th>Paid</th>
                                                            <th>Party Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let manualStockCheckData of manualStockCheckDataList ">
                                                            <td>{{ manualStockCheckData.lrNumber }}</td>
                                                            <td>{{ manualStockCheckData.bookingDateStr }}</td>
                                                            <td>{{ manualStockCheckData.totalArticles }}</td>
                                                            <td>{{ manualStockCheckData.toPay }}</td>
                                                            <td>{{ manualStockCheckData.paid }}</td>
                                                            <td>{{ manualStockCheckData.consigneeName }}</td>
                                                        </tr>

                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td style="text-align: left">Total : {{countOfLr}}</td>
                                                            <td></td>
                                                            <td style="text-align: left">{{sumOfTotArt}}</td>
                                                            <td style="text-align: left">{{sumOfTotToPay}}</td>
                                                            <td style="text-align: left">{{sumOfTotPaid}}</td>
                                                            <td></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>