import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Observable, Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterDto } from 'src/app/dto/master-dto';
import { Router } from "@angular/router";
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import { MasterService } from 'src/app/dataService/master-service';
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { LRDto } from 'src/app/dto/LR-dto';
import { FormControl } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-vehicle-area-charge-setup',
    templateUrl: './vehicle-area-charge-setup.component.html',
    styleUrls: ['./vehicle-area-charge-setup.component.css']
})
export class VehicleAreaChargeSetupComponent implements OnInit {

    masterDto: MasterDto = new MasterDto();
    saveResp: string;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    truckDataDto: TruckDataDto = new TruckDataDto();
    truckDataDtoSaveRet: any;
    truckDataDeleteDto: TruckDataDto = new TruckDataDto();
    truckDataDtoDeleteRet: any;
    areaChargeDetailsDataList: any;
    loadingIndicator = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dataTable: any;
    dtOptionsVehicleMaster: any;
    pageId = "vacsc";
    dtTriggerOtherChargeDatatable: Subject<any> = new Subject();
    dtOptionsOtherChargeDatatable: any;
    showSpinnerForAction = false;
    private newAttributeTosetOtherCharges: any = {};
    editOtherChargesId = null;
    gridTosetOtherChargeList: Array<LRDto> = [];
    selectedVehicleType: any;
    selectedArea: any;
    chargedAmt: any;
    enteredEarlyMrngTripExtAmt: any;

    validateVehicleType: any = null;
    public modelVehicleType: any;
    vehicleTypeTruckDtoOptions: TruckDataDto[];
    vehicleTypeTA: Array<TruckDataDto> = [];
    focusVehicleTypeTA$ = new Subject<string>();
    searchVehicleType = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusVehicleTypeTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.vehicleTypeTA
                : this.vehicleTypeTA.filter(v => v.truckType.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterVehicleType = (x: { truckType: string }) => x.truckType;

    controlOtherChargeArea = new FormControl();
    otherChargeAreaOptions: LRDto[];
    lrDto: LRDto = new LRDto();
    public modelOtherChargeArea: any;
    otherChargeAreaTA: Array<LRDto> = [];
    focusOtherChargeAreaTA$ = new Subject<string>();
    searchOtherChargeArea = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusOtherChargeAreaTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.otherChargeAreaTA
                : this.otherChargeAreaTA.filter(v => v.destination)).slice(0, 200))
        );
    }
    formatterOtherChargeArea = (x: { destination: string }) => x.destination;

    constructor(private router: Router, private masterService: MasterService, private masterReadService: MasterReadService,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getVehicleTypeList();
            this.getBranchAreaDetailsList();
            this.getChargesDetails();
        }
    }

    ngOnInit(): void {
        this.dtOptionsOtherChargeDatatable = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 500,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }
    }
    
    ngOnDestroy(): void {
        this.dtTriggerOtherChargeDatatable.unsubscribe();
    }

    ngAfterViewInit(): void {
        //this.dtTriggerOtherChargeDatatable.next();
    }

    getVehicleTypeRead() {
        this.truckDataDto = new TruckDataDto();
        this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDto.office = this.userDataDtoReturnSession.mainStation;
        this.truckDataDto.isActive = true;
    }
    getVehicleTypeList = () => {
        this.getVehicleTypeRead();
        this.masterReadService.getVehicleTypesLocal(this.truckDataDto).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.vehicleTypeTruckDtoOptions = response;
                    this.vehicleTypeTA = [];
                    for (let i = 0; i < this.vehicleTypeTruckDtoOptions.length; i++) {
                        this.vehicleTypeTA.push(this.vehicleTypeTruckDtoOptions[i]);
                    }
                } else {
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Server Error", "Problem occur while getting vehicle details", "error");
            },
            () => console.log('done');
    }

    clickListnerForVehicleType(event) {
        this.modelVehicleType = event.item;
        $("#" + this.pageId + "vehicleType").val(this.modelVehicleType.truckType);
        this.validateVehicleType = this.modelVehicleType.truckType;
    }

    getChargesDetailsForRead() {
        this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDto.office = this.userDataDtoReturnSession.mainStation;
        this.truckDataDto.isActive = true;
    }
    getChargesDetails = () => {
        this.showSpinnerForAction = true;
        this.getChargesDetailsForRead();
        this.masterReadService.getLocalDDDriverCharge(this.truckDataDto).subscribe(
            (response) => {
                if (response) {
                    this.areaChargeDetailsDataList = [];
                    if (response.length == 0) {
                        swal("Not records", "No Driver charges found!", "error");
                    } else {
                        this.areaChargeDetailsDataList = response;
                    }

                    this.showSpinnerForAction = false;
                }
                $("#"+this.pageId+"otherChargeDatatableId").DataTable().destroy();
                this.dtTriggerOtherChargeDatatable.next();
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting the details", "error");
            },
            () => console.log('done');
    }

    getBranchAreaDetails() {
        this.lrDto = new LRDto();
        this.lrDto.branch = this.userDataDtoReturnSession.office;
        this.lrDto.mode = "ALL";
        this.lrDto.mainstation = this.userDataDtoReturnSession.mainStation;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
    }
    getBranchAreaDetailsList = () => {
        this.getBranchAreaDetails();
        this.masterReadService.getBranchAreas(this.lrDto).subscribe(
            (response) => {
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Truck Capacity records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.controlOtherChargeArea.reset();
                    this.otherChargeAreaOptions = [];
                    this.otherChargeAreaTA = [];
                } else {
                    this.controlOtherChargeArea.reset();
                    this.otherChargeAreaOptions = response;
                    this.otherChargeAreaTA = [];
                    for (let i = 0; i < this.otherChargeAreaOptions.length; i++) {
                        this.otherChargeAreaTA.push(this.otherChargeAreaOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    }

    clickListnerForOtherChargeArea(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelOtherChargeArea = e.item;
        $("#" + this.pageId + "otherChargeArea").val(this.modelOtherChargeArea.destination);
    }

    rowSelectedOtherChargeDataOnEdit(editOtherChargeData, index) {
        console.log(editOtherChargeData);
        $("#" + this.pageId + "otherChargeArea").val(editOtherChargeData.area);
        $("#" + this.pageId + "otherChgAmt").val(editOtherChargeData.driverChg);
        $("#" + this.pageId + "vehicleType").val(editOtherChargeData.truckType);
        $("#" + this.pageId + "earlyMrngTripExtAmt").val(editOtherChargeData.mrngTripExtAmt);
        this.editOtherChargesId = index;
    }

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }
    validateSaveDetails() {
        console.log($("#" + this.pageId + "vehicleType").val());
        console.log($("#" + this.pageId + "otherChargeArea").val());
        console.log($("#" + this.pageId + "otherChgAmt").val());
        if (($("#" + this.pageId + "vehicleType").val() == null) || ($("#" + this.pageId + "vehicleType").val() == undefined) || ($("#" + this.pageId + "vehicleType").val() == "") ||
            ($("#" + this.pageId + "otherChargeArea").val() == null) || ($("#" + this.pageId + "otherChargeArea").val() == undefined) || ($("#" + this.pageId + "otherChargeArea").val() == "") ||
            ($("#" + this.pageId + "otherChgAmt").val() == null) || ($("#" + this.pageId + "otherChgAmt").val() == undefined) || ($("#" + this.pageId + "otherChgAmt").val() == "")) {
            
            swal("Mandatory Fields", "Please enter the mandatory fields to proceed!", "warning");
            return false;
        } else {
            swal({
                title: "Confirm Changes",
                text: "Sure U Want to Add/Update the changes?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.saveUpdateChargesDetails();
                }
            });

        }
    }
    geChargesDetailsForSave() {
        this.selectedVehicleType = $("#" + this.pageId + "vehicleType").val();
        this.selectedArea = $("#" + this.pageId + "otherChargeArea").val();
        this.chargedAmt = $("#" + this.pageId + "otherChgAmt").val();
        this.enteredEarlyMrngTripExtAmt = $("#" + this.pageId + "earlyMrngTripExtAmt").val();

        this.truckDataDto = new TruckDataDto();
        this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDto.userId = this.userDataDtoReturnSession.userId;
        this.truckDataDto.office = this.userDataDtoReturnSession.office;
        this.truckDataDto.mainStation = this.userDataDtoReturnSession.mainStation;
        this.truckDataDto.truckType = this.selectedVehicleType;
        this.truckDataDto.area = this.selectedArea;
        this.truckDataDto.driverChg = this.chargedAmt;
        this.truckDataDto.mrngTripExtAmt = this.enteredEarlyMrngTripExtAmt;
        console.log('geChargesDetailsForSave');
        console.log(this.truckDataDto);
    }
    saveUpdateChargesDetails = () => {
        this.geChargesDetailsForSave();
        this.masterReadService.addUpdateVehicleAreaDriverChgDetails(this.truckDataDto).
            subscribe((data) => {
                this.truckDataDtoSaveRet = data;
                if (this.truckDataDtoSaveRet.status == "Success") {
                    swal({
                        title: "Success",
                        text: "Charges details Saved/Updated Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                    this.getChargesDetails();
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Saving the details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Saving the details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };
    clearAll() {
        $('input[type="text"],[type="number"]').val('');
        this.truckDataDto = new TruckDataDto();
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}
