<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
    <div class="form-group">
        <div class="input-group">
            <mat-progress-bar mode="indeterminate" style="color: green;">
            </mat-progress-bar>
            <br>
            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                Please Wait Loading Details.....</h6>
        </div>
    </div>
</div>
<div class="col-md-12 col-sm-12">
    <div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
        <div class="col-md-8">
            <h6 class="card-title">
                <i class=" fas fa-file-alt"></i>&nbsp;Scheduled Trips To
            </h6>

        </div>
        <div class="col-md-4">
            <i title="Reload" class="fas fa-sync-alt fa xs cli" style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;" (click)="gridReconifgureOnReloadBtn()"></i>
        </div>
    </div>
</div>
<div class="col-md-12 col-sm-12">
    <div class="box-body">
        <table datatable id="{{pageId}}scheduledTripsToTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsScheduledTripsTo" [dtTrigger]="dtTriggerScheduledTripsTo">
            <thead>
                <tr>
                    <th>Hireslip Number</th>
                    <th>Destination</th>
                    <th>Departure At</th>
                    <th>Consent Status</th>
                    <th [hidden]="!consolidateEwaybillEnabled">Consolidate Ewaybill</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let scheduledTripsToData of scheduledTripsToDataList ">
                    <!--<td>{{ scheduledTripsToData.hireslipnumber }}</td>-->
                    <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="getSelectedRowOfHireslipDetails(scheduledTripsToData)">
                        {{ scheduledTripsToData.hireslipnumber }}</td>
                    <td>{{ scheduledTripsToData.toStation }}</td>
                    <td>{{ scheduledTripsToData.departuredateStr +" "+scheduledTripsToData.departureTime }}</td>
                    <!-- <td style="color: green; cursor: pointer; text-decoration: underline;" (click)="refreshFTConsentStatus(scheduledTripsToData)"> -->
                    <td style="color: green; cursor: pointer; text-decoration: underline;" (click)="validateFtStatusAllowedByDuration(scheduledTripsToData)">
                        {{ scheduledTripsToData.trackingFTConsentStatus }}</td>
                    <td [hidden]="!consolidateEwaybillEnabled" *ngIf="scheduledTripsToData.ewayBill == null" title="Click To Generate Consolidated EWB" style="color: red; cursor: pointer; text-decoration: underline;" (click)="confirmGenerateConsolidateEwaybill(scheduledTripsToData)">
                        Generate</td>
                    <td [hidden]="!consolidateEwaybillEnabled" *ngIf="scheduledTripsToData.ewayBill != null" title="Click To Download Consolidated EWB" style="color: green; cursor: pointer; text-decoration: underline;" (click)="printCEWB(scheduledTripsToData.ewayBill)">
                        {{ scheduledTripsToData.ewayBill }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>