import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
const my = new Date();
import { Subject, merge } from 'rxjs';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { SupplierDetailsDto } from "src/app/dto/SupplierDetails-dto";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { NgbDateStruct, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
import *  as moment from 'moment';

@Component({
  selector: 'app-lorry-hire-statement',
  templateUrl: './lorry-hire-statement.component.html',
  styleUrls: ['./lorry-hire-statement.component.css']
})
export class LorryHireStatementComponent implements OnInit {

  lorryHireStmtDataList: any;
  hireSlipDto: HireSlipDto = new HireSlipDto();
  userDataDtoReturnSession: any;
  isLoggedIn = true;
  supplierDetailsList: any;
  supplierDetailsDto: SupplierDetailsDto = new SupplierDetailsDto();
  supplierNameOptions: SupplierDetailsDto[];
  selectedSupplierPanNumber = '';
  hidePannumber = false;
  supplierSelected: any;
  model: NgbDateStruct;
  selectedName: any;
  selectedValue: any;
  selectedFromDate: any;
  selectedToDate: any;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  dtTriggerLorryHireStmt: Subject<any> = new Subject();
  dtOptionsLorryHireStmt: any;

  supplierDetailsDtoAll: SupplierDetailsDto = new SupplierDetailsDto();
  supplierOptions: SupplierDetailsDto[];
  public modelSupplierName: any;
  supplierNameTA: Array<SupplierDetailsDto> = [];
  focusSupplierNameTA$ = new Subject<string>();
  searchSupplierName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusSupplierNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.supplierNameTA
        : this.supplierNameTA.filter(v => v.supplierName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterSupplierName = (x: { supplierName: string }) => x.supplierName;

  viewCustomPrintV1 = false;
  public modelSupplierNameId: any;

  grandTotalHire = 0;
  //For Custom Print
  cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintList: any;
  cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
  cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
  cashMemoDtoForCustomPrintDataList: any;
  cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
  cashMemoDtoForCustomPrintDataSummaryList: any;

  fromDatePrint: any;
  toDatePrint: any;
  cashMemoDtoForCustomPrintListHeadingV1: any;
  cashMemoDtoForCustomPrintListHeadingV2: any;
  cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
  showSpinnerForAction = false;
  supplierNameSet: any;
  setTodayDateOnToDate: any;
  setTodayDateOnFromDate: any;
  selectedSupplierName: any;
  hireSlipDtoFromSearch: HireSlipDto = new HireSlipDto();
  public modelSupplierNamePaNumber: any;
  pageId = "lhstc";

  constructor(private lrReportService: LrReportService, private router: Router,
    private masterReadService: MasterReadService, private datePipe: DatePipe,
    public changeDetectorRef: ChangeDetectorRef) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      })
    }

    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
      this.getSupplierDetailsList();
      this.selectTodayDate();
    }
  }
  fieldFocus(e, el) {
    if (e.keyCode == 13) { // press A
      el.focus();
    }
  }

  rowSelectedSupplierName(e: NgbTypeaheadSelectItemEvent) {
    this.modelSupplierNameId = e.item;
    this.selectedSupplierPanNumber = this.modelSupplierNameId.tinNumber;
    // $("#"+this.pageId+"supplierPanNumber").val(this.selectedSupplierPanNumber); 
    this.modelSupplierNamePaNumber = this.selectedSupplierPanNumber;
    console.log(this.selectedSupplierPanNumber);
  }
  selectTodayDate() {
    this.setTodayDateOnToDate = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };
    this.setTodayDateOnFromDate = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };

    $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
    $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
  }

  ngOnInit(): void {
    this.dtOptionsLorryHireStmt = {
      dom: 'Bfrtip',
      buttons: [{
        extend: 'excel',
        text: '<i class="fas fa-file-excel"> Excel</i>',
        titleAttr: 'Excel',
        footer: true,
        title: function () {
          var returExtendHistroyDate = null;
          returExtendHistroyDate = "Lorry Hire Statement Report  " + " From Date : " + moment($("#lhstcfromDate").val()).format('DD-MM-YYYY') + " -  " +
            "To Date : " + moment($("#lhstctoDate").val()).format('DD-MM-YYYY') + " SupplierName  : " + $("#lhstcsupplierName").val();
          return returExtendHistroyDate;
        }
      }],

      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search..."
      },
      processing: true,
      responsive: true,
      "scrollX": true,
      "scrollY": 300,
      "scrollCollapse": true,
      "paging": false,
      "info": false,
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api(), data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };
        var tothire = api.column(4).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        $(api.column(0).footer()).html('Total : ' + data.length);
        $(api.column(4).footer()).html(tothire);
      }
    }
  }

  ngOnDestroy(): void {
    this.dtTriggerLorryHireStmt.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.dtTriggerLorryHireStmt.next();
  }
  selectToday() {
    this.model = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };
  }

  getSearchMethod() {
    this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
    this.selectedToDate = $("#" + this.pageId + "toDate").val();
    if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
      swal({
        title: "Mandatory Field",
        text: "Please select From Date and To Date to get the  details!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });

    } else {
      this.gettingDetailsForLorryHireStmt();
    }
  }

  gettingDetailsForLorryHireStmt() {
    this.selectedSupplierName = $("#" + this.pageId + "supplierName").val();
    this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
    this.selectedToDate = $("#" + this.pageId + "toDate").val();
    this.supplierNameSet = this.selectedSupplierName;
    this.hireSlipDtoFromSearch = new HireSlipDto();
    this.hireSlipDtoFromSearch.companyid = this.userDataDtoReturnSession.companyId;
    this.hireSlipDtoFromSearch.suppliername = this.selectedSupplierName;
    this.hireSlipDtoFromSearch.fromDate = this.selectedFromDate;
    this.hireSlipDtoFromSearch.toDate = this.selectedToDate;
    // this.hireSlipDtoFromSearch.supplierpanno = this.selectedSupplierPanNumber;  
    this.searchMethodForLorryHireStatDetails();
  }
  searchMethodForLorryHireStatDetails = () => {
    this.showSpinnerForAction = true;
    this.lrReportService.getLorryHireStmtRpt(this.hireSlipDtoFromSearch).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        $("#" + this.pageId + "lrryHireStmtId").DataTable().destroy();
        this.lorryHireStmtDataList = [];
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Records found for this search!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          this.lorryHireStmtDataList = response;
        }
        this.dtTriggerLorryHireStmt.next();
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Lorry Hire Statement   Report ", "info");
      }, () => console.log('done');
  };

  logInPage() {
    this.router.navigate(['/authentication/login']);
  }

  getSupplierDetails() {
    this.supplierDetailsDto = new SupplierDetailsDto();
    this.supplierDetailsDto.companyId = this.userDataDtoReturnSession.companyId;
    //this.supplierDetailsDto.mode = "Working";
  }
  getSupplierDetailsList = () => {
    this.getSupplierDetails();
    this.showSpinnerForAction = true;
    this.masterReadService.getSuplierMaster(this.supplierDetailsDto).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        if (response.length == 0) {
          this.supplierOptions = [];
          this.supplierNameTA = [];
          swal("Warning", "No Supplier Master records found!", "warning");
        } else {
          this.supplierOptions = [];
          this.supplierNameTA = [];
          this.supplierOptions = response;
          // this.supplierDetailsDtoAll.supplierName = "All";
          // this.supplierNameTA.push(this.supplierDetailsDtoAll);
          for (let i = 0; i < this.supplierOptions.length; i++) {
            this.supplierNameTA.push(this.supplierOptions[i]);
          }
        }
        //this.showSpinnerForActionForInvoiceDetails = false;
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Error While Getting Supplier Master Details", "warning");
      },
      () => console.log('done');
  };

  printMethodForLorryHireStmt() {
    this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
    this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
    this.selectedToDate = $("#" + this.pageId + "toDate").val();
    this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
    this.selectedSupplierName = $("#" + this.pageId + "supplierName").val();
    if (this.lorryHireStmtDataList.length == 0) {
      swal({
        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnNames = ["Truck No", "Date", "Hireslip No", "Route", "Total Hire"];
      this.cashMemoDtoForCustomPrintListColumnWidths = [20, 20, 20, 20, 20];
      for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintDataList = [];
      this.cashMemoDtoForCustomPrintDataSummaryList = [];
      this.grandTotalHire = 0;

      for (let i = 0; i < this.lorryHireStmtDataList.length; i++) {
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [this.lorryHireStmtDataList[i].vehicleNumber, this.lorryHireStmtDataList[i].hireSlipDateStr, this.lorryHireStmtDataList[i].hireslipnumber, this.lorryHireStmtDataList[i].column1, this.lorryHireStmtDataList[i].totalhire];
        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
        this.grandTotalHire = +this.grandTotalHire + +this.lorryHireStmtDataList[i].totalhire;
        this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lorryHireStmtDataList.length, "", "", "", this.grandTotalHire];
      }
      //heading logics For Date
      this.cashMemoDtoForCustomPrintListHeadingV1 = [];
      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
      this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];

      for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
        this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintListHeadingV2 = [];
      this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Supplier ", "PanNumber"];
      this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSupplierName, this.selectedSupplierPanNumber];

      for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
        this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
      }
      localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
      localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
      localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
      localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
      localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
      localStorage.setItem('printTitle', "Lorry Hire Statement Report");
      this.viewCustomPrintV1 = true;
      window.addEventListener('afterprint', (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }

  clearAll() {
    this.selectedFromDate = '';
    this.selectedToDate = '';
    this.selectedSupplierName = '';
    $("#" + this.pageId + "supplierName").val('');
    this.selectTodayDate();
    this.grandTotalHire = 0;
    this.showSpinnerForAction = false;
    $("#" + this.pageId + "lrryHireStmtId").DataTable().destroy();
    this.lorryHireStmtDataList = [];
    this.cashMemoDtoForCustomPrint = new CashMemoDto();
    this.cashMemoDtoForCustomPrintList = [];
    this.cashMemoDtoForCustomPrintListColumnNames = [];
    this.cashMemoDtoForCustomPrintListColumnWidths = [];
    this.cashMemoDtoForCustomPrintDataList = [];
    this.cashMemoDtoForCustomPrintData = new CashMemoDto();
    this.cashMemoDtoForCustomPrintListColumnValues = [];
    this.cashMemoDtoForCustomPrintDataSummaryList = [];
    this.viewCustomPrintV1 = false;
    this.cashMemoDtoForCustomPrintListHeadingV1 = [];
    this.cashMemoDtoForCustomPrintListHeadingV2 = [];
    this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
    this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
    this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];
    this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [];
    this.hireSlipDtoFromSearch = new HireSlipDto();
    this.dtTriggerLorryHireStmt.next();
    this.modelSupplierNameId = '';
    this.selectedSupplierPanNumber = '';
    $("#" + this.pageId + "supplierPanNumber").val('');
    this.modelSupplierNamePaNumber = '';
  }
}
