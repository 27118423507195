<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showGodwnStckRpt">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Godown Stocks Report</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <!-- <h6 class="card-title">Tempo Details</h6> -->
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>View Type</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-question"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}viewType" name="viewType" #viewType (change)="viewTypeMode(viewType.value)">
														<option selected value="summary">Summary</option>
														<option value="detail">Detail</option>
														<option value="detailWithDestination">Detail With
															Destination</option>
														<option *ngIf="partyViewType" value="partyViewTypeSummary">Party
															Wise Summary</option>
														<option *ngIf="partyViewType" value="partyViewTypeDetail">Party
															Wise Detailed</option>
													</select>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="viewGodownName" class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Godown Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}godownName" type="text" class="form-control" [(ngModel)]="modelLocalGodown" [ngbTypeahead]="searchLocalGodown" [resultFormatter]="formatterLocalGodown" [inputFormatter]="formatterLocalGodown" (focus)="focusLocalGodownTA$.next($any($event).target.value)"
                                                            placeholder="Select Godown Name" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="partyViewTypeStockAt" class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Stock At</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}stockAt" type="text" class="form-control" [(ngModel)]="modelStockAt" [ngbTypeahead]="searchStockAt" [resultFormatter]="formatterStockAt" [inputFormatter]="formatterStockAt" (focus)="focusStockAtTA$.next($any($event).target.value)"
                                                            placeholder="Select Stock At" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="partyViewTypeMainSource" class="col-sm-12 col-md-12">

                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Main Source</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <input #mainSrc id="{{pageId}}mainSrc" name="mainSrc" type="text" class="form-control" placeholder="Select Main Source" [(ngModel)]="modelForMainSrc" [ngbTypeahead]="searchMainSrcAt" [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterMainSrc"
                                                        [inputFormatter]="formatterMainSrc" (focus)="focusMainSrcTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}" />
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="viewPartyName" class="col-sm-12 col-md-12">

                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Party Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
														</span>
                                                    </div>
                                                    <input #consigneeName id="{{pageId}}consigneeName" name="consigneeName" type="text" class="form-control" placeholder="Select Consignee Name" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="consigneeNameSearchTA" (selectItem)="rowSelectedConsignee($event)"
                                                        [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterConsigneeName" [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="viewSource" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Source</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-box"></i>
														</span>
                                                    </div>
                                                    <select multiple name="source" id="{{pageId}}source" class="form-control select2-multiple">
														<option *ngFor="let srcList of srcLists">{{
															srcList }}</option>
													</select>


                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="viewDestination" class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" (selectItem)="consigneeDestListener($event)" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Date Is Hided Because We have Research Work On CheckBox Hide And Show -->
                                        <div *ngIf="viewDate" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Date</label> <input class="form-control" placeholder="yyyy-mm-dd" id="{{pageId}}destDate" name="destDate" [(ngModel)]="destDateModel" ngbDatepicker #dates="ngbDatepicker">
                                                    <div class="input-group-append" (click)="dates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="ViewDays" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Days</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
														</span>
                                                    </div>
                                                    <input type="number" id="{{pageId}}days" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod();">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearMethod();">Clear</button>
                        </div>
                    </div>



                    <div class="col-md-9 vl p-t-10">
                        <div class="row">
                            <!-- column -->
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row p-t-10">
                                            <div class="col-md-8"></div>
                                        </div>
                                        <div class="row p-t-10">
                                            <div class="col-md-8">
                                                <h6 class="card-title" [hidden]="!godownStocksSummaryReportTable">Godown Stocks Summary Report</h6>
                                                <h6 class="card-title" [hidden]="!godownStocksDetailsReportTable">Godown Stocks Details Report</h6>
                                                <h6 class="card-title" [hidden]="!godownStockReportDestinationWiseTable">Godown Stock Report Destination Wise</h6>
                                                <h6 class="card-title" [hidden]="!godownStocksReportPartyWiseSummaryTable">Godown Stocks Report Party Summary Report</h6>
                                                <h6 class="card-title" [hidden]="!godownStocksReportPartyWiseDetailsTable">Godown Stocks Party Details Report</h6>
                                            </div>

                                            <div class="col-md-4">
                                                <button type="submit" class="dt-button" style="margin-left: 74%;" (click)="customPrintStocksSummary()" id="{{pageId}}printAllBtn" [hidden]="!godownStocksSummaryReportTable">
													<span><i class="fas fa-print">Print</i></span>
												</button>
                                                <button type="submit" class="dt-button" style="margin-left: 74%;" (click)="customPrintStocksDetails()" id="{{pageId}}printAllBtn" [hidden]="!godownStocksDetailsReportTable">
													<span><i class="fas fa-print">Print</i></span>
												</button>
                                                <button type="submit" class="dt-button" style="margin-left: 74%;" (click)="customPrintDestinationWise()" id="{{pageId}}printAllBtn" [hidden]="!godownStockReportDestinationWiseTable">
													<span><i class="fas fa-print">Print</i></span>
												</button>
                                                <button type="submit" class="dt-button" style="margin-left: 74%;" (click)="customPrintPartyWiseSummary()" id="{{pageId}}printAllBtn" [hidden]="!godownStocksReportPartyWiseSummaryTable">
													<span><i class="fas fa-print">Print</i></span>
												</button>
                                                <button type="submit" class="dt-button" style="margin-left: 74%;" (click)="customPrintPartyWiseDetails()" id="{{pageId}}printAllBtn" [hidden]="!godownStocksReportPartyWiseDetailsTable">
													<span><i class="fas fa-print">Print</i></span>
												</button>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="box-body" [hidden]="!godownStocksSummaryReportTable">
                                                    <table datatable id="{{pageId}}godownStocksSummaryReportTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsGodownStocksSummaryReport" [dtTrigger]="dtTriggerGodownStocksSummaryReport">

                                                        <thead>
                                                            <tr>
                                                                <th>Total LRs</th>
                                                                <th>No Of Articles</th>
                                                                <th>To Pay</th>
                                                                <th>Paid</th>
                                                                <th>Act Wt</th>
                                                                <th>Chg Wt</th>
                                                                <th>Godown</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let godownStocksSummaryReportData of godownStocksSummaryReportDataList ">
                                                                <td>{{ godownStocksSummaryReportData.totalLrs }}</td>
                                                                <td>{{ godownStocksSummaryReportData.totalArticles }}
                                                                </td>
                                                                <td>{{ godownStocksSummaryReportData.toPay }}</td>
                                                                <td>{{ godownStocksSummaryReportData.paid }}</td>
                                                                <td>{{ godownStocksSummaryReportData.actualWeight }}
                                                                </td>
                                                                <td>{{ godownStocksSummaryReportData.chargedWeight }}
                                                                </td>
                                                                <td>{{ godownStocksSummaryReportData.godownName }}</td>
                                                            </tr>

                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>

                                                    </table>
                                                </div>

                                                <div class="box-body" [hidden]="!godownStocksDetailsReportTable">
                                                    <table datatable id="{{pageId}}godownStocksDetailsReportTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionGodownStocksDetailsReport" [dtTrigger]="dtTriggerGodownStocksDetailsReport">
                                                        <thead>
                                                            <tr>
                                                                <th>LR No</th>
                                                                <th>Booking Date</th>
                                                                <th>Source</th>
                                                                <th>Agent</th>
                                                                <th>Art</th>
                                                                <th>Desc</th>
                                                                <th>ToPay</th>
                                                                <th>Paid</th>
                                                                <th>ActWt</th>
                                                                <th>ChgWt</th>
                                                                <th>Destination</th>
                                                                <th>Consignor</th>
                                                                <th>Consignee</th>
                                                                <th>Area</th>
                                                                <th>Sub Station</th>
                                                                <th>Del Type</th>
                                                                <th>DiffInDate(Bkg-Arr)</th>
                                                                <th>DiffInDate(Cur-Arr)</th>
                                                                <th>Godown</th>
                                                                <th [hidden]=true>E-Way Bill No</th>
                                                                <th [hidden]=true>E-way Bill Valid Upto</th>
                                                                <th>Invoice Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr *ngFor="let godownStocksDetailsReportData of godownStocksDetailsReportDataList ">
                                                                <td>{{ godownStocksDetailsReportData.lrNumber }}</td>
                                                                <td>{{ godownStocksDetailsReportData.bookingDateStr }}
                                                                </td>
                                                                <td>{{ godownStocksDetailsReportData.source }}</td>
                                                                <td>{{ godownStocksDetailsReportData.agentName }}</td>
                                                                <td>{{ godownStocksDetailsReportData.totalArticles }}
                                                                </td>
                                                                <td>{{ godownStocksDetailsReportData.description }}</td>
                                                                <td>{{ godownStocksDetailsReportData.toPay }}</td>
                                                                <td>{{ godownStocksDetailsReportData.paid }}</td>
                                                                <td>{{ godownStocksDetailsReportData.actualWeight }}
                                                                </td>
                                                                <td>{{ godownStocksDetailsReportData.chargedWeight }}
                                                                </td>
                                                                <td>{{ godownStocksDetailsReportData.destination }}</td>
                                                                <td>{{ godownStocksDetailsReportData.consignorName }}
                                                                </td>
                                                                <td>{{ godownStocksDetailsReportData.consigneeName }}
                                                                </td>
                                                                <td>{{ godownStocksDetailsReportData.areaOfDelivery }}
                                                                </td>
                                                                <td>{{ godownStocksDetailsReportData.subStations }}</td>

                                                                <td>{{ godownStocksDetailsReportData.deliveryType }}
                                                                </td>
                                                                <td>{{ godownStocksDetailsReportData.dispatchedwithin }}
                                                                </td>
                                                                <td>{{ godownStocksDetailsReportData.arrivalwithin }}
                                                                </td>
                                                                <td>{{ godownStocksDetailsReportData.godownName }}</td>
                                                                <td [hidden]=true>{{ godownStocksDetailsReportData.eWayBillNo }}</td>
                                                                <td [hidden]=true>{{ godownStocksDetailsReportData.ewayBillValidUptoStr }}
                                                                </td>
                                                                <td>{{ godownStocksDetailsReportData.date }}
                                                                </td>

                                                            </tr>


                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td [hidden]=true></td>
                                                                <td [hidden]=true></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>

                                                    </table>
                                                </div>
                                                <div class="box-body" [hidden]="!godownStockReportDestinationWiseTable">
                                                    <table datatable id="{{pageId}}godownStockReportDestinationWiseTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionGodownStockReportDestinationWise" [dtTrigger]="dtTriggerGodownStockReportDestinationWise">
                                                        <thead>
                                                            <tr>
                                                                <th>Destination</th>
                                                                <th>LR No</th>
                                                                <th>Bkg Dt</th>
                                                                <th>Source</th>
                                                                <th>Agent</th>
                                                                <th>Art</th>
                                                                <th>Description</th>
                                                                <th>ToPay</th>
                                                                <th>Paid</th>
                                                                <th>Act Wt</th>
                                                                <th>Chg Wt</th>
                                                                <th>Consignor</th>
                                                                <th>Consignee</th>
                                                                <th>Area</th>
                                                                <th>Sub Station</th>
                                                                <th>Godown</th>
                                                                <th>Deliv Type</th>
                                                                <th>DiffInDate(Arr-Bkg)</th>
                                                                <th>DiffInDate(Cur-Arr)</th>
                                                                <th>DiffInDate(Cur-Bkg)</th>
                                                                <th [hidden]=true>E-Way Bill No</th>
                                                                <th [hidden]=true>E-way Bill Valid Upto</th>
                                                                <th>Invoice Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr *ngFor="let godownStockReportDestinationWiseData of godownStockReportDestinationWiseDataList ">
                                                                <td>{{ godownStockReportDestinationWiseData.destination }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.lrNumber }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.bookingDateStr }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.source }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.agentName }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.totalArticles }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.description }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.toPay }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.paid }}</td>
                                                                <td>{{ godownStockReportDestinationWiseData.actualWeight }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.chargedWeight }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.consignorName }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.consigneeName }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.areaOfDelivery }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.subStations }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.godownName }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.deliveryType }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.dispatchedwithin }}
                                                                </td>
                                                                <td>{{ godownStockReportDestinationWiseData.arrivalwithin }}
                                                                </td>
                                                                <td>{{godownStockReportDestinationWiseData.actualDays}}
                                                                </td>
                                                                <td [hidden]=true>{{ godownStockReportDestinationWiseData.eWayBillNo }}
                                                                </td>
                                                                <td [hidden]=true>{{ godownStockReportDestinationWiseData.ewayBillValidUptoStr }}
                                                                </td>
                                                                <td>{{godownStockReportDestinationWiseData.date}}
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td [hidden]=true></td>
                                                                <td [hidden]=true></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>

                                                    </table>
                                                </div>
                                                <div class="box-body" [hidden]="!godownStocksReportPartyWiseSummaryTable">
                                                    <table datatable id="{{pageId}}godownStocksReportPartyWiseSummaryTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionGodownStocksReportPartyWise" [dtTrigger]="dtTriggerGodownStocksReportPartyWise">
                                                        <thead>
                                                            <tr>
                                                                <th>Lr Status</th>
                                                                <th>Total LRs</th>
                                                                <th>Total Articles</th>
                                                                <th>To Pay</th>
                                                                <th>Mobile No</th>
                                                                <th>Office No</th>
                                                                <th>Contact Person</th>
                                                                <th>Del.Type</th>
                                                                <th>Consignee</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr *ngFor="let godownStocksReportPartyWiseData of godownStocksReportPartyWiseDataList ">
                                                                <td>{{ godownStocksReportPartyWiseData.lrStatus }}</td>
                                                                <td>{{ godownStocksReportPartyWiseData.totalLrs }}</td>
                                                                <td>{{ godownStocksReportPartyWiseData.totalArticles }}
                                                                </td>
                                                                <td>{{ godownStocksReportPartyWiseData.toPay }}</td>
                                                                <td>{{ godownStocksReportPartyWiseData.mobileNo }}</td>
                                                                <td>{{ godownStocksReportPartyWiseData.officeNo }}</td>
                                                                <td>{{ godownStocksReportPartyWiseData.contactPerson }}
                                                                </td>
                                                                <td>{{ godownStocksReportPartyWiseData.deliveryType }}
                                                                </td>
                                                                <td>{{ godownStocksReportPartyWiseData.consigneeName }}
                                                                </td>

                                                            </tr>

                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>

                                                    </table>
                                                </div>
                                                <div class="box-body" [hidden]="!godownStocksReportPartyWiseDetailsTable">
                                                    <table datatable id="{{pageId}}godownStocksReportPartyWiseDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionGodownStocksPartyDetailsReport" [dtTrigger]="dtTriggerGodownStocksPartyDetailsReport">
                                                        <thead>
                                                            <tr>
                                                                <th>Consignee</th>
                                                                <th>LR NO</th>
                                                                <th>Source</th>
                                                                <th>Art</th>
                                                                <th>To-Pay</th>
                                                                <th>Paid</th>
                                                                <th>Act Wt</th>
                                                                <th>Chg Wt</th>
                                                                <th>Consignor</th>
                                                                <th>Stocks Date</th>
                                                                <th>Date Diff</th>
                                                                <th>Invoice Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr *ngFor="let godownStocksPartyDetailsReportData of godownStocksPartyDetailsReportDataList ">
                                                                <td>{{ godownStocksPartyDetailsReportData.consigneeName }}
                                                                </td>
                                                                <td>{{ godownStocksPartyDetailsReportData.lrNumber }}
                                                                </td>
                                                                <td>{{ godownStocksPartyDetailsReportData.fromStation }}
                                                                </td>
                                                                <td>{{ godownStocksPartyDetailsReportData.totalArticles }}
                                                                </td>
                                                                <td>{{ godownStocksPartyDetailsReportData.topay }}</td>
                                                                <td>{{ godownStocksPartyDetailsReportData.paid }}</td>
                                                                <td>{{ godownStocksPartyDetailsReportData.actWeight }}
                                                                </td>
                                                                <td>{{ godownStocksPartyDetailsReportData.chargedwt }}
                                                                </td>
                                                                <td>{{ godownStocksPartyDetailsReportData.consignorName }}
                                                                </td>
                                                                <td>{{ godownStocksPartyDetailsReportData.arrivalDateStr }}
                                                                </td>
                                                                <td>{{ godownStocksPartyDetailsReportData.daysDiff }}
                                                                </td>
                                                                <td>{{ godownStocksPartyDetailsReportData.invDate }}
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>

                                                    </table>
                                                </div>


                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>