import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { MemoService } from 'src/app/dataService/memo-service';

@Component({
    selector: 'app-collection',
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.css']
})
export class CollectionComponent implements OnInit {

    collectionManDataList: any;
    userDataDto: UserDataDto = new UserDataDto();
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    loadingIndicator = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerCollectionMan: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsCollectionMan: any;
    userDtoAssignMemoUpdate: UserDataDto = new UserDataDto();
    userDtoSaveCollMan: UserDataDto = new UserDataDto();
    enteredCollectionMan: any;
    enteredAddress: any;
    enteredCity: any;
    enteredContactNo: any;
    collectionNameIsReadOnly: boolean = false;
    pageId = "colct";
    //for datatable ends
    constructor(private masterReadService: MasterReadService, private router: Router, public changeDetectorRef: ChangeDetectorRef,
        private memoService: MemoService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getCollectionManDetailsList();
            this.collectionManTable();
        }

    }
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnInit(): void {
    }

    collectionManTable() {
        //for datatable starts
        this.dtOptionsCollectionMan = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true,
            pagingType: 'full_numbers',
            pageLength: 9,
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerCollectionMan.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerCollectionMan.next();

    }
    getDetailsForCollectionMasterRead() {
        this.userDataDto.branchId = this.userDataDtoReturnSession.mainStation;
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.status = "D";
    }

    getCollectionManDetailsList = () => {
        this.getDetailsForCollectionMasterRead();
        this.masterReadService.getCollectionManMasterDetails(this.userDataDto).subscribe(
            (response) => {
                $("#" + this.pageId + "collectionManTableId").DataTable().destroy();
                if (response) {
                    this.collectionManDataList = [];
                    this.collectionManDataList = response;
                }
                this.dtTriggerCollectionMan.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Collection Man Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    rowSelected(collectionManData) {
        this.collectionNameIsReadOnly = true;
        $("#" + this.pageId + "collectionName").val(collectionManData.collectioMan);
        $("#" + this.pageId + "address").val(collectionManData.address);
        $("#" + this.pageId + "city").val(collectionManData.city);
        $("#" + this.pageId + "contactNumber").val(collectionManData.collManContct);
    }

    saveCollManData() {
        this.userDtoSaveCollMan = new UserDataDto();
        this.enteredCollectionMan = null;
        this.enteredAddress = null;
        this.enteredCity = null;
        this.enteredContactNo = null;
        this.enteredCollectionMan = $("#" + this.pageId + "collectionName").val();
        this.enteredAddress = $("#" + this.pageId + "address").val();
        this.enteredCity = $("#" + this.pageId + "city").val();
        this.enteredContactNo = $("#" + this.pageId + "contactNumber").val();

        this.userDtoSaveCollMan.collectioMan = this.enteredCollectionMan;
        this.userDtoSaveCollMan.address = this.enteredAddress;
        this.userDtoSaveCollMan.city = this.enteredCity;
        this.userDtoSaveCollMan.collManContct = this.enteredContactNo;
        this.userDtoSaveCollMan.branchId = this.userDataDtoReturnSession.mainStation;
        this.userDtoSaveCollMan.office = this.userDataDtoReturnSession.office;
        this.userDtoSaveCollMan.createdBy = this.userDataDtoReturnSession.userId;
        this.userDtoSaveCollMan.companyId = this.userDataDtoReturnSession.companyId;

    }
    confirmSave() {
        this.enteredCollectionMan = $("#" + this.pageId + "collectionName").val();
        this.enteredAddress = $("#" + this.pageId + "address").val();
        this.enteredCity = $("#" + this.pageId + "city").val();
        this.enteredContactNo = $("#" + this.pageId + "contactNumber").val();
        if (this.enteredCollectionMan == null || this.enteredCollectionMan == '' || this.enteredCollectionMan == undefined) {
            swal({
                title: "Mandatory Fields",
                text: "Please entered Collection Man, Address, City and Contact No! ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Save/Update",
                text: "Sure want to Save/Update",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((saveYes) => {
                if (saveYes) {
                    this.saveCollectionMan();
                }
            });
        }

    }
    saveCollectionMan() {
        this.saveCollManData();
        this.memoService.createCollectionManService(this.userDtoSaveCollMan).subscribe(
            (response) => {
                if (response.status == "updated") {
                    swal({
                        title: "Collection Man Updated",
                        text: "Selected collection man updated successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        this.clearFields();
                        this.clearTable();
                        this.getCollectionManDetailsList();
                    })
                } else if (response.status == "persisted") {
                    swal({
                        title: "Collection Man Saved",
                        text: "Collection man saved successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        this.clearFields();
                        this.clearTable();
                        this.getCollectionManDetailsList();
                    })
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "Problem occured while Saving/Updating Collection Man",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    }

    confirmClearBtn() {
        swal({
            title: "Clear All",
            text: "Sure want to clear all",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((clearYes) => {
            if (clearYes) {
                this.clearFields();
            }
        });
    }

    clearFields() {
        $("#" + this.pageId + "collectionName").val('');
        $("#" + this.pageId + "address").val('');
        $("#" + this.pageId + "city").val('');
        $("#" + this.pageId + "contactNumber").val('');
        this.userDtoAssignMemoUpdate = new UserDataDto();
        this.collectionNameIsReadOnly = false;
        this.userDtoSaveCollMan = new UserDataDto();
        this.enteredCollectionMan = null;
        this.enteredAddress = null;
        this.enteredCity = null;
        this.enteredContactNo = null;
    }

    assignMemoForCollectionMan(collectionManData) {
        swal({
            title: "Confirm Enable",
            text: "Sure u want to enable Stock Check / Memo Assign for collection man  '" + collectionManData.collectioMan + "' for today only?",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((assignColMan) => {
            if (assignColMan) {
                this.userDtoAssignMemoUpdate = new UserDataDto();
                this.userDtoAssignMemoUpdate.collectioMan = collectionManData.collectioMan;
                this.userDtoAssignMemoUpdate.branchId = this.userDataDtoReturnSession.mainStation;
                this.userDtoAssignMemoUpdate.city = collectionManData.city;
                this.userDtoAssignMemoUpdate.address = collectionManData.address;
                this.userDtoAssignMemoUpdate.office = this.userDataDtoReturnSession.office;
                this.userDtoAssignMemoUpdate.createdBy = this.userDataDtoReturnSession.userId;
                this.userDtoAssignMemoUpdate.companyId = this.userDataDtoReturnSession.companyId;
                this.userDtoAssignMemoUpdate.memoAssignEnabled = true;
                this.assignNewCollectionMan();
            }
        });
    }

    assignNewCollectionMan() {
        console.log("this.userDtoAssignMemoUpdate");
        console.log(this.userDtoAssignMemoUpdate);
        this.memoService.collectionManAssignEnableServiceMtd(this.userDtoAssignMemoUpdate).subscribe(
            (response) => {
                if (response.status == "success") {
                    swal({
                        title: "Collection Man Enable",
                        text: "Selected collection man enable for Stock Check / Memo Assign for today '" + this.userDtoAssignMemoUpdate.collectioMan + "'",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        this.clearFields();
                    })
                } else if (response.status == "notFound") {
                    swal({
                        title: "Collection Man Not Found",
                        text: "Selected collection Not Found '" + this.userDtoAssignMemoUpdate.collectioMan + "'",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        this.clearFields();

                    })
                } else if (response.status == "failed") {
                    swal({
                        title: "Failed",
                        text: "Failed to enable for Stock Check / Memo Assign for this '" + this.userDtoAssignMemoUpdate.collectioMan + "'",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        this.clearFields();
                    })
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "Problem occured while Saving New Collection Man",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');


    }
    clearTable() {
        $("#" + this.pageId + "collectionManTableId").DataTable().destroy();
        this.collectionManDataList = [];
        this.dtTriggerCollectionMan.next();
    }

    enableLeftToPartyAckCollectionManConfirm(collectionManData) {
        swal({
            title: "Confirm Enable",
            text: "Sure u want to enable Payment Followup process for collection man  '" + collectionManData.collectioMan + "' for today only?",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((assignColMan) => {
            if (assignColMan) {
                this.userDtoAssignMemoUpdate = new UserDataDto();
                this.userDtoAssignMemoUpdate.collectioMan = collectionManData.collectioMan;
                this.userDtoAssignMemoUpdate.branchId = this.userDataDtoReturnSession.mainStation;
                this.userDtoAssignMemoUpdate.city = collectionManData.city;
                this.userDtoAssignMemoUpdate.address = collectionManData.address;
                this.userDtoAssignMemoUpdate.office = this.userDataDtoReturnSession.office;
                this.userDtoAssignMemoUpdate.createdBy = this.userDataDtoReturnSession.userId;
                this.userDtoAssignMemoUpdate.companyId = this.userDataDtoReturnSession.companyId;
                this.userDtoAssignMemoUpdate.memoAssignEnabled = true;
                this.enableLeftToPartyAckCollectionMan();
            }
        });
    }

    enableLeftToPartyAckCollectionMan() {
        this.memoService.collectionManLeftToPartyAckPendingBlockEnable(this.userDtoAssignMemoUpdate).subscribe(
            (response) => {
                if (response.status == "success") {
                    swal({
                        title: "Success",
                        text: "Selected collection man enabled for Payment Followup process for today",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        this.clearFields();
                    })
                } else if (response.status == "notFound") {
                    swal({
                        title: "Collection Man Not Found",
                        text: "Selected collection Not Found '" + this.userDtoAssignMemoUpdate.collectioMan + "'",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        this.clearFields();

                    })
                } else if (response.status == "failed") {
                    swal({
                        title: "Failed",
                        text: "Failed to enabled",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        this.clearFields();
                    })
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "Failed to enabled",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');


    }

}
