import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';

//for datatable ends
//for modal starts
//import { OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingLrDetailsComponent } from "src/app/dashboards/dashboards-popup/pending-lr-details/pending-lr-details.component";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
//import {Subscription} from 'rxjs';
//for modal ends
@Component( {
    selector: 'app-left-to-party-statements-pending',
    templateUrl: './left-to-party-statements-pending.component.html',
    styleUrls: ['./left-to-party-statements-pending.component.css']
} )
export class LeftToPartyStatementsPendingComponent implements  OnInit {
    getDataLeftToPartyStatementPendingDataTable: any;
    leftToPartyStatementPendingDataList: any;
    onDestroyUnsubscribtionLeftToPartyStatementPending: Subscription;
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;
    dtTriggerLeftToPartyStatementPending: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLeftToPartyStatementPending: any;
    //for modal starts
    animal: string;
    name: string;
    routeQueryParams$: Subscription;
	cashmemoDtoReload:CashMemoDto =  new CashMemoDto();
	cashmemoForDetails:CashMemoDto =  new CashMemoDto();
	cashmemoForDetailsRet:CashMemoDto =  new CashMemoDto();
	responseForRemMos:any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;
    pageId="ltpsc";
    //for modal ends
    constructor( private dashboardService: DashboardService,
        //for modal starts
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router, public changeDetectorRef : ChangeDetectorRef
        //for modal ends
    ) {
        if (sessionStorage.length == 0) {
              this.isLoggedIn = false;
              swal({
                  title: "Session Expired",
                  text: "Please relogin to access the application!",
                  icon: "error",
                  closeOnClickOutside: false,
                  closeOnEsc: false,
              }).then(() => {
                  this.logInPage();
              })
          }
          if (this.isLoggedIn) {
              this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
          }
    }
	 logInPage() {
        this.router.navigate(['/authentication/login']);
    }
	ngOnInit(): void {

        this.dtOptionsLeftToPartyStatementPending = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
          
           
    }

    // ngOnDestroy(): void {
    //     this.dtTriggerLeftToPartyStatementPending.unsubscribe();
    //    // this.routeQueryParams$.unsubscribe();
    //     //for modal ends
    // }
    ngAfterViewInit(): void {
        this.dtTriggerLeftToPartyStatementPending.next();

    }
    
	gridReconifgureOnReloadBtn(){
			this.cashmemoDtoReload=  new CashMemoDto();
			this.cashmemoDtoReload.office=this.userDataDtoReturnSession.mainStation;
			this.cashmemoDtoReload.status='Pending';
			this.cashmemoDtoReload.companyId=this.userDataDtoReturnSession.companyId;
			console.log(this.cashmemoDtoReload);
			this.gridReconifgureDetails();
	}
	gridReconifgureDetails= () => {
    	         this.showSpinnerForAction = true;
    	         this.dashboardService.getCollectionDetailsLeftToPartyPendAckStmtDashboard(this.cashmemoDtoReload).subscribe(
    	             (response) => {
    	            	 this.showSpinnerForAction=false;
						  //$("#"+this.pageId+"leftToPartyStatementPendingId").DataTable().destroy();
							this.leftToPartyStatementPendingDataList=[];
    	                 if (response.length == 0) {
    	                     swal({
    	                         title: "Warning",
    	                         text: "No Details found !",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else {
    	               	  this.leftToPartyStatementPendingDataList=response;
    	               	  console.log(this.leftToPartyStatementPendingDataList);
    	                }
    	            //   this.dtTriggerLeftToPartyStatementPending.next();
					  this.changeDetectorRef.detectChanges();
    	             }), (error) => {
                         this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred While getting the Left To Party Details", "info");
                    }, () => console.log('done');
            };
            
     stmtNoDialog(leftToPartyStatementPendingData): void {
    	if(leftToPartyStatementPendingData.statementNo !=0){
			this.getStmtNoDetails(leftToPartyStatementPendingData);
    	}
    	
      
    }
	
	getStmtNoDetails(leftToPartyStatementPendingData){
			this.cashmemoForDetails=  new CashMemoDto();
			this.cashmemoForDetails.statementNo=leftToPartyStatementPendingData.statementNo;
			this.cashmemoForDetails.companyId=this.userDataDtoReturnSession.companyId;
			console.log(this.cashmemoForDetails);
			this.getStmtNoInfo(leftToPartyStatementPendingData);
	}
	getStmtNoInfo= (leftToPartyStatementPendingData) => {
    	         this.showSpinnerForAction = true;
    	         this.dashboardService.getTPStmtDetails(this.cashmemoForDetails).subscribe(
    	             (response) => {
    	            	 this.showSpinnerForAction=false;
    	                 if (response.length == 0) {
    	                     swal({
    	                         title: "Warning",
    	                         text: "No Details found !",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else {
						 
						 this.cashmemoForDetails=  new CashMemoDto();
						 this.responseForRemMos= response;
						 this.cashmemoForDetails=this.responseForRemMos;
						 console.log( this.cashmemoForDetails);
						 localStorage.clear();
							localStorage.setItem('leftToPartyForPopup',
										JSON.stringify(leftToPartyStatementPendingData));
							localStorage.setItem('leftToPartyForOtherPopup',
										JSON.stringify(this.cashmemoForDetails));
    	               
							  const dialogRef = this.dialog.open( PendingLrDetailsComponent, {
							
							} );

						// dialogRef.afterClosed().subscribe( result => {
						// 	console.log( 'The dialog was closed' );
						// 	this.router.navigate( ['.'], { relativeTo: this.route } );
						// } );
							 
    	                }
						this.changeDetectorRef.detectChanges();
    	             }), (error) => {
                        this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred While getting the Left To Party Pop Up Details", "info");
                    }, () => console.log('done');
            };
}
