<div *ngIf="isLoggedIn">
	<h1 class="title_custom" mat-dialog-title>
		<h6 class="modal-title" id="{{pageId}}modal-basic-title">Collection Man To
			Collection Man Stock Transfer</h6>
	</h1>
	<div mat-dialog-content style="width: 36vw;">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-lg-12">
								<!-- <div class="col-sm-12 col-md-12">

									<div class="control">
										<div class="form-group">
											<div class="input-group">
												<label>Collection Man From :</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-user"></i>
													</span>
												</div>
												<input class="auto_selectOption input is-medium" id="{{pageId}}collectionManFrom"
													#collectionManFrom placeholder="Select Collection Man"
													[formControl]="controlCollectionManFrom"
													[appAutocomplete]="autocompleteCollectionManFrom">
											</div>
										</div>
									</div>

									<app-autocomplete #autocompleteCollectionManFrom="appAutocomplete">
										<ng-template appAutocompleteContent>
											<ng-container
												*ngIf="(collectionManFromOptions | filterCollectioMan: controlCollectionManFrom.value) as resultCollectionManFrom">
												<app-option *ngFor="let option of resultCollectionManFrom"
													[value]="option.collectioMan"> {{
									option.collectioMan }} </app-option>
												<app-option class="no-result" *ngIf="!resultCollectionManFrom.length">No
													result</app-option>
											</ng-container>
										</ng-template>
									</app-autocomplete>
								</div> -->

								<div class="col-sm-12 col-md-12">
									<div class="control">
										<div class="form-group" id="{{pageId}}scrollable-dropdown-menu"
											style="position: static;">
											<div class="input-group">
												<label>Collection Man From : </label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-user"></i>
													</span>
												</div>
												<input #collectionManFrom id="{{pageId}}collectionManFrom"
													name="collectionManFrom" type="text" class="form-control"
													(selectItem)="collectionManFromFocus($event)"
													[(ngModel)]="enteredcollectionManFrom"
													[ngModelOptions]="{standalone: true}"
													[ngbTypeahead]="collectionManFromSearchTA"
													(focus)="focusCollectionManFromTA$.next($any($event).target.value)"
													(click)="clickTA(instanceCollectionManFrom)"
													(keyup)="fieldFocusCollectionManFrom($event)"
													#instanceCollectionManFrom="ngbTypeahead" />
											</div>
										</div>
									</div>
								</div>

								
								<!-- <div class="col-sm-12 col-md-12">

									<div class="control">
										<div class="form-group">
											<div class="input-group">
												<label>Collection Man To :</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-user"></i>
													</span>
												</div>
												<input class="auto_selectOption input is-medium" id="{{pageId}}collectionManTo"
													#collectionManTo placeholder="Select Collection Man"
													[formControl]="controlCollectionManTo"
													[appAutocomplete]="autocompleteCollectionManTo">
											</div>
										</div>
									</div>

									<app-autocomplete #autocompleteCollectionManTo="appAutocomplete">
										<ng-template appAutocompleteContent>
											<ng-container
												*ngIf="(collectionManToOptions | filterCollectioMan: controlCollectionManTo.value) as resultCollectionManTo">
												<app-option *ngFor="let option of resultCollectionManTo"
													[value]="option.collectioMan"> {{
									option.collectioMan }} </app-option>
												<app-option class="no-result" *ngIf="!resultCollectionManTo.length">No
													result</app-option>
											</ng-container>
										</ng-template>
									</app-autocomplete>
								</div> -->
								<div class="col-sm-12 col-md-12">
									<div class="control">
										<div class="form-group" id="{{pageId}}scrollable-dropdown-menu"
											style="position: static;">
											<div class="input-group">
												<label>Collection Man To : </label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-user"></i>
													</span>
												</div>
												<input #collectionManTo id="{{pageId}}collectionManTo"
													name="collectionManTo" type="text" class="form-control"
													(selectItem)="collectionManToFocus($event)"
													[(ngModel)]="enteredcollectionManTo"
													[ngModelOptions]="{standalone: true}"
													[ngbTypeahead]="collectionManToSearchTA"
													(focus)="focusCollectionManToTA$.next($any($event).target.value)"
													(click)="clickTA(instanceCollectionManTo)"
													(keyup)="fieldFocusCollectionManTo($event)"
													#instanceCollectionManTo="ngbTypeahead" />
											</div>
										</div>
									</div>
								</div>

								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Entry Type : </label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="fas fa-search"></i>
												</span>
											</div>
											<select class="custom-select col-12" id="{{pageId}}entryType" name="entryType"
												#entryType (keydown.enter)="entryTypeModeFocus($event)"
												(change)="entryTypeMode(entryType.value)">
												<option value="manual">Manual</option>
												<option value="automatic">Automatic</option>
											</select>
										</div>
									</div>
								</div>
								<!-- <div class="col-sm-12 col-md-12">
									<div class="control">
										<div class="form-group">
											<div class="input-group">
												<label>Godown</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-warehouse"></i>
													</span>
												</div>
												<input class="auto_selectOption input is-medium"
													placeholder="Select Godown" id="{{pageId}}godown"
													[formControl]="controlGodown" #godown
													[appAutocomplete]="autocompleteGodown">
											</div>
										</div>
									</div>

									<app-autocomplete #autocompleteGodown="appAutocomplete">
										<ng-template appAutocompleteContent>
											<ng-container
												*ngIf="(godownOptions | filterDest: controlGodown.value) as resultGodown">
												<app-option *ngFor="let option of resultGodown"
													(click)="getMemoCode(option)" [value]="option.destination">
													{{ option.destination }} </app-option>
												<app-option class="no-result" *ngIf="!resultGodown.length">No result
												</app-option>
											</ng-container>
										</ng-template>
									</app-autocomplete>
								</div> -->
								<div class="col-sm-12 col-md-12">
									<div class="control">
										<div class="form-group">
											<div class="input-group">
												<label>Godown : </label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-warehouse"></i>
													</span>
												</div>
												<input #godown id="{{pageId}}godown"
													type="text" placeholder="Select Godown"
													class="form-control"
													(selectItem)="getGodownMemoCode($event)"
													[(ngModel)]="modelGodownName"
													[ngModelOptions]="{standalone: true}"
													[ngbTypeahead]="entryGodownSearchTA"
													(focus)="focusGodownTA$.next($any($event).target.value)"
													(click)="clickTA(instanceGodown)"
													#instanceGodown="ngbTypeahead" />
											</div>
										</div>
									</div>
								</div>
								<div *ngIf="manualView" class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Cash Memo :</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="fas fa-file-alt"></i>
												</span>
											</div>
											<input type="text" class="form-control" id="{{pageId}}cashMemoFormat" #cashMemoFormat
												aria-describedby="basic-addon11">
											<input id="{{pageId}}memoNumber" #memoNumber type="text" class="form-control"
												(keyup)="getMemoNumber($event)" aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<!-- 								commentted due to error in transfer btn click  and add #transferBtn in btn as a id-->
								<!-- 								(keyup)="fieldFocus($event, transferBtn);getMemoNumber($event)" -->
								<div *ngIf="automaticView" class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Cash Memo :</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="fas fa-file-alt"></i>
												</span>
											</div>
											<input type="text" class="form-control" id="{{pageId}}cashMemoNumber"
												(keyup)="getMemoNumber($event)" #cashMemoNumber
												aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>LR Number :</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="fas fa-file-alt"></i>
												</span>
											</div>
											<input type="text" class="form-control" #lrNumber id="{{pageId}}lrNumber"
												aria-describedby="basic-addon11" readonly>
										</div>
									</div>
								</div>
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Memo Amount :</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
												</span>
											</div>
											<input type="number" class="form-control" id="{{pageId}}memoAmount" #memoAmount
												(keyup)="getValidateMemoNumber($event)" aria-describedby="basic-addon11"
												readonly>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div mat-dialog-actions style="float: right;">
		<!-- 			(click)="onTransferClick()" -->
		<!-- 		<button type="button" class="btn btn-outline-dark" -->
		<!-- 			(keyup)="fieldFocus($event, memoNumber);getValidateMemoNumber($event)" -->
		<!-- 			#transferBtn id="{{pageId}}transferBtn">Transfer</button> -->
		<!-- 		<button type="button" class="btn btn-outline-dark" -->
		<!-- 			(click)="finishBtnTransfer();" #finishBtn -->
		<!-- 			id="{{pageId}}finishBtn">Finish</button> -->
		<!-- 		<button type="button" class="btn btn-outline-dark" -->
		<!-- 			(click)="onCancelClick()" #cancelBtn id="{{pageId}}cancelBtn">Cancel</button> -->
		<div class="row">

			<!-- 			<button type="button" class="btn btn-outline-dark" id="{{pageId}}transferBtn" -->
			<!-- 				style="display: none;" #empty>Transfer</button> -->
			<!-- 					c('Save click'); -->
			<button type="button" class="btn btn-outline-dark" (click)="transferBtn()"
				id="{{pageId}}transferBtn">Transfer</button>
			<button type="button" class="btn btn-outline-dark" (click)="onCloseClick()">Close</button>
		</div>
	</div>
</div>

