<!-- Row -->
<div class="row">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info hide-header" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Send SMS </h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div *ngIf="showSpinnerForAction" class="col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;">
                            </mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                Please Wait Loading Details.....</h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="card-body">
                        <div class="row">

                            <div class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Notification Type</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-search"></i>
											</span>
                                        </div>

                                        <select class="custom-select col-12" id="{{pageId}}notificationType" name="notificationType" #notificationType (change)="notificationTypeMode(notificationType.value)">
											<option selected value="Whatsapp">Whatsapp</option>
											<option value="SMS">SMS</option>
										</select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Send SMS Type </label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-search"></i>
											</span>
                                        </div>

                                        <select class="custom-select col-12" id="{{pageId}}sendSmsType" name="sendSmsType" #sendSmsType (change)="sendSmsTypeMode(sendSmsType.value)">
											<option selected value="Select Send Type">Select Send Type</option>
											<option value="sourceWise">Source Wise</option>
											<option value="destinationWise">Destination Wise</option>
											<option value="commodityWise">Commodity Wise</option>
											<option value="invoiceWise">Invoice Wise</option>
											<option value="srdGroup">SRD Group</option>
                                            <option value="other">Other</option>
										</select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>SMS Type </label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-search"></i>
											</span>
                                        </div>

                                        <select class="custom-select col-12" id="{{pageId}}smsType" name="smsType" #smsType (change)="smsTypeMode(smsType.value)">
											<option selected value="Select SMS Type">Select SMS Type</option>
											<option *ngIf="viewSmsTypeAll" value="general">General</option>
											<option *ngIf="viewSmsTypeInvoice" value="accident">Accident</option>
											<option *ngIf="viewSmsTypeInvoice" value="salesTax">Sales Tax</option>
											<option *ngIf="viewSmsTypeInvoice" value="breakDown">BreakDown</option>
											<option *ngIf="viewSmsTypeInvoice" value="roadBlock">Road Block</option>

										</select>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="viewPlace" class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Place</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-search"></i>
											</span>
                                        </div>

                                        <select class="custom-select col-12" id="{{pageId}}placeType" name="placeType" #placeType (change)="placeTypeMode(placeType.value)">
											<option selected value="Select Branch">Select Branch Type</option>
											<option value="source">Source</option>
											<option value="destination">Destination</option>
										</select>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="viewSrc" class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Source</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-box"></i>
											</span>
                                        </div>
                                        <input id="{{pageId}}sourceId" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" (selectItem)="sourceListener($event)" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                            placeholder="Select Source" />
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="viewDest" class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Destination</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class=" fas fa-user"></i>
											</span>
                                        </div>
                                        <input #destination id="{{pageId}}destinationId" type="text" class="form-control" [(ngModel)]="modelDropDownDestination" [ngbTypeahead]="searchDropDownInputFieldDestination" (selectItem)="clickListnerForDestination($event)" [resultFormatter]="formatterDropDownDestination"
                                            [inputFormatter]="formatterDropDownDestination" placeholder="Select Destination" (focus)="focusDropDownDestinationTA$.next($any($event).target.value)" />
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="viewCommodity" class="col-sm-12 col-md-12">
                                <div class="control">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Commodity </label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-search"></i>
												</span>
                                            </div>

                                            <input #partyGoodsType id="{{pageId}}partyCommodity" type="text" class="form-control" [ngbTypeahead]="partyCommoditySearchTA" [(ngModel)]="modelCommodity" (selectItem)="clickListnerForCommodity($event)" (focus)="focusPartyCommodityTA$.next($any($event).target.value)"
                                                (click)="clickTA(instancePartyCommodity)" #instancePartyCommodity="ngbTypeahead" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div *ngIf="viewInvoiceNo" class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Invoice</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-file-alt"></i>
											</span>
                                        </div>
                                        <input type="text" class="form-control" id="{{pageId}}inovicenNo" #inovicenNo name="inovicenNo" aria-describedby="basic-addon11" (keyup)="getInvDetails($event)">
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="viewGrpName" class="col-sm-12 col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Group Name</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="ti-user"></i>
											</span>
                                        </div>
                                        <input id="{{pageId}}grpName" type="text" name="grpName" class="form-control" [(ngModel)]="modelGrpNameFotGet" [ngbTypeahead]="searchGrpName" (selectItem)="clickListnerForGrpName($event)" [resultFormatter]="formatterGrpName" [inputFormatter]="formatterGrpName"
                                            (focus)="focusGrpNameTA$.next($any($event).target.value)" placeholder="Select Group Name" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-12" *ngIf="viewLrNoCheckbox">
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxLrNo" (change)="checkboxIncludeLrNo($event)">
                                            <label class="custom-control-label wtfull"for="{{pageId}}checkboxLrNo"></label>
                                        </div>&nbsp;
                                        <label>Include LR No</label>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body col-md-12">
                                <div class="row">
                                    <div class="col-md-8">
                                        <h6 class="card-title">{{tableTopHeading}}</h6>
                                    </div>
                                    <div class="col-md-4" style="text-align: right;" [hidden]="viewIncludeLrNoTable">
                                        <button type="submit" (click)="checkuncheckall()" class="btn btn-info" style="padding: 0px 5px 0px 5px;" id="{{pageId}}previewBtn">Select/Deselect
											All</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" [hidden]="viewIncludeLrNoTable">
                                        <div class="box-body">

                                            <table datatable id="{{pageId}}gridCreationTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsForGridCreation" [dtTrigger]="dtTriggerForGridCreation">
                                                <thead>
                                                    <tr>
                                                        <!-- (change)="rowCheckBoxCheckedAll($event)" -->
                                                        <th>
                                                            <!-- [checked]="multiSelect" -->
                                                            <!-- <input type="checkbox" id='checkAllCheckbox'
																style="margin-left: 20px" (click)="checkuncheckall()" /> -->
                                                            Action
                                                        </th>
                                                        <th>Party Name</th>
                                                        <th>Gst Number</th>
                                                        <th>Mobile Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let gridCreationData of gridCreationDataList; let i=index">
                                                        <td><input type="checkbox" [checked]="isChecked" id='{{i}}gridCreationDataCheckboxId' style="margin-left: 20px" (change)="rowCheckBoxChecked($event, gridCreationData)" />
                                                        </td>
                                                        <td>{{ gridCreationData.consignorName }}</td>
                                                        <td>{{ gridCreationData.gstNoConsignor }}</td>
                                                        <td>{{ gridCreationData.mobileNum }}</td>

                                                        <!-- <td>{{ gridCreationData.consignorId }}</td> -->
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="col-md-12" [hidden]="!viewIncludeLrNoTable">
                                        <div class="box-body">

                                            <table datatable id="{{pageId}}includeLrNoTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsForIncludeLrNo" [dtTrigger]="dtTriggerForIncludeLrNo">
                                                <thead>
                                                    <tr>
                                                        <!-- <th>Action</th> -->
                                                        <th>LR No</th>
                                                        <th>Party Name</th>
                                                        <th>Gst Number</th>
                                                        <th>Mobile Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let includeLrNoData of includeLrNoDataList; let i=index">
                                                        <!-- <td><input type="checkbox" [checked]="isCheckedIncludeLrNo" id='{{i}}includeLrNoDataCheckboxId' style="margin-left: 20px" (change)="rowCheckBoxCheckedIncludeLrNo($event, includeLrNoData)" />
                                                        </td> -->
                                                        <td>{{ includeLrNoData.lrNumber }}</td>
                                                        <td>{{ includeLrNoData.consignorName }}</td>
                                                        <td>{{ includeLrNoData.gstNoConsignor }}</td>
                                                        <td>{{ includeLrNoData.mobileNum }}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 vl p-t-10">
                    <div class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i class="ti-user"></i>
									</span>
                                </div>
                                <input id="{{pageId}}template" type="text" name="template" class="form-control" [(ngModel)]="modelTemplate" [ngbTypeahead]="searchTemplate" (click)="clickTA(instanceTemplate)" (selectItem)="clickListnerForTemplate($event)" [resultFormatter]="formatterTemplate"
                                    [inputFormatter]="formatterTemplate" (focus)="focusTemplateTA$.next($any($event).target.value)" #instanceTemplate="ngbTypeahead" placeholder="Select Template" />
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showTextAreaTemplate" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="form-group">
                                <div class="form-group-prepend">
                                    <span class="input-group-text"> <i class=" fas fa-envelope"></i>
									</span>
                                </div>
                                <textarea class="form-control" id="{{pageId}}messageTextAreaTemp" [(ngModel)]="messageTextAreaTempModel" rows="5" readonly></textarea>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="viewInputFieldOne" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Var 1</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i class="fas fa-file-alt"></i>
									</span>
                                </div>
                                <input type="text" class="form-control" id="{{pageId}}inputFieldOne" #inputFieldOne name="inputFieldOne" aria-describedby="basic-addon11" autocomplete="off" placeholder="{#var#} - Max Char 30" oninput="javascript: if(this.value.length > this.maxLength) 
																								this.value=value.slice(0,this.maxLength);" maxLength="30">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="viewInputFieldTwo" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Var 2</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i class="fas fa-file-alt"></i>
									</span>
                                </div>
                                <input type="text" class="form-control" id="{{pageId}}inputFieldTwo" #inputFieldTwo name="inputFieldTwo" aria-describedby="basic-addon11" autocomplete="off" placeholder="{#var#} - Max Char 30" oninput="javascript: if(this.value.length > this.maxLength) 
																								this.value=value.slice(0,this.maxLength);" maxLength="30">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="viewInputFieldThree" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Var 3</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i class="fas fa-file-alt"></i>
									</span>
                                </div>
                                <input type="text" class="form-control" id="{{pageId}}inputFieldThree" #inputFieldThree name="inputFieldThree" aria-describedby="basic-addon11" autocomplete="off" placeholder="{#var#} - Max Char 30" oninput="javascript: if(this.value.length > this.maxLength) 
																								this.value=value.slice(0,this.maxLength);" maxLength="30">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="viewInputFieldFour" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Var 4</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i class="fas fa-file-alt"></i>
									</span>
                                </div>
                                <input type="text" class="form-control" id="{{pageId}}inputFieldFour" #inputFieldFour name="inputFieldFour" aria-describedby="basic-addon11" autocomplete="off" placeholder="{#var#} - Max Char 30" oninput="javascript: if(this.value.length > this.maxLength) 
																								this.value=value.slice(0,this.maxLength);" maxLength="30">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="viewInputFieldFive" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Var 5</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i class="fas fa-file-alt"></i>
									</span>
                                </div>
                                <input type="text" class="form-control" id="{{pageId}}inputFieldFive" #inputFieldFive name="inputFieldFive" aria-describedby="basic-addon11" autocomplete="off" placeholder="{#var#} - Max Char 30" oninput="javascript: if(this.value.length > this.maxLength) 
																								this.value=value.slice(0,this.maxLength);" maxLength="30">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="viewInputFieldSix" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Var 6</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i class="fas fa-file-alt"></i>
									</span>
                                </div>
                                <input type="text" class="form-control" id="{{pageId}}inputFieldSix" #inputFieldSix name="inputFieldSix" aria-describedby="basic-addon11" autocomplete="off" placeholder="{#var#} - Max Char 30" oninput="javascript: if(this.value.length > this.maxLength) 
																								this.value=value.slice(0,this.maxLength);" maxLength="30">
                            </div>
                        </div>
                    </div>
                    <!-- preview here -->
                    <div *ngIf="viewPreviewBtn" class="col-md-12" style="text-align: center;padding-bottom: 10px;">
                        <button type="submit" (click)="viewSmsTemplPreview()" class="btn btn-primary" id="{{pageId}}previewBtn">Preview</button>
                    </div>
                    <div *ngIf="viewSmsPreview" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="form-group">
                                <label>Message Preview</label>
                                <div class="form-group-prepend">
                                    <span class="input-group-text"> <i class=" fas fa-envelope"></i>
									</span>
                                </div>
                                <textarea class="form-control" id="{{pageId}}messageTextArea" [(ngModel)]="enteredMessageTextAreaModel" rows="5"></textarea>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="viewMobile" class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="form-group">
                                <label>Mobile No - Multiple No Separated By Comma (,)</label>
                                <div class="form-group-prepend">
                                    <span class="input-group-text"> <i class=" fas fa-phone"></i>
									</span>
                                </div>
                                <textarea class="form-control" id="{{pageId}}mobileNo" [(ngModel)]="mobileNoModel" rows="7"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" style="text-align: right;">
                        <!-- <button type="submit" (click)="sendSmsConfirm()" class="btn btn-success" id="{{pageId}}previewBtn">Send Message</button> -->
                        <button type="submit" (click)="sendSmsConfirmWithType()" class="btn btn-success" id="{{pageId}}previewBtn">Send Message</button>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>