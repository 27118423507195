<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">LR Short And Extra Conflict</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}mainSrc">
														<label>Source</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
														</div>
														<select class="custom-select col-12">
															<option selected>Select Source</option>
															<option value="all">ALL</option>
															<option value="delhi">Delhi</option>
															<option value="mumbai">Mumbai</option>
															<option value="nagpur">Nagpur</option>
														</select>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}destination">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
														</div>
														<select class="custom-select col-12">
															<option selected>Select Destination</option>
															<option value="bangalore">Bangalore</option>
															<option value="chennai">Chennai</option>
															<option value="kerela">Kerela</option>
														</select>
													</div>
												</div>
											</div>





										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<div class="box-body">
							<table datatable
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsShortAndExtraConflict"
								[dtTrigger]="dtTriggerShortAndExtraConflict">

								<thead>
									<tr>
										<th>LR Number</th>
										<th>Invoice No.</th>
										<th>Status</th>
										<th>Src Article</th>
										<th>Src User</th>
										<th>Src Office</th>
										<th>Dest Article</th>
										<th>Dest User</th>
										<th>Dest Office</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let shortAndExtraConflictData of shortAndExtraConflictDataList ">
										<td>{{ shortAndExtraConflictData.lrNumber }}</td>
										<td>{{ shortAndExtraConflictData.invoiceNo }}</td>
										<td>{{ shortAndExtraConflictData.status }}</td>
										<td>{{ shortAndExtraConflictData.srcArticle }}</td>
										<td>{{ shortAndExtraConflictData.srcUser }}</td>
										<td>{{ shortAndExtraConflictData.srcOffice }}</td>
										<td>{{ shortAndExtraConflictData.destArticle }}</td>
										<td>{{ shortAndExtraConflictData.destUser }}</td>
										<td>{{ shortAndExtraConflictData.destOffice }}</td>
									</tr>


									<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

								</tbody>
							</table>


						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>