// Chg_V1 : Hide column Tot Hire and Advance on print based on rights as per discuss (Asrar Jr / 13-08-2024)

import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { DatePipe } from "@angular/common";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment from 'moment';
import { MasterService } from "src/app/dataService/master-service";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { HireslipService } from "src/app/dataService/hireslip-service";

@Component({
	selector: 'app-lorry-hire-balance',
	templateUrl: './lorry-hire-balance.component.html',
	styleUrls: ['./lorry-hire-balance.component.css']
})
export class LorryHireBalanceComponent implements OnInit {

	lorryHireBalanceDataList: any;
	lorryHireBalancePaidDataList: any;
	model: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	dateWise = false;
	hireslipWise = true;
	viewDestination = false;
	destView = false;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	//For Destination
	destinationOptions: LRDto[];
	lrDtoDestination: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDestinationTA$ = new Subject<string>();
	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { destination: string }) => x.destination;
	public modelDestinationFotGet: any;
	fromDateModel: any;
	toDateModel: any;

	selectedDestination: any;
	address: any;
	office: any;

	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerLorryHireBalance: Subject<any> = new Subject();
	dtOptionsLorryHireBalance: any;
	dtTriggerLorryHireBalancePaid: Subject<any> = new Subject();
	dtOptionsLorryHireBalancePaid: any;
	showPaidLorryHireBalanceRpt = false;
	showOthersLorryHireBalanceRpt = true;
	showDest = 'LorryHireBalRpt DestView';
	showLorryHireBlnRpt = true;
	//// Main Src
	agentDtoForMainSrc: AgentDetailsDto = new AgentDetailsDto();
	agentDtoForMainSrcAll: AgentDetailsDto = new AgentDetailsDto();
	agentDtoOptionsForMainSrc: AgentDetailsDto[];

	public modelForMainSrc: any;
	mainSrcTA: Array<AgentDetailsDto> = [];
	focusMainSrcTA$ = new Subject<string>();
	searchMainSrcAt = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusMainSrcTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.mainSrcTA
				: this.mainSrcTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterMainSrc = (x: { mainBookStations: string }) => x.mainBookStations;
	///
	selectedSource: any;
	selectedSearchBy: any;
	selectedFromDate: any;
	selectedToDate: any;
	enteredHireslipNumber: any;
	hireslipDtoSearchHireslipWise: HireSlipDto = new HireSlipDto();
	hireslipDtoSearchDateWise: HireSlipDto = new HireSlipDto();
	searchByNumber = false;
	selectedPaymentType: any;

	modalRefferenceConfirmPaymentPopUp: NgbModalRef;
	modalRefferenceConfirmPaymentClosePopUp: string;
	@ViewChild('confirmPaymentPopUpTemplate') private confirmPaymentPopUpTemplate;
	selectedPaymentDate: any;
	enteredPaymentDate: any;
	selectedRowHireslipNumber: any;
	hireslipDtoSelectedRow: HireSlipDto = new HireSlipDto();
	hireslipDtoConfirmPayment: HireSlipDto = new HireSlipDto();
	hireslipDtoConfirmPrintVoucher: HireSlipDto = new HireSlipDto();
	hireslipDtoUpdatePrintVoucher: HireSlipDto = new HireSlipDto();
	hireslipDtoUpdatePrintVoucherFrmPrintBtn: HireSlipDto = new HireSlipDto();
	hireslipDtoPaymentPrintVoucher: HireSlipDto = new HireSlipDto();
	getDetailsForPrint: any;
	setPrintForVoucher: HireSlipDto = new HireSlipDto();
	selectedRowHireslipNumberForVoucher: any;
	isBalancePrint = false;
	viewVoucher = false;
	updateforVoucher = false;
	updateAfterPrintForPaymentBtn = false;

	//For Custom Print
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	viewCustomPrintV1: boolean;
	totHireSummary: number;
	advSummary: number;
	balSummary: number;
	pageId = "lorhc";
	showHireColumns: Boolean = false;

	constructor(private router: Router, private masterReadService: MasterReadService, private datePipe: DatePipe, private lrReportService: LrReportService, private masterService: MasterService,
		private hireslipService: HireslipService, private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;
			this.getMainSrcDetails();
			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
						"LorryHireBalRpt DestView") {
						this.destView = true;
					} else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LorryHireBal Rpt Show Hire") {
						this.showHireColumns = true;
					}
					/*if (this.showDest=='LorryHireBalRpt DestView') {
					  this.destView=true;         
					  }*/
				}
			}
			if (this.destView == true) {

				this.getDestinationDetails();
			} else {
				this.viewDestination = false;
			}
		}
	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}

	ngOnInit(): void {
		var exportLryHireBalDetailsArray = [];
		if (this.showHireColumns) {
			exportLryHireBalDetailsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
		} else {
			exportLryHireBalDetailsArray = [1, 2, 3, 4, 7, 8, 9, 10, 11, 12];
		}

		this.dtOptionsLorryHireBalance = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					// exportOptions: {
					// 	columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
					// },
					exportOptions: {
						columns: exportLryHireBalDetailsArray
					},
					title: function () {

						var returnSummaryExcel = null;

						if ($("#lorhcsearchBy").val() == 'hireslipWise') {
							returnSummaryExcel = "Lorry Hire Balance Report" +
								" Hireslip Number : " + $("#lorhchireslipNumber").val();
						} else {
							returnSummaryExcel = "Lorry Hire Balance Report" +
								" From Date : " + moment($("#lorhcfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#lorhctoDate").val()).format('DD-MM-YYYY');
						}

						return returnSummaryExcel;
					},

				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": false,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};

				var totHire = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				var totAdvance = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totBalance = api.column(7).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html();
				$(api.column(2).footer()).html();
				$(api.column(3).footer()).html();
				$(api.column(5).footer()).html(totHire);
				$(api.column(6).footer()).html(totAdvance);
				$(api.column(7).footer()).html(totBalance);
				$(api.column(7).footer()).html();
				$(api.column(8).footer()).html();
				$(api.column(9).footer()).html();
				$(api.column(10).footer()).html();
				$(api.column(11).footer()).html();
				$(api.column(12).footer()).html();
			}
		},
			this.dtOptionsLorryHireBalancePaid = {

				dom: 'Bfrtip',
				buttons: [
					{
						extend: 'excel',
						text: '<i class="fas fa-file-excel"> Excel</i>',
						titleAttr: 'Excel',
						footer: true,
						// exportOptions: {
						// 	columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
						// },
						exportOptions: {
							columns: exportLryHireBalDetailsArray
						},
						title: function () {

							var returnSummaryExcel = null;
							if ($("#lorhcsearchBy").val() == 'hireslipWise') {
								returnSummaryExcel = "Lorry Hire Balance Report" +
									" Hireslip Number : " + $("#lorhchireslipNumber").val();
							} else {
								returnSummaryExcel = "Lorry Hire Balance Report" +
									" From Date : " + moment($("#lorhcfromDate").val()).format('DD-MM-YYYY') + " -  " +
									"To Date : " + moment($("#lorhctoDate").val()).format('DD-MM-YYYY');
							}

							return returnSummaryExcel;
						},

					}
				],
				language: {
					search: "_INPUT_",
					searchPlaceholder: "Search..."
				},
				processing: true,
				//scroll in datatable starts
				responsive: true,
				"scrollX": true,
				"scrollY": 300,
				"scrollCollapse": true,
				"paging": false,
				"info": true,
				"footerCallback": function (row, data, start, end, display) {
					var api = this.api(), data;
					// converting to interger to find total
					var intVal = function (i) {
						return typeof i === 'string' ?
							+i.replace(/[\$,]/g, '') * 1 :
							typeof i === 'number' ?
								i : 0;
					};

					var totHire = api.column(5).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					var totAdvance = api.column(6).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);
					var totBalance = api.column(7).data().reduce(
						function (a, b) {
							return intVal(a) + intVal(b);
						}, 0);

					$(api.column(0).footer()).html('Total : ' + data.length);
					$(api.column(1).footer()).html();
					$(api.column(2).footer()).html();
					$(api.column(3).footer()).html();
					$(api.column(5).footer()).html(totHire);
					$(api.column(6).footer()).html(totAdvance);
					$(api.column(7).footer()).html(totBalance);
					$(api.column(7).footer()).html();
					$(api.column(8).footer()).html();
					$(api.column(9).footer()).html();
					$(api.column(10).footer()).html();
					$(api.column(11).footer()).html();
					$(api.column(12).footer()).html();
				}
			}
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				if (dtInstance.table().node().id == "lorryHireBalanceRptId") {
					console.log("a");
					this.lorryHireBalanceDataList = [];
					dtInstance.destroy();
					this.dtTriggerLorryHireBalance.next();
				} else if (dtInstance.table().node().id == "lorryHireBalancePaidRptId") {
					console.log("b");
					this.lorryHireBalancePaidDataList = [];
					dtInstance.destroy();
					this.lorryHireBalancePaidDataList.next();
				}
			});
		});
	}


	ngOnDestroy(): void {
		this.dtTriggerLorryHireBalance.unsubscribe();
		this.dtTriggerLorryHireBalancePaid.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerLorryHireBalance.next();
		this.dtTriggerLorryHireBalancePaid.next();
	}



	//for datepicker
	// the selectToday is the method for  selecting todays'z date
	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	//for datePicker
	searchByMode(searchBy: string) {
		if (searchBy === 'hireslipWise') {
			this.dateWise = false;
			this.hireslipWise = true;
			$("#" + this.pageId + "fromDate").val('');
			$("#" + this.pageId + "toDate").val('');
			$("#" + this.pageId + "paymentType").val('All');
			$("#" + this.pageId + "hireslipNumber").val('');
			this.viewDestination = false;
			this.modelDestination = '';
			this.fromDateModel = null;
			this.toDateModel = null;
			this.clearTable();

		} else if (searchBy === 'dateWise') {
			this.dateWise = true;
			this.hireslipWise = false;
			$("#" + this.pageId + "fromDate").val('');
			$("#" + this.pageId + "toDate").val('');
			$("#" + this.pageId + "paymentType").val('All');
			$("#" + this.pageId + "hireslipNumber").val('');
			if (this.destView == true) {
				this.viewDestination = true;
				$("#" + this.pageId + "destination").val('');
			} else {
				this.viewDestination = false;
			}
			this.modelDestination = '';
			this.fromDateModel = null;
			this.toDateModel = null;
			this.clearTable();

		} else {
			this.dateWise = false;
			this.hireslipWise = false;
			$("#" + this.pageId + "fromDate").val('');
			$("#" + this.pageId + "toDate").val('');
			$("#" + this.pageId + "paymentType").val('All');
			$("#" + this.pageId + "hireslipNumber").val('');
			this.viewDestination = false;
			this.modelDestination = '';
			this.fromDateModel = null;
			this.toDateModel = null;
			this.clearTable();

		}
	}

	searchByPaymentType(paymentType: string) {
		if (paymentType === 'all') {
			this.showOthersLorryHireBalanceRpt = true;
			this.showPaidLorryHireBalanceRpt = false;
			this.clearTable();
		} else if (paymentType === 'paid') {
			this.showOthersLorryHireBalanceRpt = false;
			this.showPaidLorryHireBalanceRpt = true;
			this.clearTable();
		} else if (paymentType === 'unpaid') {
			this.showOthersLorryHireBalanceRpt = true;
			this.showPaidLorryHireBalanceRpt = false;
			this.clearTable();
		}

	}
	clearTable() {
		$("#" + this.pageId + "lorryHireBalanceRptId").DataTable().destroy();
		$("#" + this.pageId + "lorryHireBalancePaidRptId").DataTable().destroy();
		this.lorryHireBalanceDataList = [];
		this.lorryHireBalancePaidDataList = [];
		this.dtTriggerLorryHireBalance.next();
		this.dtTriggerLorryHireBalancePaid.next();
	}
	getDestinationMethod() {
		this.lrDtoDestination = new LRDto();
		this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
		this.lrDtoDestination.mode = "desttrsp";
	}
	getDestinationDetails() {
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					//this.stationOptions = response;
					if (response.length > 0) {
						this.destinationOptions = response;
						this.destinationTA = [];
						for (let i = 0; i < this.destinationOptions.length; i++) {
							this.destinationTA.push(this.destinationOptions[i]);
						}
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log('done');

	};

	getMainSrcMethod() {
		this.agentDtoForMainSrc = new AgentDetailsDto();
		this.agentDtoForMainSrc.companyId = this.userDataDtoReturnSession.companyId;
	}
	getMainSrcDetails() {
		this.getMainSrcMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getMainBookingStationsDetails(this.agentDtoForMainSrc).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					this.agentDtoOptionsForMainSrc = [];

					this.mainSrcTA = [];
					if (response.length > 0) {
						this.agentDtoOptionsForMainSrc = response;
						console.log(this.agentDtoOptionsForMainSrc);
						this.mainSrcTA = [];
						this.agentDtoForMainSrcAll.mainBookStations = 'All';
						this.mainSrcTA.push(this.agentDtoForMainSrcAll);
						for (let i = 0; i < this.agentDtoOptionsForMainSrc.length; i++) {
							this.mainSrcTA.push(this.agentDtoOptionsForMainSrc[i]);
						}
					}

				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Stock At Details", "info");
			},
			() => console.log('done');

	};

	searchMethod() {
		this.selectedSearchBy = $("#" + this.pageId + "searchBy").val();
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		if (this.selectedSearchBy == 'hireslipWise') {
			this.enteredHireslipNumber = $("#" + this.pageId + "hireslipNumber").val();
			if (this.enteredHireslipNumber == null || this.enteredHireslipNumber == '') {
				swal({
					title: "Warning",
					text: "Please Enter Hireslip Number",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});

			} else {
				this.searchMethodForHireslipNumberWsie();
			}

		} else if (this.selectedSearchBy == 'dateWise') {

			if (this.destView == true) {
				if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedFromDate == undefined || this.selectedToDate == null || this.selectedToDate == undefined || this.selectedToDate == '' || this.selectedDestination == null || this.selectedDestination == '' || this.selectedDestination == undefined) {
					swal({
						title: "Mandatory Field",
						text: "From Date , To Date , Destination Is Mandatory Fields",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					return false;
				} else {
					this.searchMethodForDateWise();
				}

			} else {
				if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedFromDate == undefined || this.selectedToDate == null || this.selectedToDate == '' || this.selectedToDate == undefined) {
					swal({
						title: "Mandatory Field",
						text: "From Date , To Date Is Mandatory Fields",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					return false;
				} else {
					this.searchMethodForDateWise();
				}

			}
		}
	}
	searchMethodForHireslipNumberWsie() {
		this.enteredHireslipNumber = $("#" + this.pageId + "hireslipNumber").val();
		this.hireslipDtoSearchHireslipWise = new HireSlipDto();
		this.hireslipDtoSearchHireslipWise.mode = 'Hireslipno';
		this.hireslipDtoSearchHireslipWise.hireslipnumber = this.enteredHireslipNumber;
		console.log(this.hireslipDtoSearchHireslipWise);
		this.searchMethodForHireslipNumberWsieDetails();
	}
	searchMethodForHireslipNumberWsieDetails = () => {
		this.showSpinnerForAction = true;
		this.hireslipService.getLorryHireBalanceReport(this.hireslipDtoSearchHireslipWise).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "lorryHireBalanceRptId").DataTable().destroy();
				this.lorryHireBalanceDataList = [];
				$("#" + this.pageId + "lorryHireBalancePaidRptId").DataTable().destroy();
				this.lorryHireBalancePaidDataList = [];
				this.dtTriggerLorryHireBalancePaid.next();
				this.searchByNumber = false;
				if (response.length == 1) {
					this.searchByNumber = true;
					this.lorryHireBalanceDataList = response;

				}
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No report available for this datas !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

					//    } else if((this.searchByNumber)  && (this.destView == false) &&( (this.lorryHireBalanceDataList[0].toStation != this.userDataDtoReturnSession.office)|| (this.lorryHireBalanceDataList[0].toStation != this.userDataDtoReturnSession.mainStation))){
				} else if ((this.searchByNumber) && (this.destView == false) && (this.lorryHireBalanceDataList[0].toStation != this.userDataDtoReturnSession.mainStation)) {
					swal({
						title: "Alert",
						text: "Not allowed to view !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					this.lorryHireBalanceDataList = [];
				} else {
					this.lorryHireBalanceDataList = response;
					console.log(this.lorryHireBalanceDataList);
				}
				this.dtTriggerLorryHireBalance.next();
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the lorry hireslip balance  Hire slip number View", "info");
			}, () => console.log('done');
	};


	searchMethodForDateWise() {
		this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
		this.selectedToDate = $("#" + this.pageId + "toDate").val();
		this.selectedDestination = $("#" + this.pageId + "destination").val();
		this.selectedSource = $("#" + this.pageId + "source").val();
		this.selectedPaymentType = $("#" + this.pageId + "paymentType").val();
		this.hireslipDtoSearchDateWise = new HireSlipDto();
		this.hireslipDtoSearchDateWise.companyid = this.userDataDtoReturnSession.companyId;
		this.hireslipDtoSearchDateWise.fromDate = this.selectedFromDate;
		this.hireslipDtoSearchDateWise.toDate = this.selectedToDate;

		// for mode
		if (this.selectedPaymentType == 'all') {
			this.hireslipDtoSearchDateWise.mode = 'ALL';
		} else if (this.selectedPaymentType == 'paid' || this.selectedPaymentType == 'unpaid') {
			this.hireslipDtoSearchDateWise.mode = 'Specific';
		}
		//For source
		if (this.selectedSource != null && this.selectedSource != '') {
			this.hireslipDtoSearchDateWise.mainStation = this.selectedSource;
		} else {
			this.hireslipDtoSearchDateWise.mainStation = null;
		}
		//To set Paid or Unpaid
		if (this.selectedPaymentType == 'paid') {
			this.hireslipDtoSearchDateWise.isLryAdvPaid = true;
		} else if (this.selectedPaymentType == 'unpaid') {
			this.hireslipDtoSearchDateWise.isLryAdvPaid = false;
		}
		//For Destination
		if (this.destView == true) {
			this.hireslipDtoSearchDateWise.toStation = this.selectedDestination;
		} else {
			this.hireslipDtoSearchDateWise.toStation = this.userDataDtoReturnSession.mainStation;
		}
		console.log(this.hireslipDtoSearchDateWise);
		this.searchMethodForDateWiseDetails();
	}
	searchMethodForDateWiseDetails = () => {
		this.showSpinnerForAction = true;
		this.hireslipService.getLorryHireBalanceReport(this.hireslipDtoSearchDateWise).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "lorryHireBalanceRptId").DataTable().destroy();
				this.lorryHireBalanceDataList = [];
				$("#" + this.pageId + "lorryHireBalancePaidRptId").DataTable().destroy();
				this.lorryHireBalancePaidDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.selectedPaymentType = $("#" + this.pageId + "paymentType").val();
					if (this.selectedPaymentType == 'all' || this.selectedPaymentType == 'unpaid') {
						this.lorryHireBalanceDataList = response;
						console.log(this.lorryHireBalanceDataList);
					} else {
						this.lorryHireBalancePaidDataList = response;
						console.log(this.lorryHireBalancePaidDataList);
					}

				}
				this.dtTriggerLorryHireBalance.next();
				this.dtTriggerLorryHireBalancePaid.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the lorry hireslip balance date View", "info");
			}, () => console.log('done');
	};

	//Confirm Btn Part Starts From here
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	getConfirmPaymnent(confirmPaymentPopUpTemplate, lorryHireBalanceData) {

		this.selectedRowHireslipNumber = '';
		this.selectedRowHireslipNumber = lorryHireBalanceData.hireslipnumber;
		this.hireslipDtoSelectedRow = new HireSlipDto();
		this.hireslipDtoSelectedRow = lorryHireBalanceData;
		console.log(this.hireslipDtoSelectedRow);
		console.log(this.selectedRowHireslipNumber);

		if (this.selectedRowHireslipNumber != null && this.selectedRowHireslipNumber != '') {
			if (this.hireslipDtoSelectedRow.hireSlipBalance == true) {
				swal({
					title: "Warning",
					text: "This Hireslip is Already Confirmed",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});

			} else {
				this.modalRefferenceConfirmPaymentPopUp = this.modalService.open(confirmPaymentPopUpTemplate,
					{ centered: true });
				this.modalRefferenceConfirmPaymentPopUp.result.then((result) => {
					this.modalRefferenceConfirmPaymentClosePopUp = `Closed with: ${result}`;
				}, reason => {
					this.modalRefferenceConfirmPaymentClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
				});
			}

		} else {
			swal({
				title: "Warning",
				text: "No Hireslip number!",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		}
	}

	confirmBtnPopUp(confirmPaymentPopUpTemplate) {
		this.selectedPaymentDate = $("#" + this.pageId + "paymentDate").val();
		if (this.selectedPaymentDate != null && this.selectedPaymentDate != '') {
			swal({
				title: "Confirm!",
				text: "Sure you want to confirm payment?",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((confirm) => {
				if (confirm) {
					this.confirmPaymentDetails();
				} else {

				}
			});


		} else {
			swal({
				title: "Warning",
				text: "Please Select Payment Date",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		}

	}

	closeBtnPopup() {
		this.modalRefferenceConfirmPaymentPopUp.close();
		this.selectedPaymentDate = '';
		$("#" + this.pageId + "paymentDate").val('');
		this.hireslipDtoConfirmPayment = new HireSlipDto();
		this.hireslipDtoConfirmPrintVoucher = new HireSlipDto();
		this.hireslipDtoUpdatePrintVoucher = new HireSlipDto();
	}

	confirmPaymentDetails() {
		this.hireslipDtoConfirmPayment = new HireSlipDto();
		this.selectedPaymentDate = $("#" + this.pageId + "paymentDate").val();
		this.hireslipDtoConfirmPayment.hireslipnumber = this.selectedRowHireslipNumber;
		this.hireslipDtoConfirmPayment.paymentDate = this.selectedPaymentDate;
		this.hireslipDtoConfirmPayment.lastupdatedby = this.userDataDtoReturnSession.userId;
		console.log(this.hireslipDtoConfirmPayment);
		this.setConfirmPaymentDetails();
	}

	setConfirmPaymentDetails = () => {

		this.hireslipService.updateHireBalance(this.hireslipDtoConfirmPayment).subscribe(
			(response) => {
				if (response.status == 'not found') {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else if (response.status == 'success') {
					swal({
						title: "Print Voucher",
						text: "Do You Want to Print the HireSilpBalance Payment Voucher for : " + this.selectedRowHireslipNumber,
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
						buttons: ["No", "Yes"],
					}).then((print) => {
						if (print) {
							this.confirmPrintVoucher();
						} else {

						}
					});

				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {

				swal("Error", "Server Problem Occurred While updating the confirm Payment", "info");
			}, () => console.log('done');
	};


	confirmPrintVoucher() {
		this.updateAfterPrintForPaymentBtn = false;
		this.hireslipDtoConfirmPrintVoucher = new HireSlipDto();
		this.hireslipDtoConfirmPrintVoucher.hireslipnumber = this.selectedRowHireslipNumber;
		console.log(this.hireslipDtoConfirmPrintVoucher);
		this.setConfirmPrintVoucherDetails();
	}

	setConfirmPrintVoucherDetails = () => {

		this.hireslipService.getHireSlipDetailsForVoucher(this.hireslipDtoConfirmPrintVoucher).subscribe(
			(response) => {
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.getDetailsForPrint = response;
					this.setPrintForVoucher = new HireSlipDto();
					this.setPrintForVoucher = this.getDetailsForPrint[0];
					this.setPringForVoucherDetails();
					console.log(this.getDetailsForPrint[0]);
					this.updateAfterPrintForPaymentBtn = true;
					if (this.updateAfterPrintForPaymentBtn == true) {
						this.updateHireslipDetailAfterPrint();
					}

				}


			}), (error) => {

				swal("Error", "Server Problem Occurred While Getting the hireslip details for print voucher", "info");
			}, () => console.log('done');
	};


	updateHireslipDetailAfterPrint() {
		this.hireslipDtoUpdatePrintVoucher = new HireSlipDto();
		this.hireslipDtoUpdatePrintVoucher.hireslipnumber = this.selectedRowHireslipNumber;
		console.log(this.hireslipDtoUpdatePrintVoucher);
		this.setUpdateHireslipDetailAfterPrint();
	}

	setUpdateHireslipDetailAfterPrint = () => {

		this.hireslipService.setHireSlipBalPrtVoucher(this.hireslipDtoUpdatePrintVoucher).subscribe(
			(response) => {
				if (response.status == 'not found') {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else if (response.status == 'success') {
					this.closeBtnPopup();
					this.searchMethod();
				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {

				swal("Error", "Server Problem Occurred While updating the hireslip details After print voucher", "info");
			}, () => console.log('done');
	};

	/// From here Voucher click starts 			 
	getVoucherPrint(lorryHireBalanceData) {
		this.updateforVoucher = false;
		this.selectedRowHireslipNumberForVoucher = '';
		this.selectedRowHireslipNumberForVoucher = lorryHireBalanceData.hireslipnumber;
		this.isBalancePrint = lorryHireBalanceData.balanceHsPrint;
		if (this.selectedRowHireslipNumberForVoucher != null && this.selectedRowHireslipNumberForVoucher != '') {
			if (this.isBalancePrint == true) {
				swal({
					title: "Warning!",
					text: "The Voucher For This Hire Slip Has Already Been Printed.Do You Want To Print It Again ",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
					buttons: ["No", "Yes"],
				}).then((print) => {
					if (print) {
						/// hireslip voucher Print  
						this.getInfoForPrintVoucher();
						this.updateforVoucher = false;
					} else {

					}
				});
			} else {
				swal({
					title: "Confirm!",
					text: "Sure you want to print lorry hire voucher",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
					buttons: ["No", "Yes"],
				}).then((print) => {
					if (print) {
						/// hireslip voucher Print 
						this.getInfoForPrintVoucher();
						this.updateforVoucher = true;

					} else {

					}
				});

			}

		}


	}
	getInfoForPrintVoucher() {
		this.hireslipDtoPaymentPrintVoucher = new HireSlipDto();
		this.hireslipDtoPaymentPrintVoucher.hireslipnumber = this.selectedRowHireslipNumberForVoucher;
		console.log(this.hireslipDtoPaymentPrintVoucher);
		this.getInfoForPrintVoucherDetails();
	}

	getInfoForPrintVoucherDetails = () => {

		this.hireslipService.getHireSlipDetailsForVoucher(this.hireslipDtoPaymentPrintVoucher).subscribe(
			(response) => {
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.getDetailsForPrint = response;
					this.setPrintForVoucher = new HireSlipDto();
					this.setPrintForVoucher = this.getDetailsForPrint[0];
					this.setPringForVoucherDetails();
					console.log(this.getDetailsForPrint[0]);

				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {

				swal("Error", "Server Problem Occurred While Getting the hireslip details for print voucher", "info");
			}, () => console.log('done');
	};

	updateHireslipDetailAfterPrintForPrintBtn() {
		this.hireslipDtoUpdatePrintVoucherFrmPrintBtn = new HireSlipDto();
		this.hireslipDtoUpdatePrintVoucherFrmPrintBtn.hireslipnumber = this.selectedRowHireslipNumberForVoucher;
		console.log(this.hireslipDtoUpdatePrintVoucherFrmPrintBtn);
		this.setupdateHireslipDetailAfterPrintForPrintBtn();
	}

	setupdateHireslipDetailAfterPrintForPrintBtn = () => {

		this.hireslipService.setHireSlipBalPrtVoucher(this.hireslipDtoUpdatePrintVoucherFrmPrintBtn).subscribe(
			(response) => {
				if (response.status == 'not found') {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else if (response.status == 'success') {

				}

				this.changeDetectorRef.detectChanges();
			}), (error) => {

				swal("Error", "Server Problem Occurred While updating the hireslip details After print voucher", "info");
			}, () => console.log('done');
	};


	setPringForVoucherDetails() {
		localStorage.clear();
		localStorage.setItem('lorryHireBalanceVoucherPrint', JSON.stringify(this.setPrintForVoucher));
		this.viewVoucher = true;
		window.addEventListener('afterprint', (onclick) => {
			if (this.viewVoucher) {
				this.viewVoucher = false;
				this.setPrintForVoucher = new HireSlipDto();
				localStorage.clear();
				if (this.updateforVoucher == true) {
					this.updateHireslipDetailAfterPrintForPrintBtn();
					this.updateforVoucher = false;
				}
				if (this.updateAfterPrintForPaymentBtn == true) {
					this.updateHireslipDetailAfterPrint();
					this.updateAfterPrintForPaymentBtn = false;
				}
			}
		});
	}

	clearMethod() {
		this.showSpinnerForAction = false;
		this.dateWise = false;
		this.modelForMainSrc = '';
		this.hireslipWise = true;
		$("#" + this.pageId + "searchBy").val('hireslipWise');
		$("#" + this.pageId + "source").val('');
		$("#" + this.pageId + "fromDate").val('');
		$("#" + this.pageId + "toDate").val('');
		$("#" + this.pageId + "paymentType").val('All');
		$("#" + this.pageId + "hireslipNumber").val('');
		this.viewDestination = false;
		this.modelDestination = '';
		this.fromDateModel = null;
		this.toDateModel = null;
		this.clearTable();
		this.selectedSource = '';
		this.selectedSearchBy = '';
		this.selectedFromDate = '';
		this.selectedToDate = '';
		this.enteredHireslipNumber = '';
		this.hireslipDtoSearchHireslipWise = new HireSlipDto();
		this.hireslipDtoSearchDateWise = new HireSlipDto();
		this.searchByNumber = false;
		this.selectedPaymentType = '';
		this.selectedPaymentDate = '';
		this.enteredPaymentDate = '';
		this.selectedRowHireslipNumber = '';
		this.hireslipDtoSelectedRow = new HireSlipDto();
		this.hireslipDtoConfirmPayment = new HireSlipDto();
		this.hireslipDtoConfirmPrintVoucher = new HireSlipDto();
		this.hireslipDtoUpdatePrintVoucher = new HireSlipDto();
		this.hireslipDtoUpdatePrintVoucherFrmPrintBtn = new HireSlipDto();
		this.hireslipDtoPaymentPrintVoucher = new HireSlipDto();
		this.getDetailsForPrint = '';
		this.setPrintForVoucher = new HireSlipDto();
		this.selectedRowHireslipNumberForVoucher = '';
		this.isBalancePrint = false;
		this.viewVoucher = false;
		this.updateforVoucher = false;
		this.updateAfterPrintForPaymentBtn = false;
		if (this.destView == true) {
			this.viewDestination = true;
			$("#" + this.pageId + "destination").val('');
		} else {
			this.viewDestination = false;
		}


	}

	customPrintLorryHireBalRpt() {
		if (this.lorryHireBalanceDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];

			//
			// this.cashMemoDtoForCustomPrintListColumnNames = ["Hireslip No", "Track No", "Supplier Name", "Driver", "Hire", "Adv", "Bal", "Dep At", "Arr At", "Payment Dt"];
			// this.cashMemoDtoForCustomPrintListColumnWidths = [14, 10, 14, 10, 8, 7, 8, 10, 10, 9];

			//
			if (this.showHireColumns) {
				this.cashMemoDtoForCustomPrintListColumnNames = ["Hireslip No", "Track No", "Supplier Name", "Driver", "Hire", "Adv", "Bal", "Dep At", "Arr At", "Payment Dt"];
				this.cashMemoDtoForCustomPrintListColumnWidths = [14, 10, 14, 10, 8, 7, 8, 10, 10, 9];

			} else {
				this.cashMemoDtoForCustomPrintListColumnNames = ["Hireslip No", "Track No", "Supplier Name", "Driver", "Bal", "Dep At", "Arr At", "Payment Dt"];
				this.cashMemoDtoForCustomPrintListColumnWidths = [18, 10, 20, 10, 8, 13, 12, 9];
			}
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			//heading logics For Date
			this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
			this.selectedToDate = $("#" + this.pageId + "toDate").val();
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}

			this.totHireSummary = this.advSummary = this.balSummary = 0;

			for (let i = 0; i < this.lorryHireBalanceDataList.length; i++) {
				//Chg_V1
				// this.cashMemoDtoForCustomPrintListColumnValues = [this.lorryHireBalanceDataList[i].hireslipnumber, this.lorryHireBalanceDataList[i].vehicleNumber,
				// this.lorryHireBalanceDataList[i].supplierName, this.lorryHireBalanceDataList[i].drivername, this.lorryHireBalanceDataList[i].totalhire,
				// this.lorryHireBalanceDataList[i].advance, this.lorryHireBalanceDataList[i].balance, (this.lorryHireBalanceDataList[i].depatureDtStr + " " +
				// 	this.lorryHireBalanceDataList[i].departureTime), (this.lorryHireBalanceDataList[i].arrivalDtStr + " " + this.lorryHireBalanceDataList[i].arrivalTime),
				// this.lorryHireBalanceDataList[i].invoiceDtStr];

				//Chg_V1
				if (this.showHireColumns) {
					this.cashMemoDtoForCustomPrintListColumnValues = [this.lorryHireBalanceDataList[i].hireslipnumber, this.lorryHireBalanceDataList[i].vehicleNumber,
					this.lorryHireBalanceDataList[i].supplierName, this.lorryHireBalanceDataList[i].drivername, this.lorryHireBalanceDataList[i].totalhire,
					this.lorryHireBalanceDataList[i].advance, this.lorryHireBalanceDataList[i].balance, (this.lorryHireBalanceDataList[i].depatureDtStr + " " +
						this.lorryHireBalanceDataList[i].departureTime), (this.lorryHireBalanceDataList[i].arrivalDtStr + " " + this.lorryHireBalanceDataList[i].arrivalTime),
					this.lorryHireBalanceDataList[i].invoiceDtStr];
				} else {
					this.cashMemoDtoForCustomPrintListColumnValues = [this.lorryHireBalanceDataList[i].hireslipnumber, this.lorryHireBalanceDataList[i].vehicleNumber,
					this.lorryHireBalanceDataList[i].supplierName, this.lorryHireBalanceDataList[i].drivername, this.lorryHireBalanceDataList[i].balance, (this.lorryHireBalanceDataList[i].depatureDtStr + " " +
						this.lorryHireBalanceDataList[i].departureTime), (this.lorryHireBalanceDataList[i].arrivalDtStr + " " + this.lorryHireBalanceDataList[i].arrivalTime),
					this.lorryHireBalanceDataList[i].invoiceDtStr];
				}
				/////////
				this.totHireSummary = this.totHireSummary + this.lorryHireBalanceDataList[i].totalhire;
				this.advSummary = this.advSummary + this.lorryHireBalanceDataList[i].advance;
				this.balSummary = this.balSummary + this.lorryHireBalanceDataList[i].balance;
				/////
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

			}
			//Chg_V1
			// this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lorryHireBalanceDataList.length, "", "", "", this.totHireSummary, this.advSummary, this.balSummary, "", "", ""];

			//Chg_V1
			if (this.showHireColumns) {
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lorryHireBalanceDataList.length, "", "", "", this.totHireSummary, this.advSummary, this.balSummary, "", "", ""];
			} else {
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lorryHireBalanceDataList.length, "", "", "", this.balSummary, "", "", ""];
			}
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
				this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printTitle', "Lorry Hire Balance Report");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}

	customPrintPaidLorryHireBalRpt() {
		if (this.lorryHireBalancePaidDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];

			//Chg_V1
			// this.cashMemoDtoForCustomPrintListColumnNames = ["Hireslip No", "Track No", "Supplier Name", "Driver", "Hire", "Adv", "Bal", "Dep At", "Arr At", "Payment Dt"];
			// this.cashMemoDtoForCustomPrintListColumnWidths = [14, 10, 14, 10, 8, 7, 8, 10, 10, 9];

			//Chg_V1
			if (this.showHireColumns) {
				this.cashMemoDtoForCustomPrintListColumnNames = ["Hireslip No", "Track No", "Supplier Name", "Driver", "Hire", "Adv", "Bal", "Dep At", "Arr At", "Payment Dt"];
				this.cashMemoDtoForCustomPrintListColumnWidths = [14, 10, 14, 10, 8, 7, 8, 10, 10, 9];

			} else {
				this.cashMemoDtoForCustomPrintListColumnNames = ["Hireslip No", "Track No", "Supplier Name", "Driver", "Bal", "Dep At", "Arr At", "Payment Dt"];
				this.cashMemoDtoForCustomPrintListColumnWidths = [18, 10, 20, 10, 8, 13, 12, 9];
			}

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			//heading logics For Date
			this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
			this.selectedToDate = $("#" + this.pageId + "toDate").val();
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}

			this.totHireSummary = this.advSummary = this.balSummary = 0;
			for (let i = 0; i < this.lorryHireBalancePaidDataList.length; i++) {
				//Chg_V1
				// this.cashMemoDtoForCustomPrintListColumnValues = [this.lorryHireBalancePaidDataList[i].hireslipnumber, this.lorryHireBalancePaidDataList[i].vehicleNumber,
				// this.lorryHireBalancePaidDataList[i].supplierName, this.lorryHireBalancePaidDataList[i].drivername, this.lorryHireBalancePaidDataList[i].totalhire,
				// this.lorryHireBalancePaidDataList[i].advance, this.lorryHireBalancePaidDataList[i].balance, (this.lorryHireBalancePaidDataList[i].depatureDtStr + " " +
				// 	this.lorryHireBalancePaidDataList[i].departureTime), (this.lorryHireBalancePaidDataList[i].arrivalDtStr + " " + this.lorryHireBalancePaidDataList[i].arrivalTime),
				// this.lorryHireBalancePaidDataList[i].invoiceDtStr];

				//Chg_V1
				if (this.showHireColumns) {
					this.cashMemoDtoForCustomPrintListColumnValues = [this.lorryHireBalancePaidDataList[i].hireslipnumber, this.lorryHireBalancePaidDataList[i].vehicleNumber,
					this.lorryHireBalancePaidDataList[i].supplierName, this.lorryHireBalancePaidDataList[i].drivername, this.lorryHireBalancePaidDataList[i].totalhire,
					this.lorryHireBalancePaidDataList[i].advance, this.lorryHireBalancePaidDataList[i].balance, (this.lorryHireBalancePaidDataList[i].depatureDtStr + " " +
						this.lorryHireBalancePaidDataList[i].departureTime), (this.lorryHireBalancePaidDataList[i].arrivalDtStr + " " + this.lorryHireBalancePaidDataList[i].arrivalTime),
					this.lorryHireBalancePaidDataList[i].invoiceDtStr];
				} else {
					this.cashMemoDtoForCustomPrintListColumnValues = [this.lorryHireBalancePaidDataList[i].hireslipnumber, this.lorryHireBalancePaidDataList[i].vehicleNumber,
					this.lorryHireBalancePaidDataList[i].supplierName, this.lorryHireBalancePaidDataList[i].drivername, this.lorryHireBalancePaidDataList[i].balance, (this.lorryHireBalancePaidDataList[i].depatureDtStr + " " +
						this.lorryHireBalancePaidDataList[i].departureTime), (this.lorryHireBalancePaidDataList[i].arrivalDtStr + " " + this.lorryHireBalancePaidDataList[i].arrivalTime),
					this.lorryHireBalancePaidDataList[i].invoiceDtStr];
				}
				/////////
				this.totHireSummary = this.totHireSummary + this.lorryHireBalancePaidDataList[i].totalhire;
				this.advSummary = this.advSummary + this.lorryHireBalancePaidDataList[i].advance;
				this.balSummary = this.balSummary + this.lorryHireBalancePaidDataList[i].balance;
				/////
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

			}
			//Chg_V1
			// this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lorryHireBalancePaidDataList.length, "", "", "", this.totHireSummary, this.advSummary, this.balSummary, "", "", ""];

			//Chg_V1
			if (this.showHireColumns) {
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lorryHireBalancePaidDataList.length, "", "", "", this.totHireSummary, this.advSummary, this.balSummary, "", "", ""];
			} else {
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lorryHireBalancePaidDataList.length, "", "", "", this.balSummary, "", "", ""];
			}

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
				this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printTitle', "Lorry Hire Balance Report");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});

		}
	}
}

