import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgModule, ChangeDetectorRef } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends
//for datepicker starts
const my = new Date();
//for datepicker ends


import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";


@Component( {
    selector: 'app-labour-commission-setup-mumbai',
    templateUrl: './labour-commission-setup-mumbai.component.html',
    styleUrls: ['./labour-commission-setup-mumbai.component.css']
} )
export class LabourCommissionSetupMumbaiComponent implements OnInit {

    getDataFrmServiceFrTable: any;
    labourCommissionDataList: any;
    onDestroyUnsubscribtionLabourCommission: Subscription;
    model: NgbDateStruct;
    date: { year: number; month: number };

    loadingIndicator = true;
    reorderable = true;
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLabourCommission: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLabourCommission: any;
    pageId="lcsmc;"


    constructor( private labourCommissionRpt: ReportService, public changeDetectorRef : ChangeDetectorRef ) {


    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }







    ngOnInit(): void {


        this.dtOptionsLabourCommission = {
            //};
            //columns is used to for export and others starts



            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //              {
                //                  extend: 'excel',
                //                  text: '<i class="fas fa-file-excel"> Excel</i>',
                //                  titleAttr: 'Excel',
                //                  exportOptions: {
                //                      columns: ':visible'
                //                  }
                //              },
                //              {
                //                  extend: 'print',
                //                  text: '<i class="fas fa-print"> Print</i>',
                //                  titleAttr: 'Print',
                //
                //              }


            ],

            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

        }

        this.getDataFrmServiceFrTable = this.labourCommissionRpt.getSummaryData()
        this.onDestroyUnsubscribtionLabourCommission = this.getDataFrmServiceFrTable.subscribe( data => {
            this.labourCommissionDataList = data['data'];
            this.dtTriggerLabourCommission.next();
        } );



        //the below code is for the getting data through json ends

    }

    ngOnDestroy(): void {
        this.dtTriggerLabourCommission.unsubscribe();

        this.onDestroyUnsubscribtionLabourCommission.unsubscribe();
    }


}
