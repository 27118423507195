<div class="row">
	<div class="col-md-12">
		<div class="card">
			<div class="card-body">

				<div class="row">
					<div class="box-body">
						<table datatable class="table table-striped table-bordered row-border hover"
							[dtOptions]="dtOptionsLrDetails" [dtTrigger]="dtTriggerLrDetails">

							<thead>
								<tr>
									<th>Sys Lgt</th>
									<th>Sys Bdth</th>
									<th>Sys Hgt</th>
									<th>Sys Vol</th>
									<th>Sys Size Tot</th>

									<th>Dws Lgt</th>
									<th>Dws Bdth</th>
									<th>Dws Hgt</th>
									<th>Dws Vol</th>
									<th>Dws Size Tot</th>
								</tr>
							</thead>
							<tbody>
								<tr
									*ngFor="let articlesData of articlesDataList;let articlesDataDWS of articlesDataListDWS ">
									<td>{{ articlesData.length }}</td>
									<td>{{ articlesData.breadth }}</td>
									<td>{{ articlesData.height }}</td>
									<td>{{ articlesData.dwsVolumeCft }}</td>
									<td>{{ articlesData.dwsSizeTot }}</td>

									<td>{{ articlesDataDWS.length }}</td>
									<td>{{ articlesDataDWS.breadth }}</td>
									<td>{{ articlesDataDWS.height }}</td>
									<td>{{ articlesDataDWS.dwsVolumeCft }}</td>
									<td>{{ articlesDataDWS.dwsSizeTot }}</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</tfoot>
						</table>

					</div>
				</div>

			</div>
		</div>
	</div>
</div>