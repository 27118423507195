//ChgV1_Imran = Changed path of rewarding screen for new update in rewar flow By Imran On 27062024

import { ElementSchemaRegistry } from '@angular/compiler';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Route, Router, RoutesRecognized } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CommodityBookingBlockComponent } from './master/commodity/commodity-booking-block/commodity-booking-block.component';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
    title = 'app';
    lastRedirect = '';
    public tabs: Tab[] = [];
    public routes: Route[] = [];
    public currentHoverTabKey: string;
    public currentDraggedTab: Tab;
    // showSpinnerForAction = true;
    menuTitle = {
        Dashboard1Component: "Dashboard",
        BookingCashmemoComponent: "Booking Cash Memo",
        GenerateCashmemoComponent: "Generate Cash Memo",
        DummyCashmemoComponent: "Dummy",
        BillingToSrdCollectionStockComponent: "Billing To SRD Collection Stock",
        TransferToCollectionComponent: "Transfer To Collection",
        CashmemoTransferComponent: "Cashmemo Transfer",
        ManualCashmemoCheckComponent: "Manual Cashmemo Check",
        CollectionManCashmemoStockCheckComponent: "Collection Man Cashmemo Stock Check",
        CollectionManStockTransferComponent: "Collection Man Stock Transfer",
        CollectionManToCollectionManStockTransferComponent: "Collection Man To Collection Man Stock Transfer",
        CashmemoBlockComponent: "Cash Memo Block",
        PaymentFollowupComponent: "Payment Followup",
        CommissionMasterComponent: "Commission Master",
        CommodityRateMasterComponent: "Commodity Rate Master",
        DestinationAgentStatementGenerationComponent: "Destination Agent Statement Generation",
        AmountReceivableFromAgentComponent: "Amount Receivable From Agent",
        AgentCommissionMasterComponent: "Agent Commision Master",
        AgentStatementGenerationComponent: "Agent Statement Generation",
        SrdLabourMasterComponent: "SRD Labour Master",
        SrdLabourStatementGenerationComponent: "SRD Labour Stmt Generation",
        PartyLessCheckUploadTypeComponent: "Party Less Check - Upload Type",
        BalancingDifferenceTallyReportComponent: "Balancing Difference Tally Report",
        LrEditComponent: "LR Edit",
        LrsShortAndExtraComponent: "LRs Short & Extra",
        LrEnquiryFormComponent: "LR Enquiry Form",
        LrAdvanceComponent: "LR Advance",
        LrEntryFormComponent: "LR Entry Form",
        MissAgentsComponent: "Miss Agent",
        ChallanComponent: "Challan",
        LrIssueComponent: "LR Issue",
        CommodityMergeComponent: "Commodity Merge",
        SendSmsGroupDetailsComponent: "Send SMS Group Details",
        MessageMemoComponent: "Message On Cashmemo",
        EditCompanyComponent: "Edit Company",
        CommonChargesComponent: "Common Charges",
        ExpensesDetailsEntryComponent: "Expenses Details Entry",
        ConsigneeMasterComponent: "Consignee Master",
        ConsigneeAdminRateMasterComponent: "C/nee Admin Rate Master",
        ConsigneeMergeComponent: "Consignee Merge",
        ConsignorMasterComponent: "Consignor Master",
        BankComponent: "Bank",
        CollectionComponent: "Collection",
        FovExemptionMasterComponent: "FOV Exemption Master for Booking",
        SupplierMasterComponent: "Supplier Master",
        DriverMasterComponent: "Driver Master",
        TruckMasterComponent: "Truck Master",
        LorryHireDeductionSetupComponent: "Lorry Hire Deduction Setup",
        NatureOfPackComponent: "Nature Of Pack",
        ExpensesPaymentFollowupReportComponent: "Expenses Payment Followup",
        TrackingLiveReportComponent: "Tracking Report",
        PointToPointServiceCheckComponent: "Point To Point Service Check",
        AgentTempoMasterComponent: "Agent Tempo Master",
        TimeComponent: "Reward Time Setup",
        TripEtaComponent: "Trip ETA Setup",
        AboutUsComponent: "About Us",
        LatestNewsComponent: "Latest News",
        OurBranchesComponent: "Our Branches",
        RouteMappingComponent: "Route Mapping",
        StatementVerificationComponent: "Statement Verification",
        RankingComponent: "Ranking",
        BookingAgentReportComponent: "Booking Agent Rpt",
        AgentExpensesComponent: "Agent Expenses",
        OfficeHireslipComponent: "Office Hireslip",
        AgentStatementComponent: "Agent Statement",
        TdsLoadingComponent: "Agent TDS On Loading",
        AgentLocalTripComponent: "Agent Local Trip",
        AmountReceivableComponent: "Amount Receivable",
        PaidReportComponent: "Paid Report",
        CashMemoReportComponent: "Cash Memo Report",
        BookingCashmemoByInvoiceComponent: "Booking Cashmemo By Invoice",
        CashmemoDetailsComponent: "Cashmemo Details",
        LetterHeadReportComponent: "LetterHead Report",
        CollectionManReportComponent: "Collection Man",
        SrdCollectionStockReportComponent: "SRD Collection Stock Report",
        MemoLessSearchReportComponent: "Memo Less Details",
        CashMemoInCollectionManStockComponent: "Cash Memo In Collection Man Stock",
        TotalLessComponent: "Total Less Report",
        DailyBookingReportComponent: "Daily Booking",
        OfficeReportComponent: "Office Report",
        BangaloreOfficeComponent: "Bangalore Office Daily Report",
        DebitNoteReportComponent: "Debit Note Rpt",
        BrokerReportComponent: "Broker Report",
        LabourStatementReportComponent: "Labour Statement Report",
        HireslipDetailsComponent: "Hireslip Details",
        InvoiceDetailsComponent: "Invoice Details",
        AdvancePaymentComponent: "Advance Payment",
        HireslipUnloadingReportComponent: "Hireslip Unloading Report",
        TranshipmentTripComponent: "Transshipment Trip",
        LocalTripSheetComponent: "Local Trip Sheet",
        ServiceTaxReportComponent: "Service Tax",
        IssueBalanceComponent: "Issue Balance",
        LrIssueReportComponent: "LR Issue Rpt",
        ChallanIssueComponent: "Challan Issue",
        ShortAndExtraComponent: "Short And Extra",
        LrDispatchBookingReportComponent: "LR Dispatch Booking Rpt",
        AutomatedSearchComponent: "Automated Search",
        LrHistoryReportComponent: "LR History Rpt",
        LorryHireStatementComponent: "Lorry Hire Statement",
        LorryHireBalanceComponent: "Lorry Hire Balance",
        LrPerformanceReportComponent: "Performance",
        LrRptComponent: "LR Report",
        GcNotReceivedReportComponent: "G.C. Not Received",
        EWayBillDateValidationExtensionRptComponent: "EWay Bill Date Validation Extension Report",
        LcAndBcReportComponent: "LC and BC Report",
        LoaderPerformanceReportComponent: "Loader Performance Report",
        VehicleTrackingComponent: "Live Tracking",
        ChequeSearchReportComponent: "Cheque Search Details",
        ChequeDetailsReportComponent: "Cheque Details",
        LocalChallanReportComponent: "Local Challan Report",
        ConsignorReportComponent: "Consignor",
        ConsigneeReportComponent: "Consignee Report",
        BusinessStopReportComponent: "Business Stop Report",
        BusinessStartReportComponent: "Business Start Report",
        PartyLessReportComponent: "Party Less",
        PartyWiseOsReportComponent: "Party Wise OS",
        PartyWiseTotalStatusDetailsComponent: "Party Wise Total Status Details",
        CommodityMismatchReportComponent: "Commodity Mismatch Report",
        ConsigneeAdminRateReportComponent: "C/nee Admin Rate Rpt",
        RateHistoryReportComponent: "Rate History",
        AdminBusinessRateReportComponent: "Admin Business Rate Report",
        ConsigneeRateReportComponent: "Rate Report",
        DiscontinuousDetailsReportComponent: "Discontinuous Details",
        StatementRptComponent: "Statement Report",
        PartyLessCheckedAndUncheckedLrDetailsComponent: "Party Less Checked And Uncheck LR Details",
        StocksInTakenReportComponent: "Stocks In Taken Rpt",
        CommodityReportComponent: "Commodity Report",
        GodownStocksBookingReportComponent: "Godown Stocks Booking",
        GodownStocksReportComponent: "Godown Stocks",
        PendingStocksDetailsReportComponent: "Pending Details",
        StocksUnloadingReportComponent: "Stocks Unloading Report",
        StorageReportComponent: "Storage Report",
        BookingAgentDispatchPerformanceReportComponent: "Booking Agents Dispatch Performance Report",
        PerformanceReportComponent: "Performance Report",
        TruckReportComponent: "Truck Report",
        TruckOwnerDeclarationReportComponent: "Truck Owner Declaration Report",
        HaltAndDispatchedDetailsComponent: "Halt And Dispatched Details",
        NonAvailabilityOfTruckReportComponent: "Non Availability Of Truck",
        ManualStockCheckComponent: "Manual Stock Check",
        RewardingComponent: "Rewarding",
        HireslipBalanceComponent: "HireSlip Balance",
        ShipmentStatusComponent: "Shipment Status",
        StocksToCpComponent: "Stocks to CP",
        StocksBetweenGodownComponent: "Stocks Between Godowns",
        StocksForLocalBookingComponent: "Stocks For Local Booking",
        StocksForTripsheetComponent: "Stocks For Tripsheet",
        StocksGroupingComponent: "Stocks Grouping",
        PartyLessCheckComponent: "Party Less Details",
        TempoPerformanceReportComponent: "Tempo Performance Report",
        LrShortAndExtraConsolidateRptComponent: "Short & Extra Consolidate",
        AgentStockReportComponent: "Agent Stock Summary",
        InvoiceReportComponent: "Invoice Report",
        BookingAgentStatementDetailsComponent: "Booking Agent Statement Details",
        LrDwsWeightReportComponent: "LR DWS Weight Report",
        StocksForLocalBookingBarcodeComponent: "Booking Local Trip Barcode",
        DebitNoteStmtComponent: "Debit Note Stmt",
        LrEntryFormBarcodePrintComponent: "Barcode Generate",
        LabourHamaliStatementDetailsComponent: "Labour Hamali Statement Details",
        RateMasterComponent: "Rate Master",
        SendSmsComponent: "Send SMS",
        ConsignorAdminRateMasterComponent: "Consignor & LR Entry Rate Master",
        SrcLrRatecheckRptComponent: "Source LR Entry Rate Check Report",
        ConsignorMergeComponent: "Consignor Merge",
        CashmemoPodUploadComponent: "Memo POD Upload",
        VehicleSetupMasterComponent: "Vehicle Master Local",
        VehicleAreaChargeSetupComponent: "Vehicle Area Driver Charge Setup",
        CflFormulaSetupComponent: "CFL Formula Setup",
        StaffMasterComponent: "Loader Name Master",
        InchargeMasterComponent: "Loading Incharge Master",
        LoaderMasterComponent: "Loader Head Master",
        CftExemptionMasterComponent: "CFT Exemption Setup",
        CftRangeReportComponent: "CFT Range Report",
        PaymentFollowupBookingComponent: "Booking Payment Followup",
        ConsignorMasterTopartyComponent: "Consignor Toparty Master",
        BookingPaidCollectionReportComponent: "Paid Collection Report",
        BookingPartyOsReportComponent: "Booking Party OS Report",
        HirepaymentEnetBankReportComponent: "E-Net Lorry Hire Payment",
        LrEntryEnableConsigneecopyComponent: "Enable CC Copy In LR Entry",
        SpecificConsigneeDetailsComponent: "Specific Consignee Details",
        TdsDetailsEntryComponent: "Tds Details Entry",
        DestinationCommissionComponent: "Destination Commission",
        DestinationIncomeComponent: " Destination Income",
        LrHoldReasonComponent: "LR Hold Reason Entry",
        InvoiceLrEditComponent: "Invoice Lr Edit",
        CommodityMasterComponent: "Commodity Master",
        AreaComponent: "Area",
        ConsigneeMobilenumberConfirmComponent: "Consignee Mobile Number Confirm",
        ConsignorMobilenumberConfirmComponent: "Consignor Mobile Number Confirm",
        LrdeleteMasterpwdSetupComponent: "LR Delete Master Password Setup",
        InvoiceLoadingSheetFlowComponent: "Invoice Loading Sheet",
        BookingTripDetailsComponent: "Trips Of Agent Booking Details",
        GstSearchComponent: "GST Search",
        LrBookingDateCustomChangeComponent: "LR Booking Date - Custom Change",
        LoadingInvoiceEditComponent: "Loading Invoice Edit",
        AgentSubstationMasterComponent: "Agent Substation Master",
        GenerateNewEWBComponent: "Generate New EWB",
        EditLrFreightComponent: "Edit LR Freight",
        UserCreationComponent: "User Creation",
        UserFeaturesCustomizationComponent: "User Features Customization",
        RewardingNewVersionComponent: "Rewarding",
        PartywisePerformanceMonitorComponent: "Partywise Performance Monitor",
        PerKgRateSetupComponent: "Per Kg Rate Setup",
        CommodityKgLimitSetupComponent: "Commodity Kg Limit Setup",
        PartwiseCFTDetailsReportComponent: "Partywise CFT Details Report",
        CommodityBookingBlockComponent: "Commodity Booking Block",

    }
    options = {
        theme: 'light', // two possible values: light, dark
        dir: 'ltr', // two possible values: ltr, rtl
        layout: 'horizontal', // fixed value. shouldn't be changed.
        sidebartype: 'full', // fixed value. shouldn't be changed.
        sidebarpos: 'absolute', // two possible values: fixed, absolute
        headerpos: 'absolute', // two possible values: fixed, absolute
        boxed: 'boxed', // two possible values: full, boxed
        navbarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
        sidebarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
        logobg: 'skin1' // six possible values: skin(1/2/3/4/5/6)
    };
    constructor(
        public activatedRoute: ActivatedRoute,
        public router: Router,
        public cd: ChangeDetectorRef
    ) {
        // listen to routing change event to attach new tabs or activate a new one
        this.router.navigateByUrl("authentication/login");
        // this.showSpinnerForAction=true;
        router.events.subscribe(val => {
            if (val instanceof RoutesRecognized) {
                this.checkAndAddRouteTab(val);
            }
        });

    }

    checkAndAddRouteTab(val: RoutesRecognized) {
        const comp = val.state.root.firstChild.component;
        this.deactivateTabs();
        const url = val.url;
        const urlAfterRedirects = val.urlAfterRedirects;
        const path = val.state.root.firstChild.routeConfig.path;
        console.log(url, urlAfterRedirects, path);
        // this.showSpinnerForAction=false;
        if (path !== "authentication" && url !== '/' && urlAfterRedirects !== '/authentication/login') {
            //if (this.tabs.find(tab => tab.name == comp["name"]) == null) {
            if (this.tabs.find(tab => tab.name == this.menuTitle[comp["name"]]) == null) {
                this.tabs.push({
                    name: this.menuTitle[comp["name"]],
                    // name: comp["name"],
                    component: comp,
                    //      key: comp["name"],
                    key: this.menuTitle[comp["name"]],
                    active: true,
                    route: val.state.root.firstChild.routeConfig,
                    count: new BehaviorSubject<number>(0)
                });
            } else {
                //const tabToActivate = this.tabs.find(tab => tab.name == comp["name"]);
                const tabToActivate = this.tabs.find(tab => tab.name == this.menuTitle[comp["name"]]);
                if (tabToActivate) {
                    tabToActivate.active = true;
                }
            }
            this.lastRedirect = urlAfterRedirects;
        } else {
            if (urlAfterRedirects !== '/authentication/404')
                this.tabs = [];
            else if (this.lastRedirect !== null && this.lastRedirect !== '') {
                const tabToActivate = this.tabs.find(tab => tab.route.path == this.lastRedirect.substring(1));
                console.log(tabToActivate);
                if (tabToActivate) {
                    tabToActivate.active = true;
                    this.router.navigateByUrl(this.lastRedirect);
                }

            }
        }
        this.cd.markForCheck();
    }

    deactivateTabs() {
        this.tabs.forEach(tab => (tab.active = false));
    }

    disposeTab(tab: Tab) {
        if (this.tabs.length > 1) {
            this.tabs = this.tabs.filter(item => item.key !== tab.key);

            if (tab.active) {
                this.deactivateTabs();
                this.router.navigateByUrl(this.tabs[this.tabs.length - 1].route.path);
            }
        }
    }

    mouseOverTab(tab: Tab) {
        this.currentHoverTabKey = tab ? tab.key : null;
    }

}

export interface Tab {
    name: string;
    component: any;
    active: boolean;
    route: Route;
    key: string;
    count: BehaviorSubject<number>;
}
