

<!-- <div *ngIf=" router.url == '/'">
    <div class="preloader" >
        <div class="spinner">
    
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
    
        </div>
    </div>
</div> -->
<!-- <div *ngIf="router.url !== '/authentication/login' && router.url !== '/'">
<div class="preloader" *ngIf="showSpinnerForAction">
    <div class="spinner">

        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>

    </div>
</div>
</div> -->
<app-full-layout *ngIf="router.url !== '/authentication/login' && router.url !== '/'">
   
</app-full-layout>
<router-outlet *ngIf="router.url == '/authentication/login' || router.url == '/' ">
    <app-spinner></app-spinner>
</router-outlet>


<!-- Row -->
<div class="row system_responsive">
    <div class="col-lg-12" id="mt_col-12">

        <div class="card">
            <div class="card-body">
                <div class='row' id="tab_custome">
                    <div class="width_Class" *ngIf="router.url != '/authentication/login' && router.url != '/'">
                        <div class="tab bg-white border-bottom">
                            <ul class="nav nav-tabs bg-light mt-3 tab_scroll">
                                <li class="nav-item tab_css" *ngFor="let tab of tabs; let first = first;" (mouseleave)="mouseOverTab(null)" (mouseenter)="mouseOverTab(tab)">
                                    <div class="nav-link" [class.active]="tab.active">
                                        <div class="row cursor-pointer">
                                            <span class="badge badge-danger my-auto mr-2" *ngIf="(tab.count | async) != 0">{{ tab.count |
                                                    async}}</span>
                                            <div class="" [routerLink]="tab.route.path">{{ tab.name }}</div>
                                            <div class="transition-all-ease-250ms" [style.width]="currentHoverTabKey == tab.key ? 'auto': '0px'" [class.pl-3]="currentHoverTabKey == tab.key" [class.opacity-0]="currentHoverTabKey != tab.key" [class.invisible]="currentHoverTabKey != tab.key" *ngIf="tabs.length > 1"
                                                (click)="disposeTab(tab)">X</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div class="tab-content border-left border-right" style="padding: 5px!important;">
                                <ng-container *ngFor="let tab of tabs">
                                    <div class="tab-pane fade {{tab.name}}" [class.show]="tab.active" [class.active]="tab.active" [id]="tab.name" role="tabpanel">
                                        <ng-container *ngComponentOutlet="tab.component"></ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>