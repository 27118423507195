<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">Debit Note Master</h6>
			</div>
			<form>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<!-- 										<div class="col-sm-12 col-md-12"> -->
											<!-- 											<div class="form-group"> -->
											<!-- 												<div class="input-group"> -->
											<!-- 													<label>Main Heads</label> -->
											<!-- 													<div class="input-group-prepend"> -->
											<!-- 														<span class="input-group-text"> <i -->
											<!-- 															class="fas fa-user"></i> -->
											<!-- 														</span> -->
											<!-- 													</div> -->
											<!-- 													<select class="custom-select col-12" id="{{pageId}}mainHeads" -->
											<!-- 														name="mainHeads" #mainHeads -->
											<!-- 														(change)="mainHeadMethod(mainHeads.value)"> -->
											<!-- 														<option selected value="addNew">Add New</option> -->
											<!-- 														<option value="checkpostExp">Checkpost Expenses</option> -->
											<!-- 														<option value="claims">Claims</option> -->
											<!-- 														<option value="delhiOffice">Delhi Office</option> -->
											<!-- 														<option value="others">others</option> -->
											<!-- 													</select> -->
											<!-- 												</div> -->
											<!-- 											</div> -->
											<!-- 										</div> -->
											<!-- 											<div class="col-sm-12 col-md-12"> -->

											<!-- 												<div class="control"> -->
											<!-- 													<div class="form-group"> -->
											<!-- 														<div class="input-group"> -->
											<!-- 															<label>Main Heads</label> -->
											<!-- 															<div class="input-group-prepend"> -->
											<!-- 																<span class="input-group-text"> <i -->
											<!-- 																	class="fas fa-user"></i> -->
											<!-- 																</span> -->
											<!-- 															</div> -->
											<!-- 															<input class="auto_selectOption input is-medium" -->
											<!-- 																placeholder="Select Heads" [formControl]="controlMain" -->
											<!-- 																[appAutocomplete]="autocompleteMain" id="{{pageId}}mainHead" name="mainHead" (change)="mainHeads(headType.value)"> -->
											<!-- 														</div> -->
											<!-- 													</div> -->
											<!-- 												</div> -->

											<!-- 												<app-autocomplete #autocompleteMain="appAutocomplete"> -->
											<!-- 												<ng-template appAutocompleteContent> <ng-container -->
											<!-- 													*ngIf="(mainHeadsOptions | filter: controlMain.value) as resultDebitNote"> -->
											<!-- 												<app-option *ngFor="let option of resultDebitNote" -->
											<!-- 													[value]="option.label"> {{ option.label }} </app-option> <app-option -->
											<!-- 													class="no-result" *ngIf="!resultDebitNote.length">No -->
											<!-- 												result</app-option> </ng-container> </ng-template> </app-autocomplete> -->
											<!-- 											</div> -->
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Main Heads</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
														</div>
														<select #mainHead class="custom-select col-12"
															id="{{pageId}}mainHead" name="mainHead"
															(change)="mainHeads(mainHead.value)"
															[(ngModel)]="fortNightStmtDto.mainHeadList">
															<option value="Add New">Add New</option>
															<option value="Add Less">Add Less</option>
															<option value="Others">Others</option>
														</select>
													</div>
												</div>
											</div>
											<!-- 										<div *ngIf="newMainHead" class="col-sm-12 col-md-12"> -->
											<div *ngIf="newMainHeadView" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>New Main Heads</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
														</div>
														<input type="text" id="{{pageId}}newMainHeads" name="newMainHeads"
															class="form-control" aria-describedby="basic-addon11"
															[(ngModel)]="fortNightStmtDto.mainHeadNew">
													</div>
												</div>
											</div>

											<div *ngIf="subHeadView" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Sub Heads</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
														</div>
														<input type="text" id="{{pageId}}subHeads" name="subHeads"
															class="form-control" aria-describedby="basic-addon11"
															[(ngModel)]="fortNightStmtDto.subDebitNew">
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn"
								(click)="validateDebitNoteDetails()">Save</button>
						</div>
					</div>
					<div class="col-md-5 vl ">
						<div class="card-body">
							<div class="box-body">
								<table datatable
									class="table table-striped table-bordered row-border hover"
									[dtOptions]="dtOptionsDebitNoteMaster"
									[dtTrigger]="dtTriggerDebitNoteMaster">

									<thead>
										<tr>
											<th>Main Heads</th>
											<th>Sub Heads</th>
										</tr>
									</thead>
									<tbody>
										<tr
											*ngFor="let debitNoteMasterData of debitNoteMasterDataList ">
											<td>{{ debitNoteMasterData.mainDebitHeads }}</td>
											<td>{{ debitNoteMasterData.subDebitHeads }}</td>
										</tr>
									</tbody>
								</table>


							</div>
						</div>
					</div>
					<div class="col-md-4"></div>
				</div>
			</form>
			<!-- Row -->
		</div>
	</div>
</div>
