//Chg_1 : Add feild Is Gst Detained (Asrar Jr / 19/06/2024)
import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
//for datepicker starts 
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
//for datepicker ends 
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//for popup modal endss 
import { Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    animal: string;
    name: string;
}


//for the select option with filter starts
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

//for the select option with filter ends
//for service starts
import { MemoService } from "src/app/dataService/memo-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import { Router } from "@angular/router";
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
//for service ends
import { ElementRef } from "@angular/core";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@Component({
  selector: 'app-cashmemo-block',
  templateUrl: './cashmemo-block.component.html',
  styleUrls: ['./cashmemo-block.component.css']
})
export class CashmemoBlockComponent implements OnInit {
    closeResult: string;

    animal: string;
    name: string;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
	
    @ViewChild( 'content' ) content: TemplateRef<any>;
    constructor( private modalService: NgbModal,
        public dialog: MatDialog,
        private router: Router, public changeDetectorRef : ChangeDetectorRef

    ) {

        {


            if ( sessionStorage.length == 0 ) {
                this.isLoggedIn = false;
                //          sweet alert starts
                swal( {
                    title: "Session Expired",
                    text: "Please relogin to access the application!",
                    icon: "error",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                } ).then(() => {
                    this.logInPage();
                } )
                //            sweet alert ends
            }

            if ( this.isLoggedIn ) {
                this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );

            }
        }
    }
    //for popup modal starts 
    open2( content ) {
        this.modalService.open( content, { centered: true } ).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason( reason )}`;
            }
        );
    }
    private getDismissReason( reason: any ): string {
        if ( reason === ModalDismissReasons.ESC ) {
            return 'by pressing ESC';
        } else if ( reason === ModalDismissReasons.BACKDROP_CLICK ) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    //for popup modal ends 


    openDialog(): void {
        const dialogRef = this.dialog.open( CashmemoBlockPopupComponent, {

            data: { name: this.name, animal: this.animal }
        } );

        dialogRef.afterClosed().subscribe( result => {
            //            console.log( 'The dialog was closed' );
            this.animal = result;
        } );
    }

    ngOnInit() {
        //        this.modalService.open( this.content );
        setTimeout(() => {
            this.openDialog();
        }, 0 );
    }
    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }
}

@Component( {
    selector: 'app-cashmemo-block-popup',
    templateUrl: 'cashmemo-block.popup.component.html',
} )
export class CashmemoBlockPopupComponent {
    closeResult: string;


    userDataDtoReturnSession: any;
    isLoggedIn = true;
    lrDto: LRDto = new LRDto();
    userDataDto: UserDataDto = new UserDataDto();
	showBlockBtn=false;
	showUnBlockBtn=false;
	enteredLrNumber:any;
	enteredReason:any;
	showBlockUnBlockBtn='MemoBlock ShowBlockUnBlockBtn';
	lrDtoForBlock: LRDto = new LRDto();
	lrDtoForUnBlock: LRDto = new LRDto();
	showSpinnerForAction = false;
	reasonValue:any;
	pageId="caclc";
	checkedGstDetained: any;
   
    constructor(
        //for modal starts
        public dialogRef: MatDialogRef<CashmemoBlockPopupComponent>,
        @Inject( MAT_DIALOG_DATA ) public data: DialogData,
        //for modal ends
        private router: Router, private memoReport: MemoReport, private masterReadService: MasterReadService, public changeDetectorRef : ChangeDetectorRef
    ) {


        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
            //            sweet alert ends
        }

        if ( this.isLoggedIn ) {
            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );
			/*if (this.showBlockUnBlockBtn=='MemoBlock ShowBlockUnBlockBtn') {
				 this.showBlockBtn=true;
				this.showUnBlockBtn=true;        
				}*/
            if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights != null ) {
                 //    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
                     for ( let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++ ) {
                       
                         if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                             && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                             "MemoBlock ShowBlockUnBlockBtn" ) {
                          this.showBlockBtn=true;
						   this.showUnBlockBtn=true;
                         }
                     
                     }
                 }
            
        }
    }

    ngOnInit() {

    }

    fieldFocus( e, el ) {
        if ( e.keyCode == 13 ) { // press A
            el.focus();
        }
    }

    //for modal starts
    onTransferClick(): void {
        this.dialogRef.close();
    }


    onCancelClick(): void {
        this.dialogRef.close();
        this.clearFields();
    }
  

    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }
    blockMethod(){
		this.enteredLrNumber= $("#"+this.pageId+"lrNumber").val();
		this.enteredReason= $("#"+this.pageId+"reasonId").val();
		//Chg_1
		this.checkedGstDetained= $("#"+this.pageId+"isGstDetained").is(":checked");
			if(this.enteredLrNumber=='' ||this.enteredLrNumber==null || this.enteredLrNumber =='undefine'){
				swal({
							 title: "Please Enter Lr Number",
							 text: "Lr Number Filed should Not be Empty",
							 icon: "warning",
							 closeOnClickOutside: false,
							 closeOnEsc: false,
					} ).then(() => {
						 $("#"+this.pageId+"lrNumber" ).focus();
					} );
						 return false;
			}else{
				this.lrDtoForBlock=  new LRDto();
				this.lrDtoForBlock.lrNumber =this.enteredLrNumber;
				this.lrDtoForBlock.reason= this.enteredReason;
				this.lrDtoForBlock.mode='block';
				this.lrDtoForBlock.companyId=this.userDataDtoReturnSession.companyId;
				this.lrDtoForBlock.userName=this.userDataDtoReturnSession.userId;
				//Chg_1
				this.lrDtoForBlock.isGSTMemo = this.checkedGstDetained;
			}
			console.log(this.lrDtoForBlock);
			this.blockMethodDetails();
	
	}
		blockMethodDetails= () => {
    	         this.showSpinnerForAction = true;
    	         this.memoReport.blockCashMemo(this.lrDtoForBlock).subscribe(
    	             (response) => {
    	            	 this.showSpinnerForAction=false;
						 
    	               if (response.status == 'failed') {
    	                     swal({
    	                         title: "Failed",
    	                         text: "Update Failed",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else if (response.status == 'not found') {
    	                     swal({
    	                         title: "warning",
    	                         text: "Lr Not Found",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else if (response.status == 'alreadyGenCashmemo') {
    	                     swal({
    	                         title: "warning",
    	                         text: "Cashmemo is Already Generated For This Lr",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 }else if(response.status == 'success') {
    	               	    swal({
    	                         title: "Success",
    	                         text: "Update Successfully",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
							  this.clearFields();
    	                }
						this.changeDetectorRef.detectChanges();
    	             }), (error) => {
                         this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred While Updating", "info");
                    }, () => console.log('done');
            };	
	unBlockMethod(){
	
		this.enteredLrNumber= $("#"+this.pageId+"lrNumber").val();
		this.enteredReason= $("#"+this.pageId+"reasonId").val();
			if(this.enteredLrNumber=='' ||this.enteredLrNumber==null || this.enteredLrNumber =='undefine'){
				swal({
							 title: "Please Enter Lr Number",
							 text: "Lr Number Filed should Not be Empty",
							 icon: "warning",
							 closeOnClickOutside: false,
							 closeOnEsc: false,
					} ).then(() => {
						 $("#"+this.pageId+"lrNumber" ).focus();
					} );
						 return false;
			}else{
				this.lrDtoForUnBlock=  new LRDto();
				this.lrDtoForUnBlock.lrNumber =this.enteredLrNumber;
				this.lrDtoForUnBlock.reason= this.enteredReason;
				this.lrDtoForUnBlock.mode='unblock';
				this.lrDtoForUnBlock.companyId=this.userDataDtoReturnSession.companyId;
				this.lrDtoForUnBlock.userName=this.userDataDtoReturnSession.userId;
			}
			console.log(this.lrDtoForUnBlock);
			this.unBlockMethodDetails();
	
	}
		unBlockMethodDetails= () => {
    	         this.showSpinnerForAction = true;
    	         this.memoReport.blockCashMemo(this.lrDtoForUnBlock).subscribe(
    	             (response) => {
    	            	 this.showSpinnerForAction=false;
						 
    	               if (response.status == 'failed') {
    	                     swal({
    	                         title: "Failed",
    	                         text: "Update Failed",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else if (response.status == 'not found') {
    	                     swal({
    	                         title: "warning",
    	                         text: "Lr Not Found",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else if (response.status == 'alreadyGenCashmemo') {
    	                     swal({
    	                         title: "warning",
    	                         text: "Cashmemo is Already Generated For This Lr",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 }else if(response.status == 'success') {
    	               	    swal({
    	                         title: "Success",
    	                         text: "Update Successfully",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
							 this.clearFields();
    	                }
						this.changeDetectorRef.detectChanges();
    	             }), (error) => {
                         this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred While Updating", "info");
                    }, () => console.log('done');
            };
		clearFields(){
			this.enteredLrNumber='';
			$("#"+this.pageId+"lrNumber").val('');
			this.enteredReason= '';
			$("#"+this.pageId+"reasonId").val('');
			$("#"+this.pageId+"lrNumber" ).focus();
			this.lrDtoForUnBlock=  new LRDto();
			this.lrDtoForBlock=  new LRDto();
			this.reasonValue='';
			$("#" + this.pageId + "isGstDetained").prop('checked', false);
		}
	
}