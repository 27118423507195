<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showChqDetailReport">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Cheque Details Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Type</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<select #searchBy class="custom-select col-12" id="{{pageId}}chequeModeId"
															(change)="searchByType(searchBy.value)">
															<option value="ALL">ALL</option>
															<option value="Current">Current</option>
															<option value="PDC">PDC</option>
															<option value="Specific">Specific</option>
														</select>
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12" [hidden]="!showHiddenChequeDate">
												<div class="form-group">
													<div class="input-group">
														<label>Cheque Date</label> <input id="{{pageId}}chequeDates"
															class="form-control" placeholder="yyyy-mm-dd"
															name="chequeDates" ngbDatepicker
															[(ngModel)]="chequeDatesModal" #chequeDates="ngbDatepicker"
															autocomplete="off">
														<div class="input-group-append" (click)="chequeDates.toggle()">
															<span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
								(click)="validateSearchBtn()">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearBtn()">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<!-- spinner start-->
						<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<!-- spinner end-->
						<table width="100%">
							<tr>
								<td  width="80%">
									<button type=" submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
										(click)="validateSendBankDetailsBtn(chequeBankDetailsPopUpTemplate)">
										Send To Bank</button>
									<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
										(click)="validateChangeChequeDateDetailsBtn(changeChequeDateDetailsPopUpTemplate)">
										Change Cheque Date</button>
								</td>
								<td  width="20%">
									<button type="submit" class="dt-button" style="margin-left: 60%;"
										(click)="customPrintChqDetailsRpt()" id="{{pageId}}printAllBtn">
										<span><i class="fas fa-print">Print</i></span>
									</button>
								</td>
							</tr>
						</table>
						<br>
						<div class="box-body">
							<table datatable id="{{pageId}}chequeDetailsTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsChequeDetailsReport" [dtTrigger]="dtTriggerChequeDetailsReport">
								<thead>
									<tr>
										<th>
											<!--<input type="checkbox" [checked]="multiSelect"
												(change)="multiSelect = !multiSelect" />-->
										</th>
										<th>Cheque Number</th>
										<th>Cheque Date</th>
										<th>Cheque Amount</th>
										<th>Bank Name</th>
										<th>Consignee Name</th>
										<th>Collection Man</th>
										<!--<th>Action</th>-->
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let chequeDetailsReportData of chequeDetailsReportDataList ">
										<td>
											<input type="checkbox" [checked]="multiSelect" id='{{pageId}}selected'
												(change)="rowCheckBoxChecked($event, chequeDetailsReportData)" />
										</td>
										<td>{{ chequeDetailsReportData.chequeNumber }}</td>
										<td>{{ chequeDetailsReportData.chequeDate }}</td>
										<td>{{ chequeDetailsReportData.chqAmt }}</td>
										<td>{{ chequeDetailsReportData.bankName }}</td>
										<td>{{ chequeDetailsReportData.consigneeName }}</td>
										<td>{{ chequeDetailsReportData.collectionMan }}</td>
										<!--<td>
										<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
											(click)="validateSearchBtn()">Search</button>
										</td>-->
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<!--<td></td>-->
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<!--POP UP START HERE-->
	<div class="col-md-12">
		<div class="form-group">
			<div class="input-group" id="{{pageId}}">
				<ng-template #chequeBankDetailsPopUpTemplate let-ok="close" let-d="dismiss">
					<div class="modal-header">
						<h5>Cheque Bank Details :</h5>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-md-12">
								<div class="card-body">
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>Bank Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-map-marker-alt"></i>
															</span>
														</div>
														<input #bankName id="{{pageId}}bankNameId" type="text"
															class="form-control"
															(selectItem)="clickListnerForBankName($event)"
															[(ngModel)]="modelBankName" [ngbTypeahead]="searchBankName"
															[resultFormatter]="formatterBankName"
															[inputFormatter]="formatterBankName"
															(focus)="focusBankNameTA$.next($any($event).target.value)" />
													</div>
												</div>
											</div>
										</div>
									</div><br>
									<div class="row">
										<div class="col-sm-12 col-md-3">
											<button style="float: right;" type="button" class="btn btn-dark"
												id="{{pageId}}updateBtnId" (click)="updateChequeDetailsRptPopUp()">Update
											</button>
										</div>
										<div class="col-sm-12 col-md-3">
											<button style="float: right;" type="button" class="btn btn-dark"
												id="{{pageId}}cancelBtnId" (click)="closeChequeDetailsRptPopUp()">Cancel
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</div>
		</div>
	</div>

	<div class="col-md-12">
		<div class="form-group">
			<div class="input-group" id="{{pageId}}">
				<ng-template #changeChequeDateDetailsPopUpTemplate let-ok="close" let-d="dismiss">
					<div class="modal-header">
						<h5>Change Cheque Date :</h5>
						<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-md-12">
								<div class="card-body">
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>New Cheque Date</label>
													<input id="{{pageId}}changeNewChequeDates" class="form-control"
														placeholder="yyyy-mm-dd" name="changeNewChequeDates"
														ngbDatepicker #changeNewChequeDates="ngbDatepicker">
													<div class="input-group-append"
														(click)="changeNewChequeDates.toggle()">
														<span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div><br>
									<div class="row">
										<div class="col-sm-12 col-md-3">
											<button style="float: right;" type="button" class="btn btn-dark"
												id="{{pageId}}updateBtnId" (click)="updateChequeDateDetailsRptPopUp()">Update
											</button>
										</div>
										<div class="col-sm-12 col-md-3">
											<button style="float: right;" type="button" class="btn btn-dark"
												id="{{pageId}}cancelBtnId" (click)="closeChequeDateDetailsRptPopUp()">Cancel
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
	<!--POP UP START HERE-->
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>