import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends


import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for the select option with filter starts
import { FormControl } from '@angular/forms';
//import { Observable } from 'rxjs';
//import { map, startWith } from 'rxjs/operators';
import { LRDto } from 'src/app/dto/LR-dto';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map, startWith } from 'rxjs/operators';
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { GenerateCashmemoComponent } from 'src/app/cashmemo/generation/generate-cashmemo/generate-cashmemo.component';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
//for the select option with filter ends

@Component({
    selector: 'app-cash-memo-report',
    templateUrl: './cash-memo-report.component.html',
    styleUrls: ['./cash-memo-report.component.css']
})
export class CashMemoReportComponent implements OnInit {
    getDataCashememoRptFrTable: any;
    cashememoRptDataList: any;
    onDestroyUnsubscribtionCashememoRpt: Subscription;
    loadingIndicator = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerCashememoRpt: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsCashememoRpt: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;

    lrDtoDestination: LRDto = new LRDto();
    partyMasterDtoForconsigneeName: PartyMasterDto = new PartyMasterDto();
    partyMasterDtoSelectedParty: PartyMasterDto = new PartyMasterDto();
    rateMasterDto: RateMasterDto = new RateMasterDto();

    showSpinnerForAction: boolean = false;

    consigneeNameOptions: PartyMasterDto[];
    partyMasterDtoForconsigneeAllOption: PartyMasterDto = new PartyMasterDto();
    public modelConsigneePartyName: any;
    consigneePartyNameTA: Array<PartyMasterDto> = [];
    focusConsigneePartyNameTA$ = new Subject<string>();
    searchConsigneePartyName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneePartyNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneePartyNameTA
                : this.consigneePartyNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneePartyName = (x: { consigneeName: string }) => x.consigneeName;


    destinationStationOptions: LRDto[];
    lrDtoForDestinationStationAllOption: LRDto = new LRDto();
    public modelDestinationStation: any;
    destinationStationTA: Array<LRDto> = [];
    focusDestinationStationTA$ = new Subject<string>();
    searchDestinationStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationStationTA
                //: this.destinationStationTA.filter(v => v.destination).slice(0, 200))));
                : this.destinationStationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200)));

    }
    formatterDestinationStation = (x: { destination: string }) => x.destination;


    destView: boolean = false;
    hideShowDestinationDropDown: boolean = false;
    validateDestination = null;
    validateFromDate: any = null;
    validateToDate: any = null;
    validateConsigneePartyName: any = null;
    validateConsigneePartyId: any = null;
    validateType: any = null;

    modalRefferenceCashMemoHistoryPopUp: NgbModalRef;
    modalRefferenceCashMemoHistoryClosePopUp: string;
    @ViewChild('cashMemoHistoryPopUpTemplate') private cashMemoHistoryPopUpTemplate;

    address: any;
    rateMasterDtoForCashmemoReportPopUp: RateMasterDto = new RateMasterDto();
    viewCashmemoDetailsRpt = false;
    toDatesModal: any = null;
    fromDatesModal: any = null;

    destinationPrint: any = null;

    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    enterArtSummary: number;
    articlesSummary: number;

    viewCustomPrintV1: boolean;
    
    pageId="camrc";

    constructor(private cashememoRptScreen: ReportService, private masterReadService: MasterReadService
        , private router: Router, private memoReport: MemoReport, private modalService: NgbModal, public changeDetectorRef : ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.destinationPrint = this.userDataDtoReturnSession.mainStation == null ? '' : this.userDataDtoReturnSession.mainStation;
            for (let key in this.userDataDtoReturnSession.sortedMapFeatures) {
                //Whatever you want to do with key or obj[key]
                if (key == 'Rights') {
                    for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                        //Whatever you want to do with key or obj[key]
                        if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == 'CashMemoRpt DestView') {
                            console.log('CashMemoRpt DestView');
                            this.destView = true;
                        }
                    }
                }
            }
            if (this.destView) {
                this.hideShowDestinationDropDown = true;
                this.getDestinationDataList();
                // console.log('CashMemoRpt DestView');
            }
            this.getConsigneeDetails();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit() {
        var companyAddressDetls = this.address;
        var companyDestinationPrint = this.destinationPrint;
        var booleanCheckDestinationPrint = this.hideShowDestinationDropDown;
        this.dtOptionsCashememoRpt = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
                    customize: function (win) {
                        $(win.document.body).css('font-size', '10pt');
                        $(win.document.body).find('th, td').
                            css('font-family', 'Arial, Helvetica, sans-serif')
                            .css('font-size', '12px').css('text-align', 'center');
                    },
                    footer: true,
                    messageTop: function () {
                        var status = null;
                        if ($("#camrcselectType").val() == "edit") { status = "Edit" } else { status = "Delete" }

                        if (booleanCheckDestinationPrint == true) {
                            return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                                "<u>Cash Memo Report</u><br><br>" +
                                "From Date : " + moment($("#camrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#camrctoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Destination : " + $("#camrcdestinationStationId").val() + " - " +
                                "Status : " + status + "" +
                                "</td>" +
                                "</tr></table><br>"
                        } else {
                            return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                                "<td style='word-wrap: break-word;text-align:center;font-size:25px;font-weight:bold;'>" +
                                "<u>Cash Memo Report</u><br><br>" +
                                "From Date : " + moment($("#camrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#camrctoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Destination : " + companyDestinationPrint + " - " +
                                "Status : " + status + "" +
                                "</td>" +
                                "</tr></table><br>"
                        }
                    },
                    title: function () {
                        return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                            "<td align='left' style='width: 20%;'>" +
                            "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                            "</td>" +
                            "<td align='left' style='width: 30%;'>" +
                            "</td>" +
                            "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                            companyAddressDetls +
                            "</td>" +
                            "</tr></table>";
                    }
                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        var status = null;
                        if ($("#camrcselectType").val() == "edit") { status = "Edit" } else { status = "Delete" }
                        if (booleanCheckDestinationPrint == true) {
                            return "Cash Memo Report - " +
                                "From Date : " + moment($("#camrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#camrctoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Destination : " + $("#camrcdestinationStationId").val() + " - " +
                                "Status : " + status + "";
                        } else {
                            return "Cash Memo Report - " +
                                "From Date : " + moment($("#camrcfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#camrctoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Destination : " + companyDestinationPrint + " - " +
                                "Status : " + status + "";
                        }

                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var memoAmt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                //$(api.column(1).footer()).html(data.length);
                $(api.column(2).footer()).html(memoAmt);
            },
        }
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerCashememoRpt.unsubscribe();
        //this.onDestroyUnsubscribtionCashememoRpt.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerCashememoRpt.next();
    }

    clickListnerForConsigneePartyName(event) {
        this.modelConsigneePartyName = event.item;
        $("#"+this.pageId+"consigneePartyNameId").val(this.modelConsigneePartyName.consigneeName);
        this.validateConsigneePartyName = this.modelConsigneePartyName.consigneeName;
        this.validateConsigneePartyId = this.modelConsigneePartyName.consigneeId;
    }

    clickListnerForDestinationStation(event) {
        this.modelDestinationStation = event.item;
        $("#"+this.pageId+"destinationStationId").val(this.modelDestinationStation.destination);
        this.validateDestination = $("#"+this.pageId+"destinationStationId").val();
        //console.log(this.validateDestination);
        this.getConsigneeDetails();
    }

    getDestinationData() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.mode = 'destinationOnly';

    }
    getDestinationDataList() {
        this.showSpinnerForAction = true;
        this.getDestinationData();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Destination Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.destinationStationOptions = [];
                    this.destinationStationTA = [];
                    this.showSpinnerForAction = false;
                } else {
                    //console.log(response);
                    this.destinationStationOptions = response;
                    this.destinationStationTA = [];
                    $("#"+this.pageId+"consigneePartyNameId").val('');
                    //this.lrDtoForDestinationStationAllOption.consigneeName = "All";
                    //this.destinationStationTA.push(this.lrDtoForDestinationStationAllOption);
                    for (let i = 0; i < this.destinationStationOptions.length; i++) {
                        this.destinationStationTA.push(this.destinationStationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting destination Details", "warning");
            },
            () => console.log('done');
    };

    getConsigneeDetailsList() {
        this.partyMasterDtoForconsigneeName = new PartyMasterDto();
        // this.partyMasterDtoForconsigneeName.office = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsigneeName.branch = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsigneeName.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsigneeName.mode = "specific";
        //console.log(this.validateDestination);
        if ((this.validateDestination != null && this.validateDestination != undefined &&
            this.validateDestination != "")) {
            if ((this.validateDestination != null) && (this.validateDestination == "binnymills") ||
                (this.validateDestination == "Konditope")) {
                this.partyMasterDtoForconsigneeName.office = "Chennai";
            } else {
                this.partyMasterDtoForconsigneeName.office = this.validateDestination;
            }
        } else {
            this.partyMasterDtoForconsigneeName.office = this.userDataDtoReturnSession.mainStation;
        }
    }

    getConsigneeDetails = () => {
        this.consigneeNameOptions = [];
        this.consigneePartyNameTA = [];
        this.getConsigneeDetailsList();
        this.showSpinnerForAction = true;
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsigneeName).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignee Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consigneeNameOptions = [];
                    this.consigneePartyNameTA = [];
                    this.showSpinnerForAction = false;
                } else {
                    //console.log(response);
                    this.consigneeNameOptions = response;
                    this.consigneePartyNameTA = [];
                    $("#"+this.pageId+"consigneePartyNameId").val('');
                    this.partyMasterDtoForconsigneeAllOption.consigneeName = "All";
                    this.consigneePartyNameTA.push(this.partyMasterDtoForconsigneeAllOption);
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneePartyNameTA.push(this.consigneeNameOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignee details", "info");
            }, () => console.log('done');
    };
    clearBtn() {
        this.clearField();
    }
    clearField() {
        this.toDatesModal = null;
        this.fromDatesModal = null;
        $("#"+this.pageId+"fromDates").val('');
        $("#"+this.pageId+"toDates").val('');
        $("#"+this.pageId+"selectType").val('Select Type');
        $("#"+this.pageId+"modelConsigneePartyName").val('');
        $("#"+this.pageId+"consigneePartyNameId").val('');
        this.validateDestination = null;
        this.validateFromDate = null;
        this.validateToDate = null;
        this.validateConsigneePartyName = null;
        this.validateConsigneePartyId = null;
        this.validateType = null;
        $("#"+this.pageId+"cashememoRptTableId").DataTable().destroy();
        this.cashememoRptDataList = [];
        this.dtTriggerCashememoRpt.next();
        if (this.destView == true) {
            $("#"+this.pageId+"modelDestinationStation").val('');
        }
    }

    validateSearchBtn() {
        this.validateFromDate = $("#"+this.pageId+"fromDates").val();
        this.validateToDate = $("#"+this.pageId+"toDates").val();
        this.validateType = $("#"+this.pageId+"selectType").val();
        if (this.destView == true) {
            if ((this.validateFromDate == null) || (this.validateFromDate == undefined) ||
                (this.validateFromDate == "") || (this.validateToDate == null) ||
                (this.validateToDate == undefined) || (this.validateToDate == "") ||
                (this.validateDestination == null) || (this.validateDestination == undefined) ||
                (this.validateDestination == "") || (this.validateConsigneePartyName == null) ||
                (this.validateConsigneePartyName == undefined) || (this.validateConsigneePartyName == "") ||
                (this.validateType == null) || (this.validateType == undefined) ||
                (this.validateType == "Select Type")) {
                swal("Mandatory Fields", "Please select the  mandatory fields", "warning");
                return false;
            } else {
                this.getUserValues();
            }
        } else {
            if ((this.validateFromDate == null) || (this.validateFromDate == undefined) ||
                (this.validateFromDate == "") || (this.validateToDate == null) ||
                (this.validateToDate == undefined) || (this.validateToDate == "") ||
                (this.validateConsigneePartyName == null) || (this.validateConsigneePartyName == undefined) ||
                (this.validateConsigneePartyName == "") || (this.validateType == null) ||
                (this.validateType == undefined) || (this.validateType == "Select Type")) {
                swal("Mandatory Fields", "Please select the  mandatory fields", "warning");
                return false;
            } else {
                this.getUserValues();
            }
        }
    }
    getUserValues() {
        this.showSpinnerForAction = true;
        this.rateMasterDto = new RateMasterDto();
        this.validateFromDate = $("#"+this.pageId+"fromDates").val();
        this.validateToDate = $("#"+this.pageId+"toDates").val();
        this.validateType = $("#"+this.pageId+"selectType").val();

        this.rateMasterDto.fromDateStg = this.validateFromDate;
        this.rateMasterDto.toDateStg = this.validateToDate;
        this.rateMasterDto.fromDate = this.validateFromDate;
        this.rateMasterDto.toDate = this.validateToDate;
        this.rateMasterDto.destination = this.userDataDtoReturnSession.office;
        if (this.hideShowDestinationDropDown == true) {
            this.rateMasterDto.destination = this.validateDestination;
        }
        this.rateMasterDto.deliveryType = this.validateType;
        this.rateMasterDto.consignee = this.validateConsigneePartyName;
        if (this.validateConsigneePartyName != null && this.validateConsigneePartyName != undefined &&
            this.validateConsigneePartyName != "" && this.validateConsigneePartyName == "All") {
            this.rateMasterDto.consigneeId = 0;
        } else {
            this.rateMasterDto.consigneeId = this.validateConsigneePartyId;
        }
        //console.log(this.rateMasterDto);
        this.memoReport.getCashMemoHistoryDetailsReport(this.rateMasterDto).subscribe(
            (response) => {
                $("#"+this.pageId+"cashememoRptTableId").DataTable().destroy();
                this.cashememoRptDataList = [];
                if (response.length > 0) {
                    this.cashememoRptDataList = response;
                    this.showSpinnerForAction = false;
                } else {
                    this.cashememoRptDataList = [];
                    this.showSpinnerForAction = false;
                    swal("No Records", "No Records found for this search", "info");
                }
                this.dtTriggerCashememoRpt.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the cash memo history details", "info");
            }, () => console.log('done');
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    cashMemoHistoryPopUpBtnLink(cashMemoHistoryPopUpTemplate, cashememoRptData) {
        this.modalRefferenceCashMemoHistoryPopUp = this.modalService.open(cashMemoHistoryPopUpTemplate,
            { centered: true, windowClass: "modalClassForPopUpCashMemoGenerate" });
        this.modalRefferenceCashMemoHistoryPopUp.result.then((result) => {
            this.modalRefferenceCashMemoHistoryClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceCashMemoHistoryClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
        this.rateMasterDtoForCashmemoReportPopUp = new RateMasterDto();
        this.rateMasterDtoForCashmemoReportPopUp = cashememoRptData;
        localStorage.clear();
        localStorage.setItem('rateMasterDtoForCashmemoReportPopUp',
            JSON.stringify(this.rateMasterDtoForCashmemoReportPopUp));
        localStorage.setItem('cashmemoReportPopUp', "CashMemoReportPopup");
        this.viewCashmemoDetailsRpt = true
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewCashmemoDetailsRpt) {
                this.viewCashmemoDetailsRpt = false;
            }

        });
    }

    closeCashmemoRptPopUp() {
        this.modalRefferenceCashMemoHistoryPopUp.close();
    }



    customPrintCashMemoRpt() {
        if (this.cashememoRptDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Memo No", "Memo Amt", "Source", "Modified By", "Modified Date", "Reason"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [14, 20, 10, 12, 12, 12, 20];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            this.articlesSummary = this.enterArtSummary = 0;
            for (let i = 0; i < this.cashememoRptDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.cashememoRptDataList[i].gcNoteNumber, this.cashememoRptDataList[i].memoNumber,
                this.cashememoRptDataList[i].memoAmt, this.cashememoRptDataList[i].source, this.cashememoRptDataList[i].lastModifiedBy,
                this.cashememoRptDataList[i].lastModifiedDateStr, this.cashememoRptDataList[i].reason];
                /////////

                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.cashememoRptDataList.length, "", "", "", "", "", ""];

            //heading logics For Date

            this.validateFromDate = $("#"+this.pageId+"fromDates").val();
            this.validateToDate = $("#"+this.pageId+"toDates").val();


            this.cashMemoDtoForCustomPrintListHeadingV1 = [];


            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date,To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateFromDate, this.validateToDate];

            ///Heaading Logics For Party Name

            this.validateDestination = $("#"+this.pageId+"destinationStationId").val();
            this.validateType = $("#"+this.pageId+"selectType").val();

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Destination", "Status"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateDestination, this.validateType];






            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
                this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
            }


            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
            localStorage.setItem('printTitle', "Cash Memo Report");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });



        }
    }

}
