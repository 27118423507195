import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { MasterService } from 'src/app/dataService/master-service';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-memo-invoice',
    templateUrl: './memo-invoice.component.html',
    styleUrls: ['./memo-invoice.component.css']
})
export class MemoInvoiceComponent implements OnInit {

    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;
    viewTypeInvoiceNoWise = true;
    viewTypeDateWise = false;
    viewTypePartyWise = false;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    getDataFrmServiceFrTable: any;
    memoInvoiceReportDataList: any;
    onDestroyUnsubscribtionMemoInvoiceReport: Subscription;
    controlMain = new FormControl();
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerMemoInvoiceReport: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsMemoInvoiceReport: any;
    pageId = "minvc";
    constructor(private memoInvoiceReport: MasterService, public changeDetectorRef: ChangeDetectorRef) {
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    ngOnInit(): void {


        this.dtOptionsMemoInvoiceReport = {
            //};
            //columns is used to for export and others starts



            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                },
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //
                //                }


            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

        }

        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        this.getDataFrmServiceFrTable = this.memoInvoiceReport.getSummaryData()
        this.onDestroyUnsubscribtionMemoInvoiceReport = this.getDataFrmServiceFrTable.subscribe(data => {
            this.memoInvoiceReportDataList = data['data'];
            this.dtTriggerMemoInvoiceReport.next();
        });


    }

    ngOnDestroy(): void {
        this.dtTriggerMemoInvoiceReport.unsubscribe();

        this.onDestroyUnsubscribtionMemoInvoiceReport.unsubscribe();
    }



    viewTypeMode(viewType: string) {
        if (viewType === 'invoiceNoWise') {
            this.viewTypeInvoiceNoWise = true;
            this.viewTypeDateWise = false;
            this.viewTypePartyWise = false;
        } else if (viewType === 'dateWise') {
            this.viewTypeInvoiceNoWise = false;
            this.viewTypeDateWise = true;
            this.viewTypePartyWise = false;
        } else if (viewType === 'partyWise') {
            this.viewTypeInvoiceNoWise = false;
            this.viewTypeDateWise = true;
            this.viewTypePartyWise = true;
        } else {
            this.viewTypeInvoiceNoWise = false;
            this.viewTypeDateWise = false;
            this.viewTypePartyWise = false;
        }
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

}
