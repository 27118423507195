<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">User Creation</h6>
			</div>
			<div class="row  system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-3">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-12">
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>Branch*</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-home"></i>
															</span>
														</div>
														<input id="{{pageId}}destination" type="text"
															class="form-control" [(ngModel)]="office"
															[ngbTypeahead]="searchDestination"
															[resultFormatter]="formatterDestination"
															[inputFormatter]="formatterDestination"
															(focus)="focusDestinationTA$.next($any($event).target.value)"
															placeholder="Select Destination"
															[readonly]="isReadOnlyForBranch" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<hr>
							<div class="row">
								<div class="col-lg-12">
									<h6 class="card-title">User Details*</h6>
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Name*</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-user"></i>
														</span>
													</div>
													<input #name (keyup)="fieldFocus($event, contactNumber)" type="text"
														class="form-control" id="{{pageId}}name" name="name"
														aria-describedby="basic-addon11" [readonly]="isReadOnlyForName">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Contact Number</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class=" fas fa-phone"></i>
														</span>
													</div>
													<input #contactNumber (keyup)="fieldFocus($event, emailId)"
														type="number" class="form-control"
														aria-describedby="basic-addon11" id="{{pageId}}contactNumber"
														name="contactNumber">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Email Id</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class=" fas fa-envelope"></i>
														</span>
													</div>
													<input #emailId (keyup)="fieldFocus($event, loginId)" type="text"
														class="form-control" aria-describedby="basic-addon11"
														id="{{pageId}}emailId" name="emailId">

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<hr>
							<div class="row">
								<div class="col-lg-12">
									<h6 class="card-title">Login Details</h6>
									<div class="row">

										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>User Id*</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class=" fas fa-user"></i>
														</span>
													</div>
													<input #loginId (keyup)="fieldFocus($event, role)" type="text"
														class="form-control" aria-describedby="basic-addon11"
														id="{{pageId}}loginId" name="loginId"
														[readonly]="isReadOnlyForLoginId">
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Role*</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
																class="fas fa-user-circle"></i>
														</span>
													</div>
													<input #role id="{{pageId}}role" name="role"
														type="text" class="form-control" [(ngModel)]="modelRole"
														placeholder="Select Role..." [ngbTypeahead]="rolesSearchTA"
														(focus)="focusRolesTA$.next($any($event).target.value)"
														#instanceIndex="ngbTypeahead" [readonly]="isReadOnlyForRole"/>
													<!-- <select #role id="{{pageId}}role" name="role"
														class="custom-select col-12" placeholder="Select Role..."
														[readonly]="isReadOnlyForRole" >
														<option selected>Select Role...</option>
														<option value="Administrator">Administrator</option>
														<option value="Barcode">Barcode</option>
														<option value="Collection Manager">Collection
															Manager</option>
														<option value="Delivery Manager">Delivery Manager</option>
													</select> -->
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>

							<hr>
							<div class="row">
								<div class="col-lg-12">
									<h6 class="card-title">Login Time Setup</h6>
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>Set Login Time</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-clock"></i>
														</span>
													</div>
													<select #setLoginTime class="custom-select col-12"
														id="{{pageId}}setLoginTime" name="setLoginTime" #setLoginTime
														(change)="setLoginTimeMode(setLoginTime.value)">
														<option selected value="false">No</option>
														<option value="true">Yes</option>
													</select>
												</div>
											</div>
										</div>

										<div *ngIf="setLoginTimeFromTime" class="col-sm-12 col-md-12">
											<div class="form-group ">
												<div class="input-group ">
													<label>From Time</label>
													<div class="input-group-prepend ">
														<span class="input-group-text "> <i class="fas fa-clock"></i>
														</span>
													</div>
													<input type="time" class="form-control " id="{{pageId}}fromTime"
														autocomplete="off" aria-describedby="basic-addon11">
												</div>
											</div>
										</div>

										<div *ngIf="setLoginTimeToTime" class="col-sm-12 col-md-12">
											<div class="form-group ">
												<div class="input-group ">
													<label>To Time</label>
													<div class="input-group-prepend ">
														<span class="input-group-text "> <i class="fas fa-clock"></i>
														</span>
													</div>
													<input type="time" class="form-control " id="{{pageId}}toTime"
														autocomplete="off" aria-describedby="basic-addon11">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr style="width: 80%; border-top: none; margin: 3px;">
					<div class="col-md-12" style="text-align: center;">
						<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}addBtn"
							(click)="validateUserDetails()">Add/Update</button>
						<button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}deleteBtn"
							(click)="validateDeleteRow()">Delete</button>
						<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
							(click)="clearAll();">Clear</button>
					</div>
				</div>
				<div class="col-md-9 vl">
					<div class="col-md-12">
						<!-- <div class="card"> -->
							<!-- <div class="card-body"> -->
								<div class="box-body">
									<table datatable id="{{pageId}}userCreationTableId" class="table table-striped table-bordered row-border hover"
										[dtOptions]="dtOptionsUserCreation" [dtTrigger]="dtTriggerUserCreation">

										<thead>
											<tr>
												<th>Name</th>
												<th>Contact Number</th>
												<th>Email Id</th>
												<th>User Id</th>
												<th>Role</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let userCreationData of userCreationDataList"
												(click)="rowSelected( userCreationData );">
												<td>{{ userCreationData.name }}</td>
												<td>{{ userCreationData.mobileNum }}</td>
												<td>{{ userCreationData.emailId }}</td>
												<td>{{ userCreationData.userName }}</td>
												<td>{{ userCreationData.role }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							<!-- </div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>