<!--<h1 mat-dialog-title>Consignee Rate Master-Setting Rate For All</h1>-->
<div mat-dialog-content>
    <div class="row">
        <div class='row p-t-10'>
            <div class="col-sm-12 col-md-12">
                <h6 class="card-title border_bottom">Rate For All - Partywise Setup
                </h6>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row">
                <!-- spinner start-->
                <div *ngIf="showSpinnerForAction" class="col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;">
                            </mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                Please Wait Loading Details.....</h6>
                        </div>
                    </div>
                </div>
                <!-- spinner end-->
                <div class="col-md-4">
                    <div class="card-body">
                        <div class="row">
                            <!--<h6 class="card-title">Rate Details-I</h6>-->
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Party Type</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-check"></i>
                                                    </span>
                                                </div>
                                                <select class="custom-select col-12" #partyTypeIdSpec id="{{pageId}}partyTypeIdSpec">
                                                    <option selected value="Select Party Type">Select Party Type
                                                    </option>
                                                    <option value="Consignor">Consignor</option>
                                                    <option value="Consignee">Consignee</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Main Source</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-box"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}mainBookingSourceIdPartySpec" (selectItem)="clickListnerForMainBookingSource($event)" [(ngModel)]="modelMainBookingSourcePartySpec" [ngbTypeahead]="searchMainBookingSource" [resultFormatter]="formatterMainBookingSource"
                                                    [inputFormatter]="formatterMainBookingSource" (focus)="focusMainBookingSourceTA$.next($any($event).target.value)" placeholder="Select Main Source.." />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Select Index</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-box"></i>
                                                        </span>
                                                    </div>
                                                    <input #index id="{{pageId}}indexId" name="indexId" type="text" class="form-control" [(ngModel)]="modelIndex" placeholder="Select Index" (selectItem)="indexDropDownListner($event)" [ngbTypeahead]="indexSearchTA" (focus)="focusIndexTA$.next($any($event).target.value)"
                                                        #instanceIndex="ngbTypeahead" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Consignor Name &nbsp;*</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
                                                    </span>
                                                </div>
                                                <input #consignorName id="{{pageId}}consignorName" name="consignorName" type="text" class="form-control" placeholder="Select Consignor Name" [(ngModel)]="modelConsignorName" [ngbTypeahead]="consignorNameSearchTA" (selectItem)="clickListnerForConsignor($event)"
                                                    [resultFormatter]="formatterConsignorName" [inputFormatter]="formatterConsignorName" (focus)="focusConsignorNameTA$.next($any($event).target.value)" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>

                                                    <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestinationPartySpec" (selectItem)="clickListnerForDestination($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                        (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Consignee Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input #consigneeNameId id="{{pageId}}consigneeNameId" type="text" class="form-control" (selectItem)="clickListnerForConsigneeNameList($event)" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="searchConsigneeName" [resultFormatter]="formatterConsigneeName"
                                                        [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Consignee Name.." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Update Mode</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-check"></i>
                                                    </span>
                                                </div>
                                                <select class="custom-select col-12" #updateModePopUpIdPartySpec id="{{pageId}}updateModePopUpIdPartySpec">
                                                    <option selected value="Increase">Increase</option>
                                                    <option value="Decrease">Decrease</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Station Type</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-check"></i>
                                                    </span>
                                                </div>
                                                <select class="custom-select col-12" #stationTypePartySpec id="{{pageId}}stationTypePartySpec">
                                                    <option selected value="Both">Both</option>
                                                    <option value="Mainstation">Mainstation</option>
                                                    <option value="Substation">Substation</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="row">
                        <!-- <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Commodity &nbsp;*</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-box"></i>
                                        </span>
                                    </div>
                                    <input #CommodityList id="{{pageId}}commodityListIdPartySpec" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityListPartySpec" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                                        [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                                </div>
                            </div>
                        </div> -->
                        <div class="col-sm-12 col-md-12">
                            <div class="row">
                                <div class="col-sm-6 col-md-6">
                                    <div class="control">
                                        <div class="form-group">
                                            <input class="col-sm-2 col-md-2" type="radio" name="sourceType" value="specific" (change)="sourceTypeSelected('specific')" [checked]="sourceType === 'specific'">
                                            <label class="col-sm-10 col-md-10">
                                                Specific Source
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="control">
                                        <div class="form-group">
                                            <input class="col-sm-2 col-md-2" type="radio" name="sourceType" value="All" (change)="sourceTypeSelected('all')" [checked]="sourceType === 'all'">
                                            <label class="col-sm-10 col-md-10">
                                                All Source
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" [hidden]="!viewSpecificSource">
                            <div class="card">
                                <div class="card-body">
                                    <div class='row p-t-10'>
                                        <!-- <div class="col-sm-12 col-md-12">
                                            <h6 class="card-title border_bottom">Select Source to set the Rate
                                            </h6>
                                        </div> -->
                                    </div>
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}selectedSourceTableIdPartySpec" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsSourcePartySpec" [dtTrigger]="dtTriggerSourcePartySpec">
                                            <thead>
                                                <tr>
                                                    <th>Action</th>
                                                    <th>Source</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let agentDetails of agentDetailsOptionsSet let i = index">
                                                    <td><input type="checkbox" [checked]="multiSelect" id='selectedSource' (change)="rowCheckBoxForSource($event, agentDetails,i)" />
                                                    </td>
                                                    <td>{{ agentDetails.source }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12">
                            <div class="row">
                                <div class="col-sm-6 col-md-6">
                                    <div class="control">
                                        <div class="form-group">
                                            <input class="col-sm-2 col-md-2" type="radio" name="destinationType" value="specific" (change)="destinationTypeSelected('specific')" [checked]="destinationType === 'specific'">
                                            <label class="col-sm-10 col-md-10">
                                                Specific Destination
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="control">
                                        <div class="form-group">
                                            <input class="col-sm-2 col-md-2" type="radio" name="destinationType" value="all" (change)="destinationTypeSelected('all')" [checked]="destinationType === 'all'">
                                            <label class="col-sm-10 col-md-10">
                                                All Destination
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" [hidden]="!viewSpecificDestination">
                            <div class="card">
                                <div class="card-body">
                                    <div class='row p-t-10'>
                                        <!-- <div class="col-sm-12 col-md-12">
                                            <h6 class="card-title border_bottom">Select Destination to set the Rate</h6>
                                        </div> -->
                                    </div>
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}selectedDestinationTableIdPartySpec" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsDestinationPartySpec" [dtTrigger]="dtTriggerDestinationPartySpec">
                                            <thead>
                                                <tr>
                                                    <th> Action</th>
                                                    <th>Destination</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let destinationData of destinationDataList let i = index">
                                                    <td><input type="checkbox" [checked]="multiSelect" id='selectedDestination' (change)="rowCheckBoxForDestination($event, destinationData,i)" />
                                                    </td>
                                                    <td>{{ destinationData.godownName }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="row">
                                <div class="col-sm-6 col-md-6">
                                    <div class="control">
                                        <div class="form-group">
                                            <input class="col-sm-2 col-md-2" type="radio" name="commodityType" value="specific" (change)="commodityTypeSelected('specific')" [checked]="commodityType === 'specific'">
                                            <label class="col-sm-10 col-md-10">
                                                Specific Commodity
                                            </label>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="control">
                                        <div class="form-group">
                                            <input class="col-sm-2 col-md-2" type="radio" name="commodityType" value="All" (change)="commodityTypeSelected('all')" [checked]="commodityType === 'all'">
                                            <label class="col-sm-10 col-md-10">
                                                All Commodity
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" [hidden]="!viewSpecificCommodity">
                            <div class="card">
                                <div class="card-body">
                                    <div class='row p-t-10'>
                                        <!-- <div class="col-sm-12 col-md-12">
                                            <h6 class="card-title border_bottom">Select Commodity</h6>
                                        </div> -->
                                    </div>
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}selectedCommodityTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCommodity" [dtTrigger]="dtTriggerCommodity">
                                            <thead>
                                                <tr>
                                                    <th>Action</th>
                                                    <th>Commodity</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let commodityData of commodityDataList let i = index">
                                                    <td><input type="checkbox" [checked]="multiSelect" id='selected' (change)="rowCheckBoxForCommodity($event, commodityData,i)" id='selectedCommodity' />
                                                    </td>
                                                    <td>{{ commodityData.commodityName }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Type</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-search"></i>
                                        </span>
                                    </div>
                                    <select class="custom-select col-12" id="{{pageId}}searchByRateTypePartySpec" name="searchByRateTypePartySpec" #searchByRateTypePartySpec (change)="searchByRateTypeMode(searchByRateTypePartySpec.value)">
                                        <option selected value="PerKg">Per Kg</option>
                                        <option value="Fixed">Fixed Per Article</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12" *ngIf="viewActChgWgtField">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Per Unit</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-search"></i>
                                        </span>
                                    </div>
                                    <select class="custom-select col-12" id="{{pageId}}perUnitIdPartySpec" name="perUnitIdPartySpec" #perUnit>
                                        <option selected value="ActualWeight">Actual Weight
                                        </option>
                                        <option value="ChargedWeight">Charged Weight</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Basic Rate &nbsp;*</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}basicRateIdPartySpec" #basicRatePartySpec name="basicRateIdPartySpec" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForBaseChargeAmt($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Hamali</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}hamaliChgIdPartySpec" #hamaliChgPartySpec name="hamaliChgIdPartySpec" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForHamaliChargeAmt($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>GC Charge</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}gcChargeIdPartySpec" #gcChargePartySpec name="gcChargeIdPartySpec" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForGCChargeAmt($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>AOC</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}aocChargeIdPartySpec" #aocChargePartySpec name="aocChargeIdPartySpec" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForAoc($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>DD Charge</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}ddChargeIdPartySpec" #ddChargePartySpec name="ddChargeIdPartySpec" aria-describedby="basic-addon11" autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Others</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}otherChargeIdPartySpec" #otherChargePartySpec name="otherCharge" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForOther($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>FOV(%) &nbsp;</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}fovChargeIdPartySpec" #fovChargePartySpec name="fovChargeIdPartySpec" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForFov($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Fixed By &nbsp;*</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" aria-describedby="basic-addon11" autocomplete="off" id="{{pageId}}fixedByPartySpec">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Effect From &nbsp;*</label> <input class="form-control" id="{{pageId}}effectFromDatePartySpec" placeholder="yyyy-mm-dd" name="effectFromDatePartySpec" ngbDatepicker #effectFromDatesPartySpec="ngbDatepicker" autocomplete="off">
                                    <div class="input-group-append" (click)="effectFromDatesPartySpec.toggle()">
                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Fixed On &nbsp;*</label> <input id="{{pageId}}fixedOnDatePartySpec" class="form-control" placeholder="yyyy-mm-dd" name="fixedOnDatePartySpec" ngbDatepicker #fixedOnDatesPartySpec="ngbDatepicker" autocomplete="off">
                                    <div class="input-group-append" (click)="fixedOnDatesPartySpec.toggle()">
                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div *ngIf="viewSaveBtn" class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="{{pageId}}subStationCheckbox" (change)="toggleSubStationCheckbox($event)"> <label class=" custom-control-label wtfull " for="{{pageId}}subStationCheckbox">Separate Rate For
										Substation</label>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <!--col-md-6-1-end-->
                <!-- <div class="col-md-4">
                    <div class="row" *ngIf="viewSubRate">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Basic Rate &nbsp;*</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}basicRateIdSubPartySpec" #basicRateSub name="basicRateIdSubPartySpec" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForBaseChargeAmtSub($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Hamali</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}hamaliChgIdSubPartySpec" #hamaliChgSubPartySpec name="hamaliChgIdSubPartySpec" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForHamaliChargeAmtSub($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>GC Charge</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}gcChargeIdSubPartySpec" #gcChargeSubPartySpec name="gcChargeIdSubPartySpec" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForGCChargeAmtSub($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>AOC</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}aocChargeIdSubPartySpec" #aocChargeSubPartySpec name="aocChargeIdSubPartySpec" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForAocSub($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>DD Charge</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}ddChargeIdSubPartySpec" #ddChargeSubPartySpec name="ddChargeIdSubPartySpec" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForDDSub($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Others</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}otherChargeIdSubPartySpec" #otherChargeSubPartySpec name="otherChargeSubPartySpec" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForOtherSub($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>FOV(%) &nbsp;*</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}fovChargeIdSubPartySpec" #fovChargeSubPartySpec name="fovChargeIdSubPartySpec" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForFovSub($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Fixed By &nbsp;*</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" aria-describedby="basic-addon11" autocomplete="off" id="{{pageId}}fixedBySubPartySpec">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Effect From &nbsp;*</label> <input class="form-control" id="{{pageId}}effectFromDateSubPartySpec" placeholder="yyyy-mm-dd" name="effectFromDateSubPartySpec" ngbDatepicker #effectFromDateSubsPartySpec="ngbDatepicker"
                                        autocomplete="off">
                                    <div class="input-group-append" (click)="effectFromDateSubsPartySpec.toggle()">
                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Fixed On &nbsp;*</label> <input id="{{pageId}}fixedOnDateSubPartySpec" class="form-control" placeholder="yyyy-mm-dd" name="fixedOnDateSubPartySpec" ngbDatepicker #fixedOnDateSubsPartySpec="ngbDatepicker" autocomplete="off">
                                    <div class="input-group-append" (click)="fixedOnDateSubsPartySpec.toggle()">
                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!--col-md-6-end-2-->
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions style="float: right;">
    <!--<button class="btn btn-primary" mat-button (click)="onOkClickBtn();">OK</button>-->
    <button class="btn btn-primary" mat-button (click)="SaveClickBtn();">Update</button>
    <button class="btn btn-primary" mat-button (click)="ClearClickBtn();">Clear</button>
    <button class="btn btn-danger" mat-button (click)="onCancelClick();">Close</button>
</div>