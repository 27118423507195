import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupplierModel } from "src/app/master/supplier/supplier-master/supplier-model";
import { LRDto } from "src/app/dto/LR-dto";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { serverUrl } from "src/environments/environment";
import { ResponseDto } from '../dto/Response-dto';


@Injectable({
    providedIn: 'root'
})
export class MemoService {
    //for local
    //        private _baseUrl = "http://localhost:8080/srd/memo/v1";
    //For QA
    //    private _baseUrl = "http://54.210.51.40:8080/srd/memo/v1";
    //For Live
    //        private _baseUrl = "http://3.222.201.242:8080/srd/memo/v1";

    private _baseUrl = serverUrl.webMemo;

    constructor(
        private http: HttpClient,
    ) {
    }

    //        getBookingStationsService( lrDto: Object ): Observable<LRDto[]> {
    //            return this.http.post<LRDto[]>( `${this._baseUrl}/getBookingStationsDetails`, lrDto );
    //        }
    getMemoDetailService(cashMemoDto: Object): Observable<CashMemoDto[]> {
        //        console.log( cashMemoDto );
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getMemoDetail`, cashMemoDto);
    }

    updateMemoDetailService(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/updateMemoDetail`, userDataDto);
    }

    chkRemCashMemoService(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/chkRemCashMemoServiceDetail`, userDataDto);
    }

    updateMemoAllService(userDataDto: Object): Observable<UserDataDto[]> {
        //        console.log( userDataDto );
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/updateMemoAllDetail`, userDataDto);
    }

    getCashMemoUnAssgDetailService(userDataDto: Object): Observable<UserDataDto[]> {
        //        console.log( userDataDto );
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getCashMemoUnAssgDetail`, userDataDto);
    }
    transferToGodownLrsService(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/transferToGodownLrsDetail`, userDataDto);
    }

    getSingleMemoDetailService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getSingleMemoDetail`, lrDto);
    }

    createDummyCashMemoService(rateMasterDto: Object): Observable<RateMasterDto[]> {
        return this.http.post<RateMasterDto[]>(`${this._baseUrl}/createDummyCashMemo`, rateMasterDto);
    }

    chkLrInCpService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/chkLrInCpDetail`, lrDto);
    }
    getLrDetailsForCashMemoService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getLrDetailsForCashMemo`, lrDto);
    }

    createNewPartyBankMasterService(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/createNewPartyBankMasterDetail`, userDataDto);
    }

    createDeliveryAreaService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/createDeliveryArea`, lrDto);
    }
    createCashMemoService(rateMasterDto: Object): Observable<RateMasterDto> {
        //        console.log( rateMasterDto );
        return this.http.post<RateMasterDto>(`${this._baseUrl}/createCashMemo`, rateMasterDto);
    }

    createNewCollectionManService(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/createNewCollectionManDetails`, userDataDto);
    }
    updateCommodityDtlsService(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/updateCommodityDtls`, lrDto);
    }

    setCashMemoLaserPrintService(rateMasterDto: Object): Observable<RateMasterDto> {
        // console.log(rateMasterDto);
        return this.http.post<RateMasterDto>(`${this._baseUrl}/setCashMemoLaserPrint`, rateMasterDto);
    }

    updateEnableBankLrDestMemoService(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateEnableBankLrDestMemo`, lrDto);
    }


    updateEnableSelfLrDestMemoService(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateEnableSelfLrDestMemo`, lrDto);
    }

    updateEnableBlockedLrDestMemoService(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateEnableBlockedLrDestMemo`, lrDto);
    }

    getSRDToColManRptForPrintService(cashMemoDto: Object): Observable<CashMemoDto[]> {
        //        console.log( cashMemoDto );
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getSRDToColManRptForPrint`, cashMemoDto);
    }

    getCashMemoBkgDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getcashmemobkgdetails`, lrDto);
    }

    updatedBkgCashMemo(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/updatedbkgcashmemo`, lrDto);
    }

    editCashmemoDetailsService(rateMasterDto: Object): Observable<RateMasterDto> {
        //        console.log( rateMasterDto );
        return this.http.post<RateMasterDto>(`${this._baseUrl}/editCashmemoDetails`, rateMasterDto);
    }

    deleteCashMemoService(lrDto: Object): Observable<LRDto> {
        //        console.log( lrDto );
        return this.http.post<LRDto>(`${this._baseUrl}/deleteCashMemo`, lrDto);
    }

    getCollectionManDetails(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getcollectionmandetails`, userDataDto);
    }

    updateCloseCollectionService(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/updateclosecollection`, userDataDto);
    }

    getGroupStationOfLRNumber(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getgroupstationoflrnumber`, lrDto);
    }

    getTotalMemoLessAmount(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getTotalMemoLessAmount`, cashMemoDto);
    }

    getMemoLessDetails(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getMemoLessDetails`, userDataDto);
    }

    addPartyBankMasterDetails(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/addPartyBankMasterDetails`, userDataDto);
    }
    updateCashMemoLessDetails(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/updateCashMemoLessDetails`, userDataDto);
    }
    updateCashMemoCompleteDetails(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/updateCashMemoCompleteDetails`, userDataDto);
    }

    getDifferentMemoLessDetailsBasedOnLessDesc(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getDifferentMemoLessDetailsBasedOnLessDesc`, userDataDto);
    }

    validateChqNumberWithBank(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/validateChqNumberWithBank`, lrDto);
    }
    getTPStmtPendingDetailsValidationCheck(cashMemoDto: Object): Observable<CashMemoDto[]> {
        //        console.log( cashMemoDto );
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getTPStmtPendingDetailsValidationCheck`, cashMemoDto);
    }

    getConsigneeWiseBalanceDet(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getConsigneeWiseBalanceDet`, cashMemoDto);
    }

    collectionManAssignEnableServiceMtd(userDataDto: Object): Observable<UserDataDto> {
        return this.http.post<UserDataDto>(`${this._baseUrl}/collectionManAssignEnableApi`, userDataDto);
    }

    createCollectionManService(userDataDto: Object): Observable<UserDataDto> {
        return this.http.post<UserDataDto>(`${this._baseUrl}/createNewCollectionManDetails`, userDataDto);
    }

    updateTempCashMemoComplete(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/updateTempCashMemoCompleteApi`, cashMemoDto);
    }

    updateEnableCodLrDestMemo(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateEnableCodLrDestMemo`, lrDto);
    }

    getCashMemoUnAssgBkgService(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getCashMemoUnAssgBkgApi`, cashMemoDto);
    }

    getConsignorWiseBalanceDet(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getConsignorWiseBalanceDetApi`, cashMemoDto);
    }

    getMemoStatusDetailsBkgPaymentFollowup(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getMemoStatusDetailsBkgPaymentFollowupApi`, userDataDto);
    }

    updateBookingLessDetails(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/updateBookingLessDetailsApi`, userDataDto);
    }

    updateBookingPaymentCompleteDetails(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/updateBookingPaymentCompleteDetailsApi`, userDataDto);
    }

    updateTopartyLRDtlsBkg(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/updateTopartyLRDtlsBkgApi`, userDataDto);
    }

    getBkgPaidFollowupReport(cashMemoDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getBkgPaidFollowupReportApi`, cashMemoDto);
    }

    getBkgPartyOsReport(cashMemoDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getBkgPartyOsReportApi`, cashMemoDto);
    }

    getDifferentMemoLessDetailsBasedOnLessDescBooking(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/getDifferentMemoLessDetailsBasedOnLessDescBooking`, userDataDto);
    }

    getTotalMemoLessAmountBkg(cashMemoDto: Object): Observable<CashMemoDto[]> {
        return this.http.post<CashMemoDto[]>(`${this._baseUrl}/getTotalMemoLessAmountBkg`, cashMemoDto);
    }

    updateEnableHamaliEditDestMemo(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateEnableHamaliEditDestMemo`, lrDto);
    }

    updateMemoTransferV2(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/updateMemoTransferV2Api`, userDataDto);
    }

    updateMemoTransferToCollectionManV2(userDataDto: Object): Observable<UserDataDto[]> {
        return this.http.post<UserDataDto[]>(`${this._baseUrl}/updateMemoTransferToCollectionManV2Api`, userDataDto);
    }

    updateEnableCCCopyDestMemo(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateEnableCCCopyDestMemoApi`, lrDto);
    }

    updateDemurageLREnableDestMemo(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateDemurageLREnableDestMemoApi`, lrDto);
    }

    whatsAppNotificationFlowForDestCollectionClose(userDataDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/whatsAppNotificationFlowForDestCollectionCloseApi`, userDataDto);
    }

    getCashMemoBkgDetailsList(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getcashmemobkgdetailslist`, lrDto);
    }

    collectionManLeftToPartyAckPendingBlockEnable(userDataDto: Object): Observable<UserDataDto> {
        return this.http.post<UserDataDto>(`${this._baseUrl}/collectionManLeftToPartyAckPendingBlockEnableApi`, userDataDto);
    }
}








