<html>

<head>
<style>
.imb_patch {
	text-align: center;
	background-color: orange;
	padding: 2px;
}
</style>
</head>

<body>
	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">
			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">{{title}}</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-12">
						<!-- spinner start-->
						<div *ngIf="showSpinnerForAction" class="col-md-12">
							<div class="form-group">
								<div class="input-group">
									<br> <br>
									<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
									<br>

									<h6 class="card-title" align='center'
										style="color: green; margin-bottom: 10px; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<!-- spinner end-->
						<div class="box-body">
							<table datatable id="{{pageId}}manualOrCollectiontDetailId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsManualOrCollectiontDetails"
								[dtTrigger]="dtTriggerManualOrCollectionDetails">

								<thead>
									<tr>
										<th>LR No</th>
										<th>Memo No.</th>
										<th>Memo Date</th>
										<th>Total Amount</th>
										<th>Party Name</th>
										<th *ngIf="showDest">Destination</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let manualOrCollectionDetailsData of manualOrCollectionDetailsDataList let i = index ">
										<td>{{manualOrCollectionDetailsData.gcNoteNumber }}</td>
										<td>{{ manualOrCollectionDetailsData.memoNumber }}</td>
										<td>{{ manualOrCollectionDetailsData.date }}</td>
										<td>{{ manualOrCollectionDetailsData.grandTotal }}</td>
										<td>{{ manualOrCollectionDetailsData.consigneeName }}</td>
										<td *ngIf="showDest">{{
											manualOrCollectionDetailsData.destination }}</td>

									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td *ngIf="showDest"></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</body>

</html>