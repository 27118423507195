<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" id="{{pageId}}showAdvancePayemntRpt" *ngIf="isLoggedIn">
        <div *ngIf="showSpinnerForAction" class="col-md-8">
            <div class="form-group">
                <div class="input-group">
                    <mat-progress-bar mode="indeterminate" style="color: green;">
                    </mat-progress-bar>
                    <br>
                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                        Please Wait Loading Details.....</h6>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>View By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}viewBy" name="viewBy" #viewBy (change)="viewByMode(viewBy.value)">
															<option selected value="supplierWise">Supplier
																Wise</option>
															<option value="destinationWise">Destination Wise</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Search By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchBy">
															<option selected value="hireslipDateWise">HireSlip
																Date Wise</option>
															<option value="paymentDateWise">Payment Date
																Wise</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Payment Method</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}paymentMethod">
															<option selected value="all">All</option>
															<option value="paid">Paid</option>
															<option value="unPaid">UnPaid</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" [(ngModel)]="setTodayDateOnFromDate" placeholder="dd-mm-yyyy" name="fromDate" ngbDatepicker #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input class="form-control" id="{{pageId}}toDate" placeholder="dd-mm-yyyy" [(ngModel)]="setTodayDateOnToDate" name="toDate" ngbDatepicker #toDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
																class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Supplier Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}supplierName" type="text" class="form-control" [(ngModel)]="modelSupplierName" [ngbTypeahead]="searchSupplierName" [resultFormatter]="formatterSupplierName" [inputFormatter]="formatterSupplierName" (focus)="focusSupplierNameTA$.next($any($event).target.value)"
                                                            placeholder="Select Supplier" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}sourceId" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)" placeholder="Select Source"
                                                        />
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination" (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                            placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod();">Search</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll();">Clear</button>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Hireslip No</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}destinationId" aria-describedby="basic-addon11" readonly autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Amount</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}amount" aria-describedby="basic-addon11" readonly autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Payment Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="payingDates" ngbDatepicker #payingDates="ngbDatepicker" id="{{pageId}}payingDates" [(ngModel)]="modelPayingDates">
                                                        <div class="input-group-append" (click)="payingDates.toggle()">
                                                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Payment Mode</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-weight"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}payemntModeType" #payemntModeType (change)="payemntModeTypeListner(payemntModeType.value)">
                                                            <option value="Cash">Cash
                                                            </option>
                                                            <option selected value="Cheque">Cheque</option>
                                                            <option value="PDC">PDC</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" *ngIf="viewChequePdcNoField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cheque No</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}chequePdcNo" (keyup)="chequePdcNoEvent($event)" aria-describedby="basic-addon11" autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" *ngIf="viewChequeDueDateField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Cheque Due Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="chequeDueDates" ngbDatepicker #chequeDueDates="ngbDatepicker" id="{{pageId}}chequeDueDates" (keyup)="chqDueDateFocus($event)"
                                                            (ngModelChange)="focusChqDueDateCalendar()" [(ngModel)]="modelChequeDueDates" (keydown.arrowdown)="chequeDueDates.toggle();">
                                                        <div class="input-group-append" (click)="chequeDueDates.toggle()">
                                                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" *ngIf="viewChequePdcBankNameField">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <label>Bank Name</label>
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i class="fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <input #bankName id="{{pageId}}bankNameId" type="text" class="form-control" (selectItem)="clickListnerForBankName($event)" [(ngModel)]="modelBankName" [ngbTypeahead]="searchBankName" [resultFormatter]="formatterBankName" [inputFormatter]="formatterBankName"
                                                                (focus)="focusBankNameTA$.next($any($event).target.value)" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12" *ngIf="viewCashChequePdcAmtField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Amount</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="number" class="form-control" id="{{pageId}}cashChequePdcAmount" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressListnerForAmount($event);">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>PAN Number(Against payment made)</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}paymentPanNo" aria-describedby="basic-addon11" autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12" style="text-align: center;">
                                                <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}validatePanAadharBtn" (click)="confirmPanAadharLinkVerification();">Validate PAN Aadhar Link</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <table datatable id="{{pageId}}advancePaymentCashChequePdcTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAdvancePaymentCashChequePdc" [dtTrigger]="dtTriggerAdvancePaymentCashChequePdc">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Cheque</th>
                                    <th>Amount</th>
                                    <th>Bank</th>
                                    <th>Due Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let advancePaymentCashChequePdcData of 
                                advancePaymentChequePdcDataList let i = index">
                                    <td>
                                        {{ advancePaymentCashChequePdcData.paymentType }}</td>
                                    <td>{{ advancePaymentCashChequePdcData.chequeNumber }}</td>
                                    <td>{{ advancePaymentCashChequePdcData.amount }}</td>
                                    <td>{{ advancePaymentCashChequePdcData.bankName }}</td>
                                    <td>{{ advancePaymentCashChequePdcData.pdcDueDate }}</td>
                                    <td>
                                        <i style=" cursor: pointer;" class="fas fa-pencil-alt" title="Edit Bank Details" (click)="rowSelectedBankDataOnEdit(advancePaymentCashChequePdcData,i);"></i> &nbsp;&nbsp;
                                        <i style=" cursor: pointer;" class="fas fa-trash" title="Delete Bank Details" (click)="deleteSelectedBankDetails(i);"></i>
                                    </td>

                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div class="col-md-3">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Total Amount</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}totalAmountToBePaid" aria-describedby="basic-addon11" readonly autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>{{tdsLabel}}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}tdsDeducted" aria-describedby="basic-addon11" readonly autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Advance Payable</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}advancePayable" aria-describedby="basic-addon11" readonly autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Cash</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}summaryCashAmt" aria-describedby="basic-addon11" readonly autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Cheque</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}summaryChequeAmt" aria-describedby="basic-addon11" readonly autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>PDC</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}summaryPDCAmt" aria-describedby="basic-addon11" readonly autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Balance To Be Paid</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}balanceToBePaid" aria-describedby="basic-addon11" readonly autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <button type="submit" class="btn btn-success m-r-10" title="Confirm Payment Details" (click)="validateConfirmPaymentBtn();">Confirm
                            </button>
                            <button type="submit" class="btn btn-success m-r-10" title="TDS Voucher Print" (click)="validateTDSPaymentBtn();">TDS
                            </button>
                            <button type="submit" class="btn btn-dark m-r-10" title="Clear Bank Details" (click)="overAllClearFieldBtn();">Clear
                            </button>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="box-body" [hidden]="!showForSupplierWise">
                                            <table datatable id="{{pageId}}advancePaymentTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAdvancePayment" [dtTrigger]="dtTriggerAdvancePayment">
                                                <thead>
                                                    <tr>
                                                        <th [hidden]=true>Supplier</th>
                                                        <th>Action</th>
                                                        <th>Dest</th>
                                                        <th>Hireslip No</th>
                                                        <th>Hireslip Dt</th>
                                                        <th>Truck No</th>
                                                        <th>Adv</th>
                                                        <th [hidden]="!showHireColumns">Bal</th>
                                                        <th [hidden]="!showHireColumns">Tot Amt</th>
                                                        <th>Payment Dt</th>
                                                        <th>Cash Paid</th>
                                                        <th [hidden]=true>Chq Paid</th>
                                                        <th [hidden]=true>PDC Paid</th>
                                                        <th>Chq Paid</th>
                                                        <th>PDC Paid</th>
                                                        <th>Tot Adv.Paid</th>
                                                        <th>Adv.Bal</th>
                                                        <th>Owner</th>
                                                        <th>Pan No</th>
                                                        <th>TDS Deducted</th>
                                                        <th>View Img</th>
                                                        <th>Re-Upload Img</th>
                                                        <th>Remove Img</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let advancePaymentData of advancePaymentDataList ">
                                                        <td [hidden]=true>{{ advancePaymentData.supplierName }}</td>
                                                        <td *ngIf="advancePaymentData.isLryAdvPaid == true"><button style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}confirmPaymentBtn" (click)="setConfirmPayment(advancePaymentData);">
																	<i title="Confirm Payment" class="fas fa-edit"></i>
																</button>
                                                            <button style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}voucherPrintBtn" (click)="setPrintVoucher(advancePaymentData);">
																	<i title="Print Voucher" class="fas fa-print"></i>
																</button></td>

                                                        <td *ngIf="advancePaymentData.isLryAdvPaid == false"><button style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}confirmPaymentBtn" (click)="setConfirmPayment(advancePaymentData);">
																	<i title="Confirm Payment" class="fas fa-edit"></i>
																</button></td>
                                                        <td>{{ advancePaymentData.toStation }}</td>
                                                        <td>{{ advancePaymentData.hireslipnumber }}</td>
                                                        <td>{{ advancePaymentData.hireSlipDateStr }}</td>
                                                        <td>{{ advancePaymentData.vehicleNumber }}</td>
                                                        <td>{{ advancePaymentData.advance }}</td>
                                                        <td [hidden]="!showHireColumns">{{ advancePaymentData.balance }}</td>
                                                        <td [hidden]="!showHireColumns">{{ advancePaymentData.totalhire }}</td>
                                                        <td>{{ advancePaymentData.paymentDateStr }}</td>
                                                        <td>{{ advancePaymentData.cashAmt }}</td>
                                                        <td [hidden]=true>{{ advancePaymentData.advTotalChqAmt }}</td>
                                                        <td [hidden]=true>{{ advancePaymentData.advTotalPdcAmt }}</td>
                                                        <td style="cursor: pointer; color: blue;" (click)="getChqInfoTable(advancePaymentData)"><u>
 																	{{ advancePaymentData.advTotalChqAmt }}</u></td>
                                                        <td style="cursor: pointer; color: blue;" (click)="getPdcInfoTable(advancePaymentData)"><u>{{ 
 																	advancePaymentData.advTotalPdcAmt }}</u></td>
                                                        <td>{{ advancePaymentData.advTotalPaid }}</td>
                                                        <td>{{ advancePaymentData.advBalToBePaid }}</td>
                                                        <td>{{ advancePaymentData.companyName }}</td>
                                                        <td>{{ advancePaymentData.panNo }}</td>
                                                        <td>{{ advancePaymentData.tdsAmount }}</td>
                                                        <td style="cursor: pointer; color: blue;">
                                                            <a (click)="getImg(advancePaymentData,advancePaymentPopUpTemplate);">
                                                                <u>{{ advancePaymentData.imgURLPan != null ? "View
																		Declaration" : "Upload" }}</u>
                                                            </a>
                                                        </td>

                                                        <td style="cursor: pointer; color: blue;">
                                                            <a (click)="getImgForReUpload(advancePaymentData,advancePaymentPopUpTemplate);">
                                                                <u>{{ advancePaymentData.imgURLPan != null ?
																		"Re-Upload" : "----" }}</u>
                                                            </a>
                                                        </td>
                                                        <td style="cursor: pointer; color: blue;">
                                                            <a (click)="getImgForRemove(advancePaymentData);"> <u>{{
																		advancePaymentData.imgURLPan != null ? "Remove Image"
																		: "----" }}</u></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td [hidden]=true></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td [hidden]="!showHireColumns"></td>
                                                        <td [hidden]="!showHireColumns"></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td [hidden]=true></td>
                                                        <td [hidden]=true></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="box-body" [hidden]="!showForDestWise">
                                            <table datatable id="{{pageId}}advancePaymentTableIdDestWise" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsAdvancePaymentDestWise" [dtTrigger]="dtTriggerAdvancePaymentDestWise">

                                                <thead>
                                                    <tr>
                                                        <th [hidden]=true>Dest</th>
                                                        <th>Action</th>
                                                        <th>Supplier</th>
                                                        <th>Hireslip No</th>
                                                        <th>Hireslip Dt</th>
                                                        <th>Truck No</th>
                                                        <th>Adv</th>
                                                        <th [hidden]="!showHireColumns">Bal</th>
                                                        <th [hidden]="!showHireColumns">Tot Amt</th>
                                                        <th>Payment Dt</th>
                                                        <th>Cash Paid</th>
                                                        <th [hidden]=true>Chq Paid</th>
                                                        <th [hidden]=true>PDC Paid</th>
                                                        <th>Chq Paid</th>
                                                        <th>PDC Paid</th>
                                                        <th>Tot Adv.Paid</th>
                                                        <th>Adv.Bal</th>
                                                        <th>Owner</th>
                                                        <th>Pan No</th>
                                                        <th>TDS Deducted</th>
                                                        <th>View Img</th>
                                                        <th>Re-Upload Img</th>
                                                        <th>Remove Img</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let advancePaymentDataDestWise of advancePaymentDataListDestWise ">
                                                        <td [hidden]=true>{{ advancePaymentDataDestWise.toStation }}</td>
                                                        <td *ngIf="advancePaymentDataDestWise.isLryAdvPaid == true"><button style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}confirmPaymentBtn" (click)="setConfirmPayment(advancePaymentDataDestWise);">
																	<i title="Confirm Payment" class="fas fa-edit"></i>
																</button>
                                                            <button style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}voucherPrintBtn" (click)="setPrintVoucher(advancePaymentDataDestWise);">
																	<i title="Print Voucher" class="fas fa-print"></i>
																</button></td>

                                                        <td *ngIf="advancePaymentDataDestWise.isLryAdvPaid == false"><button style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}confirmPaymentBtn" (click)="setConfirmPayment(advancePaymentDataDestWise);">
																	<i title="Confirm Payment" class="fas fa-edit"></i>
																</button></td>
                                                        <td>{{ advancePaymentDataDestWise.supplierName }}</td>
                                                        <td>{{ advancePaymentDataDestWise.hireslipnumber }}</td>
                                                        <td>{{ advancePaymentDataDestWise.hireSlipDateStr }}</td>
                                                        <td>{{ advancePaymentDataDestWise.vehicleNumber }}</td>
                                                        <td>{{ advancePaymentDataDestWise.advance }}</td>
                                                        <td [hidden]="!showHireColumns">{{ advancePaymentDataDestWise.balance }}</td>
                                                        <td [hidden]="!showHireColumns">{{ advancePaymentDataDestWise.totalhire }}</td>
                                                        <td>{{ advancePaymentDataDestWise.paymentDateStr }}</td>
                                                        <td>{{ advancePaymentDataDestWise.cashAmt }}</td>
                                                        <td [hidden]=true>{{ advancePaymentDataDestWise.advTotalChqAmt }}</td>
                                                        <td [hidden]=true>{{ advancePaymentDataDestWise.advTotalPdcAmt }}</td>
                                                        <td style="cursor: pointer; color: blue;" (click)="getChqInfoTable(advancePaymentDataDestWise)"><u>
 																	{{ advancePaymentDataDestWise.advTotalChqAmt }}</u></td>
                                                        <td style="cursor: pointer; color: blue;" (click)="getPdcInfoTable(advancePaymentDataDestWise)"><u>{{ 
 																	advancePaymentDataDestWise.advTotalPdcAmt }}</u></td>
                                                        <td>{{ advancePaymentDataDestWise.advTotalPaid }}</td>
                                                        <td>{{ advancePaymentDataDestWise.advBalToBePaid }}</td>
                                                        <td>{{ advancePaymentDataDestWise.companyName }}</td>
                                                        <td>{{ advancePaymentDataDestWise.panNo }}</td>
                                                        <td>{{ advancePaymentDataDestWise.tdsAmount }}</td>
                                                        <td style="cursor: pointer; color: blue;">
                                                            <a (click)="getImg(advancePaymentDataDestWise,advancePaymentPopUpTemplate);">
                                                                <u>{{ advancePaymentDataDestWise.imgURLPan != null ? "View
																		Declaration" : "Upload" }}</u>
                                                            </a>
                                                        </td>

                                                        <td style="cursor: pointer; color: blue;">
                                                            <a (click)="getImgForReUpload(advancePaymentDataDestWise,advancePaymentPopUpTemplate);">
                                                                <u>{{ advancePaymentDataDestWise.imgURLPan != null ?
																		"Re-Upload" : "----" }}</u>
                                                            </a>
                                                        </td>
                                                        <td style="cursor: pointer; color: blue;">
                                                            <a (click)="getImgForRemove(advancePaymentDataDestWise);"> <u>{{
																		advancePaymentDataDestWise.imgURLPan != null ? "Remove Image"
																		: "----" }}</u></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td [hidden]=true></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td [hidden]="!showHireColumns"></td>
                                                        <td [hidden]="!showHireColumns"></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td [hidden]=true></td>
                                                        <td [hidden]=true></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <ng-template #advancePaymentPopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-header">
                        <h5>Declaration Upload</h5>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
						<span aria-hidden="true">&times;</span>
					</button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div *ngIf="showSpinnerForActionForUpload" class="col-md-12 p-t-10">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <mat-progress-bar mode="indeterminate" style="color: green;">
                                                    </mat-progress-bar>
                                                    <br>
                                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                        Please Wait Uploading Documnents....</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label> Copy:</label> <input type="file" id="{{pageId}}advancePaymentFileUpload" (change)="onFileChangedAdvancePayment($event)" #fileInputLeftToPartyStmt1 />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3">
                                            <button style="float: right;" type="button" class="btn btn-primary m-r-10" id="{{pageId}}uploadBtnId" (click)="uploadAdvancePaymentBtnPopUp()">Upload</button>
                                        </div>

                                    </div>
                                    <br>
                                    <div class="row">


                                        <div class="col-sm-12 col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeAdvancePaymentBtnPopUp()">Cancel</button>
                                            <button style="float: right;" type="button" class="btn btn-success m-r-10" id="{{pageId}}applyBtnId" (click)="applyAdvancePaymentBtnPopUp()">Apply</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div *ngIf="viewPaymentVoucher " onafterprint="afterPrint()" id="{{pageId}}printPaymentVoucher">
        <app-payment-voucher-rpt-print></app-payment-voucher-rpt-print>
    </div>

    <div *ngIf="viewPaymentVoucherChq" onafterprint="afterPrint()" id="{{pageId}}printPaymentVoucherChq">
        <app-payment-voucher-rpt-print></app-payment-voucher-rpt-print>
    </div>

    <div *ngIf="viewPaymentVoucherPdc" onafterprint="afterPrint()" id="{{pageId}}printPaymentVoucherPdc">
        <app-payment-voucher-rpt-print></app-payment-voucher-rpt-print>
    </div>

    <div *ngIf="viewTdsVoucher " onafterprint="afterPrint()" id="{{pageId}}printTdsVoucher">
        <app-hireslip-advance-tds-voucher-print></app-hireslip-advance-tds-voucher-print>
    </div>

    <div *ngIf="viewTdsVoucherWithPayment " onafterprint="afterPrint()" id="{{pageId}}printTdsVoucher">
        <app-hireslip-advance-tds-voucher-print></app-hireslip-advance-tds-voucher-print>
    </div>

</body>

</html>