//import { Component, OnInit } from '@angular/core';
//import { Router } from "@angular/router";
//import { ParamMap } from "@angular/router";
//import { Route } from "@angular/router";
//import { switchMap } from "rxjs/internal/operators/switchMap";
//import { ActivatedRoute } from "@angular/router";
//import { RateMasterDto } from "src/app/dto/RateMaster-dto";
////for datepicker starts
//import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
//import { DatePipe } from "@angular/common";
////for datepicker ends
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
import swal from 'sweetalert';
//for datepicker ends
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//for popup modal endss
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
import { Router, NavigationExtras } from "@angular/router";
import { MemoService } from "src/app/dataService/memo-service";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { LrService } from 'src/app/dataService/lr-service';
@Component({
    selector: 'app-lrentryform-barcode-print',
    templateUrl: './lrentryform-barcode-print.component.html',
    styleUrls: ['./lrentryform-barcode-print.component.css']
})
export class LrEntryFormBarcodePrintComponent implements OnInit {

    PrintBarcode = [];
    PrintBarcodeList = [];
    userDataDtoReturnSession: any;
    isLoggedIn = true;

    dataForPrint: any;
    resultDtos: LRDto = new LRDto();
    viewLrEntryBarcodePrintPage = true;
    uniqueText = null;
    saveLrDto: LRDto = new LRDto();
    lrDtosSaveList: Array<LRDto> = [];
    pageId = "lefbpc";

    constructor(private router: Router,
        private datePipe: DatePipe,
        private http: HttpClient,
        private lrService: LrService,
        public changeDetectorRef: ChangeDetectorRef
    ) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                //                this.logInPage();
            })
            //            sweet alert ends
        }

        if (this.isLoggedIn) {

            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.dataForPrint = JSON.parse(localStorage.getItem('printAfterSaveForLrEntry'));

            localStorage.clear();
            if (this.dataForPrint != null) {
                this.resultDtos = this.dataForPrint;

            }
            // this.uniqueCodeGenerator();
            // this.getLrBarcodeValueOnSave()

            window.onafterprint = function () {
                window.close();
            }

            window.addEventListener('afterprint', (event) => {
                if (this.viewLrEntryBarcodePrintPage) {
                    this.viewLrEntryBarcodePrintPage = false;
                    this.clearPrintFields();
                }
            });
            //            if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights != null ) {
            //                for ( let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++ ) {
            //                    if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            //                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
            //                        "LrEntryPrintAll For Single Station" ) {
            //                        this.mainStationForPrint = 'LrEntryPrintAll For Single Station';
            //
            //                    }
            //                }
            //            }

        }
    }

    ngOnInit() {
    }


    //For Clearing the Print Fields
    clearPrintFields() {
        this.dataForPrint = '';
        this.resultDtos = new LRDto();
        this.uniqueText = null;
        this.saveLrDto = new LRDto();
        this.lrDtosSaveList = [];
        this.PrintBarcode = [];
        this.PrintBarcodeList = [];
        console.log('Its Cleared');

    }

    uniqueCodeGenerator() {

        var barcodeValue = "A100";
        console.log("this.uniqueText");
        console.log(this.uniqueText);
        for (var j = 0; j < 5; j++) {
            this.uniqueText = "";
            console.log("this.uniqueText, j, barcodeValue");
            console.log(this.uniqueText, j, barcodeValue);
            this.PrintBarcode = [];
            this.uniqueText = (barcodeValue + j);
            console.log("this.uniqueText");
            console.log(this.uniqueText);
            this.PrintBarcode = [{
                barcodeValue: this.uniqueText,
            }];
            this.PrintBarcodeList.push(this.PrintBarcode);
            this.PrintBarcodeList.push(this.PrintBarcode);
            this.PrintBarcodeList.push(this.PrintBarcode);
            // this.PrintBarcodeList.push(this.PrintBarcode);
            console.log("this.PrintBarcode");
            console.log(this.PrintBarcode);
        }
        // this.saveLrDto.lrNumber=this.resultDtos.lrNumber;
        // console.log("this.PrintBarcodeList, this.PrintBarcode");
        // console.log(this.PrintBarcodeList, this.PrintBarcode);


    }




    getLrBarcodeValueOnSave = () => {
        console.log("this.lrDtosSaveList");
        console.log(this.lrDtosSaveList);
        this.uniqueCodeGenerator();
        this.lrService.saveLRBarcode(this.lrDtosSaveList).subscribe(
            (response) => {
                console.log("response");
                console.log(response);
                if (response.status == "success") {
                    console.log("this.lrDtosSaveList >> response");
                    console.log(this.lrDtosSaveList);
                    for (var j = 0; j < this.lrDtosSaveList.length; j++) {
                        this.PrintBarcode = [];
                        this.PrintBarcode = [{
                            lrnumber: this.lrDtosSaveList[j].lrNumber,
                            barcodeValue: this.lrDtosSaveList[j].barcodeValue,
                            destination: response.stationCode,
                            article: this.lrDtosSaveList[j].totalArticles,
                            // lrnumber: "SRD1234567",
                            // barcodeValue: this.lrDtosSaveList[j].barcodeValue,
                            // destination: "ABCD",
                            // article: "999",
                            dispArt: (j + 1) + "/" + this.lrDtosSaveList[j].totalArticles
                        }];
                        this.PrintBarcodeList.push(this.PrintBarcode);
                        this.PrintBarcodeList.push(this.PrintBarcode);

                    }
                    window.setTimeout(function () {
                        window.print();
                    }, 1000);
                    console.log("this.PrintBarcodeList");
                    console.log(this.PrintBarcodeList);
                } else {

                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {

                swal("Error", "Server Problem Occurred While getting the  Invoice LR details", "info");
            }, () => console.log('done');
    };
}
