<!-- 		<button type="button" -->
<!-- 				style="margin-left: 350px;margin-top: -80px;" -->
<!-- 				class="dt-button" id="{{pageId}}tableReloadBtn"(click)="gridReconifgureOnReloadBtn()"> -->
<!-- 				<i title="Reload" class="fas fa-undo"></i> -->
<!-- 			</button> -->
<!-- <i title="Reload" class="fas fa-sync-alt fa xs cli"
	style="margin-left: 20px; margin-bottom: 10px; padding: 2px 5px; background-color: burlywood;"
	(click)="gridReconifgureOnReloadBtn()"></i> -->
<div class="col-md-12 col-sm-12">
	<div class="row">
		<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
			<div class="form-group">
				<div class="input-group">
					<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
					<br>
					<h6 class="card-title" align='center'
						style="color: green; margin: auto; font-size: 15px;">Please
						Wait Loading....</h6>
				</div>
			</div>
		</div>
		<div class="col-md-12 col-sm-12">
			<div class="row" style="padding: 5px 10px;padding-bottom: 0px;">
				<div class="col-md-10">
					<h6 class="card-title">
						<i class=" fas fa-file-alt"></i>&nbsp;Left To Party Statements - Pending
					</h6>
			
				</div>
				<div class="col-md-2">
				<i title="Reload" class="fas fa-sync-alt fa xs cli"
					style="float: right;margin-bottom: 10px;padding: 2px 5px;cursor: pointer;"
					(click)="gridReconifgureOnReloadBtn()"></i>
				</div>
			</div>
		</div>
	</div>
	<div class="box-body">
		<table datatable id='{{pageId}}leftToPartyStatementPendingId'
			style='max-height: 150px;'
			class="table table-striped table-bordered row-border hover"
			[dtOptions]="dtOptionsLeftToPartyStatementPending"
			[dtTrigger]="dtTriggerLeftToPartyStatementPending">

			<thead>
				<tr>
					<th style='padding-right: 35px;'>Stmt No</th>
					<th>Date&nbsp;&nbsp;&nbsp;&nbsp;</th>
					<th style='padding-right: 100px;'>Party Name</th>
					<th>Collection Man</th>
					<th>Bal Amt</th>
					<th>Cashmemo</th>
					<th>Pending Since(Days)</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="let leftToPartyStatementPendingData of leftToPartyStatementPendingDataList ">
					<td (click)="stmtNoDialog(leftToPartyStatementPendingData)"><u
						class="hyperLink_td">{{
							leftToPartyStatementPendingData.statementNo }}</u></td>
					<td>{{ leftToPartyStatementPendingData.stmtDateStr }}</td>
					<td>{{ leftToPartyStatementPendingData.consigneeName }}</td>
					<td>{{ leftToPartyStatementPendingData.collectionMan }}</td>
					<td>{{ leftToPartyStatementPendingData.grandTotal }}</td>
					<td>{{ leftToPartyStatementPendingData.totalMemos }}</td>
					<td>{{ leftToPartyStatementPendingData.daysDiff }}</td>
				</tr>


				<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

			</tbody>
		</table>
	</div>
</div>