<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Regular Consignee Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-12">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-12">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">
										<div class="col-sm-12 col-md-3">
											<div class="form-group">
												<div class="input-group">
													<label>Search By</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-search"></i>
														</span>
													</div>
													<select class="custom-select col-12" id="{{pageId}}searchBy"
														name="searchBy">
														<option selected value="sourceWise">Source Wise</option>
														<option value="mainSourceWise">Main Source Wise</option>
													</select>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-3">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}fromDate">
													<label>From Date</label> <input class="form-control"
														placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker
														#fromDates="ngbDatepicker">
													<div class="input-group-append"
														(click)="fromDates.toggle()">
														<span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
										<div class="col-sm-12 col-md-3">
											<div class="form-group">
												<div class="input-group">
													<label>To Date</label> <input id="{{pageId}}toDate"
														class="form-control" placeholder="yyyy-mm-dd"
														name="toDates" ngbDatepicker #toDates="ngbDatepicker">
													<div class="input-group-append" (click)="toDates.toggle()">
														<span class="input-group-text"> <i
															class="fa fa-calendar"></i>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<hr class="custome_border">
							<div class="row">
								<div class="col-lg-12">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">
										<!-- 											the first autocomplete starts -->
										<div class="col-sm-12 col-md-2">

											<div class="control">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}source">
														<label>Source</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
														</div>
														<input class="auto_selectOption input is-medium"
															placeholder="Select Source" [formControl]="controlSource"
															[appAutocomplete]="autocompleteSource">
													</div>
												</div>
											</div>

											<app-autocomplete #autocompleteSource="appAutocomplete">
											<ng-template appAutocompleteContent> <ng-container
												*ngIf="(sourceOptions | filter: controlSource.value) as resultSource">
											<app-option *ngFor="let option of resultSource"
												[value]="option.label"> {{ option.label }} </app-option> <app-option
												class="no-result" *ngIf="!resultSource.length">No
											result</app-option> </ng-container> </ng-template> </app-autocomplete>
										</div>
										<!-- 											the first autocomplete ends -->

										<!-- 											the second autocomplete starts -->
										<div class="col-sm-12 col-md-3">

											<div class="control">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}destination">
														<label>Destination</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-road"></i>
															</span>
														</div>
														<input class="auto_selectOption input is-medium"
															placeholder="Select Destination"
															[formControl]="controlDestination"
															[appAutocomplete]="autocompleteDestination">
													</div>
												</div>
											</div>

											<app-autocomplete #autocompleteDestination="appAutocomplete">
											<ng-template appAutocompleteContent> <ng-container
												*ngIf="(destinationOptions | filter: controlDestination.value) as resultDestination">
											<app-option *ngFor="let option of resultDestination"
												[value]="option.label"> {{ option.label }} </app-option> <app-option
												class="no-result" *ngIf="!resultDestination.length">No
											result</app-option> </ng-container> </ng-template> </app-autocomplete>
										</div>
										<!-- 											the second autocomplete ends -->

										<!-- 											the third autocomplete starts -->
										<div class="col-sm-12 col-md-3">

											<div class="control">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}commodityList">
														<label>Commodity List</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-box"></i>
															</span>
														</div>
														<input class="auto_selectOption input is-medium"
															placeholder="Select Commodity"
															[formControl]="controlCommodityList"
															[appAutocomplete]="autocompleteCommodityList">
													</div>
												</div>
											</div>

											<app-autocomplete
												#autocompleteCommodityList="appAutocomplete">
											<ng-template appAutocompleteContent> <ng-container
												*ngIf="(commodityListOptions | filter: controlCommodityList.value) as resultCommodityList">
											<app-option *ngFor="let option of resultCommodityList"
												[value]="option.label"> {{ option.label }} </app-option> <app-option
												class="no-result" *ngIf="!resultCommodityList.length">No
											result</app-option> </ng-container> </ng-template> </app-autocomplete>
										</div>
										<!-- 											the third autocomplete ends -->

										<div class="col-sm-12 col-md-2">
											<div class="form-group">
												<div class="input-group" id="{{pageId}}periodDay">
													<label class="custome_label">Per Month LR(Avg)</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-calendar"></i>
														</span>
													</div>
													<input type="number" class="form-control"
														aria-describedby="basic-addon11">
												</div>
											</div>
										</div>
										<div class="col-md-2" style="text-align: center;">
											<button type="submit"
												class="btn btn-success custome_padding_btn m-r-10"
												id="{{pageId}}searchBtn">Search</button>
											<button type="submit"
												class="btn btn-dark custome_padding_btn" id="{{pageId}}clearBtn">Clear</button>
										</div>

									</div>
								</div>
							</div>
							<hr class="custome_border">
							<div class="row">
								<div class="col-lg-12">
									<div class="row">
										<div class="col-md-3">
											<div class="box-body">
												<h6 class="card-title">Regular Consignee Report Summary</h6>
												<table datatable
													class="table table-striped table-bordered row-border hover"
													style="height: 240px;"
													[dtOptions]="dtOptionsRegularConsigneeReportSummary"
													[dtTrigger]="dtTriggerRegularConsigneeReportSummary">

													<thead>
														<tr>
															<th>Consignee Name</th>
															<th>Number Of Lrs</th>
														</tr>
													</thead>
													<tbody>
														<tr
															*ngFor="let regularConsigneeReportSummaryData of regularConsigneeReportSummaryDataList ">
															<td>{{
																regularConsigneeReportSummaryData.consigneeName }}</td>
															<td>{{ regularConsigneeReportSummaryData.numberOfLrs
																}}</td>

														</tr>


														<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

													</tbody>
												</table>
											</div>
										</div>
										<!-- 						the second datatable starts -->
										<div class="col-md-9 vl">
											<div [hidden]=" regularConsigneeReportDetailsTable">
												<h6 class="card-title">Regular Consignee Report Details</h6>
												<table datatable
													class="table table-striped table-bordered row-border hover"
													style="height: 240px;"
													[dtOptions]="dtOptionsRegularConsigneeReportDetails"
													[dtTrigger]="dtTriggerRegularConsigneeReportDetails">

													<thead>
														<tr>
															<th>Booking Date</th>
															<th>Lr Number</th>
															<th>Source</th>
															<th>Destination</th>
															<th>Commodity</th>
															<th>Articles</th>
															<th>Actual Wgt</th>
															<th>Charged Wgt</th>
															<th>To Pay</th>
															<th>Paid</th>
															<th>Consignor Name</th>

														</tr>
													</thead>
													<tbody>
														<tr
															*ngFor="let regularConsigneeReportDetailsData of regularConsigneeReportDetailsDataList ">
															<td>{{ regularConsigneeReportDetailsData.bookingDate
																}}</td>
															<td>{{ regularConsigneeReportDetailsData.lrNumber }}</td>
															<td>{{ regularConsigneeReportDetailsData.source }}</td>
															<td>{{ regularConsigneeReportDetailsData.destination
																}}</td>
															<td>{{ regularConsigneeReportDetailsData.commodity
																}}</td>
															<td>{{ regularConsigneeReportDetailsData.articles }}</td>
															<td>{{ regularConsigneeReportDetailsData.actualWgt
																}}</td>
															<td>{{ regularConsigneeReportDetailsData.chargedWgt
																}}</td>
															<td>{{ regularConsigneeReportDetailsData.toPay }}</td>
															<td>{{ regularConsigneeReportDetailsData.paid }}</td>
															<td>{{
																regularConsigneeReportDetailsData.consignorName }}</td>
														</tr>


														<!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

													</tbody>
												</table>
											</div>
											<!-- 						the second datatable ends -->
										</div>

									</div>
								</div>

							</div>


						</div>
					</div>

				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>