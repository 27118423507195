import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { LrReportService } from 'src/app/dataService/lr-report-service';

@Component({
    selector: 'app-lr-advance',
    templateUrl: './lr-advance.component.html',
    styleUrls: ['./lr-advance.component.css']
})
export class LrAdvanceComponent implements OnInit {

    lrAdvanceDataList: any;
    model: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    public modelDestinationFotGet: any;
    public modelConsigneeNameId: any
    selectedConsigneeId: any;
    selectedDestination: any;
    selectedConsigneeName: any;
    searchConsigneeDto: PartyMasterDto = new PartyMasterDto();
    selectedFromDate: any;
    selectedToDate: any;
    showSpinnerForAction: any;
    toDateModel: any;
    isDestView = false;
    showDest = false;
    selectedLRNo: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerLrAdvance: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLrAdvance: any;
    fromDateModel: any;
    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;
    //For Consignee
    consigneeNameOptions: any;
    consigneeNameTATemp: Array<PartyMasterDto> = [];
    partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
    consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    public modelConsigneeName: any;
    consigneeNameTA: Array<PartyMasterDto> = [];
    focusConsigneeNameTA$ = new Subject<string>();
    consigneeNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeNameTA
                : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

    address: any;
    logInPage: any;
    isSourceView: any;
    showSource: boolean;
    isSourceBkgView: any;
    lrdto: LRDto = new LRDto;
    lrReport: any;
    lrData: any;
    lrRptTablelist: any[];
    pageId = "lrac";

    constructor(private router: Router, private masterReadService: MasterReadService, private lrReportService: LrReportService,
        public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.getDestinationDetails();
            this.getConsigneeDetails(false);
        }

    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnInit(): void {


        this.dtOptionsLrAdvance = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
                    footer: true,
                    exportOptions: {
                        columns: ':visible'
                    }
                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true
                }
            ],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalarticles = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actualWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var chargedWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var baseFreight = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var paid = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var goodsval = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var FOV = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var memoAmt = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(4).footer()).html(totalarticles);
                $(api.column(7).footer()).html(actualWgt);
                $(api.column(8).footer()).html(chargedWgt);
                $(api.column(9).footer()).html(baseFreight);
                $(api.column(10).footer()).html(paid);
                $(api.column(11).footer()).html(toPay);
                $(api.column(12).footer()).html(goodsval);
                $(api.column(13).footer()).html(FOV);
                $(api.column(14).footer()).html(memoAmt);
            }
        }

    }
    ngOnDestroy(): void {
        this.dtTriggerLrAdvance.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerLrAdvance.next();
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends
    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];

                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                        }

                    }
                }
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log('done');


    };
    getConsigneeDetailsList(destShow) {
        this.consigneeNameOptions = [];
        this.showSpinnerForAction = true;
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        this.selectedDestination = this.modelDestinationFotGet;
        if (destShow) {
            this.partyMasterDtoForconsignee.office = this.selectedDestination.destination;
            console.log(this.selectedDestination);
        } else {
            this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        }
        this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignee.mode = "specific";
        console.log(this.partyMasterDtoForconsignee);
    }
    getConsigneeDetails = (destShow) => {
        this.getConsigneeDetailsList(destShow);

        this.showSpinnerForAction = true;
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignee Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val('');
                } else {
                    this.consigneeNameOptions = response;
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val('');
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                    }
                    this.consigneeNameTATemp = this.consigneeNameTA;
                    console.log(this.consigneeNameTA);
                    if (destShow) {
                        $("#" + this.pageId + "consigneeName").focus();
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignee details", "info");
            }, () => console.log('done');
    };

    consigneeDestListener(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestinationFotGet = e.item;
        this.selectedDestination = this.modelDestinationFotGet.destinationId;
        this.getConsigneeDetails(true);
    }
    rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsigneeNameId = e.item;
        this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
        console.log(this.selectedConsigneeId);
        console.log(this.modelConsigneeNameId);
    }
    searchMethod() {
        //this.showSpinnerForAction = true;
        this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        console.log(this.selectedConsigneeName);
        console.log(this.selectedDestination);
        console.log(this.selectedFromDate);
        console.log(this.selectedToDate);
        this.searchConsigneeDto = new PartyMasterDto();
        if ((this.selectedConsigneeName == null) || (this.selectedConsigneeName == undefined) ||
            (this.selectedConsigneeName == '') || (this.selectedDestination == null) ||
            (this.selectedDestination == undefined) || (this.selectedDestination == '')) {
            swal("Mandatory Fields", "Please select the mandatory field", "warning");
            return false;
        } else {
            this.lrAdvanceDataListDetail();
        }
    }

    lrAdvanceData() {
        this.lrdto = new LRDto();
        this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();

        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        if ((this.selectedFromDate != null && this.selectedFromDate != '') &&
            (this.selectedFromDate != null && this.selectedFromDate != '')) {
            this.lrdto.fromDate = this.selectedFromDate;
            this.lrdto.toDate = this.selectedToDate;
        }

        this.lrdto.destination = this.selectedDestination;
        this.lrdto.consigneeId = this.selectedConsigneeId;
        this.lrdto.companyId = this.userDataDtoReturnSession.companyId;

        console.log(this.lrdto);

    }
    lrAdvanceDataListDetail() {
        this.showSpinnerForAction = true;
        this.lrAdvanceData();
        this.lrReportService.getLREnquiryAdvanceDetails(this.lrdto).subscribe(
            (response) => {
                $("#" + this.pageId + "lrAdvTable").DataTable().destroy();
                this.lrAdvanceDataList = [];

                if (response.length > 0) {
                    console.log(response);
                    this.lrAdvanceDataList = response;
                    this.showSpinnerForAction = false;
                } else {
                    this.lrAdvanceDataList = [];
                    this.showSpinnerForAction = false;
                    swal("No Records", "No Records found for this search", "info");

                }
                this.dtTriggerLrAdvance.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Lr Enquiry Advance Details", "info");
            }, () => console.log('done');
    }



    clearMethod() {
        $("#" + this.pageId + "lrAdvTable").DataTable().destroy();
        this.lrAdvanceDataList = [];
        this.dtTriggerLrAdvance.next();

        $("#" + this.pageId + "consigneeName").val('');
        $("#" + this.pageId + "consignorName").val('');
        $("#" + this.pageId + "fromDate").val('');
        $("#" + this.pageId + "toDate").val('');
        $("#" + this.pageId + "consignorGSTNumber").val('');
        $("#" + this.pageId + "consigneeGSTNumber").val('');
        this.fromDateModel = null;
        this.toDateModel = null;
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "source").val('');
        $("#" + this.pageId + "consignorIndex").val('');
        $("#" + this.pageId + "viewBy").val('sourceView');

        this.selectedConsigneeName = '';

        this.selectedFromDate = '';
        this.selectedToDate = '';


        this.modelConsigneeNameId = '';

        this.modelDestinationFotGet = '';

        if (this.isDestView) {
            this.showDest = true;
            this.consigneeNameTA = [];
        } else {
            this.consigneeNameTA = this.consigneeNameTATemp;
            $("#" + this.pageId + "consigneeName").val('All');
        }
        if (this.isSourceView) {
            this.showSource = true;
        }
        if (this.isSourceBkgView) {
            this.showSource = true;
        }
        this.showSpinnerForAction = false;
    }

    lrDetialsMethod(actionType: string) {
        console.log("Hai am Lr advance");
        console.log(actionType);
        console.log('hello1..lrTrack');
        this.selectedLRNo = actionType;
        console.log(this.selectedLRNo);
        localStorage.clear();
        localStorage.setItem('searchBy', "lrnumber");
        localStorage.setItem('enteredLrnumber', this.selectedLRNo);
        //this.lrEnquiryFormComponent.callFromOtherPage(this.selectedLRNo,"LR Advance");
        this.router.navigate(['/lr/enquiry/lrEnquiry']);

    }
}
