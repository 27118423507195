import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { MasterService } from 'src/app/dataService/master-service';
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from 'rxjs/operators';
import { LRDto } from 'src/app/dto/LR-dto';
import { FormControl } from '@angular/forms';
import { StockNotificationSetupComponent } from '../stock-notification-setup/stock-notification-setup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-edit-company',
    templateUrl: './edit-company.component.html',
    styleUrls: ['./edit-company.component.css']
})
export class EditCompanyComponent implements OnInit {

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    userDataDtoSaveRet: any;
    editCompanyDataList: any;
    emails: string;
    allEmails: any;
    //allEmails:Array<any> =[];
    selectedEmail: string;
    emailIdNew: string;
    private newAttribute: any = {};
    editEmailId = null;
    userDataDto: UserDataDto = new UserDataDto();
    userDataDtoGet: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerEditCompany: Subject<any> = new Subject();
    dtOptionsEditCompany: any;

    dtTriggerOtherChargeDatatable: Subject<any> = new Subject();
    dtOptionsOtherChargeDatatable: any;
    editCompanyId = 0;

    controlOtherChargeArea = new FormControl();
    otherChargeAreaOptions: LRDto[];
    lrDto: LRDto = new LRDto();

    public modelOtherChargeArea: any;
    otherChargeAreaTA: Array<LRDto> = [];
    focusOtherChargeAreaTA$ = new Subject<string>();
    searchOtherChargeArea = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusOtherChargeAreaTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.otherChargeAreaTA
                : this.otherChargeAreaTA.filter(v => v.destination)).slice(0, 200))
        );
    }
    formatterOtherChargeArea = (x: { destination: string }) => x.destination;

    gridTosetOtherChargeList: Array<LRDto> = [];
    private newAttributeTosetOtherCharges: any = {};
    editOtherChargesId = null;

    lrDtosOtherChargesReturnType: Array<LRDto> = [];
    lrDtosOtherCharge: LRDto = new LRDto();
    hideShowSRDPercInputField = true;
    hideShowHamaliPercInputField = true;
    rightsForMainStation = "rights to show Srd Perc and Hamali Perc only for chennai";
    pageId = "eccpt";
    showLoadingChgSetup: boolean = false;

    constructor(private masterReadService: MasterReadService, public changeDetectorRef: ChangeDetectorRef,
        private router: Router, private masterService: MasterService, public dialog: MatDialog) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getEditCompanyList();
            this.editCompanyDatatable();
            this.getBranchAreaDetailsList();
            //if(this.rightsForMainStation == "rights to show Srd Perc and Hamali Perc only for chennai"){
            if (this.userDataDtoReturnSession.mainStation == "Chennai") {
                this.hideShowSRDPercInputField = true;
                this.hideShowHamaliPercInputField = true;
            } else {
                this.hideShowSRDPercInputField = false;
                this.hideShowHamaliPercInputField = false;
            }

            if (this.userDataDtoReturnSession.showdestmemoloadingchgsetup != null && this.userDataDtoReturnSession.showdestmemoloadingchgsetup) {
                this.showLoadingChgSetup = true;
            }
        }

    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            // dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
            dtElement.dtInstance.then((dtInstance: any) => {
                // Do your stuff
                //dtInstance.destroy();
            });
        });
    }
    ngAfterViewInit(): void {
        this.dtTriggerOtherChargeDatatable.next();
    }
    /* for datatable ends */

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }
    ngOnInit(): void {
    }


    editCompanyDatatable() {
        this.dtOptionsEditCompany = {
            columns: [
                {
                    title: 'Email Id',
                    data: 'emailId'
                }
            ],
            dom: 'Bfrtip',
            buttons: [],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 150,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
        },
            this.dtOptionsOtherChargeDatatable = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 200,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
            }
    }
    ngOnDestroy(): void {
        this.dtTriggerEditCompany.unsubscribe();
        this.dtTriggerOtherChargeDatatable.unsubscribe();
        // this.onDestroyUnsubscribtionEditCompany.unsubscribe();
    }

    //  service starts
    getEditCompanyDetails() {
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.mainStation = this.userDataDtoReturnSession.mainStation;
        this.userDataDto.city = this.userDataDtoReturnSession.mainStation;
    }
    getEditCompanyList = () => {
        this.getEditCompanyDetails();
        this.masterReadService.getEditCompany(this.userDataDto).subscribe(
            (response) => {
                if (response) {
                    this.userDataDtoGet = response;
                    console.log("this.userDataDtoGet");
                    console.log(this.userDataDtoGet);
                    $("#" + this.pageId + "editCompanyDatatableId").DataTable().destroy();
                    this.dtTriggerEditCompany.next();
                    this.userDataDto = this.userDataDtoGet;

                    this.editCompanyId = this.userDataDtoGet.id;

                    if (this.userDataDto.allEmailId != null && this.userDataDto.allEmailId != "NA") {
                        this.emails = this.userDataDto.allEmailId;
                        this.allEmails = this.emails.split('#');
                    }
                    //Other Charge set here
                    $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
                    for (let i = 0; i < this.userDataDtoGet.lrDtos.length; i++) {
                        this.gridTosetOtherChargeList.push(this.userDataDtoGet.lrDtos[i]);
                    }
                    this.dtTriggerOtherChargeDatatable.next();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Edit Company Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');


        //        console.log( this.userDataDto.mainStation );
    };
    //to insert value of selected row in table to input field starts
    rowSelected(editCompanyData) {
        this.userDataDto.selectedEmail = editCompanyData;
        //        this.userDataDto.id = this.editCompanyId;

        //        console.log( this.userDataDto.selectedEmail );
    }
    //to insert value of selected row in table to input field ends
    //    redirect to other page starts
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    //  redirect to other page ends
    //    service ends
    validateEditCompanyDetails() {
        swal({
            title: "Confirm Changes",
            text: "Sure U Want to Update the changes?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.saveUpdateEditCompanyDetails();
            }
        });
    }
    saveEditCompanyDetails() {
        console.log("this.editCompanyId");
        console.log(this.editCompanyId);
        if (this.editCompanyId == null) {
            this.userDataDto.id = 0;
        } else {
            this.userDataDto.id = this.editCompanyId;
        }
        this.userDataDto.unitLoading = "Article";
        this.userDataDto.mainStation = this.userDataDtoReturnSession.mainStation;
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.userName = this.userDataDtoReturnSession.userId;
        this.userDataDto.listOfEmails = this.allEmails;
        this.userDataDto.lrDtos = this.getOtherChgValues();
        //        this.emailIdNew =null;
        //        for ( int i = 0; i < storeEmail.getCount(); i++) {
        //            emailIdNew = emailIdNew + "#"
        //                + storeEmail.getModels().get( i ).getConsigneeName();
        //        }
        //
        //        this.userDataDto.allEmailId = this.emailIdNew;
    }
    saveUpdateEditCompanyDetails = () => {
        console.log(this.userDataDto);
        this.saveEditCompanyDetails();
        this.masterService.createEditCompany(this.userDataDto).subscribe((data) => {
            this.userDataDtoSaveRet = data;
            //                console.log( this.userDataDtoSaveRet.status );
            if (this.userDataDtoSaveRet.status == "success") {
                swal({
                    title: "Success",
                    text: "Edit Company Details Saved/Updated Successfully",
                    icon: "success",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
                //this.clearAll();
                // this.getEditCompanyList();
                //this.editCompanyDatatable();
            } else {
                swal({
                    title: "Error",
                    text: "Error While Saving Edit Company Details",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            }
        }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Saving Edit Company Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    clearAll() {
        $('input[type="text"],[type="number"]').val('');
        $("#" + this.pageId + "unit").val('');
        $("#" + this.pageId + "address").val('');
        $("#" + this.pageId + "unitGodownDelivery").val('');
        this.editCompanyId = 0;
        this.allEmails = null;
        $("#" + this.pageId + "basedOnId").val('');
        this.gridTosetOtherChargeList = null;
        //if(this.rightsForMainStation == "rights to show Srd Perc and Hamali Perc only for chennai"){
        if (this.userDataDtoReturnSession.mainStation == "Chennai") {
            this.hideShowSRDPercInputField = true;
            this.hideShowHamaliPercInputField = true;
        } else {
            this.hideShowSRDPercInputField = false;
            this.hideShowHamaliPercInputField = false;
        }
    }
    addInTable() {
        this.newAttribute.emailId = null;
        this.newAttribute.emailId = $("#" + this.pageId + "selectedEmail").val();
        if (this.newAttribute.emailId != '') {
            if (this.editEmailId != null) {
                this.allEmails.splice(this.editEmailId, 1);
            }
            if (this.allEmails == null) {
                this.allEmails = [];
            }

            /* if ( this.allEmails.length == 0 ) {
                 $("#"+this.pageId+"editCompanyDatatableId").DataTable().destroy();
                 this.allEmails.push( this.newAttribute.emailId );
                 this.dtTriggerEditCompany.next();
             } else {
                 console.log("Cond - 4");
                 console.log(this.allEmails);
                 $("#"+this.pageId+"editCompanyDatatableId").DataTable().destroy();
                 this.allEmails.push(this.newAttribute.emailId);
                 console.log(this.allEmails);
                 this.dtTriggerEditCompany.next();
                 console.log(this.allEmails);
                 console.log($("#"+this.pageId+"editCompanyDatatableId").DataTable().length);
             }*/
            this.allEmails.push(this.newAttribute.emailId);
            this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
                dtElement.dtInstance.then((dtInstance: any) => {
                    console.log(`The DataTable ${index} instance ID is: ${dtInstance.table().node().id}`);
                    if (dtInstance.table().node().id == "editCompanyDatatableId") {
                        dtInstance.destroy();
                        this.dtTriggerEditCompany.next();
                    }
                });
            });
            this.newAttribute = {};
            $("#" + this.pageId + "selectedEmail").val('');
            $("#" + this.pageId + "hiddenIndex").val('');
            this.editEmailId = null;
        } else {
            swal({
                title: "Warning",
                text: "Please Enter the Email Id",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }
    }
    rowSelectedEdit(editCompanyData, index) {
        $("#" + this.pageId + "selectedEmail").val(editCompanyData);
        $("#" + this.pageId + "hiddenIndex").val(index);
        this.editEmailId = index;
    }
    rowSelectedDelete() {
        this.editEmailId = $("#" + this.pageId + "hiddenIndex").val();
        this.allEmails.splice(this.editEmailId, 1);
        $("#" + this.pageId + "selectedEmail").val('');
        $("#" + this.pageId + "hiddenIndex").val('');
        this.editEmailId = null;
        console.log("delete Status:", +this.allEmails);
    }

    getBranchAreaDetails() {
        this.lrDto = new LRDto();
        this.lrDto.branch = this.userDataDtoReturnSession.office;
        // this.lrDto.mode = this.userDataDtoReturnSession.mainStation;
        this.lrDto.mode = "ALL";
        this.lrDto.mainstation = this.userDataDtoReturnSession.mainStation;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    getBranchAreaDetailsList = () => {
        this.getBranchAreaDetails();
        this.masterReadService.getBranchAreas(this.lrDto).subscribe(
            (response) => {
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Truck Capacity records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.controlOtherChargeArea.reset();
                    this.otherChargeAreaOptions = [];
                    this.otherChargeAreaTA = [];
                } else {
                    this.controlOtherChargeArea.reset();
                    this.otherChargeAreaOptions = response;
                    this.otherChargeAreaTA = [];
                    for (let i = 0; i < this.otherChargeAreaOptions.length; i++) {
                        this.otherChargeAreaTA.push(this.otherChargeAreaOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    clickListnerForOtherChargeArea(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelOtherChargeArea = e.item;
        $("#" + this.pageId + "otherChargeArea").val(this.modelOtherChargeArea.destination);
    }

    focusOtherChargeAreaTA(e) {
        if (e.keyCode == 13) {
            //$( "#loaderStaffName" ).focus();
        }
    }

    addInTableOtherCharge() {
        if (($("#" + this.pageId + "otherChargeArea").val() == null) || ($("#" + this.pageId + "otherChargeArea").val() == undefined) ||
            ($("#" + this.pageId + "otherChargeArea").val() == "") || ($("#" + this.pageId + "basedOnId").val() == null) ||
            ($("#" + this.pageId + "basedOnId").val() == undefined) || ($("#" + this.pageId + "basedOnId").val() == "") ||
            ($("#" + this.pageId + "otherChargeAmountId").val() == null) || ($("#" + this.pageId + "otherChargeAmountId").val() == undefined) ||
            ($("#" + this.pageId + "otherChargeAmountId").val() == "")) {
            swal("Not Allowed", "Please enter all the details to proceed", "warning");
            return false;
        } else {
            this.addRowOtherChg();
        }
    }
    addRowOtherChg() {
        this.newAttributeTosetOtherCharges.areaOfDelivery = $("#" + this.pageId + "otherChargeArea").val();
        this.newAttributeTosetOtherCharges.ratePerUnit = $("#" + this.pageId + "basedOnId").val();
        this.newAttributeTosetOtherCharges.chqAmt = $("#" + this.pageId + "otherChargeAmountId").val();
        if (this.editOtherChargesId != null) {
            this.gridTosetOtherChargeList.splice(this.editOtherChargesId, 1);
        }
        if (this.gridTosetOtherChargeList == null) {
            this.gridTosetOtherChargeList = [];
        }
        if (this.gridTosetOtherChargeList.length == 0) {
            $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
            this.gridTosetOtherChargeList.push(this.newAttributeTosetOtherCharges);
            this.dtTriggerOtherChargeDatatable.next();
        } else {
            //console.log(this.newAttributeTosetOtherCharges);
            //console.log(this.gridTosetOtherChargeList);
            const result = this.gridTosetOtherChargeList.filter(otherChargeData =>
                otherChargeData.areaOfDelivery === this.newAttributeTosetOtherCharges.areaOfDelivery);
            //console.log( result );
            if (result.length > 0) {
                swal("Duplicate Entry", "The entered Area is already available," +
                    "please select different area or edit it!", "warning");
            } else {
                $("#" + this.pageId + "otherChargeDatatableId").DataTable().destroy();
                this.gridTosetOtherChargeList.push(this.newAttributeTosetOtherCharges);
                this.dtTriggerOtherChargeDatatable.next();
            }
        }
        this.newAttributeTosetOtherCharges = {};
        $("#" + this.pageId + "otherChargeArea").val('');
        $("#" + this.pageId + "basedOnId").val('');
        $("#" + this.pageId + "otherChargeAmountId").val('');
        $("#" + this.pageId + "hiddenIndexOtherChgId").val('');
        this.editOtherChargesId = null;
    }

    rowSelectedOtherChargeDataOnEdit(editOtherChargeData, index) {
        console.log(editOtherChargeData);
        $("#" + this.pageId + "otherChargeArea").val(editOtherChargeData.areaOfDelivery);
        $("#" + this.pageId + "basedOnId").val(editOtherChargeData.ratePerUnit);
        $("#" + this.pageId + "otherChargeAmountId").val(editOtherChargeData.chqAmt);
        this.editOtherChargesId = index;
    }

    getOtherChgValues() {
        this.lrDtosOtherChargesReturnType = [];
        for (let i = 0; i < this.gridTosetOtherChargeList.length; i++) {
            this.lrDtosOtherCharge = new LRDto();
            this.lrDtosOtherCharge.areaOfDelivery = this.gridTosetOtherChargeList[i].areaOfDelivery;
            this.lrDtosOtherCharge.ratePerUnit = this.gridTosetOtherChargeList[i].ratePerUnit;
            this.lrDtosOtherCharge.chqAmt = this.gridTosetOtherChargeList[i].chqAmt;
            this.lrDtosOtherCharge.deliveryType = "DD";
            this.lrDtosOtherChargesReturnType.push(this.lrDtosOtherCharge);
        }
        return this.lrDtosOtherChargesReturnType;
    }

    /*rowSelectedOtherChargeOnDelete() {
        this.editOtherChargesId = $("#"+this.pageId+"hiddenIndexOtherChgId").val();
        console.log( this.editOtherChargesId );
        this.gridTosetOtherChargeList.splice( this.editOtherChargesId, 1 );
        $("#"+this.pageId+"otherChargeArea").val('');
        $("#"+this.pageId+"basedOnId").val('');
        $("#"+this.pageId+"otherChargeAmountId").val('');
        $( "#"+this.pageId+"hiddenIndexOtherChgId" ).val('');
        this.editOtherChargesId = null;
    }*/

    stockArrivalNotificationSetupPopup() {
        const dialogRef = this.dialog.open(StockNotificationSetupComponent, {
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
            if (result == null || result == undefined || result == "Close") {
                console.log(`Dialog result: ${result}`);
            } else {
                console.log(`Dialog result: ${result}`);
            }
        });

    }
}
