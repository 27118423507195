import { NgbDateStruct, NgbTypeaheadSelectItemEvent, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MasterService } from 'src/app/dataService/master-service';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { LrService } from 'src/app/dataService/lr-service';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';

@Component({
    selector: 'app-agent-statement',
    templateUrl: './agent-statement.component.html',
    styleUrls: ['./agent-statement.component.css']
})
export class AgentStatementComponent implements OnInit {

    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;


    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    getDataFrmServiceFrTable: any;
    bkgAgentStmtReportDataList: any;
    onDestroyUnsubscribtionBkgAgentStmtReport: Subscription;


    controlMain = new FormControl();

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerBkgAgentStmtReport: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsBkgAgentStmtReport: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;
    showSpinnerForAction: boolean = false;
    fortNightStmtDtoForSearchFromDB: FortNightStmtDto = new FortNightStmtDto();

    agentNameOptions: AgentDetailsDto[];
    agentDtoForAgentName: AgentDetailsDto = new AgentDetailsDto();
    agentDtoForAgentNameAll: AgentDetailsDto = new AgentDetailsDto();
    public modelAgentName: any;
    agentNameTA: Array<AgentDetailsDto> = [];
    focusAgentNameTA$ = new Subject<string>();
    searchAgentName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusAgentNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.agentNameTA
                : this.agentNameTA.filter(v => v.subStation.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterAgentName = (x: { subStation: string }) => x.subStation;


    supAdmView: boolean = false;
    viewStmtNoField: boolean = true;
    viewDateWiseField: boolean = false;

    modelFromDate: any;
    modelToDate: any;
    validateFromDate: any;
    validateToDate: any;
    validateStmtNo: any;
    validateAgentName: any;

    fortNightStmtDtoForSearch: FortNightStmtDto = new FortNightStmtDto();

    viewStatementReportPopUp = false;
    modalRefferenceStatementRptPopUp: NgbModalRef;
    modalRefferenceStatementRptClosePopUp: string;
    @ViewChild('agentStatementReportPopUpTemplate') private agentStatementReportPopUpTemplate;

    additionValueSplit: Array<String> = [];
    deductionValueSpilt: Array<String> = [];

    modalRefferenceStatementPaymentRptPopUp: NgbModalRef;
    modalRefferenceStatementPaymentRptClosePopUp: string;
    @ViewChild('agentStatementPaymentPopUpTemplate') private agentStatementPaymentPopUpTemplate;
    viewStatementPaymentReportPopUp = false;
    bkgAgentStmtPaymentPassData: any;
    viewStatementRptLRDetlsPrint = false;
    stmtRptLrForAdditionLrPrintAgentStmt: any;
    stmtRptLrForDeductionLrPrintAgentStmt: any;
    stmtRptLrForGeneralLrPrintAgentStmt: any;
    viewCustomPrintV1 = false;
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintDataSummaryList: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    pageTitleForPrint: any;


    pageId = "agstmt";
    constructor(private agentCommodityScreen: MasterService, private modalService: NgbModal,
        private router: Router, private masterReadService: MasterReadService,
        private lrService: LrService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.fortNightStmtDtoForSearchFromDB = JSON.parse(localStorage.getItem('bkgStmtFromDBData'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "BkgAgtStmtRpt ShowAll") {
                        this.supAdmView = true;// superadmin only
                    }
                }
            }

            this.getAgentDetailList();

            if (this.fortNightStmtDtoForSearchFromDB != null && this.fortNightStmtDtoForSearchFromDB.reportMode == "DB") {
                this.setStmtDetailsDatatableList("DB");
            }
        }
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    ngOnInit(): void {

        var groupColumn = 0;
        this.dtOptionsBkgAgentStmtReport = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
                    }
                },
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 

                var totalLRs = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalArt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totActWt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totChgWt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var payableAmt = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var cash = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var cheque = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var pdc = api.column(16).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                // Update footer by showing the total with the reference of the column index 
                $(api.column(1).footer()).html('Total : ' + data.length);
                $(api.column(6).footer()).html(totalLRs);
                $(api.column(7).footer()).html(totalArt);
                $(api.column(8).footer()).html(totActWt);
                $(api.column(9).footer()).html(totChgWt);
                $(api.column(10).footer()).html(toPay);
                $(api.column(11).footer()).html(paid);
                $(api.column(12).footer()).html(payableAmt);
                $(api.column(14).footer()).html(cash);
                $(api.column(15).footer()).html(cheque);
                $(api.column(16).footer()).html(pdc);
            },
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;

                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var totalLRs = 0;
                var totalArt = 0;
                var totActWt = 0;
                var totChgWt = 0;
                var toPay = 0;
                var paid = 0;
                var payableAmt = 0;
                var cash = 0;
                var cheque = 0;
                var pdc = 0;
                var count = 0;
                //var pendingQty = 0;
                api.column(groupColumn, {
                    page: 'current'
                }).data().each(function (group, i) {

                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total"
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 3,
                                                "text": ""
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totalLRs
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totalArt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totActWt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totChgWt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": toPay
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": paid
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": payableAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": cash
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": cheque
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": pdc
                                            }))
                                        .prop('outerHTML'));
                            totalLRs = 0;
                            totalArt = 0;
                            totActWt = 0;
                            totChgWt = 0;
                            toPay = 0;
                            paid = 0;
                            payableAmt = 0;
                            cash = 0;
                            cheque = 0;
                            pdc = 0;
                            count = 0;
                            //pendingQty = 0;
                        }
                        //the below is for column name grouping (in this case we group for destination wise)
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 17,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }
                    count++;
                    totalLRs += +val[6];
                    totalArt += +val[7];
                    totActWt += +val[8];
                    totChgWt += +val[9];
                    toPay += +val[10];
                    paid += +val[11];
                    payableAmt += +val[12];
                    cash += +val[14];
                    cheque += +val[15];
                    pdc += +val[16];

                    if (i == (rows.length - 1)) {
                        //the below is for Sumary details grouping 
                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total"
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": + count
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 3,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totalLRs
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totalArt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totActWt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totChgWt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": toPay
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": paid
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": payableAmt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": cash
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": cheque
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": pdc
                                        }))


                                    .prop('outerHTML'));
                        totalLRs = 0;
                        totalArt = 0;
                        totActWt = 0;
                        totChgWt = 0;
                        toPay = 0;
                        paid = 0;
                        payableAmt = 0;
                        cash = 0;
                        cheque = 0;
                        pdc = 0;
                        count = 0;
                        //pendingQty = 0;
                    }
                });
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnDestroy(): void {
        this.dtTriggerBkgAgentStmtReport.unsubscribe();
        //this.onDestroyUnsubscribtionBkgAgentStmtReport.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerBkgAgentStmtReport.next();
    }
    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker

    clickListnerForAgentName(e: NgbTypeaheadSelectItemEvent) {
        this.modelAgentName = e.item;
        $("#" + this.pageId + "agentName").val(this.modelAgentName.subStation);
        $("#" + this.pageId + "agentName").focusout();
    }


    getUserValuesForAgentDetailsList() {
        this.agentDtoForAgentName = new AgentDetailsDto();
        //this.supAdmView = true;
        if (this.supAdmView) {
            this.agentDtoForAgentName.mode = 'Sub';
            this.agentDtoForAgentName.status = 'All';
        } else {
            this.agentDtoForAgentName.mode = 'mainBranch';
            this.agentDtoForAgentName.column2 = 'Working';
            if (this.userDataDtoReturnSession.role == "Booking Administrator") {
                this.agentDtoForAgentName.mainStation = this.userDataDtoReturnSession.mainStation;
            } else {
                this.agentDtoForAgentName.mainStation = this.userDataDtoReturnSession.mainAdminStation;
            }
        }
        this.agentDtoForAgentName.companyId = this.userDataDtoReturnSession.companyId;
    }

    getAgentDetailList() {
        this.getUserValuesForAgentDetailsList();
        this.showSpinnerForAction = true;
        this.masterReadService.getSourceListDetails(this.agentDtoForAgentName).subscribe(
            (response) => {
                console.log(response);
                this.agentNameTA = [];
                this.agentNameOptions = [];
                this.agentDtoForAgentNameAll = new AgentDetailsDto();
                this.agentDtoForAgentNameAll.subStation = "All"
                this.agentNameTA.push(this.agentDtoForAgentNameAll);
                if (response.length > 0) {
                    this.agentNameOptions = response;
                    for (let i = 0; i < this.agentNameOptions.length; i++) {
                        this.agentNameTA.push(this.agentNameOptions[i]);
                    }
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            }, () => console.log('done');
    };

    reportModeTypeListner(reportModeType: string) {
        if (reportModeType == "StatementNumber") {
            this.viewStmtNoField = true;
            this.viewDateWiseField = false;

            this.modelAgentName = null;
            this.modelFromDate = null;
            this.modelToDate = null;

            $("#" + this.pageId + "statementNumber").val('');
            $("#" + this.pageId + "agentName").val('');
            $("#" + this.pageId + "fromDates").val('');
            $("#" + this.pageId + "toDates").val('');
        } else if (reportModeType == "DateWise") {
            this.viewStmtNoField = false;
            this.viewDateWiseField = true;

            this.modelAgentName = null;
            this.modelFromDate = null;
            this.modelToDate = null;

            $("#" + this.pageId + "statementNumber").val('');
            $("#" + this.pageId + "agentName").val('');
            $("#" + this.pageId + "fromDates").val('');
            $("#" + this.pageId + "toDates").val('');
        }
    }
    validateSearchBtn() {
        if (($("#" + this.pageId + "reportModeType").val() == "StatementNumber") &&
            ($("#" + this.pageId + "statementNumber").val() == null || $("#" + this.pageId + "statementNumber").val() == undefined ||
                $("#" + this.pageId + "statementNumber").val() == "")) {
            swal("Mandatory Field", "Please enter the statement number", "warning");
            return false;
        } else if (($("#" + this.pageId + "reportModeType").val() == "DateWise") &&
            ($("#" + this.pageId + "fromDates").val() == null || $("#" + this.pageId + "fromDates").val() == undefined ||
                $("#" + this.pageId + "fromDates").val() == "" || $("#" + this.pageId + "toDates").val() == null ||
                $("#" + this.pageId + "toDates").val() == undefined || $("#" + this.pageId + "toDates").val() == "" ||
                $("#" + this.pageId + "agentName").val() == null || $("#" + this.pageId + "agentName").val() == undefined ||
                $("#" + this.pageId + "agentName").val() == "")) {
            swal("Mandatory Field", "Please enter the Agent Name,From Date & To Date", "warning");
            return false;
        } else {
            this.setStmtDetailsDatatableList(null);
        }
    }

    validateClearBtn() {
        this.bkgAgentStmtReportDataList = [];
        $("#" + this.pageId + "bkgAgentStmtReportTableId").DataTable().destroy();
        this.dtTriggerBkgAgentStmtReport.next();

        this.modelAgentName = null;
        this.modelFromDate = null;
        this.modelToDate = null;

        this.validateFromDate = null;
        this.validateToDate = null;
        this.validateStmtNo = null;
        this.validateAgentName = null;

        $("#" + this.pageId + "statementNumber").val('');
        $("#" + this.pageId + "agentName").val('');
        $("#" + this.pageId + "fromDates").val('');
        $("#" + this.pageId + "toDates").val('');

        $("#" + this.pageId + "reportModeType").val('StatementNumber');
        this.viewStmtNoField = true;
        this.viewDateWiseField = false;
    }

    getStmtDetails() {
        this.fortNightStmtDtoForSearch = new FortNightStmtDto();
        this.validateFromDate = $("#" + this.pageId + "fromDates").val();
        this.validateToDate = $("#" + this.pageId + "toDates").val();
        this.validateStmtNo = $("#" + this.pageId + "statementNumber").val();
        this.validateAgentName = $("#" + this.pageId + "agentName").val();

        this.fortNightStmtDtoForSearch.branch = this.userDataDtoReturnSession.office;
        this.fortNightStmtDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
        if ($("#" + this.pageId + "reportModeType").val() == "StatementNumber") {
            this.fortNightStmtDtoForSearch.mode = "STMT";
            this.fortNightStmtDtoForSearch.stmtNo = this.validateStmtNo;
        } else if ($("#" + this.pageId + "reportModeType").val() == "DateWise") {
            this.fortNightStmtDtoForSearch.mode = "AGENT";
            this.fortNightStmtDtoForSearch.fromDate = this.validateFromDate;
            this.fortNightStmtDtoForSearch.toDate = this.validateToDate;
            this.fortNightStmtDtoForSearch.agentName = this.validateAgentName;
        }

        if (this.supAdmView) {
            this.fortNightStmtDtoForSearch.reportMode = "superAdmin";
        } else {
            if (this.userDataDtoReturnSession.role == "Booking Administrator") {
                this.fortNightStmtDtoForSearch.mainBranch = this.userDataDtoReturnSession.mainStation;
            } else {
                this.fortNightStmtDtoForSearch.mainBranch = this.userDataDtoReturnSession.mainAdminStation;
            }
        }
    }

    setStmtDetailsDatatableList(type) {
        if (type != null && type == "DB") {
            this.fortNightStmtDtoForSearch = this.fortNightStmtDtoForSearchFromDB;
            $("#" + this.pageId + "statementNumber").val(this.fortNightStmtDtoForSearch.stmtNo);
        } else {
            this.getStmtDetails();
        }
        this.showSpinnerForAction = true;
        this.lrService.getBookingStatementReportDetails(this.fortNightStmtDtoForSearch).subscribe(
            (response) => {
                console.log(response);
                this.bkgAgentStmtReportDataList = [];
                $("#" + this.pageId + "bkgAgentStmtReportTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.bkgAgentStmtReportDataList = response;
                } else {
                    swal("Alert", "No Record Found", "warning");
                }
                this.dtTriggerBkgAgentStmtReport.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Statement Report Details", "info");
            }, () => console.log('done');
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    agentStatementReportPopUpBtnLink(agentStatementReportPopUpTemplate, bkgAgentStmtReportData) {
        this.modalRefferenceStatementRptPopUp = this.modalService.open(agentStatementReportPopUpTemplate,
            { centered: true, windowClass: "myCustomModalClass" });
        this.modalRefferenceStatementRptPopUp.result.then((result) => {
            this.modalRefferenceStatementRptClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceStatementRptClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });


        var additionValue = null, deductionValue = null;
        //var additionValueSplit = null, deductionValueSpilt = null;
        additionValue = bkgAgentStmtReportData.multipleAdd;
        deductionValue = bkgAgentStmtReportData.multipleDed;
        //console.log(additionValue);
        //console.log(deductionValue);

        this.additionValueSplit = additionValue.split("#");
        this.deductionValueSpilt = deductionValue.split("#");
        console.log(additionValue);
        console.log(deductionValue);

        console.log(this.additionValueSplit);
        console.log(this.deductionValueSpilt);

        localStorage.clear();
        window.localStorage.clear();
        localStorage.setItem('popupAgentStmtRptLrDetls', JSON.stringify(bkgAgentStmtReportData));
        localStorage.setItem('popupAgentStmtRptLrDetlsAddition', JSON.stringify(this.additionValueSplit));
        localStorage.setItem('popupAgentStmtRptLrDetlsDeduction', JSON.stringify(this.deductionValueSpilt));
        console.log(JSON.stringify(bkgAgentStmtReportData).length);
        //console.log(localStorage);
        this.viewStatementReportPopUp = true;
        window.addEventListener('afterPopUp', (event) => {
            this.viewStatementReportPopUp = false;
        });
    }

    closeAgentStatementReportPopUp() {
        this.modalRefferenceStatementRptPopUp.close();
    }

    bookingAgentStmtPayment(agentStatementPaymentPopUpTemplate, bkgAgentStmtReportData) {
        localStorage.clear();
        localStorage.setItem('popupAgentStmtPaymentRptLrDetls', JSON.stringify(bkgAgentStmtReportData));
        this.modalRefferenceStatementPaymentRptPopUp = this.modalService.open(agentStatementPaymentPopUpTemplate,
            { centered: true, windowClass: "myCustomModalClass" });
        this.modalRefferenceStatementPaymentRptPopUp.result.then((result) => {
            this.modalRefferenceStatementPaymentRptClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceStatementPaymentRptClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });

        this.bkgAgentStmtPaymentPassData = null;
        this.bkgAgentStmtPaymentPassData = bkgAgentStmtReportData;

        // localStorage.clear();
        // localStorage.setItem('popupAgentStmtPaymentRptLrDetls', JSON.stringify(bkgAgentStmtReportData));
    }

    closeAgentStatementPaymentReportPopUp() {
        this.modalRefferenceStatementPaymentRptPopUp.close();
    }







    printBkgAgentStmt() {
        // window.localStorage.clear();
        // localStorage.clear();
        /*localStorage.setItem('agentStmtLrDtlsPrintLocalStorage',
            JSON.stringify(this.statementReportLrDetailsDataList));*/
        // this.stmtRptDetails_localStorage.stmtNo
        this.modalRefferenceStatementRptPopUp.close();
        swal({
            title: "Print Bkg Agent",
            text: "Do U Want to Print the Booking Agent Statement",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((printData) => {
            if (printData) {
                // this.dataForPrint.clickListnerForPrintStmtRptLr();
                this.stmtRptLrForAdditionLrPrintAgentStmt = JSON.parse(localStorage.getItem('agentStmtLrDtlsAdditionPrintLocalStorageViaAgentStmt'));
                this.stmtRptLrForDeductionLrPrintAgentStmt = JSON.parse(localStorage.getItem('agentStmtLrDtlsDeductionPrintLocalStorageViaAgentStmt'));
                this.stmtRptLrForGeneralLrPrintAgentStmt = JSON.parse(localStorage.getItem('agentStmtLrDtlsGeneralPrintLocalStorageViaAgentStmt'));
                console.log(localStorage.size);
                localStorage.setItem('agentStmtLrDtlsAdditionPrintLocalStorage',
                    JSON.stringify(this.stmtRptLrForAdditionLrPrintAgentStmt));
                localStorage.setItem('agentStmtLrDtlsDeductionPrintLocalStorage',
                    JSON.stringify(this.stmtRptLrForDeductionLrPrintAgentStmt));
                localStorage.setItem('agentStmtLrDtlsGeneralPrintLocalStorage',
                    JSON.stringify(this.stmtRptLrForGeneralLrPrintAgentStmt));
                localStorage.setItem('clearLocalStorage', "No");
                this.viewStatementRptLRDetlsPrint = true;
                // this.showSpinnerForAction = true;
                window.addEventListener('afterprint', (onclick) => {
                    if (this.viewStatementRptLRDetlsPrint) {
                        this.viewStatementRptLRDetlsPrint = false;
                        this.lrPrintPopup();
                        // localStorage.clear();
                    }
                    // this.showSpinnerForAction = false;
                });
            } else {
                this.lrPrintPopup();
            }
        });


    }
    lrPrintPopup() {
        swal({
            title: "Print LR Details",
            text: "Do U Want to Print the Booking Agent Statement LR Details",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((printData) => {
            if (printData) {
                // this.dataForPrint.customPrintBkgAgentStmtLr();
                this.cashMemoDtoForCustomPrintList = JSON.parse(localStorage.getItem('printCashMemoDtoForCustomPrintListViaAgentStmt'));
                this.cashMemoDtoForCustomPrintDataList = JSON.parse(localStorage.getItem('printCashMemoDtoForCustomPrintDataListViaAgentStmt'));
                this.cashMemoDtoForCustomPrintDataSummaryList = JSON.parse(localStorage.getItem('printcashMemoDtoForCustomPrintDataSummaryListViaAgentStmt'));
                this.cashMemoDtoForCustomPrintListHeadingV1 = JSON.parse(localStorage.getItem('printcashMemoDtoForCustomPrintListHeadingV1ViaAgentStmt'));
                //	localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
                this.pageTitleForPrint = localStorage.getItem('printTitleViaAgentStmt');



                localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
                localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
                localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
                localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
                //	localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
                localStorage.setItem('printTitle', this.pageTitleForPrint);
                this.viewCustomPrintV1 = true;
                window.addEventListener('afterprint', (onclick) => {
                    if (this.viewCustomPrintV1) {
                        this.viewCustomPrintV1 = false;
                        localStorage.clear();
                    }
                });
            } else {
                localStorage.clear();
            }
        });

    }
}
