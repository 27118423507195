import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

/*for old datatable*///import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//for select option search starts
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
//for select option search ends

import { TripRoutes } from 'src/app/master/trip/trip.routing';
import { TimeComponent } from 'src/app/master/trip/time/time.component';
import { TripEtaComponent } from 'src/app/master/trip/trip-eta-setup/trip-eta-setup.component';
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { MaterialModule } from "src/app/material.module";

@NgModule( {
    imports: [CommonModule, 
        // RouterModule.forChild( TripRoutes ), 
        FormsModule, NgbModule, DataTablesModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, ReactiveFormsModule, AutocompleteModule, DailyReportModule,CashmemoReportModule,MaterialModule],
    declarations: [
        TimeComponent,TripEtaComponent]
} )
export class TripModule { }
