import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from "angular-datatables";
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import swal from 'sweetalert';
import { Router } from "@angular/router";
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { LrService } from 'src/app/dataService/lr-service';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LRDto } from 'src/app/dto/LR-dto';

export interface DialogData {
    animal: string;
    name: string;
}

@Component({
    selector: 'app-labour-statement-loading-unloading.component',
    templateUrl: './labour-statement-loading-unloading.component.html',
    styleUrls: ['./labour-statement-loading-unloading.component.css']
})
export class LabourStatementLoadingUnloadingComponent implements OnInit {

    model: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    controlMain = new FormControl();
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtOptionsLabourStmtLoadingHamali: any;
    dtOptionsLabourStmtUnLoadingHamali: any;
    dtOptionsLabourStatementReportLrAdditionDetails: any;
    dtOptionsLabourStatementReportLrDeductionDetails: any;
    dtTriggerLabourStmtLoadingHamali: Subject<any> = new Subject();
    dtTriggerLabourStmtUnLoadingHamali: Subject<any> = new Subject();
    dtTriggerLabourStatementReportLrAdditionDetails: Subject<any> = new Subject();
    dtTriggerLabourStatementReportLrDeductionDetails: Subject<any> = new Subject();
    labourStmtLoadingHamaliDataList: any;
    labourStmtUnloadingReportDataList: any;
    labourStatementReportLrAdditionDataList: any;
    labourStatementReportLrDeductionDataList: any;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;

    labourStmtLoadingUnloadingDataLocalStorageGet: any;
    labourStmtRptAdditionDetails_localStorage: any;
    labourStmtRptDeductionDetails_localStorage: any;

    loaderHeadName: any;
    period: any;
    stmtNo: any;

    lrDtoForSearch: LRDto = new LRDto();
    lrDtoForSearchUnloading: LRDto = new LRDto();

    additionValueLRDto: LRDto = new LRDto();
    additionValueLRDtoList: Array<LRDto> = [];

    deductionValueLRDto: LRDto = new LRDto();
    deductionValueLRDtoList: Array<LRDto> = [];
    pageId = "lsluc";

    constructor(private router: Router, private lrService: LrService,
        public dialogRef: MatDialogRef<LabourStatementLoadingUnloadingComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.labourStmtLoadingUnloadingDataLocalStorageGet = JSON.parse(localStorage.getItem('labourStmtLoadingUnloadingDetails'));
            this.labourStmtRptAdditionDetails_localStorage = JSON.parse(localStorage.getItem('popupLabourStmtRptLrDetlsAddition'));
            this.labourStmtRptDeductionDetails_localStorage = JSON.parse(localStorage.getItem('popupLabourStmtRptLrDetlsDeduction'));
            localStorage.clear();
            if (this.labourStmtLoadingUnloadingDataLocalStorageGet != null &&
                this.labourStmtLoadingUnloadingDataLocalStorageGet != undefined) {
                this.loaderHeadName = this.labourStmtLoadingUnloadingDataLocalStorageGet.loaderHead;
                this.period = this.labourStmtLoadingUnloadingDataLocalStorageGet.fromDate +
                    " To " + this.labourStmtLoadingUnloadingDataLocalStorageGet.toDate;
                this.stmtNo = this.labourStmtLoadingUnloadingDataLocalStorageGet.stmtNo;
                this.setLabourStmtLoadingDetailsDatatableList();
            }
        }
    }

    ngOnInit(): void {
        this.dtOptionsLabourStmtLoadingHamali = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "Loading Hamali Details";
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var kantaWgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(3).footer()).html(kantaWgt);
            }
        },
            this.dtOptionsLabourStmtUnLoadingHamali = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            return "Un Loading Hamali Details";
                        },
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var totaLRs = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var challanKantaWgt = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(4).footer()).html(totaLRs);
                    $(api.column(5).footer()).html(actWgt);
                    $(api.column(6).footer()).html(challanKantaWgt);
                }
            },
            this.dtOptionsLabourStatementReportLrAdditionDetails = {
                dom: 'Bfrtip',
                buttons: [
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var creditAmt = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(creditAmt);
                }
            },
            this.dtOptionsLabourStatementReportLrDeductionDetails = {
                dom: 'Bfrtip',
                buttons: [
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var debitAmt = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(debitAmt);
                }
            }
    }
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }
    ngOnDestroy(): void {
        this.dtTriggerLabourStmtLoadingHamali.unsubscribe();
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngAfterViewInit(): void {
        this.dtTriggerLabourStmtLoadingHamali.next();
        this.dtTriggerLabourStmtUnLoadingHamali.next();
        this.dtTriggerLabourStatementReportLrAdditionDetails.next();
        this.dtTriggerLabourStatementReportLrDeductionDetails.next();
    }

    setLabourStmtLoadingDetailsDatatableList() {
        this.lrDtoForSearch = new LRDto();
        this.lrDtoForSearch.fromdate = this.labourStmtLoadingUnloadingDataLocalStorageGet.fromPeriod;
        this.lrDtoForSearch.todate = this.labourStmtLoadingUnloadingDataLocalStorageGet.toPeriod;
        this.lrDtoForSearch.loaderName = this.labourStmtLoadingUnloadingDataLocalStorageGet.loaderHead;
        this.lrDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.lrService.getLrdetailsForLBRHamaliStmtNew(this.lrDtoForSearch).subscribe(
            (response) => {
                console.log(response);
                this.labourStmtLoadingHamaliDataList = [];
                $("#" + this.pageId + "labourStmtLoadingHamaliTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.labourStmtLoadingHamaliDataList = response;
                } else {
                    swal("Alert", "No Record Found Loading Hamali", "warning");
                }
                setTimeout(() => {
                    this.dtTriggerLabourStmtLoadingHamali.next();
                    this.showSpinnerForAction = false;
                    this.setLabourStmtUnLoadingDetailsDatatableList();
                }, 500);
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the LR Details Labour Hamali Statement", "info");
            }, () => console.log('done');
    }

    setLabourStmtUnLoadingDetailsDatatableList() {
        this.lrDtoForSearchUnloading = new LRDto();
        this.lrDtoForSearchUnloading.fortNightStmtNo = this.labourStmtLoadingUnloadingDataLocalStorageGet.stmtNo;
        this.lrDtoForSearchUnloading.fromdate = this.labourStmtLoadingUnloadingDataLocalStorageGet.fromPeriod;
        this.lrDtoForSearchUnloading.todate = this.labourStmtLoadingUnloadingDataLocalStorageGet.toPeriod;
        this.lrDtoForSearchUnloading.loaderName = this.labourStmtLoadingUnloadingDataLocalStorageGet.loaderHead;
        this.lrDtoForSearchUnloading.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.lrService.getLrdetailsForLBRHamaliStmtNewUnloading(this.lrDtoForSearchUnloading).subscribe(
            (response) => {
                console.log(response);
                this.labourStmtUnloadingReportDataList = [];
                $("#" + this.pageId + "labourStmtUnLoadingHamaliTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.labourStmtUnloadingReportDataList = response;
                } else {
                    swal("Alert", "No Record Found Unloading Hamali", "warning");
                }
                setTimeout(() => {
                    this.dtTriggerLabourStmtUnLoadingHamali.next();
                    this.showSpinnerForAction = false;
                    this.setStatementRptLrDetlsAddition();
                    this.setStatementRptLrDetlsDeduction();
                }, 500);
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the LR Details Labour Hamali Statement", "info");
            }, () => console.log('done');
    }

    setStatementRptLrDetlsAddition() {
        this.additionValueLRDtoList = [];
        this.labourStatementReportLrAdditionDataList = [];
        $("#" + this.pageId + "labourStatementRptAdditionTableId").DataTable().destroy();
        if (this.labourStmtRptAdditionDetails_localStorage.length > 0) {
            for (let i = 0; i < this.labourStmtRptAdditionDetails_localStorage.length; i++) {
                var additionValue = this.labourStmtRptAdditionDetails_localStorage[i];
                var additionValueSplit = additionValue.split("_");
                this.additionValueLRDto = new LRDto();
                this.additionValueLRDto.description = additionValueSplit[0];
                this.additionValueLRDto.totalAmount = additionValueSplit[1];
                this.additionValueLRDtoList.push(this.additionValueLRDto);
                this.labourStatementReportLrAdditionDataList.push(this.additionValueLRDto);
            }
            setTimeout(() => {
                this.dtTriggerLabourStatementReportLrAdditionDetails.next();
            }, 3000);
        } else {
            setTimeout(() => {
                this.dtTriggerLabourStatementReportLrAdditionDetails.next();
            }, 3000);
        }
    }


    setStatementRptLrDetlsDeduction() {
        this.deductionValueLRDtoList = [];
        this.labourStatementReportLrDeductionDataList = [];
        $("#" + this.pageId + "labourStatementRptDeductionTableId").DataTable().destroy();
        if (this.labourStmtRptDeductionDetails_localStorage.length > 0) {
            for (let i = 0; i < this.labourStmtRptDeductionDetails_localStorage.length; i++) {
                var deductionValue = this.labourStmtRptDeductionDetails_localStorage[i];
                var deductionValueSplit = deductionValue.split("_");
                this.deductionValueLRDto = new LRDto();
                this.deductionValueLRDto.description = deductionValueSplit[0];
                this.deductionValueLRDto.totalAmount = deductionValueSplit[1];
                this.labourStatementReportLrDeductionDataList.push(this.deductionValueLRDto);
            }
            setTimeout(() => {
                this.dtTriggerLabourStatementReportLrDeductionDetails.next();
            }, 3000);

        } else {
            setTimeout(() => {
                this.dtTriggerLabourStatementReportLrDeductionDetails.next();
            }, 3000);
        }
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    onPrintClick() {
        localStorage.clear();
        var lrdto: LRDto = new LRDto();
        var lrDtoLoadingList: LRDto[];
        var lrDtoUnloadingLoadingList: LRDto[];
        lrdto.lrDtos = this.labourStmtLoadingHamaliDataList;
        lrdto.lrDtoList = this.labourStmtUnloadingReportDataList;
        lrdto.loaderHead = this.labourStmtLoadingUnloadingDataLocalStorageGet.loaderHead;
        lrdto.fromDate = this.labourStmtLoadingUnloadingDataLocalStorageGet.fromDate;
        lrdto.toDate = this.labourStmtLoadingUnloadingDataLocalStorageGet.toDate;
        lrdto.fortNightStmtNo = this.labourStmtLoadingUnloadingDataLocalStorageGet.stmtNo;
        lrdto.stmtDateStr = this.labourStmtLoadingUnloadingDataLocalStorageGet.stmtDateStr;
        lrdto.loadingHamali = this.labourStmtLoadingUnloadingDataLocalStorageGet.loadingHamali;
        lrdto.unLoadingHamali = this.labourStmtLoadingUnloadingDataLocalStorageGet.unLoadingHamali;
        lrdto.totLoadingHamaliAmt = this.labourStmtLoadingUnloadingDataLocalStorageGet.totLoadingHamaliAmt;
        lrdto.totUnloadingHamaliAmt = this.labourStmtLoadingUnloadingDataLocalStorageGet.totUnloadingHamaliAmt;
        this.dialogRef.close(JSON.stringify(lrdto));
        this.changeDetectorRef.detectChanges();
    }
}
