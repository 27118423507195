import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbDateStruct, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { LRDto } from 'src/app/dto/LR-dto';
import { Router } from "@angular/router";
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { MatDialog } from '@angular/material/dialog';
import *  as moment from 'moment';
import { SupplierDetailsDto } from 'src/app/dto/SupplierDetails-dto';
import { PdcInfoPopupComponent } from 'src/app/report/hireslip-and-invoice-report/pdc-details-popup/pdc-info-popup.component';
import { ChequeInfoPopupComponent } from 'src/app/report/hireslip-and-invoice-report/cheque-details-popup/cheque-info-popup.component';
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { RequestDto } from 'src/app/dto/Request-dto';
import { EwaybillService } from 'src/app/dataService/ewaybill-service';

@Component({
    selector: 'app-advance-payment',
    templateUrl: './advance-payment.component.html',
    styleUrls: ['./advance-payment.component.css']
})
export class AdvancePaymentComponent implements OnInit {

    advancePaymentDataList: any;
    advancePaymentDataListDestWise: any;
    model: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    setTodayDateOnToDate: any;
    setTodayDateOnFromDate: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerAdvancePayment: Subject<any> = new Subject();
    dtTriggerAdvancePaymentDestWise: Subject<any> = new Subject();
    dtOptionsAdvancePayment: any;
    dtOptionsAdvancePaymentDestWise: any;
    //For Source Main Stations
    lrDtoSourceStationAllOption: LRDto = new LRDto();
    sourceStationOptions: LRDto[];
    lrDtoSourceStation: LRDto = new LRDto();

    public modelSource: any;
    sourceSubStationNameTA: Array<LRDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        // return merge(debouncedText$, inputFocus$).pipe(
        //     map(term => (term === '' ? this.sourceSubStationNameTA
        //         : this.sourceSubStationNameTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        // );
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceSubStationNameTA
                : this.sourceSubStationNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSource = (x: { source: string }) => x.source;

    //    For From Station 
    lrDtoFromStn: LRDto = new LRDto();
    lrDtoFromStnAll: LRDto = new LRDto();
    lrDtosFromStnOptionsGet: LRDto[];

    srcLists: any;
    srcList: LRDto = new LRDto();
    srcListForBkgAdmin: LRDto = new LRDto();
    rightsOfHirelsipRpt: any;

    // for To station
    lrDtoToStationAll: LRDto = new LRDto();
    lrDtoToStation: LRDto = new LRDto();
    lrDtosToStationOptionsGet: LRDto[];

    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.godownName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { godownName: string }) => x.godownName;

    destinationDtoForAll: LRDto = new LRDto();
    supplierDetailsDto: SupplierDetailsDto = new SupplierDetailsDto();
    supplierDetailsDtoAll: SupplierDetailsDto = new SupplierDetailsDto();
    supplierOptions: SupplierDetailsDto[];
    public modelSupplierName: any;
    supplierNameTA: Array<SupplierDetailsDto> = [];
    focusSupplierNameTA$ = new Subject<string>();
    searchSupplierName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSupplierNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.supplierNameTA
                : this.supplierNameTA.filter(v => v.supplierName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSupplierName = (x: { supplierName: string }) => x.supplierName;

    hashMapSupplierIndex: Map<String, Number> = new Map<String, Number>();
    hashMapSupplier: Map<String, SupplierDetailsDto> = new Map<String, SupplierDetailsDto>();

    showSpinnerForAction = false;
    selectedFromDate: any;
    selectedToDate: any;
    selectedSource: any;
    selectedDestination: any;
    selectedSupplierName: any;
    selectedViewBy: any;
    selectedPaymentMethod: any;
    selectedSearchBy: any;
    hireslipDtoForSearch: HireSlipDto = new HireSlipDto();

    listLrDtosChq: LRDto = new LRDto();
    listLrDtosChqList: Array<LRDto> = [];
    listLrDtosPdc: LRDto = new LRDto();
    listLrDtosPdcList: Array<LRDto> = [];
    viewPaymentVoucher = false;
    viewPaymentVoucherChq = false;
    viewPaymentVoucherPdc = false;

    modalRefferenceConfirmPaymentPopUp: NgbModalRef;
    modalRefferenceConfirmPaymentClosePopUp: string;
    @ViewChild('confirmPaymentPopUpTemplate') private confirmPaymentPopUpTemplate;
    viewConfirmPaymentPopUp = false;
    hirePrintDto: HireSlipDto = new HireSlipDto();
    hirePrintDtoPrintVoucher: HireSlipDto = new HireSlipDto();
    valueMin = 0.0;
    valueMax = 0.0;
    modalRefferenceAdvancePaymentPopUp: NgbModalRef;
    modalRefferenceAdvancePaymentClosePopUp: string;
    @ViewChild('advancePaymentPopUpTemplate') private advancePaymentPopUpTemplate;
    isUploaded = false;
    selectedAdvancePaymentDetails: any;
    showSpinnerForActionForUpload = false;
    panNumberUpload: any;
    truckNumberUpload: any;
    ackReckPopupStmtNo: any;

    splitedUrl: Array<any> = [];
    hireslipDtoForFinYear: HireSlipDto = new HireSlipDto();
    financialYear: any;
    fileName: any;
    truckDataDtoForRemoveImg: TruckDataDto = new TruckDataDto();
    showForSupplierWise = true;
    showForDestWise = false;
    pageId = "advpc";
    showHireColumns: Boolean = false;
    //payment flow
    dtTriggerAdvancePaymentCashChequePdc: Subject<any> = new Subject();
    dtOptionsAdvancePaymentCashChequePdc: any;
    advancePaymentChequePdcDataList: any;
    userDataDto: UserDataDto = new UserDataDto();
    bankNameOptions: UserDataDto[];
    public modelBankName: any;
    bankNameTA: Array<UserDataDto> = [];
    focusBankNameTA$ = new Subject<string>();
    searchBankName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusBankNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.bankNameTA
                : this.bankNameTA.filter(v => v.bankName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterBankName = (x: { bankName: string }) => x.bankName;

    viewCashChequePdcAmtField: boolean = true;
    viewChequePdcNoField: boolean = true;
    viewChequeDueDateField: boolean = false;
    viewChequePdcBankNameField: boolean = true;

    modelChequeDueDates: any;
    modelPayingDates: any;

    localStorageOfPaymentData: any;
    localStorageOfPaymentDataHireslipNumber: any;

    private newAttributeTosetBank: any = {};
    editBankTableDetailsId: any;

    validateBalAmtToBePaid: any;
    validateBalAmtCash: any;
    validateBalAmtCheque: any;
    validateBalAmtPdc: any;

    validateBalAmtForSave: any;
    validateSummCashAmtForSave: any;
    validateSummChequeAmtForSave: any;
    validateSummPdcAmtForSave: any;
    validatePayingDateForSave: any;
    validateInitBalAmtForSave: any;
    resultDuplicate: any;

    fortNightStmtDtoForGetUserValue: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoForReturnGetUserValue: FortNightStmtDto = new FortNightStmtDto();
    fortNightStmtDtoForMultipleValue: FortNightStmtDto = new FortNightStmtDto();

    Statement: any;
    hireslipNumber: any;
    selectedAdvancePayable: any;
    hireslipDtoForSave: HireSlipDto = new HireSlipDto();
    hireslipDtoForUserVal: HireSlipDto = new HireSlipDto();
    hireslipDtoForMulVal: HireSlipDto = new HireSlipDto();
    viewTdsVoucher = false;
    viewTdsVoucherWithPayment = false;
    validatePaymentPanNumber: any;
    requestDto: RequestDto = new RequestDto();
    tdsLabel = "TDS Deducted";
    isAdvancePaid: boolean = false;
    isPanAadharLinked: boolean = false;

    constructor(private router: Router, private hireslipService: HireslipService,
        public dialog: MatDialog, private masterReadService: MasterReadService,
        private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef, private ewaybillService: EwaybillService) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            //          userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.selectTodayDate();
            this.gettingFromStation();
            this.getToStationDetailsList();
            this.getSupplierDetailsList();
            this.getBankDetailsList();
            ///Rights
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.role != null
                        && this.userDataDtoReturnSession.role == "Booking Administrator") {
                        this.rightsOfHirelsipRpt = 'AdvancePayment Rpt R1';
                        // For Booking Administrator 
                    }
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "AdvPayment Rpt Show Hire") {
                        this.showHireColumns = true;
                    }
                }
            }
        }
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var groupColumn = 0;
        var groupColumnAdvPayment = 0;
        var exportAdvancePaymentArray = [], exportAdvancePaymentDestWiseArray = [];
        if (this.showHireColumns) {
            exportAdvancePaymentArray = [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 13, 14, 15, 16, 17, 18, 19];
            exportAdvancePaymentDestWiseArray = [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 13, 14, 15, 16, 17, 18, 19];
        } else {
            exportAdvancePaymentArray = [0, 2, 3, 4, 5, 6, 9, 10, 13, 14, 15, 16, 17, 18, 19];
            exportAdvancePaymentDestWiseArray = [0, 2, 3, 4, 5, 6, 9, 10, 13, 14, 15, 16, 17, 18, 19];
        }

        this.dtOptionsAdvancePayment = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                exportOptions: {
                    columns: exportAdvancePaymentArray
                },
                title: function () {
                    var returnSummaryExcelAmtColt = null;
                    returnSummaryExcelAmtColt = "Advance Payment Report " +
                        " From Date : " + moment($("#advpcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                        "To Date : " + moment($("#advpctoDate").val()).format('DD-MM-YYYY');
                    return returnSummaryExcelAmtColt;
                }
            }],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            destroy: true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var advance = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var balance = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var totalAmt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var cashPaid = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chqPaid = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var pdcPaid = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalAdvPaid = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var advBalance = api.column(16).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var tds = api.column(19).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total :' + data.length);
                $(api.column(1).footer()).html('Total :' + data.length);
                $(api.column(6).footer()).html(advance);
                $(api.column(7).footer()).html(balance);
                $(api.column(8).footer()).html(totalAmt);
                $(api.column(10).footer()).html(cashPaid);
                $(api.column(13).footer()).html(chqPaid);
                $(api.column(14).footer()).html(pdcPaid);
                $(api.column(15).footer()).html(totalAdvPaid);
                $(api.column(16).footer()).html(advBalance);
                $(api.column(19).footer()).html(tds);
            },
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;

                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var adv = 0;
                var bal = 0;
                var totAmt = 0;
                var cashPaid = 0;
                var totPdcPaid = 0;
                var totChqPaid = 0;
                var totAdvPaid = 0;
                var advBal = 0;
                var tdsDeducted = 0;
                var count = 0;
                //var pendingQty = 0;
                api.column(groupColumn, {
                    page: 'current'
                }).data().each(function (group, i) {

                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total"
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": adv
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": bal
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": cashPaid
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totChqPaid
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totPdcPaid
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totAdvPaid
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": advBal
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": tdsDeducted
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 3,
                                                "text": ""
                                            }))
                                        .prop('outerHTML'));
                            adv = 0;
                            bal = 0;
                            totAmt = 0;
                            cashPaid = 0;
                            totChqPaid = 0;
                            totPdcPaid = 0;
                            totAdvPaid = 0;
                            advBal = 0;
                            tdsDeducted = 0;
                            count = 0;
                            //pendingQty = 0;
                        }
                        //the below is for column name grouping (in this case we group for destination wise)
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 23,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }
                    count++;
                    adv += +val[6];
                    bal += +val[7];
                    totAmt += +val[8];
                    cashPaid += +val[10];
                    totChqPaid += +val[11];
                    totPdcPaid += +val[12];
                    totAdvPaid += +val[15];
                    advBal += +val[16];
                    tdsDeducted += +val[19];
                    //pendingQty += val[6];
                    if (i == (rows.length - 1)) {
                        //the below is for Sumary details grouping 
                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total"
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": + count
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": adv
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": bal
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totAmt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))

                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": cashPaid
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totChqPaid
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totPdcPaid
                                        }))

                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totAdvPaid
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": advBal
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": tdsDeducted
                                        }))

                                    .append($("<td></td>",
                                        {
                                            "colspan": 3,
                                            "text": ""
                                        }))

                                    .prop('outerHTML'));
                        adv = 0;
                        bal = 0;
                        totAmt = 0;
                        cashPaid = 0;
                        totChqPaid = 0;
                        totPdcPaid = 0;
                        totAdvPaid = 0;
                        advBal = 0;
                        tdsDeducted = 0;
                        count = 0;
                        //pendingQty = 0;
                    }
                });
            }
        },
            this.dtOptionsAdvancePaymentDestWise = {
                dom: 'Bfrtip',
                buttons: [{
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    exportOptions: {
                        columns: exportAdvancePaymentDestWiseArray
                    },
                    title: function () {
                        var returnSummaryExcelAmtColt = null;
                        returnSummaryExcelAmtColt = "Advance Payment Report " +
                            " From Date : " + moment($("#advpcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#advpctoDate").val()).format('DD-MM-YYYY');
                        return returnSummaryExcelAmtColt;
                    }
                }],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 300,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                destroy: true,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };

                    var advance = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var balance = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var totalAmt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var cashPaid = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chqPaid = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var pdcPaid = api.column(12).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var totalAdvPaid = api.column(15).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var advBalance = api.column(16).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var tds = api.column(19).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    // the below is for Export
                    $(api.column(0).footer()).html('Total :' + data.length);
                    // the below is for UI
                    $(api.column(1).footer()).html('Total :' + data.length);
                    $(api.column(6).footer()).html(advance);
                    $(api.column(7).footer()).html(balance);
                    $(api.column(8).footer()).html(totalAmt);
                    $(api.column(10).footer()).html(cashPaid);
                    $(api.column(13).footer()).html(chqPaid);
                    $(api.column(14).footer()).html(pdcPaid);
                    $(api.column(15).footer()).html(totalAdvPaid);
                    $(api.column(16).footer()).html(advBalance);
                    $(api.column(19).footer()).html(tds);
                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;
                    var adv = 0;
                    var bal = 0;
                    var totAmt = 0;
                    var cashPaid = 0;
                    var totPdcPaid = 0;
                    var totChqPaid = 0;
                    var totAdvPaid = 0;
                    var advBal = 0;
                    var tdsDeducted = 0;
                    var count = 0;
                    //var pendingQty = 0;
                    api.column(groupColumn, {
                        page: 'current'
                    }).data().each(function (group, i) {

                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": "Total"
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": + count
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": adv
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": bal
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totAmt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": cashPaid
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totChqPaid
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totPdcPaid
                                                }))

                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totAdvPaid
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": advBal
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": tdsDeducted
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 3,
                                                    "text": ""
                                                }))
                                            .prop('outerHTML'));
                                adv = 0;
                                bal = 0;
                                totAmt = 0;
                                cashPaid = 0;
                                totChqPaid = 0;
                                totPdcPaid = 0;
                                totAdvPaid = 0;
                                advBal = 0;
                                tdsDeducted = 0;
                                count = 0;
                                //pendingQty = 0;
                            }
                            //the below is for column name grouping (in this case we group for destination wise)
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 23,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }
                        count++;
                        adv += +val[6];
                        bal += +val[7];
                        totAmt += +val[8];
                        cashPaid += +val[10];
                        totChqPaid += +val[11];
                        totPdcPaid += +val[12];
                        totAdvPaid += +val[15];
                        advBal += +val[16];
                        tdsDeducted += +val[19];
                        //pendingQty += val[6];
                        if (i == (rows.length - 1)) {
                            //the below is for Sumary details grouping 
                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total"
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": adv
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": bal
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": cashPaid
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totChqPaid
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totPdcPaid
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totAdvPaid
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": advBal
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": tdsDeducted
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 3,
                                                "text": ""
                                            }))

                                        .prop('outerHTML'));
                            adv = 0;
                            bal = 0;
                            totAmt = 0;
                            cashPaid = 0;
                            totChqPaid = 0;
                            totPdcPaid = 0;
                            totAdvPaid = 0;
                            advBal = 0;
                            tdsDeducted = 0;
                            count = 0;
                            //pendingQty = 0;
                        }
                    });
                }
            },
            this.dtOptionsAdvancePaymentCashChequePdc = {
                dom: 'Bfrtip',
                buttons: [

                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 150,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                searching: false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var totaAmt = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    $(api.column(0).footer()).html('Total');
                    $(api.column(2).footer()).html(totaAmt);
                },
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;
                    var totalBankAmt = 0;
                    var count = 0;
                    //var pendingQty = 0;
                    api.column(groupColumnAdvPayment, {
                        page: 'current'
                    }).data().each(function (group, i) {

                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": "Total : " + count
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                })).append($("<td></td>",
                                                    {
                                                        "colspan": 1,
                                                        "text": totalBankAmt
                                                    })).append($("<td></td>",
                                                        {
                                                            "colspan": 1,
                                                            "text": ""
                                                        })).append($("<td></td>",
                                                            {
                                                                "colspan": 1,
                                                                "text": ""
                                                            })).append($("<td></td>",
                                                                {
                                                                    "colspan": 1,
                                                                    "text": ""
                                                                }))
                                            .prop('outerHTML'));
                                totalBankAmt = 0;
                                count = 0;
                                //pendingQty = 0;
                            }
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 6,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }
                        count++;
                        totalBankAmt += +val[2];
                        //pendingQty += val[6];
                        if (i == (rows.length - 1)) {

                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count

                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totalBankAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            })).append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                        .prop('outerHTML'));
                            totalBankAmt = 0;
                            count = 0;
                        }
                    });
                }
            }

    }

    ngAfterViewInit(): void {
        this.dtTriggerAdvancePayment.next();
        this.dtTriggerAdvancePaymentDestWise.next();
        this.dtTriggerAdvancePaymentCashChequePdc.next();
    }
    ngOnDestroy(): void {
        this.dtTriggerAdvancePayment.unsubscribe();
        this.dtTriggerAdvancePaymentDestWise.unsubscribe();
        this.dtTriggerAdvancePaymentCashChequePdc.unsubscribe();
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    selectTodayDate() {
        this.setTodayDateOnToDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        this.setTodayDateOnFromDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };

        $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
        $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
    }

    gettingFromStation() {
        if ((this.rightsOfHirelsipRpt == 'rightsOfHirelsipRpt') && (this.userDataDtoReturnSession.stationList != null)) {
            //    for boooking adminsitrator
            this.srcLists = this.userDataDtoReturnSession.stationList;
            this.srcList.source = this.srcLists;
            console.log(this.srcLists);
            console.log(this.srcList.source);
            this.lrDtoFromStnAll.source = "ALL";
            this.sourceSubStationNameTA.push(this.lrDtoFromStnAll);
            for (let i = 0; i < this.srcList.source.length; i++) {
                this.srcListForBkgAdmin = new LRDto();
                this.srcListForBkgAdmin.source = this.srcList.source[i];
                console.log(this.srcListForBkgAdmin.source);
                this.sourceSubStationNameTA.push(this.srcListForBkgAdmin);

            }
        } else {
            this.gettingFromStationList();
        }

    }
    gettingFromStationDetails() {
        this.lrDtoFromStn.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoFromStn.mode = "notStmtInvRpt";
    }
    gettingFromStationList = () => {
        this.showSpinnerForAction = true;
        this.gettingFromStationDetails();
        this.masterReadService.getFromStationService(this.lrDtoFromStn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Source records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {

                    this.sourceSubStationNameTA = [];
                    this.lrDtosFromStnOptionsGet = [];
                    this.lrDtosFromStnOptionsGet = response;
                    this.lrDtoFromStnAll.source = "ALL";
                    this.sourceSubStationNameTA.push(this.lrDtoFromStnAll);
                    for (let i = 0; i < this.lrDtosFromStnOptionsGet.length; i++) {
                        this.sourceSubStationNameTA.push(this.lrDtosFromStnOptionsGet[i]);

                    }


                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Destination Details", "warning");
            },
            () => console.log('done');
    };


    /// Get To Stations
    getToStationDetails() {
        this.lrDtoToStation.companyId = this.userDataDtoReturnSession.companyId;

    }
    getToStationDetailsList = () => {
        this.getToStationDetails();
        this.masterReadService.getDestinationListDetails(this.lrDtoToStation).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Destination records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {

                    this.destinationOptions = response;
                    console.log(this.destinationOptions);
                    this.destinationTA = [];
                    this.destinationDtoForAll.godownName = "ALL";
                    this.destinationTA.push(this.destinationDtoForAll);
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);

                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Destination Details", "warning");
            },
            () => console.log('done');
    };

    getSupplierDetails() {
        this.supplierDetailsDto = new SupplierDetailsDto();
        this.supplierDetailsDto.companyId = this.userDataDtoReturnSession.companyId;
        //this.supplierDetailsDto.mode = "Working";
    }
    getSupplierDetailsList = () => {
        this.getSupplierDetails();
        this.showSpinnerForAction = true;
        this.masterReadService.getSuplierMaster(this.supplierDetailsDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    this.supplierOptions = [];
                    this.supplierNameTA = [];
                    swal("Warning", "No Supplier Master records found!", "warning");
                } else {
                    this.supplierOptions = [];
                    this.supplierNameTA = [];
                    this.supplierOptions = response;
                    this.supplierDetailsDtoAll.supplierName = "All";
                    this.supplierNameTA.push(this.supplierDetailsDtoAll);
                    for (let i = 0; i < this.supplierOptions.length; i++) {
                        this.supplierNameTA.push(this.supplierOptions[i]);

                    }
                }
                //this.showSpinnerForActionForInvoiceDetails = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Supplier Master Details", "warning");
            },
            () => console.log('done');
    };

    searchMethod() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedSource = $("#" + this.pageId + "sourceId").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedSupplierName = $("#" + this.pageId + "supplierName").val();
        this.selectedViewBy = $("#" + this.pageId + "viewBy").val();
        this.selectedPaymentMethod = $("#" + this.pageId + "paymentMethod").val();
        this.selectedSearchBy = $("#" + this.pageId + "searchBy").val();

        if (this.selectedSource == null || this.selectedSource == '' || this.selectedSource == undefined) {
            swal({
                title: "Mandatory Field",
                text: "Please select Source",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else if (this.selectedDestination == null || this.selectedDestination == '' || this.selectedDestination == undefined) {
            swal({
                title: "Mandatory Field",
                text: "Please select Destination",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }
        this.hireslipDtoForSearch = new HireSlipDto();
        this.hireslipDtoForSearch.fromDate = this.selectedFromDate;
        this.hireslipDtoForSearch.toDate = this.selectedToDate;
        this.hireslipDtoForSearch.suppliername = this.selectedSupplierName;
        if (this.selectedPaymentMethod == 'all') {
            this.hireslipDtoForSearch.mode = 'All';
        } else if (this.selectedPaymentMethod == 'paid') {
            this.hireslipDtoForSearch.mode = 'Paid';
        } else if (this.selectedPaymentMethod == 'unPaid') {
            this.hireslipDtoForSearch.mode = 'Unpaid';
        }
        if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination == 'ALL') {
            this.hireslipDtoForSearch.reportMode = 'All';
        } else {
            this.hireslipDtoForSearch.reportMode = 'Specific';
        }
        this.hireslipDtoForSearch.stationList = this.userDataDtoReturnSession.stationList;
        this.hireslipDtoForSearch.fromStation = this.selectedSource;
        this.hireslipDtoForSearch.toStation = this.selectedDestination;

        if (this.selectedSearchBy != null && this.selectedSearchBy != '' && this.selectedSearchBy == 'hireslipDateWise') {
            this.hireslipDtoForSearch.searchBy = 'hireslipdate';
        } else {
            this.hireslipDtoForSearch.searchBy = 'advPaymentDate';
        }
        this.hireslipDtoForSearch.financialyear = '';
        this.hireslipDtoForSearch.companyid = this.userDataDtoReturnSession.companyId;
        console.log(this.hireslipDtoForSearch);
        this.searchMethodDetails();
    }

    searchMethodDetails = () => {
        this.showSpinnerForAction = true;
        this.hireslipService.getAdvancePaymentDetails(this.hireslipDtoForSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "advancePaymentTableId").DataTable().destroy();
                this.advancePaymentDataList = [];

                $("#" + this.pageId + "advancePaymentTableIdDestWise").DataTable().destroy();
                this.advancePaymentDataListDestWise = [];
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    if (this.selectedViewBy == 'supplierWise') {
                        this.advancePaymentDataList = response;
                    } else {
                        this.advancePaymentDataListDestWise = response;
                    }
                    console.log(this.advancePaymentDataList);
                }
                if (this.selectedViewBy == 'supplierWise') {
                    this.dtTriggerAdvancePayment.next();
                } else {
                    this.dtTriggerAdvancePaymentDestWise.next();
                }


                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Advance Payment details", "info");
            }, () => console.log('done');
    };


    getChqInfoTable(advancePaymentData) {
        if (advancePaymentData.advTotalChqAmt != null && advancePaymentData.advTotalChqAmt != 0) {
            this.chqInfoDialog(advancePaymentData);
        }
    }
    //for modal starts
    chqInfoDialog(advancePaymentData): void {
        console.log(advancePaymentData);
        this.listLrDtosChq = new LRDto();
        this.listLrDtosChq.chequeNumber = advancePaymentData.advChqNumber;
        this.listLrDtosChq.chequeAmt = advancePaymentData.advChqAmt;
        this.listLrDtosChqList = [];
        this.listLrDtosChqList.push(this.listLrDtosChq);

        //  this.listLrDtosBay= [advancePaymentData.advChqNumber,advancePaymentData.advChqAmt];
        const dialogRef = this.dialog.open(ChequeInfoPopupComponent, {
            data: { listOfDataToPass: this.listLrDtosChqList }
        });

        // dialogRef.afterClosed().subscribe(result => {

        //     this.router.navigate(['.'], { relativeTo: this.route });
        // });
    }

    getPdcInfoTable(advancePaymentData) {
        if (advancePaymentData.advPdcChqAmt != null && advancePaymentData.advPdcChqAmt != 0) {
            this.pdcInfoDialog(advancePaymentData);
        }
    }
    //for modal starts
    pdcInfoDialog(advancePaymentData): void {
        console.log(advancePaymentData);
        this.listLrDtosPdc = new LRDto();
        this.listLrDtosPdc.chequeNumber = advancePaymentData.advPdcChqNumber;
        this.listLrDtosPdc.chequeAmt = advancePaymentData.advPdcChqAmt;
        this.listLrDtosPdcList = [];
        this.listLrDtosPdcList.push(this.listLrDtosPdc);

        //  this.listLrDtosBay= [advancePaymentData.advChqNumber,advancePaymentData.advChqAmt];
        const dialogRef = this.dialog.open(PdcInfoPopupComponent, {
            data: { listOfDataToPass: this.listLrDtosPdcList }
        });

    }

    setConfirmPayment(advancePaymentData) {
        this.localStorageOfPaymentData = advancePaymentData;
        this.filledValues(this.localStorageOfPaymentData);
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    closeConfirmPaymentDetailsPopUp() {
        this.modalRefferenceConfirmPaymentPopUp.close();

    }
    clearAll() {
        $("#" + this.pageId + "sourceId").val('ALL');
        $("#" + this.pageId + "destination").val('ALL');
        $("#" + this.pageId + "supplierName").val('All');
        $("#" + this.pageId + "viewBy").val('supplierWise');
        $("#" + this.pageId + "paymentMethod").val('all');
        $("#" + this.pageId + "searchBy").val('hireslipDateWise');
        this.tdsLabel = "TDS Deducted";
        this.isPanAadharLinked = false;
        this.isAdvancePaid = false;
        $("#" + this.pageId + "paymentPanNo").prop('readonly', false);
        $("#" + this.pageId + "validatePanAadharBtn").prop('disabled', false);

        this.showSpinnerForAction = false;
        this.selectedFromDate = '';
        this.selectedToDate = '';
        this.selectedSource = '';
        this.selectedDestination = '';
        this.selectedSupplierName = '';
        this.selectedViewBy = '';
        this.selectedPaymentMethod = '';
        this.selectedSearchBy = '';
        this.hireslipDtoForSearch = new HireSlipDto();
        this.listLrDtosChq = new LRDto();
        this.listLrDtosChqList = [];
        this.listLrDtosPdc = new LRDto();
        this.listLrDtosPdcList = [];
        $("#" + this.pageId + "advancePaymentTableId").DataTable().destroy();
        $("#" + this.pageId + "advancePaymentTableIdDestWise").DataTable().destroy();
        this.advancePaymentDataList = [];
        this.advancePaymentDataListDestWise = [];
        this.dtTriggerAdvancePayment.next();
        this.dtTriggerAdvancePaymentDestWise.next();
        this.selectTodayDate();
        this.viewPaymentVoucher = false;
        this.panNumberUpload = '';
        this.truckNumberUpload = '';
        this.splitedUrl = [];
        this.hireslipDtoForFinYear = new HireSlipDto();
        this.financialYear = '';
        this.fileName = '';
        this.truckDataDtoForRemoveImg = new TruckDataDto();
        this.viewConfirmPaymentPopUp = false;
        this.hirePrintDto = new HireSlipDto();
        this.valueMin = 0.0;
        this.valueMax = 0.0;
        this.isUploaded = false;
    }


    setPrintVoucher(advancePaymentData) {
        this.hirePrintDto = new HireSlipDto();
        this.hirePrintDto.fromStation = advancePaymentData.fromStation;
        this.hirePrintDto.toStation = advancePaymentData.toStation;
        this.hirePrintDto.vehicleNumber = advancePaymentData.vehicleNumber;
        this.hirePrintDto.hireslipnumber = advancePaymentData.hireslipnumber;
        this.hirePrintDto.hireslipdate = advancePaymentData.hireslipdate;
        this.hirePrintDto.suppliername = advancePaymentData.supplierName;
        this.hirePrintDto.tdsAmount = advancePaymentData.tdsAmount;
        this.hirePrintDto.tdsPercent = advancePaymentData.tdsPercent;
        this.hirePrintDto.totalhire = advancePaymentData.totalhire;
        this.hirePrintDto.advance = advancePaymentData.advance;
        this.hirePrintDto.paid = advancePaymentData.advTotalPaid;
        this.hirePrintDto.paymentDate = advancePaymentData.paymentDate;
        this.hirePrintDto.advCashAmt = advancePaymentData.cashAmt;
        this.hirePrintDto.advTotalChqAmt = advancePaymentData.advTotalChqAmt;
        this.hirePrintDto.advTotalPdcAmt = advancePaymentData.advTotalPdcAmt;
        this.hirePrintDto.chequeno = advancePaymentData.advChqNumber;
        this.hirePrintDto.column1 = advancePaymentData.advPdcChqNumber;
        this.hirePrintDto.advChqBankName = advancePaymentData.advChqBankName;
        this.hirePrintDto.advPdcChqBankName = advancePaymentData.advPdcChqBankName;
        this.hirePrintDto.advChequeAmt = advancePaymentData.advTotalChqAmt;
        this.hirePrintDto.cashAmt = advancePaymentData.cashAmt;
        this.viewPaymentVoucher = false;
        console.log(this.hirePrintDto);
        this.printVocher();

    }
    printVocher() {
        swal({
            title: "Payment Print",
            text: "Sure U Want To Print",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((paymentPrint) => {
            if (paymentPrint) {
                this.hirePrintDto.size = 1;
                console.log('total value : ' + this.hirePrintDto.cashAmt + " - " + this.hirePrintDto.advTotalChqAmt + " - " + this.hirePrintDto.advTotalPdcAmt);
                if (this.hirePrintDto.cashAmt > 0) {
                    console.log('cash');
                    this.cashPrint(this.hirePrintDto);
                } else if (this.hirePrintDto.advTotalChqAmt > 0) {
                    console.log('cheque');
                    this.chequePrint();
                } else if (this.hirePrintDto.advTotalPdcAmt > 0) {
                    console.log('pdc');
                    this.pdcPrint();
                }
            }
        });
    }

    cashPrint(hirePrintDto) {
        swal({
            title: "Payment Confirmation",
            text: "Sure U Want To Print the Cash Voucher",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((cash) => {
            if (cash) {
                console.log('paymentCalculation 1 :' + this.hirePrintDto.cashAmt);
                if (this.hirePrintDto.cashAmt > 20000) {
                    console.log('paymentCalculation 2');
                    this.paymentCalculation(this.hirePrintDto.cashAmt);
                    this.hirePrintDto.paid = this.hirePrintDtoPrintVoucher.paid;
                    this.hirePrintDto.size = this.hirePrintDtoPrintVoucher.size;
                } else {
                    this.hirePrintDto.paid = hirePrintDto.cashAmt;
                }

                this.hirePrintDto.mode = 'cash';
                localStorage.clear();
                console.log(this.hirePrintDto);
                localStorage.setItem('paymentVoucherPrint', JSON.stringify(this.hirePrintDto));
                this.viewPaymentVoucher = true;
                window.addEventListener('afterprint', (onclick) => {
                    console.log('chekc');
                    if (this.viewPaymentVoucher) {
                        this.viewPaymentVoucher = false;
                        if (this.hirePrintDto.advTotalChqAmt > 0) {
                            this.chequePrint();
                        }
                    }
                });
            } else {
                if (this.hirePrintDto.advTotalChqAmt > 0) {
                    this.chequePrint();
                }
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    paymentCalculation(sum) {
        console.log('paymentCalculation 3');
        var lastValue = 20001, firstValve = 0, iterValve = 1;
        var finalValueMin = 0;
        var resultValue = 0.0, finalValue = 0.0, maxIterValue = 0.0;
        var finalResult = null;
        for (let i = 2; i < 20; i++) {
            let z = i * 20000;
            firstValve = lastValue;
            lastValue = z;
            maxIterValue = ++iterValve;
            if ((sum >= firstValve) && (sum <= lastValue)) {
                resultValue = maxIterValue;
                break;
            }
            lastValue = lastValue + 1;
        }
        console.log("sum - " + sum + "resultValue" + resultValue);
        finalValue = sum / resultValue;
        console.log("finalValue - " + finalValue);
        finalValueMin = Math.round(finalValue);
        finalResult = finalValueMin + "";
        this.valueMin = finalResult;

        this.hirePrintDtoPrintVoucher = new HireSlipDto();
        this.hirePrintDtoPrintVoucher.paid = this.valueMin;
        this.hirePrintDtoPrintVoucher.size = resultValue;
    }

    chequePrint() {
        swal({
            title: "Payment Confirmation",
            text: "Sure U Want To Print the Cheque Voucher",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((cheque) => {
            if (cheque) {
                console.log('inside chq : ' + this.hirePrintDto.advTotalChqAmt)
                this.hirePrintDto.paid = this.hirePrintDto.advTotalChqAmt;
                this.hirePrintDto.mode = 'cheque';
                this.hirePrintDto.size = 1;
                if (this.hirePrintDto.chequeno != null) {
                    var chequeNo = this.hirePrintDto.chequeno;
                    var chequeNoNew = chequeNo.replace('#', ',');
                    this.hirePrintDto.chequeno = chequeNoNew;
                }
                if (this.hirePrintDto.advChqBankName != null) {
                    var cheqBankName = this.hirePrintDto.advChqBankName;
                    var cheqBankNameNew = cheqBankName.replace('#', ',');
                    this.hirePrintDto.advChqBankName = cheqBankNameNew;
                }
                localStorage.clear();
                localStorage.setItem('paymentVoucherPrint', JSON.stringify(this.hirePrintDto));
                this.viewPaymentVoucherChq = true;
                window.addEventListener('afterprint', (onclick) => {
                    if (this.viewPaymentVoucherChq) {
                        this.viewPaymentVoucherChq = false;
                        if (this.hirePrintDto.advTotalPdcAmt > 0) {
                            this.pdcPrint();
                        }
                    }

                });

            } else {
                if (this.hirePrintDto.advTotalPdcAmt > 0) {
                    this.pdcPrint();
                }
            }
            this.changeDetectorRef.detectChanges();
        });

    }
    pdcPrint() {
        swal({
            title: "Payment Confirmation",
            text: "Sure U Want To Print the Pdc Voucher",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((pdc) => {
            if (pdc) {
                this.hirePrintDto.paid = this.hirePrintDto.advTotalPdcAmt;
                this.hirePrintDto.mode = 'pdcCheque';
                this.hirePrintDto.size = 1;
                if (this.hirePrintDto.column1 != null) {
                    var pdcChequeNo = this.hirePrintDto.column1;
                    var pdcChequeNoNew = pdcChequeNo.replace('#', ',');
                    this.hirePrintDto.column1 = pdcChequeNoNew;
                }
                if (this.hirePrintDto.advPdcChqBankName != null) {
                    var pdcBankName = this.hirePrintDto.advPdcChqBankName;
                    var pdcBankNameNew = pdcBankName.replace('#', ',');
                    this.hirePrintDto.advChqBankName = pdcBankNameNew;
                }
                localStorage.clear();
                localStorage.setItem('paymentVoucherPrint', JSON.stringify(this.hirePrintDto));
                this.viewPaymentVoucherPdc = true;
                window.addEventListener('afterprint', (onclick) => {
                    if (this.viewPaymentVoucherPdc) {
                        console.log('chekc');
                        this.viewPaymentVoucherPdc = false;
                    }
                });
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    paymentVoucher() {
        localStorage.clear();
        console.log(this.hirePrintDto);
        localStorage.setItem('paymentVoucherPrint', JSON.stringify(this.hirePrintDto));
        this.viewPaymentVoucher = true;
        setTimeout(() => {
            this.viewPaymentVoucher = false;
            console.log('not workingdd');
        }, 1000);
        window.addEventListener('afterPopUp', (event) => {
            this.viewPaymentVoucher = false;
            console.log('not working');
        });
    }


    getImg(advancePaymentData, advancePaymentPopUpTemplate) {
        this.getFinancialYearDetails(advancePaymentData.hireSlipDateStr);
        this.truckNumberUpload = '';
        this.panNumberUpload = '';

        if (advancePaymentData.imgURLPan == null || advancePaymentData.imgURLPan == undefined) {

            this.panNumberUpload = advancePaymentData.panNo;
            if (this.panNumberUpload.toUpperCase() == "AAAAA9999A") {

                swal({
                    title: "Warning",
                    text: "Please Update Correct Pan Number",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });

            } else {

                this.truckNumberUpload = advancePaymentData.vehicleNumber;
                this.fileName = '';
                // fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
                this.fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
                console.log(this.fileName);
                console.log(this.financialYear);
                //Upload Pop up Pending
                this.modalRefferenceAdvancePaymentPopUp = this.modalService.open(advancePaymentPopUpTemplate,
                    { centered: true });
                this.modalRefferenceAdvancePaymentPopUp.result.then((result) => {
                    this.modalRefferenceAdvancePaymentClosePopUp = `Closed with: ${result}`;
                }, reason => {
                    this.modalRefferenceAdvancePaymentClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
                });


            }

        } else {
            var imageUrl = advancePaymentData.imgURLPan;
            var newimageUrl = "https://vehicledriverdocs.s3.amazonaws.com/truckownerdeclaration/";
            this.splitedUrl = null;
            this.splitedUrl = imageUrl.split("#");
            if (this.splitedUrl.length > 1) {
                console.log(this.splitedUrl.length);
                for (let i = 0; i < this.splitedUrl.length; i++) {
                    console.log(i);
                    if (i == 0) {
                        console.log('add');
                        window.open(newimageUrl + this.splitedUrl[i], "Decl. Document - ", "");
                    } else if (i == 1) {
                        console.log('add111');
                        this.openWindow(newimageUrl, this.splitedUrl[i]);

                    }

                }

            } else {
                var completeImg = newimageUrl + imageUrl;
                console.log('ddaaa');

                window.open(completeImg, "Decl. Document", "");
                imageUrl = "";
            }
        }

    }

    openWindow(newimageUrl, image) {
        console.log(image);
        window.open(newimageUrl + image, "Decl. Document - ", "_blank");
    }

    applyAdvancePaymentBtnPopUp() {
        //	this.updateAckRecDetails();
    }

    closeAdvancePaymentBtnPopUp() {
        this.modalRefferenceAdvancePaymentPopUp.close();
        this.isUploaded = false;
        this.financialYear = '';
        this.fileName = '';
        $("#" + this.pageId + "advancePaymentFileUpload").val('');

    }


    onFileChangedAdvancePayment(event) {
        this.selectedAdvancePaymentDetails = event.target.files[0];
        console.log(this.selectedAdvancePaymentDetails);
    }

    uploadAdvancePaymentBtnPopUp() {
        console.log($("#" + this.pageId + "advancePaymentFileUpload").val());
        if ($("#" + this.pageId + "advancePaymentFileUpload").val() != null && $("#" + this.pageId + "advancePaymentFileUpload").val() != "") {
            this.showSpinnerForActionForUpload = true;
            console.log(this.selectedAdvancePaymentDetails.type);
            if ((this.selectedAdvancePaymentDetails.type != null &&
                (this.selectedAdvancePaymentDetails.type == "image/jpeg" ||
                    this.selectedAdvancePaymentDetails.type == "image/jpg" ||
                    this.selectedAdvancePaymentDetails.type == "image/png" ||
                    this.selectedAdvancePaymentDetails.type == "application/pdf"))) {
                var splitTypeAdvancePayment = this.selectedAdvancePaymentDetails.type.split("/");

                // const commonListAdvancePayment = "vehicledriverdocs&&documents&&ASRAR&&" + splitTypeAdvancePayment[1] + "&&" + this.fileName + "";
                const commonListAdvancePayment = "vehicledriverdocs&&truckownerdeclaration&&ASRAR&&" + splitTypeAdvancePayment[1] + "&&" + this.fileName + "";
                const formData = new FormData();
                formData.append(commonListAdvancePayment, commonListAdvancePayment);
                formData.append('truckownerdeclaration', this.selectedAdvancePaymentDetails, this.selectedAdvancePaymentDetails.name);
                this.masterReadService.uploadFile(formData).subscribe(
                    (response) => {
                        if (response) {
                            console.log(response);
                            this.showSpinnerForActionForUpload = false;
                            if (response[0] != "Error") {
                                swal("File Upload", "File Uploaded Succcessfully", "success");
                                this.isUploaded = true;
                            } else {
                                swal("ERROR", "Correct the File and Upload Again", "error");
                            }
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => {
                        this.showSpinnerForActionForUpload = false;
                        swal("Error", "Server Error While Uploading File", "info");
                    }, () => console.log('done');
            } else {
                this.showSpinnerForActionForUpload = false;
                swal("Mandatory Field", "Only jpg & png image format are allowed to upload", "warning");
            }
        }

    }

    getFinancialYearDetails(hireSlipDate) {
        console.log('Hireslipdate : ' + hireSlipDate);
        this.hireslipDtoForFinYear = new HireSlipDto();
        this.hireslipDtoForFinYear.fromDate = hireSlipDate;
        console.log(this.hireslipDtoForFinYear);
        this.getFinancialYearInfo();
    }

    getFinancialYearInfo = () => {

        this.hireslipService.getFinancialYearForUpload(this.hireslipDtoForFinYear).subscribe(
            (response) => {
                this.showSpinnerForAction = false;

                if (response.financialYear == null) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.financialYear = response.financialYear;
                    this.fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
                    console.log('fin year : ' + this.financialYear);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {

                swal("Error", "Server Problem Occurred While getting the Advance Payment details", "info");
            }, () => console.log('done');
    };


    getImgForReUpload(advancePaymentData, advancePaymentPopUpTemplate) {
        this.getFinancialYearDetails(advancePaymentData.hireSlipDateStr);
        this.truckNumberUpload = '';
        this.panNumberUpload = '';

        if (advancePaymentData.imgURLPan != null && advancePaymentData.imgURLPan != undefined && advancePaymentData.imgURLPan != '') {

            this.panNumberUpload = advancePaymentData.panNo;

            this.truckNumberUpload = advancePaymentData.vehicleNumber;
            this.fileName = '';
            // fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
            this.fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
            console.log(this.fileName);
            console.log(this.financialYear);
            console.log('aaaa');
            //Upload Pop up Pending
            this.modalRefferenceAdvancePaymentPopUp = this.modalService.open(advancePaymentPopUpTemplate,
                { centered: true });
            this.modalRefferenceAdvancePaymentPopUp.result.then((result) => {
                this.modalRefferenceAdvancePaymentClosePopUp = `Closed with: ${result}`;
            }, reason => {
                this.modalRefferenceAdvancePaymentClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
            });

        } else {

            swal({
                title: "Alert",
                text: "Pleaase Upload The Image First",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        }

    }


    getImgForRemove(advancePaymentData) {
        this.getFinancialYearDetails(advancePaymentData.hireSlipDateStr);
        if (advancePaymentData.imgURLPan != null && advancePaymentData.imgURLPan != undefined && advancePaymentData.imgURLPan != '') {
            swal({
                title: "Confirm Apply",
                text: "Sure You Want To Remove the Declaration",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((remove) => {
                if (remove) {
                    this.removeImgMethod(advancePaymentData);
                }
            });

        } else {

            swal({
                title: "Alert",
                text: "Pleaase Upload The Image First",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }
    }
    removeImgMethod(advancePaymentData) {
        this.panNumberUpload = advancePaymentData.panNo;
        this.truckNumberUpload = advancePaymentData.vehicleNumber;

        this.truckDataDtoForRemoveImg = new TruckDataDto();
        this.truckDataDtoForRemoveImg.mode = 'particularTruck';
        this.truckDataDtoForRemoveImg.panNo = this.panNumberUpload;
        this.truckDataDtoForRemoveImg.truckNumber = this.truckNumberUpload;
        this.truckDataDtoForRemoveImg.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDtoForRemoveImg.financialYear = this.financialYear;
        console.log(this.truckDataDtoForRemoveImg);
        this.removeImgDetails();

    }

    removeImgDetails = () => {

        this.masterReadService.setTruckOwnerDetails(this.truckDataDtoForRemoveImg).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                this.searchMethodDetails();
                this.changeDetectorRef.detectChanges();
            }), (error) => {

                swal("Error", "Server Error While setTruckOwnerDetails", "info");
            }, () => console.log('done');
    };

    clearTable() {
        $("#" + this.pageId + "advancePaymentTableId").DataTable().destroy();
        this.dtTriggerAdvancePayment.next();
        $("#" + this.pageId + "advancePaymentTableIdDestWise").DataTable().destroy();
        this.dtTriggerAdvancePaymentDestWise.next();
    }


    viewByMode(viewBy: string) {
        if (viewBy === 'supplierWise') {
            this.showForSupplierWise = true;
            this.showForDestWise = false;
            this.clearTable();

        } else if (viewBy === 'destinationWise') {
            this.showForSupplierWise = false;
            this.showForDestWise = true;
            this.clearTable();

        } else {
            this.showForSupplierWise = false;
            this.showForDestWise = false;
            this.clearTable();
        }
    }

    clickListnerForBankName(e: NgbTypeaheadSelectItemEvent) {
        this.modelBankName = e.item;
        $("#" + this.pageId + "bankNameId").val(this.modelBankName.bankName);
        $("#" + this.pageId + "bankNameId").focusout();
        $("#" + this.pageId + "" + this.pageId + "cashChequePdcAmount").focus();

    }

    getDetailsForBankMasterRead() {
        this.userDataDto.office = this.userDataDtoReturnSession.mainStation;
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.status = "Working";
    }

    getBankDetailsList = () => {
        this.showSpinnerForAction = true;
        this.bankNameOptions = [];
        this.getDetailsForBankMasterRead();
        this.masterReadService.getBankMasterDetails(this.userDataDto).subscribe(
            (response) => {
                if (response) {
                    if (response.length > 0) {
                        this.bankNameOptions = response;
                        this.bankNameTA = [];
                        for (let i = 0; i < this.bankNameOptions.length; i++) {
                            this.bankNameTA.push(this.bankNameOptions[i]);
                        }
                        this.showSpinnerForAction = false;
                    } else {
                        this.showSpinnerForAction = false;
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Bank Master Details", "error");
            },
            () => console.log('done');
    }

    payemntModeTypeListner(modeType: string) {
        if (modeType == "Cash") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = false;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = false;
            $("#" + this.pageId + "chequePdcNo").val('');
            $("#" + this.pageId + "chequeDueDates").val('');
            $("#" + this.pageId + "bankNameId").val('');
            $("#" + this.pageId + "cashChequePdcAmount").val('');
            $("#" + this.pageId + "cashChequePdcAmount").focus();
            this.modelChequeDueDates = null;
            this.modelBankName = null;
        } else if (modeType == "Cheque") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = true;
            $("#" + this.pageId + "chequePdcNo").focus();
            $("#" + this.pageId + "chequePdcNo").val('');
            $("#" + this.pageId + "chequeDueDates").val('');
            $("#" + this.pageId + "bankNameId").val('');
            $("#" + this.pageId + "cashChequePdcAmount").val('');
            this.modelChequeDueDates = null;
            this.modelBankName = null;

        } else if (modeType == "PDC") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = true;
            this.viewChequePdcBankNameField = true;
            $("#" + this.pageId + "chequePdcNo").val('');
            $("#" + this.pageId + "chequeDueDates").val('');
            $("#" + this.pageId + "bankNameId").val('');
            $("#" + this.pageId + "cashChequePdcAmount").val('');
            $("#" + this.pageId + "chequePdcNo").focus();
            this.modelChequeDueDates = null;
            this.modelBankName = null;
        }
    }

    chequePdcNoEvent(e) {
        if (e.keyCode == 13) {
            if (($("#" + this.pageId + "payemntModeType").val() == "Cheque")) {
                $("#" + this.pageId + "bankNameId").focus();
            } else if (($("#" + this.pageId + "payemntModeType").val() == "PDC")) {
                $("#" + this.pageId + "chequeDueDates").focus();
            }
        }
    }

    filledValues(localStorageOfPaymentData) {
        console.log(localStorageOfPaymentData);
        var chequeAmtSumm = 0.0, pdcAmtSmm = 0.0;
        this.isAdvancePaid = localStorageOfPaymentData.isLryAdvPaid;
        console.log(this.isAdvancePaid);
        var cashAmt = [];
        var chequeAmt = [], chequeNumber = [], cheqBankName = [];
        var pdcAmt = [], pdcNumber = [], pdcDueDate = [], pdcBankName = [];
        cashAmt = localStorageOfPaymentData.cashAmt == null ? null : localStorageOfPaymentData.cashAmt == 0 ? null
            : localStorageOfPaymentData.cashAmt;

        //cheque
        chequeAmt = localStorageOfPaymentData.advChqAmt == null ? null
            : localStorageOfPaymentData.advChqAmt.split("#");
        chequeNumber = localStorageOfPaymentData.advChqNumber == null ? null
            : localStorageOfPaymentData.advChqNumber.split("#");
        cheqBankName = localStorageOfPaymentData.advChqBankName == null ? null
            : localStorageOfPaymentData.advChqBankName.split("#");

        //pdc
        pdcAmt = localStorageOfPaymentData.advPdcChqAmt == null ? null
            : localStorageOfPaymentData.advPdcChqAmt.split("#");
        pdcNumber = localStorageOfPaymentData.advPdcChqNumber == null ? null
            : localStorageOfPaymentData.advPdcChqNumber.split("#");
        pdcDueDate = localStorageOfPaymentData.advPdcChqDueDate == null ? null
            : localStorageOfPaymentData.advPdcChqDueDate.split("#");
        pdcBankName = localStorageOfPaymentData.advPdcChqBankName == null ? null
            : localStorageOfPaymentData.advPdcChqBankName.split("#");

        this.advancePaymentChequePdcDataList = [];

        if (cashAmt != null && cashAmt != undefined) {
            this.newAttributeTosetBank = {};
            this.newAttributeTosetBank.paymentType = "Cash";
            this.newAttributeTosetBank.chequeNumber = null;
            this.newAttributeTosetBank.amount = cashAmt;
            this.newAttributeTosetBank.bankName = null;
            this.newAttributeTosetBank.pdcDueDate = null;
            this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);
        }

        if (chequeAmt != null && chequeAmt != undefined &&
            chequeAmt.length > 0) {
            for (let i = 0; i < chequeAmt.length; i++) {
                this.newAttributeTosetBank = {};
                this.newAttributeTosetBank.paymentType = "Cheque";
                this.newAttributeTosetBank.chequeNumber = chequeNumber[i];
                this.newAttributeTosetBank.amount = chequeAmt[i];
                this.newAttributeTosetBank.bankName = cheqBankName[i];
                this.newAttributeTosetBank.pdcDueDate = null;
                this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);

                chequeAmtSumm = +chequeAmtSumm + +chequeAmt[i];
            }
        }

        if (pdcAmt != null && pdcAmt != undefined &&
            pdcAmt.length > 0) {
            for (let i = 0; i < pdcAmt.length; i++) {
                this.newAttributeTosetBank = {};
                this.newAttributeTosetBank.paymentType = "PDC";
                this.newAttributeTosetBank.chequeNumber = pdcNumber[i];
                this.newAttributeTosetBank.amount = pdcAmt[i];
                this.newAttributeTosetBank.bankName = pdcBankName[i];
                this.newAttributeTosetBank.pdcDueDate = pdcDueDate[i];
                this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);

                pdcAmtSmm = +pdcAmtSmm + +pdcAmt[i];
            }
        }
        $("#" + this.pageId + "advancePaymentCashChequePdcTableId").DataTable().destroy();
        this.dtTriggerAdvancePaymentCashChequePdc.next();
        var balanceAmt = 0.0;
        setTimeout(() => {
            $("#" + this.pageId + "destinationId").val(localStorageOfPaymentData.hireslipnumber);
            $("#" + this.pageId + "amount").val(localStorageOfPaymentData.advance == null ? 0 : localStorageOfPaymentData.advance);
            if (localStorageOfPaymentData.paymentDate != null && localStorageOfPaymentData.paymentDate != undefined && localStorageOfPaymentData.paymentDate != '') {
                $("#" + this.pageId + "payingDates").val(moment(localStorageOfPaymentData.paymentDate).format("YYYY-MM-DD"));
            } else {
                $("#" + this.pageId + "payingDates").val(moment(new Date()).format("YYYY-MM-DD"));
            }
            $("#" + this.pageId + "totalAmountToBePaid").val(localStorageOfPaymentData.advance == null ? 0 : localStorageOfPaymentData.advance);
            $("#" + this.pageId + "tdsDeducted").val(localStorageOfPaymentData.tdsAmount == null ? 0 : localStorageOfPaymentData.tdsAmount);
            $("#" + this.pageId + "advancePayable").val((localStorageOfPaymentData.advance == null ? 0 : localStorageOfPaymentData.advance) + -(localStorageOfPaymentData.tdsAmount == null ? 0 : localStorageOfPaymentData.tdsAmount));
            $("#" + this.pageId + "summaryCashAmt").val(localStorageOfPaymentData.cashAmt == null ? 0 : localStorageOfPaymentData.cashAmt);
            $("#" + this.pageId + "summaryChequeAmt").val(chequeAmtSumm == null ? 0 : chequeAmtSumm);
            $("#" + this.pageId + "summaryPDCAmt").val(pdcAmtSmm == null ? 0 : pdcAmtSmm);
            console.log(localStorageOfPaymentData.advance, localStorageOfPaymentData.advCashAmt, chequeAmtSumm, pdcAmtSmm);
            balanceAmt = (localStorageOfPaymentData.advance == null ? 0 : localStorageOfPaymentData.advance) + -(localStorageOfPaymentData.cashAmt == null ? 0 : localStorageOfPaymentData.cashAmt) + -(chequeAmtSumm == null ? 0 : chequeAmtSumm) + -(pdcAmtSmm == null ? 0 : pdcAmtSmm) + -(localStorageOfPaymentData.tdsAmount == null ? 0 : localStorageOfPaymentData.tdsAmount);
            $("#" + this.pageId + "balanceToBePaid").val(balanceAmt == null ? 0 : balanceAmt);

            $("#" + this.pageId + "paymentPanNo").prop('readonly', this.isAdvancePaid);
            $("#" + this.pageId + "validatePanAadharBtn").prop('disabled', this.isAdvancePaid);
            $("#" + this.pageId + "paymentPanNo").val(localStorageOfPaymentData.panNumberAdvPayment);
            // if (localStorageOfPaymentData.isPanAadharLink == false) {
            //     this.tdsLabel = "20% TDS Deducted";
            // } else {
            //     this.tdsLabel = "TDS Deducted";
            // }
            this.changeDetectorRef.detectChanges();
        }, 1000);

        this.hirePrintDto = new HireSlipDto();
        this.hirePrintDto.fromStation = localStorageOfPaymentData.fromStation;
        this.hirePrintDto.toStation = localStorageOfPaymentData.toStation;
        this.hirePrintDto.vehicleNumber = localStorageOfPaymentData.vehicleNumber;
        this.hirePrintDto.hireslipnumber = localStorageOfPaymentData.hireslipnumber;
        this.hirePrintDto.hireslipdate = localStorageOfPaymentData.hireslipdate;
        this.hirePrintDto.suppliername = localStorageOfPaymentData.supplierName;
        this.hirePrintDto.tdsAmount = localStorageOfPaymentData.tdsAmount;
        this.hirePrintDto.tdsPercent = localStorageOfPaymentData.tdsPercent;
        this.hirePrintDto.totalhire = localStorageOfPaymentData.totalhire;
        this.hirePrintDto.advance = localStorageOfPaymentData.advance;
    }

    balanceAmt() {
        var balanceAmt = 0.0;
        this.validateBalAmtToBePaid = 0;
        this.validateBalAmtCash = 0;
        this.validateBalAmtCheque = 0;
        this.validateBalAmtPdc = 0;
        this.selectedAdvancePayable = 0;

        this.validateBalAmtCash = $("#" + this.pageId + "summaryCashAmt").val();
        this.validateBalAmtCheque = $("#" + this.pageId + "summaryChequeAmt").val();
        this.validateBalAmtPdc = $("#" + this.pageId + "summaryPDCAmt").val();
        this.selectedAdvancePayable = $("#" + this.pageId + "advancePayable").val();

        balanceAmt = +(this.selectedAdvancePayable == null ? 0 : this.selectedAdvancePayable) + -(this.validateBalAmtCash == null ? 0 : this.validateBalAmtCash) + -(this.validateBalAmtCheque == null ? 0 : this.validateBalAmtCheque) + -(this.validateBalAmtPdc == null ? 0 : this.validateBalAmtPdc);

        $("#" + this.pageId + "balanceToBePaid").val(balanceAmt == null ? 0 : balanceAmt);
    }

    calculateCashAmtSum() {
        var cashSum = 0.0;
        for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
            if (this.advancePaymentChequePdcDataList[i].paymentType == "Cash") {
                cashSum = cashSum + +this.advancePaymentChequePdcDataList[i].amount;
            }
        }
        return cashSum;
    }

    calculateChequeAmtSum() {
        var chequeSum = 0.0;
        for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
            if (this.advancePaymentChequePdcDataList[i].paymentType == "Cheque") {
                chequeSum = chequeSum + +this.advancePaymentChequePdcDataList[i].amount;
            }
        }
        return chequeSum;
    }

    calculatePdcAmtSum() {
        var pdcSum = 0.0;
        for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
            if (this.advancePaymentChequePdcDataList[i].paymentType == "PDC") {
                pdcSum = pdcSum + +this.advancePaymentChequePdcDataList[i].amount;
            }
        }
        return pdcSum;
    }

    onKeyPressListnerForAmount(event) {
        if (event.charCode == 13) {
            this.addBankDetailsInTable();
        }
    }

    addBankDetailsInTable() {
        if (($("#" + this.pageId + "payemntModeType").val() == "Cash") &&
            ($("#" + this.pageId + "cashChequePdcAmount").val() == null || $("#" + this.pageId + "cashChequePdcAmount").val() == undefined ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == "")) {
            swal("Mandatory Field", "Please enter amount", "warning");
            return false;
        } else if (($("#" + this.pageId + "payemntModeType").val() == "Cheque") &&
            ($("#" + this.pageId + "chequePdcNo").val() == null || $("#" + this.pageId + "chequePdcNo").val() == undefined ||
                $("#" + this.pageId + "chequePdcNo").val() == "" || $("#" + this.pageId + "bankNameId").val() == null ||
                $("#" + this.pageId + "bankNameId").val() == undefined || $("#" + this.pageId + "bankNameId").val() == "" ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == null || $("#" + this.pageId + "cashChequePdcAmount").val() == undefined ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == "")) {
            swal("Mandatory Field", "Please enter the  mandatory field", "warning");
            return false;
        } else if (($("#" + this.pageId + "payemntModeType").val() == "PDC") &&
            ($("#" + this.pageId + "chequePdcNo").val() == null || $("#" + this.pageId + "chequePdcNo").val() == undefined ||
                $("#" + this.pageId + "chequePdcNo").val() == "" || $("#" + this.pageId + "bankNameId").val() == null ||
                $("#" + this.pageId + "bankNameId").val() == undefined || $("#" + this.pageId + "bankNameId").val() == "" ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == null || $("#" + this.pageId + "cashChequePdcAmount").val() == undefined ||
                $("#" + this.pageId + "cashChequePdcAmount").val() == "" || $("#" + this.pageId + "chequeDueDates").val() == null ||
                $("#" + this.pageId + "chequeDueDates").val() == undefined || $("#" + this.pageId + "chequeDueDates").val() == "")) {
            swal("Mandatory Field", "Please enter the  mandatory field", "warning");
            return false;
        } else {
            this.addIntoTableOfBankDetails();
        }
    }

    addIntoTableOfBankDetails() {
        this.newAttributeTosetBank = {};
        this.newAttributeTosetBank.paymentType = $("#" + this.pageId + "payemntModeType").val();
        this.newAttributeTosetBank.chequeNumber = $("#" + this.pageId + "chequePdcNo").val();
        this.newAttributeTosetBank.amount = $("#" + this.pageId + "cashChequePdcAmount").val();
        this.newAttributeTosetBank.bankName = $("#" + this.pageId + "bankNameId").val();
        this.newAttributeTosetBank.pdcDueDate = $("#" + this.pageId + "chequeDueDates").val();
        //this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);
        if (this.editBankTableDetailsId != null &&
            this.editBankTableDetailsId != undefined) {
            this.advancePaymentChequePdcDataList.splice(this.editBankTableDetailsId, 1);
        }
        if (this.advancePaymentChequePdcDataList == null ||
            this.advancePaymentChequePdcDataList == undefined ||
            this.advancePaymentChequePdcDataList.length == 0) {
            this.advancePaymentChequePdcDataList = [];
        }
        if (this.advancePaymentChequePdcDataList.length == 0) {
            this.advancePaymentChequePdcDataList = [];
            $("#" + this.pageId + "advancePaymentCashChequePdcTableId").DataTable().destroy();
            this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);
            this.dtTriggerAdvancePaymentCashChequePdc.next();
            this.clearFieldBank();
            console.log('checking for length zero');
            $("#" + this.pageId + "summaryCashAmt").val(this.calculateCashAmtSum());
            $("#" + this.pageId + "summaryChequeAmt").val(this.calculateChequeAmtSum());
            $("#" + this.pageId + "summaryPDCAmt").val(this.calculatePdcAmtSum());
            this.balanceAmt();
        } else {
            if ($("#" + this.pageId + "payemntModeType").val() == "Cash") {
                const result = this.advancePaymentChequePdcDataList.filter(bkgAgentStmtCashChequePdcData =>
                    bkgAgentStmtCashChequePdcData.paymentType === this.newAttributeTosetBank.paymentType);
                if (result.length > 0) {
                    swal("Duplicate Entry", "Cash Amount Already Available ,Please Edit the Cash Amount and then Add", "warning");
                } else {
                    $("#" + this.pageId + "advancePaymentCashChequePdcTableId").DataTable().destroy();
                    this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);
                    this.dtTriggerAdvancePaymentCashChequePdc.next();
                    this.clearFieldBank();
                    $("#" + this.pageId + "summaryCashAmt").val(this.calculateCashAmtSum());
                    $("#" + this.pageId + "summaryChequeAmt").val(this.calculateChequeAmtSum());
                    $("#" + this.pageId + "summaryPDCAmt").val(this.calculatePdcAmtSum());
                    this.balanceAmt();
                }
            } else {
                const result = this.advancePaymentChequePdcDataList.filter(bkgAgentStmtCashChequePdcData =>
                    bkgAgentStmtCashChequePdcData.paymentType === this.newAttributeTosetBank.paymentType &&
                    bkgAgentStmtCashChequePdcData.chequeNumber === this.newAttributeTosetBank.chequeNumber);
                if (result.length > 0) {
                    swal("Duplicate Entry", "Duplicate Value Entered..Please recheck", "warning");
                } else {
                    console.log('checking');
                    console.log(this.calculateChequeAmtSum());
                    $("#" + this.pageId + "advancePaymentCashChequePdcTableId").DataTable().destroy();
                    this.advancePaymentChequePdcDataList.push(this.newAttributeTosetBank);
                    this.dtTriggerAdvancePaymentCashChequePdc.next();
                    this.clearFieldBank();
                    $("#" + this.pageId + "summaryCashAmt").val(this.calculateCashAmtSum());
                    $("#" + this.pageId + "summaryChequeAmt").val(this.calculateChequeAmtSum());
                    $("#" + this.pageId + "summaryPDCAmt").val(this.calculatePdcAmtSum());
                    this.balanceAmt();
                }
            }
        }
    }

    rowSelectedBankDataOnEdit(bkgAgentStmtCashChequePdcData, index) {
        this.editBankTableDetailsId = index;
        if (bkgAgentStmtCashChequePdcData.paymentType == "Cash") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = false;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = false;
            setTimeout(() => {
                $("#" + this.pageId + "payemntModeType").val("Cash");
                $("#" + this.pageId + "cashChequePdcAmount").val(bkgAgentStmtCashChequePdcData.amount);
            }, 1000);
        } else if (bkgAgentStmtCashChequePdcData.paymentType == "Cheque") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = false;
            this.viewChequePdcBankNameField = true;
            setTimeout(() => {
                $("#" + this.pageId + "payemntModeType").val("Cheque");
                $("#" + this.pageId + "chequePdcNo").val(bkgAgentStmtCashChequePdcData.chequeNumber);
                $("#" + this.pageId + "bankNameId").val(bkgAgentStmtCashChequePdcData.bankName);
                $("#" + this.pageId + "cashChequePdcAmount").val(bkgAgentStmtCashChequePdcData.amount);
            }, 1000);
        } else if (bkgAgentStmtCashChequePdcData.paymentType == "PDC") {
            this.viewCashChequePdcAmtField = true;
            this.viewChequePdcNoField = true;
            this.viewChequeDueDateField = true;
            this.viewChequePdcBankNameField = true;
            setTimeout(() => {
                $("#" + this.pageId + "payemntModeType").val("PDC");
                $("#" + this.pageId + "chequePdcNo").val(bkgAgentStmtCashChequePdcData.chequeNumber);
                $("#" + this.pageId + "bankNameId").val(bkgAgentStmtCashChequePdcData.bankName);
                $("#" + this.pageId + "chequeDueDates").val(bkgAgentStmtCashChequePdcData.pdcDueDate);
                $("#" + this.pageId + "cashChequePdcAmount").val(bkgAgentStmtCashChequePdcData.amount);
            }, 1000);
        }
    }

    deleteSelectedBankDetails(index) {
        $("#" + this.pageId + "advancePaymentCashChequePdcTableId").DataTable().destroy();
        this.advancePaymentChequePdcDataList.splice(index, 1);
        this.dtTriggerAdvancePaymentCashChequePdc.next();
        $("#" + this.pageId + "summaryCashAmt").val(this.calculateCashAmtSum());
        $("#" + this.pageId + "summaryChequeAmt").val(this.calculateChequeAmtSum());
        $("#" + this.pageId + "summaryPDCAmt").val(this.calculatePdcAmtSum());
        this.balanceAmt();
    }


    clearFieldBank() {
        this.editBankTableDetailsId = null;
        this.newAttributeTosetBank = {};
        $("#" + this.pageId + "chequePdcNo").val('');
        $("#" + this.pageId + "chequeDueDates").val('');
        $("#" + this.pageId + "bankNameId").val('');
        $("#" + this.pageId + "cashChequePdcAmount").val('');

        this.modelChequeDueDates = null;
        this.modelBankName = null;
    }

    clearBankDetailsInTable() {
        this.clearFieldBank();
        $("#" + this.pageId + "payemntModeType").val('Cheque');
        this.viewCashChequePdcAmtField = true;
        this.viewChequePdcNoField = true;
        this.viewChequeDueDateField = false;
        this.viewChequePdcBankNameField = true;
    }

    validateConfirmPaymentBtn() {
        if ($("#" + this.pageId + "payingDates").val() == null || $("#" + this.pageId + "payingDates").val() == undefined ||
            $("#" + this.pageId + "payingDates").val() == "") {
            swal("Mandatory Field", "Please Select the Payment Date", "warning");
            return false;
        } else {
            if ($("#" + this.pageId + "tdsDeducted").val() != null && $("#" + this.pageId + "tdsDeducted").val() != undefined &&
                $("#" + this.pageId + "tdsDeducted").val() != "") {
                swal({
                    title: "TDS Voucher Print",
                    text: "Sure U Want To Print the TDS Voucher",
                    icon: "info",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((sureYesBtn) => {
                    if (sureYesBtn) {
                        localStorage.clear();
                        console.log('inside confirm yes' + this.hirePrintDto);
                        localStorage.setItem('tdsVoucherPrint', JSON.stringify(this.localStorageOfPaymentData));
                        this.viewTdsVoucherWithPayment = true;
                        window.addEventListener('afterprint', (onclick) => {
                            console.log('dddsadfads');
                            if (this.viewTdsVoucherWithPayment) {
                                this.viewTdsVoucherWithPayment = false;
                                this.confirmPayment();
                            }
                            this.changeDetectorRef.detectChanges();
                        });
                    } else {
                        this.confirmPayment();
                    }
                    this.changeDetectorRef.detectChanges();
                });

            } else {
                this.confirmPayment();
            }
        }
    }

    getUserValues() {
        this.hireslipDtoForUserVal = new HireSlipDto();
        this.validateBalAmtForSave = 0.0;
        this.validateSummCashAmtForSave = 0.0;
        this.validateSummChequeAmtForSave = 0.0;
        this.validateSummPdcAmtForSave = 0.0;
        this.validatePayingDateForSave = '';
        this.validateInitBalAmtForSave = 0.0;

        this.validateBalAmtForSave = $("#" + this.pageId + "balanceToBePaid").val();
        this.validateSummCashAmtForSave = $("#" + this.pageId + "summaryCashAmt").val();
        this.validateSummChequeAmtForSave = $("#" + this.pageId + "summaryChequeAmt").val();
        this.validateSummPdcAmtForSave = $("#" + this.pageId + "summaryPDCAmt").val();
        this.validatePayingDateForSave = $("#" + this.pageId + "payingDates").val();
        this.validateInitBalAmtForSave = $("#" + this.pageId + "amount").val();

        this.hireslipDtoForUserVal = this.setMultipleValue();
        this.hireslipDtoForUserVal.lastupdatedby = this.userDataDtoReturnSession.userId;
        this.hireslipDtoForUserVal.advHireAmtPaidDate = this.validatePayingDateForSave;
        this.hireslipDtoForUserVal.cashAmt = this.validateSummCashAmtForSave;
        this.hireslipDtoForUserVal.advTotalChqAmt = this.validateSummChequeAmtForSave;
        this.hireslipDtoForUserVal.advTotalPdcAmt = this.validateSummPdcAmtForSave;
        this.hireslipDtoForUserVal.totalhire = this.localStorageOfPaymentData.totalHire;
        this.hireslipDtoForUserVal.tdsAmount = this.localStorageOfPaymentData.tdsAmount;
        this.hireslipDtoForUserVal.tdsPercent = this.localStorageOfPaymentData.tdsPercent;
        this.hireslipDtoForUserVal.isPanAadharLink = this.isPanAadharLinked;
        this.hireslipDtoForUserVal.panNumberAdvPayment = this.validatePaymentPanNumber;

        var totAmtPaid = 0.0;
        totAmtPaid = +(this.hireslipDtoForUserVal.cashAmt = null ? 0.0 : this.hireslipDtoForUserVal.cashAmt) +
            +(this.hireslipDtoForUserVal.advTotalChqAmt == null ? 0.0 : this.hireslipDtoForUserVal.advTotalChqAmt) +
            +(this.hireslipDtoForUserVal.advTotalPdcAmt == null ? 0.0 : this.hireslipDtoForUserVal.advTotalPdcAmt) + +(this.hireslipDtoForUserVal.tdsAmount == null ? 0.0 : this.hireslipDtoForUserVal.tdsAmount);

        this.hireslipDtoForUserVal.advTotalPaid = totAmtPaid;
        this.hireslipDtoForUserVal.hireslipnumber = this.localStorageOfPaymentData.hireslipnumber;
        return this.hireslipDtoForUserVal;
    }

    setMultipleValue() {
        this.hireslipDtoForMulVal = new HireSlipDto();
        var cashAmtMultiple = null;
        var chqNumMultiple = null, chqAmtMultiple = null, cheqBankNameMultiple = null;
        var pdcNumMultiple = null, pdcAmtMultiple = null, pdcDueDateMultiple = null, pdcBankNameMultiple = null;
        var chequeNames = "NA", pdcChequeNames = "NA";

        for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
            if (this.advancePaymentChequePdcDataList[i].paymentType == "Cheque") {
                chqNumMultiple = chqNumMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].chequeNumber;
                chqAmtMultiple = chqAmtMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].amount;
                cheqBankNameMultiple = cheqBankNameMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].bankName;

                if (i == 0) {
                    chequeNames = this.advancePaymentChequePdcDataList[i].chequeNumber;
                } else {
                    chequeNames = "," + this.advancePaymentChequePdcDataList[i].chequeNumber;
                }
            }
        }

        for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
            if (this.advancePaymentChequePdcDataList[i].paymentType == "PDC") {
                pdcNumMultiple = pdcNumMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].chequeNumber;
                pdcAmtMultiple = pdcAmtMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].amount;
                pdcDueDateMultiple = pdcDueDateMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].pdcDueDate;
                pdcBankNameMultiple = pdcBankNameMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].bankName;
                if (i == 0) {
                    pdcChequeNames = this.advancePaymentChequePdcDataList[i].chequeNumber;
                } else {
                    pdcChequeNames = "," + this.advancePaymentChequePdcDataList[i].chequeNumber;
                }
            }
        }

        for (let i = 0; i < this.advancePaymentChequePdcDataList.length; i++) {
            if (this.advancePaymentChequePdcDataList[i].paymentType == "Cash") {
                cashAmtMultiple = cashAmtMultiple + "#"
                    + this.advancePaymentChequePdcDataList[i].amount;
            }
        }

        if (chqNumMultiple != null) {
            chqNumMultiple = chqNumMultiple.replace("null#", "");
        }
        if (chqAmtMultiple != null) {
            chqAmtMultiple = chqAmtMultiple.replace("null#", "");
        }
        if (pdcNumMultiple != null) {
            pdcNumMultiple = pdcNumMultiple.replace("null#", "");
        }
        if (pdcAmtMultiple != null) {
            pdcAmtMultiple = pdcAmtMultiple.replace("null#", "");
        }
        if (pdcDueDateMultiple != null) {
            pdcDueDateMultiple = pdcDueDateMultiple.replace("null#", "");
        }
        if (pdcBankNameMultiple != null) {
            pdcBankNameMultiple = pdcBankNameMultiple.replace("null#", "");
        }
        if (cheqBankNameMultiple != null) {
            cheqBankNameMultiple = cheqBankNameMultiple.replace("null#", "");
        }

        if (cashAmtMultiple != null) {
            cashAmtMultiple = cashAmtMultiple.replace("null#", "");
        }
        this.hireslipDtoForMulVal.advChqNumber = chqNumMultiple;
        this.hireslipDtoForMulVal.advChqAmt = chqAmtMultiple;
        this.hireslipDtoForMulVal.advChqBankName = cheqBankNameMultiple;
        this.hireslipDtoForMulVal.advPdcChqNumber = pdcNumMultiple;
        this.hireslipDtoForMulVal.advPdcChqAmt = pdcAmtMultiple;
        this.hireslipDtoForMulVal.advPdcChqDueDate = pdcDueDateMultiple;
        this.hireslipDtoForMulVal.advPdcChqBankName = pdcBankNameMultiple;

        this.hirePrintDto.chequeno = chequeNames;
        this.hirePrintDto.column1 = pdcChequeNames;
        this.hirePrintDto.advChqBankName = cheqBankNameMultiple;
        this.hirePrintDto.advPdcChqBankName = pdcBankNameMultiple;
        return this.hireslipDtoForMulVal;
    }

    clearOverAllField() {
        $("#" + this.pageId + "payemntModeType").val('Cheque');
        this.viewCashChequePdcAmtField = true;
        this.viewChequePdcNoField = true;
        this.viewChequeDueDateField = false;
        this.viewChequePdcBankNameField = true;
        this.clearFieldBank();
        this.tdsLabel = "TDS Deducted";
        this.isPanAadharLinked = false;
        this.isAdvancePaid = false;
        $("#" + this.pageId + "paymentPanNo").prop('readonly', false);
        $("#" + this.pageId + "validatePanAadharBtn").prop('disabled', false);

        this.modelChequeDueDates = null;
        this.modelPayingDates = null;

        this.localStorageOfPaymentData = null;

        this.newAttributeTosetBank = {};
        this.editBankTableDetailsId = null;

        this.validateBalAmtToBePaid = null;
        this.validateBalAmtCash = null;
        this.validateBalAmtCheque = null;
        this.validateBalAmtPdc = null;

        this.validateBalAmtForSave = null;
        this.validateSummCashAmtForSave = null;
        this.validateSummChequeAmtForSave = null;
        this.validateSummPdcAmtForSave = null;
        this.validatePayingDateForSave = null;
        this.validateInitBalAmtForSave = null;
        this.resultDuplicate = null;
        this.validatePaymentPanNumber = null;

        this.fortNightStmtDtoForGetUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoForReturnGetUserValue = new FortNightStmtDto();
        this.fortNightStmtDtoForMultipleValue = new FortNightStmtDto();

        $("#" + this.pageId + "totalAmountToBePaid").val('');
        $("#" + this.pageId + "balanceToBePaid").val('');
        $("#" + this.pageId + "summaryCashAmt").val('');
        $("#" + this.pageId + "summaryChequeAmt").val('');
        $("#" + this.pageId + "summaryPDCAmt").val('');
        $("#" + this.pageId + "payingDates").val('');
        $("#" + this.pageId + "destinationId").val('');
        $("#" + this.pageId + "tdsDeducted").val('');
        $("#" + this.pageId + "amount").val('');
        $("#" + this.pageId + "advancePayable").val('');
        $("#" + this.pageId + "paymentPanNo").val('');

        this.advancePaymentChequePdcDataList = [];
        $("#" + this.pageId + "advancePaymentCashChequePdcTableId").DataTable().destroy();
        this.dtTriggerAdvancePaymentCashChequePdc.next();
        this.Statement = '';
    }
    chqDueDateFocus(e) {
        if (e.keyCode == 13) {
            this.focusChqDueDateCalendar();
        }
    }
    focusChqDueDateCalendar() {
        window.setTimeout(function () {
            $("#" + this.pageId + "bankNameId").focus();
        }, 100);

    }

    confirmPayment() {
        console.log('ddaccccc');
        this.validateBalAmtForSave = 0.0;
        this.validateSummCashAmtForSave = 0.0;
        this.validateSummChequeAmtForSave = 0.0;
        this.validateSummPdcAmtForSave = 0.0;
        this.validateBalAmtForSave = $("#" + this.pageId + "amount").val();
        this.validateSummCashAmtForSave = $("#" + this.pageId + "summaryCashAmt").val();
        this.validateSummChequeAmtForSave = $("#" + this.pageId + "summaryChequeAmt").val();
        this.validateSummPdcAmtForSave = $("#" + this.pageId + "summaryPDCAmt").val();

        var overAllTotalAmt = (this.validateSummCashAmtForSave == null ? 0.0 : parseInt(this.validateSummCashAmtForSave)) + +(this.validateSummChequeAmtForSave == null ? 0.0 : parseInt(this.validateSummChequeAmtForSave)) + +(this.validateSummPdcAmtForSave == null ? 0.0 : parseInt(this.validateSummPdcAmtForSave));
        var balnceToBePaidAmt = (this.validateBalAmtForSave == null ? 0.0 : parseInt(this.validateBalAmtForSave)) + -(overAllTotalAmt == null ? 0.0 : overAllTotalAmt);

        this.hirePrintDto.advChequeAmt = this.validateSummChequeAmtForSave;
        this.hirePrintDto.advCashAmt = this.validateSummCashAmtForSave;
        console.log(balnceToBePaidAmt);
        console.log(this.validateSummCashAmtForSave, this.validateSummChequeAmtForSave, this.validateSummPdcAmtForSave, overAllTotalAmt, balnceToBePaidAmt);
        console.log(this.hirePrintDto);
        if ((overAllTotalAmt > 0.0) && !(balnceToBePaidAmt < 0.0)) {
            swal({
                title: "Payment Confirmation",
                text: "Sure U Want To Submit the Amount Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((payment) => {
                if (payment) {
                    this.hireslipDtoForSave = new HireSlipDto();
                    this.hireslipDtoForSave = this.getUserValues();
                    if (this.hireslipDtoForSave.advTotalPaid > this.localStorageOfPaymentData.totalHire) {
                        swal("Not Allowed", "Total Paid Should not be Greater than Paid Amount", "warning");
                        return false;
                    } else {
                        this.hirePrintDto.paid = this.hireslipDtoForSave.advTotalPaid;
                        this.hirePrintDto.cashAmt = this.validateSummCashAmtForSave;
                        this.hirePrintDto.advTotalChqAmt = this.validateSummChequeAmtForSave;
                        this.hirePrintDto.advTotalPdcAmt = this.validateSummPdcAmtForSave;
                        this.saveLryAdvance(this.validateSummCashAmtForSave, this.validateSummChequeAmtForSave, this.validateSummPdcAmtForSave);

                    }
                }
            });
        } else if (balnceToBePaidAmt < 0.0) {
            swal("Warning", "You are Not Allowed to do this Transaction,Because Entered Amount is Extra", "warning");
            return false;
        } else if (overAllTotalAmt == 0.0) {
            swal("Warning", "You are Not Allowed to do this Transaction,Because Entered Amount is Zero", "warning");
            return false;
        }
    }

    saveLryAdvance(cashTotAmt, chequeTotAmt, pdcTotAmt) {
        console.log(this.hireslipDtoForSave);
        this.showSpinnerForAction = true;
        this.hireslipService.lryHireAdvPayment(this.hireslipDtoForSave).subscribe(
            (response) => {

                console.log(response);
                this.showSpinnerForAction = false;
                this.validatePayingDateForSave = $("#" + this.pageId + "payingDates").val();
                if (response.status == 'Success') {
                    this.hirePrintDto.paymentDate = this.validatePayingDateForSave;
                    this.searchMethodDetails();
                    this.clearOverAllField();
                    if (this.hirePrintDto.cashAmt > 0) {
                        console.log('cash');
                        this.cashPrint(this.hirePrintDto);
                    } else if (this.hirePrintDto.advTotalChqAmt > 0) {
                        console.log('cheque');
                        this.chequePrint();
                    } else if (this.hirePrintDto.advTotalPdcAmt > 0) {
                        console.log('pdc');
                        this.pdcPrint();
                    }
                } else if (response.status == 'Not Found') {
                    swal("Error", "Hireslip number not Found", "info");
                } else if (response.status == 'Failed') {
                    swal("Error", "Server Problem Occurred While Saving The Details", "info");
                }
                this.changeDetectorRef.detectChanges();

            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Saving The Details", "info");
            },
            () => console.log('done');
    }
    validateTDSPaymentBtn() {
        localStorage.clear();
        console.log(this.hirePrintDto);
        localStorage.setItem('tdsVoucherPrint', JSON.stringify(this.localStorageOfPaymentData));
        this.viewTdsVoucher = true;
        window.addEventListener('afterprint', (onclick) => {
            console.log('chekc');
            this.viewTdsVoucher = false;
        });
    }
    confirmPanAadharLinkVerification() {
        swal({
            title: "Confirm Verification",
            text: "Sure you want to verify the Pan-Aadhar link?",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((remove) => {
            if (remove) {
                this.validatePanAadharLink();
            }
        });
    }

    validatePanAadharLink() {
        this.isPanAadharLinked = false;
        let selectedHireslip = $("#" + this.pageId + "destinationId").val();
        console.log(selectedHireslip);
        var regpan = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
        this.validatePaymentPanNumber = $("#" + this.pageId + "paymentPanNo").val();
        if (selectedHireslip == null || selectedHireslip == undefined || selectedHireslip == "") {
            swal("Not Allowed", "Please select the Hireslip for payment!", "warning");
        } else if (this.validatePaymentPanNumber == null || this.validatePaymentPanNumber == undefined || this.validatePaymentPanNumber == "") {
            swal("Mandatory Field", "Please Enter the PAN Number for Payment!", "warning");
        } else if (!regpan.test(this.validatePaymentPanNumber)) {
            swal("Invlaid PAN", "Entered PAN Number is invalid, please verify it!", "warning");
        } else {
            this.panAadharValidationService(selectedHireslip);
        }
    }

    panAadharValidationService(selectedHireslip) {
        this.requestDto = new RequestDto();
        this.requestDto.param1 = this.validatePaymentPanNumber;
        this.requestDto.param2 = selectedHireslip;
        this.requestDto.param4 = this.userDataDtoReturnSession.mainStation;
        this.requestDto.param5 = this.userDataDtoReturnSession.office;
        this.requestDto.userId = this.userDataDtoReturnSession.userId;
        this.requestDto.type = 'Advance';
        this.showSpinnerForAction = true;
        this.changeDetectorRef.detectChanges();
        this.ewaybillService.validatePanAadharLinkVerification(this.requestDto).subscribe(
            (response) => {
                if (response.token1 != null && response.token1 == 'Y') {
                    this.isPanAadharLinked = true;
                    swal("Valid", response.token2, "info");
                } else {
                    this.isPanAadharLinked = false;
                    this.panAadharNotLinkedDeduction();
                    swal("Invalid", "PAN-AADHAR link is not valid, TDS 20% will be deducted for this payment!", "warning");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Serve Error", "Failed to validate the request, please try again or contact Admin!", "error");
            }, () => console.log('done');
    }

    panAadharNotLinkedDeduction() {
        this.tdsLabel = "20% TDS Deducted";
        console.log(this.localStorageOfPaymentData.advance);
        let deductionAmt = +((this.localStorageOfPaymentData.advance * 20) / 100).toFixed(0);
        console.log(deductionAmt);
        $("#" + this.pageId + "tdsDeducted").val(deductionAmt);
        this.localStorageOfPaymentData.tdsAmount = deductionAmt;
        this.localStorageOfPaymentData.tdsPercent = '20%';
        $("#" + this.pageId + "advancePayable").val((this.localStorageOfPaymentData.advance == null ? 0 : this.localStorageOfPaymentData.advance) + -(this.localStorageOfPaymentData.tdsAmount == null ? 0 : this.localStorageOfPaymentData.tdsAmount));
        this.balanceAmt();
    }
}


