import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DragulaService, dragula } from "ng2-dragula";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { LrService } from 'src/app/dataService/lr-service';
import { InvoiceDto } from "src/app/dto/Invoice-dto";
export interface DialogData {
	animal: string;
	name: string;
}



@Component({
	selector: 'app-unloading-sheet-details',
	templateUrl: './unloading-sheet-details.component.html',
	styleUrls: ['./unloading-sheet-details.component.css']
})
export class UnloadingSheetDetailsComponent implements OnInit {
	//PartyLessRptTable:any;
	//for datepicker starts
	model: NgbDateStruct;
	model2;
	//for datepicker ends

	loadingIndicator = true;

	//for datePicker starts
	hoveredDate: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	closeResult: string;
	//for datepicker ends
	//for the select option with filter starts
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	cashMemoDto: CashMemoDto = new CashMemoDto();
	address: any;

	showSpinnerForAction = false;

	dtOptionsUnloadingTripDetails: any;
	dtTriggerUnloadingTripDetails: Subject<any> = new Subject();
	getInvoiceNumber: any;
	showForStatusNotOk = true;
	remarkValue: any;
	enteredLRNumber: any;
	selectedStatusType: any;
	enteredShortageArticle: any;
	articles: any;
	hireslipDtoForGetLrMthd: HireSlipDto = new HireSlipDto();
	enteredLrCode: any;
	lrDtoForGerLrMthd: LRDto = new LRDto();
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	lrdtsUnloadRptRet: any;
	hireslipDtoForLRdtsUnloadRptRet: HireSlipDto = new HireSlipDto();
	dataForPopupFromShipmentStatusOtherDetails: any;
	lrDtoForGetLrshortage: LRDto = new LRDto();
	getLrShortageForSingleLrRet: any;
	lrShortageForSingleLrRet: LRDto = new LRDto();
	mainLR: any;
	alreadyExiMaint = false;
	rowSelected = false;
	enteredRemarks: any;
	selectedMention: any;
	modelDup: any;
	actArticles = 0;
	private newAttribute: any = {};
	countOfLr = 0;
	unloadingTripDetailsDataList: Array<any> = [];
	dataForPopupFromShipmentStatusDataList: any;
	hireSlipDtoForPopSave: HireSlipDto = new HireSlipDto();
	hireslipDtoForSave: HireSlipDto = new HireSlipDto();
	hireslipDtoForSaveDetailsOfLrs: Array<any> = [];
	hireslipDtoForEdit: HireSlipDto = new HireSlipDto();
	hireslipdtoForOnload: HireSlipDto = new HireSlipDto();
	selectedRowIndex: any;
	invoicedtoForInvoiceOnLoadDetails: InvoiceDto = new InvoiceDto();
	invoicedtoForSearchOnLoadDetailsRet: InvoiceDto = new InvoiceDto();
	invoicedtoForSearchOnLoadDetailsReturn: any;
	viewCustomPrintV1 = false;
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	lrCountSummary = 0;
	/////
	todayDate: any;
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];


	////
	pageId = "usdc";

	constructor(private router: Router, private datePipe: DatePipe,
		private modalService: NgbModal, private hireslipService: HireslipService, private lrService: LrService, public dialogRef: MatDialogRef<UnloadingSheetDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				//this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.dataForPopupFromShipmentStatusOtherDetails = JSON.parse(localStorage.getItem('popupShipmentStatus'));
			this.dataForPopupFromShipmentStatusDataList = JSON.parse(localStorage.getItem('popupShipmentStatusDataList'));
			//console.log(this.address);
			if (this.dataForPopupFromShipmentStatusOtherDetails != null) {
				this.getInvoiceNumber = this.dataForPopupFromShipmentStatusOtherDetails.hireslipnumber;
				this.OnLoadServiceCall();
				console.log('am in unloading');
				this.OnLoadServiceCallToGetInvoiceDetails();
				//this.customPrintGcAmtCollected();
			}

			if (this.dataForPopupFromShipmentStatusDataList != null) {
				/*$("#"+this.pageId+"unloadingTripDetailsId").DataTable().destroy();
				this.unloadingTripDetailsDataList = [];
				this.unloadingTripDetailsDataList = this.dataForPopupFromShipmentStatusDataList;
				this.dtTriggerUnloadingTripDetails.next();
				console.log(this.dataForPopupFromShipmentStatusDataList);*/

			}
			this.dataForPopupFromShipmentStatusDataList = [];
			this.dataForPopupFromShipmentStatusOtherDetails = '';


		}

	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
				//this.dtTriggerMemoLessDescriptionDetails.next();
			});
		});
	}
	ngOnInit(): void {
		var companyAddressDetls = this.address;
		this.dtOptionsUnloadingTripDetails = {
			dom: 'Bfrtip',
			buttons: [
				/*{
					extend: 'print',
					text: '<i class="fas fa-print"> Print</i>',
					titleAttr: 'Print',
					customize : function(win) {
						$(win.document.body).css('font-size','10pt');
						$(win.document.body).find('th, td').
						css('font-family','Arial, Helvetica, sans-serif')
						.css('font-size','13px').css('text-align','center');
					},
					footer : true,
					exportOptions:{
						columns :[0,1,2,3,4,5]
						},
					messageTop : function() {
						var returnVal=null;
							returnVal = "<br><table style='width: 100%;'>" +
										"<tr>" +
										"<td style='width: 30%;'>" +
										"<table>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>From Date&nbsp;&nbsp;</strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='10%' style='text-align:left !important;'>" +
										"<span style='font-size:15px;'></span><br>" +
										"</td>" +
										"</tr>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='10%' style='text-align:left !important;'>" +
										"<span style='font-size:15px;'></span><br>" +
										"</td>" +
										"</tr>" +
										"</table>" +
										"</td>" +
										"<td rowspan='2'style='width:30%;word-wrap: break-word;text-align:left !important;'>" +
										"<strong style='font-size:19px;'><u>Unloading Report</u></strong><br>" +
										"</td>" +
										"<td align='left' style='width:40%;'>" +
										"<table>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'></strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='32%' style='text-align:left !important;'>" +
										"<span style='font-size:15px;'> </span><br>" +
										"</td>" +
										"</tr>" +
										"<tr>" +
										"<td width='8%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'></strong>" +
										"</td>" +
										"<td width='2%' style='text-align:left !important;'>" +
										"<strong style='font-size:15px;'>:</strong>" +
										"</td>" +
										"<td width='32%' style='text-align:left !important; word-wrap:break-word;'>" +
										"<span style='font-size:15px;'></span><br>" +
										"</td>" +
										"</tr>" +
										"</table>" +
										"</td>" +
										"</tr>" +
										"</table><br>";
						
						return returnVal;
						
					},
					title: function () {
						return "<table style='table-layout: fixed;width: 100%;'><tr>" +
							"<td align='left' style='width: 20%;'>" +
							"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
							"</td>" +
							"<td align='left' style='width: 30%;'>" +
							"</td>" +
							"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
							companyAddressDetls +
							"</td>" +
							"</tr></table>";
					}
				},
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer : true,
					title : function() {
					
					var returnSummaryExcel=null;
						
							returnSummaryExcel = "Unloading Report";
						
						return returnSummaryExcel;
					},
					exportOptions: {
						columns: ':visible'
					}
				}*/
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 170,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};

				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html();
				$(api.column(2).footer()).html();
				$(api.column(3).footer()).html();
				$(api.column(4).footer()).html();
				$(api.column(5).footer()).html();
				$(api.column(6).footer()).html();
			}

		}
	}


	ngOnDestroy(): void {
		this.dtTriggerUnloadingTripDetails.unsubscribe();

	}
	ngAfterViewInit(): void {
		//this.dtTriggerUnloadingTripDetails.next();

	}
	//for datepicker
	// the selectToday is the method for  selecting todays'z date
	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	//for datePicker

	//for the select option with filter starts

	transform(items: any[], searchTerm: string, labelKey?: string): any {
		if (!items || !searchTerm) {
			return items;
		}

		return items.filter(
			item =>
				item[labelKey || 'label']
					.toLowerCase()
					.includes(searchTerm.toLowerCase()) === true
		);
	}
	OnLoadServiceCallToGetInvoiceDetails() {

		this.invoicedtoForInvoiceOnLoadDetails = new InvoiceDto();
		this.invoicedtoForInvoiceOnLoadDetails.invoiceNumber = this.getInvoiceNumber;
		this.invoicedtoForInvoiceOnLoadDetails.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.invoicedtoForInvoiceOnLoadDetails);
		this.getInvoiceDetailsOnLoadService();

	}
	getInvoiceDetailsOnLoadService = () => {
		this.showSpinnerForAction = true;
		this.hireslipService.getInvoiceDetails(this.invoicedtoForInvoiceOnLoadDetails).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				this.invoicedtoForSearchOnLoadDetailsReturn = '';
				this.invoicedtoForSearchOnLoadDetailsReturn = response;
				this.invoicedtoForSearchOnLoadDetailsRet = new InvoiceDto();
				console.log('getting invoice details');
				console.log(this.invoicedtoForSearchOnLoadDetailsReturn);
				this.invoicedtoForSearchOnLoadDetailsRet = this.invoicedtoForSearchOnLoadDetailsReturn;
				if ((this.invoicedtoForSearchOnLoadDetailsRet.size == 0) || (this.invoicedtoForSearchOnLoadDetailsRet.status == 'Loading')) {
					swal({
						title: "LR Shipment",
						text: "Invoice Number" + this.getInvoiceNumber + " not found",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
					//updateButton.setEnabled(false);
					$("#" + this.pageId + "updateBtn").prop("disabled", true);
				} else {


				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Failed To Load the Invoice Details....Please Try Again", "info");
			}, () => console.log('done');
	};

	searchByStatus(searchBy: string) {
		if (searchBy != 'Select Status') {

			if (searchBy === 'OK') {
				this.showForStatusNotOk = false;
				$("#" + this.pageId + "lrCode").val('');
				$("#" + this.pageId + "unloadinglrNumber").val('');
				$("#" + this.pageId + "mention").val('No');
				$("#" + this.pageId + "shortageArticle").val('');
				$("#" + this.pageId + "remark").val('');
			} else {
				this.showForStatusNotOk = true;
				$("#" + this.pageId + "lrCode").val('');
				$("#" + this.pageId + "unloadinglrNumber").val('');
				$("#" + this.pageId + "mention").val('No');
				$("#" + this.pageId + "shortageArticle").val('');
				$("#" + this.pageId + "remark").val('');
				$("#" + this.pageId + "lrCode").focus();
			}

		}
	}
	OnLoadServiceCall() {
		this.hireslipdtoForOnload = new HireSlipDto();
		this.hireslipdtoForOnload.hireslipnumber = this.getInvoiceNumber;
		this.hireslipdtoForOnload.companyid = this.userDataDtoReturnSession.companyId;
		this.getHireSlipUnLoadRptDtlsInfo();
	}
	getHireSlipUnLoadRptDtlsInfo = () => {
		this.showSpinnerForAction = true;
		this.hireslipService.getHireSlipUnLoadRptDtlsServiceV2(this.hireslipdtoForOnload).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#" + this.pageId + "unloadingTripDetailsId").DataTable().destroy();
				this.unloadingTripDetailsDataList = [];
				console.log(response);
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Unloading  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});
				} else {
					this.unloadingTripDetailsDataList = response;
					//this.customPrintForUnloading();
					// this.router.navigate(['/report/cashmemo-report/customDynamicPrintV1']);
					// window.open('/report/cashmemo-report/customDynamicPrintV1', '_blank');
					console.log('getHireSlipUnLoadRptDtlsInfo');
				}
				this.dtTriggerUnloadingTripDetails.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  Invoice Unloading details", "info");
			}, () => console.log('done');


	};



	clearFields() {
		$("#" + this.pageId + "lrCode").val('');
		$("#" + this.pageId + "unloadinglrNumber").val('');
		$("#" + this.pageId + "mention").val('');
		$("#" + this.pageId + "shortageArticle").val('');
		$("#" + this.pageId + "remark").val('');
		this.selectedStatusType = '';
		$("#" + this.pageId + "unloadingStatus").val('Select Status');
		$("#" + this.pageId + "unloadingStatus").focus();

	}
	codeFocus(e) {
		if (e.keyCode == 13) {
			$("#" + this.pageId + "unloadinglrNumber").focus();
		}
	}
	lrNumberFocus(e) {
		this.enteredLRNumber = $("#" + this.pageId + "unloadinglrNumber").val();
		this.selectedStatusType = $("#" + this.pageId + "unloadingStatus").val();
		console.log(this.enteredLRNumber, this.selectedStatusType);
		if (e.keyCode == 13) {
			if ((this.selectedStatusType == '' || this.selectedStatusType == null || this.selectedStatusType == 'Select Status') || (this.enteredLRNumber == null || this.enteredLRNumber == '')) {
				swal({
					title: "Warning",
					text: "Status & LR No is Mandatory Field",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});

			} else {
				console.log('1');
				this.getLrMtd("Lr");
			}
		}

	}

	articleFocus(e) {
		if (e.keyCode == 13) {
			console.log('1');
			this.getLrMtd("Art");
		}
	}

	remarksFocus(e) {
		this.enteredLRNumber = $("#" + this.pageId + "unloadinglrNumber").val();
		this.selectedStatusType = $("#" + this.pageId + "unloadingStatus").val();
		this.enteredShortageArticle = $("#" + this.pageId + "shortageArticle").val();


		if (e.keyCode == 13) {
			if ((this.selectedStatusType == '' || this.selectedStatusType == null || this.selectedStatusType == 'Select Status') || (this.enteredLRNumber == null || this.enteredLRNumber == '') || (this.enteredShortageArticle == null || this.enteredShortageArticle == '')) {
				swal({
					title: "Warning",
					text: "Status & LR No is Mandatory Field",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});

			} else if ((this.selectedStatusType == '' || this.selectedStatusType == null || this.selectedStatusType == '') || (this.enteredLRNumber == null || this.enteredLRNumber == '') || (this.enteredShortageArticle == null || this.enteredShortageArticle == '')) {
				swal({
					title: "Warning",
					text: "Select Status Field ",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});

			} else {
				console.log('1');
				this.getLrMtd("Remark");
			}
		}
	}

	getLrMtd(mode) {
		this.enteredLRNumber = $("#" + this.pageId + "unloadinglrNumber").val();
		this.enteredLrCode = $("#" + this.pageId + "lrCode").val();
		this.selectedStatusType = $("#" + this.pageId + "unloadingStatus").val();
		if (this.enteredLRNumber != null && this.enteredLRNumber != '') {
			this.enteredLRNumber = this.enteredLRNumber.trim().split(' ').filter(item => item.length > 0).join(' ');
			this.articles = 0;
			this.hireslipDtoForGetLrMthd = new HireSlipDto();
			this.hireslipDtoForGetLrMthd.hireslipnumber = this.getInvoiceNumber;

			if (this.enteredLrCode != null && this.enteredLrCode != '') {
				this.hireslipDtoForGetLrMthd.lrNumber = this.enteredLrCode + "" + this.enteredLRNumber;
			} else {
				this.hireslipDtoForGetLrMthd.lrNumber = this.enteredLRNumber;
			}
			this.hireslipDtoForGetLrMthd.lrNumber = this.hireslipDtoForGetLrMthd.lrNumber.trim().split(' ').filter(item => item.length > 0).join(' ');
			this.hireslipDtoForGetLrMthd.status = this.selectedStatusType;
			this.hireslipDtoForGetLrMthd.companyid = this.userDataDtoReturnSession.companyId;
			//For Getting the getLrArticleDetails
			this.lrDtoForGerLrMthd = new LRDto();
			this.lrDtoForGerLrMthd.lrNumber = this.hireslipDtoForGetLrMthd.lrNumber;
			this.getLrArticleDetails(this.lrDtoForGerLrMthd);
			console.log(this.hireslipDtoForGetLrMthd);
			if (this.hireslipDtoForGetLrMthd.status != 'Extra') {
				this.hireslipService.getLRdtsUnloadRpt(this.hireslipDtoForGetLrMthd).subscribe(
					(response) => {
						this.showSpinnerForAction = false;
						this.lrdtsUnloadRptRet = response;
						console.log(this.lrdtsUnloadRptRet);
						this.hireslipDtoForLRdtsUnloadRptRet = new HireSlipDto();
						this.hireslipDtoForLRdtsUnloadRptRet = this.lrdtsUnloadRptRet;
						if (this.hireslipDtoForLRdtsUnloadRptRet.status != null && this.hireslipDtoForLRdtsUnloadRptRet.status == 'Allow') {
							this.articles = this.hireslipDtoForLRdtsUnloadRptRet.totalArticles;
							if ((mode == 'Art') || (mode == 'Remark')) {
								this.getArtMtd(mode);
							} else {
								this.getLRShortageDetails(this.hireslipDtoForGetLrMthd);
							}
						} else {
							swal({
								title: "Warning",
								text: "LR Number : " + this.enteredLRNumber + " is Not Found In This Invoice",
								icon: "warning",
								closeOnClickOutside: false,
								closeOnEsc: false,
							}).then(() => {
								$("#" + this.pageId + "lrCode").val('');
								$("#" + this.pageId + "unloadinglrNumber").val('');
							});
						}

						this.changeDetectorRef.detectChanges();
					}), (error) => {
						this.showSpinnerForAction = false;
						swal("Error", "Server Problem Occurred While getting HireSlip UnLoad Rpt Dtls Info", "info");
					}, () => console.log('done');

			} else {
				/*if (mode == 'Lr') {
					$("#" + this.pageId + "shortageArticle").focus();
				} else if (mode == 'Art') {
					$("#" + this.pageId + "remark").focus();
				} else if (mode == 'Remark') {
					this.getValuesMtd();

				}*/

				this.hireslipService.getLRdtsUnloadRptV2(this.hireslipDtoForGetLrMthd).subscribe(
					(response) => {
						this.showSpinnerForAction = false;
						this.lrdtsUnloadRptRet = response;
						console.log(this.lrdtsUnloadRptRet);
						this.hireslipDtoForLRdtsUnloadRptRet = new HireSlipDto();
						this.hireslipDtoForLRdtsUnloadRptRet = this.lrdtsUnloadRptRet;
						if (this.hireslipDtoForLRdtsUnloadRptRet.status != null && this.hireslipDtoForLRdtsUnloadRptRet.status == 'Allow') {
							this.articles = this.hireslipDtoForLRdtsUnloadRptRet.totalArticles;
							if ((mode == 'Art') || (mode == 'Remark')) {
								this.getArtMtd(mode);
							} else {
								this.getLRShortageDetails(this.hireslipDtoForGetLrMthd);
							}
						} else {
							swal({
								title: "Warning",
								text: "LR Number : " + this.enteredLRNumber + " Not Found",
								icon: "warning",
								closeOnClickOutside: false,
								closeOnEsc: false,
							}).then(() => {
								$("#" + this.pageId + "lrCode").val('');
								$("#" + this.pageId + "unloadinglrNumber").val('');
							});
						}

						this.changeDetectorRef.detectChanges();
					}), (error) => {
						this.showSpinnerForAction = false;
						swal("Error", "Server Problem Occurred While getting HireSlip UnLoad Rpt Dtls Info", "info");
					}, () => console.log('done');
			}

		}

	}


	//getLrArticleDetails
	getLrArticleDetails = (lrDtoForGerLrMthd) => {
		this.showSpinnerForAction = true;
		this.lrService.getLrArticlesDetails(lrDtoForGerLrMthd).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log(response);
				let getLrArticleDetailsRet = response
				if (getLrArticleDetailsRet.lrNumber != null) {
					this.actArticles = getLrArticleDetailsRet.totalArticles;
					console.log(this.articles);
				} else {

				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  Lr Articles  details", "info");
			}, () => console.log('done');
	};
	//getArtMtd
	getArtMtd(mode) {
		this.enteredShortageArticle = $("#" + this.pageId + "shortageArticle").val();
		if (this.enteredShortageArticle != null) {
			if (this.enteredShortageArticle > this.articles) {
				this.enteredShortageArticle = '';
				$("#" + this.pageId + "shortageArticle").val('');
				swal({
					title: "Warning",
					text: "Entered Article is Exceeds the Total Articles ",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
			} else {
				$("#" + this.pageId + "remark").focus();
				if (mode == 'Remark') {
					this.getValuesMtd();
				}

			}
		}
	}
	//getLRShortageDetails
	getLRShortageDetails = (hireslipDtoForGetLrMthd) => {
		this.lrDtoForGetLrshortage = new LRDto();
		this.lrDtoForGetLrshortage.invoiceNumber = hireslipDtoForGetLrMthd.hireslipnumber;
		this.lrDtoForGetLrshortage.lrNumber = hireslipDtoForGetLrMthd.lrNumber;
		this.lrDtoForGetLrshortage.status = hireslipDtoForGetLrMthd.status;
		this.showSpinnerForAction = true;
		this.lrService.getLrShortageForSingleLr(this.lrDtoForGetLrshortage).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				console.log(response);
				this.getLrShortageForSingleLrRet = response;
				this.lrShortageForSingleLrRet = new LRDto();
				this.lrShortageForSingleLrRet = this.getLrShortageForSingleLrRet;
				// if (this.lrShortageForSingleLrRet.size == 1) {
				if (this.lrShortageForSingleLrRet.size > 0) {
					console.log('1');
					this.gridReconfig(this.lrShortageForSingleLrRet);
				} else {
					//added On 22/09/21 to avoid manual selection
					$("#" + this.pageId + "mention").val('NO');

					$("#" + this.pageId + "shortageArticle").focus();
				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  Lr Shortage  details", "info");
			}, () => console.log('done');
	};

	getValuesMtd() {
		console.log(this.rowSelected);
		this.mainLR = null;
		this.alreadyExiMaint = false;
		this.enteredLRNumber = $("#" + this.pageId + "unloadinglrNumber").val();
		this.enteredLrCode = $("#" + this.pageId + "lrCode").val();

		if (this.enteredLRNumber != null && this.enteredLRNumber != '') {
			this.enteredLRNumber = this.enteredLRNumber.trim().split(' ').filter(item => item.length > 0).join(' ');
			if (this.enteredLrCode != null && this.enteredLrCode != '') {
				this.mainLR = this.enteredLrCode + "" + this.enteredLRNumber;
			} else {
				this.mainLR = this.enteredLRNumber;
			}
			this.mainLR = this.mainLR.trim().split(' ').filter(item => item.length > 0).join(' ');
			console.log(this.mainLR);
		}

		if (this.rowSelected == true) {
			this.enterKeyGridReconfigure(this.modelDup);
			this.rowSelected = false;
		} else {
			this.selectedStatusType = $("#" + this.pageId + "unloadingStatus").val();
			for (let i = 0; i < this.unloadingTripDetailsDataList.length; i++) {
				let lrNoInGrid = this.unloadingTripDetailsDataList[i].lrNumber;
				let lrStsInGrid = this.unloadingTripDetailsDataList[i].status;
				if (this.mainLR == lrNoInGrid) {
					this.alreadyExiMaint = false;
					if (this.selectedStatusType == lrStsInGrid) {
						this.alreadyExiMaint = true;
						break;
					} else if ((this.selectedStatusType == "Short")
						&& (lrStsInGrid == "Extra")) {
						this.alreadyExiMaint = true;
						break;
					} else if ((this.selectedStatusType == "Extra")
						&& (lrStsInGrid == "Short")) {
						this.alreadyExiMaint = true;
						break;
					}
				} else {
					this.alreadyExiMaint = false;
				}

			}
			if (this.alreadyExiMaint == true) {

				swal({
					title: "Warning",
					text: "Duplicate Entry " + this.mainLR + " Already Exist",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				$("#" + this.pageId + "unloadingStatus").focus();

			} else {

				this.enterKeyGridReconfigure(this.modelDup);
				this.rowSelected = false;
			}

		}

		$("#" + this.pageId + "lrCode").val('');
		$("#" + this.pageId + "unloadinglrNumber").val('');
		$("#" + this.pageId + "mention").val('');
		$("#" + this.pageId + "shortageArticle").val('');
		$("#" + this.pageId + "remark").val('');
		this.selectedStatusType = '';
		$("#" + this.pageId + "unloadingStatus").val('Select Status');
		$("#" + this.pageId + "unloadingStatus").focus();
	}

	enterKeyGridReconfigure(model) {
		console.log('inside enterKeyGridReconfigure');
		let remarks = "NA";
		this.enteredRemarks = '';
		this.enteredRemarks = $("#" + this.pageId + "remark").val();

		this.selectedMention = $("#" + this.pageId + "mention").val();
		this.enteredShortageArticle = $("#" + this.pageId + "shortageArticle").val();
		this.selectedStatusType = $("#" + this.pageId + "unloadingStatus").val();

		if (this.enteredRemarks != null && this.enteredRemarks != '' && this.enteredRemarks != undefined) {
			this.enteredRemarks = this.enteredRemarks.trim().split(' ').filter(item => item.length > 0).join(' ');
			console.log(this.enteredRemarks);
			remarks = this.enteredRemarks;
			this.mainLR = this.mainLR;
			if (remarks == '' || remarks == null || remarks == undefined) {
				remarks = "NA";
			}
		}
		let art = this.enteredShortageArticle;
		//art = art.replace(".0", "");
		let mode = null;
		if (this.selectedMention == 'YES') {
			mode = "YES";
		} else if (this.selectedMention == 'NO') {
			mode = "NO";
		} else {
			mode = "NA";
		}
		console.log(this.hireslipDtoForEdit.lrNumber);
		//if (model == null) {
		if (this.hireslipDtoForEdit.lrNumber == null || this.hireslipDtoForEdit.lrNumber == '') {
			console.log('inside enterKeyGridReconfigure if model null');
			//addInTable;
			this.addInTable(this.actArticles, this.mainLR, art, remarks, this.selectedStatusType, mode);
			/*storeUnloadRpt.add(new Maintenance(actArticles, mainLR, art,
					remarks,
					lbxStatus.getItemText(lbxStatus.getSelectedIndex()), mode));
			gridUnloadRpt.reconfigure(storeUnloadRpt, cmUnloadRpt);
			storeUnloadRpt.groupBy("status");*/

		} else {
			console.log('inside enterKeyGridReconfigure if model not null');
			//addInTable;
			console.log('SELECTED ROW INDEX : ' + this.selectedRowIndex);
			this.unloadingTripDetailsDataList.splice(this.selectedRowIndex, 1);
			this.addInTable(this.actArticles, this.mainLR, art, remarks, this.selectedStatusType, mode);
			this.hireslipDtoForEdit = new HireSlipDto();
			this.selectedRowIndex = '';
			/*gridUnloadRpt.getStore().remove(model);
			storeUnloadRpt.add(new Maintenance(actArticles, mainLR, art,
					remarks,
					lbxStatus.getItemText(lbxStatus.getSelectedIndex()), mode));
			gridUnloadRpt.reconfigure(storeUnloadRpt, cmUnloadRpt);
			storeUnloadRpt.groupBy("status");*/
		}

	}
	// Grid Reconfigure
	gridReconfig(lrShortageForSingleLrRet) {
		console.log('inside gridReconfig');
		let lrNo = lrShortageForSingleLrRet.lrNumber;
		let lrStatus = lrShortageForSingleLrRet.status;
		this.alreadyExiMaint = false;
		this.selectedStatusType = $("#" + this.pageId + "unloadingStatus").val();
		for (let i = 0; i < this.unloadingTripDetailsDataList.length; i++) {
			let lrNoInGrid = this.unloadingTripDetailsDataList[i].lrNumber;
			let lrStsInGrid = this.unloadingTripDetailsDataList[i].status;
			if (lrNo == lrNoInGrid) {
				this.alreadyExiMaint = false;
				if (lrStatus == lrStsInGrid) {
					this.alreadyExiMaint = true;
					break;
				} else if ((lrStatus == "Short")
					&& (lrStsInGrid == "Extra")) {
					this.alreadyExiMaint = true;
					break;
				} else if ((lrStatus == "Extra")
					&& (lrStsInGrid == "Short")) {
					this.alreadyExiMaint = true;
					break;
				}
			} else {
				this.alreadyExiMaint = false;
			}

		}
		if (this.alreadyExiMaint == true) {
			swal({
				title: "Warning",
				text: "Duplicate Entry" + this.mainLR + " Already Exist",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
			$("#" + this.pageId + "unloadingStatus").focus();

		} else {
			console.log('inside gridReconfig in  else part');
			let remarks = "NA";
			if (lrShortageForSingleLrRet == null
				|| lrShortageForSingleLrRet == '') {
				remarks = "NA";
				console.log('inside gridReconfig2');
			} else {
				console.log('inside gridReconfig3');

				remarks = lrShortageForSingleLrRet.remarks;
			}

			this.addInTable(this.actArticles, lrShortageForSingleLrRet.lrNumber, lrShortageForSingleLrRet.totalArticles, remarks, lrShortageForSingleLrRet.status, "YES");
			$("#" + this.pageId + "lrCode").val('');
			$("#" + this.pageId + "unloadinglrNumber").val('');
			this.selectedStatusType = '';
			$("#" + this.pageId + "unloadingStatus").val('Select Status');
			$("#" + this.pageId + "unloadingStatus").focus();
		}
	}
	//Adding In Table
	addInTable(actArticles, mainLR, art, remarks, status, mode) {
		console.log('inside of addInTable')
		this.newAttribute.status = status;
		this.newAttribute.lrNumber = mainLR;
		this.newAttribute.totalArticles = actArticles;
		this.newAttribute.type = art;
		this.newAttribute.remarks = remarks;
		this.newAttribute.mode = mode;
		$("#" + this.pageId + "unloadingTripDetailsId").DataTable().destroy();
		if (this.unloadingTripDetailsDataList == null) {
			this.unloadingTripDetailsDataList = [];
		}
		if (this.unloadingTripDetailsDataList.length == 0) {
			this.unloadingTripDetailsDataList.push(this.newAttribute);
		} else {
			this.unloadingTripDetailsDataList.push(this.newAttribute);
		}
		this.dtTriggerUnloadingTripDetails.next();
		console.log(this.unloadingTripDetailsDataList);
		this.countOfLr = +this.countOfLr + 1;
		this.newAttribute = {};
	}
	// For Edit 
	rowSelectedEditForUnlaoding(unloadingTripDetailsData, index) {

		swal({
			title: "Confirm Edit",
			text: "Sure you want to Edit the selected Row? If Yes then please go to the top entry fieds to make the changes!",
			icon: "warning",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((remove) => {
			if (remove) {
				this.rowSelected = true;
				this.modelDup = '';
				this.modelDup = unloadingTripDetailsData;
				this.hireslipDtoForEdit = new HireSlipDto();
				this.hireslipDtoForEdit = this.modelDup;
				this.selectedRowIndex = index;

				let SelecedRowLrNumber = unloadingTripDetailsData.lrNumber;
				let mode = unloadingTripDetailsData.mode;
				$("#" + this.pageId + "lrCode").val('');
				$("#" + this.pageId + "unloadinglrNumber").val(SelecedRowLrNumber);
				$("#" + this.pageId + "unloadingStatus").val(unloadingTripDetailsData.status);
				$("#" + this.pageId + "remark").val(unloadingTripDetailsData.remarks);
				$("#" + this.pageId + "shortageArticle").val(unloadingTripDetailsData.type);
				if (mode != null && mode == 'YES') {
					$("#" + this.pageId + "mention").val('YES');
				} else if (mode != null
					&& mode == 'NO') {
					$("#" + this.pageId + "mention").val('NO');
				}

			}

		});


		console.log(unloadingTripDetailsData);

	}
	// For Delete 
	rowSelectedDeleteForUnlaoding(unloadingTripDetailsData, index) {
		console.log(unloadingTripDetailsData);
		swal({
			title: "Confirm Remove",
			text: "Sure you want to remove the selected Row",
			icon: "warning",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((remove) => {
			if (remove) {
				this.unloadingTripDetailsDataList.splice(index, 1);
				$("#" + this.pageId + "unloadingTripDetailsId").DataTable().destroy();

				this.dtTriggerUnloadingTripDetails.next();
				this.countOfLr = +this.countOfLr - 1;
				console.log(this.unloadingTripDetailsDataList);
			}

		});
	}

	btnPopSave() {

		swal({
			title: "Confirm Save",
			text: "Please check the entered details again, once saved cannot be update.Sure U Want to Save the Unloading Report Details",
			icon: "warning",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((save) => {
			if (save) {
				this.hireSlipDtoForPopSave = new HireSlipDto();
				this.selectedStatusType = $("#" + this.pageId + "unloadingStatus").val();
				if (this.selectedStatusType != '' && this.selectedStatusType != null && this.selectedStatusType == 'OK') {
					this.hireSlipDtoForPopSave.hireslipnumber = this.getInvoiceNumber;
					this.hireSlipDtoForPopSave.mode = "OK";
				} else {
					this.hireSlipDtoForPopSave.hireslipnumber = this.getInvoiceNumber;
					this.hireSlipDtoForPopSave.mode = "Others";
					this.hireslipDtoForSaveDetailsOfLrs = [];
					for (let i = 0; i < this.unloadingTripDetailsDataList.length; i++) {
						this.hireslipDtoForSave = new HireSlipDto();
						this.hireslipDtoForSave.type = this.unloadingTripDetailsDataList[i].status;
						this.hireslipDtoForSave.lrNumber = this.unloadingTripDetailsDataList[i].lrNumber;
						this.hireslipDtoForSave.totalArticles = this.unloadingTripDetailsDataList[i].type;
						this.hireslipDtoForSave.remarks = this.unloadingTripDetailsDataList[i].remarks;
						this.hireslipDtoForSave.messageText = this.unloadingTripDetailsDataList[i].mode;
						this.hireslipDtoForSaveDetailsOfLrs.push(this.hireslipDtoForSave);
					}
					this.hireSlipDtoForPopSave.hireSlipDtoList = this.hireslipDtoForSaveDetailsOfLrs;
					this.hireSlipDtoForPopSave.lastupdatedby = this.userDataDtoReturnSession.userId;
					this.hireSlipDtoForPopSave.companyid = this.userDataDtoReturnSession.companyId;
				}
				console.log(this.hireSlipDtoForPopSave);
				this.showSpinnerForAction = true;
				this.hireslipService.addHireSlipsUnLoadRptDetails(this.hireSlipDtoForPopSave).subscribe(
					(response) => {
						this.showSpinnerForAction = false;
						console.log(response);
						if (response.status == 'Success') {
							$("#" + this.pageId + "shortageArticle").val('');


							swal({
								title: "Success",
								text: "Saved Successfully",
								icon: "success",
								closeOnClickOutside: false,
								closeOnEsc: false,
							}).then(() => {
								swal({
									title: "Print",
									text: "Sure U Want to Print the Unloading Report ",
									icon: "warning",
									closeOnClickOutside: false,
									closeOnEsc: false,
									buttons: ["No", "Yes"],
								}).then((print) => {
									if (print) {

										this.customPrintForUnloading();
										window.open('/report/cashmemo-report/customDynamicPrintV1', '_blank');


									} else {
										this.clearAllMethod();
									}
								});

							});


						} else if (response.status == 'Cleared') {
							swal({
								title: "Success",
								text: "Successfully Cleared",
								icon: "success",
								closeOnClickOutside: false,
								closeOnEsc: false,
							});
							this.clearAllMethod();
						} else if (response.status == 'NotExist') {
							swal({
								title: "Error",
								text: "Not Saved ",
								icon: "error",
								closeOnClickOutside: false,
								closeOnEsc: false,
							});
							//this.clearAllMethod();
						} else if (response.status == 'Failed') {
							swal({
								title: "Error",
								text: "Failed To Save Or Clear ",
								icon: "error",
								closeOnClickOutside: false,
								closeOnEsc: false,
							});

						}
						this.changeDetectorRef.detectChanges();

					}), (error) => {
						this.showSpinnerForAction = false;
						swal("Error", "Server Problem Occurred While addHireSlipsUnLoadRptDetails  details", "info");
					}, () => console.log('done');

			}



		});

	}
	clearAll() {
		$("#" + this.pageId + "lrCode").val('');
		$("#" + this.pageId + "unloadinglrNumber").val('');
		$("#" + this.pageId + "mention").val('');
		$("#" + this.pageId + "shortageArticle").val('');
		$("#" + this.pageId + "remark").val('');
		$("#" + this.pageId + "unloadingStatus").val('Select Status');
		this.enteredLRNumber = '';
		this.selectedStatusType = '';
		this.enteredShortageArticle = '';
		this.enteredLrCode = '';
		this.hireslipDtoForGetLrMthd = new HireSlipDto();
		this.lrDtoForGerLrMthd = new LRDto();
		this.showSpinnerForAction = false;
		this.lrdtsUnloadRptRet = '';
		this.hireslipDtoForLRdtsUnloadRptRet = new HireSlipDto();
		this.actArticles = 0;
		this.getLrShortageForSingleLrRet = '';
		this.lrShortageForSingleLrRet = new LRDto();
		this.mainLR = null;
		this.alreadyExiMaint = false;
		this.rowSelected = false;
		this.enteredRemarks = '';
		this.hireslipDtoForEdit = new HireSlipDto();
		this.selectedRowIndex = '';
		this.countOfLr = 0;
		this.newAttribute = {};
		$("#" + this.pageId + "unloadingTripDetailsId").DataTable().destroy();
		this.unloadingTripDetailsDataList = [];
		this.dtTriggerUnloadingTripDetails.next();
		this.modelDup = '';
		this.hireSlipDtoForPopSave = new HireSlipDto();
		this.hireslipDtoForSave = new HireSlipDto();
		this.articles = 0;
	}
	clearAllMethod() {
		$("#" + this.pageId + "lrCode").val('');
		$("#" + this.pageId + "unloadinglrNumber").val('');
		$("#" + this.pageId + "mention").val('');
		$("#" + this.pageId + "shortageArticle").val('');
		$("#" + this.pageId + "remark").val('');
		$("#" + this.pageId + "unloadingStatus").val('Select Status');
		this.enteredLRNumber = '';
		this.selectedStatusType = '';
		this.enteredShortageArticle = '';
		this.enteredLrCode = '';
		this.hireslipDtoForGetLrMthd = new HireSlipDto();
		this.lrDtoForGerLrMthd = new LRDto();
		this.showSpinnerForAction = false;
		this.lrdtsUnloadRptRet = '';
		this.hireslipDtoForLRdtsUnloadRptRet = new HireSlipDto();
		this.actArticles = 0;
		this.getLrShortageForSingleLrRet = '';
		this.lrShortageForSingleLrRet = new LRDto();
		this.mainLR = null;
		this.alreadyExiMaint = false;
		this.rowSelected = false;
		this.enteredRemarks = '';
		this.hireslipDtoForEdit = new HireSlipDto();
		this.selectedRowIndex = '';
		this.countOfLr = 0;
		this.newAttribute = {};
		$("#" + this.pageId + "unloadingTripDetailsId").DataTable().destroy();
		this.unloadingTripDetailsDataList = [];
		this.dtTriggerUnloadingTripDetails.next();
		this.modelDup = '';
		this.hireSlipDtoForPopSave = new HireSlipDto();
		this.hireslipDtoForSave = new HireSlipDto();
		this.articles = 0;
		this.hireslipdtoForOnload = new HireSlipDto();
		this.cashMemoDtoForCustomPrintDataList = [];
		this.cashMemoDtoForCustomPrintDataSummaryList = [];
		this.dialogRef.close();
	}

	onCancelClick(): void {
		this.dialogRef.close();
	}

	customPrintForUnloading() {
		localStorage.clear();
		this.cashMemoDtoForCustomPrintList = [];

		this.cashMemoDtoForCustomPrintListColumnNames = ["Status", "LR Number", "Actual Article", "Enter Article", "Remark", "Mention"];
		this.cashMemoDtoForCustomPrintListColumnWidths = [20, 20, 10, 10, 30, 10];
		for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
			this.cashMemoDtoForCustomPrint = new CashMemoDto();
			this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
			this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
			this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			console.log(this.cashMemoDtoForCustomPrintList);
		}

		this.cashMemoDtoForCustomPrintDataList = [];
		this.cashMemoDtoForCustomPrintDataSummaryList = [];
		this.selectedStatusType = $("#" + this.pageId + "unloadingStatus").val();
		if (this.selectedStatusType != '' && this.selectedStatusType != null && this.selectedStatusType != 'OK') {
			for (let i = 0; i < this.unloadingTripDetailsDataList.length; i++) {
				console.log(this.unloadingTripDetailsDataList[i].lrNumber);
				this.cashMemoDtoForCustomPrintListColumnValues = [this.unloadingTripDetailsDataList[i].status, this.unloadingTripDetailsDataList[i].lrNumber,
				this.unloadingTripDetailsDataList[i].totalArticles, this.unloadingTripDetailsDataList[i].type, this.unloadingTripDetailsDataList[i].remarks, this.unloadingTripDetailsDataList[i].mode];
				//////
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.unloadingTripDetailsDataList.length, "", "", "", "", ""];
		} else {
			this.cashMemoDtoForCustomPrintListColumnValues = ["OK", "NA", "NA", "NA", "NA", "NA"];
			//////
			this.cashMemoDtoForCustomPrintData = new CashMemoDto();
			this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
			this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : 1 ", "", "", "", "", ""];

		}

		///Added for heading 
		//heading logics For Date
		this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
		this.cashMemoDtoForCustomPrintListHeadingV1 = [];
		this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Truck Number ", "Invoice Number", "Driver", "Date"];
		this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.invoicedtoForSearchOnLoadDetailsRet.vehicleNumber, this.invoicedtoForSearchOnLoadDetailsRet.hireSlipNumber, this.invoicedtoForSearchOnLoadDetailsRet.driverName, this.todayDate];

		for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
			this.cashMemoDtoForCustomPrint = new CashMemoDto();
			this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
			this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
			this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

		}

		//Heading Logics For Heading
		this.cashMemoDtoForCustomPrintListHeadingV2 = [];
		this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Supplier ", "Invoice Date", "Mobile Number"];
		this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.invoicedtoForSearchOnLoadDetailsRet.supplierName, this.datePipe.transform(this.invoicedtoForSearchOnLoadDetailsRet.invoiceDate, "dd-MM-yyyy"), this.invoicedtoForSearchOnLoadDetailsRet.mobileNum];
		for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
			this.cashMemoDtoForCustomPrint = new CashMemoDto();

			this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
			this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];

			this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
		}
		localStorage.setItem('printTitle', "Unloadding Report");
		localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
		localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
		///
		this.cashMemoDtoForCustomPrintListHeadingV3 = [];
		this.cashMemoDtoForCustomPrintListHeadingV4 = [];
		this.cashMemoDtoForCustomPrintListHeadingNamesV3 = ["Signature of Driver"];
		for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
			this.cashMemoDtoForCustomPrint = new CashMemoDto();
			this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
			this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
		}

		this.cashMemoDtoForCustomPrintListHeadingNamesV4 = ["For SRD Logistics PVT.LTD."];
		for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV4.length; i++) {
			this.cashMemoDtoForCustomPrint = new CashMemoDto();
			this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV4[i];
			this.cashMemoDtoForCustomPrintListHeadingV4.push(this.cashMemoDtoForCustomPrint);
		}


		localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
		localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
		localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
		localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
		localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
		this.clearAllMethod();
		//this.viewCustomPrintV1 = true;
		window.addEventListener('afterprint', (onclick) => {
			if (this.viewCustomPrintV1) {
				this.viewCustomPrintV1 = false;
				localStorage.clear();
				this.cashMemoDtoForCustomPrintListHeadingV2 = [];
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrintListHeadingV1 = [];
				this.cashMemoDtoForCustomPrintList = [];
				this.cashMemoDtoForCustomPrintDataList = [];
				this.cashMemoDtoForCustomPrintDataSummaryList = [];
			}
		});

	}


}

