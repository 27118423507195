import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MemoService } from "src/app/dataService/memo-service";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { DatePipe } from "@angular/common";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';

@Component({
    selector: 'app-transfer-to-collection',
    templateUrl: './transfer-to-collection.component.html',
    styleUrls: ['./transfer-to-collection.component.css']
})
export class TransferToCollectionComponent implements OnInit {
    //    gettingDataFrmServiceFrTransferToCollectionTable: any;
    transferToCollectionDataList: Array<any> = [];
    transferToCollectionDataListPush: any;
    //    onDestroyUnsubscribtionTransferToCollection: Subscription;
    loadingIndicator = true;
    //stationOptions: LRDto[];
    lrDto: LRDto = new LRDto();
    lrDtoForTable: LRDto = new LRDto();
    chkCashDtoTransfer: CashMemoDto = new CashMemoDto();
    searchCashmemoNumber: CashMemoDto = new CashMemoDto();
    userDataDto: UserDataDto = new UserDataDto();
    userDtoMemoCode: UserDataDto = new UserDataDto();
    isTransferBlockForNonDeliveryLrs = false;
    blockRights = "TransToColl isTransferBlockForNonDeliveryLrs";
    memoTypeRights = "TransToColl ShowMemoTypeView";

    manualView = false;
    scanView = true;
    memoTypeView = false;
    stationCodeDetails: any;
    memoFormat: any;
    userDataDtoReturnSession: any;
    isLoggedIn = true;

    selectedEntryType: any;
    enteredMemoNumber: any;
    enteredCashMemoFormat: any;
    enteredCashMemoNumber: any;
    memoNum = null;
    selectedStation: any;
    selectedTransferType: any;
    private newAttribute: any = {};
    controlStation = new FormControl();
    showSpinnerForAction = false;
    officeToTrans: any;


    lrDtoForStation: LRDto = new LRDto();
    stationOptions: any;
    countOfLr = 0;
    sumOfTotAmt = 0;
    sumOfTotArt = 0;
    stationForCode: any;
    address: any;
    ////


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerTransferToCollection: Subject<any> = new Subject();
    dataTable: any;

    dtOptionsTransferToCollection: any;
    selectedMemoNumberForManual: any;
    selectedMemoNumberForAutomatic: any;
    selectedCodeForManual: any;
    listOfMemoNumber: Array<any> = [];
    transferToGodownDto: CashMemoDto = new CashMemoDto();
    modelStation: any;
    stationTA: Array<PartyMasterDto> = [];
    focusStationTA$ = new Subject<string>();
    stationSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.stationTA
                : this.stationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterStation = (x: { destination: string }) => x.destination;


    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;

    avgActWtSummary: number;
    avgChgWtSummary: number;
    articleSummary: number;
    actWtSummary: number;
    totalMemoSummary: number;
    chgWtSummary: number;
    totalAmtSummary: number;
    todayDate: any;
    isReadOnly: boolean;
    pageId = "trtcc";

    constructor(private transferToCollectionScreen: ReportService, private router: Router, private memoService: MemoService, private masterReadService: MasterReadService, private memoReport: MemoReport, private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            //   this.getMemoCode();
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.getStationDtails();
            //need comment this 
            /*   if ( this.blockRights == "TransToColl isTransferBlockForNonDeliveryLrs" ) {
                   this.isTransferBlockForNonDeliveryLrs = true;
               }
               if ( this.memoTypeRights == "TransToColl ShowMemoTypeView" ) {
                   this.memoTypeView = true;
               }*/
            //rights not added anywhere (local,live)
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                //    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "TransToColl isTransferBlockForNonDeliveryLrs") {
                        this.isTransferBlockForNonDeliveryLrs = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "TransToColl ShowMemoTypeView") {
                        this.memoTypeView = true;
                    }

                }
            }
        }
    }
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    ngOnInit(): void {
        var companyAddressDetls = this.address;
        var mainStation = this.userDataDtoReturnSession.mainStation;
        var todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
        var dateformate = moment(todayDate).format('DD-MM-YYYY');
        this.dtOptionsTransferToCollection = {

            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": true,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalAmt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalArt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html();
                $(api.column(2).footer()).html(totalArt);
                $(api.column(3).footer()).html();
                $(api.column(4).footer()).html();
                $(api.column(5).footer()).html(totalAmt);
                $(api.column(6).footer()).html();
                $(api.column(7).footer()).html();


            }

        }
    }

    ngOnDestroy(): void {
        this.dtTriggerTransferToCollection.unsubscribe();

    }
    ngAfterViewInit(): void {
        this.dtTriggerTransferToCollection.next();

    }
    entryTypeMode(entryType: string) {
        if (entryType === 'manual') {
            this.manualView = true;
            this.scanView = false;
        } else if (entryType === 'scan') {
            this.manualView = false;
            this.scanView = true;
        } else {
            this.manualView = false;
            this.scanView = false;
        }
    }
    transferTypeMode(transferType: string) {
        if (transferType === 'manualCashMemo') {
            if (this.userDataDtoReturnSession.mainStation == "Chennai" && this.userDataDtoReturnSession.role != "Administrator") {
                this.isReadOnly = true;
            } else {
                this.isReadOnly = false;
            }
            this.entryTypeMode("scan");
            $("#" + this.pageId + "entryType").val("scan");
        } else if (transferType === 'paymentCashMemo') {
            this.isReadOnly = false;
        }

    }

    getStationInfo() {
        this.lrDtoForStation = new LRDto();
        this.lrDtoForStation.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForStation.mainstation = this.userDataDtoReturnSession.mainStation;
        this.lrDtoForStation.mode = "ALL";

    }
    getStationDtails = () => {
        this.getStationInfo();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoForStation).subscribe(
            (response) => {
                if (response) {
                    this.stationOptions = response;
                    this.lrDtoForStation = new LRDto();
                    this.stationTA = [];
                    //                    console.log(this.stationOptions.length);
                    for (let i = 0; i < this.stationOptions.length; i++) {
                        this.stationTA.push(this.stationOptions[i]);
                    }
                    console.log(this.stationTA, this.stationOptions);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Error While Getting Station Details", "info");
            }, () => console.log('done');
    };

    getMemoCode(e: NgbTypeaheadSelectItemEvent) {
        this.userDtoMemoCode = new UserDataDto();
        this.modelStation = e.item;
        this.userDtoMemoCode.companyId = this.userDataDtoReturnSession.companyId;
        this.stationForCode = this.modelStation.destination;
        this.userDtoMemoCode.office = this.stationForCode;
        console.log(this.userDtoMemoCode);
        this.getDetailsForMemoCode();
    }

    getDetailsForMemoCode() {
        this.masterReadService.getStationCodeService(this.userDtoMemoCode).subscribe(
            (response) => {
                if (response.length != 0) {
                    this.selectedEntryType = $("#" + this.pageId + "entryType").val();
                    if (this.selectedEntryType == "scan") {
                        this.scanView = true;
                        $("#" + this.pageId + "cashMemoAutomatic").focus();
                    } else {
                        this.stationCodeDetails = response;
                        if (this.stationCodeDetails.stationCode == null) {
                            $("#" + this.pageId + "codeForManual").val('');
                            //                            console.log( this.stationCodeDetails.stationCode );
                        } else {
                            //                            console.log( this.stationCodeDetails.stationCode );
                            this.memoFormat = this.stationCodeDetails.stationCode + "/" + this.userDataDtoReturnSession.financialYearSplit + "/";
                            //                            console.log( "Memo>>> " + this.memoFormat );
                            $("#" + this.pageId + "codeForManual").val(this.memoFormat);
                            $("#" + this.pageId + "cashMemoManual").val('');
                            $("#" + this.pageId + "cashMemoAutomatic").val('');
                            $("#" + this.pageId + "lrNumber").val('');
                            $("#" + this.pageId + "memoAmount").val('');
                            $("#" + this.pageId + "cashMemoManual").focus();
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Getting Station",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };


    getFocusMethod() {
        this.selectedEntryType = $("#" + this.pageId + "entryType").val();
        $("#" + this.pageId + "cashMemoManual").val('');
        $("#" + this.pageId + "cashMemoAutomatic").val('');
        if (this.selectedEntryType == "scan") {

            window.setTimeout(function () {
                $("#" + this.pageId + "cashMemoAutomatic").focus();
            }, 100);


        } else {

            window.setTimeout(function () {
                $("#" + this.pageId + "cashMemoManual").focus();
            }, 100);

        }
    }
    //////////////////
    getMemoNumberForManual(e) {
        if (e.keyCode == 13) {
            this.selectedMemoNumberForManual = $("#" + this.pageId + "cashMemoManual").val();
            if (this.selectedMemoNumberForManual != null && this.selectedMemoNumberForManual != '') {
                if (this.memoTypeView == true) {
                    this.selectedTransferType = $("#" + this.pageId + "transferType").val();
                    if (this.selectedTransferType == null || this.selectedTransferType == '') {
                        swal({
                            title: "Empty Data",
                            text: "Please select Transfer Type",
                            icon: "error",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        }).then(() => {
                            swal.close();
                            window.setTimeout(function () {
                                $("#" + this.pageId + "transferType").focus();
                            }, 100);
                            return false;
                        });
                    } else {
                        this.getMethodForGridReConfigure();
                    }


                } else {
                    this.getMethodForGridReConfigure();
                }

            }
            else {
                swal({
                    title: "Warning",
                    text: "Memo Number Empty",
                    icon: "error",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    swal.close();

                    window.setTimeout(function () {
                        $("#" + this.pageId + "cashMemoManual").focus();
                    }, 100);
                    return false;
                });
            }
        }

    }

    getMemoNumberForAutomatic(e) {

        /*  setTimeout(() => {
              this.showforAutomatic();
          },
              500 );*/

        if (e.keyCode == 13) {
            this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "cashMemoAutomatic").val();
            if (this.selectedMemoNumberForAutomatic != null && this.selectedMemoNumberForAutomatic != '') {
                this.getMethodForGridReConfigure();
            } else {
                swal({
                    title: "Warning",
                    text: "Memo Number Empty",
                    icon: "error",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    swal.close();
                    window.setTimeout(function () {
                        $("#" + this.pageId + "cashMemoAutomatic").focus();
                    }, 100);
                    return false;
                });
            }

        }
    }
    /* showforAutomatic() {
           this.selectedMemoNumberForAutomatic = $("#"+this.pageId+"cashMemoAutomatic" ).val();
           if ( this.selectedMemoNumberForAutomatic != null && this.selectedMemoNumberForAutomatic != '' ) {
               this.getMethodForGridReConfigure();
           }else{
               swal({
                       title: "Warning",
                       text: "Memo Number Empty",
                       icon: "error",
                       closeOnClickOutside: false,
                       closeOnEsc: false,
                   }).then(() => {
                       swal.close();
                        window.setTimeout(function () {
                              $("#"+this.pageId+"cashMemoAutomatic" ).focus();
                       }, 100);
                    return false;
                   });
           }
       }*/



    getMethodForGridReConfigure() {
        this.memoNum = '';
        this.selectedStation = $("#" + this.pageId + "station").val();
        this.selectedEntryType = $("#" + this.pageId + "entryType").val();
        this.selectedCodeForManual = $("#" + this.pageId + "codeForManual").val();
        this.selectedMemoNumberForManual = $("#" + this.pageId + "cashMemoManual").val();
        this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "cashMemoAutomatic").val();
        if (this.selectedEntryType == 'scan') {
            this.memoNum = this.selectedMemoNumberForAutomatic;

        } else {
            this.memoNum = this.selectedCodeForManual + "" + this.selectedMemoNumberForManual;

        }
        this.searchCashmemoNumber = new CashMemoDto();
        this.searchCashmemoNumber.station = this.selectedStation;
        this.searchCashmemoNumber.memoNumber = this.memoNum;
        this.gridReconfigure();
        this.showSpinnerForAction = true;
    }
    gridReconfigure() {

        this.memoReport.getSingleCashMemo(this.searchCashmemoNumber).subscribe(

            (response) => {
                this.showSpinnerForAction = false;
                this.transferToCollectionDataListPush = response;
                this.chkCashDtoTransfer = this.transferToCollectionDataListPush;
                console.log(this.chkCashDtoTransfer);
                if (this.chkCashDtoTransfer.size == 0) {
                    swal({
                        title: "LR Not Found",
                        text: "CashMemo " + this.memoNum + " not found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,

                    }).then(() => {
                        this.getFocusMethod();
                    })
                } else if (this.chkCashDtoTransfer.status == "Delivered") {
                    swal({
                        title: "LR Delivered",
                        text: "CashMemo " + this.memoNum + " Delivered!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,

                    }).then(() => {
                        this.getFocusMethod();
                    })
                } else if (this.chkCashDtoTransfer.memoType == "Cash") {
                    swal({
                        title: "Not Allowed",
                        text: "CashMemo " + this.memoNum + " type is Cash! Can't Transfer",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,

                    }).then(() => {
                        this.getFocusMethod();
                    })
                } else if (this.chkCashDtoTransfer.memoType == "PP") {
                    swal({
                        title: "Not Allowed",
                        text: "CashMemo " + this.memoNum + " type is PP! Can't Transfer",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,

                    }).then(() => {
                        this.getFocusMethod();
                    })
                }
                // only delivered LR will able to move in
                // transfer(Mr.Senthil Req) @Dated : 07/12/2017
                else if (this.isTransferBlockForNonDeliveryLrs == true) {
                    // added the patch to avoid the block of Goods
                    // Return LRs in
                    // Transfer to Collection, Goods return Lrs should
                    // allow in
                    // transfer to collection @Dated : 19/12/2017

                    if (this.chkCashDtoTransfer.goodsReturnStatus == null || this.chkCashDtoTransfer.goodsReturnStatus != "Goods Return") {
                        if (this.chkCashDtoTransfer.lrStatus != "Delivered") {
                            swal({
                                title: "Not Allowed",
                                text: "CashMemo " + this.memoNum + " not yet Deliver!",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,

                            }).then(() => {
                                this.getFocusMethod();
                            })


                        } else {
                            this.memoCheckCallAllow(this.chkCashDtoTransfer)
                        }
                    } else {
                        this.memoCheckCallAllow(this.chkCashDtoTransfer)
                    }

                } else {
                    this.memoCheckCallAllow(this.chkCashDtoTransfer)
                }

                this.changeDetectorRef.detectChanges();
            }
        ), (error) => swal({
            title: "Error",
            text: "Server Error While Getting Memo Details",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
        }),
            () => console.log('done');


    }

    memoCheckCallAllow(chkCashDtoTransfer) {

        if (this.transferToCollectionDataList.length > 0) {
            const checkMemoNumberInTable = this.transferToCollectionDataList.find(transferToCollectionData => transferToCollectionData.memoNumber === this.memoNum);
            console.log(checkMemoNumberInTable);

            if (checkMemoNumberInTable == undefined) {
                this.addInTable();
            } else {
                swal({
                    title: "Duplicate CashMemo",
                    text: "CashMemo " + this.memoNum + " already moved to transfer!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    swal.close();
                    this.getFocusMethod();
                });
            }

        } else {
            console.log('5')
            this.getFocusMethod();
            this.addInTable();
        }
    }

    addInTable() {
        console.log('8')
        this.newAttribute.memoNumber = this.chkCashDtoTransfer.memoNumber;
        this.newAttribute.lrNumber = this.chkCashDtoTransfer.lrNumber;
        this.newAttribute.totalArticles = this.chkCashDtoTransfer.totalArticles;
        this.newAttribute.consigneeName = this.chkCashDtoTransfer.consigneeName;
        this.newAttribute.consignor = this.chkCashDtoTransfer.consignor;
        this.newAttribute.amount = this.chkCashDtoTransfer.amount;
        this.newAttribute.collectionMan = this.chkCashDtoTransfer.collectionMan;
        $("#" + this.pageId + "transferToCollectionId").DataTable().destroy();
        if (this.transferToCollectionDataList == null) {
            this.transferToCollectionDataList = [];
        }


        if (this.transferToCollectionDataList.length == 0) {
            this.transferToCollectionDataList.push(this.newAttribute);
        } else {
            this.transferToCollectionDataList.push(this.newAttribute);
        }
        this.dtTriggerTransferToCollection.next();
        // console.log( this.transferToCollectionDataList );

        this.countOfLr = +this.countOfLr + 1;
        this.sumOfTotAmt = +this.sumOfTotAmt + +this.newAttribute.amount;
        this.sumOfTotArt = +this.sumOfTotArt + +this.newAttribute.totalArticles;
        this.newAttribute = {};
        $("#" + this.pageId + "cashMemoManual").val('');
        $("#" + this.pageId + "cashMemoAutomatic").val('');
    }


    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    transferBtnValidation() {
        if (this.transferToCollectionDataList.length == 0) {
            swal({
                title: "Not Record",
                text: "No memos to transfer!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                swal.close();
                this.getFocusMethod();
            });
        } else {
            this.officeToTrans = '';
            /*if (this.userDataDtoReturnSession.office=='Binnymills') {
            //As of now user only konditope
            //As per asrar bhai 
            //	this.officeToTrans = "Konditope";
                this.officeToTrans = this.userDataDtoReturnSession.office;
            }*/
            this.officeToTrans = this.selectedStation;

            swal({
                title: "Validation",
                text: "Sure U Want to Transfer the Selected CashMemos To " + this.officeToTrans,
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((transfer) => {
                if (transfer) {
                    this.getTransferMemoDetails();
                } else {

                }
            });
        }


    }
    getTransferMemoDetails() {
        this.selectedStation = $("#" + this.pageId + "station").val();
        this.transferToGodownDto = new CashMemoDto();
        if (this.memoTypeView == true) {
            this.selectedTransferType = $("#" + this.pageId + "transferType").val();
            if (this.selectedTransferType == null || this.selectedTransferType == '') {
                this.transferToGodownDto.memoType = null;
            } else {
                this.transferToGodownDto.memoType = this.selectedTransferType;
            }
        } else {
            this.transferToGodownDto.memoType = null;
        }
        this.listOfMemoNumber = [];
        for (let i = 0; i < this.transferToCollectionDataList.length; i++) {
            this.listOfMemoNumber.push(this.transferToCollectionDataList[i].memoNumber);
        }
        this.transferToGodownDto.status = 'transferToGodown';



        this.transferToGodownDto.station = this.userDataDtoReturnSession.office;
        this.transferToGodownDto.office = this.userDataDtoReturnSession.office;
        this.transferToGodownDto.stationType = this.userDataDtoReturnSession.officeType;
        this.transferToGodownDto.userName = this.userDataDtoReturnSession.userId;
        this.transferToGodownDto.mainStation = this.userDataDtoReturnSession.mainStation;
        //As per Asrar bhai Instructions
        //this.transferToGodownDto.godown='Konditope';
        this.transferToGodownDto.godown = this.selectedStation;
        //
        this.transferToGodownDto.companyId = this.userDataDtoReturnSession.companyId;
        this.transferToGodownDto.list = this.listOfMemoNumber;
        this.getTransferMemoInfo();
        console.log(this.transferToGodownDto);
        this.showSpinnerForAction = true;
    }

    getTransferMemoInfo() {
        this.showSpinnerForAction = true;
        this.memoReport.transferToGodownLrs(this.transferToGodownDto).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                if (response.status == 'Success') {
                    /* swal( {
                title: "Validation",
                text: "Are U Sure U want to Print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            } ).then(( print ) => {
                if ( print ) {
                //Print Pending
                    //this.getTransferMemoDetails();
                $("#"+this.pageId+"transferToCollectionId").DataTable().destroy();
                this.transferToCollectionDataList = [];
                this.dtTriggerTransferToCollection.next();
                $("#"+this.pageId+"cashMemoManual" ).val('');
                $("#"+this.pageId+"cashMemoAutomatic" ).val('');
                } else {
                    
                }
                   } );*/
                    swal({
                        title: "Success",
                        text: "Memos Transfered Successfully!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        swal.close();
                        this.getFocusMethod();
                    });
                    $("#" + this.pageId + "transferToCollectionId").DataTable().destroy();
                    this.transferToCollectionDataList = [];
                    this.dtTriggerTransferToCollection.next();
                    $("#" + this.pageId + "cashMemoManual").val('');
                    $("#" + this.pageId + "cashMemoAutomatic").val('');

                } else if (response.status == 'No Records') {


                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Error in getting updating memo : ", "info");
            }, () => console.log('done');
    };

    rowSelectedDeleteForTable(transferToCollectionData, index) {
        swal({
            title: "Confirm Remove",
            text: "Sure you want to remove the selected Row",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((remove) => {
            if (remove) {
                this.transferToCollectionDataList.splice(index, 1);
                $("#" + this.pageId + "transferToCollectionId").DataTable().destroy();
                this.dtTriggerTransferToCollection.next();
                this.countOfLr = +this.countOfLr - 1;
                this.sumOfTotAmt = +this.sumOfTotAmt - +this.newAttribute.amount;
                this.sumOfTotArt = +this.sumOfTotArt - +this.newAttribute.totalArticles;
                this.newAttribute = {};
                $("#" + this.pageId + "cashMemoManual").val('');
                $("#" + this.pageId + "cashMemoAutomatic").val('');

            }

        });
    }

    customPrintTransToColMan() {
        if (this.transferToCollectionDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Cash Memo", "LR No", "Tot Art", "Consignee", "Consignor", "Amount", "Collection Man"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [8, 15, 8, 20, 20, 9, 20];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];



            this.articleSummary = this.totalAmtSummary = 0;

            for (let i = 0; i < this.transferToCollectionDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.transferToCollectionDataList[i].memoNumber, this.transferToCollectionDataList[i].lrNumber,
                this.transferToCollectionDataList[i].totalArticles, this.transferToCollectionDataList[i].consigneeName, this.transferToCollectionDataList[i].consignor,
                this.transferToCollectionDataList[i].amount, this.transferToCollectionDataList[i].collectionMan];

                /////////  

                this.articleSummary = +this.articleSummary + this.transferToCollectionDataList[i].totalArticles;



                this.totalAmtSummary = +this.totalAmtSummary + this.transferToCollectionDataList[i].amount;



                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.transferToCollectionDataList.length, "", this.articleSummary, "", "", this.totalAmtSummary];
            }




            //heading logics
            var todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
            var dateformate = moment(todayDate).format('DD-MM-YYYY');

            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.todayDate];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

            }

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));

            // localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Cash Memo Transfer");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }


}

