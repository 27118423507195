//import { Component, OnInit } from '@angular/core';
//import { Router } from "@angular/router";
//import { ParamMap } from "@angular/router";
//import { Route } from "@angular/router";
//import { switchMap } from "rxjs/internal/operators/switchMap";
//import { ActivatedRoute } from "@angular/router";
//import { RateMasterDto } from "src/app/dto/RateMaster-dto";
////for datepicker starts
//import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
//import { DatePipe } from "@angular/common";
////for datepicker ends
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
//for datepicker ends
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//for popup modal endss
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
import { Router, NavigationExtras } from "@angular/router";
import { MemoService } from "src/app/dataService/memo-service";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import swal from 'sweetalert';
@Component( {
    selector: 'app-lrentryform-print2',
    templateUrl: './lrentryform-print2.component.html',
    styleUrls: ['./lrentryform-print2.component.css']
} )
export class LrEntryFormPrint2Component implements OnInit {

    PrintBarcode = [];
    userDataDtoReturnSession: any;
    isLoggedIn = true;

    dataForPrint: any;
    resultDtos: LRDto = new LRDto();
    showPrintForOtherBooking = false;
    showPrintForLocalBooking = false;
    actualPrintMode = null;
    totPaid = '';
    totToPay = '';
    artSize = '';
    gatePass = '';
    ccAttach = '';
    eSugam = '';
    gateCcESugam = '';
    artChgWt = '';
    handleWitCare = '';
    toBeBilled = '';
    toBeBilled1 = '';
    unloadByCsn = '';
    eDecCom = '';
    gateCc = '';
    selfLr = '';
    showSelfLr = false;

    address = '';
    addressSplit: Array<any> = [];
    address1 = '';
    address2 = '';
    city = '';
    pinCode = '';
    phone = '';
    cityPin = '';
    emailId = '';
    subCommodity = '';
    clerkName = '';
    message = '';
    printMode = '';
    //
    mainAddress = '';
    mainMobileNo = '';
    mainEmailId = '';
    transportId = '';
    sourcePrint = '';
    destinationPrint = '';
    entryByPrint = '';
    slash = '';

    ViewToPay = false;
    ViewPaid = false;
    mainStationForPrint = 'LrEntryPrintAll For Single Station';
    slashInCode = '';
    viewLrEntryPrintPage = true;
    pageId="lrfpc";
    constructor( private router: Router, private datePipe: DatePipe, private http: HttpClient,
        private masterReadService: MasterReadService, public changeDetectorRef : ChangeDetectorRef ) {

        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                //                this.logInPage();
            } )
            //            sweet alert ends
        }

        if ( this.isLoggedIn ) {

            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );
            this.dataForPrint = JSON.parse( localStorage.getItem( 'printAfterSaveForLrEntry' ) );
            this.resultDtos = this.dataForPrint;
            //            //Pending need to be commented
            //                        this.showPrintForOtherBooking = true;
            //                        this.showPrintForLocalBooking = true;
            //

            this.PrintBarcode = [{
                barcode: this.resultDtos.lrNumber,
                Name: 'A'
            }];

            this.validatBeforePrint();
            this.mainStationForPrint = this.resultDtos.mainstation.toLowerCase();
//            console.log( this.mainStationForPrint );
//            console.log( this.resultDtos );
            window.setTimeout( function() {
                window.print();
            }, 1000 );
            window.onafterprint = function() {
                window.close();
            }
            localStorage.clear();
            window.addEventListener( 'afterprint', ( event ) => {
                if ( this.viewLrEntryPrintPage ) {
                    this.viewLrEntryPrintPage = false;
                    this.clearPrintFields();
                }
            } );
            //            if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights != null ) {
            //                for ( let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++ ) {
            //                    if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            //                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
            //                        "LrEntryPrintAll For Single Station" ) {
            //                        this.mainStationForPrint = 'LrEntryPrintAll For Single Station';
            //
            //                    }
            //                }
            //            }

        }
    }

    ngOnInit() {
    }
    validatBeforePrint() {
        this.actualPrintMode = this.resultDtos.lrEntryPrintMode;
//        console.log( this.actualPrintMode );
        //        this.printMode=this.actualPrintMode;

        if ( this.resultDtos.isUnLoadByConsignee == true ) {
            this.unloadByCsn = "Unloaded by consignee    ";
        }
        if ( this.resultDtos.remarks != null
            && ( this.resultDtos.remarks != "Select" ) ) {
            //            this.unloadByCsn += "&nbsp;&nbsp;&nbsp;&nbsp;" + "<strong>"
            //                + this.resultDtos.remarks + "</strong>";
            this.unloadByCsn += this.resultDtos.remarks;
        }

        if ( this.resultDtos.consigneeMobileNo == null || this.resultDtos.consigneeMobileNo == '' ) {
            this.resultDtos.consigneeMobileNo = 'NA';
        } else {
            this.resultDtos.consigneeMobileNo = this.resultDtos.consigneeMobileNo;
        }

        if ( this.resultDtos.consignorMobileNo == null || this.resultDtos.consignorMobileNo == '' ) {
            this.resultDtos.consignorMobileNo = 'NA';
        } else {
            this.resultDtos.consignorMobileNo = this.resultDtos.consignorMobileNo;
        }

        if ( this.resultDtos.toBeBilled == true ) {
            //Pending
            this.toBeBilled = "background='" + this.userDataDtoReturnSession.ip + "/img/to be.PNG'";
            this.toBeBilled1 = "background='" + this.userDataDtoReturnSession.ip + "/img/billed.PNG'";

        }
        if ( ( this.resultDtos.isHandWithCare == true ) && ( this.resultDtos.isCod == true ) ) {
            this.handleWitCare = "HANDLE WITH CARE - COD";
        } else if ( this.resultDtos.isHandWithCare == true ) {
            this.handleWitCare = "HANDLE WITH CARE";
        } else if ( this.resultDtos.isCod == true ) {
            this.handleWitCare = "COD";
        }

        if ( this.resultDtos.gatePass == true ) {
            this.gatePass = "EXCISE COPY ATTACHED ";
        }
        if ( this.resultDtos.ccAttach ) {

            this.ccAttach = " C.C.ATTACHED";
        }

        if ( ( this.resultDtos.gatePass == true ) && ( this.resultDtos.ccAttach == true ) ) {
            this.slash = '/';
        }
        else {
            this.slash = '';
        }
        //        getKeralaDestination();
        this.eSugam = this.resultDtos.eWayBillNo == null ? "NA" : this.resultDtos.eWayBillNo == '' ? "NA" : this.resultDtos.eWayBillNo;
        if ( this.resultDtos.isDoorDeliv == true ) {
            this.eDecCom = "DOOR DELIVERY";
        }

        if ( this.resultDtos.selfLr != null && this.resultDtos.selfLr == true ) {
            this.selfLr = "SELF LR - DO NOT DELIVER WITHOUT CONSIGNEE COPY";
            this.showSelfLr = true;
        } else {
            this.showSelfLr = false;
        }


        if ( ( ( this.handleWitCare != null ) && ( this.handleWitCare != '' ) ) && ( ( this.resultDtos.selfLr != null ) && ( this.resultDtos.selfLr == true ) ) ) {
            this.slashInCode = ' / ';
        } else {
            this.slashInCode = '';
        }
        this.gateCcESugam = this.eSugam;
        this.gateCc = this.gatePass + this.slash + this.ccAttach;
        //        if ( this.gateCc.endsWith( "/" ) ) {
        //            //Pending
        ////                        this.gateCc = this.gateCc.substring( 0, ( this.gateCc.length() - 1 ) );
        //        }

        // destination address details
        // destination address details
        this.address = this.resultDtos.branchAddress == null ? "" : this.resultDtos.branchAddress;

        this.addressSplit = this.address.split( "," );
        this.address1 = "";
        this.address2 = "";
        if ( this.addressSplit.length > 0 ) {
            this.address2 = this.addressSplit[this.addressSplit.length - 1] + ",";
            for ( let b = 0; b < this.addressSplit.length - 1; b++ ) {
                this.address1 += this.addressSplit[b] + ",";
            }

        } else {
            this.address1 = this.address;
        }
        this.city = this.resultDtos.branchCity == null ? "" : this.resultDtos.branchCity;
        this.pinCode = this.resultDtos.branchPincode == null ? "" : this.resultDtos.branchPincode.toString();
        this.phone = this.resultDtos.branchPhNo == null ? "NA" : this.resultDtos.branchPhNo
            .toString();
        this.cityPin = this.city + " - " + this.pinCode;
        this.emailId = this.resultDtos.branchEmailId == null ? "NA" : this.resultDtos.branchEmailId;

        if ( this.resultDtos.multiArtSize != null ) {
            //Pending
            this.artSize = this.resultDtos.multiArtSizeForPrint;

            //            document.getElementById("multiArtPrint").innerHTML= this.resultDtos.multiArtSizeForPrint;
            //            this.artSize = "</br>VOLUME</br>" + this.artSize.replaceAll( "#", "</br>" );
            //            this.artSize = this.artSize.replaceAll( "_", " X " );

        }

        if ( this.resultDtos.chgWtLr != null && ( this.resultDtos.chgWtLr != 0 ) ) {
            if ( this.artSize == "" ) {
                this.artChgWt = this.resultDtos.chgWtLr + " Kgs";
            } else {
                this.artChgWt = "+" + this.resultDtos.chgWtLr + " Kgs";
            }
        }

        this.subCommodity = null;
        if ( this.resultDtos.subCommodity == "NA" ) {
            this.subCommodity = "";
        } else {
            this.subCommodity = " (" + this.resultDtos.subCommodity + ")";
//            console.log( this.resultDtos.subCommodity );
        }

        this.clerkName = null;
        if ( this.resultDtos.clerkName != null
            && this.resultDtos.clerkName == "" ) {
            this.clerkName = "";
        } else {
            this.clerkName = " / " + this.resultDtos.clerkName.toUpperCase();
        }
        this.message = null;
        if ( this.actualPrintMode != null && this.actualPrintMode == "duplicate" ) {
            this.printMode = " - DUPLICATE";
            this.message = "This is a duplicate copy, seal & signature required for authentication";
        } else {
            this.printMode = "";
            this.message = this.resultDtos.message;
        }


        if ( this.resultDtos.toPay > 0 ) {
            this.ViewToPay = true;
            this.ViewPaid = false;
        } else {
            this.ViewToPay = false;
            this.ViewPaid = true;
        }

        if ( ( this.resultDtos.lrEntryMode != null ) && ( this.resultDtos.lrEntryMode != 'localBookingTrip' ) ) {
            this.mainAddress = this.resultDtos.address == null ? "NA" : this.resultDtos.address;
//            console.log( 'Main address :' + this.mainAddress );
            this.mainMobileNo = ( this.resultDtos.mobileNum == null ? "NA" : this.resultDtos.mobileNum );
            this.mainEmailId = ( this.resultDtos.emailId == null ? "NA" : this.resultDtos.emailId );
            this.transportId = ( this.resultDtos.transportId == null ? "NA" : this.resultDtos.transportId );
            this.sourcePrint = this.resultDtos.source.toUpperCase();
            this.destinationPrint = this.resultDtos.destination.toUpperCase();
            this.entryByPrint = this.resultDtos.entryBy.toUpperCase();
//            console.log( 'entry Print :' + this.entryByPrint );

            if ( ( this.resultDtos.lrEntryCopyType != null ) && ( this.resultDtos.lrEntryCopyType == 'ALL' ) ) {
                this.showPrintForOtherBooking = true;
//                console.log( this.resultDtos.lrEntryCopyType );
            }
            // update in database

            //            if ( ( ( this.mainStationForPrint == 'LrEntryPrintAll For Single Station' ) && ( this.actualPrintMode == 'original' ) ) || ( ( this.mainStationForPrint == 'LrEntryPrintAll For Different Station' ) && ( this.actualPrintMode == 'duplicate' ) ) ) {
            //                if ( this.resultDtos.lrEntryCopyType = 'ALL' ) {
            //                    this.resultDtos.lrConsigneeCopy = true;
            //                    this.resultDtos.lrConsignorCopy = true;
            //                    this.resultDtos.lrLorryCopy = true;
            //                }
            //                this.updateLrCopyDetails();
            //            }
            if ( this.resultDtos.mainstation.toLowerCase() != null
                && ( ( this.resultDtos.mainstation.toLowerCase() == 'mumbai' && this.actualPrintMode == 'original' ) || ( ( this.resultDtos.mainstation.toLowerCase() == 'delhi' || this.resultDtos.mainstation.toLowerCase() == 'mumbai' ) && this.actualPrintMode == 'duplicate' ) ) ) {
                if ( this.resultDtos.lrEntryCopyType == 'ALL' ) {
                    this.resultDtos.lrConsigneeCopy = true;
                    this.resultDtos.lrConsignorCopy = true;
                    this.resultDtos.lrLorryCopy = true;
                }
                this.updateLrCopyDetails();
            }

        } else {
            this.showPrintForLocalBooking = true;
        }

    }
    // update in database
    updateLrCopyDetails = () => {
        this.masterReadService.updateLrCopyTypeDetails( this.resultDtos ).subscribe(
            ( response ) => {
                if ( response.length == 0 ) {
                    console.log( 'error' )
                } else {
                    console.log( 'done' )
                }
                this.changeDetectorRef.detectChanges();
            } ),
            ( error ) => console.log( error.json() ),
            () => console.log( 'done' );
    };

    //For Clearing the Print Fields
    clearPrintFields() {

        this.dataForPrint = '';
        this.resultDtos = new LRDto();
        this.showPrintForOtherBooking = false;
        this.showPrintForLocalBooking = false;
        this.actualPrintMode = null;
        this.totPaid = '';
        this.totToPay = '';
        this.artSize = '';
        this.gatePass = '';
        this.ccAttach = '';
        this.eSugam = '';
        this.gateCcESugam = '';
        this.artChgWt = '';
        this.handleWitCare = '';
        this.toBeBilled = '';
        this.toBeBilled1 = '';
        this.unloadByCsn = '';
        this.eDecCom = '';
        this.gateCc = '';
        this.selfLr = '';
        this.showSelfLr = false;

        this.address = '';
        this.addressSplit = [];
        this.address1 = '';
        this.address2 = '';
        this.city = '';
        this.pinCode = '';
        this.phone = '';
        this.cityPin = '';
        this.emailId = '';
        this.subCommodity = '';
        this.clerkName = '';
        this.message = '';
        this.printMode = '';
        //
        this.mainAddress = '';
        this.mainMobileNo = '';
        this.mainEmailId = '';
        this.transportId = '';
        this.sourcePrint = '';
        this.destinationPrint = '';
        this.entryByPrint = '';
        this.slash = '';
        this.slashInCode = '';

        this.ViewToPay = false;
        this.ViewPaid = false;
//        console.log( 'Its Cleared' );

    }
}
