<!-- Row -->
<div class="row" id="{{pageId}}labourStmtRptMainPage">
    <div class="col-lg-12">
        <div class="row system_responsive">
            <!-- spinner start-->
            <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
                <div class="form-group">
                    <div class="input-group">
                        <mat-progress-bar mode="indeterminate" style="color: green;">
                        </mat-progress-bar>
                        <br>
                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                            Please Wait Loading Details.....</h6>
                    </div>
                </div>
            </div>
            <!-- spinner end-->
            <div class="row">
                <div class="col-md-3">
                    <div class="col-lg-12">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Report Mode</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-weight"></i>
														</span>
                                    </div>
                                    <select class="custom-select col-12" id="{{pageId}}reportModeType" #reportModeType (change)="reportModeTypeListner(reportModeType.value)">
														<option selected value="StatementNumber">Statement Number
														</option>
														<option value="DateWise">Date Wise</option>
													</select>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12" *ngIf="viewStmtNoField">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Statement Number</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
														</span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}statementNumber" aria-describedby="basic-addon11" autocomplete="off">
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12" *ngIf="viewDateWiseField">
                            <div class="control">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Loader Head</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
                                        </div>
                                        <input #labourHeadNameDropDown id="{{pageId}}dropDownInputFieldLabourHeadName" type="text" class="form-control" [(ngModel)]="modelDropDownLabourHeadName" (selectItem)="clickListnerForLoaderName($event)" [ngbTypeahead]="searchDropDownInputFieldLabourHeadName"
                                            [resultFormatter]="formatterDropDownLabourHeadName" [inputFormatter]="formatterDropDownLabourHeadName" placeholder="Select Loader Head" (focus)="focusDropDownLabourHeadNameTA$.next($any($event).target.value)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12" *ngIf="viewDateWiseField">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>From Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker" id="{{pageId}}fromDates" [(ngModel)]="modelFromDate">
                                    <div class="input-group-append" (click)="fromDates.toggle()">
                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12" *ngIf="viewDateWiseField">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>To Date</label> <input id="{{pageId}}toDates" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker" [(ngModel)]="modelToDate">
                                    <div class="input-group-append" (click)="toDates.toggle()">
                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
														</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="validateSearchBtn();">Search</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="validateClearBtn();">Clear</button>
                    </div>
                </div>

                <div class="col-md-3 vl p-t-10">
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Stmt No</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i
                                                class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}destination" aria-describedby="basic-addon11" readonly autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Stmt Amount</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i
                                                class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}amount" aria-describedby="basic-addon11" readonly autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Paying Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="payingDates" ngbDatepicker #payingDates="ngbDatepicker" id="{{pageId}}payingDates" [(ngModel)]="modelPayingDates">
                                    <div class="input-group-append" (click)="payingDates.toggle()">
                                        <span class="input-group-text"> <i
                                                class="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Payment Mode</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i
                                                                class="fas fa-weight"></i>
                                                        </span>
                                    </div>
                                    <select class="custom-select col-12" id="{{pageId}}payemntModeType" #payemntModeType (change)="payemntModeTypeListner(payemntModeType.value)">
                                                        <option value="Cash">Cash
                                                        </option>
                                                        <option selected  value="Cheque">Cheque</option>
                                                        <option value="PDC">PDC</option>
                                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12" *ngIf="viewChequePdcNoField">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Cheque No</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i
                                                                class="fas fa-file-alt"></i>
                                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}chequePdcNo" aria-describedby="basic-addon11" autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12" *ngIf="viewChequeDueDateField">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Cheque Due Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="chequeDueDates" ngbDatepicker #chequeDueDates="ngbDatepicker" id="{{pageId}}chequeDueDates" [(ngModel)]="modelChequeDueDates">
                                    <div class="input-group-append" (click)="chequeDueDates.toggle()">
                                        <span class="input-group-text"> <i
                                                                class="fa fa-calendar"></i>
                                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12" *ngIf="viewChequePdcBankNameField">
                            <div class="control">
                                <div class="form-group">
                                    <div class="input-group">
                                        <label>Bank Name</label>
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <i
                                                                    class="fas fa-user"></i>
                                                            </span>
                                        </div>
                                        <input #bankName id="{{pageId}}bankNameId" type="text" class="form-control" (selectItem)="clickListnerForBankName($event)" [(ngModel)]="modelBankName" [ngbTypeahead]="searchBankName" [resultFormatter]="formatterBankName" [inputFormatter]="formatterBankName"
                                            (focus)="focusBankNameTA$.next($any($event).target.value)" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12" *ngIf="viewCashChequePdcAmtField">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Amount</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i
                                                                class="fas fa-file-alt"></i>
                                                        </span>
                                    </div>
                                    <input type="number" class="form-control" id="{{pageId}}cashChequePdcAmount" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressListnerForAmount($event);">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 vl p-t-10">
                    <div class="col-sm-12 col-md-12">
                        <table datatable id="{{pageId}}bkgAgentStmtCashChequePdcTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsBkgAgentStmtCashChequePdc" [dtTrigger]="dtTriggerBkgAgentStmtCashChequePdc">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Chq No</th>
                                    <th>Amount</th>
                                    <th>Bank Name</th>
                                    <th>Due Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let bkgAgentStmtCashChequePdcData of 
                            bkgAgentStmtCashChequePdcDataList let i = index">
                                    <td>
                                        {{ bkgAgentStmtCashChequePdcData.paymentType }}</td>
                                    <td>{{ bkgAgentStmtCashChequePdcData.chequeNumber }}</td>
                                    <td>{{ bkgAgentStmtCashChequePdcData.amount }}</td>
                                    <td>{{ bkgAgentStmtCashChequePdcData.bankName }}</td>
                                    <td>{{ bkgAgentStmtCashChequePdcData.pdcDueDate }}</td>
                                    <td>
                                        <i style=" cursor: pointer;" class="fas fa-pencil-alt" title="Edit Bank Details" (click)="rowSelectedBankDataOnEdit(bkgAgentStmtCashChequePdcData,i);"></i> &nbsp;&nbsp;
                                        <i style=" cursor: pointer;" class="fas fa-trash" title="Delete Bank Details" (click)="deleteSelectedBankDetails(i);"></i>
                                    </td>

                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div class="col-md-3 vl p-t-10">
                    <div class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Total</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i
                                            class="fas fa-file-alt"></i>
                                    </span>
                                </div>
                                <input type="text" class="form-control" id="{{pageId}}totalAmountToBePaid" aria-describedby="basic-addon11" readonly autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Cash</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i
                                            class="fas fa-file-alt"></i>
                                    </span>
                                </div>
                                <input type="text" class="form-control" id="{{pageId}}summaryCashAmt" aria-describedby="basic-addon11" readonly autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Cheque</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i
                                            class="fas fa-file-alt"></i>
                                    </span>
                                </div>
                                <input type="text" class="form-control" id="{{pageId}}summaryChequeAmt" aria-describedby="basic-addon11" readonly autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>PDC</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i
                                            class="fas fa-file-alt"></i>
                                    </span>
                                </div>
                                <input type="text" class="form-control" id="{{pageId}}summaryPDCAmt" aria-describedby="basic-addon11" readonly autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label>Balance</label>
                                <div class="input-group-prepend">
                                    <span class="input-group-text"> <i
                                            class="fas fa-file-alt"></i>
                                    </span>
                                </div>
                                <input type="text" class="form-control" id="{{pageId}}balanceToBePaid" aria-describedby="basic-addon11" readonly autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                        <button type="submit" class="btn btn-dark" style='float:right;' title="Clear Bank Details" (click)="overAllClearFieldBtn();">Clear
                        </button> &nbsp;&nbsp;
                        <button type="submit" class="btn btn-success" title="Confirm Payment Details" style='float:right;' (click)="validateConfirmPaymentBtn();">Confirm
                        </button>
                    </div>
                </div>
            </div>

            <div class="card" style="margin-left: 10px;margin-right: 10px;">
                <div class="col-md-12">
                    <div class="col-md-12" style="margin-left: 1.3%;">
                        <button type="submit" style="float: right;" class="dt-button" (click)="customPrintLabourStmtRpt()" id="{{pageId}}printAllBtn">
											<span><i class="fas fa-print">Print</i></span>
										</button>
                    </div>
                    <table datatable id="{{pageId}}labourStmtRptTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLabourStmtReport" [dtTrigger]="dtTriggerLabourStmtReport">
                        <thead>
                            <tr>
                                <th [hidden]=true>Loader Head Name</th>
                                <th>Action</th>
                                <th>Statement No</th>
                                <th>Stmt Date</th>
                                <th>From Period</th>
                                <th>To Period</th>
                                <th>Tot LRs Unloading</th>
                                <th>Tot Act Wt Unloading</th>
                                <th>Tot Kanta Wt Unloading</th>
                                <th>Payable Amt</th>
                                <th>D.O.P</th>
                                <th>Cash</th>
                                <th>Cheque</th>
                                <th>PDC</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let labourStmtReportData of labourStmtReportDataList ">
                                <td [hidden]=true>{{ labourStmtReportData.loaderHead }}</td>
                                <td *ngIf="labourStmtReportData.amtPaid == true"><button style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}confirmPaymentBtn" (click)="setLabourPaymentValues(labourStmtReportData);">
											<i title="Confirm Payment" class="fas fa-edit"></i>
										</button>
                                    <button style="padding: 1px 0px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}voucherPrintBtn" (click)="setPrintVoucher(labourStmtReportData);">
											<i title="Print Voucher" class="fas fa-print"></i>
										</button></td>
                                <td *ngIf="labourStmtReportData.amtPaid == false">
                                    <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}paymentBtn" (click)="setLabourPaymentValues(labourStmtReportData);">
												<i title="Confirm Payment" class="fa fa-credit-card"></i>
											</button>
                                </td>
                                <td style='cursor:pointer;color: blue;' (click)="getStmtNoDetails(labourStmtReportData);">
                                    {{ labourStmtReportData.stmtNo }}</td>
                                <td>{{ labourStmtReportData.stmtDateStr }}</td>
                                <td>{{ labourStmtReportData.fromDate }}</td>
                                <td>{{ labourStmtReportData.toDate }}</td>
                                <td style='cursor:pointer;color: blue;' (click)="getTotalLrsDetails(labourStmtReportData);">
                                    {{ labourStmtReportData.totalLrs }}
                                </td>
                                <td>{{ labourStmtReportData.totalActWgt }}</td>
                                <td>{{ labourStmtReportData.totalKantaWgt }}</td>
                                <td>{{ labourStmtReportData.amtToBeReceived }}</td>
                                <td>{{ labourStmtReportData.dateOfPaymentStr }}</td>
                                <td>{{ labourStmtReportData.cashAmt }}</td>
                                <td>{{ labourStmtReportData.totChequeAmt }}</td>
                                <td>{{ labourStmtReportData.totPdcAmt }}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td [hidden]=true></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>

<div *ngIf="viewLabourHamaliLoadUnLoadDetlsPrint" onafterprint="afterPrint()" id="{{pageId}}printLabourHamaliLoadUnloadId" #printForShowStmtRptId>
    <app-loading-unloading-hamali-details-print></app-loading-unloading-hamali-details-print>
</div>
<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>

<div *ngIf="viewPaymentVoucherPrint" onafterprint="afterPrint()" id="{{pageId}}viewPaymentVoucherRptV1">
    <app-payment-voucher-loading-unloading-print></app-payment-voucher-loading-unloading-print>
</div>
<div *ngIf="viewPaymentVoucherUnloadingPrint" onafterprint="afterPrint()" id="{{pageId}}viewPaymentVoucherRptUnloading">
    <app-payment-voucher-loading-unloading-print></app-payment-voucher-loading-unloading-print>
</div>