import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { Subject } from 'rxjs';
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { Router } from "@angular/router";

@Component({
    selector: 'app-bank',
    templateUrl: './bank.component.html',
    styleUrls: ['./bank.component.css']
})
export class BankComponent implements OnInit {
    bankMasterDataList: any;
    chequeDetailsDataList: Array<any> = [];
    pdcDetailsDataList: any;
    userDataDto: UserDataDto = new UserDataDto();
    userDataDtoDelete: UserDataDto = new UserDataDto();
    lrDto: LRDto = new LRDto();
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    userDataDtoSaveRet: any;
    userDataDtoDeleteRet: any;
    lrDtoSaveRet: any;
    indexIdForCheck = null;
    indexIdForPdc = null;

    undersCodeSplit: any;
    chequeHashSplit: any;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerBankMaster: Subject<any> = new Subject();
    dtTriggerChequeDetails: Subject<any> = new Subject();
    dtTriggerPDCDetails: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsBankMaster: any;
    dtOptionsChequeDetails: any;
    dtOptionsPDCDetails: any;
    selectedBankId = 0;
    pageId = "bkct";

    constructor(private masterService: MasterService, private masterReadService: MasterReadService,
        private router: Router, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getBankDetailsList();
            this.bankMasterRead();
            this.chequeDetailsTable();
            this.pdcDetailsTable();
            this.getBankPdcDetailsList();
        }
    }
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnInit(): void {

    }

    bankMasterRead() {
        this.dtOptionsBankMaster = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 270,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var total = api
                    .column(1)
                    .data()
                    .reduce(function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Total over this page
                var pageTotal = api
                    .column(1, { page: 'current' })
                    .data()
                    .reduce(function (a, b) {
                        return intVal(a) + intVal(b)
                    }, 0);

                // Update footer
                $(api.column(1).footer()).html(
                    pageTotal
                );

                var pageTotals = api
                    .column(2, { page: 'current' })
                    .data()
                    .reduce(function (a, b) {
                        return intVal(a) + intVal(b)
                    }, 0);
                $(api.column(2).footer()).html(
                    pageTotals
                );

                var pageTotals = api
                    .column(3, { page: 'current' })
                    .data()
                    .reduce(function (a, b) {
                        return intVal(a) + intVal(b)
                    }, 0);
                $(api.column(3).footer()).html(
                    pageTotals
                );

                var pageTotals = api
                    .column(4, { page: 'current' })
                    .data()
                    .reduce(function (a, b) {
                        return intVal(a) + intVal(b)
                    }, 0);
                $(api.column(4).footer()).html(
                    pageTotals
                );
                //                count starts
                var columnData = api
                    .column(
                        0)
                    .data();

                // Update footer
                $(api.column(0).footer()).html(
                    columnData.count()
                );
                //                counts ends
            }

        }
    }
    chequeDetailsTable() {
        this.dtOptionsChequeDetails = {
            dom: 'Bfrtip',
            buttons: [

            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 150,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
        }
    }
    pdcDetailsTable() {
        this.dtOptionsPDCDetails = {
            dom: 'Bfrtip',
            buttons: [

            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 100,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerBankMaster.unsubscribe();
        this.dtTriggerChequeDetails.unsubscribe();
        this.dtTriggerPDCDetails.unsubscribe();
    }

    getDetailsForBankMasterRead() {
        this.userDataDto.office = this.userDataDtoReturnSession.mainStation;
        this.userDataDto.status = "Working";
    }

    getBankDetailsList = () => {
        this.getDetailsForBankMasterRead();
        this.masterReadService.getBankMasterDetails(this.userDataDto).subscribe(
            (response) => {
                if (response) {
                    this.bankMasterDataList = response;
                    this.dtTriggerBankMaster.next();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Bank Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    //to insert value of selected row in bank master table to input field starts
    rowSelected(bankMasterData) {
        this.userDataDto.bankName = bankMasterData.bankName;
        this.userDataDto.paymentAmt = bankMasterData.paymentAmt;
        this.userDataDto.chequeStr = bankMasterData.chequeStr;
        this.userDataDto.pdcStr = bankMasterData.pdcStr;
        this.userDataDto.proviStr = bankMasterData.proviStr;
        this.selectedBankId = bankMasterData.bankId;
        //        console.log('On Select :'+this.selectedBankId);

        this.chequeDetailsDataList = [];
        if (this.userDataDto.chequeStr != null && this.userDataDto.chequeStr != "NA") {
            let array = this.userDataDto.chequeStr.split("#");
            //            console.log( array );
            //            for ( let i = 1; i < 100; i++ ) {
            //                console.log( i );
            //            }
            //            for ( let i = 0; i < this.chqStr.length; i++ ) {
            for (let item in array) {
                //                console.log( this.chqStr ); // prints elements: 10, 20, 30, 40
                //                console.log( index[1] ); // prints indexes: 0, 1, 2, 3
                this.undersCodeSplit = array[item].split("_");
                //                console.log( this.undersCodeSplit );
                var newAttributeCheque: any = {};
                newAttributeCheque.chqType = "Cheque";
                newAttributeCheque.chqNumber = this.undersCodeSplit[0];
                newAttributeCheque.chqAmt = this.undersCodeSplit[1];
                //                console.log( "1st>> " + newAttributeCheque.chqType + "," + newAttributeCheque.chqNumber + "," + newAttributeCheque.chqAmt );
                this.chequeDetailsDataList.push(newAttributeCheque);
            }
        }

        if (this.userDataDto.pdcStr != null && this.userDataDto.pdcStr != "NA") {
            let array = this.userDataDto.pdcStr.split("#");
            for (let item in array) {
                this.undersCodeSplit = array[item].split("_");
                var newAttributePdc: any = {};
                newAttributePdc.chqType = "PDC";
                newAttributePdc.chqNumber = this.undersCodeSplit[0];
                newAttributePdc.chqAmt = this.undersCodeSplit[1];
                this.chequeDetailsDataList.push(newAttributePdc);
            }

        }

        if (this.userDataDto.proviStr != null && this.userDataDto.proviStr != "NA") {

            let array = this.userDataDto.proviStr.split("#");
            for (let item in array) {
                this.undersCodeSplit = array[item].split("_");
                var newAttributeProvisonChq: any = {};
                newAttributeProvisonChq.chqType = "Provision Cheque";
                newAttributeProvisonChq.chqNumber = this.undersCodeSplit[0];
                newAttributeProvisonChq.chqAmt = this.undersCodeSplit[1];
                this.chequeDetailsDataList.push(newAttributeProvisonChq);
            }

        }
        this.dtTriggerChequeDetails.next();
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    getBankPdcDetailsRead() {
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;

    }

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }

    getBankPdcDetailsList = () => {
        this.getBankPdcDetailsRead();
        this.masterReadService.getBankMasterPdcDetails(this.lrDto).subscribe(
            (response) => {
                if (response) {
                    this.pdcDetailsDataList = response;
                    this.dtTriggerPDCDetails.next();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Bank Master PDC Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    validateBankDetails() {
        this.saveUpdateBankDetails();
    }
    saveBankDetails() {
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.createdBy = this.userDataDtoReturnSession.userId;
        this.userDataDto.office = this.userDataDtoReturnSession.mainStation;
        this.userDataDto.listChqDetails = this.chequeDetailsDataList;
        this.userDataDto.bankId = this.selectedBankId;
    }
    saveUpdateBankDetails = () => {
        this.saveBankDetails();
        this.masterService.createBankMaster(this.userDataDto).
            subscribe((data) => {
                this.userDataDtoSaveRet = data;
                if (this.userDataDtoSaveRet.status == "persisted") {
                    this.saveUpdateBankPdcDetails();
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Saving Bank Master Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Saving Bank Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    clearAll() {
        $('input[type="text"],[type="number"]').val('');
        $("#" + this.pageId + "bankName").val('');
        $("#" + this.pageId + "paymentAmt").val('');
        this.selectedBankId = 0;
        this.chequeDetailsDataList = [];
    }
    addInTableCheck() {
        var newAttributeSaveCheck: any = {};
        newAttributeSaveCheck.chqType = $("#" + this.pageId + "chequeDetails").val();
        newAttributeSaveCheck.chqNumber = $("#" + this.pageId + "chequeNo").val();
        newAttributeSaveCheck.chqAmt = $("#" + this.pageId + "chequeAmt").val();

        if ((newAttributeSaveCheck.chqType != '') && (newAttributeSaveCheck.chqType != null) && (newAttributeSaveCheck.chqNumber != '') && (newAttributeSaveCheck.chqNumber != null) && (newAttributeSaveCheck.chqAmt != '') && (newAttributeSaveCheck.chqAmt != null)) {
            if (this.indexIdForCheck != null) {
                this.chequeDetailsDataList.splice(this.indexIdForCheck, 1);
            }
            if (this.chequeDetailsDataList == null) {
                this.chequeDetailsDataList = [];
            }

            if (this.chequeDetailsDataList.length == 0) {
                this.chequeDetailsDataList.push(newAttributeSaveCheck);
            } else {

                let array = this.chequeDetailsDataList;
                this.chequeDetailsDataList.push(newAttributeSaveCheck);

            }
            newAttributeSaveCheck = {};
            $("#" + this.pageId + "chequeDetails").val('');
            $("#" + this.pageId + "chequeNo").val('');
            $("#" + this.pageId + "chequeAmt").val('');
            $("#" + this.pageId + "hiddenIndexCheck").val('');
            this.indexIdForCheck = null;
        } else {
            swal({
                title: "Warning",
                text: "Please Enter the Mandatory Fields",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        }

    }

    rowSelectedDeleteCheck() {
        this.indexIdForCheck = $("#" + this.pageId + "hiddenIndexCheck").val();
        this.chequeDetailsDataList.splice(this.indexIdForCheck, 1);
        $("#" + this.pageId + "chequeDetails").val('');
        $("#" + this.pageId + "chequeNo").val('');
        $("#" + this.pageId + "chequeAmt").val('');
        $("#" + this.pageId + "hiddenIndexCheck").val('');
        this.indexIdForCheck = null;
    }

    chqRowSelected(chequeDetailsData, index) {
        $("#" + this.pageId + "chequeDetails").val(chequeDetailsData.chqType);
        //        this.userDataDto.chqType = chequeDetailsData.chqType;
        this.userDataDto.chqNumber = chequeDetailsData.chqNumber;
        this.userDataDto.chqAmt = chequeDetailsData.chqAmt;
        $("#" + this.pageId + "hiddenIndexCheck").val(index);
        this.indexIdForCheck = index;
        //to insert value of selected row in table to input field ends

    }

    addInTablePdc() {
        var newAttributeSavePdc: any = {};
        newAttributeSavePdc.source = $("#" + this.pageId + "pdcType").val();
        newAttributeSavePdc.billNumber = $("#" + this.pageId + "pdcChequeNo").val();
        newAttributeSavePdc.paid = $("#" + this.pageId + "pdcAmount").val();

        if ((newAttributeSavePdc.source != '') && (newAttributeSavePdc.source != null) && (newAttributeSavePdc.billNumber != '') && (newAttributeSavePdc.billNumber != null) && (newAttributeSavePdc.paid != '') && (newAttributeSavePdc.paid != null)) {
            if (this.indexIdForPdc != null) {
                this.pdcDetailsDataList.splice(this.indexIdForPdc, 1);
            }
            if (this.pdcDetailsDataList == null) {
                this.pdcDetailsDataList = [];
            }

            if (this.chequeDetailsDataList.length == 0) {
                this.pdcDetailsDataList.push(newAttributeSavePdc);
            } else {

                let array = this.chequeDetailsDataList;
                this.pdcDetailsDataList.push(newAttributeSavePdc);

            }
            newAttributeSavePdc = {};
            $("#" + this.pageId + "pdcType").val('');
            $("#" + this.pageId + "pdcChequeNo").val('');
            $("#" + this.pageId + "pdcAmount").val('');
            $("#" + this.pageId + "hiddenIndexPdc").val('');
            this.indexIdForPdc = null;
        } else {
            swal({
                title: "Warning",
                text: "Please Enter the Mandatory Fields1",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        }

    }

    rowSelectedDeletePdc() {
        this.indexIdForPdc = $("#" + this.pageId + "hiddenIndexPdc").val();
        this.pdcDetailsDataList.splice(this.indexIdForPdc, 1);
        $("#" + this.pageId + "pdcType").val('');
        $("#" + this.pageId + "pdcChequeNo").val('');
        $("#" + this.pageId + "pdcAmount").val('');
        $("#" + this.pageId + "hiddenIndexPdc").val('');
        this.indexIdForPdc = null;
    }



    pdcRowSelected(pdcDetailsData, index) {
        $("#" + this.pageId + "pdcType").val(pdcDetailsData.source);
        //        this.userDataDto.pdcType = pdcDetailsData.source;
        this.userDataDto.pdcChequeNo = pdcDetailsData.billNumber;
        this.userDataDto.pdcAmount = pdcDetailsData.paid;
        $("#" + this.pageId + "hiddenIndexPdc").val(index);
        this.indexIdForPdc = index;
        //to insert value of selected row in table to input field ends

    }


    saveBankPdcDetails() {
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.userName = this.userDataDtoReturnSession.userId;
        this.lrDto.listPdcDetails = this.pdcDetailsDataList;
    }
    saveUpdateBankPdcDetails = () => {
        this.saveBankPdcDetails();
        this.masterService.createBankMasterPdcDetails(this.lrDto).
            subscribe((data) => {
                this.lrDtoSaveRet = data;
                if (this.lrDtoSaveRet.status == "success") {
                    swal({
                        title: "Success",
                        text: "Bank Master Details Saved/Updated Successfull",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Saving Bank Master  PDC Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Saving Bank Master PDC  Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    checkClearenceValidate() {

        var newAttributeCC: any = {};
        newAttributeCC.chqType = $("#" + this.pageId + "chequeDetails").val();
        //        console.log(newAttributeCC.chqType);
        if (newAttributeCC.chqType != null && newAttributeCC.chqType != '') {
            this.clearAllCheck();
            this.chequeClearenceDetails();
            newAttributeCC = {};
        } else {
            swal({
                title: "Warning",
                text: "Please select the check Type",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }


    }

    getChequeClearenceDetails() {
        var newAttributeForCC: any = {};
        newAttributeForCC.chqType = $("#" + this.pageId + "chequeDetails").val();
        newAttributeForCC.chqAmt = $("#" + this.pageId + "chequeAmt").val();
        //        console.log(newAttributeForCC.chqAmt );
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.createdBy = this.userDataDtoReturnSession.userId;
        this.userDataDto.office = this.userDataDtoReturnSession.mainStation;
        this.userDataDto.listChqDetails = this.chequeDetailsDataList;
        this.userDataDto.bankId = this.selectedBankId;
        this.userDataDto.amount = newAttributeForCC.chqAmt;
        this.userDataDto.type = newAttributeForCC.chqType;
        newAttributeForCC = {};
        $("#" + this.pageId + "chequeDetails").val('');
        $("#" + this.pageId + "chequeNo").val('');
        $("#" + this.pageId + "chequeAmt").val('');
        $("#" + this.pageId + "hiddenIndexCheck").val('');
        this.indexIdForCheck = null;
    }
    chequeClearenceDetails = () => {
        this.getChequeClearenceDetails();
        this.masterService.chequeClearenceDetails(this.userDataDto).
            subscribe((data) => {
                this.lrDtoSaveRet = data;
                if (this.lrDtoSaveRet.status == "success") {
                    swal({
                        title: "Success",
                        text: "Cheque Clearence Details Updated Successfull",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else if (this.lrDtoSaveRet.status == "norecord") {
                    swal({
                        title: "No record Found",
                        text: "Error While Updating Cheque Clearence Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Updating Cheque Clearence Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Updating Cheque Clearence Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };


    clearAllCheck() {
        this.indexIdForCheck = $("#" + this.pageId + "hiddenIndexCheck").val();
        this.chequeDetailsDataList.splice(this.indexIdForCheck, 1);
    }

    validateDeleteRow() {
        this.deleteBankMasterDetails();
    }

    getDeleteBankMasterDetails() {
        this.userDataDtoDelete.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDtoDelete.userId = this.userDataDtoReturnSession.userId;
        this.userDataDtoDelete.bankId = this.selectedBankId;
        //        console.log('On Delete :'+this.userDataDtoDelete.bankId);
    }

    deleteBankMasterDetails = () => {
        this.getDeleteBankMasterDetails();
        this.masterService.deleteBankMaster(this.userDataDtoDelete).
            subscribe((data) => {
                this.userDataDtoDeleteRet = data;
                if (this.userDataDtoDeleteRet.status == "updated") {
                    swal({
                        title: "Success",
                        text: "Bank  Details Removed Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.clearAll();
                    //                    this.getBankDetailsList();
                } else {
                    swal({
                        title: "Error",
                        text: "Error While Deleting Bank Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Deleting Bank Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };


}

