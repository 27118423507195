import {
  Component,
  Injector,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Injectable,
  OnInit
} from "@angular/core";
import {
  ActivatedRoute,
  Router,
  RoutesRecognized,
  Route
} from "@angular/router";
export interface Tab {
  name: string;
  component: any;
  active: boolean;
  route: Route;
  key: string;
  count: BehaviorSubject<number>;
}

import { BehaviorSubject } from "rxjs";
@Injectable()
export class CurrentTabInjector {
  public currentTab: Tab;
}



@Component({
  selector: 'app-tabqueue',
  templateUrl: './tabqueue.component.html',
  styleUrls: ['./tabqueue.component.css']
})
export class TabqueueComponent implements OnInit {
  public tabs: Tab[] = [];
  public routes: Route[] = [];
  public currentHoverTabKey: string;
  public currentDraggedTab: Tab;
  lastRedirect = '';
  // public tabInjectors: TabInjector[] = [];
pageId="tqc";
  myInjector: Injector;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {
    // listen to routing change event to attach new tabs or activate a new one
    this.router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        this.checkAndAddRouteTab(val);
      }
    });
  }

  ngOnInit() {
    // get all routes to mock a navigation
    this.routes = this.router.config;
  }

  disposeTab(tab: Tab) {
    if (this.tabs.length > 1) {
      this.tabs = this.tabs.filter(item => item.key !== tab.key);

      if (tab.active) {
        // deactivate all tabs
        this.deactivateTabs();
        this.router.navigateByUrl(this.tabs[this.tabs.length - 1].route.path);
      }
    }
  }

  mouseOverTab(tab: Tab) {
    this.currentHoverTabKey = tab ? tab.key : null;
  }

  checkAndAddRouteTab(val: RoutesRecognized) {
    const comp = val.state.root.firstChild.routeConfig;
    this.deactivateTabs();
    const url = val.url;
    const urlAfterRedirects = val.urlAfterRedirects;
    const path = val.state.root.firstChild.routeConfig.path;
    console.log(url, urlAfterRedirects, path);
    if (path !== "authentication" && url !== '/' && urlAfterRedirects !== '/authentication/login' && comp) {
        if (this.tabs.find(tab => tab.name == comp["name"]) == null) {
            this.tabs.push({
                name: comp["path"],
                component: comp,
                key: comp["path"],
                active: true,
                route: val.state.root.firstChild.routeConfig,
                count: new BehaviorSubject<number>(0)
            });
        } else {
            const tabToActivate = this.tabs.find(tab => tab.name == comp["name"]);
            if (tabToActivate) {
                tabToActivate.active = true;
            }
        }
        this.lastRedirect = urlAfterRedirects;
    } else {
        if(urlAfterRedirects !== '/authentication/404')
        this.tabs = [];
        else if(this.lastRedirect !== null && this.lastRedirect !== ''){
            const tabToActivate = this.tabs.find(tab => tab.route.path == this.lastRedirect.substring(1));
            console.log(tabToActivate);
            if (tabToActivate) {
                tabToActivate.active = true;
                this.router.navigateByUrl(this.lastRedirect);
            }
               
        }
    }
    this.cd.markForCheck();
}

  deactivateTabs() {
    this.tabs.forEach(tab => (tab.active = false));
  }

  // getTabInjector(tabKey: string): Injector {
  //   return this.tabInjectors.find(tab => tab.tabKey == tabKey).injector;
  // }
}
