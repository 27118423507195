<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">FORTNIGHT DETAILS</h6> -->
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Statement No :</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" #statementNo id="{{pageId}}statementNo" aria-describedby="basic-addon11" (keyup)="checkStmtNo($event)">


                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}serviceCharge">
														<label></label>

														<button type="submit" class="btn btn-success s-r-10"
															id="{{pageId}}generateBtn" disabled>Go</button>
													</div>
												</div>
											</div> -->
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Branch</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-road"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}branch" aria-describedby="basic-addon11" readonly>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Period</label> <input class="form-control" placeholder="yyyy-mm-dd" name="fromPeriods" ngbDatepicker #fromPeriods="ngbDatepicker" id="{{pageId}}fromPeriod" readonly>
                                                        <div class="input-group-append" (click)="fromPeriods.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Period</label> <input id="{{pageId}}toPeriod" class="form-control" placeholder="yyyy-mm-dd" name="toPeriods" ngbDatepicker #toPeriods="ngbDatepicker" readonly>
                                                        <div class="input-group-append" (click)="toPeriods.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Received Date</label> <input class="form-control" placeholder="yyyy-mm-dd" name="receivedDates" ngbDatepicker #receivedDates="ngbDatepicker" id="{{pageId}}receivedDate">
                                                        <div class="input-group-append" (click)="receivedDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <div class="card">
                            <div class="card-body">
                                <div class='row'>
                                    <!-- <div class="col-sm-4 col-md-4">
										<div class="control">
											<div class="form-group">
												<label>Debit Heads</label>
												<div class="input-group" id="{{pageId}}debitHeads">
													<input class="auto_selectOption_FullWidth input is-medium"
														placeholder="Select Agent" [formControl]="control"
														[appAutocomplete]="autocomplete">
												</div>
											</div>
										</div>

										<app-autocomplete #autocomplete="appAutocomplete">
										<ng-template appAutocompleteContent> <ng-container
											*ngIf="(debitHeadsOptions | filter: control.value) as resultDebitHeads">
										<app-option *ngFor="let option of resultDebitHeads"
											[value]="option.label"> {{ option.label }} </app-option> <app-option
											class="no-result" *ngIf="!resultDebitHeads.length">No
										result</app-option> </ng-container> </ng-template> </app-autocomplete>
									</div> -->
                                    <div class="col-sm-3 col-md-3">
                                        <div class="form-group">
                                            <label>Debit Head</label>
                                            <div class="input-group">
                                                <input id="{{pageId}}debitHead" type="text" class="form-control" [(ngModel)]="modelDebitHead" [ngbTypeahead]="searchDebitHead" (selectItem)="debitHeadListener($event)" [readonly]="readonlyDebitHead" [resultFormatter]="formatterDeebitHead" [inputFormatter]="formatterDeebitHead"
                                                    (keyup)="debitHeadFocus($event)" (focus)="focusDebitHeadTA$.next($any($event).target.value)" placeholder="Select Debit" />
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewOtherDebit" class="col-sm-3 col-md-3">
                                        <div class="form-group">
                                            <label>Sub Head</label>
                                            <div class="input-group">
                                                <input id="{{pageId}}subHead" type="text" class="form-control" [(ngModel)]="modelDebitHeadOthers" (selectItem)="debitSubHeadListener($event)" [readonly]="readonlyDebitSubHead" [ngbTypeahead]="searchDebitHeadOthers" [resultFormatter]="formatterDebitHeadOthers"
                                                    [inputFormatter]="formatterDebitHeadOthers" (keyup)="subHeadFocus($event)" (focus)="focusDebitHeadOthersTA$.next($any($event).target.value)" placeholder="Select Sub Head" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-md-4">
                                        <div class="form-group">
                                            <label>Description </label>
                                            <div class="input-group">
                                                <input type="text" id="{{pageId}}description" class="form-control" aria-describedby="basic-addon11" readonly>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-2 col-md-2">
                                        <div class="form-group">
                                            <label>Value </label>
                                            <div class="input-group">
                                                <input type="number" id="{{pageId}}value" class="form-control" aria-describedby="basic-addon11" (keyup)="fouckValueMtd($event)">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                </mat-progress-bar>
                                                <br>
                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                    Please Wait Loading Details.....</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-body">
                            <table datatable id="{{pageId}}debitHeadTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsDebitNoteStmt" [dtTrigger]="dtTriggerDebitNoteStmt">

                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Debit(INR)</th>
                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let debitNoteStmtData of debitNoteStmtDataList let i = index">
                                        <td>{{ debitNoteStmtData.description }}</td>
                                        <td>{{ debitNoteStmtData.debit }}</td>
                                        <td>
                                            <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableEditBtn" (click)="rowSelectedEdit(debitNoteStmtData,i);">
                                                <i title="Edit" class="fas fa-pencil-alt"></i>
                                            </button>&nbsp;
                                            <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDelete(debitNoteStmtData,i)">
                                                <i title="Remove" class="fas fa-trash"></i>
                                            </button>
                                        </td>


                                    </tr>


                                    <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>

                                    </tr>
                                </tfoot>
                            </table>


                        </div>
                        <div class='row'>
                            <hr style="width: 80%; border-top: none; margin: 3px;">
                            <div class="col-md-12" style="text-align: left;">
                                <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}finishBtn" (click)="finishBtnMtd()">Finish</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>
</body>

</html>