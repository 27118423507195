<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">
        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="row system_responsive" style="margin-bottom: 10px;">

                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-home"></i>
															</span>
                                                        </div>
                                                        <input #agentName id="{{pageId}}agentName" type="text" class="form-control" [(ngModel)]="agentNameModal" (selectItem)="clickListnerForSource($event)" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="agentNameSearchTA" [resultFormatter]="formatterAgentName"
                                                            [inputFormatter]="formatterAgentName" placeholder="Select Source" (focus)="focusAgentNameTA$.next($any($event).target.value)" #instanceAgentName="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div [hidden]="!showCftDestination" class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
                                                        </div>

                                                        <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="clickListnerForDestination($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Commodity &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-box"></i>
														</span>
                                                    </div>
                                                    <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                                                        [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <h6 class="card-title">CFT - Slabs Setting</h6>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}from">
                                                            <label class="wtfull">From Range</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}to">
                                                            <label class="wtfull">To Range</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6">
                                                    <div class="form-group">
                                                        <div class="input-group" id="{{pageId}}amount">
                                                            <label class="wtfull">Consider Value</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input type="number" id="{{pageId}}fromRange" name="fromRange" class="form-control" #fromRange (keyup)="fieldFocus($event, toRange)" aria-describedby="basic-addon11" [(ngModel)]="lrDtoSave.fromRange">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-3">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <input type="number" id="{{pageId}}toRange" name="toRange" class="form-control" #toRange (keyup)="fieldFocus($event, considerValue)" aria-describedby="basic-addon11" [(ngModel)]="lrDtoSave.toRange">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-5">
                                                    <div class="form-group">
                                                        <div class="input-group">

                                                            <input type="number" id="{{pageId}}considerValueField" name="considerValueField" class="form-control" aria-describedby="basic-addon11" #considerValue (keyup)="fieldFocus($event, addBtn)" [(ngModel)]="lrDtoSave.considerValue">&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-1">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <a href="javascript:;" type='button' id="{{pageId}}addInTable" #addBtn class="btn btn-icon-only yellow" (click)="addInTable();"> <i class="fa fa-plus"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="box-body">
                                                        <table datatable id="{{pageId}}slabsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCflSlabsSetup" [dtTrigger]="dtTriggerCflSlabsSetup">
                                                            <thead>
                                                                <tr>
                                                                    <th>From Range</th>
                                                                    <th>To Range</th>
                                                                    <th>Consider Value</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let slabSetupData of slabSetupDataList; let i = index">
                                                                    <td>{{ slabSetupData.fromRange }}</td>
                                                                    <td>{{ slabSetupData.toRange }}</td>
                                                                    <td>{{ slabSetupData.considerValue }}</td>
                                                                    <td>
                                                                        <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableEditBtn" (click)="rowSelectedEdit(slabSetupData,i);">
                                                                                <i title="Edit" class="fas fa-pencil-alt"></i>
                                                                            </button>&nbsp;
                                                                        <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDelete(i);">
                                                                                <i title="Remove" class="fas fa-trash"></i>
                                                                            </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr style="width: 95%;">
                                            <div class="col-md-12" style="text-align: center;">
                                                <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}saveBtn" #saveBtn (click)="validateSetupDetails()">Save</button>
                                                <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}clearBtn" (click)="clearDetails()">Clear</button>
                                                <button class="btn btn-danger" mat-button (click)="onCancelClick();">Close</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 vl">
                    <div class="row">
                        <div class="col-md-12 vl">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h6 class="card-title">CFT Details</h6>
                                            <div class="box-body">
                                                <table datatable id="{{pageId}}slabSetupDataAllTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCflSlabsAllSetup" [dtTrigger]="dtTriggerCflSlabsAllSetup">
                                                    <thead>
                                                        <tr>
                                                            <th>Source</th>
                                                            <th>Destination</th>
                                                            <th>Commodity</th>
                                                            <th>Slabs</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let slabSetupDataAll of slabSetupDataAllList; let i = index">
                                                            <td>{{ slabSetupDataAll.source }}</td>
                                                            <td>{{ slabSetupDataAll.destination }}</td>
                                                            <td>{{ slabSetupDataAll.commodityName }}</td>
                                                            <td><button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}editBtn" (click)="rowSelectedView(slabSetupDataAll);">
                                                                    <i title="View" class="fas fa-edit"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>