import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteModule } from 'src/app/autocomplete/autocomplete.module';
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { BankComponent } from 'src/app/master/fixing/bank/bank.component';
import { CollectionComponent } from 'src/app/master/fixing/collection/collection.component';
import { DebitNoteMasterComponent } from 'src/app/master/fixing/debit-note-master/debit-note-master.component';
import { ExpensesHeadsComponent } from 'src/app/master/fixing/expenses-heads/expenses-heads.component';
import { TdsDetailsEntryComponent } from 'src/app/master/fixing/tds-details-entry/tds-details-entry.component';
import { FovExemptionMasterComponent } from 'src/app/master/fixing/fov-exemption/fov-exemption.component';
import { MaterialModule } from "src/app/material.module";
import { CashmemoReportModule } from 'src/app/report/cashmemo-report/cashmemo-report.module';
import { CflFormulaSetupComponent } from './cfl-formula-setup/cfl-formula-setup.component';
import { CftExemptionMasterComponent } from './cft-exemption/cft-exemption.component';
import { AreaComponent } from 'src/app/master/fixing/area/area.component';
import { CflFormulaSetupSpecificComponent } from './cfl-formula-setup-specific/cfl-formula-setup-specific.component';
import { CflFormulaSetupConsignorComponent } from './cfl-formula-setup-consignor/cfl-formula-setup-consignor.component';
import { LrBookingDateCustomChangeComponent } from './lr-booking-date-custom-change/lr-booking-date-custom-change.component';
import { PerKgRateSetupComponent } from './per-kg-rate-setup/per-kg-rate-setup.component';
import { CommodityKgLimitSetupComponent } from './commodity-kg-limit-setup/commodity-kg-limit-setup.component';

@NgModule({
    imports: [CommonModule,
        // RouterModule.forChild(FixingRoutes), 
        FormsModule, NgbModule, DataTablesModule, MatAutocompleteModule, MatFormFieldModule, ReactiveFormsModule, MatInputModule, AutocompleteModule, DailyReportModule, MaterialModule, CashmemoReportModule],
    declarations: [
        BankComponent,
        CollectionComponent,
        DebitNoteMasterComponent,
        ExpensesHeadsComponent,
        TdsDetailsEntryComponent,
        FovExemptionMasterComponent,
        CflFormulaSetupComponent,
        CftExemptionMasterComponent,
        AreaComponent,
        CflFormulaSetupSpecificComponent,
        CflFormulaSetupConsignorComponent,
        LrBookingDateCustomChangeComponent,
        PerKgRateSetupComponent,
        CommodityKgLimitSetupComponent
    ]
})
export class FixingModule { }
