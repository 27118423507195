import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { MasterService } from 'src/app/dataService/master-service';
//from the particular global folder ends
//for datatable ends

//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

//for the select option with filter ends
//service starts
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from "@angular/router";
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import swal from 'sweetalert';
import { MasterReadService } from "src/app/dataService/masterread-service";
//service ends
@Component( {
    selector: 'app-truck-master',
    templateUrl: './agent-tempo-master.component.html',
    styleUrls: ['./agent-tempo-master.component.css']
} )
export class AgentTempoMasterComponent implements OnInit {
    @ViewChild( "pinCode" ) pinField: ElementRef;
    newTruck = false;
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    truckDataDto: TruckDataDto = new TruckDataDto();
    truckDataDtoSave: TruckDataDto = new TruckDataDto();
    truckDataCheckDto: TruckDataDto = new TruckDataDto();
    truckDataDtoCheckRet: any;
    truckDataDtoSaveRet: any;
    truckDataDeleteDto: TruckDataDto = new TruckDataDto();
    truckDataDtoDeleteRet: any;
    enteredTruckNumber: any;
    enteredTruckCapacity: any;
    setAgentTempo = 'new';
    saveOrEdit = 'save';
    enteredOwnerName: any;
    enteredPanNO: any;


    //for datatable starts
    //    gettingDataFrmServiceFrTruckMasterTable: any;


    agentTempoMasterDataList: any;



    //    onDestroyUnsubscribtionTruckMaster: Subscription;

    //for datatable ends

    loadingIndicator = true;

    //for datatable starts
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerAgentTempoMaster: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsAgentTempoMaster: any;

    //for datatable ends

    //for the select option with filter starts

    control = new FormControl();

    regStateOptions = [
        { id: 1, label: 'Assam' },
        { id: 2, label: 'Delhi' },
        { id: 3, label: 'Tamil Nadu' },
    ];

    //    truckTypeOptions = [
    //        { id: 1, label: '1 Ton' },
    //        { id: 2, label: '2 Ton' },
    //        { id: 3, label: '4 Ton' },
    //    ];
    //for the select option with filter ends
    pageId="agtmc";


    constructor(/* for datatable starts */private masterService: MasterService, public changeDetectorRef : ChangeDetectorRef, /* for datatable endss */
        //          service starts
        private masterReadService: MasterReadService,
        private http: HttpClient,
        private router: Router
        //            service ends        
    ) {
        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
            //            sweet alert ends
        }

        if ( this.isLoggedIn ) {
            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );

        }
    }
    pinfocus(): void {
        this.pinField.nativeElement.focus();
    }
    fieldFocus( e, el ) {
        if ( e.keyCode == 13 ) { // press A
            el.focus();
        }
    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
    /* for datatable ends */


    ngOnInit(): void {

        //for datatable starts
        this.dtOptionsAgentTempoMaster = {
            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
            ],

            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 280,
            "scrollCollapse": true,
            //pagination starts
            "paging": true,
            "info": true,
            "pagingType": 'full_numbers',
            "pageLength": 25,
            //pagination ends
            //scroll in datatable ends
        }
    }



    ngOnDestroy(): void {
        //for datatable starts
        this.dtTriggerAgentTempoMaster.unsubscribe();

        //        this.onDestroyUnsubscribtionTruckMaster.unsubscribe();
        //for datatable ends
    }

    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

    //  service starts
    getAgentTempoDetailsForRead() {
        this.truckDataDto = new TruckDataDto();
        this.truckDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
    }
    getAgentTempoMasterDetailsList = () => {
        this.getAgentTempoDetailsForRead();
        this.masterService.getAgentTempoDetails( this.truckDataDto ).subscribe(
            ( response ) => {
                if ( response ) {
                    this.agentTempoMasterDataList = response;
                    if ( this.setAgentTempo == 'new' ) {
                        this.dtTriggerAgentTempoMaster.next();
                        this.setAgentTempo = 'old';
                    }
                }
                this.changeDetectorRef.detectChanges();
            } ), ( error ) => swal( {
                title: "Error",
                text: "Server Error While Getting Truck Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };
    ngAfterViewInit() { }


    checkTempoNumber( e ) {
        this.enteredTruckNumber = $("#"+this.pageId+"truckNumber" ).val();
        if ( e.keyCode == 13 ) {
            if ( ( this.enteredTruckNumber != '' ) || ( this.enteredTruckNumber != null ) ) {
                this.tempoNumberChk();
            }
        }
    }
    tempoNumberChk = () => {
        this.enteredTruckNumber = $("#"+this.pageId+"truckNumber" ).val();
        this.truckDataCheckDto = new TruckDataDto();
        this.truckDataCheckDto.truckNumber = this.enteredTruckNumber;
        this.masterService.checkAgentTempoNumber( this.truckDataCheckDto ).subscribe(
            ( response ) => {
                this.truckDataDtoCheckRet = response;
                console.log( this.truckDataDtoCheckRet );
                if ( this.truckDataDtoCheckRet.status == 'exist' ) {

                    swal( {
                        title: "Alert",
                        text: "This Tempo Number: " + this.truckDataCheckDto.truckNumber
                        + " already exists",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } ).then(() => {
                        $("#"+this.pageId+"truckNumber" ).val( '' );
                        window.setTimeout( function() {
                            $( "#"+this.pageId+"truckNumber" ).focus();
                        }, 100 );
                    } );
                }
                this.changeDetectorRef.detectChanges();
            } ),
            ( error ) => console.log( error.json() ),
            () => console.log( 'done' );
    };
    validateAgentTempoDetails() {
        this.enteredTruckNumber = $("#"+this.pageId+"truckNumber" ).val();
        this.enteredTruckCapacity = $("#"+this.pageId+"truckCapacityInTones" ).val();

        if ( ( this.enteredTruckNumber == '' ) || ( this.enteredTruckNumber == null ) || ( this.enteredTruckCapacity == '' ) || ( this.enteredTruckCapacity == null ) ) {
            swal( {
                title: "Warning",
                text: "Please Enter the Mandetory Fields",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } );
        } else {
            this.enteredTruckNumber=this.enteredTruckNumber.replace(/\s/g,"");
            if ( this.enteredTruckNumber.length < 9 ) {
                swal( {
                    title: "Alert",
                    text: "The Entered Truck Number has less then nine latters",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                } ).then(() => {
                    window.setTimeout( function() {
                        $("#"+this.pageId+"truckNumber" ).focus();
                    }, 100 );
                } );
            } else {
                if (/\d/.test(this.enteredTruckNumber) && /[a-zA-Z]/.test(this.enteredTruckNumber)) {
                this.saveUpdateAgentTempoDetails();
                }else{
                    swal( {
                        title: "Alert",
                        text: "The Entered Truck Number is not valid, it should be Alphanumeric",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } ).then(() => {

                        window.setTimeout( function() {
                            $( "#"+this.pageId+"truckNumber" ).focus();
                        }, 100 );
                    } );
                }
            }
        }
    }
    getAgentTempoDetailsForSave() {
        this.enteredTruckNumber = $("#"+this.pageId+"truckNumber" ).val();
        this.enteredTruckNumber=this.enteredTruckNumber.replace(/\s/g,"");
        this.enteredTruckCapacity = $("#"+this.pageId+"truckCapacityInTones" ).val();
        this.enteredOwnerName = $("#"+this.pageId+"ownerName" ).val();
        this.enteredPanNO = $("#"+this.pageId+"panNumber" ).val();
        this.truckDataDtoSave = new TruckDataDto();
        this.truckDataDtoSave.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDtoSave.userId = this.userDataDtoReturnSession.userId;
        this.truckDataDtoSave.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.truckDataDtoSave.suppAdd = this.saveOrEdit;
        this.truckDataDtoSave.truckNumber = this.enteredTruckNumber.toUpperCase();
        this.truckDataDtoSave.ownerName = this.enteredOwnerName;
        this.truckDataDtoSave.panNo = this.enteredPanNO;
        this.truckDataDtoSave.truckCapcityDouble = this.enteredTruckCapacity;
    }
    saveUpdateAgentTempoDetails = () => {
        this.getAgentTempoDetailsForSave();
        this.masterService.createAgentTempo( this.truckDataDtoSave ).
            subscribe(( data ) => {
                this.truckDataDtoSaveRet = data;
                //                console.log( this.truckDataDtoSaveRet.status );
                if ( this.truckDataDtoSaveRet.status == "persisted" ) {
                    swal( {
                        title: "Success",
                        text: "Agent Tempo Details Saved/Updated Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.clearAll();
                    this.getAgentTempoMasterDetailsList();
                }
                else if ( this.truckDataDtoSaveRet.status == "exist" ) {
                    swal( {
                        title: "Alert",
                        text: "This Tempo Number: " + this.truckDataDto.truckNumber
                        + " already exists",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } ).then(() => {
                        //                        $("#"+this.pageId+"truckNumber" ).val( '' );
                        window.setTimeout( function() {
                            $( "#"+this.pageId+"truckNumber" ).focus();
                        }, 100 );
                    } );
                }
                else {
                    swal( {
                        title: "Error",
                        text: "Error While Saving Truck Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );

                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Saving Truck Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };
    clearAll() {
        $( 'input[type="text"],[type="number"]' ).val( '' );
        this.newTruck = false;
        this.truckDataDto.truckNumber = null;
        this.saveOrEdit = 'save';
        this.truckDataDto = new TruckDataDto();
        this.truckDataCheckDto = new TruckDataDto();
        this.truckDataDtoCheckRet = '';
        this.truckDataDtoSaveRet = '';
        this.truckDataDeleteDto = new TruckDataDto();
        this.truckDataDtoDeleteRet = '';
        this.enteredTruckNumber = '';
        this.enteredTruckCapacity = '';
        this.truckDataDtoSave = new TruckDataDto();
        this.enteredOwnerName = '';
        this.enteredPanNO = '';

    }
    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }

    rowSelected( truckMasterData ) {
        this.truckDataDto.truckNumber = truckMasterData.truckNumber;
        this.truckDataDto.ownerName = truckMasterData.ownerName;
        this.truckDataDto.panNo = truckMasterData.panNo;
        this.truckDataDto.truckCapcityDouble = truckMasterData.truckCapacity;
        this.saveOrEdit = 'edit';
    }
    //    service ends

    validateDeleteRow() {
        this.deleteAgentTempoDetails();
    }

    getDeleteAgentTempoDetails() {
        this.truckDataDeleteDto = new TruckDataDto();
        this.truckDataDeleteDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckDataDeleteDto.truckNumber = this.truckDataDto.truckNumber;
        this.truckDataDeleteDto.userId = this.userDataDtoReturnSession.userId;
    }
    deleteAgentTempoDetails = () => {
        this.getDeleteAgentTempoDetails();
        this.masterService.deleteAgentTempoMaster( this.truckDataDeleteDto ).
            subscribe(( data ) => {
                this.truckDataDtoDeleteRet = data;
                if ( this.truckDataDtoDeleteRet.status == "updated" ) {
                    swal( {
                        title: "Success",
                        text: "Agent Tempo Details Deleted Successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    this.clearAll();
                    this.getAgentTempoMasterDetailsList();
                    //                    this.getDriverNameList();
                } else {
                    swal( {
                        title: "Error",
                        text: "Error While Deleting Agent Tempo Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                }
            } ),
            ( error ) => swal( {
                title: "Error",
                text: "Server Error While Deleting Agent Tempo Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ),
            () => console.log( 'done' );
    };



}
