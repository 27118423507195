export class PartyModel {

    consigneename: string;
    consignorName: string;
    tinNumber: string;
    mobileNumber: string;
    officeNumber: string;
    email: string;
    contactperson: string;
    deliverytype: string;
    collectionMan: string;
    address: string;
    area: string;
    city: string;
    state: string;
    pincode: string;
    userName: string;
    entryDate: Date;
    enteredBy: string;
    lastUpdatedDate: Date;
    lastUpdatedBy: string;
    companyBranch: string;
    effectivefrom: Date;
    effectiveto: Date;
    branch: string;
    status: string;
    office: string;
    mode: string;
    newConsigneeCity: string;
    role: string;
    hamaliGD: number;
    hamaliDD: number;
    serviceCharge: number;
    receiptCharge: number;
    demurrage: number;
    discount: number;
    paid: number;
    servTaxNum: string;
    stateCode: string;
    oldCgneeName: string;
    consignorId: number;
    consigneeId: number;
    oldConsigneeId: number;
    subStation: string;
    imgURL: string;
    imgUploaded: boolean;
    isAllowed: boolean;
    multipleCommodity: string;
    invoiceNumber: string;
    destination: string;
    message: string;
    isBlocked: boolean;
    inStockRpt: boolean;
    indexValue: string;
    rptMode: string;
    isSendRemindSMS: string;
    allEmailId: string;
    unldgCommodities: string;
    unldgChg: string;
    unldgUnit: string;
    mainStation: string;
    phoneNoAllow: boolean;
    isConfirmed: boolean;
    oldCgnorName: string;
    oldConsignorId: number;
    sendStockSms: boolean;
    isLatest: boolean;
    totalLrs: number;
    grandTotal: number;
    totalMemos: number;
    totalArticles: number;
    transitLrs: number;
    eDeclarationPwd: string;
    unldgSource: string;
    leftamount: number;
    isAlert: boolean;
    isSrcBlock: boolean;
    alertMessage: string;
    srcAlertMessage: string;
    gstNumber: string;
    gstNoConsignor: string;
    isGST: boolean;
    code: string;
    partyArea: string;
    firstName: string;
    lastName: string;
    id:number;
    mainCommodity:string;
}