<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" >
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<div class="row">
						<div class="col-md-4">
							<h6 class="card-title text-white">Manual CashMemo's Check</h6>
						</div>
						<div class="col-md-8 btn_bootstrap" style='text-align: right;'>
							<button type="submit" class="customCss btn btn-secondary"
								id="{{pageId}}checkAllBtn" (click)="checkAllBtn(manualCashmemoCheckPrintPopUpTemplate);">Checked Memos-All</button>
							<button type="submit" class="customCss btn btn-secondary"
								id="{{pageId}}checkBtn" (click)="checkBtn(manualCashmemoCheckPrintPopUpTemplate);"disabled>Checked Memos</button>
							<button type="submit" class="customCss btn btn-secondary"
								id="{{pageId}}unCheckAllBtn" (click)="unCheckAllBtn(manualCashmemoCheckPrintPopUpTemplate);">UnChecked Memos-All</button>
							<button type="submit" class="customCss btn btn-secondary"
								id="{{pageId}}unCheckBtn" (click)="unCheckBtn(manualCashmemoCheckPrintPopUpTemplate);"disabled>UnChecked Memos</button>
						</div>
					</div>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-4">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Entry Type :</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-search"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}entryType"
															name="entryType" #entryType
															(change)="entryTypeMode(entryType.value)" [disabled]="isReadOnly" >
															<option value="manual">Manual</option>
															<option selected value="automatic">Automatic</option>
														</select>
													</div>
												</div>
											</div>

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Staion</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-user"></i>
															</span>
														</div>
														<input #station id="{{pageId}}station" name="station" type="text"
															class="form-control" placeholder="Select Station"
															[(ngModel)]="modelStationName"
															[ngbTypeahead]="stationSearchTA"
															(selectItem)="getMemoCode($event)"
															[ngModelOptions]="{standalone: true}"
															[resultFormatter]="formatterStation"
															[inputFormatter]="formatterStation"
															(focus)="focusStationTA$.next($any($event).target.value)"
															[ngModelOptions]="{standalone: true}" />
													</div>
												</div>
											</div>



											<div *ngIf="manualView" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Memo Number :</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" class="form-control" id='{{pageId}}codeForManual'
															aria-describedby="basic-addon11"> <input
															type="text" class="form-control"
															(keyup)="getMemoNumberForManual($event)"
															aria-describedby="basic-addon11" id='{{pageId}}cashMemoManual'>
													</div>
												</div>
											</div>

											<div *ngIf="automaticView" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Memo Number :</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															id="{{pageId}}cashMemoAutomatic"
															(keyup)="getMemoNumberForAutomatic($event)"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Memo Amount :</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
														</div>
														<input type="text" class="form-control" id="{{pageId}}memoAmount"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Lr Number :</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" class="form-control" id="{{pageId}}lrNumber"
															aria-describedby="basic-addon11">
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12" style="margin-left: 50px;">
												<div class="form-group">
													<button type="submit" class="btn btn-success m-r-10"
														id="{{pageId}}finishBtn" (click)="finishMethod();"disabled>Finish</button>
													<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"(click)="clearAllMethod();">Clear</button>
												</div>
											</div>
											<div class="col-sm-12 col-md-12" align="right">
												<h6 *ngIf="lblMemoCount">{{textMemoCount}}</h6>
											</div>
											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-8 vl p-t-10">
						<div class="row">
							<!-- column -->
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<div class="card">
									<div class="card-body">
										<div class="row p-t-10">
											<div class="col-md-8"></div>
										</div>
										<div class="row p-t-10">
											<div class="col-md-8">
												<h6 class="card-title">Manual Cash Memo Check</h6>
											</div>

										</div>

										<div class="row">
											<div class="col-md-12">
												<div class="box-body">
													<table datatable id="{{pageId}}manualCashmemoCheckId"
														class="table table-striped table-bordered row-border hover"
														[dtOptions]="dtOptionsManualCashmemoCheck"
														[dtTrigger]="dtTriggerManualCashmemoCheck">

														<thead>
															<tr>
																<th>Memo Number</th>
																<th>Memo Date</th>
																<th>Total Amount</th>
																<th>Lr Number</th>
																<th>Articles</th>
																<th>Area</th>
																<th>Party Name</th>
																<th>Collection Man</th>
															</tr>
														</thead>
														<tbody>
															<tr
																*ngFor="let manualCashmemoCheckData of manualCashmemoCheckDataList ">
																<td>{{ manualCashmemoCheckData.memoNumber }}</td>
																<td>{{ manualCashmemoCheckData.memoDateStr }}</td>
																<td>{{ manualCashmemoCheckData.grandTotal }}</td>
																<td>{{ manualCashmemoCheckData.lrNumber }}</td>
																<td>{{ manualCashmemoCheckData.articles }}</td>
																<td>{{ manualCashmemoCheckData.area }}</td>
																<td>{{ manualCashmemoCheckData.consigneeName }}</td>
																<td>{{ manualCashmemoCheckData.collectionMan }}</td>

															</tr>
														</tbody>
														<tfoot>
															<tr>
																<td style="text-align: left">Total :
																				{{countOfLr}}</td>
																<td></td>
																<td style="text-align: left">{{sumOfTotAmt}}</td>
																<td></td>
																<td style="text-align: left">{{sumOfTotArt}}</td>
																<td></td>
																<td></td>
																<td></td>
															</tr>
														</tfoot>
													</table>
												</div>
											</div>
										</div>
									</div>


								</div>
							</div>

						</div>
					</div>



				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	
	<div class="col-md-12">
		<div class="form-group">
			<div class="input-group" id="{{pageId}}">
				<ng-template #manualCashmemoCheckPrintPopUpTemplate let-ok="close" let-d="dismiss">
					<div class="modal-body">
						<div class="row">
							<div class="col-md-12">
								<div class="card-body">
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div *ngIf="viewManualCashmemoCheckPrintPopUp" onafterPopUp="afterPopUp()"
												id="{{pageId}}popupManualCashmemoCheckPrint">
												<app-manual-cashmemo-check-and-collectionman-stock-check-print></app-manual-cashmemo-check-and-collectionman-stock-check-print>
											</div>
										</div>
									</div><br>
									<div class="row">
										<div class="col-sm-12 col-md-4">
										</div>
										<div class="col-sm-12 col-md-2">
											<button style="float: right;" type="button" class="btn btn-dark"
												id="{{pageId}}cancelBtnId" (click)="closeManualCashmemoCheckPrintPopUp()">Cancel
											</button>
										</div>
										<div class="col-sm-12 col-md-4">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</body>

</html>

