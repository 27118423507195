<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showDailyBkgRpt">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">

            <div class="row system_responsive" style="margin-bottom: 10px;">

                <div class="col-md-7 vl p-t-10">
                    <div class="row">
                        <div class="col-sm-6 col-md-6">
                            <div class="form-group">
                                <div class="input-group">

                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <select class="custom-select col-6" id="{{pageId}}partyType" name="partyType"
                                        #partyType (change)="partyTypeMode(partyType.value)"
                                        placeholder="Select Party Type..">
                                        <option selected value="selectParty">Select Type</option>
                                        <option value="consigneeWise">Consignee</option>
                                        <option value="consignorWise">Consignor</option>
                                        <option value="lrNumberSearch">Lr Wise</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <a style="cursor: pointer; color: blue;"
                                (click)="openLrDetailsModal(lrWiseTrackingModal)"><u>Set LR Monitor</u></a>
                        </div>
                    </div>
                    <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center'
                                    style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="box-body">
                            <table datatable id="{{pageId}}partyPerfMonitorTable"
                                class="table table-striped table-bordered row-border hover"
                                [dtOptions]="dtOptionsPartyPerfMonitor" [dtTrigger]="dtTriggerPartyPerfMonitor">
                                <thead>
                                    <tr>


                                        <th [hidden]="!viewConsigneeColumns">Consignee</th>
                                        <th [hidden]="!viewConsigneeColumns">Consignee GST</th>
                                        <th [hidden]="!viewConsignorColumns">Consignor</th>
                                        <th [hidden]="!viewConsignorColumns">Consignor GST</th>
                                        <th [hidden]="!viewConsigneeColumns">Destination</th>
                                        <th [hidden]="!viewConsignorColumns">Source</th>
                                        <th [hidden]="!viewLrTrackingColumns">Lr Number</th>
                                        <th [hidden]="!viewLrTrackingColumns">Tracked At</th>
                                        <th [hidden]="!viewLrTrackingColumns">Disable Tracking</th>
                                        <th [hidden]="!viewLrTrackingColumns">View Details</th>
                                        <th [hidden]="viewLrTrackingColumns">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let partyPerfMonitorData of partyPerfMonitorDataList ">

                                        <td [hidden]="!viewConsigneeColumns">{{ partyPerfMonitorData.consigneeName }}
                                        </td>
                                        <td [hidden]="!viewConsigneeColumns">{{ partyPerfMonitorData.gstNoConsignee }}
                                        </td>
                                        <td [hidden]="!viewConsignorColumns">{{ partyPerfMonitorData.consignorName }}
                                        </td>
                                        <td [hidden]="!viewConsignorColumns">{{ partyPerfMonitorData.gstNoConsignor }}
                                        </td>
                                        <td [hidden]="!viewConsigneeColumns">{{ partyPerfMonitorData.destination }}
                                        </td>
                                        <td [hidden]="!viewConsignorColumns">{{ partyPerfMonitorData.source }}
                                        </td>
                                        <td [hidden]="!viewLrTrackingColumns">{{ partyPerfMonitorData.lrNumber }}
                                        </td>
                                        <td [hidden]="!viewLrTrackingColumns">{{ partyPerfMonitorData.lrTrackingDateStr
                                            }}
                                        </td>
                                        <td [hidden]="!viewLrTrackingColumns"><button type="button"
                                                style="padding: 1px 4px; background-color: #70000000;"
                                                class="btn m-r-10" id="{{pageId}}disableLrTrackingBtn"
                                                (click)="confirmDisableLrTrackingDetails(partyPerfMonitorData)">
                                                <i title="Disable Tracking" class="fas fa-ban"></i>
                                            </button>
                                        </td>
                                        <td [hidden]="!viewLrTrackingColumns"><button type="button"
                                                style="padding: 1px 4px; background-color: #08007e00;"
                                                class="btn m-r-10" id="{{pageId}}disableLrTrackingBtn"
                                                (click)="rowSelectedGetForPerfMonitor(partyPerfMonitorData,'lrWise')">
                                                <i title="View" class="fas fa-eye"></i>
                                            </button>
                                        </td>
                                        <td [hidden]="viewLrTrackingColumns"><button type="button"
                                                style="padding: 1px 4px; background-color: #ffffff00;"
                                                class="btn m-r-10" id="{{pageId}}viewPerfMonitorDet"
                                                (click)="rowSelectedForParties(partyPerfMonitorData);">
                                                <i title="View" class="fas fa-eye"></i>
                                            </button></td>

                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td [hidden]="!viewConsigneeColumns"></td>
                                        <td [hidden]="!viewConsigneeColumns"></td>
                                        <td [hidden]="!viewConsignorColumns"></td>
                                        <td [hidden]="!viewConsignorColumns"></td>
                                        <td [hidden]="!viewConsigneeColumns"></td>
                                        <td [hidden]="!viewConsignorColumns"></td>
                                        <td [hidden]="!viewLrTrackingColumns"></td>
                                        <td [hidden]="!viewLrTrackingColumns"></td>
                                        <td [hidden]="!viewLrTrackingColumns"></td>
                                        <td [hidden]="!viewLrTrackingColumns"></td>
                                        <td [hidden]="viewLrTrackingColumns"></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-md-12" *ngIf="showPartyDetails">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12" *ngIf="showConsigneeName">
                                    <div class="row ">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label style="font-size: 13px;">Consignee Name</label>
                                                    <h6 style="font-size: 14px;">: {{ consigneeName
                                                        }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12" *ngIf="showConsignorName">
                                    <div class="row ">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label style="font-size: 13px;">Consignor Name</label>
                                                    <h6 style="font-size: 14px;">: {{ consignorName
                                                        }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="row ">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Booking Date</label>
                                                    <input class="form-control" placeholder="From Date"
                                                        id="{{pageId}}bookingFromDate" name="bookingFromDate"
                                                        ngbDatepicker #bookingFromDate="ngbDatepicker"
                                                        autoComplete="off">
                                                    <div class="input-group-append" (click)="bookingFromDate.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input class="form-control" placeholder="To Date"
                                                id="{{pageId}}bookingToDate" name="bookingToDate" ngbDatepicker
                                                #bookingToDate="ngbDatepicker" autoComplete="off">
                                            <div class="input-group-append" (click)="bookingToDate.toggle()">
                                                <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <button type="submit" class="btn btn-success" id="{{pageId}}searchBtn"
                                        (click)="validateGetPartyDetailsForSelectedBookingDate();">Search</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div class="col-md-12 vl p-t-10">
                    <!-- <div *ngIf="showSpinnerForAction" class="col-md-7 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div> -->

                    <div class="card-body">
                        <div class="box-body">
                            <table datatable id="{{pageId}}partyPerfMonitorDetailViewTable"
                                class="table table-striped table-bordered row-border hover"
                                [dtOptions]="dtOptionsPartyPerfMonitorDetailView"
                                [dtTrigger]="dtTriggerPartyPerfMonitorDetailView">
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>LR Number</th>
                                        <th>Current Status</th>
                                        <th [hidden]="!viewConsignorColumns">Destination</th>
                                        <th [hidden]="!viewConsigneeColumns">Source</th>
                                        <th [hidden]="!viewConsignorColumns">Consignee</th>
                                        <th [hidden]="!viewConsignorColumns">Consignee GST</th>
                                        <th [hidden]="!viewConsigneeColumns">Consignor</th>
                                        <th [hidden]="!viewConsigneeColumns">Consignor GST</th>
                                        <th>Art</th>
                                        <th>Commodity</th>
                                        <th>Act Wt</th>
                                        <th>Chg Wt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>Destination Memo Date</th>
                                        <th>Pvt Marker</th>
                                        <th>Booking Date(A)</th>
                                        <th>Entry Date(B)</th>
                                        <th>Diff(B-A)</th>
                                        <th>Dep.Dt Frm Src(C)</th>
                                        <th>Diff(C-B)</th>
                                        <th>Src Inv No</th>
                                        <th>Arr.Dt At 1st Stop(D)</th>
                                        <th>Diff(D-C)</th>
                                        <th>Dep.Dt Frm 1st Stop(E)</th>
                                        <th>Dep.Frm 1st Stop Inv No</th>
                                        <th>Diff(E-D)</th>
                                        <th>Arr.Dt At 2nd Stop(F)</th>
                                        <th>Diff(F-E)</th>
                                        <th>Dep.Dt Frm 2nd Stop(G)</th>
                                        <th>Dep.Frm 2nd Stop Inv No</th>
                                        <th>Diff(G-F)</th>
                                        <th>Arr.Dt At Dest(H)</th>
                                        <th>Diff(H-G)</th>
                                        <th>Delivery Date(I)</th>
                                        <th>Diff(I-H)</th>
                                        <th>Tot No.of Days</th>
                                        <th>Cur Dt(-)Bkg Dt(Only for Transit Lrs)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let partyPerfMonitorDetailViewData of partyPerfMonitorDetailViewDataList ">
                                        <td><button class="btn1" title="View Print"
                                                (click)="setLrPerformanceMonitorPrintDetails(partyPerfMonitorDetailViewData);">
                                                <i class="fa fa-print"></i>
                                            </button>
                                        </td>
                                        <td>{{ partyPerfMonitorDetailViewData.lrNumber }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.status }}</td>
                                        <td [hidden]="!viewConsignorColumns">{{
                                            partyPerfMonitorDetailViewData.destination }}</td>
                                        <td [hidden]="!viewConsigneeColumns">{{ partyPerfMonitorDetailViewData.source }}
                                        </td>
                                        <td [hidden]="!viewConsignorColumns">{{
                                            partyPerfMonitorDetailViewData.consigneeName }}</td>
                                        <td [hidden]="!viewConsignorColumns">{{
                                            partyPerfMonitorDetailViewData.gstNoConsignee }}</td>
                                        <td [hidden]="!viewConsigneeColumns">{{
                                            partyPerfMonitorDetailViewData.consignorName }}</td>
                                        <td [hidden]="!viewConsigneeColumns">{{
                                            partyPerfMonitorDetailViewData.gstNoConsignor }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.totalArticles }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.commodityName }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.actualWeight }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.chargedWeight}}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.toPay }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.paid }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.memoDateStr }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.privateMarker }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.bookingDateStr }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.enteredDateStr }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.diff9 }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.departuredateStr }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.diff8 }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.invoiceNumber }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.arrivalDateStr }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.diff1 }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.depDateFrmTrnspDateStr}}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.dummyInvoice}}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.diff2 }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.arrDateAtTrnspDateStr }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.diff3 }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.depDateFrmTrnspDate2Str }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.dummyInvoice2}}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.diff4 }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.arrDateAtTrnspDate2Str }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.diff5 }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.deliveryDateStr }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.diff6 }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.diffDays }}</td>
                                        <td>{{ partyPerfMonitorDetailViewData.diff7 }}</td>

                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>Total</td>
                                        <td></td>
                                        <td [hidden]="!viewConsignorColumns"></td>
                                        <td [hidden]="!viewConsigneeColumns"></td>
                                        <td [hidden]="!viewConsignorColumns"></td>
                                        <td [hidden]="!viewConsignorColumns"></td>
                                        <td [hidden]="!viewConsigneeColumns"></td>
                                        <td [hidden]="!viewConsigneeColumns"></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group">
                <ng-template #lrWiseTrackingModal let-ok="close" let-d="dismiss">
                    <div class="custom-modal-content">
                        <div class="modal-header">
                            <h5>Enter LR Number to Track</h5>
                            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">

                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <label>LR Number</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="fas fa-clipboard"></i></span>
                                            </div>
                                            <div>
                                                <input type="text" class="form-control" id="{{pageId}}lrNumberForTrack"
                                                    autocomplete="off" aria-describedby="basic-addon11">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <label style="white-space: nowrap;">Descripton (Multiple Descripton Separate by
                                            Hash)</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <textarea class="form-control" rows="3" id="{{pageId}}description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}updateLrTrackBtn"
                                (click)="confirmUpdateLrTrackingDetails();">Confirm</button>
                            <button type="submit" class="btn btn-dark" id="{{pageId}}scanCodePopupCloseBtnId"
                                (click)="closeLrTrackingModal();">Cancel</button>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

</div>

<div *ngIf="viewLrPerformanceMonitorPrint" onafterprint="afterPrint()" id="{{pageId}}printVoucher">
    <app-lr-performance-monitor-print></app-lr-performance-monitor-print>
</div>