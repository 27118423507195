<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}consigneemasterPageId">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info hide-header" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Consignee Master</h6>
            </div>
            <!--<form (ngSubmit)="getConsigneeDetailsList()">-->

            <div class="row " style="margin-bottom: 10px;">
                <!-- spinner start-->
                <div *ngIf="showSpinnerForAction" class="col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;">
                            </mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                Please Wait Loading Details.....</h6>
                        </div>
                    </div>
                </div>
                <!-- spinner end-->
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Search By GST</label>
                                            <a href="javascript:void(0)" class="btn  btn-facebook" data-toggle="tooltip" title="" data-original-title="Login with Facebook"> <i aria-hidden="true" class="fab  fa-facebook"></i> </a>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}searchByGstId" autocomplete="off" name="searchByGst" (keypress)="validateForSearchByGst($event)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">

                                    <div class="control">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>City</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="icon-home"></i>
                                                    </span>
                                                </div>
                                                <ng-template #rt let-r="result" let-t="term">
                                                    <ngb-highlight [result]="r.destination" [term]="t"></ngb-highlight>
                                                    -
                                                    <ngb-highlight [result]="r.state" [term]="t"></ngb-highlight>
                                                </ng-template>
                                                <input #destinationCity id="{{pageId}}destinationCityId" type="text" class="form-control" (selectItem)="clickListnerForDestinationCity($event)" [(ngModel)]="modelDestinationCity" [ngbTypeahead]="searchDestinationCity" [resultFormatter]="formatterDestinationCity"
                                                    [inputFormatter]="formatterDestinationCity" [resultTemplate]="rt" (focus)="focusDestinationCityTA$.next($any($event).target.value)" autocomplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-lg-12">
                                    <h6 class="card-title">Party Details</h6>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Consignee Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-user-circle"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}consigneeNameId" name="consigneeNameId" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Contact Person</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}contactPersonId" name="contactPersonId" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>State</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-map-marker-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input #stateNameCode id="{{pageId}}stateNameAndCodeId" type="text" class="form-control" (selectItem)="clickListnerForStateNameAndCode($event)" [(ngModel)]="modelStateNameAndCode" [ngbTypeahead]="searchStateNameAndCode" [resultFormatter]="formatterStateNameAndCode"
                                                            [inputFormatter]="formatterStateNameAndCode" (focus)="focusStateNameAndCodeTA$.next($any($event).target.value)" autocomplete="off" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!---->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Mobile Number</label>
                                                    <a style="cursor: pointer; color: blue; font-weight: bolder;" (click)="viewMobileNumber();">{{viewAddMobileNo}}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>GST Number</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}gstNumberId" autocomplete="off" name="gstNumberId">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Office Number</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-phone"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" autocomplete="off" aria-describedby="basic-addon11" id="{{pageId}}officeNumberId" name="officeNumberId">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-12 col-md-12">
											<div class="form-group">
												<div class="input-group">
													<label>E-Declaration PassWord</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i class="fas fa-phone"></i>
														</span>
													</div>
													<input type="text" class="form-control" autocomplete="off"
														aria-describedby="basic-addon11"
														id="{{pageId}}eDeclarationPassWordId"
														name="eDeclarationPassWordId">
												</div>
											</div>
										</div> -->
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Email-Id</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-envelope"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}emailId" name="emailId" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Address</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                                                        </span>
                                                    </div>
                                                    <textarea class="form-control " rows="2" id="{{pageId}}addressId" name="addressId" autocomplete="off"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Pincode</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-hashtag"></i>
                                                        </span>
                                                    </div>
                                                    <input type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}pincode" name="pincode" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                        <hr style="width: 95%;">
                                        <div class="col-sm-12 col-md-12">
                                            <h6 class="card-title">Shipping Details</h6>
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Delivery Type</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-truck"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}deliveryType" name="deliveryType" #deliveryType (change)="delivTypeMethod(deliveryType.value)">
                                                        <option selected value="select">Select.....</option>
                                                        <option value="godown">Godown</option>
                                                        <option value="doorDelivery">Door Delivery</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="viewArea" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Area</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-map-marker-alt"></i>
                                                        </span>
                                                    </div>
                                                    <!--<select class="custom-select col-12" id="{{pageId}}area" name="area" #area
														(change)="areaMethod(area.value)">
														<option selected value="selectArea">Select Area</option>
														<option value="addNew">Add New</option>
													</select>-->
                                                    <input #source id="{{pageId}}area" type="text" class="form-control" (selectItem)="clickListnerForArea($event,newAreaPopUpTemplate)" [(ngModel)]="modelArea" [ngbTypeahead]="searchArea" [resultFormatter]="formatterArea" [inputFormatter]="formatterArea"
                                                        (focus)="focusAreaTA$.next($any($event).target.value)" placeholder="Area" autocomplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Collection Man</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input #collectionMan id="{{pageId}}collectionManId" type="text" class="form-control" (selectItem)="clickListnerForCollectionMan($event,collectionManPopUpTemplate)" [(ngModel)]="modelCollectionMan" [ngbTypeahead]="searchCollectionMan" [resultFormatter]="formatterCollectionMan"
                                                        [inputFormatter]="formatterCollectionMan" (focus)="focusCollectionManTA$.next($any($event).target.value)" autocomplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12" *ngIf="viewPartyAreaField">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Party Area</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-map-marker-alt"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}partyAreaId" name="partyAreaId">
                                                        <option selected value="binnymills">Binnymills</option>
                                                        <option value="konditope">Konditope</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 vl">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <hr style="width: 95%;">
                                <div class="col-sm-12 col-md-12">
                                    <h6 class="card-title">Application Setting</h6>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Mode</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-user"></i>
                                                </span>
                                            </div>
                                            <select id="{{pageId}}modeAllowedId" name="modeAllowedId" class="custom-select col-12">
                                                <option value="false" selected>No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Send Rmd SMS</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-envelope"></i>
                                                </span>
                                            </div>
                                            <select id="{{pageId}}sendRemainderSmsId" name="sendRemainderSmsId" class="custom-select col-12">
                                                <option value="true" selected>Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Send Stock Sms</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-envelope"></i>
                                                </span>
                                            </div>
                                            <select id="{{pageId}}sendStockSmsId" name="sendStockSmsId" #sendStockSmsId class="custom-select col-12">
                                                <option value="true" selected>Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Des Block</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-ban"></i>
                                                </span>
                                            </div>
                                            <select id="{{pageId}}desBlockId" name="desBlockId" class="custom-select col-12" #desBlockId (change)="desBlockMethod(desBlockId.value)">
                                                <option value="false" selected>No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12" *ngIf="viewBlockMessageRemarks">
                                    <div class="form-group">
                                        <div class="form-group">
                                            <div class="form-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-notes-medical"></i>
                                                </span>
                                            </div>
                                            <textarea type="text" class="form-control" id="{{pageId}}blockMessageRemarksId" rows="2" [(ngModel)]="blockMessageRemarksId"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Pop Up Alert</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
                                                        class="fas fa-exclamation-triangle"></i>
                                                </span>
                                            </div>
                                            <select id="{{pageId}}popUpAlertId" name="popUpAlertId" class="custom-select col-12" #popUpAlertId (change)="isPopUpAlertMethod(popUpAlertId.value)">
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12" *ngIf="viewisPopUpAlertMessageRemarks">
                                    <div class="form-group">
                                        <div class="form-group">
                                            <div class="form-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-notes-medical"></i>
                                                </span>
                                            </div>
                                            <textarea type="text" class="form-control" id="{{pageId}}isPopupAlertMessageRemarksId" rows="2" [(ngModel)]="popUpAlertMessageRemarks"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>SRC Block</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-ban"></i>
                                                </span>
                                            </div>
                                            <select id="{{pageId}}srcBlockId" name="srcBlockId" class="custom-select col-12" #srcBlockId (change)="srcBlockMethod(srcBlockId.value)">
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12" *ngIf="viewSrcBlockMessageRemarks">
                                    <div class="form-group">
                                        <div class="form-group">
                                            <div class="form-group-prepend">
                                                <span class="input-group-text"><i class="fas fa-notes-medical"></i>
                                                    &nbsp;SRC Block Message
                                                </span>
                                            </div>
                                            <textarea type="text" class="form-control" id="{{pageId}}isSrcBlockMessageRemarksId" rows="2" [(ngModel)]="srcBlockMessageRemarksId"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Memo Time Period</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-clock"></i>
                                                </span>
                                            </div>
                                            <input #memoTimePeriod type="number" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}memoTimePeriod" name="memoTimePeriod">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Demurage Exemption</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-ban"></i>
                                                </span>
                                            </div>
                                            <select id="{{pageId}}demurageExmp" name="demurageExmp" class="custom-select col-12" #demurageExmp>
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Payment Mode</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                </span>
                                            </div>
                                            <select #paymentMode id="{{pageId}}paymentMode" name="paymentMode" class="custom-select col-12" #paymentMode>
                                                <option selected value="Cash">Cash</option>
                                                <option value="Outstanding">Outstanding</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12" *ngIf="blockCFTCheckBox">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Disable Default CFT(In LR Booking)</label>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="{{pageId}}checkboxCft" (change)="cftSetupForConsignee($event)">
                                                <label class="custom-control-label wtfull" for="{{pageId}}checkboxCft">Disable</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="viewLeftPartyOption" class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Allow Left To Party Statement</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-rupee-sign"></i>
                                                </span>
                                            </div>
                                            <select #leftToPartyAllow id="{{pageId}}leftToPartyAllow" name="leftToPartyAllow" class="custom-select col-12">
                                                <option selected value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 vl">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-7 pd_1_at_largeView" style="padding-top: 5px;">

                                    <div class="control">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input #CommodityMainUnldg id="{{pageId}}CommodityMainUnldgId" type="text" class="form-control" (selectItem)="clickListnerForCommodityMainUnldg($event)" [(ngModel)]="modelCommodityMainUnldg" [ngbTypeahead]="searchCommodityMainUnldg" [resultFormatter]="formatterCommodityMainUnldg"
                                                    [inputFormatter]="formatterCommodityMainUnldg" (focus)="focusCommodityMainUnldgTA$.next($any($event).target.value)" placeholder="Commodity" autocomplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-5 pd_1_at_largeView">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input #source id="{{pageId}}source" type="text" class="form-control" (selectItem)="clickListnerForSource($event)" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                                placeholder="Source" autocomplete="off" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-5 pd_1_at_largeView">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input type="number" id="{{pageId}}unldnChg" placeholder="Unldg Chg" class="form-control" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4 pd_1_at_largeView">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <select class="custom-select col-12" id="{{pageId}}unitId">
                                                <option selected value="Unit">Unit</option>
                                                <option value="Article">Article</option>
                                                <option value="Actual Weight">Actual Weight</option>
                                                <option value="Chargable Weight">Chargable Weight</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3 pd_1_at_largeView">
                                    <a href="javascript:;" type='button' #addBtn style="padding: 1px 2px;margin-left: -5px;" id="{{pageId}}addCommodityPlusBtnId" class="btn btn-icon-only yellow" (click)="addCommodityDetailsTable();">
                                        <i class="fa fa-plus" title="Add"></i>
                                    </a>
                                    <a href="javascript:;" type='button' #addBtn style="padding: 1px 2px;" class="btn btn-icon-only yellow" (click)="clearCommodityDetailsTable();">
                                        <i class="fa fa-trash" title="Clear"></i>
                                    </a>
                                </div>
                                <div class="col-md-12">
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}unldgCommodityTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCommodity" [dtTrigger]="dtTriggerCommodity">

                                            <thead>
                                                <tr>
                                                    <th>Commodity</th>
                                                    <th>Unldg Chg</th>
                                                    <th>Source</th>
                                                    <th>Unit</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let commodityData of commodityDataList let i=index">
                                                    <td>{{ commodityData.commodity }}</td>
                                                    <td>{{ commodityData.unldgChg }}</td>
                                                    <td>{{ commodityData.source }}</td>
                                                    <td>{{ commodityData.unit }}</td>
                                                    <td>
                                                        <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableLRDetailsBtn" (click)="rowSelectedEditUnldgCommodityDeatils(commodityData,i);">
                                                            <i title="Edit Unldg Commodity Details"
                                                                class="fas fa-pencil-alt"></i>
                                                        </button> &nbsp;&nbsp;
                                                        <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="deleteRowSelectedUnldgCommodity(commodityData,i)">
                                                            <i title="Remove" class="fas fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <hr style="width: 95%;">
                                <div class="col-sm-12 col-md-8 pd_1_at_largeView" style="padding-top: 5px;">
                                    <h6 class="card-title">Consignee Commodities</h6>
                                </div>
                                <div class="col-sm-12 col-md-8 pd_1_at_largeView">
                                    <div class="control">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input #commodityMain id="{{pageId}}commodityMainId" type="text" class="form-control" (selectItem)="clickListnerForCommodityMain($event)" [(ngModel)]="modelCommodityMain" [ngbTypeahead]="searchCommodityMain" [resultFormatter]="formatterCommodityMain"
                                                    [inputFormatter]="formatterCommodityMain" (focus)="focusCommodityMainTA$.next($any($event).target.value)" autocomplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4 pd_1_at_largeView">
                                    <a href="javascript:;" type='button' #addBtn style="padding: 1px 2px;" class="btn btn-icon-only yellow" (click)="addConsigneeCommodityDetailsTable();">
                                        <i class="fa fa-plus" title="Add"></i>
                                    </a>
                                    &nbsp;
                                    <a href="javascript:;" type='button' #addBtn style="padding: 1px 2px;" class="btn btn-icon-only yellow" (click)="clearConsigneeCommodityDetailsTable();">
                                        <i class="fa fa-trash" title="Clear"></i>
                                    </a>
                                    <!--&nbsp; <i style="cursor: pointer;" placement="right"
								ngbTooltip="Remove" class="fas fa-trash"></i>-->
                                </div>
                                <div class="col-md-12">
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}consigneeCommodityTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsConsigneeCommodity" [dtTrigger]="dtTriggerConsigneeCommodity">

                                            <thead>
                                                <tr>
                                                    <th>Commodity</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let consigneeCommodityData of consigneeCommodityDataList let i = index">
                                                    <td>{{ consigneeCommodityData.commodity }}</td>
                                                    <td>
                                                        <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableLRDetailsBtn" (click)="rowSelectedEditConsigneeCommodityDeatils(consigneeCommodityData,i);">
                                                            <i title="Edit Consignee Commodity Details"
                                                                class="fas fa-pencil-alt"></i>
                                                        </button>&nbsp;&nbsp;
                                                        <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="deleteRowSelectedConsigneeCommodity(consigneeCommodityData,i)">
                                                            <i title="Remove" class="fas fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <hr style="width: 95%;">
                                <div class="col-md-12" style="padding-top: 5px;">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <h6 class="card-title">
                                                Status Details &nbsp; <i style="cursor: pointer;" placement="right" ngbTooltip="Get Details" class="fas fa-clipboard"></i>
                                            </h6>
                                        </div>
                                        <div class="col-md-4">
                                            <button style="padding: 1px 4px" type="submit" class="btn btn-success m-r-10" id="{{pageId}}getDetailsBtn" (click)="getConsigneeDetailsList();">Get
                                                Details</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}consigneeStatusTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsConsigneeStatusDetails" [dtTrigger]="dtTriggerConsigneeStatusDetails">

                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Total</th>
                                                    <th>Active</th>
                                                    <th>Non Active</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let consigneeStatusDetailsData of consigneeStatusDetailsDataList ">
                                                    <td>
                                                        {{ consigneeStatusDetailsData.source }}</td>
                                                    <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="totalconsigneeActiveNonActivePopUpBtnLink(consigneeStatusDetailsData);">
                                                        {{ consigneeStatusDetailsData.totalConsignee }}</td>
                                                    <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="consigneeActivePopUpBtnLink(consigneeStatusDetailsData);">
                                                        {{ consigneeStatusDetailsData.activeConsignee }}</td>
                                                    <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="consigneenonActivePopUpBtnLink(consigneeStatusDetailsData);">
                                                        {{ consigneeStatusDetailsData.nonActiveConsignee }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-4 vl">
                    <div class="card">
                        <div class="card-body cardbody_pd">
                            <!--<div class="col-md-12">-->
                            <div class="row">
                                <div class="col-md-12">

                                    <div class="card">
                                        <div class="card-body">
                                            <div class="box-body">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-12" *ngIf="viewMonitorPerfToggle">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <label>Monitor Delivery Performance</label>

                                                                <mat-slide-toggle [(ngModel)]="isMonitorDelPerformance" color="primary" (change)="onMonitorDelPerfToggleChange($event)">
                                                                </mat-slide-toggle>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-4 col-md-4">
                                                        <div class="control">
                                                            <div class="form-group">
                                                                <div class="input-group">
                                                                    <input #consigneeIndex id="{{pageId}}consigneeIndex" name="consigneeIndex" type="text" class="form-control" placeholder="Select Consignee Index" (selectItem)="rowSelectedConsigneeIndex($event)" [ngbTypeahead]="consigneeIndexSearchTA" (focus)="focusConsigneeIndexTA$.next($any($event).target.value)"
                                                                        (click)="clickTA(instanceConsigneeIndex)" #instanceConsigneeIndex="ngbTypeahead" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-5 col-md-5">
                                                        <button type="submit" style="float: right;" class="dt-button" (click)="getDestBlockedConsigneeDetails();" id="{{pageId}}destBlockConsgneBtnId">
                                                            <span>Get Blocked Consignee</span>
                                                        </button>
                                                    </div>
                                                    <div class="col-sm-3 col-md-3">
                                                        <button type="submit" style="float: right;" class="dt-button" (click)="customizePrintBtn();" id="{{pageId}}printAllBtnId">
                                                            <span><i class="fas fa-print">Print</i></span>
                                                        </button>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <table datatable id="{{pageId}}consigneeDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsConsigneeDetails" [dtTrigger]="dtTriggerConsigneeDetails">

                                                            <thead>
                                                                <tr>
                                                                    <th>Consignee Name</th>
                                                                    <th>GST No</th>
                                                                    <th [hidden]=true>Commodity Wise</th>
                                                                    <th [hidden]=true>Delivery Type</th>
                                                                    <th [hidden]=true>Area</th>
                                                                    <th [hidden]="!viewOnlyPrintExcel">Address</th>
                                                                    <th [hidden]="!viewOnlyPrintExcel">Mobile Number
                                                                    </th>
                                                                    <th [hidden]="!viewOnlyPrintExcel">Contact Person
                                                                    </th>
                                                                    <th [hidden]="!viewOnlyPrintExcel">Collection Man
                                                                    </th>
                                                                    <th [hidden]="!viewOnlyPrintExcel">E-Mail-ID</th>
                                                                    <th [hidden]="!viewOnlyPrintExcel">Is Dest Blocked
                                                                    </th>
                                                                    <th [hidden]="!viewOnlyPrintExcel">Dest Block Reason
                                                                    </th>
                                                                    <th [hidden]=true>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let consigneeDetailsData of consigneeDetailsDataList " (click)="rowSelectedEditConsigneeDeatils(consigneeDetailsData);" style="cursor: pointer;">
                                                                    <td>{{ consigneeDetailsData.consigneeName }}</td>
                                                                    <td>{{ consigneeDetailsData.gstNoConsignee }}</td>
                                                                    <td [hidden]=true>{{ consigneeDetailsData.multipleCommodity }}
                                                                    </td>
                                                                    <td [hidden]=true>{{ consigneeDetailsData.deliveryType == null ?"NA" : consigneeDetailsData.deliveryType}}
                                                                    </td>
                                                                    <td [hidden]=true>{{ consigneeDetailsData.area }}
                                                                    </td>
                                                                    <td [hidden]="!viewOnlyPrintExcel">
                                                                        {{ consigneeDetailsData.address }}</td>
                                                                    <td [hidden]="!viewOnlyPrintExcel">
                                                                        {{ consigneeDetailsData.mobileNumber }}</td>
                                                                    <td [hidden]="!viewOnlyPrintExcel">
                                                                        {{ consigneeDetailsData.contactPerson }}</td>
                                                                    <td [hidden]="!viewOnlyPrintExcel">
                                                                        {{ consigneeDetailsData.collectionMan }}</td>
                                                                    <td [hidden]="!viewOnlyPrintExcel">
                                                                        {{ consigneeDetailsData.email }}</td>
                                                                    <td [hidden]="!viewOnlyPrintExcel">
                                                                        {{ consigneeDetailsData.destBlocked ? 'yes' : 'no' }}
                                                                    </td>
                                                                    <td [hidden]="!viewOnlyPrintExcel">
                                                                        {{ consigneeDetailsData.blockedRemarks }}</td>
                                                                    <td [hidden]=true>
                                                                        <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableLRDetailsBtn" (click)="rowSelectedEditConsigneeDeatils(consigneeDetailsData);">
                                                                            <i title="Edit Consignee Details"
                                                                                class="fas fa-pencil-alt"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <!--<tfoot>
															<tr>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
																<td></td>
															</tr>
														</tfoot>-->
                                                        </table>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--</div>-->
                        </div>

                        <!-- <div class="card">
							<div class="card-body" style="padding: 0px;">
								<div class="row">
								</div>
							</div>
						</div> -->


                    </div>
                </div>
                <!-- <div class="col-md-12 vl">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<hr style="width: 95%;">
								<div class="col-md-4">
								</div>
								<div class="col-md-4 vl">
									<div class="card">
										<div class="card-body">
											<div class="row">
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="card">
										<div class="card-body">
											<div class="row">
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-8 vl">
								</div>
							</div>
						</div>
					</div>
				</div> -->
                <hr style="width: 80%; border-top: none; margin: 3px;">
                <div class="col-lg-12" style="text-align: center;">
                    <button type="button" class="btn btn-success m-r-10" id="{{pageId}}finishBtn" *ngIf="viewSaveBtn" (click)="validateSaveConsignee()">Finish</button>
                    <button type="button" class="btn btn-success m-r-10" id="{{pageId}}updateBtn" *ngIf="viewUpdateBtn" (click)="validateUpdateConsignee()">Update</button>
                    <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="validateClearBtn();">Clear</button>
                    <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}mobileNoUpdationBtn" (click)="validateMobileNumberUpdationBtn();">Mobile Number
                        Updation</button>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>

<!--POP UP START HERE-->
<div class="col-md-12">
    <div class="form-group">
        <div class="input-group" id="{{pageId}}">
            <ng-template #collectionManPopUpTemplate let-ok="close" let-d="dismiss">
                <div class="modal-header">
                    <h5>Add New Collection Man :</h5>
                    <!--<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button> -->
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <!-- spinner start-->
                                    <div *ngIf="showSpinnerForAction1" class="col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                </mat-progress-bar>
                                                <br>
                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                    Please Wait Loading Details.....</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- spinner end-->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>New Collection Man</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-user"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}newCollectionManId" name="newCollectionManId">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <!--<div class="col-sm-12 col-md-4"></div>
									<div class="col-sm-12 col-md-4">
									</div>-->
                                    <div class="col-sm-12 col-md-12">
                                        <button style="float: right;" type="button" class="btn btn-success" id="{{pageId}}saveBtnId" (click)="saveNewCollectionManPopUpBtn();">Save</button>
                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}closeBtnId" (click)="closeNewCollectionManPopUpBtn();">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<!--POP UP START HERE-->
<div class="col-md-12">
    <div class="form-group">
        <div class="input-group" id="{{pageId}}">
            <ng-template #newAreaPopUpTemplate let-ok="close" let-d="dismiss">
                <div class="modal-header">
                    <h5>Add New Area :</h5>
                    <!--<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
								<span aria-hidden="true">&times;</span>
							</button> -->
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <!-- spinner start-->
                                    <div *ngIf="showSpinnerForAction2" class="col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                </mat-progress-bar>
                                                <br>
                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                    Please Wait Loading Details.....</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- spinner end-->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>New Area</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i
                                                            class="fas fa-map-marker-alt"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" aria-describedby="basic-addon11" id="{{pageId}}newAreaId" name="newAreaId">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <!--<div class="col-sm-12 col-md-4"></div>
									<div class="col-sm-12 col-md-4">
									</div>-->
                                    <div class="col-sm-12 col-md-12">
                                        <button style="float: right;" type="button" class="btn btn-success" id="{{pageId}}saveBtnId" (click)="saveNewDeliveryAreaPopUpBtn();">Save</button>
                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}closeBtnId" (click)="closeNewDeliveryAreaPopUpBtn();">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<!--<div class="col-md-12">
	<div class="form-group">
		<div class="input-group">
			<ng-template #srcBlockMessagePopUpTemplate let-ok="close" let-d="dismiss">
				<div class="modal-header">
					<h5>Add New Collection Man :</h5>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-md-12">
							<div class="card-body">
								<div class="row">
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="form-group">
												<label>Message</label>
												<div class="form-group-prepend">
													<span class="input-group-text"> <i class="fas fa-notes-medical"></i>
													</span>
												</div>
												<textarea type="text" class="form-control" id="{{pageId}}srcBlockMessageId"
													rows="4"></textarea>
											</div>
										</div>
									</div>
								</div>
								<br>
								<div class="row">
									<div class="col-sm-12 col-md-12">
										<button style="float: right;" type="button" class="btn btn-success"
											id="{{pageId}}closeBtnId" (click)="setMessageOfSrcBlockPopUpBtn();">Set
											Message</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>-->

<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>