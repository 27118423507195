import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
const my = new Date();
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { LRDto } from "src/app/dto/LR-dto";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { ConsigneeService } from 'src/app/dataService/consignee-service';

@Component({
    selector: 'app-consignee-admin-rate-report',
    templateUrl: './consignee-admin-rate-report.component.html',
    styleUrls: ['./consignee-admin-rate-report.component.css']
})
export class ConsigneeAdminRateReportComponent implements OnInit {

    consigneeAdminRateReportDataList: any;
    commodityRateDetailsTableDataList: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerConsigneeAdminRateReport: Subject<any> = new Subject();
    dtTriggerCommodityRateDetailsTable: Subject<any> = new Subject();
    dtOptionsConsigneeAdminRateReport: any;
    dtOptionsCommodityRateDetailsTable: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction = false;

    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    selectedDestination: any;
    searchDetailsByRateDto: RateMasterDto = new RateMasterDto();
    searchDetailsByCommodityDto: RateMasterDto = new RateMasterDto();
    pageId = "carrc";

    constructor(private router: Router, private masterReadService: MasterReadService, private consigneeService: ConsigneeService,
        public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.getDestinationDetails();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        this.dtOptionsConsigneeAdminRateReport = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',

                }, {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 200,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
        }

        this.dtOptionsCommodityRateDetailsTable = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
        }
    }

    ngOnDestroy(): void {
        this.dtTriggerConsigneeAdminRateReport.unsubscribe();
        this.dtTriggerCommodityRateDetailsTable.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerConsigneeAdminRateReport.next();
        this.dtTriggerCommodityRateDetailsTable.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destination").val(this.modelDestination.destination);
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                        }

                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log('done');

    };

    searchMethod() {
        this.searchDetailsByRateDto = new RateMasterDto();
        this.selectedDestination = $("#" + this.pageId + "destination").val();

        if (this.selectedDestination == null || this.selectedDestination == undefined ||
            this.selectedDestination == "") {
            swal("Mandatory Field", "Destination is mandatory fields", "warning");
            return false;
        } else {
            this.searchDetailsByRateDto.destination = this.selectedDestination;
            this.searchDetailsByRateDto.companyId = this.userDataDtoReturnSession.companyId;
            this.searchDetailsForConsigneeAdminBusinessRateRptForParty();
        }
    }

    searchDetailsForConsigneeAdminBusinessRateRptForParty = () => {
        this.showSpinnerForAction = true;
        this.consigneeService.getConsigneeAdminRateReportDetailsTrxRpt(this.searchDetailsByRateDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "consigneeAdminRateForPartiesTableId").DataTable().destroy();
                this.consigneeAdminRateReportDataList = [];
                if (response.length == 0) {
                    swal("Warning", "No  Rate Details found !", "warning");
                } else {
                    this.consigneeAdminRateReportDataList = response;
                    console.log(this.consigneeAdminRateReportDataList);
                    this.searchDetailsForConsigneeAdminRateRptForCommodity();
                }
                this.dtTriggerConsigneeAdminRateReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the  Rate For Parties details", "info");
            }, () => console.log('done');
    };

    searchDetailsForConsigneeAdminRateRptForCommodity = () => {
        this.searchDetailsByCommodityDto = new RateMasterDto();
        this.selectedDestination = $("#" + this.pageId + "destination").val();

        this.searchDetailsByCommodityDto.city = this.selectedDestination;
        this.searchDetailsByCommodityDto.mode = "specific";
        this.searchDetailsByCommodityDto.rptModeId = 1;
        this.searchDetailsByCommodityDto.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.consigneeService.getConsigneeAdminRateDetails(this.searchDetailsByCommodityDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "consigneeCommodityRateDetailsTableId").DataTable().destroy();
                this.commodityRateDetailsTableDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Commodity Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.commodityRateDetailsTableDataList = response;
                    console.log(this.commodityRateDetailsTableDataList);
                }
                this.dtTriggerCommodityRateDetailsTable.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Commodity Rate details", "info");
            }, () => console.log('done');
    };

    clearMethod() {
        $("#" + this.pageId + "consigneeAdminRateForPartiesTableId").DataTable().destroy();
        this.consigneeAdminRateReportDataList = [];
        this.dtTriggerConsigneeAdminRateReport.next();

        $("#" + this.pageId + "consigneeCommodityRateDetailsTableId").DataTable().destroy();
        this.commodityRateDetailsTableDataList = [];
        this.dtTriggerCommodityRateDetailsTable.next();

        this.modelDestination = null;
        this.selectedDestination = null;
        $("#" + this.pageId + "destination").val('');
    }
}
