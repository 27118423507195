import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { LRDto } from "src/app/dto/LR-dto";

@Component({
	selector: 'app-agent-local-trip-discription-report',
	templateUrl: './agent-local-trip-popup.component.html',
	styleUrls: ['./agent-local-trip-popup.component.css']
})
export class AgentLocalTripPopupComponent implements OnInit {

	agentLocalTripDataPopupList: any;
	onDestroyUnsubscribtionMemoLessDescription: Subscription;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerAgentLocalTripPopupDetails: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsAgentLocalTripPopupDetails: any;

	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;


	showSpinnerForAction = false;

	model: NgbDateStruct;
	model2;

	loadingIndicator = true;
	lrdtoForAgentLocalTripLocalStorage: any;
	//cashMemoDtoLocalStorage:CashMemoDto = new CashMemoDto();
	//cashMemoDto:CashMemoDto = new CashMemoDto();
	lrDto: LRDto = new LRDto();


	//For Custom Print
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingV2: any;
	cashMemoDtoForCustomPrintListHeadingV3: any;
	cashMemoDtoForCustomPrintListHeadingV4: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;

	actWtSummary: number;
	totArtSummary: number;
	chgWtSummary: number;
	viewCustomPrintV1: boolean;
	lrdtoNewTripCreateAndPrint: LRDto = new LRDto();
	pageId = "altrpo";
	constructor(private reportService: ReportService, private router: Router,
		private masterReadService: MasterReadService,
		private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.lrdtoForAgentLocalTripLocalStorage = JSON.parse(localStorage.getItem('popupAgentLocalTrip'));
			localStorage.clear();
			console.log(this.lrdtoForAgentLocalTripLocalStorage)
			this.getAgentLocalTripDetailsList(this.lrdtoForAgentLocalTripLocalStorage);
		}

	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
				//this.dtTriggerMemoLessDescriptionDetails.next();
			});
		});
	}

	ngOnInit(): void {
		this.dtOptionsAgentLocalTripPopupDetails = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			searching: false,
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 350,
			"scrollCollapse": true,
			"paging": false,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				var totalLrs = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totalArticles = api.column(4).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var actWeight = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var chrgWeight = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				// Update footer by showing the total with the reference of the column index 
				$(api.column(0).footer()).html();
				$(api.column(1).footer()).html();
				$(api.column(2).footer()).html('Total : ' + data.length);
				$(api.column(3).footer()).html();
				$(api.column(4).footer()).html(totalArticles);
				$(api.column(5).footer()).html(actWeight);
				$(api.column(6).footer()).html(chrgWeight);


			},
		}
	}


	ngOnDestroy(): void {
		this.dtTriggerAgentLocalTripPopupDetails.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerAgentLocalTripPopupDetails.next();
	}

	getAgentLocalTripDetailsList(lrdtoForAgentLocalTripLocalStorage) {

		this.lrDto = new LRDto();

		this.lrDto.tripId = lrdtoForAgentLocalTripLocalStorage.id;
		this.reportService.getBkgLocalTripLrDetails(this.lrDto).subscribe(
			(response) => {
				$("#" + this.pageId + "agentLocalTripPopupTableId").DataTable().destroy();
				this.agentLocalTripDataPopupList = [];
				if (response.length > 0) {
					console.log(response);

					this.agentLocalTripDataPopupList = response;

					setTimeout(() => {
						//this.customPrintAgentLocalTrpPopup();
						localStorage.clear();
						this.lrdtoNewTripCreateAndPrint.tripNo = this.lrdtoForAgentLocalTripLocalStorage.localTripNumber;
						this.lrdtoNewTripCreateAndPrint.tripDate = this.lrdtoForAgentLocalTripLocalStorage.tripDateStr;
						this.lrdtoNewTripCreateAndPrint.tripDateStr = this.lrdtoForAgentLocalTripLocalStorage.tripDateStr;
						this.lrdtoNewTripCreateAndPrint.localTripNumber = this.lrdtoForAgentLocalTripLocalStorage.localTripNumber;
						this.lrdtoNewTripCreateAndPrint.areaOfDelivery = this.lrdtoForAgentLocalTripLocalStorage.deliveryarea;
						this.lrdtoNewTripCreateAndPrint.branch = this.lrdtoForAgentLocalTripLocalStorage.tripFrom;
						this.lrdtoNewTripCreateAndPrint.transportName = "NA";
						this.lrdtoNewTripCreateAndPrint.vehicleNumber = this.lrdtoForAgentLocalTripLocalStorage.vehicleNumber;
						this.lrdtoNewTripCreateAndPrint.driverName = this.lrdtoForAgentLocalTripLocalStorage.driverName;
						this.lrdtoNewTripCreateAndPrint.mobileNum = "NA";
						this.lrdtoNewTripCreateAndPrint.lrDtoEligible = this.agentLocalTripDataPopupList;

						this.lrdtoNewTripCreateAndPrint.tripDateSrc = this.lrdtoForAgentLocalTripLocalStorage.tripDate;
						this.lrdtoNewTripCreateAndPrint.eWayBillNo = this.lrdtoForAgentLocalTripLocalStorage.eWayBillNo;

						localStorage.setItem('stockTripsheetDriverPrint', JSON.stringify(this.lrdtoNewTripCreateAndPrint));
					}, 1000);

				} else {
					swal("No Records", "No Records found for this search", "info");
				}

				//this.dtTriggerAgentLocalTripPopupDetails.next();
				setTimeout(() => {
					this.dtTriggerAgentLocalTripPopupDetails.next();
				}, 3000);
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				swal("Error", "Server Problem Occurred While getting the Memo Less Description Details", "info");
			},
			() => console.log('done');
	}

	customPrintAgentLocalTrpPopup() {
		if (this.agentLocalTripDataPopupList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Booking Date", "LR Number", "Articles", "Destination", "Pvt Mark", "Unloading Marking", "Act Wt", "Chg Wt"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [10, 15, 10, 18, 10, 15, 11, 11];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];

			//heading logics For Date
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Date", "Vehicle", "No"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.lrdtoForAgentLocalTripLocalStorage.tripDateStr, this.lrdtoForAgentLocalTripLocalStorage.vehicleNumber, this.lrdtoForAgentLocalTripLocalStorage.localTripNumber];
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Agent Name", "Delivery Area", "Total Lrs"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.lrdtoForAgentLocalTripLocalStorage.tripFrom, this.lrdtoForAgentLocalTripLocalStorage.deliveryarea, this.agentLocalTripDataPopupList.length];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}
			this.totArtSummary = this.actWtSummary = this.chgWtSummary = 0;
			for (let i = 0; i < this.agentLocalTripDataPopupList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.agentLocalTripDataPopupList[i].bookingDateStr, this.agentLocalTripDataPopupList[i].lrNumber,
				this.agentLocalTripDataPopupList[i].totalArticles, this.agentLocalTripDataPopupList[i].destination, this.agentLocalTripDataPopupList[i].privateMarker,
					"", this.agentLocalTripDataPopupList[i].actualWeight, this.agentLocalTripDataPopupList[i].chargedWeight];
				/////////
				this.totArtSummary = this.totArtSummary + this.agentLocalTripDataPopupList[i].totalArticles;
				this.actWtSummary = this.actWtSummary + this.agentLocalTripDataPopupList[i].actualWeight;
				this.chgWtSummary = this.chgWtSummary + this.agentLocalTripDataPopupList[i].chargedWeight;
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.agentLocalTripDataPopupList.length, "", this.totArtSummary, "", "", "", this.actWtSummary, this.chgWtSummary];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
				this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
			localStorage.setItem('printTitle', "Local Booking LR Details");
			//window.open('/report/cashmemo-report/customDynamicPrintV1', '_blank');
			//window.open('/srd/report/cashmemo-report/customDynamicPrintV1', '_blank');
			/* this.viewCustomPrintV1 = true;
			 window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});*/



		}
	}

}

