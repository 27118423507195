import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
const my = new Date();
import { LRDto } from "src/app/dto/LR-dto";
import { ReportService } from "src/app/dataService/report-service";
import { DatePipe } from "@angular/common";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, merge } from "rxjs";
import { MasterReadService } from "src/app/dataService/masterread-service";
import swal from "sweetalert";
import { Router } from "@angular/router";
import *  as moment from 'moment';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';//for the select option with filter ends

@Component({
    selector: 'app-booking-agent-report',
    templateUrl: './booking-agent-report.component.html',
    styleUrls: ['./booking-agent-report.component.css']
})
export class BookingAgentReportComponent implements OnInit {


    agentTypeSubAgent = true;
    agentTypeMainAgent = false;

    searchByDestinationWise = true;
    searchBySummaryDetails = false;
    searchByToPayReport = false;
    searchByPaidReport = false;
    searchByAgentWiseBookingReport = false;
    searchByAgentWiseBookingReportItemWise = false;
    reorderable = true;
    getDataAgentReportDestinationWiseDetailsDataListTable: any;
    getDataAgentReportSummaryDetailsTable: any;
    getDataAgentToPayReport: any;
    getDataAgentPaidReport: any;
    getDataAgentWiseBooingReport: any;
    getDataAgentWiseBooingReportItemWise: any;

    agentReportDestinationWiseDetailsDataList: any;
    agentReportSummaryDetailsDataList: any;
    agentToPayReportDataList: any;
    agentPaidReportDataList: any;
    agentWiseBooingReportDataList: any;
    agentWiseBooingReportItemWiseDataList: any;

    onDestroyUnsubscribtionAgentReportDestinationWiseDetails: Subscription;
    onDestroyUnsubscribtionAgentReportSummaryDetails: Subscription;
    onDestroyUnsubscribtionAgentToPayReport: Subscription;
    onDestroyUnsubscribtionAgentPaidReport: Subscription;
    onDestroyUnsubscribtionAgentWiseBooingReport: Subscription;
    onDestroyUnsubscribtionAgentWiseBooingReportItemWise: Subscription;
    //for the select option with filter starts

    controlAgentName = new FormControl();
    controlSelectMainAgent = new FormControl();
    controlSource = new FormControl();
    controlDestination = new FormControl();
    model: NgbDateStruct;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerAgentReportDestinationWiseDetails: Subject<any> = new Subject();
    dtTriggerAgentReportSummaryDetails: Subject<any> = new Subject();
    dtTriggerAgentToPayReport: Subject<any> = new Subject();
    dtTriggerAgentPaidReport: Subject<any> = new Subject();
    dtTriggerAgentWiseBooingReport: Subject<any> = new Subject();
    dtTriggerAgentWiseBooingReportItemWise: Subject<any> = new Subject();
    dtOptionsAgentReportDestinationWiseDetails: any;
    dtOptionsAgentReportSummaryDetails: any;
    dtOptionsAgentToPayReport: any;
    dtOptionsAgentPaidReport: any;
    dtOptionsAgentWiseBooingReport: any;
    dtOptionsAgentWiseBooingReportItemWise: any;
    //For Sorce Main Stations
    lrDtoSourceStationAllOption: LRDto = new LRDto();
    sourceStationOptions: LRDto[];
    lrDtoSourceStation: LRDto = new LRDto();

    public modelSource: any;
    sourceSubStationNameTA: Array<LRDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceSubStationNameTA
                : this.sourceSubStationNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSource = (x: { source: string }) => x.source;

    //For Sorce Main Stations
    lrDtoDestinationStationAllOption: LRDto = new LRDto();
    destinationStationOptions: LRDto[];
    lrDtoDestinationStation: LRDto = new LRDto();

    public modelDestination: any;
    destinationSubStationNameTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationSubStationNameTA
                : this.destinationSubStationNameTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    selectedFromDate: any;
    selectedToDate: any;
    userDataDtoReturnSession: any;
    destinationDtoForAll: LRDto = new LRDto();
    destinationList: Array<any> = [];
    showSpinnerForAction = false;
    lrDtoFromStn: LRDto = new LRDto();
    lrDtoFromStnAll: LRDto = new LRDto();
    lrDtosFromStnOptionsGet: LRDto[];
    isLoggedIn = true;
    setTodayDateOnFromDate: any;
    setTodayDateOnToDate: any;
    lrDtoToStn: LRDto = new LRDto();
    lrDtoToStnAll: LRDto = new LRDto();
    lrDtosToStnOptionsGet: LRDto[];
    srcListsSet: Array<any> = [];
    srcList: LRDto = new LRDto;
    srcLists: any;
    lrDtoSourceAddOption: LRDto = new LRDto();
    adminStationSourceHireSlipDto: HireSlipDto = new HireSlipDto();
    adminStationSourceOption: HireSlipDto[];
    showSrc = false;
    lrDtoForSubAgent: LRDto = new LRDto();
    lrDtoForMainAgent: LRDto = new LRDto();
    //For Sub Agent 
    lrDtoSubStationNameAllOption: LRDto = new LRDto();
    subStationNameStationOptions: LRDto[];
    lrDtoSubStationName: LRDto = new LRDto();
    public modelSubStationName: any;
    subStationNameTA: Array<LRDto> = [];
    focusSubAgentNameTA$ = new Subject<string>();
    searchSubAgentName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSubAgentNameTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.subStationNameTA
                : this.subStationNameTA.filter(v => v.agentName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSubAgentName = (x: { agentName: string }) => x.agentName;

    //For Main Agent
    lrDtoMainStationNameAllOption: LRDto = new LRDto();
    mainStationNameStationOptions: LRDto[];
    lrDtoMainStationName: LRDto = new LRDto();
    public modelMainAgentName: any;
    mainStationNameTA: Array<LRDto> = [];
    focusMainAgentNameTA$ = new Subject<string>();
    searchMainAgentName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMainAgentNameTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.mainStationNameTA
                : this.mainStationNameTA.filter(v => v.agentName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMainAgentName = (x: { agentName: string }) => x.agentName;
    agentReportDestinationWiseDetailsTable = true;
    agentToPayReportDataListTable = false;
    agentPaidReportDataListTable = false;
    agentWiseBooingReportDataListTable = false;
    itemWiseTable = false;
    agentReportSummaryDetaLilsTable = false;
    selectedSource: any;
    selectedDestination: any;
    selectedMainAgentName: any;
    selectedSubAgentName: any;
    selectedSearchBy: any;
    selectedAgentType: any;
    lrDtoForBtnSearch: LRDto = new LRDto();
    searchSrcListInLoop: Array<any> = [];
    sentSrcList: Array<any> = [];
    viewCustomPrintV1 = false;
    grandActWt = 0;
    grandChWt = 0;
    grandToPay = 0;
    grandPaid = 0;
    grandTotalLRVal = 0;
    grandTotalArtVal = 0;
    grandTotalLCChg = 0;
    //For Custom Print
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    pageId = "bkgar";

    constructor(private reportService: ReportService, private router: Router, private masterReadService: MasterReadService,
        private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.gettingToStationList();
            this.selectTodayDate();
            this.gettingSubAgent();
            this.gettingMainAgent();
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Source BkgAgtRpt") {
                        this.showSrc = true;
                    }
                }
            }
        }
        if (this.showSrc == true) {
            if (this.userDataDtoReturnSession.role == "Super Administrator") {
                this.gettingFromStationList();
            } else {
                if (this.userDataDtoReturnSession.stationList != null && this.userDataDtoReturnSession.stationList != '') {
                    this.getSourceList();
                } else {
                    this.setAdminStationSourceDetails();
                }
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    selectTodayDate() {
        this.setTodayDateOnToDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        this.setTodayDateOnFromDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };

        $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
        $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
    }
    ngOnInit(): void {
        this.dtOptionsAgentReportDestinationWiseDetails = {
            dom: "Bfrtip",
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,

                title: function () {
                    var returnDest = null;
                    if ($("#bkgaragentType").val() == 'subAgent') {
                        returnDest = "Agent Report Destination Wise " +
                            " From Date : " + moment($("#bkgarfromDate").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#bkgartoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#bkgarsubAgentName").val();
                        return returnDest;
                    } else {
                        returnDest = "Agent Report Destination Wise " +
                            " From Date : " + moment($("#bkgarfromDate").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#bkgartoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#bkgarmainAgentName").val();
                        return returnDest;
                    }
                }
            }],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search...",
            },

            processing: true,
            //scroll in datatable starts
            responsive: true,
            scrollX: true,
            scrollY: 380,
            scrollCollapse: true,

            paging: false,
            info: false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var actWgt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var chgWgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var art = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);



                var toPay = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var paid = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var lcChg = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);



                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html();
                $(api.column(2).footer()).html(actWgt);
                $(api.column(3).footer()).html(chgWgt);
                $(api.column(4).footer()).html(art);
                $(api.column(5).footer()).html(toPay);
                $(api.column(6).footer()).html(paid);
                $(api.column(7).footer()).html(lcChg);



            }
        },

            this.dtOptionsAgentReportSummaryDetails = {

                dom: "Bfrtip",

                buttons: [{
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,

                    title: function () {

                        var returnSummary = null;
                        if ($("#bkgaragentType").val() == 'subAgent') {
                            returnSummary = "Agent Report Summary" +
                                " From Date : " + moment($("#bkgarfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#bkgartoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#bkgarsubAgentName").val();
                            return returnSummary;
                        } else {
                            returnSummary = "Agent Report Summary" +
                                " From Date : " + moment($("#bkgarfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#bkgartoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#bkgarmainAgentName").val();
                            return returnSummary;
                        }


                    }
                }],

                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search...",
                },

                processing: true,
                //scroll in datatable starts
                responsive: true,
                scrollX: true,
                scrollY: 380,
                scrollCollapse: true,
                paging: false,
                info: false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };

                    var actWgt = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    var chgWgt = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    var art = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);



                    var toPay = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var paid = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    var lcChg = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);



                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(actWgt);
                    $(api.column(2).footer()).html(chgWgt);
                    $(api.column(3).footer()).html(art);
                    $(api.column(4).footer()).html(toPay);
                    $(api.column(5).footer()).html(paid);
                    $(api.column(6).footer()).html(lcChg);



                }
            },
            this.dtOptionsAgentToPayReport = {

                dom: "Bfrtip",

                buttons: [{
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,

                    title: function () {

                        var returnToPay = null;
                        if ($("#bkgaragentType").val() == 'subAgent') {
                            returnToPay = "To Pay Report For " + $("#bkgarsubAgentName").val() + " Station" +
                                " From Date : " + moment($("#bkgarfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#bkgartoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#bkgarsubAgentName").val();
                            return returnToPay;
                        } else {
                            returnToPay = "To Pay Report For " + $("#bkgarmainAgentName").val() + " Station" +
                                " From Date : " + moment($("#bkgarfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#bkgartoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#bkgarmainAgentName").val();
                            return returnToPay;
                        }


                    }
                }],

                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search...",
                },

                processing: true,
                //scroll in datatable starts
                responsive: true,
                scrollX: true,
                scrollY: 380,
                scrollCollapse: true,

                paging: false,
                info: false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };

                    var toPay = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var lcChg = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);



                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html();
                    $(api.column(2).footer()).html();
                    $(api.column(3).footer()).html();
                    $(api.column(4).footer()).html();
                    $(api.column(5).footer()).html();
                    $(api.column(6).footer()).html(toPay);
                    $(api.column(7).footer()).html(lcChg);



                }
            },

            this.dtOptionsAgentPaidReport = {

                dom: "Bfrtip",

                buttons: [{
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,

                    title: function () {

                        var returnPaid = null;
                        if ($("#bkgaragentType").val() == 'subAgent') {
                            returnPaid = "Paid Report For " + $("#bkgarsubAgentName").val() + " Station" +
                                " From Date : " + moment($("#bkgarfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#bkgartoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#bkgarsubAgentName").val();
                            return returnPaid;
                        } else {
                            returnPaid = "Paid Report For " + $("#bkgarmainAgentName").val() + " Station" +
                                " From Date : " + moment($("#bkgarfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#bkgartoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#bkgarmainAgentName").val();
                            return returnPaid;
                        }


                    }
                }],

                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search...",
                },

                processing: true,
                //scroll in datatable starts
                responsive: true,
                scrollX: true,
                scrollY: 380,
                scrollCollapse: true,
                paging: false,
                info: false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var paid = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    var lcChg = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);



                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html();
                    $(api.column(2).footer()).html();
                    $(api.column(3).footer()).html();
                    $(api.column(4).footer()).html();
                    $(api.column(5).footer()).html(paid);
                    $(api.column(6).footer()).html(lcChg);



                }
            },
            this.dtOptionsAgentWiseBooingReport = {

                dom: "Bfrtip",

                buttons: [{
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,

                    title: function () {

                        var returnAgent = null;
                        if ($("#bkgaragentType").val() == 'subAgent') {
                            returnAgent = "Commodity Item Wise Details Report" +
                                " From Date : " + moment($("#bkgarfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#bkgartoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#bkgarsubAgentName").val();
                            return returnAgent;
                        } else {
                            returnAgent = "Commodity Item Wise Details Report" +
                                " From Date : " + moment($("#bkgarfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#bkgartoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#bkgarmainAgentName").val();
                            return returnAgent;
                        }


                    }
                }],

                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search...",
                },

                processing: true,
                //scroll in datatable starts
                responsive: true,
                scrollX: true,
                scrollY: 380,
                scrollCollapse: true,

                paging: false,
                info: false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };

                    var actWgt = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    var chgWgt = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    var art = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    var lcChg = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);



                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(actWgt);
                    $(api.column(2).footer()).html();
                    $(api.column(3).footer()).html(chgWgt);
                    $(api.column(4).footer()).html();
                    $(api.column(5).footer()).html(art);
                    $(api.column(6).footer()).html(lcChg);




                }
            },
            this.dtOptionsAgentWiseBooingReportItemWise = {

                dom: "Bfrtip",

                buttons: [{
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,

                    title: function () {

                        var returnAgentItem = null;
                        if ($("#bkgaragentType").val() == 'subAgent') {
                            returnAgentItem = "Commodity Item Wise Details Report" +
                                " From Date : " + moment($("#bkgarfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#bkgartoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#bkgarsubAgentName").val();
                            return returnAgentItem;
                        } else {
                            returnAgentItem = "Commodity Item Wise Details Report" +
                                " From Date : " + moment($("#bkgarfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#bkgartoDate").val()).format('DD-MM-YYYY') + " Agent Name : " + $("#bkgarmainAgentName").val();
                            return returnAgentItem;
                        }


                    }
                }],

                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search...",
                },

                processing: true,
                //scroll in datatable starts
                responsive: true,
                scrollX: true,
                scrollY: 380,
                scrollCollapse: true,

                paging: false,
                info: false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };


                    var actWgt = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    var chgWgt = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    var art = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);


                    var lcChg = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);



                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(actWgt);
                    $(api.column(2).footer()).html();
                    $(api.column(3).footer()).html(chgWgt);
                    $(api.column(4).footer()).html();
                    $(api.column(5).footer()).html(art);
                    $(api.column(6).footer()).html(lcChg);



                }
            }
    }
    ngOnDestroy(): void {
        this.dtTriggerAgentReportDestinationWiseDetails.unsubscribe();
        this.dtTriggerAgentReportSummaryDetails.unsubscribe();
        this.dtTriggerAgentToPayReport.unsubscribe();
        this.dtTriggerAgentPaidReport.unsubscribe();
        this.dtTriggerAgentWiseBooingReport.unsubscribe();
        this.dtTriggerAgentWiseBooingReportItemWise.unsubscribe();
    }
    ngAfterViewInit(): void {
        this.dtTriggerAgentReportDestinationWiseDetails.next();
        this.dtTriggerAgentReportSummaryDetails.next();
        this.dtTriggerAgentToPayReport.next();
        this.dtTriggerAgentPaidReport.next();
        this.dtTriggerAgentWiseBooingReport.next();
        this.dtTriggerAgentWiseBooingReportItemWise.next();

    }

    hideTable() {
        this.agentReportDestinationWiseDetailsTable = false;
        this.agentReportSummaryDetaLilsTable = false;
        this.agentToPayReportDataListTable = false;
        this.agentPaidReportDataListTable = false;
        this.agentWiseBooingReportDataListTable = false;
        this.itemWiseTable = false;
    }
    clearTable() {
        $("#" + this.pageId + "agentReportDestinationWiseDetailsId").DataTable().destroy();
        this.agentReportDestinationWiseDetailsDataList = [];
        this.dtTriggerAgentReportDestinationWiseDetails.next();

        $("#" + this.pageId + "agentReportSummaryDetaLilsId").DataTable().destroy();
        this.agentReportSummaryDetailsDataList = [];
        this.dtTriggerAgentReportSummaryDetails.next();

        $("#" + this.pageId + "agentToPayReportId").DataTable().destroy();
        this.agentToPayReportDataList = [];
        this.dtTriggerAgentToPayReport.next();

        $("#" + this.pageId + "agentPaidReportId").DataTable().destroy();
        this.agentPaidReportDataList = [];
        this.dtTriggerAgentPaidReport.next();

        $("#" + this.pageId + "agentWiseBooingReportId").DataTable().destroy();
        this.agentWiseBooingReportDataList = [];
        this.dtTriggerAgentWiseBooingReport.next();

        $("#" + this.pageId + "agentWiseBooingReportItemWiseId").DataTable().destroy();
        this.agentWiseBooingReportItemWiseDataList = [];
        this.dtTriggerAgentWiseBooingReportItemWise.next();

    }
    agentTypeMode(agentType: string) {
        if (agentType === 'subAgent') {
            this.agentTypeSubAgent = true;
            this.agentTypeMainAgent = false;
        } else if (agentType === 'mainAgent') {
            this.agentTypeSubAgent = false;
            this.agentTypeMainAgent = true;
        } else {
            this.agentTypeSubAgent = false;
            this.agentTypeMainAgent = false;
        }
    }


    searchByMode(searchBy: string) {
        if (searchBy === 'destinationWise') {
            this.hideTable();
            this.clearTable();
            this.agentReportDestinationWiseDetailsTable = true;
        } else if (searchBy === 'summaryDetails') {
            this.hideTable();
            this.clearTable();
            this.agentReportSummaryDetaLilsTable = true;

        } else if (searchBy === 'toPayReport') {
            this.hideTable();
            this.clearTable();
            this.agentToPayReportDataListTable = true;

        } else if (searchBy === 'paidReport') {
            this.hideTable();
            this.clearTable();
            this.agentPaidReportDataListTable = true;

        } else if (searchBy === 'agentWiseBookingReport') {

            this.hideTable();
            this.clearTable();
            this.agentWiseBooingReportDataListTable = true;

        } else if (searchBy === 'agentWiseBookingReportItemWise') {

            this.hideTable();
            this.clearTable();
            this.itemWiseTable = true;
        } else {
            this.hideTable();
            this.clearTable();
        }
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker
    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends

    gettingFromStationDetails() {
        this.lrDtoFromStn = new LRDto();
        this.lrDtoFromStn.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoFromStn.mode = "notStmtInvRpt";
    }
    gettingFromStationList = () => {
        this.showSpinnerForAction = true;
        this.gettingFromStationDetails();
        this.masterReadService.getFromStationService(this.lrDtoFromStn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Source records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {

                    this.sourceSubStationNameTA = [];
                    this.lrDtosFromStnOptionsGet = [];
                    this.lrDtosFromStnOptionsGet = response;
                    this.lrDtoFromStnAll.source = "All";
                    this.sourceSubStationNameTA.push(this.lrDtoFromStnAll);
                    this.searchSrcListInLoop = [];
                    for (let i = 0; i < this.lrDtosFromStnOptionsGet.length; i++) {
                        this.sourceSubStationNameTA.push(this.lrDtosFromStnOptionsGet[i]);

                    }
                    this.searchSrcListInLoop = [];
                    for (let i = 0; i < this.lrDtosFromStnOptionsGet.length; i++) {
                        this.searchSrcListInLoop.push(this.lrDtosFromStnOptionsGet[i].source);
                    }



                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Source Details", "warning");
            },
            () => console.log('done');
    };

    gettingToStationDetails() {
        this.lrDtoToStn = new LRDto();
        this.lrDtoToStn.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoToStn.mode = "other";
        this.lrDtoToStn.reportMode = "subStation";
        console.log(this.lrDtoToStn);

    }
    gettingToStationList = () => {
        this.showSpinnerForAction = true;
        this.gettingToStationDetails();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoToStn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No  records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {

                    this.destinationSubStationNameTA = [];
                    this.lrDtosToStnOptionsGet = [];
                    this.lrDtosToStnOptionsGet = response;
                    this.lrDtoToStnAll.destination = "All";
                    this.destinationSubStationNameTA.push(this.lrDtoToStnAll);
                    for (let i = 0; i < this.lrDtosToStnOptionsGet.length; i++) {
                        this.destinationSubStationNameTA.push(this.lrDtosToStnOptionsGet[i]);

                    }




                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Destination Details", "warning");
            },
            () => console.log('done');
    };

    getSourceList() {
        console.log('not');
        this.srcLists = this.userDataDtoReturnSession.stationList;
        this.srcList.subStations = this.srcLists;
        this.sourceSubStationNameTA = [];
        this.lrDtoFromStnAll.source = "All";
        this.sourceSubStationNameTA.push(this.lrDtoFromStnAll);
        for (let i = 0; i < this.srcList.subStations.length; i++) {
            this.lrDtoSourceAddOption = new LRDto();
            this.lrDtoSourceAddOption.source = this.srcList.subStations[i];
            this.sourceSubStationNameTA.push(this.lrDtoSourceAddOption);
        }
        this.searchSrcListInLoop = [];
        for (let i = 0; i < this.srcList.subStations.length; i++) {
            this.searchSrcListInLoop.push(this.srcList.subStations[i]);

        }
        window.setTimeout(function () {
            $("#" + this.pageId + "source").val('All');
        }, 200);


    }


    setAdminStationSourceDetails = () => {
        this.adminStationSourceHireSlipDto = new HireSlipDto();
        this.adminStationSourceHireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
        this.adminStationSourceHireSlipDto.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.masterReadService.getAdminStationsBranch(this.adminStationSourceHireSlipDto).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.sourceSubStationNameTA = [];
                    this.lrDtoFromStnAll.source = "All";
                    this.sourceSubStationNameTA.push(this.lrDtoFromStnAll);
                    swal("Warning", "No Admin Station  records found!", "warning");
                } else {

                    this.adminStationSourceOption = response;
                    this.sourceSubStationNameTA = [];
                    this.lrDtoFromStnAll.source = "All";
                    this.sourceSubStationNameTA.push(this.lrDtoFromStnAll);
                    console.log(this.adminStationSourceOption);
                    for (let i = 0; i < this.adminStationSourceOption.length; i++) {
                        this.lrDtoSourceAddOption = new LRDto();
                        this.lrDtoSourceAddOption.source = this.adminStationSourceOption[i].branch;
                        this.sourceSubStationNameTA.push(this.lrDtoSourceAddOption);
                    }
                    this.searchSrcListInLoop = [];
                    for (let i = 0; i < this.adminStationSourceOption.length; i++) {
                        this.searchSrcListInLoop.push(this.adminStationSourceOption[i].branch);

                    }



                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    gettingSubAgent() {
        this.lrDtoForSubAgent = new LRDto();
        this.lrDtoForSubAgent.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForSubAgent.mode = "Sub";
        console.log(this.lrDtoForSubAgent);
        this.gettingSubAgentList();
    }
    gettingSubAgentList = () => {
        this.showSpinnerForAction = true;
        this.gettingFromStationDetails();
        this.masterReadService.getAgentName(this.lrDtoForSubAgent).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Source records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {

                    this.subStationNameTA = [];
                    this.subStationNameStationOptions = [];
                    this.subStationNameStationOptions = response;
                    this.lrDtoSubStationNameAllOption.agentName = "All";
                    this.subStationNameTA.push(this.lrDtoSubStationNameAllOption);
                    for (let i = 0; i < this.subStationNameStationOptions.length; i++) {
                        this.subStationNameTA.push(this.subStationNameStationOptions[i]);

                    }


                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Sub Agent Name Details", "warning");
            },
            () => console.log('done');
    };

    gettingMainAgent() {

        this.lrDtoForMainAgent = new LRDto();
        this.lrDtoForMainAgent.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForMainAgent.mode = "Main";
        console.log(this.lrDtoForMainAgent);
        this.gettingMainAgentList();
    }
    gettingMainAgentList = () => {
        this.showSpinnerForAction = true;
        this.gettingFromStationDetails();
        this.masterReadService.getAgentName(this.lrDtoForMainAgent).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Source records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {

                    this.mainStationNameTA = [];
                    this.mainStationNameStationOptions = [];
                    this.mainStationNameStationOptions = response;
                    // this.lrDtoMainStationNameAllOption.agentName = "ALL";
                    // this.mainStationNameTA.push(this.lrDtoMainStationNameAllOption);
                    for (let i = 0; i < this.mainStationNameStationOptions.length; i++) {
                        this.mainStationNameTA.push(this.mainStationNameStationOptions[i]);

                    }

                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Main Agent Details", "warning");
            },
            () => console.log('done');
    };

    validateSearch() {
        this.selectedSource = $("#" + this.pageId + "source").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedMainAgentName = $("#" + this.pageId + "mainAgentName").val();
        this.selectedSubAgentName = $("#" + this.pageId + "subAgentName").val();
        this.selectedSearchBy = $("#" + this.pageId + "searchBy").val();
        this.selectedAgentType = $("#" + this.pageId + "agentType").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();

        this.lrDtoForBtnSearch = new LRDto();
        if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
            swal({
                title: "Mandatory Field",
                text: "Please select From Date,To Date  to get the  details!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        } else {
            this.lrDtoForBtnSearch.agentName = this.selectedSubAgentName;
            this.lrDtoForBtnSearch.destination = this.selectedDestination;
            this.lrDtoForBtnSearch.mainAgent = this.selectedMainAgentName;
            this.lrDtoForBtnSearch.companyId = this.userDataDtoReturnSession.companyId;
            let office = this.userDataDtoReturnSession.office.toLowerCase();
            if (this.showSrc == true) {
                if (this.selectedSource == '' || this.selectedSource == null || this.selectedSource == 'All') {
                    this.sentSrcList = [];
                    this.sentSrcList = this.searchSrcListInLoop;
                    this.lrDtoForBtnSearch.list = this.sentSrcList;
                } else {
                    this.sentSrcList = [];
                    this.sentSrcList.push(this.selectedSource);
                    this.lrDtoForBtnSearch.list = this.sentSrcList;
                }
            } else {
                this.sentSrcList = [];
                this.sentSrcList.push(this.userDataDtoReturnSession.office);
                this.lrDtoForBtnSearch.list = this.sentSrcList;
            }

            if (this.selectedAgentType == 'subAgent') {
                if (this.selectedSubAgentName == null || this.selectedSubAgentName == '' || this.selectedDestination == null || this.selectedDestination == '') {
                    swal({
                        title: "Mandatory Field",
                        text: "Agent Name And  Destination Are Mandatory Fields",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDtoForBtnSearch.fromDate = this.selectedFromDate;
                    this.lrDtoForBtnSearch.toDate = this.selectedToDate;
                    if (this.selectedSearchBy == 'destinationWise') {
                        this.lrDtoForBtnSearch.agentId = "Id";
                        this.lrDtoForBtnSearch.reportMode = "Detail";
                        this.searchByDestinationWiseSubAgent();
                    } else if (this.selectedSearchBy == 'summaryDetails') {
                        this.lrDtoForBtnSearch.reportMode = "Summary";
                        this.searchBySummaryWiseSubAgent();
                    } else if (this.selectedSearchBy == 'toPayReport') {
                        this.lrDtoForBtnSearch.mode = "ToPay";
                        this.lrDtoForBtnSearch.reportMode = "Detail";
                        this.searchByToPayWiseSubAgent();
                    } else if (this.selectedSearchBy == 'paidReport') {
                        this.lrDtoForBtnSearch.mode = "Paid";
                        this.lrDtoForBtnSearch.reportMode = "Detail";
                        this.searchByPaidWiseSubAgent();
                    } else if (this.selectedSearchBy == 'agentWiseBookingReport') {
                        this.lrDtoForBtnSearch.reportMode = "NotSummary";
                        this.searchByAgentWiseBookingRptSummary();
                    } else if (this.selectedSearchBy == 'agentWiseBookingReportItemWise') {
                        this.lrDtoForBtnSearch.reportMode = "Summary";
                        this.searchByAgentWiseBookingItemWiseRptSummary();
                    }
                }
            } else {
                if (this.selectedMainAgentName == null || this.selectedMainAgentName == '' || this.selectedDestination == null || this.selectedDestination == '') {
                    swal({
                        title: "Mandatory Field",
                        text: "Main Agent Name  Are Mandatory Fields",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDtoForBtnSearch.fromDate = this.selectedFromDate;
                    this.lrDtoForBtnSearch.toDate = this.selectedToDate;
                    if (this.selectedSearchBy == 'destinationWise') {
                        this.lrDtoForBtnSearch.mode = "dest";
                        this.searchByDestinationWiseMainAgent();
                    } else if (this.selectedSearchBy == 'summaryDetails') {
                        this.lrDtoForBtnSearch.mode = "summ";
                        this.searchBySummaryWiseMainAgent();
                    } else if (this.selectedSearchBy == 'toPayReport') {
                        this.lrDtoForBtnSearch.mode = "toPay";
                        this.searchByToPayWiseMainAgent();
                    } else if (this.selectedSearchBy == 'paidReport') {
                        this.lrDtoForBtnSearch.mode = "paid";
                        this.searchByPaidWiseMainAgent();
                    } else if (this.selectedSearchBy == 'agentWiseBookingReport') {
                        this.lrDtoForBtnSearch.mode = "comm";
                        this.searchByAgentWiseBookingRptSummaryMainAgent();
                    } else if (this.selectedSearchBy == 'agentWiseBookingReportItemWise') {
                        this.lrDtoForBtnSearch.mode = "comItem";
                        this.searchByAgentWiseBookingItemWiseRptSummaryMainAgent();
                    }
                }
            }
            console.log(this.lrDtoForBtnSearch);
        }
    }

    searchByDestinationWiseSubAgent = () => {
        this.showSpinnerForAction = true;
        this.reportService.getAgentDestinationWise(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "agentReportDestinationWiseDetailsId").DataTable().destroy();
                this.agentReportDestinationWiseDetailsDataList = [];
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.agentReportDestinationWiseDetailsDataList = response;
                }
                this.dtTriggerAgentReportDestinationWiseDetails.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Agent   Report ", "info");
            }, () => console.log('done');
    };
    searchBySummaryWiseSubAgent = () => {
        this.showSpinnerForAction = true;
        this.reportService.getAgentDestinationWise(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "agentReportSummaryDetaLilsId").DataTable().destroy();
                this.agentReportSummaryDetailsDataList = [];

                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.agentReportSummaryDetailsDataList = response;
                }
                this.dtTriggerAgentReportSummaryDetails.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Agent   Report ", "info");
            }, () => console.log('done');
    };



    searchByToPayWiseSubAgent = () => {
        this.showSpinnerForAction = true;
        this.reportService.getAgentDestinationWise(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "agentToPayReportId").DataTable().destroy();
                this.agentToPayReportDataList = [];


                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.agentToPayReportDataList = response;
                }
                this.dtTriggerAgentToPayReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Agent   Report ", "info");
            }, () => console.log('done');
    };

    searchByPaidWiseSubAgent = () => {
        this.showSpinnerForAction = true;
        this.reportService.getAgentDestinationWise(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "agentPaidReportId").DataTable().destroy();
                this.agentPaidReportDataList = [];



                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.agentPaidReportDataList = response;
                }
                this.dtTriggerAgentPaidReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Agent   Report ", "info");
            }, () => console.log('done');
    };

    searchByAgentWiseBookingRptSummary = () => {
        this.showSpinnerForAction = true;
        this.reportService.getBookingAgentCommodityReport(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "agentWiseBooingReportId").DataTable().destroy();
                this.agentWiseBooingReportDataList = [];



                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.agentWiseBooingReportDataList = response;
                }
                this.dtTriggerAgentWiseBooingReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Agent   Report ", "info");
            }, () => console.log('done');
    };


    searchByAgentWiseBookingItemWiseRptSummary = () => {
        this.showSpinnerForAction = true;
        this.reportService.getBookingAgentCommodityReport(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "agentWiseBooingReportItemWiseId").DataTable().destroy();
                this.agentWiseBooingReportItemWiseDataList = [];

                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.agentWiseBooingReportItemWiseDataList = response;
                }
                this.dtTriggerAgentWiseBooingReportItemWise.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Agent   Report ", "info");
            }, () => console.log('done');
    };

    searchByDestinationWiseMainAgent = () => {
        this.showSpinnerForAction = true;
        this.reportService.getMainBkgAgentReport(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "agentReportDestinationWiseDetailsId").DataTable().destroy();
                this.agentReportDestinationWiseDetailsDataList = [];


                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.agentReportDestinationWiseDetailsDataList = response;
                }
                this.dtTriggerAgentReportDestinationWiseDetails.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Agent   Report ", "info");
            }, () => console.log('done');
    };

    searchBySummaryWiseMainAgent = () => {
        this.showSpinnerForAction = true;
        this.reportService.getMainBkgAgentReport(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "agentReportSummaryDetaLilsId").DataTable().destroy();
                this.agentReportSummaryDetailsDataList = [];

                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.agentReportSummaryDetailsDataList = response;
                }
                this.dtTriggerAgentReportSummaryDetails.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Agent   Report ", "info");
            }, () => console.log('done');
    };

    searchByToPayWiseMainAgent = () => {
        this.showSpinnerForAction = true;
        this.reportService.getMainBkgAgentReport(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "agentToPayReportId").DataTable().destroy();
                this.agentToPayReportDataList = [];


                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.agentToPayReportDataList = response;
                }
                this.dtTriggerAgentToPayReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Agent   Report ", "info");
            }, () => console.log('done');
    };

    searchByPaidWiseMainAgent = () => {
        this.showSpinnerForAction = true;
        this.reportService.getMainBkgAgentReport(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "agentPaidReportId").DataTable().destroy();
                this.agentPaidReportDataList = [];



                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.agentPaidReportDataList = response;
                }
                this.dtTriggerAgentPaidReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Agent   Report ", "info");
            }, () => console.log('done');
    };

    searchByAgentWiseBookingRptSummaryMainAgent = () => {
        this.showSpinnerForAction = true;
        this.reportService.getMainBkgAgentReport(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "agentWiseBooingReportId").DataTable().destroy();
                this.agentWiseBooingReportDataList = [];



                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.agentWiseBooingReportDataList = response;
                }
                this.dtTriggerAgentWiseBooingReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Agent   Report ", "info");
            }, () => console.log('done');
    };


    searchByAgentWiseBookingItemWiseRptSummaryMainAgent = () => {
        this.showSpinnerForAction = true;
        this.reportService.getMainBkgAgentReport(this.lrDtoForBtnSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "agentWiseBooingReportItemWiseId").DataTable().destroy();
                this.agentWiseBooingReportItemWiseDataList = [];

                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.agentWiseBooingReportItemWiseDataList = response;
                }
                this.dtTriggerAgentWiseBooingReportItemWise.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Agent   Report ", "info");
            }, () => console.log('done');
    };

    pritnDetailsWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedAgentType = $("#" + this.pageId + "agentType").val();
        this.selectedMainAgentName = $("#" + this.pageId + "mainAgentName").val();
        this.selectedSubAgentName = $("#" + this.pageId + "subAgentName").val();

        if (this.agentReportDestinationWiseDetailsDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];


            this.cashMemoDtoForCustomPrintListColumnNames = ["LR Number", "Destination", "Act. Wgt", "Chg. Wgt", "Art", "To Pay", "Paid", "Lc Charge"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [20, 20, 10, 10, 10, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandToPay = 0;
            this.grandPaid = 0;
            this.grandTotalArtVal = 0;
            this.grandTotalLCChg = 0;


            for (let i = 0; i < this.agentReportDestinationWiseDetailsDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.agentReportDestinationWiseDetailsDataList[i].lrNumber, this.agentReportDestinationWiseDetailsDataList[i].destination, this.agentReportDestinationWiseDetailsDataList[i].actualWeight, this.agentReportDestinationWiseDetailsDataList[i].chargedWeight, this.agentReportDestinationWiseDetailsDataList[i].totalArticles, this.agentReportDestinationWiseDetailsDataList[i].toPay, this.agentReportDestinationWiseDetailsDataList[i].paid, this.agentReportDestinationWiseDetailsDataList[i].lcChg];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandToPay = +this.grandToPay + +this.agentReportDestinationWiseDetailsDataList[i].toPay;
                this.grandPaid = +this.grandPaid + +this.agentReportDestinationWiseDetailsDataList[i].paid;
                this.grandTotalArtVal = +this.grandTotalArtVal + +this.agentReportDestinationWiseDetailsDataList[i].totalArticles;
                this.grandActWt = +this.grandActWt + +this.agentReportDestinationWiseDetailsDataList[i].actualWeight;
                this.grandChWt = +this.grandChWt + +this.agentReportDestinationWiseDetailsDataList[i].chargedWeight;
                this.grandTotalLCChg = +this.grandTotalLCChg + +this.agentReportDestinationWiseDetailsDataList[i].lcChg;

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.agentReportDestinationWiseDetailsDataList.length, "", this.grandActWt, this.grandChWt, this.grandTotalArtVal, this.grandToPay, this.grandPaid, this.grandTotalLCChg];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Agent Name"];
            if (this.selectedAgentType == 'subAgent') {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSubAgentName];
            } else {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedMainAgentName];
            }


            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Agent Report Destination Wise");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }


    pritnSummaryWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedAgentType = $("#" + this.pageId + "agentType").val();
        this.selectedMainAgentName = $("#" + this.pageId + "mainAgentName").val();
        this.selectedSubAgentName = $("#" + this.pageId + "subAgentName").val();

        if (this.agentReportSummaryDetailsDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];


            this.cashMemoDtoForCustomPrintListColumnNames = ["Station", "Actual Weight", "Charged Weight", "Articles", "To Pay", "Paid", "Lc Charge"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [20, 20, 20, 10, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandToPay = 0;
            this.grandPaid = 0;
            this.grandTotalArtVal = 0;
            this.grandTotalLCChg = 0;


            for (let i = 0; i < this.agentReportSummaryDetailsDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.agentReportSummaryDetailsDataList[i].destination, this.agentReportSummaryDetailsDataList[i].actualWeight, this.agentReportSummaryDetailsDataList[i].chargedWeight, this.agentReportSummaryDetailsDataList[i].totalArticles, this.agentReportSummaryDetailsDataList[i].toPay, this.agentReportSummaryDetailsDataList[i].paid, this.agentReportSummaryDetailsDataList[i].lcChg];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandToPay = +this.grandToPay + +this.agentReportSummaryDetailsDataList[i].toPay;
                this.grandPaid = +this.grandPaid + +this.agentReportSummaryDetailsDataList[i].paid;
                this.grandTotalArtVal = +this.grandTotalArtVal + +this.agentReportSummaryDetailsDataList[i].totalArticles;
                this.grandActWt = +this.grandActWt + +this.agentReportSummaryDetailsDataList[i].actualWeight;
                this.grandChWt = +this.grandChWt + +this.agentReportSummaryDetailsDataList[i].chargedWeight;
                this.grandTotalLCChg = +this.grandTotalLCChg + +this.agentReportSummaryDetailsDataList[i].lcChg;

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.agentReportSummaryDetailsDataList.length, this.grandActWt, this.grandChWt, this.grandTotalArtVal, this.grandToPay, this.grandPaid, this.grandTotalLCChg];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Agent Name"];
            if (this.selectedAgentType == 'subAgent') {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSubAgentName];
            } else {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedMainAgentName];
            }


            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Agent Report Summary");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    pritnToPayWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedAgentType = $("#" + this.pageId + "agentType").val();
        this.selectedMainAgentName = $("#" + this.pageId + "mainAgentName").val();
        this.selectedSubAgentName = $("#" + this.pageId + "subAgentName").val();
        let agentname = '';

        if (this.agentToPayReportDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];


            this.cashMemoDtoForCustomPrintListColumnNames = ["Destination", "LR Number", "Booking Date", "Invoice Number", "Consignor Name", "Consignor GST No", "To Pay", "Lc Charge"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [20, 10, 10, 10, 20, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];


            this.grandToPay = 0;
            this.grandTotalLCChg = 0;



            for (let i = 0; i < this.agentToPayReportDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.agentToPayReportDataList[i].destination, this.agentToPayReportDataList[i].lrNumber, this.agentToPayReportDataList[i].bookingDateStr, this.agentToPayReportDataList[i].invoiceNumber, this.agentToPayReportDataList[i].consignorName, this.agentToPayReportDataList[i].gstNoConsignor, this.agentToPayReportDataList[i].toPay, this.agentToPayReportDataList[i].lcChg];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);


                this.grandToPay = +this.grandToPay + +this.agentToPayReportDataList[i].toPay;
                this.grandTotalLCChg = +this.grandTotalLCChg + +this.agentToPayReportDataList[i].lcChg;

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.agentToPayReportDataList.length, "", "", "", "", "", this.grandToPay, this.grandTotalLCChg];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Agent Name"];
            if (this.selectedAgentType == 'subAgent') {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSubAgentName];
                agentname = this.selectedSubAgentName;
            } else {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedMainAgentName];
                agentname = this.selectedMainAgentName;
            }


            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "To Pay Report For " + agentname + " Station ");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    pritnPaidWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedAgentType = $("#" + this.pageId + "agentType").val();
        this.selectedMainAgentName = $("#" + this.pageId + "mainAgentName").val();
        this.selectedSubAgentName = $("#" + this.pageId + "subAgentName").val();
        let agentname = '';

        if (this.agentPaidReportDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];


            this.cashMemoDtoForCustomPrintListColumnNames = ["Destination", "LR Number", "Booking Date", "Invoice Number", "Consignor Name", "Paid", "Lc Charge"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [20, 10, 10, 10, 30, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];


            this.grandToPay = 0;
            this.grandTotalLCChg = 0;



            for (let i = 0; i < this.agentPaidReportDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.agentPaidReportDataList[i].destination, this.agentPaidReportDataList[i].lrNumber, this.agentPaidReportDataList[i].bookingDateStr, this.agentPaidReportDataList[i].invoiceNumber, this.agentPaidReportDataList[i].consignorName, this.agentPaidReportDataList[i].paid, this.agentPaidReportDataList[i].lcChg];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);


                this.grandPaid = +this.grandPaid + +this.agentPaidReportDataList[i].paid;
                this.grandTotalLCChg = +this.grandTotalLCChg + +this.agentPaidReportDataList[i].lcChg;

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.agentPaidReportDataList.length, "", "", "", "", this.grandPaid, this.grandTotalLCChg];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Agent Name"];
            if (this.selectedAgentType == 'subAgent') {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSubAgentName];
                agentname = this.selectedSubAgentName;
            } else {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedMainAgentName];
                agentname = this.selectedMainAgentName;
            }


            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Paid Report For " + agentname + " Station ");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    pritnAgentBkgWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedAgentType = $("#" + this.pageId + "agentType").val();
        this.selectedMainAgentName = $("#" + this.pageId + "mainAgentName").val();
        this.selectedSubAgentName = $("#" + this.pageId + "subAgentName").val();

        if (this.agentWiseBooingReportDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];

            this.cashMemoDtoForCustomPrintListColumnNames = ["Booking Item", "Actual Weight", "% Of Booking", "Charged Weight", "% Of Booking", "Articles", "Lc Charge"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [20, 20, 10, 20, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandActWt = 0;
            this.grandChWt = 0;

            this.grandTotalArtVal = 0;
            this.grandTotalLCChg = 0;


            for (let i = 0; i < this.agentWiseBooingReportDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.agentWiseBooingReportDataList[i].destination, this.agentWiseBooingReportDataList[i].actualWeight, this.agentWiseBooingReportDataList[i].actWtPercent, this.agentWiseBooingReportDataList[i].chargedWeight, this.agentWiseBooingReportDataList[i].chgWtPercent, this.agentWiseBooingReportDataList[i].totalArticles, this.agentWiseBooingReportDataList[i].lcChg];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandActWt = +this.grandActWt + +this.agentWiseBooingReportDataList[i].actualWeight;
                this.grandChWt = +this.grandChWt + +this.agentWiseBooingReportDataList[i].chargedWeight;
                this.grandTotalArtVal = +this.grandTotalArtVal + +this.agentWiseBooingReportDataList[i].totalArticles;
                this.grandTotalLCChg = +this.grandTotalLCChg + +this.agentWiseBooingReportDataList[i].lcChg;

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.agentWiseBooingReportDataList.length, this.grandActWt, "", this.grandChWt, "", this.grandTotalArtVal, this.grandTotalLCChg];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Agent Name"];
            if (this.selectedAgentType == 'subAgent') {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSubAgentName];
            } else {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedMainAgentName];
            }


            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Commodity Item Wise Details Report");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    pritnAgentBkgItemWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedAgentType = $("#" + this.pageId + "agentType").val();
        this.selectedMainAgentName = $("#" + this.pageId + "mainAgentName").val();
        this.selectedSubAgentName = $("#" + this.pageId + "subAgentName").val();

        if (this.agentWiseBooingReportItemWiseDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];

            this.cashMemoDtoForCustomPrintListColumnNames = ["Booking Item", "Actual Weight", "% Of Booking", "Charged Weight", "% Of Booking", "Articles", "Lc Charge"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [20, 20, 10, 20, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandActWt = 0;
            this.grandChWt = 0;

            this.grandTotalArtVal = 0;
            this.grandTotalLCChg = 0;


            for (let i = 0; i < this.agentWiseBooingReportItemWiseDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.agentWiseBooingReportItemWiseDataList[i].destination, this.agentWiseBooingReportItemWiseDataList[i].actualWeight, this.agentWiseBooingReportItemWiseDataList[i].actWtPercent, this.agentWiseBooingReportItemWiseDataList[i].chargedWeight, this.agentWiseBooingReportItemWiseDataList[i].chgWtPercent, this.agentWiseBooingReportItemWiseDataList[i].totalArticles, this.agentWiseBooingReportItemWiseDataList[i].lcChg];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.grandActWt = +this.grandActWt + +this.agentWiseBooingReportItemWiseDataList[i].actualWeight;
                this.grandChWt = +this.grandChWt + +this.agentWiseBooingReportItemWiseDataList[i].chargedWeight;
                this.grandTotalArtVal = +this.grandTotalArtVal + +this.agentWiseBooingReportItemWiseDataList[i].totalArticles;
                this.grandTotalLCChg = +this.grandTotalLCChg + +this.agentWiseBooingReportItemWiseDataList[i].lcChg;

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.agentWiseBooingReportItemWiseDataList.length, this.grandActWt, "", this.grandChWt, "", this.grandTotalArtVal, this.grandTotalLCChg];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Agent Name"];
            if (this.selectedAgentType == 'subAgent') {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSubAgentName];
            } else {
                this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedMainAgentName];
            }


            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Commodity Item Wise Details Report");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }
    clearAll() {
        this.selectedSource = '';
        $("#" + this.pageId + "source").val('');
        this.selectedDestination = '';
        $("#" + this.pageId + "destination").val('');
        this.selectedMainAgentName = '';
        $("#" + this.pageId + "mainAgentName").val('');
        this.selectedSubAgentName = '';
        $("#" + this.pageId + "subAgentName").val('');
        this.selectedSearchBy = '';
        $("#" + this.pageId + "searchBy").val('destinationWise');
        this.selectedAgentType = '';
        $("#" + this.pageId + "agentType").val('subAgent');
        this.agentTypeSubAgent = true;
        this.agentTypeMainAgent = false;
        this.selectedToDate = '';
        this.selectedFromDate = '';
        this.lrDtoForBtnSearch = new LRDto();

        this.selectTodayDate();
        this.hideTable();
        this.clearTable();
        this.agentReportDestinationWiseDetailsTable = true;

        this.grandActWt = 0;
        this.grandChWt = 0;
        this.grandToPay = 0;
        this.grandPaid = 0;
        this.grandTotalLRVal = 0;
        this.grandTotalArtVal = 0;
        this.grandTotalLCChg = 0;
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrintList = [];
        this.cashMemoDtoForCustomPrintListColumnNames = [];
        this.cashMemoDtoForCustomPrintListColumnWidths = [];
        this.cashMemoDtoForCustomPrintDataList = [];
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [];
        this.cashMemoDtoForCustomPrintDataSummaryList = [];
        this.viewCustomPrintV1 = false;
        this.cashMemoDtoForCustomPrintListHeadingV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [];
        this.selectTodayDate();
        this.showSpinnerForAction = false;
    }
}
