import { Component, OnInit, ViewChildren, QueryList, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//for datatable starts

import { DataTableDirective } from "angular-datatables";
//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//from the particular global folder starts
//import { SuplierService } from './supplier-master-service';
import { DashboardService } from 'src/app/dataService/dashboard-service';
//from the particular global folder ends
import { ElementRef } from "@angular/core";
import { Subject, Subscription } from 'rxjs';

//for datatable ends
//for redirect to other page starts
import { Router } from '@angular/router';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import swal from 'sweetalert';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
//for redirect to other page ends
@Component( {
    selector: 'app-srd-labour-hamali-statement',
    templateUrl: './srd-labour-hamali-statement.component.html',
    styleUrls: ['./srd-labour-hamali-statement.component.css']
} )
export class SrdLabourHamaliStatementComponent implements  OnInit {

    srdLabourHamaliStatementDataList: any;
    dtTriggerSrdLabourHamaliStatement: Subject<any> = new Subject();
    dtOptionsSrdLabourHamaliStatement: any;
    
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    hireSlipDtoLabourCommission: HireSlipDto = new HireSlipDto();
pageId="slhsc";
    constructor(private router: Router,private destStmtReport : DestStmtReport, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //sweet alert ends
        }
        if (this.isLoggedIn) {
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }

    }

    ngOnInit(): void {
        this.dtOptionsSrdLabourHamaliStatement = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
    }

    getLabourHamaliPendingStmtDetailsUserValues() {
		this.hireSlipDtoLabourCommission = new HireSlipDto();
		this.hireSlipDtoLabourCommission.status = "Pending";
		this.hireSlipDtoLabourCommission.mainStation = this.userDataDtoReturnSession.mainStation;
	}
    getLabourHamaliPendingStmtDetails = () => {
		this.showSpinnerForAction = true;
		this.getLabourHamaliPendingStmtDetailsUserValues();
		this.destStmtReport.getLabourHamaliPendingStmtDetails(this.hireSlipDtoLabourCommission).subscribe(
			(response) => {
				//$("#"+this.pageId+"labourStmtTableId").DataTable().destroy();
				this.srdLabourHamaliStatementDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No Details found!",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.srdLabourHamaliStatementDataList = response;
				}
				// setTimeout(() => {
				// 	this.dtTriggerSrdLabourHamaliStatement.next();
	            // }, 1000);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Labour Statement Details", "info");
			}, () => console.log('done');
	};

    // ngOnDestroy(): void {
    //     this.dtTriggerSrdLabourHamaliStatement.unsubscribe();
    // }
    ngAfterViewInit(): void {
        this.dtTriggerSrdLabourHamaliStatement.next();
    }
    logInPage() {
      this.router.navigate(['/authentication/login']);
  }
    goToLabourHamaliStatementDetailsPage(srdLabourHamaliStatementData) {
    	localStorage.clear();
        localStorage.setItem('srdLabourHamaliStatementData', JSON.stringify(srdLabourHamaliStatementData));
        this.router.navigate( ['/dashboard/labourHamaliStatementDetails'] );
    }
}
