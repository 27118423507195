<div class="page row system_responsive" id="{{pageId}}firstPrint">
    <div class="col-md-12">

        <div>
            <div>
                <table width='100%' border='0' cellspacing='0' cellpadding='0'>
                    <tr>
                        <td style="padding-top: 10px;" width='60%' valign='top'>
                            <img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
                        </td>
                        <td style="padding-top: 10px;" width='40%' valign='top'>
                            {{address}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-6">
                <h6 style='text-align: left; font-size: large;text-transform:uppercase;'>
                    <strong>G.C Taken By:  </strong>{{collectionMan}}
                </h6>
            </div>
            <div class="col-md-6">
                <h6 style='text-align: right; font-size: large;'>
                    <strong>Date:  </strong>{{todayDate}}
                </h6>
            </div>
        </div>
        <br>
        <!--Second  row starts-->
        <!--<div class="container border">
			<table width='100%' border='0' cellpadding='0' cellspacing='0'>
				<tr>
					<td width='6%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000; border-left: 1px solid #000;'
						valign='top' type='text'><strong> Note **</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='number'><strong> LR No</strong></td>
					<td width='4%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='number'><strong> Art</strong></td>
					<td width='6%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='number'><strong> Pvt.Mrk</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Booking Date</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Booking From</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Description</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong>Consignee</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong>Consignor</strong></td>
					<td width='8%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Act Wgt</strong></td>
					<td width='8%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Chg Wgt</strong></td>
					<td width='8%' align='center'
						style='border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;border-right: 1px solid #000;'
						valign='top' type='text'><strong>Goods Value</strong></td>
				</tr>
			</table>
		</div>-->
        <!--<div class="container border">
			<div>-->
        <table width='100%' border='0' cellpadding='0' cellspacing='0' style='table-layout: fixed;'>
            <tr>
                <td width='20%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; border-left: 1px solid #000;' valign='top' type='text'><strong> Memo No</strong></td>
                <td width='20%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='number'><strong> Memo Date</strong></td>
                <td width='20%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='number'><strong> Assign Date</strong></td>
                <td width='20%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='number'><strong> Memo Amount</strong></td>
                <td width='20%' align='center' style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;' valign='top' type='text'><strong> L.R No</strong></td>
            </tr>
            <ng-container *ngFor="let item of result">
                <tr>
                    <td colspan="12" style='border: 1px solid #000;'><strong>{{item.consigneeName}}</strong></td>
                </tr>
                <tr *ngFor="let value of item.values">
                    <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
                        <span>{{value.memoNumber}}</span>
                    </td>
                    <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.memoDateStr}}</td>
                    <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.assignedDateStr}}</td>
                    <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <span>{{value.amount}}</span>
                    </td>
                    <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        {{value.lrNumber}}</td>

                </tr>
                <tr>
                    <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
                        <strong> Total </strong>
                    </td>
                    <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <strong> {{item.values.length}}</strong>
                    </td>
                    <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>
                    <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                        <strong> {{item.amount}}</strong>
                    </td>
                    <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    </td>

                </tr>
            </ng-container>
            <br>
            <br>
            <tr>
                <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
                    <strong> Total </strong>
                </td>
                <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong> {{totMemoNo}}</strong>
                </td>
                <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>
                <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                    <strong> {{totMemoAmt}}</strong>
                </td>
                <td width='20%' align='center' style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
                </td>

            </tr>
        </table>
        <!--/div>-->
        <!--</div>
		</div>-->
        <!--<br>
		<div class="container border">
			<table>
				<tr>
					<td width='6%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
						<strong> Total </strong> </td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='4%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong> {{totArtNet}}</strong>
					</td>
					<td width='6%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					</td>
					<td width='8%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong>{{totActWgtNet}}</strong></td>
					<td width='8%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
						<strong>{{totChgWgtNet}}</strong></td>
					<td width='8%' align='center'
						style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-right: 1px solid #000; '>
						<strong>{{totGoodsValueNet}}</strong></td>
				</tr>
			</table>
		</div>-->


    </div>
</div>