<!--<html>
<head>
	<style>
		/* for ipad and ipad pro */
		@media (min-width : 768px) and (max-width: 1200px) {
			.col-md-3 {
				flex: 0 0 50% !important;
				max-width: 100% !important;
			}
		}
	</style>
</head>
<body>-->
<!-- Row -->
<div class="row" id="{{pageId}}mainPageOfFortnightStmtDtlsId">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Statement Details</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-6">
                    <div class="col-md-12">
                        <div class="card">

                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body" style="border-bottom: 1px solid orange;">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="custom_font_Lable">Statement Number</label>
                                                    <div class="input-group" id="{{pageId}}statementNumber">
                                                        <label class="custom_font_Lables" style="font-size: 15px;">{{
															fortnightStatementSmrySelectedData.stmtId }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="custom_font_Lable">Period</label>
                                                    <div class="input-group" id="{{pageId}}period">
                                                        <label class="custom_font_Lables" style="font-size: 15px;">{{
															fortnightStatementSmrySelectedData.fromPeriodStr }} To {{
															fortnightStatementSmrySelectedData.toPeriodStr }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="custom_font_Lable">Account Statement
														Of</label>
                                                    <div class="input-group" id="{{pageId}}accountStatement">
                                                        <label class="custom_font_Lables" style="font-size: 15px;">{{
															fortnightStatementSmrySelectedData.destination }}</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-12 p-t-10">
                        <div class="box-body">
                            <!-- 							the first datatble starts -->
                            <table datatable id="{{pageId}}destStmtDetailsId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsLrDetails" [dtTrigger]="dtTriggerLrDetails">

                                <thead>
                                    <tr>
                                        <th>Source</th>
                                        <th>Invoice Number</th>
                                        <th>Total Lrs</th>
                                        <th>Total Weight</th>
                                        <th>Total Pay</th>
                                        <th>Total Paid</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lrDetailsData of lrDetailsDataList ">
                                        <td>{{ lrDetailsData.souce }}</td>
                                        <td (click)="hireslipDialog(lrDetailsData)"><u class="hyperLink_td">{{ lrDetailsData.invoiceNumber }}</u></td>
                                        <td>{{ lrDetailsData.totalLrs }}</td>
                                        <td>{{ lrDetailsData.totalActWgt }}</td>
                                        <td>{{ lrDetailsData.totalAmount }}</td>
                                        <td>{{ lrDetailsData.totalPaid }}</td>
                                    </tr>
                                    <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-12 p-t-10" style="text-align: center">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}updateBtn" (click)="saveStmtDetails('Pending',content1PopUpTemplate)">Update</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}finishBtn" (click)="saveStmtDetails('Finished',content1PopUpTemplate)">Finish</button>
                        <!--<button type="submit" class="btn btn-dark" id="{{pageId}}finishBtn"
							(click)="printStmtDetails()">Print</button>-->
                    </div>

                </div>
                <div class="col-md-6 vl">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-plus"></i>
															</span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}commonDescription" name="commonDescription">
															<option value="Addition" selected>Addition</option>
															<option value="Deduction">Deduction</option>
														</select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
                                                        </div>
                                                        <input type="text" id="{{pageId}}description" [readonly]="descReadOnly" placeholder="Enter The Description" class="form-control" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
																class="fas fa-rupee-sign"></i>
															</span>
                                                        </div>
                                                        <input type="number" id="{{pageId}}value" placeholder="Value" class="form-control" aria-describedby="basic-addon11">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <a href="javascript:;" type='button' id="{{pageId}}addInTable" #addBtn style="padding: 1px 4px;" class="btn btn-icon-only yellow" (click)="addInTable();">
                                                            <i class="fa fa-plus" title="Add"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h6 style="border-bottom: 1px solid orange;" class="card-title">Additions</h6>
                            </div>
                        </div>
                        <div class="box-body p-t-10">
                            <!-- 							<the second datatable starts  -->
                            <div>
                                <table datatable id="{{pageId}}additionTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionAdditionDatatable" [dtTrigger]="dtTriggerAdditionDatatable">
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th>Credit(Rs)</th>
                                            <th>Debit(Rs)</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let additionDatatableData of additionDatatableDataList ; let i = index">
                                            <td>{{ additionDatatableData.description }}</td>
                                            <td>{{ additionDatatableData.totalAmount }}</td>
                                            <td>0.0</td>
                                            <td><button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}additionEditBtn" (click)="rowSelectedEditAddition(additionDatatableData,i);">
													<i title="Edit" class="fas fa-edit"></i>
												</button>
                                                <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}additionRemoveBtn" (click)="rowSelectedDeleteAddition(additionDatatableData,i)">
													<i title="Remove" class="fas fa-trash"></i>
												</button></td>
                                        </tr>

                                        <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>

                                </table>
                                <!--							 the second datatable ends  -->
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 p-t-10 p-b-10">
                        <!-- 							the third datatable starts  -->
                        <div class="row">
                            <div class="col-md-12">
                                <h6 style="border-bottom: 1px solid orange;" class="card-title">Deductions</h6>
                            </div>
                        </div>
                        <div class="box-body p-t-10">
                            <div class="p-t-10">
                                <table datatable id="{{pageId}}deductionTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionDeductionDatatable" [dtTrigger]="dtTriggerDeductionDatatable">
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th>Credit(Rs)</th>
                                            <th>Debit(Rs)</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let deductionDatatableData of deductionDatatableDataList ; let j = index">
                                            <td>{{ deductionDatatableData.description }}</td>
                                            <td>0.0</td>
                                            <td>{{ deductionDatatableData.totalAmount }}</td>
                                            <td><button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}dedEditBtn" (click)="rowSelectedEditDeduction(deductionDatatableData,j);">
													<i title="Edit" class="fas fa-edit"></i>
												</button>
                                                <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}dedRemoveBtn" (click)="rowSelectedDeleteDed(deductionDatatableData,j)">
													<i title="Remove" class="fas fa-trash"></i>
												</button></td>
                                        </tr>

                                        <!-- <tr *ngIf = "supList.length==0">
														<td colspan="9">No Data To Display</td>
													</tr>-->

                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <!-- 							the third datatable ends  -->

                        </div>
                    </div>
                    <div class="row srd_patch m-l-10 m-r-10">
                        <div class="col-md-6">
                            <p style="margin-bottom: 1px;" class="card-title m-b-1">Receivable Amount
                            </p>
                        </div>
                        <div class="col-md-6" style="text-align: center;">
                            <p style="margin-bottom: 1px;" class="card-title">{{recAmt}}</p>
                        </div>
                    </div>

                    <div class="row srd_patch m-l-10 m-r-10">
                        <div class="col-md-6">
                            <p style="margin-bottom: 1px;" class="card-title m-b-1">Due Amount
                            </p>
                        </div>
                        <div class="col-md-6" style="text-align: center;">
                            <p style="margin-bottom: 1px;" class="card-title">{{dueAmt}}</p>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>

<!--</body>
</html>-->

<!--Print LR-->

<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>

<div *ngIf="viewStatementRptLRDetlsPrint" onafterprint="afterPrint()" id="{{pageId}}printForShowStmtDtlsId" #printForShowStmtRptId>
    <app-statement-rpt-lr-details-print></app-statement-rpt-lr-details-print>
</div>

<!--Pop up-->
<div class="col-md-12">
    <div class="form-group">
        <div class="input-group">
            <ng-template #content1PopUpTemplate let-ok="close" let-d="dismiss">
                <h6>
                    &nbsp;&nbsp;&nbsp;<u>Email Ids :</u>
                </h6>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div *ngIf="showSpinnerForAction1" class="col-md-9 p-t-10">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <mat-progress-bar mode="indeterminate" style="color: green;">
                                                </mat-progress-bar>
                                                <br>
                                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                    Please Wait Loading Details.....</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <span>Email Ids(Multiple Id seperated by comma's</span>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="form-group">
                                                <!--<label>Email Ids(Multiple Id seperated by comma's</label>-->
                                                <div class="form-group-prepend">
                                                    <span class="input-group-text"> <i
													class="fas fa-notes-medical"></i>
												</span>
                                                </div>
                                                <textarea type="text" class="form-control" id="fsdcmultipleEmailsId" rows="4" name="multipleEmailsId"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="viewStatementRptLRDetlsDocx" id="{{pageId}}printForDocxShowStmtDtlsId" #printForDocxShowStmtDtlsId>
                                        <app-statement-rpt-lr-details-print></app-statement-rpt-lr-details-print>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12">
                                        <button style="float: right;" type="button" class="btn btn-success" id="{{pageId}}cancelBtnId" (click)="sendFortnightStmtPopUpBtn();">Send</button>
                                        <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeFortnightStmtPopUpBtn();">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>