import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
//for datepicker starts 
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
//for datepicker ends 
//for popup modal starts 
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//for popup modal endss 
import { Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    animal: string;
    name: string;
}

//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { Router } from "@angular/router";
import { MemoService } from "src/app/dataService/memo-service";
import { MasterReadService } from "src/app/dataService/masterread-service";
//for the select option with filter ends
import swal from 'sweetalert';

import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { Subject, Subscription, merge } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-collection-man-to-collection-man-stock-transfer',
    templateUrl: './collection-man-to-collection-man-stock-transfer.component.html',
    styleUrls: ['./collection-man-to-collection-man-stock-transfer.component.css']
})





export class CollectionManToCollectionManStockTransferComponent implements OnInit {
    closeResult: string;


    //added for dummy
    animal: string;
    name: string;
    isLoggedIn = true;
    userDataDtoReturnSession: any;

    @ViewChild('content') content: TemplateRef<any>;

    constructor(private modalService: NgbModal,
        public dialog: MatDialog,
        private router: Router, public changeDetectorRef: ChangeDetectorRef

    ) {




        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //            sweet alert ends
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));




        }
    }
    //for popup modal starts 
    //    open2( content ) {
    //        this.modalService.open( content, { centered: true } ).result.then(
    //            result => {
    //                this.closeResult = `Closed with: ${result}`;
    //            },
    //            reason => {
    //                this.closeResult = `Dismissed ${this.getDismissReason( reason )}`;
    //            }
    //        );
    //    }
    //    private getDismissReason( reason: any ): string {
    //        if ( reason === ModalDismissReasons.ESC ) {
    //            return 'by pressing ESC';
    //        } else if ( reason === ModalDismissReasons.BACKDROP_CLICK ) {
    //            return 'by clicking on a backdrop';
    //        } else {
    //            return `with: ${reason}`;
    //        }
    //    }
    //for popup modal ends 


    openDialog(): void {
        const dialogRef = this.dialog.open(CollectionManToCollectionManStockTransferPopupComponent);

        // dialogRef.afterClosed().subscribe(result => {
        //            console.log( 'The dialog was closed' );
        //            this.animal = result;
        // });
    }

    ngOnInit() {
        //        this.modalService.open( this.content );
        setTimeout(() => {
            this.openDialog();
        }, 0);
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}




@Component({
    selector: 'app-collection-man-to-collection-man-stock-transfer-popup',
    templateUrl: './collection-man-to-collection-man-stock-transfer-popup.component.html',
})

export class CollectionManToCollectionManStockTransferPopupComponent implements OnInit {

    closeResult: string;
    manualView = true;
    automaticView = false;

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    lrDto: LRDto = new LRDto();
    userDataDto: UserDataDto = new UserDataDto();
    getDtlsCashMemoDto: CashMemoDto = new CashMemoDto();
    //    checkDtlsCashMemoDto: CashMemoDto = new CashMemoDto();
    userDataDtoTransferMemo: UserDataDto = new UserDataDto();
    chkUserDtoTransferMemo: UserDataDto = new UserDataDto();
    userDtoCollectionMan: UserDataDto = new UserDataDto();
    userDtoTransferAll: UserDataDto = new UserDataDto();
    collectionManFromOptions: UserDataDto[];
    collectionManToOptions: UserDataDto[];
    godownOptions: LRDto[];
    //    stationList: any;
    selectedGodown: any;
    selectedEntryType: any;
    selectedCollectionManFrom: any;
    selectedCollectionManTo: any;
    enteredMemoNumber: any;
    enteredCashMemoFormat: any;
    enteredCashMemoNumber: any;
    stationCodeDetails: any;
    memoFormat: any;
    memoNum = null;
    memoDetails: any;
    memoDetailsTransfer: any;
    updateMemoDetails: any;



    //for the select option with filter starts

    controlCollectionManFrom = new FormControl();
    controlCollectionManTo = new FormControl();
    controlGodown = new FormControl();

    //    collectionManFromOptions = [
    //        { id: 1, label: 'All' },
    //        { id: 2, label: 'Agra' },
    //        { id: 3, label: 'Bawana' },
    //        { id: 4, label: 'Delhi' }
    //    ];
    //
    //
    //
    //    collectionManToOptions = [
    //        { id: 1, label: 'All' },
    //        { id: 2, label: 'Aamer' },
    //        { id: 3, label: 'Afzal' },
    //        { id: 3, label: 'Arif' }
    //    ];


    //for the select option with filter ends
    // Station
    public modelGodownName: any;
    @ViewChild( 'instanceGodown' ) instanceGodown: NgbTypeahead;
    godownTA: Array<any> = [];
    focusGodownTA$ = new Subject<string>();
    entryGodownSearchTA = ( text$: Observable<string> ) => {
        const debouncedText$ = text$.pipe( debounceTime( 200 ), distinctUntilChanged() );
        const inputFocus$ = this.focusGodownTA$;

        return merge( debouncedText$, inputFocus$ ).pipe(
            map( term => ( term === '' ? this.godownTA
                : this.godownTA.filter( v => v.toLowerCase().indexOf( term.toLowerCase() ) > -1 ) ).slice( 0, 200 ) )
        );
    }
    collectionManOptions: UserDataDto[];
// collection man from
collectionManAnyForResponse: any;
    public modelCollectionManFromName: any;
     @ViewChild( 'instanceCollectionManFrom' ) instanceCollectionManFrom: NgbTypeahead;
     collectionManFromTA: Array<any> = [];
     focusCollectionManFromTA$ = new Subject<string>();
     collectionManFromSearchTA = ( text$: Observable<string> ) => {
         const debouncedText$ = text$.pipe( debounceTime( 200 ), distinctUntilChanged() );
         const inputFocus$ = this.focusCollectionManFromTA$;
 
         return merge( debouncedText$, inputFocus$ ).pipe(
             map( term => ( term === '' ? this.collectionManFromTA
                 : this.collectionManFromTA.filter( v => v.toLowerCase().indexOf( term.toLowerCase() ) > -1 ) ).slice( 0, 200 ) )
         );
     }
     
//  collection man to
public modelCollectionManToName: any;
@ViewChild( 'instanceCollectionManFrom' ) instanceCollectionManTo: NgbTypeahead;
collectionManToTA: Array<any> = [];
focusCollectionManToTA$ = new Subject<string>();
collectionManToSearchTA = ( text$: Observable<string> ) => {
    const debouncedText$ = text$.pipe( debounceTime( 200 ), distinctUntilChanged() );
    const inputFocus$ = this.focusCollectionManToTA$;

    return merge( debouncedText$, inputFocus$ ).pipe(
        map( term => ( term === '' ? this.collectionManToTA
            : this.collectionManToTA.filter( v => v.toLowerCase().indexOf( term.toLowerCase() ) > -1 ) ).slice( 0, 200 ) )
    );
}

    pageId = "ctstc";
    constructor(
        //for modal starts
        public dialogRef: MatDialogRef<CollectionManToCollectionManStockTransferPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        //for modal ends
        private router: Router, private memoService: MemoService, private masterReadService: MasterReadService, public changeDetectorRef: ChangeDetectorRef
    ) {
        {


            if (sessionStorage.length == 0) {
                this.isLoggedIn = false;
                //          sweet alert starts
                swal({
                    title: "Session Expired",
                    text: "Please relogin to access the application!",
                    icon: "error",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    this.logInPage();
                })
                //            sweet alert ends
            }

            if (this.isLoggedIn) {
                this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
                this.getDestinationDetails();
                //                this.getMemoCode();
                this.getCollectionManDetailsList();


            }
        }
    }
    //for popup modal starts 
    //    open2( content ) {
    //        this.modalService.open( content, { centered: true } ).result.then(
    //            result => {
    //                this.closeResult = `Closed with: ${result}`;
    //            },
    //            reason => {
    //                this.closeResult = `Dismissed ${this.getDismissReason( reason )}`;
    //            }
    //        );
    //    }
    //    private getDismissReason( reason: any ): string {
    //        if ( reason === ModalDismissReasons.ESC ) {
    //            return 'by pressing ESC';
    //        } else if ( reason === ModalDismissReasons.BACKDROP_CLICK ) {
    //            return 'by clicking on a backdrop';
    //        } else {
    //            return `with: ${reason}`;
    //        }
    //    }
    //for popup modal ends 

    entryTypeMode(entryType: string) {
        if (entryType === 'manual') {
            this.manualView = true;
            this.automaticView = false;
            window.setTimeout(function () {
                $("#ctstcgodown").focus();
            }, 100);
        } else if
            (entryType === 'automatic') {
            this.manualView = false;
            this.automaticView = true;
            window.setTimeout(function () {
                $("#ctstcgodown").focus();
            }, 100);
        } else {
            this.manualView = false;
            this.automaticView = false;
        }
    }

    ngOnInit() {
    }

    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }
    getDestinationMethod() {
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.mainstation = this.userDataDtoReturnSession.mainStation;
        this.lrDto.mode = "ALL";

    }
    getDestinationDetails() {
        this.getDestinationMethod();

        this.masterReadService.getDestinationForLREntryService(this.lrDto).subscribe(
            (response) => {
                if ( response ) {
                    this.godownOptions = response;
                    this.godownTA = [];
//                    console.log(this.stationOptions.length);
                    for ( let i = 0; i < this.godownOptions.length; i++ ) {
                        this.godownTA.push( this.godownOptions[i].destination );
                    }
                }
                // if (response) {
                //     this.godownOptions = response;

                // }
                //                else {
                //                    swal( {
                //                        title: "Warning",
                //                        text: "No records found for the selected city!",
                //                        icon: "warning",
                //                        closeOnClickOutside: false,
                //                        closeOnEsc: false,
                //                    } );

                //                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Getting Godown Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    getDetailsForMemoCode() {
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.office = this.modelGodownName;
        //        console.log( "Dto>> " + this.userDataDto.office );
        //        this.userDataDto.office = "Chennai";

    }
    getMemoCode() {

        this.getDetailsForMemoCode();
        this.masterReadService.getStationCodeService(this.userDataDto).subscribe(
            (response) => {
                if (response.length != 0) {

                    this.selectedEntryType = $("#" + this.pageId + "entryType").val();
                    //                    this.cashMemoFormat = $( "#cashMemoFormat" ).val();

                    if (this.selectedEntryType == "automatic") {
                        this.automaticView = true;
                        $("#" + this.pageId + "cashMemoNumber").focus();
                    } else {
                        this.stationCodeDetails = response;
                        if (this.stationCodeDetails.stationCode == null) {
                            $("#" + this.pageId + "cashMemoFormat").val('');
                            //                            console.log( this.stationCodeDetails.stationCode );
                        } else {
                            //                            console.log( this.stationCodeDetails.stationCode );
                            this.memoFormat = this.stationCodeDetails.stationCode + "/" + this.userDataDtoReturnSession.financialYearSplit + "/";
                            //this.memoFormat = 'BNG/1920/';
                            //                            console.log( "Memo>>> " + this.memoFormat );
                            //                        this.cashMemoFormat = this.memoFormat;
                            $("#" + this.pageId + "cashMemoFormat").val(this.memoFormat);
                            $("#" + this.pageId + "memoNumber").val('');
                            $("#" + this.pageId + "cashMemoNumber").val('');
                            $("#" + this.pageId + "lrNumber").val('');
                            $("#" + this.pageId + "memoAmount").val('');
                            window.setTimeout(function () {
                                $("#ctstcmemoNumber").focus();
                            }, 100);
                        }


                    }


                }
                //                else {
                //                                    swal( {
                //                                        title: "Warning",
                //                                        text: "No records found for the selected city!",
                //                                        icon: "warning",
                //                                        closeOnClickOutside: false,
                //                                        closeOnEsc: false,
                //                                    } );

                //                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Getting Station",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };



    getDetailsForCollectionMasterRead() {
        this.userDtoCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;
        this.userDtoCollectionMan.companyId = this.userDataDtoReturnSession.companyId;
        this.userDtoCollectionMan.status = "D";
    }

    getCollectionManDetailsList() {

        this.getDetailsForCollectionMasterRead();
        this.masterReadService.getCollectionManMasterDetails(this.userDtoCollectionMan).subscribe(
            (response) => {
                if ( response.length == 0 ) {
                    swal( {
                        title: "Warning",
                        text: "No Collection Man  found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    } );
                    // this.controlCollectionMan.reset();
                    this.collectionManFromTA = [];
                    this.collectionManToTA = [];

                } else {
                    // this.controlCollectionMan.reset();
                    this.collectionManAnyForResponse = response;
                    this.collectionManOptions = this.collectionManAnyForResponse;
                    this.collectionManFromTA = [];
                    this.collectionManToTA = [];
                    
                    for ( let i = 0; i < this.collectionManOptions.length; i++ ) {
                        this.collectionManFromTA.push( this.collectionManOptions[i].collectioMan );
                        this.collectionManToTA.push( this.collectionManOptions[i].collectioMan );
                        
                    }
                    //                    this.enteredMemoCodeMain=this.userDataDtoReturnSession.office;


                }
                this.changeDetectorRef.detectChanges();
                // if (response) {
                //     //                    console.log( response );
                //     this.collectionManFromOptions = response;
                //     this.collectionManToOptions = response;
                //     //                    this.dtTriggerCollectionMan.next();
                //     //                    console.log( this.collectionManFromOptions[0] + "<< from & to >>" + this.collectionManToOptions[0] );
                // }
                //                else {
                //                    swal( {
                //                        title: "Warning",
                //                        text: "No records found for the selected city!",
                //                        icon: "warning",
                //                        closeOnClickOutside: false,
                //                        closeOnEsc: false,
                //                    } );

                //                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Getting Collection Man Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };
    getFocusMethod() {
        this.selectedEntryType = $("#" + this.pageId + "entryType").val();
        if (this.selectedEntryType == "automatic") {
            window.setTimeout(function () {
                $("#ctstccashMemoNumber").val('');
                $("#ctstccashMemoNumber").focus();
                $("#ctstclrNumber").val('');
                $("#ctstcmemoAmount").val('')
            }, 1000);

        } else {
            window.setTimeout(function () {
                $("#ctstcmemoNumber").val('');
                $("#ctstcmemoNumber").focus();
                $("#ctstclrNumber").val('');
                $("#ctstcmemoAmount").val('')
            }, 1000);

        }
    }

    getMemoNumber(e) {
        if (e.keyCode == 13) { // press A
            //            this.selectedEntryType = $( "#entryType" ).val();
            //            if ( this.selectedEntryType == "automatic" ) {
            //                $( "#cashMemoNumber" ).focus();
            //                
            //                this.enteredCashMemoNumber = $( "#cashMemoNumber" ).val();
            //            } else {
            //                $( "#memoNumber" ).focus();
            //                this.enteredMemoNumber = $( "#memoNumber" ).val();
            //                this.enteredCashMemoFormat = $( "#cashMemoFormat" ).val();
            //            }
            this.getMemoDetails();
        }

    }

    getMethodForMemoDtails() {
        this.getDtlsCashMemoDto = new CashMemoDto();
        this.selectedEntryType = $("#" + this.pageId + "entryType").val();
        this.enteredMemoNumber = $("#" + this.pageId + "memoNumber").val();
        this.enteredCashMemoFormat = $("#" + this.pageId + "cashMemoFormat").val();
        this.enteredCashMemoNumber = $("#" + this.pageId + "cashMemoNumber").val();
        //        this.selectedCollectionManFrom = $( "#collectionManFrom" ).val();
        //        this.selectedCollectionManTo = $( "#collectionManTo" ).val();
        //        console.log( this.selectedCollectionManFrom + " << from && to >> " + this.selectedCollectionManTo );
        //        if ( this.selectedCollectionManFrom == '' || this.selectedCollectionManFrom == null || this.selectedCollectionManTo == '' || this.selectedCollectionManTo == null ) {
        //            swal( {
        //                title: "Mandatory Fields",
        //                text: "Please select Collection Man to proceed",
        //                icon: "warning",
        //                closeOnClickOutside: false,
        //                closeOnEsc: false,
        //
        //            } );
        //        } else {
        this.memoNum = ''
        if (this.selectedEntryType == "automatic") {
            this.memoNum = this.enteredCashMemoNumber;
        } else {
            this.memoNum = this.enteredCashMemoFormat + "" + this.enteredMemoNumber;
            if (this.enteredMemoNumber == '') {

                this.memoNum = null;
            }
        }

        this.getDtlsCashMemoDto.companyId = this.userDataDtoReturnSession.companyId;
        this.getDtlsCashMemoDto.memoNumber = this.memoNum;
    }



    getMemoDetails() {
        this.getMethodForMemoDtails();
        if (this.memoNum == null || this.memoNum == "") {
            swal({
                title: "Warning",
                text: "Memo Number is Mandatory!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,

            }).then(() => {
                this.getFocusMethod();
            })

            //            this.selectedEntryType = $( "#entryType" ).val();
            //            if ( this.selectedEntryType == "automatic" ) {
            //                $( "#cashMemoNumber" ).focus();
            //            } else {
            //                $( "#memoNumber" ).focus();
            //
            //            }



        } else {
            this.memoService.getMemoDetailService(this.getDtlsCashMemoDto).subscribe(
                (response) => {
                    if (response) {
                        this.memoDetails = response;

                        //                        this.checkDtlsCashMemoDto = this.memoDetails;
                        if (this.memoDetails.cashMemoName == null && this.memoDetails.status == null && this.memoDetails.grandTotal == null) {
                            swal({
                                title: "Warning",
                                text: "Cash Memo Number " + this.getDtlsCashMemoDto.memoNumber + " Not Found",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,

                            }).then(() => {
                                this.getFocusMethod();
                            })
                        } else if (this.memoDetails.cashMemoName == "Cash") {
                            swal({
                                title: "Warning",
                                text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " Amount is collected",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,

                            }).then(() => {
                                this.getFocusMethod();
                            })
                        } else if (this.memoDetails.cashMemoName != "Cash") {

                            if (this.memoDetails.status == "transfer" || this.memoDetails.status == "transfertogodown" || this.memoDetails.status == "missing") {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " not in SRD Collection Stock",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,
                                }).then(() => {
                                    this.getFocusMethod();
                                })
                            } else if (this.memoDetails.status == "billing") {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " is in Billing Stock",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })
                            } else if (this.memoDetails.status == "collection") {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " is in SRD Collection Stock",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })
                            } else if (this.memoDetails.status == "cmstock" && this.memoDetails.column1 == "Pending") {
                                $("#" + this.pageId + "memoAmount").val(this.memoDetails.grandTotal);
                                $("#" + this.pageId + "lrNumber").val(this.memoDetails.gcNoteNumber);
                                window.setTimeout(function () {
                                    $("#ctstctransferBtn").focus();
                                }, 100);

                                //                                this.getFocusMethod();
                            } else if (this.memoDetails.status == "completed") {
                                swal({
                                    title: "Warning",
                                    text: "" + this.getDtlsCashMemoDto.memoNumber + " Collection Closed",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })
                            } else {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo Number " + this.getDtlsCashMemoDto.memoNumber + " Not Found",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })
                            }

                        }

                    }
                    //                else {
                    //                    swal( {
                    //                        title: "Warning",
                    //                        text: "No records found for the selected city!",
                    //                        icon: "warning",
                    //                        closeOnClickOutside: false,
                    //                        closeOnEsc: false,
                    //                    } );

                    //                }
                    this.changeDetectorRef.detectChanges();
                }), (error) => swal({
                    title: "Server Error",
                    text: "While Getting Memo Details",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }),
                () => console.log('done');
        }
    }
    transferBtn() {
        this.selectedCollectionManFrom = $("#" + this.pageId + "collectionManFrom").val();
        this.selectedCollectionManTo = $("#" + this.pageId + "collectionManTo").val();
        //        console.log( this.selectedCollectionManFrom + " << from check && to check >> " + this.selectedCollectionManTo );
        if (this.selectedCollectionManFrom == '' || this.selectedCollectionManTo == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please select Collection Man to proceed",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,

            });
        } else {
            this.validateMemoOnTransfer();
        }
    }
    getValidateMemoNumber(e) {
        if (e.keyCode == 13) { // press Enter
            this.selectedCollectionManFrom = $("#" + this.pageId + "collectionManFrom").val();
            this.selectedCollectionManTo = $("#" + this.pageId + "collectionManTo").val();
            //            console.log( this.selectedCollectionManFrom + " << from check && to check >> " + this.selectedCollectionManTo );
            if (this.selectedCollectionManFrom == '' || this.selectedCollectionManTo == '') {
                swal({
                    title: "Mandatory Fields",
                    text: "Please select Collection Man to proceed",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,

                });
            } else {
                this.validateMemoOnTransfer();
            }

        }

    }
    validateMemoOnTransfer() {
        this.getMethodForMemoDtails();
        if (this.memoNum == null || this.memoNum == "") {
            swal({
                title: "Warning",
                text: "Memo Number is Mandatory!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.getFocusMethod();
            })


            //            this.selectedEntryType = $( "#entryType" ).val();
            //            if ( this.selectedEntryType == "automatic" ) {
            //                $( "#cashMemoNumber" ).focus();
            //            } else {
            //                $( "#memoNumber" ).focus();
            //
            //            }



        } else {
            this.memoService.getMemoDetailService(this.getDtlsCashMemoDto).subscribe(
                (response) => {
                    if (response) {
                        this.memoDetailsTransfer = response;

                        //                        this.checkDtlsCashMemoDto = this.memoDetailsTransfer;
                        if (this.memoDetailsTransfer.cashMemoName == null && this.memoDetailsTransfer.status == null && this.memoDetailsTransfer.grandTotal == null) {
                            swal({
                                title: "Warning",
                                text: "Cash Memo Number " + this.getDtlsCashMemoDto.memoNumber + " Not Found",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,

                            }).then(() => {
                                this.getFocusMethod();
                            })
                        } else if (this.memoDetailsTransfer.cashMemoName == "Cash") {
                            swal({
                                title: "Warning",
                                text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " Amount is collected",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,

                            }).then(() => {
                                this.getFocusMethod();
                            })
                        } else if (this.memoDetailsTransfer.cashMemoName != "Cash") {

                            if (this.memoDetailsTransfer.status == "transfer" || this.memoDetailsTransfer.status == "transfertogodown" || this.memoDetailsTransfer.status == "missing") {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " not in SRD Collection Stock",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,
                                }).then(() => {
                                    this.getFocusMethod();
                                })


                            } else if (this.memoDetailsTransfer.status == "billing") {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " is in Billing Stock",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })
                            } else if (this.memoDetailsTransfer.status == "collection") {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo  " + this.getDtlsCashMemoDto.memoNumber + " is in SRD Collection Stock",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })
                            } else if (this.memoDetailsTransfer.status == "cmstock" && this.memoDetailsTransfer.column1 == "Pending") {
                                this.transferMemoMethod();
                            } else if (this.memoDetailsTransfer.status == "completed") {
                                swal({
                                    title: "Warning",
                                    text: "" + this.getDtlsCashMemoDto.memoNumber + " Collection Closed",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })
                            } else {
                                swal({
                                    title: "Warning",
                                    text: "Cash Memo Number " + this.getDtlsCashMemoDto.memoNumber + " Not Found",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,

                                }).then(() => {
                                    this.getFocusMethod();
                                })
                            }

                        }

                    }
                    //                else {
                    //                    swal( {
                    //                        title: "Warning",
                    //                        text: "No records found for the selected city!",
                    //                        icon: "warning",
                    //                        closeOnClickOutside: false,
                    //                        closeOnEsc: false,
                    //                    } );

                    //                }
                    this.changeDetectorRef.detectChanges();
                }), (error) => swal({
                    title: "Server Error",
                    text: "While Getting Memo Details",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }),
                () => console.log('done');
        }

    }

    getMethodForTransferMemoDtails() {
        this.selectedEntryType = $("#" + this.pageId + "entryType").val();
        this.enteredMemoNumber = $("#" + this.pageId + "memoNumber").val();
        this.enteredCashMemoFormat = $("#" + this.pageId + "cashMemoFormat").val();
        this.enteredCashMemoNumber = $("#" + this.pageId + "cashMemoNumber").val();
        this.selectedCollectionManFrom = $("#" + this.pageId + "collectionManFrom").val();
        this.selectedCollectionManTo = $("#" + this.pageId + "collectionManTo").val();
        this.memoNum = ''
        if (this.selectedEntryType == "automatic") {
            this.memoNum = this.enteredCashMemoNumber;
        } else {
            this.memoNum = this.enteredCashMemoFormat + "" + this.enteredMemoNumber;
            if (this.enteredMemoNumber == '') {

                this.memoNum = null;

            }
        }
        this.userDataDtoTransferMemo.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDtoTransferMemo.memoNumber = this.memoNum;
        this.userDataDtoTransferMemo.mode = "cm2cmstock";
        this.userDataDtoTransferMemo.collectioMan = this.selectedCollectionManFrom;
        this.userDataDtoTransferMemo.collectioManTo = this.selectedCollectionManTo;
        this.userDataDtoTransferMemo.updatedBy = this.userDataDtoReturnSession.userId;
    }
    transferMemoMethod() {
        this.getMethodForTransferMemoDtails();

        this.memoService.updateMemoDetailService(this.userDataDtoTransferMemo).subscribe(
            (response) => {
                if (response) {
                    this.updateMemoDetails = response;

                    this.chkUserDtoTransferMemo = this.updateMemoDetails;
                    if (this.chkUserDtoTransferMemo.result == "exists") {
                        $("#" + this.pageId + "memoAmount").val('');
                        swal({
                            title: "Warning",
                            text: "Transfer To Collection Man Stock CashMemo " + this.userDataDtoTransferMemo.memoNumber + " already transferred to collection man stock!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,

                        }).then(() => {
                            this.getFocusMethod();
                        })
                    } else if (this.chkUserDtoTransferMemo.result == "notexists") {
                        swal({
                            title: "Warning",
                            text: "Transfer To Collection Man Stock - CashMemo " + this.userDataDtoTransferMemo.memoNumber + " does not exist!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,

                        }).then(() => {
                            this.getFocusMethod();
                        })
                    } else {
                        $("#" + this.pageId + "memoNumber").val('');
                        $("#" + this.pageId + "cashMemoNumber").val('');
                        $("#" + this.pageId + "lrNumber").val('');
                        $("#" + this.pageId + "memoAmount").val('')
                        this.getFocusMethod();
                        //                        LRCounts++;
                        //                        lblLRCountsValues.setText("" + LRCounts);
                        //                        cashMemoDto.setCollectionMan(cbCollectionMan
                        //                                .getRawValue());
                        //                        cashMemoDtos.add(cashMemoDto);
                    }

                }


                //                else {
                //                    swal( {
                //                        title: "Warning",
                //                        text: "No records found for the selected city!",
                //                        icon: "warning",
                //                        closeOnClickOutside: false,
                //                        closeOnEsc: false,
                //                    } );

                //                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Updating Memo Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');

    }

    onCloseClick(): void {
        this.dialogRef.close();
    }

    clickTA( inp ) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent( new Event( 'input' ) );
        window.setTimeout(function () {
            inp._elementRef.nativeElement.focus();
        }, 1000);
        
    }

    getGodownMemoCode(e: NgbTypeaheadSelectItemEvent){
        this.modelGodownName = e.item;
        // console.log("this.modelStationName");
        // console.log(this.modelStationName);
    this.getMemoCode();
}

collectionManFromFocus( e: NgbTypeaheadSelectItemEvent) {
    this.modelCollectionManFromName = e.item;
    window.setTimeout(function () {
        $("#ctstccollectionManTo").focus();
    }, 100);

}
collectionManToFocus( e: NgbTypeaheadSelectItemEvent) {
    this.modelCollectionManToName = e.item;
    window.setTimeout(function () {
        $("#ctstcentryType").focus();
    }, 100);
}

fieldFocusCollectionManFrom(e) {
    if (e.keyCode == 13) {
        window.setTimeout(function () {
            $("#ctstccollectionManTo").focus();
        }, 100);
    }

}

fieldFocusCollectionManTo(e) {
    if (e.keyCode == 13) {
        window.setTimeout(function () {
            $("#ctstcentryType").focus();
        }, 100);
    }

}

entryTypeModeFocus(e) {
    if (e.keyCode == 13) {
        window.setTimeout(function () {
            $("#ctstcgodown").focus();
        }, 100);
    }
}




// ctstc
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}


