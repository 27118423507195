import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

//for the select option with filter ends
//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";


@Component( {
    selector: 'app-consignee-details',
    templateUrl: './consignee-details.component.html',
    styleUrls: ['./consignee-details.component.css']
} )
export class ConsigneeDetailsComponent implements OnInit {

    getDataGodownStockSmryDetailsTable: any;
    getDataConsigneeOsDetailsTable: any;
    getDataConsigneeRateDetails: any;


    godownStockSmryDetailsDataList: any;
    consigneeOsDetailsDataList: any;
    consigneeRateDetailsDataList: any;


    onDestroyUnsubscribtionGodownStockSmryDetails: Subscription;
    onDestroyUnsubscribtionConsigneeOsDetails: Subscription;
    onDestroyUnsubscribtionConsigneeRateDetails: Subscription;



    //for the select option with filter starts

    control = new FormControl();

    partyOptions = [
        { id: 1, label: 'AR Tiles' },
        { id: 2, label: 'GK Enterprises' },
        { id: 3, label: 'GQ Associates' },
    ];

    destinationOptions = [
        { id: 1, label: 'Bangalore' },
        { id: 2, label: 'Calicut' },
        { id: 3, label: 'Delhi' },
    ];


    //for the select option with filter ends



    //summaryTable:any;

    loadingIndicator = true;

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerGodownStockSmryDetails: Subject<any> = new Subject();
    dtTriggerConsigneeOsDetails: Subject<any> = new Subject();
    dtTriggerConsigneeRateDetails: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsGodownStockSmryDetails: any;
    dtOptionsConsigneeOsDetails: any;
    dtOptionsConsigneeRateDetails: any;
    pageId="cdcs";

    constructor( private consigneeDetailsRpt: ReportService, public changeDetectorRef : ChangeDetectorRef ) {


    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }







    ngOnInit(): void {


        //the first datatable starts
        this.dtOptionsGodownStockSmryDetails = {
            //};
            //columns is used to for export and others starts
            columns: [
                {
                    title: 'Consignee Name',
                    data: 'consigneeName'
                },
                {
                    title: 'Total Lrs',
                    data: 'totalLrs'
                },
                {
                    title: 'Articles',
                    data: 'articles'
                },
                {
                    title: 'To-Pay',
                    data: 'toPay'
                },
                {
                    title: 'Paid',
                    data: 'paid'
                },
                {
                    title: 'Act Wght',
                    data: 'actWght'
                },
                {
                    title: 'Chg Wght',
                    data: 'chgWght'
                },
                {
                    title: 'Stocks At',
                    data: 'stocksAt'
                }

            ],


            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [

                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print Consignee Wise</i>',
                //                    titleAttr: 'Print Consignee Wise',
                //
                //                },
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print All</i>',
                //                    titleAttr: 'Print All',
                //
                //                },
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                }


            ],
            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 180,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends                          
        },
            //the first datatable ends
            //            the second datatable starts 
            this.dtOptionsConsigneeOsDetails = {
                //};
                //columns is used to for export and others starts
                columns: [
                    {
                        title: 'Consignee Name',
                        data: 'consigneeName'
                    },
                    {
                        title: 'Total Memo',
                        data: 'totalMemo'
                    },
                    {
                        title: 'Total Amount',
                        data: 'totalAmount'
                    },
                    {
                        title: 'Total Article',
                        data: 'totalArticle'
                    },
                    {
                        title: 'Area',
                        data: 'area'
                    },
                    {
                        title: 'Collection Man',
                        data: 'collectionMan'
                    }
                ],
                //columns is used to for export and others endss
                // the below code is for button export starts
                dom: 'Bfrtip',
                /*buttons: [
                          'excel', 'print'
                      ],*/
                buttons: [
                    //                    {
                    //                        extend: 'print',
                    //                        text: '<i class="fas fa-print"> Print</i>',
                    //                        titleAttr: 'Print',
                    //
                    //                    },
                    //                    {
                    //                        extend: 'excel',
                    //                        text: '<i class="fas fa-file-excel"> Excel</i>',
                    //                        titleAttr: 'Excel',
                    //                        exportOptions: {
                    //                            columns: ':visible'
                    //                        }
                    //                    }
                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 180,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends

            },
            //the second datatable ends
            //the third datatable starts            
            this.dtOptionsConsigneeRateDetails = {
                //};
                //columns is used to for export and others starts
                columns: [
                    {
                        title: 'Consignee Name',
                        data: 'consigneeName'
                    },
                    {
                        title: 'Source',
                        data: 'source'
                    },
                    {
                        title: 'Deliv Type',
                        data: 'delivType'
                    },
                    {
                        title: 'Unit',
                        data: 'unit'
                    },
                    {
                        title: 'Src.Ham',
                        data: 'srcHam'
                    },
                    {
                        title: 'Des.Ham',
                        data: 'desHam'
                    },
                    {
                        title: 'Rate',
                        data: 'rate'
                    },
                    {
                        title: 'Receipt Chg',
                        data: 'receiptChg'
                    },
                    {
                        title: 'Service Chg',
                        data: 'serviceChg'
                    },
                    {
                        title: 'GC Chg',
                        data: 'gcChg'
                    },
                    {
                        title: 'Per Unit',
                        data: 'perUnit'
                    }

                ],
                //columns is used to for export and others endss
                // the below code is for button export starts
                dom: 'Bfrtip',
                /*buttons: [
                          'excel', 'print'
                      ],*/
                buttons: [
                    //                    {
                    //                        extend: 'print',
                    //                        text: '<i class="fas fa-print"> Print</i>',
                    //                        titleAttr: 'Print',
                    //                    },
                    //                    {
                    //                        extend: 'excel',
                    //                        text: '<i class="fas fa-file-excel"> Excel</i>',
                    //                        titleAttr: 'Excel',
                    //                        exportOptions: {
                    //                            columns: ':visible'
                    //                        }
                    //                    }
                ],
                // the below code is for button export ends
                //place holder in search/filter in datatable starts
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                //place holder in search/filter in datatable ends
                processing: true,
                //scroll in datatable starts
                responsive: true,
                "scrollX": true,
                "scrollY": 180,
                "scrollCollapse": true,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                "paging": false,
                "info": false,
                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
                //scroll in datatable ends         
            },
            //the third datatable ends



            //the below code is for the getting data through json starts
            //            this.supplierList.getAllData().subscribe(data => {
            //                this.lrDispatchBknRptList = data['data'];
            //                this.dtTriggerSummary.next();
            //                } );
            //first service starts 
            this.getDataGodownStockSmryDetailsTable = this.consigneeDetailsRpt.getSummaryData()
        this.onDestroyUnsubscribtionGodownStockSmryDetails = this.getDataGodownStockSmryDetailsTable.subscribe( data => {
            this.godownStockSmryDetailsDataList = data['data'];
            this.dtTriggerGodownStockSmryDetails.next();
        } );
        //first service ends
        //second service starts
        this.getDataConsigneeOsDetailsTable = this.consigneeDetailsRpt.getSummaryData()
        this.onDestroyUnsubscribtionConsigneeOsDetails = this.getDataConsigneeOsDetailsTable.subscribe( data => {
            this.consigneeOsDetailsDataList = data['data'];
            this.dtTriggerConsigneeOsDetails.next();
        } );
        //second service ends
        //third service starts
        this.getDataConsigneeRateDetails = this.consigneeDetailsRpt.getSummaryData()
        this.onDestroyUnsubscribtionConsigneeRateDetails = this.getDataConsigneeRateDetails.subscribe( data => {
            this.consigneeRateDetailsDataList = data['data'];
            this.dtTriggerConsigneeRateDetails.next();
        } );
        //third service ends

    }




    ngOnDestroy(): void {
        this.dtTriggerGodownStockSmryDetails.unsubscribe();
        this.dtTriggerConsigneeOsDetails.unsubscribe();
        this.dtTriggerConsigneeRateDetails.unsubscribe();


        this.onDestroyUnsubscribtionGodownStockSmryDetails.unsubscribe();
        this.onDestroyUnsubscribtionConsigneeOsDetails.unsubscribe();
        this.onDestroyUnsubscribtionConsigneeRateDetails.unsubscribe();

    }

    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

    //for the select option with filter ends

}
