import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DataTablesModule } from "angular-datatables";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ReactiveFormsModule } from "@angular/forms";
import { AutocompleteModule } from "src/app/autocomplete/autocomplete.module";
import { DailyReportModule } from "src/app/report/daily-report/daily-report.module";
import { LrEditComponent } from "src/app/lr/edit/lr-edit/lr-edit.component";
import { InvoiceLrEditComponent } from "src/app/lr/edit/invoice-lr-edit/invoice-lr-edit.component";
import { LrsShortAndExtraComponent } from "src/app/lr/edit/lrs-short-and-extra/lrs-short-and-extra.component";
import { MaterialModule } from "src/app/material.module";
import { MultipleArticleSizeComponent } from "src/app/lr/edit/multiple-article-size/multiple-article-size.component";
import { MultiplePackNatureComponent } from "src/app/lr/edit/multiple-pack-nature/multiple-pack-nature.component";
import { EntryModule } from "src/app/lr/entry/entry.module";
import { GstInvoiceDetainedComponent } from "src/app/lr/edit/gst-Invoice-Detained/gst-Invoice-Detained.component";
import { EditLrFreightComponent } from "./edit-lr-freight/edit-lr-freight.component";

@NgModule({
  imports: [
    CommonModule,
    // RouterModule.forChild(EditRoutes),
    FormsModule,
    FormsModule,
    NgbModule,
    DataTablesModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AutocompleteModule,
    DailyReportModule,
    MaterialModule,
    EntryModule,
  ],
  declarations: [
    LrEditComponent,
    InvoiceLrEditComponent,
    LrsShortAndExtraComponent,
    MultipleArticleSizeComponent,
    MultiplePackNatureComponent,
    GstInvoiceDetainedComponent,
    EditLrFreightComponent,
  ],
  entryComponents: [MultipleArticleSizeComponent, MultiplePackNatureComponent],
})
export class EditModule { }
