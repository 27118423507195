import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
//service starts
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
//service ends
//for redirect to other page starts
import { Router } from "@angular/router";
//for redirect to other page ends
import { MasterDto } from "src/app/dto/master-dto";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterService } from "src/app/dataService/master-service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  errorMsg = false;
  errorMsgTimeExpired = false;
  masterDto: MasterDto = new MasterDto();
  userDataDtoReturn: any;
  userDataDtoReturnSession: any;
  userDataDto: UserDataDto = new UserDataDto();
  showSpinnerForAction = false;

  constructor(
    //          service starts
    private http: HttpClient,
    //            service ends
    //for redirect to other page starts
    private router: Router,
    //for redirect to other page ends
    private masterSerivce: MasterService,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  loginform = true;
  recoverform = false;
  showGif = false;
  showLoginForm = true;
  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }

  showloginform() {
    this.loginform = this.loginform;
    this.recoverform = this.recoverform;
  }
  //  service starts
  getValuesForLoginUserList() {
    this.userDataDto.companyId = "srd";
  }

  ngOnInit(): void {}
  /*let apiUrlLoginUserModel = `http://localhost:8080/srd/master/v1/returnLoginUserdetailsModel`;
    this.http.get(apiUrlLoginUserModel).subscribe(
        (response) => {

            this.truckMasterDataList = response.json();

        }),
        (error) => console.log(error.json()),
        () => console.log('done');*/
  //    getLoginUserDetailsList = () => {
  //        let apiUrlLoginUserDetails = `http://localhost:8080/srd/login/v1/getLoginUserDetails`;
  //        this.http.get( apiUrlLoginUserDetails, {
  //            params: {
  //                partyDto: this.getValuesForLoginUserList(),
  //                mainStation: 'Delhi',
  //            },
  //        } ).subscribe(
  //            ( response ) => {
  //                if ( response.json().length == 0 ) {
  //                    alert( 'No records found for the selected city!' );
  //                } else {
  ////                    this.router = response.json();
  ////                    this.dtTriggerTruckMaster.next();
  ////                    console.log( this.truckMasterDataList[5] );
  //                }
  //            } ),
  //            ( error ) => console.log( error.json() ),
  //            () => console.log( 'done' );
  //    };

  signIn() {
    this.showSpinnerForAction = true;

    this.getValuesForLoginUserList();
    this.masterSerivce
      .loginAuthenticationService(this.userDataDto)
      .subscribe((response) => {
        this.userDataDtoReturn = response;
        if (this.userDataDtoReturn.status == "Success") {
          sessionStorage.setItem(
            "SRDWeb",
            JSON.stringify(this.userDataDtoReturn)
          );
          sessionStorage.setItem("Username", this.userDataDtoReturn.userName);
          this.userDataDtoReturnSession = JSON.parse(
            sessionStorage.getItem("SRDWeb")
          );
          this.router.navigate(["/dashboard/classic"]);
          //this.navCtrl.push('');
          //for redirect to other page starts
        } else if (this.userDataDtoReturn.status == "TimeExpired") {
          this.errorMsgTimeExpired = true;
          this.errorMsg = false;
        } else if (this.userDataDtoReturn.status == "NotExist") {
          this.errorMsg = true;
          this.errorMsgTimeExpired = false;
          this.showSpinnerForAction = false;
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log("done");
  }

  isUserLoggedIn() {
    let user = sessionStorage.getItem("Username");
    return !(user === null);
  }
}
