<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}invoiceDedailsId">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Invoice Details</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Search By</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-search"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchBy" name="searchBy" #searchBy (change)="searchByMode(searchBy.value)">
                                                            <option [hidden]="!hideAndShowInvoiceNoDropDown" selected
                                                                value="invoiceNumber">Invoice Number</option>
                                                            <option [hidden]="!hideAndShowDateWiseDropDown"
                                                                value="dateWise">Date Wise</option>
                                                            <option [hidden]="!hideAndShowSourceWiseDropDown"
                                                                value="sourceWise">Source Wise</option>
                                                            <option [hidden]="!hideAndShowDestinationWiseDropDown"
                                                                value="destinationWise">Destination Wise</option>
                                                            <option [hidden]="!hideAndShowDateAndStationDropDown"
                                                                value="dataAndStationWise">Date And Station
                                                                Wise</option>
                                                            <option [hidden]="!hideAndShowMainInvoiceDropDown"
                                                                value="mainInvoice">Main Invoice</option>
                                                            <option [hidden]="!hideAndShowConsolidateInvoiceNoDropDown"
                                                                value="consolidatedInvoice">Consolidated
                                                                Invoice</option>
                                                            <option
                                                                [hidden]="!hideAndShowInvoiceNoWithDestinationDropDown"
                                                                value="invNoWithDestination">Invoice Number
                                                                With Destination</option>
                                                            <option [hidden]="!hideAndShowMainBkgSrcWiseDropDown"
                                                                value="mainBkgSrcWise">Main Booking Source Wise</option>
                                                            <option [hidden]="!hideAndShowDateWiseDetailsDropDown"
                                                                value="dateWiseDetails">Date Wise Details</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div [hidden]="!hideAndShowInvoiceNumberField" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Invoice Number</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-file-alt"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}invoiceNumberId" name="invoiceNumberId" aria-describedby="basic-addon11" autoComplete="off">
                                                    </div>
                                                </div>
                                            </div>


                                            <div [hidden]="!hideAndShowFromToDateField" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" placeholder="yyyy-mm-dd" id="{{pageId}}fromDates" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker" [(ngModel)]="fromDatesModal" autoComplete="off">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div [hidden]="!hideAndShowFromToDateField" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDate" class="form-control" placeholder="yyyy-mm-dd" name="toDate" ngbDatepicker #toDate="ngbDatepicker" [(ngModel)]="toDatesModal" autoComplete="off">
                                                        <div class="input-group-append" (click)="toDate.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" [hidden]="!hideAndShowSourceField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
                                                            </span>
                                                        </div>
                                                        <input id="{{pageId}}sourceId" type="text" class="form-control" [(ngModel)]="modelSource" [ngbTypeahead]="searchSource" (selectItem)="sourceListener($event)" [resultFormatter]="formatterSource" [inputFormatter]="formatterSource" (focus)="focusSourceTA$.next($any($event).target.value)"
                                                            placeholder="Select Source" />
                                                    </div>
                                                </div>
                                            </div>
                                            <!--[hidden]="!hideAndShowSourceStationForOtherField"
											[hidden]="!hideAndShowSourceStationField"
										-->
                                            <!--<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Source</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-box"></i>
															</span>
														</div>
														<input id="{{pageId}}sourceBookingStationForOtherId" type="text"
															class="form-control"
															[(ngModel)]="modelSourceBookingStationForOther"
															[ngbTypeahead]="searchSourceBookingStationForOther"
															[resultFormatter]="formatterSourceBookingStationForOther"
															[inputFormatter]="formatterSourceBookingStationForOther"
															(focus)="focusSourceBookingStationForOtherTA$.next($any($event).target.value)"
															placeholder="Select Source.." />
													</div>
												</div>
											</div>
											<div class="col-sm-12 col-md-12" >
												<div class="form-group">
													<div class="input-group">
														<label>Source</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-box"></i>
															</span>
														</div>
														<input id="{{pageId}}sourceBookingStationId" type="text"
															class="form-control" [(ngModel)]="modelSourceBookingStation"
															[ngbTypeahead]="searchSourceBookingStation"
															[resultFormatter]="formatterSourceBookingStation"
															[inputFormatter]="formatterSourceBookingStation"
															(focus)="focusSourceBookingStationTA$.next($any($event).target.value)"
															placeholder="Select Source.." />
													</div>
												</div>
											</div>-->
                                            <div [hidden]="!hideAndShowDestinationField" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group" id="{{pageId}}destination">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class=" fas fa-road"></i>
                                                            </span>
                                                        </div>
                                                        <input id="{{pageId}}destinationId" type="text" class="form-control" (selectItem)="clickListnerForDestination($event)" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination.." />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" [hidden]="!hideAndShowMainBkgSourceField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" id="{{pageId}}mainBookingSourceId" (selectItem)="clickListnerForMainBookingSource($event)" [(ngModel)]="modelMainBookingSource" [ngbTypeahead]="searchMainBookingSource" [resultFormatter]="formatterMainBookingSource"
                                                            [inputFormatter]="formatterMainBookingSource" (focus)="focusMainBookingSourceTA$.next($any($event).target.value)" placeholder="Select Source.." />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12" [hidden]="!hideAndShowViaSourceField">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Via</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
                                                            </span>
                                                        </div>
                                                        <input id="{{pageId}}viaSourceId" type="text" class="form-control" [(ngModel)]="modelViaSourceStation" [ngbTypeahead]="searchViaSourceStation" (selectItem)="viaSourceStationListener($event)" [resultFormatter]="formatterViaSource" [inputFormatter]="formatterViaSource"
                                                            (focus)="focusViaSourceStationTA$.next($any($event).target.value)" placeholder="Select Source" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div [hidden]="!hideAndShowMainAndSubStationField" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Station</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-warehouse"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchStationWiseBy" name="searchStationWiseBy" #searchStationWiseBy (change)="searchByStationWiseMode(searchStationWiseBy.value)">
                                                            <!--<option selected>Select Station</option>-->
                                                            <option value="mainStation">MainStation Wise</option>
                                                            <option value="subStation">SubStation Wise</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div [hidden]="!hideAndShowMainInvoiceOfStationSubStationField" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Station</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i
                                                                    class="fas fa-warehouse"></i>
                                                            </span>
                                                        </div>
                                                        <select class="custom-select col-12" id="{{pageId}}searchMainInvoiceStationWiseBy" #searchMainInvoiceStationWiseBy (change)="searchByMainInvoiceStationWiseMode(searchMainInvoiceStationWiseBy.value)">
                                                            <!--<option selected>Select Station</option>-->
                                                            <option value="mainStation">MainStation Wise</option>
                                                            <option value="subStation">SubStation Wise</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div [hidden]="!hideAndShowInvoiceDateField" class="col-sm-12 col-md-12">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Invoice Date</label> <input class="form-control" placeholder="yyyy-mm-dd" id="{{pageId}}invoiceDate" name="invoiceDate" ngbDatepicker #invoiceDate="ngbDatepicker" [(ngModel)]="invoiceDateModal"
                                                            autoComplete="off">
                                                        <div class="input-group-append" (click)="invoiceDate.toggle()">
                                                            <span class="input-group-text"> <i
                                                                    class="fa fa-calendar"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--<div *ngIf="viewStationInvoice" class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group" id="{{pageId}}station">
														<label>Station</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																	class="fas fa-warehouse"></i>
															</span>
														</div>
														<select class="custom-select col-12">
															<option selected>Select Station</option>
															<option value="mainStation">MainStation Wise</option>
															<option value="subStation">SubStation Wise</option>
														</select>
													</div>
												</div>
											</div>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 80%; border-top: none; margin: 3px;">
                        <div class="col-md-12" style="text-align: center;">
                            <button type="submit" class="btn btn-success m-r-10" (click)="validateSearchBtn();" id="{{pageId}}searchBtn">Search</button>
                            <button type="submit" class="btn btn-dark" (click)="validateClearBtn();" id="{{pageId}}clearBtn">Clear</button>
                        </div>
                    </div>
                    <div class="col-md-9 vl p-t-10">
                        <div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
                            <div class="form-group">
                                <div class="input-group">
                                    <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                    <br>
                                    <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                        Please Wait Loading Details.....</h6>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintInvoiceDetailsRpt()" id="{{pageId}}printAllBtn" [hidden]="!hideAndShowInvoiceNoDetailsTable">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                        <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintInvoiceDetailsRpt()" id="{{pageId}}printAllBtn" [hidden]="!hideAndShowInvoiceDetailsTable">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                        <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintDesSubStationRpt()" id="{{pageId}}printAllBtn" [hidden]="!hideAndShowDestSubStationTable">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                        <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintInvoiceMainStationRpt()" id="{{pageId}}printAllBtn" [hidden]="!hideAndShowInvoiceMainStationTable">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                        <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintInvoiceSubStationRpt()" id="{{pageId}}printAllBtn" [hidden]="!hideAndShowInvoiceSubStationTable">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                        <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintConsolInvoiceRpt()" id="{{pageId}}printAllBtn" [hidden]="!hideAndShowConsolidatedInvoiceTable">
                            <span><i class="fas fa-print">Print</i></span>
                        </button>
                        <div class="box-body" [hidden]="!hideAndShowInvoiceNoDetailsTable">
                            <table datatable id="{{pageId}}invoiceNoDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsInvoiceNoWiseDetails" [dtTrigger]="dtTriggerInvoiceDetails">
                                <thead>
                                    <tr>
                                        <th>Destination</th>
                                        <th>Invoice No</th>
                                        <th>L.Rs</th>
                                        <th>Invoice Date</th>
                                        <th>Article</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>Discount</th>
                                        <th>F O V</th>
                                        <th>DD Amt</th>
                                        <th>Loaded By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let invoiceDetailsData of invoiceDetailsDataList ">
                                        <td>{{ invoiceDetailsData.destination }}</td>
                                        <!-- <td>{{ invoiceDetailsData.toStation }}</td> -->
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="invoiceDetailsRptPopUpBtnLink(invoiceDetailsLrsSummaryPopUpTemplate,invoiceDetailsData)">
                                            {{ invoiceDetailsData.invoiceNumber }}</td>
                                        <td>{{ invoiceDetailsData.totalLrs }}</td>
                                        <td>{{ invoiceDetailsData.invoiceDateStr }}</td>
                                        <td>{{ invoiceDetailsData.totalArticles }}</td>
                                        <td>{{ invoiceDetailsData.actualWeight }}</td>
                                        <td>{{ invoiceDetailsData.chargedWeight }}</td>
                                        <td>{{ invoiceDetailsData.toPay }}</td>
                                        <td>{{ invoiceDetailsData.paid }}</td>
                                        <td>{{ invoiceDetailsData.discountAmount }}</td>
                                        <td>{{ invoiceDetailsData.riskCharge }}</td>
                                        <td>{{ invoiceDetailsData.ddAmt }}</td>
                                        <td>{{ invoiceDetailsData.loaderName }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div class="box-body" [hidden]="!hideAndShowInvoiceDetailsTable">
                            <table datatable id="{{pageId}}invoiceDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsInvoiceDetails" [dtTrigger]="dtTriggerInvoiceDetails">
                                <thead>
                                    <tr>
                                        <th [hidden]=true>Destination</th>
                                        <th>Invoice No</th>
                                        <th>L.Rs</th>
                                        <th>Invoice Date</th>
                                        <th>Article</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>Discount</th>
                                        <th>F O V</th>
                                        <th>DD Amt</th>
                                        <th>Loaded By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let invoiceDetailsData of invoiceDetailsDataList ">
                                        <!--<td>{{ invoiceDetailsData.destination }}</td>-->
                                        <td [hidden]=true>{{ invoiceDetailsData.toStation }}</td>
                                        <td style="color: blue; cursor: pointer; text-decoration: underline;" (click)="invoiceDetailsRptPopUpBtnLink(invoiceDetailsLrsSummaryPopUpTemplate,invoiceDetailsData)">
                                            {{ invoiceDetailsData.invoiceNumber }}</td>
                                        <td>{{ invoiceDetailsData.totalLrs }}</td>
                                        <td>{{ invoiceDetailsData.invoiceDateStr }}</td>
                                        <td>{{ invoiceDetailsData.totalArticles }}</td>
                                        <td>{{ invoiceDetailsData.actualWeight }}</td>
                                        <td>{{ invoiceDetailsData.chargedWeight }}</td>
                                        <td>{{ invoiceDetailsData.toPay }}</td>
                                        <td>{{ invoiceDetailsData.paid }}</td>
                                        <td>{{ invoiceDetailsData.discountAmount }}</td>
                                        <td>{{ invoiceDetailsData.riskCharge }}</td>
                                        <td>{{ invoiceDetailsData.ddAmt }}</td>
                                        <td>{{ invoiceDetailsData.loaderName }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td [hidden]=true></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div class="box-body" [hidden]="!hideAndShowDestSubStationTable">
                            <table datatable id="{{pageId}}invoiceDetailsDestSubStationTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsDestSubStation" [dtTrigger]="dtTriggerDestSubStation">
                                <thead>
                                    <tr>
                                        <th>Stations</th>
                                        <th>LRs</th>
                                        <th>Article</th>
                                        <th>Actual Wt</th>
                                        <th>Charged Wt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>Service Tax</th>
                                        <th>DD Amt</th>
                                        <th>Loaded By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let destSubStationData of destSubStationDataList ">
                                        <td>{{ destSubStationData.toStation }}</td>
                                        <td>{{ destSubStationData.totalLrs }}</td>
                                        <td>{{ destSubStationData.totalArticles }}</td>
                                        <td>{{ destSubStationData.actualWeight }}</td>
                                        <td>{{ destSubStationData.chargedWeight }}</td>
                                        <td>{{ destSubStationData.toPay }}</td>
                                        <td>{{ destSubStationData.paid }}</td>
                                        <td>{{ destSubStationData.serviceTax }}</td>
                                        <td>{{ destSubStationData.ddAmt }}</td>
                                        <td>{{ destSubStationData.loaderName }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>

                            </table>
                        </div>

                        <div class="box-body" [hidden]="!hideAndShowInvoiceMainStationTable">
                            <table datatable id="{{pageId}}invoiceDetailsForMainStationTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsInvoiceMainStation" [dtTrigger]="dtTriggerInvoiceMainStation">
                                <thead>
                                    <tr>
                                        <th [hidden]=true>Destination</th>
                                        <th>Invoice Number</th>
                                        <th>Lrs</th>
                                        <th>Invoice Date</th>
                                        <th>Art</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>FOV</th>
                                        <th>Less</th>
                                        <th>DD Amt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let invoiceMainStationData of invoiceMainStationDataList ">
                                        <td [hidden]=true>{{ invoiceMainStationData.destination }}</td>
                                        <td (click)="mainSrcMainLrDetailsPopUpBtnLink(mainSrcLrDetailsPopUpTemplate,invoiceMainStationData)">
                                            <u class="hyperLink_td">{{invoiceMainStationData.invoiceNumber}}</u>
                                        </td>
                                        <td>{{ invoiceMainStationData.totalLrs }}</td>
                                        <td>{{ invoiceMainStationData.invoiceDateStr }}</td>
                                        <td>{{ invoiceMainStationData.totalArticles }}</td>
                                        <td>{{ invoiceMainStationData.actualWeight }}</td>
                                        <td>{{ invoiceMainStationData.chargedWeight }}</td>
                                        <td>{{ invoiceMainStationData.toPay }}</td>
                                        <td>{{ invoiceMainStationData.paid }}</td>
                                        <td>{{ invoiceMainStationData.riskCharge }}</td>
                                        <td>{{ invoiceMainStationData.discountAmount }}</td>
                                        <td>{{ invoiceMainStationData.ddAmt }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td [hidden]=true></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div class="box-body" [hidden]="!hideAndShowInvoiceSubStationTable">
                            <table datatable id="{{pageId}}invoiceDetailsForMainInvoiceSubStationTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsInvoiceSubStation" [dtTrigger]="dtTriggerInvoiceSubStation">
                                <thead>
                                    <tr>
                                        <th [hidden]=true>Destination</th>
                                        <th>Invoice Number</th>
                                        <th>Lrs</th>
                                        <th>Invoice Date</th>
                                        <th>Art</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>Less</th>
                                        <th>DD Amt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let invoiceSubStationData of invoiceSubStationDataList ">
                                        <td [hidden]=true>{{ invoiceSubStationData.destination }}</td>
                                        <td>{{ invoiceSubStationData.invoiceNumber }}</td>
                                        <td>{{ invoiceSubStationData.totalLrs }}</td>
                                        <td>{{ invoiceSubStationData.invoiceDateStr }}</td>
                                        <td>{{ invoiceSubStationData.totalArticles }}</td>
                                        <td>{{ invoiceSubStationData.actualWeight }}</td>
                                        <td>{{ invoiceSubStationData.chargedWeight }}</td>
                                        <td>{{ invoiceSubStationData.toPay }}</td>
                                        <td>{{ invoiceSubStationData.paid }}</td>
                                        <td>{{ invoiceSubStationData.discountAmount }}</td>
                                        <td>{{ invoiceSubStationData.ddAmt }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td [hidden]=true></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div class="box-body" [hidden]="!hideAndShowConsolidatedInvoiceTable">
                            <table datatable id="{{pageId}}invoiceDetailsConsolidateTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsConsolidatedInvoice" [dtTrigger]="dtTriggerConsolidatedInvoice">
                                <thead>
                                    <tr>
                                        <th>Invoice No</th>
                                        <th>Destination</th>
                                        <th>LRs</th>
                                        <th>Invoice Date</th>
                                        <th>Article</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>To Pay</th>
                                        <th>Paid</th>
                                        <th>F O V</th>
                                        <th>Discount</th>
                                        <th>Service Tax</th>
                                        <th>DD Amt</th>
                                        <th>Loaded By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let consolidatedInvoiceData of consolidatedInvoiceDataList ">
                                        <td>{{ consolidatedInvoiceData.invoiceNumber }}</td>
                                        <td>{{ consolidatedInvoiceData.destination }}</td>
                                        <td>{{ consolidatedInvoiceData.totalLrs }}</td>
                                        <td>{{ consolidatedInvoiceData.invoiceDate }}</td>
                                        <td>{{ consolidatedInvoiceData.articles }}</td>
                                        <td>{{ consolidatedInvoiceData.actualWeight }}</td>
                                        <td>{{ consolidatedInvoiceData.chargedWeight }}</td>
                                        <td>{{ consolidatedInvoiceData.toPay }}</td>
                                        <td>{{ consolidatedInvoiceData.paid }}</td>
                                        <td>{{ consolidatedInvoiceData.fov }}</td>
                                        <td>{{ consolidatedInvoiceData.lessAmt }}</td>
                                        <td>{{ consolidatedInvoiceData.lessAmt }}</td>
                                        <td>{{ consolidatedInvoiceData.ddAmt }}</td>
                                        <td>{{ consolidatedInvoiceData.loadedBy }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div class="box-body" [hidden]="!hideAndShowInvoiceDetailsDateWiseDetailsTable">
                            <table datatable id="{{pageId}}invoiceDetailsDateWiseDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsInvoiceDetailsDateWiseDetails" [dtTrigger]="dtTriggerInvoiceDetailsDateWiseDetails">
                                <thead>
                                    <tr>
                                        <th>Source</th>
                                        <th>Destination</th>
                                        <th>LR No</th>
                                        <th>Consignee</th>
                                        <th>Consignor</th>
                                        <th>Art</th>
                                        <th>Act Wgt</th>
                                        <th>Chg Wgt</th>
                                        <th>Goods Value</th>
                                        <th>E-Way Bill No</th>
                                        <th>E-Way Bill Valid Date</th>
                                        <th>Invoice No</th>
                                        <th>Invoice Date</th>
                                        <th>Truck No</th>
                                        <th>Driver No</th>
                                        <th>Supplier Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let dateWiseDetailsData of invoiceDetailsDateWiseDetailsList ">

                                        <td>{{ dateWiseDetailsData.source }}</td>
                                        <td>{{ dateWiseDetailsData.destination }}</td>
                                        <td>{{ dateWiseDetailsData.lrNumber }}</td>
                                        <td>{{ dateWiseDetailsData.consigneeName }}</td>
                                        <td>{{ dateWiseDetailsData.consignorName }}</td>
                                        <td>{{ dateWiseDetailsData.articles }}</td>
                                        <td>{{ dateWiseDetailsData.actualWeight }}</td>
                                        <td>{{ dateWiseDetailsData.chargedWeight }}</td>
                                        <td>{{ dateWiseDetailsData.goodsValue }}</td>
                                        <td>{{ dateWiseDetailsData.eWayBillNo }}</td>
                                        <td>{{ dateWiseDetailsData.ewayBillValidUptoStr }}</td>
                                        <td>{{ dateWiseDetailsData.invoiceNumber }}</td>
                                        <td>{{ dateWiseDetailsData.invoiceDateStr }}</td>
                                        <td>{{ dateWiseDetailsData.vehicleNumber }}</td>
                                        <td>{{ dateWiseDetailsData.driverContactNum }}</td>
                                        <td>{{ dateWiseDetailsData.supplierName }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <!--</div>-->
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <!--POP UP START HERE-->
    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group">
                <ng-template #invoiceDetailsLrsSummaryPopUpTemplate let-ok="close" let-d="dismiss">
                    <div class="modal-body">
                        <!-- 					<div class="row"> -->
                        <!-- 						<div class="col-md-12"> -->
                        <!-- 							<div class="card-body"> -->
                        <!-- 								<div class="row"> -->
                        <!-- 									<div class="col-sm-12 col-md-12"> -->
                        <!-- 										<button style="margin-left: 975px;" type="button" -->
                        <!-- 											class="btn btn-dark" id="{{pageId}}printBtnId" -->
                        <!-- 											(click)="printInvoiceCustom()">Print Invoice</button> -->
                        <!-- 										<button style="margin-left: 975px;" type="button" -->
                        <!-- 											class="btn btn-dark" id="{{pageId}}printBtnId" -->
                        <!-- 											(click)="printInvUnldSheet()">Unloading Sheet</button> -->
                        <!-- 										<button type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" -->
                        <!-- 											(click)="closeInvoiceDetailsRptPopUp()">Close</button> -->
                        <!-- 									</div> -->

                        <!-- 									<div class="col-sm-12 col-md-12"> -->
                        <!-- 										<div *ngIf="viewInvoiceDetailsLrsSummaryRptPopUp" -->
                        <!-- 											onafterPopUp="afterPopUp()" -->
                        <!-- 											id="{{pageId}}popupInvoiceDetailsLrsSummaryRpt"> -->
                        <!-- 											<app-invoice-details-lr-summary> -->
                        <!-- 											</app-invoice-details-lr-summary> -->
                        <!-- 										</div> -->
                        <!-- 									</div> -->
                        <!-- 								</div> -->
                        <!-- 							</div> -->
                        <!-- 						</div> -->
                        <!-- 					</div> -->

                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}printBtnId" (click)="printInvoiceCustom()">Print
                                                Invoice</button>
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}printBtnId" (click)="printInvUnldSheet()">Unloading
                                                Sheet</button>
                                            <button style="float: right;" type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" (click)="closeInvoiceDetailsRptPopUp()">Close</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div *ngIf="viewInvoiceDetailsLrsSummaryRptPopUp" onafterPopUp="afterPopUp()" id="{{pageId}}popupInvoiceDetailsLrsSummaryRpt">
                                                <app-invoice-details-lr-summary>
                                                </app-invoice-details-lr-summary>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <!--<div class="modal-footer">
						<div class="col-md-4"></div>
						<div class="col-md-4">
							<button type="button" style='background: green;width: 100%;color: white;'
								class="btn btn-outline-dark" (click)="closeStmtVerificationRptPopUp()">Close</button>
						</div>
						<div class="col-md-4"></div>
					</div>-->
                </ng-template>
            </div>
        </div>
    </div>

    <div class="col-md-12">
        <div class="form-group">
            <div class="input-group" id="{{pageId}}">
                <ng-template #mainSrcLrDetailsPopUpTemplate let-ok="close" let-d="dismiss">
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-body">
                                    <div class="row">
                                        <!-- 									<div class="col-sm-12 col-md-12"> -->
                                        <!-- 										<button style="margin-left: 975px;" type="button" -->
                                        <!-- 											class="btn btn-dark" id="{{pageId}}printBtnId" -->
                                        <!-- 											(click)="agentLocalTripPopUpPrint()">Print</button> -->
                                        <!-- 										<button type="button" class="btn btn-dark" id="{{pageId}}cancelBtnId" -->
                                        <!-- 											(click)="mainSrcLrDetailsPopUpClose()">Cancel</button> -->
                                        <!-- 									</div> -->
                                        <div class="col-sm-12 col-md-12">
                                            <div *ngIf="viewMainSrcLrDetailsPopUp" onafterPopUp="afterPopUp()" id="{{pageId}}popupLrDetails">
                                                <app-invoice-lrdetails-mainsrc-popup>
                                                </app-invoice-lrdetails-mainsrc-popup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

</body>

</html>

<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
    <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>

<div *ngIf="viewInvoiceDetailsPrint_Hireslip" onafterprint="afterPrint()" id="{{pageId}}printInvoiceDetailsHireslip">
    <app-invoice-details-rpt-print></app-invoice-details-rpt-print>
</div>