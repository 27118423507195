<!--<h1 mat-dialog-title>Consignee Rate Master-Setting Rate For All</h1>-->
<div mat-dialog-content>
    <div class="row">
        <div class='row p-t-10'>
            <div class="col-sm-12 col-md-12">
                <h6 class="card-title border_bottom">Rate For All Setup - Commodity
                </h6>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row">
                <!-- spinner start-->
                <div *ngIf="showSpinnerForAction" class="col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;">
                            </mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                Please Wait Loading Details.....</h6>
                        </div>
                    </div>
                </div>
                <!-- spinner end-->
                <div class="col-md-4">
                    <div class="card-body">
                        <div class="row">
                            <!--<h6 class="card-title">Rate Details-I</h6>-->
                            <div class="col-lg-12">
                                <div class="row">
                                    <div *ngIf="sourceView" class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Main Source</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-box"></i>
                                                    </span>
                                                </div>
                                                <input type="text" class="form-control" id="{{pageId}}mainBookingSourceId" (selectItem)="clickListnerForMainBookingSource($event)" [(ngModel)]="modelMainBookingSource" [ngbTypeahead]="searchMainBookingSource" [resultFormatter]="formatterMainBookingSource"
                                                    [inputFormatter]="formatterMainBookingSource" (focus)="focusMainBookingSourceTA$.next($any($event).target.value)" placeholder="Select Main Source.." />
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Source &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="ti-home"></i>
                                                        </span>
                                                    </div>
                                                    <input #agentName id="{{pageId}}agentName" type="text"
                                                        class="form-control"
                                                        (selectItem)="clickListnerForSource($event)"
                                                        [(ngModel)]="agentNameModal"
                                                        [ngModelOptions]="{standalone: true}"
                                                        [ngbTypeahead]="agentNameSearchTA"
                                                        [resultFormatter]="formatterAgentName"
                                                        [inputFormatter]="formatterAgentName"
                                                        placeholder="Select Source"
                                                        (focus)="focusAgentNameTA$.next($any($event).target.value)"
                                                        #instanceAgentName="ngbTypeahead" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Update Mode</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-check"></i>
                                                    </span>
                                                </div>
                                                <select class="custom-select col-12" #updateModePopUpId id="{{pageId}}updateModePopUpId">
                                                    <option selected value="Increase">Increase</option>
                                                    <option value="Decrease">Decrease</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-12 col-md-12">
                                        <div class="control">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Destination &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>

                                                    <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination" (focus)="focusDestinationTA$.next($any($event).target.value)"
                                                        placeholder="Select Destination" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Station Type</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class=" fas fa-check"></i>
                                                    </span>
                                                </div>
                                                <select class="custom-select col-12" #stationType id="{{pageId}}stationType">
                                                    <option selected value="Both">Both</option>
                                                    <option value="Mainstation">Mainstation</option>
                                                    <option value="Substation">Substation</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="row">
                                            <div class="col-sm-6 col-md-6">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <input class="col-sm-2 col-md-2" type="radio" name="sourceType" value="specific" (change)="sourceTypeSelected('specific')" [checked]="sourceType === 'specific'">
                                                        <label class="col-sm-10 col-md-10">
                                                            Specific Source
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-md-6">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <input class="col-sm-2 col-md-2" type="radio" name="sourceType" value="All" (change)="sourceTypeSelected('all')" [checked]="sourceType === 'all'">
                                                        <label class="col-sm-10 col-md-10">
                                                            All Source
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12" [hidden]="!viewSpecificSource">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class='row p-t-10'>
                                                    <!-- <div class="col-sm-12 col-md-12">
                                                        <h6 class="card-title border_bottom">Select Source to set the Rate
                                                        </h6>
                                                    </div> -->
                                                </div>
                                                <div class="box-body">
                                                    <table datatable id="{{pageId}}selectedSourceTableIdPartySpec" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsSourcePartySpec" [dtTrigger]="dtTriggerSourcePartySpec">
                                                        <thead>
                                                            <tr>
                                                                <th>Action</th>
                                                                <th>Source</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let agentDetails of agentDetailsOptionsSet let i = index">
                                                                <td><input type="checkbox" [checked]="multiSelect" id='selectedSource' (change)="rowCheckBoxForSource($event, agentDetails,i)" />
                                                                </td>
                                                                <td>{{ agentDetails.source }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="row">
                                            <div class="col-sm-6 col-md-6">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <input class="col-sm-2 col-md-2" type="radio" name="destinationType" value="specific" (change)="destinationTypeSelected('specific')" [checked]="destinationType === 'specific'">
                                                        <label class="col-sm-10 col-md-10">
                                                            Specific Destination
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-md-6">
                                                <div class="control">
                                                    <div class="form-group">
                                                        <input class="col-sm-2 col-md-2" type="radio" name="destinationType" value="all" (change)="destinationTypeSelected('all')" [checked]="destinationType === 'all'">
                                                        <label class="col-sm-10 col-md-10">
                                                            All Destination
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12" [hidden]="!viewSpecificDestination">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class='row p-t-10'>
                                                    <div class="col-sm-12 col-md-12">
                                                        <h6 class="card-title border_bottom">Select Destination to set the Rate</h6>
                                                    </div>
                                                </div>
                                                <div class="box-body">
                                                    <table datatable id="{{pageId}}selectedDestinationTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsDestination" [dtTrigger]="dtTriggerDestination">
                                                        <thead>
                                                            <tr>
                                                                <th>Action</th>
                                                                <th>Destination</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let destinationData of destinationDataList let i = index">
                                                                <td><input type="checkbox" [checked]="multiSelect" id='selectedDestination' (change)="rowCheckBoxForDestination($event, destinationData,i)" />
                                                                </td>
                                                                <td>{{ destinationData.godownName }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="row">
                        <!-- <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Commodity &nbsp;*</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-box"></i>
										</span>
                                    </div>
                                    <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                                        [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                                </div>
                            </div>
                        </div> -->
                        <div class="col-sm-12 col-md-12">
                            <div class="row">
                                <div class="col-sm-6 col-md-6">
                                    <div class="control">
                                        <div class="form-group">
                                            <input class="col-sm-2 col-md-2" type="radio" name="commodityType" value="specific" (change)="commodityTypeSelected('specific')" [checked]="commodityType === 'specific'">
                                            <label class="col-sm-10 col-md-10">
                                                Specific Commodity
                                            </label>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="control">
                                        <div class="form-group">
                                            <input class="col-sm-2 col-md-2" type="radio" name="commodityType" value="All" (change)="commodityTypeSelected('all')" [checked]="commodityType === 'all'">
                                            <label class="col-sm-10 col-md-10">
                                                All Commodity
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" [hidden]="!viewSpecificCommodity">
                            <div class="card">
                                <div class="card-body">
                                    <div class='row p-t-10'>
                                        <!-- <div class="col-sm-12 col-md-12">
                                            <h6 class="card-title border_bottom">Select Commodity</h6>
                                        </div> -->
                                    </div>
                                    <div class="box-body">
                                        <table datatable id="{{pageId}}selectedCommodityTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCommodity" [dtTrigger]="dtTriggerCommodity">
                                            <thead>
                                                <tr>
                                                    <th>Action</th>
                                                    <th>Commodity</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let commodityData of commodityDataList let i = index">
                                                    <td><input type="checkbox" [checked]="multiSelect" (change)="rowCheckBoxForCommodity($event, commodityData,i)" id='selectedCommodity' />
                                                    </td>
                                                    <td>{{ commodityData.commodityName }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Type</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-search"></i>
                                        </span>
                                    </div>
                                    <select class="custom-select col-12" id="{{pageId}}searchByRateType" name="searchByRateType" #searchByRateType (change)="searchByRateTypeMode(searchByRateType.value)">
                                        <option selected value="PerKg">Per Kg</option>
                                        <option value="Fixed">Fixed Per Article</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12" *ngIf="viewActChgWgtField">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Per Unit</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-search"></i>
                                        </span>
                                    </div>
                                    <select class="custom-select col-12" id="{{pageId}}perUnitId" name="perUnitId" #perUnit>
                                        <option selected value="ActualWeight">Actual Weight
                                        </option>
                                        <option value="ChargedWeight">Charged Weight</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Basic Rate &nbsp;*</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}basicRateId" #basicRate name="basicRateId" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForBaseChargeAmt($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Hamali</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}hamaliChgId" #hamaliChg name="hamaliChgId" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForHamaliChargeAmt($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>GC Charge</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}gcChargeId" #gcCharge name="gcChargeId" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForGCChargeAmt($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>AOC</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}aocChargeId" #aocCharge name="aocChargeId" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForAoc($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>DD Charge</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}ddChargeId" #ddCharge name="ddChargeId" aria-describedby="basic-addon11" autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Others</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}otherChargeId" #otherCharge name="otherCharge" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForOther($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>FOV(%) &nbsp;*</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}fovChargeId" #fovCharge name="fovChargeId" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForFov($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Fixed By &nbsp;*</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" aria-describedby="basic-addon11" autocomplete="off" id="{{pageId}}fixedBy">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Effect From &nbsp;*</label> <input class="form-control" id="{{pageId}}effectFromDate" placeholder="yyyy-mm-dd" name="effectFromDate" ngbDatepicker #effectFromDates="ngbDatepicker" autocomplete="off">
                                    <div class="input-group-append" (click)="effectFromDates.toggle()">
                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Fixed On &nbsp;*</label> <input id="{{pageId}}fixedOnDate" class="form-control" placeholder="yyyy-mm-dd" name="fixedOnDate" ngbDatepicker #fixedOnDates="ngbDatepicker" autocomplete="off">
                                    <div class="input-group-append" (click)="fixedOnDates.toggle()">
                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div *ngIf="viewSaveBtn" class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="{{pageId}}subStationCheckbox" (change)="toggleSubStationCheckbox($event)"> <label class=" custom-control-label wtfull " for="{{pageId}}subStationCheckbox">Separate Rate For
										Substation</label>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <!--col-md-6-1-end-->
                <div class="col-md-4">
                    <div class="row" *ngIf="viewSubRate">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Basic Rate &nbsp;*</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}basicRateIdSub" #basicRateSub name="basicRateIdSub" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForBaseChargeAmtSub($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Hamali</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}hamaliChgIdSub" #hamaliChgSub name="hamaliChgIdSub" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForHamaliChargeAmtSub($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>GC Charge</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}gcChargeIdSub" #gcChargeSub name="gcChargeIdSub" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForGCChargeAmtSub($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>AOC</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}aocChargeIdSub" #aocChargeSub name="aocChargeIdSub" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForAocSub($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>DD Charge</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}ddChargeIdSub" #ddChargeSub name="ddChargeIdSub" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForDDSub($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Others</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}otherChargeIdSub" #otherChargeSub name="otherChargeSub" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForOtherSub($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>FOV(%) &nbsp;*</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" id="{{pageId}}fovChargeIdSub" #fovChargeSub name="fovChargeIdSub" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForFovSub($event.keyCode)">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Fixed By &nbsp;*</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" aria-describedby="basic-addon11" autocomplete="off" id="{{pageId}}fixedBySub">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Effect From &nbsp;*</label> <input class="form-control" id="{{pageId}}effectFromDateSub" placeholder="yyyy-mm-dd" name="effectFromDateSub" ngbDatepicker #effectFromDateSubs="ngbDatepicker" autocomplete="off">
                                    <div class="input-group-append" (click)="effectFromDateSubs.toggle()">
                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div class="input-group">
                                    <label>Fixed On &nbsp;*</label> <input id="{{pageId}}fixedOnDateSub" class="form-control" placeholder="yyyy-mm-dd" name="fixedOnDateSub" ngbDatepicker #fixedOnDateSubs="ngbDatepicker" autocomplete="off">
                                    <div class="input-group-append" (click)="fixedOnDateSubs.toggle()">
                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--col-md-6-end-2-->
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions style="float: right;">
    <!--<button class="btn btn-primary" mat-button (click)="onOkClickBtn();">OK</button>-->
    <button class="btn btn-primary" mat-button (click)="SaveClickBtn();">Update</button>
    <button class="btn btn-primary" mat-button (click)="ClearClickBtn();">Clear</button>
    <button class="btn btn-danger" mat-button (click)="onCancelClick();">Close</button>
</div>