import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbModal, NgbTypeaheadSelectItemEvent, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { LRDto } from 'src/app/dto/LR-dto';
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { Invoice } from 'src/app/dataService/invoice';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { LrService } from 'src/app/dataService/lr-service';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';

@Component({
    selector: 'app-invoice-details',
    templateUrl: './invoice-details.component.html',
    styleUrls: ['./invoice-details.component.css']
})
export class InvoiceDetailsComponent implements OnInit {


    getDataInvoiceDetailsDataListTable: any;
    getDataDestSubStationTable: any;
    getDataInvoiceMainStation: any;
    getDataInvoiceSubStation: any;
    getDataServiceFrConsolidatedInvoiceTable: any;

    invoiceDetailsDataList: any;
    invoiceDetailsDataListTemp: any;
    destSubStationDataList: any;
    invoiceMainStationDataList: any;
    invoiceSubStationDataList: any;
    consolidatedInvoiceDataList: any;

    onDestroyUnsubscribtionInvoiceDetails: Subscription;
    onDestroyUnsubscribtionDestSubStation: Subscription;
    onDestroyUnsubscribtionInvoiceMainStation: Subscription;
    onDestroyUnsubscribtionInvoiceSubStation: Subscription;
    onDestroyUnsubscribtionConsolidatedInvoice: Subscription;



    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;


    searchByInvoiceNumber = true;
    searchByDate = false;
    viewSource = false;
    viewDestination = false;
    viewStationDestination = false;
    viewStationInvoice = false;
    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerInvoiceDetails: Subject<any> = new Subject();
    dtTriggerDestSubStation: Subject<any> = new Subject();
    dtTriggerInvoiceMainStation: Subject<any> = new Subject();
    dtTriggerInvoiceSubStation: Subject<any> = new Subject();
    dtTriggerConsolidatedInvoice: Subject<any> = new Subject();
    dtTriggerInvoiceDetailsDateWiseDetails: Subject<any> = new Subject();

    dataTable: any;

    dtOptionsInvoiceNoWiseDetails: any;
    dtOptionsInvoiceDetails: any;
    dtOptionsDestSubStation: any;
    dtOptionsInvoiceMainStation: any;
    dtOptionsInvoiceSubStation: any;
    dtOptionsConsolidatedInvoice: any;


    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;

    lrDtoDestinationAllOption: LRDto = new LRDto();
    destinationOptions: LRDto[];
    destinationTempOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    agentDtoOptionSourceStationAll: AgentDetailsDto = new AgentDetailsDto();
    agentDtoOptionSourceStation: AgentDetailsDto[];
    agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
    public modelMainBookingSource: any;
    mainBookingSourceTA: Array<AgentDetailsDto> = [];
    focusMainBookingSourceTA$ = new Subject<string>();
    searchMainBookingSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMainBookingSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.mainBookingSourceTA
                : this.mainBookingSourceTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMainBookingSource = (x: { mainBookStations: string }) => x.mainBookStations;

    lrDtoSourceStationAllOption: LRDto = new LRDto();
    sourceStationOptions: LRDto[];
    lrDtoSourceStation: LRDto = new LRDto();
    public modelSource: any;
    sourceSubStationNameTA: Array<LRDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceSubStationNameTA
                : this.sourceSubStationNameTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSource = (x: { subStations: string }) => x.subStations;

    lrDtoViaSourceStationAllOption: LRDto = new LRDto();
    viaSourceStationOptions: LRDto[];
    lrDtoViaSourceStation: LRDto = new LRDto();
    public modelViaSourceStation: any;
    viaSourceStationNameTA: Array<LRDto> = [];
    focusViaSourceStationTA$ = new Subject<string>();
    searchViaSourceStation = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusViaSourceStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.viaSourceStationNameTA
                : this.viaSourceStationNameTA.filter(v => v.destination && v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterViaSource = (x: { destination: string }) => x.destination;


    rightsForSuperAdministrator = "Rights For Invoice Details Rpt Super Administrator";
    rightsForTransshipmentManager = "Rights For Invoice Details Rpt Transshipment Manager";
    rightsForBookingAdministrator = "Rights For Invoice Details Rpt Booking Administrator";
    rightsForBookingManager = "Rights For Invoice Details Rpt Booking Manager";
    rightsForBookingOffice = "Rights For Invoice Details Rpt Booking Office";
    rightsForAdministrator = "Rights For Invoice Details Rpt Administrator";
    rightsForDeliveryManager = "Rights For Invoice Details Rpt Delivery Manager";
    rightsForCollectionManager = "Rights For Invoice Details Rpt Collection Manager";
    rightsForDeliveryOfficer = "Rights For Invoice Details Rpt Delivery Office";
    rightsForBarcode = "Rights For Invoice Details Rpt Barcode";


    isShowResult: boolean = false;
    showSpinnerForAction = false;

    hideAndShowInvoiceNoDropDown: boolean = false;
    hideAndShowDateWiseDropDown: boolean = false;
    hideAndShowSourceWiseDropDown: boolean = false;
    hideAndShowDestinationWiseDropDown: boolean = false;
    hideAndShowDateAndStationDropDown: boolean = false;
    hideAndShowMainInvoiceDropDown: boolean = false;
    hideAndShowConsolidateInvoiceNoDropDown: boolean = false;
    hideAndShowInvoiceNoWithDestinationDropDown: boolean = false;
    hideAndShowMainBkgSrcWiseDropDown: boolean = false;

    hideAndShowInvoiceNumberField: boolean = false;
    hideAndShowFromToDateField: boolean = false;
    hideAndShowSourceField: boolean = false;
    hideAndShowDestinationField: boolean = false;
    hideAndShowMainBkgSourceField: boolean = false;
    hideAndShowMainAndSubStationField: boolean = false;
    hideAndShowMainInvoiceOfStationSubStationField: boolean = false;
    hideAndShowViaSourceField: boolean = false;
    //hideAndShowSourceStationForOtherField: boolean = false;
    //hideAndShowSourceStationField: boolean = false;
    //hideAndShowSourceField: boolean = false;

    hideAndShowInvoiceDetailsTable: boolean = false;
    hideAndShowDestSubStationTable: boolean = false;
    hideAndShowInvoiceMainStationTable: boolean = false;
    hideAndShowInvoiceSubStationTable: boolean = false;
    hideAndShowConsolidatedInvoiceTable: boolean = false;
    hideAndShowInvoiceNoDetailsTable: boolean = false;

    lrDtoForSearchData: LRDto = new LRDto();

    validateInvoiceNumberIdValue: any = null;
    validateFromDateIdValue: any = null;
    validateToDateIdValue: any = null;
    validateSourceStationIdValue: any = null;
    validateMainSourceBkgStationIdValue: any = null;
    validateViaSourceStationIdValue: any = null;
    fromDatesModal: any = null;
    toDatesModal: any = null;
    validateDestinationStationIdValue: any = null;

    viewInvoiceDetailsLrsSummaryRptPopUp = false;
    modalRefferenceInvoiceDetailsPopUp: NgbModalRef;
    modalRefferenceInvoiceDetailsClosePopUp: string;
    @ViewChild('invoiceDetailsLrsSummaryPopUpTemplate') private invoiceDetailsLrsSummaryPopUpTemplate;

    lrDtoForLocalStorage: LRDto = new LRDto();
    fromDateForLocalStorage: any = null;
    toDateForLocalStorage: any = null;
    searchByModeForLocalStorage: any = null;

    modalRefferenceMainSrcLrDetailsPopUp: NgbModalRef;
    modalRefferenceMainSrcLrDetailsClosePopUp: string;
    viewMainSrcLrDetailsPopUp = false;

    lrDtoLoadingSheet: LRDto = new LRDto();
    invoiceDetailsLoadingSheetDataList: any;
    artSummary: number;

    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    viewCustomPrintV1: boolean;
    viewInvoiceDetailsPrint_Hireslip: boolean;

    lrsSummary: number;
    articleSummary: number;
    acWtSummary: number;
    chgWtSummary: number;
    toPaySummary: number;
    paidSummary: number;
    fovSummary: number;
    discountSummary: number;
    serTaxSummary: number;
    lessAmountSummary: number;
    pageId = "invdc";
    hideAndShowInvoiceDateField: boolean = false;
    hideAndShowInvoiceDetailsDateWiseDetailsTable: boolean = false;
    hideAndShowDateWiseDetailsDropDown: boolean = false;
    lrDto: LRDto = new LRDto();
    invoiceDateModal: any;
    invoiceDetailsDateWiseDetailsList: any;
    validateInvoiceDateValue: any = null;
    dtOptionsInvoiceDetailsDateWiseDetails: any;

    constructor(private invoiceDetailsRpt: Invoice,
        private memoLessRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
        private modalService: NgbModal, private lrService: LrService, public changeDetectorRef: ChangeDetectorRef,
        private destStmtReport: DestStmtReport) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;


            //here hide show logic
            this.hideAndShowInvoiceNoDropDown = true;
            this.hideAndShowDateWiseDropDown = true;
            this.hideAndShowInvoiceNumberField = true;
            this.hideAndShowInvoiceNoDetailsTable = true;
            this.hideAndShowDateWiseDetailsDropDown = true;
            /* if(this.rightsForSuperAdministrator == "Rights For Invoice Details Rpt Super Administrator"){
                 */
            if (this.userDataDtoReturnSession.role != null &&
                this.userDataDtoReturnSession.role == "Super Administrator") {
                this.hideAndShowConsolidateInvoiceNoDropDown = true;
            } else {
                this.hideAndShowConsolidateInvoiceNoDropDown = false;
            }

            /* if (this.rightsForTransshipmentManager == "Rights For Invoice Details Rpt Transshipment Manager" ||
                 this.rightsForSuperAdministrator == "Rights For Invoice Details Rpt Super Administrator" ||
                 this.rightsForBookingAdministrator == "Rights For Invoice Details Rpt Booking Administrator") {
             */
            if (this.userDataDtoReturnSession.role != null &&
                this.userDataDtoReturnSession.role == "Transshipment Manager" ||
                this.userDataDtoReturnSession.role == "Super Administrator" ||
                this.userDataDtoReturnSession.role == "Booking Administrator") {
                this.hideAndShowDateAndStationDropDown = true;
                this.hideAndShowSourceWiseDropDown = true;
                this.hideAndShowDestinationWiseDropDown = true;
                this.hideAndShowMainInvoiceDropDown = true;
            } else {
                this.hideAndShowDateAndStationDropDown = false;
                this.hideAndShowMainInvoiceDropDown = false;
            }

            /*if ((this.rightsForBookingManager == "Rights For Invoice Details Rpt Booking Manager") ||
                ((this.rightsForBookingOffice == "Rights For Invoice Details Rpt Booking Office") &&
                    (this.rightsForAdministrator == "Rights For Invoice Details Rpt Administrator"))) {
            */
            if ((this.userDataDtoReturnSession.role == "Booking Manager") ||
                ((this.userDataDtoReturnSession.officeType == "Booking Office") &&
                    (this.userDataDtoReturnSession.role == "Administrator"))) {
                this.hideAndShowDestinationWiseDropDown = true;
                this.hideAndShowSourceWiseDropDown = false;
            }
            /* else if ((this.rightsForDeliveryManager == "Rights For Invoice Details Rpt Delivery Manager") ||
                 (this.rightsForCollectionManager == "Rights For Invoice Details Rpt Collection Manager") ||
                 (this.rightsForDeliveryOfficer == "Rights For Invoice Details Rpt Delivery Office") &&
                 ((this.rightsForAdministrator == "Rights For Invoice Details Rpt Administrator") ||
                     (this.rightsForBarcode == "Rights For Invoice Details Rpt Barcode"))) {
                         */
            else if ((this.userDataDtoReturnSession.role == "Delivery Manager") ||
                (this.userDataDtoReturnSession.role == "Collection Manager") ||
                (this.userDataDtoReturnSession.officeType == "Delivery Office") &&
                ((this.userDataDtoReturnSession.role == "Administrator") ||
                    (this.userDataDtoReturnSession.role == "Barcode"))) {
                this.hideAndShowDestinationWiseDropDown = false;
                this.hideAndShowSourceWiseDropDown = true;
            }

            /* if(this.rightsForBookingManager == "Rights For Invoice Details Rpt Booking Manager"){
                */
            if (this.userDataDtoReturnSession.role == "Booking Manager") {
                this.hideAndShowInvoiceNoWithDestinationDropDown = true;
            } else {
                this.hideAndShowInvoiceNoWithDestinationDropDown = false;
            }



            //this.getSourceStationDetails();
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Show Invoice Details Result") {
                        this.isShowResult = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "MainBgkSrcWiseInvRpt") {
                        this.hideAndShowMainBkgSrcWiseDropDown = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "MainInvRpt") {
                        this.hideAndShowMainInvoiceDropDown = true;
                    }
                }

                this.getFromStationMethod();
                this.getDestinationDetails();
                this.getMainBookingSourceDetails();
                this.getViaSourceStationDetails();
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    ngOnInit(): void {
        var companyAddressDetls = this.address;
        var groupColumnDateWise = 0;
        this.dtOptionsInvoiceNoWiseDetails = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        if ($("#invdcsearchBy").val() == "invoiceNumber") {
                            return " Destination Invoice Report Summary - " +
                                "Invoice No : " + $("#invdcinvoiceNumberId").val() + "";
                        } else if ($("#invdcsearchBy").val() == "dateWise") {
                            return " Destination Invoice Report Summary - " +
                                "Form Date : " + moment($("#invdcfromDates").val()).format("DD-MM-YYYY") + " - " +
                                "To Date : " + moment($("#invdctoDate").val()).format("DD-MM-YYYY") + "";
                        } else if ($("#invdcsearchBy").val() == "sourceWise") {
                            return " Destination Invoice Report Summary - " +
                                "Form Date : " + moment($("#invdcfromDates").val()).format("DD-MM-YYYY") + " - " +
                                "To Date : " + moment($("#invdctoDate").val()).format("DD-MM-YYYY") + " - " +
                                "Source : " + $("#invdcsourceId").val() + "";
                        } else if ($("#invdcsearchBy").val() == "destinationWise") {
                            return " Destination Report Summary - " +
                                "Form Date : " + moment($("#invdcfromDates").val()).format("DD-MM-YYYY") + " - " +
                                "To Date : " + moment($("#invdctoDate").val()).format("DD-MM-YYYY") + " - " +
                                "Destination : " + $("#invdcdestinationId").val() + " - " +
                                "Station : " + $("#invdcsearchStationWiseBy").val() + "";
                        }
                        else if ($("#invdcsearchBy").val() == "dataAndStationWise") {
                            return " Destination Invoice Report Summary - " +
                                "Form Date : " + moment($("#invdcfromDates").val()).format("DD-MM-YYYY") + " - " +
                                "To Date : " + moment($("#invdctoDate").val()).format("DD-MM-YYYY") + " - " +
                                "Source : " + $("#invdcsourceId").val() + " - " +
                                "Destination : " + $("#invdcdestinationId").val() + "";
                        } else if ($("#invdcsearchBy").val() == "mainBkgSrcWise") {
                            return " Destination Invoice Report Summary - " +
                                "Form Date : " + moment($("#invdcfromDates").val()).format("DD-MM-YYYY") + " - " +
                                "To Date : " + moment($("#invdctoDate").val()).format("DD-MM-YYYY") + " - " +
                                "Source : " + $("#invdcmainBookingSourceId").val() + " - " +
                                "Destination : " + $("#invdcdestinationId").val() + "";
                        }
                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 320,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalLrs = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var articles = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPayAmt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paidAmt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var discAmt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var fovAmt = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var ddAmt = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(totalLrs);
                $(api.column(4).footer()).html(articles);
                $(api.column(5).footer()).html(actWgt);
                $(api.column(6).footer()).html(chgWgt);
                $(api.column(7).footer()).html(toPayAmt);
                $(api.column(8).footer()).html(paidAmt);
                $(api.column(9).footer()).html(discAmt.toFixed());
                $(api.column(10).footer()).html(fovAmt);
                $(api.column(11).footer()).html(ddAmt);
            }
        },
            this.dtOptionsInvoiceDetails = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            if ($("#invdcsearchBy").val() == "invoiceNumber") {
                                return " Destination Invoice Report Summary - " +
                                    "Invoice No : " + $("#invdcinvoiceNumberId").val() + "";
                            } else if ($("#invdcsearchBy").val() == "dateWise") {
                                return " Destination Invoice Report Summary - " +
                                    "Form Date : " + moment($("#invdcfromDates").val()).format("DD-MM-YYYY") + " - " +
                                    "To Date : " + moment($("#invdctoDate").val()).format("DD-MM-YYYY") + "";
                            } else if ($("#invdcsearchBy").val() == "sourceWise") {
                                return " Destination Invoice Report Summary - " +
                                    "Form Date : " + moment($("#invdcfromDates").val()).format("DD-MM-YYYY") + " - " +
                                    "To Date : " + moment($("#invdctoDate").val()).format("DD-MM-YYYY") + " - " +
                                    "Source : " + $("#invdcsourceId").val() + "";
                            } else if ($("#invdcsearchBy").val() == "destinationWise") {
                                return " Destination Report Summary - " +
                                    "Form Date : " + moment($("#invdcfromDates").val()).format("DD-MM-YYYY") + " - " +
                                    "To Date : " + moment($("#invdctoDate").val()).format("DD-MM-YYYY") + " - " +
                                    "Destination : " + $("#invdcdestinationId").val() + " - " +
                                    "Station : " + $("#invdcsearchStationWiseBy").val() + "";
                            }
                            else if ($("#invdcsearchBy").val() == "dataAndStationWise") {
                                return " Destination Invoice Report Summary - " +
                                    "Form Date : " + moment($("#invdcfromDates").val()).format("DD-MM-YYYY") + " - " +
                                    "To Date : " + moment($("#invdctoDate").val()).format("DD-MM-YYYY") + " - " +
                                    "Source : " + $("#invdcsourceId").val() + " - " +
                                    "Destination : " + $("#invdcdestinationId").val() + "";
                            } else if ($("#invdcsearchBy").val() == "mainBkgSrcWise") {
                                return " Destination Invoice Report Summary - " +
                                    "Form Date : " + moment($("#invdcfromDates").val()).format("DD-MM-YYYY") + " - " +
                                    "To Date : " + moment($("#invdctoDate").val()).format("DD-MM-YYYY") + " - " +
                                    "Source : " + $("#invdcmainBookingSourceId").val() + " - " +
                                    "Destination : " + $("#invdcdestinationId").val() + "";
                            }
                        },
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 320,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;
                    var totArt = 0;
                    var actWgt = 0;
                    var chgWgt = 0;
                    var toPayAmt = 0;
                    var paidAmt = 0;
                    var totLrs = 0;
                    var fov = 0;
                    var less = 0;
                    var count = 0;
                    var totddAmt = 0;
                    api.column(groupColumnDateWise, {
                        page: 'current'
                    }).data().each(function (group, i) {
                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": "Total : " + count
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totLrs
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totArt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": actWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": chgWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": toPayAmt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": paidAmt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": less
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": fov
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totddAmt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .prop('outerHTML'));
                                totLrs = 0;
                                totArt = 0;
                                actWgt = 0;
                                chgWgt = 0;
                                toPayAmt = 0;
                                paidAmt = 0;
                                count = 0;
                                fov = 0;
                                less = 0;
                                totddAmt = 0;
                            }
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #e2c185 !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 12,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }
                        count++;
                        totLrs += +val[2];
                        totArt += +val[4];
                        actWgt += +val[5];
                        chgWgt += +val[6];
                        toPayAmt += +val[7];
                        paidAmt += +val[8];
                        less += +val[9];
                        fov += +val[10];
                        totddAmt += +val[11];
                        if (i == (rows.length - 1)) {

                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totLrs
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totArt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": actWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": chgWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": toPayAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": paidAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": less
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": fov
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totddAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .prop('outerHTML'));
                            totLrs = 0;
                            totArt = 0;
                            actWgt = 0;
                            chgWgt = 0;
                            toPayAmt = 0;
                            paidAmt = 0;
                            fov = 0;
                            less = 0;
                            count = 0;
                            totddAmt = 0;
                        }
                    });
                },
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var totalLrs = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var articles = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWgt = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPayAmt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paidAmt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var discAmt = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var fovAmt = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var ddAmt = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    $(api.column(1).footer()).html('Total : ' + data.length);
                    $(api.column(2).footer()).html(totalLrs);
                    $(api.column(4).footer()).html(articles);
                    $(api.column(5).footer()).html(actWgt);
                    $(api.column(6).footer()).html(chgWgt);
                    $(api.column(7).footer()).html(toPayAmt);
                    $(api.column(8).footer()).html(paidAmt);
                    $(api.column(9).footer()).html(discAmt.toFixed());
                    $(api.column(10).footer()).html(fovAmt);
                    $(api.column(11).footer()).html(ddAmt);
                }
            },
            this.dtOptionsDestSubStation = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            if ($("#invdcsearchBy").val() == "destinationWise") {
                                return " Destination Invoice Report Summary - " +
                                    "Form Date : " + moment($("#invdcfromDates").val()).format("DD-MM-YYYY") + " - " +
                                    "To Date : " + moment($("#invdctoDate").val()).format("DD-MM-YYYY") + " - " +
                                    "Destination : " + $("#invdcdestinationId").val() + " - " +
                                    "Station : " + $("#invdcsearchStationWiseBy").val() + "";
                            }
                        },
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 320,
                "scrollCollapse": true,
                "paging": false,
                "info": true,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var totalLrs = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var articles = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(3).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWgt = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPayAmt = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paidAmt = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var serviceAmt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var ddAmt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(totalLrs);
                    $(api.column(2).footer()).html(articles);
                    $(api.column(3).footer()).html(actWgt);
                    $(api.column(4).footer()).html(chgWgt);
                    $(api.column(5).footer()).html(toPayAmt);
                    $(api.column(6).footer()).html(paidAmt);
                    $(api.column(7).footer()).html(serviceAmt);
                    $(api.column(7).footer()).html(ddAmt);
                }
            },
            this.dtOptionsInvoiceMainStation = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;
                    var totArt = 0;
                    var actWgt = 0;
                    var chgWgt = 0;
                    var toPayAmt = 0;
                    var paidAmt = 0;
                    var totLrs = 0;
                    var fov = 0;
                    var less = 0;
                    var count = 0;
                    var totddAmt = 0;
                    api.column(groupColumnDateWise, {
                        page: 'current'
                    }).data().each(function (group, i) {

                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": "Total : " + count
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totLrs
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totArt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": actWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": chgWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": toPayAmt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": paidAmt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": fov
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": less
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totddAmt
                                                }))
                                            .prop('outerHTML'));
                                totLrs = 0;
                                totArt = 0;
                                actWgt = 0;
                                chgWgt = 0;
                                toPayAmt = 0;
                                paidAmt = 0;
                                count = 0;
                                fov = 0;
                                less = 0;
                                totddAmt = 0;
                            }
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #e2c185 !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 11,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }
                        count++;
                        totLrs += +val[2];
                        totArt += +val[4];
                        actWgt += +val[5];
                        chgWgt += +val[6];
                        toPayAmt += +val[7];
                        paidAmt += +val[8];
                        fov += +val[9];
                        less += +val[10];
                        totddAmt += +val[11];
                        if (i == (rows.length - 1)) {

                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totLrs
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totArt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": actWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": chgWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": toPayAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": paidAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": fov
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": less
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totddAmt
                                            }))
                                        .prop('outerHTML'));
                            totLrs = 0;
                            totArt = 0;
                            actWgt = 0;
                            chgWgt = 0;
                            toPayAmt = 0;
                            paidAmt = 0;
                            fov = 0;
                            less = 0;
                            count = 0;
                            totddAmt = 0;
                        }
                    });
                },
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var totalLrs = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var articles = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWgt = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPayAmt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paidAmt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var discAmt = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var fovAmt = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var ddAmt = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    $(api.column(1).footer()).html('Total : ' + data.length);
                    $(api.column(2).footer()).html(totalLrs);
                    $(api.column(4).footer()).html(articles);
                    $(api.column(5).footer()).html(actWgt);
                    $(api.column(6).footer()).html(chgWgt);
                    $(api.column(7).footer()).html(toPayAmt);
                    $(api.column(8).footer()).html(paidAmt);
                    $(api.column(9).footer()).html(discAmt.toFixed());
                    $(api.column(10).footer()).html(fovAmt);
                    $(api.column(11).footer()).html(ddAmt);
                }
            },
            this.dtOptionsInvoiceSubStation = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "drawCallback": function (settings) {
                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;

                    var api = this.api();
                    var rows = api.rows({
                        page: 'current'
                    }).nodes();
                    var last = null;
                    var totArt = 0;
                    var actWgt = 0;
                    var chgWgt = 0;
                    var toPayAmt = 0;
                    var paidAmt = 0;
                    var totLrs = 0;
                    var fov = 0;
                    var less = 0;
                    var count = 0;
                    var totddAmt = 0;
                    api.column(groupColumnDateWise, {
                        page: 'current'
                    }).data().each(function (group, i) {

                        var val = api.row(api.row($(rows).eq(i)).index()).data();
                        if (last !== group) {
                            if (i != 0) {
                                $(rows)
                                    .eq(i)
                                    .before(
                                        $(
                                            "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                            {
                                                "class": "group",
                                                "data-id": group
                                            })
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": "Total : " + count
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totLrs
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": ""
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totArt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": actWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": chgWgt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": toPayAmt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": paidAmt
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": less
                                                }))
                                            .append($("<td></td>",
                                                {
                                                    "colspan": 1,
                                                    "text": totddAmt
                                                }))
                                            .prop('outerHTML'));
                                totLrs = 0;
                                totArt = 0;
                                actWgt = 0;
                                chgWgt = 0;
                                toPayAmt = 0;
                                paidAmt = 0;
                                count = 0;
                                fov = 0;
                                less = 0;
                                totddAmt = 0;
                            }
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #e2c185 !important; font-weight:bold;text-align: center;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append(
                                            $("<td></td>",
                                                {
                                                    "colspan": 10,
                                                    "text": group
                                                })).prop('outerHTML'));
                            last = group;
                        }
                        count++;
                        totLrs += +val[2];
                        totArt += +val[4];
                        actWgt += +val[5];
                        chgWgt += +val[6];
                        toPayAmt += +val[7];
                        paidAmt += +val[8];
                        less += +val[9];
                        totddAmt += +val[10];
                        if (i == (rows.length - 1)) {

                            $(rows)
                                .eq(i)
                                .after(
                                    $(
                                        "<tr style='background-color: #f7cd82 !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total : " + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totLrs
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totArt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": actWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": chgWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": toPayAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": paidAmt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": less
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totddAmt
                                            }))
                                        .prop('outerHTML'));
                            totLrs = 0;
                            totArt = 0;
                            actWgt = 0;
                            chgWgt = 0;
                            toPayAmt = 0;
                            paidAmt = 0;
                            fov = 0;
                            less = 0;
                            count = 0;
                            totddAmt = 0;
                        }
                    });
                },
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var totalLrs = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var articles = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWgt = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPayAmt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paidAmt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var discAmt = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var ddAmt = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(2).footer()).html(totalLrs);
                    $(api.column(4).footer()).html(articles);
                    $(api.column(5).footer()).html(actWgt);
                    $(api.column(6).footer()).html(chgWgt);
                    $(api.column(7).footer()).html(toPayAmt);
                    $(api.column(8).footer()).html(paidAmt);
                    $(api.column(9).footer()).html(discAmt.toFixed());
                    $(api.column(10).footer()).html(ddAmt);
                }
            },
            this.dtOptionsConsolidatedInvoice = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            if ($("#invdcsearchBy").val() == "consolidatedInvoice") {
                                return " Destination Invoice Report Summary - " +
                                    "Invoice No : " + $("#invdcinvoiceNumberId").val() + "";
                            }
                        },
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 380,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var totalLrs = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var articles = api.column(4).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWgt = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var toPayAmt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var paidAmt = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var fovAmt = api.column(9).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var discAmt = api.column(10).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var serviceAmt = api.column(11).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var ddAmt = api.column(12).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(2).footer()).html(totalLrs);
                    $(api.column(4).footer()).html(articles);
                    $(api.column(5).footer()).html(actWgt);
                    $(api.column(6).footer()).html(chgWgt);
                    $(api.column(7).footer()).html(toPayAmt);
                    $(api.column(8).footer()).html(paidAmt);
                    $(api.column(9).footer()).html(fovAmt);
                    $(api.column(10).footer()).html(discAmt);
                    $(api.column(11).footer()).html(serviceAmt);
                    $(api.column(12).footer()).html(ddAmt);
                }
            },
            this.dtOptionsInvoiceDetailsDateWiseDetails = {
                dom: 'Bfrtip',
                buttons: [
                    {
                        extend: 'excel',
                        text: '<i class="fas fa-file-excel"> Excel</i>',
                        titleAttr: 'Excel',
                        footer: true,
                        title: function () {
                            return " Date Wise Details Invoice Report - ";

                        },
                        exportOptions: {
                            columns: ':visible'
                        }
                    }
                ],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 330,
                "scrollCollapse": true,
                "paging": false,
                "info": true,
                "order": [[11, "asc"]],
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var articles = api.column(5).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var actWgt = api.column(6).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var chgWgt = api.column(7).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                    var goodsValue = api.column(8).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0).toFixed(2);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(5).footer()).html(articles);
                    $(api.column(6).footer()).html(actWgt);
                    $(api.column(7).footer()).html(chgWgt);
                    $(api.column(8).footer()).html(goodsValue);
                }
            }
    }


    ngAfterViewInit(): void {
        this.dtTriggerInvoiceDetails.next();
        this.dtTriggerDestSubStation.next();
        this.dtTriggerInvoiceMainStation.next();
        this.dtTriggerInvoiceSubStation.next();
        this.dtTriggerConsolidatedInvoice.next();
        this.dtTriggerInvoiceDetailsDateWiseDetails.next();
    }


    ngOnDestroy(): void {
        this.dtTriggerInvoiceDetails.unsubscribe();
        this.dtTriggerDestSubStation.unsubscribe();
        this.dtTriggerInvoiceMainStation.unsubscribe();
        this.dtTriggerInvoiceSubStation.unsubscribe();
        this.dtTriggerConsolidatedInvoice.unsubscribe();
        this.dtTriggerInvoiceDetailsDateWiseDetails.unsubscribe();

        //this.onDestroyUnsubscribtionInvoiceDetails.unsubscribe();
        //this.onDestroyUnsubscribtionDestSubStation.unsubscribe();
        //this.onDestroyUnsubscribtionInvoiceMainStation.unsubscribe();
        //this.onDestroyUnsubscribtionInvoiceSubStation.unsubscribe();
        //this.onDestroyUnsubscribtionConsolidatedInvoice.unsubscribe();
    }

    searchByStationWiseMode(searchBy: string) {
        console.log(searchBy);
        if (searchBy === 'mainStation') {
            this.hideAndShowInvoiceDetailsTable = true;
            this.hideAndShowDestSubStationTable = false;
            this.hideAndShowInvoiceMainStationTable = false;
            this.hideAndShowInvoiceSubStationTable = false;
            this.hideAndShowConsolidatedInvoiceTable = false;
            this.hideAndShowInvoiceNoDetailsTable = false;

            this.invoiceDetailsDataList = [];
            this.invoiceDetailsDataListTemp = [];
            $("#" + this.pageId + "invoiceDetailsTableId").DataTable().destroy();
            this.dtTriggerInvoiceDetails.next();
        } else if (searchBy === 'subStation') {
            this.hideAndShowInvoiceDetailsTable = false;
            this.hideAndShowDestSubStationTable = true;
            this.hideAndShowInvoiceMainStationTable = false;
            this.hideAndShowInvoiceSubStationTable = false;
            this.hideAndShowConsolidatedInvoiceTable = false;
            this.hideAndShowInvoiceNoDetailsTable = false;

            this.destSubStationDataList = [];
            $("#" + this.pageId + "invoiceDetailsDestSubStationTableId").DataTable().destroy();
            this.dtTriggerDestSubStation.next();
        }
    }

    searchByMainInvoiceStationWiseMode(searchMainInvoiceStationWiseBy: string) {
        console.log(searchMainInvoiceStationWiseBy);
        if (searchMainInvoiceStationWiseBy === 'mainStation') {
            this.hideAndShowInvoiceDetailsTable = false;
            this.hideAndShowDestSubStationTable = false;
            this.hideAndShowInvoiceMainStationTable = true;
            this.hideAndShowInvoiceSubStationTable = false;
            this.hideAndShowConsolidatedInvoiceTable = false;
            this.hideAndShowInvoiceNoDetailsTable = false;

            $("#" + this.pageId + "destinationId").val('All');
            this.hideAndShowViaSourceField = false;

            this.invoiceMainStationDataList = [];
            $("#" + this.pageId + "invoiceDetailsForMainStationTableId").DataTable().destroy();
            this.dtTriggerInvoiceMainStation.next();
        } else if (searchMainInvoiceStationWiseBy === 'subStation') {
            this.hideAndShowInvoiceDetailsTable = false;
            this.hideAndShowDestSubStationTable = false;
            this.hideAndShowInvoiceMainStationTable = false;
            this.hideAndShowInvoiceSubStationTable = true;
            this.hideAndShowConsolidatedInvoiceTable = false;
            this.hideAndShowInvoiceNoDetailsTable = false;

            $("#" + this.pageId + "destinationId").val('All');
            this.hideAndShowViaSourceField = false;

            this.invoiceSubStationDataList = [];
            $("#" + this.pageId + "invoiceDetailsForMainInvoiceSubStationTableId").DataTable().destroy();
            this.dtTriggerInvoiceSubStation.next();
        }
    }
    validateClearBtn() {
        $("#" + this.pageId + "searchBy").val('invoiceNumber');
        //field hide show
        this.hideAndShowInvoiceNumberField = true;
        this.hideAndShowFromToDateField = false;
        this.hideAndShowSourceField = false;
        this.hideAndShowDestinationField = false;
        this.hideAndShowMainBkgSourceField = false;
        this.hideAndShowMainAndSubStationField = false;
        this.hideAndShowMainInvoiceOfStationSubStationField = false;
        this.hideAndShowViaSourceField = false;
        this.hideAndShowInvoiceDateField = false;

        // table hide show
        this.hideAndShowInvoiceDetailsTable = false;
        this.hideAndShowDestSubStationTable = false;
        this.hideAndShowInvoiceMainStationTable = false;
        this.hideAndShowInvoiceSubStationTable = false;
        this.hideAndShowConsolidatedInvoiceTable = false;
        this.hideAndShowInvoiceNoDetailsTable = true;
        this.hideAndShowInvoiceDetailsDateWiseDetailsTable = false;

        this.invoiceDetailsDataList = [];
        $("#" + this.pageId + "invoiceDetailsTableId").DataTable().destroy();
        this.dtTriggerInvoiceDetails.next();

        this.clearInputField();
    }
    clearInputField() {
        $("#" + this.pageId + "invoiceNumberId").val('');
        $("#" + this.pageId + "searchStationWiseBy").val('mainStation');
        this.modelDestination = null;
        this.modelSource = null;
        this.modelMainBookingSource = null;
        this.modelViaSourceStation = null;
        this.fromDatesModal = null;
        this.toDatesModal = null;
        this.validateInvoiceNumberIdValue = null;
        this.validateFromDateIdValue = null;
        this.validateToDateIdValue = null;
        this.validateSourceStationIdValue = null;
        this.validateMainSourceBkgStationIdValue = null;
        this.validateViaSourceStationIdValue = null;
        this.invoiceDateModal = null;
        this.invoiceDetailsDataListTemp = [];
    }

    searchByMode(searchBy: string) {
        if (searchBy === 'invoiceNumber') {
            //field hide show
            this.hideAndShowInvoiceNumberField = true;
            this.hideAndShowFromToDateField = false;
            this.hideAndShowSourceField = false;
            this.hideAndShowDestinationField = false;
            this.hideAndShowMainBkgSourceField = false;
            this.hideAndShowMainAndSubStationField = false;
            this.hideAndShowMainInvoiceOfStationSubStationField = false;
            this.hideAndShowViaSourceField = false;
            this.hideAndShowInvoiceDateField = false;

            // table hide show
            this.hideAndShowInvoiceDetailsTable = false;
            this.hideAndShowDestSubStationTable = false;
            this.hideAndShowInvoiceMainStationTable = false;
            this.hideAndShowInvoiceSubStationTable = false;
            this.hideAndShowConsolidatedInvoiceTable = false;
            this.hideAndShowInvoiceNoDetailsTable = true;
            this.hideAndShowInvoiceDetailsDateWiseDetailsTable = false;

            this.invoiceDetailsDataList = [];
            $("#" + this.pageId + "invoiceDetailsTableId").DataTable().destroy();
            this.dtTriggerInvoiceDetails.next();

            this.clearInputField();
            //$("#"+this.pageId+"searchBy").val('invoiceNumber');

        } else if (searchBy === 'dateWise') {
            this.hideAndShowInvoiceNumberField = false;
            this.hideAndShowFromToDateField = true;
            this.hideAndShowSourceField = false;
            this.hideAndShowDestinationField = false;
            this.hideAndShowMainBkgSourceField = false;
            this.hideAndShowMainAndSubStationField = false;
            this.hideAndShowMainInvoiceOfStationSubStationField = false;
            this.hideAndShowViaSourceField = false;
            this.hideAndShowInvoiceDateField = false;

            this.hideAndShowInvoiceDetailsTable = true;
            this.hideAndShowDestSubStationTable = false;
            this.hideAndShowInvoiceMainStationTable = false;
            this.hideAndShowInvoiceSubStationTable = false;
            this.hideAndShowConsolidatedInvoiceTable = false;
            this.hideAndShowInvoiceNoDetailsTable = false;
            this.hideAndShowInvoiceDetailsDateWiseDetailsTable = false;

            this.invoiceDetailsDataList = [];
            $("#" + this.pageId + "invoiceDetailsTableId").DataTable().destroy();
            this.dtTriggerInvoiceDetails.next();

            this.clearInputField();
            //$("#"+this.pageId+"searchBy").val('dateWise');

        } else if (searchBy === 'sourceWise') {
            this.hideAndShowInvoiceNumberField = false;
            this.hideAndShowFromToDateField = true;
            this.hideAndShowSourceField = true;
            this.hideAndShowDestinationField = false;
            this.hideAndShowMainBkgSourceField = false;
            this.hideAndShowMainAndSubStationField = false;
            this.hideAndShowMainInvoiceOfStationSubStationField = false;
            this.hideAndShowViaSourceField = false;
            this.hideAndShowInvoiceDateField = false;

            this.hideAndShowInvoiceDetailsTable = true;
            this.hideAndShowDestSubStationTable = false;
            this.hideAndShowInvoiceMainStationTable = false;
            this.hideAndShowInvoiceSubStationTable = false;
            this.hideAndShowConsolidatedInvoiceTable = false;
            this.hideAndShowInvoiceNoDetailsTable = false;
            this.hideAndShowInvoiceDetailsDateWiseDetailsTable = false;

            this.invoiceDetailsDataList = [];
            $("#" + this.pageId + "invoiceDetailsTableId").DataTable().destroy();
            this.dtTriggerInvoiceDetails.next();

            this.clearInputField();

        } else if (searchBy === 'destinationWise') {
            this.hideAndShowInvoiceNumberField = false;
            this.hideAndShowFromToDateField = true;
            this.hideAndShowSourceField = false;
            this.hideAndShowDestinationField = true;
            this.hideAndShowMainBkgSourceField = false;
            this.hideAndShowMainAndSubStationField = true;
            this.hideAndShowMainInvoiceOfStationSubStationField = false;
            this.hideAndShowViaSourceField = false;
            this.hideAndShowInvoiceDateField = false;

            this.hideAndShowInvoiceDetailsTable = true;
            this.hideAndShowDestSubStationTable = false;
            this.hideAndShowInvoiceMainStationTable = false;
            this.hideAndShowInvoiceSubStationTable = false;
            this.hideAndShowConsolidatedInvoiceTable = false;
            this.hideAndShowInvoiceNoDetailsTable = false;
            this.hideAndShowInvoiceDetailsDateWiseDetailsTable = false;

            this.invoiceDetailsDataList = [];
            $("#" + this.pageId + "invoiceDetailsTableId").DataTable().destroy();
            this.dtTriggerInvoiceDetails.next();

            this.clearInputField();
            this.setDestinationDropDownValueBasedOnSelectionListner();

        } else if (searchBy === 'dataAndStationWise') {
            this.hideAndShowInvoiceNumberField = false;
            this.hideAndShowFromToDateField = true;
            this.hideAndShowSourceField = true;
            this.hideAndShowDestinationField = true;
            this.hideAndShowMainBkgSourceField = false;
            this.hideAndShowMainAndSubStationField = false;
            this.hideAndShowMainInvoiceOfStationSubStationField = false;
            this.hideAndShowViaSourceField = false;
            this.hideAndShowInvoiceDateField = false;

            this.hideAndShowInvoiceDetailsTable = true;
            this.hideAndShowDestSubStationTable = false;
            this.hideAndShowInvoiceMainStationTable = false;
            this.hideAndShowInvoiceSubStationTable = false;
            this.hideAndShowConsolidatedInvoiceTable = false;
            this.hideAndShowInvoiceNoDetailsTable = false;
            this.hideAndShowInvoiceDetailsDateWiseDetailsTable = false;

            this.invoiceDetailsDataList = [];
            $("#" + this.pageId + "invoiceDetailsTableId").DataTable().destroy();
            this.dtTriggerInvoiceDetails.next();

            this.clearInputField();

            $("#" + this.pageId + "destinationId").val('');
            this.destinationTA = [];
            this.destinationOptions = [];
            this.destinationOptions = this.destinationTempOptions;
            for (let i = 0; i < this.destinationOptions.length; i++) {
                this.destinationTA.push(this.destinationOptions[i]);
            }

        } else if (searchBy === 'mainInvoice') {
            this.hideAndShowInvoiceNumberField = false;
            this.hideAndShowFromToDateField = true;
            this.hideAndShowSourceField = false;
            this.hideAndShowDestinationField = true;
            this.hideAndShowMainBkgSourceField = false;
            this.hideAndShowMainAndSubStationField = false;
            this.hideAndShowMainInvoiceOfStationSubStationField = true;
            this.hideAndShowViaSourceField = false;
            this.hideAndShowInvoiceDateField = false;

            this.hideAndShowInvoiceDetailsTable = false;
            this.hideAndShowDestSubStationTable = false;
            this.hideAndShowInvoiceMainStationTable = true;
            this.hideAndShowInvoiceSubStationTable = false;
            this.hideAndShowConsolidatedInvoiceTable = false;
            this.hideAndShowInvoiceNoDetailsTable = false;
            this.hideAndShowInvoiceDetailsDateWiseDetailsTable = false;

            this.invoiceMainStationDataList = [];
            $("#" + this.pageId + "invoiceDetailsForMainStationTableId").DataTable().destroy();
            this.dtTriggerInvoiceMainStation.next();

            this.clearInputField();
            this.setDestinationDropDownValueBasedOnSelectionListner();

        } else if (searchBy === 'consolidatedInvoice') {
            this.hideAndShowInvoiceNumberField = true;
            this.hideAndShowFromToDateField = false;
            this.hideAndShowSourceField = false;
            this.hideAndShowDestinationField = false;
            this.hideAndShowMainBkgSourceField = false;
            this.hideAndShowMainAndSubStationField = false;
            this.hideAndShowMainInvoiceOfStationSubStationField = false;
            this.hideAndShowViaSourceField = false;
            this.hideAndShowInvoiceDateField = false;

            this.hideAndShowInvoiceDetailsTable = false;
            this.hideAndShowDestSubStationTable = false;
            this.hideAndShowInvoiceMainStationTable = false;
            this.hideAndShowInvoiceSubStationTable = false;
            this.hideAndShowConsolidatedInvoiceTable = true;
            this.hideAndShowInvoiceNoDetailsTable = false;
            this.hideAndShowInvoiceDetailsDateWiseDetailsTable = false;

            this.consolidatedInvoiceDataList = [];
            $("#" + this.pageId + "invoiceDetailsConsolidateTableId").DataTable().destroy();
            this.dtTriggerConsolidatedInvoice.next();

            this.clearInputField();

        } else if (searchBy === 'invNoWithDestination') {
            this.hideAndShowInvoiceNumberField = true;
            this.hideAndShowFromToDateField = false;
            this.hideAndShowSourceField = false;
            this.hideAndShowDestinationField = true;
            this.hideAndShowMainBkgSourceField = false;
            this.hideAndShowMainAndSubStationField = false;
            this.hideAndShowMainInvoiceOfStationSubStationField = false;
            this.hideAndShowViaSourceField = false;
            this.hideAndShowInvoiceDateField = false;

            //differnet table cmg chck once
            //need to work

            this.clearInputField();
            this.setDestinationDropDownValueBasedOnSelectionListner();

        } else if (searchBy === 'mainBkgSrcWise') {
            this.hideAndShowInvoiceNumberField = false;
            this.hideAndShowFromToDateField = true;
            this.hideAndShowSourceField = false;
            this.hideAndShowDestinationField = true;
            this.hideAndShowMainBkgSourceField = true;
            this.hideAndShowMainAndSubStationField = false;
            this.hideAndShowMainInvoiceOfStationSubStationField = false;
            this.hideAndShowViaSourceField = false;
            this.hideAndShowInvoiceDateField = false;

            this.hideAndShowInvoiceDetailsTable = true;
            this.hideAndShowDestSubStationTable = false;
            this.hideAndShowInvoiceMainStationTable = false;
            this.hideAndShowInvoiceSubStationTable = false;
            this.hideAndShowConsolidatedInvoiceTable = false;
            this.hideAndShowInvoiceNoDetailsTable = false;
            this.hideAndShowInvoiceDetailsDateWiseDetailsTable = false;

            this.invoiceDetailsDataList = [];
            $("#" + this.pageId + "invoiceDetailsTableId").DataTable().destroy();
            this.dtTriggerInvoiceDetails.next();

            this.clearInputField();
            this.setDestinationDropDownValueBasedOnSelectionListner();
        } else if (searchBy === 'dateWiseDetails') {
            if (this.userDataDtoReturnSession.role == "Super Administrator") {
                this.hideAndShowInvoiceNumberField = false;
                this.hideAndShowFromToDateField = false;
                this.hideAndShowSourceField = false;
                this.hideAndShowMainBkgSourceField = false;
                this.hideAndShowMainAndSubStationField = false;
                this.hideAndShowMainInvoiceOfStationSubStationField = false;
                this.hideAndShowViaSourceField = false;
                this.hideAndShowInvoiceDateField = true;
                this.hideAndShowDestinationField = true;
            } else {
                this.hideAndShowInvoiceNumberField = false;
                this.hideAndShowFromToDateField = false;
                this.hideAndShowSourceField = false;
                this.hideAndShowDestinationField = false;
                this.hideAndShowMainBkgSourceField = false;
                this.hideAndShowMainAndSubStationField = false;
                this.hideAndShowMainInvoiceOfStationSubStationField = false;
                this.hideAndShowViaSourceField = false;
                this.hideAndShowInvoiceDateField = true;
            }

            this.hideAndShowInvoiceDetailsTable = false;
            this.hideAndShowDestSubStationTable = false;
            this.hideAndShowInvoiceMainStationTable = false;
            this.hideAndShowInvoiceSubStationTable = false;
            this.hideAndShowConsolidatedInvoiceTable = false;
            this.hideAndShowInvoiceNoDetailsTable = false;
            this.hideAndShowInvoiceDetailsDateWiseDetailsTable = true;

            this.invoiceDetailsDateWiseDetailsList = [];
            $("#" + this.pageId + "invoiceDetailsDateWiseDetailsTableId").DataTable().destroy();
            this.dtTriggerInvoiceDetailsDateWiseDetails.next();

            this.clearInputField();
        }
        /* else {
            this.searchByInvoiceNumber = false;
            this.searchByDate = false;
            this.viewSource = false;
            this.viewDestination = false;
            this.viewStationDestination = false;
            this.viewStationInvoice = false;
        }*/
    }


    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker


    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
        if ($("#" + this.pageId + "destinationId").val() != "All" && $("#" + this.pageId + "searchBy").val() == "mainInvoice" &&
            $("#" + this.pageId + "searchMainInvoiceStationWiseBy").val() == "mainStation" ||
            $("#" + this.pageId + "searchMainInvoiceStationWiseBy").val() == "subStation") {
            this.hideAndShowViaSourceField = true;
            $("#" + this.pageId + "viaSourceId").val('All');
        } else {
            this.hideAndShowViaSourceField = false;
            this.modelViaSourceStation = null;
        }
    }
    sourceListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSource = e.item;
        $("#" + this.pageId + "sourceId").val(this.modelSource.subStations);
    }

    clickListnerForMainBookingSource(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelMainBookingSource = e.item;
        $("#" + this.pageId + "mainBookingSourceId").val(this.modelMainBookingSource.mainBookStations);
    }

    viaSourceStationListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelViaSourceStation = e.item;
        $("#" + this.pageId + "viaSourceId").val(this.modelViaSourceStation.destination);
    }

    setDestinationDropDownValueBasedOnSelectionListner() {
        // $("#"+this.pageId+"destinationId").val('');
        this.destinationTA = [];
        this.destinationOptions = [];
        this.destinationOptions = this.destinationTempOptions;
        this.lrDtoDestinationAllOption = new LRDto();
        this.lrDtoDestinationAllOption.destination = "All";
        this.destinationTA.push(this.lrDtoDestinationAllOption);
        for (let i = 0; i < this.destinationOptions.length; i++) {
            this.destinationTA.push(this.destinationOptions[i]);
        }
        $("#" + this.pageId + "destinationId").val('All');
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId
        this.lrDtoDestination.mode = "desttrsp";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.destinationTempOptions = [];
                    this.destinationOptions = response;
                    this.destinationTempOptions = this.destinationOptions;
                    this.lrDtoDestinationAllOption = new LRDto();
                    this.lrDtoDestinationAllOption.destination = "All";
                    this.destinationTA.push(this.lrDtoDestinationAllOption);
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.destinationTempOptions = [];
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };

    getMainBookingSourceDataDetails() {
        this.agentDtoSourceStation = new AgentDetailsDto();
        this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
    }
    getMainBookingSourceDetails() {
        this.getMainBookingSourceDataDetails();
        this.showSpinnerForAction = true;
        this.masterReadService.getMainBookingStationsDetails(this.agentDtoSourceStation).subscribe(
            (response) => {
                //console.log(response);
                if (response.length > 0) {
                    this.mainBookingSourceTA = [];
                    this.agentDtoOptionSourceStation = [];
                    this.agentDtoOptionSourceStation = response;
                    this.agentDtoOptionSourceStationAll.mainBookStations = "All";
                    this.mainBookingSourceTA.push(this.agentDtoOptionSourceStationAll);
                    for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
                        this.mainBookingSourceTA.push(this.agentDtoOptionSourceStation[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Main Biiking Source Details", "info");
            },
            () => console.log('done');

    };


    getFromStationMethod() {
        //if (this.rightsForBookingAdministrator == "Rights For Invoice Details Rpt Booking Administrator") {
        if (this.userDataDtoReturnSession.role != null &&
            this.userDataDtoReturnSession.role == "Booking Administrator" &&
            this.userDataDtoReturnSession.stationList != "") {
            this.getAgentNamesMethod();
        } else {
            this.getSourceStationDetails();
        }
    }

    getAgentNamesMethod() {
        this.lrDtoSourceStation = new LRDto();
        this.lrDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
        //if (this.rightsForBookingAdministrator == "Rights For Invoice Details Rpt Booking Administrator") {
        if (this.userDataDtoReturnSession.role != null &&
            this.userDataDtoReturnSession.role == "Booking Administrator") {
            this.lrDtoSourceStation.city = this.userDataDtoReturnSession.mainStation;
        } else {
            this.lrDtoSourceStation.city = this.userDataDtoReturnSession.mainAdminStation;
        }
        this.showSpinnerForAction = true;
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSourceStation).subscribe(
            (response) => {
                console.log("Agent");
                console.log(response);
                if (response.length > 0) {
                    this.sourceSubStationNameTA = [];
                    this.sourceStationOptions = [];
                    this.sourceStationOptions = response;
                    // this.lrDtoSourceStationAllOption.subStations = "All";
                    //this.sourceSubStationNameTA.push(this.lrDtoSourceStationAllOption);
                    for (let i = 0; i < this.sourceStationOptions.length; i++) {
                        this.sourceSubStationNameTA.push(this.sourceStationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    }

    getSourceStationMethod() {
        this.lrDtoSourceStation = new LRDto();
        this.lrDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSourceStation.mode = "All";
    }
    getSourceStationDetails() {
        this.getSourceStationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSourceStation).subscribe(
            (response) => {
                console.log("Source");
                console.log(response);
                if (response.length > 0) {
                    this.sourceSubStationNameTA = [];
                    this.sourceStationOptions = [];
                    this.sourceStationOptions = response;
                    //this.lrDtoSourceStationAllOption.subStations = "All";
                    //this.sourceSubStationNameTA.push(this.lrDtoSourceStationAllOption);
                    for (let i = 0; i < this.sourceStationOptions.length; i++) {
                        this.sourceSubStationNameTA.push(this.sourceStationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    };

    getViaSourceStationMethod() {
        this.lrDtoViaSourceStation = new LRDto();
        this.lrDtoViaSourceStation.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoViaSourceStation.mode = "logininfo";
    }
    getViaSourceStationDetails() {
        this.getViaSourceStationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoViaSourceStation).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.viaSourceStationNameTA = [];
                    this.viaSourceStationOptions = [];
                    this.viaSourceStationOptions = response;
                    this.lrDtoViaSourceStationAllOption.subStations = "All";
                    this.viaSourceStationNameTA.push(this.lrDtoViaSourceStationAllOption);
                    for (let i = 0; i < this.viaSourceStationOptions.length; i++) {
                        this.viaSourceStationNameTA.push(this.viaSourceStationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');
    };

    validateSearchBtn() {
        this.lrDtoForSearchData = new LRDto();
        this.lrDtoForSearchData.mode = null;
        this.lrDtoForSearchData.companyId = this.userDataDtoReturnSession.companyId;
        if ($("#" + this.pageId + "searchBy").val() == "invoiceNumber") {
            if (($("#" + this.pageId + "invoiceNumberId").val() == null) || ($("#" + this.pageId + "invoiceNumberId").val() == undefined) ||
                ($("#" + this.pageId + "invoiceNumberId").val() == "")) {
                swal(" Mandatory Field", "Invoice Number Is Mandatory Field", "warning");
                return false;
            } else {
                this.validateInvoiceNumberIdValue = $("#" + this.pageId + "invoiceNumberId").val();
                this.lrDtoForSearchData.invoiceNumber = this.validateInvoiceNumberIdValue;
                //Not used this mode in service of invoice as per asrar bhaiya  
                /* if (this.userDataDtoReturnSession.mainStation == "Chennai") {
                     this.lrDtoForSearchData.mode = "Chennai";
                 }*/
                this.setInvoiceNumberDataDetailsForReport(this.lrDtoForSearchData);
            }
        } else if ($("#" + this.pageId + "searchBy").val() == "dateWise") {
            if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDate").val() == null) ||
                ($("#" + this.pageId + "toDate").val() == undefined) || ($("#" + this.pageId + "toDate").val() == "")) {
                swal(" Mandatory Field", "From Date And To Date Are Mandatory Fields", "warning");
                return false;
            } else {
                /*else if (this.rightsForSuperAdministrator == "Rights For Invoice Details Rpt Super Administrator" ||
                this.rightsForTransshipmentManager == "Rights For Invoice Details Rpt Transshipment Manager" ||
                 this.isShowResult == true) { */
                if (this.userDataDtoReturnSession.role == "Super Administrator" ||
                    this.userDataDtoReturnSession.role == "Transshipment Manager" ||
                    this.isShowResult == true) {
                    this.lrDtoForSearchData.mode = "all";
                }
                /* else if ((this.rightsForBookingManager == "Rights For Invoice Details Rpt Booking Manager") ||
                     ((this.rightsForBookingOffice == "Rights For Invoice Details Rpt Booking Office") &&
                         (this.rightsForAdministrator == "Rights For Invoice Details Rpt Administrator"))) {
                 */
                else if ((this.userDataDtoReturnSession.role == "Booking Manager") ||
                    ((this.userDataDtoReturnSession.officeType == "Booking Office") &&
                        (this.userDataDtoReturnSession.role == "Administrator"))) {
                    this.lrDtoForSearchData.mode = "booking";
                    this.lrDtoForSearchData.officeType = this.userDataDtoReturnSession.office;
                    this.lrDtoForSearchData.fromStation = this.userDataDtoReturnSession.mainStation;
                }
                /* 
                else if ((this.rightsForDeliveryManager == "Rights For Invoice Details Rpt Delivery Manager") ||
                    (this.rightsForCollectionManager == "Rights For Invoice Details Rpt Collection Manager") ||
                    ((this.rightsForDeliveryOfficer == "Rights For Invoice Details Rpt Delivery Office") &&
                        ((this.rightsForAdministrator == "Rights For Invoice Details Rpt Administrator") ||
                            (this.rightsForBarcode == "Rights For Invoice Details Rpt Barcode")))) {
               */
                else if ((this.userDataDtoReturnSession.role == "Delivery Manager") ||
                    (this.userDataDtoReturnSession.role == "Collection Manager") ||
                    ((this.userDataDtoReturnSession.officeType == "Delivery Office") &&
                        ((this.userDataDtoReturnSession.role == "Administrator") ||
                            (this.userDataDtoReturnSession.role == "Barcode")))) {
                    this.lrDtoForSearchData.mode = "delivery";
                    this.lrDtoForSearchData.officeType = this.userDataDtoReturnSession.mainStation;
                    this.lrDtoForSearchData.toStation = this.userDataDtoReturnSession.office;
                }
                //else if(this.rightsForBookingAdministrator == "Rights For Invoice Details Rpt Booking Administrator"){
                else if (this.userDataDtoReturnSession.role == "Booking Administrator") {
                    this.lrDtoForSearchData.mode = "adminStation";
                    this.lrDtoForSearchData.list = this.userDataDtoReturnSession.stationList;
                }
                if ($("#" + this.pageId + "fromDates").val() != null && $("#" + this.pageId + "toDate").val() != null) {
                    this.validateFromDateIdValue = $("#" + this.pageId + "fromDates").val();
                    this.validateToDateIdValue = $("#" + this.pageId + "toDate").val();
                    this.lrDtoForSearchData.fromDate = this.validateFromDateIdValue;
                    this.lrDtoForSearchData.toDate = this.validateToDateIdValue;
                }
                //Not used this mode in service of invoice as per asrar bhaiya  
                /* if (this.userDataDtoReturnSession.mainStation == "Chennai") {
                     this.lrDtoForSearchData.status = "Chennai";
                 }*/
                this.setDateWiseDataDetailsForReport(this.lrDtoForSearchData);
            }
        }
        else if ($("#" + this.pageId + "searchBy").val() == "sourceWise") {
            if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDate").val() == null) ||
                ($("#" + this.pageId + "toDate").val() == undefined) || ($("#" + this.pageId + "toDate").val() == "") ||
                ($("#" + this.pageId + "sourceId").val() == null) || ($("#" + this.pageId + "sourceId").val() == undefined) ||
                ($("#" + this.pageId + "sourceId").val() == "")) {
                swal(" Mandatory Field", "From Date,To Date And Source Are Mandatory Fields", "warning");
                return false;
            } else {
                /*if (this.rightsForSuperAdministrator == "Rights For Invoice Details Rpt Super Administrator" ||
                    this.rightsForTransshipmentManager == "Rights For Invoice Details Rpt Transshipment Manager" ||
                    this.isShowResult == true) { 
                */
                if (this.userDataDtoReturnSession.role == "Super Administrator" ||
                    this.userDataDtoReturnSession.role == "Transshipment Manager" ||
                    this.isShowResult == true) {
                    this.lrDtoForSearchData.mode = "all";
                }
                /* else if ((this.rightsForDeliveryManager == "Rights For Invoice Details Rpt Delivery Manager")
                     || (this.rightsForCollectionManager == "Rights For Invoice Details Rpt Collection Manager")
                     || ((this.rightsForDeliveryOfficer == "Rights For Invoice Details Rpt Delivery Office")
                         && ((this.rightsForAdministrator == "Rights For Invoice Details Rpt Administrator")
                             || (this.rightsForBarcode == "Rights For Invoice Details Rpt Barcode")))) {
                */
                else if ((this.userDataDtoReturnSession.role == "Delivery Manager")
                    || (this.userDataDtoReturnSession.role == "Collection Manager")
                    || ((this.userDataDtoReturnSession.officeType == "Delivery Office")
                        && ((this.userDataDtoReturnSession.role == "Administrator")
                            || (this.userDataDtoReturnSession.role == "Barcode")))) {
                    this.lrDtoForSearchData.mode = "delivery";
                    this.lrDtoForSearchData.destination = this.userDataDtoReturnSession.office;
                    this.lrDtoForSearchData.toStation = this.userDataDtoReturnSession.mainStation;
                }
                //else if (this.rightsForBookingAdministrator == "Rights For Invoice Details Rpt Booking Administrator") {
                else if (this.userDataDtoReturnSession.role == "Booking Administrator") {
                    this.lrDtoForSearchData.mode = "all";
                    this.lrDtoForSearchData.list = this.userDataDtoReturnSession.stationList;
                }
                if ($("#" + this.pageId + "fromDates").val() != null && $("#" + this.pageId + "toDate").val() != null) {
                    this.validateFromDateIdValue = $("#" + this.pageId + "fromDates").val();
                    this.validateToDateIdValue = $("#" + this.pageId + "toDate").val();
                    this.lrDtoForSearchData.fromDate = this.validateFromDateIdValue;
                    this.lrDtoForSearchData.toDate = this.validateToDateIdValue;
                }
                this.validateSourceStationIdValue = $("#" + this.pageId + "sourceId").val();
                this.lrDtoForSearchData.source = this.validateSourceStationIdValue;
                //Not used this mode in service of invoice as per asrar bhaiya  
                /* if (this.userDataDtoReturnSession.mainStation == "Chennai") {
                     this.lrDtoForSearchData.status = "Chennai";
                 }*/
                this.setSourceStationWiseDataDetailsForReport(this.lrDtoForSearchData);
            }
        } else if ($("#" + this.pageId + "searchBy").val() == "destinationWise") {
            /*if (this.rightsForSuperAdministrator == "Rights For Invoice Details Rpt Super Administrator" ||
                    this.rightsForTransshipmentManager == "Rights For Invoice Details Rpt Transshipment Manager") { 
            */
            if (this.userDataDtoReturnSession.role == "Super Administrator" ||
                this.userDataDtoReturnSession.role == "Transshipment Manager") {
                this.lrDtoForSearchData.mode = "all";
            }
            /* else if ((this.rightsForBookingManager == "Rights For Invoice Details Rpt Booking Manager")
               || ((this.rightsForBookingOffice == "Rights For Invoice Details Rpt Booking Office")
                   && (this.rightsForAdministrator == "Rights For Invoice Details Rpt Administrator"))) {
               this.lrDtoForSearchData.mode = "booking";
            */
            else if ((this.userDataDtoReturnSession.role == "Booking Manager")
                || ((this.userDataDtoReturnSession.officeType == "Booking Office")
                    && (this.userDataDtoReturnSession.role == "Administrator"))) {
                this.lrDtoForSearchData.mode = "booking";
            }
            //else if (this.rightsForBookingAdministrator == "Rights For Invoice Details Rpt Booking Administrator") {
            else if (this.userDataDtoReturnSession.role == "Booking Administrator") {
                this.lrDtoForSearchData.mode = "adminStation";
                this.lrDtoForSearchData.list = this.userDataDtoReturnSession.stationList;
            }
            // validation
            if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDate").val() == null) ||
                ($("#" + this.pageId + "toDate").val() == undefined) || ($("#" + this.pageId + "toDate").val() == "") ||
                ($("#" + this.pageId + "destinationId").val() == null) || ($("#" + this.pageId + "destinationId").val() == undefined) ||
                ($("#" + this.pageId + "destinationId").val() == "")) {
                swal(" Mandatory Field", "From Date,To Date And Destination Are Mandatory Fields", "warning");
                return false;
            } else {

                this.validateFromDateIdValue = $("#" + this.pageId + "fromDates").val();
                this.validateToDateIdValue = $("#" + this.pageId + "toDate").val();
                this.lrDtoForSearchData.fromDate = this.validateFromDateIdValue;
                this.lrDtoForSearchData.toDate = this.validateToDateIdValue;
                //
                if ($("#" + this.pageId + "searchStationWiseBy").val() == "mainStation") {
                    if ($("#" + this.pageId + "destinationId").val() == "All") {
                        this.lrDtoForSearchData.destinationType = "allDest";
                    } else if ($("#" + this.pageId + "destinationId").val() != "All") {
                        this.validateDestinationStationIdValue = $("#" + this.pageId + "destinationId").val();
                        this.lrDtoForSearchData.destinationType = "notAllDest";
                        this.lrDtoForSearchData.destination = this.validateDestinationStationIdValue;
                    }
                    this.lrDtoForSearchData.source = this.userDataDtoReturnSession.office;
                    this.lrDtoForSearchData.stationType = "mainStation";
                    this.setDestinationOfMainStationDataDetailsForReport(this.lrDtoForSearchData);
                } else if ($("#" + this.pageId + "searchStationWiseBy").val() == "subStation") {
                    if ($("#" + this.pageId + "destinationId").val() == "All") {
                        this.lrDtoForSearchData.destinationType = "allDest";
                    } else if ($("#" + this.pageId + "destinationId").val() != "All") {
                        this.validateDestinationStationIdValue = $("#" + this.pageId + "destinationId").val();
                        this.lrDtoForSearchData.destinationType = "notAllDest";
                        this.lrDtoForSearchData.destination = this.validateDestinationStationIdValue;
                    }
                    this.lrDtoForSearchData.source = this.userDataDtoReturnSession.office;
                    this.lrDtoForSearchData.stationType = "notMainStation";
                    this.setDestinationOfSubStationDataDetailsForReport(this.lrDtoForSearchData);
                }
            }
        } else if ($("#" + this.pageId + "searchBy").val() == "dataAndStationWise") {
            // validation
            if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDate").val() == null) ||
                ($("#" + this.pageId + "toDate").val() == undefined) || ($("#" + this.pageId + "toDate").val() == "") ||
                ($("#" + this.pageId + "sourceId").val() == null) || ($("#" + this.pageId + "sourceId").val() == undefined) ||
                ($("#" + this.pageId + "sourceId").val() == "") || ($("#" + this.pageId + "destinationId").val() == null) ||
                ($("#" + this.pageId + "destinationId").val() == undefined) || ($("#" + this.pageId + "destinationId").val() == "")) {
                swal(" Mandatory Field", "From Date,To date,Source And Destination Are Mandatory Fields", "warning");
                return false;
            } else {
                this.validateFromDateIdValue = $("#" + this.pageId + "fromDates").val();
                this.validateToDateIdValue = $("#" + this.pageId + "toDate").val();
                this.validateSourceStationIdValue = $("#" + this.pageId + "sourceId").val();
                this.validateDestinationStationIdValue = $("#" + this.pageId + "destinationId").val();
                this.lrDtoForSearchData.fromDate = this.validateFromDateIdValue;
                this.lrDtoForSearchData.toDate = this.validateToDateIdValue;
                this.lrDtoForSearchData.source = this.validateSourceStationIdValue;
                this.lrDtoForSearchData.destination = this.validateDestinationStationIdValue;
                this.lrDtoForSearchData.mode = null;
                this.setDateWiseAndStationWiseDataDetailsForReport(this.lrDtoForSearchData);
            }
        }
        //Main Bkg SrcWise validation
        else if ($("#" + this.pageId + "searchBy").val() == "mainBkgSrcWise") {
            if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDate").val() == null) ||
                ($("#" + this.pageId + "toDate").val() == undefined) || ($("#" + this.pageId + "toDate").val() == "") ||
                ($("#" + this.pageId + "mainBookingSourceId").val() == null) || ($("#" + this.pageId + "mainBookingSourceId").val() == undefined) ||
                ($("#" + this.pageId + "mainBookingSourceId").val() == "") || ($("#" + this.pageId + "destinationId").val() == null) ||
                ($("#" + this.pageId + "destinationId").val() == undefined) || ($("#" + this.pageId + "destinationId").val() == "")) {
                swal(" Mandatory Field", "From Date,To date,Source And Destination Are Mandatory Fields", "warning");
                return false;
            } else {
                this.validateFromDateIdValue = $("#" + this.pageId + "fromDates").val();
                this.validateToDateIdValue = $("#" + this.pageId + "toDate").val();
                this.validateMainSourceBkgStationIdValue = $("#" + this.pageId + "mainBookingSourceId").val();
                this.validateDestinationStationIdValue = $("#" + this.pageId + "destinationId").val();
                this.lrDtoForSearchData.fromDate = this.validateFromDateIdValue;
                this.lrDtoForSearchData.toDate = this.validateToDateIdValue;
                this.lrDtoForSearchData.source = this.validateMainSourceBkgStationIdValue;
                this.lrDtoForSearchData.destination = this.validateDestinationStationIdValue;
                this.lrDtoForSearchData.mode = "mainBkgSrc";
                this.setDateWiseAndStationWiseDataDetailsForReport(this.lrDtoForSearchData);
                // console.log(this.lrDtoForSearchData);
            }
        }
        //Consolidate validation
        else if ($("#" + this.pageId + "searchBy").val() == "consolidatedInvoice") {
            if (($("#" + this.pageId + "invoiceNumberId").val() == null) || ($("#" + this.pageId + "invoiceNumberId").val() == undefined) ||
                ($("#" + this.pageId + "invoiceNumberId").val() == "")) {
                swal(" Mandatory Field", "Invoice Number Is Mandatory Field", "warning");
                return false;
            } else {
                this.validateInvoiceNumberIdValue = $("#" + this.pageId + "invoiceNumberId").val();
                this.lrDtoForSearchData.invoiceNumber = this.validateInvoiceNumberIdValue;
                this.lrDtoForSearchData.fromStation = this.userDataDtoReturnSession.mainStation;
                this.lrDtoForSearchData.toStation = this.userDataDtoReturnSession.mainStation;
                //if(this.rightsForSuperAdministrator == "Super Administrator"){
                if (this.userDataDtoReturnSession.role == "Rights For Invoice Details Rpt Super Administrator") {
                    this.lrDtoForSearchData.reportMode = "SuperAdmin";
                } else {
                    this.lrDtoForSearchData.reportMode = "";
                }
                //Method Call HERE
            }
        }
        //Main Invoice validation
        else if ($("#" + this.pageId + "searchBy").val() == "mainInvoice") {
            if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
                ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDate").val() == null) ||
                ($("#" + this.pageId + "toDate").val() == undefined) || ($("#" + this.pageId + "toDate").val() == "") ||
                ($("#" + this.pageId + "destinationId").val() == null) || ($("#" + this.pageId + "destinationId").val() == undefined) ||
                ($("#" + this.pageId + "destinationId").val() == "")) {
                swal(" Mandatory Field", "From Date,To Date And Destination Are Mandatory Fields", "warning");
                return false;
            } else {
                this.lrDtoForSearchData.adminReport = "invoice";

                this.validateFromDateIdValue = $("#" + this.pageId + "fromDates").val();
                this.validateToDateIdValue = $("#" + this.pageId + "toDate").val();
                this.validateDestinationStationIdValue = $("#" + this.pageId + "destinationId").val();
                this.validateViaSourceStationIdValue = $("#" + this.pageId + "viaSourceId").val();
                this.lrDtoForSearchData.fromDate = this.validateFromDateIdValue;
                this.lrDtoForSearchData.toDate = this.validateToDateIdValue;
                this.lrDtoForSearchData.destination = this.validateDestinationStationIdValue;
                if (this.validateDestinationStationIdValue != null &&
                    this.validateDestinationStationIdValue == "All") {
                    this.lrDtoForSearchData.mode = "mode1";
                } else {
                    this.lrDtoForSearchData.mode = "mode2";
                }
                this.lrDtoForSearchData.via = this.validateViaSourceStationIdValue;

                // if (this.rightsForBookingAdministrator == "Rights For Invoice Details Rpt Booking Administrator") {
                if (this.userDataDtoReturnSession.role == "Booking Administrator") {
                    this.lrDtoForSearchData.source = "bkgAdmin";
                    this.lrDtoForSearchData.list = this.userDataDtoReturnSession.stationList;
                } else {
                    this.lrDtoForSearchData.source = "supAdmin";
                }

                if ($("#" + this.pageId + "searchMainInvoiceStationWiseBy").val() == "mainStation") {
                    this.lrDtoForSearchData.reportMode = "mainStation";
                    if (this.hideAndShowViaSourceField == true) {
                        //Method Call HERE
                        this.setDateWiseAndMainInvoiceDetailsForReport(this.lrDtoForSearchData);
                    } else {
                        //Method Call HERE
                        this.setDateWiseAndMainInvoiceDetailsForReport(this.lrDtoForSearchData);
                    }
                } else if ($("#" + this.pageId + "searchMainInvoiceStationWiseBy").val() == "subStation") {
                    this.lrDtoForSearchData.reportMode = "subStation";
                    if (this.hideAndShowViaSourceField == true) {
                        //Method Call HERE
                        this.setDateWiseAndMainInvoiceSubstationDetailsForReport(this.lrDtoForSearchData);
                    } else {
                        //Method Call HERE
                        this.setDateWiseAndMainInvoiceSubstationDetailsForReport(this.lrDtoForSearchData);
                    }
                }
            }
        }
        //Invoice with Destination validation
        else if ($("#" + this.pageId + "searchBy").val() == "invNoWithDestination") {
            if (($("#" + this.pageId + "invoiceNumberId").val() == null) || ($("#" + this.pageId + "invoiceNumberId").val() == undefined) ||
                ($("#" + this.pageId + "invoiceNumberId").val() == "") || ($("#" + this.pageId + "destinationId").val() == null) ||
                ($("#" + this.pageId + "destinationId").val() == undefined) || ($("#" + this.pageId + "destinationId").val() == "")) {
                swal(" Mandatory Field", "Invoice Number and destination are mandatory fieldss", "warning");
                return false;
            } else {
                this.validateInvoiceNumberIdValue = $("#" + this.pageId + "invoiceNumberId").val();
                this.validateDestinationStationIdValue = $("#" + this.pageId + "destinationId").val();
                this.lrDtoForSearchData.invoiceNumber = this.validateInvoiceNumberIdValue;
                this.lrDtoForSearchData.destination = this.validateDestinationStationIdValue;
                //Method Call HERE
            }
            // Invoice Details of DateWise Details
        } else if ($("#" + this.pageId + "searchBy").val() == "dateWiseDetails") {
            if (this.userDataDtoReturnSession.role == "Super Administrator") {
                if ($("#" + this.pageId + "invoiceDate").val() == null || $("#" + this.pageId + "invoiceDate").val() == undefined ||
                    $("#" + this.pageId + "invoiceDate").val() == "" || $("#" + this.pageId + "destinationId").val() == null ||
                    $("#" + this.pageId + "destinationId").val() == undefined || $("#" + this.pageId + "destinationId").val() == "") {
                    swal(" Mandatory Field", "Destination and Invoice Date Is Mandatory Field", "warning");
                    return false;
                }
            } else {
                if ($("#" + this.pageId + "invoiceDate").val() == null || $("#" + this.pageId + "invoiceDate").val() == undefined ||
                    $("#" + this.pageId + "invoiceDate").val() == "") {
                    swal(" Mandatory Field", "Invoice Date Is Mandatory Field", "warning");
                    return false;
                }
            }

            if (this.userDataDtoReturnSession.role == "Super Administrator") {
                this.validateInvoiceDateValue = $("#" + this.pageId + "invoiceDate").val();
                this.lrDtoForSearchData.invoiceDate = this.validateInvoiceDateValue;
                this.validateDestinationStationIdValue = $("#" + this.pageId + "destinationId").val();
                this.lrDtoForSearchData.toStation = this.validateDestinationStationIdValue;
                this.setInvoiceDetailsOfDateWiseDetails(this.lrDtoForSearchData);
            } else {
                this.validateInvoiceDateValue = $("#" + this.pageId + "invoiceDate").val();
                this.lrDtoForSearchData.invoiceDate = this.validateInvoiceDateValue;
                this.lrDtoForSearchData.toStation = this.userDataDtoReturnSession.mainStation;
                this.setInvoiceDetailsOfDateWiseDetails(this.lrDtoForSearchData);
            }
        }
    }

    /*
    rightsForSuperAdministrator = "Rights For Invoice Details Rpt Super Administrator";
    rightsForTransshipmentManager = "Rights For Invoice Details Rpt Transshipment Manager";
    rightsForBookingAdministrator = "Rights For Invoice Details Rpt Booking Administrator";
    rightsForBookingManager = "Rights For Invoice Details Rpt Booking Manager";
    rightsForBookingOffice = "Rights For Invoice Details Rpt Booking Office";
    rightsForAdministrator = "Rights For Invoice Details Rpt Administrator";
    rightsForDeliveryManager = "Rights For Invoice Details Rpt Delivery Manager";
    rightsForCollectionManager = "Rights For Invoice Details Rpt Collection Manager";
    rightsForDeliveryOfficer = "Rights For Invoice Details Rpt Delivery Office";
    rightsForBarcode = "Rights For Invoice Details Rpt Barcode";
    */


    //Invoice Number Search Flow 
    setInvoiceNumberDataDetailsForReport(lrDtoForSearchData) {
        this.showSpinnerForAction = true;
        this.invoiceDetailsRpt.getInvoiceDetailsInvoiceNo(lrDtoForSearchData).subscribe(
            (response) => {
                console.log(response);
                this.invoiceDetailsDataList = [];
                this.invoiceDetailsDataListTemp = [];
                this.invoiceDetailsDataListTemp = response;
                $("#" + this.pageId + "invoiceNoDetailsTableId").DataTable().destroy();
                if (response.length == 0) {
                    swal("Alert", "This Invoice number Does Not Exist", "warning");
                } else if (response[0].source != null &&
                    response[0].source == this.userDataDtoReturnSession.mainStation) {
                    this.invoiceDetailsDataList = response;
                } else if (response[0].destination != null &&
                    (response[0].destination == this.userDataDtoReturnSession.mainStation ||
                        response[0].destination == this.userDataDtoReturnSession.office)) {
                    this.invoiceDetailsDataList = response;
                } else if ((response[0].source != null && response[0].destination != null) &&
                    (response[0].source == this.userDataDtoReturnSession.office ||
                        response[0].destination == this.userDataDtoReturnSession.office)) {
                    this.invoiceDetailsDataList = response;
                }
                //else if (this.rightsForSuperAdministrator == "Rights For Invoice Details Rpt Super Administrator") {
                else if (this.userDataDtoReturnSession.role == "Super Administrator") {
                    this.invoiceDetailsDataList = response;
                }
                // else if (this.rightsForBookingAdministrator == "Rights For Invoice Details Rpt Booking Administrator") {
                else if (this.userDataDtoReturnSession.role == "Booking Administrator") {
                    var isMatched = false;
                    for (let i = 0; i < this.userDataDtoReturnSession.stationList.length; i++) {
                        if (response[0].source == this.userDataDtoReturnSession.stationList[i]) {
                            isMatched = true;
                        }
                    }
                    if (isMatched == true) {
                        this.invoiceDetailsDataList = response;
                    } else {
                        swal("Alert", "You Are Not Allowed To See This Invoice Number Details", "warning");
                        $("#" + this.pageId + "invoiceNumberId").val('');
                    }
                } else if (this.isShowResult == true) {
                    this.invoiceDetailsDataList = response;
                } else {
                    var isMatched = false;
                    for (let i = 0; i < this.invoiceDetailsDataListTemp.length; i++) {
                        if (response[i].destination != null &&
                            (response[i].destination.toLowerCase() == this.userDataDtoReturnSession.mainStation.toLowerCase() ||
                                response[i].destination.toLowerCase() == this.userDataDtoReturnSession.office.toLowerCase())) {
                            isMatched = true;
                        }
                    }
                    if (isMatched == true) {
                        this.invoiceDetailsDataList = response;
                    } else {
                        swal("Alert", "You Are Not Allowed To See This Invoice Number Details", "warning");
                        $("#" + this.pageId + "invoiceNumberId").val('');
                    }
                }
                this.dtTriggerInvoiceDetails.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Ooops...Error In Getting Information For This Invoice Number", "info");
            },
            () => console.log('done');
    }


    setDateWiseDataDetailsForReport(lrDtoForSearchData) {
        this.showSpinnerForAction = true;
        this.invoiceDetailsRpt.getInvoiceDetailsDateWise(lrDtoForSearchData).subscribe(
            (response) => {
                console.log(response);
                this.invoiceDetailsDataList = [];
                $("#" + this.pageId + "invoiceDetailsTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.invoiceDetailsDataList = response;
                } else {
                    swal("Alert", "No Datas Found For This Information", "warning");
                }
                this.dtTriggerInvoiceDetails.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Ooops...Error In Getting Information For This from date & to date report details", "info");
            },
            () => console.log('done');
    }

    setSourceStationWiseDataDetailsForReport(lrDtoForSearchData) {
        this.showSpinnerForAction = true;
        this.invoiceDetailsRpt.getInvoiceDetailsSourceWise(lrDtoForSearchData).subscribe(
            (response) => {
                console.log(response);
                this.invoiceDetailsDataList = [];
                $("#" + this.pageId + "invoiceDetailsTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.invoiceDetailsDataList = response;
                } else {
                    swal("Alert", "No Datas Found For This Information", "warning");
                }
                this.dtTriggerInvoiceDetails.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Ooops...Error In Getting Information For This from date,to date & source report details", "info");
            },
            () => console.log('done');
    }

    setDestinationOfMainStationDataDetailsForReport(lrDtoForSearchData) {
        this.showSpinnerForAction = true;
        this.invoiceDetailsRpt.getInvoiceDetailsDestinationWise(lrDtoForSearchData).subscribe(
            (response) => {
                console.log(response);
                this.invoiceDetailsDataList = [];
                $("#" + this.pageId + "invoiceDetailsTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.invoiceDetailsDataList = response;
                } else {
                    swal("Alert", "No Datas Found For This Information", "warning");
                }
                this.dtTriggerInvoiceDetails.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Ooops...Error In Getting Information For This from date,to date & destination report details", "info");
            },
            () => console.log('done');
    }

    setDestinationOfSubStationDataDetailsForReport(lrDtoForSearchData) {
        this.showSpinnerForAction = true;
        this.invoiceDetailsRpt.getInvoiceDetailsDestinationWiseSubStation(lrDtoForSearchData).subscribe(
            (response) => {
                console.log(response);
                this.destSubStationDataList = [];
                $("#" + this.pageId + "invoiceDetailsDestSubStationTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.destSubStationDataList = response;
                } else {
                    swal("Alert", "No Datas Found For This Information", "warning");
                }
                this.dtTriggerDestSubStation.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Ooops...Error In Getting Information For This from date,to date & destination report details", "info");
            },
            () => console.log('done');
    }

    setDateWiseAndStationWiseDataDetailsForReport(lrDtoForSearchData) {
        this.showSpinnerForAction = true;
        this.invoiceDetailsRpt.getInvoiceDetailsDateStation(lrDtoForSearchData).subscribe(
            (response) => {
                console.log(response);
                this.invoiceDetailsDataList = [];
                $("#" + this.pageId + "invoiceDetailsTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.invoiceDetailsDataList = response;
                } else {
                    swal("Alert", "No Datas Found For This Information", "warning");
                }
                this.dtTriggerInvoiceDetails.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Ooops...Error In Getting Information For This from date & to date report details", "info");
            },
            () => console.log('done');
    }

    setDateWiseAndMainInvoiceDetailsForReport(lrDtoForSearchData) {
        this.showSpinnerForAction = true;
        this.invoiceDetailsRpt.getInvoiceDetailsMainInvoiceService(lrDtoForSearchData).subscribe(
            (response) => {
                console.log(response);
                this.invoiceMainStationDataList = [];
                $("#" + this.pageId + "invoiceDetailsForMainStationTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.invoiceMainStationDataList = response;
                } else {
                    swal("Alert", "No Datas Found For This Information", "warning");
                }
                this.dtTriggerInvoiceMainStation.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Ooops...Error while getting report details", "info");
            },
            () => console.log('done');
    }

    setDateWiseAndMainInvoiceSubstationDetailsForReport(lrDtoForSearchData) {
        this.showSpinnerForAction = true;
        this.invoiceDetailsRpt.getInvoiceDetailsMainInvoiceService(lrDtoForSearchData).subscribe(
            (response) => {
                console.log(response);
                this.invoiceSubStationDataList = [];
                $("#" + this.pageId + "invoiceDetailsForMainInvoiceSubStationTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.invoiceSubStationDataList = response;
                } else {
                    swal("Alert", "No Datas Found For This Information", "warning");
                }
                this.dtTriggerInvoiceSubStation.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Ooops...Error while getting report details", "info");
            },
            () => console.log('done');
    }





    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    invoiceDetailsRptPopUpBtnLink(invoiceDetailsLrsSummaryPopUpTemplate, invoiceDetailsData) {
        this.modalRefferenceInvoiceDetailsPopUp = this.modalService.open(invoiceDetailsLrsSummaryPopUpTemplate,
            {
                centered: true,
                // size: "lg" });
                windowClass: "myCustomModalClass"
            });
        this.modalRefferenceInvoiceDetailsPopUp.result.then((result) => {
            this.modalRefferenceInvoiceDetailsClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceInvoiceDetailsClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
        this.lrDtoForLocalStorage = new LRDto();
        this.fromDateForLocalStorage = null;
        this.toDateForLocalStorage = null;
        this.searchByModeForLocalStorage = null;

        this.fromDateForLocalStorage = $("#" + this.pageId + "fromDates").val();
        this.toDateForLocalStorage = $("#" + this.pageId + "toDate").val();
        this.searchByModeForLocalStorage = $("#" + this.pageId + "searchBy").val();

        this.lrDtoForLocalStorage.invoiceNumber = invoiceDetailsData.invoiceNumber;
        this.lrDtoForLocalStorage.fromDate = this.fromDateForLocalStorage;
        this.lrDtoForLocalStorage.toDate = this.toDateForLocalStorage;
        this.lrDtoForLocalStorage.mode = this.searchByModeForLocalStorage;
        this.lrDtoForLocalStorage.vehicleNumber = invoiceDetailsData.vehicleNumber;
        this.lrDtoForLocalStorage.source = invoiceDetailsData.source;
        this.lrDtoForLocalStorage.destination = invoiceDetailsData.destination;
        this.lrDtoForLocalStorage.loaderName = invoiceDetailsData.loaderName;
        this.lrDtoForLocalStorage.invoiceDateStr = invoiceDetailsData.invoiceDateStr;
        this.lrDtoForLocalStorage.guranteeWt = invoiceDetailsData.guranteeWt;
        this.lrDtoForLocalStorage.supplierName = invoiceDetailsData.supplierName;
        this.lrDtoForLocalStorage.truckType = invoiceDetailsData.truckType;
        localStorage.clear();
        localStorage.setItem('invoiceDetailsRptLrsDetailsPopUpTemplate',
            JSON.stringify(this.lrDtoForLocalStorage));
        this.viewInvoiceDetailsLrsSummaryRptPopUp = true;
        window.addEventListener('afterPopUp', (event) => {
            this.viewInvoiceDetailsLrsSummaryRptPopUp = false;
        });
    }
    closeInvoiceDetailsRptPopUp() {
        this.modalRefferenceInvoiceDetailsPopUp.close();
    }

    printInvUnldSheet() {
        this.getUnloadingPrintLretailsService();
    }

    getUnloadingPrintLretailsService() {
        this.showSpinnerForAction = true;
        this.lrDtoLoadingSheet = new LRDto();
        this.lrDtoLoadingSheet.invoiceNumber = this.lrDtoForLocalStorage.invoiceNumber;
        this.lrService.getInvoiceLoadingSheetPrint(this.lrDtoLoadingSheet).subscribe(
            (response) => {
                this.invoiceDetailsLoadingSheetDataList = [];
                this.invoiceDetailsLoadingSheetDataList = response;
                this.customPrintUnloadingSheet();
                this.showSpinnerForAction = false;

                this.modalRefferenceInvoiceDetailsPopUp.close();
                this.viewCustomPrintV1 = true;

                window.addEventListener('afterprint', (onclick) => {
                    if (this.viewCustomPrintV1) {
                        this.viewCustomPrintV1 = false;
                        localStorage.clear();
                    }
                });
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Unloading LR details", "info");
            }, () => console.log('done');
    }

    mainSrcMainLrDetailsPopUpBtnLink(mainSrcLrDetailsPopUpTemplate, invoiceMainStationData) {
        this.modalRefferenceMainSrcLrDetailsPopUp = this.modalService.open(mainSrcLrDetailsPopUpTemplate,
            { centered: true, windowClass: 'my-class' });
        this.modalRefferenceMainSrcLrDetailsPopUp.result.then((result) => {
            this.modalRefferenceMainSrcLrDetailsClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceMainSrcLrDetailsClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
        localStorage.clear();
        localStorage.setItem('popupMainSrcLrDetailsPopup', JSON.stringify(invoiceMainStationData));
        this.viewMainSrcLrDetailsPopUp = true;
        window.addEventListener('afterPopUp', (event) => {
            this.viewMainSrcLrDetailsPopUp = false;
        });
    }
    mainSrcLrDetailsPopUpClose() {
        this.modalRefferenceMainSrcLrDetailsPopUp.close();
    }

    printInvoiceCustom() {
        this.modalRefferenceInvoiceDetailsPopUp.close();
        this.viewInvoiceDetailsPrint_Hireslip = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewInvoiceDetailsPrint_Hireslip) {
                this.viewInvoiceDetailsPrint_Hireslip = false;
                localStorage.clear();
            }
        });
    }

    customPrintInvoiceDetailsRpt() {
        if (this.invoiceDetailsDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Invoice No", "Destination", "Lrs", "Invoice Dt", "Art", "Act Wgt", "Chg Wgt", "To Pay", "Paid", "F O V", "Discount"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [16, 13, 4, 11, 6, 8, 8, 8, 7, 7, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            //heading logics For Date

            this.validateFromDateIdValue = $("#" + this.pageId + "fromDates").val();
            this.validateToDateIdValue = $("#" + this.pageId + "toDate").val();
            this.validateInvoiceNumberIdValue = $("#" + this.pageId + "invoiceNumberId").val();

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];

            if (this.validateInvoiceNumberIdValue == null || this.validateInvoiceNumberIdValue == '' || this.validateInvoiceNumberIdValue == undefined) {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Dt", "To Dt"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateFromDateIdValue, this.validateToDateIdValue];
            }
            else {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Invoice Number"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateInvoiceNumberIdValue];
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.lrsSummary = this.articleSummary = this.acWtSummary = this.chgWtSummary = this.toPaySummary = this.paidSummary = this.fovSummary = this.discountSummary = 0;

            for (let i = 0; i < this.invoiceDetailsDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.invoiceDetailsDataList[i].invoiceNumber, this.invoiceDetailsDataList[i].toStation,
                this.invoiceDetailsDataList[i].totalLrs, this.invoiceDetailsDataList[i].invoiceDateStr, this.invoiceDetailsDataList[i].totalArticles,
                this.invoiceDetailsDataList[i].actualWeight, this.invoiceDetailsDataList[i].chargedWeight, this.invoiceDetailsDataList[i].toPay,
                this.invoiceDetailsDataList[i].paid, this.invoiceDetailsDataList[i].riskCharge, this.invoiceDetailsDataList[i].discountAmount];
                this.lrsSummary = this.lrsSummary + this.invoiceDetailsDataList[i].totalLrs;
                this.articleSummary = this.articleSummary + this.invoiceDetailsDataList[i].totalArticles;
                this.acWtSummary = this.acWtSummary + this.invoiceDetailsDataList[i].actualWeight;
                this.chgWtSummary = this.chgWtSummary + this.invoiceDetailsDataList[i].chargedWeight;
                this.toPaySummary = this.toPaySummary + this.invoiceDetailsDataList[i].toPay;
                this.paidSummary = this.paidSummary + this.invoiceDetailsDataList[i].paid;
                this.fovSummary = this.fovSummary + this.invoiceDetailsDataList[i].riskCharge;
                this.discountSummary = this.discountSummary + this.invoiceDetailsDataList[i].discountAmount;
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.invoiceDetailsDataList.length, "", this.lrsSummary, "", this.articleSummary, this.acWtSummary, this.chgWtSummary, this.toPaySummary, this.paidSummary, this.fovSummary, this.discountSummary];

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printTitle', "Destination Invoice Summary");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }

    customPrintDesSubStationRpt() {
        if (this.destSubStationDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Stations", "LRs", "Article", "Actual Wt", "Charged Wt", "To Pay", "Paid", "Service Tax", "Loaded By"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [14, 10, 6, 8, 8, 8, 8, 8, 8, 8, 12];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            //heading logics For Date

            this.validateFromDateIdValue = $("#" + this.pageId + "fromDates").val();
            this.validateToDateIdValue = $("#" + this.pageId + "toDate").val();
            this.validateInvoiceNumberIdValue = $("#" + this.pageId + "invoiceNumberId").val();

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];

            if (this.validateInvoiceNumberIdValue == null || this.validateInvoiceNumberIdValue == '' || this.validateInvoiceNumberIdValue == undefined) {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateFromDateIdValue, this.validateToDateIdValue];
            }
            else {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Invoice Number"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateInvoiceNumberIdValue];
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.lrsSummary = this.articleSummary = this.acWtSummary = this.chgWtSummary = this.toPaySummary = this.paidSummary = this.serTaxSummary = 0;

            for (let i = 0; i < this.destSubStationDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.destSubStationDataList[i].toStation, this.destSubStationDataList[i].totalLrs,
                this.destSubStationDataList[i].totalArticles, this.destSubStationDataList[i].actualWeight, this.destSubStationDataList[i].chargedWeight,
                this.destSubStationDataList[i].toPay, this.destSubStationDataList[i].paid, this.destSubStationDataList[i].serviceTax,
                this.destSubStationDataList[i].loaderName];
                this.lrsSummary = this.lrsSummary + this.destSubStationDataList[i].totalLrs;
                this.articleSummary = this.articleSummary + this.destSubStationDataList[i].totalArticles;
                this.acWtSummary = this.acWtSummary + this.destSubStationDataList[i].actualWeight;
                this.chgWtSummary = this.chgWtSummary + this.destSubStationDataList[i].chargedWeight;
                this.toPaySummary = this.toPaySummary + this.destSubStationDataList[i].toPay;
                this.paidSummary = this.paidSummary + this.destSubStationDataList[i].paid;
                this.serTaxSummary = this.serTaxSummary + this.destSubStationDataList[i].riskCharge;
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.destSubStationDataList.length, "", this.lrsSummary, "", this.articleSummary, this.acWtSummary, this.chgWtSummary, this.toPaySummary, this.paidSummary, this.fovSummary, this.discountSummary];

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printTitle', "Destination Invoice Report Summary");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }
    customPrintInvoiceMainStationRpt() {
        if (this.invoiceMainStationDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Invoice Number", "Total Lrs", "Invoice Date", "Articles", "Actual Weight", "Charged Weight", "To Pay", "Paid", "F O V", "Less Amount"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];


            //heading logics For Date

            this.validateFromDateIdValue = $("#" + this.pageId + "fromDates").val();
            this.validateToDateIdValue = $("#" + this.pageId + "toDate").val();
            this.validateInvoiceNumberIdValue = $("#" + this.pageId + "invoiceNumberId").val();

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];

            if (this.validateInvoiceNumberIdValue == null || this.validateInvoiceNumberIdValue == '' || this.validateInvoiceNumberIdValue == undefined) {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateFromDateIdValue, this.validateToDateIdValue];
            }
            else {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Invoice Number"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateInvoiceNumberIdValue];
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.lrsSummary = this.articleSummary = this.acWtSummary = this.chgWtSummary = this.toPaySummary = this.paidSummary = this.serTaxSummary = this.fovSummary = this.lessAmountSummary = 0;

            for (let i = 0; i < this.invoiceMainStationDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.invoiceMainStationDataList[i].invoiceNumber, this.invoiceMainStationDataList[i].totalLrs,
                this.invoiceMainStationDataList[i].invoiceDate, this.invoiceMainStationDataList[i].articles, this.invoiceMainStationDataList[i].actualWeight,
                this.invoiceMainStationDataList[i].chargedWeight, this.invoiceMainStationDataList[i].toPay, this.invoiceMainStationDataList[i].paid,
                this.invoiceMainStationDataList[i].fov, this.invoiceMainStationDataList[i].lessAmount];
                this.lrsSummary = this.lrsSummary + this.invoiceMainStationDataList[i].totalLrs;
                this.articleSummary = this.articleSummary + this.invoiceMainStationDataList[i].articles;
                this.acWtSummary = this.acWtSummary + this.invoiceMainStationDataList[i].actualWeight;
                this.chgWtSummary = this.chgWtSummary + this.invoiceMainStationDataList[i].chargedWeight;
                this.toPaySummary = this.toPaySummary + this.invoiceMainStationDataList[i].toPay;
                this.paidSummary = this.paidSummary + this.invoiceMainStationDataList[i].paid;
                this.fovSummary = this.fovSummary + this.invoiceMainStationDataList[i].fov;
                this.lessAmountSummary = this.lessAmountSummary + this.invoiceMainStationDataList[i].lessAmount;
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.invoiceMainStationDataList.length, this.lrsSummary, "", this.articleSummary, this.acWtSummary, this.chgWtSummary, this.toPaySummary, this.paidSummary, this.fovSummary, this.lessAmountSummary];
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printTitle', "Invoice Details Main Station");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }
    customPrintInvoiceSubStationRpt() {
        if (this.invoiceSubStationDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Invoice Number", "Total Lrs", "Invoice Date", "Articles", "Actual Weight", "Charged Weight", "To Pay", "Paid", "Less Amount"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            //heading logics For Date

            this.validateFromDateIdValue = $("#" + this.pageId + "fromDates").val();
            this.validateToDateIdValue = $("#" + this.pageId + "toDate").val();
            this.validateInvoiceNumberIdValue = $("#" + this.pageId + "invoiceNumberId").val();
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            if (this.validateInvoiceNumberIdValue == null || this.validateInvoiceNumberIdValue == '' || this.validateInvoiceNumberIdValue == undefined) {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateFromDateIdValue, this.validateToDateIdValue];
            }
            else {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Invoice Number"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateInvoiceNumberIdValue];
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();

                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];

                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.lrsSummary = this.articleSummary = this.acWtSummary = this.chgWtSummary = this.toPaySummary = this.paidSummary = this.serTaxSummary = this.lessAmountSummary = 0;

            for (let i = 0; i < this.invoiceSubStationDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.invoiceSubStationDataList[i].invoiceNumber, this.invoiceSubStationDataList[i].totalLrs,
                this.invoiceSubStationDataList[i].invoiceDate, this.invoiceSubStationDataList[i].articles, this.invoiceSubStationDataList[i].actualWeight,
                this.invoiceSubStationDataList[i].chargedWeight, this.invoiceSubStationDataList[i].toPay, this.invoiceSubStationDataList[i].paid,
                this.invoiceSubStationDataList[i].lessAmount];

                /////////
                this.lrsSummary = this.lrsSummary + this.invoiceSubStationDataList[i].totalLrs;
                this.articleSummary = this.articleSummary + this.invoiceSubStationDataList[i].articles;
                this.acWtSummary = this.acWtSummary + this.invoiceSubStationDataList[i].actualWeight;
                this.chgWtSummary = this.chgWtSummary + this.invoiceSubStationDataList[i].chargedWeight;
                this.toPaySummary = this.toPaySummary + this.invoiceSubStationDataList[i].toPay;
                this.paidSummary = this.paidSummary + this.invoiceSubStationDataList[i].paid;
                this.lessAmountSummary = this.lessAmountSummary + this.invoiceSubStationDataList[i].lessAmount;


                /////
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.invoiceSubStationDataList.length, this.lrsSummary, "", this.articleSummary, this.acWtSummary, this.chgWtSummary, this.toPaySummary, this.paidSummary, this.lessAmountSummary];

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printTitle', "Invoice Details Main Station");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }

    customPrintConsolInvoiceRpt() {
        if (this.consolidatedInvoiceDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Invoice Number", "Destination", "Lrs", "Invoice Date", "Article", "Act Wgt", "Chg Wgt", "To Pay", "Paid", "F O V", "Discount", "Service Tax", "Loaded By"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            //heading logics For Date
            this.validateFromDateIdValue = $("#" + this.pageId + "fromDates").val();
            this.validateToDateIdValue = $("#" + this.pageId + "toDate").val();
            this.validateInvoiceNumberIdValue = $("#" + this.pageId + "invoiceNumberId").val();
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            if (this.validateInvoiceNumberIdValue == null || this.validateInvoiceNumberIdValue == '' || this.validateInvoiceNumberIdValue == undefined) {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateFromDateIdValue, this.validateToDateIdValue];
            }
            else {
                this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Invoice Number"];
                this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateInvoiceNumberIdValue];
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.lrsSummary = this.articleSummary = this.acWtSummary = this.chgWtSummary = this.toPaySummary = this.paidSummary = this.serTaxSummary = this.lessAmountSummary = 0;

            for (let i = 0; i < this.consolidatedInvoiceDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.consolidatedInvoiceDataList[i].invoiceNumber, this.consolidatedInvoiceDataList[i].destination,
                this.consolidatedInvoiceDataList[i].totalLrs, this.consolidatedInvoiceDataList[i].invoiceDate, this.consolidatedInvoiceDataList[i].articles,
                this.consolidatedInvoiceDataList[i].actualWeight, this.consolidatedInvoiceDataList[i].chargedWeight, this.consolidatedInvoiceDataList[i].toPay,
                this.consolidatedInvoiceDataList[i].paid, this.consolidatedInvoiceDataList[i].fov, this.consolidatedInvoiceDataList[i].discount, this.consolidatedInvoiceDataList[i].lessAmount, this.consolidatedInvoiceDataList[i].loadedBy];

                this.lrsSummary = this.lrsSummary + this.consolidatedInvoiceDataList[i].totalLrs;
                this.articleSummary = this.articleSummary + this.consolidatedInvoiceDataList[i].articles;
                this.acWtSummary = this.acWtSummary + this.consolidatedInvoiceDataList[i].actualWeight;
                this.chgWtSummary = this.chgWtSummary + this.consolidatedInvoiceDataList[i].chargedWeight;
                this.toPaySummary = this.toPaySummary + this.consolidatedInvoiceDataList[i].toPay;
                this.paidSummary = this.paidSummary + this.consolidatedInvoiceDataList[i].paid;
                this.fovSummary = this.fovSummary + this.consolidatedInvoiceDataList[i].fov;
                this.discountSummary = this.discountSummary + this.consolidatedInvoiceDataList[i].discount;
                this.serTaxSummary = this.serTaxSummary + this.consolidatedInvoiceDataList[i].lessAmount;
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.consolidatedInvoiceDataList.length, "", this.lrsSummary, "", this.articleSummary, this.acWtSummary, this.chgWtSummary, this.toPaySummary, this.paidSummary, this.lessAmountSummary, this.fovSummary, this.discountSummary, this.serTaxSummary];

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printTitle', "Invoice Details Main Station");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });

        }
    }

    customPrintUnloadingSheet() {
        if (this.invoiceDetailsLoadingSheetDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Art", "Pvt Mark", "Consignee", "Dest Code", "Case", "GCase", "Cart", "GCart", "Bundle", "Roll"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [11, 6, 10, 17, 8, 8, 8, 8, 8, 8, 8];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            //heading logics

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["Truck", "Inv No", "Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.lrDtoForLocalStorage.vehicleNumber, this.lrDtoForLocalStorage.invoiceNumber, this.lrDtoForLocalStorage.invoiceDateStr];

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Source", "Destination", "Printed On"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.lrDtoForLocalStorage.source, this.lrDtoForLocalStorage.destination, moment(new Date()).format("DD-MM-YYYY")];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            this.artSummary = 0;

            for (let i = 0; i < this.invoiceDetailsLoadingSheetDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.invoiceDetailsLoadingSheetDataList[i].lrNumber,
                this.invoiceDetailsLoadingSheetDataList[i].totalArticles, this.invoiceDetailsLoadingSheetDataList[i].privateMarker,
                this.invoiceDetailsLoadingSheetDataList[i].consigneeName, this.invoiceDetailsLoadingSheetDataList[i].stationCode,
                    "", "", "", "", "", ""];

                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

                this.artSummary = this.artSummary + this.invoiceDetailsLoadingSheetDataList[i].totalArticles;
            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.invoiceDetailsLoadingSheetDataList.length, this.artSummary, "", "", "", "", "", "", "", "", ""];

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Unloading Report");
        }
    }

    setInvoiceDetailsOfDateWiseDetails(lrDtoForSearchData) {
        this.showSpinnerForAction = true;
        console.log("DateWiseCheck");
        console.log(lrDtoForSearchData)
        this.destStmtReport.getInvoiceDetailsDateWiseDetails(lrDtoForSearchData).subscribe(
            (response) => {
                console.log(response);
                $("#" + this.pageId + "invoiceDetailsDateWiseDetailsTableId").DataTable().destroy();
                this.invoiceDetailsDateWiseDetailsList = [];
                if (response.length > 0) {
                    this.invoiceDetailsDateWiseDetailsList = response;
                } else {
                    swal("Alert", "No Datas Found For This Information", "warning");
                }
                console.log("DateWiseDetails");
                console.log(this.invoiceDetailsDateWiseDetailsList);
                this.dtTriggerInvoiceDetailsDateWiseDetails.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Invoice Date Wise Details", "info");
            }, () => console.log('done');
    }
}
