<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}agentStmtBalanceDetailsRptId">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Balancing Difference Tally
						Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<div class="row">

											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Select Region</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-search	"></i>
															</span>
														</div>
														<select class="custom-select col-12" id="{{pageId}}transferType">
															<option value="South">South</option>
															<option value="MH">MH</option>
														</select>
													</div>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}getReportBtn" (click)="getDestAgentStmtTrxAmt()">Get
								Report</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<h6 class="card-title border_bottom">Agent Statement Amount
							Transaction Details</h6>
						<button type="submit" class="dt-button" style="margin-left: 90%;"
							(click)="customPrintAgentBalDetails()" id="{{pageId}}printBtn">
							<span><i class="fas fa-print">Print</i></span>
						</button>
						<div class="box-body">
							<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;">
										</mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>

							<table datatable id="{{pageId}}destAgentBalDetailsTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsAgentStmtAmount"
								[dtTrigger]="dtTriggerAgentStmtAmount">

								<thead>
									<tr>
										<th>Station</th>
										<th>Total Statement Amount</th>
										<th>Total Amount Received</th>
										<th>Total Debit Note Amount</th>
										<th>Balance Amount</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let agentStmtAmountData of agentStmtAmountDataList ">
										<td>{{ agentStmtAmountData.destination }}</td>
										<td>{{ agentStmtAmountData.totalAmount}}</td>
										<td>{{ agentStmtAmountData.totalAmtPaid }}</td>
										<td>{{ agentStmtAmountData.debitTotal }}</td>
										<td>{{ agentStmtAmountData.balanceAmt }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>


						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()"
		id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>