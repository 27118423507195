<html>

<head>
</head>

<body>

    <!-- Row -->
    <div class="row system_responsive">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="row">
                    <!-- column -->
                    <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 10px;">
                    <div class="col-md-4 ">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
													class="fas fa-user"></i>
												</span>
                                            </div>
                                            <input #source id="{{pageId}}source" name="source" type="text" class="form-control" placeholder="Select Source" [(ngModel)]="modelForMainSrc" [ngbTypeahead]="searchMainSrcAt" [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterMainSrc" [inputFormatter]="formatterMainSrc"
                                                (focus)="focusMainSrcTA$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
													class=" fas fa-road"></i>
												</span>
                                            </div>
                                            <input #consignorIndex id="{{pageId}}consignorIndex" name="consignorIndex" type="text" class="form-control" placeholder="Select Consignor Index" (selectItem)="consignorDropDownListnerPending($event)" [ngbTypeahead]="consignorIndexSearchTA" (focus)="focusConsignorIndexTA$.next($any($event).target.value)"
                                                (click)="clickTA(instanceConsignorIndex)" #instanceConsignorIndex="ngbTypeahead" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-12 p-t-10">
                                    <div class="box-body">
                                        <div class="row" style="border-bottom: 1px solid orange; padding-bottom: 4px;">
                                            <div class="col-sm-12 col-md-7">
                                                <h6 class="card-title">Unconfirm Consignor Details</h6>
                                            </div>
                                            <div class="col-sm-12 col-md-5">
                                                <button style="padding: 1px 4px;" id="{{pageId}}confirmBtn" type="submit" class="btn btn-success m-r-10" (click)="confrimBtnMethod();">Confirm</button>
                                                <button style="padding: 1px 4px; background: blue;" type="submit" class="btn btn-success m-r-10" id="{{pageId}}moveToMergeBtn" (click)="moveToMergeMethod();">Move</button>
                                            </div>
                                        </div>
                                        <div class="row p-t-10">
                                            <div class="col-sm-12 col-md-12">
                                                <table datatable id="{{pageId}}repeatedConsignorId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsRepeatedConsignorList" [dtTrigger]="dtTriggerRepeatedConsignorList">
                                                    <thead>
                                                        <tr>
                                                            <th>Action</th>
                                                            <th>Consignor Name</th>
                                                            <th>Consignor GST Number</th>
                                                            <th>Is Ret Set?</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let repeatedConsignorListData of repeatedConsignorListDataList ">
                                                            <td><input type="checkbox" [checked]="multiSelect" id='selected' style="margin-left: 20px" (change)="rowCheckBoxChecked($event, repeatedConsignorListData)" />
                                                            </td>
                                                            <td>{{ repeatedConsignorListData.consignorName }}</td>
                                                            <td>{{ repeatedConsignorListData.gstNoConsignor }}</td>
                                                            <td *ngIf="repeatedConsignorListData.isAdminRateSet== false">
                                                                {{ repeatedConsignorListData.isAdminRateSet== true ? 'Yes' : 'No'}}</td>
                                                            <td *ngIf="repeatedConsignorListData.isAdminRateSet== true" style='font-size: 13px; color: red; font-weight: bold;'>
                                                                {{ repeatedConsignorListData.isAdminRateSet== true ? 'Yes' : 'No'}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 vl">
                        <div class="card-body">
                            <!-- <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
													class="fas fa-user"></i>
												</span>
                                            </div>
                                            <input #source id="{{pageId}}sourceMerge" name="sourceMerge" type="text" class="form-control" placeholder="Select Source" [(ngModel)]="modelForMainSrcMerge" [ngbTypeahead]="searchMainSrcAtMerge" [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterMainSrcMerge"
                                                [inputFormatter]="formatterMainSrcMerge" (focus)="focusMainSrcTAMerge$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}" />
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-md-12 p-t-10">
                                    <div class="box-body">
                                        <div class="row" style="border-bottom: 1px solid orange; padding-bottom: 4px;">
                                            <div class="col-sm-12 col-md-9">
                                                <h6 class="card-title">To be merge Consignor Details</h6>
                                            </div>
                                            <div class="col-sm-12 col-md-3" align='right'>
                                                <button style="padding: 1px 4px;background: brown;" type="submit" class="btn btn-success m-r-10" id="{{pageId}}mergeBtn" (click)="mergeBtnMethod();">Merge</button>
                                            </div>
                                        </div>
                                        <table datatable id="{{pageId}}mergeConsignorId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionMergeConsignorList" [dtTrigger]="dtTriggerMergeConsignorList">
                                            <thead>
                                                <tr>
                                                    <th>Consignor Name</th>
                                                    <th>GST Number</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr style="cursor: pointer;" *ngFor="let mergeConsignorListData of mergeConsignorListDataList " (click)="rowSelectedFromMerge(mergeConsignorListData);">
                                                    <td>{{ mergeConsignorListData.consignorName }}</td>
                                                    <td>{{ mergeConsignorListData.gstNoConsignor }}</td>
                                                    <td>
                                                        <button type="button" style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}tableRemoveBtn" (click)="rowSelectedDeleteForMerge(mergeConsignorListData,i);">
															<i title="Remove" class="fas fa-trash"></i>
														</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="row p-t-10">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Consignor Name</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
													class="fas fa-user"></i>
												</span>
                                            </div>
                                            <input #consignorNameConfirmed type="text" id="{{pageId}}consignorNameConfirmed" name="consignorNameConfirmed" [(ngModel)]="consignorName" class="form-control" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 vl">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
													class="fas fa-user"></i>
												</span>
                                            </div>
                                            <input #source id="{{pageId}}sourceConfirmed" name="sourceConfirmed" type="text" class="form-control" placeholder="Select Source" [(ngModel)]="modelForMainSrcConfirmed" [ngbTypeahead]="searchMainSrcAtConfirmed" [ngModelOptions]="{standalone: true}" [resultFormatter]="formatterMainSrcConfirmed"
                                                [inputFormatter]="formatterMainSrcConfirmed" (focus)="focusMainSrcTAConfirmed$.next($any($event).target.value)" [ngModelOptions]="{standalone: true}" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
													class=" fas fa-road"></i>
												</span>
                                            </div>
                                            <input #consignorIndexConfirmed id="{{pageId}}consignorIndexConfirmed" name="consignorIndexConfirmed" type="text" class="form-control" placeholder="Select Index" (selectItem)="consignorDropDownListnerConfirmed($event)" [ngbTypeahead]="consignorIndexSearchTAConfirmed"
                                                (focus)="focusConsignorIndexTAConfirmed$.next($any($event).target.value)" (click)="clickTA(instanceConsignorIndexConfirmed)" #instanceConsignorIndexConfirmed="ngbTypeahead" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="box-body">
                                        <div class="row" style="border-bottom: 1px solid orange;">
                                            <div class="col-sm-12 col-md-8">
                                                <h6 class="card-title">Confirmed Consignor Details</h6>
                                            </div>
                                            <div class="col-sm-12 col-md-4" align='right'>
                                                <button style="padding: 1px 4px;" type="submit" (click)="undoBtnMethod();" class="btn btn-dark m-r-10" id="{{pageId}}undoBtn">Undo</button>
                                            </div>
                                        </div>
                                        <div class="row p-t-10">
                                            <div class="col-sm-12 col-md-12">
                                                <table datatable id="{{pageId}}confirmedConsigorTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionConfirmedConsignorList" [dtTrigger]="dtTriggerConfirmedConsignorList">
                                                    <thead>
                                                        <tr>
                                                            <th>Confirmed Consignor Name</th>
                                                            <th>Consignor GST Number</th>
                                                            <th>Is Ret Set?</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr style="cursor: pointer;" *ngFor="let confirmedConsignorListData of confirmedConsignorListDataList; let i = index" (click)="rowSelectedForUndo(confirmedConsignorListData);">
                                                            <td>{{ confirmedConsignorListData.consignorName }}</td>
                                                            <td>{{ confirmedConsignorListData.gstNoConsignor }}</td>
                                                            <td *ngIf="confirmedConsignorListData.isAdminRateSet== false">
                                                                {{ confirmedConsignorListData.isAdminRateSet== true ? 'Yes' : 'No'}}</td>
                                                            <td *ngIf="confirmedConsignorListData.isAdminRateSet== true" style='font-size: 13px; color: red; font-weight: bold;'>
                                                                {{ confirmedConsignorListData.isAdminRateSet== true ? 'Yes' : 'No'}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Row -->
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="input-group" id="{{pageId}}popupDetailsTwo">
                            <ng-template #content let-ok="close" let-yes="close" let-no="close" let-d="dismiss">
                                <div class="modal-body">
                                    <div class="row" style="text-align: center;">
                                        <div class="col-md-12">
                                            <!-- 										<h5 class="m-b-10">Sure you want to UnConfirm the -->
                                            <!-- 											selected Consignors?</h5> -->
                                            <h5 class="m-b-10">
                                                <i class="fas fa-exclamation-triangle"></i> Please select consignor name to Undo
                                            </h5>
                                        </div>
                                        <div class="col-md-12 p-t-10">
                                            <button type="button" class="btn btn-outline-secondary" (click)="ok('Yes click')">Ok</button>
                                            <!-- 										<button type="button" class="btn btn-outline-secondary" -->
                                            <!-- 											(click)="yes('Yes click')">Yes</button> -->
                                            <!-- 										<button type="button" class="btn btn-outline-danger" -->
                                            <!-- 											(click)="no('No click')">No</button> -->
                                        </div>
                                    </div>
                                </div>
                            </ng-template>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</body>

</html>