import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { LRDto } from 'src/app/dto/LR-dto';

export interface DialogData {
    animal: string;
    name: string;
}

@Component({
    selector: 'app-party-less-check-details-V2',
    templateUrl: './party-less-check-details-V2.component.html',
    styleUrls: ['./party-less-check-details-V2.component.css']
})
export class PartyLessCheckDetailsV2Component implements OnInit {
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction = false;

    selectedTotalLessData: any;
    selectedMode: any;
    pageId = "plcV2dc";
    bookingLessDetailsReportDataList: any;
    dtTriggerBookingLessDetailsPartyLessReport: Subject<any> = new Subject();
    dtOptionsBookingLessDetailsPartyLessReport: any;
    lrDtoForBookingServiceTax: LRDto = new LRDto();

    constructor(private router: Router,
        public dialogRef: MatDialogRef<PartyLessCheckDetailsV2Component>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef, private lrReportService: LrReportService
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.selectedTotalLessData = JSON.parse(localStorage.getItem('totalLessDataPopup'));
            this.selectedMode = JSON.parse(localStorage.getItem('totalLessMode'));

            setTimeout(() => {
                this.setBookingLessDetailsReport()
            }, 1000);
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var stmtId = this.selectedTotalLessData.stmtId;
        this.dtOptionsBookingLessDetailsPartyLessReport = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return "LR Details " + " - " +
                            "Stmt No : " + stmtId;
                    },
                    customize: function (xlsx: { xl: { worksheets: { [x: string]: any; }; }; }) {
                        var sheet = xlsx.xl.worksheets['sheet1.xml'];
                        //for border in table export
                        $('row c', sheet).attr('s', '25');
                        // for first column to be bold
                        // $('row:first c', sheet).attr('s', '2');
                        $('row:nth-child(2) c', sheet).attr('s', '2');
                        // for last column to be bold
                        $('row:last c', sheet).attr('s', '2');

                        // width set for column
                        var col = $('col', sheet);
                        console.log('excel : ' + col);
                        $(col[26]).attr('width', 100);
                        // for wrap text for particulr column
                        $('row c[r^="AB"]', sheet).attr('s', '55');
                        //center
                        // $('row c[r^="A"]', sheet).attr('s', '51');
                        // $('row c[r^="B"]', sheet).attr('s', '51');
                        // $('row c[r^="C"]', sheet).attr('s', '51');
                        // $('row c[r^="D"]', sheet).attr('s', '51');

                        //$('row c[r*="1"]', sheet).attr( 's', '25' );
                        //$('row c[r*="1"]', sheet).attr( 's', '51' );
                        $('row:eq(0) c', sheet).attr('s', '2');

                        var lastXfIndex = $('cellXfs xf', sheet).length;
                        console.log('center : ' + lastXfIndex);
                        var greyBoldCentered = lastXfIndex + 4;
                        // $('row:eq(0) c', sheet).attr('s', 50);


                        // $('row:eq(0) c', sheet).attr('s', '50');
                        // $('row:eq(1) c', sheet).attr('s', '50');
                        // $('row:last c', sheet).attr('s', '50');
                    },
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
                            11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                            21, 22, 23, 24, 25, 26]
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 350,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": true,
            //"order": [[7, 'asc'], [17, 'asc']],
            // columnDefs: [{
            //     targets: 0,
            //     render: function (data, type, row, meta) {
            //         console.log(meta.row);
            //         console.log(type == 'export' ? meta.row + 1 : data);
            //         return meta.row + 1;
            //     }
            // }],

            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 

                var article = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWt = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var baseFreight = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var toPay = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(14).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var fov = api.column(15).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var disc = api.column(16).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var sHamali = api.column(27).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var lcChg = api.column(28).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var bcChg = api.column(29).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var aocChg = api.column(30).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var ddChg = api.column(31).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var gcChg = api.column(32).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total');
                $(api.column(1).footer()).html(data.length);
                $(api.column(9).footer()).html(article);
                $(api.column(10).footer()).html(actWgt);
                $(api.column(11).footer()).html(chgWt);
                $(api.column(12).footer()).html(baseFreight);
                $(api.column(13).footer()).html(toPay);
                $(api.column(14).footer()).html(paid);
                $(api.column(15).footer()).html(fov);
                $(api.column(16).footer()).html(disc);

                $(api.column(20).footer()).html(sHamali);
                $(api.column(21).footer()).html(gcChg);
                $(api.column(22).footer()).html(lcChg);
                $(api.column(23).footer()).html(bcChg);
                $(api.column(24).footer()).html(aocChg);
                $(api.column(25).footer()).html(ddChg);
            }
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerBookingLessDetailsPartyLessReport.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerBookingLessDetailsPartyLessReport.unsubscribe();
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    setBookingLessDetailsReport() {
        this.getBookingServiceTaxReportData();
        this.showSpinnerForAction = true;
        this.lrReportService.getBookingServiceTaxBookingLessDetails(this.lrDtoForBookingServiceTax).subscribe(
            (response) => {
                console.log(response);
                this.bookingLessDetailsReportDataList = [];
                $("#" + this.pageId + "bookingLessDetailsReportPartyLessTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.bookingLessDetailsReportDataList = response;
                } else {
                    swal("Alert", "No datas found for this information", "warning");
                }
                this.dtTriggerBookingLessDetailsPartyLessReport.next();
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the details", "info");
            }, () => console.log('done');
    }

    getBookingServiceTaxReportData() {
        this.lrDtoForBookingServiceTax = new LRDto();
        this.lrDtoForBookingServiceTax.fortNightStmtNo = this.selectedTotalLessData.stmtId;
        this.lrDtoForBookingServiceTax.mode = "partylesscheck";
    }

}
