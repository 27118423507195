import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from "@angular/core";
import { ReportService } from "src/app/dataService/report-service";
import { NgbDateStruct, NgbTypeaheadSelectItemEvent, NgbModalRef, NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
const my = new Date();
import { Subject, Subscription, merge } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from "sweetalert";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { LRDto } from "src/app/dto/LR-dto";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { DatePipe } from "@angular/common";
import *  as moment from 'moment';
import { MultipleArticleSizePopupComponent } from "src/app/lr/enquiry/multiple-article-size-popup/multiple-article-size-popup.component";
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: "app-lr-dws-wgt-rpt",
    templateUrl: "./lr-dws-wgt-rpt.component.html",
    styleUrls: ["./lr-dws-wgt-rpt.component.css"],
})
export class LrDwsWeightReportComponent implements OnInit {
    gettingDataFrmServiceFrSmryTable: any;
    lrDwsWgtDataList: any;
    onDestroyUnsubscribtionSmry: Subscription;
    model: NgbDateStruct;
    model2;
    loadingIndicator = true;
    viewTypeChallan = false;
    viewTypeOther = true;
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    @ViewChildren(DataTableDirective)
    public dtElements: QueryList<DataTableDirective>;

    dtTriggerLrDwsWgt: Subject<any> = new Subject();
    dtOptionsLrDwsWgt: any;
    showAgentCB = false;
    showSpinnerForAction = false;
    userDataDtoReturnSession: any;
    superAdminview: boolean = false;
    isLoggedIn = true;
    address: any;

    modalRefferenceMultipleArtSizePopUp: NgbModalRef;
    modalRefferenceMultipleArtSizeClosePopUp: string;
    viewMultipleArtSizePopUp = false;
    modalRefferenceMultipleArtSizeV3PopUp: NgbModalRef;
    modalRefferenceMultipleArtSizeV3ClosePopUp: string;
    viewMultipleArtSizeV3PopUp = false;
    //
    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    lrDtoDestinationAll: LRDto = new LRDto();

    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ""
                    ? this.destinationTA
                    : this.destinationTA.filter(
                        (v) =>
                            v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1
                    )
                ).slice(0, 200)
            )
        );
    };
    formatterDestination = (x: { destination: string }) => x.destination;
    ////
    selectedDestination: any;
    setTodayDateOnFromDate: any;
    setTodayDateOnToDate: any;

    srcAllDest = false;
    srcAll = false;
    srcSpec = false;
    destView = false;
    agentView = false;

    showDestination = true;
    srcListsSet: Array<any> = [];
    // srcList: Array<any> = [];
    selectedFromDate: any;
    selectedToDate: any;
    selectedViewMode: any;
    sentSrcList: Array<any> = [];
    selectedLrNumber: any;
    showAgent = false;
    //For Source
    srcList: LRDto = new LRDto;
    srcLists: any;
    sourceOptions: LRDto[];
    lrDtoSource: LRDto = new LRDto();
    public modelSource: any;
    sourceTA: Array<LRDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceTA
                : this.sourceTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))

        );
    }
    formatterSource = (x: { subStations: string }) => x.subStations;
    //
    lrDtoSourceOptions: LRDto[];
    lrDtoSourceAllOption: LRDto = new LRDto();
    lrDtoSourceAddOption: LRDto = new LRDto();
    selectedSource: any;
    lrDtoForSearch: LRDto = new LRDto();
    searchSrcListInLoop: Array<any> = [];
    srcListTrue = false;
    setStation: any;
    //
    viewCustomPrintV1 = false;

    grandActWt = 0;
    grandChWt = 0;
    grandTotalArtVal = 0;
    //For Custom Print
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    eneteredChallanNumber: any;
    public modelDestinationFotGet: any;
    listOfMultipleArticles: any;
    listOfMultipleArticlesDWS: any;
    pageId = "ldwpc";

    constructor(private reportService: ReportService,
        private masterReadService: MasterReadService,
        private datePipe: DatePipe,
        private router: Router,
        public changeDetectorRef: ChangeDetectorRef,
        public dialog: MatDialog, private modalService: NgbModal) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            });
        }

        //Rights For Agent
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(
                sessionStorage.getItem("SRDWeb")
            );
            this.address = this.userDataDtoReturnSession.addressId == null ? "" : this.userDataDtoReturnSession.addressId;
            this.setStation = this.userDataDtoReturnSession.mainStation;


            this.getDestinationDetails();
            this.selectTodayDate();
            this.showAgent = true;
            // In this src and agent is visible to all
            // but for super admin src and agent from same service
            // for others src and agent is form stationlist
            // In  // if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
            //     //    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
            // if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            //     && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
            //     "ConsigneeRpt DestView") {
            //     this.destView = true;
            //     // all except delivery off
            //     //  this.showorhideDestination = true;
            // } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            //     && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
            //     "ConsigneeRpt SrcAll") {
            //     this.srcAll = true;
            //     // this.showorhideDestination = true;
            // } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            //     && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
            //     "ConsigneeRpt SrcSpec") {
            //     this.srcSpec = true;
            //     // this.showorhideDestination = true;
            // } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            //     && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
            //     "ConsigneeRpt AgentView") {
            //     // this.agentView = true;
            //     // for all
            //     this.showAgentCB = true;
            // } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            //     && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
            //     "ConsigneeRpt SrcAllDest") {
            //     this.srcAllDest = true;
            //     //source for destination

            //     //  this.showorhideDestination = true;
            // } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            //     && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
            //     "ConsigneeRpt ShowDest") {
            //     this.srcAllDest = true;
            //     //New Right // by basha to show destination
            //     this.showorhideDestination = true;
            // } else

            for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

                if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                    && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                    "ConsigneeRpt srcList") {
                    // New Right
                    //It is true for super admin //by basha  to show src and agent from service
                    this.srcListTrue = true;

                }
            }
            // }
            // this report rigths is given only for  iqbal, superadmin,manojadmin login to show destiantion for others it is hidden as per asrar bhai
            // hardcoded to check
            // this.srcListTrue = false;

            if (this.srcListTrue == true) {
                this.getSourceDetails();
                console.log('1');
            } else {
                console.log('2');
                this.getSourceList();
            }

        }


    }
    logInPage() {
        this.router.navigate(["/authentication/login"]);
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    selectTodayDate() {
        this.setTodayDateOnToDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        this.setTodayDateOnFromDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };

        $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
        $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
    }
    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        inp._elementRef.nativeElement.focus();
    }
    ngOnInit(): void {
        var groupColumn = 0;
        this.dtOptionsLrDwsWgt = {

            dom: "Bfrtip",

            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                exportOptions: {
                    columns: [0, 1, 2, 3, 4, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]
                },
                title: function () {

                    var returSummary = null;
                    if ($("#ldwpcviewType").val() == "challanNoWise") {
                        returSummary = "LR DWS Weight Report " +
                            " Challan No : " + $("#ldwpcchallanNumber").val();
                    } else {
                        returSummary = "LR DWS Weight Report " +
                            " From Date : " + moment($("#ldwpcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#ldwpctoDate").val()).format('DD-MM-YYYY') + ", " +
                            "Source : " + $("#ldwpcsource").val() + "," +
                            "Destination : " + $("#ldwpcdestination").val();
                    }

                    return returSummary;
                }
            }],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search...",
            },

            processing: true,
            //scroll in datatable starts
            responsive: true,
            scrollX: true,
            scrollY: 380,
            scrollCollapse: true,

            paging: false,
            info: false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var actWgt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var art = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var dwsActWgt = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var dwsChgWgt = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var dwsArt = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var diffActWgt = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                // var perArtActWgtDiff = api.column(14).data().reduce(
                //     function (a, b) {
                //         return intVal(a) + intVal(b);
                //     }, 0);
                var diffChgWgt = api.column(16).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var diffArt = api.column(17).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var dwsVolumeTot = api.column(18).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var sysVolumeTot = api.column(19).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var diffDwsSysVol = api.column(20).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var dwsSizeTot = api.column(21).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var sysSizeTot = api.column(22).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var diffDwsSysSize = api.column(23).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                if (art != null && art > 0) {
                    var perArtActWgtDiff = ((diffActWgt == null ? 0 : diffActWgt) / (art == null ? 0 : art));
                    $(api.column(14).footer()).html("" + (perArtActWgtDiff == null ? 0 : perArtActWgtDiff.toFixed(0)));
                }

                if (dwsActWgt != null && dwsActWgt > 0) {
                    var diffActWgtPerc = ((diffActWgt == null ? 0 : diffActWgt) / (dwsActWgt == null ? 0 : dwsActWgt)) * 100;
                    $(api.column(15).footer()).html("" + (diffActWgtPerc == null ? 0 : diffActWgtPerc.toFixed(0)));
                }

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(6).footer()).html(actWgt);
                $(api.column(7).footer()).html(chgWgt);
                $(api.column(8).footer()).html(art);
                $(api.column(10).footer()).html(dwsActWgt);
                $(api.column(11).footer()).html(dwsChgWgt);
                $(api.column(12).footer()).html(dwsArt);
                $(api.column(13).footer()).html(diffActWgt);
                //$(api.column(14).footer()).html(perArtActWgtDiff);
                $(api.column(16).footer()).html(diffChgWgt);
                $(api.column(17).footer()).html(diffArt);
                $(api.column(18).footer()).html(dwsVolumeTot);
                $(api.column(19).footer()).html(sysVolumeTot);
                $(api.column(20).footer()).html(diffDwsSysVol);
                $(api.column(21).footer()).html(dwsSizeTot);
                $(api.column(22).footer()).html(sysSizeTot);
                $(api.column(23).footer()).html(diffDwsSysSize);
            },

        }
    }


    ngOnDestroy(): void {
        this.dtTriggerLrDwsWgt.unsubscribe();



    }
    ngAfterViewInit(): void {
        this.dtTriggerLrDwsWgt.next();

    }

    viewTypeMode(viewType: string) {
        if (viewType === "bookingDateWise") {
            this.viewTypeOther = true;
            this.viewTypeChallan = false;
            this.clearTable();
            this.selectTodayDate();
            this.clearInputFieldonChange();
        } else if (viewType === "challanDateWise") {
            this.viewTypeOther = true;
            this.viewTypeChallan = false;
            this.clearTable();
            this.selectTodayDate();
            this.clearInputFieldonChange();
        } else if (viewType === "challanNoWise") {
            this.viewTypeOther = false;
            this.viewTypeChallan = true;
            this.clearTable();
            this.clearInputFieldonChange();
        } else if (viewType === "entryDateWise") {
            this.viewTypeOther = true;
            this.viewTypeChallan = false;
            this.clearTable();
            this.selectTodayDate();
            this.clearInputFieldonChange();
        } else {
            this.viewTypeOther = false;
            this.viewTypeChallan = false;
            this.clearTable();
            this.clearInputFieldonChange();
        }
    }

    clearTable() {
        $("#" + this.pageId + "lrDwsWgtReportTableId").DataTable().destroy();
        this.lrDwsWgtDataList = [];
        this.dtTriggerLrDwsWgt.next();
    }
    clearInputFieldonChange() {
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "source").val('');
        $("#" + this.pageId + "challanNumber").val('');

    }






    //for the select option with filter ends
    //For Destination DropDown
    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        // this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "LrForm";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService
            .getDestinationForLREntryService(this.lrDtoDestination)
            .subscribe((response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        this.lrDtoDestinationAll.destination = "All";
                        this.destinationTA.push(this.lrDtoDestinationAll);
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                        }
                        console.log(this.userDataDtoReturnSession.mainStation);

                        $("#" + this.pageId + "destination").val("All");

                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal(
                    "Error",
                    "Server Problem Occurred While getting the Destination Details",
                    "info"
                );
            },
            () => console.log("done");
    }



    //FromStation for superadmin 
    getSourceDetailedList() {
        this.lrDtoSource.mode = 'Booking';
        this.lrDtoSource.reportMode = 'InclMainStation';
        this.lrDtoSource.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.lrDtoSource);
    }

    getSourceDetails() {
        this.getSourceDetailedList();
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSource).subscribe(
            (response) => {
                if (response) {
                    if (response.length > 0) {
                        this.lrDtoSourceOptions = response;

                        console.log(this.lrDtoSourceOptions);
                        this.sourceTA = [];

                        this.searchSrcListInLoop = [];

                        this.lrDtoSourceAllOption.subStations = "All";
                        this.sourceTA.push(this.lrDtoSourceAllOption);

                        for (let i = 0; i < this.lrDtoSourceOptions.length; i++) {
                            this.sourceTA.push(this.lrDtoSourceOptions[i]);

                        }
                        for (let i = 0; i < this.lrDtoSourceOptions.length; i++) {
                            this.searchSrcListInLoop.push(this.lrDtoSourceOptions[i].subStations);

                        }
                        $("#" + this.pageId + "source").val('All');

                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Agent Details", "info");
            },
            () => console.log('done');

    };

    //From Station
    getSourceList() {
        console.log('not');
        this.srcLists = this.userDataDtoReturnSession.stationList;
        this.srcList.subStations = this.srcLists;
        this.sourceTA = [];
        this.lrDtoSourceAllOption.subStations = 'All';
        this.sourceTA.push(this.lrDtoSourceAllOption);

        for (let i = 0; i < this.srcList.subStations.length; i++) {
            this.lrDtoSourceAddOption = new LRDto();
            this.lrDtoSourceAddOption.subStations = this.srcList.subStations[i];
            this.sourceTA.push(this.lrDtoSourceAddOption);


        }
        for (let i = 0; i < this.srcList.subStations.length; i++) {
            this.searchSrcListInLoop.push(this.srcList.subStations[i]);
        }
        window.setTimeout(function () {
            $("#" + this.pageId + "source").val('All');
        }, 200);


    }

    //For Search Button
    searchMethod() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedSource = $("#" + this.pageId + "source").val();
        this.selectedViewMode = $("#" + this.pageId + "viewType").val();
        this.eneteredChallanNumber = $("#" + this.pageId + "challanNumber").val();

        if (this.selectedViewMode == "bookingDateWise" || this.selectedViewMode == "challanDateWise" || this.selectedViewMode == "entryDateWise") {
            if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedFromDate == undefined
                || this.selectedToDate == null || this.selectedToDate == '' || this.selectedToDate == undefined
                || this.selectedSource == null || this.selectedSource == '' || this.selectedSource == undefined
                || this.selectedDestination == null || this.selectedDestination == '' || this.selectedDestination == undefined) {
                swal({
                    title: "Mandatory Field",
                    text: "Please select From Date,To Date, Source And Destination  to get the  details!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });

            } else {
                this.getSearchDetails();
            }
        } else if (this.selectedViewMode == "challanNoWise") {
            if (this.eneteredChallanNumber == null || this.eneteredChallanNumber == '' || this.eneteredChallanNumber == undefined) {
                swal({
                    title: "Mandatory Field",
                    text: "Please entered Challan No  to get the  details!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });

            } else {
                this.getSearchDetails();
            }
        }



    }

    setDetailsForSearch() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedSource = $("#" + this.pageId + "source").val();
        this.selectedViewMode = $("#" + this.pageId + "viewType").val();
        this.eneteredChallanNumber = $("#" + this.pageId + "challanNumber").val();
        this.lrDtoForSearch = new LRDto();
        if (this.selectedViewMode == "bookingDateWise" || this.selectedViewMode == "challanDateWise" || this.selectedViewMode == "entryDateWise") {

            this.lrDtoForSearch.fromDate = this.selectedFromDate;
            this.lrDtoForSearch.toDate = this.selectedToDate;
            this.lrDtoForSearch.source = this.selectedSource;
            this.lrDtoForSearch.destination = this.selectedDestination;
            if ((this.selectedSource != '') && (this.selectedSource != null) && (this.selectedSource == 'All')) {
                this.sentSrcList = [];
                this.sentSrcList = this.searchSrcListInLoop;
                this.lrDtoForSearch.list = this.sentSrcList;
            } else {
                this.sentSrcList = [];
                this.sentSrcList.push(this.selectedSource);
                this.lrDtoForSearch.list = this.sentSrcList;
            }

        } else if (this.selectedViewMode == "challanNoWise") {
            this.lrDtoForSearch.challanNo = this.eneteredChallanNumber;
        }
        this.lrDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForSearch.mode = this.selectedViewMode;
        this.lrDtoForSearch.offfice = this.userDataDtoReturnSession.office;
        this.lrDtoForSearch.userName = this.userDataDtoReturnSession.userName;
        this.lrDtoForSearch.mainstation = this.userDataDtoReturnSession.mainStation;

        console.log("this.lrDtoForSearch");
        console.log(this.lrDtoForSearch);


    }
    getSearchDetails = () => {
        this.setDetailsForSearch();
        this.showSpinnerForAction = true;
        this.reportService.getlrDwsWgtReport(this.lrDtoForSearch).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "lrDwsWgtReportTableId").DataTable().destroy();
                this.lrDwsWgtDataList = [];
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.lrDwsWgtDataList = response;
                    console.log("this.lrDwsWgtDataList");
                    console.log(this.lrDwsWgtDataList);
                }
                this.dtTriggerLrDwsWgt.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Lr DWS Weight  Report ", "info");
            }, () => console.log('done');
    };






    pritnSummaryWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");


        if (this.lrDwsWgtDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];


            this.cashMemoDtoForCustomPrintListColumnNames = ["Consignee Name", "Total Lrs", "Consignee Gst", "To Pay", "Paid", "Article", "Act Wgt", "Chg Wgt", "Goods Value", "F O V", "IS Rate Set"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 10, 10, 10, 10, 10, 10, 5, 5];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];


            this.grandTotalArtVal = 0;
            this.grandActWt = 0;
            this.grandChWt = 0;


            for (let i = 0; i < this.lrDwsWgtDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.lrDwsWgtDataList[i].consigneeName, this.lrDwsWgtDataList[i].totalLrs, this.lrDwsWgtDataList[i].gstNoConsignee, this.lrDwsWgtDataList[i].toPay, this.lrDwsWgtDataList[i].paid, this.lrDwsWgtDataList[i].totalArticles, this.lrDwsWgtDataList[i].actualWeight, this.lrDwsWgtDataList[i].chargedWeight, this.lrDwsWgtDataList[i].goodsValue, this.lrDwsWgtDataList[i].riskCharge, this.lrDwsWgtDataList[i].isAdminRateSet];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);



                this.grandTotalArtVal = +this.grandTotalArtVal + +this.lrDwsWgtDataList[i].totalArticles;
                this.grandActWt = +this.grandActWt + +this.lrDwsWgtDataList[i].actualWeight;
                this.grandChWt = +this.grandChWt + +this.lrDwsWgtDataList[i].chargedWeight;




                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lrDwsWgtDataList.length, this.grandTotalArtVal, "", "", "", this.grandTotalArtVal, this.grandActWt, this.grandChWt, "", "", ""];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            //  this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From", "To "];
            //   this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSource, this.selectedDestination];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            //  localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Consignee Summary Report ");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }
    clearValidaton() {
        swal({
            title: "Clear All",
            text: "Sure You Want To Clear All",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((clear) => {
            if (clear) {
                this.clearAll();
            }
        });
    }

    clearAll() {

        this.selectedFromDate = '';
        this.selectedLrNumber = '';
        this.selectedToDate = '';
        this.selectedDestination = '';
        this.eneteredChallanNumber = '';
        $("#" + this.pageId + "source").val('');
        $("#" + this.pageId + "viewType").val('bookingDateWise');
        $("#" + this.pageId + "challanNumber").val('');
        $("#" + this.pageId + "destination").val('');
        this.selectedViewMode = '';
        this.lrDtoForSearch = new LRDto();
        this.selectTodayDate();
        this.viewTypeOther = true;
        this.viewTypeChallan = false;

        this.clearTable();

        this.grandActWt = 0;
        this.grandChWt = 0;

        this.grandTotalArtVal = 0;

        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrintList = [];
        this.cashMemoDtoForCustomPrintListColumnNames = [];
        this.cashMemoDtoForCustomPrintListColumnWidths = [];
        this.cashMemoDtoForCustomPrintDataList = [];
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [];
        this.cashMemoDtoForCustomPrintDataSummaryList = [];
        this.viewCustomPrintV1 = false;
        this.cashMemoDtoForCustomPrintListHeadingV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [];
        this.selectTodayDate();
        this.showSpinnerForAction = false;
        this.selectedDestination = '';
        this.modelDestinationFotGet = '';
    }


    destinationListener(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestinationFotGet = e.item;
        this.selectedDestination = this.modelDestinationFotGet.destination;
        console.log(this.selectedDestination)

    }

    rowSelectedSysSizeViewMtd(lrDwsWgtData, multipleArtSizePopUpTemplate) {
        console.log("lrDwsWgtData");
        console.log(lrDwsWgtData);
        this.listOfMultipleArticles = null;
        this.listOfMultipleArticles = lrDwsWgtData.listOfmultipleArt;
        console.log("this.listOfMultipleArticles");
        console.log(this.listOfMultipleArticles);
        if (this.listOfMultipleArticles == null) {
            swal({
                title: "Warning",
                text: "No Multiple Articel for this LR!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.multipelArticleSizeDialog(multipleArtSizePopUpTemplate);
        }

    }
    rowSelectedDwsSizeViewMtd(lrDwsWgtData, multipleArtSizePopUpTemplate) {
        console.log("lrDwsWgtData");
        console.log(lrDwsWgtData);
        this.listOfMultipleArticles = null;
        this.listOfMultipleArticles = lrDwsWgtData.dwsListOfmultipleArt;
        console.log("this.listOfMultipleArticles");
        console.log(this.listOfMultipleArticles);
        if (this.listOfMultipleArticles == null) {
            swal({
                title: "Warning",
                text: "No Multiple Articel for this LR!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.multipelArticleSizeDialog(multipleArtSizePopUpTemplate);
        }

    }

    rowSelectedOverallDiffViewMtd(lrDwsWgtData, multipleArtSizeV3PopUpTemplate) {
        console.log("lrDwsWgtData");
        console.log(lrDwsWgtData);
        this.listOfMultipleArticles = null;
        this.listOfMultipleArticles = lrDwsWgtData.listOfmultipleArt;
        this.listOfMultipleArticlesDWS = null;
        this.listOfMultipleArticlesDWS = lrDwsWgtData.dwsListOfmultipleArt;
        console.log("this.listOfMultipleArticles");
        console.log(this.listOfMultipleArticles);
        if (this.listOfMultipleArticles == null) {
            swal({
                title: "Warning",
                text: "No details found!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.multipelArticleSizeV3Dialog(multipleArtSizeV3PopUpTemplate);
        }

    }

    multipelArticleSizeDialog(multipleArtSizePopUpTemplate) {
        this.modalRefferenceMultipleArtSizePopUp = this.modalService.open(multipleArtSizePopUpTemplate,
            { centered: true, windowClass: "myCustomModalClass" });
        this.modalRefferenceMultipleArtSizePopUp.result.then((result) => {
            this.modalRefferenceMultipleArtSizeClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceMultipleArtSizeClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });

        localStorage.clear();
        localStorage.setItem('popupListOfMultipleArticles', JSON.stringify(this.listOfMultipleArticles));
        this.viewMultipleArtSizePopUp = true;
        window.addEventListener('afterPopUp', (event) => {
            this.viewMultipleArtSizePopUp = false;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    closeMultipleArtSizePopUp() {
        this.modalRefferenceMultipleArtSizePopUp.close();

    }

    multipelArticleSizeV3Dialog(multipleArtSizeV3PopUpTemplate) {
        this.modalRefferenceMultipleArtSizeV3PopUp = this.modalService.open(multipleArtSizeV3PopUpTemplate,
            { centered: true, windowClass: "myCustomModalClassV3" });
        this.modalRefferenceMultipleArtSizeV3PopUp.result.then((result) => {
            this.modalRefferenceMultipleArtSizeV3ClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceMultipleArtSizeV3ClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });

        localStorage.clear();
        localStorage.setItem('popupListOfMultipleArticles', JSON.stringify(this.listOfMultipleArticles));
        localStorage.setItem('popupListOfMultipleArticlesDWS', JSON.stringify(this.listOfMultipleArticlesDWS));
        this.viewMultipleArtSizeV3PopUp = true;
        window.addEventListener('afterPopUp', (event) => {
            this.viewMultipleArtSizeV3PopUp = false;
        });
    }

    closeMultipleArtSizeV3PopUp() {
        this.modalRefferenceMultipleArtSizeV3PopUp.close();

    }
}
