import { ModalDismissReasons, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import swal from "sweetalert";
import { ReportService } from "src/app/dataService/report-service";
import { AgentDetailsDto } from "src/app/dto/AgentDetails-dto";
import { DatePipe } from "@angular/common";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, merge } from "rxjs";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { Router } from "@angular/router";
import { NgbModal, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { LRDto } from 'src/app/dto/LR-dto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataTableDirective } from "angular-datatables";
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from "@angular/core";
import { ViewChild } from "@angular/core";
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
const my = new Date();
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import *  as moment from 'moment';
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import { StockService } from 'src/app/dataService/stock-service';

@Component({
    selector: 'app-office-hireslip',
    templateUrl: './office-hireslip.component.html',
    styleUrls: ['./office-hireslip.component.css']
})
export class OfficeHireslipComponent implements OnInit {

    model: NgbDateStruct;


    showFromDate = true;
    showToDate = true;
    searchByDestinationWise = true;

    searchByMonthlyWise = false;

    searchByYearlyWise = false;

    searchByDestinationWiseTable = true;
    searchByAllDestinationTable = false;
    searchByMonthlyWiseTable = false;
    searchBySummaryReportTable = false;

    searchByYearlyWiseTable = false;

    searchBySummaryReportTable2 = false;


    @ViewChildren(DataTableDirective)
    public dtElements: QueryList<DataTableDirective>;

    dataTable: any;

    loadingIndicator = true;
    reorderable = true;

    destinationWiseRows = [];
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any;
    sourceView = false;
    showSource = false;
    sourceAdminView = false;
    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;
    showSpinnerForAction = false;
    destinationDtoForAll: LRDto = new LRDto();
    destinationList: Array<any> = [];

    ///For Source Main Booking Station
    agentDtoOptionSourceStationAll: AgentDetailsDto = new AgentDetailsDto();
    agentDtoOptionSourceStation: AgentDetailsDto[];
    agentDtoSourceStation: AgentDetailsDto = new AgentDetailsDto();
    public modelMainBookingSource: any;
    mainBookingSourceTA: Array<AgentDetailsDto> = [];
    focusMainBookingSourceTA$ = new Subject<string>();
    searchMainBookingSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusMainBookingSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.mainBookingSourceTA
                : this.mainBookingSourceTA.filter(v => v.mainBookStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterMainBookingSource = (x: { mainBookStations: string }) => x.mainBookStations;


    //For Source SUbstation
    lrDtoSourceStationAllOption: LRDto = new LRDto();
    sourceStationOptions: LRDto[];
    lrDtoSourceStation: LRDto = new LRDto();
    public modelSource: any;

    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSource = (x: { destination: string }) => x.destination;

    truckTypeOptions: TruckDataDto[];
    hmTruckType: Map<Number, Number> = new Map<Number, Number>();
    hmTruckTypeDto: Map<Number, TruckDataDto> = new Map<Number, TruckDataDto>();
    truckTypeDataDto: TruckDataDto = new TruckDataDto();
    public modelTruckType: any;
    truckTypeTA: Array<TruckDataDto> = [];
    focusTruckTypeTA$ = new Subject<string>();
    searchTruckType = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusTruckTypeTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.truckTypeTA
                : this.truckTypeTA.filter(v => v.truckType.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterTruckType = (x: { truckType: string }) => x.truckType;
    setInvoiceNo: any;
    closeResult: string;
    selectedTruckTypeId: any;
    selectedTruckType: any;

    dtTriggerDestinationWise: Subject<any> = new Subject();
    dtTriggerMonthlyWise: Subject<any> = new Subject();
    dtTriggerSummaryReport: Subject<any> = new Subject();
    dtTriggerSummaryReport2: Subject<any> = new Subject();

    dtTriggerAllDestination: Subject<any> = new Subject();

    dtOptionsAllDestination: any;
    dtOptionsMonthlyWise: any;
    dtOptionsSummaryReport: any;
    dtOptionsSummaryReport2: any;
    dtOptionsDestinationWise: any;

    destinationWiseDataList: any;
    monthlyWiseDataList: any;
    summaryReportDataList: any;
    summaryReport2DataList: any;
    allDestinationDataList: any;
    selectedFromDate: any;
    selectedToDate: any;
    setTodayDateOnFromDate: any;
    setTodayDateOnToDate: any;
    isMainSrcMultipleDelv = false;
    hireslipdtoForSearchBtn: HireSlipDto = new HireSlipDto();
    selectedViewMode: any;
    selectedSource: any;
    selectedDestination: any;
    srcList: Array<any> = [];
    sentSrcList: Array<any> = [];
    selectedSourceMainBkg: any;
    monthlySrcList: Array<any> = [];
    showForSingleDestWise = true;
    showForMultiDestWise = false;
    selectedFinancialYear: any;

    grandActWt = 0;
    grandChWt = 0;
    grandToPay = 0;
    grandPaid = 0;
    grandTotalAmt = 0;
    grandTotalhire = 0;
    grandAdvance = 0;
    grandGurWeight = 0;
    grandKantaWeight = 0;



    //For Custom Print
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    fromDatePrint: any;
    toDatePrint: any;
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    mainStn: any;
    viewCustomPrintV1 = false;
    viewCustomPrintV2 = false;
    viewCustomPrintV3 = false;
    viewCustomPrintV4 = false;
    viewCustomPrintV5 = false;
    viewCustomPrintV6 = false;
    yearlyIndexOptions = [
        { id: 1, label: '2010-2011' },
        { id: 2, label: '2011-2012' },
        { id: 3, label: '2012-2013' },
        { id: 4, label: '2013-2014' },
        { id: 5, label: '2014-2015' },
        { id: 6, label: '2015-2016' },
        { id: 7, label: '2016-2017' },
        { id: 8, label: '2017-2018' },
        { id: 9, label: '2018-2019' },
        { id: 10, label: '2019-2020' },
        { id: 11, label: '2020-2021' },
        { id: 12, label: '2021-2022' },
        { id: 13, label: '2022-2023' },
        { id: 14, label: '2023-2024' },
        { id: 15, label: '2024-2025' }];

    //For Yearly Index
    public modelYearlyIndex: any;
    @ViewChild('instanceYearlyIndex') instanceYearlyIndex: NgbTypeahead;
    yearlyIndexTA: Array<any> = [];
    focusYearlyIndexTA$ = new Subject<string>();
    yearlyIndexSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusYearlyIndexTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.yearlyIndexTA
                : this.yearlyIndexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    showYear = false;
    monthlyWiseDataLoop: any;
    pageId = "ofchs";

    showHireColumns: Boolean = false;

    constructor(
        private modalService: NgbModal,
        private masterReadService: MasterReadService,
        private reportService: ReportService, private datePipe: DatePipe,
        private router: Router, public changeDetectorRef: ChangeDetectorRef, private stockService: StockService) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            });
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(
                sessionStorage.getItem("SRDWeb")
            );
            this.getDestinationDetails();
            this.getMainBookingSourceDetails();
            this.selectTodayDate();
            this.setYearlyIndex();
            //  this.getSourceStationDetails();
            this.getTruckTypeDetails();
            //Rights For Agent

            this.address = this.userDataDtoReturnSession.addressId == null ? "" : this.userDataDtoReturnSession.addressId;
            this.mainStn = this.userDataDtoReturnSession.mainStation == null ? "" : this.userDataDtoReturnSession.mainStation;
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
                        this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "BkgOffHSRpt SourceView") {
                        this.showSource = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null &&
                        this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "MultipleDlvMainSrc") {
                        this.isMainSrcMultipleDelv = true;
                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "OfficeHireslip Rpt Show Hire") {
                        this.showHireColumns = true;
                    }
                }
            }
        }
    }

    logInPage() {
        this.router.navigate(["/authentication/login"]);
    }
    selectTodayDate() {
        this.setTodayDateOnToDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        this.setTodayDateOnFromDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };

        $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
        $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
    }

    ngOnInit(): void {
        let main = this.mainStn;
        let showSrc = this.showSource;
        var groupColumn = 0;
        var exportDestinationWiseArray = [], exportDestinationAllArray = [], exportMonthlyWiseArray = [], exportSummaryArray = [];
        if (this.showHireColumns) {
            exportDestinationWiseArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,18];
            exportDestinationAllArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
            exportMonthlyWiseArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
            exportSummaryArray = [0, 1, 2, 3];
        } else {
            exportDestinationWiseArray = [0, 1, 2, 3, 4, 5, 6, 7, 10, 11, 12, 13, 14, 15, 16, 17,18];
            exportDestinationAllArray = [0, 1, 2, 3, 6, 7, 8, 9];
            exportMonthlyWiseArray = [0, 1, 2, 3, 4, 7, 8, 9, 10, 11];
            exportSummaryArray = [0, 2, 3];
        }

        this.dtOptionsDestinationWise = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                exportOptions: {
                    columns: exportDestinationWiseArray
                },
                title: function () {
                    var returSummary = null;
                    if ($("#ofchssearchBy").val() == 'destinationWise') {
                        returSummary = "Booking HireSlip Details Report " +
                            " From Date : " + moment($("#ofchsfromDate").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#ofchstoDate").val()).format('DD-MM-YYYY') + " From Station : " + main;
                    } else {
                        returSummary = "Booking HireSlip Details Report " +
                            " From Date : " + moment($("#ofchsfromDate").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#ofchstoDate").val()).format('DD-MM-YYYY') + " From Station : " + main + " To Station : " + $("#ofchsdestination").val();
                    }

                    return returSummary;
                }
            }],
            processing: true,
            //scroll in datatable starts
            responsive: true,
            scrollX: true,
            scrollY: 380,
            scrollCollapse: true,
            paging: false,
            info: false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };


                var toPay = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var paid = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalFrgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var hire = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var advance = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var gurWgt = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var kantaWgt = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actWgt = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var chgWgt = api.column(13).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);




                $(api.column(1).footer()).html('Total : ' + data.length);
                $(api.column(5).footer()).html(toPay);
                $(api.column(6).footer()).html(paid);
                $(api.column(7).footer()).html(totalFrgt);
                $(api.column(8).footer()).html(hire);
                $(api.column(9).footer()).html(advance);
                $(api.column(10).footer()).html(gurWgt);
                $(api.column(11).footer()).html(kantaWgt);
                $(api.column(12).footer()).html(actWgt);
                $(api.column(13).footer()).html(chgWgt);
            },
            "drawCallback": function (settings) {
                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;

                var api = this.api();
                var rows = api.rows({
                    page: 'current'
                }).nodes();
                var last = null;
                var toPay = 0;
                var paid = 0;
                var totalFrgt = 0;
                var hire = 0;
                var advance = 0;
                var gurWgt = 0;
                var kantaWgt = 0;
                var actWgt = 0;
                var chgWgt = 0;
                var count = 0;
                //var pendingQty = 0;
                api.column(groupColumn, {
                    page: 'current'
                }).data().each(function (group, i) {

                    var val = api.row(api.row($(rows).eq(i)).index()).data();
                    if (last !== group) {
                        if (i != 0) {
                            $(rows)
                                .eq(i)
                                .before(
                                    $(
                                        "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                        {
                                            "class": "group",
                                            "data-id": group
                                        })
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": "Total"
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": + count
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 2,
                                                "text": ""
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": toPay
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": paid
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": totalFrgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": hire
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": advance
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": gurWgt
                                            }))

                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": kantaWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": actWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 1,
                                                "text": chgWgt
                                            }))
                                        .append($("<td></td>",
                                            {
                                                "colspan": 5,
                                                "text": ""
                                            }))
                                        .prop('outerHTML'));
                            toPay = 0;
                            paid = 0;
                            totalFrgt = 0;
                            hire = 0;
                            advance = 0;
                            gurWgt = 0;
                            kantaWgt = 0;
                            actWgt = 0;
                            chgWgt = 0;
                            count = 0;
                            //pendingQty = 0;
                        }
                        //the below is for column name grouping (in this case we group for destination wise)
                        $(rows)
                            .eq(i)
                            .before(
                                $(
                                    "<tr style='background-color: #ddd !important; font-weight:bold;text-align: center;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append(
                                        $("<td></td>",
                                            {
                                                "colspan": 18,
                                                "text": group
                                            })).prop('outerHTML'));
                        last = group;
                    }
                    count++;
                    toPay += +val[5];
                    paid += +val[6];
                    totalFrgt += +val[7];
                    hire += +val[8];
                    advance += +val[9];
                    gurWgt += +val[10];
                    kantaWgt += +val[11];
                    actWgt += +val[12];
                    chgWgt += +val[13];
                    //pendingQty += val[6];
                    if (i == (rows.length - 1)) {
                        //the below is for Sumary details grouping 
                        $(rows)
                            .eq(i)
                            .after(
                                $(
                                    "<tr style='background-color: #d2c1ba !important; font-weight:bold;'></tr>",
                                    {
                                        "class": "group",
                                        "data-id": group
                                    })
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": "Total"
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": + count
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 2,
                                            "text": ""
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": toPay
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": paid
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": totalFrgt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": hire
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": advance
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": gurWgt
                                        }))

                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": kantaWgt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": actWgt
                                        }))
                                    .append($("<td></td>",
                                        {
                                            "colspan": 1,
                                            "text": chgWgt
                                        }))

                                    .append($("<td></td>",
                                        {
                                            "colspan": 5,
                                            "text": ""
                                        }))

                                    .prop('outerHTML'));
                        toPay = 0;
                        paid = 0;
                        totalFrgt = 0;
                        hire = 0;
                        gurWgt = 0;
                        advance = 0;
                        kantaWgt = 0;
                        actWgt = 0;
                        chgWgt = 0;
                        count = 0;
                        //pendingQty = 0;
                    }
                });
            }

        }, this.dtOptionsAllDestination = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                exportOptions: {
                    columns: exportDestinationAllArray
                },
                title: function () {
                    var returSummary = null;
                    returSummary = "Booking HireSlip Report-All Destination " +
                        " From Date : " + moment($("#ofchsfromDate").val()).format('DD-MM-YYYY') + " -  " +
                        "To Date : " + moment($("#ofchstoDate").val()).format('DD-MM-YYYY') + " From Station : " + main + " To Station : " + $("#ofchsdestination").val();
                    return returSummary;
                }
            }],
            processing: true,
            //scroll in datatable starts
            responsive: true,
            scrollX: true,
            scrollY: 380,
            scrollCollapse: true,
            paging: false,
            info: false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };


                var toPay = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var paid = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalFrgt = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var hire = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var advance = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var gurWgt = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var kantaWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var chgWgt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);




                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(toPay);
                $(api.column(2).footer()).html(paid);
                $(api.column(3).footer()).html(totalFrgt);
                $(api.column(4).footer()).html(hire);
                $(api.column(5).footer()).html(advance);
                $(api.column(6).footer()).html(gurWgt);
                $(api.column(7).footer()).html(kantaWgt);
                $(api.column(8).footer()).html(actWgt);
                $(api.column(9).footer()).html(chgWgt);


            }
        }, this.dtOptionsMonthlyWise = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                exportOptions: {
                    columns: exportMonthlyWiseArray
                },
                title: function () {
                    var returSummary = null;
                    if (showSrc == true) {
                        returSummary = "Booking HireSlip Report-Monthly Wise Details" +
                            " From Date : " + moment($("#ofchsfromDate").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#ofchstoDate").val()).format('DD-MM-YYYY') + " From Station : " + $("#ofchssourceId").val() + " To Station : " + $("#ofchsdestination").val();
                    } else {
                        returSummary = "Booking HireSlip Report-Monthly Wise Details " +
                            " From Date : " + moment($("#ofchsfromDate").val()).format('DD-MM-YYYY') + " -  " +
                            "To Date : " + moment($("#ofchstoDate").val()).format('DD-MM-YYYY') + " From Station : " + main + " To Station : " + $("#ofchsdestination").val();
                    }
                    return returSummary;
                }
            }],
            processing: true,
            //scroll in datatable starts
            responsive: true,
            scrollX: true,
            scrollY: 380,
            scrollCollapse: true,
            paging: false,
            info: false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                var toPay = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var paid = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalFrgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var hire = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var advance = api.column(6).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var gurWgt = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var kantaWgt = api.column(8).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actWgt = api.column(9).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                var chgWgt = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html();
                $(api.column(2).footer()).html(toPay);
                $(api.column(3).footer()).html(paid);
                $(api.column(4).footer()).html(totalFrgt);
                $(api.column(5).footer()).html(hire);
                $(api.column(6).footer()).html(advance);
                $(api.column(7).footer()).html(gurWgt);
                $(api.column(8).footer()).html(kantaWgt);
                $(api.column(9).footer()).html(actWgt);
                $(api.column(10).footer()).html(chgWgt);
                $(api.column(11).footer()).html();
            }
        }, this.dtOptionsSummaryReport = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                title: function () {
                    var returSummary = null;
                    returSummary = "SUMMARY REPORT " +
                        " From Date : " + moment($("#ofchsfromDate").val()).format('DD-MM-YYYY') + " -  " +
                        "To Date : " + moment($("#ofchstoDate").val()).format('DD-MM-YYYY');
                    return returSummary;
                }
            }],
            processing: true,
            //scroll in datatable starts
            responsive: true,
            scrollX: true,
            scrollY: 380,
            scrollCollapse: true,
            paging: false,
            info: false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var toPay = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actWhgt = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(toPay);
                $(api.column(2).footer()).html(actWhgt);


            }
        }, this.dtOptionsSummaryReport2 = {
            dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="fas fa-file-excel"> Excel</i>',
                titleAttr: 'Excel',
                footer: true,
                exportOptions: {
                    columns: exportSummaryArray
                },
                title: function () {
                    var returSummary = null;
                    returSummary = "SUMMARY REPORT " +
                        " From Date : " + moment($("#ofchsfromDate").val()).format('DD-MM-YYYY') + " -  " +
                        "To Date : " + moment($("#ofchstoDate").val()).format('DD-MM-YYYY');
                    return returSummary;
                }
            }],
            processing: true,
            //scroll in datatable starts
            responsive: true,
            scrollX: true,
            scrollY: 380,
            scrollCollapse: true,
            paging: false,
            info: false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var hire = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var kanta = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var paid = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(hire);
                $(api.column(2).footer()).html(kanta);
                $(api.column(3).footer()).html(paid);

            }
        }
    }
    ngOnDestroy(): void {
        this.dtTriggerDestinationWise.unsubscribe();
        this.dtTriggerAllDestination.unsubscribe();
        this.dtTriggerMonthlyWise.unsubscribe();
        this.dtTriggerSummaryReport.unsubscribe();
        this.dtTriggerSummaryReport2.unsubscribe();


    }
    ngAfterViewInit(): void {
        this.dtTriggerDestinationWise.next();
        this.dtTriggerAllDestination.next();
        this.dtTriggerMonthlyWise.next();
        this.dtTriggerSummaryReport.next();
        this.dtTriggerSummaryReport2.next();
    }
    setYearlyIndex() {
        for (let i = 0; i < this.yearlyIndexOptions.length; i++) {
            this.yearlyIndexTA.push(this.yearlyIndexOptions[i].label);
        }
    }
    hideTable() {
        this.searchByDestinationWiseTable = false;
        this.searchByAllDestinationTable = false;
        this.searchByMonthlyWiseTable = false;
        this.searchBySummaryReportTable = false;
        this.searchByYearlyWiseTable = false;
        this.showForSingleDestWise = false;
        this.showForMultiDestWise = false;
        this.showYear = false;
    }
    clearTable() {

        $("#" + this.pageId + "searchByDestinationWiseTableId").DataTable().destroy();
        this.destinationWiseDataList = [];
        this.dtTriggerDestinationWise.next();

        $("#" + this.pageId + "searchByAllDestinationTableId").DataTable().destroy();
        this.allDestinationDataList = [];
        this.dtTriggerAllDestination.next();

        $("#" + this.pageId + "searchByMonthlyWiseTableId").DataTable().destroy();
        this.monthlyWiseDataList = [];
        this.dtTriggerMonthlyWise.next();

        $("#" + this.pageId + "searchBySummaryReportTableId").DataTable().destroy();
        this.summaryReportDataList = [];
        this.dtTriggerSummaryReport.next();

        $("#" + this.pageId + "searchBySummaryReportTable2").DataTable().destroy();
        this.summaryReport2DataList = [];
        this.dtTriggerSummaryReport2.next();

    }
    searchByMode(searchBy: string) {

        if (searchBy === 'destinationWise') {
            this.showFromDate = true;
            this.showToDate = true;

            if (this.showSource == true) {
                this.sourceView = true;
            } else {
                this.sourceView = false;
            }
            this.hideTable();
            this.clearTable();
            this.sourceView = false;
            this.sourceAdminView = false;
            this.searchByDestinationWise = true;
            this.searchByMonthlyWise = false;
            this.searchByYearlyWise = false;
            this.showForSingleDestWise = true;
            this.showForMultiDestWise = false;

            this.searchByDestinationWiseTable = true;
        } else if (searchBy === 'allDestination') {
            this.showFromDate = true;
            this.showToDate = true;
            if (this.showSource == true) {
                this.sourceView = true;
            } else {
                this.sourceView = false;
            }
            this.sourceView = false;
            this.sourceAdminView = false;
            this.searchByDestinationWise = true;
            this.searchByMonthlyWise = false;
            this.searchByYearlyWise = false;
            this.hideTable();
            this.clearTable();

            this.searchByAllDestinationTable = true;

        } else if (searchBy === 'monthlyWise') {
            this.showFromDate = false;
            this.showToDate = false;
            if (this.isMainSrcMultipleDelv == true) {
                this.sourceView = false;
                this.sourceAdminView = true;
            } else {
                this.sourceView = false;
                this.sourceAdminView = false;
            }
            this.searchByDestinationWise = true;
            this.searchByMonthlyWise = false;
            this.searchByYearlyWise = true;
            this.hideTable();
            this.clearTable();

            this.searchByMonthlyWiseTable = true;
            this.showYear = true;


        } else if (searchBy === 'summaryReport') {
            this.showFromDate = true;
            this.showToDate = true;
            if (this.showSource == true) {
                this.sourceView = true;
            } else {
                this.sourceView = false;
            }
            this.sourceView = false;
            this.sourceAdminView = false;
            this.searchByDestinationWise = false;
            this.searchByMonthlyWise = false;
            this.searchByYearlyWise = false;

            this.hideTable();
            this.clearTable();

            this.searchBySummaryReportTable = true;


        } else if (searchBy === 'multiDeliveryWise') {
            this.showFromDate = true;
            this.showToDate = true;
            if (this.showSource == true) {
                this.sourceView = true;
            } else {
                this.sourceView = false;
            }
            this.sourceAdminView = false;
            this.searchByDestinationWise = true;
            this.searchByMonthlyWise = false;
            this.searchByYearlyWise = false;
            this.hideTable();
            this.clearTable();
            this.searchByDestinationWiseTable = true;
            this.showForSingleDestWise = false;
            this.showForMultiDestWise = true;
        } else {
            this.showFromDate = false;
            this.showToDate = false;
            if (this.showSource == true) {
                this.sourceView = true;
            } else {
                this.sourceView = false;
            }
            this.hideTable();
            this.clearTable();

        }
    }
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }
    //For Destination Listener
    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
    }
    //Click Listener For Source main Station
    clickListnerForMainBookingSource(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelMainBookingSource = e.item;
        $("#" + this.pageId + "mainBookingSourceId").val(this.modelMainBookingSource.mainBookStations);
    }

    ///Click Listener For Source subStation
    sourceListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSource = e.item;
        $("#" + this.pageId + "sourceId").val(this.modelSource.subStations);
    }

    ///
    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        ///Mode Added
        this.lrDtoDestination.mode = "desttrsp";
    }

    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(

            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        this.destinationDtoForAll.destination = "ALL";
                        this.destinationTA.push(this.destinationDtoForAll);

                        this.destinationList = [];
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                            this.destinationList.push(this.destinationOptions[i].destination);
                        }

                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log('done');

    };

    getMainBookingSourceDataDetails() {
        this.agentDtoSourceStation = new AgentDetailsDto();
        this.agentDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
    }
    getMainBookingSourceDetails() {
        this.getMainBookingSourceDataDetails();
        this.showSpinnerForAction = true;
        this.masterReadService.getMainBookingStationsDetails(this.agentDtoSourceStation).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.mainBookingSourceTA = [];
                    this.agentDtoOptionSourceStation = [];
                    this.agentDtoOptionSourceStation = response;
                    this.agentDtoOptionSourceStationAll.mainBookStations = "ALL";
                    this.mainBookingSourceTA.push(this.agentDtoOptionSourceStationAll);
                    for (let i = 0; i < this.agentDtoOptionSourceStation.length; i++) {
                        this.mainBookingSourceTA.push(this.agentDtoOptionSourceStation[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Main Booking Source Details", "info");
            },
            () => console.log('done');

    };
    validateSearchBtn() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedSource = $("#" + this.pageId + "sourceId").val();
        this.selectedSourceMainBkg = $("#" + this.pageId + "mainBookingSourceId").val();
        this.selectedViewMode = $("#" + this.pageId + "searchBy").val();
        this.selectedFinancialYear = $("#" + this.pageId + "yearlyIndex").val();

        this.hireslipdtoForSearchBtn = new HireSlipDto();
        if (this.selectedFromDate != null && this.selectedFromDate != '' && this.selectedToDate != null && this.selectedToDate != '') {
            this.hireslipdtoForSearchBtn.fromDate = this.selectedFromDate;
            this.hireslipdtoForSearchBtn.toDate = this.selectedToDate;
        }
        this.srcList = this.userDataDtoReturnSession.stationList;
        this.sentSrcList = [];
        this.sentSrcList = this.srcList;
        this.hireslipdtoForSearchBtn.list = this.sentSrcList;
        this.hireslipdtoForSearchBtn.companyid = this.userDataDtoReturnSession.companyId;

        if (this.selectedViewMode == 'destinationWise' || this.selectedViewMode == 'multiDeliveryWise') {
            if (this.isMainSrcMultipleDelv == true) {
                this.hireslipdtoForSearchBtn.mainBookingStation = this.selectedSourceMainBkg;
            } else {
                this.hireslipdtoForSearchBtn.mainBookingStation = this.userDataDtoReturnSession.mainAdminStation;
            }
            this.destinationWiseMandatoryField();
        } else if (this.selectedViewMode == 'allDestination') {
            this.allDestinationWiseMandatoryField();
        } else if (this.selectedViewMode == 'monthlyWise') {
            this.monthlyWiseMandatoryField();
        } else if (this.selectedViewMode == 'summaryReport') {
            this.summaryReportMandatoryField();
        }

    }

    destinationWiseMandatoryField() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedViewMode = $("#" + this.pageId + "searchBy").val();
        if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '' || this.selectedDestination == null || this.selectedDestination == '') {
            swal({
                title: "Mandatory Field",
                text: "From date,to date and destination are mandatory fields",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.hireslipdtoForSearchBtn.toStation = this.selectedDestination;
            if (this.selectedViewMode == 'destinationWise') {
                this.searchMethodForDestinationWise();
            } else if (this.selectedViewMode == 'multiDeliveryWise') {
                this.searchMethodForMultiDestinationWise();
            }

        }
    }

    allDestinationWiseMandatoryField() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();

        if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
            swal({
                title: "Mandatory Field",
                text: "From date and to date are mandatory fields",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.hireslipdtoForSearchBtn.toStation = this.selectedDestination;
            this.searchMethodForAllDestinationWise();

        }
    }
    summaryReportMandatoryField() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();

        if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
            swal({
                title: "Mandatory Field",
                text: "From date and to date are mandatory fields",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.hireslipdtoForSearchBtn.toStation = this.selectedDestination;
            this.searchMethodForSummaryReportWise();
            this.searchMethodForSummaryReport2Wise();

        }
    }
    monthlyWiseMandatoryField() {
        this.selectedSource = $("#" + this.pageId + "sourceId").val();
        this.selectedSourceMainBkg = $("#" + this.pageId + "mainBookingSourceId").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedFinancialYear = $("#" + this.pageId + "yearlyIndex").val();

        if (this.showSource == true) {
            if (this.selectedSource == null || this.selectedSource == '' || this.selectedSource == undefined || this.selectedDestination == null || this.selectedDestination == '' || this.selectedDestination == undefined || this.selectedFinancialYear == null || this.selectedFinancialYear == '' || this.selectedFinancialYear == undefined) {
                swal({
                    title: "Mandatory Field",
                    text: "Financial year ,Source and To station are mandatory fields",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });

            } else {

                this.hireslipdtoForSearchBtn.fromStation = this.selectedSource;
                this.hireslipdtoForSearchBtn.toStation = this.selectedDestination;
                this.hireslipdtoForSearchBtn.financialYear = this.selectedFinancialYear;

                if (this.selectedSource != null && this.selectedSource != '' && this.selectedSource == 'ALL') {
                    this.monthlySrcList = [];
                    this.monthlySrcList = this.destinationList;
                    this.hireslipdtoForSearchBtn.listOfSource = this.monthlySrcList;
                } else {
                    this.monthlySrcList = [];
                    this.monthlySrcList.push(this.selectedSource);
                    this.hireslipdtoForSearchBtn.listOfSource = this.monthlySrcList;
                }

                if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != 'ALL') {
                    this.searchMethodForMonthlyWise();

                } else {
                    swal({
                        title: "Warning",
                        text: "All Destination Search Option Not Available",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }
        } else {

            if (this.selectedDestination == null || this.selectedDestination == '' || this.selectedDestination == undefined || this.selectedFinancialYear == null || this.selectedFinancialYear == '' || this.selectedFinancialYear == undefined) {
                swal({
                    title: "Mandatory Field",
                    text: "Financial year and To station are mandatory fields",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });

            } else {

                this.hireslipdtoForSearchBtn.toStation = this.selectedDestination;
                this.hireslipdtoForSearchBtn.financialYear = this.selectedFinancialYear;
                this.monthlySrcList = [];
                this.monthlySrcList.push(this.mainStn);
                this.hireslipdtoForSearchBtn.listOfSource = this.monthlySrcList;

                if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != 'ALL') {
                    this.searchMethodForMonthlyWise();

                } else {
                    swal({
                        title: "Warning",
                        text: "All Destination Search Option Not Available",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }


        }

    }


    searchMethodForDestinationWise = () => {
        this.showSpinnerForAction = true;
        console.log(this.hireslipdtoForSearchBtn);
        this.reportService.getOfficeHireslipRptDestinationwise(this.hireslipdtoForSearchBtn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "searchByDestinationWiseTableId").DataTable().destroy();
                this.destinationWiseDataList = [];

                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.destinationWiseDataList = response;
                }

                this.dtTriggerDestinationWise.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Wise  Report ", "info");
            }, () => console.log('done');
    };

    searchMethodForMultiDestinationWise = () => {
        this.showSpinnerForAction = true;
        console.log(this.hireslipdtoForSearchBtn);
        this.reportService.getOfficeHireslipRptMultiDestinationwise(this.hireslipdtoForSearchBtn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "searchByDestinationWiseTableId").DataTable().destroy();
                this.destinationWiseDataList = [];

                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.destinationWiseDataList = response;
                }

                this.dtTriggerDestinationWise.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting therMulti Destination Wise Report ", "info");
            }, () => console.log('done');
    };

    searchMethodForAllDestinationWise = () => {
        this.showSpinnerForAction = true;
        console.log(this.hireslipdtoForSearchBtn);
        this.reportService.getBookingHireSlipRptAllDestination(this.hireslipdtoForSearchBtn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "searchByAllDestinationTableId").DataTable().destroy();
                this.allDestinationDataList = [];

                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.allDestinationDataList = response;
                }

                this.dtTriggerAllDestination.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the All Destination Wise Details  Report ", "info");
            }, () => console.log('done');
    };

    searchMethodForMonthlyWise = () => {
        this.showSpinnerForAction = true;
        console.log(this.hireslipdtoForSearchBtn);
        this.reportService.getBookingHireSlipRptMonthlyWise(this.hireslipdtoForSearchBtn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "searchByMonthlyWiseTableId").DataTable().destroy();
                this.monthlyWiseDataList = [];
                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.monthlyWiseDataLoop = [];
                    $("#" + this.pageId + "searchByMonthlyWiseTableId").DataTable().destroy();
                    for (let key in response) {
                        this.monthlyWiseDataLoop = response[key];
                        console.log(key);
                        console.log(this.monthlyWiseDataLoop);
                        for (let i = 0; i < this.monthlyWiseDataLoop.length; i++) {
                            this.monthlyWiseDataList.push(this.monthlyWiseDataLoop[i]);
                        }


                    }
                    console.log(this.monthlyWiseDataList);
                }
                this.dtTriggerMonthlyWise.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the  Monthly Wise Details  Report ", "info");
            }, () => console.log('done');
    };
    searchMethodForSummaryReportWise = () => {
        this.showSpinnerForAction = true;
        console.log(this.hireslipdtoForSearchBtn);
        this.reportService.getBookingHireslipRptSummaryWiseLR(this.hireslipdtoForSearchBtn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "searchBySummaryReportTableId").DataTable().destroy();
                this.summaryReportDataList = [];


                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.summaryReportDataList = response;
                }

                this.dtTriggerSummaryReport.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the  Summary Wise Details  Report ", "info");
            }, () => console.log('done');
    };
    searchMethodForSummaryReport2Wise = () => {
        this.showSpinnerForAction = true;
        console.log(this.hireslipdtoForSearchBtn);
        this.reportService.getBookingHireslipRptSummaryWiseHire(this.hireslipdtoForSearchBtn).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "searchBySummaryReportTable2").DataTable().destroy();
                this.summaryReport2DataList = [];

                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.summaryReport2DataList = response;
                }

                this.dtTriggerSummaryReport2.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the  Summary Wise 2 Details  Report ", "info");
            }, () => console.log('done');
    };

    pritnDestinationWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");


        if (this.destinationWiseDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];

            this.cashMemoDtoForCustomPrintListColumnNames = ["Destination", "Invoice No", "Date", "Truck No", "Truck Lgt", "To Pay", "Paid", "Freight", "Hire", "Advance", "Gur Wt", "KantaWt", "Act Wgt", "Chg Wgt", "Broker", "Loaded By", "Choudhary Name"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandToPay = 0;
            this.grandPaid = 0;
            this.grandTotalAmt = 0;
            this.grandTotalhire = 0;
            this.grandAdvance = 0;
            this.grandGurWeight = 0;
            this.grandKantaWeight = 0;
            this.grandActWt = 0;
            this.grandChWt = 0;


            for (let i = 0; i < this.destinationWiseDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.destinationWiseDataList[i].toStation, this.destinationWiseDataList[i].hireslipnumber, this.destinationWiseDataList[i].hireSlipDateStr, this.destinationWiseDataList[i].vehicleNumber, this.destinationWiseDataList[i].trkLgth, this.destinationWiseDataList[i].toPay, this.destinationWiseDataList[i].paid, this.destinationWiseDataList[i].totalAmt, this.destinationWiseDataList[i].totalhire, this.destinationWiseDataList[i].advance, this.destinationWiseDataList[i].gurWeight, this.destinationWiseDataList[i].kantaWeight, this.destinationWiseDataList[i].actWeight, this.destinationWiseDataList[i].chargedWt, this.destinationWiseDataList[i].suppliername, this.destinationWiseDataList[i].loadedby, this.destinationWiseDataList[i].loaderHead];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);


                this.grandToPay = +this.grandToPay + +this.destinationWiseDataList[i].toPay;
                this.grandPaid = +this.grandPaid + +this.destinationWiseDataList[i].paid;
                this.grandTotalAmt = +this.grandTotalAmt + +this.destinationWiseDataList[i].totalAmt;
                this.grandTotalhire = +this.grandTotalhire + +this.destinationWiseDataList[i].totalhire;
                this.grandAdvance = +this.grandAdvance + +this.destinationWiseDataList[i].advance;
                this.grandGurWeight = +this.grandGurWeight + +this.destinationWiseDataList[i].gurWeight;
                this.grandKantaWeight = +this.grandKantaWeight + +this.destinationWiseDataList[i].kantaWeight;
                this.grandActWt = +this.grandActWt + +this.destinationWiseDataList[i].actWeight;
                this.grandChWt = +this.grandChWt + +this.destinationWiseDataList[i].chargedWt;


                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.destinationWiseDataList.length, "", "", "", "", this.grandToPay, this.grandPaid, this.grandTotalAmt, this.grandTotalhire, this.grandAdvance, this.grandGurWeight, this.grandKantaWeight, this.grandActWt, this.grandChWt, "", "", ""];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Station"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.mainStn];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Booking HireSlip Details Report ");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    pritnMultiDestinationWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");


        if (this.destinationWiseDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];

            this.cashMemoDtoForCustomPrintListColumnNames = ["Destination", "Invoice No", "Date", "Truck No", "Truck Lgt", "To Pay", "Paid", "Freight", "Hire", "Advance", "Gur Wt", "KantaWt", "Act Wgt", "Chg Wgt", "Broker", "Loaded By", "Choudhary Name"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandToPay = 0;
            this.grandPaid = 0;
            this.grandTotalAmt = 0;
            this.grandTotalhire = 0;
            this.grandAdvance = 0;
            this.grandGurWeight = 0;
            this.grandKantaWeight = 0;
            this.grandActWt = 0;
            this.grandChWt = 0;


            for (let i = 0; i < this.destinationWiseDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.destinationWiseDataList[i].toStation, this.destinationWiseDataList[i].hireslipnumber, this.destinationWiseDataList[i].hireSlipDateStr, this.destinationWiseDataList[i].vehicleNumber, this.destinationWiseDataList[i].trkLgth, this.destinationWiseDataList[i].toPay, this.destinationWiseDataList[i].paid, this.destinationWiseDataList[i].totalAmt, this.destinationWiseDataList[i].totalhire, this.destinationWiseDataList[i].advance, this.destinationWiseDataList[i].gurWeight, this.destinationWiseDataList[i].kantaWeight, this.destinationWiseDataList[i].actWeight, this.destinationWiseDataList[i].chargedWt, this.destinationWiseDataList[i].suppliername, this.destinationWiseDataList[i].loadedby, this.destinationWiseDataList[i].loaderHead];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);


                this.grandToPay = +this.grandToPay + +this.destinationWiseDataList[i].toPay;
                this.grandPaid = +this.grandPaid + +this.destinationWiseDataList[i].paid;
                this.grandTotalAmt = +this.grandTotalAmt + +this.destinationWiseDataList[i].totalAmt;
                this.grandTotalhire = +this.grandTotalhire + +this.destinationWiseDataList[i].totalhire;
                this.grandAdvance = +this.grandAdvance + +this.destinationWiseDataList[i].advance;
                this.grandGurWeight = +this.grandGurWeight + +this.destinationWiseDataList[i].gurWeight;
                this.grandKantaWeight = +this.grandKantaWeight + +this.destinationWiseDataList[i].kantaWeight;
                this.grandActWt = +this.grandActWt + +this.destinationWiseDataList[i].actWeight;
                this.grandChWt = +this.grandChWt + +this.destinationWiseDataList[i].chargedWt;


                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.destinationWiseDataList.length, "", "", "", "", this.grandToPay, this.grandPaid, this.grandTotalAmt, this.grandTotalhire, this.grandAdvance, this.grandGurWeight, this.grandKantaWeight, this.grandActWt, this.grandChWt, "", "", ""];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }


            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Station"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.mainStn];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Booking HireSlip Details Report ");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    pritnAllDestinationWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        console.log('alldest');
        if (this.allDestinationDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];



            this.cashMemoDtoForCustomPrintListColumnNames = ["Destination", "To Pay", "Paid", "Freight", "Hire", "Advance", "Gur Wt", "KantaWt", "Act Wgt", "Chg Wgt"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandToPay = 0;
            this.grandPaid = 0;
            this.grandTotalAmt = 0;
            this.grandTotalhire = 0;
            this.grandAdvance = 0;
            this.grandGurWeight = 0;
            this.grandKantaWeight = 0;
            this.grandActWt = 0;
            this.grandChWt = 0;


            for (let i = 0; i < this.allDestinationDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.allDestinationDataList[i].toStation, this.allDestinationDataList[i].toPay, this.allDestinationDataList[i].paid, this.allDestinationDataList[i].totalAmt, this.allDestinationDataList[i].totalhire, this.allDestinationDataList[i].advance, this.allDestinationDataList[i].gurWeight, this.allDestinationDataList[i].kantaWeight, this.allDestinationDataList[i].actWeight, this.allDestinationDataList[i].chargedWt];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);


                this.grandToPay = +this.grandToPay + +this.allDestinationDataList[i].toPay;
                this.grandPaid = +this.grandPaid + +this.allDestinationDataList[i].paid;
                this.grandTotalAmt = +this.grandTotalAmt + +this.allDestinationDataList[i].totalAmt;
                this.grandTotalhire = +this.grandTotalhire + +this.allDestinationDataList[i].totalhire;
                this.grandAdvance = +this.grandAdvance + +this.allDestinationDataList[i].advance;
                this.grandGurWeight = +this.grandGurWeight + +this.allDestinationDataList[i].gurWeight;
                this.grandKantaWeight = +this.grandKantaWeight + +this.allDestinationDataList[i].kantaWeight;
                this.grandActWt = +this.grandActWt + +this.allDestinationDataList[i].actWeight;
                this.grandChWt = +this.grandChWt + +this.allDestinationDataList[i].chargedWt;

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.allDestinationDataList.length, this.grandToPay, this.grandPaid, this.grandTotalAmt, this.grandTotalhire, this.grandAdvance, this.grandGurWeight, this.grandKantaWeight, this.grandActWt, this.grandChWt];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }


            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Station", "To Station"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.mainStn, this.selectedDestination];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Booking HireSlip Report-All Destination");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    pritnSummary1Wise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();

        if (this.summaryReportDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];


            this.cashMemoDtoForCustomPrintListColumnNames = ["Destination", "To Pay", "Actual Weight"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [40, 30, 30];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandActWt = 0;
            this.grandToPay = 0;


            for (let i = 0; i < this.summaryReportDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.summaryReportDataList[i].fromStation, this.summaryReportDataList[i].toPay, this.summaryReportDataList[i].actWeight];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);


                this.grandToPay = +this.grandToPay + +this.summaryReportDataList[i].toPay;
                this.grandActWt = +this.grandActWt + +this.summaryReportDataList[i].actWeight;

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.summaryReportDataList.length, this.grandToPay, this.grandActWt];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];



            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            //this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            // localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "SUMMARY REPORT");
            this.viewCustomPrintV2 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV2) {
                    this.viewCustomPrintV2 = false;
                    localStorage.clear();
                }
            });
        }
    }

    pritnSummary2Wise() {
        console.log('ss');
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();

        if (this.summaryReport2DataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];

            this.cashMemoDtoForCustomPrintListColumnNames = ["DESTINATION", "Hire", "KANTA WT", "PAID"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [40, 20, 20, 20];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandTotalhire = 0;
            this.grandKantaWeight = 0;
            this.grandPaid = 0;


            for (let i = 0; i < this.summaryReport2DataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.summaryReport2DataList[i].toStation, this.summaryReport2DataList[i].totalhire, this.summaryReport2DataList[i].kantaWeight, this.summaryReport2DataList[i].paid];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);



                this.grandPaid = +this.grandPaid + +this.summaryReport2DataList[i].paid;
                this.grandTotalhire = +this.grandTotalhire + +this.summaryReport2DataList[i].totalhire;
                this.grandKantaWeight = +this.grandKantaWeight + +this.summaryReport2DataList[i].kantaWeight;


                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.summaryReport2DataList.length, this.grandTotalhire, this.grandKantaWeight, this.grandPaid];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];





            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            //   this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            // localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "SUMMARY REPORT");
            this.viewCustomPrintV2 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV2) {
                    this.viewCustomPrintV2 = false;
                    localStorage.clear();
                }
            });
        }
    }


    pritnMonthlyWise() {
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedSource = $("#" + this.pageId + "sourceId").val();
        let sourcePrint = null;
        if (this.showSource == true) {
            sourcePrint = this.selectedSource;
        } else {
            sourcePrint = this.mainStn;
        }



        if (this.monthlyWiseDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];


            this.cashMemoDtoForCustomPrintListColumnNames = ["Source", "Month", "To Pay", "Paid", "Freight", "Hire", "Advance", "Gur Wt", "KantaWt", "Act Wgt", "Chg Wgt"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 5, 5, 10, 10, 10, 10, 10, 10, 10];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];

            this.grandActWt = 0;
            this.grandChWt = 0;
            this.grandToPay = 0;
            this.grandPaid = 0;
            this.grandTotalAmt = 0;
            this.grandTotalhire = 0;
            this.grandAdvance = 0;
            this.grandGurWeight = 0;
            this.grandKantaWeight = 0;
            this.grandActWt = 0;
            this.grandChWt = 0;



            for (let i = 0; i < this.monthlyWiseDataList.length; i++) {
                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintListColumnValues = [this.monthlyWiseDataList[i].fromStation, this.monthlyWiseDataList[i].monthName, (this.monthlyWiseDataList[i].toPay == null ? 0 : this.monthlyWiseDataList[i].toPay), (this.monthlyWiseDataList[i].paid == null ? 0 : this.monthlyWiseDataList[i].paid), (this.monthlyWiseDataList[i].totalAmt == null ? 0 : this.monthlyWiseDataList[i].totalAmt), (this.monthlyWiseDataList[i].totalhire == null ? 0 : this.monthlyWiseDataList[i].totalhire), (this.monthlyWiseDataList[i].advance == null ? 0 : this.monthlyWiseDataList[i].advance), (this.monthlyWiseDataList[i].gurWeight == null ? 0 : this.monthlyWiseDataList[i].gurWeight), (this.monthlyWiseDataList[i].kantaWeight == null ? 0 : this.monthlyWiseDataList[i].kantaWeight), (this.monthlyWiseDataList[i].actWeight == null ? 0 : this.monthlyWiseDataList[i].actWeight), (this.monthlyWiseDataList[i].chargedWt == null ? 0 : this.monthlyWiseDataList[i].chargedWt)];

                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);


                this.grandToPay = +this.grandToPay + +this.monthlyWiseDataList[i].toPay;
                this.grandPaid = +this.grandPaid + +this.monthlyWiseDataList[i].paid;
                this.grandTotalAmt = +this.grandTotalAmt + +this.monthlyWiseDataList[i].totalAmt;
                this.grandTotalhire = +this.grandTotalhire + +this.monthlyWiseDataList[i].totalhire;
                this.grandAdvance = +this.grandAdvance + +this.monthlyWiseDataList[i].advance;
                this.grandGurWeight = +this.grandGurWeight + +this.monthlyWiseDataList[i].gurWeight;
                this.grandKantaWeight = +this.grandKantaWeight + +this.monthlyWiseDataList[i].kantaWeight;
                this.grandActWt = +this.grandActWt + +this.monthlyWiseDataList[i].actWeight;
                this.grandChWt = +this.grandChWt + +this.monthlyWiseDataList[i].chargedWt;

                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.monthlyWiseDataList.length, "", this.grandToPay, this.grandPaid, this.grandTotalAmt, this.grandTotalhire, this.grandAdvance, this.grandGurWeight, this.grandKantaWeight, this.grandActWt, this.grandChWt];
            }
            //heading logics For Date

            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            //  this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            //  this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];


            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["From Station", "To Station"];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [sourcePrint, this.selectedDestination];


            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Booking HireSlip Report-Monthly Wise Details");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }

    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        inp._elementRef.nativeElement.focus();
    }

    clearAll() {
        this.hideTable();
        this.clearTable();
        this.selectedViewMode = '';
        $("#" + this.pageId + "searchBy").val('destinationWise');
        this.hireslipdtoForSearchBtn = new HireSlipDto();
        this.selectedSource = '';
        $("#" + this.pageId + "sourceId").val('');
        this.selectedSourceMainBkg = '';
        $("#" + this.pageId + "mainBookingSourceId").val('');
        this.selectedFromDate = '';
        $("#" + this.pageId + "fromDate").val('');
        this.selectedToDate = '';
        $("#" + this.pageId + "toDate").val('');
        this.selectedDestination = '';
        $("#" + this.pageId + "destination").val('');
        this.selectedFinancialYear = '';
        $("#" + this.pageId + "yearlyIndex").val('');


        this.grandActWt = 0;
        this.grandChWt = 0;
        this.grandToPay = 0;
        this.grandPaid = 0;
        this.grandTotalAmt = 0;
        this.grandTotalhire = 0;
        this.grandAdvance = 0;
        this.grandGurWeight = 0;
        this.grandKantaWeight = 0;
        this.grandActWt = 0;
        this.grandChWt = 0;
        this.monthlySrcList = [];
        this.monthlyWiseDataLoop = [];
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrintList = [];
        this.cashMemoDtoForCustomPrintListColumnNames = [];
        this.cashMemoDtoForCustomPrintListColumnWidths = [];
        this.cashMemoDtoForCustomPrintDataList = [];
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [];
        this.cashMemoDtoForCustomPrintDataSummaryList = [];
        this.viewCustomPrintV1 = false;
        this.viewCustomPrintV2 = false;
        this.cashMemoDtoForCustomPrintListHeadingV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
        this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];
        this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [];

        this.showSpinnerForAction = false;

        this.sourceView = false;
        this.sourceAdminView = false;
        this.searchByDestinationWise = true;
        this.searchByDestinationWiseTable = true;
        this.searchByMonthlyWise = false;
        this.searchByYearlyWise = false;
        this.showForSingleDestWise = true;
        this.showForMultiDestWise = false;
        this.showFromDate = true;
        this.showToDate = true;
        this.selectTodayDate();
    }

    open2(content2, destinationWiseData) {
        this.setInvoiceNo = null;
        this.setInvoiceNo = destinationWiseData.hireslipnumber;
        this.modalService.open(content2, { centered: true }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    getTruckTypeDetails() {
        this.truckTypeDataDto = new TruckDataDto();
        this.truckTypeDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.truckTypeDataDto.mode = "active";
        this.masterReadService.getTruckTypeCommonDetails(this.truckTypeDataDto).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.truckTypeOptions = [];
                    this.truckTypeTA = [];
                } else {
                    this.truckTypeOptions = [];
                    this.truckTypeTA = [];
                    this.truckTypeOptions = response;
                    for (let i = 0; i < this.truckTypeOptions.length; i++) {
                        this.truckTypeTA.push(this.truckTypeOptions[i]);
                        this.hmTruckType.set(this.truckTypeOptions[i].id, i + 1);
                        this.hmTruckTypeDto.set(this.truckTypeOptions[i].id, this.truckTypeOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Error While Getting Truck Type Details", "warning");
            }, () => console.log('done');
    };

    clickListnerForTruckType(e: NgbTypeaheadSelectItemEvent) {
        this.modelTruckType = e.item;
        $("#" + this.pageId + "truckType").val(this.modelTruckType.truckType);
        $("#" + this.pageId + "truckType").focusout();
        this.selectedTruckTypeId = null;
        this.selectedTruckTypeId = this.modelTruckType.id;
    }

    updateTruckTypeBtn() {
        this.selectedTruckType = null;
        this.truckTypeDataDto = new TruckDataDto();
        this.selectedTruckType = $("#" + this.pageId + "truckType").val();

        if ((this.selectedTruckType == null) || (this.selectedTruckType == undefined) || (this.selectedTruckType == '')) {
            swal({
                title: "Mandatory Fields",
                text: "Please select the Truck Type to proceed!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                $("#ofchstruckType").focus();
            });
        } else {
            swal({
                title: "Update Truck Type",
                text: "Are you sure want to Update the Truck Type '" + this.selectedTruckType + "' for the Invoice No: '" + this.setInvoiceNo + "'? ",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.truckTypeDataDto.hireslipNumber = this.setInvoiceNo;
                    this.truckTypeDataDto.truckTypeId = this.selectedTruckTypeId;
                    this.truckTypeDataDto.userId = this.userDataDtoReturnSession.userName;
                    this.updateTruckTypeMethod();
                }
            });
        }
    }

    updateTruckTypeMethod() {
        this.showSpinnerForAction = true;
        this.stockService.updateHireslipTruckType(this.truckTypeDataDto).subscribe(
            (response) => {
                if (response.status == 'Success') {
                    this.setInvoiceNo = null;
                    this.selectedTruckType = null;
                    $("#" + this.pageId + "truckType").val('');
                    this.modalService.dismissAll();
                    swal({
                        title: "Success",
                        text: "Truck Type updated successfully!",
                        icon: "info",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        this.validateSearchBtn();
                    });

                } else if (response.status == 'Failed') {
                    swal("Failed", "Failed to update the Truck Type!", "error");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server problem occurred while updating the Truck Type", "error");
            }, () => console.log('done');
    }

}
