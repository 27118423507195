import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule } from '@angular/core';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from 'src/app/dataService/master-service';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { FormControl } from '@angular/forms';
import { DataTableDirective } from "angular-datatables";
import { SupplierDetailsDto } from 'src/app/dto/SupplierDetails-dto';
import { map, startWith } from "rxjs/operators";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, Subscription, Observable, merge } from "rxjs";
import { Router } from "@angular/router";
import swal from "sweetalert";
import { DriverdetailsDto } from 'src/app/dto/Driverdetails-dto';
import { TruckDataDto } from 'src/app/dto/TruckData-dto';
import { LRDto } from 'src/app/dto/LR-dto';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { LrService } from 'src/app/dataService/lr-service';
import { HireslipService } from 'src/app/dataService/hireslip-service';
import * as moment from "moment";

@Component({
    selector: 'app-invoice-lr-edit',
    templateUrl: './invoice-lr-edit.component.html',
    styleUrls: ['./invoice-lr-edit.component.css']
})
export class InvoiceLrEditComponent implements OnInit {

    editView = true;
    invoiceView = false;
    getDataFrmServiceFrTable: any;
    lrDetailsDataList: any;
    onDestroyUnsubscribtionLrDetails: Subscription;
    loadingIndicator = true;
    hoveredDate: NgbDateStruct;
    closeResult: string;
    control = new FormControl();
    pageId = "ilec";

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLrDetails: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLrDetails: any;

    ///For Supplier Name
    supplierDetailsDto: SupplierDetailsDto = new SupplierDetailsDto();
    supplierOptions: SupplierDetailsDto[];
    public modelSupplierName: any;
    supplierNameTA: Array<SupplierDetailsDto> = [];
    focusSupplierNameTA$ = new Subject<string>();
    searchSupplierName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusSupplierNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ""
                    ? this.supplierNameTA
                    : this.supplierNameTA.filter(
                        (v) =>
                            v.supplierName.toLowerCase().indexOf(term.toLowerCase()) > -1
                    )
                ).slice(0, 200)
            )
        );
    };
    formatterSupplierName = (x: { supplierName: string }) => x.supplierName;

    //For Driver Name

    driverDetailsDto: DriverdetailsDto = new DriverdetailsDto();
    driverNameOptions: DriverdetailsDto[];
    public modelDriverName: any;
    driverNameTA: Array<DriverdetailsDto> = [];
    focusDriverNameTA$ = new Subject<string>();
    searchDriverName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDriverNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.driverNameTA
                : this.driverNameTA.filter(v => v.driverName && v.licenceNo.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    formatterDriverName = (x: { driverName: string }) => x.driverName;

    truckDataDtoForTruckNo: TruckDataDto = new TruckDataDto();
    truckNumberOptions: TruckDataDto[];
    public modelTruckNumber: any;
    truckNumberTA: Array<TruckDataDto> = [];
    focusTruckNumber$ = new Subject<string>();
    searchTruckNumber = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusTruckNumber$;

        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ""
                    ? this.truckNumberTA
                    : this.truckNumberTA.filter(
                        (v) =>
                            v.truckNumber.toLowerCase().indexOf(term.toLowerCase()) > -1
                    )
                ).slice(0, 200)
            )
        );
    };
    formatterTruckNumber = (x: { truckNumber: string }) => x.truckNumber;

    //For Loader Name
    staffDetailsLrDto: LRDto = new LRDto();
    loaderStaffOption: LRDto[];
    public modelLoaderStaffName: any;
    loaderStaffNameTA: Array<LRDto> = [];
    focusLoaderStaffNameTA$ = new Subject<string>();
    searchLoaderStaffName = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusLoaderStaffNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.loaderStaffNameTA
                : this.loaderStaffNameTA.filter(v => v.staffName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterLoaderStaffName = (x: { staffName: string }) => x.staffName;

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    address: any = null;
    showSpinnerForAction = false;
    onSelectEntryDriverId: any;
    hireSlipDto: HireSlipDto = new HireSlipDto();
    enteredInvoiceNumber: any;
    hireSlipDetailLists: any;
    vehicleNoSearch: any;
    hireSlipDtoForUpdate: HireSlipDto = new HireSlipDto();
    enteredGurWt: any;
    enteredKantaWt: any;
    enteredLoaderStaffName: any;
    enteredTruckNumberSearch: any;
    enteredSupplierName: any;
    enteredDriverName: any;
    enteredLicenceNumber: any;
    enteredTotalHire: any;
    enteredAdvance: any;
    enteredBalance: any;
    enteredDepatureDate: any;
    enteredDepartureTime: any;
    enteredScheduledDate: any;
    enteredScheduledTime: any;
    enteredTruckNumber: any;
    hireSlipDtoRet: HireSlipDto = new HireSlipDto();
    departureTimeSplit: any[];
    departureDate: any;
    departureTime: any;
    scheduledDate: any;
    arrivalTime: any;
    // driverId: 0;
    hireSlipDtoDriver: HireSlipDto = new HireSlipDto();
    userValueSupplierDetailsDto: SupplierDetailsDto = new SupplierDetailsDto();
    userValueTruckDto: TruckDataDto = new TruckDataDto();
    stockStatus: any;
    isReadOnlyForLoaderName: boolean = false;
    isReadOnlyForSearchTruckNo: boolean = false;
    isReadOnlyForSelectedTruckNumber: boolean = false;
    isReadOnlyForSupplierName: boolean = false;
    isReadOnlyForDriverName: boolean = false;
    isReadOnlyForLicenceNumber: boolean = false;
    isReadOnlyForTotalHire: boolean = false;
    isReadOnlyForAdvance: boolean = false;
    isReadOnlyForBalance: boolean = false;
    isReadOnlyForDepartureDate: boolean = false;
    isReadOnlyForDepartureTime: boolean = false;
    isReadOnlyForScheduledDate: boolean = false;
    isReadOnlyForScheduledTime: boolean = false;
    rewardStatus: boolean = false;
    hireslipAdvanceStatus: boolean = false;
    hireslipBalanceStatus: boolean = false;
    fortnightStmtNo: any;






    constructor(private lrDetailsRpt: MasterService,
        private router: Router, private hireslipService: HireslipService,
        private masterReadService: MasterReadService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;


            this.getSupplierDetailsList();
            this.getDriverNameList();
            this.getStaffNameDetailsList();
        }
    }
    logInPage() {
        this.router.navigate(["/authentication/login"]);

    }

    ngOnInit(): void {
        this.dtOptionsLrDetails = {
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            buttons: [
                //                {
                //                    extend: 'excel',
                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
                //                    titleAttr: 'Excel',
                //                    exportOptions: {
                //                        columns: ':visible'
                //                    }
                //                },
                //                {
                //                    extend: 'print',
                //                    text: '<i class="fas fa-print"> Print</i>',
                //                    titleAttr: 'Print',
                //
                //                }


            ],

            // the below code is for button export ends
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

        }

        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );
        this.getDataFrmServiceFrTable = this.lrDetailsRpt.getSummaryData()
        this.onDestroyUnsubscribtionLrDetails = this.getDataFrmServiceFrTable.subscribe(data => {
            this.lrDetailsDataList = data['data'];
            this.dtTriggerLrDetails.next();
        });

        //the below code is for the getting data through json ends
    }

    ngOnDestroy(): void {
        this.dtTriggerLrDetails.unsubscribe();
        this.onDestroyUnsubscribtionLrDetails.unsubscribe();
    } 

    editByMethod(editBy: string) {
        if (editBy === 'lrEdit') {
            this.editView = true;
            this.invoiceView = false;
        } else if (editBy === 'invoiceEdit') {
            this.editView = false;
            this.invoiceView = true;
        } else {
            this.editView = false;
            this.invoiceView = false;
        }
    }

    /* Hash Map start */
    hashMapSupplierIndex: Map<String, Number> = new Map<String, Number>();
    hashMapSupplier: Map<String, SupplierDetailsDto> = new Map<
        String,
        SupplierDetailsDto
    >();
    hashMapDriver: Map<String, DriverdetailsDto> = new Map<String, DriverdetailsDto>();
    hashMapDriverIndex: Map<Number, String> = new Map<Number, String>();
    hashMapDriverIndexNew: Map<Number, Number> = new Map<Number, Number>();
    hashmapTruckNumberIndex: Map<String, Number> = new Map<String, Number>();
    hashmapTruck: Map<String, TruckDataDto> = new Map<String, TruckDataDto>();
    hashmapLoaderHead: Map<String, Number> = new Map<String, Number>();
    hashmapLoader: Map<String, Number> = new Map<String, Number>();
    ///For Supplier Name

    getSupplierDetails() {
        this.supplierDetailsDto = new SupplierDetailsDto();
        this.supplierDetailsDto.companyId = this.userDataDtoReturnSession.companyId;
        //this.supplierDetailsDto.mode = "Working";
    }
    getSupplierDetailsList = () => {
        this.getSupplierDetails();
        this.masterReadService
            .getSuplierMaster(this.supplierDetailsDto)
            .subscribe((response) => {
                if (response.length == 0) {
                    this.supplierOptions = [];
                    this.supplierNameTA = [];
                    swal("Warning", "No Supplier Master records found!", "warning");
                } else {
                    this.supplierOptions = [];
                    this.supplierNameTA = [];
                    this.supplierOptions = response;

                    for (let i = 0; i < this.supplierOptions.length; i++) {
                        this.supplierNameTA.push(this.supplierOptions[i]);
                        this.hashMapSupplier.set(
                            this.supplierOptions[i].supplierName,
                            this.supplierOptions[i]
                        );
                        this.hashMapSupplierIndex.set(
                            this.supplierOptions[i].supplierName,
                            i
                        );
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                swal(
                    "Error",
                    "Server Error While Getting Supplier Master Details",
                    "warning"
                );
            },
            () => console.log("done");
    };

    clickListnerForDriverName(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDriverName = e.item;
        $("#" + this.pageId + "licenceNumber").val('');
        $("#" + this.pageId + "licenceNumber").val(this.modelDriverName.licenceNo);
        $("#" + this.pageId + "licenceNumber").prop('readonly', true);
        $("#" + this.pageId + "bayNumber").focus();
        /* window.setTimeout(function () {
             $("#"+this.pageId+"bayNumber").focus();
             $("#"+this.pageId+"licenceNumber").prop('readonly', true);
         }, 100);*/
        this.onSelectEntryDriverId = null;
        this.onSelectEntryDriverId = this.modelDriverName.id;

    }

    clickListnerForLoaderStaffName(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelLoaderStaffName = e.item;
        window.setTimeout(function () {
            $("#" + this.pageId + "truckNumber").focus();
        }, 100);
    }


    //For Driver Name
    getDriverDetailsForRead() {
        this.driverDetailsDto = new DriverdetailsDto();
        this.driverDetailsDto.companyId = this.userDataDtoReturnSession.companyId;

        this.driverDetailsDto.mode = "ALl";

    }

    getDriverNameList = () => {
        this.getDriverDetailsForRead();
        this.masterReadService.getDriver(this.driverDetailsDto).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.driverNameOptions = [];
                    this.driverNameTA = [];
                    swal("Warning", "No Driver Master records found!", "warning");
                } else {
                    this.driverNameOptions = [];
                    this.driverNameTA = [];
                    this.driverNameOptions = response;

                    for (let i = 0; i < this.driverNameOptions.length; i++) {
                        this.driverNameTA.push(this.driverNameOptions[i]);
                        // this.driverNameTA.push( this.driverNameOptions[i].driverName,this.driverNameOptions[i]);
                        this.hashMapDriver.set(this.driverNameOptions[i].driverName, this.driverNameOptions[i]);
                        this.hashMapDriverIndex.set(this.driverNameOptions[i].id, this.driverNameOptions[i].driverName);
                        this.hashMapDriverIndexNew.set(this.driverNameOptions[i].id, i);
                        //console.log(this.driverNameTA.push( this.driverNameOptions[i].driverName),this.driverNameOptions[i]);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Error While Getting Driver Master Details", "warning");
            },
            () => console.log('done');
    };

    //For Loader Name
    getStaffDetailsForRead() {
        this.staffDetailsLrDto = new LRDto();
        this.staffDetailsLrDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    getStaffNameDetailsList = () => {
        this.getStaffDetailsForRead();
        this.masterReadService.getStaffDetails(this.staffDetailsLrDto).subscribe(
            (response) => {
                if (response.length == 0) {
                    this.loaderStaffOption = [];
                    this.loaderStaffNameTA = [];
                    swal("Warning", "No Staff Name records found!", "warning");
                } else {
                    this.loaderStaffOption = [];
                    this.loaderStaffNameTA = [];
                    this.loaderStaffOption = response;
                    for (let i = 0; i < this.loaderStaffOption.length; i++) {
                        this.loaderStaffNameTA.push(this.loaderStaffOption[i]);
                        this.hashmapLoader.set(this.loaderStaffNameTA[i].staffName, i + 1);
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Error While Getting Loader Name Details", "warning");
            },
            () => console.log('done');
    };

    getHireSlipEnquiry(e) {
        if (e.keyCode == 13) {
            this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
            if (this.enteredInvoiceNumber != null) {
                this.getHireslipDetail();
            }
        }
    }

    getTruckMasterDetailsSingle() {
        this.getTruckDetailsForReadSingle();
        this.showSpinnerForAction = true;
        this.masterReadService
            .getTruck(this.truckDataDtoForTruckNo)
            .subscribe((response) => {
                if (response.length == 0) {
                    this.truckNumberOptions = [];
                    this.truckNumberTA = [];
                    swal("Warning", "No Truck Number records found!", "warning");
                    $("#" + this.pageId + "truckNumber").val("");
                } else {
                    this.truckNumberOptions = [];
                    this.truckNumberTA = [];
                    this.truckNumberOptions = response;
                    console.log("t1-truckNo : " + response.length);
                    for (let i = 0; i < this.truckNumberOptions.length; i++) {
                        this.truckNumberTA.push(this.truckNumberOptions[i]);
                        this.hashmapTruck.set(
                            this.truckNumberOptions[i].truckNumber,
                            this.truckNumberOptions[i]
                        );
                        this.hashmapTruckNumberIndex.set(
                            this.truckNumberOptions[i].truckNumber,
                            i + 1
                        );
                        $("#" + this.pageId + "truckNumber").val(
                            this.truckNumberOptions[i].truckNumber
                        );
                        $("#" + this.pageId + "panNumberSearchId").val(
                            this.truckNumberOptions[i].tinNo
                        );
                    }
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Truck Details", "warning");
            },
            () => console.log("done");
    }

    getTruckDetailsForReadSingle() {
        this.vehicleNoSearch = $("#" + this.pageId + "truckNumberSearch").val();

        this.truckDataDtoForTruckNo = new TruckDataDto();
        this.truckDataDtoForTruckNo.companyId =
            this.userDataDtoReturnSession.companyId;
        this.truckDataDtoForTruckNo.status = "Working";
        this.truckDataDtoForTruckNo.mode = "TruckNo";
        this.truckDataDtoForTruckNo.vehicleName = this.vehicleNoSearch;
    }

    clickListnerForTruckNumber(e: NgbTypeaheadSelectItemEvent) {
        this.modelTruckNumber = e.item;
        $("#" + this.pageId + "panNumberSearchId").val(this.modelTruckNumber.tinNo);
        window.setTimeout(function () {
            $("#sgcsupplierName").focus();
        }, 100);
    }

    onKeyPressFieldForLicNo(event) {
        if (event == 13) {
            window.setTimeout(function () {
                $("#sgcbayNumber").focus();
            }, 100);
        }
    }

    getDriverDetailsForReadTruckNo() {
        this.truckDataDtoForTruckNo = new TruckDataDto();
        this.truckDataDtoForTruckNo.companyId =
            this.userDataDtoReturnSession.companyId;
        this.truckDataDtoForTruckNo.status = "Working";
    }

    getTruckMasterDetailsList = () => {
        this.showSpinnerForAction = true;
        this.getDriverDetailsForReadTruckNo();
        this.masterReadService
            .getTruck(this.truckDataDtoForTruckNo)
            .subscribe((response) => {
                if (response.length == 0) {
                    this.truckNumberOptions = [];
                    this.truckNumberTA = [];
                    swal("Warning", "No Truck Number records found!", "warning");
                } else {
                    this.truckNumberOptions = [];
                    this.truckNumberTA = [];
                    this.truckNumberOptions = response;
                    console.log("t1 : " + response.length);
                    for (let i = 0; i < this.truckNumberOptions.length; i++) {
                        this.truckNumberTA.push(this.truckNumberOptions[i]);
                        this.hashmapTruck.set(
                            this.truckNumberOptions[i].truckNumber,
                            this.truckNumberOptions[i]
                        );
                        this.hashmapTruckNumberIndex.set(
                            this.truckNumberOptions[i].truckNumber,
                            i + 1
                        );
                    }
                    console.log("t2 : " + response.length);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal(
                    "Error",
                    "Server Error While Getting Truck Master Details",
                    "warning"
                );
            },
            () => console.log("done");
    };

    setHireslipDetailsForGetData() {
        this.hireSlipDto = new HireSlipDto();
        this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
        this.hireSlipDto.hireslipnumber = this.enteredInvoiceNumber;
        this.hireSlipDto.invoiceNumber = this.enteredInvoiceNumber;
        this.hireSlipDto.companyid = this.userDataDtoReturnSession.companyId;
    }

    getHireslipDetail() {
        this.setHireslipDetailsForGetData();
        this.showSpinnerForAction = true;
        console.log(this.hireSlipDto);
        this.hireslipService.getHireslipDetails(this.hireSlipDto)
            .subscribe((response) => {
                console.log(response);
                if (response) {
                    this.hireSlipDetailLists = response;
                    console.log(this.hireSlipDetailLists);
                    this.clearFeilds();
                    this.stockStatus = this.hireSlipDetailLists.status;
                    this.rewardStatus = this.hireSlipDetailLists.isRewarded;
                    this.hireslipAdvanceStatus = this.hireSlipDetailLists.isLryAdvPaid;
                    this.hireslipBalanceStatus = this.hireSlipDetailLists.hireSlipBalance;
                    this.fortnightStmtNo = this.hireSlipDetailLists.stmtNo
                    this.setInFeilds();
                    this.showSpinnerForAction = false;
                    this.changeDetectorRef.detectChanges();
                } else {
                    swal("No Data", "No Records found for this search", "warning");
                }
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting HireSlip Details", "warning");
            },
            () => console.log("done");
    }

    setInFeilds() {
        $("#" + this.pageId + "source").val(this.hireSlipDetailLists.fromstation);
        $("#" + this.pageId + "destination").val(this.hireSlipDetailLists.tostation);
        $("#" + this.pageId + "status").val(this.hireSlipDetailLists.status);
        $("#" + this.pageId + "gurWt").val(this.hireSlipDetailLists.gurWeight);
        $("#" + this.pageId + "kantaWt").val(this.hireSlipDetailLists.kantaWeight);
        $("#" + this.pageId + "loaderStaffName").val(this.hireSlipDetailLists.loadedby);
        $("#" + this.pageId + "truckNumberSearch").val(this.hireSlipDetailLists.vehicleNumber);
        $("#" + this.pageId + "supplierName").val(this.hireSlipDetailLists.supplierName);

        $("#" + this.pageId + "licenceNumber").val(this.hireSlipDetailLists.driverLicState);
        $("#" + this.pageId + "totalHire").val(this.hireSlipDetailLists.totalhire);
        $("#" + this.pageId + "advance").val(this.hireSlipDetailLists.advance);
        $("#" + this.pageId + "balance").val(this.hireSlipDetailLists.balance);
        $("#" + this.pageId + "departureDate").val(this.hireSlipDetailLists.departuredateStr);
        $("#" + this.pageId + "departureTime").val(this.hireSlipDetailLists.departureTime);
        $("#" + this.pageId + "scheduledDate").val(this.hireSlipDetailLists.scheduleddateStr);
        $("#" + this.pageId + "scheduledTime").val(this.hireSlipDetailLists.scheduledTime);
        $("#" + this.pageId + "truckNumber").val(this.hireSlipDetailLists.vehicleNumber);
        $("#" + this.pageId + "driverName").val(this.hireSlipDetailLists.drivername);

        this.onSelectEntryDriverId = this.hireSlipDetailLists.driverId;

        console.log("iiid");
        console.log(this.onSelectEntryDriverId);
        this.readonlyForStockStatus();
        this.readonlyForRewardStatus();
        this.readonlyForHireslipAdvance();
        this.readonlyForHireslipBalance();
        this.readonlyForStatementStatus();
    }

    getTruckMasterDetailsSingleEvent(event) {
        if (event == 13) {
            this.getTruckMasterDetailsSingle();
        }
    }

    onKeyPressFieldForDepartureTime(event) {
        if (event == 13) {
            window.setTimeout(function () {
                $("#sgcentryCP").focus();
            }, 100);
        }
    }

    onKeyPressFieldForScheduledTime(event) {
        if (event == 13) {
            window.setTimeout(function () {
                $("#sgcentryCP").focus();
            }, 100);
        }
    }

    clearFeilds() {
        $("#" + this.pageId + "source").val('');
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "status").val('');
        $("#" + this.pageId + "gurWt").val('');
        $("#" + this.pageId + "kantaWt").val('');
        $("#" + this.pageId + "loaderStaffName").val('');
        $("#" + this.pageId + "truckNumberSearch").val('');
        $("#" + this.pageId + "supplierName").val('');
        $("#" + this.pageId + "driverName").val('');
        $("#" + this.pageId + "licenceNumber").val('');
        $("#" + this.pageId + "totalHire").val('');
        $("#" + this.pageId + "advance").val('');
        $("#" + this.pageId + "balance").val('');
        $("#" + this.pageId + "departureDate").val('');
        $("#" + this.pageId + "departureTime").val('');
        $("#" + this.pageId + "scheduledDate").val('');
        $("#" + this.pageId + "scheduledTime").val('');
        $("#" + this.pageId + "truckNumber").val('');
        // this.driverId = 0;
        this.isReadOnlyForLoaderName = false;
        this.isReadOnlyForSearchTruckNo = false;
        this.isReadOnlyForSelectedTruckNumber = false;
        this.isReadOnlyForSupplierName = false;
        this.isReadOnlyForDriverName = false;
        this.isReadOnlyForLicenceNumber = false;
        this.isReadOnlyForTotalHire = false;
        this.isReadOnlyForAdvance = false;
        this.isReadOnlyForBalance = false;
        this.isReadOnlyForDepartureDate = false;
        this.isReadOnlyForDepartureTime = false;
        this.isReadOnlyForScheduledDate = false;
        this.isReadOnlyForScheduledTime = false;
    }

    clearAllFeilds() {
        $("#" + this.pageId + "invoiceNumber").val('');
        this.clearFeilds();
    }

    setInvoiceLrEdit() {
        this.enteredInvoiceNumber = $("#" + this.pageId + "invoiceNumber").val();
        this.enteredGurWt = $("#" + this.pageId + "gurWt").val();
        this.enteredKantaWt = $("#" + this.pageId + "kantaWt").val();
        this.enteredLoaderStaffName = $("#" + this.pageId + "loaderStaffName").val();
        this.enteredTruckNumberSearch = $("#" + this.pageId + "truckNumberSearch").val();
        this.enteredSupplierName = $("#" + this.pageId + "supplierName").val();
        this.enteredDriverName = $("#" + this.pageId + "driverName").val();
        this.enteredLicenceNumber = $("#" + this.pageId + "licenceNumber").val();
        this.enteredTotalHire = $("#" + this.pageId + "totalHire").val();
        this.enteredAdvance = $("#" + this.pageId + "advance").val();
        this.enteredBalance = $("#" + this.pageId + "balance").val();
        this.enteredDepatureDate = $("#" + this.pageId + "departureDate").val();
        this.enteredDepartureTime = $("#" + this.pageId + "departureTime").val();
        this.enteredScheduledDate = $("#" + this.pageId + "scheduledDate").val();
        this.enteredScheduledTime = $("#" + this.pageId + "scheduledTime").val();
        this.enteredTruckNumber = $("#" + this.pageId + "truckNumber").val();

        this.hireSlipDtoForUpdate = new HireSlipDto();
        this.hireSlipDtoForUpdate.hireslipnumber = this.enteredInvoiceNumber;
        this.hireSlipDtoForUpdate.invoiceNumber = this.enteredInvoiceNumber;
        this.hireSlipDtoForUpdate.gurWeight = this.enteredGurWt;
        this.hireSlipDtoForUpdate.kantaWeight = this.enteredKantaWt
        this.hireSlipDtoForUpdate.loadedby = this.enteredLoaderStaffName;
        this.hireSlipDtoForUpdate.vehicleNumber = this.enteredTruckNumber;
        this.hireSlipDtoForUpdate.suppliername = this.enteredSupplierName;
        this.hireSlipDtoForUpdate.drivername = this.enteredDriverName;
        this.hireSlipDtoForUpdate.driverLicState = this.enteredLicenceNumber;
        this.hireSlipDtoForUpdate.totalhire = this.enteredTotalHire;
        this.hireSlipDtoForUpdate.advance = this.enteredAdvance;
        this.hireSlipDtoForUpdate.balance = this.enteredBalance;
        this.hireSlipDtoForUpdate.lastupdatedby = this.userDataDtoReturnSession.userId;
        this.hireSlipDtoForUpdate.column1 = this.enteredSupplierName;

        if (
            this.enteredDepatureDate != null &&
            this.enteredDepatureDate != ""
        ) {
            this.departureDate = moment(this.enteredDepatureDate).format(
                "YYYY-MM-DD"
            );
        }
        if (
            this.enteredDepartureTime != null &&
            this.enteredDepartureTime != ""
        ) {
            this.departureTimeSplit = [];
            this.departureTimeSplit = this.enteredDepartureTime.split(":");
            this.departureTime =
                this.departureTimeSplit[0] + ":" + this.departureTimeSplit[1];
        }
        if (
            this.enteredScheduledDate != null &&
            this.enteredScheduledDate != ""
        ) {
            this.scheduledDate = moment(this.enteredScheduledDate).format(
                "YYYY-MM-DD"
            );
        }
        if (
            this.enteredScheduledTime != null &&
            this.enteredScheduledTime != ""
        ) {
            var arrivalTimeSplit = [];
            arrivalTimeSplit = this.enteredScheduledTime.split(":");
            this.arrivalTime = arrivalTimeSplit[0] + ":" + arrivalTimeSplit[1];
        }

        this.hireSlipDtoForUpdate.departureTime = this.departureTime;
        this.hireSlipDtoForUpdate.scheduledTime = this.arrivalTime;
        this.hireSlipDtoForUpdate.departuredateStr = this.departureDate;
        this.hireSlipDtoForUpdate.scheduleddateStr = this.scheduledDate;
        this.hireSlipDtoForUpdate.departuredate = this.departureDate;
        this.hireSlipDtoForUpdate.driverId = this.onSelectEntryDriverId;

        console.log(this.hireSlipDtoForUpdate.driverId);
    }

    updateInvoiceLrEditDetails() {
        swal({
            title: "Confirm Update",
            text: "Sure U want to Update Invoice Lr Edit Details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.setInvoiceLrEdit();
                console.log(this.hireSlipDtoForUpdate);
                this.hireslipService.updateHireslipDetails(this.hireSlipDtoForUpdate).
                    subscribe((data) => {
                        this.hireSlipDtoRet = data;
                        if (this.hireSlipDtoRet.status == "Success") {
                            swal("Success", "Invoice Lr Edit Details saved/updated successfully!", "info");
                            this.clearAllFeilds();
                        } else {
                            swal("Failed", "Failed to update the Invoice Lr Edit Details!", "error");
                        }
                    }), (error) => {
                        swal("Error", "Server problem occurred while updating the Invoice Lr Edit Details!", "error");
                    },
                    () => console.log('done');
            }
        });
    }

    validateUpdateInvoiceLrEditDetailsDetails() {
        if ($("#" + this.pageId + "gurWt").val() == undefined || $("#" + this.pageId +
            "gurWt").val() == null || $("#" + this.pageId + "gurWt").val() == "" ||
            $("#" + this.pageId + "kantaWt").val() == undefined || $("#" + this.pageId + "kantaWt").val() == null ||
            $("#" + this.pageId + "kantaWt").val() == "" || $("#" + this.pageId + "loaderStaffName").val() == undefined ||
            $("#" + this.pageId + "loaderStaffName").val() == null || $("#" + this.pageId + "loaderStaffName").val() == "" ||
            $("#" + this.pageId + "truckNumberSearch").val() == undefined || $("#" + this.pageId + "truckNumberSearch").val() == null ||
            $("#" + this.pageId + "truckNumberSearch").val() == "" || $("#" + this.pageId + "supplierName").val() == undefined ||
            $("#" + this.pageId + "supplierName").val() == null || $("#" + this.pageId + "supplierName").val() == "" ||
            $("#" + this.pageId + "driverName").val() == undefined || $("#" + this.pageId + "driverName").val() == null ||
            $("#" + this.pageId + "driverName").val() == "" || $("#" + this.pageId + "licenceNumber").val() == undefined ||
            $("#" + this.pageId + "licenceNumber").val() == null || $("#" + this.pageId + "licenceNumber").val() == "" ||
            $("#" + this.pageId + "totalHire").val() == undefined || $("#" + this.pageId + "totalHire").val() == null ||
            $("#" + this.pageId + "totalHire").val() == "" || $("#" + this.pageId + "advance").val() == undefined ||
            $("#" + this.pageId + "advance").val() == null || $("#" + this.pageId + "advance").val() == "" ||
            $("#" + this.pageId + "balance").val() == undefined || $("#" + this.pageId + "balance").val() == null ||
            $("#" + this.pageId + "balance").val() == "" || $("#" + this.pageId + "departureDate").val() == undefined ||
            $("#" + this.pageId + "departureDate").val() == null || $("#" + this.pageId + "departureDate").val() == "" ||
            $("#" + this.pageId + "departureTime").val() == undefined || $("#" + this.pageId + "departureTime").val() == null ||
            $("#" + this.pageId + "departureTime").val() == "" || $("#" + this.pageId + "scheduledDate").val() == undefined ||
            $("#" + this.pageId + "scheduledDate").val() == null || $("#" + this.pageId + "scheduledDate").val() == "" ||
            $("#" + this.pageId + "scheduledTime").val() == undefined || $("#" + this.pageId + "scheduledTime").val() == null ||
            $("#" + this.pageId + "scheduledTime").val() == "" || $("#" + this.pageId + "truckNumber").val() == undefined ||
            $("#" + this.pageId + "truckNumber").val() == null || $("#" + this.pageId + "truckNumber").val() == "") {
            swal(" Mandatory Field", "All fields are mandatory", "warning");
            return false;
        } else {
            this.updateInvoiceLrEditDetails();
        }
    }

    readonlyForStockStatus() {
        console.log("status");
        console.log(this.stockStatus);
        if (this.stockStatus != null && this.stockStatus == "Stock") {
            this.isReadOnlyForLoaderName = true;
            this.isReadOnlyForSearchTruckNo = true;
            this.isReadOnlyForSelectedTruckNumber = true;
            this.isReadOnlyForSupplierName = true;
            this.isReadOnlyForDriverName = true;
            this.isReadOnlyForLicenceNumber = true;
            this.isReadOnlyForDepartureDate = true;
            this.isReadOnlyForDepartureTime = true;
            this.isReadOnlyForScheduledDate = true;
            this.isReadOnlyForScheduledTime = true;
        }
    }

    readonlyForRewardStatus() {
        console.log("reward");
        console.log(this.rewardStatus);
        if (this.rewardStatus == true) {
            this.isReadOnlyForDepartureDate = true;
            this.isReadOnlyForDepartureTime = true;
            this.isReadOnlyForScheduledDate = true;
            this.isReadOnlyForScheduledTime = true;
        }
    }

    readonlyForHireslipAdvance() {
        console.log("advance");
        console.log(this.hireslipAdvanceStatus);
        if (this.hireslipAdvanceStatus == true) {
            this.isReadOnlyForAdvance = true;
        }
    }

    readonlyForHireslipBalance() {
        console.log("balance");
        console.log(this.hireslipBalanceStatus);
        if (this.hireslipBalanceStatus == true) {
            this.isReadOnlyForTotalHire = true;
            this.isReadOnlyForAdvance = true;
            this.isReadOnlyForBalance = true;
        }
    }

    readonlyForStatementStatus() {
        if (this.fortnightStmtNo != null) {
            this.isReadOnlyForTotalHire = true;
            this.isReadOnlyForAdvance = true;
            this.isReadOnlyForBalance = true;
        }
    }
}
