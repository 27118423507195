import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { StockService } from 'src/app/dataService/stock-service';
import { FormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from 'src/app/dataService/master-service';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { HireslipService } from 'src/app/dataService/hireslip-service';
import { MemoService } from 'src/app/dataService/memo-service';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { LRDto } from 'src/app/dto/LR-dto';
import *  as moment from 'moment';
import { DatePipe } from "@angular/common";
//for popup modal ends

@Component({
    selector: 'app-stocks-to-cp',
    templateUrl: './stocks-to-cp.component.html',
    styleUrls: ['./stocks-to-cp.component.css']
})
export class StocksToCpComponent implements OnInit {

    //for datatable starts
    gettingDataFrmServiceFrStocksToCpTable: any;
    stocksToCpDataList: any;
    onDestroyUnsubscribtionStocksToCp: Subscription;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
pageId="stcc";
    loadingIndicator = true;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    //for datepicker ends

    //for popup modal starts
    closeResultMobile: string;
    //for popup modal ends

    //for datatable starts
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerStocksToCp: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsStocksToCp: any;
    //for datatable ends

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;

    checkPostOptions: LRDto[];
    public modelcheckPost: any;
    lrDtoCheckPost: LRDto = new LRDto();
    lrDtoAddNewCheckPost: LRDto = new LRDto();
    checkPostTA: Array<LRDto> = [];
    focusCheckPostTA$ = new Subject<string>();
    checkPostSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCheckPostTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.checkPostTA
                : this.checkPostTA.filter(v => v.checkPostName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCheckPost = (x: { checkPostName: string }) => x.checkPostName;

    modalRefferenceCheckPostPopUp: NgbModalRef;
    modalRefferenceCheckPostClosePopUp: string;
    @ViewChild('popModelNewCheckPostContent') private popModelNewCheckPostContent;

    lrDtoLRCheckPost: LRDto = new LRDto();
    lrDtoForSetLrDetailsValue: LRDto = new LRDto();
    lrDtoAddNewCheckPostName: LRDto = new LRDto();
    lrDtoToStock: LRDto = new LRDto();
    lrDtoToCp: LRDto = new LRDto();

    bookingDateModal: any = null;
    dateModal: any = null;

    isReadonlyBookingDate = false;
    isReadonlyInvoiceNo = false;
    isReadonlyDestination = false;
    isReadonlyConsigneeName = false;
    isReadonlyArticle = false;
    isReadonlyVehicleNo = false;
    isReadonlyDriverName = false;

    validateLRNumber: any = null;
    validateNewCheckPostName: any = null;
    validateNoticeNumber: any = null;

    validateCatchDate: any = null;
    validateCheckPostName: any = null;

    editSelectedRowBookingDate: any = null;
    address: any;
    showOnlyForPrint = false;

    constructor(private masterService: MasterService, private masterReadService: MasterReadService,
        private router: Router, private modalService: NgbModal,
        private memoService: MemoService, private hireslipService: HireslipService,
        private lrReport: LrReportService, private datePipe: DatePipe, public changeDetectorRef : ChangeDetectorRef) {

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.setCheckPostDetailsList();
            this.setLRCheckPostDetailsData();

            this.isReadonlyBookingDate = true;
            this.isReadonlyInvoiceNo = true;
            this.isReadonlyDestination = true;
            this.isReadonlyConsigneeName = true;
            this.isReadonlyArticle = true;
            this.isReadonlyVehicleNo = true;
            this.isReadonlyDriverName = true;
        }
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        this.dtOptionsStocksToCp = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
                    customize: function (win) {
                        $(win.document.body).css('font-size', '10pt');
                        $(win.document.body).find('th, td').
                            css('font-family', 'Arial, Helvetica, sans-serif')
                            .css('font-size', '12px').css('text-align', 'center');
                    },
                    footer: true,
                    messageTop: function () {
                        return "<br><table style='width: 100%;'>" +
                            "<tr>" +
                            "<td rowspan='3' style='width: 20%;'>" +
                            "<table>" +
                            "</table>" +
                            "</td>" +
                            "<td rowspan='3'style='width:60%;word-wrap: break-word;text-align:left !important;'>" +
                            "<strong style='font-size:25px;'><u>Check Post Detained LR's Detail</u></strong><br>" +
                            "</td>" +
                            "<td rowspan='3' align='left' style='width:20%;'>" +
                            "</td>" +
                            "</tr>" +
                            "</table><br>";
                    },
                    title: function () {
                        return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                            "<td align='left' style='width: 20%;'>" +
                            "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                            "</td>" +
                            "<td align='left' style='width: 30%;'>" +
                            "</td>" +
                            "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                            companyAddressDetls +
                            "</td>" +
                            "</tr></table>";
                    },
                    exportOptions: {
                        columns: [0, 1, 2, 7, 8, 3, 4, 5]
                    }
                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        return " Check Post Detained LR's Detail -" + "";

                    },
                    exportOptions: {
                        columns: [0, 1, 2, 7, 8, 3, 4, 5]
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 280,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": true,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var totalArticle = api.column(7).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(7).footer()).html(totalArticle);
            }
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerStocksToCp.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        //for datatable starts
        this.dtTriggerStocksToCp.unsubscribe();
        //this.onDestroyUnsubscribtionStocksToCp.unsubscribe();
        //for datatable ends
    }

    //for datepicker starts
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker ends
    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }
        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for popup modal starts 
    addNewCheckPostPopup(popModelNewCheckPostContent) {
        this.modalRefferenceCheckPostPopUp = this.modalService.open(popModelNewCheckPostContent,
            { centered: true, size: "sm" });
        this.modalRefferenceCheckPostPopUp.result.then((result) => {
            this.modalRefferenceCheckPostClosePopUp = `Closed with: ${result}`;
        }, reason => {
            this.modalRefferenceCheckPostClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }


    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    //for popup modal ends

    checkPostDropDownListner(event, popModelNewCheckPostContent) {
        this.modelcheckPost = event.item.checkPostName;
        $("#"+this.pageId+"checkPost").val(event.item.checkPostName);
        if ($("#"+this.pageId+"checkPost").val() == "Add New") {
            this.addNewCheckPostPopup(popModelNewCheckPostContent);
        }
    }


    getCheckPostDetailsData() {
        this.lrDtoCheckPost = new LRDto();
        this.lrDtoCheckPost.companyId = this.userDataDtoReturnSession.companyId;
    }

    setCheckPostDetailsList = () => {
        this.getCheckPostDetailsData();
        this.showSpinnerForAction = true;
        this.masterReadService.getCheckPostList(this.lrDtoCheckPost).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.checkPostOptions = [];
                    this.checkPostTA = [];

                    this.checkPostOptions = response;
                    this.lrDtoAddNewCheckPost = new LRDto();
                    this.lrDtoAddNewCheckPost.checkPostName = "Add New";
                    this.checkPostTA.push(this.lrDtoAddNewCheckPost);
                    for (let i = 0; i < this.checkPostOptions.length; i++) {
                        this.checkPostTA.push(this.checkPostOptions[i]);
                    }
                } else {
                    this.checkPostOptions = [];
                    this.checkPostTA = [];
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Check Post Drop Down Details", "warning");
            },
            () => console.log('done');
    };

    getLRCheckPostDetailsData() {
        this.lrDtoLRCheckPost = new LRDto();
        this.lrDtoLRCheckPost.branch = this.userDataDtoReturnSession.mainStation;
        this.lrDtoLRCheckPost.companyId = this.userDataDtoReturnSession.companyId;
    }
    setLRCheckPostDetailsData() {
        this.getLRCheckPostDetailsData();
        this.showSpinnerForAction = true;
        this.lrReport.getCPStockReport(this.lrDtoLRCheckPost).subscribe(
            (response) => {
                console.log(response);
                this.stocksToCpDataList = [];
                $("#"+this.pageId+"stocksToCPTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.stocksToCpDataList = response;
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.dtTriggerStocksToCp.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting CP Stock Report Details", "warning");
            },
            () => console.log('done');
    }

    lrNumberKeyPressEventListner(event) {
        console.log(event);
        if (event.charCode == 13) {
            this.getLRDetailsData();
        }
    }

    getLRDetailsData() {
        this.lrDtoForSetLrDetailsValue = new LRDto();
        this.validateLRNumber = $("#"+this.pageId+"lrNumber").val();
        this.lrDtoForSetLrDetailsValue.lrNumber = this.validateLRNumber;
        this.lrDtoForSetLrDetailsValue.stocksAt = this.userDataDtoReturnSession.office;
        this.lrDtoForSetLrDetailsValue.companyId = this.userDataDtoReturnSession.companyId;
        this.showSpinnerForAction = true;
        this.lrReport.getLROfSpecificStation(this.lrDtoForSetLrDetailsValue).subscribe(
            (response) => {
                console.log(response);
                if (response.size == 0) {
                    swal("Not Found", "LR Number " + $("#"+this.pageId+"lrNumber").val() + " not found!", "warning");
                    this.clearFields();
                } else if ((response.status == "Pending") &&
                    (!(response.stocksAt == this.userDataDtoReturnSession.office))) {
                    swal("Not Found", "LR Number " + $("#"+this.pageId+"lrNumber").val() + " not found!", "warning");
                    this.clearFields();
                } else if (response.status == "Delivered") {
                    swal("Not Found", "LR Number " + $("#"+this.pageId+"lrNumber").val() + " Already Delivered", "warning");
                    this.clearFields();
                } else if (response.paid == 0.0 && response.cashMemoNumber != null) {
                    swal("Not Allowed", "LR Number " + $("#"+this.pageId+"lrNumber").val() + " not found!", "warning");
                    this.clearFields();
                } else {
                    this.setLRDetailsValues(response);
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting CP Stock Report Details", "warning");
            },
            () => console.log('done');
    }
    setLRDetailsValues(lrdtoReturnResponse) {
        $("#"+this.pageId+"bookingDates").val(lrdtoReturnResponse.bookingDate == null ? null : moment(lrdtoReturnResponse.bookingDate).format("YYYY-MM-DD"));
        $("#"+this.pageId+"invoiceNumber").val(lrdtoReturnResponse.invoiceNumber);
        $("#"+this.pageId+"destination").val(lrdtoReturnResponse.destination);
        $("#"+this.pageId+"consigneeName").val(lrdtoReturnResponse.consigneeName);
        $("#"+this.pageId+"totalArticle").val(lrdtoReturnResponse.totalArticles);
        $("#"+this.pageId+"vehicleNumber").val(lrdtoReturnResponse.vehicleNumber);
        $("#"+this.pageId+"driverName").val(lrdtoReturnResponse.driverName);

        $("#"+this.pageId+"checkPost").val(lrdtoReturnResponse.checkPostName);
        $("#"+this.pageId+"noticeNo").val(lrdtoReturnResponse.noticeNumber);
        $("#"+this.pageId+"dates").val(lrdtoReturnResponse.catchDate == null ? null : moment(lrdtoReturnResponse.catchDate).format("YYYY-MM-DD"));


    }

    clearFields() {
        $("#"+this.pageId+"lrNumber").val('');
        $("#"+this.pageId+"bookingDates").val('');
        $("#"+this.pageId+"invoiceNumber").val('');
        $("#"+this.pageId+"destination").val('');
        $("#"+this.pageId+"consigneeName").val('');
        $("#"+this.pageId+"totalArticle").val('');
        $("#"+this.pageId+"vehicleNumber").val('');
        $("#"+this.pageId+"driverName").val('');

        $("#"+this.pageId+"checkPost").val('');
        $("#"+this.pageId+"noticeNo").val('');
        $("#"+this.pageId+"dates").val('');
        this.bookingDateModal = null;
        this.dateModal = null;
        this.modelcheckPost = null;
    }

    overAllClearBtnListner() {
        $("#"+this.pageId+"lrNumber").val('');
        this.clearFields();
        this.stocksToCpDataList = [];
        $("#"+this.pageId+"stocksToCPTableId").DataTable().destroy();
        this.dtTriggerStocksToCp.next();

        this.isReadonlyBookingDate = true;
        this.isReadonlyInvoiceNo = true;
        this.isReadonlyDestination = true;
        this.isReadonlyConsigneeName = true;
        this.isReadonlyArticle = true;
        this.isReadonlyVehicleNo = true;
        this.isReadonlyDriverName = true;
        setTimeout(() => {
            this.setLRCheckPostDetailsData();
        }, 3000);

    }

    saveNewCheckPostNameBtn() {
        if ($("#"+this.pageId+"newCheckPostName").val() == null || $("#"+this.pageId+"newCheckPostName").val() == undefined ||
            $("#"+this.pageId+"newCheckPostName").val() == "") {
            swal("Mandatory Field", "Please enter the Check Post Name", "warning");
        } else {
            this.setNewCheckPostNameDetails();
        }

    }

    setNewCheckPostNameDetails() {
        this.lrDtoAddNewCheckPostName = new LRDto();
        this.validateNewCheckPostName = $("#"+this.pageId+"newCheckPostName").val();
        this.lrDtoAddNewCheckPostName.checkPostName = this.validateNewCheckPostName;
        this.lrDtoAddNewCheckPostName.userName = this.userDataDtoReturnSession.userId;
        this.masterReadService.addCheckPost(this.lrDtoAddNewCheckPostName).subscribe(
            (response) => {
                console.log(response);
                if (response.status == "Success") {
                    swal("Alert", "Check Post Name Save Successfully", "success");
                    this.modalRefferenceCheckPostPopUp.close();
                    this.setCheckPostDetailsList();
                    $("#"+this.pageId+"newCheckPostName").val('');
                } else if (response.status == "Exist") {
                    swal("CheckPost Already Exist", "" + $("#"+this.pageId+"newCheckPostName").val() + " CheckPost already exist!", "warning");
                    return false;
                } else {
                    swal("Alert", "Failed to save New Check Post Name", "error");
                    return false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting Add Check Post Details", "error");
            },
            () => console.log('done');
    };

    selectedRowForStockToCp(stocksToCpData) {
        //console.log(stocksToCpData);
        swal({
            title: "To Stock",
            text: "Sure U want to move the LR:" + stocksToCpData.lrNumber + " into stock?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((yesToStockBtn) => {
            if (yesToStockBtn) {
                this.lrDtoToStock = new LRDto();
                this.lrDtoToStock.lrNumber = stocksToCpData.lrNumber;
                this.lrDtoToStock.noticeNumber = stocksToCpData.noticeNumber;
                this.lrDtoToStock.userName = this.userDataDtoReturnSession.userId;
                this.lrDtoToStock.companyId = this.userDataDtoReturnSession.companyId;
                this.showSpinnerForAction = true;
                //console.log(this.lrDtoToStock);
                this.lrReport.updateCPStocks(this.lrDtoToStock).subscribe(
                    (response) => {
                        console.log(response);
                        if (response.status == "Success") {
                            swal("Stocks To Cp", "Stock To CP Details Save/Update Successfully", "success");
                            this.setLRCheckPostDetailsData();
                            this.clearFields();
                        } else {
                            swal("Stocks To Cp", "Failed to save/update the Stock To CP details", "warning");
                        }
                        this.showSpinnerForAction = false;
                        this.changeDetectorRef.detectChanges();
                    }), (error) => {
                        this.showSpinnerForAction = false;
                        swal("Error", "Server Error While Getting update CP Stocks  Details", "error");
                    },
                    () => console.log('done');
            }
        });
    }

    editSelectedRowForStockToCp(stocksToCpData) {
        console.log(stocksToCpData);
        $("#"+this.pageId+"lrNumber").val(stocksToCpData.lrNumber);
        //stocksToCpData.bookingDateStr == null ? null : moment(stocksToCpData.bookingDateStr).format("YYYY-MM-DD")
        if (stocksToCpData.bookingDate != null && stocksToCpData.bookingDate != undefined) {
            this.editSelectedRowBookingDate = moment(stocksToCpData.bookingDate).format("YYYY-MM-DD");
            $("#"+this.pageId+"bookingDates").val(moment(stocksToCpData.bookingDate).format("YYYY-MM-DD"));
        }

        $("#"+this.pageId+"invoiceNumber").val(stocksToCpData.invoiceNumber);
        $("#"+this.pageId+"destination").val(stocksToCpData.destination);
        $("#"+this.pageId+"consigneeName").val(stocksToCpData.consigneeName);
        $("#"+this.pageId+"totalArticle").val(stocksToCpData.totalArticles);
        $("#"+this.pageId+"vehicleNumber").val(stocksToCpData.vehicleNumber);
        $("#"+this.pageId+"driverName").val(stocksToCpData.driverName);

        $("#"+this.pageId+"checkPost").val(stocksToCpData.checkPostName);
        $("#"+this.pageId+"noticeNo").val(stocksToCpData.noticeNumber);
        $("#"+this.pageId+"dates").val(stocksToCpData.catchDate == null ? null : moment(stocksToCpData.catchDate).format("YYYY-MM-DD"));
    }

    validateToCheckPostBtn() {
        if (($("#"+this.pageId+"lrNumber").val() == null) || ($("#"+this.pageId+"lrNumber").val() == undefined) ||
            ($("#"+this.pageId+"lrNumber").val() == "") || ($("#"+this.pageId+"checkPost").val() == null) ||
            ($("#"+this.pageId+"checkPost").val() == undefined) || ($("#"+this.pageId+"checkPost").val() == '') ||
            ($("#"+this.pageId+"noticeNo").val() == null) || ($("#"+this.pageId+"noticeNo").val() == undefined) ||
            ($("#"+this.pageId+"noticeNo").val() == "") || ($("#"+this.pageId+"dates").val() == null) ||
            ($("#"+this.pageId+"dates").val() == undefined) || ($("#"+this.pageId+"dates").val() == "")) {
            swal("Mandatory Fields", "Please enter the Mandatory Fields", "warning");
            return false;
        } else {
            swal({
                title: "To Checkpost",
                text: "Sure U want to marks the LR: " + $("#"+this.pageId+"lrNumber").val() + " as captured in checkpost?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((yesToCpBtn) => {
                if (yesToCpBtn) {
                    this.setToCheckPostDataDetails();
                }
            });
        }
    }

    setToCheckPostDataDetails() {
        this.lrDtoToCp = new LRDto();

        this.validateLRNumber = $("#"+this.pageId+"lrNumber").val();
        this.validateNoticeNumber = $("#"+this.pageId+"noticeNo").val();
        this.validateCatchDate = $("#"+this.pageId+"dates").val();
        this.validateCheckPostName = $("#"+this.pageId+"checkPost").val();

        this.lrDtoToCp.lrNumber = this.validateLRNumber;
        this.lrDtoToCp.noticeNumber = this.validateNoticeNumber;
        this.lrDtoToCp.catchDate = this.validateCatchDate;
        this.lrDtoToCp.checkPostName = this.validateCheckPostName;
        this.showSpinnerForAction = true;
        this.lrReport.addStocksToCp(this.lrDtoToCp).subscribe(
            (response) => {
                console.log(response);
                if (response.status == "alreadyexist") {
                    swal("Duplicate Entry", "LR No: " + $("#"+this.pageId+"lrNumber").val() + " Already in Check Post", "warning");
                } else if (response.status == "NotInStock") {
                    swal("Alert", "LR No: " + $("#"+this.pageId+"lrNumber").val() + " Not In Stock", "warning");
                } else if (response.status == "Success") {
                    swal("Alert", "LR No: " + $("#"+this.pageId+"lrNumber").val() + " Added Stocks To CP ", "success");
                    this.setLRCheckPostDetailsData();
                    this.clearFields();
                } else {
                    swal("Stocks To Cp", "Failed to save/update the Stock To CP details", "warning");
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Error While Getting add Stocks To Cp  Details", "error");
            },
            () => console.log('done');
    }
}