<div class="page row system_responsive" id="{{pageId}}firstPrint">
	<div class="col-md-12">

		<div>
			<div>
				<table width='100%' border='0' cellspacing='0' cellpadding='0'>
					<tr>
						<td style="padding-top: 10px;" width='40%' valign='top'><img
								src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
							<br> {{address}}</td>

						<td width='60%' valign='top'>
							<table width='100%' border='0' cellspacing='0' cellpadding='0'>
								<tr>
									<td>
										<table width='100%' border='0' cellspacing='0' cellpadding='0'>
											<tr>
												<td width='40%' style='text-align: right'><strong>Loader
														Head&nbsp;:&nbsp;</strong></td>
												<td width='60%' style='border-bottom: 1px solid #000;'>
													{{loaderHad}}</td>
											</tr>
										</table>
									</td>
								</tr>
								<tr>
									<td>&nbsp;</td>
								</tr>
								<!--<br>-->
								<tr>
									<td>
										<table width='100%' border='0' cellspacing='0' cellpadding='0'>
											<tr>
												<td width='40%' style='text-align: right'><strong>Statement
														Number&nbsp;:&nbsp;
													</strong></td>
												<td width='60%' style='border-bottom: 1px solid #000;'>
													{{stmtNo}}</td>
											</tr>
										</table>
									</td>
								</tr>
								<tr>
									<td>&nbsp;</td>
								</tr>
								<!--<br>-->
								<tr>
									<td>
										<table width='100%' border='0' cellspacing='0' cellpadding='0'>
											<tr>
												<td width='40%' style='text-align: right'><strong>Statement
														Date&nbsp;:&nbsp;
													</strong></td>
												<td width='60%' style='border-bottom: 1px solid #000;'>
													{{stmtDate}}</td>
											</tr>
										</table>
									</td>
								</tr>
								<tr>
									<td>&nbsp;</td>
								</tr>
								<!--<br>-->
								<tr>
									<td>
										<table width='100%' border='0' cellspacing='0' cellpadding='0'>
											<tr>
												<td width='40%' style='text-align: right'><strong>Statement
														Period&nbsp;:&nbsp;
													</strong></td>
												<td width='60%' style='border-bottom: 1px solid #000;'>
													{{period}}</td>
											</tr>
										</table>
									</td>
								</tr>
								<tr>
									<td>&nbsp;</td>
								</tr>

							</table>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<!--<br>-->
		<div class="row">
			<div class="col-md-12">
				<h6 style='text-align: center; font-size: large;'>
					<strong><u>Summary</u></strong>
				</h6>
			</div>
		</div>
		<br>
		<!--Second  row starts-->
		<table width='100%' border='0' cellpadding='0' cellspacing='0' style='table-layout: fixed;'>
			<tr>
				<td width='15%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; border-left: 1px solid #000;'
					valign='top' type='text'><strong>Hamali</strong></td>
				<td width='15%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;'
					valign='top' type='number'><strong>No Of Vehicles</strong></td>
				<td width='15%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;'
					valign='top' type='number'><strong>Kanta Wgt</strong></td>
				<td width='15%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;'
					valign='top' type='number'><strong>Act Wgt</strong></td>
				<td width='15%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;'
					valign='top' type='number'><strong>Rate Per Qty</strong></td>
				<td width='15%' align='center'
					style='border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; border-right: 1px solid #000;'
					valign='top' type='text'><strong>Total Hamali Amt</strong></td>
			</tr>
			<tr>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
					Loading
				</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					{{loadingTotalCount}}</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					{{loadingKantaWgt}}</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					{{loadingHamali}}</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-right: 1px solid #000;'>
					{{totLoadingHamaliAmt}}</td>
			</tr>
			<tr>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
					Un Loading
				</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					{{unloadingTotalCount}}</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					{{unloadingTotActWgt}}</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					{{unloadingHamali}}</td>
				<td width='15%' align='center'
					style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-right: 1px solid #000;'>
					{{totUnloadingHamaliAmt}}</td>
			</tr>
		</table>
		<br>
		<br>
		<div class="row">
			<div class="col-md-12">
				<h6 style='text-align: center; font-size: large;'>
					<strong><u>Loading Hamali Details</u></strong>
				</h6>
			</div>
		</div>
		<br>
		<table width='100%' border='0' cellpadding='0' cellspacing='0' style='table-layout: fixed;'>
			<tr>
				<td width='40%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; border-left: 1px solid #000;'
					valign='top' type='text'><strong>Invoice Number</strong></td>
				<td width='30%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;'
					valign='top' type='number'><strong>Invoice Date</strong></td>
				<td width='30%' align='center'
					style='border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; border-right: 1px solid #000;'
					valign='top' type='text'><strong>Kanta Weight</strong></td>
			</tr>
			<tr *ngFor="let loadingDate of lrDto_LoadingDetailsPrint">
				<td width='40%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
					{{loadingDate.invoiceNumber}}
				</td>
				<td width='30%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					{{loadingDate.hireslipDateStr}}</td>
				<td width='30%' align='center'
					style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-right: 1px solid #000;'>
					{{loadingDate.kantaWeight}}</td>
			</tr>
			<tr>
				<td width='40%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
					<strong> Total {{loadingTotalCount}}</strong>
				</td>
				<td width='30%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='30%' align='center'
					style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-right: 1px solid #000;'>
					<strong>{{loadingKantaWgt}}</strong>
				</td>
			</tr>
		</table>
		<br><br>
		<div class="row">
			<div class="col-md-12">
				<h6 style='text-align: center; font-size: large;'>
					<strong><u>Un Loading Hamali Details</u></strong>
				</h6>
			</div>
		</div>
		<br>
		<table width='100%' border='0' cellpadding='0' cellspacing='0' style='table-layout: fixed;'>
			<tr>
				<td width='18%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; border-left: 1px solid #000;'
					valign='top' type='text'><strong> Local challan No</strong></td>
				<td width='18%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;'
					valign='top' type='number'><strong>Local Challan Arrival</strong></td>
				<td width='12%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;'
					valign='top' type='number'><strong> Tempo No</strong></td>
				<td width='12%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;'
					valign='top' type='number'><strong> Agency Name</strong></td>
				<td width='8%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;'
					valign='top' type='text'><strong> Total Lrs</strong></td>
				<td width='14%' align='center'
					style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000;'
					valign='top' type='text'><strong> Actual Wgt</strong></td>
				<td width='18%' align='center'
					style='border-bottom: 1px solid #000; word-wrap: break-word; border-top: 1px solid #000; border-right: 1px solid #000;'
					valign='top' type='text'><strong>Local Challan Kanta
						Wgt</strong></td>
			</tr>
			<tr *ngFor="let unloadingDate of lrDto_UnLoadingDetailsPrint">
				<td width='18%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
					{{unloadingDate.challanNo}}
				</td>
				<td width='18%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					{{unloadingDate.arrivalDateStr}}</td>
				<td width='12%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					<span>{{unloadingDate.vehicleNumber}}</span>
				</td>
				<td width='12%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					{{unloadingDate.agentName}}</td>
				<td width='8%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					{{unloadingDate.totalLrs}}</td>
				<td width='14%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					{{unloadingDate.actualWeight}}</td>
				<td width='18%' align='center'
					style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-right: 1px solid #000;'>
					{{unloadingDate.challanKantaWgt}}</td>
			</tr>
			<tr>
				<td width='18%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-left: 1px solid #000;'>
					<strong> Total {{unloadingTotalCount}}</strong>
				</td>
				<td width='18%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='12%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					<strong> </strong>
				</td>
				<td width='12%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
				</td>
				<td width='8%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					<strong>{{unloadingTotalLrs}}</strong></td>
				<td width='14%' align='center'
					style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'>
					<strong>{{unloadingTotActWgt}}</strong></td>
				<td width='18%' align='center'
					style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word; border-right: 1px solid #000;'>
					<strong>{{unloadingKantaWgt}}</strong>
				</td>
			</tr>
		</table>


	</div>
</div>