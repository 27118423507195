import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterService } from 'src/app/dataService/master-service';
import { FormControl } from '@angular/forms';
import { Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { UserDataDto } from 'src/app/dto/UserData-dto';
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";

@Component({
    selector: 'app-user-creation',
    templateUrl: './user-creation.component.html',
    styleUrls: ['./user-creation.component.css']
})
export class UserCreationComponent implements OnInit {

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    userDataDto: UserDataDto = new UserDataDto();
    lrDto: LRDto = new LRDto();
    userDataDtoSaveRet: any;
    isUploaded = false;
    setLoginTimeFromTime = false;
    setLoginTimeToTime = false;
    userDataDeleteDto: UserDataDto = new UserDataDto();
    userDataDeleteDtoRet: any;
    gettingDataFrmServiceFrUserCreationTable: any;
    userCreationDataList: any;
    loadingIndicator = true;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerUserCreation: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsUserCreation: any;
    pageId = "uscc";
    lrDtoToStn: LRDto = new LRDto();
    lrDtoToStnAll: LRDto = new LRDto();
    lrDtosToStnOptionsGet: any;
    enteredName: any;
    enteredContactNumber: any;
    enteredEmailId: any;
    enteredLoginId: any;
    selectedRole: any;
    selectedFromTime: any;
    selectedToTime: any;
    selectedLoginTime: any;
    userDataDtoForEdit: UserDataDto = new UserDataDto();
    selectedBranch: any;
    saveoredit = "Save";
    isReadOnlyForName = false;
    isReadOnlyForBranch = false;
    isReadOnlyForLoginId = false;
    isReadOnlyForRole = false;
    indexPartySearchTA: Array<any> = [];
    lrDtoDestinationStationAllOption: LRDto = new LRDto();
    destinationStationOptions: LRDto[];
    lrDtoDestinationStation: LRDto = new LRDto();

    public office: any;
    destinationSubStationNameTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationSubStationNameTA
                : this.destinationSubStationNameTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    roleOptions = [
        { id: 1, day: 'Administrator' },
        { id: 2, day: 'Barcode' },
        { id: 3, day: 'Collection Manager' },
        { id: 4, day: 'Delivery Manager' },
    ];

    public modelRole: any;
    @ViewChild('instanceIndex') instanceIndex: NgbTypeahead;
    roleOptionsTA: Array<any> = [];
    focusRolesTA$ = new Subject<string>();
    rolesSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusRolesTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.roleOptionsTA
                : this.roleOptionsTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    constructor(private masterService: MasterService, private http: HttpClient, private router: Router, private masterReadService: MasterReadService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.userDataDto.office = this.userDataDtoReturnSession.office;
            this.getUserCreationDetails();
            this.gettingToStationList();
            this.setRoles();
        }
    }
    fieldFocus(e, el) {
        if (e.keyCode == 13) { // press A
            el.focus();
        }
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    setRoles() {
        for (let i = 0; i < this.roleOptions.length; i++) {
            this.roleOptionsTA.push(this.roleOptions[i].day);
            this.indexPartySearchTA.push(this.roleOptions[i].day);
        }
    }

    ngOnInit(): void {
        this.dtOptionsUserCreation = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerUserCreation.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerUserCreation.unsubscribe();
    }

    gettingToStationDetails() {
        this.lrDtoToStn = new LRDto();
        this.lrDtoToStn.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoToStn.mode = "other";
        this.lrDtoToStn.reportMode = "subStation";
        console.log(this.lrDtoToStn);

    }
    gettingToStationList = () => {
        this.gettingToStationDetails();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoToStn).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No  records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.destinationSubStationNameTA = [];
                    this.lrDtosToStnOptionsGet = [];
                    this.lrDtosToStnOptionsGet = response;
                    this.lrDtoToStnAll.destination = "All";
                    this.destinationSubStationNameTA.push(this.lrDtoToStnAll);
                    for (let i = 0; i < this.lrDtosToStnOptionsGet.length; i++) {
                        this.destinationSubStationNameTA.push(this.lrDtosToStnOptionsGet[i]);

                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Error While Getting Destination Details", "warning");
            },
            () => console.log('done');
    };

    setLoginTimeMode(searchBy: string) {
        if (searchBy === 'true') {
            this.setLoginTimeFromTime = true;
            this.setLoginTimeToTime = true;

        } else if (searchBy === 'false') {
            this.setLoginTimeFromTime = false;
            this.setLoginTimeToTime = false;

        } else {
            this.setLoginTimeFromTime = false;
            this.setLoginTimeToTime = false;
        }
    }

    getUserDetailsForRead() {
        // if (this.userDataDtoReturnSession.role == "Super Administrator") {
        //     this.lrDto.destination = this.userDataDto.office;
        // } else {
        //     this.lrDto.destination = this.userDataDtoReturnSession.office;
        // }

        //both condition passing same office
        this.lrDto.destination = this.userDataDtoReturnSession.office;
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
    }

    getUserCreationDetails = () => {
        this.getUserDetailsForRead();
        this.masterReadService.getUserDetails(this.lrDto).subscribe(
            (response) => {
                if (response) {
                    console.log(response);
                    $("#" + this.pageId + "userCreationTableId").DataTable().destroy();
                    this.userCreationDataList = response;
                    this.dtTriggerUserCreation.next();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting User Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    getUserDetailsForSave() {
        this.enteredName = $("#" + this.pageId + "name").val();
        this.enteredContactNumber = $("#" + this.pageId + "contactNumber").val();
        this.enteredEmailId = $("#" + this.pageId + "emailId").val();
        this.enteredLoginId = $("#" + this.pageId + "loginId").val();
        this.selectedRole = $("#" + this.pageId + "role").val();
        this.selectedToTime = $("#" + this.pageId + "toTime").val();
        this.selectedFromTime = $("#" + this.pageId + "fromTime").val();
        this.selectedLoginTime = $("#" + this.pageId + "setLoginTime").val();
        this.selectedBranch = $("#" + this.pageId + "destination").val();

        this.userDataDto = new UserDataDto();
        this.userDataDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDto.eMailAddress = this.enteredEmailId;
        this.userDataDto.createdBy = this.userDataDtoReturnSession.userId;
        this.userDataDto.updatedBy = this.userDataDtoReturnSession.userId;
        this.userDataDto.name = this.enteredName;
        this.userDataDto.contactNo = this.enteredContactNumber;
        this.userDataDto.userId = this.enteredLoginId;
        this.userDataDto.role = this.selectedRole;
        // this.userDataDto.office = this.userDataDtoReturnSession.office;
        this.userDataDto.office = this.selectedBranch;
        this.userDataDto.timeLogin = this.selectedLoginTime;
        this.userDataDto.saveOrEdit = this.saveoredit;

        var fromTimeTimeSplit = [];
        if (this.selectedFromTime) {
            fromTimeTimeSplit = this.selectedFromTime.split(":");
            this.userDataDto.fromTime = fromTimeTimeSplit[0] + ":" + fromTimeTimeSplit[1];
        } else {
            this.userDataDto.fromTime = null;
        }

        var toTimeTimeSplit = [];
        if (this.selectedToTime) {
            toTimeTimeSplit = this.selectedToTime.split(":");
            this.userDataDto.toTime = toTimeTimeSplit[0] + ":" + toTimeTimeSplit[1];
        } else {
            this.userDataDto.toTime = null;
        }

        // this.userDataDto.saveOrEdit = this.saveoredit;

        // if (this.userDataDtoReturnSession.role == "Super Administrator") {
        //     this.userDataDto.office = this.userDataDto.office;
        // } else {
        //     this.userDataDto.office = this.userDataDtoReturnSession.office;
        // }
        // //        console.log('helo :' +this.userDataDto.timeLogin);
        // if (this.userDataDto.timeLogin != true) {
        //     this.userDataDto.fromTime = null;
        //     this.userDataDto.toTime = null;
        // } else {

        // }
    }

    saveUpdateUserDetails = () => {
        swal({
            title: "Confirm Save/Update",
            text: "Sure U want to Save/Update User Creation Details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.getUserDetailsForSave();
                console.log(this.userDataDto);
                this.masterService.createUser(this.userDataDto).
                    subscribe((data) => {
                        this.userDataDtoSaveRet = data;
                        if (this.userDataDtoSaveRet.status == "Success") {
                            swal({
                                title: "Success",
                                text: "User Details Saved/Updated Successfull",
                                icon: "success",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                            this.clearAll();
                            this.getUserCreationDetails();
                        } else if (this.userDataDtoSaveRet.status == "exist") {
                            swal({
                                title: "Duplicate entry",
                                text: "User Id Already Exist",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                        }
                        else {
                            swal({
                                title: "Error",
                                text: "Error While Saving User Details",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                        }
                    }),
                    (error) => swal({
                        title: "Error",
                        text: "Server Error While Saving User Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }),
                    () => console.log('done');
            }
        });
    };

    validateUserDetails() {
        if ($("#" + this.pageId + "destination").val() == null || $("#" + this.pageId +
            "destination").val() == undefined || $("#" + this.pageId + "destination").val() == "" ||
            $("#" + this.pageId + "name").val() == null || $("#" + this.pageId + "name").val() == undefined ||
            $("#" + this.pageId + "name").val() == "" || $("#" + this.pageId + "loginId").val() == null ||
            $("#" + this.pageId + "loginId").val() == undefined || $("#" + this.pageId + "loginId").val() == "" ||
            $("#" + this.pageId + "role").val() == null || $("#" + this.pageId + "role").val() == undefined ||
            $("#" + this.pageId + "role").val() == "") {
            swal(" Mandatory Field", "Please select mandatory fields", "warning");
            return false;
        } else {
            this.saveUpdateUserDetails();
        }
    }

    clearAll() {
        $('input[type="text"],[type="number"]').val('');
        $("#" + this.pageId + "branch").val('');
        $("#" + this.pageId + "role").val('');
        $("#" + this.pageId + "toTime").val('');
        $("#" + this.pageId + "fromTime").val('');
        $("#" + this.pageId + "setLoginTime").val('');
        this.setLoginTimeFromTime = false;
        this.setLoginTimeToTime = false;
        this.isReadOnlyForName = false;
        this.isReadOnlyForBranch = false;
        this.isReadOnlyForLoginId = false;
        this.isReadOnlyForRole = false;
        this.saveoredit = "Save";
    }

    rowSelected(userCreationData) {
        console.log(userCreationData);
        $("#" + this.pageId + "name").val(userCreationData.name);
        $("#" + this.pageId + "contactNumber").val(userCreationData.mobileNum);
        $("#" + this.pageId + "emailId").val(userCreationData.emailId);
        $("#" + this.pageId + "loginId").val(userCreationData.userName);
        $("#" + this.pageId + "role").val(userCreationData.role);
        $("#" + this.pageId + "destination").val(userCreationData.branch);
        $("#" + this.pageId + "setLoginTime").val(userCreationData.timeLogin ? "true" : "false");
        $("#" + this.pageId + "fromTime").val(userCreationData.fromTime);
        $("#" + this.pageId + "toTime").val(userCreationData.toTime);

        if (userCreationData.timeLogin) {
            this.setLoginTimeFromTime = true;
            this.setLoginTimeToTime = true;
            // $("#" + this.pageId + "fromTime").val(userCreationData.fromTime);
            // $("#" + this.pageId + "toTime").val(userCreationData.toTime);
        } else {
            this.setLoginTimeFromTime = false;
            this.setLoginTimeToTime = false;
            this.userDataDto.fromTime = '';
            this.userDataDto.toTime = '';
        }
        this.saveoredit = "Edit";
        this.isReadOnlyForName = true;
        this.isReadOnlyForBranch = true;
        this.isReadOnlyForLoginId = true;
        this.isReadOnlyForRole = true;
    }

    validateDeleteRow() {
        this.deleteUserDetails();
    }

    getDeleteUserDetails() {
        this.enteredLoginId = $("#" + this.pageId + "loginId").val();
        this.selectedBranch = $("#" + this.pageId + "destination").val();

        this.userDataDeleteDto = new UserDataDto();
        this.userDataDeleteDto.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDeleteDto.updatedBy = this.userDataDtoReturnSession.userId;
        this.userDataDeleteDto.userId = this.enteredLoginId;
        // this.userDataDeleteDto.office = this.userDataDtoReturnSession.office;
        this.userDataDeleteDto.office = this.selectedBranch;
    }

    deleteUserDetails = () => {
        swal({
            title: "Confirm Delete",
            text: "Sure U want to Delete User Creation Details?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.getDeleteUserDetails();
                console.log(this.userDataDeleteDto);
                this.masterService.deleteUser(this.userDataDeleteDto).
                    subscribe((data) => {
                        this.userDataDeleteDtoRet = data;
                        if (this.userDataDeleteDtoRet.status == "Success") {
                            swal({
                                title: "Success",
                                text: "User Details Deleted Successfully",
                                icon: "success",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                            this.clearAll();
                            this.getUserCreationDetails();
                        } else {
                            swal({
                                title: "Error",
                                text: "Error While Deleting User Details",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                        }
                    }),
                    (error) => swal({
                        title: "Error",
                        text: "Server Error While Deleting User Details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }),
                    () => console.log('done');
            }
        });
    };

}