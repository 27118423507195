import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from 'src/app/dataService/master-service';
import { Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Router } from "@angular/router";
import { UserDataDto } from 'src/app/dto/UserData-dto';
import swal from 'sweetalert';
import { HttpClient } from '@angular/common/http';
import { LRDto } from 'src/app/dto/LR-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { MasterReadService } from 'src/app/dataService/masterread-service';

@Component({
    selector: 'app-user-features-customization',
    templateUrl: './user-features-customization.component.html',
    styleUrls: ['./user-features-customization.component.css']
})
export class UserFeaturesCustomizationComponent implements OnInit {

    userFeaturesCustomizationDataList: any;
    screenSpecialRightsDataList: any;

    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerUserFeaturesCustomization: Subject<any> = new Subject();
    dtTriggerScreenSpecialRights: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsUserFeaturesCustomization: any;
    pageId = "usfc";
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    lrDtoToStn: LRDto = new LRDto();
    lrDtoToStnAll: LRDto = new LRDto();
    lrDtosToStnOptionsGet: any;
    lrDto: LRDto = new LRDto();
    userCreationDataList: any;
    selectedStation: any;
    userDataDto: UserDataDto = new UserDataDto();
    appModuleList: any;
    selectedRows: any;
    saveAppModuleDetails: any;
    userDataDtoForSave: UserDataDto = new UserDataDto();
    selectedUserId: any;
    selectedOffice: any;

    public station: any;
    destinationSubStationNameTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationSubStationNameTA
                : this.destinationSubStationNameTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    public modelUserId: any;
    @ViewChild('instanceIndex') instanceIndex: NgbTypeahead;
    stationOptionsTA: Array<any> = [];
    focusStationTA$ = new Subject<string>();
    stationSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.stationOptionsTA
                : this.stationOptionsTA.filter(v => v.userName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterUserId = (x: { userName: string }) => x.userName;

    constructor(private masterService: MasterService, private http: HttpClient, private router: Router,
        public changeDetectorRef: ChangeDetectorRef, private masterReadService: MasterReadService
    ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }

        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.gettingToStationList();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    setUserIdList() {
        for (let i = 0; i < this.userCreationDataList.length; i++) {
            $("#" + this.pageId + "userRole").val(this.userCreationDataList.role);
        }
    }

    ngOnInit(): void {
        this.dtOptionsUserFeaturesCustomization = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerUserFeaturesCustomization.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerUserFeaturesCustomization.unsubscribe();
    }

    gettingToStationDetails() {
        this.lrDtoToStn = new LRDto();
        this.lrDtoToStn.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoToStn.mode = "other";
        this.lrDtoToStn.reportMode = "subStation";
        console.log(this.lrDtoToStn);

    }
    gettingToStationList = () => {
        this.gettingToStationDetails();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoToStn).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No  records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.destinationSubStationNameTA = [];
                    this.lrDtosToStnOptionsGet = [];
                    this.lrDtosToStnOptionsGet = response;
                    this.lrDtoToStnAll.destination = "All";
                    this.destinationSubStationNameTA.push(this.lrDtoToStnAll);
                    for (let i = 0; i < this.lrDtosToStnOptionsGet.length; i++) {
                        this.destinationSubStationNameTA.push(this.lrDtosToStnOptionsGet[i]);

                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                swal("Error", "Server Error While Getting Destination Details", "warning");
            },
            () => console.log('done');
    };

    getUserCreationDetails = (e: NgbTypeaheadSelectItemEvent) => {
        this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDto.destination = e.item.destination;
        console.log(this.lrDto);
        this.userCreationDataList = [];
        this.masterReadService.getUserDetails(this.lrDto).subscribe(
            (response) => {
                if (response) {
                    console.log(response);
                    this.clearFeilds();
                    this.userCreationDataList = response;
                    console.log(this.userCreationDataList);
                    this.stationOptionsTA = [];
                    for (let i = 0; i < this.userCreationDataList.length; i++) {
                        this.stationOptionsTA.push(this.userCreationDataList[i]);
                    }
                }
                else {
                    swal({
                        title: "Warning",
                        text: "No records found for the selected State!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting User Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    function(): void {
        setTimeout(() => {
            this.getAppModuleDetails();
        }, 2000);
    }

    setForGetAppModuleDetails() {
        this.selectedUserId = $("#" + this.pageId + "userId").val();
        this.selectedOffice = $("#" + this.pageId + "station").val();

        this.userDataDto = new UserDataDto();
        this.userDataDto.userId = this.selectedUserId;
        this.userDataDto.office = this.selectedOffice;
        console.log(this.userDataDto);
    }

    getAppModuleDetails = () => {
        this.setForGetAppModuleDetails();
        this.masterReadService.getAppModuleLists(this.userDataDto).subscribe(
            (response) => {
                if (response) {
                    console.log(response);
                    $("#" + this.pageId + "userFeaturesCustomizationTableId").DataTable().destroy();
                    this.appModuleList = response;
                    console.log(this.appModuleList);
                    this.dtTriggerUserFeaturesCustomization.next();
                }
                else {
                    swal({
                        title: "Warning",
                        text: "No records found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting App Module Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    stateSearchViewDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        console.log(e);
        console.log(e.item);
        $("#" + this.pageId + "userRole").val(e.item.role);
    }

    checkBoxForAppModuleDataClicked(e) {
        this.selectedRows = [];
        for (let i = 0; i < this.appModuleList.length; i++) {
            const appModuleData = this.appModuleList[i];
            if (appModuleData.isSelected) {
                this.selectedRows.push(appModuleData);
            }
        }
        console.log('Selected Rows:', this.selectedRows);
        this.changeDetectorRef.detectChanges();
    }

    setForSaveUserAppModule() {
        this.selectedUserId = $("#" + this.pageId + "userId").val();
        this.selectedOffice = $("#" + this.pageId + "station").val();

        this.saveAppModuleDetails = [];
        for (let i = 0; i < this.selectedRows.length; i++) {
            this.userDataDtoForSave = new UserDataDto();
            this.userDataDtoForSave.id = this.selectedRows[i].id;
            this.userDataDtoForSave.userId = this.selectedUserId;
            this.userDataDtoForSave.office = this.selectedOffice;
            this.saveAppModuleDetails.push(this.userDataDtoForSave);
        }
        console.log('Selected Rows:', this.saveAppModuleDetails);
        // this.userDataDtoForSave.listOfInteger = this.saveAppModuleDetails;   
        console.log(this.userDataDtoForSave);
    }

    saveAppModuleForUser() {
        if (!this.selectedRows || this.selectedRows.length == null || this.selectedRows.length == undefined
            || this.selectedRows.length == 0
        ) {
            swal({
                title: "Select Rows",
                text: "Please select at least one row.",
                icon: "warning",
                closeOnClickOutside: true,
                closeOnEsc: true,
            }).then(function () {
                swal.close();
                window.setTimeout(function () {
                    $('#lsaecinvoiceNumber').focus();
                }, 100);

            });
        } else {
            swal({
                title: "Confirm Save",
                text: "Sure U want to Save Selected Screen?",
                icon: "info",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((sureYesBtn) => {
                if (sureYesBtn) {
                    this.setForSaveUserAppModule();
                    this.masterReadService.saveUserAppModule(this.saveAppModuleDetails).subscribe(
                        (response) => {
                            if (response.status == "Success") {
                                swal("Success", "Selected details Save", "success");
                                this.getAppModuleDetails();
                            } else {
                                swal("Alert", "Error while saving from lists", "warning");
                            }
                            // this.showSpinnerForAction = false;
                            this.changeDetectorRef.detectChanges();
                        }), (error) => {
                            // this.showSpinnerForAction = false;
                            swal("Error", "Server Error While saving from lists", "warning");
                        }, () => console.log('done');
                }
            });
        }
    }

    validateAppUserDetails() {
        if ($("#" + this.pageId + "station").val() == null || $("#" + this.pageId +
            "station").val() == undefined || $("#" + this.pageId + "station").val() == "" ||
            $("#" + this.pageId + "userId").val() == null || $("#" + this.pageId + "userId").val() == undefined ||
            $("#" + this.pageId + "userId").val() == "") {
            swal(" Mandatory Field", "Please select mandatory fields", "warning");
            return false;
        } else {
            this.saveAppModuleForUser();
        }
    }

    clearFeilds() {
        $("#" + this.pageId + "userId").val('');
        $("#" + this.pageId + "userRole").val('');
    }

    clearBtn() {
        swal({
            title: "Clear",
            text: "Sure U want to clear the selected items?",
            icon: "info",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((sureYesBtn) => {
            if (sureYesBtn) {
                this.getAppModuleDetails();
            }
        });
    }

}