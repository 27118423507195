import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HireslipService } from 'src/app/dataService/hireslip-service';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { LRDto } from 'src/app/dto/LR-dto';

@Component( {
    selector: 'app-tracking-live-report',
    templateUrl: './tracking-live-report.component.html',
    styleUrls: ['./tracking-live-report.component.css']
} )
export class TrackingLiveReportComponent implements OnInit {

    gettingDataFrmServiceFrTrackingLiveReportTable: any;


    trackingLiveReportDataList: any;


    onDestroyUnsubscribtionTrackingLiveReport: Subscription;

    dtTriggerTrackingLiveReport: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsTrackingLiveReport: any;
    
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;
    lrDtoUserValue: LRDto = new LRDto();
    ftTrackAll: boolean = false;
    pageId="tlrpt";

    constructor(private router: Router,private hireslipService : HireslipService, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "FTTrackingAll") {
                        this.ftTrackAll = true;
                    }
                }
            }
            setTimeout(() => {
            	this.getDetails();
            }, 1000);
        }

    }

    ngOnInit(): void {
        this.dtOptionsTrackingLiveReport = {
                        dom: 'Bfrtip',
            buttons: [
                {
                 extend: 'excel',
                 text: '<i class="fas fa-file-excel"> Excel</i>',
                 titleAttr: 'Excel',
                 exportOptions: {
                 columns: ':visible'
                  }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": true
        }
    }
    
    getDetails = () => {
		this.showSpinnerForAction = true;
		this.lrDtoUserValue = new LRDto();
		this.lrDtoUserValue.reportMode = "allTrips";
		this.lrDtoUserValue.status = "Stock";
		this.lrDtoUserValue.branch = this.userDataDtoReturnSession.mainStation;
		this.lrDtoUserValue.isTrue = this.ftTrackAll;
		
		this.hireslipService.getTrackingDetailsFTHireslips(this.lrDtoUserValue).subscribe(
			(response) => {
				$("#"+this.pageId+"trackingRptTableId").DataTable().destroy();
				this.trackingLiveReportDataList = [];
				if (response.length == 0) {
					swal({
						title: "Live Tracking Details",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.trackingLiveReportDataList = response;
				}
				setTimeout(() => {
					this.dtTriggerTrackingLiveReport.next();
	            }, 1000);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	};
	
	trackInvoice(trackingLiveReportData){
		localStorage.clear();
		localStorage.setItem('searchMode', "tripId");
        localStorage.setItem('trackingLiveReportData', JSON.stringify(trackingLiveReportData));
        this.router.navigate( ['/report/others-report/vehicleTracking'] );
	}
    
    ngOnDestroy(): void {
        this.dtTriggerTrackingLiveReport.unsubscribe();
    }
    
    ngAfterViewInit(): void {
        this.dtTriggerTrackingLiveReport.next();
    }
    
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
}