import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LRDto } from "src/app/dto/LR-dto";
import { serverUrl } from "src/environments/environment";
import { ResponseDto } from '../dto/Response-dto';
import { HireSlipDto } from '../dto/HireSlip-dto';

@Injectable({
    providedIn: 'root'
})
export class EwaybillService {

    private _baseUrl = serverUrl.webEwaybill;

    constructor(private http: HttpClient) {
    }

    getDetailsFromEwayBillRefreshEwb(responseDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getDetailsFromEwayBillRefreshEwbApi`, responseDto);
    }

    getDetailsForAutoValidityExtensionDetails(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getDetailsForAutoValidityExtensionDetailsApi`, lrDto);
    }

    extendAutoEwayBillValidity(lrDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/extendAutoEwayBillValidityApi`, lrDto);
    }

    generateConsolidateEwaybill(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/generateConsolidateEwaybillApi`, requestDto);
    }

    generateConsolidateEwaybillLocalTrip(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/generateConsolidateEwaybillLocalTripApi`, requestDto);
    }

    generateConsolidateEwaybillForLR(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/generateConsolidateEwaybillForLRApi`, requestDto);
    }

    downloadConsolidatedEWBApi(jsonRequest: any, authToken: string): Observable<Blob> {
        const body = JSON.stringify(jsonRequest);
        console.log('body');
        console.log(body);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        });

        return this.http.post('https://api.easywaybill.in/ezewb/v1/reports/generatePdf?gstin=29AAMCS7073E1Z0', body, {
            headers: headers,
            responseType: 'blob'
        });
    }

    generateEwaybillToken(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/generateEwaybillTokenApi`, requestDto);
    }

    ewaybillPartBUpdate(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/ewaybillPartBUpdateApi`, lrDto);
    }

    validateEwaybillExpiryDateForDestLocalTrip(requestDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/getMultipleLrsEwayBillRefreshEwbApi`, requestDto);
    }

    generateDeliveryChallanForEwayBill(requestDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/generateDCForEwayBillApi`, requestDto);
    }

    validatePanAadharLinkVerification(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/validatePanAadharLinkApi`, requestDto);
    }

    getDetailsFromEwayBillSearchByGstNo(responseDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/getDetailsFromEwayBillSearchByGstNoApi`, responseDto);
    }

    generateNewEwayBill(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/generateNewEwayBillApi`, lrDto);
    }

    generateNewEwayBill_V2(lrDto: Object): Observable<LRDto[]> {
        return this.http.post<LRDto[]>(`${this._baseUrl}/generateNewEwayBill_V2Api`, lrDto);
    }

    updateAutoMultiEwayBillExpiryDate(lrDto: Object): Observable<LRDto> {
        return this.http.post<LRDto>(`${this._baseUrl}/updateAutoMultiEwayBillExpiryDateApi`, lrDto);
    }

    getDetailsFromPanCardApiServiceForLrEntry(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/getDetailsFromPanCardApiServiceForLrEntryApi`, requestDto);
    }

    getDetailsFromAadharCardApiServiceForLrEntry(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/getDetailsFromAadharCardApiServiceForLrEntryApi`, requestDto);
    }

    getDetailsFromAadharApiAfterOtpServiceForLrEntry(requestDto: Object): Observable<ResponseDto> {
        return this.http.post<ResponseDto>(`${this._baseUrl}/getDetailsFromAadharApiAfterOtpServiceForLrEntryApi`, requestDto);
    }
}
