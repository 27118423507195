<div class="col-md-12">
    <div class="form-group">
        <div class="input-group" id="{{pageId}}popupDetails">
            <ng-template #content let-a="close" let-b="close" let-c="close" let-d="dismiss">
                <div class="modal-header">
                    <h6 class="modal-title" id="{{pageId}}modal-basic-title">Initiate Advance Consent
                    </h6>
                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
					<span aria-hidden="true">&times;</span>
				</button>
                </div>
                <div *ngIf="showSpinnerForAction" class="col-md-12">
                    <div class="form-group">
                        <div class="input-group">
                            <mat-progress-bar mode="indeterminate" style="color: green;">
                            </mat-progress-bar>
                            <br>
                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                Please wait processing.....</h6>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-8">
                            <form>
                                <div class="col-sm-12 col-md-12">

                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Driver Number</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i
												class="fas fa-file-alt"></i>
											</span>
                                            </div>
                                            <input type="text" class="form-control" id="{{pageId}}driverNumber" aria-describedby="basic-addon11">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <button type="button" class="btn_custome_padding btn btn-outline-success" (click)="confirmAdvanceConsent();">Initiate Consent</button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" (click)="b('close click')">Close</button>
                </div>
            </ng-template>

            <a style="cursor: pointer; color: blue; font-weight: bolder;" (click)="open2(content)">Click Here</a>
        </div>
    </div>
</div>