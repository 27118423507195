
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from "@angular/common";
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, NavigationExtras } from "@angular/router";
import { MemoService } from "src/app/dataService/memo-service";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { UserDataDto } from "src/app/dto/UserData-dto";
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { ActivatedRoute } from "@angular/router";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import swal from 'sweetalert';
@Component( {
    selector: 'app-collectionman-gc-left-toparty-print',
    templateUrl: './collectionman-gc-left-toparty-print.component.html',
    styleUrls: ['./collectionman-gc-left-toparty-print.component.css']
} )
export class CollectionManGCLeftToPartyPrintComponent implements OnInit {
    todayDate: any;
    userDataDtoReturnSession: any;
    dateConvert: any;
    isLoggedIn = true;
    collectionMan:any;
    totalAmount:number;
    pfCollectionManDetailsDataList:any;
    totalCount:any;
    memoAmount:number;
    address:any;
    image:any;
    dataForPrintGCLeftToPartyMemo: any;
    dataForPrintGCLeftToPartyMemoList: any;
	pageId="cgclc";
    
    constructor( private router: Router, private datePipe: DatePipe, public changeDetectorRef : ChangeDetectorRef ) {

        if ( sessionStorage.length == 0 ) {
            this.isLoggedIn = false;
            //          sweet alert starts
            swal( {
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            } ).then(() => {
                this.logInPage();
            } )
            //            sweet alert ends
        }
        if ( this.isLoggedIn ) {

            this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );
            //            this.dataForPrint = localStorage.getItem( 'print' );
          
			
			this.dataForPrintGCLeftToPartyMemo = JSON.parse( localStorage.getItem( 'printGCLeftToPartyFromCollectionManRpt' ) );
            this.dataForPrintGCLeftToPartyMemoList = JSON.parse( localStorage.getItem( 'printGCLeftToPartyMemolistFromCollectionManRpt' ) );
         
	      console.log( this.dataForPrintGCLeftToPartyMemo);
           console.log( this.dataForPrintGCLeftToPartyMemoList);
            
			//For GC Left to Party  from collection Man report 
			if ( this.dataForPrintGCLeftToPartyMemo != null ) {
                this.collectionMan = this.dataForPrintGCLeftToPartyMemo.collectionMan;
            } else{
                this.collectionMan='NA';
            }
            if ( this.dataForPrintGCLeftToPartyMemoList != null ) {
                this.pfCollectionManDetailsDataList=this.dataForPrintGCLeftToPartyMemoList;
            }
			
            this.todayDate = this.datePipe.transform( new Date(), "dd-MM-yyyy" );
            
            if ( this.dataForPrintGCLeftToPartyMemoList != null ) {
                this.totalCount=this.dataForPrintGCLeftToPartyMemoList.length;
                this.totalAmount=0;
                this.memoAmount=0;
	                for ( let i = 0; i< this.dataForPrintGCLeftToPartyMemoList.length; i++) {
	                    this.memoAmount = this.dataForPrintGCLeftToPartyMemoList[i].amount;
	                    this.totalAmount= +this.totalAmount + +this.memoAmount;
	                    
	                }
                }
            this.image =this.userDataDtoReturnSession.ip;
            

            this.validatBeforePrint();
            window.setTimeout( function() {
               window.print();
            }, 1000 );
            window.onafterprint = function() {
                window.close();
            }
            localStorage.clear();
            window.addEventListener( 'afterprint', ( event ) => {
                this.clearField();
            } );
        }
    }

    ngOnInit() {

    }
    
    validatBeforePrint() {
        this.address = this.userDataDtoReturnSession.addressId == null ? ' ' : this.userDataDtoReturnSession.addressId;
    }
    clearField() {
       this.address='';
       this.collectionMan='';
       this.totalAmount=0;
       this.pfCollectionManDetailsDataList='';
       this.totalCount=0;
       this.memoAmount=0;
	   this.dataForPrintGCLeftToPartyMemo='';
	   this.dataForPrintGCLeftToPartyMemoList='';
    }

    logInPage() {
        this.router.navigate( ['/authentication/login'] );
    }
}
