<div class="card" *ngIf="isLoggedIn">
    <div class="card-body">
        <div class="d-md-flex align-items-center">
            <div>
                <h4 class="card-title">Invoice Loading Sheet</h4>

            </div>

        </div>
        <div class="row">
            <div class="col-lg-6">
                <input type="text" class="table-filter" placeholder="Type to filter the name column..." (keyup)="updateFilter($event)" />
            </div>
            <div class="col-lg-6 btn-toggle-right">
                <button type="button" class="btn btn-warning waves-light" id='newEntryBtn' name="newEntryBtn" (click)="confirmExportInvoiceLoading()"><i class="fas fa-file-export"></i>&nbsp;Export</button>

            </div>
            <div class="col-md-12">
                <div class="box-body">
                    <ngx-datatable #invoiceLoadingTable class="material" [rows]="invoiceLoadingSheet" [columns]="columns" [columnMode]="'force'" [headerHeight]="30" [footerHeight]="30" [rowHeight]="'auto'" [summaryRow]="true" [summaryPosition]="'bottom'">

                        <!-- [summaryRow]="true"    [summaryPosition]="summaryPosition" -->

                        <ngx-datatable-column name="Booking Date" prop="bookingDate">
                            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                                {{ row.bookingDate }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="LR No" prop="lrNo">
                            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                                {{ row.lrNo }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Destination" prop="destination">
                            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                                {{ row.destination }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Booked From" prop="bookedFrom">
                            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                                {{ row.bookedFrom }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="A.Wgt" prop="actualWeight">
                            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                                {{ row.actualWeight }}
                            </ng-template>

                        </ngx-datatable-column>
                        <ngx-datatable-column name="C.Wgt" prop="cWgt">
                            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                                {{ row.cWgt }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Pvt Mak" prop="pvtMak">
                            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                                {{ row.pvtMak }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Article" prop="article">
                            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                                {{ row.article }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Loading Article" prop="loadingArticle" [width]="auto">
                            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                                <!-- Display the article value -->
                                <!-- {{ row.article }} -->

                                <!-- Add a button and input field with some space between them -->
                                <!-- <button (click)="onMinusButtonClick(row)" style="margin-right: 5px;">-</button> -->
                                <button type="button" class="btn btn-info waves-light" name="newEntryBtn" style="margin-left: 5px;" (click)="onMinusButtonClick(row,1)"><i
                                        class="fas fa-minus-circle"></i></button>
                                <input type="number" [(ngModel)]="row.loadingArticle" (keypress)="articleKeyPress($event, row)" class="small-input">
                                <!-- <button (click)="onPlusButtonClick(row)" style="margin-left: 5px;">+</button> -->
                                <button type="button" class="btn btn-info waves-light" name="newEntryBtn" style="margin-left: 5px;" (click)="onPlusButtonClick(row,1)"><i
                                        class="fas fa-plus-circle"></i></button>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Notes" prop="notes">
                            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                                {{ row.notes }}
                            </ng-template>
                        </ngx-datatable-column>
                        <!-- <ngx-datatable-column name="Remarks" prop="remarks" [width]="auto">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
    
                            <input type="text" [(ngModel)]="row.remarks" placeholder="Enter Remarks">
    
                        </ng-template>
                    </ngx-datatable-column> -->
                        <ngx-datatable-column name="Actions">
                            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                                <button type='button' name='deleteBtn' id='deleteBtn' (click)="deleteInvoiceLoading(row)" class='btn btn-success btn-edit btn-icon-only blue'><i
                                        class="fa fa-trash"></i></button>
                            </ng-template>
                            <ng-template ngx-datatable-footer-template let-column="column" let-rowIndex="rowIndex">
                                <div class="footer-cell" *ngIf="column.prop === 'actualWeight'">
                                    Total: {{ calculateColumnSummary('actualWeight') }}
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <!-- <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template>
                            <div class="footer-row col-12">

                                <div class="footer-cell col-lg-1"></div>
                                <div class="footer-cell col-lg-1"></div>

                                <div class="footer-cell col-lg-1"></div>
                                <div class="footer-cell col-lg-1"></div>


                                <div class="footer-cell col-lg-1"><b> {{ actualWeight }}</b></div>


                                <div class="footer-cell col-lg-1"><b>{{ cWeight }}</b></div>




                                <div class="footer-cell col-lg-1"></div>
                                <div class="footer-cell col-lg-1"><b> {{ article }}</b></div>
                                <div class="footer-cell col-lg-1"></div>
                                <div class="footer-cell col-lg-1"></div>
                                <div class="footer-cell col-lg-1"></div>
                                <div class="footer-cell col-lg-1"></div>
                            </div>
                        </ng-template>
                    </ngx-datatable-footer> -->




                    </ngx-datatable>




                </div>
            </div>
        </div>
        <br>
        <div class="d-md-flex align-items-center">
            <div>
                <h4 class="card-title">Stock LRs</h4>

            </div>

        </div>
        <div class="row">
            <div class="col-lg-6">
                <input type="text" class="table-filter" placeholder="Type to filter the name column..." (keyup)="updateFilterStockLr($event)" />
            </div>
            <div class="col-lg-6 btn-toggle-right">
                <button type="button" class="btn btn-warning waves-light" id='newEntryBtn' name="newEntryBtn" (click)="confirmExportStockLr()"><i class="fas fa-file-export"></i>&nbsp;Export</button>

            </div>
            <div class="col-md-12">

                <ngx-datatable #stockLrTable class="material custom-datatable" [rows]="stockLrList" [columns]="columns" [columnMode]="'force'" [headerHeight]="30" [footerHeight]="30" [rowHeight]="'auto'" [scrollbarH]="auto" [groupRowsBy]="'destination'" [groupExpansionDefault]="true"
                    [summaryRow]="true" [summaryPosition]="'bottom'">
                    <!-- [summaryRow]="true"    [summaryPosition]="summaryPosition" -->
                    <ngx-datatable-group-header [rowHeight]="auto" #myGroupHeader>
                        <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
                            <div style="padding-left:5px;">
                                <a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Group" (click)="toggleExpandGroup(group)">
                                    <b>Destination Name: {{group.value[0].destination}}</b>
                                </a>
                            </div>
                        </ng-template>
                    </ngx-datatable-group-header>

                    <ngx-datatable-column name="Booking Date" prop="bookingDate">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            {{ row.bookingDate }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="LR No" prop="lrNo">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            {{ row.lrNo }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Destination" prop="destination">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            {{ row.destination }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Booked From" prop="bookedFrom">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            {{ row.bookedFrom }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="A.Wgt" prop="actualWeight">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            {{ row.actualWeight }}
                        </ng-template>

                    </ngx-datatable-column>
                    <ngx-datatable-column name="C.Wgt" prop="cWgt">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            {{ row.cWgt }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Pvt Mak" prop="pvtMak">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            {{ row.pvtMak }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Article" prop="article">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            {{ row.article }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Notes" prop="notes">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            {{ row.notes }}
                        </ng-template>
                    </ngx-datatable-column>
                    <!-- <ngx-datatable-column name="Remarks" prop="remarks" [width]="auto">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
    
                            <input type="text" [(ngModel)]="row.remarks" placeholder="Enter Remarks">
    
                        </ng-template>
                    </ngx-datatable-column> -->
                    <ngx-datatable-column name="Actions">
                        <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                            <button type='button' name='addBtn' id='addBtn' (click)="addStockLr(row)" class='btn btn-success btn-edit btn-icon-only blue'><i class="fa fa-plus"></i></button>
                        </ng-template>

                    </ngx-datatable-column>

                    <!-- <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template>
                            <div class="footer-row col-12">
                
                                <div class="footer-cell col-lg-2"></div>
                                <div class="footer-cell col-lg-1"></div>
                
                                <div class="footer-cell col-lg-1"></div>
                                <div class="footer-cell col-lg-1"></div>
                
                                <div class="footer-cell col-lg-1" style="white-space: nowrap;"><b> {{ actualWeightStock }}</b></div>
                                <div class="footer-cell col-lg-1" style="white-space: nowrap;"><b> {{ cWeightStock }}</b></div>
                
                                <div class="footer-cell col-lg-1"></div>
                                <div class="footer-cell col-lg-1" style="white-space: nowrap;"><b> {{ articleStock }}</b></div>
                                <div class="footer-cell col-lg-1"></div>
                                <div class="footer-cell col-lg-1"></div>
                
                            </div>
                        </ng-template>
                    </ngx-datatable-footer> -->




                </ngx-datatable>



            </div>

        </div>

    </div>
</div>