import { Component, OnInit, ViewChildren, QueryList,ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';

import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ConsignorService } from 'src/app/dataService/consignor-service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { DragulaService, dragula } from "ng2-dragula";
//for drag and drop ends
//for popup modal starts 
import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from "src/app/dataService/memo-service";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MemoReport } from 'src/app/dataService/memo-report';
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment  from 'moment';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
import { MasterService } from "src/app/dataService/master-service";
import { InvoiceDto } from "src/app/dto/Invoice-dto";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { HireslipService } from "src/app/dataService/hireslip-service";


@Component({
  selector: 'app-hireslip-unloading-report',
  templateUrl: './hireslip-unloading-report.component.html',
  styleUrls: ['./hireslip-unloading-report.component.css']
})
export class HireslipUnloadingReportComponent implements OnInit {
getDataFrmServiceFrHireslipUnloadingRptTable: any;
hireslipUnloadingRptDataList: any; 
//summaryTable:any;
//for datepicker starts
model: NgbDateStruct;
model2;
//for datepicker ends

loadingIndicator = true;
searchByHireslipNo = true;
searchByDestination=false;

//for datePicker starts
hoveredDate: NgbDateStruct;
fromDate: NgbDateStruct;
toDate: NgbDateStruct;
closeResult: string;
//for datepicker ends
    

@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

dtTriggerHireslipUnloadingRpt: Subject<any> = new Subject();

        dataTable: any;
        dtOptionsHireslipUnloadingRpt: any;
        

//For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	selectedDestination:any;
	address:any;
    office:any;
	superAdminView=false;
    viewDestination=false;
	selectedSearchType:any;
	fromDateModel:any;
	toDateModel:any;
	selectedFromDate:any;
	selectedToDate:any;
	enteredHireslipNumber:any;
    hireslipDtoSearchDetails:HireSlipDto = new HireSlipDto();
	unloadingSheetDetailsPopUp: NgbModalRef;
	unloadingSheetDetailsClosePopUp: string;
	@ViewChild('unloadingSheetDetailsPopUpTemplate') private unloadingSheetDetailsPopUpTemplate;
	viewUnloadingSheetDetailsPopUp=false;
	invoicedtoForSearchOtherDetails:InvoiceDto = new InvoiceDto();
	hireslipdtoGetDetailsForPopup:InvoiceDto = new InvoiceDto();
	hireslipdtoRecDetails:any;
	ShowDestForUnLoadRpt = "Show Dest In UnLoadRpt";

//For Custom Print
cashMemoDtoForCustomPrintListHeadingV1: any;
cashMemoDtoForCustomPrintListHeadingV2: any;
cashMemoDtoForCustomPrintListHeadingV3: any;
cashMemoDtoForCustomPrintListHeadingV4: any;
cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
cashMemoDtoForCustomPrintList: any;
cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
cashMemoDtoForCustomPrintDataList: any;
cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
cashMemoDtoForCustomPrintDataSummaryList: any;
viewCustomPrintV1: boolean;

totArtSummary:number;
gurWtSummary:number;
kantaWtSummary:number;
viewUnloadingRptPopupGrpPrint=false;
pageId="hurc";

    constructor(private router: Router, private masterReadService: MasterReadService, private datePipe: DatePipe, private lrReportService: LrReportService, private masterService: MasterService, private consigneeService: ConsigneeService, private hireslipService: HireslipService, private modalService: NgbModal,
   	public changeDetectorRef : ChangeDetectorRef) {

        	  if (sessionStorage.length == 0) {
                  this.isLoggedIn = false;
                  swal({
                      title: "Session Expired",
                      text: "Please relogin to access the application!",
                      icon: "error",
                      closeOnClickOutside: false,
                      closeOnEsc: false,
                  }).then(() => {
                      this.logInPage();
                  })
              }
              if (this.isLoggedIn) {
                  this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
                  this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
				  this.office = this.userDataDtoReturnSession.office == null ? '' : this.userDataDtoReturnSession.office;
				 
				  
				    ///Rights for show Show Source List
                 //            if ( this.ShowDestForUnLoadRpt == "Show Dest In UnLoadRpt" ) {
                 //                this.viewDestination = true;
                 //this.superAdminView = true;
                 //this.getDestinationDetails();         
                    //} 
				  
                  if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights != null ) {
                     
                          for ( let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++ ) {
                            if ( this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                                  && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                                  "SuperAdminView UnLoadRpt" ) {
                            	this.superAdminView = true;	
                              }		  
                          
                          }
                      }
                  this.superAdminView =true;
                  if (this.superAdminView ==true) {
					   this.viewDestination=true;
					  this.getDestinationDetails();
					 }else{
					  this.viewDestination=false;	 
					 }
              }
            
        }
        logInPage() {
            this.router.navigate(['/authentication/login']);
        }
      
		clickTA(inp) {
            inp._elementRef.nativeElement.value = '';
            inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
            inp._elementRef.nativeElement.focus();
        }
        
        rerender(): void {
            this.dtElements.forEach((dtElement: DataTableDirective) => {
              dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
				dtInstance.destroy();
              });
            });
          }

   ngOnInit(): void {
	   var companyAddressDetls = this.address;
		var mainStation =this.userDataDtoReturnSession.mainStation;
		var destVisible=false;
		  if (this.superAdminView ==true) {
			 destVisible=true;	  
		  }else{
			 destVisible=false;	  
		  }		
  		 this.dtOptionsHireslipUnloadingRpt = {                    
                  dom: 'Bfrtip',                   
                     buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer : true,
					title : function() {
					
					var returnSummaryExcel=null;
						if (destVisible == true) {
						if($("#hurcsearchBy").val()=='hireslipNumberWise'){
							returnSummaryExcel = "Hireslip Unloading Details Report"+
									" Hireslip Number : "+$("#hurchireslipNumber").val();
						}else{
						returnSummaryExcel = "Hireslip Unloading Details Report"+
									" From Date : "+ moment($("#hurcfromDate").val()).format('DD-MM-YYYY') + " -  "+
									"To Date : " +  moment($("#hurctoDate").val()).format('DD-MM-YYYY')+" Destination : "+$("#hurcdestination").val();
						}
							
						} else {
							if($("#hurcsearchBy").val()=='hireslipNumberWise'){
							returnSummaryExcel = "Hireslip Unloading Details Report"+
									" Hireslip Number : "+$("#hurchireslipNumber").val();
							}else{
							returnSummaryExcel = "Hireslip Unloading Details Report"+
										" From Date : "+ moment($("#hurcfromDate").val()).format('DD-MM-YYYY') + " -  "+
										"To Date : " +  moment($("#hurctoDate").val()).format('DD-MM-YYYY')+" Destination : "+mainStation;
						}
						}
						return returnSummaryExcel;
					},
					exportOptions: {
						columns: ':visible'
					}
				}
			], 
					 processing: true,
            			//scroll in datatable starts
            			responsive: true,
            			"scrollX": false,
            			"scrollY":300,
            			"scrollCollapse": true,
            			"paging": true,
            			"info": true,
              			"footerCallback": function ( row, data, start, end, display ) {
              				var api = this.api(), data;
              				// converting to interger to find total
              				var intVal = function ( i ) {
              					return typeof i === 'string' ?
              					+i.replace(/[\$,]/g, '') * 1:
              						typeof i === 'number' ?
              							i : 0;
              				};  
								
						var articles = api.column( 3 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
							
						var actualWgt = api.column( 4 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
						var chargedWgt = api.column( 5 ).data().reduce( 
						function (a, b) {
									 return intVal(a) + intVal(b);
						}, 0 );
						
						$( api.column( 0 ).footer() ).html('Total : '+data.length);
						$( api.column( 1 ).footer() ).html();
						$( api.column( 2 ).footer() ).html();
						$( api.column( 3 ).footer() ).html(articles);
						$( api.column( 4 ).footer() ).html(actualWgt);
						$( api.column( 5 ).footer() ).html(chargedWgt);
							
                          }
                      
                }
           
        }
        
      
     
        ngOnDestroy(): void {
            this.dtTriggerHireslipUnloadingRpt.unsubscribe();
    
        }
         
        ngAfterViewInit(): void {
			this.dtTriggerHireslipUnloadingRpt.next();	
		}
 		getDestinationMethod() {
            this.lrDtoDestination = new LRDto();
            this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
            this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
            this.lrDtoDestination.mode = "desttrsp";
        }
        getDestinationDetails() {
            this.getDestinationMethod();
            this.showSpinnerForAction=true;
            this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
                (response) => {
                	 this.showSpinnerForAction=false;
                    if (response) {
                        //this.stationOptions = response;
                        if (response.length > 0) {
                            this.destinationOptions = response;
                            this.destinationTA = [];
                            for (let i = 0; i < this.destinationOptions.length; i++) {
                                this.destinationTA.push(this.destinationOptions[i]);
                            }
                            
                        }
                    }
                    this.changeDetectorRef.detectChanges();
                }),(error) => {
              			this.showSpinnerForAction = false;
              		   swal("Error","Server Problem Occurred While getting the Destination Details","info");
              		},
              		   () => console.log('done');
                    
        };
        
       
        
        searchByMode( searchBy: string ) {
            if ( searchBy === 'hireslipNumberWise' ) {
                this.searchByHireslipNo = true;
                this.searchByDestination=false;
				$("#"+this.pageId+"fromDate").val('');
				$("#"+this.pageId+"toDate").val('');
				$("#"+this.pageId+"hireslipUnloadingRptId").DataTable().destroy();
				this.hireslipUnloadingRptDataList=[];
				this.dtTriggerHireslipUnloadingRpt.next();
				$("#"+this.pageId+"hireslipNumber").val('');
				 if (this.superAdminView ==true) {
				   this.viewDestination=true;
				  $("#"+this.pageId+"destination").val('');
				 }else{
				  this.viewDestination=false;	 
				 }
				 this.modelDestination='';
				 this.fromDateModel=null;
					this.toDateModel=null;

            } else if ( searchBy === 'destinationWise' ) {
                this.searchByHireslipNo = false;
                this.searchByDestination=true;
				$("#"+this.pageId+"fromDate").val('');
				$("#"+this.pageId+"toDate").val('');
				
				$("#"+this.pageId+"hireslipUnloadingRptId").DataTable().destroy();
				this.hireslipUnloadingRptDataList=[];
				this.dtTriggerHireslipUnloadingRpt.next();
				$("#"+this.pageId+"hireslipNumber").val('');
				 if (this.superAdminView ==true) {
				   this.viewDestination=true;
				  $("#"+this.pageId+"destination").val('');
				 }else{
				  this.viewDestination=false;	 
				 }
				 this.modelDestination='';
				 this.fromDateModel=null;
					this.toDateModel=null;
                
            }  else {
				$("#"+this.pageId+"fromDate").val('');
				$("#"+this.pageId+"toDate").val('');
                this.searchByHireslipNo = false;
                this.searchByDestination=false;
				 $("#"+this.pageId+"hireslipUnloadingRptId").DataTable().destroy();
				this.hireslipUnloadingRptDataList=[];
				this.dtTriggerHireslipUnloadingRpt.next();
				$("#"+this.pageId+"hireslipNumber").val('');
				 if (this.superAdminView ==true) {
			   this.viewDestination=true;
			  $("#"+this.pageId+"destination").val('');
			 }else{
			  this.viewDestination=false;	 
			 }
				 this.modelDestination='';
				 this.fromDateModel=null;
					this.toDateModel=null;
                
            }
        }
      
        selectToday() {
            this.model = {
                year: my.getFullYear(),
                month: my.getMonth() + 1,
                day: my.getDate()
            };
        }
		
	searchMethod(){
		this.selectedSearchType= $("#"+this.pageId+"searchBy").val();
		this.selectedFromDate=$("#"+this.pageId+"fromDate").val();
		this.selectedToDate=$("#"+this.pageId+"toDate").val();
		this.selectedDestination=$("#"+this.pageId+"destination").val();
		this.enteredHireslipNumber=$("#"+this.pageId+"hireslipNumber").val();
		
		 this.hireslipDtoSearchDetails = new HireSlipDto();
		
		if(this.selectedSearchType=='hireslipNumberWise'){
			if(this.enteredHireslipNumber==null ||this.enteredHireslipNumber=='' ){
			  swal({
					 title: "Warning",
					 text: "Please Enter Hireslip Number",
					 icon: "warning",
					 closeOnClickOutside: false,
					 closeOnEsc: false,
				 });
				 return false;
			}else{
			
			 if (this.superAdminView ==true) {
			  this.hireslipDtoSearchDetails.mode ="HireSlipNo"
			 }else{
			  this.hireslipDtoSearchDetails.mode ="HireSlipNoSpec"
			  this.hireslipDtoSearchDetails.toStation = this.userDataDtoReturnSession.mainStation;
			 }
			  this.hireslipDtoSearchDetails.hireslipnumber =this.enteredHireslipNumber;
			}
		}else if(this.selectedSearchType=='destinationWise'){
		 if (this.superAdminView ==true) {
				if(this.selectedFromDate == null || this. selectedFromDate =='' || this.selectedToDate == null || this.selectedToDate =='' ||this.selectedDestination == null ||this.selectedDestination ==''){	
				  swal( {
			                title: "Mandatory Field",
			                text: "From Date , To Date , Destination Is Mandatory Fields",
			                icon: "warning",
			                closeOnClickOutside: false,
			                closeOnEsc: false,
			            } );
						return false;
					}else{
						 this.hireslipDtoSearchDetails.mode ="DateWise";
						this.hireslipDtoSearchDetails.toStation = this.selectedDestination;
						this.hireslipDtoSearchDetails.fromDate = this.selectedFromDate;
						this.hireslipDtoSearchDetails.toDate = this.selectedToDate;
					
					}
		
		  }else{
				if(this.selectedFromDate == null || this. selectedFromDate =='' || this.selectedToDate == null || this.selectedToDate =='' ){	
				  swal( {
			                title: "Mandatory Field",
			                text: "From Date , To Date Is Mandatory Fields",
			                icon: "warning",
			                closeOnClickOutside: false,
			                closeOnEsc: false,
			            } );
						return false;
					}else{
						 this.hireslipDtoSearchDetails.mode ="DateWise";
						this.hireslipDtoSearchDetails.toStation = this.userDataDtoReturnSession.mainStation;
						this.hireslipDtoSearchDetails.fromDate = this.selectedFromDate;
						this.hireslipDtoSearchDetails.toDate = this.selectedToDate;
					
					}
		  
		  }
	 
	    
	   }
		console.log(this.hireslipDtoSearchDetails);
		this.searchMethodDetails();
   }
   
		searchMethodDetails = () => {
    	         this.showSpinnerForAction = true;
    	         this.hireslipService.getUnloadingRptDtls(this.hireslipDtoSearchDetails).subscribe(
    	             (response) => {
    	            	 this.showSpinnerForAction=false;
						  $("#"+this.pageId+"hireslipUnloadingRptId").DataTable().destroy();
							this.hireslipUnloadingRptDataList=[];
               
    	                 if (response.length == 0) {
    	                     swal({
    	                         title: "Warning",
    	                         text: "No  Details found !",
    	                         icon: "warning",
    	                         closeOnClickOutside: false,
    	                         closeOnEsc: false,
    	                     });
    	                     
    	                 } else {
    	               	  this.hireslipUnloadingRptDataList=response;
    	               	  console.log(this.hireslipUnloadingRptDataList);
    	                }
    	                 this.dtTriggerHireslipUnloadingRpt.next();
    	                 this.changeDetectorRef.detectChanges();
    	             }), (error) => {
                         this.showSpinnerForAction = false;
                        swal("Error", "Server Problem Occurred While getting the  Hireslip Unloading details", "info");
                    }, () => console.log('done');
            };
			

    unloadingSheetDetailsPopUpBtnLink(unloadingSheetDetailsPopUpTemplate,hireslipUnloadingRptData) {
		this.invoicedtoForSearchOtherDetails = new InvoiceDto();
		this.invoicedtoForSearchOtherDetails.invoiceNumber = hireslipUnloadingRptData.hireslipnumber;
		this.hireslipService.getInvoiceDetails(this.invoicedtoForSearchOtherDetails).subscribe(
			(response) => {
				if (response) {
					this.hireslipdtoRecDetails = response;
					console.log(this.hireslipdtoRecDetails);
					this.hireslipdtoGetDetailsForPopup=new InvoiceDto();
					this.hireslipdtoGetDetailsForPopup=this.hireslipdtoRecDetails;
					
					this.unloadingSheetDetailsPopUp = this.modalService.open(unloadingSheetDetailsPopUpTemplate,
					{ centered: true,size: 'lg', windowClass: "modalClassForPopUpTruckOwner" });
				this.unloadingSheetDetailsPopUp.result.then((result) => {
					this.unloadingSheetDetailsClosePopUp = `Closed with: ${result}`;
				}, reason => {
					this.unloadingSheetDetailsClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
				});
				localStorage.clear();
				localStorage.setItem('popupUnloadingSheetDetails', JSON.stringify(hireslipUnloadingRptData));
				localStorage.setItem('popupUnloadingSheetOtherPrintDetails', JSON.stringify(this.hireslipdtoGetDetailsForPopup));
				this.viewUnloadingSheetDetailsPopUp = true;
				window.addEventListener('afterPopUp', (event) => {
					this.viewUnloadingSheetDetailsPopUp = false;
				});
					
				} else {
					swal("No Records", "No Records found for this search", "info");
				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				swal("Error", "Server Problem Occurred While getting the Memo Less Description Details", "info");
			},
			() => console.log('done');
			
		
	}
	
		
	closeUnloadingSheetDetailsPopUp() {
		this.unloadingSheetDetailsPopUp.close();
	}
	
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
	
	clearMethod(){
		this.selectedSearchType='';
		this.searchByHireslipNo = true;
        this.searchByDestination=false;
		$("#"+this.pageId+"searchBy").val('hireslipNumberWise');
		this.selectedFromDate='';
		$("#"+this.pageId+"fromDate").val('');
		this.selectedToDate='';
		$("#"+this.pageId+"toDate").val('');
		this.selectedDestination='';
		this.enteredHireslipNumber='';
		$("#"+this.pageId+"hireslipUnloadingRptId").DataTable().destroy();
		this.hireslipUnloadingRptDataList=[];
		this.dtTriggerHireslipUnloadingRpt.next();
		$("#"+this.pageId+"hireslipNumber").val('');
		 if (this.superAdminView ==true) {
		   this.viewDestination=true;
		  $("#"+this.pageId+"destination").val('');
		 }else{
		  this.viewDestination=false;	 
		 }
		 this.modelDestination='';
		 this.fromDateModel=null;
		this.toDateModel=null;
		 this.hireslipDtoSearchDetails = new HireSlipDto();
		 this.invoicedtoForSearchOtherDetails = new InvoiceDto();
		 this.hireslipdtoGetDetailsForPopup=new InvoiceDto();
		localStorage.clear();
		this.showSpinnerForAction = false;
		this.hireslipdtoRecDetails='';
	
	}
			
	customPrintHireSlipUnloadRpt() {
		if (this.hireslipUnloadingRptDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["Hireslip No", "Hireslip Date", "Booking Station", "Total Articles", "Gurantee Weight", "Kanta Weight"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [25, 15, 15, 15, 15, 15];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}
			this.cashMemoDtoForCustomPrintDataList = [];
			this.cashMemoDtoForCustomPrintDataSummaryList = [];

			//heading logics For Date
			this.selectedFromDate = $("#"+this.pageId+"fromDate").val();
			this.selectedToDate = $("#"+this.pageId+"toDate").val();
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From","To"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate,this.selectedToDate ];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}

			///Heaading Logics For Party Name
			this.selectedDestination = $("#"+this.pageId+"destination").val();
		
			this.cashMemoDtoForCustomPrintListHeadingV2 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Destination"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedDestination ];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
				this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
			}

			this.totArtSummary=this.gurWtSummary=this.kantaWtSummary=0;
			
			for (let i = 0; i < this.hireslipUnloadingRptDataList.length; i++) {
				this.cashMemoDtoForCustomPrintListColumnValues = [this.hireslipUnloadingRptDataList[i].hireslipnumber,this.hireslipUnloadingRptDataList[i].hireSlipDateStr, 
				this.hireslipUnloadingRptDataList[i].fromStation, this.hireslipUnloadingRptDataList[i].totalArticles,
				this.hireslipUnloadingRptDataList[i].gurWeight, this.hireslipUnloadingRptDataList[i].kantaWeight];
				
				/////////
				this.totArtSummary=this.totArtSummary+this.hireslipUnloadingRptDataList[i].totalArticles;
				this.gurWtSummary=this.gurWtSummary+this.hireslipUnloadingRptDataList[i].gurWeight;
				this.kantaWtSummary=this.kantaWtSummary+this.hireslipUnloadingRptDataList[i].kantaWeight;
				/////
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

			}
			this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.hireslipUnloadingRptDataList.length, "", "",this.totArtSummary,this.gurWtSummary,this.kantaWtSummary ];

			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
				this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
			}

			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
			localStorage.setItem('printTitle', "Hireslip Unloading Details Report");
			this.viewCustomPrintV1 = true;

			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});

		}
	}
	
	unloadingRptPopupGrp() {
			this.unloadingSheetDetailsPopUp.close();
			this.viewUnloadingRptPopupGrpPrint = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewUnloadingRptPopupGrpPrint) {
					this.viewUnloadingRptPopupGrpPrint = false;
					localStorage.clear();
				}
			});
	};
       
}
