export class ResponseDto {
    status: string;
    successful: string;
    message: string;
    ewayBillNo: string;
    token1: string;
    flag1: boolean;
    orgId: number;
    token2: string;
    mobileNo: number;
    pincode: string;
    type: string;
    mode: string;
  blockedCommodity: Boolean;
}