<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">{{consigneeNameOnLoadSet}} Details Report {{fromDateOnLoadSet}} to
						{{toDateOnLoadSet}}</h6>
				</div>
				<div class="row system_responsive">
					<div class="col-md-12  p-t-10">
						<div class="row">
							<!-- column -->
							<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
								<div class="form-group">
									<div class="input-group">
										<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
										<br>
										<h6 class="card-title" align='center'
											style="color: green; margin: auto; font-size: 18px;">
											Please Wait Loading Details.....</h6>
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<div class="card-body">
									<div class="box-body">
										<table datatable id="{{pageId}}businessStartLrsSummaryTableId"
											class="table table-striped table-bordered row-border hover"
											[dtOptions]="dtOptionsBusinessStartLrsSummaryReport"
											[dtTrigger]="dtTriggerBusinessStartLrsSummaryReport">

											<thead>
												<tr>
													<th>LR No</th>
													<th>Booking Date</th>
													<th>Source</th>
													<th>Commodity</th>
													<th>Articles</th>
													<th>Act Wt</th>
													<th>Chg Wt</th>
													<th>To Pay</th>
													<th>Paid</th>
												</tr>
											</thead>
											<tbody>
												<tr
													*ngFor="let businessStartRptLrsSummaryData of businessStartRptLrsSummaryList  ">
													<td>{{businessStartRptLrsSummaryData.lrNumber }}</td>
													<td>{{ businessStartRptLrsSummaryData.bookingDateStr}}
													</td>
													<td>{{ businessStartRptLrsSummaryData.agentName }}
													</td>
													<td>{{businessStartRptLrsSummaryData.commodityName }}
													</td>
													<td>{{ businessStartRptLrsSummaryData.totalArticles }}</td>
													<td>{{ businessStartRptLrsSummaryData.actualWeight }}</td>
													<td>{{businessStartRptLrsSummaryData.chargedWeight }}
													</td>
													<td>{{ businessStartRptLrsSummaryData.toPay }}
													</td>
													<td>{{ businessStartRptLrsSummaryData.paid }}
													</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
												</tr>
											</tfoot>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>