<html>

<head>
</head>

<body>

	<!-- Row -->
	<div class="row">
		<div class="col-lg-12">
			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">LR Details</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-12">
						<!-- spinner start-->
						<div [hidden]="!showSpinnerForAction" class="col-md-9 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<!-- spinner end-->
						<div class="box-body">
							<table datatable id="{{pageId}}stockUnloadingDetainedTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsUnloadingReportDetained"
								[dtTrigger]="dtTriggerUnloadingReportDetained">
								<thead>
									<tr>
										<th>LR No</th>
										<th>LR Status</th>
										<th>Consignee</th>
										<th>Art</th>
										<th>Pack Nature</th>
										<th>Delivery Point</th>
										<th>Notice No</th>
										<th>Check Post Name</th>
										<th>Status</th>
										<th>Check Post Date</th>
										<th>Pvt Marker</th>
										<th [hidden]="!notshowcolumn">Case</th>
										<th [hidden]="!notshowcolumn">GCase</th>
										<th [hidden]="!notshowcolumn">Cart</th>
										<th [hidden]="!notshowcolumn">GCart</th>
										<th [hidden]="!notshowcolumn">Bundle</th>
										<th [hidden]="!notshowcolumn">Roll</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let unloadingReportDetainedData of unloadingReportDetainedDataList ">
										<td>{{unloadingReportDetainedData.lrNumber}}</td>
										<td>{{unloadingReportDetainedData.lrStatus}}</td>
										<td>{{unloadingReportDetainedData.consigneeName}}</td>
										<td>{{unloadingReportDetainedData.totalArticles}}</td>
										<td>{{unloadingReportDetainedData.packNature}}</td>
										<td>{{unloadingReportDetainedData.deliveryPoints}}</td>
										<td>{{unloadingReportDetainedData.noticeNumber}}</td>
										<td>{{unloadingReportDetainedData.checkPostName}}</td>
										<td>{{unloadingReportDetainedData.status}}</td>
										<td>{{unloadingReportDetainedData.date}}</td>
										<td>{{unloadingReportDetainedData.privateMarker}}</td>
										<td [hidden]="!notshowcolumn"></td>
										<td [hidden]="!notshowcolumn"></td>
										<td [hidden]="!notshowcolumn"></td>
										<td [hidden]="!notshowcolumn"></td>
										<td [hidden]="!notshowcolumn"></td>
										<td [hidden]="!notshowcolumn"></td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td [hidden]="!notshowcolumn"></td>
										<td [hidden]="!notshowcolumn"></td>
										<td [hidden]="!notshowcolumn"></td>
										<td [hidden]="!notshowcolumn"></td>
										<td [hidden]="!notshowcolumn"></td>
										<td [hidden]="!notshowcolumn"></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>
</body>

</html>