import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';

export interface DialogData {
	animal: string;
	name: string;
}
@Component({
	selector: 'app-consignee-mobilenumber-update-datatable',
	templateUrl: './consignee-mobilenumber-update-datatable.component.html',
	styleUrls: ['./consignee-mobilenumber-update-datatable.component.css']
})
export class ConsigneeMobileNumberUpdateDatatableComponent implements OnInit {

	gridConsigneeMobileNoList: any;
	onDestroyUnsubscribtionHamaliDetails: Subscription;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerConsigneeMultipleMobileNoList: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsConsigneeMultipleMobileNoList: any;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;
	showSpinnerForAction = false;
	model: NgbDateStruct;
	model2;
	loadingIndicator = true;
	popUpOpenConsigneeMobileNoData: any;
	popUpOpenConsigneeMobileNoListLocalStorage: any;
	partyMasterDto: PartyMasterDto = new PartyMasterDto();
	viewCustomPrintV1 = false;
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;
	fromDatePrint: any;
	toDatePrint: any;
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
	//Custom Print Footer
	summary1: number;
	summary2: number;
	summary3: number;
	summary4: number;
	summary5: number;
	summary6: number;
	pageId = "cmndc";

	constructor(private router: Router, private masterReadService: MasterReadService,
		public dialogRef: MatDialogRef<ConsigneeMobileNumberUpdateDatatableComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			console.log(data);
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.popUpOpenConsigneeMobileNoData = JSON.parse(localStorage.getItem('ConsigneeGridViewMobileNo'));
			this.popUpOpenConsigneeMobileNoListLocalStorage = JSON.parse(localStorage.getItem('ConsigneeGridViewMobileNoLocalStorage'));
			localStorage.clear();
			if (this.popUpOpenConsigneeMobileNoData != null &&
				this.popUpOpenConsigneeMobileNoData != undefined &&
				this.popUpOpenConsigneeMobileNoData == "ConsigneeGridViewMobileNo") {
				if (this.popUpOpenConsigneeMobileNoListLocalStorage != null &&
					this.popUpOpenConsigneeMobileNoListLocalStorage != undefined) {
					this.gridReconfigure();
				}
			}
		}
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		this.dtOptionsConsigneeMultipleMobileNoList = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						return "In Complete Consignees Details Report - ";
					},
				}, {
					extend: 'print',
					text: '<i class="fas fa-print"> Print</i>',
					titleAttr: 'Print',
					footer: true,
					exportOptions: {
						columns: [0, 1, 2, 3, 4, 5, 6, 8, 9]
					}
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			searching: false,
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 200,
			"scrollCollapse": true,
			"paging": false,
			"info": true,
		}
	}


	ngOnDestroy(): void {
		//this.dtTriggerConsigneeMultipleMobileNoList.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerConsigneeMultipleMobileNoList.next();
	}

	gridReconfigure() {
		this.partyMasterDto = new PartyMasterDto();
		this.partyMasterDto.city = this.popUpOpenConsigneeMobileNoListLocalStorage;
		this.partyMasterDto.companyId = this.userDataDtoReturnSession.companyId;
		this.showSpinnerForAction = true;
		this.masterReadService.getConsigneeMasterMobileNoUpdation(this.partyMasterDto).subscribe(
			(response) => {
				$("#" + this.pageId + "consigneeMobileNoListTableId").DataTable().destroy();
				this.gridConsigneeMobileNoList = [];
				if (response.length > 0) {
					this.gridConsigneeMobileNoList = response;
				} else {
					swal("Empty", "No datas found for this information", "warning");
				}
				this.dtTriggerConsigneeMultipleMobileNoList.next();
				this.showSpinnerForAction = false;
				console.log(response);
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Consignee Master Mobile No Updation", "warning");
			},
			() => console.log('done');
	}


	onCancelClick(): void {
		this.dialogRef.close("Close");
		localStorage.clear();
	}

	onOkClickBtn(): void {
		if (this.gridConsigneeMobileNoList != null &&
			this.gridConsigneeMobileNoList != undefined &&
			this.gridConsigneeMobileNoList.length > 0) {
			this.dialogRef.close(JSON.stringify(this.gridConsigneeMobileNoList));
		} else {
			this.gridConsigneeMobileNoList = [];
			this.dialogRef.close(JSON.stringify(this.gridConsigneeMobileNoList));
		}
	}

	customPrint() {
		if (this.gridConsigneeMobileNoList.length == 0) {
			swal({
				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = [
				"Consignee Name",
				"GST Number",
				"Contact Person",
				"Mobile Number",
				"Office Number",
				"Email Id",
				"Address",
				"Collection Man",
				"Commodity"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [15, 10, 10, 10, 10, 10, 15, 10, 10];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];

			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.summary1 = this.summary2 = this.summary3 = this.summary4 = this.summary5 = this.summary6 = 0;
			for (let i = 0; i < this.gridConsigneeMobileNoList.length; i++) {
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintListColumnValues = [
					this.gridConsigneeMobileNoList[i].consigneeName,
					this.gridConsigneeMobileNoList[i].gstNoConsignee,
					this.gridConsigneeMobileNoList[i].contactPerson,
					this.gridConsigneeMobileNoList[i].mobileNumber,
					this.gridConsigneeMobileNoList[i].officeNumber,
					this.gridConsigneeMobileNoList[i].email,
					this.gridConsigneeMobileNoList[i].address,
					this.gridConsigneeMobileNoList[i].collectionMan,
					this.gridConsigneeMobileNoList[i].multipleCommodity
				];
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

				// this.summary1 = this.summary1 + this.issueBalanceReportDataList[i].totalLrs;
			}

			this.cashMemoDtoForCustomPrintDataSummaryList = [
				"Total : " + this.gridConsigneeMobileNoList.length,
				"", "", ""];
			//heading logics For Date
			//this.fromDatePrint = $("#"+this.pageId+"fromDates").val();
			//this.toDatePrint = $("#"+this.pageId+"toDates").val();
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["City"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.popUpOpenConsigneeMobileNoListLocalStorage];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "In Complete Consignees Details Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}


}

