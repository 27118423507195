//PFCHG_V1 @Asrar Chg, for binnymills, can assigne memos which are in billing stock(GC Not rec)
//PFCHG_V2 @Asrar Chg, added 'Complete for Direct Assigned' option for specCollection
//PFCHG_V3 @Asrar Chg, send whatsapp notification of collection closed details to consignee
//PFCHG_V4 @Asrar Chg, validate for lefttoparty stmt assign in consignee master and if allow 'No' then block to create stmt for tht consignee @Senthils Sir Dated : 09/11/2023
//PFCHG_V5 @Imran Chg, Added Debit mode and upi in payment mode added on 10052024
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ConsignorService } from 'src/app/dataService/consignor-service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from "src/app/dataService/memo-service";
import swal from 'sweetalert';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";

@Component({
    selector: 'app-payment-followup',
    templateUrl: './payment-followup.component.html',
    styleUrls: ['./payment-followup.component.css']
})
export class PaymentFollowupComponent implements OnInit {

    isLinear = false;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    thirdFormGroup: FormGroup;
    fourthFormGroup: FormGroup;
    //    the payment followup starts
    manualView = true;
    automaticView = false;

    //for entry less starts
    entryLessManualView = true;
    entryLessAutomaticView = false;

    //for entry less ends
    //    for payemt entry starts
    //for hide and show starts
    viewByCash = false;
    viewByCashs = false;
    viewByChequeNumber = false;
    viewByChequeDate = false;
    viewByConsigneeBankName = false;
    viewByReferenceNumber = false;
    paymentEntryManualView = true;
    paymentEntryAutomaticView = false;

    consigneeWiseDetails = false;

    //for hide and show starts
    //  for payemt entry ends
    //    pages starts
    //to insert value of selected row in table to input field starts 
    consignorName: string;
    selectedUser: any;
    //to insert value of selected row in table to input field ends
    //    GETDATAFRMSERVICEFRPFASSIGNEDJOBDETAILSTABLE: any;
    //for entry less starts
    getDataFrmServiceFrLessEntriesDataTableTable: any;
    getDataFrmServiceFrConsigneeWiseBalanceDetailsTable: any;
    //for entry less ends
    //for Payment entry Starts
    getDataFrmServiceFrPaymentAmountEntriesDataTable: any;
    //for Payment entry ends
    pfCollectionManDetailsDataList: Array<any> = [];
    pfCollectionManDetailsDataListForSearch: Array<any> = [];
    pfAssignedJobDetailsDataList: Array<any> = [];
    findModel: Array<any> = [];

    //for entry less starts
    lessEntriesDataTableDataList: Array<any> = [];
    consigneeWiseBalanceDetailsDataList: Array<any> = [];
    //for entry less ends
    //for Payment entry Starts
    paymentAmountEntriesDataList: Array<any> = [];
    //for Payment entry ends


    onDestroyUnsubscribtionPfCollectionManDetails: Subscription;
    onDestroyUnsubscribtionPfAssignedJobDetails: Subscription;

    //for entry less starts
    onDestroyUnsubscribtionLessEntriesDataTable: Subscription;
    onDestroyUnsubscribtionConsigneeWiseBalanceDetails: Subscription;
    //for entry less ends
    //for Payment entry Starts
    onDestroyUnsubscribtionPaymentAmountEntries: Subscription;
    //for Payment entry ends
    //for the select option with filter starts

    //for datepicker starts
    model: NgbDateStruct;
    model2;
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    //for datepicker ends


    controlActionType = new FormControl();
    controlCollectionMan = new FormControl();
    controlStation = new FormControl();
    controlCollectionManAssign = new FormControl();
    controlCollectionManToParty = new FormControl();
    //for entry less starts
    controlConsigneeName = new FormControl();
    controlEntryLessStation = new FormControl();
    controlEntryLessType = new FormControl();
    //for entry less ends
    //for Payment entry Starts
    controlConsigneeBankName = new FormControl();
    controlStationEntry = new FormControl();
    controlConsigneeNamePayment = new FormControl();
    //for Payment entry ends

    //for Payment entry Starts

    //for Payment entry ends



    actionTypeOptions = [
        { id: 1, label: 'Assign' },
        { id: 2, label: 'Complete' },
        { id: 3, label: 'Status Back' },
        { id: 4, label: 'To Party' },
        { id: 5, label: 'Status Back & Assign' },
        { id: 6, label: 'Finish' },
    ];

    //    collectionManOptions = [
    //        { id: 1, label: 'IKRAM' },
    //        { id: 2, label: 'IMRAN' },
    //        { id: 3, label: 'IQBAL' },
    //        { id: 4, label: 'ISMAIL' },
    //        { id: 5, label: 'KARTHIK' },
    //    ];
    //for entry less starts
    consigneeNameOptions: any;
    consigneeNameOptionsPayment: any;

    entryLessStationOptions: LRDto[];
    paymentLessStationOptions: LRDto[];
    // entryLessTypeOptions
    entryLessTypeOptions = [
        { id: 1, label: 'FLAT DISCOUNT' },
        { id: 2, label: 'RATE DIFFERENCE' },
        { id: 3, label: 'WEIGHT DIFFERENCE' },
        { id: 4, label: 'DEMMURAGE' },
        { id: 5, label: 'UNLOADING' },
        { id: 5, label: 'DOOR DELIVERY' },
        { id: 5, label: 'FOV' },
        { id: 5, label: 'TDS' },
        { id: 5, label: 'CLAIM' },
        { id: 5, label: 'FOC' }
    ];
    //for entry less ends
    //for Payment entry Starts
    consigneeBankNameArrayRet: UserDataDto[];
    consigneeBankNameArray: any;

    //for Payment entry ends                          ];

    //for the select option with filter ends

    //for popup modal starts
    closeResultContentNotAssigned: string;
    closeResultContentAssigned: string
    closeResultContent: string;
    closeResultContentAcknowledgement: string;
    closeResultForCollectionManAssign: string;

    //    for less entry starts
    closeResultContentLessEntryTds: string;
    closeResultContentLessEntryStatusBack: string;
    //  for less entry ends
    //for popup modal ends

    //summaryTable:any;

    loadingIndicator = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dataTable: any;
    dtTriggerPfCollectionManDetails: Subject<any> = new Subject();
    dtTriggerPfAssignedJobDetails: Subject<any> = new Subject();
    //for entry less starts
    dtTriggerLessEntriesDataTable: Subject<any> = new Subject();
    dtTriggerConsigneeWiseBalanceDetails: Subject<any> = new Subject();
    //for entry less ends
    //for Payment entry Starts
    dtTriggerPaymentAmountEntries: Subject<any> = new Subject();
    //for Payment entry ends
    // @ViewChild('dataTable') table: ElementRef;
    dtOptionsPfCollectionManDetails: any;
    //    dtOptionsPfCollectionManDetails: DataTables.Settings = {};
    dtOptionPfAssignedJobDetails: any;

    //for entry less starts
    dtOptionsLessEntriesDataTable: any;
    dtOptionsConsigneeWiseBalanceDetails: any;
    //for entry less ends
    //for Payment entry Starts
    dtOptionsPaymentAmountEntries: any;
    //for Payment entry ends
    //    constructor( private _formBuilder: FormBuilder,
    //        private paymentFollowScreen: ConsignorService,
    //        //for popup modal starts
    //        private modalService: NgbModal,
    //        //for popup modal ends         
    //        //for redirect to other page starts
    //        private router: Router,
    //        //for redirect to other page ends
    //    ) { }
    //From Basha
    userDataDtoReturnSession: any;
    isLoggedIn = true;
    userDataDtoForCollectionMan: UserDataDto = new UserDataDto();
    userDataDtoForCollectionManRet: any;
    collectionManOptions: UserDataDto[];
    collectionManOptionsAssign: UserDataDto[];
    collectionManOptionsToParty: UserDataDto[];
    selectedCollectionMan: any;
    enteredCollectionMan: any;
    selectedCollectionManAssign: any;
    selectedActionType: any;
    cashMemoDtoTable: CashMemoDto = new CashMemoDto();
    collectionManLrDataList: any;
    cashMemoDtoForTPStmt: CashMemoDto = new CashMemoDto();
    lrDtoForStation: LRDto = new LRDto();
    stationOptions: LRDto[];
    userDtoMemoCode: UserDataDto = new UserDataDto();
    selectedEntryType: any;
    stationCodeDetails: any;
    memoFormat: any;
    selectedCodeForManual: any;
    selectedMemoNumberForManual: any;
    selectedMemoNumberForAutomatic: any;

    searchedMemoInfo: CashMemoDto = new CashMemoDto();
    searchedMemoForMemoAlreadyExist: CashMemoDto = new CashMemoDto();
    searchedMemoForDup: CashMemoDto = new CashMemoDto();
    memoNum: any;
    modelForSearched: any;
    modelDup: any;
    modelAlreadyExist: any;
    modelAll: any;
    assingedDetailsDataList: CashMemoDto = new CashMemoDto();
    tempStoreDup: CashMemoDto = new CashMemoDto();
    getsearchInCollectionManTableFoDup: CashMemoDto = new CashMemoDto();
    getsearchInCollectionManTableForAlreadyExist: CashMemoDto = new CashMemoDto();
    letValueofCollectionMan: CashMemoDto = new CashMemoDto();
    letValueofSearchInCollectionManTableFoDup: CashMemoDto = new CashMemoDto();
    letValueofSearchInCollectionManTableFoAlreadyExist: CashMemoDto = new CashMemoDto();
    letValueofSearchInCollectionManTableFoAlreadyExistParty: CashMemoDto = new CashMemoDto();
    letValueofSearchInCollectionManTableFoAlreadyExistToParty: CashMemoDto = new CashMemoDto();
    getValueofSearchInCollectionManTableFoAlreadyExistToParty: CashMemoDto = new CashMemoDto();
    setValueofSearchInCollectionManTableFoAlreadyExistToParty: CashMemoDto = new CashMemoDto();
    addInTable = true;
    enteredCollectionManAssign: any;
    userDataDtoForLRAssign: UserDataDto = new UserDataDto();
    listOfLrNumber: Array<any> = [];
    tempstore: Array<any> = [];
    userDataDtoForLRAssignSRDStock: UserDataDto = new UserDataDto();
    userDataDtoForLRStatusBack: UserDataDto = new UserDataDto();
    removeMaint: Array<any> = [];
    isExist = false;
    userDataDtoForLRToParty: UserDataDto = new UserDataDto();
    userDataDtoForLRToPartySRDStock: UserDataDto = new UserDataDto();
    consigneeeName: any;
    enteredCollectionManToParty: any;
    selectedCollectionManToParty: any;
    consigneeExist: Boolean;
    tempStoreForAllTrx: Array<any> = [];
    value = true;
    userDataDtoForLRFinish: UserDataDto = new UserDataDto();
    enteredCompletedOnDate: any;
    tdyCompletedOnDate: any;
    updateCloseCollection: any;
    cashMemoDtoForAssignPrint: CashMemoDto = new CashMemoDto();
    viewAssignMemoPrint = false;
    showPaymentFollowup = true;
    partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
    consigneeOptions: any;
    consigneeOptionsRet: PartyMasterDto[];
    consigneeOptionsAdd: PartyMasterDto = new PartyMasterDto();
    selectedEntryTypeForLess: any;
    modelForLessMaintain: any;
    searchedMemoForMemoExistInTable: CashMemoDto = new CashMemoDto();
    getsearchInCollectionManTableForEntryLess: CashMemoDto = new CashMemoDto();
    letValueofSearchInCollectionManTableFoEntryLess: CashMemoDto = new CashMemoDto();
    letValueofSearchInCollectionManTableFoEntryLessAny: Array<any> = [];
    selectedConsignee: any;
    selectedConsigneeId: any;
    selectedTypeLess: any;
    nextEntryType: any;
    isDuplicateLess = false;
    //    findModel: any;
    searchedMemoForMemoExistInLessEntryTable: CashMemoDto = new CashMemoDto();
    searchedMemoForMemoExistInLessEntryTableAny: any;

    letValueofMemoExistInLessEntryTableAny: Array<any> = [];
    isEdit = false;
    //    maintenanceDisc: any;
    maintenanceDiscArray: Array<any> = [];
    //    searchedMemoForDiscInLessEntry: CashMemoDto = new CashMemoDto();
    //    searchedMemoForDiscInLessEntryAny: any;
    //    getsearchInMemoExistDiscInLessEntry: CashMemoDto = new CashMemoDto();
    //    letValueofMemoExistDiscInLessEntry: CashMemoDto = new CashMemoDto();
    totalAmt = 0.0;
    totalBalAmt = 0.0;
    enteredAmt = 0.0;
    clickedRowAmt = 0.0;
    enteredTotalLess: any;
    enteredLessAmt: any;
    balanceAmountFinal = 0.0;
    amountToBeCollected = 0.0;
    //    searchedMemoFrmAssingedTable: CashMemoDto = new CashMemoDto();
    //    maintenanceMemoNumber: any;
    //    getsearchInMemoFrmAssingedTable: CashMemoDto = new CashMemoDto();
    //    letValueofMemoFrmAssingedTable: CashMemoDto = new CashMemoDto();
    letValueofMemoFrmAssingedTableAny: Array<any> = [];
    lrDtoForGroupStation: LRDto = new LRDto();
    lrDtoForGroupStationRet: any;
    lrDtoForGroupStationSet: LRDto = new LRDto();
    private newAttribute: any = {};
    private newAttributeForTDS: any = {};
    private newAttributeForPayment: any = {};
    private newAttributeForConsigneeWiseBalance: any = {};
    editTableId = null;
    editTableIdForPayment = null;
    slashSplitMemo: Array<any> = [];
    maintenanceCompletedLessLrs: any;
    maintenanceCompletedLrs: any;
    countOfLessLR = 0;
    sumOfLessAmt = 0;
    editlessamount = 0;
    getConsingeewisememoDto: CashMemoDto = new CashMemoDto();
    getConsingeewisememoAny: any;
    isDuplicateBulkLess = false;
    tdsBulkTotMemoAmt = 0.0;
    listOfMemoNumber: Array<any> = [];
    cashMemoDtoForTDS: CashMemoDto = new CashMemoDto();
    cashMemoDtoForTDSRet: Array<any> = [];
    statusBackMemoNumber: any;
    letValueofSearchInAssingedTableForStatusBack: Array<any> = [];
    userDataDtosLessTemp: Array<any> = [];
    userDataDtosPaymentChqTemp: Array<any> = [];
    userDataDtosPaymentNeftTemp: Array<any> = [];
    userDataDtoForStatusBack: UserDataDto = new UserDataDto();
    userDataDtoForStatusBackRet: any;
    userDataDtoForStatusRet: any;
    lblDelhiLessValue: any;
    lblMumLessValue: any;
    lblGujLessValue: any;
    lblPjbLessValue: any;
    lblTDSLessValue: any;
    lblClaimLessValue: any;
    lblTotLessSummaryValue: any;
    lblTotChqAmtSummaryValue: any;
    lblTotNeftAmtSummaryValue: any;
    nf2000: any;
    nf500: any;
    nf200: any;
    nf100: any;
    nf50: any;
    nf20: any;
    nf10: any;
    nf5: any;
    nf2: any;
    nf1: any;
    nf2000Count: any;
    nf500Count: any;
    nf200Count: any;
    nf100Count: any;
    nf50Count: any;
    nf20Count: any;
    nf10Count: any;
    nf5Count: any;
    nf2Count: any;
    nf1Count: any;
    lblTotCashAmtSummaryValue: any;
    userDataDtoForLess: Array<any> = [];
    userDataDtoForPayment: Array<any> = [];
    userDataDtoForCash: Array<any> = [];
    userDataDtoForLessDetails: UserDataDto = new UserDataDto();
    userDataDtoForPaymentDetails: UserDataDto = new UserDataDto();
    userDataDtoForPaymentCashDetails: UserDataDto = new UserDataDto();
    isLessClicked = false;
    isPaymentClicked = false;
    //Pending for checking the below dto is used
    //    userDataDtoListForLess: UserDataDto[] = [];
    //   Pending  for checking purpose the viewNextBtn is made true so after checking do the it as false  
    viewNextBtn = false;
    userDataDtoForBankDetails: UserDataDto = new UserDataDto();
    userDataDtoForBankDetailsRet: UserDataDto = new UserDataDto();
    viewNewBankName = false;
    selectedEntryTypeForPayment: any;
    paymentLessManualView = true;
    paymentLessAutomaticView = false;
    viewByConsigneeName = false;
    selectedPaymentMode: any;
    showNextBtn = false;
    payType: string;
    payMode: string;
    letValueofMemoExistInPaymentEntryTableAny: Array<any> = [];
    countOfAssignLR = 0;
    sumOfAssignLR = 0;
    userDataDtoForChq: UserDataDto = new UserDataDto();
    userDataDtoForChqRet; any;
    balanceAmtInAddingTable = 0;
    getChequeDate: any;
    countOfPaymentMemo = 0;
    sumOfMemoAmtPayment = 0;
    sumOfLessAmtPayment = 0;
    sumOfBalanceAmtPayment = 0;
    editMemoAmtForPayment = 0;
    editLessAmtForPayment = 0;
    editBalanceAmtForPayment = 0;
    selectedBankName: any;
    userDataDtoForBankName: UserDataDto = new UserDataDto();
    enteredNewBankName: any;
    enteredCheckNumber: any;
    enteredRefNumber: any;
    viewFinishBtn = true;
    viewShowNexInAssingBtn = false;
    viewShowNexInAssingBtn2 = false;
    showNextBtnInAssign = false;
    showForCheckandNeft = false;
    userDataDtoForFinishDetails: UserDataDto = new UserDataDto();
    isDuplicateChq = false;
    lrDtoForValidateChq: LRDto = new LRDto();
    retrunStatus: any;
    diffLessRet: any;
    validateChqRet: any;
    getChallanPrintValDto: CashMemoDto = new CashMemoDto();
    lessDescOpt: Array<any> = [];
    lessDescOptSent: Array<any> = [];
    viewChallanRptPrint = false;
    collectionManTP: any;
    countTp: any;
    stationForCode: any;
    consigneeWiseBalancelDto: CashMemoDto = new CashMemoDto();
    consigneeWiseBalancelDtoForUpdate: CashMemoDto = new CashMemoDto();
    consigneeWiseBalancelRet: any;
    countOfConsingeeWiseMemo = 0;
    sumOfConsingeeWiseTotalCashMemo = 0;
    sumOfConsingeeWiseGrandTotal = 0;
    sumOfConsingeeWisePartyLessDed = 0;
    sumOfConsingeeWiseAdvChqAmt = 0;
    sumOfConsingeeWiseNeftAmtl = 0;
    sumOfConsingeeWiseBalance = 0;
    countOFMemosCollectionTable = 0;
    sumOFMemosCollectionTable = 0;
    setCollectionDetails = 'new';
    setAssingedDetails = 'new';
    newAttForColTable: any = {};
    modelAllRemove: any;
    tempStoreForRemove: CashMemoDto = new CashMemoDto();
    consigneeWiseTrigger = 'new';
    showLessLbl = false;
    showChqLbl = false;
    showNEFTLbl = false;
    showCashLbl = false;
    getCashValDto: CashMemoDto = new CashMemoDto();
    userDataDtosPaymentCashTemp: Array<any> = [];
    grandTotalForCash: any;
    showCashLblAmt = false;
    getMainStation: any;
    showSpinner = false;
    showResetBtn = false;
    modelStationForPayment: any;
    modelStationForPaymentAmt: any;
    modelStationForLess: any;
    modelStationForLessAmt: any;
    showSpinnerForAction = false;
    setLessEntryDetails = 'new';
    setPaymentEntryDetails = 'new';
    tdsLessMulti = 1.5;
    twoThousandRupeeCountModel: any;
    twoThousandRupeeModel: any;
    fiveHundredRupeeCountModel: any;
    fiveHundredRupeeModel: any;
    twoHundredRupeeCountModel: any;
    twoHundredRupeeModel: any;
    hundredRupeeCountModel: any;
    hundredRupeeModel: any;
    fiftyRupeeCountModel: any;
    fiftyRupeeModel: any;
    twentyRupeeCountModel: any;
    twentyRupeeModel: any;
    tenRupeeCountModel: any;
    tenRupeeModel: any;
    fiveRupeeCountModel: any;
    fiveRupeeModel: any;
    twoRupeeCountModel: any;
    twoRupeeModel: any;
    oneRupeeCountModel: any;
    oneRupeeModel: any;
    newPartyBankDetial: any;
    //for collection man 1
    @ViewChild('instanceCollectionMan') instanceCollectionMan: NgbTypeahead;
    collectionManTA: Array<any> = [];
    collectionManToChkMemoEnbl: Array<UserDataDto> = [];
    memoAssignEnblObj: boolean;
    focusCollectionManTA$ = new Subject<string>();
    collectionManSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCollectionManTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.collectionManTA
                : this.collectionManTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //for collection man 2
    @ViewChild('collectionManAssign') collectionManAssign: NgbTypeahead;
    collectionManAssignTA: Array<any> = [];
    focusCollectionManAssignTA$ = new Subject<string>();
    collectionManAssignSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCollectionManAssignTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.collectionManAssignTA
                : this.collectionManAssignTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //for collection man 3
    @ViewChild('collectionManToParty') collectionManToParty: NgbTypeahead;
    collectionManToPartyTA: Array<any> = [];
    focusCollectionManToPartyTA$ = new Subject<string>();
    collectionManToPartySearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCollectionManToPartyTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.collectionManToPartyTA
                : this.collectionManToPartyTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //Station 1
    @ViewChild('instanceStation') instanceStation: NgbTypeahead;
    stationTA: Array<any> = [];
    focusStationTA$ = new Subject<string>();
    stationSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.stationTA
                : this.stationTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //Station 2
    @ViewChild('instanceEntryLessStation') instanceEntryLessStation: NgbTypeahead;
    entryLessStationTA: Array<any> = [];
    focusEntryLessStationTA$ = new Subject<string>();
    entryLessStationSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusEntryLessStationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.entryLessStationTA
                : this.entryLessStationTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //Station 3
    @ViewChild('paymentLessStn') paymentLessStn: NgbTypeahead;
    paymentLessStnTA: Array<any> = [];
    focusPaymentLessStnTA$ = new Subject<string>();
    paymentLessStnSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusPaymentLessStnTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.paymentLessStnTA
                : this.paymentLessStnTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //Consignee 1
    //    @ViewChild( 'instanceConsigneeName' ) instanceConsigneeName: NgbTypeahead;
    //    consigneeNameTA: Array<any> = [];
    //    focusConsigneeNameTA$ = new Subject<string>();
    //    consigneeNameSearchTA = ( text$: Observable<string> ) => {
    //        const debouncedText$ = text$.pipe( debounceTime( 200 ), distinctUntilChanged() );
    //        const inputFocus$ = this.focusConsigneeNameTA$;
    //
    //        return merge( debouncedText$, inputFocus$ ).pipe(
    //            map( term => ( term === '' ? this.consigneeNameTA
    //                : this.consigneeNameTA.filter( v => v.toLowerCase().indexOf( term.toLowerCase() ) > -1 ) ).slice( 0, 200 ) )
    //        );
    //    }

    public modelConsigneeName: any;
    public modelStationName: any;
    public modelPartyBankName: any;
    public modelCollectionManName: any;
    consigneeNameTA: Array<PartyMasterDto> = [];
    focusConsigneeNameTA$ = new Subject<string>();
    consigneeNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeNameTA
                : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;

    //Consignee 2
    //    @ViewChild( 'instanceConsigneeNameForPayment' ) instanceConsigneeNameForPayment: NgbTypeahead;
    //    consigneeNameForPaymentTA: Array<any> = [];
    //    focusConsigneeNameForPaymentTA$ = new Subject<string>();
    //    consigneeNameForPaymentSearchTA = ( text$: Observable<string> ) => {
    //        const debouncedText$ = text$.pipe( debounceTime( 200 ), distinctUntilChanged() );
    //        const inputFocus$ = this.focusConsigneeNameForPaymentTA$;
    //
    //        return merge( debouncedText$, inputFocus$ ).pipe(
    //            map( term => ( term === '' ? this.consigneeNameForPaymentTA
    //                : this.consigneeNameForPaymentTA.filter( v => v.toLowerCase().indexOf( term.toLowerCase() ) > -1 ) ).slice( 0, 200 ) )
    //        );
    //    }
    public modelConsigneeNameForPayment: any;
    consigneeNameForPaymentTA: Array<PartyMasterDto> = [];
    focusConsigneeNameForPaymentTA$ = new Subject<string>();
    consigneeNameForPaymentSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeNameTA
                : this.consigneeNameForPaymentTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneeNameForPayment = (x: { consigneeName: string }) => x.consigneeName;
    //Consignee Bank 
    @ViewChild('instanceConsigneeBankName') instanceConsigneeBankName: NgbTypeahead;
    consigneeBankNameTA: Array<any> = [];
    focusConsigneeBankNameTA$ = new Subject<string>();
    consigneeBankNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeBankNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeBankNameTA
                : this.consigneeBankNameTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    //action Type TA
    public modelActionType: any;
    @ViewChild('instanceActionType') instanceActionType: NgbTypeahead;
    actionTypeTA: Array<any> = [];
    focusActionTypeTA$ = new Subject<string>();
    actionTypeSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusActionTypeTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.actionTypeTA
                : this.actionTypeTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //less Type TA
    public modelLessType: any;
    @ViewChild('instanceLessType') instanceLessType: NgbTypeahead;
    lessTypeTA: Array<any> = [];
    focusLessTypeTA$ = new Subject<string>();
    lessTypeSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusLessTypeTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.lessTypeTA
                : this.lessTypeTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }

    todayDate: any;
    memoDateCheck: Date;

    //for collection man for status back and assign popup
    @ViewChild('collectionManStatusBackAssign') collectionManStatusBackAssign: NgbTypeahead;
    collectionManStatusBackAssignTA: Array<any> = [];
    focusCollectionManStatusBackAssignTA$ = new Subject<string>();
    collectionManStatusBackAssignSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCollectionManStatusBackAssignTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.collectionManStatusBackAssignTA
                : this.collectionManStatusBackAssignTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    selectedCollectionManStatusBackAssign: any;
    modelCollectionManStatusBackAssign: any;
    userDataDtoForLRStatusBackAssign: UserDataDto = new UserDataDto();
    pageId = "pmtfc";
    userDataDtoForWhatsApp: UserDataDto = new UserDataDto();
    sendCollectionCloseWhatsappUpdates: boolean = false;
    //PFCHG_V5
    showDRAccLbl = false;
    showUPILbl = false;
    lblTotDebtAcccAmtSummaryValue: any;
    lblTotUPIAmtSummaryValue: any;
    sumOfConsingeeWiseDebitAcAmtl = 0;
    sumOfConsingeeWiseUPIAmtl = 0;
    userDataDtosPaymentDebitAccTemp: Array<any> = [];
    userDataDtosPaymentUPITemp: Array<any> = [];
    constructor(private _formBuilder: FormBuilder, private memoService: MemoService, private modalService: NgbModal, private masterReadService: MasterReadService, private router: Router, private datePipe: DatePipe, public dialog: MatDialog, private route: ActivatedRoute, private paymentFollowScreen: ConsignorService, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            //            config.max = 100;
            //            config.striped = true;
            //            config.animated = true;
            //            config.type = 'success';
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            if (this.userDataDtoReturnSession.sendDestPaymentCollectionWhatsApp != null && this.userDataDtoReturnSession.sendDestPaymentCollectionWhatsApp) {
                this.sendCollectionCloseWhatsappUpdates = true;
            }
            this.setActionVal();
            this.setLessVal();
            this.collectionManLrDetailsDataTable();
            this.assignedJobDetailsTable();
            this.getCollectionManDetails();
            this.consigneeWiseDetailsTable();
            this.getStationDtails();
            this.selectTodayCompletedOnDate();
            this.getConsigneeDetails();
            this.getPartyBankDetailsService();
            this.lessEntriesTable();
            this.paymentEntriesDataTable();


            //            console.log(this.userDataDtoReturnSession.addressId);
            //            console.log( this.userDataDtoReturnSession.sortedMapFeatures );
            //            console.log( Object.keys( this.userDataDtoReturnSession.sortedMapFeatures ) );
            //            for ( let key in this.userDataDtoReturnSession.sortedMapFeatures ) {
            //                //Whatever you want to do with key or obj[key]
            //                if ( key == 'LR' ) {
            //                    for ( let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.LR.length; i++ ) {
            //                        //Whatever you want to do with key or obj[key]
            //                        if ( this.userDataDtoReturnSession.sortedMapFeatures.LR[i] == 'LR Delivery Status' ) {
            //                            console.log( 'LR Delivery Status' );
            //                        }
            //                    }
            //                }
            //            }
        }
    }
    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    selectTodayCompletedOnDate() {
        this.tdyCompletedOnDate = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
        //        console.log( this.tdyCompletedOnDate );
        $("#" + this.pageId + "completedOnDate").val(this.tdyCompletedOnDate);
    }
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
            dtElement.dtInstance.then((dtInstance: any) => {
                // Do your stuff
                dtInstance.destroy();
                //                this.dtTriggerPfAssignedJobDetails.next();
                //                this.dtTriggerPfCollectionManDetails.next();
                //                console.log('The DataTable ${index} instance ID is:${dtInstance.table().node().id}');
                //                console.log('The DataTable ${index} instance ID is:${dtInstance.table().node().id}');
            });
        });
    }


    ngOnInit(): void {
        //        the below is for validator starts
        this.firstFormGroup = this._formBuilder.group({
            //        firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
            //            secondCtrl: ['', Validators.required]
        });
        this.thirdFormGroup = this._formBuilder.group({
            //            thirdCtrl: ['', Validators.required]
        });
        this.fourthFormGroup = this._formBuilder.group({
            //            fourthCtrl: ['', Validators.required]
        });
        //        the below is for validator ends
        //        pages
        //first datatable starts
        //        this.dtOptionsPfCollectionManDetails = {
        //            // the below code is for button export starts
        //            dom: 'Bfrtip',
        //            /*buttons: [
        //                      'excel', 'print'
        //                  ],*/
        //            buttons: [
        //                //                {
        //                //                    extend: 'excel',
        //                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
        //                //                    titleAttr: 'Excel',
        //                //                    exportOptions: {
        //                //                        columns: ':visible'
        //                //                    }
        //                //                },
        //                //                {
        //                //                    extend: 'print',
        //                //                    text: '<i class="fas fa-print"> Print</i>',
        //                //                    titleAttr: 'Print',
        //                //
        //                //                }
        //
        //            ],
        //
        //            // the below code is for button export ends                          
        //
        //            //place holder in search/filter in datatable starts
        //            language: {
        //                search: "_INPUT_",
        //                searchPlaceholder: "Search..."
        //            },
        //            //place holder in search/filter in datatable ends
        //
        //            processing: true,
        //            //scroll in datatable starts
        //            responsive: true,
        //            "scrollX": true,
        //            "scrollY": 200,
        //            "scrollCollapse": true,
        //            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
        //            pagingType: 'full_numbers',
        //            pageLength: 6,
        //            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
        //            //scroll in datatable ends
        //
        //
        //        },//first datatable ends
        //second datatable starts 
        //        this.dtOptionPfAssignedJobDetails = {
        //            // the below code is for button export starts
        //            dom: 'Bfrtip',
        //            /*buttons: [
        //                      'excel', 'print'
        //                  ],*/
        //            buttons: [
        //                //                    {
        //                //                        extend: 'excel',
        //                //                        text: '<i class="fas fa-file-excel"> Excel</i>',
        //                //                        titleAttr: 'Excel',
        //                //                        exportOptions: {
        //                //                            columns: ':visible'
        //                //                        }
        //                //                    },
        //                //                    {
        //                //                        extend: 'print',
        //                //                        text: '<i class="fas fa-print"> Print</i>',
        //                //                        titleAttr: 'Print',
        //                //                    }
        //            ],
        //            // the below code is for button export ends
        //            //place holder in search/filter in datatable starts
        //            language: {
        //                search: "_INPUT_",
        //                searchPlaceholder: "Search..."
        //            },
        //            //place holder in search/filter in datatable ends
        //            processing: true,
        //            //scroll in datatable starts
        //            responsive: true,
        //            "scrollX": true,
        //            "scrollY": 200,
        //            "scrollCollapse": true,
        //            //this to paggination and content like showing 1 to 3 of 20 entries Starts
        //            pagingType: 'full_numbers',
        //            pageLength: 6,
        //            //this to paggination and content like showing 1 to 3 of 20 entries Starts
        //            //scroll in datatable ends
        //        },
        //second datatable ends
        //for entry less starts
        //third datatable starts
        //        this.dtOptionsLessEntriesDataTable = {
        //            // the below code is for button export starts
        //            dom: 'Bfrtip',
        //            /*buttons: [
        //                      'excel', 'print'
        //                  ],*/
        //            buttons: [
        //                //                {
        //                //                    extend: 'excel',
        //                //                    text: '<i class="fas fa-file-excel"> Excel</i>',
        //                //                    titleAttr: 'Excel',
        //                //                    exportOptions: {
        //                //                        columns: ':visible'
        //                //                    }
        //                //                },
        //                //                {
        //                //                    extend: 'print',
        //                //                    text: '<i class="fas fa-print"> Print</i>',
        //                //                    titleAttr: 'Print',
        //                //
        //                //                }
        //
        //            ],
        //
        //            // the below code is for button export ends                          
        //
        //            //place holder in search/filter in datatable starts
        //            language: {
        //                search: "_INPUT_",
        //                searchPlaceholder: "Search..."
        //            },
        //            //place holder in search/filter in datatable ends
        //
        //            processing: true,
        //            //scroll in datatable starts
        //            responsive: true,
        //            "scrollX": true,
        //            "scrollY": 200,
        //            "scrollCollapse": true,
        //            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
        //            "paging": false,
        //            "info": false,
        //            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
        //            //scroll in datatable ends
        //
        //
        //        },//third datatable ends

        //fourth datatable starts
        //            this.dtOptionsConsigneeWiseBalanceDetails = {
        //                // the below code is for button export starts
        //                dom: 'Bfrtip',
        //                /*buttons: [
        //                          'excel', 'print'
        //                      ],*/
        //                buttons: [
        //                    //                {
        //                    //                    extend: 'excel',
        //                    //                    text: '<i class="fas fa-file-excel"> Excel</i>',
        //                    //                    titleAttr: 'Excel',
        //                    //                    exportOptions: {
        //                    //                        columns: ':visible'
        //                    //                    }
        //                    //                },
        //                    //                {
        //                    //                    extend: 'print',
        //                    //                    text: '<i class="fas fa-print"> Print</i>',
        //                    //                    titleAttr: 'Print',
        //                    //
        //                    //                }
        //
        //                ],
        //
        //                // the below code is for button export ends                          
        //
        //                //place holder in search/filter in datatable starts
        //                language: {
        //                    search: "_INPUT_",
        //                    searchPlaceholder: "Search..."
        //                },
        //                //place holder in search/filter in datatable ends
        //
        //                processing: true,
        //                //scroll in datatable starts
        //                responsive: true,
        //                "scrollX": true,
        //                "scrollY": 200,
        //                "scrollCollapse": true,
        //                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
        //                "paging": false,
        //                "info": false,
        //                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
        //                //scroll in datatable ends
        //
        //
        //            },//fourth datatable ends
        //for entry less ends

        //for Payment entry Starts
        //fifth datatable starts
        //            this.dtOptionsPaymentAmountEntries = {
        //                // the below code is for button export starts
        //                dom: 'Bfrtip',
        //                /*buttons: [
        //                          'excel', 'print'
        //                      ],*/
        //                buttons: [
        //                ],
        //
        //                // the below code is for button export ends                          
        //
        //                //place holder in search/filter in datatable starts
        //                language: {
        //                    search: "_INPUT_",
        //                    searchPlaceholder: "Search..."
        //                },
        //                //place holder in search/filter in datatable ends
        //
        //                processing: true,
        //                //scroll in datatable starts
        //                responsive: true,
        //                "scrollX": true,
        //                "scrollY": 200,
        //                "scrollCollapse": true,
        //                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
        //                "paging": false,
        //                "info": false,
        //                //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
        //                //scroll in datatable ends
        //
        //
        //            },//fifth datatable ends


        //for Payment entry ends

        //the below code is for the getting data through json starts
        //            this.supplierList.getAllData().subscribe(data => {
        //                this.lrDispatchBknRptList = data['data'];
        //                this.dtTriggerSummary.next();
        //                } );

        //first service starts 
        //            this.getDataFrmServiceFrPfCollectionManDetailsTable = this.paymentFollowScreen.getSummaryData()
        //        this.onDestroyUnsubscribtionPfCollectionManDetails = this.getDataFrmServiceFrPfCollectionManDetailsTable.subscribe( data => {
        //            this.pfCollectionManDetailsDataList = data['data'];
        //            this.dtTriggerPfCollectionManDetails.next();
        //        } );
        //first service ends
        //second service starts 

        //            this.getDataFrmServiceFrPfAssignedJobDetailsTable = this.paymentFollowScreen.getSummaryData();
        //        this.onDestroyUnsubscribtionPfAssignedJobDetails = this.getDataFrmServiceFrPfAssignedJobDetailsTable.subscribe( data => {
        //            this.pfAssignedJobDetailsDataList = data['data'];
        //            this.dtTriggerPfAssignedJobDetails.next();
        //        } );
        //second service ends
        //for entry less starts
        //third service starts 

        //            this.getDataFrmServiceFrLessEntriesDataTableTable = this.paymentFollowScreen.getSummaryData()
        //        this.onDestroyUnsubscribtionLessEntriesDataTable = this.getDataFrmServiceFrLessEntriesDataTableTable.subscribe( data => {
        //            this.lessEntriesDataTableDataList = data['data'];
        //            this.dtTriggerLessEntriesDataTable.next();
        //        } );
        //third service ends
        //fourth service starts 

        //  this.getDataFrmServiceFrConsigneeWiseBalanceDetailsTable = this.paymentFollowScreen.getSummaryData();
        //        this.onDestroyUnsubscribtionConsigneeWiseBalanceDetails = this.getDataFrmServiceFrConsigneeWiseBalanceDetailsTable.subscribe( data => {
        //            this.consigneeWiseBalanceDetailsDataList = data['data'];
        //            this.dtTriggerConsigneeWiseBalanceDetails.next();
        //        } );
        //fourth service ends
        //for entry less ends
        //for Payment entry Starts
        //fifth service starts 
        //        this.getDataFrmServiceFrPaymentAmountEntriesDataTable = this.paymentFollowScreen.getSummaryData()
        //        this.onDestroyUnsubscribtionPaymentAmountEntries = this.getDataFrmServiceFrPaymentAmountEntriesDataTable.subscribe( data => {
        //            this.paymentAmountEntriesDataList = data['data'];
        //            this.dtTriggerPaymentAmountEntries.next();
        //        } );
        //fifth service ends

        //for Payment entry ends
        //the below code is for the getting data through json ends
    }
    ngAfterViewInit(): void {
        this.dtTriggerPfCollectionManDetails.next();
        this.dtTriggerPfAssignedJobDetails.next();
        this.dtTriggerLessEntriesDataTable.next();
        this.dtTriggerConsigneeWiseBalanceDetails.next();
        this.dtTriggerPaymentAmountEntries.next();
    }

    ngOnDestroy(): void {
        this.dtTriggerPfCollectionManDetails.unsubscribe();
        this.dtTriggerPfAssignedJobDetails.unsubscribe();
        this.dtTriggerLessEntriesDataTable.unsubscribe();
        this.dtTriggerConsigneeWiseBalanceDetails.unsubscribe();
        this.dtTriggerPaymentAmountEntries.unsubscribe();
    }
    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends
    //to insert value of selected row in table to input field starts
    RowSelected(confirmedConsignorListData) {
        this.selectedUser = confirmedConsignorListData;
        this.consignorName = this.selectedUser.name;
        //        console.log( this.selectedUser.name );
    }
    //to insert value of selected row in table to input field ends

    //    the payment followup starts
    //for popup modal starts 

    openNotAssignedPopup(contentNotAssigned) {
        this.modalService.open(contentNotAssigned, { centered: true }).result.then(
            resultContent => {
                this.closeResultContentNotAssigned = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContentNotAssigned = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    openAssignedPopup(contentAssigned) {
        this.modalService.open(contentAssigned, { centered: true }).result.then(
            resultContent => {
                this.closeResultContentAssigned = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContentAssigned = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    openPopup(content) {
        this.modalService.open(content, { centered: true }).result.then(
            resultContent => {
                this.closeResultContent = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContent = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    openAcknowledgementPopup(contentAcknowledgement) {
        this.modalService.open(contentAcknowledgement, { centered: true }).result.then(
            resultContent => {
                this.closeResultContentAcknowledgement = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContentAcknowledgement = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }



    //    //    for less entry starts
    //    openLessEntryTdsPopup( contentLessEntryTds ) {
    //        this.modalService.open( contentLessEntryTds, { centered: true } ).result.then(
    //            resultContent => {
    //                this.closeResultContentLessEntryTds = `Closed with: ${resultContent}`;
    //            },
    //            reason => {
    //                this.closeResultContentLessEntryTds = `Dismissed ${this.getDismissReason( reason )}`;
    //            }
    //        );
    //    }
    //    openLessEntryStatusBackPopup( contentLessEntryStatusBack ) {
    //        this.modalService.open( contentLessEntryStatusBack, { centered: true } ).result.then(
    //            resultContent => {
    //                this.closeResultContentLessEntryStatusBack = `Closed with: ${resultContent}`;
    //            },
    //            reason => {
    //                this.closeResultContentLessEntryStatusBack = `Dismissed ${this.getDismissReason( reason )}`;
    //            }
    //        );
    //    }

    open10(content10) {
        this.modalService.open(content10, { centered: true }).result.then(
            result => {
                this.closeResultForCollectionManAssign = `Closed with: ${result}`;
            },
            reason => {
                this.closeResultForCollectionManAssign = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
    open11(content11) {
        this.modalService.open(content11, { centered: true }).result.then(
            result => {
                this.closeResultForCollectionManAssign = `Closed with: ${result}`;
            },
            reason => {
                this.closeResultForCollectionManAssign = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    //  for less entry ends    


    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    //for popup modal ends

    entryTypeMode(entryType: string) {
        if (entryType === 'manual') {
            this.manualView = true;
            this.automaticView = false;
        } else if
            (entryType === 'automatic') {
            this.manualView = false;
            this.automaticView = true;
        } else {
            this.manualView = false;
            this.automaticView = false;
        }
    }
    //    the payment followup ends

    //  for payment entry starts

    entryLessPaymentEntryMode(paymentLessEntryType: string) {
        if (paymentLessEntryType === 'paymentLessManual') {
            this.paymentLessManualView = true;
            this.paymentLessAutomaticView = false;
        } else if (paymentLessEntryType === 'paymentLessAutomatic') {
            this.paymentLessManualView = false;
            this.paymentLessAutomaticView = true;
        } else {
            this.paymentLessManualView = false;
            this.paymentLessAutomaticView = false;

        }
    }

    //for entry less ends

    //for entry less starts
    //for hide and show starts
    entryLessEntryTypeMode(entryLessPaymentEntry: string) {
        if (entryLessPaymentEntry === 'entryLessManual') {
            this.entryLessManualView = true;
            this.entryLessAutomaticView = false;

        } else if (entryLessPaymentEntry === 'entryLessAutomatic') {
            this.entryLessManualView = false;
            this.entryLessAutomaticView = true;
        } else {
            this.entryLessManualView = false;
            this.entryLessAutomaticView = false;
        }
    }

    paymentModeMode(paymentMode: string) {
        $("#" + this.pageId + "consigneeNameForPayment").val('');
        this.selectedConsigneeId = 0;
        this.modelConsigneeNameForPayment = '';

        if (paymentMode === 'cash') {
            //   console.log( 'cash' );
            this.viewByCash = true;
            this.viewByCashs = true;
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = false;
            this.viewByReferenceNumber = false;
            this.viewByConsigneeName = false;
            //            this.showNextBtn = false;
            this.showNextBtn = true;
            //            this.controlConsigneeBankName.reset();
            //Pending process 
            //            txtBankName.clear();
            //            cbBankName.clear();
            $("#" + this.pageId + "consigneeBankName").val('');
            $("#" + this.pageId + "newBankName").val('');
            this.selectedConsigneeId = 0;
            this.payType = "Cash";
            this.payMode = "completedcash";
            this.showForCheckandNeft = false;
            this.viewNewBankName = false;
            this.paymentAmountEntriesDataList = [];
            this.countOfPaymentMemo = 0;
            this.sumOfMemoAmtPayment = 0;
            this.sumOfLessAmtPayment = 0;
            this.sumOfBalanceAmtPayment = 0;
            $("#" + this.pageId + "consigneeNameForPayment").val('');
            this.modelConsigneeNameForPayment = '';
            $("#" + this.pageId + "codeForManualLessPayment").val('');
            $("#" + this.pageId + "codeForManualLessPayment").val(this.memoFormat);
            this.modelStationForPaymentAmt = this.memoFormat;

        } else if (paymentMode === 'cheque') {
            //   console.log( 'cheque' );
            $("#" + this.pageId + "consigneeNameForPayment").val('');
            this.viewByCash = true;
            this.viewByCashs = false;
            this.viewByChequeNumber = true;
            this.viewByChequeDate = true;
            this.viewByConsigneeBankName = true;
            this.viewByReferenceNumber = false;
            this.viewByConsigneeName = true;
            this.showNextBtn = true;
            this.payType = "Cheque";
            this.payMode = "completedcheque";
            $("#" + this.pageId + "consigneeBankName").val('');
            $("#" + this.pageId + "newBankName").val('');
            this.selectedConsigneeId = 0;
            //            this.controlConsigneeBankName.reset();
            this.showForCheckandNeft = true;
            this.viewNewBankName = false;
            this.paymentAmountEntriesDataList = [];
            this.countOfPaymentMemo = 0;
            this.sumOfMemoAmtPayment = 0;
            this.sumOfLessAmtPayment = 0;
            this.sumOfBalanceAmtPayment = 0;
            this.modelConsigneeNameForPayment = '';
            //    console.log(this.modelStationName);
            this.modelStationForPayment = this.modelStationName;
            $("#" + this.pageId + "codeForManualLessPayment").val('');
            $("#" + this.pageId + "codeForManualLessPayment").val(this.memoFormat);
            this.modelStationForPaymentAmt = this.memoFormat;
        }
        else if (paymentMode === 'neft') {
            //   console.log( 'neft' );
            $("#" + this.pageId + "consigneeNameForPayment").val('');
            this.viewByCash = true;
            this.viewByCashs = false;
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = true;
            this.viewByReferenceNumber = true;
            this.viewByConsigneeName = true;
            this.showNextBtn = true;
            this.payType = "NEFT/IMPS";
            this.payMode = "completedneft";
            $("#" + this.pageId + "consigneeBankName").val('');
            $("#" + this.pageId + "newBankName").val('');
            this.selectedConsigneeId = 0;
            //            this.controlConsigneeBankName.reset();
            this.showForCheckandNeft = true;
            this.viewNewBankName = false;
            this.paymentAmountEntriesDataList = [];
            this.countOfPaymentMemo = 0;
            this.sumOfMemoAmtPayment = 0;
            this.sumOfLessAmtPayment = 0;
            this.sumOfBalanceAmtPayment = 0;
            this.modelConsigneeNameForPayment = '';
            this.modelStationForPayment = this.modelStationName;
            $("#" + this.pageId + "codeForManualLessPayment").val(this.memoFormat);
            this.modelStationForPaymentAmt = this.memoFormat;
        } else if (paymentMode === 'debitacc') {
            //   console.log( 'neft' );
            $("#" + this.pageId + "consigneeNameForPayment").val('');
            this.viewByCash = true;
            this.viewByCashs = false;
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = false;
            this.viewByReferenceNumber = true;
            this.viewByConsigneeName = true;
            this.showNextBtn = true;
            this.payType = "Debit Account";
            this.payMode = "completeddebitacc";
            $("#" + this.pageId + "consigneeBankName").val('');
            $("#" + this.pageId + "newBankName").val('');
            this.selectedConsigneeId = 0;
            //this.controlConsigneeBankName.reset();
            this.showForCheckandNeft = true;
            this.viewNewBankName = false;
            this.paymentAmountEntriesDataList = [];
            this.countOfPaymentMemo = 0;
            this.sumOfMemoAmtPayment = 0;
            this.sumOfLessAmtPayment = 0;
            this.sumOfBalanceAmtPayment = 0;
            this.modelConsigneeNameForPayment = '';
            this.modelStationForPayment = this.modelStationName;
            $("#" + this.pageId + "codeForManualLessPayment").val(this.memoFormat);
            this.modelStationForPaymentAmt = this.memoFormat;
        } else if (paymentMode === 'upi') {
            //   console.log( 'neft' );
            $("#" + this.pageId + "consigneeNameForPayment").val('');
            this.viewByCash = true;
            this.viewByCashs = false;
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = false;
            this.viewByReferenceNumber = true;
            this.viewByConsigneeName = true;
            this.showNextBtn = true;
            this.payType = "UPI";
            this.payMode = "completedupi";
            $("#" + this.pageId + "consigneeBankName").val('');
            $("#" + this.pageId + "newBankName").val('');
            this.selectedConsigneeId = 0;
            //            this.controlConsigneeBankName.reset();
            this.showForCheckandNeft = true;
            this.viewNewBankName = false;
            this.paymentAmountEntriesDataList = [];
            this.countOfPaymentMemo = 0;
            this.sumOfMemoAmtPayment = 0;
            this.sumOfLessAmtPayment = 0;
            this.sumOfBalanceAmtPayment = 0;
            this.modelConsigneeNameForPayment = '';
            this.modelStationForPayment = this.modelStationName;
            $("#" + this.pageId + "codeForManualLessPayment").val(this.memoFormat);
            this.modelStationForPaymentAmt = this.memoFormat;
        } else {
            this.viewByCash = false;
            this.viewByCashs = false;
            this.viewByChequeNumber = false;
            this.viewByChequeDate = false;
            this.viewByConsigneeBankName = false;
            this.viewByReferenceNumber = false;
            this.viewByConsigneeName = false;
            this.showNextBtn = true;
            this.payType = "";
            this.payMode = "";
            $("#" + this.pageId + "consigneeBankName").val('');
            $("#" + this.pageId + "newBankName").val('');
            this.selectedConsigneeId = 0;
            //            this.controlConsigneeBankName.reset();
            this.showForCheckandNeft = false;
            this.viewNewBankName = false;
            this.paymentAmountEntriesDataList = [];
            this.countOfPaymentMemo = 0;
            this.sumOfMemoAmtPayment = 0;
            this.sumOfLessAmtPayment = 0;
            this.sumOfBalanceAmtPayment = 0;
            $("#" + this.pageId + "consigneeNameForPayment").val('');
            this.modelConsigneeNameForPayment = '';
            this.modelStationForPaymentAmt = this.memoFormat;
        }
    }
    //for hide and show ends
    //    for payment entry ends


    //for datepicker starts
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker ends
    //for redirect to other page starts
    goToCollectionManPage() {
        this.router.navigate(['/report/cashmemo-report/collectionManReport']);
    }
    //for redirect to other page starts
    //    hide and show consignee wise details starts

    showTheConsigneeWiseDetails() {
        this.consigneeWiseDetails = true;
        this.nextEntryType = 'Less Entry';
        this.modelStationForLess = this.modelStationName;
        this.modelStationForLessAmt = this.memoFormat;
        this.callForCompleteCollection();
    }
    setPaymentEntry() {
        this.nextEntryType = 'Payment Entry';
        this.updateLessDetails();
        // calculate total
        this.calculateAllTotalAndBalance();
        //Pending
        this.updateConsigneeBalanceDetails();
        this.showChqLbl = true;
        this.showNEFTLbl = true;

        this.showCashLbl = true;
        //// newly added basha       
        this.viewByCash = false;
        this.viewByCashs = false;
        this.viewByChequeNumber = false;
        this.viewByChequeDate = false;
        this.viewByConsigneeBankName = false;
        this.viewByReferenceNumber = false;
        this.viewByConsigneeName = false;
        this.showNextBtn = true;
        this.payType = "";
        this.payMode = "";
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.showForCheckandNeft = false;
        this.viewNewBankName = false;
        this.paymentAmountEntriesDataList = [];
        this.countOfPaymentMemo = 0;
        this.sumOfMemoAmtPayment = 0;
        this.sumOfLessAmtPayment = 0;
        this.sumOfBalanceAmtPayment = 0;
        $("#" + this.pageId + "consigneeNameForPayment").val('');
        this.selectedConsigneeId = 0;
        this.modelConsigneeNameForPayment = '';
        this.modelStationForPaymentAmt = this.memoFormat;
        $("#" + this.pageId + "paymentMode").val('select');
        //PFCHG_V5
        this.showDRAccLbl = true;
        this.showUPILbl = true;

    }
    setActionVal() {
        for (let i = 0; i < this.actionTypeOptions.length; i++) {
            this.actionTypeTA.push(this.actionTypeOptions[i].label);
        }
        //PFCHG_V2
        if (this.userDataDtoReturnSession.specCollectionFlow) {
            this.actionTypeTA.push("Complete for Direct Assigned");
        }
    }
    ///for Action Type
    actionTypeFocus(e: NgbTypeaheadSelectItemEvent) {
        this.modelActionType = e.item;
        $("#" + this.pageId + "collectionMan").val('');
        this.selectedCollectionMan = '';
        this.enteredCollectionMan = '';
        this.pfCollectionManDetailsDataList = [];
        this.pfCollectionManDetailsDataListForSearch = [];
        this.pfAssignedJobDetailsDataList = [];
        this.countOfAssignLR = 0;
        this.sumOfAssignLR = 0;
        this.countOFMemosCollectionTable = 0;
        this.sumOFMemosCollectionTable = 0;
        this.newAttForColTable = {};
        this.tempstore = [];
        this.selectedActionType = this.modelActionType;

        if (this.selectedActionType == null || this.selectedActionType == '') {
            //       console.log( this.selectedActionType );
            swal({
                title: "Warning",
                text: "Please select Action Type to get the details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.viewFinishBtn = true;
            this.viewShowNexInAssingBtn2 = false;
            this.showNextBtnInAssign = false;
        } else if (this.selectedActionType === 'Complete' || this.selectedActionType === 'Complete for Direct Assigned') {
            this.showNextBtnInAssign = true;
            this.viewFinishBtn = false;
            this.viewShowNexInAssingBtn2 = false;
        } else {
            this.viewFinishBtn = true;
            this.viewShowNexInAssingBtn2 = false;
            this.showNextBtnInAssign = false;
        }

    }
    setLessVal() {
        for (let i = 0; i < this.entryLessTypeOptions.length; i++) {
            this.lessTypeTA.push(this.entryLessTypeOptions[i].label);
        }
    }
    lessTypeFocus(e: NgbTypeaheadSelectItemEvent) {
        this.modelLessType = e.item;
        console.log("this.modelLessType");
        console.log(this.modelLessType);
        this.selectedTypeLess = $("#" + this.pageId + "lessType").val();
        console.log("this.selectedTypeLess");
        console.log(this.selectedTypeLess);

        this.rowSelectedLessType();
    }

    validateNextBtnInAssign() {
        this.enteredCompletedOnDate = $("#" + this.pageId + "completedOnDate").val();

        if (this.pfAssignedJobDetailsDataList.length == 0) {
            swal({
                title: "Empty Datas",
                text: "No Cashmemo Found in Assigned Job!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.viewFinishBtn = false;
            this.showNextBtnInAssign = true;
            this.viewShowNexInAssingBtn2 = false;
        } else if (this.enteredCompletedOnDate == null || this.enteredCompletedOnDate == '') {
            swal({
                title: "Mandetory",
                text: "Please Select Date",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.viewFinishBtn = false;
            this.showNextBtnInAssign = true;
            this.viewShowNexInAssingBtn2 = false;
        } else {
            swal({
                title: "OK",
                text: "Please Click Next button again to continue",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.viewFinishBtn = false;
            this.showNextBtnInAssign = false;
            this.viewShowNexInAssingBtn2 = true;
            $("#pmtfcnextBtnAssign").focus();
        }
    }

    //for Collection Man 
    getCollectionManInfo() {
        this.userDataDtoForCollectionMan = new UserDataDto();
        this.userDataDtoForCollectionMan.branchId = this.userDataDtoReturnSession.mainStation;
        this.userDataDtoForCollectionMan.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDtoForCollectionMan.status = "D";
        //PFCHG_V1
        this.userDataDtoForCollectionMan.godownName = this.userDataDtoReturnSession.office;
        this.userDataDtoForCollectionMan.specCollectionFlow = this.userDataDtoReturnSession.specCollectionFlow;
        //
        //        console.log('D');
    }


    getCollectionManDetails = () => {
        this.getCollectionManInfo();
        this.masterReadService.getCollectionManMasterDetails(this.userDataDtoForCollectionMan).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Collection Man  found!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.controlCollectionMan.reset();
                    this.collectionManOptions = [];
                    this.controlCollectionManAssign.reset();
                    this.collectionManOptionsAssign = [];
                    this.controlCollectionManToParty.reset();
                    this.collectionManOptionsToParty = [];
                    this.collectionManTA = [];
                    this.collectionManAssignTA = [];
                    this.collectionManStatusBackAssignTA = [];
                    this.collectionManToPartyTA = [];
                    this.collectionManToChkMemoEnbl = [];
                } else {
                    this.controlCollectionMan.reset();
                    this.userDataDtoForCollectionManRet = response;
                    this.collectionManOptions = this.userDataDtoForCollectionManRet;
                    // console.log("this.collectionManOptions");
                    // console.log(this.collectionManOptions);
                    this.controlCollectionManAssign.reset();
                    this.collectionManOptionsAssign = this.userDataDtoForCollectionManRet;
                    this.controlCollectionManToParty.reset();
                    this.collectionManOptionsToParty = this.userDataDtoForCollectionManRet;
                    this.collectionManTA = [];
                    this.collectionManAssignTA = [];
                    this.collectionManStatusBackAssignTA = [];
                    this.collectionManToPartyTA = [];
                    this.collectionManToChkMemoEnbl = [];
                    for (let i = 0; i < this.collectionManOptions.length; i++) {
                        this.collectionManTA.push(this.collectionManOptions[i].collectioMan);
                        this.collectionManAssignTA.push(this.collectionManOptions[i].collectioMan);
                        this.collectionManToPartyTA.push(this.collectionManOptions[i].collectioMan);
                        this.collectionManStatusBackAssignTA.push(this.collectionManOptions[i].collectioMan);
                        this.collectionManToChkMemoEnbl.push(this.collectionManOptions[i]);
                    }
                    //                    this.enteredMemoCodeMain=this.userDataDtoReturnSession.office;
                    // console.log("this.collectionManToChkMemoEnbl");
                    // console.log(this.collectionManToChkMemoEnbl);
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };


    collectionManFocus(e: NgbTypeaheadSelectItemEvent, contentAcknowledgement) {
        //        if ( e.keyCode == 13 ) {
        this.modelCollectionManName = e.item;
        console.log('this.modelCollectionManName');
        console.log(this.modelCollectionManName);
        console.log(this.modelCollectionManName.leftToPartyBlockEnabled);
        this.enteredCollectionMan = $("#" + this.pageId + "collectionMan").val();
        console.log(this.enteredCollectionMan);
        this.selectedActionType = $("#" + this.pageId + "actionType").val();
        this.selectedCollectionMan = $("#" + this.pageId + "collectionMan").val();
        if (this.selectedActionType == null || this.selectedActionType == '') {
            swal({
                title: "Warning",
                text: "Please select Action Type to get the details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            $("#" + this.pageId + "collectionMan").val('');
            this.modelCollectionManName = '';
        } else {
            this.showSpinnerForAction = true;
            if (this.userDataDtoReturnSession.validateTPReq != null
                && this.userDataDtoReturnSession.validateTPReq) {
                if (this.userDataDtoReturnSession.destLeftToPartyAckSubmitPeriod != null &&
                    (this.selectedActionType === "Assign" || this.selectedActionType === "To Party")) {
                    this.validateTPStmtPending(contentAcknowledgement);
                } else {
                    this.getCollectionManGridDtails();
                }
            } else {
                this.getCollectionManGridDtails();
            }
        }
    }


    getCollectionManGridInfo() {
        this.selectedActionType = $("#" + this.pageId + "actionType").val();
        //        this.selectedCollectionMan = $("#"+this.pageId+"collectionMan" ).val();
        this.selectedCollectionMan = this.modelCollectionManName;
        // console.log("this.selectedActionType");
        // console.log(this.selectedActionType);
        console.log(this.selectedCollectionMan);


        this.cashMemoDtoTable = new CashMemoDto();
        this.cashMemoDtoTable.status = 'collection';
        this.cashMemoDtoTable.mode = 'selected';
        this.cashMemoDtoTable.collectionMan = this.selectedCollectionMan;
        this.cashMemoDtoTable.destination = this.userDataDtoReturnSession.mainStation;
        this.cashMemoDtoTable.godown = this.userDataDtoReturnSession.office;
        this.cashMemoDtoTable.companyId = this.userDataDtoReturnSession.companyId;
        if (this.selectedActionType == 'Status Back & Assign') {
            this.cashMemoDtoTable.memoType = "Status Back";
        } else {
            this.cashMemoDtoTable.memoType = this.selectedActionType;
        }
        //PFCHG_V1
        this.cashMemoDtoTable.specCollectionFlow = this.userDataDtoReturnSession.specCollectionFlow;
    }


    getCollectionManGridDtails = () => {
        this.showSpinnerForAction = true;
        this.getCollectionManGridInfo();
        this.memoService.getCashMemoUnAssgDetailService(this.cashMemoDtoTable).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    this.pfCollectionManDetailsDataList = [];
                    this.pfCollectionManDetailsDataListForSearch = [];
                    //                    this.pfCollectionManDetailsDataList = response;
                    this.pfCollectionManDetailsDataListForSearch = response;
                    //                    this.rerender();
                    //                    console.log( this.pfCollectionManDetailsDataList );
                    //                    $("#"+this.pageId+'collectionManTable').DataTable().destroy();
                    if (this.setCollectionDetails == 'new') {
                        this.dtTriggerPfCollectionManDetails.next();
                        //                        this.dtTriggerPfCollectionManDetails.unsubscribe();
                        //                        $("#"+this.pageId+'collectionManTable').DataTable().destroy();
                        this.setCollectionDetails = 'old';
                    }
                    this.countOFMemosCollectionTable = 0;
                    this.sumOFMemosCollectionTable = 0;
                    this.newAttForColTable = {};

                    console.log("this.pfCollectionManDetailsDataListForSearch");
                    console.log(this.pfCollectionManDetailsDataListForSearch);
                    for (let i = 0; i < this.pfCollectionManDetailsDataListForSearch.length; i++) {
                        this.newAttForColTable.memoNumber = this.pfCollectionManDetailsDataListForSearch[i].memoNumber;
                        this.newAttForColTable.lrNumber = this.pfCollectionManDetailsDataListForSearch[i].lrNumber;
                        this.newAttForColTable.amount = this.pfCollectionManDetailsDataListForSearch[i].amount;
                        this.newAttForColTable.consigneeName = this.pfCollectionManDetailsDataListForSearch[i].consigneeName;
                        this.newAttForColTable.consigneeId = this.pfCollectionManDetailsDataListForSearch[i].consigneeId;
                        this.newAttForColTable.column1 = this.pfCollectionManDetailsDataListForSearch[i].column1;
                        this.newAttForColTable.enteredDate = this.pfCollectionManDetailsDataListForSearch[i].enteredDate;
                        this.newAttForColTable.memoTimePeriod = this.pfCollectionManDetailsDataListForSearch[i].memoTimePeriod;
                        this.newAttForColTable.memoDate = this.pfCollectionManDetailsDataListForSearch[i].memoDate;
                        this.newAttForColTable.memoDirectAssg = this.pfCollectionManDetailsDataListForSearch[i].memoDirectAssg;
                        this.newAttForColTable.leftToPartyEnable = this.pfCollectionManDetailsDataListForSearch[i].leftToPartyEnable;
                        this.pfCollectionManDetailsDataList.push(this.newAttForColTable);
                        this.newAttForColTable = {};
                        this.countOFMemosCollectionTable = this.countOFMemosCollectionTable + 1;
                        this.sumOFMemosCollectionTable = this.sumOFMemosCollectionTable + +(this.pfCollectionManDetailsDataListForSearch[i].amount);
                    }
                    console.log("this.pfCollectionManDetailsDataList");
                    console.log(this.pfCollectionManDetailsDataList);
                    this.cashMemoDtoTable = new CashMemoDto();
                }
                this.collectionManChkMethod();
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    collectionManLrDetailsDataTable() {
        this.dtOptionsPfCollectionManDetails = {
            // the below code is for button export starts
            //            destroy: true,
            dom: 'Bfrtip',
            buttons: [

            ],
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 200,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: false
        }
    }

    assignedJobDetailsTable() {
        this.dtOptionPfAssignedJobDetails = {
            dom: 'Bfrtip',
            buttons: [
            ],
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 200,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: false

        }
    }

    consigneeWiseDetailsTable() {
        this.dtOptionsConsigneeWiseBalanceDetails = {
            dom: 'Bfrtip',
            buttons: [
            ],
            //            language: {
            //                search: "_INPUT_",
            //                searchPlaceholder: "Search..."
            //            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 200,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: false,
            //            pagingType: 'full_numbers',
            //            pageLength: 6,
            //            "rowCallback": (row: Node, data: any[] | Object, index: number) => {
            //                const self = this;
            //                $('td', row).unbind('click');
            //                $('td', row).bind('click', () => {
            //                  self.someClickHandler(data);
            //                });
            //                return row;
            //              },
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                //                count starts
                //                var columnData = api
                //                    .column(
                //                    0 )
                //                    .data();
                //
                //                // Update footer
                //                $( api.column( 0 ).footer() ).html(
                //                    columnData.count()
                //                );
                //                //                counts ends
                //                //
                //                var pageTotals = api
                //                    .column( 2, { page: 'current' } )
                //                    .data()
                //                    .reduce( function( a, b ) {
                //                        return intVal( a ) + intVal( b )
                //                    }, 0 );
                //                $( api.column( 2 ).footer() ).html(
                //                    pageTotals
                //                );


            }

        }
    }

    lessEntriesTable() {
        this.dtOptionsLessEntriesDataTable = {
            dom: 'Bfrtip',
            buttons: [
            ],
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 200,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: false,
        }
    }

    paymentEntriesDataTable() {
        this.dtOptionsPaymentAmountEntries = {
            dom: 'Bfrtip',
            buttons: [
            ],
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 200,
            "scrollCollapse": true,
            "info": false,
            paging: false,
            searching: false
        }
    }

    validateTPStmtPendingInfo() {
        //Pending 
        this.selectedCollectionMan = this.modelCollectionManName;
        this.cashMemoDtoForTPStmt = new CashMemoDto();
        this.cashMemoDtoForTPStmt.collectionMan = this.selectedCollectionMan;
        this.cashMemoDtoForTPStmt.companyId = this.userDataDtoReturnSession.companyId;
        this.cashMemoDtoForTPStmt.destination = this.userDataDtoReturnSession.mainStation;
        this.cashMemoDtoForTPStmt.tpStatus = 'Pending';
        this.cashMemoDtoForTPStmt.status = 'To Party';
        this.cashMemoDtoForTPStmt.destLeftToPartyAckSubmitPeriod = this.userDataDtoReturnSession.destLeftToPartyAckSubmitPeriod;
        //        console.log( this.selectedCollectionMan);
    }


    validateTPStmtPending = (contentAcknowledgement) => {
        this.validateTPStmtPendingInfo();
        this.memoService.getTPStmtPendingDetailsValidationCheck(this.cashMemoDtoForTPStmt).subscribe(
            (response) => {
                if (response.length > 0) {
                    console.log('1');
                    console.log(response[0].leftToPartyBlockEnabled);
                    this.collectionManTP = this.selectedCollectionMan;
                    this.countTp = response.length;
                    this.showSpinnerForAction = false;
                    if (response[0].leftToPartyBlockEnabled == false) {
                        this.modalService.open(contentAcknowledgement, { centered: true });
                    } else {
                        this.getCollectionManGridDtails();
                    }
                } else {
                    console.log('2');
                    this.getCollectionManGridDtails();
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    cancelTP() {
        this.cashMemoDtoForTPStmt = new CashMemoDto();
        this.collectionManTP = '';
        this.countTp = '';
        this.getCollectionManGridDtails();
    }


    ///For getting List of Station
    getStationInfo() {
        this.lrDtoForStation = new LRDto();
        this.lrDtoForStation.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoForStation.mainstation = this.userDataDtoReturnSession.mainStation;
        this.lrDtoForStation.mode = "ALL";
    }


    getStationDtails = () => {
        this.getStationInfo();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoForStation).subscribe(
            (response) => {
                if (response) {
                    this.stationOptions = response;
                    this.entryLessStationOptions = response;
                    this.paymentLessStationOptions = response;
                    //                    console.log( this.entryLessStationOptions );
                    this.lrDtoForStation = new LRDto();
                    this.stationTA = [];
                    this.entryLessStationTA = [];
                    this.paymentLessStnTA = [];
                    //                    console.log(this.stationOptions.length);
                    for (let i = 0; i < this.stationOptions.length; i++) {
                        this.stationTA.push(this.stationOptions[i].destination);
                        this.entryLessStationTA.push(this.stationOptions[i].destination);
                        this.paymentLessStnTA.push(this.stationOptions[i].destination);
                    }


                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => swal({
                title: "Error",
                text: "Server Error While Getting Station Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };


    //    ///For Getting Memo Code
    //    getDetailsForMemoCode( mode ) {
    //        this.userDtoMemoCode = new UserDataDto();
    //        this.userDtoMemoCode.companyId = this.userDataDtoReturnSession.companyId;
    //        if ( mode == 'main' ) {
    ////            this.stationForCode = $("#"+this.pageId+"station" ).val();
    //            this.enteredMemoCodeMain= $("#"+this.pageId+"station" ).val();
    //            this.stationForCode = this.enteredMemoCodeMain;
    //            console.log(this.enteredMemoCodeMain);
    //            this.userDtoMemoCode.office = this.stationForCode;
    //            console.log(this.userDtoMemoCode.office);
    //        } else if ( mode == 'entry' ) {
    ////            this.stationForCode = $("#"+this.pageId+"entryLessStation" ).val();
    //            this.stationForCode = this.enteredMemoCodeLess;
    //            this.userDtoMemoCode.office = this.stationForCode;
    //        } else if ( mode == 'payment' ) {
    ////            this.stationForCode = $("#"+this.pageId+"paymentLessStn" ).val();
    //            this.stationForCode = this.enteredMemoCodePayment;
    //            this.userDtoMemoCode.office = this.stationForCode;
    //        }
    //    }
    //    getMemoCode( e, mode: string ) {
    ////        if ( e.keyCode == 13 ) {
    //            this.getDetailsForMemoCode( mode );           
    //            this.masterReadService.getStationCodeService( this.userDtoMemoCode ).subscribe(
    //                ( response ) => {
    //                    if ( response.length != 0 ) {
    //                        this.stationCodeDetails = response;
    //                        this.selectedEntryType = $("#"+this.pageId+"entryType" ).val();
    //                        this.selectedEntryTypeForLess = $("#"+this.pageId+"entryLessEntryType" ).val();
    //                        this.selectedEntryTypeForPayment = $("#"+this.pageId+"entryLessPaymentEntry" ).val();
    //
    //                        if ( mode == 'main' ) {
    //                            if ( this.selectedEntryType == "automatic" ) {
    //                                this.manualView = false;
    //                                this.automaticView = true;
    //                                $("#pmtfcmemoNumberForAutomatic" ).focus();
    //                            } else {
    //
    //                                if ( this.stationCodeDetails.stationCode == null ) {
    //                                    $("#"+this.pageId+"codeForManual" ).val( '' );
    //                                } else {
    //                                    //Basha Pending
    //                                    this.memoFormat = this.stationCodeDetails.stationCode + "/" + this.userDataDtoReturnSession.financialYearSplit + "/";
    //                                    //                                this.memoFormat = 'BNG/1920/';
    //                                    $("#"+this.pageId+"codeForManual" ).val( this.memoFormat );
    //                                    $("#"+this.pageId+"memoNumberForManual" ).val( '' );
    //                                    $("#"+this.pageId+"memoNumberForAutomatic" ).val( '' );
    //                                    $("#pmtfcmemoNumberForManual" ).focus();
    //                                }
    //                            }
    //
    //
    //
    //                        } else if ( mode == 'entry' ) {
    //                            if ( this.selectedEntryTypeForLess == "entryLessAutomatic" ) {
    //                                this.entryLessManualView = false;
    //                                this.entryLessAutomaticView = true;
    //                                $("#pmtfcmemoNumberForAutomaticLess" ).focus();
    //                            } else {
    //
    //                                if ( this.stationCodeDetails.stationCode == null ) {
    //                                    $("#"+this.pageId+"codeForManualLess" ).val( '' );
    //                                } else {
    //                                    //Basha Pending
    //                                    this.memoFormat = this.stationCodeDetails.stationCode + "/" + this.userDataDtoReturnSession.financialYearSplit + "/";
    //                                    //                                this.memoFormat = 'BNG/1920/';
    //                                    $("#"+this.pageId+"codeForManualLess" ).val( this.memoFormat );
    //                                    $("#"+this.pageId+"memoNumberForManualLess" ).val( '' );
    //                                    $("#pmtfcmemoNumberForManualLess" ).focus();
    //                                    $("#"+this.pageId+"memoNumberForAutomaticLess" ).val( '' );
    //                                }
    //                            }
    //
    //                        } else if ( mode == 'payment' ) {
    //                            if ( this.selectedEntryTypeForPayment == "paymentLessAutomatic" ) {
    //                                this.paymentLessManualView = false;
    //                                this.paymentLessAutomaticView = true;
    //                                $("#pmtfcmemoNumberForAutomaticLessPayment" ).focus();
    //                            } else {
    //
    //                                if ( this.stationCodeDetails.stationCode == null ) {
    //                                    $("#"+this.pageId+"codeForManualLessPayment" ).val( '' );
    //                                } else {
    //                                    //Basha Pending
    //                                    this.memoFormat = this.stationCodeDetails.stationCode + "/" + this.userDataDtoReturnSession.financialYearSplit + "/";
    //                                    //                                this.memoFormat = 'BNG/1920/';
    //                                    $("#"+this.pageId+"codeForManualLessPayment" ).val( this.memoFormat );
    //                                    $("#"+this.pageId+"memoNumberForManualLessPayment" ).val( '' );
    //                                    $("#pmtfcmemoNumberForManualLessPayment" ).focus();
    //                                    $("#"+this.pageId+"memoNumberForAutomaticLessPayment" ).val( '' );
    //                                }
    //                            }
    //                        }
    //                        this.userDtoMemoCode = new UserDataDto();
    //                    }
    //
    //                } ), ( error ) => swal( {
    //                    title: "Server Error",
    //                    text: "While Getting Station",
    //                    icon: "warning",
    //                    closeOnClickOutside: false,
    //                    closeOnEsc: false,
    //                } ),
    //                () => console.log( 'done' );
    ////        }
    //    };

    ///For Getting Memo Code

    getMemoCode(e: NgbTypeaheadSelectItemEvent, mode: string) {
        this.userDtoMemoCode = new UserDataDto();
        this.modelStationName = e.item;
        this.userDtoMemoCode.companyId = this.userDataDtoReturnSession.companyId;
        //        console.log(this.modelStationName);

        if (mode == 'main') {
            //            this.stationForCode = $("#"+this.pageId+"station" ).val();
            this.stationForCode = this.modelStationName;
            this.userDtoMemoCode.office = this.stationForCode;
        } else if (mode == 'entry') {
            //            this.stationForCode = $("#"+this.pageId+"entryLessStation" ).val();
            this.stationForCode = this.modelStationName;
            this.userDtoMemoCode.office = this.stationForCode;
        } else if (mode == 'payment') {
            //            this.stationForCode = $("#"+this.pageId+"paymentLessStn" ).val();
            this.stationForCode = this.modelStationName;
            this.userDtoMemoCode.office = this.stationForCode;
        }
        this.getDetailsForMemoCode(mode);
    }
    getDetailsForMemoCode(mode: string) {
        // if ( e.keyCode == 13 ) {
        //      console.log( this.userDtoMemoCode.office = this.stationForCode);

        this.masterReadService.getStationCodeService(this.userDtoMemoCode).subscribe(
            (response) => {
                if (response.length != 0) {
                    this.stationCodeDetails = response;
                    this.selectedEntryType = $("#" + this.pageId + "entryType").val();
                    this.selectedEntryTypeForLess = $("#" + this.pageId + "entryLessEntryType").val();
                    this.selectedEntryTypeForPayment = $("#" + this.pageId + "entryLessPaymentEntry").val();

                    if (mode == 'main') {
                        if (this.selectedEntryType == "automatic") {
                            this.manualView = false;
                            this.automaticView = true;
                            $("#pmtfcmemoNumberForAutomatic").focus();
                        } else {

                            if (this.stationCodeDetails.stationCode == null) {
                                $("#" + this.pageId + "codeForManual").val('');
                            } else {
                                //Basha Pending
                                this.memoFormat = this.stationCodeDetails.stationCode + "/" + this.userDataDtoReturnSession.financialYearSplit + "/";
                                //                                this.memoFormat = 'BNG/1920/';
                                $("#" + this.pageId + "codeForManual").val(this.memoFormat);
                                $("#" + this.pageId + "memoNumberForManual").val('');
                                $("#" + this.pageId + "memoNumberForAutomatic").val('');

                                window.setTimeout(function () {
                                    $("#pmtfcmemoNumberForManual").focus();
                                }, 100);
                            }
                        }



                    } else if (mode == 'entry') {
                        if (this.selectedEntryTypeForLess == "entryLessAutomatic") {
                            this.entryLessManualView = false;
                            this.entryLessAutomaticView = true;
                            $("#pmtfcmemoNumberForAutomaticLess").focus();
                        } else {

                            if (this.stationCodeDetails.stationCode == null) {
                                $("#" + this.pageId + "codeForManualLess").val('');
                            } else {
                                //Basha Pending
                                this.memoFormat = this.stationCodeDetails.stationCode + "/" + this.userDataDtoReturnSession.financialYearSplit + "/";
                                //                                this.memoFormat = 'BNG/1920/';
                                $("#" + this.pageId + "codeForManualLess").val(this.memoFormat);
                                $("#" + this.pageId + "memoNumberForManualLess").val('');
                                $("#pmtfcmemoNumberForManualLess").focus();
                                $("#" + this.pageId + "memoNumberForAutomaticLess").val('');
                            }
                        }

                    } else if (mode == 'payment') {
                        if (this.selectedEntryTypeForPayment == "paymentLessAutomatic") {
                            this.paymentLessManualView = false;
                            this.paymentLessAutomaticView = true;
                            $("#pmtfcmemoNumberForAutomaticLessPayment").focus();
                        } else {

                            if (this.stationCodeDetails.stationCode == null) {
                                $("#" + this.pageId + "codeForManualLessPayment").val('');
                            } else {
                                //Basha Pending
                                this.memoFormat = this.stationCodeDetails.stationCode + "/" + this.userDataDtoReturnSession.financialYearSplit + "/";
                                //                                this.memoFormat = 'BNG/1920/';
                                $("#" + this.pageId + "codeForManualLessPayment").val(this.memoFormat);
                                $("#" + this.pageId + "memoNumberForManualLessPayment").val('');
                                $("#pmtfcmemoNumberForManualLessPayment").focus();
                                $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val('');
                            }
                        }
                    }
                    this.userDtoMemoCode = new UserDataDto();
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Getting Station",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
        //}
    };
    getMemoNumberForAutomatic(e, keyEnter: string) {
        setTimeout(() => {
            this.showforAutomatic(keyEnter);
        },
            500);


    }
    showforAutomatic(keyEnter: string) {
        this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomatic").val();
        if (this.selectedMemoNumberForAutomatic != null && this.selectedMemoNumberForAutomatic != '') {
            this.gridReconfigureAssg(keyEnter);
        }
    }
    //    getMemoNumberForAutomatic( e, keyEnter: string ) {
    //        if ( e.keyCode == 13 ) {
    //        this.selectedMemoNumberForAutomatic = $("#"+this.pageId+"memoNumberForAutomatic" ).val();
    //        if ( this.selectedMemoNumberForAutomatic != null && this.selectedMemoNumberForAutomatic != '' ) {
    //            this.gridReconfigureAssg( keyEnter );
    //        }
    //        }
    //    }
    getMemoNumberForManual(e, keyEnter: string) {
        if (e.keyCode == 13) {
            this.selectedMemoNumberForManual = $("#" + this.pageId + "memoNumberForManual").val();
            if (this.selectedMemoNumberForManual != null && this.selectedMemoNumberForManual != '') {

                this.gridReconfigureAssg(keyEnter);
            }
            //            this.gridReconfigureAssg( keyEnter );
        }

    }
    collectionManChkMethod() {
        // this.modelCollectionManName = e.item;
        this.enteredCollectionMan = $("#" + this.pageId + "collectionMan").val();
        this.memoAssignEnblObj = null;

        for (var i = 0; i < this.collectionManToChkMemoEnbl.length; i++) {
            if (this.collectionManToChkMemoEnbl[i].collectioMan.toLowerCase() == this.enteredCollectionMan.toLowerCase()) {
                this.memoAssignEnblObj = this.collectionManToChkMemoEnbl[i].memoAssignEnabled;
                break;
            }
        }
        console.log("this.memoAssignEnblObj >> in method");
        console.log(this.memoAssignEnblObj);
    }
    gridReconfigureAssg(keyEnter) {
        console.log('gridReconfigureAssg');
        console.log(this.pfAssignedJobDetailsDataList[0]);
        this.tempStoreDup = new CashMemoDto();
        this.memoNum = '';
        this.selectedActionType = $("#" + this.pageId + "actionType").val();
        this.selectedEntryType = $("#" + this.pageId + "entryType").val();
        this.selectedCodeForManual = $("#" + this.pageId + "codeForManual").val();
        this.selectedMemoNumberForManual = $("#" + this.pageId + "memoNumberForManual").val();
        this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomatic").val();
        if (this.selectedEntryType == 'automatic') {
            this.memoNum = this.selectedMemoNumberForAutomatic;
            //            console.log( this.memoNum );
        } else {
            this.memoNum = this.selectedCodeForManual + "" + this.selectedMemoNumberForManual;
            //            console.log( this.memoNum );
        }
        //Checking in collection Man data List
        this.searchedMemoInfo = new CashMemoDto();
        this.searchedMemoInfo = this.searchInCollectionManTable(this.memoNum);
        //        console.log( this.searchedMemoInfo );
        if (this.searchedMemoInfo.memoNumber == null || this.searchedMemoInfo.memoNumber == '') {
            this.modelForSearched = '';
        } else {
            this.modelForSearched = this.searchedMemoInfo.memoNumber;
        }
        //        console.log( '1' + this.modelForSearched );
        //Checking in Assingning Man data List
        this.searchedMemoForDup = new CashMemoDto();
        this.searchedMemoForDup = this.searchInCollectionManTableFoDup(this.memoNum);
        if (this.searchedMemoForDup.memoNumber == null || this.searchedMemoForDup.memoNumber == '') {
            this.modelDup = '';
        } else {
            this.modelDup = this.searchedMemoForDup.memoNumber;
        }

        //        console.log( '2 ' + this.modelDup );
        //        //Checking in Assingning Man data List
        this.searchedMemoForMemoAlreadyExist = new CashMemoDto();
        this.searchedMemoForMemoAlreadyExist = this.searchInCollectionManTableForAlreadyExist(this.memoNum);

        if (this.searchedMemoForMemoAlreadyExist.memoNumber == null || this.searchedMemoForMemoAlreadyExist.memoNumber == '') {
            this.modelAlreadyExist = '';
        } else {

            this.modelAlreadyExist = this.searchedMemoForMemoAlreadyExist.memoNumber;
        }

        //        console.log( this.modelAlreadyExist );

        if (keyEnter == 'keyEnter' && (this.modelForSearched == null || this.modelForSearched == '')) {
            swal({
                title: "CashMemo Assigning",
                text: "CashMemo " + this.memoNum + " does not exist!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                if (this.selectedEntryType == 'automatic') {
                    $("#pmtfcmemoNumberForAutomatic").focus();
                } else {
                    $("#pmtfcmemoNumberForManual").focus();
                }
            });
            this.addInTable = false;
        } else if (this.modelAlreadyExist != null && this.modelAlreadyExist != '' && this.consigneeExist == false) {
            swal({
                title: "CashMemo Assigning",
                text: "CashMemo" + this.memoNum + " already transfer!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                if (this.selectedEntryType == 'automatic') {
                    $("#pmtfcmemoNumberForAutomatic").focus();
                } else {
                    $("#pmtfcmemoNumberForManual").focus();
                }
            });
            this.addInTable = false;
        } else if (this.selectedActionType != null && this.selectedActionType != '' && this.selectedActionType == 'To Party') {
            this.assingedDetailsDataList = this.pfAssignedJobDetailsDataList[0];
            console.log(this.assingedDetailsDataList);
            console.log(this.searchedMemoInfo);
            //            console.log( this.searchedMemoForMemoAlreadyExist.consigneeName );

            //PFCHG_V4
            this.addInTable = false;
            if (this.searchedMemoInfo.leftToPartyEnable == 'No') {
                swal({
                    title: "Not Allowed",
                    text: "Left To-Party is not allowed for this Consignee's Memo, please contact Admin to enable it!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            } else {
                if (this.pfAssignedJobDetailsDataList.length > 0) {
                    if (this.assingedDetailsDataList.consigneeName != this.searchedMemoForMemoAlreadyExist.consigneeName) {
                        swal({
                            title: "Not Allowed",
                            text: "Different Consignee's Memo can't be possible at once in To Party, please select the same Consignee's Memo!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    } else if (this.modelForSearched != this.modelDup) {
                        if (keyEnter == 'keyEnter') {
                            this.addInTable = true;
                            this.tempStoreDup = this.searchedMemoInfo;
                            // this.gridReconfigureAllRemove();
                        }
                    }
                } else if (this.modelForSearched != this.modelDup) {
                    if (keyEnter == 'keyEnter') {
                        this.addInTable = true;
                        this.tempStoreDup = this.searchedMemoInfo;
                        //                console.log( this.tempStoreDup );
                        // this.gridReconfigureAllRemove();
                    }
                }
            }

        } else if (this.modelForSearched != this.modelDup) {
            if (keyEnter == 'keyEnter') {
                this.addInTable = true;
                this.tempStoreDup = this.searchedMemoInfo;
                //                console.log( this.tempStoreDup );
                // this.gridReconfigureAllRemove();
            }
        }

        // console.log("this.todayDate");
        // console.log(this.todayDate);
        // console.log("this.tempStoreDup");
        // console.log(this.tempStoreDup);
        // let enteredDateCheckStr = null;
        // enteredDateCheckStr = this.datePipe.transform(this.memoDateCheck, "yyyy-MM-dd h:mm:ss a zzzz");
        // console.log("this.memoDateCheck, enteredDateCheckStr, this.userDataDtoReturnSession  >> 1");
        // console.log(this.memoDateCheck, newDate, this.userDataDtoReturnSession);
        // console.log("this.memoDateCheck  >> 2");
        // console.log(this.memoDateCheck);
        // console.log("this.memoDateCheck, this.todayDate  >> 4");
        // console.log(this.memoDateCheck, this.todayDate);
        // if (this.memoDateCheck < this.todayDate) {
        //     this.addInTable = false;
        //     swal({
        //         title: "Cashmemo Block",
        //         text: "Cashmemo is block it extend the buffer time, cashmemo no: " + this.memoNum,
        //         icon: "warning",
        //         closeOnClickOutside: false,
        //         closeOnEsc: false,
        //     });
        // } else {
        //     this.addInTable = true;
        // }
        // console.log("this.userDataDtoReturnSession");
        // console.log(this.userDataDtoReturnSession);
        // today date
        if (this.selectedActionType === "Assign") {
            if (this.userDataDtoReturnSession.blockDestMemoTimePeriod == false) {
                // this.addInTable = true;
                console.log("am inside of if godowntable >> blockDestMemoTimePeriod is false");
            } else {
                // console.log("am inside of else godowntable >> blockDestMemoTimePeriod is true");
                console.log("this.memoAssignEnblObj >> condition before");
                console.log(this.memoAssignEnblObj);
                if (this.memoAssignEnblObj == false) {
                    // memoAssignEnabled
                    this.todayDate = new Date();
                    this.todayDate.setDate(this.todayDate.getDate());
                    this.todayDate = this.datePipe.transform(this.todayDate, "yyyy-MM-dd");
                    let enteredDateCheckStrChk = null;
                    let addMemoTime = null;
                    let consigneeDtlsMemoTime = null;
                    let newDate = null;
                    // memoTimePeriod
                    consigneeDtlsMemoTime = this.tempStoreDup.memoTimePeriod;
                    if (consigneeDtlsMemoTime != null && consigneeDtlsMemoTime > 0) {
                        addMemoTime = consigneeDtlsMemoTime;
                    } else {
                        addMemoTime = this.userDataDtoReturnSession.memoTimePeriod;
                    }
                    console.log("this.tempStoreDup");
                    console.log(this.tempStoreDup);
                    // console.log("this.memoDateCheck, this.todayDate, addMemoTime  >> 1");
                    // console.log(this.memoDateCheck, this.todayDate, addMemoTime);
                    //entered date
                    this.memoDateCheck = this.tempStoreDup.memoDate;
                    newDate = new Date(this.memoDateCheck);
                    this.memoDateCheck = newDate;
                    this.memoDateCheck.setDate(this.memoDateCheck.getDate() + addMemoTime);
                    enteredDateCheckStrChk = this.datePipe.transform(this.memoDateCheck, "yyyy-MM-dd");
                    this.memoDateCheck = enteredDateCheckStrChk;
                    this.selectedActionType = $("#" + this.pageId + "actionType").val();
                    // console.log("this.memoDateCheck, this.todayDate, addMemoTime  >> 2");
                    // console.log(this.memoDateCheck, this.todayDate, addMemoTime);
                    // console.log("this.tempStoreDup");
                    // console.log(this.tempStoreDup);
                    console.log("this.memoDateCheck, this.todayDate");
                    console.log(this.memoDateCheck, this.todayDate);
                    if (this.memoDateCheck < this.todayDate) {
                        this.addInTable = false;
                        swal({
                            title: "Cashmemo Block",
                            text: "Cashmemo Assign is blocked due to memo stock period exceeds, please contact Admin. Cashmemo No:  " + this.memoNum + "",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    } else {
                        this.addInTable = true;
                    }
                }
            }
        }


        if (this.addInTable == true) {
            this.gridReconfigureAllRemove();
            if (this.pfAssignedJobDetailsDataList == null) {
                //                console.log( '21' );
                this.pfAssignedJobDetailsDataList = [];
                //                this.dtTriggerPfAssignedJobDetails.next();
            }


            //            if ( this.pfAssignedJobDetailsDataList.length == 0 ) {
            //                console.log( '22' );
            //                this.pfAssignedJobDetailsDataList.push( this.tempStoreDup );
            //            } else {
            //                console.log( '23' );
            //                this.pfAssignedJobDetailsDataList.push( this.tempStoreDup );
            //            }
            if (this.setAssingedDetails == 'new') {
                this.dtTriggerPfAssignedJobDetails.next();
                this.setAssingedDetails = 'old';
            }
            //            console.log(this.tempStoreDup);
            this.pfAssignedJobDetailsDataList.push(this.tempStoreDup);
            this.countOfAssignLR = +this.countOfAssignLR + 1;
            this.sumOfAssignLR = +this.sumOfAssignLR + +this.tempStoreDup.amount;
            this.tempstore.push(this.tempStoreDup);
            //            console.log( this.tempstore.length );
            this.tempStoreDup = new CashMemoDto();
            this.addInTable = false;

        } else {
            this.tempStoreDup = new CashMemoDto();
            this.addInTable = false;
        }
        $("#" + this.pageId + "memoNumberForAutomatic").val('');
        $("#" + this.pageId + "memoNumberForManual").val('');
        this.value = true;

    }
    //
    searchInCollectionManTable(selectedNum: string): CashMemoDto {
        //        console.log( '1111' );
        //        console.log( selectedNum );
        //        console.log( this.pfCollectionManDetailsDataList );
        //        console.log( this.pfCollectionManDetailsDataList.find( pfCollectionManDetailsData => pfCollectionManDetailsData.memoNumber === selectedNum ) );
        this.letValueofCollectionMan = (this.pfCollectionManDetailsDataList.find(pfCollectionManDetailsData => pfCollectionManDetailsData.memoNumber === selectedNum));
        //        console.log( this.letValueofCollectionMan );
        if (this.letValueofCollectionMan == null || this.letValueofCollectionMan == undefined) {
            this.letValueofCollectionMan = new CashMemoDto();
            //            console.log( 'boss1s' );
        } else {
            //            console.log( 'boss2s' );
            this.letValueofCollectionMan = this.letValueofCollectionMan;
        }
        //        console.log( this.letValueofCollectionMan );
        return this.letValueofCollectionMan;
    }
    /////////////////////
    searchInCollectionManTableFoDup(selectedNum: string) {

        if (this.pfAssignedJobDetailsDataList.length == 0) {
            this.getsearchInCollectionManTableFoDup = new CashMemoDto();
            //            console.log( ' 1' );
        } else {
            //            console.log( '2' );
            this.getsearchInCollectionManTableFoDup = this.enteredSearchInCollectionManTableFoDup(selectedNum)
        }
        //        console.log( this.getsearchInCollectionManTableFoDup );
        return this.getsearchInCollectionManTableFoDup;
    }
    enteredSearchInCollectionManTableFoDup(selectedNum: string): CashMemoDto {
        //        console.log( '1111' );
        this.letValueofSearchInCollectionManTableFoDup = (this.pfAssignedJobDetailsDataList.find(pfAssignedJobDetailsData => pfAssignedJobDetailsData.memoNumber === selectedNum));
        if (this.letValueofSearchInCollectionManTableFoDup == null || this.letValueofSearchInCollectionManTableFoDup == undefined) {
            this.letValueofSearchInCollectionManTableFoDup = new CashMemoDto();
            //            console.log( 'boss1' );
        } else {
            //            console.log( 'boss1' );
            this.letValueofSearchInCollectionManTableFoDup = this.letValueofSearchInCollectionManTableFoDup;
        }
        //        console.log( this.letValueofSearchInCollectionManTableFoDup );
        return this.letValueofSearchInCollectionManTableFoDup;
    }
    ///////////

    searchInCollectionManTableForAlreadyExist(selectedNum: string) {

        if (this.pfAssignedJobDetailsDataList.length == 0) {
            this.getsearchInCollectionManTableForAlreadyExist = new CashMemoDto();
            //            console.log( ' 1s' );
        } else {
            //            console.log( '2s' );
            this.getsearchInCollectionManTableForAlreadyExist = this.enteredSearchInCollectionManTableForAlreadyExist(selectedNum)
        }
        //        console.log( this.getsearchInCollectionManTableForAlreadyExist );
        return this.getsearchInCollectionManTableForAlreadyExist;
    }
    enteredSearchInCollectionManTableForAlreadyExist(selectedNum: string): CashMemoDto {
        this.consigneeExist = false;
        //        console.log( '1111' );
        this.letValueofSearchInCollectionManTableFoAlreadyExist = (this.pfAssignedJobDetailsDataList.find(pfAssignedJobDetailsData => pfAssignedJobDetailsData.memoNumber === selectedNum));
        if (this.letValueofSearchInCollectionManTableFoAlreadyExist == null || this.letValueofSearchInCollectionManTableFoAlreadyExist == undefined) {
            //            this.letValueofSearchInCollectionManTableFoAlreadyExist = new CashMemoDto();
            //            console.log( 'boss11' );
            this.selectedActionType = $("#" + this.pageId + "actionType").val();
            if (this.selectedActionType == 'To Party') {
                this.letValueofSearchInCollectionManTableFoAlreadyExistParty = this.getConsigneeNameParty(selectedNum);
                this.consigneeeName = this.letValueofSearchInCollectionManTableFoAlreadyExistParty.consigneeName;
                //                console.log( this.consigneeeName );
                this.letValueofSearchInCollectionManTableFoAlreadyExist = this.searchingToParty(this.consigneeeName);
            } else {
                this.letValueofSearchInCollectionManTableFoAlreadyExist = new CashMemoDto();
            }

        } else {
            //            console.log( 'boss12' );
            this.letValueofSearchInCollectionManTableFoAlreadyExist = this.letValueofSearchInCollectionManTableFoAlreadyExist;
        }
        //        console.log( this.letValueofSearchInCollectionManTableFoAlreadyExist );
        return this.letValueofSearchInCollectionManTableFoAlreadyExist;
    }

    searchingToParty(selectedConsigneeName: string): CashMemoDto {
        this.consigneeExist = false;
        //        console.log( '2222' );
        this.getValueofSearchInCollectionManTableFoAlreadyExistToParty = (this.pfAssignedJobDetailsDataList.find(pfAssignedJobDetailsData => pfAssignedJobDetailsData.consigneeName === selectedConsigneeName));
        if (this.getValueofSearchInCollectionManTableFoAlreadyExistToParty == null || this.getValueofSearchInCollectionManTableFoAlreadyExistToParty == undefined) {
            this.getValueofSearchInCollectionManTableFoAlreadyExistToParty = new CashMemoDto();
            //            console.log( '21' );
            this.consigneeExist = false;
        } else {
            //            console.log( '22' );
            this.consigneeExist = true;
            this.getValueofSearchInCollectionManTableFoAlreadyExistToParty = this.getValueofSearchInCollectionManTableFoAlreadyExistToParty;
        }

        //        console.log( this.getValueofSearchInCollectionManTableFoAlreadyExistToParty );
        //        console.log( this.consigneeExist );
        return this.getValueofSearchInCollectionManTableFoAlreadyExistToParty;
    }

    getConsigneeNameParty(selectedNum: string): CashMemoDto {
        this.setValueofSearchInCollectionManTableFoAlreadyExistToParty = (this.pfCollectionManDetailsDataList.find(pfCollectionManDetailsData => pfCollectionManDetailsData.memoNumber === selectedNum));
        if (this.setValueofSearchInCollectionManTableFoAlreadyExistToParty == null || this.setValueofSearchInCollectionManTableFoAlreadyExistToParty == undefined) {
            this.setValueofSearchInCollectionManTableFoAlreadyExistToParty = new CashMemoDto();
            //            console.log( '21' );
            this.consigneeExist = false;
        } else {
            //            console.log( '22' );
            this.consigneeExist = true;
            this.setValueofSearchInCollectionManTableFoAlreadyExistToParty = this.setValueofSearchInCollectionManTableFoAlreadyExistToParty;
        }

        return this.setValueofSearchInCollectionManTableFoAlreadyExistToParty;
    }
    /////////////////////////////

    gridReconfigureAllRemove() {
        let cashmemonumber = null;
        this.selectedEntryType = $("#" + this.pageId + "entryType").val();
        this.selectedCodeForManual = $("#" + this.pageId + "codeForManual").val();
        this.selectedMemoNumberForManual = $("#" + this.pageId + "memoNumberForManual").val();
        this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomatic").val();
        if (this.selectedEntryType == 'automatic') {
            cashmemonumber = this.selectedMemoNumberForAutomatic;
        } else {
            cashmemonumber = this.selectedCodeForManual + "" + this.selectedMemoNumberForManual;
        }
        //Pending of date , remove btn,all btn, remove all btn, and reconfigure of datalist of both
        let index = -1;
        for (let i = 0; i < this.pfCollectionManDetailsDataList.length; i++) {
            if (this.pfCollectionManDetailsDataList[i].memoNumber === cashmemonumber) {
                index = i;
                break;

            }
        }
        if (index === -1) {
            alert("Something gone wrong gridReconfigureAllRemove()");
        }
        this.pfCollectionManDetailsDataList.splice(index, 1);
        this.pfCollectionManDetailsDataListForSearch.splice(index, 1);
        this.countOFMemosCollectionTable = 0;
        this.sumOFMemosCollectionTable = 0;
        for (let i = 0; i < this.pfCollectionManDetailsDataList.length; i++) {
            this.countOFMemosCollectionTable = this.countOFMemosCollectionTable + 1;
            this.sumOFMemosCollectionTable = this.sumOFMemosCollectionTable + +(this.pfCollectionManDetailsDataList[i].amount);
        }

        //        this.pfCollectionManDetailsDataList.splice( this.pfCollectionManDetailsDataList.find( pfCollectionManDetailsData => pfCollectionManDetailsData.memoNumber === cashmemonumber ), 1 );
        //        console.log( this.pfCollectionManDetailsDataList );
    }
    //Btn Finish
    collectionManFinish(content10, content11, popupCollectionManAssign) {
        this.selectedActionType = $("#" + this.pageId + "actionType").val();
        this.selectedCollectionMan = this.enteredCollectionMan;
        // this.selectedCollectionManStatusBackAssign = $("#" + this.pageId + "collectionManStatusBackAssign").val();
        // console.log("this.selectedCollectionManStatusBackAssign, this.modelCollectionManStatusBackAssign ");
        // console.log(this.selectedCollectionManStatusBackAssign, this.modelCollectionManStatusBackAssign);

        if (this.pfAssignedJobDetailsDataList.length == 0) {
            swal({
                title: "Empty Datas",
                text: "No Cashmemo Found in Assigned Job!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            //For testing
            //            this.modalService.open( content10, { centered: true } ); 
        } else if (this.selectedActionType == null || this.selectedActionType == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please select Action type",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else if (this.selectedCollectionMan == null || this.selectedCollectionMan == '') {
            swal({
                title: "Mandatory Fields",
                text: "Please Select Collection Man to Proceed",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else if (this.selectedActionType != null && this.selectedActionType == 'Assign') {
            this.callForAssignMemo(content10);
        } else if (this.selectedActionType != null && this.selectedActionType == 'Status Back') {
            this.callForStatusBackMemo();
        } else if (this.selectedActionType != null && this.selectedActionType == 'To Party') {
            this.callForToPartyMemo(content11);
        } else if (this.selectedActionType != null && this.selectedActionType == 'Finish') {
            this.callForClosingCollection();
        } else if (this.selectedActionType != null && this.selectedActionType == 'Complete') {
            this.callForCompleteCollection();
        } else if (this.selectedActionType != null && this.selectedActionType == 'Status Back & Assign') {
            this.modalService.open(popupCollectionManAssign, { centered: true });
            // if (this.selectedCollectionManStatusBackAssign != null && this.selectedCollectionManStatusBackAssign == '') {
            //     swal({
            //         title: "Mandatory Fields",
            //         text: "Please Select Collection Man Assign to Proceed",
            //         icon: "warning",
            //         closeOnClickOutside: false,
            //         closeOnEsc: false,
            //     });
            // } else{
            //     this.callForStatusBackAssignMemo();

            // }

        }
    }

    //For Assign in btn finish
    callForAssignMemo(content10) {
        this.selectedCollectionMan = this.enteredCollectionMan;
        if (!this.userDataDtoReturnSession.collectionFlow && this.selectedCollectionMan == 'SRD STOCK') {
            this.modalService.open(content10, { centered: true });
        } else {
            swal({
                title: "Assigning Memos",
                text: "Sure U Want to Assign the selected Memos to " + this.selectedCollectionMan,
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((assingMemo) => {
                if (assingMemo) {
                    //Pending
                    this.userDataDtoForLRAssign = new UserDataDto();
                    this.selectedCollectionMan = this.enteredCollectionMan;

                    this.userDataDtoForLRAssign.collectioMan = this.selectedCollectionMan;
                    this.userDataDtoForLRAssign.mode = 'assign';
                    this.listOfLrNumber = [];
                    for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                        this.listOfLrNumber.push(this.pfAssignedJobDetailsDataList[i].memoNumber);
                    }
                    //                    console.log( this.listOfLrNumber );

                    this.userDataDtoForLRAssign.list = this.listOfLrNumber;
                    this.updateCashMemoInfo(this.userDataDtoForLRAssign);
                    //Pending
                    this.cashMemoDtoForAssignPrint = new CashMemoDto();
                    this.cashMemoDtoForAssignPrint.collectionMan = this.selectedCollectionMan;
                    this.setPringForGCTakenReport(this.cashMemoDtoForAssignPrint);
                    //                                        new GCTakenReport(storeAssg,
                    //                                                cashMemoDto);
                    //                    popupAssign.hide();
                }

            });
        }

    }

    //For Asssign In Pop up btn 
    validateCollectionManAssign() {
        //        console.log(this.enteredCollectionManAssign);
        this.selectedCollectionManAssign = this.enteredCollectionManAssign;
        if (this.selectedCollectionManAssign == null || this.selectedCollectionManAssign == '') {
            swal({
                title: "Collection Man Not Found",
                text: "Please Select A Collection Man",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Assigning Memos",
                text: "Sure U Want to Assign the selected Memos to " + this.selectedCollectionManAssign,
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((assingMemo) => {
                if (assingMemo) {
                    //Pending
                    this.userDataDtoForLRAssignSRDStock = new UserDataDto();
                    this.selectedCollectionManAssign = this.enteredCollectionManAssign;

                    this.userDataDtoForLRAssignSRDStock.collectioMan = this.selectedCollectionManAssign;
                    this.userDataDtoForLRAssignSRDStock.mode = 'assign';
                    this.listOfLrNumber = [];
                    for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                        this.listOfLrNumber.push(this.pfAssignedJobDetailsDataList[i].memoNumber);
                    }
                    //                    console.log( this.listOfLrNumber );

                    this.userDataDtoForLRAssignSRDStock.list = this.listOfLrNumber;
                    this.updateCashMemoInfo(this.userDataDtoForLRAssignSRDStock);
                    //Pending
                    this.cashMemoDtoForAssignPrint = new CashMemoDto();
                    this.cashMemoDtoForAssignPrint.collectionMan = this.selectedCollectionManAssign;
                    this.setPringForGCTakenReport(this.cashMemoDtoForAssignPrint);
                    //                    new GCTakenReport(storeAssg,
                    //                            cashMemoDto);
                    //                    popupAssign.hide();
                }

            });
        }
    }
    //For Update Memo
    updateCashMemoInfo(userDataDtoForLR) {
        this.selectedCollectionMan = this.enteredCollectionMan;
        userDataDtoForLR.companyId = this.userDataDtoReturnSession.companyId;
        userDataDtoForLR.city = this.userDataDtoReturnSession.mainStation;
        userDataDtoForLR.updatedBy = this.userDataDtoReturnSession.userId;
        userDataDtoForLR.collectionFlow = this.userDataDtoReturnSession.collectionFlow;
        console.log("userDataDtoForLR");
        console.log(userDataDtoForLR);
        this.updateCashMemoDetails(userDataDtoForLR);

    }


    updateCashMemoDetails = (userDataDtoForLR) => {
        this.memoService.updateMemoDetailService(userDataDtoForLR).subscribe(
            (response) => {
                if (response.length == 0) {
                    //Pending 
                    alert('');
                } else {
                    if (userDataDtoForLR.mode == 'assign') {
                        this.userDataDtoForStatusRet = '';
                        this.userDataDtoForStatusRet = response;
                        if (this.userDataDtoForStatusRet.result == 'success') {
                            // the swal is hidden bcoz it hide the print
                            //                            swal( {
                            //                                title: "Success",
                            //                                text: "Selected Cashmemos assigned to " + userDataDtoForLR.collectioMan,
                            //                                icon: "success",
                            //                                closeOnClickOutside: false,
                            //                                closeOnEsc: false,
                            //                            } );
                            this.clearForAssign();
                        } else {
                            swal({
                                title: "Server Error",
                                text: "Server problem occurred while Assigning the memo's",
                                icon: "error",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                        }

                    } else if (userDataDtoForLR.mode == 'statusBack') {
                        this.userDataDtoForStatusRet = '';
                        this.userDataDtoForStatusRet = response;
                        if (this.userDataDtoForStatusRet.result == 'success') {
                            swal({
                                title: "Success",
                                text: "Selected Cashmemos taken into stocks",
                                icon: "success",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                            this.clearForStatusBack();
                        } else {
                            swal({
                                title: "Server Error",
                                text: "Server problem occurred while taking the memo's Back",
                                icon: "error",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                        }

                    } else if (userDataDtoForLR.mode == 'toParty') {
                        this.userDataDtoForStatusRet = '';
                        this.userDataDtoForStatusRet = response;
                        if (this.userDataDtoForStatusRet.result == 'success') {
                            swal({
                                title: "Success",
                                text: "Selected Cashmemos marks as To-Party",
                                icon: "success",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                            this.clearToParty();
                        } else {
                            swal({
                                title: "Server Error",
                                text: "Server problem occurred while marking the memo's as To-Party",
                                icon: "error",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                        }
                    } else if (userDataDtoForLR.mode == 'statusBackAndAssign') {
                        this.userDataDtoForStatusRet = '';
                        this.userDataDtoForStatusRet = response;
                        if (this.userDataDtoForStatusRet.result == 'success') {
                            this.modalService.dismissAll();
                            swal({
                                title: "Success",
                                text: "Entered cashmemos are status back and assigned to selected collectionman",
                                icon: "success",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                            this.clearForStatusBackAssign();
                        } else {
                            swal({
                                title: "Server Error",
                                text: "Server problem occurred while Status Back Assign",
                                icon: "error",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                        }

                    }
                    this.pfAssignedJobDetailsDataList = [];
                    //Pending
                    this.tempstore = [];
                    this.tempStoreDup = new CashMemoDto();
                    //Pending 
                    this.gridReconfigureAssg("other");
                    this.controlCollectionMan.reset();
                    this.controlCollectionManAssign.reset();
                    $("#" + this.pageId + "consigneeBankName").val('');
                    $("#" + this.pageId + "newBankName").val('');
                    this.controlConsigneeBankName.reset();
                    this.viewNewBankName = false;
                    this.clearAllAfterBtnFinish();
                    this.userDataDtoForStatusRet = '';
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    clearAllAfterBtnFinish() {
        this.memoNum = '';
        this.selectedActionType = '';
        this.selectedEntryType = '';
        this.selectedCodeForManual = '';
        this.selectedMemoNumberForManual = '';
        this.selectedMemoNumberForAutomatic = '';
        this.modelForSearched = '';
        this.letValueofCollectionMan = new CashMemoDto();
        this.getsearchInCollectionManTableFoDup = new CashMemoDto();
        this.searchedMemoForDup = new CashMemoDto();
        this.searchedMemoInfo = new CashMemoDto();
        this.letValueofSearchInCollectionManTableFoDup = new CashMemoDto();
        this.searchedMemoForMemoAlreadyExist = new CashMemoDto();
        this.getsearchInCollectionManTableForAlreadyExist = new CashMemoDto();
        this.letValueofSearchInCollectionManTableFoAlreadyExist = new CashMemoDto();
        this.setValueofSearchInCollectionManTableFoAlreadyExistToParty = new CashMemoDto();
        this.getValueofSearchInCollectionManTableFoAlreadyExistToParty = new CashMemoDto();
        this.letValueofSearchInCollectionManTableFoAlreadyExistParty = new CashMemoDto();
        this.modelAlreadyExist = '';
        this.modelDup = '';
        this.countOfAssignLR = 0;
        this.sumOfAssignLR = 0;
        this.consigneeeName = '';
        this.consigneeExist = false;
        //        this.countOFMemosCollectionTable = 0;
        //        this.sumOFMemosCollectionTable = 0;
        //        this.newAttForColTable = {};
        //        this.pfCollectionManDetailsDataList = [];
        //        this.pfCollectionManDetailsDataListForSearch = [];
        this.selectTodayCompletedOnDate();
    }
    clearForAssign() {
        this.userDataDtoForLRAssign = new UserDataDto();
        this.selectedCollectionMan = '';
        this.selectedCollectionManAssign = '';
        this.listOfLrNumber = [];
        this.userDataDtoForLRAssignSRDStock = new UserDataDto();
        this.selectTodayCompletedOnDate();
    }
    clearForStatusBack() {
        this.selectedCollectionMan = '';
        this.userDataDtoForLRStatusBack = new UserDataDto();
        this.listOfLrNumber = [];
        this.selectTodayCompletedOnDate();
    }
    clearToParty() {
        this.userDataDtoForLRToPartySRDStock = new UserDataDto();
        this.selectedCollectionManToParty = '';
        this.listOfLrNumber = [];
        this.userDataDtoForLRToParty = new UserDataDto();
        this.selectedCollectionMan = '';
        this.selectTodayCompletedOnDate();
    }



    callForStatusBackMemo() {
        if (this.chkPendingMemosExist()) {
            this.removePendingMemos();
        } else {
            swal({
                title: "Status Back",
                text: "Sure U want to take status back for the selected cashmemos",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((statusBackMemo) => {
                if (statusBackMemo) {
                    //Pending
                    this.userDataDtoForLRStatusBack = new UserDataDto();
                    this.selectedCollectionMan = this.enteredCollectionMan;
                    this.userDataDtoForLRStatusBack.collectioMan = this.selectedCollectionMan;
                    this.userDataDtoForLRStatusBack.mode = 'statusBack';
                    this.listOfLrNumber = [];
                    for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                        this.listOfLrNumber.push(this.pfAssignedJobDetailsDataList[i].memoNumber);
                    }
                    //                    console.log( this.listOfLrNumber );
                    this.userDataDtoForLRStatusBack.list = this.listOfLrNumber;
                    this.updateCashMemoInfo(this.userDataDtoForLRStatusBack);

                }

            });
        }
    }

    chkPendingMemosExist() {
        this.isExist = false;
        //        console.log( this.tempstore );
        for (let i = 0; i < this.tempstore.length; i++) {
            if (this.tempstore[i].column1 != null && this.tempstore[i].column1 == 'Pending') {
                this.isExist = true;
                break;
            }
        }
        //        console.log( this.isExist );
        return this.isExist;
    }

    removePendingMemos() {
        swal({
            title: "Not Allowed",
            text: "Some Memos are still in Pending.For further processing, those memos sholud be Remove",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
        }).then(() => {
            for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                let column1 = this.pfAssignedJobDetailsDataList[i].column1;
                if (column1 != null && column1 == 'Pending') {
                    this.removeMaint.push(this.pfAssignedJobDetailsDataList[i]);
                    //                    this.tempstore.splice( i, 1 );
                    //                    this.pfAssignedJobDetailsDataList.splice( i, 1 );

                }
            }
            //            console.log( this.removeMaint );


            let index = -1;
            //            For removing from tempstore
            //            console.log( this.tempstore );
            for (let i = 0; i < this.removeMaint.length; i++) {
                for (let j = 0; j < this.tempstore.length; j++) {
                    if (this.tempstore[j].memoNumber === this.removeMaint[i].memoNumber) {
                        //                        console.log( j );
                        index = j;
                    }
                    if (index === -1) {
                        alert("Something gone wrong1");
                    }
                    this.tempstore.splice(index, 1);
                }
            }
            //            console.log( this.tempstore );

            //            console.log( this.pfAssignedJobDetailsDataList );
            for (let i = 0; i < this.removeMaint.length; i++) {
                for (let j = 0; j < this.pfAssignedJobDetailsDataList.length; j++) {
                    if (this.pfAssignedJobDetailsDataList[j].memoNumber === this.removeMaint[i].memoNumber) {
                        index = j;
                    }
                    if (index === -1) {
                        alert("Something gone wrong2");
                    }
                    this.pfAssignedJobDetailsDataList.splice(index, 1);
                }


            }
            //            console.log( this.pfAssignedJobDetailsDataList );

            this.countOfAssignLR = 0;
            this.sumOfAssignLR = 0;
            for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                this.countOfAssignLR = +this.countOfAssignLR + 1;
                this.sumOfAssignLR = +this.sumOfAssignLR + +this.pfAssignedJobDetailsDataList[i].amount;
            }

            for (let i = 0; i < this.removeMaint.length; i++) {
                this.pfCollectionManDetailsDataList.push(this.removeMaint[i]);
            }
            this.countOFMemosCollectionTable = 0;
            this.sumOFMemosCollectionTable = 0;
            for (let i = 0; i < this.pfCollectionManDetailsDataList.length; i++) {
                this.countOFMemosCollectionTable = this.countOFMemosCollectionTable + 1;
                this.sumOFMemosCollectionTable = this.sumOFMemosCollectionTable + +(this.pfCollectionManDetailsDataList[i].amount);
            }


            //Pending 
            //            tempStore.removeAll(removeMaint);
            //            storeAssg.removeAll();
            //            storeAssg.add(tempStore);
            //            gridAssg.reconfigure(storeAssg, cmAssg);
            //            storeAll.add(removeMaint);
            //            gridAll.reconfigure(storeAll, cmAll);
        });
    }
    ///To Party 
    callForToPartyMemo(content11) {
        this.selectedCollectionMan = this.enteredCollectionMan;
        if (!this.userDataDtoReturnSession.collectionFlow && this.selectedCollectionMan == 'SRD STOCK') {
            this.modalService.open(content11, { centered: true });
        } else {

            swal({
                title: "Cashmemo To-Party",
                text: "Sure U Want to Mark the selected memos As To-Party " + this.selectedCollectionMan,
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((toPartyMemo) => {
                if (toPartyMemo) {
                    //Pending
                    this.userDataDtoForLRToParty = new UserDataDto();
                    this.selectedCollectionMan = this.enteredCollectionMan;
                    this.userDataDtoForLRToParty.collectioMan = this.selectedCollectionMan;
                    this.userDataDtoForLRToParty.mode = 'toParty';
                    this.listOfLrNumber = [];
                    for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                        this.listOfLrNumber.push(this.pfAssignedJobDetailsDataList[i].memoNumber);
                    }
                    //                    console.log( this.listOfLrNumber );
                    this.userDataDtoForLRToParty.list = this.listOfLrNumber;
                    this.userDataDtoForLRToParty.partyName = this.pfAssignedJobDetailsDataList[0].consigneeName;
                    //                    console.log( this.pfAssignedJobDetailsDataList );
                    if (this.pfAssignedJobDetailsDataList[0].consigneeName != null && this.pfAssignedJobDetailsDataList[0].consigneeName != '') {
                        this.userDataDtoForLRToParty.consigneeId = this.pfAssignedJobDetailsDataList[0].consigneeId;
                    }

                    this.updateCashMemoInfo(this.userDataDtoForLRToParty);
                    //Pending
                    //                    CashMemoDto cashMemoDto = new CashMemoDto();
                    //                    cashMemoDto
                    //                            .setCollectionMan(comboBoxAssg
                    //                                    .getRawValue());
                    //                    new GCTakenReport(storeAssg,
                    //                            cashMemoDto);
                    //                    popupAssign.hide();
                }

            });
        }

    }
    //To Party Pop up
    validateCollectionManToParty() {

        this.selectedCollectionManToParty = this.enteredCollectionManToParty;
        if (this.selectedCollectionManToParty == null || this.selectedCollectionManToParty == '') {
            swal({
                title: "Collection Man Not Selected",
                text: "Please Select A Collection Man",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Cashmemo To-Party",
                text: "Sure U Want to Mark the selected memos As To-Party ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((toPartyMemo) => {
                if (toPartyMemo) {
                    //Pending
                    this.userDataDtoForLRToPartySRDStock = new UserDataDto();
                    this.selectedCollectionManToParty = this.enteredCollectionManToParty;
                    this.userDataDtoForLRToPartySRDStock.collectioMan = this.selectedCollectionManToParty;
                    this.userDataDtoForLRToPartySRDStock.mode = 'toParty';
                    this.listOfLrNumber = [];
                    for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                        this.listOfLrNumber.push(this.pfAssignedJobDetailsDataList[i].memoNumber);
                    }
                    //                    console.log( this.listOfLrNumber );
                    this.userDataDtoForLRToPartySRDStock.list = this.listOfLrNumber;
                    this.userDataDtoForLRToPartySRDStock.partyName = this.pfAssignedJobDetailsDataList[0].consigneeName;
                    //                    console.log( this.pfAssignedJobDetailsDataList );
                    if (this.pfAssignedJobDetailsDataList[0].consigneeName != null && this.pfAssignedJobDetailsDataList[0].consigneeName != '') {
                        this.userDataDtoForLRToPartySRDStock.consigneeId = this.pfAssignedJobDetailsDataList[0].consigneeId;
                    }
                    this.updateCashMemoInfo(this.userDataDtoForLRToPartySRDStock);
                    //Pending
                    //                    CashMemoDto cashMemoDto = new CashMemoDto();
                    //                    cashMemoDto
                    //                            .setCollectionMan(comboBoxAssg
                    //                                    .getRawValue());
                    //                    new GCTakenReport(storeAssg,
                    //                            cashMemoDto);
                    //                    popupAssign.hide();
                }

            });
        }

    }
    //for Btn All
    validateAllBtn() {
        if (this.pfCollectionManDetailsDataList.length == 0) {
            swal({
                title: "Empty Datas",
                text: "No Cashmemo Found",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.gridTransferAllToAssg();
        }
    }
    //for Btn  Remove All
    validateRemoveAllBtn() {
        if (this.pfAssignedJobDetailsDataList.length == 0) {
            swal({
                title: "Empty Datas",
                text: "No Cashmemo Found",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            //            this.chkPendingMemosExist();
            //            this.removePendingMemos();
            this.gridTransferAllToUnAssg();
        }
    }
    gridTransferAllToUnAssg() {

        //        console.log( this.pfAssignedJobDetailsDataList );
        for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
            this.pfCollectionManDetailsDataList.push(this.pfAssignedJobDetailsDataList[i]);
            this.pfCollectionManDetailsDataListForSearch.push(this.pfAssignedJobDetailsDataList[i]);
        }
        this.countOfAssignLR = 0;
        this.sumOfAssignLR = 0;
        this.pfAssignedJobDetailsDataList = [];
        //        console.log( this.pfAssignedJobDetailsDataList );
        this.countOFMemosCollectionTable = 0;
        this.sumOFMemosCollectionTable = 0;
        for (let i = 0; i < this.pfCollectionManDetailsDataList.length; i++) {
            this.countOFMemosCollectionTable = this.countOFMemosCollectionTable + 1;
            this.sumOFMemosCollectionTable = this.sumOFMemosCollectionTable + +(this.pfCollectionManDetailsDataList[i].amount);
        }
    }
    gridTransferAllToAssg() {
        //        console.log( this.pfAssignedJobDetailsDataList.length );
        //        console.log( this.tempstore.length );
        //        console.log( this.pfCollectionManDetailsDataList.length );
        this.countOfAssignLR = 0;
        this.sumOfAssignLR = 0;
        for (let i = 0; i < this.pfCollectionManDetailsDataList.length; i++) {
            this.modelAlreadyExist = null;
            this.modelAll = null;
            //            console.log( this.pfCollectionManDetailsDataList[i].memoNumber );
            //Pending
            this.modelAll = (this.pfCollectionManDetailsDataList.find(pfCollectionManDetailsData => pfCollectionManDetailsData.memoNumber === this.pfCollectionManDetailsDataList[i].memoNumber));
            //            console.log( this.modelAll );
            if (this.pfAssignedJobDetailsDataList.length > 0) {
                this.modelAlreadyExist = (this.pfAssignedJobDetailsDataList.find(pfAssignedJobDetailsData => pfAssignedJobDetailsData.memoNumber === this.pfCollectionManDetailsDataList[i].memoNumber));
                //Pending
            }
            //            console.log( this.modelAlreadyExist );
            //            console.log( 'modelAll.getColumn1()...  ' + this.modelAll.column1 );
            if ((this.modelAlreadyExist == null || this.modelAlreadyExist == undefined) && this.modelAll != null
                && this.modelAll.column1 != null && this.modelAll.column1 == 'Assigned') {
                this.countOFMemosCollectionTable = this.countOFMemosCollectionTable - 1;
                this.sumOFMemosCollectionTable = this.sumOFMemosCollectionTable - +(this.pfCollectionManDetailsDataListForSearch[i].amount);
                if (this.setAssingedDetails == 'new') {
                    this.dtTriggerPfAssignedJobDetails.next();
                    this.setAssingedDetails = 'old';
                }

                this.pfAssignedJobDetailsDataList.push(this.pfCollectionManDetailsDataList[i]);
                this.tempstore.push(this.pfCollectionManDetailsDataList[i]);
                this.tempStoreForAllTrx.push(this.pfCollectionManDetailsDataList[i]);
                this.countOfAssignLR = +this.countOfAssignLR + 1;
                this.sumOfAssignLR = +this.sumOfAssignLR + +this.pfCollectionManDetailsDataList[i].amount;
            }
        }
        for (let i = 0; i < this.tempStoreForAllTrx.length; i++) {
            this.pfCollectionManDetailsDataList.splice(this.pfCollectionManDetailsDataList.find(pfCollectionManDetailsData => pfCollectionManDetailsData.memoNumber === this.tempStoreForAllTrx[i]), 1);
        }
        //        console.log( this.pfCollectionManDetailsDataList );
        //        console.log( this.pfAssignedJobDetailsDataList );
        //        console.log( this.tempstore );
        //        console.log( this.tempStoreForAllTrx );
        this.value = false;
    }

    //For action type finish 
    callForClosingCollection() {
        this.selectedCollectionMan = this.enteredCollectionMan;
        //        this.completedDate=
        this.enteredCompletedOnDate = $("#" + this.pageId + "completedOnDate").val();

        if (this.enteredCompletedOnDate == null || this.enteredCompletedOnDate == '') {
            swal({
                title: "Mandetory",
                text: "Please Select Date",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            swal({
                title: "Closing Collection",
                text: "Are U sure want to close the collection of " + this.selectedCollectionMan,
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((finishMemo) => {
                if (finishMemo) {
                    //Pending
                    this.userDataDtoForLRFinish = new UserDataDto();
                    this.selectedCollectionMan = this.enteredCollectionMan;
                    this.userDataDtoForLRFinish.collectioMan = this.selectedCollectionMan;
                    this.userDataDtoForLRFinish.updatedBy = this.userDataDtoReturnSession.userId;
                    this.userDataDtoForLRFinish.city = this.userDataDtoReturnSession.mainStation;
                    this.enteredCompletedOnDate = this.datePipe.transform(this.enteredCompletedOnDate, "yyyy-MM-dd");
                    //                    console.log( this.enteredCompletedOnDate );
                    this.userDataDtoForLRFinish.completedOn = this.enteredCompletedOnDate;
                    this.userDataDtoForLRFinish.companyId = this.userDataDtoReturnSession.companyId;
                    this.updateCloseCollectionMethod(this.userDataDtoForLRFinish);
                }

            });
        }

    }


    updateCloseCollectionMethod = (userDataDtoForLRFinish) => {
        this.memoService.updateCloseCollectionService(userDataDtoForLRFinish).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Error",
                        text: "Server Error ",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.updateCloseCollection = '';
                } else {
                    this.updateCloseCollection = response;
                    if (this.updateCloseCollection.status == 'success') {
                        swal({
                            title: "Success",
                            text: "Success",
                            icon: "success",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.clearFinish();
                    } else {
                        swal({
                            title: "Error",
                            text: "Server Error ",
                            icon: "error",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    }
                    this.updateCloseCollection = '';
                    this.tempstore = [];
                    this.pfAssignedJobDetailsDataList = [];
                    this.clearAllAfterBtnFinish();

                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    clearFinish() {
        this.userDataDtoForLRFinish = new UserDataDto();
        this.selectedCollectionMan = '';
        this.enteredCompletedOnDate = '';
        $("#" + this.pageId + "completedOnDate").val('');
        this.selectTodayCompletedOnDate();
    }

    setPringForGCTakenReport(cashMemoDtoForAssignPrint) {
        localStorage.clear();
        //        console.log( this.resultDtos );
        //        console.log( this.pfAssignedJobDetailsDataList );
        localStorage.setItem('printAfterAssignMemo', JSON.stringify(this.cashMemoDtoForAssignPrint));
        localStorage.setItem('printAfterAssignMemolist', JSON.stringify(this.pfAssignedJobDetailsDataList));
        this.viewAssignMemoPrint = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewAssignMemoPrint) {
                this.viewAssignMemoPrint = false;
                this.cashMemoDtoForAssignPrint = new CashMemoDto();
                localStorage.clear();
            }
        });
    }

    //    val(){
    //        alert('why are you here ?');
    //    }
    getConsigneeDetailsList() {
        this.consigneeNameOptions = [];
        this.consigneeNameOptionsPayment = [];
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.city = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignee.mode = "specific";
    }
    getConsigneeDetails = () => {
        this.getConsigneeDetailsList();
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignee Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.controlConsigneeName.reset();
                    this.consigneeNameOptions = [];
                    this.controlConsigneeNamePayment.reset();
                    this.consigneeNameOptionsPayment = [];
                    this.consigneeNameTA = [];
                    this.consigneeNameForPaymentTA = [];
                } else {
                    this.controlConsigneeName.reset();
                    this.consigneeNameOptions = response;
                    //                    console.log(this.consigneeNameOptions);
                    this.consigneeNameTA = [];
                    this.consigneeNameForPaymentTA = [];
                    $("#" + this.pageId + "consigneeName").val('');
                    $("#" + this.pageId + "consigneeNameForPayment").val('');
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                        this.consigneeNameForPaymentTA.push(this.consigneeNameOptions[i]);
                    }
                    this.controlConsigneeNamePayment.reset();
                    this.consigneeNameOptionsPayment = response;
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    reloadConsignee() {
        this.getConsigneeDetails();
    }
    reloadBank() {
        this.getPartyBankDetailsService();
    }
    //Frm here Adding in table of Less Entry Starts

    getMemoNumberForEntryLess(e, LessType: string) {
        if (e.keyCode == 13) {
            if (LessType == 'manual') {
                this.commonValidationsOnMemoEnter("manual");
                //                console.log( 'location1' );
            } else if (LessType == 'scan') {
                this.commonValidationsOnMemoEnter("scan");
            }
        }
    }

    validateLessAmount(e) {
        if (e.keyCode == 13) {

            if (this.nextEntryType == 'Payment Entry') {
                this.enteredLessAmt = $("#" + this.pageId + "paymentLessAmount").val();
                if (this.enteredLessAmt == '' || this.enteredLessAmt == null) {
                    swal({
                        title: "Not Allowed",
                        text: "Please Enter Amount",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.enteredLessAmt = '';
                    this.commonValidationsOnMemoEnter("paymentLess");
                }

            } else if (this.nextEntryType == 'Less Entry') {
                this.enteredLessAmt = $("#" + this.pageId + "entryLessAmount").val();
                if (this.enteredLessAmt == '' || this.enteredLessAmt == null) {
                    swal({
                        title: "Not Allowed",
                        text: "Please Enter Amount",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.enteredLessAmt = '';
                    this.commonValidationsOnMemoEnter("amtLess");
                }

            }
        }
    }
    //    rowSelectedConsignee( consigneeName ) {
    //        this.selectedConsigneeId = consigneeName.consigneeId;
    //        window.setTimeout( function() {
    //            $("#pmtfctotalLess" ).focus();
    //        }, 100 );
    //    }
    rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelConsigneeName = e.item;
        this.selectedConsigneeId = this.modelConsigneeName.consigneeId;
        //        console.log('Id');
        //        console.log(this.selectedConsigneeId);
        window.setTimeout(function () {
            $("#pmtfctotalLess").focus();
        }, 100);
    }
    //    rowSelectedConsigneePayment( consigneeName ) {
    //        this.selectedConsigneeId = consigneeName.consigneeId;
    //        window.setTimeout( function() {
    //            $("#pmtfctotalAmountForPayment" ).focus();
    //        }, 100 );
    //    }
    rowSelectedConsigneePayment(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelConsigneeNameForPayment = e.item;
        this.selectedConsigneeId = this.modelConsigneeNameForPayment.consigneeId;
        //        console.log('Id2');
        //        console.log(this.selectedConsigneeId);
        window.setTimeout(function () {
            $("#pmtfctotalAmountForPayment").focus();
        }, 100);
    }
    commonValidationsOnMemoEnter(keyEnter) {
        this.tempStoreDup = new CashMemoDto();
        this.memoNum = '';
        this.selectedActionType = '';
        this.selectedEntryType = '';
        this.selectedCodeForManual = '';
        this.selectedMemoNumberForManual = '';
        this.selectedMemoNumberForAutomatic = '';
        this.selectedActionType = $("#" + this.pageId + "actionType").val();

        if (this.nextEntryType == 'Less Entry') {
            this.selectedEntryType = $("#" + this.pageId + "entryLessEntryType").val();
            this.selectedCodeForManual = $("#" + this.pageId + "codeForManualLess").val();
            this.selectedMemoNumberForManual = $("#" + this.pageId + "memoNumberForManualLess").val();
            this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomaticLess").val();
            this.selectedConsignee = $("#" + this.pageId + "consigneeName").val();
            this.selectedTypeLess = $("#" + this.pageId + "lessType").val();

            if (this.selectedEntryType == 'entryLessAutomatic') {
                this.memoNum = this.selectedMemoNumberForAutomatic;
                //                console.log( this.memoNum );
            } else {
                this.memoNum = this.selectedCodeForManual + "" + this.selectedMemoNumberForManual;
                //                console.log( this.memoNum );
            }
        } else if (this.nextEntryType == 'Payment Entry') {
            this.selectedEntryType = $("#" + this.pageId + "entryLessPaymentEntry").val();
            this.selectedCodeForManual = $("#" + this.pageId + "codeForManualLessPayment").val();
            this.selectedMemoNumberForManual = $("#" + this.pageId + "memoNumberForManualLessPayment").val();
            this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val();
            this.selectedConsignee = $("#" + this.pageId + "consigneeNameForPayment").val();
            if (this.selectedEntryType == 'paymentLessAutomatic') {
                this.memoNum = this.selectedMemoNumberForAutomatic;
                //                console.log( this.memoNum );
            } else {
                this.memoNum = this.selectedCodeForManual + "" + this.selectedMemoNumberForManual;
                //                console.log( this.memoNum );
            }
        }
        this.searchedMemoForMemoExistInTable = this.searchedMemoForMemoExistInTableForLess(this.memoNum);

        if (this.searchedMemoForMemoExistInTable.memoNumber == null || this.searchedMemoForMemoExistInTable.memoNumber == '') {
            this.modelForLessMaintain = '';
        } else {

            this.modelForLessMaintain = this.searchedMemoForMemoExistInTable;
        }

        //        const checkAssignedTable =this.pfAssignedJobDetailsDataList.find( pfAssignedJobDetailsData => pfAssignedJobDetailsData.memoNumber ===  this.memoNum  );
        //                console.log( checkAssignedTable );
        //        this.letValueofSearchInCollectionManTableFoEntryLessAny=[];
        //        if(checkAssignedTable.length == 0){
        //            this.letValueofSearchInCollectionManTableFoEntryLessAny=[];
        //        }else{
        //            this.letValueofSearchInCollectionManTableFoEntryLessAny=checkAssignedTable
        //        }


        if (this.selectedConsignee == null || this.selectedConsignee == '') {
            swal({
                title: "Not Allowed",
                text: "Please select a Consignee Name",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else if (this.modelForLessMaintain == null || this.modelForLessMaintain == '') {
            if (this.selectedMemoNumberForManual != null && this.selectedMemoNumberForManual != '') {
                swal({
                    title: "Not Allowed",
                    text: "Cashmemo Number " + this.memoNum + " does not exist!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            }

        } else if (this.modelForLessMaintain.consigneeId != this.selectedConsigneeId) {
            //    console.log( this.modelForLessMaintain.consigneeId );
            //  console.log( this.selectedConsigneeId );
            swal({
                title: "Not Allowed",
                text: "Consignee Name bind with Memo is not matched with the selected Consignee above, please verify! ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else if (this.nextEntryType != '' && this.nextEntryType == 'Less Entry') {
            //console.log( 'location2' );
            //             duplicate less entry with same less description should blocked
            this.isDuplicateLess = false;
            const result = this.lessEntriesDataTableDataList.filter(lessEntriesDataTableData => lessEntriesDataTableData.chqType === this.memoNum);
            //   console.log( result );
            this.letValueofMemoExistInLessEntryTableAny = [];
            // console.log( this.letValueofMemoExistInLessEntryTableAny );
            this.letValueofMemoExistInLessEntryTableAny = result;
            //console.log( this.letValueofMemoExistInLessEntryTableAny );
            for (let i = 0; i < this.letValueofMemoExistInLessEntryTableAny.length; i++) {
                if (this.letValueofMemoExistInLessEntryTableAny != null
                    && this.letValueofMemoExistInLessEntryTableAny[i].chqNumber == this.selectedTypeLess
                    && !this.isEdit) {
                    //      console.log( 'am here' );
                    this.isDuplicateLess = true;
                    swal({
                        title: "Not Allowed",
                        text: "Duplicate Less Entry Cashmemo Number '" + this.memoNum + "' - Less Type '" + this.selectedTypeLess + "'",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                    break;
                }
            }

            if (!this.isDuplicateLess) {
                //console.log( 'location3' );
                // console.log( this.modelForLessMaintain );
                this.lessSpecifcValidations(this.modelForLessMaintain, this.memoNum, keyEnter);
            }

        } else if (this.nextEntryType != '' && this.nextEntryType == 'Payment Entry') {
            // console.log( 'hhhhhh' );
            const paymentTableCheck = this.paymentAmountEntriesDataList.filter(paymentAmountEntriesData => paymentAmountEntriesData.chqType === this.memoNum);
            //console.log( paymentTableCheck );
            this.letValueofMemoExistInPaymentEntryTableAny = [];
            //console.log( this.letValueofMemoExistInLessEntryTableAny );
            this.letValueofMemoExistInPaymentEntryTableAny = paymentTableCheck;
            //console.log( this.letValueofMemoExistInPaymentEntryTableAny );
            if (this.isEdit == false && this.letValueofMemoExistInPaymentEntryTableAny.length > 0) {
                swal({
                    title: "Not Allowed",
                    text: "Duplicate Entry for the Cashmemo Number " + this.memoNum,
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            } else {
                this.paymentSpecifcValidations(keyEnter);
            }
        }


    }


    searchedMemoForMemoExistInTableForLess(selectedNum: string) {

        if (this.pfAssignedJobDetailsDataList.length == 0) {
            this.getsearchInCollectionManTableForEntryLess = new CashMemoDto();
            //            console.log( ' 1' );
        } else {
            // console.log( '2' );
            this.getsearchInCollectionManTableForEntryLess = this.enteredSearchInCollectionManTableFoEnryLess(selectedNum)
        }
        //   console.log( this.getsearchInCollectionManTableForEntryLess );
        return this.getsearchInCollectionManTableForEntryLess;
    }
    enteredSearchInCollectionManTableFoEnryLess(selectedNum: string): CashMemoDto {
        //    console.log( '1111' );
        this.letValueofSearchInCollectionManTableFoEntryLess = (this.pfAssignedJobDetailsDataList.find(pfAssignedJobDetailsData => pfAssignedJobDetailsData.memoNumber === selectedNum));
        if (this.letValueofSearchInCollectionManTableFoEntryLess == null || this.letValueofSearchInCollectionManTableFoDup == undefined) {
            this.letValueofSearchInCollectionManTableFoEntryLess = new CashMemoDto();
            //   console.log( 'boss1' );
        } else {
            //    console.log( 'boss1cd' );
            this.letValueofSearchInCollectionManTableFoEntryLess = this.letValueofSearchInCollectionManTableFoEntryLess;
        }
        //   console.log( this.letValueofSearchInCollectionManTableFoEntryLess );
        return this.letValueofSearchInCollectionManTableFoEntryLess;
    }

    /// For checking the memo number exist in Less Entry table befor adding the new row 
    lessSpecifcValidations(modelForLessMaintain, memoNum, keyEnter) {
        let discount = 0.0;
        discount = modelForLessMaintain.discount == null ? 0.0 : modelForLessMaintain.discount;
        // console.log( discount );

        if (discount > 0) {
            //console.log( 'location4' );
            // show alert only at first time for single memo hence the below
            // condition
            //            this.searchedMemoForDiscInLessEntry = this.searchedMemoForForDiscInLessEntryTable( memoNum );
            //            this.searchedMemoForDiscInLessEntryAny = this.searchedMemoForDiscInLessEntry;
            //
            //           if ( this.searchedMemoForDiscInLessEntryAny.chqType == null || this.searchedMemoForDiscInLessEntryAny.chqType == '' ) {
            //                this.maintenanceDisc = '';
            //            } else {
            //
            //                this.maintenanceDisc = this.searchedMemoForDiscInLessEntryAny;
            //
            //            }
            //           console.log( this.maintenanceDisc );
            const result = this.lessEntriesDataTableDataList.filter(lessEntriesDataTableData => lessEntriesDataTableData.chqType === memoNum);
            //  console.log( 'hello' );
            //console.log( result );
            this.maintenanceDiscArray = [];
            if (result == undefined) {
                this.maintenanceDiscArray = [];
                //  console.log( 'empty' );
            } else {
                this.maintenanceDiscArray = result;
                //  console.log( 'notempty' );
            }
            // console.log( this.maintenanceDiscArray );
            //            if ( this.maintenanceDiscArray == [] || this.maintenanceDiscArray == null ) {
            if (this.maintenanceDiscArray.length == 0) {
                swal({
                    title: "Already discounted",
                    text: "Discount already given for the Cashmemo Number " + memoNum + ", are you want to give additional less?",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                    //                    dangerMode: true,
                }).then((lessType) => {
                    if (lessType) {
                        if (keyEnter != null && keyEnter == 'amtLess') {
                            this.lessEntry();
                        } else {
                            window.setTimeout(function () {
                                $("#pmtfclessType").focus();
                            }, 100);
                        }

                    } else {
                        $("#" + this.pageId + "memoNumberForAutomaticLess").val('');
                        $("#" + this.pageId + "memoNumberForManualLess").val('');
                        $("#" + this.pageId + "lessType").val('');
                        $("#pmtfcmemoNumberForManualLess").focus();

                    }
                });

            } else {
                //Pending


                if (keyEnter != null && keyEnter == 'amtLess') {
                    this.lessEntry();
                } else {
                    window.setTimeout(function () {
                        $("#pmtfclessType").focus();
                    }, 100);
                }
            }
        } else {

            //  console.log( 'location5' );
            //Pending


            if (keyEnter != null && keyEnter == 'amtLess') {
                this.lessEntry();
            } else {
                window.setTimeout(function () {
                    $("#pmtfclessType").focus();
                }, 100);
            }

        }

    }
    lessEntry() {
        //  console.log( 'location6' );
        this.enteredTotalLess = $("#" + this.pageId + "totalLess").val();
        this.enteredLessAmt = $("#" + this.pageId + "entryLessAmount").val();
        this.enteredLessAmt = this.enteredLessAmt == null ? 0.0 : this.enteredLessAmt == '' ? 0.0 : this.enteredLessAmt;
        this.totalAmt = this.enteredTotalLess == null ? 0.0 : this.enteredTotalLess == '' ? 0.0 : this.enteredTotalLess;
        this.totalBalAmt = this.balanceAmountFinal;
        this.enteredAmt = 0.0;
        this.clickedRowAmt = 0.0;

        if (this.isEdit) {
            //Pending
            this.clickedRowAmt = this.maintenanceCompletedLessLrs.chqAmount;
        }
        for (let i = 0; i < this.lessEntriesDataTableDataList.length; i++) {
            this.enteredAmt = +this.enteredAmt + +this.lessEntriesDataTableDataList[i].chqAmount;
        }
        this.enteredAmt = +this.enteredAmt + +this.enteredLessAmt;
        // deduct the clicked row when edit
        this.enteredAmt = +this.enteredAmt - +this.clickedRowAmt;
        // console.log( this.enteredAmt, this.totalAmt, this.totalBalAmt );
        if (this.enteredTotalLess == null || this.enteredTotalLess == '') {
            swal({
                title: "Not Allowed",
                text: "Please enter the total Less amount ",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else if (this.enteredAmt > this.totalAmt) {
            swal({
                title: "Not Allowed",
                text: "Total entered amount exceed from the actual Amount, please verify!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        } else if (this.enteredAmt > this.totalBalAmt) {
            swal({
                title: "Not Allowed",
                text: "Amount exceeded from the actual Bill Amount, please verify!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            $("#" + this.pageId + "entryLessAmount").val('');
        } else {
            this.insertRowLessEntry();
        }
    }

    validateTotalLess(e) {
        if (e.keyCode == 13) {
            if (this.nextEntryType == 'Less Entry') {
                if (!this.validateAmtWithBillBalTotal()) {
                    window.setTimeout(function () {
                        //                        $("#pmtfcentryLessStation" ).focus();
                        $("#pmtfcmemoNumberForManualLess").focus();
                    }, 100);
                }
            } else if (this.nextEntryType == 'Payment Entry') {
                if (!this.validateAmtWithBillBalTotal()) {
                    this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
                    if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cash') {
                        window.setTimeout(function () {
                            $("#pmtfctwoThousandRupeeCount").focus();
                        }, 100);
                    } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                        window.setTimeout(function () {
                            $("#pmtfcchequeNumber").focus();
                        }, 100);
                    } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                        window.setTimeout(function () {
                            $("#" + this.pageId + "consigneeBankName").val('');
                            $("#" + this.pageId + "newBankName").val('');
                            $("#pmtfcconsigneeBankName").focus();
                        }, 100);
                    }
                }
            }
        }
    }
    //    validateAmtWithBillBalTotal() {
    //        if ( this.nextEntryType == 'Less Entry' ) {
    //            this.enteredTotalLess = $("#"+this.pageId+"totalLess" ).val();
    //        } else if ( this.nextEntryType == 'Payment Entry' ) {
    //            this.enteredTotalLess = $("#"+this.pageId+"totalAmountForPayment" ).val();
    //
    //        }
    //        this.totalAmt = this.enteredTotalLess == null ? 0.0 : this.enteredTotalLess == '' ? 0.0 : this.enteredTotalLess;
    //        this.totalBalAmt = this.balanceAmountFinal;
    //        console.log( this.totalAmt, this.totalBalAmt );
    //
    //
    //        if ( this.totalAmt > this.totalBalAmt ) {
    //            swal( {
    //                title: "Not Allowed",
    //                text: "Total amount exceed from the actual Bill Balance Amount, please verify!",
    //                icon: "warning",
    //                closeOnClickOutside: false,
    //                closeOnEsc: false,
    //            } );
    //            $("#"+this.pageId+"totalLess" ).val( '' );
    //            return true;
    //        } else {
    //            return false;
    //        }
    //    }



    validateAmtWithBillBalTotal() {
        if (this.nextEntryType == 'Less Entry') {
            this.enteredTotalLess = $("#" + this.pageId + "totalLess").val();
        } else if (this.nextEntryType == 'Payment Entry') {
            this.enteredTotalLess = $("#" + this.pageId + "totalAmountForPayment").val();

        }
        this.totalAmt = this.enteredTotalLess == null ? 0.0 : this.enteredTotalLess == '' ? 0.0 : this.enteredTotalLess;
        this.totalBalAmt = this.balanceAmountFinal;
        //  console.log( this.totalAmt, this.totalBalAmt );

        if (this.isPaymentClicked) {
            return false;
        } else {
            if (this.totalAmt > this.totalBalAmt) {
                swal({
                    title: "Not Allowed",
                    text: "Total amount exceed from the actual Bill Balance Amount, please verify!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
                $("#" + this.pageId + "totalLess").val('');
                return true;
            } else {
                return false;
            }
        }



    }
    //    searchedMemoForForDiscInLessEntryTable( memoNum ) {
    //        if ( this.lessEntriesDataTableDataList.length == 0 ) {
    //            this.getsearchInMemoExistDiscInLessEntry = new CashMemoDto();
    //            console.log( ' 1' );
    //        } else {
    //            console.log( '2' );
    //            this.getsearchInMemoExistDiscInLessEntry = this.enteredSearchDiscInLessEntry( memoNum )
    //        }
    //        console.log( this.getsearchInMemoExistDiscInLessEntry );
    //        return this.getsearchInMemoExistDiscInLessEntry;
    //    }
    //    enteredSearchDiscInLessEntry( memoNum: string ): CashMemoDto {
    //        console.log( '1111' );
    //        this.letValueofMemoExistDiscInLessEntry = ( this.lessEntriesDataTableDataList.find( lessEntriesDataTableData => lessEntriesDataTableData.chqType === memoNum ) );
    //        if ( this.letValueofMemoExistDiscInLessEntry == null || this.letValueofMemoExistDiscInLessEntry == undefined ) {
    //            this.letValueofMemoExistDiscInLessEntry = new CashMemoDto();
    //            console.log( 'boss1' );
    //        } else {
    //            console.log( 'boss1cd' );
    //            this.letValueofMemoExistDiscInLessEntry = this.letValueofMemoExistDiscInLessEntry;
    //        }
    //        console.log( this.letValueofMemoExistDiscInLessEntry );
    //        return this.letValueofMemoExistDiscInLessEntry;
    //    }
    insertRowLessEntry() {
        //  console.log( 'i am here' );
        this.memoNum = '';
        this.selectedEntryType = '';
        this.selectedCodeForManual = '';
        this.selectedMemoNumberForManual = '';
        this.selectedMemoNumberForAutomatic = '';
        this.selectedEntryType = $("#" + this.pageId + "entryLessEntryType").val();
        this.selectedCodeForManual = $("#" + this.pageId + "codeForManualLess").val();
        this.selectedMemoNumberForManual = $("#" + this.pageId + "memoNumberForManualLess").val();
        this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomaticLess").val();
        this.selectedConsignee = $("#" + this.pageId + "consigneeName").val();
        if (this.selectedEntryType == 'entryLessAutomatic') {
            this.memoNum = this.selectedMemoNumberForAutomatic;
            //     console.log( this.memoNum );
        } else {
            this.memoNum = this.selectedCodeForManual + "" + this.selectedMemoNumberForManual;
            //        console.log( this.memoNum );
        }
        //Pending
        //                if (maintenanceCompletedLessLrs != null) {
        //                    listStoreCompleteLessLrs.remove(maintenanceCompletedLessLrs);
        //                }

        //        this.searchedMemoFrmAssingedTable = this.searchedMemoForMemoFrmAssingedTable( this.memoNum );
        //
        //        if ( this.searchedMemoFrmAssingedTable.memoNumber == null || this.searchedMemoFrmAssingedTable.memoNumber == '' ) {
        //            this.maintenanceMemoNumber = '';
        //        } else {
        //
        //            this.modelForLessMaintain = this.searchedMemoFrmAssingedTable;
        //        }

        const checkAssignedTable = this.pfAssignedJobDetailsDataList.find(pfAssignedJobDetailsData => pfAssignedJobDetailsData.memoNumber === this.memoNum);
        //  console.log( 'Checking' );
        // console.log( checkAssignedTable );
        this.letValueofMemoFrmAssingedTableAny = [];
        if (checkAssignedTable == undefined) {
            this.letValueofMemoFrmAssingedTableAny = [];
        } else {
            this.letValueofMemoFrmAssingedTableAny = checkAssignedTable
        }

        //  console.log( this.letValueofMemoFrmAssingedTableAny );
        this.getGroupStationOfLR(this.letValueofMemoFrmAssingedTableAny, this.memoNum);
    }

    //    searchedMemoForMemoFrmAssingedTable( selectedNum: string ) {
    //
    //        if ( this.pfAssignedJobDetailsDataList.length == 0 ) {
    //            this.getsearchInMemoFrmAssingedTable = new CashMemoDto();
    //            console.log( ' 1' );
    //        } else {
    //            console.log( '2' );
    //            this.getsearchInMemoFrmAssingedTable = this.enteredSearchFromAssignedTable( selectedNum )
    //        }
    //        console.log( this.getsearchInMemoFrmAssingedTable );
    //        return this.getsearchInMemoFrmAssingedTable;
    //    }
    //    enteredSearchFromAssignedTable( selectedNum: string ): CashMemoDto {
    //        console.log( '1111' );
    //        this.letValueofMemoFrmAssingedTable = ( this.pfAssignedJobDetailsDataList.find( pfAssignedJobDetailsData => pfAssignedJobDetailsData.memoNumber === selectedNum ) );
    //        if ( this.letValueofMemoFrmAssingedTable == null || this.letValueofMemoFrmAssingedTable == undefined ) {
    //            this.letValueofMemoFrmAssingedTable = new CashMemoDto();
    //            console.log( 'boss1' );
    //        } else {
    //            console.log( 'boss1cd' );
    //            this.letValueofMemoFrmAssingedTable = this.letValueofMemoFrmAssingedTable;
    //        }
    //        console.log( this.letValueofMemoFrmAssingedTable );
    //        return this.letValueofMemoFrmAssingedTable;
    //    }
    getGroupStationOfLR(modelForLessMaintain, memoNum) {
        this.lrDtoForGroupStation = new LRDto();
        this.lrDtoForGroupStation.lrNumber = modelForLessMaintain.lrNumber;
        this.getGroupStationOfLRService(memoNum);

    }
    getGroupStationOfLRService = (memoNum) => {
        this.memoService.getGroupStationOfLRNumber(this.lrDtoForGroupStation).subscribe(
            (response) => {
                if (response.length == 0) {

                } else {
                    this.lrDtoForGroupStationRet = response;
                    //                    console.log(this.lrDtoForGroupStationRet)
                    this.lrDtoForGroupStationSet = new LRDto();
                    this.lrDtoForGroupStation = new LRDto();
                    this.lrDtoForGroupStationSet = this.lrDtoForGroupStationRet;
                    this.addInLessEntryTable(memoNum);
                    //                    console.log(this.lrDtoForGroupStationSet);

                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    addInLessEntryTable(memoNum) {

        this.newAttribute.chqType = memoNum;
        this.newAttribute.chqNumber = $("#" + this.pageId + "lessType").val();
        this.newAttribute.chqAmount = $("#" + this.pageId + "entryLessAmount").val();
        this.newAttribute.cashMemoAmt = this.lrDtoForGroupStationSet.cashAmt;
        this.newAttribute.balance = 0.0;
        this.newAttribute.mainStation = this.lrDtoForGroupStationSet.mainstation;
        this.newAttribute.consigneeId = this.selectedConsigneeId;
        this.newAttribute.consigneeName = $("#" + this.pageId + "consigneeName").val();
        //        console.log( this.newAttribute.cashMemoAmt, this.newAttribute.mainStation);

        if (this.editTableId != null) {
            this.lessEntriesDataTableDataList.splice(this.editTableId, 1);
            this.countOfLessLR = +this.countOfLessLR - 1;
            this.sumOfLessAmt = +this.sumOfLessAmt - +this.editlessamount;
        }
        if (this.lessEntriesDataTableDataList == null) {
            this.lessEntriesDataTableDataList = [];
        }
        //console.log(this.setLessEntryDetails);
        if (this.setLessEntryDetails == 'new') {
            this.dtTriggerLessEntriesDataTable.next();
            this.setLessEntryDetails = 'old';
        }
        if (this.lessEntriesDataTableDataList.length == 0) {
            this.lessEntriesDataTableDataList.push(this.newAttribute);
        } else {
            this.lessEntriesDataTableDataList.push(this.newAttribute);
        }
        // console.log( this.lessEntriesDataTableDataList );

        this.countOfLessLR = +this.countOfLessLR + 1;
        this.sumOfLessAmt = +this.sumOfLessAmt + +this.newAttribute.chqAmount;
        //        for(let i=0; i <this.lessEntriesDataTableDataList.length; i++){
        //       
        //        }

        this.newAttribute = {};
        this.isEdit = false;
        $("#" + this.pageId + "entryLessAmount").val('');
        $("#" + this.pageId + "lessType").val('');
        //        $("#"+this.pageId+"memoNumberForManualLess" ).val( '' );
        //        $("#"+this.pageId+"memoNumberForAutomaticLess" ).val( '' );
        //        $("#pmtfcmemoNumberForManualLess" ).focus();
        this.selectedEntryType = $("#" + this.pageId + "entryLessEntryType").val();
        this.selectedCodeForManual = $("#" + this.pageId + "codeForManualLess").val();

        if (this.selectedEntryType == 'entryLessAutomatic') {
            $("#" + this.pageId + "memoNumberForAutomaticLess").val('');
            $("#pmtfcmemoNumberForAutomaticLess").focus();
        } else {
            $("#" + this.pageId + "memoNumberForManualLess").val('');
            $("#pmtfcmemoNumberForManualLess").focus();
        }
        $("#" + this.pageId + "hiddenIndexForLessEntry").val('');
        this.editTableId = null;
        ///Pending 
        this.maintenanceCompletedLessLrs = [];
        this.editlessamount = 0;
        this.lrDtoForGroupStationRet = '';
        this.lrDtoForGroupStationSet = new LRDto();
        this.controlEntryLessType.reset();
    }



    callForCompleteCollection() {
        if (this.chkPendingMemosExist()) {
            //Pending
            this.removePendingMemos();
        } else {
            //Pending
            this.getTotalAmtOfCompleteLrs();
            this.getConsigneeWiseBalanceDetails();
        }
    }
    getConsigneeWiseBalanceDetails() {
        this.consigneeWiseBalancelDto = new CashMemoDto();
        this.consigneeWiseBalancelDto.consigneeWiseMemo = this.pfAssignedJobDetailsDataList;
        this.consigneeWiseBalancelDto.consigneeWiseMode = "new";
        this.getConsigneeWiseBalanceInfo();
    }
    getConsigneeWiseBalanceInfo = () => {
        this.memoService.getConsigneeWiseBalanceDet(this.consigneeWiseBalancelDto).subscribe(
            (response) => {
                if (response.length == 0) {

                } else {
                    this.consigneeWiseBalancelRet = response;
                    this.setValInConsigneeWiseBalanceDetails();
                    if (this.consigneeWiseTrigger == 'new') {
                        this.dtTriggerConsigneeWiseBalanceDetails.next();
                        this.consigneeWiseTrigger = 'old'
                    }

                    //console.log( this.consigneeWiseBalancelRet );
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    setValInConsigneeWiseBalanceDetails() {
        this.consigneeWiseBalanceDetailsDataList = [];
        this.countOfConsingeeWiseMemo = 0;
        this.sumOfConsingeeWiseTotalCashMemo = 0;
        this.sumOfConsingeeWiseGrandTotal = 0;
        this.sumOfConsingeeWisePartyLessDed = 0;
        this.sumOfConsingeeWiseAdvChqAmt = 0;
        this.sumOfConsingeeWiseNeftAmtl = 0;
        this.sumOfConsingeeWiseBalance = 0;
        //PFCHG_V5
        this.sumOfConsingeeWiseDebitAcAmtl = 0;
        this.sumOfConsingeeWiseBalance = 0;


        for (let key in this.consigneeWiseBalancelRet.hashMapConsigneeBalance) {
            //Whatever you want to do with key or obj[key]
            console.log(key);
            console.log(this.consigneeWiseBalancelRet.hashMapConsigneeBalance[key]);
            if (key != '') {
                this.newAttributeForConsigneeWiseBalance = {};
                this.newAttributeForConsigneeWiseBalance.consigneeName = this.consigneeWiseBalancelRet.hashMapConsigneeBalance[key].consignee;
                this.newAttributeForConsigneeWiseBalance.totalCashMemo = this.consigneeWiseBalancelRet.hashMapConsigneeBalance[key].size;
                this.newAttributeForConsigneeWiseBalance.grandTotal = this.consigneeWiseBalancelRet.hashMapConsigneeBalance[key].grandTotal;
                this.newAttributeForConsigneeWiseBalance.partyLessDed = this.consigneeWiseBalancelRet.hashMapConsigneeBalance[key].less;
                this.newAttributeForConsigneeWiseBalance.advChqAmt = this.consigneeWiseBalancelRet.hashMapConsigneeBalance[key].chequeAmt;
                this.newAttributeForConsigneeWiseBalance.neftAmt = this.consigneeWiseBalancelRet.hashMapConsigneeBalance[key].neftAmt;
                this.newAttributeForConsigneeWiseBalance.balance = this.consigneeWiseBalancelRet.hashMapConsigneeBalance[key].balanceAmt;
                this.newAttributeForConsigneeWiseBalance.consigneeId = this.consigneeWiseBalancelRet.hashMapConsigneeBalance[key].consigneeId;

                this.newAttributeForConsigneeWiseBalance.debitAcAmt = this.consigneeWiseBalancelRet.hashMapConsigneeBalance[key].debitAcAmt;
                this.newAttributeForConsigneeWiseBalance.upiAmt = this.consigneeWiseBalancelRet.hashMapConsigneeBalance[key].upiAmt;
                this.countOfConsingeeWiseMemo = +this.countOfConsingeeWiseMemo + 1;
                this.sumOfConsingeeWiseTotalCashMemo = +this.sumOfConsingeeWiseTotalCashMemo + +this.newAttributeForConsigneeWiseBalance.totalCashMemo;
                this.sumOfConsingeeWiseGrandTotal = +this.sumOfConsingeeWiseGrandTotal + +this.newAttributeForConsigneeWiseBalance.grandTotal;
                this.sumOfConsingeeWisePartyLessDed = +this.sumOfConsingeeWisePartyLessDed + +this.newAttributeForConsigneeWiseBalance.partyLessDed;
                this.sumOfConsingeeWiseAdvChqAmt = +this.sumOfConsingeeWiseAdvChqAmt + +this.newAttributeForConsigneeWiseBalance.advChqAmt;
                this.sumOfConsingeeWiseNeftAmtl = +this.sumOfConsingeeWiseNeftAmtl + +this.newAttributeForConsigneeWiseBalance.neftAmt;
                this.sumOfConsingeeWiseBalance = +this.sumOfConsingeeWiseBalance + +this.newAttributeForConsigneeWiseBalance.balance;
                //PFCHG_V5
                this.sumOfConsingeeWiseDebitAcAmtl = +this.sumOfConsingeeWiseDebitAcAmtl + +this.newAttributeForConsigneeWiseBalance.debitAcAmt;
                this.sumOfConsingeeWiseUPIAmtl = +this.sumOfConsingeeWiseUPIAmtl + +this.newAttributeForConsigneeWiseBalance.upiAmt;
                this.consigneeWiseBalanceDetailsDataList.push(this.newAttributeForConsigneeWiseBalance);
            }
        }
    }

    updateConsigneeBalanceDetails() {
        //        // less entries
        //        for ( let i = 0; i < this.consigneeWiseBalanceDetailsDataList.length; i++ ) {
        //            let totLessAmt = 0.0, totChqAmt = 0.0, totNeftAmt = 0.0, balanceAmt = 0.0;
        //            for ( let j = 0; j < this.userDataDtosLessTemp.length; j++ ) {
        //                if ( this.consigneeWiseBalanceDetailsDataList[j].consigneeId == this.userDataDtosLessTemp[j].consigneeId ) {
        //                    totLessAmt = +totLessAmt + +( this.userDataDtosLessTemp[j].paymentAmt == null ? 0.0 : this.userDataDtosLessTemp[j].paymentAmt );
        //                }
        //            }
        //            // chq entries
        //            for ( let j = 0; j < this.userDataDtosPaymentChqTemp.length; j++ ) {
        //                if ( this.consigneeWiseBalanceDetailsDataList[j].consigneeId == this.userDataDtosPaymentChqTemp[j].consigneeId ) {
        //                    totChqAmt = +totChqAmt + +( this.userDataDtosPaymentChqTemp[j].paymentAmt == null ? 0.0 : this.userDataDtosPaymentChqTemp[j].paymentAmt );
        //                }
        //            }
        //            // neft entries
        //            for ( let j = 0; j < this.userDataDtosPaymentNeftTemp.length; j++ ) {
        //                if ( this.consigneeWiseBalanceDetailsDataList[j].consigneeId == this.userDataDtosPaymentNeftTemp[j].consigneeId ) {
        //                    totNeftAmt = +totNeftAmt + +( this.userDataDtosPaymentNeftTemp[j].paymentAmt == null ? 0.0 : this.userDataDtosPaymentNeftTemp[j].paymentAmt );
        //                }
        //            }
        //
        //
        ////            this.setValOfConsignee(this.consigneeWiseBalanceDetailsDataList[i].consigneeId);
        //            
        //            for ( let key in this.consigneeWiseBalancelRet.hashMapConsigneeBalance ) {
        //
        //                if ( key == this.consigneeWiseBalanceDetailsDataList[i].consigneeId ) {
        //                    this.consigneeWiseBalancelDto = new CashMemoDto();
        //                    this.consigneeWiseBalancelDto = this.consigneeWiseBalancelRet.hashMapConsigneeBalance[key];
        //                    if( this.consigneeWiseBalancelDto != null){
        //                        
        //                        this.consigneeWiseBalancelDto.less =totLessAmt;
        //                        this.consigneeWiseBalancelDto.chequeAmt =totChqAmt;
        //                        this.consigneeWiseBalancelDto.neftAmt =totNeftAmt;
        //                        balanceAmt= +this.consigneeWiseBalancelDto.grandTotal - +(+totLessAmt + +totChqAmt + +totNeftAmt);
        //                        console.log(balanceAmt);
        //                        this.consigneeWiseBalancelDto.balanceAmt =balanceAmt;
        //                        this.consigneeWiseBalancelRet.hashMapConsigneeBalance.set( this.consigneeWiseBalanceDetailsDataList[i].consigneeId,this.consigneeWiseBalancelDto);
        //                        console.log( this.consigneeWiseBalancelRet);
        //                    }
        //                }
        //            }
        //        }
        //        this.setValInConsigneeWiseBalanceDetails();
        this.getConsigneeWiseBalanceUpdateDetails();
    }
    getConsigneeWiseBalanceUpdateDetails() {
        this.consigneeWiseBalancelDtoForUpdate = new CashMemoDto();
        this.consigneeWiseBalancelDtoForUpdate.consigneeWiseMemo = this.pfAssignedJobDetailsDataList;
        this.consigneeWiseBalancelDtoForUpdate.consigneeWiseBalanceDtails = this.consigneeWiseBalanceDetailsDataList;
        this.consigneeWiseBalancelDtoForUpdate.listLessDetails = this.userDataDtosLessTemp;
        this.consigneeWiseBalancelDtoForUpdate.listChqDetails = this.userDataDtosPaymentChqTemp;

        this.consigneeWiseBalancelDtoForUpdate.listNEFTDetails = this.userDataDtosPaymentNeftTemp;
        this.consigneeWiseBalancelDtoForUpdate.consigneeWiseMode = "update";
        //console.log( this.userDataDtosLessTemp, this.userDataDtosPaymentChqTemp );
        //PFCHG_V5
        this.consigneeWiseBalancelDtoForUpdate.listDebitAccDetails = this.userDataDtosPaymentDebitAccTemp;
        this.consigneeWiseBalancelDtoForUpdate.listUPIDetails = this.userDataDtosPaymentUPITemp;
        this.getConsigneeWiseBalanceUpdate();
    }
    getConsigneeWiseBalanceUpdate = () => {
        this.memoService.getConsigneeWiseBalanceDet(this.consigneeWiseBalancelDtoForUpdate).subscribe(
            (response) => {
                if (response.length == 0) {

                } else {
                    this.consigneeWiseBalancelRet = [];
                    this.consigneeWiseBalancelRet = response;
                    this.setValInConsigneeWiseBalanceDetails();
                    //    console.log( this.consigneeWiseBalancelRet );
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    clearConsigneewiseDetails() {
        this.countOfConsingeeWiseMemo = 0;
        this.sumOfConsingeeWiseTotalCashMemo = 0;
        this.sumOfConsingeeWiseGrandTotal = 0;
        this.sumOfConsingeeWisePartyLessDed = 0;
        this.sumOfConsingeeWiseAdvChqAmt = 0;
        this.sumOfConsingeeWiseNeftAmtl = 0;
        this.sumOfConsingeeWiseBalance = 0;
        this.consigneeWiseBalanceDetailsDataList = [];
        this.consigneeWiseBalancelRet = [];
        this.newAttributeForConsigneeWiseBalance = {};
        this.consigneeWiseBalancelDto = new CashMemoDto();
        this.consigneeWiseBalancelDtoForUpdate = new CashMemoDto();
        this.sumOfConsingeeWiseDebitAcAmtl = 0;
        this.sumOfConsingeeWiseUPIAmtl = 0;

    }
    //    setValOfConsignee(consigneeId){
    //        this.consigneeWiseBalancelDto = new CashMemoDto();
    //        this.consigneeWiseBalancelDto.hashMapConsigneeBalance = this.consigneeWiseBalancelRet.hashMapConsigneeBalance;
    //        this.getConsigneeWiseBalanceInfo();
    //    }
    //    getConsigneeWiseUpdateInfo = () => {
    //        this.memoService.getConsigneeWiseBalanceUpdateDet( this.consigneeWiseBalancelDto ).subscribe(
    //            ( response ) => {
    //                if ( response.length == 0 ) {
    //
    //                } else {
    //                    this.consigneeWiseBalancelRet = response;
    //                    this.setValInConsigneeWiseBalanceDetails();
    //                    console.log( this.consigneeWiseBalancelRet );
    //                }
    //
    //            } ),
    //            ( error ) => console.log( error.json() ),
    //            () => console.log( 'done' );
    //    };

    getTotalAmtOfCompleteLrs() {
        let totAmt = 0.0;
        for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
            totAmt = +totAmt + +this.pfAssignedJobDetailsDataList[i].amount;
        }
        this.balanceAmountFinal = totAmt;
        this.amountToBeCollected = totAmt;
        //  console.log( this.balanceAmountFinal, this.amountToBeCollected );
    }



    rowSelectedEditForLessEntry(lessEntriesDataTableData, index) {
        $("#" + this.pageId + "lessType").val(lessEntriesDataTableData.chqNumber);
        $("#" + this.pageId + "entryLessAmount").val(lessEntriesDataTableData.chqAmount);
        //For manual 
        let selectedMemo = lessEntriesDataTableData.chqType;
        this.slashSplitMemo = selectedMemo.split("/");
        let destSplit = this.slashSplitMemo[0];
        let yearSplit = this.slashSplitMemo[1];
        let memoNumberSplit = this.slashSplitMemo[2];
        //   console.log( selectedMemo, this.slashSplitMemo, destSplit, yearSplit, memoNumberSplit );
        let memoNumberCombine = destSplit + "/" + yearSplit + "/";

        //        $("#"+this.pageId+"lessType" ).val( lessEntriesDataTableData.chqNumber );
        $("#" + this.pageId + "codeForManualLess").val(memoNumberCombine);
        $("#" + this.pageId + "memoNumberForManualLess").val(memoNumberSplit);
        this.modelStationForLessAmt = memoNumberCombine;

        //////
        //For Automatic
        $("#" + this.pageId + "memoNumberForAutomaticLess").val(lessEntriesDataTableData.chqType);
        /////

        $("#" + this.pageId + "hiddenIndexForLessEntry").val(index);
        this.editTableId = index;
        ///
        this.maintenanceCompletedLessLrs = [];
        this.maintenanceCompletedLessLrs = lessEntriesDataTableData;
        //   console.log( this.maintenanceCompletedLessLrs );
        this.isEdit = true;

        //Set as manual as per old logic
        $("#" + this.pageId + "entryLessEntryType").val('entryLessManual');
        this.entryLessManualView = true;
        this.entryLessAutomaticView = false;
        if (this.nextEntryType == 'Payment Entry') {
            $("#" + this.pageId + "entryLessAmount").val(lessEntriesDataTableData.balance);
        } else {
            $("#" + this.pageId + "entryLessAmount").val(lessEntriesDataTableData.chqAmount);
        }
        this.editlessamount = 0;
        this.editlessamount = lessEntriesDataTableData.chqAmount;
        $("#" + this.pageId + "consigneeName").val(lessEntriesDataTableData.consigneeName);
        this.selectedConsigneeId = lessEntriesDataTableData.consigneeId;
        //   console.log( this.selectedConsigneeId );
    }
    rowSelectedDeleteForLessEntry(lessEntriesDataTableData, index) {
        swal({
            title: "Confirm Remove",
            text: "Sure you want to remove the selected less entry",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((remove) => {
            if (remove) {
                //  console.log( this.lessEntriesDataTableDataList.length );
                this.lessEntriesDataTableDataList.splice(index, 1);
                $("#" + this.pageId + "hiddenIndexForLessEntry").val('');
                //  console.log( this.lessEntriesDataTableDataList.length );
                this.countOfLessLR = +this.countOfLessLR - 1;
                this.sumOfLessAmt = +this.sumOfLessAmt - +lessEntriesDataTableData.chqAmount;
                $("#" + this.pageId + "entryLessAmount").val('');
                $("#" + this.pageId + "lessType").val('');
                $("#" + this.pageId + "memoNumberForManualLess").val('');
                $("#pmtfcmemoNumberForManualLess").focus();
                this.editTableId = null;
                this.maintenanceCompletedLessLrs = [];
                this.isEdit = false;
                this.controlEntryLessType.reset();
            }

        });
    }
    //    for less entry starts

    toggleEditableForTDS(event, contentLessEntryTds) {
        if (event.target.checked) {
            this.openLessEntryTdsPopup(contentLessEntryTds)
        } else {
            this.resetTDSBulkFields();
        }
    }

    // TDS Process starts form here
    openLessEntryTdsPopup(contentLessEntryTds) {
        this.selectedConsignee = $("#" + this.pageId + "consigneeName").val();
        if (this.selectedConsignee == '' || this.selectedConsignee == null) {
            swal({
                title: "Mandatory Field",
                text: "Please select Consignee Name to get TDS Bulk details!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            //Pending
            $("#" + this.pageId + "checkboxTDS").prop("checked", false);

        } else {
            this.isDuplicateBulkLess = false;
            for (let i = 0; i < this.lessEntriesDataTableDataList.length; i++) {

                let consigneeId = this.lessEntriesDataTableDataList[i].consigneeId;
                let lessDesc = this.lessEntriesDataTableDataList[i].chqNumber;

                if ((this.selectedConsigneeId == consigneeId)
                    && (lessDesc == 'TDS')) {
                    this.isDuplicateBulkLess = true;
                    break;
                }
            }

            if (this.isDuplicateBulkLess) {
                swal({
                    title: "Not Allowed",
                    text: "TDS Less entry is already done for this Consignee Memo, please verify again!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
                this.resetTDSBulkFields();
            } else {
                this.tdsBulkEntryflow();
                this.modalService.open(contentLessEntryTds, { centered: true }).result.then(
                    resultContent => {
                        this.closeResultContentLessEntryTds = `Closed with: ${resultContent}`;
                    },
                    reason => {
                        this.closeResultContentLessEntryTds = `Dismissed ${this.getDismissReason(reason)}`;
                    }
                );
            }
        }

    }

    resetTDSBulkFields() {
        $("#" + this.pageId + "totalMemoForTDS").val('');
        $("#" + this.pageId + "memoAmountForTDS").val('');
        $("#" + this.pageId + "lessForTDS").val('');
        $("#" + this.pageId + "tdsLessTwoPer").val('');
        //Pending
        $("#" + this.pageId + "checkboxTDS").prop("checked", false);
        //        need to clear the cashMemoDtoForTDSRet is  Pending
        this.cashMemoDtoForTDSRet = [];
    }

    tdsBulkEntryflow() {
        // filter memos from the assigned job details by this
        // selectedConsigneeId
        this.tdsBulkTotMemoAmt = 0.0;
        this.listOfMemoNumber = [];
        for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
            let consigneeId = this.pfAssignedJobDetailsDataList[i].consigneeId;
            if (consigneeId == this.selectedConsigneeId) {
                this.tdsBulkTotMemoAmt = +this.tdsBulkTotMemoAmt + +this.pfAssignedJobDetailsDataList[i].amount;
                this.listOfMemoNumber.push(this.pfAssignedJobDetailsDataList[i].memoNumber);
            }
        }

        //   console.log( this.tdsBulkTotMemoAmt, this.listOfMemoNumber );

        if (this.listOfMemoNumber.length == 0) {
            swal({
                title: "TDS Bulk Entry",
                text: "No memos to calculate TDS bulk details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.resetTDSBulkFields();
        } else {
            //                        $("#"+this.pageId+"totalMemoForTDS" ).val( this.listOfMemoNumber.length );
            //                        $("#"+this.pageId+"memoAmountForTDS" ).val( this.tdsBulkTotMemoAmt );
            this.cashMemoDtoForTDS = new CashMemoDto();
            this.cashMemoDtoForTDS.list = this.listOfMemoNumber;
            //  console.log( 'Dto' );
            // console.log( this.cashMemoDtoForTDS );
            this.getTotLessAmt(this.cashMemoDtoForTDS);
        }
    }

    getTotLessAmt = (cashMemoDtoForTDS) => {
        this.memoService.getTotalMemoLessAmount(cashMemoDtoForTDS).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "TDS Bulk Entry",
                        text: "Server problem occurred while calculating the TDS bulk details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.cashMemoDtoForTDS = new CashMemoDto();
                } else {
                    //    console.log( 'success' );
                    this.cashMemoDtoForTDSRet = response;
                    //                    console.log( this.cashMemoDtoForTDSRet );
                    //                    this.cashMemoDtoForTDSSet = new CashMemoDto();
                    //                    this.cashMemoDtoForTDSSet = this.cashMemoDtoForTDSRet;
                    let tdsBulkAmt = 0.0;
                    let less = 0.0;
                    for (let i = 0; i < this.cashMemoDtoForTDSRet.length; i++) {
                        let balAfterDedLess = +this.cashMemoDtoForTDSRet[i].grandTotal - +this.cashMemoDtoForTDSRet[i].less;
                        let calTds = Math.round((balAfterDedLess * this.tdsLessMulti) / 100);
                        tdsBulkAmt = +tdsBulkAmt + +calTds;
                        less = +less + +this.cashMemoDtoForTDSRet[i].less;
                        //                             console.log( balAfterDedLess );
                        //                           console.log( calTds );
                        //                         console.log( tdsBulkAmt );
                        //                        console.log( less );
                    }
                    $("#" + this.pageId + "lessForTDS").val(less);
                    $("#" + this.pageId + "tdsLessTwoPer").val(tdsBulkAmt);
                    $("#" + this.pageId + "totalMemoForTDS").val(this.listOfMemoNumber.length);
                    $("#" + this.pageId + "memoAmountForTDS").val(this.tdsBulkTotMemoAmt);
                    this.tdsBulkTotMemoAmt = 0.0;
                    this.listOfMemoNumber = [];
                    tdsBulkAmt = 0.0;
                    less = 0.0;
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    tdsInsert() {
        swal({
            title: "Confirm Apply",
            text: "Sure you want to apply the TDS bulk entries?",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((insert) => {
            if (insert) {
                this.tdsBulkEntryInsert();
                this.resetTDSBulkFields();
            }

        });
    }

    uncheck() {
        this.resetTDSBulkFields();
    }

    tdsBulkEntryInsert() {
        for (let i = 0; i < this.cashMemoDtoForTDSRet.length; i++) {
            //            console.log(this.cashMemoDtoForTDSRet);
            let balAfterDedLess = +this.cashMemoDtoForTDSRet[i].grandTotal - +this.cashMemoDtoForTDSRet[i].less;
            let calTds = Math.round((balAfterDedLess * this.tdsLessMulti) / 100);
            //            console.log(balAfterDedLess);
            //            console.log(calTds);
            this.newAttributeForTDS.chqType = this.cashMemoDtoForTDSRet[i].memoNumber;
            this.newAttributeForTDS.chqNumber = "TDS";
            this.newAttributeForTDS.chqAmount = calTds;
            this.newAttributeForTDS.cashMemoAmt = this.cashMemoDtoForTDSRet[i].grandTotal;
            this.newAttributeForTDS.balance = 0.0;
            this.newAttributeForTDS.mainStation = "NA";
            this.newAttributeForTDS.consigneeId = this.cashMemoDtoForTDSRet[i].consigneeId;
            this.newAttributeForTDS.consigneeName = this.cashMemoDtoForTDSRet[i].consigneeName;

            if (this.lessEntriesDataTableDataList == null) {
                this.lessEntriesDataTableDataList = [];
            }

            if (this.lessEntriesDataTableDataList.length == 0) {
                this.lessEntriesDataTableDataList.push(this.newAttributeForTDS);
            } else {
                this.lessEntriesDataTableDataList.push(this.newAttributeForTDS);
            }
            this.countOfLessLR = +this.countOfLessLR + 1;
            this.sumOfLessAmt = +this.sumOfLessAmt + +this.newAttributeForTDS.chqAmount;
            this.newAttributeForTDS = {};
            this.cashMemoDtoForTDS = new CashMemoDto();
        }
    }
    toggleEditableForStatuBack(event, contentLessEntryStatusBack) {
        if (event.target.checked) {
            this.openLessEntryStatusBackPopup(contentLessEntryStatusBack);
        } else {

        }
    }

    toggleEditableForStatuBackPayment(event, contentLessEntryStatusBack) {
        if (event.target.checked) {
            this.openLessEntryStatusBackPopup(contentLessEntryStatusBack);
        } else {

        }
    }
    openLessEntryStatusBackPopup(contentLessEntryStatusBack) {
        this.modalService.open(contentLessEntryStatusBack, { centered: true }).result.then(
            resultContent => {
                this.closeResultContentLessEntryStatusBack = `Closed with: ${resultContent}`;
            },
            reason => {
                this.closeResultContentLessEntryStatusBack = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    validateStatusBackBtn() {
        this.verifyMemoExistInLessOrPaymentBeforeStatusBackOnComplete();
    }

    verifyMemoExistInLessOrPaymentBeforeStatusBackOnComplete() {
        this.statusBackMemoNumber = $("#" + this.pageId + "cashmemoNumberForStatusBack").val();
        //   console.log( this.statusBackMemoNumber );
        const checkAssignedTable = this.pfAssignedJobDetailsDataList.find(pfAssignedJobDetailsData => pfAssignedJobDetailsData.memoNumber === this.statusBackMemoNumber);
        //    console.log( checkAssignedTable );
        this.letValueofSearchInAssingedTableForStatusBack = [];
        if (checkAssignedTable == undefined) {
            this.letValueofSearchInAssingedTableForStatusBack = [];
        } else {
            this.letValueofSearchInAssingedTableForStatusBack = checkAssignedTable;
        }

        if (this.letValueofSearchInAssingedTableForStatusBack.length == 0) {
            swal({
                title: "No Record",
                text: "No Memo found, please verify memo number",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {

            this.isExist = false;
            /////Pending
            for (let i = 0; i < this.userDataDtosLessTemp.length; i++) {
                if (this.statusBackMemoNumber == this.userDataDtosLessTemp[i].memoNumber) {
                    this.isExist = true;
                    break;
                }
            }

            for (let i = 0; i < this.userDataDtosPaymentChqTemp.length; i++) {
                if (this.statusBackMemoNumber == this.userDataDtosPaymentChqTemp[i].memoNumber) {
                    this.isExist = true;
                    break;
                }
            }

            for (let i = 0; i < this.userDataDtosPaymentNeftTemp.length; i++) {
                if (this.statusBackMemoNumber == this.userDataDtosPaymentNeftTemp[i].memoNumber) {
                    this.isExist = true;
                    break;
                }
            }
            ////

            if (this.isExist) {
                swal({
                    title: "Not Allowed",
                    text: "Less/Payment entry for the Memo number " + this.statusBackMemoNumber + " is already entered, please verify and edit the entries?",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            } else {
                swal({
                    title: "Confirm Action",
                    text: "Sure you want to take status back?",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((statusBack) => {
                    if (statusBack) {
                        this.statusBackOnComplete();
                    }

                });
            }
        }
    }


    statusBackOnComplete() {
        this.statusBackMemoNumber = $("#" + this.pageId + "cashmemoNumberForStatusBack").val();
        this.selectedCollectionMan = this.enteredCollectionMan;
        this.userDataDtoForStatusBack = new UserDataDto();
        this.listOfMemoNumber = [];
        this.listOfMemoNumber.push(this.statusBackMemoNumber);
        this.userDataDtoForStatusBack.list = this.listOfMemoNumber;
        this.userDataDtoForStatusBack.mode = "statusBack";
        this.userDataDtoForStatusBack.collectioMan = this.selectedCollectionMan;
        this.userDataDtoForStatusBack.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDtoForStatusBack.city = this.userDataDtoReturnSession.mainStation;
        this.userDataDtoForStatusBack.updatedBy = this.userDataDtoReturnSession.userId;
        this.userDataDtoForStatusBack.collectionFlow = this.userDataDtoReturnSession.collectionFlow;
        this.updateStatusBack(this.userDataDtoForStatusBack);
    }
    updateStatusBack = (userDataDtoForStatusBack) => {
        this.memoService.updateMemoDetailService(userDataDtoForStatusBack).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Server Error",
                        text: "Server problem occurred while taken back the memo into stock",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.userDataDtoForStatusBackRet = response;
                    //    console.log( this.userDataDtoForStatusBackRet );
                    //need to check the splice
                    //                    this.pfAssignedJobDetailsDataList.splice( this.pfAssignedJobDetailsDataList.find( pfAssignedJobDetailsData => pfAssignedJobDetailsData.memoNumber === this.statusBackMemoNumber ), 1 );

                    if (this.userDataDtoForStatusBackRet.result == 'success') {
                        let index = -1;
                        for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                            if (this.pfAssignedJobDetailsDataList[i].memoNumber === this.statusBackMemoNumber) {
                                index = i;
                                break;
                            }
                        }
                        if (index === -1) {
                            alert("Something gone wrong while removing the row");
                        }
                        //    console.log( this.pfAssignedJobDetailsDataList );

                        this.pfAssignedJobDetailsDataList.splice(index, 1);

                        // console.log( this.pfAssignedJobDetailsDataList );
                        //Pending
                        this.getTotalAmtOfCompleteLrs();
                        this.calculateAllTotalAndBalance();
                        this.statusBackMemoNumber = '';
                        $("#" + this.pageId + "cashmemoNumberForStatusBack").val('')
                        swal({
                            title: "Success",
                            text: "Cashmemos taken back into stocks",
                            icon: "success",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.updateConsigneeBalanceDetails();
                    } else {
                        swal({
                            title: "Server Error",
                            text: "Server problem occurred while taken back the memo into stock",
                            icon: "error",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    }

                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    uncheckStatusBack() {
        $("#" + this.pageId + "cashmemoNumberForStatusBack").val('');
        $("#" + this.pageId + "statusBack").prop("checked", false);
        $("#" + this.pageId + "statusBackPayment").prop("checked", false);
        this.userDataDtoForStatusBack = new UserDataDto();
        this.listOfMemoNumber = [];
        this.userDataDtoForStatusBackRet = '';
    }

    calculateAllTotalAndBalance() {
        this.calculateTotalLessWithDifferentTypes();
        this.calculateTotalChequePayment();
        this.calculateTotalNeftPayment();
        this.calculateTotalCashPayment();
        //PFCHG_V5
        this.calculateTotalDebitAccPayment();
        this.calculateTotalUPIPayment();

        //
        let totalAmt = 0.0, totalLess = 0.0, totalChq = 0.0, totalNeft = 0.0, totalCash = 0.0, balanceAmt = 0.0, totDebitAcc = 0.0, totUPIAmt = 0.0;
        totalAmt = this.amountToBeCollected == null ? 0.0 : this.amountToBeCollected;
        totalLess = this.lblTotLessSummaryValue == null ? 0.0 : this.lblTotLessSummaryValue == '' ? 0.0 : this.lblTotLessSummaryValue;
        totalChq = this.lblTotChqAmtSummaryValue == null ? 0.0 : this.lblTotChqAmtSummaryValue == '' ? 0.0 : this.lblTotChqAmtSummaryValue;
        totalNeft = this.lblTotNeftAmtSummaryValue == null ? 0.0 : this.lblTotNeftAmtSummaryValue == '' ? 0.0 : this.lblTotNeftAmtSummaryValue;
        totalCash = this.lblTotCashAmtSummaryValue == null ? 0.0 : this.lblTotCashAmtSummaryValue == '' ? 0.0 : this.lblTotCashAmtSummaryValue;
        //PFCHG_V5
        totDebitAcc = this.lblTotDebtAcccAmtSummaryValue == null ? 0.0 : this.lblTotDebtAcccAmtSummaryValue == '' ? 0.0 : this.lblTotDebtAcccAmtSummaryValue;
        totUPIAmt = this.lblTotUPIAmtSummaryValue == null ? 0.0 : this.lblTotUPIAmtSummaryValue == '' ? 0.0 : this.lblTotUPIAmtSummaryValue;
        balanceAmt = +totalAmt - +(+totalLess + +totalChq + +totalNeft + +totalCash + totDebitAcc + totUPIAmt);
        //
        //     console.log( totalAmt, totalLess, totalChq, totalNeft, totalCash, balanceAmt );
        this.balanceAmountFinal = balanceAmt;

    }
    calculateTotalLessWithDifferentTypes() {
        let amtLess = 0.0, amtLessDlh = 0.0, amtLessMum = 0.0, amtLessGuj = 0.0, amtLessPjb = 0.0, amtLessTDS = 0.0, amtLessClaim = 0.0;
        //   console.log( this.userDataDtosLessTemp );
        for (let i = 0; i < this.userDataDtosLessTemp.length; i++) {
            if (this.userDataDtosLessTemp[i].paymentType == 'TDS') {
                amtLessTDS = +amtLessTDS + +this.userDataDtosLessTemp[i].paymentAmt;
            } else if (this.userDataDtosLessTemp[i].paymentType == 'CLAIM') {
                amtLessClaim = +amtLessClaim + +this.userDataDtosLessTemp[i].paymentAmt;
            } else {
                if (this.userDataDtosLessTemp[i].mainStation != null
                    && this.userDataDtosLessTemp[i].mainStation == 'DELHI') {
                    amtLessDlh = +amtLessDlh + +this.userDataDtosLessTemp[i].paymentAmt;
                } else if (this.userDataDtosLessTemp[i].mainStation != null
                    && this.userDataDtosLessTemp[i].mainStation == 'MUMBAI') {
                    amtLessMum = +amtLessMum + +this.userDataDtosLessTemp[i].paymentAmt;
                } else if (this.userDataDtosLessTemp[i].mainStation != null
                    && this.userDataDtosLessTemp[i].mainStation == 'GUJARAT') {
                    amtLessGuj = +amtLessGuj + +this.userDataDtosLessTemp[i].paymentAmt;
                } else if (this.userDataDtosLessTemp[i].mainStation != null
                    && this.userDataDtosLessTemp[i].mainStation == 'PUNJAB') {
                    amtLessPjb = +amtLessPjb + +this.userDataDtosLessTemp[i].paymentAmt;
                }
            }
            amtLess = +amtLess + +this.userDataDtosLessTemp[i].paymentAmt;
        }

        this.lblDelhiLessValue = amtLessDlh;
        this.lblMumLessValue = amtLessMum;
        this.lblGujLessValue = amtLessGuj;
        this.lblPjbLessValue = amtLessPjb;
        this.lblTDSLessValue = amtLessTDS;
        this.lblClaimLessValue = amtLessClaim;
        this.lblTotLessSummaryValue = amtLess;
    }

    calculateTotalChequePayment() {
        let amtRecChq = 0.0;
        for (let i = 0; i < this.userDataDtosPaymentChqTemp.length; i++) {
            amtRecChq = +amtRecChq + +this.userDataDtosPaymentChqTemp[i].paymentAmt;
        }
        this.lblTotChqAmtSummaryValue = amtRecChq;
    }

    calculateTotalNeftPayment() {
        let amtRecNeft = 0.0;
        for (let i = 0; i < this.userDataDtosPaymentNeftTemp.length; i++) {
            amtRecNeft = +amtRecNeft + +this.userDataDtosPaymentNeftTemp[i].paymentAmt;
        }
        this.lblTotNeftAmtSummaryValue = amtRecNeft;
    }

    calculateTotalCashPayment() {
        let totAmtInCash = 0.0;
        this.nf2000 = $("#" + this.pageId + "twoThousandRupee").val();
        this.nf500 = $("#" + this.pageId + "fiveHundredRupee").val();
        this.nf200 = $("#" + this.pageId + "twoHundredRupee").val();
        this.nf100 = $("#" + this.pageId + "hundredRupee").val();
        this.nf50 = $("#" + this.pageId + "fiftyRupee").val();
        this.nf20 = $("#" + this.pageId + "twentyRupee").val();
        this.nf10 = $("#" + this.pageId + "tenRupee").val();
        this.nf5 = $("#" + this.pageId + "fiveRupee").val();
        this.nf2 = $("#" + this.pageId + "twoRupee").val();
        this.nf1 = $("#" + this.pageId + "oneRupee").val();
        //    console.log( this.nf2000 );
        totAmtInCash = +(this.nf2000 == null ? 0.0 : this.nf2000 == '' ? 0.0 : this.nf2000)
            + +(this.nf500 == null ? 0.0 : this.nf500 == '' ? 0.0 : this.nf500)
            + +(this.nf200 == null ? 0.0 : this.nf200 == '' ? 0.0 : this.nf200)
            + +(this.nf100 == null ? 0.0 : this.nf100 == '' ? 0.0 : this.nf100)
            + +(this.nf50 == null ? 0.0 : this.nf50 == '' ? 0.0 : this.nf50)
            + +(this.nf20 == null ? 0.0 : this.nf20 == '' ? 0.0 : this.nf20)
            + +(this.nf10 == null ? 0.0 : this.nf10 == '' ? 0.0 : this.nf10)
            + +(this.nf5 == null ? 0.0 : this.nf5 == '' ? 0.0 : this.nf5)
            + +(this.nf2 == null ? 0.0 : this.nf2 == '' ? 0.0 : this.nf2)
            + +(this.nf1 == null ? 0.0 : this.nf1 == '' ? 0.0 : this.nf1);
        //   console.log( totAmtInCash );


        if (this.showCashLblAmt) {
            this.lblTotCashAmtSummaryValue = this.getCashValDto.grandTotal;

        } else {
            this.lblTotCashAmtSummaryValue = totAmtInCash;
            //    console.log( this.lblTotCashAmtSummaryValue );
        }



    }
    // modified the logic, all the entries will be store in temp
    // dto(userDataDtosLessTemp) and will only go to DB when click on finish,
    // hence disabled the below service
    // @Dated : 10/09/2018
    updateLessDetails() {
        this.getLessLRDetails();
        swal({
            title: "Memo Less",
            text: "Less details updated temporarily, please note that it will only be save permanently when click on Finish",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
        });
        //Pending
        this.clearLessFields();
    }
    clearLessFields() {
        this.isEdit = false;
        this.viewNewBankName = false;
        $("#" + this.pageId + "chequeNumber").val('');
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.selectedConsigneeId = 0;
        this.controlConsigneeBankName.reset();
        this.controlConsigneeName.reset();
        $("#" + this.pageId + "totalAmountForPayment").val('');
        $("#" + this.pageId + "chequeDate").val('');
        $("#" + this.pageId + "referenceNumber").val('');
        $("#" + this.pageId + "paymentLessAmount").val('');
        $("#" + this.pageId + "memoNumberForManualLessPayment").val('');
        this.payMode = null;
        this.payType = null;
        $("#" + this.pageId + "entryLessAmount").val('');
        $("#" + this.pageId + "lessType").val('');
        $("#" + this.pageId + "memoNumberForManualLess").val('');
        this.editTableId = null;
        this.editTableIdForPayment = null;
        this.controlEntryLessType.reset();
        $("#" + this.pageId + "consigneeName").val('');
        $("#" + this.pageId + "totalLess").val('');
        this.controlConsigneeNamePayment.reset();
        this.paymentAmountEntriesDataList = [];
        $("#" + this.pageId + "consigneeNameForPayment").val('');
        this.selectedConsigneeId = 0;
        this.modelConsigneeNameForPayment = '';
        this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
        this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
        this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
        this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
        this.newAttributeForPayment = {};
        //Pending still the less page values need to cleared 
        //        listStoreCompleteLrs.removeAll();
        //        gridCompleteLrs.reconfigure(listStoreCompleteLrs, cmCompleteLrs);
        //        listStoreCompleteLessLrs.removeAll();
        //        gridCompleteLessLrs.reconfigure(listStoreCompleteLessLrs,
        //                cmCompleteLessLrs);
    }

    getLessLRDetails() {
        //Pending
        if (this.isLessClicked) {
            this.userDataDtosLessTemp = [];
            this.isLessClicked = false;
        }

        this.userDataDtoForLess = [];
        //        this.userDataDtoListForLess = [];
        //this.userDataDtosLessTemp = [];

        for (let i = 0; i < this.lessEntriesDataTableDataList.length; i++) {
            let maintenance = this.lessEntriesDataTableDataList[i];
            //      console.log( maintenance );
            this.userDataDtoForLessDetails = new UserDataDto();
            this.userDataDtoForLessDetails.memoNumber = maintenance.chqType;
            this.userDataDtoForLessDetails.paymentType = maintenance.chqNumber;
            this.userDataDtoForLessDetails.paymentAmt = maintenance.chqAmount;
            this.userDataDtoForLessDetails.updatedBy = this.userDataDtoReturnSession.userId;
            this.userDataDtoForLessDetails.bankId = 0;
            this.userDataDtoForLessDetails.office = this.userDataDtoReturnSession.mainStation;
            this.userDataDtoForLessDetails.amount = maintenance.cashMemoAmt;
            this.userDataDtoForLessDetails.consigneeId = maintenance.consigneeId;
            this.userDataDtoForLessDetails.partyName = maintenance.consigneeName;
            this.userDataDtoForLessDetails.mainStation = maintenance.mainStation;
            this.userDataDtoForLess.push(this.userDataDtoForLessDetails);
            //Pending need to check this below new dto list no need of this object userDataDtoListForLess may delete this 
            //            this.userDataDtoListForLess.push( this.userDataDtoForLessDetails );
            // add the entry details in temp file
            this.userDataDtosLessTemp.push(this.userDataDtoForLessDetails);
        }
        //    console.log( this.userDataDtoForLess );
        //  console.log( this.userDataDtosLessTemp );
        $("#" + this.pageId + "consigneeNameForPayment").val('');
        this.selectedConsigneeId = 0;
        this.modelConsigneeNameForPayment = '';
        this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
        this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
        this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
        this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
        this.newAttributeForPayment = {};
        return this.userDataDtoForLess;
    }

    validateTotalLessEnteredWithActulLessOnNext() {
        this.enteredTotalLess = $("#" + this.pageId + "totalLess").val();

        let totalAmt = this.enteredTotalLess == null ? 0.0 : this.enteredTotalLess == '' ? 0.0 : this.enteredTotalLess;
        let enteredAmt = 0.0;
        for (let i = 0; i < this.lessEntriesDataTableDataList.length; i++) {
            enteredAmt = +enteredAmt + +this.lessEntriesDataTableDataList[i].chqAmount;
        }
        if (enteredAmt < totalAmt) {
            swal({
                title: "Not Allowed",
                text: "Total entered less entries amount are not tallied with the Total Less, please verify!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            return true;
        } else {
            return false;
        }
    }
    validateNextBtn(e) {
        this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
        if (this.nextEntryType == 'Less Entry') {

            //            if ( this.lessEntriesDataTableDataList.length == 0 ) {
            //                swal( {
            //                    title: "Not Allowed",
            //                    text: "No Less entries found to proceed",
            //                    icon: "warning",
            //                    closeOnClickOutside: false,
            //                    closeOnEsc: false,
            //                } );
            //            } else
            if (this.validateTotalLessEnteredWithActulLessOnNext()) {

            } else {
                swal({
                    title: "Confirm Changes",
                    text: "Sure you want to add the Less details",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((next) => {
                    if (next) {
                        swal({
                            title: "OK",
                            text: "Please Click Next button again to continue",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.viewNextBtn = true;
                        this.showLessLbl = false;
                    }

                });
            }
        } else if (this.nextEntryType == 'Payment Entry') {

            if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cash') {
                swal({
                    title: "Confirm Changes",
                    text: "Sure you want to add the Cash Payment details",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    buttons: ["No", "Yes"],
                }).then((cash) => {
                    if (cash) {
                        this.updateCashAmt();
                        this.calculateTotalCashPayment();
                        // calculate total
                        this.calculateAllTotalAndBalance();
                    }

                });
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                if (this.paymentAmountEntriesDataList.length == 0) {
                    swal({
                        title: "Not Allowed",
                        text: "No Cheque/PDC Payment entries found to proceed",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    swal({
                        title: "Confirm Changes",
                        text: "Sure you want to add the Cheque/PDC Payment details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((check) => {
                        if (check) {
                            this.updateChqDetails('Cheque/PDC');
                            this.calculateAllTotalAndBalance();
                            //Pending
                            this.updateConsigneeBalanceDetails();
                        }

                    });
                }
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                if (this.paymentAmountEntriesDataList.length == 0) {
                    swal({
                        title: "Not Allowed",
                        text: "No NEFT/IMPS Payment entries found to proceed",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    swal({
                        title: "Confirm Changes",
                        text: "Sure you want to add the NEFT/IMPS Payment details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((check) => {
                        if (check) {
                            this.updateNeftDetails('NEFT/IMPS');
                            this.calculateAllTotalAndBalance();
                            //Pending
                            this.updateConsigneeBalanceDetails();
                        }

                    });
                }
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'debitacc') {
                if (this.paymentAmountEntriesDataList.length == 0) {
                    swal({
                        title: "Not Allowed",
                        text: "No Debit A/c Payment entries found to proceed",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    swal({
                        title: "Confirm Changes",
                        text: "Sure you want to add the Debit A/c Payment details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((check) => {
                        if (check) {
                            this.updateNeftDetails('Debit Account');
                            this.calculateAllTotalAndBalance();
                            //Pending
                            this.updateConsigneeBalanceDetails();
                        }

                    });
                }
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                if (this.paymentAmountEntriesDataList.length == 0) {
                    swal({
                        title: "Not Allowed",
                        text: "No UPI Payment entries found to proceed",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    swal({
                        title: "Confirm Changes",
                        text: "Sure you want to add the UPI Payment details",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                        buttons: ["No", "Yes"],
                    }).then((check) => {
                        if (check) {
                            this.updateNeftDetails('UPI');
                            this.calculateAllTotalAndBalance();
                            //Pending
                            this.updateConsigneeBalanceDetails();
                        }

                    });
                }
            }
        }
    }

    getMethodForgetPartyBankDetailsService() {
        this.userDataDtoForBankDetails = new UserDataDto();
        this.userDataDtoForBankDetails.office = this.userDataDtoReturnSession.mainStation;
        this.userDataDtoForBankDetails.companyId = this.userDataDtoReturnSession.companyId;
    }

    getPartyBankDetailsService() {
        this.getMethodForgetPartyBankDetailsService();
        this.masterReadService.getPartyBankDetailsService(this.userDataDtoForBankDetails).subscribe(
            (response) => {
                this.controlConsigneeBankName.reset();
                this.consigneeBankNameArray = [];
                this.consigneeBankNameArray = response;
                this.consigneeBankNameArrayRet = this.consigneeBankNameArray;
                this.userDataDtoForBankDetailsRet.bankName = "ADD NEW BANK";
                this.consigneeBankNameArrayRet.push(this.userDataDtoForBankDetailsRet);
                this.consigneeBankNameTA = [];
                for (let i = 0; i < this.consigneeBankNameArrayRet.length; i++) {
                    this.consigneeBankNameTA.push(this.consigneeBankNameArrayRet[i].bankName);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "Problem occur while getting Party Bank Details",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    //    partyBankMethod( e ) {
    //        if ( e.keyCode == 13 ) {
    //            this.selectedBankName = $("#"+this.pageId+"consigneeBankName" ).val();
    //            if ( this.selectedBankName == 'ADD NEW BANK' ) {
    //                this.viewNewBankName = true;
    //                $("#pmtfcnewBankName" ).focus();
    //            } else {
    //                this.viewNewBankName = false;
    //                this.validateDuplicateChqEntry();
    //                //            this.selectedPaymentMode = $("#"+this.pageId+"paymentMode" ).val();
    //                //            this.enteredCheckNumber = $("#"+this.pageId+"chequeNumber" ).val();
    //                //            this.selectedBankName = option.bankName;
    //                //            if ( this.isDuplicateChq == true ) {
    //                //                swal( {
    //                //                    title: "Not Allowed",
    //                //                    text: "Cheque Number " + this.enteredCheckNumber + " with Bank Name " + this.selectedBankName + " already exist, please verify!",
    //                //                    icon: "warning",
    //                //                    closeOnClickOutside: false,
    //                //                    closeOnEsc: false,
    //                //                } );
    //                //                $("#"+this.pageId+"chequeNumber" ).val( '' );
    //                //            }else{
    //                //                if ( this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque' ) {
    //                //                    window.setTimeout( function() {
    //                //                        $("#pmtfcpaymentLessStn" ).focus();
    //                //                    }, 100 );
    //                //                } else if ( this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft' ) {
    //                //                    window.setTimeout( function() {
    //                //                        $("#pmtfcreferenceNumber" ).focus();
    //                //                    }, 100 );
    //                //                }
    //                //            }
    //
    //
    //            }
    //        }
    //    }
    partyBankMethod(e: NgbTypeaheadSelectItemEvent) {
        this.modelPartyBankName = e.item;
        this.selectedBankName = this.modelPartyBankName;
        if (this.selectedBankName == 'ADD NEW BANK') {
            this.viewNewBankName = true;
            $("#pmtfcnewBankName").focus();
        } else {
            this.viewNewBankName = false;
            this.validateDuplicateChqEntry();
        }

    }
    validateDuplicateChqEntry = () => {
        this.enteredCheckNumber = $("#" + this.pageId + "chequeNumber").val();
        //        this.selectedBankName = option.bankName;
        //        this.selectedBankName = $("#"+this.pageId+"consigneeBankName" ).val();
        this.selectedBankName = this.modelPartyBankName;
        this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
        this.enteredCheckNumber = $("#" + this.pageId + "chequeNumber").val();
        this.lrDtoForValidateChq = new LRDto();
        this.lrDtoForValidateChq.chequeNumber = this.enteredCheckNumber;
        this.lrDtoForValidateChq.bankName = this.selectedBankName;
        this.memoService.validateChqNumberWithBank(this.lrDtoForValidateChq).subscribe(
            (response) => {
                //                console.log(response);
                if (response != null) {
                    if (response.length == 0) {
                        swal({
                            title: "Server Error",
                            text: "Server problem occurred while Validating Cheque number",
                            icon: "error",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    } else {
                        this.validateChqRet = response;
                        if (this.validateChqRet.size > 0) {
                            this.isDuplicateChq = true;
                            //                        this.selectedPaymentMode = $("#"+this.pageId+"paymentMode" ).val();
                            //                        this.enteredCheckNumber = $("#"+this.pageId+"chequeNumber" ).val();
                            //                        this.selectedBankName = option.bankName;
                            //                        this.selectedBankName = $("#"+this.pageId+"consigneeBankName" ).val();
                            if (this.isDuplicateChq == true) {
                                swal({
                                    title: "Not Allowed",
                                    text: "Cheque Number " + this.enteredCheckNumber + " with Bank Name " + this.selectedBankName + " already exist, please verify!",
                                    icon: "warning",
                                    closeOnClickOutside: false,
                                    closeOnEsc: false,
                                });
                                $("#" + this.pageId + "chequeNumber").val('');
                                //                            this.getPartyBankDetailsService()
                            }

                        } else {
                            this.isDuplicateChq = false;

                        }
                        // console.log( this.isDuplicateChq );
                        // console.log( this.selectedPaymentMode );
                        if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                            window.setTimeout(function () {
                                //                            $("#pmtfcpaymentLessStn" ).focus();
                                $("#pmtfcmemoNumberForManualLessPayment").focus();
                            }, 100);

                        } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                            window.setTimeout(function () {
                                $("#pmtfcreferenceNumber").focus();
                            }, 100);

                        }
                    }
                } else {
                    this.isDuplicateChq = false;
                    if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                        window.setTimeout(function () {
                            //                            $("#pmtfcpaymentLessStn" ).focus();
                            $("#pmtfcmemoNumberForManualLessPayment").focus();
                        }, 100);

                    } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                        window.setTimeout(function () {
                            $("#pmtfcreferenceNumber").focus();
                        }, 100);

                    }
                }

                this.changeDetectorRef.detectChanges();
            }), (error) => console.log(error.json()),
            () => console.log('done');
        //        return this.isDuplicateChq;

    };
    fieldFocusNewBank(e) {
        this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
        if (e.keyCode == 13) {
            if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                window.setTimeout(function () {
                    $("#pmtfcpaymentLessStn").focus();
                }, 100);
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                window.setTimeout(function () {
                    $("#pmtfcreferenceNumber").focus();
                }, 100);
            }
        }
    }
    //    focusChqDate(e) {
    //       
    //        if ( e.keyCode == 13 ) {
    //            window.setTimeout( function() {
    ////                $("#pmtfcconsigneeBankName" ).focus();
    //            }, 100 );
    //        }
    //    }
    focusChqDate(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $("#pmtfcconsigneeBankName").focus();
            }, 100);
        }
    }

    focusChqDateEnter(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $("#pmtfcconsigneeBankName").focus();
            }, 100);
        }
    }








    rowSelectedLessType() {

        window.setTimeout(function () {
            $("#pmtfcentryLessAmount").focus();
        }, 100);
    }
    fieldFocusChqNo(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                $("#pmtfcchequeDate").focus();
            }, 100);
        }
    }
    fieldFocusRef(e) {
        if (e.keyCode == 13) {
            window.setTimeout(function () {
                //                $("#pmtfcpaymentLessStn" ).focus();
                $("#pmtfcmemoNumberForManualLessPayment").focus();
            }, 100);
        }
    }
    validateCashWithAmount() {
        let totAmt = 0.0;
        this.nf2000 = $("#" + this.pageId + "twoThousandRupee").val();
        this.nf500 = $("#" + this.pageId + "fiveHundredRupee").val();
        this.nf200 = $("#" + this.pageId + "twoHundredRupee").val();
        this.nf100 = $("#" + this.pageId + "hundredRupee").val();
        this.nf50 = $("#" + this.pageId + "fiftyRupee").val();
        this.nf20 = $("#" + this.pageId + "twentyRupee").val();
        this.nf10 = $("#" + this.pageId + "tenRupee").val();
        this.nf5 = $("#" + this.pageId + "fiveRupee").val();
        this.nf2 = $("#" + this.pageId + "twoRupee").val();
        this.nf1 = $("#" + this.pageId + "oneRupee").val();
        //   console.log( this.nf2000 );
        totAmt = +(this.nf2000 == null ? 0.0 : this.nf2000 == '' ? 0.0 : this.nf2000)
            + +(this.nf500 == null ? 0.0 : this.nf500 == '' ? 0.0 : this.nf500)
            + +(this.nf200 == null ? 0.0 : this.nf200 == '' ? 0.0 : this.nf200)
            + +(this.nf100 == null ? 0.0 : this.nf100 == '' ? 0.0 : this.nf100)
            + +(this.nf50 == null ? 0.0 : this.nf50 == '' ? 0.0 : this.nf50)
            + +(this.nf20 == null ? 0.0 : this.nf20 == '' ? 0.0 : this.nf20)
            + +(this.nf10 == null ? 0.0 : this.nf10 == '' ? 0.0 : this.nf10)
            + +(this.nf5 == null ? 0.0 : this.nf5 == '' ? 0.0 : this.nf5)
            + +(this.nf2 == null ? 0.0 : this.nf2 == '' ? 0.0 : this.nf2)
            + +(this.nf1 == null ? 0.0 : this.nf1 == '' ? 0.0 : this.nf1);
        //    console.log( totAmt );
        this.enteredTotalLess = $("#" + this.pageId + "totalAmountForPayment").val();
        this.enteredTotalLess = this.enteredTotalLess == null ? 0.0 : this.enteredTotalLess == '' ? 0.0 : this.enteredTotalLess;
        //    console.log( totAmt, this.enteredTotalLess );
        if (totAmt > this.enteredTotalLess) {
            swal({
                title: "Not Allowed",
                text: "Entered Cash amount exceed from the Total Amount, please verify!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            return true;
        } else {
            return false;
        }
    }

    calculateDenominationTotalAmt() {
        let totAmt = 0.0;
        this.nf2000 = $("#" + this.pageId + "twoThousandRupee").val();
        this.nf500 = $("#" + this.pageId + "fiveHundredRupee").val();
        this.nf200 = $("#" + this.pageId + "twoHundredRupee").val();
        this.nf100 = $("#" + this.pageId + "hundredRupee").val();
        this.nf50 = $("#" + this.pageId + "fiftyRupee").val();
        this.nf20 = $("#" + this.pageId + "twentyRupee").val();
        this.nf10 = $("#" + this.pageId + "tenRupee").val();
        this.nf5 = $("#" + this.pageId + "fiveRupee").val();
        this.nf2 = $("#" + this.pageId + "twoRupee").val();
        this.nf1 = $("#" + this.pageId + "oneRupee").val();
        //   console.log( this.nf2000 );
        totAmt = +(this.nf2000 == null ? 0.0 : this.nf2000 == '' ? 0.0 : this.nf2000)
            + +(this.nf500 == null ? 0.0 : this.nf500 == '' ? 0.0 : this.nf500)
            + +(this.nf200 == null ? 0.0 : this.nf200 == '' ? 0.0 : this.nf200)
            + +(this.nf100 == null ? 0.0 : this.nf100 == '' ? 0.0 : this.nf100)
            + +(this.nf50 == null ? 0.0 : this.nf50 == '' ? 0.0 : this.nf50)
            + +(this.nf20 == null ? 0.0 : this.nf20 == '' ? 0.0 : this.nf20)
            + +(this.nf10 == null ? 0.0 : this.nf10 == '' ? 0.0 : this.nf10)
            + +(this.nf5 == null ? 0.0 : this.nf5 == '' ? 0.0 : this.nf5)
            + +(this.nf2 == null ? 0.0 : this.nf2 == '' ? 0.0 : this.nf2)
            + +(this.nf1 == null ? 0.0 : this.nf1 == '' ? 0.0 : this.nf1);
        //    console.log( totAmt );
        return totAmt;
    }

    twoThousandRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf2000Count = $("#" + this.pageId + "twoThousandRupeeCount").val();
            this.nf2000Count = this.nf2000Count == null ? 0 : this.nf2000Count == '' ? 0 : this.nf2000Count;
            $("#" + this.pageId + "twoThousandRupee").val(this.nf2000Count * 2000);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "twoThousandRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfcfiveHundredRupeeCount").focus();
                }, 100);

            }

        }
    }
    twoThousandRupeeBlur(e) {
        this.nf2000Count = $("#" + this.pageId + "twoThousandRupeeCount").val();
        this.nf2000Count = this.nf2000Count == null ? 0 : this.nf2000Count == '' ? 0 : this.nf2000Count;
        $("#" + this.pageId + "twoThousandRupee").val(this.nf2000Count * 2000);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "twoThousandRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        }
    }

    fiveHundredRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf500Count = $("#" + this.pageId + "fiveHundredRupeeCount").val();
            this.nf500Count = this.nf500Count == null ? 0 : this.nf500Count == '' ? 0 : this.nf500Count;
            $("#" + this.pageId + "fiveHundredRupee").val(this.nf500Count * 500);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "fiveHundredRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfctwoHundredRupeeCount").focus();
                }, 100);

            }

        }
    }
    fiveHundredRupeeBlur(e) {
        this.nf500Count = $("#" + this.pageId + "fiveHundredRupeeCount").val();
        this.nf500Count = this.nf500Count == null ? 0 : this.nf500Count == '' ? 0.0 : this.nf500Count;
        $("#" + this.pageId + "fiveHundredRupee").val(this.nf500Count * 500);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "fiveHundredRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);


        }
    }

    twoHundredRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf200Count = $("#" + this.pageId + "twoHundredRupeeCount").val();
            this.nf200Count = this.nf200Count == null ? 0 : this.nf200Count == '' ? 0 : this.nf200Count;
            $("#" + this.pageId + "twoHundredRupee").val(this.nf200Count * 200);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "twoHundredRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfchundredRupeeCount").focus();
                }, 100);

            }

        }
    }
    twoHundredRupeeBlur(e) {
        this.nf200Count = $("#" + this.pageId + "twoHundredRupeeCount").val();
        this.nf200Count = this.nf200Count == null ? 0 : this.nf200Count == '' ? 0 : this.nf200Count;
        $("#" + this.pageId + "twoHundredRupee").val(this.nf200Count * 200);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "twoHundredRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);

        }
    }

    hundredRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf100Count = $("#" + this.pageId + "hundredRupeeCount").val();
            this.nf100Count = this.nf100Count == null ? 0 : this.nf100Count == '' ? 0 : this.nf100Count;
            $("#" + this.pageId + "hundredRupee").val(this.nf100Count * 100);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "hundredRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfcfiftyRupeeCount").focus();
                }, 100);

            }

        }
    }
    hundredRupeeBlur(e) {
        this.nf100Count = $("#" + this.pageId + "hundredRupeeCount").val();
        this.nf100Count = this.nf100Count == null ? 0 : this.nf100Count == '' ? 0 : this.nf100Count;
        $("#" + this.pageId + "hundredRupee").val(this.nf100Count * 100);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "hundredRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);


        }
    }

    fiftyRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf50Count = $("#" + this.pageId + "fiftyRupeeCount").val();
            this.nf50Count = this.nf50Count == null ? 0 : this.nf50Count == '' ? 0 : this.nf50Count;
            $("#" + this.pageId + "fiftyRupee").val(this.nf50Count * 50);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "fiftyRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfctwentyRupeeCount").focus();
                }, 100);

            }

        }
    }
    fiftyRupeeBlur(e) {
        this.nf50Count = $("#" + this.pageId + "fiftyRupeeCount").val();
        this.nf50Count = this.nf50Count == null ? 0 : this.nf50Count == '' ? 0 : this.nf50Count;
        $("#" + this.pageId + "fiftyRupee").val(this.nf50Count * 50);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "fiftyRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            window.setTimeout(function () {
                $("#pmtfctwentyRupeeCount").focus();
            }, 100);

        }
    }

    twentyRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf20Count = $("#" + this.pageId + "twentyRupeeCount").val();
            this.nf20Count = this.nf20Count == null ? 0 : this.nf20Count == '' ? 0 : this.nf20Count;
            $("#" + this.pageId + "twentyRupee").val(this.nf20Count * 20);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "twentyRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfctenRupeeCount").focus();
                }, 100);

            }

        }
    }
    twentyRupeeBlur(e) {
        this.nf20Count = $("#" + this.pageId + "twentyRupeeCount").val();
        this.nf20Count = this.nf20Count == null ? 0 : this.nf20Count == '' ? 0 : this.nf20Count;
        $("#" + this.pageId + "twentyRupee").val(this.nf20Count * 20);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "twentyRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);


        }
    }

    tenRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf10Count = $("#" + this.pageId + "tenRupeeCount").val();
            this.nf10Count = this.nf10Count == null ? 0 : this.nf10Count == '' ? 0 : this.nf10Count;
            $("#" + this.pageId + "tenRupee").val(this.nf10Count * 10);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "tenRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfcfiveRupeeCount").focus();
                }, 100);

            }

        }
    }
    tenRupeeBlur(e) {
        this.nf10Count = $("#" + this.pageId + "tenRupeeCount").val();
        this.nf10Count = this.nf10Count == null ? 0 : this.nf10Count == '' ? 0 : this.nf10Count;
        $("#" + this.pageId + "tenRupee").val(this.nf10Count * 10);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "tenRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            window.setTimeout(function () {
                $("#pmtfcfiveRupeeCount").focus();
            }, 100);

        }
    }

    fiveRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf5Count = $("#" + this.pageId + "fiveRupeeCount").val();
            this.nf5Count = this.nf5Count == null ? 0 : this.nf5Count == '' ? 0 : this.nf5Count;
            $("#" + this.pageId + "fiveRupee").val(this.nf5Count * 5);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "fiveRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfctwoRupeeCount").focus();
                }, 100);

            }

        }
    }
    fiveRupeeBlur(e) {
        this.nf5Count = $("#" + this.pageId + "fiveRupeeCount").val();
        this.nf5Count = this.nf5Count == null ? 0 : this.nf5Count == '' ? 0 : this.nf5Count;
        $("#" + this.pageId + "fiveRupee").val(this.nf5Count * 5);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "fiveRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        }
    }


    twoRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf2Count = $("#" + this.pageId + "twoRupeeCount").val();
            this.nf2Count = this.nf2Count == null ? 0 : this.nf2Count == '' ? 0 : this.nf2Count;
            $("#" + this.pageId + "twoRupee").val(this.nf2Count * 2);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "twoRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
                window.setTimeout(function () {
                    $("#pmtfconeRupeeCount").focus();
                }, 100);

            }

        }
    }
    twoRupeeBlur(e) {
        this.nf2Count = $("#" + this.pageId + "twoRupeeCount").val();
        this.nf2Count = this.nf2Count == null ? 0 : this.nf2Count == '' ? 0 : this.nf2Count;
        $("#" + this.pageId + "twoRupee").val(this.nf2Count * 2);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "twoRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        }
    }

    oneRupeeEvent(e) {
        if (e.keyCode == 13) {
            this.nf1Count = $("#" + this.pageId + "oneRupeeCount").val();
            this.nf1Count = this.nf1Count == null ? 0 : this.nf1Count == '' ? 0 : this.nf1Count;
            $("#" + this.pageId + "oneRupee").val(this.nf1Count * 1);
            if (this.validateCashWithAmount()) {
                $("#" + this.pageId + "oneRupee").val('');
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
            } else {
                let calculatedTotalAmt = this.calculateDenominationTotalAmt();
                $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);

            }

        }
    }
    oneRupeeBlur(e) {
        this.nf1Count = $("#" + this.pageId + "oneRupeeCount").val();
        this.nf1Count = this.nf1Count == null ? 0 : this.nf1Count == '' ? 0 : this.nf1Count;
        $("#" + this.pageId + "oneRupee").val(this.nf1Count * 1);
        if (this.validateCashWithAmount()) {
            $("#" + this.pageId + "oneRupee").val('');
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);
        } else {
            let calculatedTotalAmt = this.calculateDenominationTotalAmt();
            $("#" + this.pageId + "totalRupeeDenomination").val(calculatedTotalAmt);

        }
    }
    //    paymentSpecifcValidations( keyEnter ) {
    //        let enteredAmt = 0.0;
    //        let clickedRowAmt = 0.0;
    //        this.enteredTotalLess = $("#"+this.pageId+"totalAmountForPayment" ).val();
    //        this.enteredTotalLess = this.enteredTotalLess == null ? 0.0 : this.enteredTotalLess == '' ? 0.0 : this.enteredTotalLess;
    //        this.enteredLessAmt = $("#"+this.pageId+"paymentLessAmount" ).val();
    //        this.enteredLessAmt = this.enteredLessAmt == null ? 0.0 : this.enteredLessAmt == '' ? 0.0 : this.enteredLessAmt;
    //        let totalAmt = this.enteredTotalLess;
    //        let memoNum = null;
    //        this.selectedEntryType = $("#"+this.pageId+"entryLessPaymentEntry" ).val();
    //        this.selectedCodeForManual = $("#"+this.pageId+"codeForManualLessPayment" ).val();
    //        this.selectedMemoNumberForManual = $("#"+this.pageId+"memoNumberForManualLessPayment" ).val();
    //        this.selectedMemoNumberForAutomatic = $("#"+this.pageId+"memoNumberForAutomaticLessPayment" ).val();
    //        this.selectedConsignee = $("#"+this.pageId+"consigneeNameForPayment" ).val();
    //        if ( this.selectedEntryType == 'paymentLessAutomatic' ) {
    //            memoNum = this.selectedMemoNumberForAutomatic;
    //            console.log( memoNum );
    //        } else {
    //            memoNum = this.selectedCodeForManual + "" + this.selectedMemoNumberForManual;
    //            console.log( memoNum );
    //        }
    //
    //        if ( this.isEdit ) {
    //            this.clickedRowAmt = this.maintenanceCompletedLrs.chqAmount;
    //        }
    //        for ( let i = 0; i < this.paymentAmountEntriesDataList.length; i++ ) {
    //            enteredAmt = +enteredAmt + +this.paymentAmountEntriesDataList[i].chqAmount;
    //        }
    //
    //        enteredAmt = +enteredAmt + +this.enteredLessAmt;
    //        console.log( enteredAmt, totalAmt );
    //        // deduct the clicked row when edit
    //        enteredAmt = +enteredAmt - +this.clickedRowAmt;
    //        console.log( enteredAmt );
    //
    //        if ( enteredAmt > totalAmt ) {
    //            swal( {
    //                title: "Not Allowed",
    //                text: "Total entered amount exceed from the actual Amount, please verify!",
    //                icon: "warning",
    //                closeOnClickOutside: false,
    //                closeOnEsc: false,
    //            } );
    //
    //        } else {
    //            this.totalBalAmt = this.balanceAmountFinal;
    //
    //            if ( enteredAmt > this.totalBalAmt ) {
    //                swal( {
    //                    title: "Not Allowed",
    //                    text: "Amount exceeded from the actual Bill Amount, please verify!",
    //                    icon: "warning",
    //                    closeOnClickOutside: false,
    //                    closeOnEsc: false,
    //                } );
    //                $("#"+this.pageId+"paymentLessAmount" ).val( '' );
    //                $("#pmtfcpaymentLessAmount" ).focus();
    //
    //            } else {
    //                this.insertRowPaymentEntry( memoNum, keyEnter );
    //            }
    //        }
    //
    //
    //    }
    paymentSpecifcValidations(keyEnter) {

        let enteredAmt = 0.0;
        let clickedRowAmt = 0.0;
        this.enteredTotalLess = $("#" + this.pageId + "totalAmountForPayment").val();
        this.enteredTotalLess = this.enteredTotalLess == null ? 0.0 : this.enteredTotalLess == '' ? 0.0 : this.enteredTotalLess;
        this.enteredLessAmt = $("#" + this.pageId + "paymentLessAmount").val();
        this.enteredLessAmt = this.enteredLessAmt == null ? 0.0 : this.enteredLessAmt == '' ? 0.0 : this.enteredLessAmt;
        let totalAmt = this.enteredTotalLess;
        let memoNum = null;
        this.selectedEntryType = $("#" + this.pageId + "entryLessPaymentEntry").val();
        this.selectedCodeForManual = $("#" + this.pageId + "codeForManualLessPayment").val();
        this.selectedMemoNumberForManual = $("#" + this.pageId + "memoNumberForManualLessPayment").val();
        this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val();
        this.selectedConsignee = $("#" + this.pageId + "consigneeNameForPayment").val();
        if (this.selectedEntryType == 'paymentLessAutomatic') {
            memoNum = this.selectedMemoNumberForAutomatic;
            //       console.log( memoNum );
        } else {
            memoNum = this.selectedCodeForManual + "" + this.selectedMemoNumberForManual;
            //        console.log( memoNum );
        }
        if (this.enteredLessAmt == '' || this.enteredLessAmt == null || this.enteredLessAmt == 0.0) {

            this.insertRowPaymentEntry(memoNum, keyEnter);
        } else {


            if (this.isEdit) {
                this.clickedRowAmt = this.maintenanceCompletedLrs.chqAmount;
            }
            for (let i = 0; i < this.paymentAmountEntriesDataList.length; i++) {
                enteredAmt = +enteredAmt + +this.paymentAmountEntriesDataList[i].chqAmount;
            }

            enteredAmt = +enteredAmt + +this.enteredLessAmt;
            //    console.log( enteredAmt, totalAmt );
            // deduct the clicked row when edit
            enteredAmt = +enteredAmt - +this.clickedRowAmt;
            //   console.log( enteredAmt );
            if (this.isPaymentClicked) {
                if (enteredAmt > totalAmt) {
                    swal({
                        title: "Not Allowed",
                        text: "Total entered amount exceed from the actual Amount, please verify!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.insertRowPaymentEntry(memoNum, keyEnter);

                }

            } else {
                if (enteredAmt > totalAmt) {
                    swal({
                        title: "Not Allowed",
                        text: "Total entered amount exceed from the actual Amount, please verify!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.totalBalAmt = this.balanceAmountFinal;

                    if (enteredAmt > this.totalBalAmt) {
                        swal({
                            title: "Not Allowed",
                            text: "Amount exceeded from the actual Bill Amount, please verify!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        $("#" + this.pageId + "paymentLessAmount").val('');
                        $("#pmtfcpaymentLessAmount").focus();

                    } else {
                        this.insertRowPaymentEntry(memoNum, keyEnter);
                    }
                }

            }

        }



    }
    insertRowPaymentEntry(memoNum, keyEnter) {
        // console.log( 'here' );
        //Pending
        //        if (this.maintenanceCompletedLrs != null) {
        //            listStoreCompleteLrs.remove(maintenanceCompletedLrs);
        //        }
        this.userDataDtoForChq = new UserDataDto();
        this.listOfMemoNumber = [];
        this.listOfMemoNumber.push(memoNum);
        this.userDataDtoForChq.list = this.listOfMemoNumber;
        this.userDataDtoForChq.memoNumber = memoNum;
        this.userDataDtoForChq.companyId = this.userDataDtoReturnSession.companyId;
        this.getLessDetails(this.userDataDtoForChq, keyEnter);

    }

    getLessDetails = (userDataDtoForChq, keyEnter) => {
        this.memoService.getMemoLessDetails(userDataDtoForChq).subscribe(
            (response) => {
                if (response.length == 0) {
                    swal({
                        title: "Server Error",
                        text: "Server problem occurred while taken back the memo into stock",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.userDataDtoForChqRet = response;
                    //                     console.log( this.userDataDtoForChqRet );
                    for (let i = 0; i < this.userDataDtoForChqRet.length; i++) {
                        if (this.userDataDtoForChqRet[i].status == 'completed') {
                            swal({
                                title: "Duplicate Entry",
                                text: "Payment entry is already done for this memo, if want to edit, click on the corresponding Total beside!",
                                icon: "error",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                            this.selectedEntryType = $("#" + this.pageId + "entryLessPaymentEntry").val();
                            this.selectedCodeForManual = $("#" + this.pageId + "codeForManualLessPayment").val();
                            this.selectedMemoNumberForManual = $("#" + this.pageId + "memoNumberForManualLessPayment").val();
                            this.selectedMemoNumberForAutomatic = $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val();
                            this.selectedConsignee = $("#" + this.pageId + "consigneeNameForPayment").val();
                            if (this.selectedEntryType == 'paymentLessAutomatic') {
                                window.setTimeout(function () {
                                    $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val('');
                                    $("#pmtfcmemoNumberForAutomaticLessPayment").focus();
                                }, 100);
                            } else {
                                window.setTimeout(function () {
                                    $("#" + this.pageId + "memoNumberForManualLessPayment").val('');
                                    $("#pmtfcmemoNumberForManualLessPayment").focus();
                                }, 100);
                            }

                        } else {
                            window.setTimeout(function () {
                                //                                $("#pmtfcpaymentLessAmount" ).focus();
                                $("#pmtfcmemoNumberForManualLessPayment").focus();
                            }, 100);


                            //                            for checking Pending added by basha bcoz after the memonumber enter key it directly added in table so i did this code to prevent adding in table
                            //                            if ( keyEnter != null && keyEnter == 'paymentLess' ) {
                            let totalLess = 0.0;
                            for (let j = 0; j < this.userDataDtosLessTemp.length; j++) {
                                if (this.userDataDtosLessTemp[j].memoNumber == this.userDataDtoForChq.memoNumber) {
                                    totalLess = +totalLess + +this.userDataDtosLessTemp[j].paymentAmt;
                                }

                            }

                            // if its edit then consider nflessamt value
                            //                   console.log( totalLess );
                            this.balanceAmtInAddingTable = +this.userDataDtoForChqRet[i].amount - +totalLess;
                            //                   console.log( this.balanceAmtInAddingTable, this.userDataDtoForChqRet[i].amount, totalLess );
                            if (this.isEdit) {
                                this.enteredLessAmt = $("#" + this.pageId + "paymentLessAmount").val();
                                this.enteredLessAmt = this.enteredLessAmt == null ? 0.0 : this.enteredLessAmt == '' ? 0.0 : this.enteredLessAmt;
                                this.balanceAmtInAddingTable = this.enteredLessAmt;
                            }
                            this.addInPaymentTable(this.userDataDtoForChq.memoNumber, totalLess, this.userDataDtoForChqRet[i].amount);
                            //                            }
                        }

                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    addInPaymentTable(memoNum, totalLess, amount) {
        this.getChequeDate = $("#" + this.pageId + "chequeDate").val();
        this.getMainStation = $("#" + this.pageId + "paymentLessStn").val();
        this.selectedBankName = $("#" + this.pageId + "consigneeBankName").val();
        this.enteredNewBankName = $("#" + this.pageId + "newBankName").val();
        this.getChequeDate = this.datePipe.transform(this.getChequeDate, "yyyy-MM-dd");
        //   console.log( this.getChequeDate );
        this.newAttributeForPayment.chqType = memoNum;
        this.newAttributeForPayment.chqNumber = "NA"
        this.newAttributeForPayment.chqAmount = totalLess;
        this.newAttributeForPayment.cashMemoAmt = amount;
        this.newAttributeForPayment.balance = this.balanceAmtInAddingTable;
        //        this.newAttributeForPayment.mainStation = "NA";
        this.newAttributeForPayment.mainStation = this.getMainStation;
        this.newAttributeForPayment.chequeno = $("#" + this.pageId + "chequeNumber").val();
        if (this.selectedBankName != '' && this.selectedBankName == 'ADD NEW BANK') {
            this.newAttributeForPayment.bankname = this.enteredNewBankName;
        } else {
            this.newAttributeForPayment.bankname = (this.selectedBankName == null ? null : this.selectedBankName == '' ? null : this.selectedBankName);
        }
        //        this.newAttributeForPayment.bankname = $("#"+this.pageId+"consigneeBankName" ).val();
        this.newAttributeForPayment.chequedate = this.getChequeDate;
        this.newAttributeForPayment.remarks = $("#" + this.pageId + "referenceNumber").val();
        this.newAttributeForPayment.consigneeId = this.selectedConsigneeId;
        this.newAttributeForPayment.consigneeName = $("#" + this.pageId + "consigneeNameForPayment").val();
        this.newAttributeForPayment.paymenttype = $("#" + this.pageId + "paymentMode").val();

        if (this.editTableIdForPayment != null) {
            this.paymentAmountEntriesDataList.splice(this.editTableIdForPayment, 1);
            this.countOfPaymentMemo = +this.countOfPaymentMemo - 1;
            this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment - +this.editMemoAmtForPayment;
            this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment - +this.editLessAmtForPayment;
            this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment - +this.editBalanceAmtForPayment;
        }


        if (this.paymentAmountEntriesDataList == null) {
            this.paymentAmountEntriesDataList = [];
        }
        //        console.log(this.setPaymentEntryDetails);
        if (this.setPaymentEntryDetails == 'new') {
            this.dtTriggerPaymentAmountEntries.next();
            this.setPaymentEntryDetails = 'old';
        }
        //        console.log(this.setPaymentEntryDetails);
        if (this.paymentAmountEntriesDataList.length == 0) {
            this.paymentAmountEntriesDataList.push(this.newAttributeForPayment);
        } else {
            this.paymentAmountEntriesDataList.push(this.newAttributeForPayment);
        }
        // console.log( this.paymentAmountEntriesDataList )
        this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
        this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
        this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
        this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;


        this.newAttributeForPayment = {};
        this.isEdit = false;
        $("#" + this.pageId + "paymentLessAmount").val('');
        $("#" + this.pageId + "lessType").val('');
        //        $("#"+this.pageId+"memoNumberForManualLessPayment" ).val( '' );
        //        $("#pmtfcmemoNumberForManualLessPayment" ).focus();
        this.selectedEntryType = $("#" + this.pageId + "entryLessPaymentEntry").val();

        if (this.selectedEntryType == 'paymentLessAutomatic') {
            $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val('');
            $("#pmtfcmemoNumberForAutomaticLessPayment").focus();
        } else {
            $("#" + this.pageId + "memoNumberForManualLessPayment").val('');
            $("#pmtfcmemoNumberForManualLessPayment").focus();
        }
        $("#" + this.pageId + "hiddenIndexForPaymentEntry").val('');
        this.editTableIdForPayment = null;
        ///Pending 
    }

    rowSelectedEditForPaymentEntry(paymentAmountEntriesData, index) {
        $("#" + this.pageId + "entryLessPaymentEntry").val('paymentLessManual');
        $("#" + this.pageId + "consigneeNameForPayment").val(paymentAmountEntriesData.consigneeName);
        //        $("#"+this.pageId+"totalAmountForPayment" ).val( paymentAmountEntriesData.chqAmount );
        $("#" + this.pageId + "chequeNumber").val(paymentAmountEntriesData.chequeno);
        $("#" + this.pageId + "chequeDate").val(paymentAmountEntriesData.chequedate);
        $("#" + this.pageId + "consigneeBankName").val(paymentAmountEntriesData.bankname);
        $("#" + this.pageId + "paymentLessAmount").val(paymentAmountEntriesData.balance);
        $("#" + this.pageId + "referenceNumber").val(paymentAmountEntriesData.remarks);
        $("#" + this.pageId + "paymentLessStn").val(paymentAmountEntriesData.mainStation);
        this.paymentLessManualView = true;
        this.paymentLessAutomaticView = false;
        $("#" + this.pageId + "paymentMode").val(paymentAmountEntriesData.paymenttype);
        //        $("#"+this.pageId+"entryLessPaymentEntry" ).val( paymentAmountEntriesData.paymenttype );
        this.selectedConsigneeId = paymentAmountEntriesData.consigneeId;
        //    console.log( this.selectedConsigneeId );
        //For manual 
        let selectedMemo = paymentAmountEntriesData.chqType;
        this.slashSplitMemo = selectedMemo.split("/");
        let destSplit = this.slashSplitMemo[0];
        let yearSplit = this.slashSplitMemo[1];
        let memoNumberSplit = this.slashSplitMemo[2];
        //    console.log( selectedMemo, this.slashSplitMemo, destSplit, yearSplit, memoNumberSplit );
        let memoNumberCombine = destSplit + "/" + yearSplit + "/";

        $("#" + this.pageId + "codeForManualLessPayment").val(memoNumberCombine);
        $("#" + this.pageId + "memoNumberForManualLessPayment").val(memoNumberSplit);
        this.modelStationForPaymentAmt = memoNumberCombine;
        //////
        //For Automatic
        $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val(paymentAmountEntriesData.chqType);
        /////

        $("#" + this.pageId + "hiddenIndexForPaymentEntry").val(index);
        this.editTableIdForPayment = index;
        ///
        this.maintenanceCompletedLrs = [];
        this.maintenanceCompletedLrs = paymentAmountEntriesData;
        //    console.log( this.maintenanceCompletedLrs );
        this.isEdit = true;
        //
        this.editMemoAmtForPayment = 0;
        this.editLessAmtForPayment = 0;
        this.editBalanceAmtForPayment = 0;
        this.editMemoAmtForPayment = paymentAmountEntriesData.cashMemoAmt;
        this.editLessAmtForPayment = paymentAmountEntriesData.chqAmount;
        this.editBalanceAmtForPayment = paymentAmountEntriesData.balance;
    }

    rowSelectedDeleteForPaymentEntry(paymentAmountEntriesData, index) {

        swal({
            title: "Confirm Remove",
            text: "Sure you want to remove the selected Payment entry",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((remove) => {
            if (remove) {
                this.paymentAmountEntriesDataList.splice(index, 1);
                this.isEdit = false;
                $("#" + this.pageId + "paymentLessAmount").val('');
                $("#" + this.pageId + "memoNumberForManualLessPayment").val('');
                $("#pmtfcmemoNumberForManualLessPayment").focus();
                this.maintenanceCompletedLrs = [];
                $("#" + this.pageId + "hiddenIndexForPaymentEntry").val('');
                this.editTableIdForPayment = null;
                this.countOfPaymentMemo = +this.countOfPaymentMemo - 1;
                this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment - +paymentAmountEntriesData.cashMemoAmt;
                this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment - +paymentAmountEntriesData.chqAmount;
                this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment - +paymentAmountEntriesData.balance;

            }

        });
    }

    updateCashAmt() {
        if (this.isPaymentClicked) {
            this.userDataDtosPaymentCashTemp = [];
            this.isPaymentClicked = false;
        }
        this.grandTotalForCash = $("#" + this.pageId + "totalRupeeDenomination").val();
        this.nf2000 = $("#" + this.pageId + "twoThousandRupee").val();
        this.nf500 = $("#" + this.pageId + "fiveHundredRupee").val();
        this.nf200 = $("#" + this.pageId + "twoHundredRupee").val();
        this.nf100 = $("#" + this.pageId + "hundredRupee").val();
        this.nf50 = $("#" + this.pageId + "fiftyRupee").val();
        this.nf20 = $("#" + this.pageId + "twentyRupee").val();
        this.nf10 = $("#" + this.pageId + "tenRupee").val();
        this.nf5 = $("#" + this.pageId + "fiveRupee").val();
        this.nf2 = $("#" + this.pageId + "twoRupee").val();
        this.nf1 = $("#" + this.pageId + "oneRupee").val();
        this.nf2000Count = $("#" + this.pageId + "twoThousandRupeeCount").val();
        this.nf500Count = $("#" + this.pageId + "fiveHundredRupeeCount").val();
        this.nf200Count = $("#" + this.pageId + "twoHundredRupeeCount").val();
        this.nf100Count = $("#" + this.pageId + "hundredRupeeCount").val();
        this.nf50Count = $("#" + this.pageId + "fiftyRupeeCount").val();
        this.nf20Count = $("#" + this.pageId + "twentyRupeeCount").val();
        this.nf10Count = $("#" + this.pageId + "tenRupeeCount").val();
        this.nf5Count = $("#" + this.pageId + "fiveRupeeCount").val();
        this.nf2Count = $("#" + this.pageId + "twoRupeeCount").val();
        this.nf1Count = $("#" + this.pageId + "oneRupeeCount").val();

        this.nf2000 = this.nf2000 == null ? 0.0 : this.nf2000 == '' ? 0.0 : this.nf2000;
        this.nf500 = this.nf500 == null ? 0.0 : this.nf500 == '' ? 0.0 : this.nf500;
        this.nf200 = this.nf200 == null ? 0.0 : this.nf200 == '' ? 0.0 : this.nf200;
        this.nf100 = this.nf100 == null ? 0.0 : this.nf100 == '' ? 0.0 : this.nf100;
        this.nf50 = this.nf50 == null ? 0.0 : this.nf50 == '' ? 0.0 : this.nf50;
        this.nf20 = this.nf20 == null ? 0.0 : this.nf20 == '' ? 0.0 : this.nf20;
        this.nf10 = this.nf10 == null ? 0.0 : this.nf10 == '' ? 0.0 : this.nf10;
        this.nf5 = this.nf5 == null ? 0.0 : this.nf5 == '' ? 0.0 : this.nf5;
        this.nf2 = this.nf2 == null ? 0.0 : this.nf2 == '' ? 0.0 : this.nf2;
        this.nf1 = this.nf1 == null ? 0.0 : this.nf1 == '' ? 0.0 : this.nf1;

        this.nf2000Count = this.nf2000Count == null ? 0 : this.nf2000Count == '' ? 0 : this.nf2000Count;
        this.nf500Count = this.nf500Count == null ? 0 : this.nf500Count == '' ? 0 : this.nf500Count;
        this.nf200Count = this.nf200Count == null ? 0 : this.nf200Count == '' ? 0 : this.nf200Count;
        this.nf100Count = this.nf100Count == null ? 0 : this.nf100Count == '' ? 0 : this.nf100Count;
        this.nf50Count = this.nf50Count == null ? 0 : this.nf50Count == '' ? 0 : this.nf50Count;
        this.nf20Count = this.nf20Count == null ? 0 : this.nf20Count == '' ? 0 : this.nf20Count;
        this.nf10Count = this.nf10Count == null ? 0 : this.nf10Count == '' ? 0 : this.nf10Count;
        this.nf5Count = this.nf5Count == null ? 0 : this.nf5Count == '' ? 0 : this.nf5Count;
        this.nf2Count = this.nf2Count == null ? 0 : this.nf2Count == '' ? 0 : this.nf2Count;
        this.nf1Count = this.nf1Count == null ? 0 : this.nf1Count == '' ? 0 : this.nf1Count;

        this.getCashValDto = new CashMemoDto();
        this.getCashValDto.nfof2000Count = this.nf2000Count;
        this.getCashValDto.nfof500Count = this.nf500Count;
        this.getCashValDto.nfof200Count = this.nf200Count;
        this.getCashValDto.nfof100Count = this.nf100Count;
        this.getCashValDto.nfof50Count = this.nf50Count;
        this.getCashValDto.nfof20Count = this.nf20Count;
        this.getCashValDto.nfof10Count = this.nf10Count;
        this.getCashValDto.nfof5Count = this.nf5Count;
        this.getCashValDto.nfof2Count = this.nf2Count;
        this.getCashValDto.nfof1Count = this.nf1Count;
        this.getCashValDto.nfOF2000 = this.nf2000;
        this.getCashValDto.nfOF500 = this.nf500;
        this.getCashValDto.nfOF200 = this.nf200;
        this.getCashValDto.nfOF100 = this.nf100;
        this.getCashValDto.nfOF50 = this.nf50;
        this.getCashValDto.nfOF20 = this.nf20;
        this.getCashValDto.nfOF10 = this.nf10;
        this.getCashValDto.nfOF5 = this.nf5;
        this.getCashValDto.nfOF2 = this.nf2;
        this.getCashValDto.nfOF1 = this.nf1;
        this.getCashValDto.grandTotal = this.grandTotalForCash;
        this.userDataDtosPaymentCashTemp.push(this.getCashValDto);
        //    console.log( this.userDataDtosPaymentCashTemp );
        this.showCashLblAmt = true;
        swal({
            title: "Memo Payment",
            text: "Payment details updated temporarily, please note that it will only be save permanently when click on Finish",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
        });
        //        this.resetPaymentMode();
        //        this.clearCompletePopupCashFields();
    }

    updateChqDetails(type) {
        //        console.log( this.payType, this.payMode );
        if (this.payType == '' || this.payType == null || this.payMode == '' || this.payMode == null) {
            swal({
                title: "Warning",
                text: "Payment Type or PaymentMmode is missing for lr's",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
            //PFCHG_V5
            if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cash') {
                this.payType = "Cash";
                this.payMode = "completedcash";

            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                this.payType = "Cheque";
                this.payMode = "completedcheque";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                this.payType = "NEFT/IMPS";
                this.payMode = "completedneft";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'debitacc') {
                this.payType = "Debit Account";
                this.payMode = "completeddebitacc";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                this.payType = "UPI";
                this.payMode = "upi";
            }
        } else {
            this.getChqAndNeftLRDetails(type);
            this.selectedBankName = $("#" + this.pageId + "consigneeBankName").val();
            // add new bank name
            if (this.selectedBankName != '' && this.selectedBankName == 'ADD NEW BANK') {
                this.addBankNames();
            } else {
                this.clearCompletePopupFieldsOnTypeChange();
            }
            this.resetPaymentMode();
            swal({
                title: "Memo Payment",
                text: "Payment details updated temporarily, please note that it will only be save permanently when click on Finish",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }


    }

    resetPaymentMode() {
        this.viewByCash = false;
        this.viewByCashs = false;
        this.viewByChequeNumber = false;
        this.viewByChequeDate = false;
        this.viewByConsigneeBankName = false;
        this.viewByReferenceNumber = false;
        this.viewByConsigneeName = false;
        this.showNextBtn = true;
        this.payType = "";
        this.payMode = "";
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.selectedConsigneeId = 0;
        this.controlConsigneeBankName.reset();
        this.showForCheckandNeft = false;
        this.viewNewBankName = false;
        $("#" + this.pageId + "paymentMode").val('select');
        //        this.dtTriggerPaymentAmountEntries.unsubscribe();
        //        this.paymentEntriesDataTable();
    }
    addBankNames() {
        this.enteredNewBankName = $("#" + this.pageId + "newBankName").val();
        this.userDataDtoForBankName = new UserDataDto();
        this.userDataDtoForBankName.bankId = 0;
        this.userDataDtoForBankName.bankName = this.enteredNewBankName;
        this.userDataDtoForBankName.office = this.userDataDtoReturnSession.mainStation;
        this.userDataDtoForBankName.companyId = this.userDataDtoReturnSession.companyId;
        this.userDataDtoForBankName.createdBy = this.userDataDtoReturnSession.userId;
        this.addBankDetails();
    }

    //    addBankDetails = () => {
    //        this.memoService.addPartyBankMasterDetails( this.userDataDtoForBankName ).subscribe(
    //            ( response ) => {
    //                if ( response.length == 0 ) {
    //                    swal( {
    //                        title: "Server Error",
    //                        text: "Ooops...Error Occured While adding the new Bank Name",
    //                        icon: "error",
    //                        closeOnClickOutside: false,
    //                        closeOnEsc: false,
    //                    } );
    //                } else {
    //                    this.clearCompletePopupFieldsOnTypeChange();
    //                    this.getPartyBankDetailsService();
    //
    //                }
    //            } ),
    //            ( error ) => console.log( error.json() ),
    //            () => console.log( 'done' );
    //    };
    addBankDetails() {
        this.memoService.createNewPartyBankMasterService(this.userDataDtoForBankName).subscribe(
            (response) => {
                if (response) {
                    this.newPartyBankDetial = response;
                    if (this.newPartyBankDetial.status == "persisted") {
                        this.getPartyBankDetailsService();
                        this.clearCompletePopupFieldsOnTypeChange();
                    } else {
                        swal({
                            title: "Not Saved",
                            text: "Error Occured While adding the new Bank Name",
                            icon: "error",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                    }
                    this.newPartyBankDetial = '';

                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "Problem occur while Saving New Bank Name",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');

    }

    //    getChqAndNeftLRDetails( type ) {
    //        console.log( this.payType, this.payMode );
    //        this.selectedPaymentMode = $("#"+this.pageId+"paymentMode" ).val();
    //        if ( this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cash' ) {
    //            this.payType = "Cash";
    //                this.payMode = "completedcash";
    //        } else if ( this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque' ) {
    //            this.payType = "Cheque";
    //            this.payMode = "completedcheque";
    //        } else if ( this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft' ) {
    //            this.payType = "NEFT/IMPS";
    //            this.payMode = "completedneft";
    //        }
    //        this.userDataDtoForPayment = [];
    //        this.selectedCollectionMan = this.enteredCollectionMan;
    //        this.enteredCheckNumber = $("#"+this.pageId+"chequeNumber" ).val();
    //        this.selectedBankName = $("#"+this.pageId+"consigneeBankName" ).val();
    //        this.enteredNewBankName = $("#"+this.pageId+"newBankName" ).val();
    //        this.getChequeDate = $("#"+this.pageId+"chequeDate" ).val();
    //        this.enteredRefNumber = $("#"+this.pageId+"referenceNumber" ).val();
    //        this.getMainStation = $("#"+this.pageId+"paymentLessStn" ).val();
    //        if ( this.getChequeDate == null || this.getChequeDate == '' ) {
    //            this.getChequeDate = null;
    //        } else {
    //            this.getChequeDate = this.datePipe.transform( this.getChequeDate, "yyyy-MM-dd" );
    //        }
    //        this.enteredCompletedOnDate = $("#"+this.pageId+"completedOnDate" ).val();
    //        if ( this.enteredCompletedOnDate == null || this.enteredCompletedOnDate == '' ) {
    //            this.enteredCompletedOnDate == null;
    //        } else {
    //            this.enteredCompletedOnDate = this.datePipe.transform( this.enteredCompletedOnDate, "yyyy-MM-dd" );
    //            //       console.log( this.enteredCompletedOnDate );
    //        }
    //        for ( let i = 0; i < this.paymentAmountEntriesDataList.length; i++ ) {
    //
    //            this.userDataDtoForPaymentDetails = new UserDataDto();
    //            this.userDataDtoForPaymentDetails.memoNumber = this.paymentAmountEntriesDataList[i].chqType;
    //            this.userDataDtoForPaymentDetails.paymentAmt = this.paymentAmountEntriesDataList[i].balance;
    //            this.userDataDtoForPaymentDetails.collectioMan = this.selectedCollectionMan;
    //            this.userDataDtoForPaymentDetails.paymentType = this.payType;
    //            this.userDataDtoForPaymentDetails.chequeNumber = this.enteredCheckNumber == null ? null : this.enteredCheckNumber == '' ? null : this.enteredCheckNumber;
    //            this.userDataDtoForPaymentDetails.updatedBy = this.userDataDtoReturnSession.userId;
    //
    //            if ( this.selectedBankName != '' && this.selectedBankName == 'ADD NEW BANK' ) {
    //                this.userDataDtoForPaymentDetails.bankName = this.enteredNewBankName;
    //            } else {
    //                this.userDataDtoForPaymentDetails.bankName = this.selectedBankName == null ? null : this.selectedBankName == '' ? null : this.selectedBankName;
    //            }
    //            this.userDataDtoForPaymentDetails.chequeDate = this.getChequeDate;
    //            this.userDataDtoForPaymentDetails.completedOn = this.enteredCompletedOnDate;
    //            this.userDataDtoForPaymentDetails.features = this.enteredRefNumber == null ? null : this.enteredRefNumber == '' ? null : this.enteredRefNumber;
    //            // if received amt is less then balance amt then the remaining amt
    //            // will consider as cash
    //
    //            let memoAmt = this.paymentAmountEntriesDataList[i].cashMemoAmt;
    //            let amtRec = this.paymentAmountEntriesDataList[i].balance;
    //            let lessAmt = this.paymentAmountEntriesDataList[i].chqAmount
    //            let balAsCash = +memoAmt - ( +amtRec + +lessAmt );
    //            this.userDataDtoForPaymentDetails.balance = balAsCash;
    //            this.userDataDtoForPaymentDetails.mode = this.payMode;
    //            this.userDataDtoForPaymentDetails.amount = memoAmt;
    //            this.userDataDtoForPaymentDetails.chqAmt = lessAmt;
    //            this.userDataDtoForPaymentDetails.type = this.paymentAmountEntriesDataList[i].paymenttype;
    //            this.userDataDtoForPaymentDetails.partyName = this.paymentAmountEntriesDataList[i].consigneeName;
    //            this.userDataDtoForPaymentDetails.consigneeId = this.paymentAmountEntriesDataList[i].consigneeId;
    //            //Added By Basha
    //            this.userDataDtoForPaymentDetails.mainStation = this.getMainStation;
    //
    //
    //            this.userDataDtoForPayment.push( this.userDataDtoForPaymentDetails );
    //            //Pending need to check this below new dto list no need of this object userDataDtoListForLess may delete this 
    //            // add the entry details in temp file
    //            if ( type == 'Cheque/PDC' ) {
    //                if ( this.isPaymentClicked ) {
    //                    this.userDataDtosPaymentChqTemp = [];
    //                    this.isPaymentClicked = false;
    //                }
    //                this.userDataDtosPaymentChqTemp.push( this.userDataDtoForPaymentDetails );
    //            } else if ( type == 'NEFT/IMPS' ) {
    //                if ( this.isPaymentClicked ) {
    //                    this.userDataDtosPaymentNeftTemp = [];
    //                    this.isPaymentClicked = false;
    //                }
    //                this.userDataDtosPaymentNeftTemp.push( this.userDataDtoForPaymentDetails );
    //            }
    //        }
    //        $("#"+this.pageId+"consigneeNameForPayment" ).val( '' );
    //        this.selectedConsigneeId = 0;
    //        this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
    //        this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
    //        this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
    //        this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
    //        this.newAttributeForPayment = {};
    //        this.modelConsigneeNameForPayment = '';
    //        console.log( this.userDataDtoForPayment );
    //        console.log( this.userDataDtosPaymentChqTemp );
    //        console.log( this.userDataDtosPaymentNeftTemp );
    //        return this.userDataDtoForPayment;
    //    }
    getChqAndNeftLRDetails(type) {
        console.log(this.payType, this.payMode);
        if (this.payType == '' || this.payType == null || this.payMode == '' || this.payMode == null) {
            swal({
                title: "Warning",
                text: "Payment Type or PaymentMmode is missing for lr's",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
            //PFCHG_V5
            if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cash') {
                this.payType = "Cash";
                this.payMode = "completedcash";

            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                this.payType = "Cheque";
                this.payMode = "completedcheque";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                this.payType = "NEFT/IMPS";
                this.payMode = "completedneft";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'debitacc') {
                this.payType = "Debit Account";
                this.payMode = "completeddebitacc";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                this.payType = "UPI";
                this.payMode = "completedupi";
            }
        } else {
            this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
            //PFCHG_V5
            if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cash') {
                this.payType = "Cash";
                this.payMode = "completedcash";

            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                this.payType = "Cheque";
                this.payMode = "completedcheque";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                this.payType = "NEFT/IMPS";
                this.payMode = "completedneft";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'debitacc') {
                this.payType = "Debit Account";
                this.payMode = "completeddebitacc";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                this.payType = "UPI";
                this.payMode = "completedupi";
            }
            this.userDataDtoForPayment = [];
            this.selectedCollectionMan = this.enteredCollectionMan;
            if (this.getChequeDate == null || this.getChequeDate == '') {
                this.getChequeDate = null;
            } else {
                this.getChequeDate = this.datePipe.transform(this.getChequeDate, "yyyy-MM-dd");
            }
            this.enteredCompletedOnDate = $("#" + this.pageId + "completedOnDate").val();
            if (this.enteredCompletedOnDate == null || this.enteredCompletedOnDate == '') {
                this.enteredCompletedOnDate == null;
            } else {
                this.enteredCompletedOnDate = this.datePipe.transform(this.enteredCompletedOnDate, "yyyy-MM-dd");
                //       console.log( this.enteredCompletedOnDate );
            }
            for (let i = 0; i < this.paymentAmountEntriesDataList.length; i++) {
                this.userDataDtoForPaymentDetails = new UserDataDto();
                this.userDataDtoForPaymentDetails.memoNumber = this.paymentAmountEntriesDataList[i].chqType;
                this.userDataDtoForPaymentDetails.paymentAmt = this.paymentAmountEntriesDataList[i].balance;
                this.userDataDtoForPaymentDetails.collectioMan = this.selectedCollectionMan;
                this.userDataDtoForPaymentDetails.paymentType = this.payType;
                this.userDataDtoForPaymentDetails.chequeNumber = (this.paymentAmountEntriesDataList[i].chequeno == null ? null : this.paymentAmountEntriesDataList[i].chequeno == '' ? null : this.paymentAmountEntriesDataList[i].chequeno);
                this.userDataDtoForPaymentDetails.updatedBy = this.userDataDtoReturnSession.userId;
                this.userDataDtoForPaymentDetails.bankName = (this.paymentAmountEntriesDataList[i].bankname == null ? null : this.paymentAmountEntriesDataList[i].bankname == '' ? null : this.paymentAmountEntriesDataList[i].bankname);
                this.userDataDtoForPaymentDetails.chequeDate = this.paymentAmountEntriesDataList[i].chequedate;
                this.userDataDtoForPaymentDetails.completedOn = this.enteredCompletedOnDate;
                this.userDataDtoForPaymentDetails.features = (this.paymentAmountEntriesDataList[i].remarks == null ? null : this.paymentAmountEntriesDataList[i].remarks == '' ? null : this.paymentAmountEntriesDataList[i].remarks);
                // if received amt is less then balance amt then the remaining amt
                // will consider as cash

                let memoAmt = this.paymentAmountEntriesDataList[i].cashMemoAmt;
                let amtRec = this.paymentAmountEntriesDataList[i].balance;
                let lessAmt = this.paymentAmountEntriesDataList[i].chqAmount
                let balAsCash = +memoAmt - (+amtRec + +lessAmt);
                this.userDataDtoForPaymentDetails.balance = balAsCash;
                this.userDataDtoForPaymentDetails.mode = this.payMode;
                this.userDataDtoForPaymentDetails.amount = memoAmt;
                this.userDataDtoForPaymentDetails.chqAmt = lessAmt;
                this.userDataDtoForPaymentDetails.type = this.paymentAmountEntriesDataList[i].paymenttype;
                this.userDataDtoForPaymentDetails.partyName = this.paymentAmountEntriesDataList[i].consigneeName;
                this.userDataDtoForPaymentDetails.consigneeId = this.paymentAmountEntriesDataList[i].consigneeId;
                //Added By Basha
                this.userDataDtoForPaymentDetails.mainStation = this.paymentAmountEntriesDataList[i].mainStation;
                this.userDataDtoForPayment.push(this.userDataDtoForPaymentDetails);
                //Pending need to check this below new dto list no need of this object userDataDtoListForLess may delete this 
                // add the entry details in temp file
                if (type == 'Cheque/PDC') {
                    if (this.isPaymentClicked) {
                        this.userDataDtosPaymentChqTemp = [];
                        this.isPaymentClicked = false;
                    }
                    this.userDataDtosPaymentChqTemp.push(this.userDataDtoForPaymentDetails);
                } else if (type == 'NEFT/IMPS') {
                    if (this.isPaymentClicked) {
                        this.userDataDtosPaymentNeftTemp = [];
                        this.isPaymentClicked = false;
                    }
                    this.userDataDtosPaymentNeftTemp.push(this.userDataDtoForPaymentDetails);
                }
                //PFCHG_V5
                else if (type == 'Debit Account') {
                    if (this.isPaymentClicked) {
                        this.userDataDtosPaymentDebitAccTemp = [];
                        this.isPaymentClicked = false;
                    }
                    this.userDataDtosPaymentDebitAccTemp.push(this.userDataDtoForPaymentDetails);
                } else if (type == 'UPI') {
                    if (this.isPaymentClicked) {
                        this.userDataDtosPaymentUPITemp = [];
                        this.isPaymentClicked = false;
                    }
                    this.userDataDtosPaymentUPITemp.push(this.userDataDtoForPaymentDetails);
                }
            }
            $("#" + this.pageId + "consigneeNameForPayment").val('');
            this.selectedConsigneeId = 0;
            this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
            this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
            this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
            this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
            this.newAttributeForPayment = {};
            this.modelConsigneeNameForPayment = '';
            console.log(this.userDataDtoForPayment);
            console.log(this.userDataDtosPaymentChqTemp);
            console.log(this.userDataDtosPaymentNeftTemp);
            console.log(this.userDataDtosPaymentDebitAccTemp);
            console.log(this.userDataDtosPaymentUPITemp);
        }

        return this.userDataDtoForPayment;
    }
    clearCompletePopupFieldsOnTypeChange() {
        this.isEdit = false;
        $("#" + this.pageId + "chequeNumber").val('');
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.viewNewBankName = false;
        this.selectedConsigneeId = 0;
        this.controlConsigneeBankName.reset();
        this.controlConsigneeName.reset();
        $("#" + this.pageId + "totalAmountForPayment").val('');
        $("#" + this.pageId + "chequeDate").val('');
        $("#" + this.pageId + "referenceNumber").val('');
        $("#" + this.pageId + "paymentLessAmount").val('');
        $("#" + this.pageId + "memoNumberForManualLessPayment").val('');
        $("#" + this.pageId + "entryLessAmount").val('');
        $("#" + this.pageId + "lessType").val('');
        $("#" + this.pageId + "memoNumberForManualLess").val('');
        this.editTableId = null;
        this.editTableIdForPayment = null;
        this.controlEntryLessType.reset();
        $("#" + this.pageId + "consigneeName").val('');
        $("#" + this.pageId + "totalLess").val('');
        this.controlConsigneeNamePayment.reset();
        this.paymentAmountEntriesDataList = [];
        this.countOfPaymentMemo = 0;
        this.sumOfMemoAmtPayment = 0;
        this.sumOfLessAmtPayment = 0;
        this.sumOfBalanceAmtPayment = 0;
        this.getPartyBankDetailsService();

        //Pending still the less page values need to cleared 
        //        listStoreCompleteLrs.removeAll();
        //        gridCompleteLrs.reconfigure(listStoreCompleteLrs, cmCompleteLrs);
        //        listStoreCompleteLessLrs.removeAll();
        //        gridCompleteLessLrs.reconfigure(listStoreCompleteLessLrs,
        //                cmCompleteLessLrs);
    }

    clearCompletePopupCashFields() {
        this.nf2000Count = '';
        this.nf500Count = '';
        this.nf200Count = '';
        this.nf100Count = '';
        this.nf50Count = '';
        this.nf20Count = '';
        this.nf10Count = '';
        this.nf5Count = '';
        this.nf2Count = '';
        this.nf1Count = '';

        this.nf2000 = '';
        this.nf500 = '';
        this.nf200 = '';
        this.nf100 = '';
        this.nf50 = '';
        this.nf20 = '';
        this.nf10 = '';
        this.nf5 = '';
        this.nf2 = '';
        this.nf1 = '';
        $("#" + this.pageId + "totalRupeeDenomination").val('');
        $("#" + this.pageId + "paymentLessAmount").val('');
        $("#" + this.pageId + "entryLessAmount").val('');
        this.grandTotalForCash = '';
        $("#" + this.pageId + "totalRupeeDenomination").val('');
        $("#" + this.pageId + "twoThousandRupee").val();
        $("#" + this.pageId + "fiveHundredRupee").val('');
        $("#" + this.pageId + "twoHundredRupee").val('');
        $("#" + this.pageId + "hundredRupee").val('');
        $("#" + this.pageId + "fiftyRupee").val('');
        $("#" + this.pageId + "twentyRupee").val('');
        $("#" + this.pageId + "tenRupee").val('');
        $("#" + this.pageId + "fiveRupee").val('');
        $("#" + this.pageId + "twoRupee").val('');
        $("#" + this.pageId + "oneRupee").val('');
        $("#" + this.pageId + "twoThousandRupeeCount").val('');
        $("#" + this.pageId + "fiveHundredRupeeCount").val('');
        $("#" + this.pageId + "twoHundredRupeeCount").val('');
        $("#" + this.pageId + "hundredRupeeCount").val('');
        $("#" + this.pageId + "fiftyRupeeCount").val('');
        $("#" + this.pageId + "twentyRupeeCount").val('');
        $("#" + this.pageId + "tenRupeeCount").val('');
        $("#" + this.pageId + "fiveRupeeCount").val('');
        $("#" + this.pageId + "twoRupeeCount").val('');
        $("#" + this.pageId + "oneRupeeCount").val('');

    }


    updateNeftDetails(type) {
        if (this.payType == '' || this.payType == null || this.payMode == '' || this.payMode == null) {
            swal({
                title: "Warning",
                text: "Payment Type or PaymentMmode is missing for lr's",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
            this.selectedPaymentMode = $("#" + this.pageId + "paymentMode").val();
            //PFCHG_V5
            if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cash') {
                this.payType = "Cash";
                this.payMode = "completedcash";

            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'cheque') {
                this.payType = "Cheque";
                this.payMode = "completedcheque";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'neft') {
                this.payType = "NEFT/IMPS";
                this.payMode = "completedneft";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'debitacc') {
                this.payType = "Debit Account";
                this.payMode = "completeddebitacc";
            } else if (this.selectedPaymentMode != '' && this.selectedPaymentMode == 'upi') {
                this.payType = "UPI";
                this.payMode = "completedupi";
            }
        } else {
            this.getChqAndNeftLRDetails(type);
            this.selectedBankName = $("#" + this.pageId + "consigneeBankName").val();
            // add new bank name
            if (this.selectedBankName != '' && this.selectedBankName == 'ADD NEW BANK') {
                this.addBankNames();
            } else {
                this.clearCompletePopupFieldsOnTypeChange();
            }
            this.resetPaymentMode();
            swal({
                title: "Memo Payment",
                text: "Payment details updated temporary, please note that it will only be save permanently when click on Finish",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }
    }
    //// Finish Btn Process starts here
    validatePaymentEntryFinishBtn(e) {
        var balancedAmtInFinal = this.balanceAmountFinal;
        let cashAmt = this.calculateDenominationTotalAmt();
        if (balancedAmtInFinal > cashAmt) {
            swal({
                title: "Not Allowed",
                text: "Balance Amount should be NIL to finish this entry, please verify!",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });

        } else {
            swal({
                title: "Confirm Finish",
                text: "Sure you want to finish this collection transaction",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((finish) => {
                if (finish) {
                    $("#" + this.pageId + "paymentEntryFinishBtn").prop('disabled', true);
                    //                    this.selectedPaymentMode = $("#"+this.pageId+"paymentMode" ).val();
                    //                    if ( this.selectedPaymentMode === 'cheque' ) {
                    //                        this.viewByCash = true;
                    //                        this.viewByCashs = true
                    //                        
                    //                    }
                    //                    else if ( this.selectedPaymentMode === 'neft' ) {
                    //                        this.viewByCash = true;
                    //                        this.viewByCashs = true
                    //                    } else if ( this.selectedPaymentMode === 'Select Payment Mode' ) {
                    //                        this.viewByCash = true;
                    //                        this.viewByCashs = true
                    //                    } 
                    this.viewByCash = true;
                    this.viewByCashs = true
                    this.showSpinner = true;
                    this.callTempoForCash();
                    this.calculateAllTotalAndBalance();
                    this.setCashAmtModel();
                    this.finishCompleteEntries();
                    $(
                        "html, body")
                        .animate(
                            {
                                scrollTop: 0
                            },
                            "medium");
                }

            });
        }
    }


    finishCompleteEntries() {

        this.userDataDtoForFinishDetails = new UserDataDto();
        this.userDataDtoForFinishDetails.listLessDetails = this.userDataDtosLessTemp;
        this.finishCompleteDetails();
        //    console.log( this.userDataDtoForFinishDetails );
    }

    //    finishCompleteEntries() {
    //        for ( let i = 0; i < this.userDataDtosPaymentChqTemp.length; i++ ) {
    //            let paymentMode = this.userDataDtosPaymentChqTemp[i].mode;
    //            let paymentType = this.userDataDtosPaymentChqTemp[i].paymentType;
    //            if (  paymentMode == '' || paymentMode == null || paymentType == '' || paymentType == null ) {
    //                swal( {
    //                    title: "Warning",
    //                    text: "Payment Type or PaymentMmode is missing for lr's In Check Entries",
    //                    icon: "warning",
    //                    closeOnClickOutside: false,
    //                    closeOnEsc: false,
    //                } );
    //                return false;
    //
    //            }
    //        }
    //        for ( let i = 0; i < this.userDataDtosPaymentNeftTemp.length; i++ ) {
    //            let paymentMode = this.userDataDtosPaymentNeftTemp[i].mode;
    //            let paymentType = this.userDataDtosPaymentNeftTemp[i].paymentType;
    //            if (  paymentMode == '' || paymentMode == null || paymentType == '' || paymentType == null ) {
    //                swal( {
    //                    title: "Warning",
    //                    text: "Payment Type or PaymentMmode is missing for lr's In NEFT/PDC Entries",
    //                    icon: "warning",
    //                    closeOnClickOutside: false,
    //                    closeOnEsc: false,
    //                } );
    //                return false;
    //
    //            }
    //        }
    //        for ( let i = 0; i < this.userDataDtosPaymentCashTemp.length; i++ ) {
    //            let paymentMode = this.userDataDtosPaymentCashTemp[i].mode;
    //            let paymentType = this.userDataDtosPaymentCashTemp[i].paymentType;
    //            if ( paymentMode == '' || paymentMode == null || paymentType == '' || paymentType == null ) {
    //                swal( {
    //                    title: "Warning",
    //                    text: "Payment Type or PaymentMmode is missing for lr's In Cash Entries",
    //                    icon: "warning",
    //                    closeOnClickOutside: false,
    //                    closeOnEsc: false,
    //                } );
    //                return false;
    //
    //            }
    //        }
    //
    //        this.userDataDtoForFinishDetails = new UserDataDto();
    //        this.userDataDtoForFinishDetails.listLessDetails = this.userDataDtosLessTemp;
    //        this.finishCompleteDetails();
    //        //    console.log( this.userDataDtoForFinishDetails );
    //    }

    finishCompleteDetails = () => {
        this.memoService.updateCashMemoLessDetails(this.userDataDtoForFinishDetails).subscribe(
            (response) => {

                this.retrunStatus = response;
                if (this.retrunStatus.status == "success") {
                    this.finishPaymenChqEntries();
                } else {
                    $("#" + this.pageId + "paymentEntryFinishBtn").prop('disabled', false);
                    this.showSpinner = false;
                    swal({
                        title: "Memo Less",
                        text: "Server Problem occurred while updating the Memo Less details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.retrunStatus = '';
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    finishPaymenChqEntries() {
        this.userDataDtoForFinishDetails = new UserDataDto();
        //        this.userDataDtoForFinishDetails.listChqDetails=this.userDataDtosPaymentChqTemp;
        this.userDataDtoForFinishDetails.listCashDetails = this.userDataDtosPaymentChqTemp;
        this.userDataDtoForFinishDetails.mode = 'completedcheque';
        //PFCHG_V1
        this.userDataDtoForFinishDetails.specCollectionFlow = this.userDataDtoReturnSession.specCollectionFlow;
        //
        this.finishPaymenChqDetails();
        console.log(this.userDataDtoForFinishDetails);
    }

    finishPaymenChqDetails = () => {
        this.memoService.updateCashMemoCompleteDetails(this.userDataDtoForFinishDetails).subscribe(
            (response) => {

                this.retrunStatus = response;
                if (this.retrunStatus.status == "success") {
                    this.finishPaymenNeftEntries();
                } else {
                    this.showSpinner = false;
                    swal({
                        title: "Memo Payment",
                        text: "Server Problem occurred while updating the Memo Cheque Payment details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.retrunStatus = '';
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    finishPaymenNeftEntries() {
        this.userDataDtoForFinishDetails = new UserDataDto();
        //        this.userDataDtoForFinishDetails.listNEFTDetails=this.userDataDtosPaymentNeftTemp;
        this.userDataDtoForFinishDetails.listCashDetails = this.userDataDtosPaymentNeftTemp;
        this.userDataDtoForFinishDetails.mode = 'completedneft';
        //PFCHG_V1
        this.userDataDtoForFinishDetails.specCollectionFlow = this.userDataDtoReturnSession.specCollectionFlow;
        //
        this.finishPaymenNeftDetails();
        //    console.log( this.userDataDtoForFinishDetails );

    }
    finishPaymenNeftDetails = () => {
        this.memoService.updateCashMemoCompleteDetails(this.userDataDtoForFinishDetails).subscribe(
            (response) => {
                this.retrunStatus = response;
                if (this.retrunStatus.status == "success") {
                    this.finishPaymenDebitAcEntries();
                } else {
                    this.showSpinner = false;
                    swal({
                        title: "Memo Payment",
                        text: "Server Problem occurred while updating the Memo Neft Payment details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.retrunStatus = '';
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    //PFCHG_V1
    finishPaymenDebitAcEntries() {
        this.userDataDtoForFinishDetails = new UserDataDto();
        //        this.userDataDtoForFinishDetails.listNEFTDetails=this.userDataDtosPaymentNeftTemp;
        this.userDataDtoForFinishDetails.listCashDetails = this.userDataDtosPaymentDebitAccTemp;
        this.userDataDtoForFinishDetails.mode = 'completeddebitacc';
        //PFCHG_V1
        this.userDataDtoForFinishDetails.specCollectionFlow = this.userDataDtoReturnSession.specCollectionFlow;
        //
        this.finishPaymenDebitAcDetails();
        //    console.log( this.userDataDtoForFinishDetails );

    }
    finishPaymenDebitAcDetails = () => {
        this.memoService.updateCashMemoCompleteDetails(this.userDataDtoForFinishDetails).subscribe(
            (response) => {
                this.retrunStatus = response;
                if (this.retrunStatus.status == "success") {
                    this.finishPaymenUPIEntries();
                } else {
                    this.showSpinner = false;
                    swal({
                        title: "Memo Payment",
                        text: "Server Problem occurred while updating the Memo Debit A/c Payment details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.retrunStatus = '';
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    //PFCHG_V1
    finishPaymenUPIEntries() {
        this.userDataDtoForFinishDetails = new UserDataDto();
        //        this.userDataDtoForFinishDetails.listNEFTDetails=this.userDataDtosPaymentNeftTemp;
        this.userDataDtoForFinishDetails.listCashDetails = this.userDataDtosPaymentUPITemp;
        this.userDataDtoForFinishDetails.mode = 'completedupi';
        //PFCHG_V1
        this.userDataDtoForFinishDetails.specCollectionFlow = this.userDataDtoReturnSession.specCollectionFlow;
        //
        this.finishPaymenUPIDetails();
        //    console.log( this.userDataDtoForFinishDetails );

    }
    finishPaymenUPIDetails = () => {
        this.memoService.updateCashMemoCompleteDetails(this.userDataDtoForFinishDetails).subscribe(
            (response) => {
                this.retrunStatus = response;
                if (this.retrunStatus.status == "success") {
                    this.updateCashEntries();
                } else {
                    this.showSpinner = false;
                    swal({
                        title: "Memo Payment",
                        text: "Server Problem occurred while updating the Memo UPI Payment details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.retrunStatus = '';
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    //
    updateCashEntries() {
        this.userDataDtoForFinishDetails = new UserDataDto();
        this.userDataDtoForFinishDetails.listCashDetails = this.getCashLRDetails();
        this.userDataDtoForFinishDetails.mode = 'completedcash';
        //PFCHG_V1
        this.userDataDtoForFinishDetails.specCollectionFlow = this.userDataDtoReturnSession.specCollectionFlow;
        //
        this.updateCashDetails();
        //    console.log( this.userDataDtoForFinishDetails );
    }
    updateCashDetails = () => {
        this.memoService.updateCashMemoCompleteDetails(this.userDataDtoForFinishDetails).subscribe(
            (response) => {
                this.retrunStatus = response;
                if (this.retrunStatus.status == "success") {
                    this.updateCollectionNumberOnComplete();
                } else {
                    this.showSpinner = false;
                    swal({
                        title: "Memo Payment",
                        text: "Server Problem occurred while updating the Memo Cash Payment details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.retrunStatus = '';
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    getCashLRDetails() {
        this.userDataDtoForCash = [];
        this.selectedCollectionMan = this.enteredCollectionMan;
        this.enteredCompletedOnDate = $("#" + this.pageId + "completedOnDate").val();
        if (this.enteredCompletedOnDate == null || this.enteredCompletedOnDate == '') {
            this.enteredCompletedOnDate == null;
        } else {
            this.enteredCompletedOnDate = this.datePipe.transform(this.enteredCompletedOnDate, "yyyy-MM-dd");
        }
        for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
            this.userDataDtoForPaymentCashDetails = new UserDataDto();
            this.userDataDtoForPaymentCashDetails.memoNumber = this.pfAssignedJobDetailsDataList[i].memoNumber;
            this.userDataDtoForPaymentCashDetails.amount = this.pfAssignedJobDetailsDataList[i].amount;
            this.userDataDtoForPaymentCashDetails.collectioMan = this.selectedCollectionMan;
            this.userDataDtoForPaymentCashDetails.paymentType = 'Cash';
            this.userDataDtoForPaymentCashDetails.completedOn = this.enteredCompletedOnDate;
            this.userDataDtoForPaymentCashDetails.updatedBy = this.userDataDtoReturnSession.userId;
            this.userDataDtoForPaymentCashDetails.mode = 'completedcash';
            this.userDataDtoForCash.push(this.userDataDtoForPaymentCashDetails);
        }
        //    console.log( this.userDataDtoForCash );
        return this.userDataDtoForCash;
    }
    updateCollectionNumberOnComplete() {
        this.selectedCollectionMan = this.enteredCollectionMan;
        this.enteredCompletedOnDate = $("#" + this.pageId + "completedOnDate").val();
        if (this.enteredCompletedOnDate == null || this.enteredCompletedOnDate == '') {
            this.enteredCompletedOnDate == null;
        } else {
            this.enteredCompletedOnDate = this.datePipe.transform(this.enteredCompletedOnDate, "yyyy-MM-dd");
        }
        this.userDataDtoForFinishDetails = new UserDataDto();
        this.userDataDtoForFinishDetails.collectioMan = this.selectedCollectionMan;
        this.userDataDtoForFinishDetails.completedOn = this.enteredCompletedOnDate;
        this.userDataDtoForFinishDetails.updatedBy = this.userDataDtoReturnSession.userId;
        this.userDataDtoForFinishDetails.city = this.userDataDtoReturnSession.mainStation;
        this.userDataDtoForFinishDetails.companyId = this.userDataDtoReturnSession.companyId;
        this.updateCollectionNumberOnCompleteDetails();
        //    console.log( this.userDataDtoForFinishDetails );

    }
    updateCollectionNumberOnCompleteDetails = () => {
        this.memoService.updateCloseCollectionService(this.userDataDtoForFinishDetails).subscribe(
            (response) => {
                this.updateCloseCollection = response;
                this.showSpinner = false;
                if (this.updateCloseCollection.status == 'success') {
                    swal({
                        title: "Memo Payment",
                        text: "Payment details updated successfully",
                        icon: "success",
                        closeOnClickOutside: true,
                        closeOnEsc: true,
                    }).then(() => {
                        //PFCHG_V3
                        if (this.sendCollectionCloseWhatsappUpdates) {
                            this.whatsAppFlowForCollectionClose();
                        }

                        this.getAllLessDetailsOnFinish();
                    });
                } else {
                    swal({
                        title: "Error",
                        text: "Server Problem occurred while closing the collection",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.updateCloseCollection = '';
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    getAllLessDetailsOnFinish() {
        this.userDataDtoForFinishDetails = new UserDataDto();
        this.listOfMemoNumber = [];
        for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
            this.listOfMemoNumber.push(this.pfAssignedJobDetailsDataList[i].memoNumber);
        }
        this.userDataDtoForFinishDetails.list = this.listOfMemoNumber;
        this.getAllLessDetailsOnFinishDetails();
        //   console.log( this.userDataDtoForFinishDetails );
    }

    getAllLessDetailsOnFinishDetails = () => {
        this.memoService.getDifferentMemoLessDetailsBasedOnLessDesc(this.userDataDtoForFinishDetails).subscribe(
            (response) => {
                if (response.length == 0) {
                    //Pending
                    alert('');
                } else {
                    //Pending
                    this.diffLessRet = response;
                    this.setPringForChallanReport();
                    //                    Object.keys(this.diffLessRet.hashMapDiffLess);
                    //                    for ( let key in this.diffLessRet.hashMapDiffLess ) {
                    //                        //Whatever you want to do with key or obj[key]
                    //                        console.log( key );
                    //                        console.log( this.diffLessRet.hashMapDiffLess[key] );
                    //                    }
                    //                    console.log( this.diffLessRet );
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };
    getChallanPrintValues() {
        this.selectedCollectionMan = this.enteredCollectionMan;
        this.enteredCompletedOnDate = $("#" + this.pageId + "completedOnDate").val();
        if (this.enteredCompletedOnDate == null || this.enteredCompletedOnDate == '') {
            this.enteredCompletedOnDate == '';
        } else {
            this.enteredCompletedOnDate = this.datePipe.transform(this.enteredCompletedOnDate, "dd-MM-yyyy");
        }
        this.nf2000 = $("#" + this.pageId + "twoThousandRupee").val();
        this.nf500 = $("#" + this.pageId + "fiveHundredRupee").val();
        this.nf200 = $("#" + this.pageId + "twoHundredRupee").val();
        this.nf100 = $("#" + this.pageId + "hundredRupee").val();
        this.nf50 = $("#" + this.pageId + "fiftyRupee").val();
        this.nf20 = $("#" + this.pageId + "twentyRupee").val();
        this.nf10 = $("#" + this.pageId + "tenRupee").val();
        this.nf5 = $("#" + this.pageId + "fiveRupee").val();
        this.nf2 = $("#" + this.pageId + "twoRupee").val();
        this.nf1 = $("#" + this.pageId + "oneRupee").val();
        this.nf2000Count = $("#" + this.pageId + "twoThousandRupeeCount").val();
        this.nf500Count = $("#" + this.pageId + "fiveHundredRupeeCount").val();
        this.nf200Count = $("#" + this.pageId + "twoHundredRupeeCount").val();
        this.nf100Count = $("#" + this.pageId + "hundredRupeeCount").val();
        this.nf50Count = $("#" + this.pageId + "fiftyRupeeCount").val();
        this.nf20Count = $("#" + this.pageId + "twentyRupeeCount").val();
        this.nf10Count = $("#" + this.pageId + "tenRupeeCount").val();
        this.nf5Count = $("#" + this.pageId + "fiveRupeeCount").val();
        this.nf2Count = $("#" + this.pageId + "twoRupeeCount").val();
        this.nf1Count = $("#" + this.pageId + "oneRupeeCount").val();
        this.nf2000 = this.nf2000 == null ? 0.0 : this.nf2000 == '' ? 0.0 : this.nf2000;
        this.nf500 = this.nf500 == null ? 0.0 : this.nf500 == '' ? 0.0 : this.nf500;
        this.nf200 = this.nf200 == null ? 0.0 : this.nf200 == '' ? 0.0 : this.nf200;
        this.nf100 = this.nf100 == null ? 0.0 : this.nf100 == '' ? 0.0 : this.nf100;
        this.nf50 = this.nf50 == null ? 0.0 : this.nf50 == '' ? 0.0 : this.nf50;
        this.nf20 = this.nf20 == null ? 0.0 : this.nf20 == '' ? 0.0 : this.nf20;
        this.nf10 = this.nf10 == null ? 0.0 : this.nf10 == '' ? 0.0 : this.nf10;
        this.nf5 = this.nf5 == null ? 0.0 : this.nf5 == '' ? 0.0 : this.nf5;
        this.nf2 = this.nf2 == null ? 0.0 : this.nf2 == '' ? 0.0 : this.nf2;
        this.nf1 = this.nf1 == null ? 0.0 : this.nf1 == '' ? 0.0 : this.nf1;

        this.nf2000Count = this.nf2000Count == null ? 0 : this.nf2000Count == '' ? 0 : this.nf2000Count;
        this.nf500Count = this.nf500Count == null ? 0 : this.nf500Count == '' ? 0 : this.nf500Count;
        this.nf200Count = this.nf200Count == null ? 0 : this.nf200Count == '' ? 0 : this.nf200Count;
        this.nf100Count = this.nf100Count == null ? 0 : this.nf100Count == '' ? 0 : this.nf100Count;
        this.nf50Count = this.nf50Count == null ? 0 : this.nf50Count == '' ? 0 : this.nf50Count;
        this.nf20Count = this.nf20Count == null ? 0 : this.nf20Count == '' ? 0 : this.nf20Count;
        this.nf10Count = this.nf10Count == null ? 0 : this.nf10Count == '' ? 0 : this.nf10Count;
        this.nf5Count = this.nf5Count == null ? 0 : this.nf5Count == '' ? 0 : this.nf5Count;
        this.nf2Count = this.nf2Count == null ? 0 : this.nf2Count == '' ? 0 : this.nf2Count;
        this.nf1Count = this.nf1Count == null ? 0 : this.nf1Count == '' ? 0 : this.nf1Count;

        this.getChallanPrintValDto = new CashMemoDto();
        this.getChallanPrintValDto.totalLess = this.lblTotLessSummaryValue == '' ? null : this.lblTotLessSummaryValue;
        this.getChallanPrintValDto.collectionMan = this.selectedCollectionMan;
        this.getChallanPrintValDto.completedOn = this.enteredCompletedOnDate;
        this.getChallanPrintValDto.amtToBeCollected = this.amountToBeCollected;
        this.getChallanPrintValDto.delhiLess = this.lblDelhiLessValue == '' ? null : this.lblDelhiLessValue;
        this.getChallanPrintValDto.mumbaiLess = this.lblMumLessValue == '' ? null : this.lblMumLessValue;
        this.getChallanPrintValDto.gujaratLess = this.lblGujLessValue == '' ? null : this.lblGujLessValue;
        this.getChallanPrintValDto.punjabLess = this.lblPjbLessValue == '' ? null : this.lblPjbLessValue;
        this.getChallanPrintValDto.tds = this.lblTDSLessValue == '' ? null : this.lblTDSLessValue;
        this.getChallanPrintValDto.claim = this.lblClaimLessValue == '' ? null : this.lblClaimLessValue;
        this.getChallanPrintValDto.totalChqAmt = this.lblTotChqAmtSummaryValue == '' ? null : this.lblTotChqAmtSummaryValue;
        this.getChallanPrintValDto.totalNeftAmt = this.lblTotNeftAmtSummaryValue == '' ? null : this.lblTotNeftAmtSummaryValue;
        this.getChallanPrintValDto.totalCashAmt = this.lblTotCashAmtSummaryValue == '' ? null : this.lblTotCashAmtSummaryValue;
        this.getChallanPrintValDto.totalCashAmt = this.lblTotCashAmtSummaryValue == '' ? null : this.lblTotCashAmtSummaryValue;
        this.getChallanPrintValDto.balanceAmt = this.balanceAmountFinal;
        this.getChallanPrintValDto.nfof2000Count = this.nf2000Count;
        this.getChallanPrintValDto.nfof500Count = this.nf500Count;
        this.getChallanPrintValDto.nfof200Count = this.nf200Count;
        this.getChallanPrintValDto.nfof100Count = this.nf100Count;
        this.getChallanPrintValDto.nfof50Count = this.nf50Count;
        this.getChallanPrintValDto.nfof20Count = this.nf20Count;
        this.getChallanPrintValDto.nfof10Count = this.nf10Count;
        this.getChallanPrintValDto.nfof5Count = this.nf5Count;
        this.getChallanPrintValDto.nfof2Count = this.nf2Count;
        this.getChallanPrintValDto.nfof1Count = this.nf1Count;
        this.getChallanPrintValDto.nfOF2000 = this.nf2000;
        this.getChallanPrintValDto.nfOF500 = this.nf500;
        this.getChallanPrintValDto.nfOF200 = this.nf200;
        this.getChallanPrintValDto.nfOF100 = this.nf100;
        this.getChallanPrintValDto.nfOF50 = this.nf50;
        this.getChallanPrintValDto.nfOF20 = this.nf20;
        this.getChallanPrintValDto.nfOF10 = this.nf10;
        this.getChallanPrintValDto.nfOF5 = this.nf5;
        this.getChallanPrintValDto.nfOF2 = this.nf2;
        this.getChallanPrintValDto.nfOF1 = this.nf1;
        this.lessDescOpt = this.entryLessTypeOptions;
        //        for ( let i = 0; i < this.lessDescOpt.length; i++ ) {
        //            this.lessDescOptSent.push(this.entryLessTypeOptions[i].label);
        //        }
        //   console.log( this.lessDescOptSent );
        //    console.log( this.lessDescOptSent.length );
        this.getChallanPrintValDto.lessDesc = this.lessDescOpt;
        this.getChallanPrintValDto.totalMemos = this.pfAssignedJobDetailsDataList.length;
        let totAmt = 0.0;
        for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
            totAmt = +totAmt + +this.pfAssignedJobDetailsDataList[i].amount;
        }
        this.getChallanPrintValDto.grandTotal = totAmt;
        //PFCHG_V5
        this.getChallanPrintValDto.totalDebitAcAmt = this.lblTotDebtAcccAmtSummaryValue == '' ? null : this.lblTotDebtAcccAmtSummaryValue;
        this.getChallanPrintValDto.totalUPIAmt = this.lblTotUPIAmtSummaryValue == '' ? null : this.lblTotUPIAmtSummaryValue;
        //
        return this.getChallanPrintValDto;
    }

    setPringForChallanReport() {
        this.getChallanPrintValues();
        localStorage.clear();
        localStorage.setItem('printAfterChallanMemo', JSON.stringify(this.getChallanPrintValDto));
        localStorage.setItem('printAfterChallanMemolist', JSON.stringify(this.diffLessRet));
        this.viewAssignMemoPrint = false;
        this.viewChallanRptPrint = true;
        window.addEventListener('afterprint', (onclick) => {
            if (this.viewChallanRptPrint) {
                this.viewChallanRptPrint = false;
                this.viewAssignMemoPrint = false;
                this.getChallanPrintValDto = new CashMemoDto();
                this.diffLessRet = '';
                this.clearConsigneewiseDetails();
                this.clearCompletePopupCashFields();
                this.clearCompletePopupFieldsOnTypeChange();
                this.clearALLFieldsAfterCompelte();
                ///Pending Clear Part on Complete
                localStorage.clear();
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        // inp._elementRef.nativeElement.focus();
        window.setTimeout(function () {
            inp._elementRef.nativeElement.focus();
        }, 1000);
    }

    rowSelectedDeleteForAssignedTable(pfAssignedJobDetailsData, index) {
        //    console.log( pfAssignedJobDetailsData.memoNumber );
        this.gridReconfigureAssgRemove(pfAssignedJobDetailsData);
    }
    gridReconfigureAssgRemove(pfAssignedJobDetailsData) {
        if (this.value == false) {
            //       console.log( 'a' );
            let index = -1;
            for (let i = 0; i < this.tempstore.length; i++) {
                if (this.tempstore[i].memoNumber === pfAssignedJobDetailsData.memoNumber) {
                    index = i;
                    break;
                }
            }
            if (index === -1) {
                alert("Something gone wrong");
            }
            this.tempstore.splice(index, 1);
            //Here instead of clearing the whole pfAssignedJobDetailsDataList i just removed the deleted memo and did the footer calculation
            //        console.log( this.pfAssignedJobDetailsDataList );
            this.modelAllRemove = (this.pfCollectionManDetailsDataList.find(pfCollectionManDetailsData => pfCollectionManDetailsData.memoNumber === pfAssignedJobDetailsData.memoNumber));
            if (this.modelAllRemove == null) {
                this.pfCollectionManDetailsDataList.push(pfAssignedJobDetailsData);
                this.countOFMemosCollectionTable = 0;
                this.sumOFMemosCollectionTable = 0;
                for (let i = 0; i < this.pfCollectionManDetailsDataList.length; i++) {
                    this.countOFMemosCollectionTable = this.countOFMemosCollectionTable + 1;
                    this.sumOFMemosCollectionTable = this.sumOFMemosCollectionTable + +(this.pfCollectionManDetailsDataList[i].amount);
                }

                let index = -1;
                for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                    if (this.pfAssignedJobDetailsDataList[i].memoNumber === pfAssignedJobDetailsData.memoNumber) {
                        index = i;
                        break;
                    }
                }
                if (index === -1) {
                    alert("Something gone wrong while removing the row");
                }
                //           console.log( this.pfAssignedJobDetailsDataList );

                this.pfAssignedJobDetailsDataList.splice(index, 1);

                //            console.log( this.pfAssignedJobDetailsDataList );
                this.countOfAssignLR = 0;
                this.sumOfAssignLR = 0;
                for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                    this.countOfAssignLR = +this.countOfAssignLR + 1;
                    this.sumOfAssignLR = +this.sumOfAssignLR + +this.pfAssignedJobDetailsDataList[i].amount;
                }


            }

        } else {
            //    console.log( 'b' );
            let index = -1;
            //For removing from tempstore
            for (let i = 0; i < this.tempstore.length; i++) {
                if (this.tempstore[i].memoNumber === pfAssignedJobDetailsData.memoNumber) {
                    index = i;
                    break;
                }
            }
            if (index === -1) {
                alert("Something gone wrong");
            }
            this.tempstore.splice(index, 1);
            //For removing from pfAssignedJobDetailsDataList
            for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                if (this.pfAssignedJobDetailsDataList[i].memoNumber === pfAssignedJobDetailsData.memoNumber) {
                    index = i;
                    break;
                }
            }
            if (index === -1) {
                alert("Something gone wrong");
            }
            this.pfAssignedJobDetailsDataList.splice(index, 1);
            //        console.log( this.tempstore );
            //            this.pfAssignedJobDetailsDataList=[];
            //            this.pfAssignedJobDetailsDataList.push(this.tempstore);
            //       console.log( this.pfAssignedJobDetailsDataList );
            //for foooter in Assign Table
            this.countOfAssignLR = 0;
            this.sumOfAssignLR = 0;
            for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                this.countOfAssignLR = +this.countOfAssignLR + 1;
                this.sumOfAssignLR = +this.sumOfAssignLR + +this.pfAssignedJobDetailsDataList[i].amount;
            }

            this.modelAllRemove = (this.pfCollectionManDetailsDataList.find(pfCollectionManDetailsData => pfCollectionManDetailsData.memoNumber === pfAssignedJobDetailsData.memoNumber));
            if (this.modelAllRemove == null) {
                //            console.log( this.pfCollectionManDetailsDataList );
                this.pfCollectionManDetailsDataList.push(pfAssignedJobDetailsData);

                //for foooter in Collection Table
                this.countOFMemosCollectionTable = 0;
                this.sumOFMemosCollectionTable = 0;
                for (let i = 0; i < this.pfCollectionManDetailsDataList.length; i++) {
                    this.countOFMemosCollectionTable = this.countOFMemosCollectionTable + 1;
                    this.sumOFMemosCollectionTable = this.sumOFMemosCollectionTable + +(this.pfCollectionManDetailsDataList[i].amount);
                }

            }
        }
    }
    callTempoForLess() {
        //        commented by basha on 10/08/2020
        //        this.isLessClicked = true;

        this.isLessClicked = false;
        this.viewNextBtn = false;
        this.showLessLbl = true;
        this.nextEntryType = 'Less Entry';
        //    console.log( 'sa1' );
        this.showChqLbl = false;
        this.showNEFTLbl = false;
        this.showCashLbl = false;
        this.showNextBtnInAssign = false;
        this.viewShowNexInAssingBtn2 = true;
        this.lessEntriesDataTableDataList = [];
        this.countOfLessLR = 0;
        this.sumOfLessAmt = 0;
        //  console.log( this.userDataDtosLessTemp );
        //PFCHG_V5
        this.showDRAccLbl = false;
        this.showUPILbl = false;

    }
    callTempoForLessForDataTable() {
        this.isLessClicked = true;
        this.getLessDetailsOnClickFromTemp();
    }




    getLessDetailsOnClickFromTemp() {
        //    console.log( '' );
        this.isEdit = false;
        let totalLess = this.lblTotLessSummaryValue == '' ? 0 : this.lblTotLessSummaryValue;
        $("#" + this.pageId + "totalLess").val(totalLess);
        this.countOfLessLR = 0;
        this.sumOfLessAmt = 0;
        this.lessEntriesDataTableDataList = [];
        this.newAttribute = {};
        // console.log( this.userDataDtosLessTemp );

        for (let i = 0; i < this.userDataDtosLessTemp.length; i++) {
            let balanceAmt = this.userDataDtosLessTemp[i].amount - (this.userDataDtosLessTemp[i].paymentAmt == null ? 0.0 : this.userDataDtosLessTemp[i].paymentAmt);
            this.newAttribute.chqType = this.userDataDtosLessTemp[i].memoNumber;
            this.newAttribute.chqNumber = this.userDataDtosLessTemp[i].paymentType;
            this.newAttribute.chqAmount = this.userDataDtosLessTemp[i].paymentAmt;
            this.newAttribute.cashMemoAmt = this.userDataDtosLessTemp[i].amount;
            this.newAttribute.balance = balanceAmt;
            this.newAttribute.mainStation = this.userDataDtosLessTemp[i].mainStation;
            this.newAttribute.consigneeId = this.userDataDtosLessTemp[i].consigneeId;
            this.newAttribute.consigneeName = this.userDataDtosLessTemp[i].partyName;
            this.lessEntriesDataTableDataList.push(this.newAttribute);
            this.countOfLessLR = +this.countOfLessLR + 1;
            this.sumOfLessAmt = +this.sumOfLessAmt + +this.newAttribute.chqAmount;
            this.newAttribute = {};
        }
    }
    callTempoForChq() {
        //   console.log( 'sa2' );
        this.viewByCash = true;
        this.viewByCashs = false;
        this.viewByChequeNumber = true;
        this.viewByChequeDate = true;
        this.viewByConsigneeBankName = true;
        this.viewByReferenceNumber = false;
        this.viewByConsigneeName = true;
        this.showNextBtn = true;
        this.payType = "Cheque";
        this.payMode = "completedcheque";
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.selectedConsigneeId = 0;
        this.controlConsigneeBankName.reset();
        this.showForCheckandNeft = true;
        this.viewNewBankName = false;

        //
        //        this.isLessClicked = true;
        this.viewNextBtn = false;
        this.showLessLbl = false;
        this.nextEntryType = 'Payment Entry';
        //    console.log( 'sa222' );
        this.showChqLbl = true;
        this.showNEFTLbl = true;
        this.showCashLbl = true;
        this.showNextBtnInAssign = false;
        this.viewShowNexInAssingBtn2 = true;
        this.paymentAmountEntriesDataList = [];
        this.countOfPaymentMemo = 0;
        this.sumOfMemoAmtPayment = 0;
        this.sumOfLessAmtPayment = 0;
        this.sumOfBalanceAmtPayment = 0;
        this.getChqDetailsOnClickFromTemp();
        //PFCHG_V5
        this.showDRAccLbl = true;
        this.showUPILbl = true;
    }
    getChqDetailsOnClickFromTemp() {
        this.isPaymentClicked = true;
        this.isEdit = false;
        let totalLess = this.lblTotChqAmtSummaryValue == '' ? 0 : this.lblTotChqAmtSummaryValue;
        $("#" + this.pageId + "totalAmountForPayment").val(totalLess);

        this.newAttributeForPayment = {};
        for (let i = 0; i < this.userDataDtosPaymentChqTemp.length; i++) {
            this.newAttributeForPayment.chqType = this.userDataDtosPaymentChqTemp[i].memoNumber;
            this.newAttributeForPayment.chqNumber = "NA"
            this.newAttributeForPayment.chqAmount = this.userDataDtosPaymentChqTemp[i].chqAmt == null ? 0 : this.userDataDtosPaymentChqTemp[i].chqAmt;
            this.newAttributeForPayment.cashMemoAmt = this.userDataDtosPaymentChqTemp[i].amount;
            this.newAttributeForPayment.balance = this.userDataDtosPaymentChqTemp[i].paymentAmt;
            //            this.newAttributeForPayment.mainStation = "NA";
            this.newAttributeForPayment.mainStation = this.userDataDtosPaymentChqTemp[i].mainStation;
            this.newAttributeForPayment.chequeno = this.userDataDtosPaymentChqTemp[i].chequeNumber;
            this.newAttributeForPayment.bankname = this.userDataDtosPaymentChqTemp[i].bankName;
            this.getChequeDate = this.userDataDtosPaymentChqTemp[i].chequeDate;
            if (this.getChequeDate == null || this.getChequeDate == '') {
                this.getChequeDate = null;
            } else {
                this.getChequeDate = this.datePipe.transform(this.getChequeDate, "yyyy-MM-dd");
            }
            this.newAttributeForPayment.chequedate = this.getChequeDate;
            this.newAttributeForPayment.remarks = this.userDataDtosPaymentChqTemp[i].features;
            this.newAttributeForPayment.consigneeId = this.userDataDtosPaymentChqTemp[i].consigneeId;
            this.newAttributeForPayment.consigneeName = this.userDataDtosPaymentChqTemp[i].partyName;
            this.newAttributeForPayment.paymenttype = this.userDataDtosPaymentChqTemp[i].type
            this.paymentAmountEntriesDataList.push(this.newAttributeForPayment);
            this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
            this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
            this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
            this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
            this.newAttributeForPayment = {};
            this.getChequeDate = '';
        }
        $("#" + this.pageId + "paymentMode").val('cheque');
    }

    callTempoForNEFT() {
        this.viewByCash = true;
        this.viewByCashs = false;
        this.viewByChequeNumber = false;
        this.viewByChequeDate = false;
        this.viewByConsigneeBankName = true;
        this.viewByReferenceNumber = true;
        this.viewByConsigneeName = true;
        this.showNextBtn = true;
        this.payType = "NEFT/IMPS";
        this.payMode = "completedneft";
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.selectedConsigneeId = 0;
        this.controlConsigneeBankName.reset();
        this.showForCheckandNeft = true;
        this.viewNewBankName = false;
        //
        //        this.isLessClicked = true;
        this.viewNextBtn = false;
        this.showLessLbl = false;
        this.nextEntryType = 'Payment Entry';
        //   console.log( 'sa222' );
        this.showChqLbl = true;
        this.showNEFTLbl = true;
        this.showCashLbl = true;
        this.showNextBtnInAssign = false;
        this.viewShowNexInAssingBtn2 = true;
        this.paymentAmountEntriesDataList = [];
        this.countOfPaymentMemo = 0;
        this.sumOfMemoAmtPayment = 0;
        this.sumOfLessAmtPayment = 0;
        this.sumOfBalanceAmtPayment = 0;
        this.getNEFTDetailsOnClickFromTemp();
        //PFCHG_V5
        this.showDRAccLbl = true;
        this.showUPILbl = true;
    }
    getNEFTDetailsOnClickFromTemp() {
        this.isPaymentClicked = true;
        this.isEdit = false;
        let totalLess = this.lblTotNeftAmtSummaryValue == '' ? 0 : this.lblTotNeftAmtSummaryValue;
        $("#" + this.pageId + "totalAmountForPayment").val(totalLess);
        this.newAttributeForPayment = {};
        for (let i = 0; i < this.userDataDtosPaymentNeftTemp.length; i++) {
            this.newAttributeForPayment.chqType = this.userDataDtosPaymentNeftTemp[i].memoNumber;
            this.newAttributeForPayment.chqNumber = "NA"
            this.newAttributeForPayment.chqAmount = this.userDataDtosPaymentNeftTemp[i].chqAmt == null ? 0 : this.userDataDtosPaymentNeftTemp[i].chqAmt;
            this.newAttributeForPayment.cashMemoAmt = this.userDataDtosPaymentNeftTemp[i].amount;
            this.newAttributeForPayment.balance = this.userDataDtosPaymentNeftTemp[i].paymentAmt;
            //            this.newAttributeForPayment.mainStation = "NA";
            this.newAttributeForPayment.mainStation = this.userDataDtosPaymentNeftTemp[i].mainStation;
            this.newAttributeForPayment.chequeno = this.userDataDtosPaymentNeftTemp[i].chequeNumber;
            this.newAttributeForPayment.bankname = this.userDataDtosPaymentNeftTemp[i].bankName;
            this.getChequeDate = this.userDataDtosPaymentNeftTemp[i].chequeDate;
            if (this.getChequeDate == null || this.getChequeDate == '') {
                this.getChequeDate = null;
            } else {
                this.getChequeDate = this.datePipe.transform(this.getChequeDate, "yyyy-MM-dd");
            }
            this.newAttributeForPayment.chequedate = this.getChequeDate;
            this.newAttributeForPayment.remarks = this.userDataDtosPaymentNeftTemp[i].features;
            this.newAttributeForPayment.consigneeId = this.userDataDtosPaymentNeftTemp[i].consigneeId;
            this.newAttributeForPayment.consigneeName = this.userDataDtosPaymentNeftTemp[i].partyName;
            this.newAttributeForPayment.paymenttype = this.userDataDtosPaymentNeftTemp[i].type
            this.paymentAmountEntriesDataList.push(this.newAttributeForPayment);
            this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
            this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
            this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
            this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
            this.newAttributeForPayment = {};
            this.getChequeDate = '';

        }
        $("#" + this.pageId + "paymentMode").val('neft');
    }


    callTempoForCash() {
        this.viewByCash = true;
        this.viewByCashs = true;
        this.viewByChequeNumber = false;
        this.viewByChequeDate = false;
        this.viewByConsigneeBankName = false;
        this.viewByReferenceNumber = false;
        this.viewByConsigneeName = false;
        this.showNextBtn = true;
        this.controlConsigneeBankName.reset();
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.selectedConsigneeId = 0;
        this.payType = "Cash";
        this.payMode = "completedcash";
        this.showForCheckandNeft = false;
        this.viewNewBankName = false;
        $("#" + this.pageId + "paymentMode").val('cash');
        this.isEdit = false;
        let totalLess = this.lblTotCashAmtSummaryValue == '' ? 0 : this.lblTotCashAmtSummaryValue;
        $("#" + this.pageId + "totalAmountForPayment").val(totalLess);
        this.setCashAmt();

        //    console.log( this.getCashValDto );
    }


    setCashAmt() {
        for (let i = 0; i < this.userDataDtosPaymentCashTemp.length; i++) {
            this.twoThousandRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof2000Count;
            $("#" + this.pageId + "twoThousandRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF2000);
            $("#" + this.pageId + "fiveHundredRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF500);
            $("#" + this.pageId + "twoHundredRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF200);
            $("#" + this.pageId + "hundredRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF100);
            $("#" + this.pageId + "fiftyRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF50);
            $("#" + this.pageId + "twentyRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF20);
            $("#" + this.pageId + "tenRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF10);
            $("#" + this.pageId + "fiveRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF5);
            $("#" + this.pageId + "twoRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF2);
            $("#" + this.pageId + "oneRupee").val(this.userDataDtosPaymentCashTemp[i].nfOF1);
            $("#" + this.pageId + "twoThousandRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof2000Count);
            $("#" + this.pageId + "fiveHundredRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof500Count);
            $("#" + this.pageId + "twoHundredRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof200Count);
            $("#" + this.pageId + "hundredRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof100Count);
            $("#" + this.pageId + "fiftyRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof50Count);
            $("#" + this.pageId + "twentyRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof20Count);
            $("#" + this.pageId + "tenRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof10Count);
            $("#" + this.pageId + "fiveRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof5Count);
            $("#" + this.pageId + "twoRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof2Count);
            $("#" + this.pageId + "oneRupeeCount").val(this.userDataDtosPaymentCashTemp[i].nfof1Count);
            $("#" + this.pageId + "totalRupeeDenomination").val(this.userDataDtosPaymentCashTemp[i].grandTotal);
        }
    }
    setCashAmtModel() {
        for (let i = 0; i < this.userDataDtosPaymentCashTemp.length; i++) {
            this.twoThousandRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof2000Count;
            this.fiveHundredRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof500Count;
            this.twoHundredRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof200Count;
            this.hundredRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof100Count;
            this.fiftyRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof50Count;
            this.twentyRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof20Count;
            this.tenRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof10Count;
            this.fiveRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof5Count;
            this.twoRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof2Count;
            this.oneRupeeCountModel = this.userDataDtosPaymentCashTemp[i].nfof1Count;
            this.twoThousandRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF2000;
            this.fiveHundredRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF500;
            this.twoHundredRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF200;
            this.hundredRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF100;
            this.fiftyRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF50;
            this.twentyRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF20;
            this.tenRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF10;
            this.fiveRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF5;
            this.twoRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF2;
            this.oneRupeeModel = this.userDataDtosPaymentCashTemp[i].nfOF1;
        }
    }
    clearALLFieldsAfterCompelte() {
        $("#" + this.pageId + "paymentEntryFinishBtn").prop('disabled', false);
        this.pfAssignedJobDetailsDataList = [];
        this.tempstore = [];
        this.tempStoreDup = new CashMemoDto();
        this.controlCollectionMan.reset();
        this.controlCollectionManAssign.reset();
        $("#" + this.pageId + "consigneeBankName").val('');
        this.selectedConsigneeId = 0;
        $("#" + this.pageId + "newBankName").val('');
        this.controlConsigneeBankName.reset();
        this.viewNewBankName = false;
        this.clearAllAfterBtnFinish();
        this.userDataDtoForStatusRet = '';
        this.memoNum = '';
        this.selectedActionType = '';
        this.selectedEntryType = '';
        this.selectedCodeForManual = '';
        this.selectedMemoNumberForManual = '';
        this.selectedMemoNumberForAutomatic = '';
        this.modelForSearched = '';
        this.letValueofCollectionMan = new CashMemoDto();
        this.getsearchInCollectionManTableFoDup = new CashMemoDto();
        this.searchedMemoForDup = new CashMemoDto();
        this.searchedMemoInfo = new CashMemoDto();
        this.letValueofSearchInCollectionManTableFoDup = new CashMemoDto();
        this.searchedMemoForMemoAlreadyExist = new CashMemoDto();
        this.getsearchInCollectionManTableForAlreadyExist = new CashMemoDto();
        this.letValueofSearchInCollectionManTableFoAlreadyExist = new CashMemoDto();
        this.setValueofSearchInCollectionManTableFoAlreadyExistToParty = new CashMemoDto();
        this.getValueofSearchInCollectionManTableFoAlreadyExistToParty = new CashMemoDto();
        this.letValueofSearchInCollectionManTableFoAlreadyExistParty = new CashMemoDto();
        this.getCashValDto = new CashMemoDto();
        this.modelAlreadyExist = '';
        this.modelDup = '';
        this.countOfAssignLR = 0;
        this.sumOfAssignLR = 0;
        this.consigneeeName = '';
        this.consigneeExist = false;
        this.countOFMemosCollectionTable = 0;
        this.sumOFMemosCollectionTable = 0;
        this.newAttForColTable = {};
        this.pfCollectionManDetailsDataList = [];
        this.pfCollectionManDetailsDataListForSearch = [];
        this.userDataDtoForLRAssign = new UserDataDto();
        this.selectedCollectionMan = '';
        this.selectedCollectionManAssign = '';
        this.listOfLrNumber = [];
        this.userDataDtoForLRAssignSRDStock = new UserDataDto();
        this.userDataDtoForLRStatusBack = new UserDataDto();
        this.userDataDtoForLRToPartySRDStock = new UserDataDto();
        this.userDataDtoForLRToParty = new UserDataDto();
        this.userDataDtoForLRFinish = new UserDataDto();
        this.selectedCollectionMan = '';
        this.enteredCompletedOnDate = '';
        $("#" + this.pageId + "completedOnDate").val('');
        this.enteredTotalLess = '';
        $("#" + this.pageId + "totalLess").val('');
        this.enteredLessAmt = '';
        this.enteredAmt = 0.0;
        this.clickedRowAmt = 0.0;
        this.totalAmt = 0.0;
        this.totalBalAmt = 0.0;
        this.enteredAmt = 0.0;
        this.clickedRowAmt = 0.0;
        this.balanceAmountFinal = 0.0;
        this.amountToBeCollected = 0.0;
        this.letValueofMemoFrmAssingedTableAny = [];
        this.lrDtoForGroupStation = new LRDto();
        this.lrDtoForGroupStationRet = '';
        this.lrDtoForGroupStationSet = new LRDto();
        this.newAttribute = {};
        this.newAttributeForTDS = {};
        this.newAttributeForPayment = {};
        this.newAttributeForConsigneeWiseBalance = {};
        this.editTableId = null;
        this.editTableIdForPayment = null;
        this.slashSplitMemo = [];
        this.maintenanceCompletedLessLrs = [];
        this.maintenanceCompletedLrs = [];
        this.countOfLessLR = 0;
        this.sumOfLessAmt = 0;
        this.editlessamount = 0;
        this.getConsingeewisememoDto = new CashMemoDto();
        this.getConsingeewisememoAny = '';
        this.isDuplicateBulkLess = false;
        this.tdsBulkTotMemoAmt = 0.0;
        this.listOfMemoNumber = [];
        this.cashMemoDtoForTDS = new CashMemoDto();
        this.cashMemoDtoForTDSRet = [];
        this.statusBackMemoNumber = '';
        this.letValueofSearchInAssingedTableForStatusBack = [];
        this.userDataDtosLessTemp = [];
        this.userDataDtosPaymentChqTemp = [];
        this.userDataDtosPaymentNeftTemp = [];
        this.userDataDtoForStatusBack = new UserDataDto();
        this.userDataDtoForStatusBackRet = '';
        this.userDataDtoForStatusRet = '';
        this.lblDelhiLessValue = '';
        this.lblMumLessValue = '';
        this.lblGujLessValue = '';
        this.lblPjbLessValue = '';
        this.lblTDSLessValue = '';
        this.lblClaimLessValue = '';
        this.lblTotLessSummaryValue = '';
        this.lblTotChqAmtSummaryValue = '';
        this.lblTotNeftAmtSummaryValue = '';
        this.lblTotCashAmtSummaryValue = '';
        this.userDataDtoForLess = [];
        this.userDataDtoForPayment = [];
        this.userDataDtoForCash = [];
        this.userDataDtoForLessDetails = new UserDataDto();
        this.userDataDtoForPaymentDetails = new UserDataDto();
        this.userDataDtoForPaymentCashDetails = new UserDataDto();
        this.isLessClicked = false;
        this.isPaymentClicked = false;
        this.viewNextBtn = false;
        this.userDataDtoForBankDetails = new UserDataDto();
        this.userDataDtoForBankDetailsRet = new UserDataDto();
        this.viewNewBankName = false;
        this.selectedEntryTypeForPayment = '';
        this.paymentLessManualView = true;
        this.paymentLessAutomaticView = false;
        this.viewByConsigneeName = false;
        this.selectedPaymentMode = '';
        this.showNextBtn = false;
        this.payType = '';
        this.payMode = '';
        this.letValueofMemoExistInPaymentEntryTableAny = [];
        this.countOfAssignLR = 0;
        this.sumOfAssignLR = 0;
        this.userDataDtoForChq = new UserDataDto();
        this.userDataDtoForChqRet = '';
        this.balanceAmtInAddingTable = 0;
        this.getChequeDate = '';
        this.countOfPaymentMemo = 0;
        this.sumOfMemoAmtPayment = 0;
        this.sumOfLessAmtPayment = 0;
        this.sumOfBalanceAmtPayment = 0;
        this.editMemoAmtForPayment = 0;
        this.editLessAmtForPayment = 0;
        this.editBalanceAmtForPayment = 0;
        this.selectedBankName = '';
        this.userDataDtoForBankName = new UserDataDto();
        this.enteredNewBankName = '';
        this.enteredCheckNumber = '';
        this.enteredRefNumber = '';
        this.viewFinishBtn = true;
        this.viewShowNexInAssingBtn = false;
        this.viewShowNexInAssingBtn2 = false;
        this.showNextBtnInAssign = false;
        this.showForCheckandNeft = false;
        this.userDataDtoForFinishDetails = new UserDataDto();
        this.isDuplicateChq = false;
        this.lrDtoForValidateChq = new LRDto();
        this.retrunStatus = '';
        this.diffLessRet = '';
        this.validateChqRet = '';
        this.getChallanPrintValDto = new CashMemoDto();
        this.lessDescOpt = [];
        this.lessDescOptSent = [];
        this.viewChallanRptPrint = false;
        this.collectionManTP = '';
        this.countTp = '';
        this.stationForCode = '';
        this.consigneeWiseBalancelDto = new CashMemoDto();
        this.consigneeWiseBalancelDtoForUpdate = new CashMemoDto();
        this.consigneeWiseBalancelRet = '';
        this.countOfConsingeeWiseMemo = 0;
        this.sumOfConsingeeWiseTotalCashMemo = 0;
        this.sumOfConsingeeWiseGrandTotal = 0;
        this.sumOfConsingeeWisePartyLessDed = 0;
        this.sumOfConsingeeWiseAdvChqAmt = 0;
        this.sumOfConsingeeWiseNeftAmtl = 0;
        this.sumOfConsingeeWiseBalance = 0;
        this.countOFMemosCollectionTable = 0;
        this.sumOFMemosCollectionTable = 0;
        this.setCollectionDetails = 'old';
        this.setAssingedDetails = 'old';
        this.newAttForColTable = {};
        this.modelAllRemove = '';
        this.tempStoreForRemove = new CashMemoDto();
        this.consigneeWiseTrigger = 'old';
        this.showLessLbl = false;
        this.showChqLbl = false;
        this.showNEFTLbl = false;
        this.countOFMemosCollectionTable = 0;
        this.sumOFMemosCollectionTable = 0;
        this.isLessClicked = true;
        this.isEdit = false;
        this.addInTable = false;
        this.viewNextBtn = false;
        this.grandTotalForCash = '';
        this.showCashLbl = false;
        this.userDataDtosPaymentCashTemp = [];
        this.showCashLblAmt = false;
        $("#" + this.pageId + "actionType").val('');
        $("#" + this.pageId + "collectionMan").val('');
        this.enteredCollectionMan = '';
        $("#" + this.pageId + "entryType").val('manual');
        $("#" + this.pageId + "station").val('');
        $("#" + this.pageId + "codeForManual").val('');
        $("#" + this.pageId + "memoNumberForManual").val('');
        $("#" + this.pageId + "memoNumberForAutomatic").val('');
        this.manualView = true;
        this.automaticView = false;
        $("#" + this.pageId + "completedOnDa t e").val('');
        this.tdyCompletedOnDate = '';
        $("#" + this.pageId + "collectionManAssig n ").val('');
        this.enteredCollectionManAssign = '';
        $("#" + this.pageId + "collectionManToParty").val('');
        this.enteredCollectionManToParty = '';
        $("#" + this.pageId + "consigneeName").val('');
        $("#" + this.pageId + "totalLess").val('');
        $("#" + this.pageId + "totalMemoForTDS").val('');
        $("#" + this.pageId + "memoAmountForTDS").val('');
        $("#" + this.pageId + "lessForTDS").val('');
        $("#" + this.pageId + "tdsLessTwoPer").val('');
        $("#" + this.pageId + "entryLessEntryType").val('entryLessManual');
        $("#" + this.pageId + "entryLessStation").val('');
        this.entryLessManualView = true;
        this.entryLessAutomaticView = false;
        $("#" + this.pageId + "codeForManualLess").val('');
        $("#" + this.pageId + "memoNumberForManualLess").val('');
        $("#" + this.pageId + "memoNumberForAutomaticLess").val('');
        $("#" + this.pageId + "lessType").val('');
        $("#" + this.pageId + "entryLessAmount").val('');
        $("#" + this.pageId + "hiddenIndexForLessEntry").val('');
        $("#" + this.pageId + "paymentMode").val('select');
        $("#" + this.pageId + "consigneeNameForPayment").val('');
        $("#" + this.pageId + "totalAmountForPayment").val('');
        $("#" + this.pageId + "chequeNumber").val('');
        $("#" + this.pageId + "chequeDate").val('');
        $("#" + this.pageId + "consigneeNameForPayment").val('');
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        $("#" + this.pageId + "referenceNumber").val('');
        $("#" + this.pageId + "totalRupeeDenomination").val('');
        $("#" + this.pageId + "entryLessPaymentEntry").val('paymentLessManual');
        $("#" + this.pageId + "paymentLessStn").val('');
        $("#" + this.pageId + "codeForManualLessPayment").val('');
        $("#" + this.pageId + "memoNumberForManualLessPayment").val('');
        $("#" + this.pageId + "memoNumberForAutomaticLessPayment").val('');
        $("#" + this.pageId + "paymentLessAmount").val('');
        $("#" + this.pageId + "hiddenIndexForPaymentEntry").val('');
        $("#" + this.pageId + "cashmemoNumberForStatusBack").val('');
        this.viewByCash = false;
        this.viewByCashs = false;
        this.viewByChequeNumber = false;
        this.viewByChequeDate = false;
        this.viewByConsigneeBankName = false;
        this.viewByReferenceNumber = false;
        this.viewByConsigneeName = false;
        this.modelConsigneeName = '';
        this.modelConsigneeNameForPayment = '';
        this.showSpinner = false;
        this.selectTodayCompletedOnDate();
        this.showResetBtn = true;
        this.lessEntriesDataTableDataList = [];
        this.paymentAmountEntriesDataList = [];
        this.modelStationForPayment = '';
        this.modelStationForPaymentAmt = '';
        this.modelStationForLess = '';
        this.modelStationForLessAmt = '';
        this.modelStationName = '';
        this.setLessEntryDetails = 'old';
        this.setPaymentEntryDetails = 'old';
        this.modelPartyBankName = '';
        this.twoThousandRupeeCountModel = '';
        this.twoThousandRupeeModel = '';
        this.fiveHundredRupeeCountModel = '';
        this.fiveHundredRupeeModel = '';
        this.twoHundredRupeeCountModel = '';
        this.twoHundredRupeeModel = '';
        this.hundredRupeeCountModel = '';
        this.hundredRupeeModel = '';
        this.fiftyRupeeCountModel = '';
        this.fiftyRupeeModel = '';
        this.twentyRupeeCountModel = '';
        this.twentyRupeeModel = '';
        this.tenRupeeCountModel = '';
        this.tenRupeeModel = '';
        this.fiveRupeeCountModel = '';
        this.fiveRupeeModel = '';
        this.twoRupeeCountModel = '';
        this.twoRupeeModel = '';
        this.oneRupeeCountModel = '';
        this.oneRupeeModel = '';
        this.modelCollectionManName = '';
        $("#" + this.pageId + "collectionManStatusBackAssign").val('');
        this.userDataDtoForLRStatusBackAssign = new UserDataDto();
        this.modelCollectionManStatusBackAssign = null;
        this.selectedCollectionManStatusBackAssign = null;

        //        window.location.href = "/cashmemo/paymentFollowup";
        //PFCHG_V5
        this.showDRAccLbl = false;
        this.showUPILbl = false;
        this.lblTotDebtAcccAmtSummaryValue = '';
        this.lblTotUPIAmtSummaryValue = '';
        this.sumOfConsingeeWiseDebitAcAmtl = 0;
        this.sumOfConsingeeWiseUPIAmtl = 0;
        this.userDataDtosPaymentDebitAccTemp = [];
        this.userDataDtosPaymentUPITemp = [];
    }

    resetAll() {
        this.clearALLFieldsAfterCompelte();
        this.consigneeWiseDetails = false;
        this.showResetBtn = false;
        this.modelActionType = '';
        //        console.log(this.setLessEntryDetails);
    }



    // if (this.selectedCollectionManStatusBackAssign != null && this.selectedCollectionManStatusBackAssign == '') {
    //     swal({
    //         title: "Mandatory Fields",
    //         text: "Please Select Collection Man Assign to Proceed",
    //         icon: "warning",
    //         closeOnClickOutside: false,
    //         closeOnEsc: false,
    //     });
    // } else{
    //     this.callForStatusBackAssignMemo();

    // }

    //For Asssign In Pop up btn 
    validateCollectionManStatusBackAssign() {
        // console.log("this.selectedCollectionManStatusBackAssign, this.modelCollectionManStatusBackAssign >> validateCollectionManStatusBackAssign() ");
        // console.log(this.selectedCollectionManStatusBackAssign, this.modelCollectionManStatusBackAssign);
        this.selectedCollectionManStatusBackAssign = this.modelCollectionManStatusBackAssign;
        if (this.selectedCollectionManStatusBackAssign == null || this.selectedCollectionManStatusBackAssign == '' || this.selectedCollectionManStatusBackAssign == undefined) {
            swal({
                title: "Collection Man Not Found",
                text: "Please Select A Collection Man",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.callForStatusBackAssignMemo();
        }
    }

    callForStatusBackAssignMemo() {
        if (this.chkPendingMemosExist()) {
            this.removePendingMemos();
        } else {
            swal({
                title: "Status Back & Assign",
                text: "Sure U want to take status back for the selected cashmemos and Assigned to '" + this.modelCollectionManStatusBackAssign + " '",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
                buttons: ["No", "Yes"],
            }).then((statusBacAssignkMemo) => {
                if (statusBacAssignkMemo) {
                    //Pending
                    this.userDataDtoForLRStatusBackAssign = new UserDataDto();
                    this.selectedCollectionManStatusBackAssign = this.modelCollectionManStatusBackAssign;
                    this.userDataDtoForLRStatusBackAssign.collectioMan = this.selectedCollectionManStatusBackAssign;
                    this.userDataDtoForLRStatusBackAssign.mode = 'statusBackAndAssign';
                    this.listOfLrNumber = [];
                    for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
                        this.listOfLrNumber.push(this.pfAssignedJobDetailsDataList[i].memoNumber);
                    }
                    //                    console.log( this.listOfLrNumber );
                    this.userDataDtoForLRStatusBackAssign.list = this.listOfLrNumber;
                    this.updateCashMemoInfo(this.userDataDtoForLRStatusBackAssign);
                    // console.log("this.userDataDtoForLRStatusBackAssign");
                    // console.log(this.userDataDtoForLRStatusBackAssign);
                }

            });
        }
    }
    clearForStatusBackAssign() {
        this.userDataDtoForLRStatusBackAssign = new UserDataDto();
        this.listOfLrNumber = [];
        this.modelCollectionManStatusBackAssign = null;
        this.selectedCollectionManStatusBackAssign = null;
        $("#" + this.pageId + "collectionManStatusBackAssign").val('');
        this.selectTodayCompletedOnDate();
    }

    //PFCHG_V3
    whatsAppFlowForCollectionClose = () => {
        this.userDataDtoForWhatsApp = new UserDataDto();
        this.listOfMemoNumber = [];
        for (let i = 0; i < this.pfAssignedJobDetailsDataList.length; i++) {
            this.listOfMemoNumber.push(this.pfAssignedJobDetailsDataList[i].memoNumber);
        }
        this.userDataDtoForWhatsApp.list = this.listOfMemoNumber;
        this.userDataDtoForWhatsApp.city = this.userDataDtoReturnSession.office;
        this.userDataDtoForWhatsApp.mainStation = this.userDataDtoReturnSession.mainStation;

        this.memoService.whatsAppNotificationFlowForDestCollectionClose(this.userDataDtoForWhatsApp).subscribe(
            (response) => {
                this.showSpinner = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => console.log(error.json()),
            () => console.log('done');
    };

    callTempoForDebtAcc() {
        this.viewByCash = true;
        this.viewByCashs = false;
        this.viewByChequeNumber = false;
        this.viewByChequeDate = false;
        this.viewByConsigneeBankName = false;
        this.viewByReferenceNumber = true;
        this.viewByConsigneeName = true;
        this.showNextBtn = true;
        this.payType = "Debit Account";
        this.payMode = "completeddebitacc";
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.selectedConsigneeId = 0;
        this.controlConsigneeBankName.reset();
        this.showForCheckandNeft = true;
        this.viewNewBankName = false;
        //
        //        this.isLessClicked = true;
        this.viewNextBtn = false;
        this.showLessLbl = false;
        this.nextEntryType = 'Payment Entry';
        //   console.log( 'sa222' );
        this.showChqLbl = true;
        this.showNEFTLbl = true;
        this.showCashLbl = true;
        this.showNextBtnInAssign = false;
        this.viewShowNexInAssingBtn2 = true;
        this.paymentAmountEntriesDataList = [];
        this.countOfPaymentMemo = 0;
        this.sumOfMemoAmtPayment = 0;
        this.sumOfLessAmtPayment = 0;
        this.sumOfBalanceAmtPayment = 0;
        this.getDebitAccDetailsOnClickFromTemp();
        //PFCHG_V5
        this.showDRAccLbl = true;
        this.showUPILbl = true;
    }

    callTempoForUPI() {
        this.viewByCash = true;
        this.viewByCashs = false;
        this.viewByChequeNumber = false;
        this.viewByChequeDate = false;
        this.viewByConsigneeBankName = false;
        this.viewByReferenceNumber = true;
        this.viewByConsigneeName = false;
        this.showNextBtn = true;
        this.payType = "UPI";
        this.payMode = "completedupi";
        $("#" + this.pageId + "consigneeBankName").val('');
        $("#" + this.pageId + "newBankName").val('');
        this.selectedConsigneeId = 0;
        this.controlConsigneeBankName.reset();
        this.showForCheckandNeft = true;
        this.viewNewBankName = false;
        //this.isLessClicked = true;
        this.viewNextBtn = false;
        this.showLessLbl = false;
        this.nextEntryType = 'Payment Entry';
        //   console.log( 'sa222' );
        this.showChqLbl = true;
        this.showNEFTLbl = true;
        this.showCashLbl = true;
        this.showNextBtnInAssign = false;
        this.viewShowNexInAssingBtn2 = true;
        this.paymentAmountEntriesDataList = [];
        this.countOfPaymentMemo = 0;
        this.sumOfMemoAmtPayment = 0;
        this.sumOfLessAmtPayment = 0;
        this.sumOfBalanceAmtPayment = 0;
        this.getUPIDetailsOnClickFromTemp();
        //PFCHG_V5
        this.showDRAccLbl = true;
        this.showUPILbl = true;
    }
    //PFCHG_V5
    calculateTotalDebitAccPayment() {
        let amtRecDebitAcc = 0.0;
        for (let i = 0; i < this.userDataDtosPaymentDebitAccTemp.length; i++) {
            amtRecDebitAcc = +amtRecDebitAcc + +this.userDataDtosPaymentDebitAccTemp[i].paymentAmt;
        }
        this.lblTotDebtAcccAmtSummaryValue = amtRecDebitAcc;
    }

    calculateTotalUPIPayment() {
        let amtRecUPI = 0.0;
        for (let i = 0; i < this.userDataDtosPaymentUPITemp.length; i++) {
            amtRecUPI = +amtRecUPI + +this.userDataDtosPaymentUPITemp[i].paymentAmt;
        }
        this.lblTotUPIAmtSummaryValue = amtRecUPI;
    }
    getDebitAccDetailsOnClickFromTemp() {
        this.isPaymentClicked = true;
        this.isEdit = false;
        let totalLess = this.lblTotDebtAcccAmtSummaryValue == '' ? 0 : this.lblTotDebtAcccAmtSummaryValue;
        $("#" + this.pageId + "totalAmountForPayment").val(totalLess);
        this.newAttributeForPayment = {};
        for (let i = 0; i < this.userDataDtosPaymentDebitAccTemp.length; i++) {
            this.newAttributeForPayment.chqType = this.userDataDtosPaymentDebitAccTemp[i].memoNumber;
            this.newAttributeForPayment.chqNumber = "NA"
            this.newAttributeForPayment.chqAmount = this.userDataDtosPaymentDebitAccTemp[i].chqAmt == null ? 0 : this.userDataDtosPaymentDebitAccTemp[i].chqAmt;
            this.newAttributeForPayment.cashMemoAmt = this.userDataDtosPaymentDebitAccTemp[i].amount;
            this.newAttributeForPayment.balance = this.userDataDtosPaymentDebitAccTemp[i].paymentAmt;
            //            this.newAttributeForPayment.mainStation = "NA";
            this.newAttributeForPayment.mainStation = this.userDataDtosPaymentDebitAccTemp[i].mainStation;
            this.newAttributeForPayment.chequeno = this.userDataDtosPaymentDebitAccTemp[i].chequeNumber;
            this.newAttributeForPayment.bankname = this.userDataDtosPaymentDebitAccTemp[i].bankName;
            this.getChequeDate = this.userDataDtosPaymentDebitAccTemp[i].chequeDate;
            if (this.getChequeDate == null || this.getChequeDate == '') {
                this.getChequeDate = null;
            } else {
                this.getChequeDate = this.datePipe.transform(this.getChequeDate, "yyyy-MM-dd");
            }
            this.newAttributeForPayment.chequedate = this.getChequeDate;
            this.newAttributeForPayment.remarks = this.userDataDtosPaymentDebitAccTemp[i].features;
            this.newAttributeForPayment.consigneeId = this.userDataDtosPaymentDebitAccTemp[i].consigneeId;
            this.newAttributeForPayment.consigneeName = this.userDataDtosPaymentDebitAccTemp[i].partyName;
            this.newAttributeForPayment.paymenttype = this.userDataDtosPaymentDebitAccTemp[i].type
            this.paymentAmountEntriesDataList.push(this.newAttributeForPayment);
            this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
            this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
            this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
            this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
            this.newAttributeForPayment = {};
            this.getChequeDate = '';

        }
        $("#" + this.pageId + "paymentMode").val('debitacc');
    }

    getUPIDetailsOnClickFromTemp() {
        this.isPaymentClicked = true;
        this.isEdit = false;
        let totalLess = this.lblTotUPIAmtSummaryValue == '' ? 0 : this.lblTotUPIAmtSummaryValue;
        $("#" + this.pageId + "totalAmountForPayment").val(totalLess);
        this.newAttributeForPayment = {};
        for (let i = 0; i < this.userDataDtosPaymentUPITemp.length; i++) {
            this.newAttributeForPayment.chqType = this.userDataDtosPaymentUPITemp[i].memoNumber;
            this.newAttributeForPayment.chqNumber = "NA"
            this.newAttributeForPayment.chqAmount = this.userDataDtosPaymentUPITemp[i].chqAmt == null ? 0 : this.userDataDtosPaymentUPITemp[i].chqAmt;
            this.newAttributeForPayment.cashMemoAmt = this.userDataDtosPaymentUPITemp[i].amount;
            this.newAttributeForPayment.balance = this.userDataDtosPaymentUPITemp[i].paymentAmt;
            //            this.newAttributeForPayment.mainStation = "NA";
            this.newAttributeForPayment.mainStation = this.userDataDtosPaymentUPITemp[i].mainStation;
            this.newAttributeForPayment.chequeno = this.userDataDtosPaymentUPITemp[i].chequeNumber;
            this.newAttributeForPayment.bankname = this.userDataDtosPaymentUPITemp[i].bankName;
            this.getChequeDate = this.userDataDtosPaymentUPITemp[i].chequeDate;
            if (this.getChequeDate == null || this.getChequeDate == '') {
                this.getChequeDate = null;
            } else {
                this.getChequeDate = this.datePipe.transform(this.getChequeDate, "yyyy-MM-dd");
            }
            this.newAttributeForPayment.chequedate = this.getChequeDate;
            this.newAttributeForPayment.remarks = this.userDataDtosPaymentUPITemp[i].features;
            this.newAttributeForPayment.consigneeId = this.userDataDtosPaymentUPITemp[i].consigneeId;
            this.newAttributeForPayment.consigneeName = this.userDataDtosPaymentUPITemp[i].partyName;
            this.newAttributeForPayment.paymenttype = this.userDataDtosPaymentUPITemp[i].type
            this.paymentAmountEntriesDataList.push(this.newAttributeForPayment);
            this.countOfPaymentMemo = +this.countOfPaymentMemo + 1;
            this.sumOfMemoAmtPayment = +this.sumOfMemoAmtPayment + +this.newAttributeForPayment.cashMemoAmt;
            this.sumOfLessAmtPayment = +this.sumOfLessAmtPayment + +this.newAttributeForPayment.chqAmount;
            this.sumOfBalanceAmtPayment = +this.sumOfBalanceAmtPayment + +this.newAttributeForPayment.balance;
            this.newAttributeForPayment = {};
            this.getChequeDate = '';

        }
        $("#" + this.pageId + "paymentMode").val('upi');
    }

}
