<html>
<head>
</head>

<body>

	<!-- Row -->
	<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showChqSearchRpt">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info"
					style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Cheque Search Report</h6>
				</div>
				<div class="row system_responsive" style="margin-bottom: 10px;">
					<div class="col-md-3">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12">
										<!-- <h6 class="card-title">Tempo Details</h6> -->
										<div class="row">
											<div class="col-sm-12 col-md-12">
												<div class="form-group">
													<div class="input-group">
														<label>Cheque/Referenc Number</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i
																class="fas fa-file-alt"></i>
															</span>
														</div>
														<input type="text" class="form-control"
															id="{{pageId}}chequeOrReferencNumber" autocomplete="off"
															aria-describedby="basic-addon11"
															(keypress)="onKeyPressFieldForChequeReffNo($event.keyCode)">
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
						<div class="col-md-12" style="text-align: center;">
							<button type="submit" class="btn btn-success m-r-10"
								id="{{pageId}}searchBtn" (click)="validateSearchBtn()">Search</button>
							<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
								(click)="clearChequeBtn()">Clear</button>
						</div>
					</div>
					<div class="col-md-9 vl p-t-10">
						<!-- spinner start-->
						<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
							<div class="form-group">
								<div class="input-group">
									<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
									<br>
									<h6 class="card-title" align='center'
										style="color: green; margin: auto; font-size: 18px;">
										Please Wait Loading Details.....</h6>
								</div>
							</div>
						</div>
						<!-- spinner end-->
						<div class="box-body">

							<button type="submit" class="dt-button" style="margin-left: 92%;"
								(click)="customPrintChqSearch()" id="{{pageId}}printAllBtn">
								<span><i class="fas fa-print">Print</i></span>
							</button>

							<table datatable id="{{pageId}}chequeSearchTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsChequeSearchReport"
								[dtTrigger]="dtTriggerChequeSearchReport">

								<thead>
									<tr>
										<th>Memo Number</th>
										<th>LR No.</th>
										<th>Source</th>
										<th>Party Name</th>
										<th>Coll.Cls.Date</th>
										<th>Memo Amt</th>
										<th>Memo Less</th>
										<th>TDS Less</th>
										<th>Claim Less</th>
										<th>Cheque Amt</th>
										<th>NEFT Amt</th>
										<th>Chq./Reff.No</th>
										<th>Bank Name</th>
										<th>Chq Date</th>
										<th>Collection Man</th>
									</tr>
								</thead>
								<tbody>
									<tr
										*ngFor="let chequeSearchReportData of chequeSearchReportDataList ">
										<td>{{ chequeSearchReportData.memoNumber }}</td>
										<td>{{ chequeSearchReportData.lrNumber }}</td>
										<td>{{ chequeSearchReportData.source }}</td>
										<td>{{ chequeSearchReportData.consigneeName }}</td>
										<td>{{ chequeSearchReportData.completedOnStr }}</td>
										<td>{{ chequeSearchReportData.amount }}</td>
										<td>{{ chequeSearchReportData.less }}</td>
										<td>{{ chequeSearchReportData.tds }}</td>
										<td>{{ chequeSearchReportData.claim }}</td>
										<td>{{ chequeSearchReportData.chequeAmt }}</td>
										<td>{{ chequeSearchReportData.neftAmt }}</td>
										<td>{{ chequeSearchReportData.chqNumber }}</td>
										<td>{{ chequeSearchReportData.bankName }}</td>
										<td>{{ chequeSearchReportData.dateInDate }}</td>
										<td>{{ chequeSearchReportData.collectionMan }}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>


						</div>
					</div>
				</div>
				<!-- Row -->
			</div>
		</div>
	</div>

	<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()"
		id="{{pageId}}viewCustomPrintV1">
		<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
	</div>
</body>

</html>