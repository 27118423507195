import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { LRDto } from 'src/app/dto/LR-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";

@Component({
    selector: 'app-booking-agent-dispatch-performance-report',
    templateUrl: './booking-agent-dispatch-performance-report.component.html',
    styleUrls: ['./booking-agent-dispatch-performance-report.component.css']
})
export class BookingAgentDispatchPerformanceReportComponent implements OnInit {

    gettingDataFrmServiceFrSmryTable: any;
    gettingDataFrmServiceFrDetailTable: any;

    summaryDataList: any;
    detailDataList: any;

    onDestroyUnsubscribtionSmry: Subscription;
    onDestroyUnsubscribtionDetail: Subscription;


    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;
    viewTypeSummary = true;
    viewTypeDetail = false;


    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerSummary: Subject<any> = new Subject();
    dtTriggerDetail: Subject<any> = new Subject();



    dataTable: any;
    dtOptionsSummary: any;
    dtOptionDetail: any;

    isLoggedIn = true;
    userDataDtoReturnSession: any;
    address: any = null;

    lrDtoDestinationAllOption: LRDto = new LRDto();
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    lrDtoSourceStationAllOption: LRDto = new LRDto();
    sourceStationOptions: LRDto[];
    lrDtoSourceStation: LRDto = new LRDto();
    public modelSource: any;
    sourceSubStationNameTA: Array<LRDto> = [];
    focusSourceTA$ = new Subject<string>();
    searchSource = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusSourceTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.sourceSubStationNameTA
                : this.sourceSubStationNameTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterSource = (x: { subStations: string }) => x.subStations;


    showSpinnerForAction = false;

    hideshowBookingAgentDispatchPerformanceSummaryRptTable = false;
    hideshowBookingAgentDispatchPerformanceDetailsRptTable = false;

    hideshowDropDownSourceField = false;

    sourceView: boolean = false;
    destination: boolean = false;
    mode: string = null;

    toDatesModal: any = null;
    fromDatesModal: any = null;
    validateSourceSubStationValue: any = null;
    validateDestinationValue: any = null;
    validateFromDateValue: any = null;
    valiateToDateValue: any = null;

    lrDtoForBkgAgentDispatchRptSummary: LRDto = new LRDto();
    lrDtoForBkgAgentDispatchRptDetails: LRDto = new LRDto();

    rightsForBookingAdministrator = "Rights For BkgAgentDispPerfRpt For BookingAdministrator Source";
    rightsForSuperAdministrator = "Rights For BkgAgentDispPerfRpt For SuperAdministrator Source";

    //For Custom Print
    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;
    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;

    //Custom Print Footer
    totalLrsSummary: number;
    totArticleSummary: number;
    actWghtSummary: number;
    chagWghtSummary: number;
    artSummary: number;

    viewCustomPrintV1 = false;
    pageId = "bkadp";

    constructor(private memoLessRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, private memoReport: MemoReport,
        private modalService: NgbModal, private lrReportService: LrReportService, public changeDetectorRef: ChangeDetectorRef) {

        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.hideshowBookingAgentDispatchPerformanceSummaryRptTable = true;


            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "Source BkgGodStks") {
                        this.hideshowDropDownSourceField = true;
                        this.sourceView = true;
                        this.mode = "All";
                    }
                }
            }

            this.getDestinationDetails();
            if (this.sourceView == true) {
                this.getSourceStationDetails();
            }
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {
        var companyAddressDetls = this.address;
        var booleanSourceView = this.sourceView;
        this.dtOptionsSummary = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": true,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var totalLrs = api.column(2).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var article = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var actWgt = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWt = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(totalLrs);
                $(api.column(3).footer()).html(article);
                $(api.column(4).footer()).html(actWgt);
                $(api.column(5).footer()).html(chgWt);
            }
        }, this.dtOptionDetail = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'print',
                    text: '<i class="fas fa-print"> Print</i>',
                    titleAttr: 'Print',
                    customize: function (win) {
                        $(win.document.body).css('font-size', '10pt');
                        $(win.document.body).find('th, td').
                            css('font-family', 'Arial, Helvetica, sans-serif')
                            .css('font-size', '12px').css('text-align', 'center');
                    },
                    footer: true,
                    messageTop: function () {
                        if (booleanSourceView == true) {
                            return "<br><table style='width: 100%;'>" +
                                "<tr>" +
                                "<td style='width: 20%;'>" +
                                "<table>" +
                                "<tr>" +
                                "<td width='8%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>From Date</strong>" +
                                "</td>" +
                                "<td width='2%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>:</strong>" +
                                "</td>" +
                                "<td width='10%' style='text-align:left !important;'>" +
                                "<span style='font-size:15px;'>" + moment($("#bkadpfromDates").val()).format('DD-MM-YYYY') + "</span><br>" +
                                "</td>" +
                                "</tr>" +
                                "<tr>" +
                                "<td width='8%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                                "</td>" +
                                "<td width='2%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>:</strong>" +
                                "</td>" +
                                "<td width='10%' style='text-align:left !important;'>" +
                                "<span style='font-size:15px;'>" + moment($("#bkadptoDates").val()).format('DD-MM-YYYY') + "</span><br>" +
                                "</td>" +
                                "</tr>" +
                                "<tr>" +
                                "<td width='8%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>Source</strong>" +
                                "</td>" +
                                "<td width='2%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>:</strong>" +
                                "</td>" +
                                "<td width='10%' style='text-align:left !important;'>" +
                                "<span style='font-size:15px;'>" + $("#bkadpsourceId").val() + "</span><br>" +
                                "</td>" +
                                "</tr>" +
                                "<tr>" +
                                "<td width='8%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>Destination</strong>" +
                                "</td>" +
                                "<td width='2%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>:</strong>" +
                                "</td>" +
                                "<td width='10%' style='text-align:left !important;'>" +
                                "<span style='font-size:15px;'>" + $("#bkadpdestinationId").val() + "</span><br>" +
                                "</td>" +
                                "</tr>" +
                                "</table>" +
                                "</td>" +
                                "<td rowspan='3'style='width:60%;word-wrap: break-word;text-align:left !important;'>" +
                                "<strong style='font-size:25px;'><u>Booking Agent Dispatch Performance - Details</u></strong><br>" +
                                "</td>" +
                                "<td rowspan='3' align='left' style='width:20%;'>" +
                                "</td>" +
                                "</tr>" +
                                "</table><br>";
                        } else {
                            return "<br><table style='width: 100%;'>" +
                                "<tr>" +
                                "<td style='width: 20%;'>" +
                                "<table>" +
                                "<tr>" +
                                "<td width='8%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>From Date</strong>" +
                                "</td>" +
                                "<td width='2%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>:</strong>" +
                                "</td>" +
                                "<td width='10%' style='text-align:left !important;'>" +
                                "<span style='font-size:15px;'>" + moment($("#bkadpfromDates").val()).format('DD-MM-YYYY') + "</span><br>" +
                                "</td>" +
                                "</tr>" +
                                "<tr>" +
                                "<td width='8%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>" +
                                "</td>" +
                                "<td width='2%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>:</strong>" +
                                "</td>" +
                                "<td width='10%' style='text-align:left !important;'>" +
                                "<span style='font-size:15px;'>" + moment($("#bkadptoDates").val()).format('DD-MM-YYYY') + "</span><br>" +
                                "</td>" +
                                "</tr>" +
                                "<tr>" +
                                "<td width='8%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>Destination</strong>" +
                                "</td>" +
                                "<td width='2%' style='text-align:left !important;'>" +
                                "<strong style='font-size:15px;'>:</strong>" +
                                "</td>" +
                                "<td width='10%' style='text-align:left !important;'>" +
                                "<span style='font-size:15px;'>" + $("#bkadpdestinationId").val() + "</span><br>" +
                                "</td>" +
                                "</tr>" +
                                "</table>" +
                                "</td>" +
                                "<td rowspan='3'style='width:60%;word-wrap: break-word;text-align:left !important;'>" +
                                "<strong style='font-size:25px;'><u>Booking Agent Dispatch Performance - Details</u></strong><br>" +
                                "</td>" +
                                "<td rowspan='3' align='left' style='width:20%;'>" +
                                "</td>" +
                                "</tr>" +
                                "</table><br>";
                        }

                    },
                    title: function () {
                        return "<table style='table-layout: fixed;width: 100%;'><tr>" +
                            "<td align='left' style='width: 20%;'>" +
                            "<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
                            "</td>" +
                            "<td align='left' style='width: 30%;'>" +
                            "</td>" +
                            "<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
                            companyAddressDetls +
                            "</td>" +
                            "</tr></table>";
                    },
                    exportOptions: {
                        columns: [0, 1, 3, 4, 5, 2, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16]
                    }
                },
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    title: function () {
                        if (booleanSourceView == true) {
                            return "Booking Agent Dispatch Performance Details Report- " +
                                "From Date : " + moment($("#bkadpfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date" + moment($("#bkadptoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Source : " + $("#bkadpsourceId").val() + " - " +
                                "Destination : " + $("#bkadpdestinationId").val() + "";
                        } else {
                            return "Booking Agent Dispatch Performance Details Report - " +
                                "From Date : " + moment($("#bkadpfromDates").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date" + moment($("#bkadptoDates").val()).format('DD-MM-YYYY') + " - " +
                                "Destination : " + $("#bkadpdestinationId").val() + "";
                        }

                    },
                    exportOptions: {
                        columns: ':visible'
                    }
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": true,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                // computing column Total of the complete result 
                var totalLrs = api.column(1).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var article = api.column(10).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);

                var actWt = api.column(11).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var chgWt = api.column(12).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);


                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html(totalLrs);
                $(api.column(10).footer()).html(article);
                $(api.column(11).footer()).html(actWt);
                $(api.column(12).footer()).html(chgWt);
            }
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerSummary.next();
        //this.dtTriggerDetail.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }


    ngOnDestroy(): void {
        this.dtTriggerSummary.unsubscribe();
        this.dtTriggerDetail.unsubscribe();
        // this.onDestroyUnsubscribtionSmry.unsubscribe();
        //this.onDestroyUnsubscribtionDetail.unsubscribe();
    }

    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }


    viewTypeMode(viewType: string) {
        if (viewType === 'summary') {
            this.hideshowBookingAgentDispatchPerformanceSummaryRptTable = true;
            this.hideshowBookingAgentDispatchPerformanceDetailsRptTable = false;

            $("#" + this.pageId + "bookingAgentDispatchPerformanceDetailsRptTableId").DataTable().destroy();
            this.detailDataList = [];

            $("#" + this.pageId + "bookingAgentDispatchPerformanceSummaryRptTableId").DataTable().destroy();
            this.summaryDataList = [];
            this.dtTriggerSummary.next();

            this.modelSource = null;
            this.modelDestination = null;

            $("#" + this.pageId + "destinationId").val('All');

            this.toDatesModal = null;
            this.fromDatesModal = null;
            this.validateSourceSubStationValue = null;
            this.validateDestinationValue = null;
            this.validateFromDateValue = null;
            this.valiateToDateValue = null;
        } else if (viewType === 'detail') {
            this.hideshowBookingAgentDispatchPerformanceSummaryRptTable = false;
            this.hideshowBookingAgentDispatchPerformanceDetailsRptTable = true;

            $("#" + this.pageId + "bookingAgentDispatchPerformanceSummaryRptTableId").DataTable().destroy();
            this.summaryDataList = [];

            $("#" + this.pageId + "bookingAgentDispatchPerformanceDetailsRptTableId").DataTable().destroy();
            this.detailDataList = [];
            this.dtTriggerDetail.next();

            this.modelSource = null;
            this.modelDestination = null;
            $("#" + this.pageId + "destinationId").val('All');

            this.toDatesModal = null;
            this.fromDatesModal = null;
            this.validateSourceSubStationValue = null;
            this.validateDestinationValue = null;
            this.validateFromDateValue = null;
            this.valiateToDateValue = null;
        }
    }



    sourceListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelSource = e.item;
        $("#" + this.pageId + "sourceId").val(this.modelSource.subStations);
        //this.validateSourceSubStationValue = this.modelSource.subStations;

    }

    clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
        this.modelDestination = e.item;
        $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
        // this.validateDestinationIdValue = this.modelDestination.destination;
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.showSpinnerForAction = true;
        this.getDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.destinationTA = [];
                    this.destinationOptions = [];
                    this.destinationOptions = response;
                    this.lrDtoDestinationAllOption.destination = "All";
                    this.destinationTA.push(this.lrDtoDestinationAllOption);
                    for (let i = 0; i < this.destinationOptions.length; i++) {
                        this.destinationTA.push(this.destinationOptions[i]);
                    }
                    $("#" + this.pageId + "destinationId").val('All');
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "While Getting destination details", "warning");
            },
            () => console.log('done');
    };

    getSourceStationMethod() {
        this.lrDtoSourceStation = new LRDto();
        this.lrDtoSourceStation.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoSourceStation.reportMode = "InclMainStation";
        /* if (this.rightsForSuperAdministrator != null &&
             this.rightsForSuperAdministrator == "Rights For BkgAgentDispPerfRpt For SuperAdministrator Source") {
        */
        if (this.userDataDtoReturnSession.role != null &&
            this.userDataDtoReturnSession.role == "Super Administrator") {
            this.lrDtoSourceStation.mode = "Booking";
        } else {
            /*if(this.rightsForBookingAdministrator !=null && 
            this.rightsForBookingAdministrator == "Rights For BkgAgentDispPerfRpt For BookingAdministrator Source"){
            */
            if (this.userDataDtoReturnSession.role != null &&
                this.userDataDtoReturnSession.role == "Booking Administrator") {
                this.lrDtoSourceStation.city = this.userDataDtoReturnSession.mainStation;
            } else if (this.userDataDtoReturnSession.role != null &&
                this.userDataDtoReturnSession.role == "Booking Manager") {
                this.lrDtoSourceStation.city = this.userDataDtoReturnSession.mainAdminStation;
            } else {
                this.lrDtoSourceStation.mode = "Booking";
            }
        }

    }

    getSourceStationDetails() {
        this.getSourceStationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getSubBookingStationDetailsService(this.lrDtoSourceStation).subscribe(
            (response) => {
                console.log(response);
                if (response.length > 0) {
                    this.sourceSubStationNameTA = [];
                    this.sourceStationOptions = [];
                    this.sourceStationOptions = response;
                    for (let i = 0; i < this.sourceStationOptions.length; i++) {
                        this.sourceSubStationNameTA.push(this.sourceStationOptions[i]);
                    }
                    this.showSpinnerForAction = false;
                } else {
                    this.sourceSubStationNameTA = [];
                    this.showSpinnerForAction = false;
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Source Details", "info");
            },
            () => console.log('done');

    };

    clearField() {
        this.hideshowBookingAgentDispatchPerformanceSummaryRptTable = true;
        this.hideshowBookingAgentDispatchPerformanceDetailsRptTable = false;
        this.detailDataList = [];
        $("#" + this.pageId + "bookingAgentDispatchPerformanceDetailsRptTableId").DataTable().destroy();
        this.summaryDataList = [];
        $("#" + this.pageId + "bookingAgentDispatchPerformanceSummaryRptTableId").DataTable().destroy();
        this.dtTriggerSummary.next();

        this.toDatesModal = null;
        this.fromDatesModal = null;
        this.validateSourceSubStationValue = null;
        this.validateDestinationValue = null;
        this.validateFromDateValue = null;
        this.valiateToDateValue = null;
        this.modelSource = null;
        this.modelDestination = null;
        $("#" + this.pageId + "destinationId").val('All');
    }

    validateClearBtn() {
        $("#" + this.pageId + "viewType").val('summary');
        this.clearField();
        $("#" + this.pageId + "destinationId").val('All');
    }

    /* validateSearchBtn() {
         if (this.sourceView == true) {
             if ($("#"+this.pageId+"viewType").val() == "summary") {
                 if (($("#"+this.pageId+"fromDates").val() == null) || ($("#"+this.pageId+"fromDates").val() == undefined) ||
                     ($("#"+this.pageId+"fromDates").val() == "") || ($("#"+this.pageId+"toDates").val() == null) ||
                     ($("#"+this.pageId+"toDates").val() == undefined) || ($("#"+this.pageId+"toDates").val() == "") ||
                     ($("#"+this.pageId+"sourceId").val() == null) || ($("#"+this.pageId+"sourceId").val() == undefined) ||
                     ($("#"+this.pageId+"sourceId").val() == "") || ($("#"+this.pageId+"destinationId").val() == null) ||
                     ($("#"+this.pageId+"destinationId").val() == undefined) || ($("#"+this.pageId+"destinationId").val() == "")) {
                     swal("Mandatory Field", "From Date,To Date,Source & Destination is Mandatory Field", "warning");
                     return false;
                 } else {
                     this.setBookingAgentDispatchPerformanceRptSummaryList();
                 }
             } else if ($("#"+this.pageId+"viewType").val() == "detail") {
                 if (($("#"+this.pageId+"fromDates").val() == null) || ($("#"+this.pageId+"fromDates").val() == undefined) ||
                     ($("#"+this.pageId+"fromDates").val() == "") || ($("#"+this.pageId+"toDates").val() == null) ||
                     ($("#"+this.pageId+"toDates").val() == undefined) || ($("#"+this.pageId+"toDates").val() == "") ||
                     ($("#"+this.pageId+"sourceId").val() == null) || ($("#"+this.pageId+"sourceId").val() == undefined) ||
                     ($("#"+this.pageId+"sourceId").val() == "") || ($("#"+this.pageId+"destinationId").val() == null) ||
                     ($("#"+this.pageId+"destinationId").val() == undefined) || ($("#"+this.pageId+"destinationId").val() == "")) {
                     swal("Mandatory Field", "From Date,To Date,Source & Destination is Mandatory Field", "warning");
                     return false;
                 } else {
 
                 }
             }
         } else {
             if ($("#"+this.pageId+"viewType").val() == "summary") {
                 if (($("#"+this.pageId+"fromDates").val() == null) || ($("#"+this.pageId+"fromDates").val() == undefined) ||
                     ($("#"+this.pageId+"fromDates").val() == "") || ($("#"+this.pageId+"toDates").val() == null) ||
                     ($("#"+this.pageId+"toDates").val() == undefined) || ($("#"+this.pageId+"toDates").val() == "") ||
                     ($("#"+this.pageId+"destinationId").val() == null) || ($("#"+this.pageId+"destinationId").val() == undefined) ||
                     ($("#"+this.pageId+"destinationId").val() == "")) {
                     swal("Mandatory Field", "From Date,To Date & Destination is Mandatory Field", "warning");
                     return false;
                 } else {
                     this.setBookingAgentDispatchPerformanceRptSummaryList();
                 }
             } else if ($("#"+this.pageId+"viewType").val() == "detail") {
                 if (($("#"+this.pageId+"fromDates").val() == null) || ($("#"+this.pageId+"fromDates").val() == undefined) ||
                     ($("#"+this.pageId+"fromDates").val() == "") || ($("#"+this.pageId+"toDates").val() == null) ||
                     ($("#"+this.pageId+"toDates").val() == undefined) || ($("#"+this.pageId+"toDates").val() == "") ||
                     ($("#"+this.pageId+"destinationId").val() == null) || ($("#"+this.pageId+"destinationId").val() == undefined) ||
                     ($("#"+this.pageId+"destinationId").val() == "")) {
                     swal("Mandatory Field", "From Date,To Date & Destination is Mandatory Field", "warning");
                     return false;
                 } else {
 
                 }
             }
         }
     }*/
    validateSearchBtn() {
        if (this.sourceView == true) {
            this.validateSearchForSourceDestinationWiseMethod();
        } else {
            this.validateSearchForDestinationWiseMethod();
        }
    }

    validateSearchForSourceDestinationWiseMethod() {
        if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
            ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
            ($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "") ||
            ($("#" + this.pageId + "sourceId").val() == null) || ($("#" + this.pageId + "sourceId").val() == undefined) ||
            ($("#" + this.pageId + "sourceId").val() == "") || ($("#" + this.pageId + "destinationId").val() == null) ||
            ($("#" + this.pageId + "destinationId").val() == undefined) || ($("#" + this.pageId + "destinationId").val() == "")) {
            swal("Mandatory Field", "From Date,To Date,Source & Destination is Mandatory Field", "warning");
            return false;
        } else {
            this.searchMethod();
        }
    }

    validateSearchForDestinationWiseMethod() {
        if (($("#" + this.pageId + "fromDates").val() == null) || ($("#" + this.pageId + "fromDates").val() == undefined) ||
            ($("#" + this.pageId + "fromDates").val() == "") || ($("#" + this.pageId + "toDates").val() == null) ||
            ($("#" + this.pageId + "toDates").val() == undefined) || ($("#" + this.pageId + "toDates").val() == "") ||
            ($("#" + this.pageId + "destinationId").val() == null) || ($("#" + this.pageId + "destinationId").val() == undefined) ||
            ($("#" + this.pageId + "destinationId").val() == "")) {
            swal("Mandatory Field", "From Date,To Date & Destination is Mandatory Field", "warning");
            return false;
        } else {
            this.searchMethod();
        }
    }
    searchMethod() {
        if ($("#" + this.pageId + "viewType").val() == "summary") {
            this.setBookingAgentDispatchPerformanceRptSummaryList();
        } else {
            this.setBookingAgentDispatchPerformanceRptDetailsList();
        }
    }

    getBookingAgentDispatchPerformanceRptSummaryData() {
        this.lrDtoForBkgAgentDispatchRptSummary = new LRDto();
        this.lrDtoForBkgAgentDispatchRptSummary.companyId = this.userDataDtoReturnSession.companyId;
        this.validateFromDateValue = $("#" + this.pageId + "fromDates").val();
        this.valiateToDateValue = $("#" + this.pageId + "toDates").val();
        this.validateDestinationValue = $("#" + this.pageId + "destinationId").val();

        this.lrDtoForBkgAgentDispatchRptSummary.fromDate = this.validateFromDateValue;
        this.lrDtoForBkgAgentDispatchRptSummary.toDate = this.valiateToDateValue;

        this.lrDtoForBkgAgentDispatchRptSummary.toStation = this.validateDestinationValue;

        if (this.sourceView == true) {
            this.validateSourceSubStationValue = $("#" + this.pageId + "sourceId").val();
            this.lrDtoForBkgAgentDispatchRptSummary.fromStation = this.validateSourceSubStationValue;
        } else {
            this.lrDtoForBkgAgentDispatchRptSummary.fromStation = this.userDataDtoReturnSession.office;
        }
    }

    setBookingAgentDispatchPerformanceRptSummaryList() {
        this.getBookingAgentDispatchPerformanceRptSummaryData();
        this.showSpinnerForAction = true;
        this.lrReportService.getBookingAgentDispatchPeformanceSummaryRpt(this.lrDtoForBkgAgentDispatchRptSummary).subscribe(
            (response) => {
                console.log(response);
                this.summaryDataList = [];
                $("#" + this.pageId + "bookingAgentDispatchPerformanceSummaryRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.summaryDataList = response;
                    this.showSpinnerForAction = false;
                } else {
                    swal("No Data", "No Records found for this search", "info");
                    this.showSpinnerForAction = false;
                }
                this.dtTriggerSummary.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Agent Dispatch Performance Rpt Summary", "error");
            },
            () => console.log('done');
    }

    getBookingAgentDispatchPerformanceRptDetailsData() {
        this.lrDtoForBkgAgentDispatchRptDetails = new LRDto();
        this.lrDtoForBkgAgentDispatchRptDetails.companyId = this.userDataDtoReturnSession.companyId;
        this.validateFromDateValue = $("#" + this.pageId + "fromDates").val();
        this.valiateToDateValue = $("#" + this.pageId + "toDates").val();
        this.validateDestinationValue = $("#" + this.pageId + "destinationId").val();

        this.lrDtoForBkgAgentDispatchRptDetails.fromDate = this.validateFromDateValue;
        this.lrDtoForBkgAgentDispatchRptDetails.toDate = this.valiateToDateValue;

        this.lrDtoForBkgAgentDispatchRptDetails.toStation = this.validateDestinationValue;

        if (this.sourceView == true) {
            this.validateSourceSubStationValue = $("#" + this.pageId + "sourceId").val();
            this.lrDtoForBkgAgentDispatchRptDetails.fromStation = this.validateSourceSubStationValue;
        } else {
            this.lrDtoForBkgAgentDispatchRptDetails.fromStation = this.userDataDtoReturnSession.office;
        }
    }

    setBookingAgentDispatchPerformanceRptDetailsList() {
        this.getBookingAgentDispatchPerformanceRptDetailsData();
        this.showSpinnerForAction = true;
        this.lrReportService.getBookingAgentDispatchPeformanceDetailsRpt(this.lrDtoForBkgAgentDispatchRptDetails).subscribe(
            (response) => {
                console.log(response);
                this.detailDataList = [];
                $("#" + this.pageId + "bookingAgentDispatchPerformanceDetailsRptTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.detailDataList = response;
                    this.showSpinnerForAction = false;
                } else {
                    swal("No Data", "No Records found for this search", "info");
                    this.showSpinnerForAction = false;
                }
                this.dtTriggerDetail.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Booking Agent Dispatch Performance Rpt Details", "error");
            },
            () => console.log('done');
    }

    customPrintBookingAgentDispPerSummary() {
        if (this.summaryDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Agent", "Total LRs", "Booking Date", "Articles", "Actual Weight", "Charged Weight"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [20, 15, 17, 17, 15, 16];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.totalLrsSummary = this.totArticleSummary = this.actWghtSummary = this.chagWghtSummary = 0;
            for (let i = 0; i < this.summaryDataList.length; i++) {

                this.cashMemoDtoForCustomPrintListColumnValues = [this.summaryDataList[i].agentName, this.summaryDataList[i].totalLrs, this.summaryDataList[i].bookingDateStr
                    , this.summaryDataList[i].totalArticles, this.summaryDataList[i].actualWeight, this.summaryDataList[i].chargedWeight];


                this.totalLrsSummary = this.totalLrsSummary + this.summaryDataList[i].totalLrs;
                this.totArticleSummary = this.totArticleSummary + this.summaryDataList[i].totalArticles;
                this.actWghtSummary = this.actWghtSummary + this.summaryDataList[i].actualWeight;
                this.chagWghtSummary = this.chagWghtSummary + this.summaryDataList[i].chargedWeight;


                this.cashMemoDtoForCustomPrintData = new CashMemoDto();


                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.summaryDataList.length, this.totalLrsSummary, "", this.totArticleSummary, this.actWghtSummary, this.chagWghtSummary];
            }


            //heading logics For Date
            //this.todayDate=this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.validateFromDateValue = $("#" + this.pageId + "fromDates").val();
            this.valiateToDateValue = $("#" + this.pageId + "toDates").val();

            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateFromDateValue, this.valiateToDateValue];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Source", "Destination"];
            this.validateDestinationValue = $("#" + this.pageId + "destinationId").val();
            this.validateSourceSubStationValue = $("#" + this.pageId + "sourceId").val();
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateSourceSubStationValue, this.validateDestinationValue];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);

            }
            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Booking Agent Dispatch Performance Summary");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }




    customPrintBookingAgentDispPerDetail() {
        if (this.detailDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["Agent Name", "LR Number", "Booking Date", "Entry Date", "Bill Date", "Diff(Entry-Bkg)", "Diff(Bkg-Bill)", "Collection Centre", "Destination", "Art", "Act Wt", "Chg Wt", "Consignee Name", "Consignor Name", "Commodity", "Current Status"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [6, 8, 5, 5, 6, 5, 5, 7, 9, 4, 4, 4, 9, 9, 9, 5];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }

            this.cashMemoDtoForCustomPrintDataList = [];

            this.cashMemoDtoForCustomPrintDataSummaryList = [];
            this.artSummary = this.actWghtSummary = this.chagWghtSummary = 0;
            for (let i = 0; i < this.detailDataList.length; i++) {

                this.cashMemoDtoForCustomPrintListColumnValues = [this.detailDataList[i].agentName, this.detailDataList[i].lrNumber, this.detailDataList[i].bookingDateStr,
                this.detailDataList[i].enteredDateStr, this.detailDataList[i].billDateStr, this.detailDataList[i].dispatchedwithin, this.detailDataList[i].diffBkgBillDate,
                this.detailDataList[i].source, this.detailDataList[i].destination, this.detailDataList[i].totalArticles,
                this.detailDataList[i].actualWeight, this.detailDataList[i].chargedWeight, this.detailDataList[i].consigneeName, this.detailDataList[i].consignorName, this.detailDataList[i].commodityName,
                this.detailDataList[i].status];


                this.artSummary = this.artSummary + this.detailDataList[i].totalArticles;
                this.actWghtSummary = this.actWghtSummary + this.detailDataList[i].actualWeight;
                this.chagWghtSummary = this.chagWghtSummary + this.detailDataList[i].chargedWeight;

                this.cashMemoDtoForCustomPrintData = new CashMemoDto();


                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
                this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.detailDataList.length, "", "", "", "", "", "", "", "", this.artSummary, this.actWghtSummary, this.chagWghtSummary, "", "", "", ""];
            }


            //heading logics For Date
            //this.todayDate=this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.validateFromDateValue = $("#" + this.pageId + "fromDates").val();
            this.valiateToDateValue = $("#" + this.pageId + "toDates").val();

            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.validateFromDateValue, this.valiateToDateValue];

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

            }

            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Source", "Destination"];
            this.validateDestinationValue = $("#" + this.pageId + "destinationId").val();
            this.validateSourceSubStationValue = $("#" + this.pageId + "sourceId").val();
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.validateSourceSubStationValue, this.validateDestinationValue];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);

            }



            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
            localStorage.setItem('printTitle', "Booking Agent Dispatch Performance-Details");
            this.viewCustomPrintV1 = true;
            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }


}
