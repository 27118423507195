import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";
import { ReportService } from 'src/app/dataService/report-service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { MasterReadService } from "src/app/dataService/masterread-service";
const my = new Date();
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { MemoReport } from 'src/app/dataService/memo-report';
import *  as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

/*import { DragulaService, dragula } from "ng2-dragula";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material';*/
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { HireslipService } from "src/app/dataService/hireslip-service";
import { InvoiceDto } from "src/app/dto/Invoice-dto";
import { DatePipe } from "@angular/common";

@Component({
	selector: 'app-manual-cashmemo-check-and-collectionman-stock-check-print',
	templateUrl: './manual-cashmemo-check-and-collectionman-stock-check-print.component.html',
	styleUrls: ['./manual-cashmemo-check-and-collectionman-stock-check-print.component.css']
})
export class ManualCashmemoCheckAndCollectionmanStockCheckPrintComponent implements OnInit {

	manualOrCollectionDetailsDataList: any;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerManualOrCollectionDetails: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsManualOrCollectiontDetails: any;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;
	showSpinnerForAction = false;
	model: NgbDateStruct;
	model2;
	loadingIndicator = true;
	cashMemoDtoLocalStorage:CashMemoDto = new CashMemoDto();
	cashMemoDtoFromManual:CashMemoDto = new CashMemoDto();
	cashMemoDtoFromCollection:CashMemoDto = new CashMemoDto();
	memoLessDescDataList: any;
	dtTriggerMemoLessDescriptionDetails: Subject<any> = new Subject();
	dataForPopupFromManual:any;
	dataForPopupFromManualOtherDetails:any;
	dataForPopupFromCollection:any;
	dataForPopupFromCollectionOtherDetails:any;
		date:any;
		dataListForManual:any;
		dataListForCollection:any;
		title:any;
		showDest=false;
		showCollectionMan=false;
		collectionManName:any;
		pageId="mccpc";

	constructor(private memoLessRpt: ReportService, private router: Router,
		private masterReadService: MasterReadService, private memoReport: MemoReport,
		private modalService: NgbModal, private hireslipService: HireslipService, private datePipe: DatePipe) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
				this.dataForPopupFromManual = JSON.parse( localStorage.getItem( 'popupManualCashmemoCheckDetails' ) );
				this.dataForPopupFromManualOtherDetails = JSON.parse( localStorage.getItem( 'popupManualCashmemoCheckPrintDetails' ) );
				this.dataForPopupFromCollection = JSON.parse( localStorage.getItem( 'popupCollectionManCashmemoStockCheckDetails' ) );
				this.dataForPopupFromCollectionOtherDetails = JSON.parse( localStorage.getItem( 'popupCollectionManCashmemoStockCheckPrintDetails' ) );
				localStorage.clear();
				///For Manual
				if(this.dataForPopupFromManual !=null){
					this.cashMemoDtoFromManual = new CashMemoDto();
					this.cashMemoDtoFromManual = this.dataForPopupFromManual;
					
	        	if(this.cashMemoDtoFromManual !=null){
					this.showCollectionMan=false;
					if(this.cashMemoDtoFromManual.memoCheck==true){
					this.title="Checked Cashmemos";
					}else{
					this.title="Unchecked Cashmemos";
					}
					if(this.cashMemoDtoFromManual.mode=='All'){
					this.showDest=true;
					}else{
					this.showDest=false;
					}
				
					}
				}
				
				if(this.dataForPopupFromManualOtherDetails !=null){
			
					$("#"+this.pageId+"manualOrCollectiontDetailId").DataTable().destroy();
					this.manualOrCollectionDetailsDataList = [];
					this.manualOrCollectionDetailsDataList = this.dataForPopupFromManualOtherDetails;
					this.dtTriggerManualOrCollectionDetails.next();
               
				}
				//For Collection
				if(this.dataForPopupFromCollection !=null){
					this.cashMemoDtoFromCollection = new CashMemoDto();
					this.cashMemoDtoFromCollection = this.dataForPopupFromCollection;
					
	        	if(this.cashMemoDtoFromCollection !=null){
					this.showCollectionMan=true;
					this.collectionManName=this.cashMemoDtoFromCollection.collectionMan;
					if(this.cashMemoDtoFromCollection.memoCheck==true){
					this.title="Collection Man Stock - Checked Cashmemos";
					}else{
					this.title="Collection Man Stock - Unchecked Cashmemos";
					}
					if(this.cashMemoDtoFromCollection.mode=='All'){
					this.showDest=true;
					}else{
					this.showDest=false;
					}
					
					}
				}
				if(this.dataForPopupFromCollectionOtherDetails !=null){
					
					$("#"+this.pageId+"manualOrCollectiontDetailId").DataTable().destroy();
					this.manualOrCollectionDetailsDataList = [];
					this.manualOrCollectionDetailsDataList = this.dataForPopupFromCollectionOtherDetails;
					this.dtTriggerManualOrCollectionDetails.next();
				}
					this.dataForPopupFromManual='';
					this.dataForPopupFromManualOtherDetails=[];
					this.dataForPopupFromCollection='';
					this.dataForPopupFromCollectionOtherDetails=[];
					
		}

	}

	logInPage() {
        this.router.navigate(['/authentication/login']);
    }
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
				//this.dtTriggerMemoLessDescriptionDetails.next();
			});
		});
	}
		
	ngOnInit(): void {
		var companyAddressDetls = this.address;
		  var todayDate=this.datePipe.transform(new Date(), "yyyy-MM-dd");
		   var dateformate= moment(todayDate).format('DD-MM-YYYY');
        var showTitle=this.title;
        var showDestination=this.showDest;
		var viewCollection =this.showCollectionMan;
		var namelbl ='';
		var col='';
		var colman='';
		if(viewCollection==true){
			namelbl ='Collection Man'
			col=';';
			colman=this.collectionManName;
		}else{
			 namelbl ='';
			 col='';
			colman='';
		}
		this.dtOptionsManualOrCollectiontDetails = {
			dom: 'Bfrtip',
			buttons: [
						{
							extend: 'print',
							text: '<i class="fas fa-print"> Print</i>',
							titleAttr: 'Print',
							customize : function(win) {
								$(win.document.body).css('font-size','10pt');
								$(win.document.body).find('th, td').
								css('font-family','Arial, Helvetica, sans-serif')
								.css('font-size','13px').css('text-align','center');
							},
							footer : true,
							messageTop : function() {
								var returnVal=null;
									returnVal = "<br><table style='width: 100%;'>" +
												"<tr>" +
												"<td style='width: 25%;'>" +
												"<table>" +
												"<tr>" +
												"<td width='8%' style='text-align:left !important;'>" +
												"<strong style='font-size:15px;'>Date&nbsp;&nbsp;</strong>" +
												"</td>" +
												"<td width='2%' style='text-align:left !important;'>" +
												"<strong style='font-size:15px;'>:</strong>" +
												"</td>" +
												"<td width='10%' style='text-align:left !important;'>" +
												"<span style='font-size:15px;'>" +dateformate+ "</span><br>" +
												"</td>" +
												"</tr>" +
												"</table>" +
												"</td>" +
												"<td rowspan='1'style='width:40%;word-wrap: break-word;text-align:left !important;'>" +
												"<strong style='font-size:17px;'><u>"+showTitle+"</u></strong><br>" +
												"</td>" +
												"<td align='left' style='width:35%;'>" +
												"<table>" +
												"<tr>" +
												"<td width='8%' style='text-align:left !important;'>" +
												"<strong style='font-size:13px;'>"+namelbl+"</strong>" +
												"</td>" +
												"<td width='2%' style='text-align:left !important;'>" +
												"<strong style='font-size:13px;'>"+col+"</strong>" +
												"</td>" +
												"<td width='32%' style='text-align:left !important;'>" +
												"<span style='font-size:13px;'>"+colman+"</span><br>" +
												"</td>" +
												"</tr>" +
												"</table>" +
												"</td>" +
												"</tr>" +
												"</table><br>";
								
								return returnVal;
								
							},
							title: function () {
								return "<table style='table-layout: fixed;width: 100%;'><tr>" +
									"<td align='left' style='width: 20%;'>" +
									"<img src='assets/images/srdLogisticPrintLogo.png' alt='SRDLogo'>" +
									"</td>" +
									"<td align='left' style='width: 30%;'>" +
									"</td>" +
									"<td style='word-wrap: break-word;width: 50%;text-align: right;'>" +
									companyAddressDetls +
									"</td>" +
									"</tr></table>";
							}
						}
						
					], 
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			searching: false,
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 350,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				// computing column Total of the complete result 
				var totAmt = api.column(3).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				
									if(showDestination==true){
										$(api.column(0).footer()).html('Total : ' + data.length);
										$(api.column(1).footer()).html();
										$(api.column(2).footer()).html();
										$(api.column(3).footer()).html(totAmt);
										$(api.column(4).footer()).html();
										$(api.column(5).footer()).html();
									}else{
										$(api.column(0).footer()).html('Total : ' + data.length);
										$(api.column(1).footer()).html();
										$(api.column(2).footer()).html();
										$(api.column(3).footer()).html(totAmt);
										$(api.column(4).footer()).html();
				
									}
						
			}
		}
	}


	ngOnDestroy(): void {
		this.dtTriggerManualOrCollectionDetails.unsubscribe();
	}
	ngAfterViewInit(): void {
		this.dtTriggerManualOrCollectionDetails.next();
	}

}

