<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showLRIssueBalcRpt">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">LR Issue Balance Report</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<!-- spinner start-->
				<div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
					<div class="form-group">
						<div class="input-group">
							<mat-progress-bar mode="indeterminate" style="color: green;">
							</mat-progress-bar>
							<br>
							<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
								Please Wait Loading Details.....</h6>
						</div>
					</div>
				</div>
				<!-- spinner end-->
				<div class="col-md-3">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col-lg-12">
									<!-- <h6 class="card-title">Tempo Details</h6> -->
									<div class="row">
										<div class="col-sm-12 col-md-12">
											<div class="control">
												<div class="form-group">
													<div class="input-group">
														<label>Agent Name</label>
														<div class="input-group-prepend">
															<span class="input-group-text"> <i class="fas fa-user"></i>
															</span>
														</div>
														<input id="{{pageId}}source" type="text" class="form-control"
															[(ngModel)]="modelSource" [ngbTypeahead]="searchSource"
															(selectItem)="clickListnerForSource($event)"
															[resultFormatter]="formatterSource"
															[inputFormatter]="formatterSource"
															(focus)="focusSourceTA$.next($any($event).target.value)"
															placeholder="Select Source" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr style="width: 80%; border-top: none; margin: 3px;">
					<div class="col-md-12" style="text-align: center;">
						<button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
							(click)="validateSearchBtn();">Search</button>
						<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearBtn();">Clear</button>
					</div>
				</div>
				<div class="col-md-9 vl p-t-10">
					<button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintLRIssueBal();"
						id="{{pageId}}printAllBtn">
						<span><i class="fas fa-print">Print</i></span>
					</button>
					<div class="card-body">
						<div class="box-body">
							<h6 class="card-title border_bottom">LR Issue Balance Report</h6>
							<table datatable id="{{pageId}}issueBalanceRptTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsIssueBalanceReport" [dtTrigger]="dtTriggerIssueBalanceReport">
								<thead>
									<tr>
										<th>Agent Name</th>
										<th>Total LR Issued</th>
										<th>Total LR Entered</th>
										<th>Remaining LRs</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let issueBalanceData of issueBalanceReportDataList ">
										<td>{{ issueBalanceData.agentName }}</td>
										<td>{{ issueBalanceData.totalLrs }}</td>
										<td>{{ issueBalanceData.totallrsfromTripSheet }}</td>
										<td style="color: blue; cursor: pointer; text-decoration: underline;"
											(click)="issueBalanceRptPopUpBtnLink(issueBalanceData)">
											{{ issueBalanceData.totalInvoice }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>

<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
	<app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>