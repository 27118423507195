<html>

<head>
	<style>
		/* to fix the height of a single row in drag and drop starts */
		.table-striped tbody tr:nth-of-type(odd) {
			background-color: rgba(0, 0, 0, .05);
			max-height: 24px;
		}

		.table-striped tbody tr:nth-of-type(even) {
			max-height: 24px;
		}

		/* to fix the height of a single row in drag and drop ends */
	</style>
</head>

<body>

	<!-- Row -->
	<div class="row system_responsive">
		<div class="col-lg-12">

			<div class="card " style="border: 1px solid darkcyan !important;">
				<div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
					<h6 class="card-title text-white">Commodity Merge</h6>
				</div>
				<div class="row">
					<!-- column -->
					<div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
						<div class="form-group">
							<div class="input-group">
								<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
								<br>
								<h6 class="card-title" align='center'
									style="color: green; margin: auto; font-size: 18px;">
									Please Wait Loading Details.....</h6>
							</div>
						</div>
					</div>
				</div>
				<div class="row" style="margin-bottom: 10px;">
					<div class="col-md-4 ">
						<div class="card-body">

							<div class="row">
								<div class="col-md-12 p-t-10">
									<div class="box-body">
										<div class="row" style="border-bottom: 1px solid orange; padding-bottom: 4px;">

											<div class="col-sm-12 col-md-5">
												<h6 class="card-title">Repeated Commodity List</h6>
											</div>

											<div class="col-sm-12 col-md-7">
												
												<button style="padding: 1px 4px; background: blue;margin-left:80px;" type="submit"
													class="btn btn-success m-r-10" id="{{pageId}}moveToMergeBtn"
													(click)="moveToMergeMethod();">Move
													to Merge</button>
											</div>
										</div>

										<div class="row p-t-10">
											<div class="col-sm-12 col-md-12">
												<table datatable id="{{pageId}}repeatedCommodityId"
													class="table table-striped table-bordered row-border hover"
													[dtOptions]="dtOptionsRepeatedCommodityList"
													[dtTrigger]="dtTriggerRepeatedCommodityList">

													<thead>
														<tr>
															<th>Action</th>
															<th>Commodity Name</th>

														</tr>
													</thead>
													<tbody style="height: 40vh;" class='dragndrop'
														[dragula]='"first-bag"'>
														<tr
															*ngFor="let repeatedCommodityListData of repeatedCommodityListDataList ">
															<td><input type="checkbox" [checked]="multiSelect"
																	id='selected' style="margin-left: 20px"
																	(change)="rowCheckBoxChecked($event, repeatedCommodityListData)" />
															</td>
															<td>{{ repeatedCommodityListData.commodityName }}</td>

														</tr>

													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 							the first datatable ends  -->

					<div class="col-md-4 vl">
						<div class="card-body">
							<div class="row">

								<div class="col-md-12 p-t-10">
									<div class="box-body">
										<div class="row" style="border-bottom: 1px solid orange; padding-bottom: 4px;">
											<div class="col-sm-12 col-md-6">
												<h6 class="card-title">Merge Commodity List</h6>
											</div>

											<div class="col-sm-12 col-md-6" align='right'>
												<button style="padding: 1px 4px;background: brown;" type="submit"
													class="btn btn-success m-r-10" id="{{pageId}}mergeBtn"
													(click)="mergeBtnMethod();">Merge</button>
											</div>
										</div>
										<div class="row">

											<div class="col-sm-12 col-md-12">
												<h6 class="card-title"></h6>
											</div>

										</div>

										<table datatable id="{{pageId}}mergeCommodityId"
											class="table table-striped table-bordered row-border hover"
											[dtOptions]="dtOptionMergeCommodityList"
											[dtTrigger]="dtTriggerMergeCommodityList">
											<thead>
												<tr>
													<th>Commodity Name</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody style="height: 40vh;" class='dragndrop' [dragula]='"first-bag"'>
												<tr style="cursor: pointer;"
													*ngFor="let mergeCommodityListData of mergeCommodityListDataList"
													(click)="rowSelectedFromMerge(mergeCommodityListData);">
													<td>{{ mergeCommodityListData.commodityName }}</td>
													<td>
														<button type="button"
															style="padding: 1px 4px; background-color: #ffffff00;"
															class="btn m-r-10" id="{{pageId}}tableRemoveBtn"
															(click)="rowSelectedDeleteForMerge(mergeCommodityListData,i);">
															<i title="Remove" class="fas fa-trash"></i>
														</button>
													</td>
												</tr>
											</tbody>

										</table>
									</div>
								</div>
								<!--							 the second datatable ends  -->
							</div>
							<div class="row p-t-10">
								<div class="col-sm-12 col-md-12">
									<div class="form-group">
										<div class="input-group">
											<label>Commodity Name</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="fas fa-user"></i>
												</span>
											</div>
											<input #commodityNameConfirmed type="text" id="{{pageId}}commodityNameConfirmed"
												name="commodityNameConfirmed" [(ngModel)]="commodityName"
												class="form-control" aria-describedby="basic-addon11">
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr style="width: 80%; border-top: none; margin: 3px;">
					</div>
					<!-- 							the third datatable starts  -->
					<div class="col-md-4 vl">
						<div class="card-body">
							
							<div class="row">

								<div class="col-sm-12 col-md-12">
									<div class="box-body">
										<div class="row" style="border-bottom: 1px solid orange;">

											<div class="col-sm-12 col-md-8">
												<h6 class="card-title">Confirmed Commodity List</h6>
											</div>


											<div class="col-sm-12 col-md-4" align='right'>
											
											</div>
										</div>
										<div class="row p-t-10">
											<div class="col-sm-12 col-md-12">
												<table datatable id="{{pageId}}confirmedCommodityId"
													class="table table-striped table-bordered row-border hover"
													[dtOptions]="dtOptionConfirmedCommodityList"
													[dtTrigger]="dtTriggerConfirmedCommodityList">
													<thead>
														<tr>
															<th>Commodity Name</th>
															
														</tr>
													</thead>
													<tbody>

														<tr style="cursor: pointer;"
															*ngFor="let confirmedCommodityListData of confirmedCommodityListDataList; let i = index"
															(click)="rowSelectedForUndo(confirmedCommodityListData);">
															<td>{{ confirmedCommodityListData.commodityName }}</td>
															
														</tr>

													</tbody>

												</table>
											</div>
											<!-- 							the third datatable ends  -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Row -->
				<div class="col-md-12">
					<div class="form-group">
						<div class="input-group" id="{{pageId}}popupDetailsTwo">
							<ng-template #content let-yes="close" let-no="close" let-ok="close" let-d="dismiss">
								<div class="modal-body">
									<div class="row" style="text-align: center;">
										<div class="col-md-12">

											<h5 class="m-b-10">
												<i class="fas fa-exclamation-triangle"></i> Please select
												Commodtiy name to Undo
											</h5>
										</div>
										<div class="col-md-12 p-t-10">
											<button type="button" class="btn btn-outline-secondary"
												(click)="ok('Yes click')">Ok</button>

										</div>
									</div>
								</div>
							</ng-template>


						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</body>

</html>