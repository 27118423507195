<!-- Row -->
<div class="row" *ngIf="isLoggedIn" id="{{pageId}}showBrokerRpt">
  <div class="col-lg-12">

    <div class="card " style="border: 1px solid darkcyan!important;">
      <div class="card-header bg-info" style="background-color: orange!important;padding:5px;">
        <h6 class="card-title text-white">Broker Report</h6>
      </div>
      <div class="row system_responsive" style="margin-bottom:10px;">
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <!-- <h6 class="card-title">Tempo Details</h6> -->
                  <div class="row">
                    <div class="col-sm-12 col-md-12">
                      <div class="form-group">
                        <div class="input-group">
                          <label>From Date</label> <input class="form-control" placeholder="yyyy-mm-dd" id="{{pageId}}fromDates"
                            name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                          <div class="input-group-append" (click)="fromDates.toggle()">
                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12">
                      <div class="form-group">
                        <div class="input-group">
                          <label>To Date</label> <input id="{{pageId}}toDates" class="form-control" placeholder="yyyy-mm-dd"
                            name="toDates" ngbDatepicker #toDates="ngbDatepicker">
                          <div class="input-group-append" (click)="toDates.toggle()">
                            <span class="input-group-text"> <i class="fa fa-calendar"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12" *ngIf="viewSourceField">
                      <div class="form-group">
                        <div class="input-group">
                          <label>Source</label>
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="fas fa-box"></i>
                            </span>
                          </div>
                          <input id="{{pageId}}source" type="text" class="form-control" [(ngModel)]="modelSource"
                            [ngbTypeahead]="searchSource" (selectItem)="clickListnerForSource($event)"
                            [resultFormatter]="formatterSource" [inputFormatter]="formatterSource"
                            (focus)="focusSourceTA$.next($any($event).target.value)" placeholder="Select Source" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12" *ngIf="viewDestinationField">
                      <div class="form-group">
                        <div class="input-group">
                          <label>Destination</label>
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class=" fas fa-road"></i>
                            </span>
                          </div>
                          <input id="{{pageId}}destinationId" type="text" class="form-control"
                            (selectItem)="clickListnerForDestination($event)" [(ngModel)]="modelDestination"
                            [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination"
                            [inputFormatter]="formatterDestination"
                            (focus)="focusDestinationTA$.next($any($event).target.value)"
                            placeholder="Select Destination.." />
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style="width:80%;border-top: none;margin:3px;">
          <div class="col-md-12" style="text-align:center;">
            <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn"
              (click)="validateSearchBtn();">Search</button>
            <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearBtn();">Clear</button>
          </div>
        </div>
        <div class="col-md-9 vl">
          <div class="col-md-12">

            <!-- spinner start-->
            <div *ngIf="showSpinnerForAction" class="col-sm-12 col-md-12">
              <div class="form-group">
                <div class="input-group">
                  <mat-progress-bar mode="indeterminate" style="color: green;">
                  </mat-progress-bar>
                  <br>
                  <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                    Please Wait Loading Details.....</h6>
                </div>
              </div>
            </div>
            <!-- spinner end-->
            <div class="card">
              <div class="card-body">
                <button type="submit" style='float: right;'class="btn btn-success m-r-10" id="{{pageId}}summaryPrintBtnId"
                  (click)="validateSummaryPrintBtn();">Summary Print</button>
                <table datatable id="{{pageId}}brokerRptTableId" class="table table-striped table-bordered row-border hover"
                  [dtOptions]="dtOptionsBrokerDetailsRptHireslipDateDetails"
                  [dtTrigger]="dtTriggerBrokerDetailsRptHireslipDateDetails">

                  <thead>
                    <tr>
                      <th>Hireslip Date</th>
                      <th>Destination</th>
                      <th>Broker Name</th>
                      <th>No Of Trucks</th>
                      <th>Truck Hire Average</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let brokerReportData of brokerReportDataList ">
                      <td>{{ brokerReportData.hireSlipDateStr }}</td>
                      <td>{{ brokerReportData.tostation }}</td>
                      <td>{{ brokerReportData.suppliername }}</td>
                      <td>{{ brokerReportData.countTrucks }}</td>
                      <td>{{ brokerReportData.totalhire }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- Row -->
    </div>
  </div>
</div>

<div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
  <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
</div>