import { Component, OnInit, ViewChildren, QueryList,ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts

//from the particular global folder ends
//for datatable ends
//for popup modal ends
//for modal starts
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LRDto } from "src/app/dto/LR-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ReportService } from 'src/app/dataService/report-service';
export interface DialogData {
    listOfDataToPass: string[];
}
//for modal ends
@Component( {
    selector: 'app-pdc-info-popup',
    templateUrl: './pdc-info-popup.component.html',
    styleUrls: ['./pdc-info-popup.component.css']
} )
export class PdcInfoPopupComponent implements OnInit {

    //for datatable starts

    lrDto: LRDto = new LRDto();
    userDataDtoReturnSession: any;

    lrDetailsDataList: any;
    //    listOfDataToGet: LRDto = new LRDto();
    listOfDataToGet: any;
    list: string[];



    //for datatable ends

    //summaryTable:any;

    loadingIndicator = true;

    //for datatable starts
    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLrDetails: Subject<any> = new Subject();

    dataTable: any;
    dtOptionsLrDetails: any;

    //for datatable ends
	pageId="pdcpc";
    constructor(
            /* for datatable starts */private masterReadService: MasterReadService, private reportService: ReportService, /* for datatable ends */
        //for modal starts
        public dialogRef: MatDialogRef<PdcInfoPopupComponent>,
        @Inject( MAT_DIALOG_DATA ) public data: DialogData, public changeDetectorRef : ChangeDetectorRef
        //for modal ends
    ) {
        this.userDataDtoReturnSession = JSON.parse( sessionStorage.getItem( 'SRDWeb' ) );

        this.listOfDataToGet = this.data.listOfDataToPass;
        
         console.log( "Finally >>> " + this.listOfDataToGet );

    }
    /* for datatable starts */
    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }
    /* for datatable ends */






    ngOnInit(): void {

    }
    dataTableBayInfo(){
        this.dtOptionsLrDetails={
            //columns is used to for export and others endss


            // the below code is for button export starts
            dom: 'Bfrtip',
            /*buttons: [
                      'excel', 'print'
                  ],*/
            //            buttons: [
            //                {
            //                    extend: 'excel',
            //                    text: '<i class="fas fa-file-excel"> Excel</i>',
            //                    titleAttr: 'Excel',
            //                    footer: true,
            //                    title: this.changeDataTableTitileForExcel(),
            //                    messageTop: this.chnageDataTableMessageTopExcel(),
            //                    exportOptions: {
            //                        columns: ':visible'
            //                    }
            //                },
            //                {
            //                    extend: 'print',
            //                    text: '<i class="fas fa-print"> Print</i>',
            //                    titleAttr: 'Print',
            //                    title: this.changeDataTableTitileForPrint(),
            //                    //                    messageTop: this.chnageDataTableMessageTop(),
            //                    autoPrint: true,
            //                    footer: true,
            //
            //                }
            //            ],



            // the below code is for button export ends


            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends



            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 280,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            "paging": false,
            "info": false,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            //scroll in datatable ends

            "footerCallback": function( row, data, start, end, display ) {
                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
                var intVal = function( i ) {
                    return typeof i === 'string' ?
                        +i.replace( /[\$,]/g, '' ) * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var columnData = api
                    .column(
                    0 )
                    .data();

                // Update footer
                $( api.column( 0 ).footer() ).html(
                    columnData.count()
                );


                // Total over this page

                // var pageTotals = api
                //     .column( 2 )
                //     .data()
                //     .reduce( function( a, b ) {
                //         return intVal( a ) + intVal( b )
                //     }, 0 );
                // $( api.column( 2 ).footer() ).html(
                //     pageTotals
                // );






                //                count starts

                //                counts ends
            }
            //end footerCallback   
        }
    }
    ngOnDestroy(): void {
        this.dtTriggerLrDetails.unsubscribe();
    }

    //for modal starts
    onCancelClick(): void {
        this.dialogRef.close();
    }
    //for modal ends



    
    

}
