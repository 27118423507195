<html>

<head>
    <style>
        .imb_patch {
            text-align: center;
            background-color: orange;
            padding: 2px;
        }
    </style>
</head>

<body>

    <!-- Row -->
    <div class="row" *ngIf="isLoggedIn" id="{{pageId}}showAdminBussRpt">
        <div class="col-lg-12">

            <div class="card " style="border: 1px solid darkcyan !important;">
                <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                    <h6 class="card-title text-white">Admin Business Rate Report</h6>
                </div>
                <div class="row system_responsive" style="margin-bottom: 10px;">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body" style="border-bottom: 1px solid orange;">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <!-- <h6 class="card-title">Tempo Details</h6> -->
                                        <div class="row">


                                            <div *ngIf="viewDestination" class="col-md-3">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class=" fas fa-road"></i>
															</span>
                                                        </div>
                                                        <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="clickListnerForDestination($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>From Date</label> <input class="form-control" id="{{pageId}}fromDate" [(ngModel)]="fromDateModel" placeholder="yyyy-mm-dd" name="fromDates" ngbDatepicker #fromDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="fromDates.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>To Date</label> <input id="{{pageId}}toDate" [(ngModel)]="toDateModel" class="form-control" placeholder="yyyy-mm-dd" name="toDates" ngbDatepicker #toDates="ngbDatepicker">
                                                        <div class="input-group-append" (click)="toDates.toggle()">
                                                            <span class="input-group-text"> <i
																	class="fa fa-calendar"></i>
															</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">

                                                <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="searchMethod();">Search</button>
                                                <button type="submit" class="btn btn-dark m-r-10" id="{{pageId}}searchBtn" (click)="validateClearBtn();">Clear</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showSpinnerForAction" class="col-md-12 vl p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 vl p-t-10">

                        <div class="box-body">
                            <h6 class="card-title">&nbsp;&nbsp;Rate For Parties</h6>
                            <table datatable id="{{pageId}}rateForPartiesTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsRateForPartiesTable" [dtTrigger]="dtTriggerRateForPartiesTable">

                                <thead>
                                    <tr>
                                        <th>Consignee Name</th>
                                        <th>Source</th>
                                        <th>Rate Type</th>
                                        <th>Rate</th>
                                        <th>GC Chg</th>
                                        <th>S.Ham</th>
                                        <th>LC Chg</th>
                                        <th>BC Chg</th>
                                        <th>AOC Chg</th>
                                        <th>FOV</th>
                                        <th>DD</th>
                                        <th>Others</th>
                                        <th>D.Ham</th>
                                        <th>Receipt Chg</th>
                                        <th>Service Chg</th>
                                        <th>Per Unit</th>
                                        <th>Commodity</th>
                                        <th>Eff. From</th>
                                        <th>Tot Art</th>
                                        <th>Per Day Art</th>
                                        <th>Tot Act Wgt</th>
                                        <th>Per Day Act Wgt</th>
                                        <th>Tot Chg Wgt</th>
                                        <th>Per Day Chg Wgt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let rateForPartiesTableData of rateForPartiesTableDataList ">
                                        <td>{{ rateForPartiesTableData.consignee }}</td>
                                        <td>{{ rateForPartiesTableData.source }}</td>
                                        <td>{{ rateForPartiesTableData.column2 }}</td>
                                        <td>{{ rateForPartiesTableData.rate }}</td>
                                        <td>{{ rateForPartiesTableData.gcCharge }}</td>
                                        <td>{{ rateForPartiesTableData.hamaliChg == true ?'Yes': rateForPartiesTableData.hamaliChg == false ?'No': 'No' }}
                                        </td>
                                        <td>{{ rateForPartiesTableData.lcChg == true ?'Yes': rateForPartiesTableData.lcChg == false ?'No': 'No' }}
                                        </td>
                                        <td>{{ rateForPartiesTableData.bcChg == true ?'Yes': rateForPartiesTableData.bcChg == false ?'No': 'No' }}
                                        </td>
                                        <td>{{ rateForPartiesTableData.aoc == true ?'Yes': rateForPartiesTableData.aoc == false ?'No': 'No' }}
                                        </td>
                                        <td>{{ rateForPartiesTableData.riskCharge == true ?'Yes': rateForPartiesTableData.riskCharge == false ?'No': 'No' }}
                                        </td>
                                        <td>{{ rateForPartiesTableData.dd == true ?'Yes': rateForPartiesTableData.dd == false ?'No': 'No' }}
                                        </td>
                                        <td>{{ rateForPartiesTableData.others == true ?'Yes': rateForPartiesTableData.others == false ?'No': 'No' }}
                                        </td>
                                        <td>{{ rateForPartiesTableData.hamaliDestination== null ? null:rateForPartiesTableData.hamaliDestination}}
                                        </td>
                                        <td>{{ rateForPartiesTableData.receiptCharges }}</td>
                                        <td>{{ rateForPartiesTableData.serviceCharges }}</td>
                                        <td>{{ (rateForPartiesTableData.perUnit !=null && rateForPartiesTableData.perUnit =='Article') ? 'ART' :(rateForPartiesTableData.perUnit !=null && rateForPartiesTableData.perUnit =='Actual Weight') ? 'AW' :'CW'}}
                                        </td>
                                        <td>{{ rateForPartiesTableData.commodityName }}</td>
                                        <td>{{ rateForPartiesTableData.effectiveFromDate }}</td>
                                        <td>{{ rateForPartiesTableData.totalArticles }}</td>
                                        <td>{{ rateForPartiesTableData.perDayArt }}</td>
                                        <td>{{ rateForPartiesTableData.totalActWgt }}</td>
                                        <td>{{ rateForPartiesTableData.perDayActWgt }}</td>
                                        <td>{{ rateForPartiesTableData.totalChgWgt }}</td>
                                        <td>{{ rateForPartiesTableData.perDayChgWgt }}</td>
                                    </tr>

                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>
                        <div class="box-body" style="padding-top: 20px;">
                            <button type="submit" class="dt-button" style="margin-left: 92%;" (click)="customPrintAdminBussinessRateRpt();" id="{{pageId}}printAllBtn">
								<span><i class="fas fa-print">Print</i></span>
							</button>
                            <h6 class="card-title">&nbsp;&nbsp;Commodity Rate Details</h6>
                            <table datatable id="{{pageId}}commodityRateDetailsTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsCommodityRateDetailsTable" [dtTrigger]="dtTriggerCommodityRateDetailsTable">

                                <thead>
                                    <tr>
                                        <th>Commodity</th>
                                        <th>Source</th>
                                        <th>Unit</th>
                                        <th>Rate</th>
                                        <th>GC Chg</th>
                                        <th>S.Ham</th>
                                        <th>LC Chg</th>
                                        <th>BC Chg</th>
                                        <th>AOC Chg</th>
                                        <th>FOV</th>
                                        <th>DD</th>
                                        <th>Others</th>
                                        <th>D.Ham</th>
                                        <th>Receipt Chg</th>
                                        <th>Service Chg</th>
                                        <th>Per Unit</th>
                                        <th>Eff. From</th>
                                        <th>Tot Art</th>
                                        <th>Per Day Art</th>
                                        <th>Tot Act Wgt</th>
                                        <th>Per Day Act Wgt</th>
                                        <th>Tot Chg Wgt</th>
                                        <th>Per Day Chg Wgt</th>
                                        <th style='display:none;'>Del.Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let commodityRateDetailsTableData of commodityRateDetailsTableDataList ">
                                        <td>{{ commodityRateDetailsTableData.commodityName }}</td>
                                        <td>{{ commodityRateDetailsTableData.source }}</td>
                                        <td>{{ commodityRateDetailsTableData.unit }}</td>
                                        <td>{{ commodityRateDetailsTableData.rate }}</td>
                                        <td>{{ commodityRateDetailsTableData.gcCharge }}</td>
                                        <td>{{ commodityRateDetailsTableData.shamaliChgStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.lcChgStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.bcChgStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.aocChgStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.riskChargeStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.ddChgStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.otherChgStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.shamaliChgStr }}</td>
                                        <td>{{ commodityRateDetailsTableData.receiptCharges }}</td>
                                        <td>{{ commodityRateDetailsTableData.serviceCharges }}</td>
                                        <td>{{ commodityRateDetailsTableData.perUnit }}</td>
                                        <td>{{ commodityRateDetailsTableData.effectiveFromDateStg }}</td>
                                        <td>{{ commodityRateDetailsTableData.totalArticles == null ? 0 : commodityRateDetailsTableData.totalArticles}}
                                        </td>
                                        <td>{{ commodityRateDetailsTableData.perDayArt == null ? 0 : commodityRateDetailsTableData.perDayArt }}
                                        </td>
                                        <td>{{ commodityRateDetailsTableData.totalActWgt == null ? 0 : commodityRateDetailsTableData.totalActWgt }}
                                        </td>
                                        <td>{{ commodityRateDetailsTableData.perDayActWgt == null ? 0 : commodityRateDetailsTableData.perDayActWgt }}
                                        </td>
                                        <td>{{ commodityRateDetailsTableData.totalChgWgt == null ? 0 : commodityRateDetailsTableData.totalChgWgt }}
                                        </td>
                                        <td>{{ commodityRateDetailsTableData.perDayChgWgt == null ? 0 : commodityRateDetailsTableData.perDayChgWgt }}
                                        </td>
                                        <td style='display:none;'>{{ commodityRateDetailsTableData.deliveryType }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style='display:none;'></td>
                                    </tr>
                                </tfoot>


                            </table>

                        </div>
                    </div>
                </div>
                <!-- Row -->
            </div>
        </div>
    </div>

    <div *ngIf="viewCustomPrintV1" onafterprint="afterPrint()" id="{{pageId}}viewCustomPrintV1">
        <app-custom-dynamic-printV1></app-custom-dynamic-printV1>
    </div>
</body>

</html>