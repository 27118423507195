<!-- Row -->
<div class="row">
    <div class="col-lg-12">
        <div class="card" style="border: 1px solid darkcyan !important">
            <div class="row system_responsive" style="margin-bottom: 10px">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div *ngIf="sourceView" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Main Source</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-box"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}mainBookingSourceId" (selectItem)="clickListnerForMainBookingSource($event)" [(ngModel)]="modelMainBookingSource" [ngbTypeahead]="searchMainBookingSource" [resultFormatter]="formatterMainBookingSource"
                                                        [inputFormatter]="formatterMainBookingSource" (focus)="focusMainBookingSourceTA$.next($any($event).target.value)" placeholder="Select Main Source.." />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Source &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="ti-home"></i>
                                                            </span>
                                                        </div>
                                                        <input #agentName id="{{pageId}}agentName" type="text" class="form-control" [(ngModel)]="agentNameModal" (selectItem)="clickListnerForSource($event)" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="agentNameSearchTA" [resultFormatter]="formatterAgentName"
                                                            [inputFormatter]="formatterAgentName" placeholder="Select Source" (focus)="focusAgentNameTA$.next($any($event).target.value)" #instanceAgentName="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Rate For</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-search"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}rateFor" name="rateFor" #rateFor (change)="rateForMode(rateFor.value)">
                                                        <option selected value="Commodity">Commodity
                                                        </option>
                                                        <option value="Party">Party</option>
                                                        <option value="RateForAll">Rate For All - Commodity</option>
                                                        <option value="RateForAllPartySpec">Rate For All - Party
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="viewParty" class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Select Index</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-box"></i>
                                                            </span>
                                                        </div>
                                                        <input #index id="{{pageId}}indexId" name="indexId" type="text" class="form-control" [(ngModel)]="modelIndex" placeholder="Select Index" (selectItem)="indexDropDownListner($event)" [ngbTypeahead]="indexSearchTA" (focus)="focusIndexTA$.next($any($event).target.value)"
                                                            #instanceIndex="ngbTypeahead" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="viewParty" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Consignor Name &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input #consignorName id="{{pageId}}consignorName" name="consignorName" type="text" class="form-control" placeholder="Select Consignor Name" [(ngModel)]="modelConsignorName" [ngbTypeahead]="consignorNameSearchTA" (selectItem)="clickListnerForConsignor($event)"
                                                        [resultFormatter]="formatterConsignorName" [inputFormatter]="formatterConsignorName" (focus)="focusConsignorNameTA$.next($any($event).target.value)" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Destination &nbsp;*</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>

                                                        <input id="{{pageId}}destination" type="text" class="form-control" [(ngModel)]="modelDestination" (selectItem)="clickListnerForDestination($event)" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                            (focus)="focusDestinationTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="viewParty" class="col-sm-12 col-md-12">
                                            <div class="control">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <label>Consignee Name</label>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"> <i class="fas fa-user"></i>
                                                            </span>
                                                        </div>
                                                        <input #consigneeNameId id="{{pageId}}consigneeNameId" type="text" class="form-control" (selectItem)="clickListnerForConsigneeNameList($event)" [(ngModel)]="modelConsigneeName" [ngbTypeahead]="searchConsigneeName" [resultFormatter]="formatterConsigneeName"
                                                            [inputFormatter]="formatterConsigneeName" (focus)="focusConsigneeNameTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Consignee Name.." />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Commodity &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-box"></i>
                                                        </span>
                                                    </div>
                                                    <input #CommodityList id="{{pageId}}commodityListId" type="text" class="form-control" (selectItem)="clickListnerForCommodityList($event)" [(ngModel)]="modelCommodityList" [ngbTypeahead]="searchCommodityList" [resultFormatter]="formatterCommodityList"
                                                        [inputFormatter]="formatterCommodityList" (focus)="focusCommodityListTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity.." />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Type</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-search"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}searchByRateType" name="searchByRateType" #searchByRateType (change)="searchByRateTypeMode(searchByRateType.value)">
                                                        <option selected value="Fixed">Fixed Per Article
                                                        </option>
                                                        <option value="PerKg">Per Kg</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12" *ngIf="viewActChgWgtField">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Per Unit</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-search"></i>
                                                        </span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}perUnitId" name="perUnitId" #perUnitId (change)="rateOnActWgtMethod(perUnitId.value)" [(ngModel)]="perUnitIdModal">
                                                        <option selected value="ActualWeight">Actual Weight
                                                        </option>
                                                        <option value="ChargedWeight">Charged Weight</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12" *ngIf="viewRateOnActWgtField">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Rate On AW</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
                                                                class="fas fa-rupee-sign"></i>
                                                        </span>
                                                    </div>
                                                    <input type="number" class="form-control" [(ngModel)]="rateOnActWgt" id="{{pageId}}rateOnActWgt" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Basic Rate &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}basicRateId" #basicRate name="basicRateId" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForBaseChargeAmt($event.keyCode)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Hamali</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}hamaliChgId" #hamaliChg name="hamaliChgId" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForHamaliChargeAmt($event.keyCode)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>GC Charge</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}gcChargeId" #gcCharge name="gcChargeId" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForGCChargeAmt($event.keyCode)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>AOC</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}aocChargeId" #aocCharge name="aocChargeId" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForAoc($event.keyCode)">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>DD Charge</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}ddChargeId" #ddCharge name="ddChargeId" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForDD($event.keyCode)">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Others</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}otherChargeId" #otherCharge name="otherCharge" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForOther($event.keyCode)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>FOV(%) &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}fovChargeId" #fovCharge name="fovChargeId" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForFov($event.keyCode)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Fixed By &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-describedby="basic-addon11" autocomplete="off" id="{{pageId}}fixedBy" [(ngModel)]="modelfixedBy">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Effect From &nbsp;*</label> <input class="form-control" id="{{pageId}}effectFromDate" placeholder="yyyy-mm-dd" name="effectFromDate" ngbDatepicker #effectFromDates="ngbDatepicker" autocomplete="off"
                                                        [(ngModel)]="modelEffectFromDate">
                                                    <div class="input-group-append" (click)="effectFromDates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Fixed On &nbsp;*</label> <input id="{{pageId}}fixedOnDate" class="form-control" placeholder="yyyy-mm-dd" name="fixedOnDate" ngbDatepicker #fixedOnDates="ngbDatepicker" autocomplete="off" [(ngModel)]="modelFixedOnDate">
                                                    <div class="input-group-append" (click)="fixedOnDates.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="viewSaveBtn" class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="{{pageId}}subStationCheckbox" (change)="toggleSubStationCheckbox($event)"> <label class=" custom-control-label wtfull " for="{{pageId}}subStationCheckbox">Separate Rate For
                                                        Substation</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="viewSubRate">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Basic Rate &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}basicRateIdSub" #basicRateSub name="basicRateIdSub" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForBaseChargeAmtSub($event.keyCode)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Hamali</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}hamaliChgIdSub" #hamaliChgSub name="hamaliChgIdSub" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForHamaliChargeAmtSub($event.keyCode)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>GC Charge</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}gcChargeIdSub" #gcChargeSub name="gcChargeIdSub" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForGCChargeAmtSub($event.keyCode)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>AOC</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}aocChargeIdSub" #aocChargeSub name="aocChargeIdSub" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForAocSub($event.keyCode)">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>DD Charge</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}ddChargeIdSub" #ddChargeSub name="ddChargeIdSub" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForDDSub($event.keyCode)">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Others</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}otherChargeIdSub" #otherChargeSub name="otherChargeSub" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForOtherSub($event.keyCode)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>FOV(%) &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-file-alt"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" id="{{pageId}}fovChargeIdSub" #fovChargeSub name="fovChargeIdSub" aria-describedby="basic-addon11" autocomplete="off" (keypress)="onKeyPressFieldForFovSub($event.keyCode)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Fixed By &nbsp;*</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i class="fas fa-user"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" aria-describedby="basic-addon11" autocomplete="off" id="{{pageId}}fixedBySub" [(ngModel)]="modelFixedBySub">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Effect From &nbsp;*</label> <input class="form-control" id="{{pageId}}effectFromDateSub" placeholder="yyyy-mm-dd" name="effectFromDateSub" ngbDatepicker #effectFromDateSubs="ngbDatepicker" autocomplete="off"
                                                        [(ngModel)]="modelEffectFromDateSub">
                                                    <div class="input-group-append" (click)="effectFromDateSubs.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Fixed On &nbsp;*</label> <input id="{{pageId}}fixedOnDateSub" class="form-control" placeholder="yyyy-mm-dd" name="fixedOnDateSub" ngbDatepicker #fixedOnDateSubs="ngbDatepicker" autocomplete="off"
                                                        [(ngModel)]="modelFixedOnDateSub">
                                                    <div class="input-group-append" (click)="fixedOnDateSubs.toggle()">
                                                        <span class="input-group-text"> <i class="fa fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr style="width: 80%; border-top: none; margin: 3px" />
                                <div *ngIf="viewActionBtn" class="col-md-12" style="text-align: center">
                                    <button type="submit" *ngIf="viewSaveBtn" (click)="validateBeforeSave();" class="btn btn-success" id="{{pageId}}saveBtn">Save</button>
                                    <button type="submit" *ngIf="viewUpdateBtn" (click)="validateBeforeUpdate();" class="btn btn-success" id="{{pageId}}updateBtn">Update</button>
                                    <button type="submit" class="btn btn-dark" (click)="confirmClear();" id="{{pageId}}clearBtn">
                                        Clear</button>
                                    <button type="submit" *ngIf="viewDeleteBtn" class="btn btn-dark" (click)="confirmDelete();" id="{{pageId}}deleteBtn">
                                        Delete</button>
                                    <!-- <button type="submit" (click)="confirmSave();" class="btn btn-info"
								id="{{pageId}}rateForAllBtn">Rate For All</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-9 vl p-t-10">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div *ngIf="showSpinnerForAction || showSpinnerForConsignorName" class="col-md-12 p-t-10">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <mat-progress-bar mode="indeterminate" style="color: green;">
                                            </mat-progress-bar>
                                            <br>
                                            <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                                Please Wait Loading Details.....</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="ti-home"></i>
                                                                    </span>
                                                                </div>
                                                                <input #agentNameRptSearch id="{{pageId}}agentNameRptSearch" type="text" class="form-control" [(ngModel)]="agentNameRptSearchModal" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="agentNameSearchRptSearchTA" [resultFormatter]="formatterAgentNameRptSearch"
                                                                    [inputFormatter]="formatterAgentNameRptSearch" placeholder="Select Source" (focus)="focusAgentNameRptSearchTA$.next($any($event).target.value)" #instanceAgentNameRptSearch="ngbTypeahead"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="fas fa-user"></i>
                                                                    </span>
                                                                </div>

                                                                <input id="{{pageId}}destinationSearchRpt" type="text" class="form-control" [(ngModel)]="modelDestinationSearchRpt" [ngbTypeahead]="searchDestinationSearchRpt" [resultFormatter]="formatterDestinationSearchRpt" [inputFormatter]="formatterDestinationSearchRpt"
                                                                    (focus)="focusDestinationSearchRptTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-box"></i>
                                                                </span>
                                                            </div>
                                                            <input #CommodityList id="{{pageId}}commodityListIdSearchRpt" type="text" class="form-control" [(ngModel)]="modelCommodityListSearchRpt" [ngbTypeahead]="searchCommodityListSearchRpt" [resultFormatter]="formatterCommodityListSearchRpt" [inputFormatter]="formatterCommodityListSearchRpt"
                                                                (focus)="focusCommodityListSearchRptTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-2" style="text-align: center">
                                                    <button type="submit" (click)="getRateDetails();" class="dt-button" style="margin-left: -70%;font-weight:bold;" id="{{pageId}}commodityRateBtn">Search</button>
                                                </div>
                                            </div>

                                            <div class='row p-t-10'>
                                                <div class="col-sm-12 col-md-12">
                                                    <h6 class="card-title border_bottom">Commodity Rate Details
                                                    </h6>
                                                </div>
                                            </div>

                                            <div class="box-body">
                                                <table datatable id="{{pageId}}ConsignorAdminRateMasterForCommodityTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsForConsignorAdminRateMasterForCommodity" [dtTrigger]="dtTriggerForConsignorAdminRateMasterForCommodity">
                                                    <thead>
                                                        <tr>
                                                            <th>Source</th>
                                                            <th>Destination</th>
                                                            <th>Commodity Name</th>
                                                            <th>Type</th>
                                                            <th>Unit</th>
                                                            <th>Basic Rate</th>
                                                            <th>Hamali</th>
                                                            <th>GC Charge</th>
                                                            <th>AOC</th>
                                                            <th>DD Chg</th>
                                                            <th>Others</th>
                                                            <th>FOV(%)</th>
                                                            <th>Fixed By</th>
                                                            <th>WEF Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let consignorAdminRateMasterData of consignorAdminRateMasterDataListForCommodity" style="cursor: pointer;" (click)="editOnSelecteCommodityRateDetails(consignorAdminRateMasterData);">
                                                            <td>{{ consignorAdminRateMasterData.source }}</td>
                                                            <td>{{ consignorAdminRateMasterData.destination }}<span *ngIf="consignorAdminRateMasterData.subStationRate==true">
                                                                    - For
                                                                    All Sub Station</span></td>
                                                            <td>{{ consignorAdminRateMasterData.commodityName }}
                                                            </td>
                                                            <td>{{ consignorAdminRateMasterData.ratePerUnit }}</td>
                                                            <td>{{ consignorAdminRateMasterData.perUnit }}</td>
                                                            <td>{{ consignorAdminRateMasterData.basicCharge }}</td>
                                                            <td>{{ consignorAdminRateMasterData.hamali }}</td>
                                                            <td>{{ consignorAdminRateMasterData.gcCharge }}</td>
                                                            <td>{{ consignorAdminRateMasterData.aocChargeAmt }}</td>
                                                            <td>{{ consignorAdminRateMasterData.ddChargeAmt }}</td>
                                                            <td>{{ consignorAdminRateMasterData.otherChgAmt }}</td>
                                                            <td>{{ consignorAdminRateMasterData.riskChargeAmt }}
                                                            </td>
                                                            <td>{{ consignorAdminRateMasterData.fixedBy }}</td>
                                                            <td>{{ consignorAdminRateMasterData.effectiveFromDateStg }}
                                                            </td>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-12" style="padding:5px"></div> -->
                                <div class="col-md-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-2">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="ti-home"></i>
                                                                    </span>
                                                                </div>
                                                                <input #agentNameRptSearchParty id="{{pageId}}agentNameRptSearchParty" type="text" class="form-control" [(ngModel)]="agentNameRptSearchPartyModal" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="agentNameSearchRptSearchPartyTA" [resultFormatter]="formatterAgentNameRptSearchParty"
                                                                    [inputFormatter]="formatterAgentNameRptSearchParty" placeholder="Select Source" (focus)="focusAgentNameRptSearchPartyTA$.next($any($event).target.value)" #instanceAgentNameRptSearchParty="ngbTypeahead"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="fas fa-user"></i>
                                                                    </span>
                                                                </div>

                                                                <input id="{{pageId}}destinationSearchRptParty" type="text" class="form-control" [(ngModel)]="modelDestinationSearchRptParty" [ngbTypeahead]="searchDestinationSearchRptParty" [resultFormatter]="formatterDestinationSearchRptParty" [inputFormatter]="formatterDestinationSearchRptParty"
                                                                    (focus)="focusDestinationSearchRptPartyTA$.next($any($event).target.value)" placeholder="Select Destination" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-box"></i>
                                                                </span>
                                                            </div>
                                                            <input #CommodityList id="{{pageId}}commodityListIdSearchRptParty" type="text" class="form-control" [(ngModel)]="modelCommodityListSearchRptParty" [ngbTypeahead]="searchCommodityListSearchRptParty" [resultFormatter]="formatterCommodityListSearchRptParty"
                                                                [inputFormatter]="formatterCommodityListSearchRptParty" (focus)="focusCommodityListSearchRptPartyTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Commodity" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2">
                                                    <div class="control">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"> <i
                                                                            class="fas fa-box"></i>
                                                                    </span>
                                                                </div>
                                                                <input #index id="{{pageId}}indexIdPartySearch" name="indexIdPartySearch" type="text" class="form-control" [(ngModel)]="modelIndexPartySearch" placeholder="Select Index" (selectItem)="indexDropDownListnerPartySearch($event)" [ngbTypeahead]="indexSearchPartySearchTA"
                                                                    (focus)="focusIndexPartySearchTA$.next($any($event).target.value)" #instanceIndexPartySearch="ngbTypeahead" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"> <i
                                                                        class="fas fa-user"></i>
                                                                </span>
                                                            </div>
                                                            <input #consignorNamePartySearch id="{{pageId}}consignorNamePartySearch" name="consignorNamePartySearch" type="text" class="form-control" placeholder="Select Consignor" [(ngModel)]="modelConsignorNamePartySearch" [ngbTypeahead]="consignorNameSearchPartySearchTA"
                                                                (selectItem)="clickListnerForConsignorRptSearch($event)" [resultFormatter]="formatterConsignorNamePartySearch" [inputFormatter]="formatterConsignorNamePartySearch" (focus)="focusConsignorNamePartySearchTA$.next($any($event).target.value)"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-1" style="text-align: center">
                                                    <button type="submit" (click)="getPartyRateDetails();" class="dt-button" style="margin-left: -70%;font-weight:bold;" id="{{pageId}}partyRateSearchBtn">Search</button>
                                                </div>
                                            </div>

                                            <div class='row p-t-10'>
                                                <div class="col-sm-12 col-md-12">
                                                    <h6 class="card-title border_bottom">Party Rate Details</h6>
                                                </div>
                                            </div>
                                            <div class="box-body">
                                                <table datatable id="{{pageId}}ConsignorAdminRateMasterForPartyTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsForConsignorAdminRateMasterForParty" [dtTrigger]="dtTriggerForConsignorAdminRateMasterForParty">
                                                    <thead>
                                                        <tr>
                                                            <th>Source</th>
                                                            <th>Destination</th>
                                                            <th>Consignor Name</th>
                                                            <th>Consignee Name</th>
                                                            <th>Commodity Name</th>
                                                            <th>Type</th>
                                                            <th>Unit</th>
                                                            <th>Basic Rate</th>
                                                            <th>Hamali</th>
                                                            <th>GC Charge</th>
                                                            <th>AOC</th>
                                                            <th>DD Chg</th>
                                                            <th>Others</th>
                                                            <th>FOV(%)</th>
                                                            <th>Fixed By</th>
                                                            <th>WEF Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let consignorAdminRateMasterData of consignorAdminRateMasterDataListForParty" style="cursor: pointer;" (click)="editOnSelectePartyRateDetails(consignorAdminRateMasterData);">
                                                            <td>{{ consignorAdminRateMasterData.source }}</td>
                                                            <td>{{ consignorAdminRateMasterData.destination }}<span *ngIf="consignorAdminRateMasterData.subStationRate==true">
                                                                    - For
                                                                    All Sub Station</span></td>
                                                            <td>{{ consignorAdminRateMasterData.consignorName }}
                                                                <td>{{ consignorAdminRateMasterData.consigneeName }}
                                                                </td>
                                                                <td>{{ consignorAdminRateMasterData.commodityName }}
                                                                </td>
                                                                <td>{{ consignorAdminRateMasterData.ratePerUnit }}</td>
                                                                <td>{{ consignorAdminRateMasterData.perUnit }}</td>
                                                                <td>{{ consignorAdminRateMasterData.basicCharge }}</td>
                                                                <td>{{ consignorAdminRateMasterData.hamali }}</td>
                                                                <td>{{ consignorAdminRateMasterData.gcCharge }}</td>
                                                                <td>{{ consignorAdminRateMasterData.aocChargeAmt }}</td>
                                                                <td>{{ consignorAdminRateMasterData.ddChargeAmt }}</td>
                                                                <td>{{ consignorAdminRateMasterData.otherChgAmt }}</td>
                                                                <td>{{ consignorAdminRateMasterData.riskChargeAmt }}
                                                                </td>
                                                                <td>{{ consignorAdminRateMasterData.fixedBy }}</td>
                                                                <td>{{ consignorAdminRateMasterData.effectiveFromDateStg }}
                                                                </td>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Row -->
        </div>
    </div>
</div>