<!-- Row -->
<div class="row">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Our Branches</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Branch Name</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}branchName" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="form-group">
                                                    <label>Address</label>
                                                    <div class="form-group-prepend">
                                                        <span class="input-group-text"> <i
															class=" fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <textarea class="form-control" id="{{pageId}}address" rows="3"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Area</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}area" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>City</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}city" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Pincode</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}pincode" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Contact Person</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}contactPerson" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Contact Number 1</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}contNo1" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Contact Number 2</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}contNo2" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Fax</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}fax" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Email Id</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}emailId" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Latitude</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}latitude" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Longitude</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}longitude" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Branch Type</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <select class="custom-select col-12" id="{{pageId}}branchType" name="branchType">
														<option value="Main Station" selected>Main
															Station</option>
														<option value="Sub Station">Sub Station</option>
													</select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-12">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <label>Station Type</label>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"> <i
															class="fas fa-envelope"></i>
														</span>
                                                    </div>
                                                    <input type="text" id="{{pageId}}contactPerson" class="form-control" aria-describedby="basic-addon11">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}searchBtn" (click)="confirmSave();">Save</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearFields()">Clear</button>
                    </div>
                </div>

                <div class="col-md-4 vl p-t-10">
                    <div class="card-body">
                        <div class="box-body">
                            <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                                <div class="form-group">
                                    <div class="input-group">
                                        <mat-progress-bar mode="indeterminate" style="color: green;">
                                        </mat-progress-bar>
                                        <br>
                                        <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                            Please Wait Loading Details.....</h6>
                                    </div>
                                </div>
                            </div>

                            <table datatable id="{{pageId}}ourBranchesTableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsOurBranches" [dtTrigger]="dtTriggerOurBranches">

                                <thead>
                                    <tr>
                                        <th>Branch Name</th>
                                        <th>Latitude</th>
                                        <th>Longitude</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ourBranchesData of ourBranchesDataList ">
                                        <td>{{ ourBranchesData.branchName }}</td>
                                        <td>{{ ourBranchesData.latitude }}</td>
                                        <td>{{ ourBranchesData.longitude }}</td>
                                        <td><button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}editBtn" (click)="rowSelectedEdit(ourBranchesData);">
												<i title="View" class="fas fa-edit"></i>
											</button>
                                            <button style="padding: 1px 4px; background-color: #ffffff00;" class="btn m-r-10" id="{{pageId}}removeBtn" (click)="confirmDelete(ourBranchesData)">
												<i title="Remove" class="fas fa-trash"></i>
											</button></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

            </div>
            <!-- Row -->
        </div>
    </div>
</div>