import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LRDto } from "src/app/dto/LR-dto";

export interface DialogData {
    listOfDataToPass: string[];
}

@Component({
    selector: 'app-multiple-article-range-popup',
    templateUrl: './multiple-article-range-popup.component.html',
    styleUrls: ['./multiple-article-range-popup.component.css']
})
export class MultipleArticleRangePopupComponent implements OnInit {

    lrDto: LRDto = new LRDto();
    userDataDtoReturnSession: any;

    lrDetailsDataList: any;
    listOfDataToGet: any;
    list: string[];
    splitArticlesDetails: string;
    commaSplitArticle: any;
    undersCodeSplitTime: string[];
    articlesDataList: any;
    pageId = "marpc";
    loadingIndicator = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerLrDetails: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsLrDetails: any;

    constructor(public dialogRef: MatDialogRef<MultipleArticleRangePopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef) {

        this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        this.listOfDataToGet = this.data.listOfDataToPass;
        this.setValuesInTable();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        });
    }

    ngOnInit(): void {
        this.dtOptionsLrDetails = {
            dom: 'Bfrtip',
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 280,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var columnData = api.column(0).data();

                $(api.column(0).footer()).html(
                    columnData.count()
                );
            }
        }
    }
    ngOnDestroy(): void {
        this.dtTriggerLrDetails.unsubscribe();
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    setValuesInTable() {
        this.articlesDataList = this.listOfDataToGet
    }
}
