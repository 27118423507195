export class SupplierDetailsDto {
    supplierName: string;
    address: string;
    city: string;
    pincode: string;
    state: string;
    tinNumber: string;
    contactNumber: string;
    ContactPerson: string;
    id: number;
    mode: string;
    column1: string;
    column2: string;
    suppCode: string;
    companyId: string;
    status: string;
    userId: string;
}