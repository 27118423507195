import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbDateStruct, NgbCalendar, ModalDismissReasons, NgbModalRef, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription, Observable, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { MasterReadService } from 'src/app/dataService/masterread-service';

//from the particular global folder ends
//for datatable ends
//for modal starts
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';
import *  as moment from 'moment';
const my = new Date();
import { DatePipe } from "@angular/common";
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { ReportService } from 'src/app/dataService/report-service';
import { MemoReport } from 'src/app/dataService/memo-report';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { StockService } from 'src/app/dataService/stock-service';
import { PartyMasterDto } from 'src/app/dto/PartyMaster-dto';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { ConsigneeService } from 'src/app/dataService/consignee-service';
export interface DialogData {
	animal: string;
	name: string;
}
//for modal ends
@Component({
	selector: 'app-consignee-active-nonactive-details',
	templateUrl: './consignee-active-nonactive-details.component.html',
	styleUrls: ['./consignee-active-nonactive-details.component.css']
})
export class ConsigneeActiveNonActiveDetailsComponent implements OnInit {

	gridConsigneeActiveNonActiveList: any;
	onDestroyUnsubscribtionHamaliDetails: Subscription;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerConsigneeActiveNonActivePopUp: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsConsigneeActiveNonActivePopUp: any;
	pageId="candc";


	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;

	showSpinnerForAction = false;

	model: NgbDateStruct;
	model2;

	loadingIndicator = true;

	popUpOpenConsigneeActiveNonActiveData: any;
	popUpOpenActiveNonActiveDistinationLocalStorage: any;
	popUpOpenActiveNonActiveListLocalStorage: any;
	popUpOpenActiveNonActiveModeLocalStorage: any;

	lrDto: LRDto = new LRDto();

	onloadDestination: any;
	onloadSource: any;
	onloadMode: any;



	constructor(private memoLessRpt: ReportService, private router: Router,
		private masterReadService: MasterReadService, private memoReport: MemoReport,
		private stockService: StockService, private consigneeService: ConsigneeService,
		private modalService: NgbModal, public dialogRef: MatDialogRef<ConsigneeActiveNonActiveDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef : ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			console.log(data);
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.popUpOpenConsigneeActiveNonActiveData = JSON.parse(localStorage.getItem('ConsigneeStatusGrid'));
			this.popUpOpenActiveNonActiveDistinationLocalStorage = JSON.parse(localStorage.getItem('ConsigneeStatusGridLocalStorage'));
			this.popUpOpenActiveNonActiveListLocalStorage = JSON.parse(localStorage.getItem('ConsigneeStatusGridLocalStorage1'));
			this.popUpOpenActiveNonActiveModeLocalStorage = JSON.parse(localStorage.getItem('ConsigneeStatusSourceLocalStorage'));

			localStorage.clear();
			if (this.popUpOpenConsigneeActiveNonActiveData != null &&
				this.popUpOpenConsigneeActiveNonActiveData != undefined &&
				this.popUpOpenConsigneeActiveNonActiveData == "ConsigneeStatusGrid") {
				if (this.popUpOpenActiveNonActiveDistinationLocalStorage != null &&
					this.popUpOpenActiveNonActiveDistinationLocalStorage != undefined) {
					this.onloadDestination = this.popUpOpenActiveNonActiveDistinationLocalStorage;
					this.onloadSource = this.popUpOpenActiveNonActiveListLocalStorage.source;
					this.onloadMode = this.popUpOpenActiveNonActiveModeLocalStorage;
					this.gridReconfigure();
				}
			}
		}
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		this.dtOptionsConsigneeActiveNonActivePopUp = {
			dom: 'Bfrtip',
			buttons: [
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						return "Consignees Active Non Active Details - ";
					},
				}, {
					extend: 'print',
					text: '<i class="fas fa-print"> Print</i>',
					titleAttr: 'Print',
					footer: true,
				}
			],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			searching: false,
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 200,
			"scrollCollapse": true,
			"paging": false,
			"info": true,
		}
	}


	ngOnDestroy(): void {
		//this.dtTriggerConsigneeActiveNonActivePopUp.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerConsigneeActiveNonActivePopUp.next();
	}

	gridReconfigure() {
		this.lrDto = new LRDto();
		this.lrDto.city = this.popUpOpenActiveNonActiveDistinationLocalStorage;
		this.lrDto.mode = this.popUpOpenActiveNonActiveModeLocalStorage;
		this.lrDto.source = this.popUpOpenActiveNonActiveListLocalStorage.source;
		this.lrDto.companyId = this.userDataDtoReturnSession.companyId;
		this.showSpinnerForAction = true;
		this.consigneeService.getConsigneeActNonActiveDetails(this.lrDto).subscribe(
			(response) => {
				$("#"+this.pageId+"consigneeActiveNonActivePopUpTableId").DataTable().destroy();
				this.gridConsigneeActiveNonActiveList = [];
				if (response.length > 0) {
					this.gridConsigneeActiveNonActiveList = response;
				} else {
					swal("Empty", "No datas found for this information", "warning");
				}
				this.dtTriggerConsigneeActiveNonActivePopUp.next();
				this.showSpinnerForAction = false;
				console.log(response);
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While getting Consignee Active Non Active Details", "warning");
			},
			() => console.log('done');
	}


	onCancelClick(): void {
		this.dialogRef.close("Close");
		localStorage.clear();
	}
}

