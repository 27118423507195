import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { StockService } from 'src/app/dataService/stock-service';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MatStepper, MatVerticalStepper } from '@angular/material/stepper';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { LRDto } from 'src/app/dto/LR-dto';
import { HireslipService } from 'src/app/dataService/hireslip-service';
//from the particular global folder ends
//for datatable ends


@Component({
    selector: 'app-stocks-for-tripsheet-page-stock-summary',
    templateUrl: './stocks-for-tripsheet-page-stock-summary.component.html',
    styleUrls: ['./stocks-for-tripsheet-page-stock-summary.component.css']
})
export class StocksForTripsheetPageStockSummaryComponent implements OnInit {

    //for datatable starts
    gettingDataFrmServiceFrStockSummaryTable: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    onDestroyUnsubscribtionStockSummary: Subscription;
    onDestroyUnsubscribtionStockDetails: Subscription;

    dataTable: any;
    dtOptionsStockSummary: any;
    dtOptionsStockDetails: any;

    dtTriggerStockSummary: Subject<any> = new Subject();
    dtTriggerStockDetails: Subject<any> = new Subject();

    stockSummaryDataList: any;
    stockDetailsDataList: any;

    //for datatable end

    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;

    rightsToShowDeliveryMan = "StockForTripsheet ShowDelvryMan";
    showDeliveryMan = false;


    lrDtoArticleSummary: LRDto = new LRDto();
    lrDtoStocksDetails: LRDto = new LRDto();

    lrDtoPassLocalStorage: LRDto = new LRDto();

    makeAtripBtnHideShow = false;
    tripSheetBtnHideShow = false;

    makeATripBtnId: string;
    tripSheetBtnId: string;

    viewBinsStockMsg: any = null;

pageId="stpsc";
    constructor(private modalService: NgbModal, private stockSummaryScreen: StockService,
        private stockDetailsScreen: StockService, private router: Router,
        private masterReadService: MasterReadService, private hireslipService: HireslipService, public changeDetectorRef : ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            //sweet alert starts
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //sweet alert ends
        }

        if (this.isLoggedIn) {
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.viewBinsStockMsg = "Bins Stock";
            /*if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                //    console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "StockForTripsheet ShowDelvryMan") {
                        this.showDeliveryMan = true;
                    }
                }
            }*/

            this.setTripCrationForArticlesStocksSummaryDetailsList();
        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

    ngOnInit(): void {

        //for datatable starts
        this.dtOptionsStockSummary = {
            dom: 'Bfrtip',
            buttons: [
            ],
            order: [[1, 'asc']],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 230,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            pagingType: 'full_numbers',
            //pageLength: 8,
        }
        this.dtOptionsStockDetails = {
            dom: 'Bfrtip',
            buttons: [
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            //place holder in search/filter in datatable ends
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 230,
            "scrollCollapse": true,
            //this used to hide paggination and content like showing 1 to 3 of 20 entries Starts
            pagingType: 'full_numbers',
            //pageLength: 8,
        }
    }

    ngAfterViewInit(): void {
        this.dtTriggerStockSummary.next();
        this.dtTriggerStockDetails.next();
    }

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnDestroy(): void {
        this.dtTriggerStockSummary.unsubscribe();
        this.dtTriggerStockDetails.unsubscribe();
        //this.onDestroyUnsubscribtionStockSummary.unsubscribe();
    }

    getTripCrationForArticlesStocksSummaryDetails() {
        this.lrDtoArticleSummary = new LRDto();
        this.lrDtoArticleSummary.branch = this.userDataDtoReturnSession.office;
        this.lrDtoArticleSummary.branchType = this.userDataDtoReturnSession.stationType;
        this.lrDtoArticleSummary.companyId = this.userDataDtoReturnSession.companyId;
    }
    setTripCrationForArticlesStocksSummaryDetailsList() {
        this.getTripCrationForArticlesStocksSummaryDetails();
        this.showSpinnerForAction = true;
        this.hireslipService.getArticlesStocksSummForDestination(this.lrDtoArticleSummary).subscribe(
            (response) => {
                console.log(response);
                this.stockSummaryDataList = [];
                $("#"+this.pageId+"tripSheetForStockSummaryTableId").DataTable().destroy();
                if (response.length > 0) {
                    this.stockSummaryDataList = response;
                    this.showSpinnerForAction = false;
                } else {
                    this.showSpinnerForAction = false;
                }
                this.dtTriggerStockSummary.next();
                //store.groupBy("endDest");
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  Stock Summary Details", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    }

    rowSelectedGetTripLRDeatils(stockSummaryTableData) {
        this.lrDtoStocksDetails = new LRDto();
        this.viewBinsStockMsg = stockSummaryTableData.endDest;
        this.stockDetailsDataList = [];
        $("#"+this.pageId+"tripSheetForStockDetailsTableId").DataTable().destroy();
        this.dtTriggerStockDetails.next();
        this.lrDtoStocksDetails.branch = this.userDataDtoReturnSession.office;
        if (stockSummaryTableData.totalLrs > 0) {
            this.makeATripBtnId = stockSummaryTableData.endDest;
            this.tripSheetBtnId = stockSummaryTableData.endDest;

            this.lrDtoStocksDetails.destination = stockSummaryTableData.endDest;
            this.lrDtoStocksDetails.stationType = stockSummaryTableData.stationType;
            this.lrDtoStocksDetails.subStations = stockSummaryTableData.endDest;
            this.lrDtoStocksDetails.branchType = this.userDataDtoReturnSession.stationType;
            this.lrDtoStocksDetails.companyId = this.userDataDtoReturnSession.companyId;

            this.getTripCreationForArticlesStocksDetailsList(this.lrDtoStocksDetails);
        }
    }

    getTripCreationForArticlesStocksDetailsList(lrDtoStocksDetails) {
        this.showSpinnerForAction = true;
        this.hireslipService.getArticlesStocksForDestination(lrDtoStocksDetails).subscribe(
            (response) => {
                console.log(response);
                this.stockDetailsDataList = [];
                $("#"+this.pageId+"tripSheetForStockDetailsTableId").DataTable().destroy();
                this.stockDetailsDataList = response;
                setTimeout(() => {
                    this.dtTriggerStockDetails.next();
                }, 3000);

                if (response.length == 0) {
                    this.makeAtripBtnHideShow = true;
                    this.tripSheetBtnHideShow = true;
                } else if (this.lrDtoStocksDetails.stationType != null &&
                    (!(this.lrDtoStocksDetails.stationType == "")) &&
                    (this.lrDtoStocksDetails.stationType == "local")) {
                    this.makeAtripBtnHideShow = false;
                    this.tripSheetBtnHideShow = true;
                } else if (this.lrDtoStocksDetails.stationType != null &&
                    (!(this.lrDtoStocksDetails.stationType == "")) &&
                    (this.lrDtoStocksDetails.stationType == "Others")) {
                    this.makeAtripBtnHideShow = false;
                    this.tripSheetBtnHideShow = false;
                } else if (this.lrDtoStocksDetails.stationType != null &&
                    (!(this.lrDtoStocksDetails.stationType == "")) &&
                    (this.lrDtoStocksDetails.stationType == "UnDelivered")) {
                    this.makeAtripBtnHideShow = false;
                    this.tripSheetBtnHideShow = false;
                } else {
                    this.makeAtripBtnHideShow = true;
                    this.tripSheetBtnHideShow = false;
                }
                this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal("Server Error", "Problem occur while getting  Stock Details Details", "warning");
                console.log(error.json())
            },
            () => console.log('done');
    };

    tripSheetBtnClickListner() {
        console.log(this.tripSheetBtnId);
        if (this.tripSheetBtnId != null && this.tripSheetBtnId == "Others") {
            swal("Trips For Other", "Can't generate Trips for UnAssigned Lrs", "warning");
            return false;
        } else {
            /* if (this.showDeliveryMan != null &&
                 this.showDeliveryMan == true) {
             */
            if (this.userDataDtoReturnSession.office != null &&
                this.userDataDtoReturnSession.office == "Binnymills") {
                this.lrDtoPassLocalStorage = new LRDto();
                this.lrDtoPassLocalStorage.destination = this.tripSheetBtnId;
                this.lrDtoPassLocalStorage.mode = "localgrouping";
                this.lrDtoPassLocalStorage.invoiceNumber = null;
                this.lrDtoPassLocalStorage.tripId = 0;
                localStorage.clear();
                localStorage.setItem('stockTripSheetScheduleForLocalStorage', JSON.stringify(this.lrDtoPassLocalStorage));
            } else {
                this.lrDtoPassLocalStorage = new LRDto();
                this.lrDtoPassLocalStorage.destination = this.tripSheetBtnId;
                this.lrDtoPassLocalStorage.mode = "localgrouping";
                this.lrDtoPassLocalStorage.invoiceNumber = null;
                this.lrDtoPassLocalStorage.module = "destination";
                this.lrDtoPassLocalStorage.selectedTripTo = this.tripSheetBtnId;
                this.lrDtoPassLocalStorage.tripId = 0;
                localStorage.clear();
                localStorage.setItem('stockTripSheetScheduleForLocalStorage', JSON.stringify(this.lrDtoPassLocalStorage));
            }
        }
    }
}