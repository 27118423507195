import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { ElementRef } from "@angular/core";

import { ReportService } from 'src/app/dataService/report-service';

import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

const my = new Date();

////
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';



import { ConsignorService } from 'src/app/dataService/consignor-service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for drag and drop starts
import { DragulaService, dragula } from "ng2-dragula";
//for drag and drop ends
//for popup modal starts 
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from "src/app/dataService/memo-service";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MemoReport } from 'src/app/dataService/memo-report';
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import *  as moment from 'moment';
///

@Component({
    selector: 'app-letter-head-report',
    templateUrl: './letter-head-report.component.html',
    styleUrls: ['./letter-head-report.component.css']
})
export class LetterHeadReportComponent implements OnInit {


    getDataFrmServiceFrLetterHeadRptTable: any;
    letterHeadRptDataList: any;
    onDestroyUnsubscribtionLetterHeadRpt: Subscription;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends
    showSpinnerForAction = false;
    closeResultContentLetterHead: string;

    loadingIndicator = true;

    viewDateWise = true;
    viewLrNoWise = false;

    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    showDest = false;
    showGodown = false;
    viewConsigneeWise = false;
    viewConsignorWise = false;
    isDestView = false;
    isGodownView = false;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    consignorIndexOptions = [
        { id: 2, label: 'A' },
        { id: 2, label: 'B' },
        { id: 3, label: 'C' },
        { id: 4, label: 'D' },
        { id: 5, label: 'E' },
        { id: 6, label: 'F' },
        { id: 7, label: 'G' },
        { id: 8, label: 'H' },
        { id: 9, label: 'I' },
        { id: 10, label: 'J' },
        { id: 11, label: 'K' },
        { id: 12, label: 'L' },
        { id: 13, label: 'M' },
        { id: 14, label: 'N' },
        { id: 15, label: 'O' },
        { id: 16, label: 'P' },
        { id: 17, label: 'Q' },
        { id: 18, label: 'R' },
        { id: 19, label: 'S' },
        { id: 20, label: 'T' },
        { id: 21, label: 'U' },
        { id: 22, label: 'V' },
        { id: 23, label: 'W' },
        { id: 24, label: 'X' },
        { id: 25, label: 'Y' },
        { id: 26, label: 'Z' }];
    consigneeNameOptions: any;
    partyMasterDtoForconsignee: PartyMasterDto = new PartyMasterDto();
    consignorNameOptions: any;
    partyMasterDtoForconsignor: PartyMasterDto = new PartyMasterDto();
    selectedDestination: any;
    selectedIndex: any;
    selectedConsigneeGSTNumber: any;
    selectedConsignorGSTNumber: any;
    selectedConsigneeName: any;
    selectedFromDate: any;
    selectedToDate: any;
    selectedConsignorName: any;
    selectedGodown: any;
    selectedViewBy: any;
    enteredLrNumber: any;
    showHideDestination = 'Show Destination For LetterHead';
    showHideGodown = 'Show Godown For LetterHead';
    selectedConsigneeId: any;
    selectedConsignorId: any;
    getSelectedRowMemoNumber: any;
    selectedRecDate: any;
    searchLetterHeadRateDto = new RateMasterDto();
    returnLrSetRateDto = new RateMasterDto();
    modalRefferenceContentLetterHeadPopUp: NgbModalRef;
    modalRefferenceContentLetterHeadClosePopUp: string;
    @ViewChild('contentLetterHead') private contentLetterHead;
    //For consignor Index
    public modelConsignorIndex: any;
    @ViewChild('instanceConsignorIndex') instanceConsignorIndex: NgbTypeahead;
    consignorIndexTA: Array<any> = [];
    focusConsignorIndexTA$ = new Subject<string>();
    consignorIndexSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorIndexTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorIndexTA
                : this.consignorIndexTA.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    //for ConsignorName
    consignorNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    consignorNameTA: Array<PartyMasterDto> = [];
    focusConsignorNameTA$ = new Subject<string>();
    consignorNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsignorNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consignorNameTA
                : this.consignorNameTA.filter(v => v.consignorName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsignorName = (x: { consignorName: string }) => x.consignorName;
    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.destinationTA
                : this.destinationTA.filter(v => v.destination)).slice(0, 200))
        );
    }
    formatterDestination = (x: { destination: string }) => x.destination;

    //for ConsigneeName
    consigneeNamePartyDtoAllOption: PartyMasterDto = new PartyMasterDto();
    consigneeNameTA: Array<PartyMasterDto> = [];
    consigneeNameTATemp: Array<PartyMasterDto> = [];
    focusConsigneeNameTA$ = new Subject<string>();
    consigneeNameSearchTA = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusConsigneeNameTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.consigneeNameTA
                : this.consigneeNameTA.filter(v => v.consigneeName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterConsigneeName = (x: { consigneeName: string }) => x.consigneeName;



    //For Godown
    localDestinationOptions: LRDto[];
    lrDtoLocalDestination: LRDto = new LRDto();
    lrDtoLocalDestinationAllOption: LRDto = new LRDto();
    public modelLocalDestination: any;
    public modelDestinationFotGet: any;
    public modelConsigneeNameId: any;
    public modelConsignorNameId: any;
    localDestinationTA: Array<LRDto> = [];
    focusLocalDestinationTA$ = new Subject<string>();
    searchLocalDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusLocalDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.localDestinationTA
                : this.localDestinationTA.filter(v => v.destination)).slice(0, 200))
        );
    }
    formatterLocalDestination = (x: { destination: string }) => x.destination;
    //for datepicker ends


    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerLetterHeadRpt: Subject<any> = new Subject();


    dataTable: any;
    dtOptionsLetterHeadRpt: any;

    modalRefferenceLetterHeadPopUp: NgbModalRef;
    modalRefferenceLetterHeadClosePopUp: string;
    @ViewChild('letterHeadPopUpTemplate') private letterHeadPopUpTemplate;

    selectedLetterHeadDetails: File;
    isUploaded: boolean = false;
    lrNoPopUp: any = null;
    memoNumberPopUp: any = null;
    rateMasterDto: RateMasterDto = new RateMasterDto();

    fromDatesModal: any = null;
    toDatesModal: any = null;
    modelConsigneeName: any;
    modelConsignorName: any;
    address: any;
    viewCustomPrintV1: any;

    cashMemoDtoForCustomPrintListHeadingV1: any;
    cashMemoDtoForCustomPrintListHeadingV2: any;
    cashMemoDtoForCustomPrintListHeadingV3: any;
    cashMemoDtoForCustomPrintListHeadingV4: any;

    cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
    cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];


    cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintList: any;
    cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
    cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
    cashMemoDtoForCustomPrintDataList: any;
    cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
    cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
    cashMemoDtoForCustomPrintDataSummaryList: any;
    pageId = "lhrc"
    constructor(private memoLessRpt: ReportService, private router: Router,
        private memoReport: MemoReport, private masterReadService: MasterReadService, private modalService: NgbModal, public changeDetectorRef: ChangeDetectorRef) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
            this.setConsginorIndex();
            this.getConsigneeDetails(false);

            ///Rights for show or hide Destination 
            //            if ( this.showHideDestination == "Show Destination For LetterHead" ) {
            //                this.showDest = true;
            //            } else {
            //                this.showDest = false;
            //            }
            //           


            ///Rights for show or hide Godwon
            //            if ( this.showHideGodown == "Show Godown For LetterHead" ) {
            //                this.showGodown = true;
            //            } else {
            //                this.showGodown = false;
            //            }

            console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                console.log(this.userDataDtoReturnSession.sortedMapFeatures.Rights);
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {

                    if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Dest CashMemoLetterHeadSupAdmin") {
                        this.isDestView = true;

                    } else if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                        && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                        "Godown CashMemoLetterHeadAdmin") {
                        this.isGodownView = true;

                    }
                }
            }
            if (this.isDestView) {
                this.showDest = true;
                this.getDestinationDetails();
            }
            if (this.isGodownView) {
                this.showGodown = true;
                this.getLocalDestinationDetails();
            }

        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }


    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }


    ngOnInit(): void {
        var companyAddressDetls = this.address;
        var mainStation = this.userDataDtoReturnSession.mainStation;
        var destVisible = false;
        if (this.isDestView == true) {
            destVisible = true;
        } else {
            destVisible = false;
        }
        var godownVisible = false;
        if (this.isGodownView == true) {
            godownVisible = true;
        } else {
            godownVisible = false;
        }
        this.dtOptionsLetterHeadRpt = {

            dom: 'Bfrtip',
            buttons: [

                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                    footer: true,
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5]
                    },
                    title: function () {
                        var returnSummary = null;
                        if ($("#lhrcviewBy").val() == 'dateView') {
                            if (destVisible == true) {
                                returnSummary = "CashMemo LetterHead Report - " +
                                    "From Date : " + moment($("#lhrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                    "To Date : " + moment($("#lhrctoDate").val()).format('DD-MM-YYYY') + " Destination : " + $("#lhrcdestination").val();

                            } else if (godownVisible == true) {
                                returnSummary = "CashMemo LetterHead Report - " +
                                    "From Date : " + moment($("#lhrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                    "To Date : " + moment($("#lhrctoDate").val()).format('DD-MM-YYYY') + " Godown : " + $("#lhrclocalDestinationId").val();

                            } else {
                                returnSummary = "CashMemo LetterHead Report - " +
                                    "From Date : " + moment($("#lhrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                    "To Date : " + moment($("#lhrctoDate").val()).format('DD-MM-YYYY') + "Destination : " + mainStation;

                            }

                        } else if ($("#lhrcviewBy").val() == 'consigneeView') {
                            if (destVisible == true) {
                                returnSummary = "CashMemo LetterHead Report - " +
                                    "From Date : " + moment($("#lhrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                    "To Date : " + moment($("#lhrctoDate").val()).format('DD-MM-YYYY') + " Destination : " + $("#lhrcdestination").val() + " Consignee Name :  " + $("#lhrcconsigneeName").val();


                            } else {
                                returnSummary = "CashMemo LetterHead Report - " +
                                    "From Date : " + moment($("#lhrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                    "To Date : " + moment($("#lhrctoDate").val()).format('DD-MM-YYYY') + " Destination : " + mainStation + " Consignee Name :  " + $("#lhrcconsigneeName").val();
                            }

                        } else if ($("#lhrcviewBy").val() == 'consignorView') {
                            if (destVisible == true) {
                                returnSummary = "CashMemo LetterHead Report - " +
                                    "From Date : " + moment($("#lhrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                    "To Date : " + moment($("#lhrctoDate").val()).format('DD-MM-YYYY') + " Destination : " + $("#lhrcdestination").val() + " Consignor Name :  " + $("#lhrcconsignorName").val();

                            } else {
                                returnSummary = "CashMemo LetterHead Report - " +
                                    "From Date : " + moment($("#" + this.pageId + "fromDate").val()).format('DD-MM-YYYY') + " -  " +
                                    "To Date : " + moment($("#" + this.pageId + "toDate").val()).format('DD-MM-YYYY') + " Destination : " + mainStation + " Consignor Name :  " + $("#lhrcconsignorName").val();

                            }
                        } else if ($("#" + this.pageId + "viewBy").val() == 'lrView') {
                            returnSummary = "CashMemo LetterHead Report - " +
                                "From Date : " + moment($("#lhrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
                                "To Date : " + moment($("#lhrctoDate").val()).format('DD-MM-YYYY') + " Lr Number :  " + $("#lhrclrNumber").val();

                        }

                        return returnSummary;

                    },

                }
            ],
            //place holder in search/filter in datatable starts
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            //scroll in datatable starts
            responsive: true,
            "scrollX": true,
            "scrollY": 300,
            "scrollCollapse": true,
            "paging": true,
            "info": true,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };

                // Update footer by showing the total with the reference of the column index 
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(1).footer()).html();
                $(api.column(2).footer()).html();
                $(api.column(3).footer()).html();
                $(api.column(4).footer()).html();
                $(api.column(5).footer()).html();
                $(api.column(6).footer()).html();
                $(api.column(7).footer()).html();

            }

        }
    }

    ngOnDestroy(): void {
        this.dtTriggerLetterHeadRpt.unsubscribe();

    }
    ngAfterViewInit(): void {
        this.dtTriggerLetterHeadRpt.next();

    }

    viewByMode(viewBy: string) {
        if (viewBy === 'dateView') {
            this.viewDateWise = true;
            this.viewLrNoWise = false;
            this.viewConsigneeWise = false;
            this.viewConsignorWise = false;
            this.showDest = false;
            this.showGodown = false;
            if (this.isDestView) {
                this.showDest = true;
            }
            if (this.isGodownView) {
                this.showGodown = true;
            }
        } else if (viewBy === 'consigneeView') {
            this.viewDateWise = true;
            this.viewLrNoWise = false;
            this.showGodown = false;
            this.viewConsigneeWise = true;
            this.viewConsignorWise = false;
            this.showDest = false;
            if (this.isDestView) {
                this.showDest = true;
                $("#" + this.pageId + "destination").val('');
                $("#" + this.pageId + "consigneeName").val('');
                this.modelConsigneeName = '';
                this.consigneeNameTA = [];
            } else {
                this.consigneeNameTA = this.consigneeNameTATemp;
                $("#" + this.pageId + "consigneeName").val('All');
            }
        } else if (viewBy === 'consignorView') {
            this.viewDateWise = true;
            this.viewLrNoWise = false;
            this.showGodown = false;
            this.viewConsigneeWise = false;
            this.viewConsignorWise = true;
            this.showDest = false;
            if (this.isDestView) {
                this.showDest = true;
            }
            $("#" + this.pageId + "consignorName").val('');
            this.modelConsignorName = '';
            this.consignorNameTA = [];
        } else if (viewBy === 'lrView') {
            this.viewDateWise = false;
            this.viewLrNoWise = true;
            this.viewConsigneeWise = false;
            this.viewConsignorWise = false;
            this.showDest = false;
            this.showGodown = false;
            if (this.isDestView) {
                this.showDest = true;
            }

        } else {
            this.viewDateWise = false;
            this.viewLrNoWise = false;
            this.showDest = false;
            this.showGodown = false;
            this.viewConsigneeWise = false;
            this.viewConsignorWise = false;

        }
    }

    clickTA(inp) {
        inp._elementRef.nativeElement.value = '';
        inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
        inp._elementRef.nativeElement.focus();
    }



    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    setConsginorIndex() {
        for (let i = 0; i < this.consignorIndexOptions.length; i++) {
            this.consignorIndexTA.push(this.consignorIndexOptions[i].label);
        }
    }

    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "destinationOnly";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];
                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
            },
            () => console.log('done');

    };


    getLocalDestinationMethod() {
        this.lrDtoLocalDestination = new LRDto();
        this.lrDtoLocalDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoLocalDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoLocalDestination.mainstation = this.userDataDtoReturnSession.mainStation;
        this.lrDtoLocalDestination.mode = "All";
    }
    getLocalDestinationDetails() {
        this.getLocalDestinationMethod();
        this.masterReadService.getDestinationForLREntryService(this.lrDtoLocalDestination).subscribe(
            (response) => {
                if (response) {
                    //this.stationOptions = response;
                    console.log(response);
                    if (response.length > 0) {
                        this.localDestinationOptions = response;
                        this.localDestinationTA = [];
                        this.lrDtoLocalDestinationAllOption.destination = "All";
                        this.localDestinationTA.push(this.lrDtoLocalDestinationAllOption);
                        for (let i = 0; i < this.localDestinationOptions.length; i++) {
                            this.localDestinationTA.push(this.localDestinationOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Server Error",
                text: "While Getting destination details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    getConsigneeDetailsList(destShow) {
        this.consigneeNameOptions = [];
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        this.selectedDestination = this.modelDestinationFotGet;
        if (destShow) {
            this.partyMasterDtoForconsignee.office = this.selectedDestination.destination;
            console.log(this.selectedDestination);
        } else {
            this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        }
        this.partyMasterDtoForconsignee.branch = this.userDataDtoReturnSession.mainStation;
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignee.mode = "specific";
        console.log(this.partyMasterDtoForconsignee);
    }
    getConsigneeDetails = (destShow) => {
        this.getConsigneeDetailsList(destShow);

        this.showSpinnerForAction = true;
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignee Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];
                } else {
                    this.consigneeNameOptions = response;
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val('');
                    this.consigneeNamePartyDtoAllOption.consigneeName = "All";
                    this.consigneeNameTA.push(this.consigneeNamePartyDtoAllOption);
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                    }
                    this.consigneeNameTATemp = this.consigneeNameTA;
                    $("#" + this.pageId + "consigneeName").val('All');
                    if (this.selectedViewBy == 'consigneeView') {
                        $("#" + this.pageId + "consigneeGSTNumber").focus();
                    }
                    if (this.selectedViewBy == 'consignorView') {
                        $("#" + this.pageId + "consignorGSTNumber").focus();
                    }
                    console.log(this.consigneeNameTA);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignee details", "info");
            }, () => console.log('done');
    };

    consignorDropDownListner(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorIndex = e.item;
        this.getConsignorDetailsList();
    }

    getConsignorDetailsList() {
        this.consignorNameOptions = [];
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        this.selectedIndex = this.modelConsignorIndex;
        console.log(this.selectedIndex);
        this.partyMasterDtoForconsignor.indexValue = this.selectedIndex;

        if (this.selectedIndex != null && this.selectedIndex != '') {
            if (this.selectedIndex == 'All') {
                this.partyMasterDtoForconsignor.indexValue = "";
            }
            this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
            this.partyMasterDtoForconsignor.mode = "Index";
            this.getConsignorDetails();
        }

        console.log(this.partyMasterDtoForconsignor);
    }
    getConsignorDetails = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorMaster(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameOptions = [];
                    this.consignorNameTA = [];
                } else {
                    this.consignorNameOptions = response;
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');
                    this.consignorNamePartyDtoAllOption.consignorName = "All";
                    this.consignorNameTA.push(this.consignorNamePartyDtoAllOption);
                    for (let i = 0; i < this.consignorNameOptions.length; i++) {
                        this.consignorNameTA.push(this.consignorNameOptions[i]);
                    }
                    window.setTimeout(function () {
                        $("#" + this.pageId + "consignorName").focus();
                    }, 100);
                    console.log(this.consignorNameTA);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    };


    getConsigneeGstNumber(e) {
        if (e.keyCode == 13) {
            this.selectedConsigneeGSTNumber = $("#" + this.pageId + "consigneeGSTNumber").val();
            console.log(this.selectedConsigneeGSTNumber);
            if (this.selectedConsigneeGSTNumber != null && this.selectedConsigneeGSTNumber != '') {
                this.getConsigneeDetailsWithGst();
                //                 
            } else {
                swal({
                    title: "Warning",
                    text: "Please Enter the Consignee GST number",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    $("#" + this.pageId + "consigneeGSTNumber").focus();
                });
                this.consigneeNameOptions = [];
                this.consigneeNameTA = [];


            }

        }
    }

    getConsigneeDetailsWithGstList() {
        this.consigneeNameOptions = [];
        this.partyMasterDtoForconsignee = new PartyMasterDto();
        this.selectedConsigneeGSTNumber = $("#" + this.pageId + "consigneeGSTNumber").val();
        if (this.showDest) {
            this.selectedDestination = this.modelDestinationFotGet;
            this.partyMasterDtoForconsignee.office = this.selectedDestination.destination;
            console.log(this.selectedDestination);
        } else {
            this.partyMasterDtoForconsignee.office = this.userDataDtoReturnSession.mainStation;
        }
        this.partyMasterDtoForconsignee.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignee.isGST = true;
        this.partyMasterDtoForconsignee.mode = "Tinnumber";
        this.partyMasterDtoForconsignee.gstNoConsignee = this.selectedConsigneeGSTNumber;
        console.log(this.partyMasterDtoForconsignee);
    }
    getConsigneeDetailsWithGst = () => {
        this.getConsigneeDetailsWithGstList();
        this.showSpinnerForAction = true;
        this.masterReadService.getConsigneeMaster(this.partyMasterDtoForconsignee).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignee Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consigneeNameOptions = [];
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val('');
                    this.selectedConsigneeId = '';
                } else {
                    this.consigneeNameOptions = response;
                    this.consigneeNameTA = [];
                    $("#" + this.pageId + "consigneeName").val('');
                    for (let i = 0; i < this.consigneeNameOptions.length; i++) {
                        this.consigneeNameTA.push(this.consigneeNameOptions[i]);
                    }
                    $("#" + this.pageId + "consigneeName").val(this.consigneeNameOptions[0].consigneeName);
                    this.selectedConsigneeId = this.consigneeNameOptions[0].consigneeId;
                    console.log(this.consigneeNameTA);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignee details", "info");
            }, () => console.log('done');
    };


    consigneeDestListener(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestinationFotGet = e.item;
        this.selectedViewBy = $("#" + this.pageId + "viewBy").val();
        if (this.selectedViewBy == 'consigneeView') {
            this.getConsigneeDetails(true);
        }
        if (this.selectedViewBy == 'consignorView') {
            $("#" + this.pageId + "consignorGSTNumber").focus();
        }

    }

    //For Getting Consignor Details
    getConsignorGstNumber(e) {
        if (e.keyCode == 13) {
            this.selectedConsignorGSTNumber = $("#" + this.pageId + "consignorGSTNumber").val();
            //            console.log( this.selectedConsignorGSTNumber );
            if (this.selectedConsignorGSTNumber != null && this.selectedConsignorGSTNumber != '') {
                this.getConsignorDetailsForGst();
            } else {
                swal({
                    title: "Warning",
                    text: "Please Enter the Consignor GST number",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then(() => {
                    $("#" + this.pageId + "consignorGSTNumber").focus();
                })
                this.consignorNameOptions = [];
                this.consignorNameTA = [];
            }

        }
    }

    getConsignorDetailsForGst() {
        this.consignorNameOptions = [];
        this.partyMasterDtoForconsignor = new PartyMasterDto();

        this.selectedConsignorGSTNumber = $("#" + this.pageId + "consignorGSTNumber").val();
        this.partyMasterDtoForconsignor = new PartyMasterDto();
        //  this.partyMasterDtoForconsignor.mainStation = this.userDataDtoReturnSession.mainAdminStation;
        this.partyMasterDtoForconsignor.companyId = this.userDataDtoReturnSession.companyId;
        this.partyMasterDtoForconsignor.isGST = true;
        this.partyMasterDtoForconsignor.mode = "LREntry";
        this.partyMasterDtoForconsignor.gstNoConsignor = this.selectedConsignorGSTNumber;
        this.getConsignorDetailsForGSTList();
    }
    getConsignorDetailsForGSTList = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.getConsignorMaster(this.partyMasterDtoForconsignor).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Consignor Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    this.consignorNameOptions = [];
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');
                } else {
                    this.consignorNameOptions = response;
                    this.consignorNameTA = [];
                    $("#" + this.pageId + "consignorName").val('');
                    this.selectedConsignorId = '';
                    for (let i = 0; i < this.consignorNameOptions.length; i++) {
                        this.consignorNameTA.push(this.consignorNameOptions[i]);
                    }
                    $("#" + this.pageId + "consignorName").val(this.consignorNameOptions[0].consignorName);
                    this.selectedConsignorId = this.consignorNameOptions[0].consignorId;
                    console.log(this.consignorNameTA);
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the consignor details", "info");
            }, () => console.log('done');
    };


    rowSelectedConsignee(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsigneeNameId = e.item;
        this.selectedConsigneeId = this.modelConsigneeNameId.consigneeId;
    }

    rowSelectedConsignor(e: NgbTypeaheadSelectItemEvent) {
        this.modelConsignorNameId = e.item;
        this.selectedConsignorId = this.modelConsignorNameId.consignorId;
    }

    searchMethod() {
        this.searchLetterHeadRateDto = new RateMasterDto();
        this.selectedConsigneeName = $("#" + this.pageId + "consigneeName").val();
        this.selectedConsignorName = $("#" + this.pageId + "consignorName").val();
        this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
        this.selectedToDate = $("#" + this.pageId + "toDate").val();
        console.log(this.selectedFromDate, this.selectedToDate);
        this.selectedConsignorGSTNumber = $("#" + this.pageId + "consignorGSTNumber").val();
        this.selectedConsigneeGSTNumber = $("#" + this.pageId + "consigneeGSTNumber").val();
        this.selectedViewBy = $("#" + this.pageId + "viewBy").val();
        this.searchLetterHeadRateDto.consigneeName = this.selectedConsigneeName;

        this.searchLetterHeadRateDto.withLetterHead = true;
        this.searchLetterHeadRateDto.companyId = this.userDataDtoReturnSession.companyId;
        this.selectedDestination = $("#" + this.pageId + "destination").val();
        this.selectedGodown = $("#" + this.pageId + "localDestinationId").val();
        this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();

        this.searchLetterHeadRateDto.consigneeId = 0;
        if (this.selectedConsigneeName != null && this.selectedConsigneeName != '' && this.selectedConsigneeName != 'All') {
            this.searchLetterHeadRateDto.consigneeId = this.selectedConsigneeId
        }
        this.searchLetterHeadRateDto.consignorId = 0;
        this.searchLetterHeadRateDto.gstConsgnor = this.selectedConsignorGSTNumber;
        console.log(this.selectedConsignorId);

        if (this.selectedConsignorName != null && this.selectedConsignorName != '' && this.selectedConsignorName != 'All') {
            this.searchLetterHeadRateDto.consignorId = this.selectedConsignorId
            console.log(this.selectedConsignorId);
        }
        if (this.selectedConsignorGSTNumber != null && this.selectedConsignorGSTNumber != '') {

            this.searchLetterHeadRateDto.consignor = this.selectedConsignorName;
            if (this.selectedConsignorName != null && this.selectedConsignorName != '' && this.selectedConsignorName != 'All') {
                this.searchLetterHeadRateDto.consignorId = this.selectedConsignorId;
                console.log(this.selectedConsignorId);
            }

        }

        if (this.selectedViewBy == 'lrView') {
            if (this.isDestView) {
                if ((this.selectedDestination != null) && (this.selectedDestination != '') && (this.enteredLrNumber != null) && (this.enteredLrNumber != '')) {
                    this.searchLetterHeadRateDto.destination = this.selectedDestination;
                    this.searchLetterHeadRateDto.gcNoteNumber = this.enteredLrNumber;
                    this.searchLetterHeadRateDto.mode = 'LR';
                    this.searchMethodGetDetails();
                } else {
                    swal({
                        title: "Mandatory Field",
                        text: "LR No  And Destination Is Mandatory Fields",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            } else {

                if (this.enteredLrNumber != null && this.enteredLrNumber != '') {
                    this.searchLetterHeadRateDto.gcNoteNumber = this.enteredLrNumber;
                    this.searchLetterHeadRateDto.destination = this.userDataDtoReturnSession.mainStation;
                    this.searchLetterHeadRateDto.mode = 'LR';
                    this.searchMethodGetDetails();
                } else {
                    swal({
                        title: "Mandatory Field",
                        text: "LR No  Is Mandatory Fields",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
            }
        }
        //dateView
        else if (this.selectedViewBy == 'dateView') {
            if (this.selectedFromDate != null && this.selectedFromDate != '' && this.selectedToDate != null && this.selectedToDate != '') {
                this.searchLetterHeadRateDto.fromDateStg = this.selectedFromDate;
                this.searchLetterHeadRateDto.toDateStg = this.selectedToDate;
                this.searchLetterHeadRateDto.destination = this.userDataDtoReturnSession.mainStation;
                this.searchLetterHeadRateDto.mode = 'DateWiseSpecific';
                if (this.isDestView) {
                    if ((this.selectedDestination != null) && (this.selectedDestination != '')) {
                        this.searchLetterHeadRateDto.destination = this.selectedDestination;
                        this.searchLetterHeadRateDto.mode = 'DateWiseSpecific';
                    } else {
                        swal({
                            title: "Mandatory Field",
                            text: "Please select Destination",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });

                    }

                }
                if (this.isGodownView) {
                    if ((this.selectedGodown != null) && (this.selectedGodown != '')) {
                        if (this.selectedGodown == 'All') {
                            this.searchLetterHeadRateDto.destination = this.userDataDtoReturnSession.mainStation;
                            this.searchLetterHeadRateDto.mode = 'DateWiseAll';
                        } else {
                            this.searchLetterHeadRateDto.destination = this.selectedGodown;
                            this.searchLetterHeadRateDto.mode = 'DateWiseSpecific';
                        }
                    } else {
                        swal({
                            title: "Mandatory Field",
                            text: "Please select Godown",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });

                    }

                }
                this.searchMethodGetDetails();
            } else {
                swal({
                    title: "Mandatory Field",
                    text: "From Date & To Date Is Mandatory Fields",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            }
        }
        //ConsigneeView
        else if (this.selectedViewBy == 'consigneeView') {
            if (this.selectedFromDate != null && this.selectedFromDate != '' && this.selectedToDate != null && this.selectedToDate != '') {
                this.searchLetterHeadRateDto.fromDateStg = this.selectedFromDate;
                this.searchLetterHeadRateDto.toDateStg = this.selectedToDate;
                this.searchLetterHeadRateDto.destination = this.userDataDtoReturnSession.mainStation;


                if (this.searchLetterHeadRateDto.consigneeId == 0) {
                    swal({
                        title: "Mandatory Field",
                        text: "Please Select Consignee",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {

                    if (this.isDestView) {
                        if ((this.selectedDestination != null) && (this.selectedDestination != '')) {
                            this.searchLetterHeadRateDto.destination = this.selectedDestination;
                            this.searchLetterHeadRateDto.mode = 'ConsigneeSpecific';
                            this.searchMethodGetDetails();

                        } else {
                            swal({
                                title: "Mandatory Field",
                                text: "Please select Destination",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });
                        }
                    } else {
                        this.searchLetterHeadRateDto.mode = 'ConsigneeSpecific';
                        this.searchMethodGetDetails();
                    }
                }


            } else {
                swal({
                    title: "Mandatory Field",
                    text: "From Date & To Date Is Mandatory Fields",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            }
        }
        //Consignorview
        else if (this.selectedViewBy == 'consignorView') {
            console.log(this.isDestView, this.selectedDestination);
            if (this.selectedFromDate != null && this.selectedFromDate != '' && this.selectedToDate != null && this.selectedToDate != '') {
                this.searchLetterHeadRateDto.fromDateStg = this.selectedFromDate;
                this.searchLetterHeadRateDto.toDateStg = this.selectedToDate;
                this.searchLetterHeadRateDto.destination = this.userDataDtoReturnSession.mainStation;

                if (this.searchLetterHeadRateDto.consignorId == 0) {

                    swal({
                        title: "Mandatory Field",
                        text: "Please select Consignor",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    if (this.isDestView) {
                        if ((this.selectedDestination != null) && (this.selectedDestination != '')) {
                            this.searchLetterHeadRateDto.destination = this.selectedDestination;
                            this.searchLetterHeadRateDto.mode = 'ConsignorSpecific';
                            this.searchMethodGetDetails();
                        } else {
                            swal({
                                title: "Mandatory Field",
                                text: "Please select Destination",
                                icon: "warning",
                                closeOnClickOutside: false,
                                closeOnEsc: false,
                            });

                        }
                    } else {
                        this.searchLetterHeadRateDto.mode = 'ConsignorSpecific';
                        this.searchMethodGetDetails();
                    }
                }

            } else {
                swal({
                    title: "Mandatory Field",
                    text: "From Date & To Date Is Mandatory Fields",
                    icon: "warning",
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                });
            }
        }
        ///
    }
    ///
    clearMethod() {
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "letterHeadRptTable").DataTable().destroy();
        this.letterHeadRptDataList = [];
        this.dtTriggerLetterHeadRpt.next();
        this.searchLetterHeadRateDto = new RateMasterDto();
        $("#" + this.pageId + "consigneeName").val('');
        $("#" + this.pageId + "consignorName").val('');
        this.fromDatesModal = null;
        this.toDatesModal = null;
        $("#" + this.pageId + "fromDate").val('');
        $("#" + this.pageId + "toDate").val('');
        $("#" + this.pageId + "consignorGSTNumber").val('');
        $("#" + this.pageId + "consigneeGSTNumber").val('');
        $("#" + this.pageId + "lrNumber").val('');
        $("#" + this.pageId + "destination").val('');
        $("#" + this.pageId + "localDestinationId").val('');
        $("#" + this.pageId + "consignorIndex").val('');
        $("#" + this.pageId + "consignorIndex").val('');
        $("#" + this.pageId + "viewBy").val('dateView');
        this.selectedConsigneeName = '';
        this.selectedConsignorName = '';
        this.selectedFromDate = '';
        this.selectedToDate = '';
        this.selectedConsignorGSTNumber = '';
        this.selectedConsigneeGSTNumber = '';
        this.selectedViewBy = '';
        this.selectedDestination = '';
        this.selectedGodown = '';
        this.enteredLrNumber = '';
        this.selectedConsigneeId = '';
        this.selectedConsignorId = '';
        this.viewDateWise = true;
        this.viewLrNoWise = false;
        this.viewConsigneeWise = false;
        this.viewConsignorWise = false;
        this.showDest = false;
        this.showGodown = false;

        this.modelConsigneeName = '';
        this.modelConsignorName = '';
        this.consignorNameTA = [];

        if (this.isDestView) {
            this.showDest = true;
            this.consigneeNameTA = [];
        } else {
            this.consigneeNameTA = this.consigneeNameTATemp;
            $("#" + this.pageId + "consigneeName").val('All');
        }
        if (this.isGodownView) {
            this.showGodown = true;
        }
        this.showSpinnerForAction = false;
        this.selectedRecDate = '';
        $("#" + this.pageId + "receivedDate").val('');
        this.getSelectedRowMemoNumber = '';
        this.modelConsigneeNameId = '';
        this.modelConsignorNameId = '';
        this.modelDestinationFotGet = '';
    }
    searchMethodGetDetails = () => {
        this.showSpinnerForAction = true;
        console.log(this.searchLetterHeadRateDto);
        this.memoReport.getCashMemoLetterHeadDtls(this.searchLetterHeadRateDto).subscribe(
            (response) => {
                console.log(response);
                this.showSpinnerForAction = false;
                $("#" + this.pageId + "letterHeadRptTable").DataTable().destroy();
                this.letterHeadRptDataList = [];
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No  Details found !",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {
                    this.letterHeadRptDataList = response;
                }
                this.dtTriggerLetterHeadRpt.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the  details", "info");
            }, () => console.log('done');
    };

    openLessEntryStatusBackPopup(contentLetterHead) {
        this.modalService.open(contentLetterHead, { centered: true }).result.then(
            resultContent => {
                this.contentLetterHead = `Closed with: ${resultContent}`;
            },
            reason => {
                this.contentLetterHead = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    /*rowSelectedLetterRpt( letterHeadRptData, index ) {
          this.getSelectedRowMemoNumber='';
        this.getSelectedRowMemoNumber=letterHeadRptData.memoNumber;
   console.log(this.getSelectedRowMemoNumber);
          }*/

    setReturnLr(contentLetterHead, letterHeadRptData) {

        this.getSelectedRowMemoNumber = '';
        this.getSelectedRowMemoNumber = letterHeadRptData.memoNumber;
        console.log(this.getSelectedRowMemoNumber);

        if (this.getSelectedRowMemoNumber != null && this.getSelectedRowMemoNumber != '') {
            /* this.modalService.open( contentLetterHead, { centered: true } ).result.then(
                 resultContent => {
                     this.closeResultContentLetterHead = `Closed with: ${resultContent}`;
                 },
                 reason => {
                     this.closeResultContentLetterHead = `Dismissed ${this.getDismissReason( reason )}`;
                 }
             );*/

            this.modalRefferenceContentLetterHeadPopUp = this.modalService.open(contentLetterHead,
                { centered: true, windowClass: "modalClassForPopUpTruckOwner" });
            this.modalRefferenceContentLetterHeadPopUp.result.then((result) => {
                this.modalRefferenceContentLetterHeadClosePopUp = `Closed with: ${result}`;
            }, reason => {
                this.modalRefferenceContentLetterHeadClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    validateApplyBtnPopUp(contentLetterHead) {
        this.selectedRecDate = $("#" + this.pageId + "receivedDate").val();
        if (this.selectedRecDate != null && this.selectedRecDate != '') {
            this.returnLrDetails();

        } else {
            swal({
                title: "Warning",
                text: "Please Select Received Date",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        }

    }

    closeBtnPopup() {
        this.selectedRecDate = '';
        $("#" + this.pageId + "receivedDate").val('');
        this.getSelectedRowMemoNumber = '';
    }

    getReturnLrDetails() {
        this.selectedRecDate = $("#" + this.pageId + "receivedDate").val();
        this.returnLrSetRateDto = new RateMasterDto();
        this.returnLrSetRateDto.receiveDate = this.selectedRecDate;
        this.returnLrSetRateDto.lastModifiedBy = this.userDataDtoReturnSession.userId;
        this.returnLrSetRateDto.memoNumber = this.getSelectedRowMemoNumber;
        this.returnLrSetRateDto.mode = "ReceivedDate";
        this.returnLrSetRateDto.companyId = this.userDataDtoReturnSession.companyId;
        console.log(this.returnLrSetRateDto);
    }
    returnLrDetails = () => {
        this.getReturnLrDetails();
        this.showSpinnerForAction = true;
        this.memoReport.updateCashMemoLetterHead(this.returnLrSetRateDto).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                let retrunStatusForUpdateMemo = response;
                if (retrunStatusForUpdateMemo.status == "success") {
                    swal({
                        title: "Warning",
                        text: "Changes Are Saved Successfully",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    }).then(() => {
                        this.modalRefferenceContentLetterHeadPopUp.close();
                        this.searchMethodGetDetails();
                        $("#" + this.pageId + "receivedDate").val('');
                    })
                } else {

                    swal({
                        title: "Warning",
                        text: "Changes Are Not Saved",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Failed", "info");
            }, () => console.log('done');
    };

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    getImg(letterHeadRptData, letterHeadPopUpTemplate) {
        this.lrNoPopUp = null;
        this.memoNumberPopUp = null;
        if (letterHeadRptData.imgURL == null || letterHeadRptData.imgURL == undefined) {
            this.lrNoPopUp = letterHeadRptData.gcNoteNumber;
            this.memoNumberPopUp = letterHeadRptData.memoNumber;
            this.modalRefferenceLetterHeadPopUp = this.modalService.open(letterHeadPopUpTemplate,
                { centered: true });
            this.modalRefferenceLetterHeadPopUp.result.then((result) => {
                this.modalRefferenceContentLetterHeadClosePopUp = `Closed with: ${result}`;
            }, reason => {
                this.modalRefferenceContentLetterHeadClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
            });
        } else {
            window.open(letterHeadRptData.imgURL, '_blank');
        }
    }

    onFileChangedLetterHead(event) {
        this.selectedLetterHeadDetails = event.target.files[0];
        //console.log(this.selectedLetterHeadDetails);
    }

    uploadLetterHeadBtnPopUp() {
        if ($("#" + this.pageId + "letterHeadFileUpload").val() != null &&
            $("#" + this.pageId + "letterHeadFileUpload").val() != undefined &&
            $("#" + this.pageId + "letterHeadFileUpload").val() != "") {
            if ((this.selectedLetterHeadDetails.type != null) &&
                (this.selectedLetterHeadDetails.type == "image/jpeg" ||
                    this.selectedLetterHeadDetails.type == "image/jpg" ||
                    this.selectedLetterHeadDetails.type == "image/png")) {
                var splitTypeLetterHead = this.selectedLetterHeadDetails.type.split("/");

                const commonListLetterHeadDoc = "letterheaddocs&&documents&&ASRAR&&" + splitTypeLetterHead[1] + "&&" + this.lrNoPopUp + "";
                const formData = new FormData();
                formData.append(commonListLetterHeadDoc, commonListLetterHeadDoc);
                formData.append('myfileLetterHead', this.selectedLetterHeadDetails, this.selectedLetterHeadDetails.name);
                this.masterReadService.saveRCInsurancePanForTruckMasterFileUpload(formData).subscribe(
                    (response) => {
                        if (response) {
                            if (response[0] != "Error") {
                                this.isUploaded = true;
                                this.applyLetterHeadBtnPopUp();
                                swal("File Upload", "File Uploaded Succesfully", "success");
                            } else {
                                swal("ERROR", "Correct the File and Upload Again", "warning");
                            }
                        }
                        this.changeDetectorRef.detectChanges();
                    }), (error) => swal("Error", "Server Error While Uploading a File", "error"),
                    () => console.log('done');
            }
        } else {
            swal("Mandatory Field", "Only jpg & png image format are allowed to upload for Letter head Document File", "warning");
        }
    }

    applyLetterHeadBtnPopUp() {
        this.rateMasterDto = new RateMasterDto();
        this.rateMasterDto.memoNumber = this.memoNumberPopUp;
        this.rateMasterDto.lastModifiedBy = this.userDataDtoReturnSession.userId;
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDto.mode = "Image";
        if (this.isUploaded) {
            const constImgUrlLic = "http://letterheaddocs.s3.amazonaws.com/documents/" + this.lrNoPopUp + "_" + this.selectedLetterHeadDetails.name + "";
            this.rateMasterDto.imgURL = constImgUrlLic;
        }
        this.memoReport.updateCashMemoLetterHead(this.rateMasterDto).subscribe(
            (response) => {
                let responseRateMasterDtoReturn = response;
                if (responseRateMasterDtoReturn.status == "success") {
                    swal("Result", "Changes Are Saved Successfully", "success");
                    this.modalRefferenceLetterHeadPopUp.close();
                    this.searchMethodGetDetails();
                    this.closeLetterHeadBtnPopUp();
                } else {
                    swal("Result", "Changes Are Not Saved", "warning");
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                // this.showSpinnerForAction = false;
                swal("Error", "Server Failed", "info");
            }, () => console.log('done');
    }

    closeLetterHeadBtnPopUp() {
        this.modalRefferenceLetterHeadPopUp.close();
        this.lrNoPopUp = null;
        this.memoNumberPopUp = null;
        this.isUploaded = false;
        $("#" + this.pageId + "letterHeadFileUpload").val('');
    }
    customPrintLetterHeadRpt() {
        if (this.letterHeadRptDataList.length == 0) {
            swal({

                title: "No records found to print",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {

            localStorage.clear();
            this.cashMemoDtoForCustomPrintList = [];
            this.cashMemoDtoForCustomPrintListColumnNames = ["LR No", "Memo Number", "Memo Date", "Memo Amt", "Consignor"];
            this.cashMemoDtoForCustomPrintListColumnWidths = [25, 25, 20, 20, 35];
            for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
                this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
                this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
            }
            this.cashMemoDtoForCustomPrintDataList = [];
            this.cashMemoDtoForCustomPrintDataSummaryList = [];


            for (let i = 0; i < this.letterHeadRptDataList.length; i++) {
                this.cashMemoDtoForCustomPrintListColumnValues = [this.letterHeadRptDataList[i].gcNoteNumber, this.letterHeadRptDataList[i].memoNumber,
                this.letterHeadRptDataList[i].memoName, this.letterHeadRptDataList[i].memoAmt, this.letterHeadRptDataList[i].consignor];
                /////////

                this.cashMemoDtoForCustomPrintData = new CashMemoDto();
                this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
                this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

            }
            this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.letterHeadRptDataList.length, "", "", "", ""];
            //heading logics For Date


            //this.todayDate=this.datePipe.transform(new Date(), "dd-MM-yyyy");
            this.cashMemoDtoForCustomPrintListHeadingV1 = [];
            this.cashMemoDtoForCustomPrintListHeadingV2 = [];
            this.cashMemoDtoForCustomPrintListHeadingV3 = [];
            this.cashMemoDtoForCustomPrintListHeadingV4 = [];

            this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date", "To Date"];
            this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Destination"];

            this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.searchLetterHeadRateDto.fromDateStg, this.searchLetterHeadRateDto.toDateStg];
            this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.searchLetterHeadRateDto.destination];


            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
                this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
            }

            for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
                this.cashMemoDtoForCustomPrint = new CashMemoDto();
                this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
                this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
                this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
            }

            //this.cashMemoDtoForCustomPrintListHeadingNamesV3 = ["Received the above number of G.C for collection amounting to //Rs."+this.memoAmountSummary+". I will render the account in the evening"];
            //for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV3.length; i++) {
            //this.cashMemoDtoForCustomPrint = new CashMemoDto();
            //this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV3[i];
            //this.cashMemoDtoForCustomPrintListHeadingV3.push(this.cashMemoDtoForCustomPrint);
            //	}

            //this.cashMemoDtoForCustomPrintListHeadingNamesV4 = ["Signature"];
            //for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV4.length; i++) {
            //	this.cashMemoDtoForCustomPrint = new CashMemoDto();
            //	this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV4[i];
            //	this.cashMemoDtoForCustomPrintListHeadingV4.push(this.cashMemoDtoForCustomPrint);
            //}

            localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
            localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
            localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
            localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));

            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV3', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV3));
            //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV4', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV4));
            localStorage.setItem('printTitle', "CashMemo LetterHead Report");
            this.viewCustomPrintV1 = true;

            window.addEventListener('afterprint', (onclick) => {
                if (this.viewCustomPrintV1) {
                    this.viewCustomPrintV1 = false;
                    localStorage.clear();
                }
            });
        }
    }
}
