
import { NgbDateStruct, NgbCalendar, NgbTypeaheadSelectItemEvent, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { NgModule, ChangeDetectorRef } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { ReportService } from 'src/app/dataService/report-service';
//from the particular global folder ends
//for datepicker starts
const my = new Date();
//for datepicker ends
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable, merge } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
//for the select option with filter ends
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { LRDto } from 'src/app/dto/LR-dto';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import swal from "sweetalert";
import { Router } from '@angular/router';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { RateMasterDto } from 'src/app/dto/RateMaster-dto';

@Component({
    selector: 'app-commodity-rate-master',
    templateUrl: './commodity-rate-master.component.html',
    styleUrls: ['./commodity-rate-master.component.css']
})
export class CommodityRateMasterComponent implements OnInit {

    getDataFrmServiceFrTable: any;
    commissionRateMasterDataList: any;
    onDestroyUnsubscribtionCommissionRateMaster: Subscription;

    getDataFrmServiceFrCommodityTable: any;
    commodityDataList: any;
    onDestroyUnsubscribtionCommodity: Subscription;
    model: NgbDateStruct;
    date: { year: number; month: number };

    loadingIndicator = true;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

    dtTriggerCommissionRateMaster: Subject<any> = new Subject();
    dtTriggerCommodity: Subject<any> = new Subject();
    dataTable: any;
    dtOptionsCommissionRateMaster: any;
    dtOptionsCommodity: any;

    //For Destination
    destinationOptions: LRDto[];
    lrDtoDestination: LRDto = new LRDto();
    public modelDestination: any;
    destinationTA: Array<LRDto> = [];
    focusDestinationTA$ = new Subject<string>();
    searchDestination = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const inputFocus$ = this.focusDestinationTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map((term) =>
                (term === ""
                    ? this.destinationTA
                    : this.destinationTA.filter(
                        (v) =>
                            v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1
                    )
                ).slice(0, 200)
            )
        );
    };
    formatterDestination = (x: { destination: string }) => x.destination;
    //For Commodity 
    rateMasterDto: RateMasterDto = new RateMasterDto();

    rateMasterDtoOptions: RateMasterDto[];
    rateMasterDtoCommodityUnldAddNew: RateMasterDto = new RateMasterDto();
    public modelCommodityMainUnldg: any;
    modelCommodityMainUnldgTA: Array<RateMasterDto> = [];
    focusCommodityMainUnldgTA$ = new Subject<string>();
    searchCommodityMainUnldg = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommodityMainUnldgTA$;

        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.modelCommodityMainUnldgTA
                : this.modelCommodityMainUnldgTA.filter(v => v.commodityName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommodityMainUnldg = (x: { commodityName: string }) => x.commodityName;


    userDataDtoReturnSession: any;
    isLoggedIn = true;
    showSpinnerForAction = false;
    address: any;
    rateMasterDtoForGetDetails: RateMasterDto = new RateMasterDto();
    rateMasterDtoForDestination: RateMasterDto = new RateMasterDto();
    selectedDestination: any;
    public modelDestinationFotGet: any;
    private newAttributeToSetParticulars: any = {};
    editParticularsTableDetailsId: any;
    enteredGDHamali: any;
    enteredDDHamali: any;
    selectedCommodity: any;
    enteredRecieptCharge: any;
    enteredServiceCharge: any;
    selectedUnit: any;
    rateMasterDtoForSave: RateMasterDto = new RateMasterDto();
    particularDetailsRateDto: Array<RateMasterDto> = [];
    rateMasterDtoForValidateParticular: RateMasterDto = new RateMasterDto();
    rateMasterDtoForDelete: RateMasterDto = new RateMasterDto();
    rowId = 0;
    pageId="crtmc";
    constructor(private commissionRateMasterRpt: ReportService, private router: Router,
        private masterReadService: MasterReadService, public changeDetectorRef : ChangeDetectorRef) {



        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
            this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;

            this.getDestinationDetails();
            this.getCommodityMasterDetailsList();

        }
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    control = new FormControl();

    commodityOptions = [
        { id: 1, label: 'BICYCLE' },
        { id: 2, label: 'HANDLE' },
        { id: 3, label: 'COOPER  SHEET' },
        { id: 3, label: 'PLYWOOD' }
    ];

    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Do your stuff
                dtInstance.destroy();
            });
        });
    }

    ngOnInit(): void {
        this.dtOptionsCommissionRateMaster = {

            dom: "Bfrtip",

            buttons: [],

            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search...",
            },

            processing: true,
            //scroll in datatable starts
            responsive: true,
            scrollX: true,
            scrollY: 380,
            scrollCollapse: true,

            paging: false,
            info: false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };



            }
        },
            this.dtOptionsCommodity = {

                dom: "Bfrtip",

                buttons: [],

                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search...",
                },

                processing: true,
                //scroll in datatable starts
                responsive: true,
                scrollX: true,
                scrollY: 160,
                scrollCollapse: true,
                paging: false,
                info: true,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };


                }
            }
    }


    ngOnDestroy(): void {
        this.dtTriggerCommissionRateMaster.unsubscribe();
        this.dtTriggerCommodity.unsubscribe();


    }
    ngAfterViewInit(): void {
        this.dtTriggerCommissionRateMaster.next();
        this.dtTriggerCommodity.next();
    }

    //for the select option with filter starts

    transform(items: any[], searchTerm: string, labelKey?: string): any {
        if (!items || !searchTerm) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) === true
        );
    }

    //for the select option with filter ends


    //For Destination Drop down
    getDestinationMethod() {
        this.lrDtoDestination = new LRDto();
        this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
        this.lrDtoDestination.mode = "destinationWithCity";
    }
    getDestinationDetails() {
        this.getDestinationMethod();
        this.showSpinnerForAction = true;
        this.masterReadService
            .getDestinationForLREntryService(this.lrDtoDestination)
            .subscribe((response) => {
                this.showSpinnerForAction = false;
                if (response) {
                    //this.stationOptions = response;
                    if (response.length > 0) {
                        this.destinationOptions = response;
                        this.destinationTA = [];

                        for (let i = 0; i < this.destinationOptions.length; i++) {
                            this.destinationTA.push(this.destinationOptions[i]);
                        }
                    }
                }
                this.changeDetectorRef.detectChanges();
            }),
            (error) => {
                this.showSpinnerForAction = false;
                swal(
                    "Error",
                    "Server Problem Occurred While getting the Destination Details",
                    "info"
                );
            },
            () => console.log("done");
    }
    //For Commodity

    getCommodityListRead() {
        this.rateMasterDto.companyId = this.userDataDtoReturnSession.companyId;
        //this.rateMasterDto.destination = null;
        this.rateMasterDto.mode = "AllCommodity";
    }

    getCommodityMasterDetailsList = () => {
        //        console.log( this.lrDto.companyId );
        this.getCommodityListRead();
        this.masterReadService.getCommodityDetails(this.rateMasterDto).subscribe(
            (response) => {
                if (response) {
                    console.log(response);

                    if (response.length == 0) {
                        swal({
                            title: "Warning",
                            text: "No commodity details records found!",
                            icon: "warning",
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        });
                        this.rateMasterDtoOptions = [];
                        this.modelCommodityMainUnldgTA = [];


                    } else {
                        this.rateMasterDtoOptions = response;
                        this.modelCommodityMainUnldgTA = [];

                        for (let i = 0; i < this.rateMasterDtoOptions.length; i++) {
                            this.modelCommodityMainUnldgTA.push(this.rateMasterDtoOptions[i]);
                        }


                    }
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => swal({
                title: "Error",
                text: "Server Error While Getting Commodity Master Details",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }),
            () => console.log('done');
    };

    getDetailsMethod() {
        this.rateMasterDtoForGetDetails = new RateMasterDto();
        this.rateMasterDtoForGetDetails.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDtoForGetDetails.mode = 'All';
        this.rateMasterDtoForGetDetails.status = 'InUse';
        console.log(this.rateMasterDtoForGetDetails);
        this.getDetailsMethodInfo();

    }
    getDetailsMethodInfo = () => {
        this.showSpinnerForAction = true;

        this.masterReadService.getDestCommdRateDetails(this.rateMasterDtoForGetDetails).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#"+this.pageId+"commissionRateMasterTableId").DataTable().destroy();
                this.commissionRateMasterDataList = [];

                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                } else {
                    this.commissionRateMasterDataList = response;
                }

                this.dtTriggerCommissionRateMaster.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Commodity Details", "info");
            }, () => console.log('done');
    };
    commodityDestListener(e: NgbTypeaheadSelectItemEvent) {
        this.modelDestinationFotGet = e.item;
        this.selectedDestination = this.modelDestinationFotGet.destination;
        console.log(this.selectedDestination)
        if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedDestination != "All") {
            this.getDetailsMethodforDest();
        } else {
            $("#"+this.pageId+"commodityTableId").DataTable().destroy();
            this.commodityDataList = [];
            this.dtTriggerCommodity.next();

        }
    }
    getDetailsMethodforDest() {
        this.selectedDestination = this.modelDestinationFotGet.destination;
        this.rateMasterDtoForDestination = new RateMasterDto();
        this.rateMasterDtoForDestination.companyId = this.userDataDtoReturnSession.companyId;
        this.rateMasterDtoForDestination.mode = 'specific';
        this.rateMasterDtoForDestination.status = 'InUse';
        this.rateMasterDtoForDestination.destination = this.selectedDestination;
        console.log(this.rateMasterDtoForDestination);
        this.getDetailsMethodForDestInfo();
        console.log('came');

    }


    getDetailsMethodForDestInfo = () => {
        this.showSpinnerForAction = true;

        this.masterReadService.getDestCommdRateDetails(this.rateMasterDtoForDestination).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                $("#"+this.pageId+"commodityTableId").DataTable().destroy();
                this.commodityDataList = [];


                console.log(response);
                if (response.length == 0) {
                    swal({
                        title: "Warning",
                        text: "No Records found for this search!",
                        icon: "warning",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                    $("#"+this.pageId+"unit").val('Article');
                    $("#"+this.pageId+"reciptCharge").val('');
                    $("#"+this.pageId+"serviceCharge").val('');
                } else {
                    this.commodityDataList = response;
                    console.log(this.commodityDataList);
                    let setunit = this.commodityDataList[0].unit;
                    console.log(setunit);
                    if (setunit == 'Article') {
                        $("#"+this.pageId+"unit").val('Article');
                    } else if (setunit == 'Actual Weight') {
                        $("#"+this.pageId+"unit").val('ActualWeight');
                    } else if (setunit == 'Charged Weight') {
                        $("#"+this.pageId+"unit").val('ChargedWeight');
                    }

                    $("#"+this.pageId+"reciptCharge").val(this.commodityDataList[0].receiptCharges);
                    $("#"+this.pageId+"serviceCharge").val(this.commodityDataList[0].serviceCharges);
                }

                this.dtTriggerCommodity.next();
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Commodity for Particular Destination Details", "info");
            }, () => console.log('done');
    };

    rowSelectedParticularDataOnEdit(commodityData, index) {
        this.editParticularsTableDetailsId = index;
        console.log(this.editParticularsTableDetailsId);
        this.showSpinnerForAction = true;
        // $("#"+this.pageId+"commodityTableId").DataTable().destroy();
        // this.commodityDataList.splice(index, 1);
        // this.dtTriggerCommodity.next();

        setTimeout(() => {
            $("#"+this.pageId+"CommodityMainUnldgId").val(commodityData.commodityName);
            $("#"+this.pageId+"gdHamali").val(commodityData.hamaligd);
            $("#"+this.pageId+"ddHamali").val(commodityData.hamali);
            this.rowId = 0;
            this.rowId = commodityData.id;
            console.log(this.rowId);
            this.showSpinnerForAction = false;
        }, 1000);

    }

    deleteSelectedParticularDetails(commodityData, index) {
        this.rowId = commodityData.id;
        console.log(this.rowId );
        swal({
            title: "Remove",
            text: "Sure You want to remove the particular commodity details",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
            buttons: ["No", "Yes"],
        }).then((bulk) => {
            if (bulk) {
                if (this.rowId == 0) {
                    $("#"+this.pageId+"commodityTableId").DataTable().destroy();
                    this.commodityDataList.splice(index, 1);
                    this.dtTriggerCommodity.next();

                } else {
                    this.deleteCommodity(this.rowId);
                }


            }
        });

    }

    deleteCommodity = (commodityData) => {
        this.showSpinnerForAction = true;
        this.rateMasterDtoForDelete = new RateMasterDto();
        this.rateMasterDtoForDelete.id = this.rowId;
        this.rateMasterDtoForDelete.companyId = this.userDataDtoReturnSession.companyId;
        this.masterReadService.deleteDestCommdRateDetails(this.rateMasterDtoForDelete).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                console.log(this.commodityDataList.length);
                if (response.status == "success") {
                    this.getDetailsMethodforDest();
                    this.getDetailsMethod();
                    swal({
                        title: "Delete",
                        text: "Commodity Rate details Deleted successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {

                    swal({
                        title: "Failed",
                        text: "Problem occurred while Deleting the Commodity Rate details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Deleting the Commodity Rate  details", "info");
            }, () => console.log('done');
    };

    validateAddInTable() {
        this.selectedCommodity = $("#"+this.pageId+"CommodityMainUnldgId").val();
        this.enteredGDHamali = ($("#"+this.pageId+"gdHamali").val() == null ? 0 : $("#"+this.pageId+"gdHamali").val() == '' ? 0 : $("#"+this.pageId+"gdHamali").val() == undefined ? 0 : $("#"+this.pageId+"gdHamali").val());
        this.enteredDDHamali = ($("#"+this.pageId+"ddHamali").val() == null ? 0 : $("#"+this.pageId+"ddHamali").val() == '' ? 0 : $("#"+this.pageId+"ddHamali").val() == undefined ? 0 : $("#"+this.pageId+"ddHamali").val());

        if (this.selectedCommodity == null || this.selectedCommodity == '') {
            swal({
                title: "Warning",
                text: "Please Select Commodity",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {
            this.newAttributeToSetParticulars.commodityName = this.selectedCommodity;
            this.newAttributeToSetParticulars.hamaligd = this.enteredGDHamali;
            this.newAttributeToSetParticulars.hamali = this.enteredDDHamali;
            this.newAttributeToSetParticulars.id = 0;
            if (this.editParticularsTableDetailsId != null && this.editParticularsTableDetailsId != undefined) {
                $("#"+this.pageId+"commodityTableId").DataTable().destroy();
                this.commodityDataList.splice(this.editParticularsTableDetailsId, 1);
                if (this.rowId != 0) {
                    this.newAttributeToSetParticulars.id = this.rowId;
                } else {
                    this.newAttributeToSetParticulars.id = 0;
                }


                console.log(this.editParticularsTableDetailsId);
                console.log(this.newAttributeToSetParticulars.id);

            }

            if (this.commodityDataList == null ||
                this.commodityDataList == undefined ||
                this.commodityDataList.length == 0) {
                this.commodityDataList = [];
            }
            if (this.commodityDataList.length == 0) {
                this.commodityDataList = [];
                $("#"+this.pageId+"commodityTableId").DataTable().destroy();
                this.commodityDataList.push(this.newAttributeToSetParticulars);
                this.dtTriggerCommodity.next();
                this.clearFields();
            } else {
                const result = this.commodityDataList.filter(commodityData =>
                    commodityData.commodityName === this.newAttributeToSetParticulars.commodityName);
                if (result.length > 0) {
                    swal("Duplicate Entry", "Commodity Name  Already Available ,Please Edit the Item Name and then Add", "warning");
                } else {
                    $("#"+this.pageId+"commodityTableId").DataTable().destroy();
                    this.commodityDataList.push(this.newAttributeToSetParticulars);
                    this.dtTriggerCommodity.next();
                    this.clearFields();
                    console.log(this.commodityDataList.length);
                }
            }



        }



    }
    clearFields() {
        this.selectedCommodity = '';
        $("#"+this.pageId+"CommodityMainUnldgId").val('');
      
        this.modelCommodityMainUnldg = '';
        this.enteredGDHamali = '';
        $("#"+this.pageId+"gdHamali").val('');
        this.enteredDDHamali = '';
        $("#"+this.pageId+"ddHamali").val('');
        this.editParticularsTableDetailsId = null;
        this.newAttributeToSetParticulars = {};
        this.rowId = 0;
    }

    clearAll() {
        $("#"+this.pageId+"commodityTableId").DataTable().destroy();
        this.commodityDataList = [];
        this.dtTriggerCommodity.next();
        $("#"+this.pageId+"destination").val('');
        this.clearFields();
        $("#"+this.pageId+"unit").val('Article');
        $("#"+this.pageId+"reciptCharge").val('');
        $("#"+this.pageId+"serviceCharge").val('');
        this.rateMasterDtoForDestination = new RateMasterDto();
        this.modelDestinationFotGet = '';
        this.rowId = 0;

    }
    btnApply() {
        this.selectedDestination = $("#"+this.pageId+"destination").val();
        this.enteredRecieptCharge = ($("#"+this.pageId+"reciptCharge").val() == null ? 0 : $("#"+this.pageId+"reciptCharge").val() == '' ? 0 : $("#"+this.pageId+"reciptCharge").val() == undefined ? 0 : $("#"+this.pageId+"reciptCharge").val());
        this.enteredServiceCharge = ($("#"+this.pageId+"serviceCharge").val() == null ? 0 : $("#"+this.pageId+"serviceCharge").val() == '' ? 0 : $("#"+this.pageId+"serviceCharge").val() == undefined ? 0 : $("#"+this.pageId+"serviceCharge").val());
        this.selectedUnit = $("#"+this.pageId+"unit").val();
        if (this.selectedDestination == null || this.selectedDestination == '' || this.commodityDataList.length == 0) {
            swal({
                title: "Warning",
                text: "Please Select Destination or Data is Empty",
                icon: "warning",
                closeOnClickOutside: false,
                closeOnEsc: false,
            });
        } else {

            this.rateMasterDtoForSave = new RateMasterDto();
            this.rateMasterDtoForSave.rateMasterDtos = this.getDataListValues();
            this.rateMasterDtoForSave.destination = this.selectedDestination;
            this.rateMasterDtoForSave.unit = this.selectedUnit;
            this.rateMasterDtoForSave.receiptCharges = this.enteredRecieptCharge;
            this.rateMasterDtoForSave.serviceCharges = this.enteredServiceCharge;
            this.rateMasterDtoForSave.userName = this.userDataDtoReturnSession.userId;
            this.rateMasterDtoForSave.companyId = this.userDataDtoReturnSession.companyId;
            console.log(this.rateMasterDtoForSave);
            this.saveCommodityRateDetails();
        }


    }
    getDataListValues() {
        this.particularDetailsRateDto = [];
        for (let i = 0; i < this.commodityDataList.length; i++) {
            let maintenance = this.commodityDataList[i];
            this.rateMasterDtoForValidateParticular = new RateMasterDto();
            this.rateMasterDtoForValidateParticular.commodityName = maintenance.commodityName;
            this.rateMasterDtoForValidateParticular.hamaligd = maintenance.hamaligd;
            this.rateMasterDtoForValidateParticular.hamaliDD = maintenance.hamali;
            this.particularDetailsRateDto.push(this.rateMasterDtoForValidateParticular);
        }
        return this.particularDetailsRateDto;

    }

    saveCommodityRateDetails = () => {
        this.showSpinnerForAction = true;
        this.masterReadService.addDestCommdRateDetails(this.rateMasterDtoForSave).subscribe(
            (response) => {
                this.showSpinnerForAction = false;
                console.log(response);
                if (response.status == "success") {
                    this.clearAll();
                    this.getDetailsMethod();

                    swal({
                        title: "Success",
                        text: "Commodity Rate details saved successfully",
                        icon: "success",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });

                } else {

                    swal({
                        title: "Failed",
                        text: "Problem occurred while saving the Commodity Rate details",
                        icon: "error",
                        closeOnClickOutside: false,
                        closeOnEsc: false,
                    });
                }
                this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While Adding the Commodity Rate  details", "info");
            }, () => console.log('done');
    };




}
