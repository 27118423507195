import { Component, OnInit, ViewChildren, QueryList,ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { ElementRef } from "@angular/core";

import { ReportService } from 'src/app/dataService/report-service';

import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

const my = new Date();

////
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';



import { ConsignorService } from 'src/app/dataService/consignor-service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Subject, Subscription, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//for drag and drop starts
import { DragulaService, dragula } from "ng2-dragula";
//for drag and drop ends
//for popup modal starts 
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MemoService } from "src/app/dataService/memo-service";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { UserDataDto } from "src/app/dto/UserData-dto";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from "@angular/common";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { LRDto } from "src/app/dto/LR-dto";
import { PartyMasterDto } from "src/app/dto/PartyMaster-dto";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { filter } from "rxjs/internal/operators/filter";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MemoReport } from 'src/app/dataService/memo-report';
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { AgentDetailsDto } from 'src/app/dto/AgentDetails-dto';
import { LrReportService } from 'src/app/dataService/lr-report-service';
import *  as moment from 'moment';

@Component({
	selector: 'app-lr-history-report',
	templateUrl: './lr-history-report.component.html',
	styleUrls: ['./lr-history-report.component.css']
})
export class LrHistoryReportComponent implements OnInit {
	gettingDataFrmServiceFrTable: any;
	lrHistoryRptDataList: any;

	//summaryTable:any;
	//for datepicker starts
	model: NgbDateStruct;
	model2;
	//for datepicker ends

	loadingIndicator = true;
	searchByLrNo = true;
	searchByDateWise = false;
	searchByUserName = false;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	address: any;

	//for datePicker starts
	hoveredDate: NgbDateStruct;
	fromDate: NgbDateStruct;
	toDate: NgbDateStruct;
	closeResult: string;
	fromDateModel: any;
	toDateModel: any;
	public modelDestinationFotGet: any;
	selectedDestination: any;
	//for datepicker ends
	//For Destination
	destinationOptions: LRDto[];
	lrDtoDestination: LRDto = new LRDto();
	public modelDestination: any;
	destinationTA: Array<LRDto> = [];
	focusDestinationTA$ = new Subject<string>();
	searchDestination = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusDestinationTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.destinationTA
				: this.destinationTA.filter(v => v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterDestination = (x: { destination: string }) => x.destination;
	///
	userNameOptions: LRDto[];
	lrDtoUserName: LRDto = new LRDto();
	public modelUserName: any;
	userNameTA: Array<LRDto> = [];
	focusUserNameTA$ = new Subject<string>();
	searchUserName = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusUserNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.userNameTA
				: this.userNameTA.filter(v => v.userName.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterUserName = (x: { userName: string }) => x.userName;
	searchLrHistoryRptDto: LRDto = new LRDto();
	searchLrHistoryRptGet: any;
	selectedMode: any;
	selectedSearchBy: any;
	selectedUserName: any;
	selectedFromDate: any;
	selectedToDate: any;
	enteredLrNumber: any;
	/* showLRNumberInPrint='';
	 showFromDateInPrint='';
	 showToDateInPrint='';
	 showUserNameInPrint='';*/

	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;

	dtTriggerLrHistoryRpt: Subject<any> = new Subject();

	dataTable: any;
	dtOptionsLrHistoryRpt: any;
	//For Custom Print
	cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintList: any;
	cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
	cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
	cashMemoDtoForCustomPrintDataList: any;
	cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
	cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
	cashMemoDtoForCustomPrintDataSummaryList: any;

	viewCustomPrintV1 = false;
	fromDatePrint: any;
	toDatePrint: any;
	cashMemoDtoForCustomPrintListHeadingV1: any;
	cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
	cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];

	artSummary: number
	chgWtSummary: number
	actWtSummary: number;
	//Custom Print Footer
	pageId="lrhrc";

	constructor(private memoLessRpt: ReportService, private router: Router,
		private memoReport: MemoReport, private masterReadService: MasterReadService,
		private modalService: NgbModal, private lrReportService: LrReportService, public changeDetectorRef : ChangeDetectorRef) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.getDestinationDetails();

		}

	}
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				// Do your stuff
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {

		var companyAddressDetls = this.address;
		/*var userName=$("#"+this.pageId+"userName").val();  
		var searchBy=$("#"+this.pageId+"searchBy").val();  
		var lrNumber=$("#"+this.pageId+"lrNumber").val(); 
		var toShow=null;
		 this.showLRNumberInPrint='';
		 this.showFromDateInPrint='';
		 this.showToDateInPrint='';
		 this.showUserNameInPrint='';
		// document.getElementById('entLr').innerHTML = "Lr Number" +$("#"+this.pageId+"lrNumber").val();
		if(searchBy=='lrNo'){
			console.log('1');
		toShow="Lr Number :"+ $("#"+this.pageId+"lrNumber").val();  
		}else if(searchBy=='dateWise'){
			console.log('2');
			toShow="From Date : "+ moment($("#"+this.pageId+"fromDate").val()).format('DD-MM-YYYY') + " -  "+"To Date : " +  moment($("#"+this.pageId+"toDate").val()).format('DD-MM-YYYY') + "";
		}else if(searchBy=='userName'){
			console.log('3');
			toShow="User Name :"+ $("#"+this.pageId+"userName").val() +"From Date : "+ moment($("#"+this.pageId+"fromDate").val()).format('DD-MM-YYYY') + " -  "+"To Date : " +  moment($("#"+this.pageId+"toDate").val()).format('DD-MM-YYYY') + "";
		}*/
		this.dtOptionsLrHistoryRpt = {
			dom: 'Bfrtip',
			buttons: [
				
				{
					extend: 'excel',
					text: '<i class="fas fa-file-excel"> Excel</i>',
					titleAttr: 'Excel',
					footer: true,
					title: function () {
						var returnValExl = null;
						var searchTypeExl = $("#lrhrcsearchBy").val();

						if (searchTypeExl == 'lrNo') {
							returnValExl = "LR History Report -" +
								"Lr Number : " + $("#lrhrclrNumber").val();
						} else if (searchTypeExl == 'dateWise') {
							returnValExl = "LR History Report " +
								"From Date : " + moment($("#lrhrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#lrhrctoDate").val()).format('DD-MM-YYYY');
						} else if (searchTypeExl == 'userName') {
							returnValExl = "LR History Report " +
								"From Date : " + moment($("#lrhrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
								"To Date : " + moment($("#lrhrctoDate").val()).format('DD-MM-YYYY') + "  UserName : " + $("#lrhrcuserName").val();
						}
						return returnValExl;



					},
					exportOptions: {
						columns: ':visible'
					}
				}
			],
			// the below code is for button export ends
			//place holder in search/filter in datatable starts
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 300,
			"scrollCollapse": true,
			"paging": true,
			"info": true,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};



				var articles = api.column(2).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var actualWeight = api.column(10).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var chargedWeight = api.column(11).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var toPay = api.column(12).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var paid = api.column(13).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);


				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(1).footer()).html();
				$(api.column(2).footer()).html(articles);
				$(api.column(3).footer()).html();
				$(api.column(4).footer()).html();
				$(api.column(5).footer()).html();
				$(api.column(6).footer()).html();
				$(api.column(7).footer()).html();
				$(api.column(8).footer()).html();
				$(api.column(9).footer()).html();
				$(api.column(10).footer()).html(actualWeight);
				$(api.column(11).footer()).html(chargedWeight);
				$(api.column(12).footer()).html(toPay);
				$(api.column(13).footer()).html(paid);


			}

		}

	}

	ngOnDestroy(): void {
		this.dtTriggerLrHistoryRpt.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerLrHistoryRpt.next();

	}

	clickTA(inp) {
		inp._elementRef.nativeElement.value = '';
		inp._elementRef.nativeElement.dispatchEvent(new Event('input'));
		inp._elementRef.nativeElement.focus();
	}

	searchByMode(searchBy: string) {
		if (searchBy === 'lrNo') {
			this.searchByLrNo = true;
			this.searchByDateWise = false;
			this.searchByUserName = false;


		} else if (searchBy === 'dateWise') {
			this.searchByLrNo = false;
			this.searchByDateWise = true;
			this.searchByUserName = false;

		} else if (searchBy === 'userName') {
			this.searchByLrNo = false;
			this.searchByDateWise = true;
			this.searchByUserName = true;


		} else {
			this.searchByLrNo = false;
			this.searchByDateWise = false;
			this.searchByUserName = false;

		}
	}


	//for datepicker
	// the selectToday is the method for  selecting todays'z date
	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	getDestinationMethod() {
		this.lrDtoDestination = new LRDto();
		this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
		this.lrDtoDestination.mode = "logininfo";
	}
	getDestinationDetails() {
		console.log('a');
		this.getDestinationMethod();
		this.showSpinnerForAction = true;
		this.masterReadService.getDestinationForLREntryService(this.lrDtoDestination).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response) {
					//this.stationOptions = response;
					if (response.length > 0) {
						this.destinationOptions = response;
						this.destinationTA = [];
						for (let i = 0; i < this.destinationOptions.length; i++) {
							this.destinationTA.push(this.destinationOptions[i]);
						}
					}
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the Destination Details", "info");
			},
			() => console.log('done');

	};

	destinationChange(e: NgbTypeaheadSelectItemEvent) {
		this.modelDestinationFotGet = e.item;
		this.selectedDestination = this.modelDestinationFotGet.destination;
		console.log(this.selectedDestination);
		if (this.selectedDestination != null && this.selectedDestination != '') {
			this.getSelectDestinationUser();
		}
	}
	getUserDetailsForRead() {
		this.selectedDestination = this.modelDestinationFotGet.destination;
		this.lrDtoUserName = new LRDto();
		this.lrDtoUserName.destination = this.selectedDestination;
		this.lrDtoUserName.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.lrDtoUserName.destination);

	}


	getSelectDestinationUser = () => {
		this.getUserDetailsForRead();
		this.showSpinnerForAction = true;
		this.masterReadService.getUserDetails(this.lrDtoUserName).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				this.userNameOptions = [];
				this.userNameTA = [];
				console.log(response);
				if (response.length > 0) {
					this.userNameOptions = response;
					this.userNameTA = [];
					for (let i = 0; i < this.userNameOptions.length; i++) {
						this.userNameTA.push(this.userNameOptions[i]);
					}

					$("#"+this.pageId+"userName").focus();

				} else {
					this.userNameOptions = [];
					this.userNameTA = [];
				}
				this.changeDetectorRef.detectChanges();

			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the User Details", "info");
			},
			() => console.log('done');

	};

	searchMethod() {
		this.searchLrHistoryRptDto = new LRDto();
		this.selectedFromDate = $("#"+this.pageId+"fromDate").val();
		this.selectedToDate = $("#"+this.pageId+"toDate").val();
		this.selectedDestination = $("#"+this.pageId+"destination").val();
		this.selectedUserName = $("#"+this.pageId+"userName").val();
		this.enteredLrNumber = $("#"+this.pageId+"lrNumber").val();
		this.selectedSearchBy = $("#"+this.pageId+"searchBy").val();
		this.selectedMode = $("#"+this.pageId+"mode").val();
		/* this.showLRNumberInPrint='';
		this.showFromDateInPrint='';
		this.showToDateInPrint='';
		this.showUserNameInPrint='';*/
		console.log(this.selectedFromDate, this.selectedToDate);

		if (this.selectedSearchBy == 'dateWise' || this.selectedSearchBy == 'userName') {
			if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {

				swal({
					title: "Mandatory Field",
					text: "From Date & To Date Is Mandatory Fields",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			}
		}

		this.searchLrHistoryRptDto.fromDate = this.selectedFromDate;
		this.searchLrHistoryRptDto.toDate = this.selectedToDate;

		if (this.selectedSearchBy == 'lrNo') {
			if (this.enteredLrNumber != null && this.enteredLrNumber != '') {
				this.searchLrHistoryRptDto.lrNumber = this.enteredLrNumber;
				// this.showLRNumberInPrint="Lr Number"+ this.enteredLrNumber;
				this.searchLrHistoryRptDto.reportMode = 'LR';
				if (this.selectedMode != null && this.selectedMode != '' && this.selectedMode != 'All') {
					this.searchLrHistoryRptDto.mode = this.selectedMode;
				}

			} else {
				swal({
					title: "Warning",
					text: "Please Enter Lr Number",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			}

		} else if (this.selectedSearchBy == 'dateWise') {
			this.searchLrHistoryRptDto.reportMode = 'Date';
			// this.showFromDateInPrint="From Date : "+ moment($("#"+this.pageId+"fromDate").val()).format('DD-MM-YYYY');
			//this.showToDateInPrint=" - To Date : " +  moment($("#"+this.pageId+"toDate").val()).format('DD-MM-YYYY');
			if (this.selectedMode != null && this.selectedMode != '' && this.selectedMode != 'All') {
				this.searchLrHistoryRptDto.mode = this.selectedMode;
			}

		} else if (this.selectedSearchBy == 'userName') {
			if (this.selectedDestination != null && this.selectedDestination != '' && this.selectedUserName != 'null' && this.selectedUserName != '') {
				this.searchLrHistoryRptDto.reportMode = 'User';
				this.searchLrHistoryRptDto.userName = this.selectedUserName;
				//this.showUserNameInPrint="User Name" +  this.selectedUserName;
				if (this.selectedMode != null && this.selectedMode != '' && this.selectedMode != 'All') {
					this.searchLrHistoryRptDto.mode = this.selectedMode;
				}


			} else {
				swal({
					title: "Warning",
					text: "Please Select the User",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
				return false;
			}

		}
		this.searchMethodGetDetails();

	}



	searchMethodGetDetails = () => {
		this.showSpinnerForAction = true;
		console.log(this.searchLrHistoryRptDto);
		this.lrReportService.getLrHistoryRptDetails(this.searchLrHistoryRptDto).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				$("#"+this.pageId+"lrHistoryRpt").DataTable().destroy();
				this.lrHistoryRptDataList = [];
				if (response.length == 0) {
					swal({
						title: "Warning",
						text: "No  Details found !",
						icon: "warning",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.lrHistoryRptDataList = response;
					console.log(this.lrHistoryRptDataList);
				}
				this.dtTriggerLrHistoryRpt.next();
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the  details", "info");
			}, () => console.log('done');
	};

	clearMethod() {
		$("#"+this.pageId+"lrHistoryRpt").DataTable().destroy();
		this.lrHistoryRptDataList = [];
		this.fromDateModel = null;
		this.toDateModel = null;
		this.dtTriggerLrHistoryRpt.next();
		this.searchLrHistoryRptDto = new LRDto();
		$("#"+this.pageId+"fromDate").val('');
		$("#"+this.pageId+"toDate").val('');
		$("#"+this.pageId+"destination").val('');
		$("#"+this.pageId+"userName").val('');
		$("#"+this.pageId+"searchBy").val('lrNo');
		$("#"+this.pageId+"lrNumber").val('');
		$("#"+this.pageId+"mode").val('');
		this.selectedFromDate = '';
		this.selectedToDate = '';
		this.selectedDestination = '';
		this.selectedSearchBy = '';
		this.selectedMode = '';
		this.showSpinnerForAction = false;
		this.searchByDateWise = false;
		this.searchByUserName = false;
		this.searchByLrNo = true;
		this.enteredLrNumber = '';

	}


	customPrintLrHistoryRpt() {
		if (this.lrHistoryRptDataList.length == 0) {
			swal({

				title: "No records found to print",
				icon: "warning",
				closeOnClickOutside: false,
				closeOnEsc: false,
			});
		} else {
			localStorage.clear();
			this.cashMemoDtoForCustomPrintList = [];
			this.cashMemoDtoForCustomPrintListColumnNames = ["LR Number", "Type", "Art", "Book Date", "Src", "Dest", "Agt", "Consignee", "Consignor", "Entered By", "Act Wt", "Chg Wt"];
			this.cashMemoDtoForCustomPrintListColumnWidths = [9, 5, 4, 6, 10, 10, 12, 11, 10, 11, 6, 6];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
				this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
				this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
			}

			this.cashMemoDtoForCustomPrintDataList = [];

			this.cashMemoDtoForCustomPrintDataSummaryList = [];
			this.artSummary = this.chgWtSummary = this.actWtSummary = 0;
			for (let i = 0; i < this.lrHistoryRptDataList.length; i++) {
				this.cashMemoDtoForCustomPrintData = new CashMemoDto();
				this.cashMemoDtoForCustomPrintListColumnValues = [this.lrHistoryRptDataList[i].lrNumber, this.lrHistoryRptDataList[i].invoiceType, this.lrHistoryRptDataList[i].totalArticles, this.lrHistoryRptDataList[i].bookDate, this.lrHistoryRptDataList[i].source, this.lrHistoryRptDataList[i].destination,
				this.lrHistoryRptDataList[i].agentName, this.lrHistoryRptDataList[i].consigneeName, this.lrHistoryRptDataList[i].consignorName, this.lrHistoryRptDataList[i].userName, this.lrHistoryRptDataList[i].actualWeight, this.lrHistoryRptDataList[i].chargeWeight];
				this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
				this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);

				this.artSummary=this.artSummary+this.lrHistoryRptDataList[i].totalArticles;
				this.chgWtSummary=this.chgWtSummary+this.lrHistoryRptDataList[i].chargeWeight;
				this.actWtSummary=this.actWtSummary+this.lrHistoryRptDataList[i].actualWeight;
				this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.lrHistoryRptDataList.length,"",this.artSummary,"","","","","","","",this.actWtSummary,this.chgWtSummary];	
			}
			//heading logics For Date
			//	this.fromDatePrint=this.datePipe.transform(this.memoData.tillDate, "dd-MM-yyyy");
			this.cashMemoDtoForCustomPrintListHeadingV1 = [];
			this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Date","To Date"];
			this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.searchLrHistoryRptDto.fromDate,this.searchLrHistoryRptDto.toDate];
			for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
				this.cashMemoDtoForCustomPrint = new CashMemoDto();
				this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
				this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
				this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
			}
			localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
			localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
			localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
			localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
			//localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
			localStorage.setItem('printTitle', "LR History Report");
			this.viewCustomPrintV1 = true;
			window.addEventListener('afterprint', (onclick) => {
				if (this.viewCustomPrintV1) {
					this.viewCustomPrintV1 = false;
					localStorage.clear();
				}
			});
		}
	}
}
