import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
    listOfDataToPass: string[];
}

@Component({
    selector: 'app-short-extra-lr-enquiry-popup',
    templateUrl: './bay-info-popup.component.html',
    styleUrls: ['./bay-info-popup.component.css']
})
export class BayInfoPopupComponent implements OnInit {

    userDataDtoReturnSession: any;
    listOfDataToGet: any;
    @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
    dtTriggerLrDetails: Subject<any> = new Subject();
    dtOptionsLrDetails: any;
    pageId = "bipc";

    constructor(public changeDetectorRef: ChangeDetectorRef, public dialogRef: MatDialogRef<BayInfoPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        this.listOfDataToGet = this.data.listOfDataToPass;
    }
    rerender(): void {
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
        });
    }

    ngOnInit(): void {

    }
    dataTableBayInfo() {
        this.dtOptionsLrDetails = {
            dom: 'Bfrtip',
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 280,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var columnData = api
                    .column(
                        0)
                    .data();

                $(api.column(0).footer()).html(
                    columnData.count()
                );
                var pageTotals = api
                    .column(2)
                    .data()
                    .reduce(function (a, b) {
                        return intVal(a) + intVal(b)
                    }, 0);
                $(api.column(2).footer()).html(
                    pageTotals
                );
            }
        }
    }
    ngOnDestroy(): void {
        this.dtTriggerLrDetails.unsubscribe();
    }
    onCancelClick(): void {
        this.dialogRef.close();
    }
}
