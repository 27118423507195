<div mat-dialog-content>
	<div class="row">
		<div class="col-md-12">
			<div class="row">
				<div class="col-sm-12 col-md-4">
					<div class="form-group">
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text"> <i class="fas fa-university"></i>
								</span>
							</div>
							<input id="{{pageId}}multiInvoiceDestinationId" type="text" class="form-control"
								(selectItem)="clickListnerForMultiInvoiceStation($event)"
								[(ngModel)]="modelMultiInvoiceDest" [ngbTypeahead]="searchMultiInvoiceDest"
								[resultFormatter]="formatterMultiInvoiceDest"
								[inputFormatter]="formatterMultiInvoiceDest"
								(focus)="focusMultiInvoiceDestTA$.next($any($event).target.value)" />
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-4">
					<div class="form-group">
						<div class="input-group">
							<div class="input-group-prepend">
								<span class="input-group-text"> <i class="fas fa-search"></i>
								</span>
							</div>
							<input type="text" class="form-control" id="{{pageId}}multiInvoicePopupInvoiceFormat"
								aria-describedby=" basic-addon11 " autocomplete="off" />
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-2">
					<div class="control">
						<div class="form-group">
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text"> <i class="fas fa-university"></i>
									</span>
								</div>
								<input type="text" class="form-control" id="{{pageId}}multiInvoicePopUpNo"
									aria-describedby=" basic-addon11 "
									(keypress)="keyEventHandlerMultiInvoicePopUp($event.keyCode)" autocomplete="off" />
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="form-group">
						<div class="input-group">
							<a href="javascript:;" type='button' #addBtn style="padding: 1px 4px;"
								class="btn btn-icon-only yellow">
								<i class="fa fa-times" title="Clear"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="card-body">
						<div class="box-body">
							<table datatable id="{{pageId}}multipleInvoiceLastPointTableId"
								class="table table-striped table-bordered row-border hover"
								[dtOptions]="dtOptionsHireslipDetails" [dtTrigger]="dtTriggerHireslipDetails">

								<thead>
									<tr>
										<th>Invoice Number</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let multiInvoiceNoData of gridTosetInvoiceNumberList let i = index ">
										<td>{{ multiInvoiceNoData.cmMultiInvoice }}</td>
										<td>
											<button style="padding: 1px 4px; background-color: #ffffff00;"
												class="btn m-r-10" id="{{pageId}}tableRemoveBtn"
												(click)="rowSelectedDelete(multipleInvoiceNoLinkedHireslip,i)">
												<i title="Remove" class="fas fa-trash"></i>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div mat-dialog-actions style="float: right;">
	<button class="btn btn-outline-success" mat-button (click)="onMultipleInvoiceOkClick()">Ok</button>
	<button class="btn btn-outline-danger" mat-button (click)="onCancelClick()">Close</button>
</div>