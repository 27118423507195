<div *ngIf="isLoggedIn"
	style="border-top:1px solid #000;border-right:1px solid #000;border-left:1px solid #000;border-bottom:1px solid #000;">
	<h1 class="title_custom" mat-dialog-title style="border-bottom:1px solid #000;">
		<h6 class="modal-title" id="{{pageId}}modal-basic-title" style="color: blue;"><strong>&nbsp;Change Login
				Password</strong></h6>
	</h1>
	<div class="row">
		<div *ngIf="showSpinnerForAction" class="col-md-12 p-t-10">
			<div class="form-group">
				<div class="input-group">
					<mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
					<br>
					<h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">Please
						Wait Updating details....</h6>
				</div>
			</div>
		</div>
	</div>
	<div mat-dialog-content style="width: 36vw;">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="col-lg-12">
								<form>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>Current Password</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-file-alt"></i>
													</span>
												</div>
												<input type="password" class="form-control" id="{{pageId}}curPwd"
													name="curPwd" aria-describedby="basic-addon11">
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>New Password</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i class="fas fa-file-alt"></i>
													</span>
												</div>
												<input type="password" class="form-control" id="{{pageId}}newPwd"
													name="newPwd" aria-describedby="basic-addon11">
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div mat-dialog-actions style="float: right;">

		<button type="button" class="btn btn-outline-dark" (click)="confirmChangePassword();">Change Password</button>
		<button type="button" class="btn btn-outline-dark" (click)="onCancelClick()" #cancelBtn
			id="{{pageId}}cancelBtn">Cancel</button>
	</div>
</div>