import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from "@angular/core";
import { ViewChild } from "@angular/core";
import {
  NgbModal,
  NgbTypeaheadSelectItemEvent,
  NgbModalRef,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
const my = new Date();
import { Subject, Observable, merge } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { map } from "rxjs/operators";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import swal from "sweetalert";
import { Router } from "@angular/router";
import { MasterReadService } from "src/app/dataService/masterread-service";
import { LRDto } from "src/app/dto/LR-dto";
import { SupplierDetailsDto } from "src/app/dto/SupplierDetails-dto";
import { TruckDataDto } from "src/app/dto/TruckData-dto";
import { CashMemoDto } from "src/app/dto/CashMemo-dto";
import { HireSlipDto } from "src/app/dto/HireSlip-dto";
import { LrService } from "src/app/dataService/lr-service";
// import * as moment from 'moment'

@Component({
  selector: "app-truck-owner-declaration-report",
  templateUrl: "./truck-owner-declaration-report.component.html",
  styleUrls: ["./truck-owner-declaration-report.component.css"],
})
export class TruckOwnerDeclarationReportComponent implements OnInit {

  truckOwnerDeclarationRptDataList: any;
  model: NgbDateStruct;
  searchByDestAndSrc = true;
  searchByPanNo = false;
  searchByTruckNo = false;
  searchBySupplierName = false;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  @ViewChildren(DataTableDirective)
  public dtElements: QueryList<DataTableDirective>;

  dtTriggerTruckOwnerDeclarationRpt: Subject<any> = new Subject();
  dtOptionsTruckOwnerDeclarationRpt: any;
  userDataDtoReturnSession: any;
  showSpinnerForAction = false;
  isLoggedIn = true;
  address: any = null;

  //    For From Station
  lrDtoFromStn: LRDto = new LRDto();
  lrDtoFromStnAll: LRDto = new LRDto();
  lrDtosFromStnOptionsGet: LRDto[];

  srcLists: any;
  srcList: LRDto = new LRDto();
  srcListForBkgAdmin: LRDto = new LRDto();
  rightsOfHirelsipRpt: any;

  //For Sorce Main Stations
  lrDtoSourceStationAllOption: LRDto = new LRDto();
  sourceStationOptions: LRDto[];
  lrDtoSourceStation: LRDto = new LRDto();

  public modelSource: any;
  sourceSubStationNameTA: Array<LRDto> = [];
  focusSourceTA$ = new Subject<string>();
  searchSource = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusSourceTA$;

    // return merge(debouncedText$, inputFocus$).pipe(
    //     map(term => (term === '' ? this.sourceSubStationNameTA
    //         : this.sourceSubStationNameTA.filter(v => v.subStations.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    // );
    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.sourceSubStationNameTA
        : this.sourceSubStationNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterSource = (x: { source: string }) => x.source;

  ///For Destination
  public modelDestinationFotGet: any;
  destinationOptions: LRDto[];
  lrDtoDestination: LRDto = new LRDto();
  lrDtoOptionDestinationAll: LRDto = new LRDto();
  public modelDestination: any;
  destinationTA: Array<LRDto> = [];
  focusDestinationTA$ = new Subject<string>();
  searchDestination = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusDestinationTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.destinationTA
          : this.destinationTA.filter(
            (v) =>
              v.destination.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterDestination = (x: { destination: string }) => x.destination;
  destinationList: Array<any> = [];

  ///For Supplier Name
  supplierDetailsDto: SupplierDetailsDto = new SupplierDetailsDto();
  supplierOptions: SupplierDetailsDto[];
  public modelSupplierName: any;
  supplierNameTA: Array<SupplierDetailsDto> = [];
  focusSupplierNameTA$ = new Subject<string>();
  searchSupplierName = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const inputFocus$ = this.focusSupplierNameTA$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.supplierNameTA
          : this.supplierNameTA.filter(
            (v) =>
              v.supplierName.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 200)
      )
    );
  };
  formatterSupplierName = (x: { supplierName: string }) => x.supplierName;

  //
  hireslipDtoForSearch: HireSlipDto = new HireSlipDto();
  dtoSearch: HireSlipDto = new HireSlipDto();

  validateFromDate: any;
  validateToDate: any;
  listOfSource: string[];
  listOfDestination: string[];
  validateSource: any;
  validateDestination: any;
  validatePanNo: any;
  validateVehicleNumber: any;
  validateSupplierName: any;
  validateFromStation: any;
  validateToStation: any;

  showSpinnerForActionForUpload = false;
  modalRefferenceTruckOwnerDeclPopUp: NgbModalRef;
  modalRefferenceTruckOwnerDeclClosePopUp: string;
  @ViewChild('truckOwnerDeclPopUpTemplate') private truckOwnerDeclPopUpTemplate;
  isUploaded = false;
  selectedTruckOwnerDeclDetails: any;
  selectedTruckOwnerDeclDetails2: any;
  splitedUrl: Array<any> = [];

  panNumberUpload: any;
  truckNumberUpload: any;
  fileName: any;
  financialYear: any;
  truckDataDtoForRemoveImg: TruckDataDto = new TruckDataDto();


  //For Custom Print
  cashMemoDtoForCustomPrintListHeadingV1: any;
  cashMemoDtoForCustomPrintListHeadingV2: any;
  cashMemoDtoForCustomPrintListHeadingV3: any;
  cashMemoDtoForCustomPrintListHeadingV4: any;
  cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV3: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV4: Array<any> = [];
  cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintList: any;
  cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
  cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
  cashMemoDtoForCustomPrintDataList: any;
  cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
  cashMemoDtoForCustomPrintDataSummaryList: any;
  viewCustomPrintV1: boolean;

  footerSummaryTotalHireAmt: any;
  footerSummaryTdsAmt: any;
  pageId = "trodr";
  showHireColumns: Boolean = false;
  curFYYear: any;
  curFYMonth: any;

  constructor(private router: Router, private masterReadService: MasterReadService,
    private modalService: NgbModal, private lrService: LrService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      })
    }
    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
      this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
      if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
        for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
          if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "TruckOwnerDec Rpt Show Hire") {
            this.showHireColumns = true;
          }
        }
      }
      this.getDestinationDetails();
      this.gettingFromStation();
      this.getSupplierDetailsList();
    }
  }

  logInPage() {
    this.router.navigate(["/authentication/login"]);
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Do your stuff
        dtInstance.destroy();
      });
    });
  }

  ngOnInit(): void {
    var exportTruckOwnerDeclarationArray = [];
    if (this.showHireColumns) {
      exportTruckOwnerDeclarationArray = [0, 2, 3, 4, 5, 6, 7, 8, 10];
    } else {
      exportTruckOwnerDeclarationArray = [0, 2, 3, 4, 5, 6, 7, 10];
    }

    this.dtOptionsTruckOwnerDeclarationRpt = {
      dom: "Bfrtip",
      buttons: [
        {
          extend: "excel",
          text: '<i class="fas fa-file-excel"> Excel</i>',
          titleAttr: "Excel",
          exportOptions: {
            columns: exportTruckOwnerDeclarationArray
          },
        },
      ],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search...",
      },
      processing: true,
      responsive: true,
      scrollX: true,
      scrollY: 330,
      scrollCollapse: true,
      paging: false,
      info: false,
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api(), data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };
        var totalHire = api.column(8).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var totalTds = api.column(10).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);

        $(api.column(0).footer()).html('Total : ' + data.length);
        $(api.column(8).footer()).html(totalHire);
        $(api.column(10).footer()).html(totalTds);
      }
    };
  }

  ngAfterViewInit(): void {
    this.dtTriggerTruckOwnerDeclarationRpt.next();
  }

  ngOnDestroy(): void {
    //this.dtTriggerTruckOwnerDeclarationRpt.unsubscribe();
    //this.onDestroyUnsubscribtionSmry.unsubscribe();
  }

  viewTypeMode(viewType: string) {
    if (viewType === "destination") {
      this.searchByDestAndSrc = true;
      this.searchByPanNo = false;
      this.searchByTruckNo = false;
      this.searchBySupplierName = false;
      this.gettingFromStation();
      this.getDestinationDetails();
      this.truckOwnerDeclarationRptDataList = [];
      $("#" + this.pageId + "truckOwnerDeclTableId").DataTable().destroy();
      this.dtTriggerTruckOwnerDeclarationRpt.next();
    } else if (viewType === "panNumber") {
      this.searchByDestAndSrc = false;
      this.searchByPanNo = true;
      this.searchByTruckNo = false;
      this.searchBySupplierName = false;
      this.truckOwnerDeclarationRptDataList = [];
      $("#" + this.pageId + "truckOwnerDeclTableId").DataTable().destroy();
      this.dtTriggerTruckOwnerDeclarationRpt.next();
    } else if (viewType === "truckNumber") {
      this.searchByDestAndSrc = false;
      this.searchByPanNo = false;
      this.searchByTruckNo = true;
      this.searchBySupplierName = false;
      this.truckOwnerDeclarationRptDataList = [];
      $("#" + this.pageId + "truckOwnerDeclTableId").DataTable().destroy();
      this.dtTriggerTruckOwnerDeclarationRpt.next();
    } else if (viewType === "supplierName") {
      this.searchByDestAndSrc = false;
      this.searchByPanNo = false;
      this.searchByTruckNo = false;
      this.searchBySupplierName = true;
      this.getSupplierDetailsList();
      this.truckOwnerDeclarationRptDataList = [];
      $("#" + this.pageId + "truckOwnerDeclTableId").DataTable().destroy();
      this.dtTriggerTruckOwnerDeclarationRpt.next();
    } else {
      this.searchByDestAndSrc = false;
      this.searchByPanNo = false;
      this.searchByTruckNo = false;
      this.searchBySupplierName = false;
      this.truckOwnerDeclarationRptDataList = [];
      $("#" + this.pageId + "truckOwnerDeclTableId").DataTable().destroy();
      this.dtTriggerTruckOwnerDeclarationRpt.next();
    }
  }

  clearBtn() {
    $("#" + this.pageId + "viewType").val('destination');
    this.searchByDestAndSrc = true;
    this.searchByPanNo = false;
    this.searchByTruckNo = false;
    this.searchBySupplierName = false;
    this.gettingFromStation();
    this.getDestinationDetails();
    this.truckOwnerDeclarationRptDataList = [];
    $("#" + this.pageId + "truckOwnerDeclTableId").DataTable().destroy();
    this.dtTriggerTruckOwnerDeclarationRpt.next();

    this.isUploaded = false;
    this.financialYear = '';
    this.fileName = '';
    $("#" + this.pageId + "truckOwnerDeclFileUpload").val('');
    $("#" + this.pageId + "truckOwnerDeclFileUpload2").val('');

    $("#" + this.pageId + "fromDates").val('');
    $("#" + this.pageId + "toDates").val('');
    $("#" + this.pageId + "sourceId").val('');
    this.modelSource = null;
    $("#" + this.pageId + "destinationId").val('');
    this.modelDestination = null;
    $("#" + this.pageId + "panNumber").val('');
    $("#" + this.pageId + "truckNumber").val('');
    $("#" + this.pageId + "supplierName").val('');
    this.modelSupplierName = null;
  }

  sourceListener(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelSource = e.item;
    $("#" + this.pageId + "sourceId").val(this.modelSource.fromStation);
  }

  clickListnerForDestination(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelDestination = e.item;
    $("#" + this.pageId + "destinationId").val(this.modelDestination.destination);
  }

  clickListnerForSupplier(e: NgbTypeaheadSelectItemEvent, fubi: any) {
    this.modelSupplierName = e.item;
    $("#" + this.pageId + "supplierName").val(this.modelSupplierName.supplierName);
  }

  //for datepicker
  // the selectToday is the method for  selecting todays'z date
  selectToday() {
    this.model = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate(),
    };
  }

  //for datePicker

  //For Main Source

  //From Station here need to check the stationList
  gettingFromStation() {
    if (this.userDataDtoReturnSession.role != null &&
      this.userDataDtoReturnSession.role == 'Booking Administrator' &&
      this.userDataDtoReturnSession.stationList != '') {
      //    for boooking adminsitrator
      this.srcLists = this.userDataDtoReturnSession.stationList;
      this.srcList.source = this.srcLists;
      console.log(this.srcLists);
      console.log(this.srcList.source);
      this.lrDtoFromStnAll.source = "ALL";
      this.sourceSubStationNameTA.push(this.lrDtoFromStnAll);
      for (let i = 0; i < this.srcList.source.length; i++) {
        this.srcListForBkgAdmin = new LRDto();
        this.srcListForBkgAdmin.source = this.srcList.source[i];
        console.log(this.srcListForBkgAdmin.source);
        this.sourceSubStationNameTA.push(this.srcListForBkgAdmin);
      }
    } else {
      this.gettingFromStationList();
    }

  }
  gettingFromStationDetails() {
    this.lrDtoFromStn.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoFromStn.mode = "notStmtInvRpt";

  }
  gettingFromStationList = () => {
    this.gettingFromStationDetails();
    this.masterReadService.getFromStationService(this.lrDtoFromStn).subscribe(
      (response) => {
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Source records found!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          this.sourceSubStationNameTA = [];
          this.lrDtosFromStnOptionsGet = [];
          this.lrDtosFromStnOptionsGet = response;
          this.lrDtoFromStnAll.source = "ALL";
          this.sourceSubStationNameTA.push(this.lrDtoFromStnAll);
          for (let i = 0; i < this.lrDtosFromStnOptionsGet.length; i++) {
            this.sourceSubStationNameTA.push(this.lrDtosFromStnOptionsGet[i]);

          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => console.log(error.json()),
      () => console.log('done');
  };


  ///For Destination
  getDestinationMethod() {
    this.lrDtoDestination = new LRDto();
    this.lrDtoDestination.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoDestination.branch = this.userDataDtoReturnSession.office;
    this.lrDtoDestination.mode = "destinationOnly";
  }

  getDestinationDetails() {
    this.getDestinationMethod();
    this.showSpinnerForAction = true;
    this.masterReadService
      .getDestinationForLREntryService(this.lrDtoDestination)
      .subscribe((response) => {
        this.showSpinnerForAction = false;
        if (response) {
          //this.stationOptions = response;
          if (response.length > 0) {
            this.destinationTA = [];
            this.destinationOptions = response;

            this.lrDtoOptionDestinationAll.destination = "All";
            this.destinationTA.push(this.lrDtoOptionDestinationAll);
            this.destinationList = [];
            for (let i = 0; i < this.destinationOptions.length; i++) {
              this.destinationTA.push(this.destinationOptions[i]);
              this.destinationList.push(this.destinationOptions[i].destination);
            }
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal(
          "Error",
          "Server Problem Occurred While getting the Destination Details",
          "info"
        );
      },
      () => console.log("done");
  }

  ///For Supplier Name

  getSupplierDetails() {
    this.supplierDetailsDto = new SupplierDetailsDto();
    this.supplierDetailsDto.companyId = this.userDataDtoReturnSession.companyId;
    //this.supplierDetailsDto.mode = "Working";
  }
  getSupplierDetailsList = () => {
    this.getSupplierDetails();
    this.masterReadService
      .getSuplierMaster(this.supplierDetailsDto)
      .subscribe((response) => {
        if (response.length == 0) {
          this.supplierOptions = [];
          this.supplierNameTA = [];
          swal("Warning", "No Supplier Master records found!", "warning");
        } else {
          this.supplierOptions = [];
          this.supplierNameTA = [];
          this.supplierOptions = response;

          for (let i = 0; i < this.supplierOptions.length; i++) {
            this.supplierNameTA.push(this.supplierOptions[i]);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        swal(
          "Error",
          "Server Error While Getting Supplier Master Details",
          "warning"
        );
      },
      () => console.log("done");
  };


  validateBtnSearch() {
    if (($("#" + this.pageId + "viewType").val() == "destination") &&
      ($("#" + this.pageId + "fromDates").val() == null || $("#" + this.pageId + "fromDates").val() == undefined ||
        $("#" + this.pageId + "fromDates").val() == "" || $("#" + this.pageId + "toDates").val() == null ||
        $("#" + this.pageId + "toDates").val() == undefined || $("#" + this.pageId + "toDates").val() == "" ||
        $("#" + this.pageId + "sourceId").val() == null || $("#" + this.pageId + "sourceId").val() == undefined ||
        $("#" + this.pageId + "sourceId").val() == "" || $("#" + this.pageId + "destinationId").val() == null ||
        $("#" + this.pageId + "destinationId").val() == undefined || $("#" + this.pageId + "destinationId").val() == "")) {
      swal("Mandatory Fields", "Please select the mandatory fields", "waring");
      return false;
    } else if (($("#" + this.pageId + "viewType").val() == "panNumber") &&
      ($("#" + this.pageId + "fromDates").val() == null || $("#" + this.pageId + "fromDates").val() == undefined ||
        $("#" + this.pageId + "fromDates").val() == "" || $("#" + this.pageId + "toDates").val() == null ||
        $("#" + this.pageId + "toDates").val() == undefined || $("#" + this.pageId + "toDates").val() == "" ||
        $("#" + this.pageId + "panNumber").val() == null || $("#" + this.pageId + "panNumber").val() == undefined ||
        $("#" + this.pageId + "panNumber").val() == "")) {
      swal("Mandatory Fields", "Please select the mandatory fields", "waring");
      return false;
    } else if (($("#" + this.pageId + "viewType").val() == "truckNumber") &&
      ($("#" + this.pageId + "fromDates").val() == null || $("#" + this.pageId + "fromDates").val() == undefined ||
        $("#" + this.pageId + "fromDates").val() == "" || $("#" + this.pageId + "toDates").val() == null ||
        $("#" + this.pageId + "toDates").val() == undefined || $("#" + this.pageId + "toDates").val() == "" ||
        $("#" + this.pageId + "truckNumber").val() == null || $("#" + this.pageId + "truckNumber").val() == undefined ||
        $("#" + this.pageId + "truckNumber").val() == "")) {
      swal("Mandatory Fields", "Please select the mandatory fields", "waring");
      return false;
    } else if (($("#" + this.pageId + "viewType").val() == "supplierName") &&
      ($("#" + this.pageId + "fromDates").val() == null || $("#" + this.pageId + "fromDates").val() == undefined ||
        $("#" + this.pageId + "fromDates").val() == "" || $("#" + this.pageId + "toDates").val() == null ||
        $("#" + this.pageId + "toDates").val() == undefined || $("#" + this.pageId + "toDates").val() == "" ||
        $("#" + this.pageId + "supplierName").val() == null || $("#" + this.pageId + "supplierName").val() == undefined ||
        $("#" + this.pageId + "supplierName").val() == "")) {
      swal("Mandatory Fields", "Please select the mandatory fields", "waring");
      return false;
    } else {
      this.setTruckOwnerDeclDetails();
    }
  }

  setTruckOwnerDeclDetails() {
    this.hireslipDtoForSearch = new HireSlipDto();
    this.listOfSource = [];
    this.listOfDestination = [];
    this.validateSource = null;
    this.validateDestination = null;
    this.validateFromDate = null;
    this.validateToDate = null;
    this.validatePanNo = null;
    this.validateVehicleNumber = null;
    this.validateSupplierName = null;
    this.validateFromStation = null;
    this.validateToStation = null;

    this.validateFromStation = $("#" + this.pageId + "sourceId").val();
    this.validateToStation = $("#" + this.pageId + "destinationId").val();

    this.hireslipDtoForSearch.fromstation = this.validateFromStation;
    this.hireslipDtoForSearch.toStation = this.validateToStation;

    this.hireslipDtoForSearch.mode = "Source and Destination";
    if ($("#" + this.pageId + "viewType").val() == "destination") {
      //source
      if ($("#" + this.pageId + "sourceId").val() != null && $("#" + this.pageId + "sourceId").val() != undefined &&
        $("#" + this.pageId + "sourceId").val() == "ALL") {
        for (let i = 0; i < this.sourceSubStationNameTA.length; i++) {
          this.listOfSource.push(this.sourceSubStationNameTA[i].source);
        }
      } else {
        this.validateSource = $("#" + this.pageId + "sourceId").val();
        this.listOfSource.push(this.validateSource);
      }

      //destination
      if ($("#" + this.pageId + "destinationId").val() != null && $("#" + this.pageId + "destinationId").val() != undefined &&
        $("#" + this.pageId + "destinationId").val() == "All") {
        for (let i = 0; i < this.destinationTA.length; i++) {
          this.listOfDestination.push(this.destinationTA[i].destination);
        }
      } else {
        this.validateDestination = $("#" + this.pageId + "destinationId").val();
        this.listOfDestination.push(this.validateDestination);
      }
      this.validateFromDate = $("#" + this.pageId + "fromDates").val();
      this.validateToDate = $("#" + this.pageId + "toDates").val();

      this.hireslipDtoForSearch.mode = "Source and Destination";
      this.hireslipDtoForSearch.fromDate = this.validateFromDate;
      this.hireslipDtoForSearch.toDate = this.validateToDate;
      this.hireslipDtoForSearch.listOfSource = this.listOfSource;
      this.hireslipDtoForSearch.listOfDest = this.listOfDestination;
      this.hireslipDtoForSearch.financialyear = this.userDataDtoReturnSession.financialYearSplit;
    } else if ($("#" + this.pageId + "viewType").val() == "panNumber") {
      this.validateFromDate = $("#" + this.pageId + "fromDates").val();
      this.validateToDate = $("#" + this.pageId + "toDates").val();
      this.validatePanNo = $("#" + this.pageId + "panNumber").val();

      this.hireslipDtoForSearch.mode = "Pan No.";
      this.hireslipDtoForSearch.fromDate = this.validateFromDate;
      this.hireslipDtoForSearch.toDate = this.validateToDate;
      this.hireslipDtoForSearch.panNo = this.validatePanNo;
      this.hireslipDtoForSearch.financialyear = this.userDataDtoReturnSession.financialYearSplit;
    } else if ($("#" + this.pageId + "viewType").val() == "truckNumber") {
      this.validateFromDate = $("#" + this.pageId + "fromDates").val();
      this.validateToDate = $("#" + this.pageId + "toDates").val();
      this.validateVehicleNumber = $("#" + this.pageId + "truckNumber").val();

      this.hireslipDtoForSearch.mode = "Truck No.";
      this.hireslipDtoForSearch.fromDate = this.validateFromDate;
      this.hireslipDtoForSearch.toDate = this.validateToDate;
      this.hireslipDtoForSearch.vehicleNumber = this.validateVehicleNumber;
      this.hireslipDtoForSearch.financialyear = this.userDataDtoReturnSession.financialYearSplit;
    } else if ($("#" + this.pageId + "viewType").val() == "supplierName") {
      this.validateFromDate = $("#" + this.pageId + "fromDates").val();
      this.validateToDate = $("#" + this.pageId + "toDates").val();
      this.validateSupplierName = $("#" + this.pageId + "supplierName").val();

      this.hireslipDtoForSearch.mode = "Supplier Name";
      this.hireslipDtoForSearch.fromDate = this.validateFromDate;
      this.hireslipDtoForSearch.toDate = this.validateToDate;
      this.hireslipDtoForSearch.suppliername = this.validateSupplierName;
      this.hireslipDtoForSearch.financialyear = this.userDataDtoReturnSession.financialYearSplit;
    }
    this.dtoSearch = new HireSlipDto();
    this.dtoSearch = this.hireslipDtoForSearch;
    this.showSpinnerForAction = true;
    this.lrService.getTruckOwnerDeclDetails(this.hireslipDtoForSearch)
      .subscribe((response) => {
        this.showSpinnerForAction = false;
        console.log(response);
        this.truckOwnerDeclarationRptDataList = [];
        $("#" + this.pageId + "truckOwnerDeclTableId").DataTable().destroy();
        if (response.length > 0) {
          this.truckOwnerDeclarationRptDataList = response;
        } else {
          swal("Alert", "No Record Found", "warning");
        }
        this.dtTriggerTruckOwnerDeclarationRpt.next();
        this.changeDetectorRef.detectChanges();
      }),
      (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Truck Owner Decl Details", "error");
      },
      () => console.log("done");
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeTruckOwnerDeclBtnPopUp() {
    this.modalRefferenceTruckOwnerDeclPopUp.close();
    this.isUploaded = false;
    this.financialYear = '';
    this.fileName = '';
    $("#" + this.pageId + "truckOwnerDeclFileUpload").val('');
    $("#" + this.pageId + "truckOwnerDeclFileUpload2").val('');
  }

  getImg(truckOwnerData, truckOwnerDeclPopUpTemplate) {
    console.log(truckOwnerData);
    //this.getFinancialYearDetails(advancePaymentData.hireSlipDateStr);
    this.truckNumberUpload = '';
    this.panNumberUpload = '';
    // this.financialYear = this.userDataDtoReturnSession.financialYearSplit;
    if (truckOwnerData.imgURLPan == null || truckOwnerData.imgURLPan == undefined) {
      this.panNumberUpload = truckOwnerData.panNo;
      if (this.panNumberUpload.toUpperCase() == "AAAAA9999A") {

        swal({
          title: "Warning",
          text: "Please Update Correct Pan Number",
          icon: "warning",
          closeOnClickOutside: false,
          closeOnEsc: false,
        });
      } else {
        this.truckNumberUpload = truckOwnerData.vehicleNumber;
        this.fileName = '';
        // fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
        if ($("#" + this.pageId + "uploadBy").val() == "uploadByPanNumber") {
          this.fileName = this.panNumberUpload + "_" + truckOwnerData.financialYear;
        } else {
          this.fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + truckOwnerData.financialYear;
        }
        //this.fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
        console.log(this.fileName);
        console.log(truckOwnerData.financialYear);
        //Upload Pop up Pending
        this.modalRefferenceTruckOwnerDeclPopUp = this.modalService.open(truckOwnerDeclPopUpTemplate,
          { centered: true });
        this.modalRefferenceTruckOwnerDeclPopUp.result.then((result) => {
          this.modalRefferenceTruckOwnerDeclClosePopUp = `Closed with: ${result}`;
        }, reason => {
          this.modalRefferenceTruckOwnerDeclClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    } else {
      var imageUrl = truckOwnerData.imgURLPan;
      var newimageUrl = "https://vehicledriverdocs.s3.amazonaws.com/truckownerdeclaration/";
      this.splitedUrl = null;
      this.splitedUrl = imageUrl.split("#");
      // if (this.splitedUrl.length > 1) {
      console.log(this.splitedUrl.length);
      for (let i = 0; i < this.splitedUrl.length; i++) {
        console.log(i);
        console.log(this.splitedUrl[i]);
        window.open(newimageUrl + this.splitedUrl[i]);
        // if (i == 0) {
        //   console.log('add');
        //   window.open(newimageUrl + this.splitedUrl[i], "Decl. Document - ", "");
        // } else if (i == 1) {
        //   console.log('add111');
        //   this.openWindow(newimageUrl, this.splitedUrl[i]);
        // }
      }
      // } else {
      //   console.log(newimageUrl);
      //   console.log(imageUrl);
      //   var completeImg = imageUrl;
      //   //var completeImg = newimageUrl + imageUrl;
      //   console.log('ddaaa');
      //   //window.open(completeImg, "Decl. Document", "");
      //   window.open(newimageUrl + completeImg, "Decl. Document", "");
      //   imageUrl = "";
      // }
    }
  }

  openWindow(newimageUrl, image) {
    console.log(image);
    //window.open(newimageUrl + image, "Decl. Document - ", "");
  }

  //file upload
  onFileChangedTruckOwnerDecl(event) {
    this.selectedTruckOwnerDeclDetails = event.target.files[0];
    console.log(this.selectedTruckOwnerDeclDetails);
  }

  //file upload2
  onFileChangedTruckOwnerDecl2(event) {
    this.selectedTruckOwnerDeclDetails2 = event.target.files[0];
    console.log(this.selectedTruckOwnerDeclDetails2);
  }

  uploadTruckOwnerDeclBtnPopUp() {
    console.log($("#" + this.pageId + "truckOwnerDeclFileUpload").val());
    if ($("#" + this.pageId + "truckOwnerDeclFileUpload").val() != null && $("#" + this.pageId + "truckOwnerDeclFileUpload").val() != "") {
      this.showSpinnerForActionForUpload = true;
      console.log(this.selectedTruckOwnerDeclDetails.type);
      if ((this.selectedTruckOwnerDeclDetails.type != null &&
        (this.selectedTruckOwnerDeclDetails.type == "image/jpeg" ||
          this.selectedTruckOwnerDeclDetails.type == "image/jpg" ||
          this.selectedTruckOwnerDeclDetails.type == "image/png" ||
          this.selectedTruckOwnerDeclDetails.type == "application/pdf"))) {
        var splitTypeAdvancePayment = this.selectedTruckOwnerDeclDetails.type.split("/");

        //const commonListAdvancePayment = "vehicledriverdocs&&truckownerdeclaration&&ASRAR&&" + splitTypeAdvancePayment[1] + "&&" + this.fileName + "";
        const commonListAdvancePayment = "vehicledriverdocs&&truckownerdeclaration&&" + this.truckNumberUpload + "&&" + splitTypeAdvancePayment[1] + "&&" + this.fileName + "";
        const formData = new FormData();
        formData.append(commonListAdvancePayment, commonListAdvancePayment);
        formData.append('truckownerdeclaration', this.selectedTruckOwnerDeclDetails, this.selectedTruckOwnerDeclDetails.name);
        if ($("#" + this.pageId + "truckOwnerDeclFileUpload2").val() != null && $("#" + this.pageId + "truckOwnerDeclFileUpload2").val() != "") {
          formData.append('truckownerdeclaration2', this.selectedTruckOwnerDeclDetails2, this.selectedTruckOwnerDeclDetails2.name);
        }
        this.masterReadService.uploadFileTruckOwnerDec(formData).subscribe(
          (response) => {
            if (response) {
              console.log(response);
              this.showSpinnerForActionForUpload = false;
              if (response[0] != "Error") {
                swal("File Upload", "File Uploaded Succcessfully", "success");
                this.isUploaded = true;
                this.closeTruckOwnerDeclBtnPopUp();
                this.setTruckOwnerDeclDetails();
              } else {
                swal("ERROR", "Correct the File and Upload Again", "error");
              }
            }
            this.changeDetectorRef.detectChanges();
          }), (error) => {
            this.showSpinnerForActionForUpload = false;
            swal("Error", "Server Error While Uploading File", "info");
          }, () => console.log('done');
      } else {
        this.showSpinnerForActionForUpload = false;
        swal("Mandatory Field", "Only jpg & png image format are allowed to upload", "warning");
      }
    }

    //2nd upload
    // console.log($("#" + this.pageId + "truckOwnerDeclFileUpload2").val());
    // if ($("#" + this.pageId + "truckOwnerDeclFileUpload2").val() != null && $("#" + this.pageId + "truckOwnerDeclFileUpload2").val() != "") {
    //   this.showSpinnerForActionForUpload = true;
    //   console.log(this.selectedTruckOwnerDeclDetails2.type);
    //   if ((this.selectedTruckOwnerDeclDetails2.type != null &&
    //     (this.selectedTruckOwnerDeclDetails2.type == "image/jpeg" ||
    //       this.selectedTruckOwnerDeclDetails2.type == "image/jpg" ||
    //       this.selectedTruckOwnerDeclDetails2.type == "image/png" ||
    //       this.selectedTruckOwnerDeclDetails2.type == "application/pdf"))) {
    //     var splitTypeAdvancePayment = this.selectedTruckOwnerDeclDetails2.type.split("/");
    //     const commonListAdvancePayment = "vehicledriverdocs&&truckownerdeclaration&&" + this.truckNumberUpload + "&&" + splitTypeAdvancePayment[1] + "&&" + this.fileName + "";
    //     const formData = new FormData();
    //     formData.append(commonListAdvancePayment, commonListAdvancePayment);
    //     formData.append('truckownerdeclaration', this.selectedTruckOwnerDeclDetails2, this.selectedTruckOwnerDeclDetails2.name);
    //     this.masterReadService.uploadFileTruckOwnerDec(formData).subscribe(
    //       (response) => {
    //         if (response) {
    //           console.log(response);
    //           this.showSpinnerForActionForUpload = false;
    //           if (response[0] != "Error") {
    //             swal("File Upload", "File Uploaded Succcessfully", "success");
    //             this.isUploaded = true;
    //             this.closeTruckOwnerDeclBtnPopUp();
    //             this.setTruckOwnerDeclDetails();
    //           } else {
    //             swal("ERROR", "Correct the File and Upload Again", "error");
    //           }
    //         }
    //         this.changeDetectorRef.detectChanges();
    //       }), (error) => {
    //         this.showSpinnerForActionForUpload = false;
    //         swal("Error", "Server Error While Uploading File", "info");
    //       }, () => console.log('done');
    //   } else {
    //     this.showSpinnerForActionForUpload = false;
    //     swal("Mandatory Field", "Only jpg & png image format are allowed to upload", "warning");
    //   }
    // }
  }

  // 2nd image 
  getImgForReUpload(truckOwnerData, truckOwnerDeclPopUpTemplate) {
    //this.getFinancialYearDetails(advancePaymentData.hireSlipDateStr);
    this.truckNumberUpload = '';
    this.panNumberUpload = '';
    // this.financialYear = this.userDataDtoReturnSession.financialYearSplit;
    console.log(truckOwnerData.financialYear);
    if (truckOwnerData.imgURLPan != null && truckOwnerData.imgURLPan != undefined &&
      truckOwnerData.imgURLPan != '') {

      this.panNumberUpload = truckOwnerData.panNo;

      this.truckNumberUpload = truckOwnerData.vehicleNumber;
      this.fileName = '';
      if ($("#" + this.pageId + "uploadBy").val() == "uploadByPanNumber") {
        this.fileName = this.panNumberUpload + "_" + truckOwnerData.financialYear;
      } else {
        this.fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + truckOwnerData.financialYear;
      }
      //this.fileName = this.panNumberUpload + "_" + this.truckNumberUpload + "_" + this.financialYear;
      console.log(this.fileName);
      console.log('aaaa');
      //Upload Pop up Pending
      this.modalRefferenceTruckOwnerDeclPopUp = this.modalService.open(truckOwnerDeclPopUpTemplate,
        { centered: true });
      this.modalRefferenceTruckOwnerDeclPopUp.result.then((result) => {
        this.modalRefferenceTruckOwnerDeclClosePopUp = `Closed with: ${result}`;
      }, reason => {
        this.modalRefferenceTruckOwnerDeclClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
      });

    } else {
      swal({
        title: "Alert",
        text: "Pleaase Upload The Image First",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });

    }
  }

  //3 image 
  getImgForRemove(truckOwnerData) {
    //this.getFinancialYearDetails(advancePaymentData.hireSlipDateStr);
    //this.getCurrentFinancialYear(truckOwnerData.departuredate);
    if (truckOwnerData.imgURLPan != null && truckOwnerData.imgURLPan != undefined &&
      truckOwnerData.imgURLPan != '') {
      swal({
        title: "Confirm Apply",
        text: "Sure You Want To Remove the Declaration",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ["No", "Yes"],
      }).then((remove) => {
        if (remove) {
          this.removeImgMethod(truckOwnerData);
        }
      });

    } else {
      swal({
        title: "Alert",
        text: "Pleaase Upload The Image First",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    }
  }

  removeImgMethod(truckOwnerData) {
    //this.financialYear = this.userDataDtoReturnSession.financialYearSplit;
    this.panNumberUpload = truckOwnerData.panNo;
    this.truckNumberUpload = truckOwnerData.vehicleNumber;

    this.truckDataDtoForRemoveImg = new TruckDataDto();

    if ($("#" + this.pageId + "uploadBy").val() == "uploadByPanNumber") {
      this.truckDataDtoForRemoveImg.mode = 'allTruck';
      this.truckDataDtoForRemoveImg.panNo = this.panNumberUpload;
    } else {
      this.truckDataDtoForRemoveImg.mode = 'particularTruck';
      this.truckDataDtoForRemoveImg.panNo = this.panNumberUpload;
      this.truckDataDtoForRemoveImg.truckNumber = this.truckNumberUpload;
    }
    this.truckDataDtoForRemoveImg.companyId = this.userDataDtoReturnSession.companyId;
    this.truckDataDtoForRemoveImg.financialYear = truckOwnerData.financialYear;
    this.truckDataDtoForRemoveImg.hireslipDate = this.validateFromDate;
    console.log(this.truckDataDtoForRemoveImg);
    this.removeImgDetails();
  }

  removeImgDetails = () => {
    this.masterReadService.setTruckOwnerDetails(this.truckDataDtoForRemoveImg).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        console.log(response);
        this.setTruckOwnerDeclDetails();
        this.changeDetectorRef.detectChanges();
      }), (error) => {

        swal("Error", "Server Error While setTruckOwnerDetails", "info");
      }, () => console.log('done');
  };

  // getCurrentFinancialYear(hireslipDate) {
  //   console.log('getCurrentFinancialYear');
  //   console.log(hireslipDate);
  //   console.log(moment(hireslipDate).format('YYYY'));
  //   console.log(moment(hireslipDate).format('MM'));
  //   this.curFYYear = moment(hireslipDate).format('YYYY');
  //   var month = moment(hireslipDate).format('MM');
  //   var fiscalyear = "";
  //   var today = new Date();
  //   if ((month + 1) <= 3) {
  //     fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
  //   } else {
  //     fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
  //   }
  //   console.log(fiscalyear);
  // }

  customPrinttruckOwnDecRpt() {
    if (this.truckOwnerDeclarationRptDataList.length == 0) {
      swal({
        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnNames = ["Pan No", "Hireslip Date", "Owner Name", "Truck No",
        "Supplier Name", "Source", "Dest", "Hire Amount", "TDS(Y/N)", "TDS Amt"];
      this.cashMemoDtoForCustomPrintListColumnWidths = [11, 10, 14, 11, 16, 8, 10, 8, 5, 7];

      for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }

      this.cashMemoDtoForCustomPrintDataList = [];

      this.cashMemoDtoForCustomPrintDataSummaryList = [];
      this.footerSummaryTotalHireAmt = this.footerSummaryTdsAmt = 0;
      for (let i = 0; i < this.truckOwnerDeclarationRptDataList.length; i++) {

        this.cashMemoDtoForCustomPrintListColumnValues =
          [this.truckOwnerDeclarationRptDataList[i].panNo,
          this.truckOwnerDeclarationRptDataList[i].departuredateStr,
          this.truckOwnerDeclarationRptDataList[i].companyName,
          this.truckOwnerDeclarationRptDataList[i].vehicleNumber,
          this.truckOwnerDeclarationRptDataList[i].suppliername,
          this.truckOwnerDeclarationRptDataList[i].fromstation,
          this.truckOwnerDeclarationRptDataList[i].tostation,
          this.truckOwnerDeclarationRptDataList[i].totalhire,
          this.truckOwnerDeclarationRptDataList[i].tdsYesNo,
          this.truckOwnerDeclarationRptDataList[i].tdsAmount];

        //For Summary
        this.footerSummaryTotalHireAmt = +this.footerSummaryTotalHireAmt + +this.truckOwnerDeclarationRptDataList[i].totalhire;
        this.footerSummaryTdsAmt = +this.footerSummaryTdsAmt + +this.truckOwnerDeclarationRptDataList[i].tdsAmount;

        this.cashMemoDtoForCustomPrintData = new CashMemoDto();


        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
        // this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.truckOwnerDeclarationRptDataList.length, "", this.gurWtSummary, this.kantaWtSummary, "","","","","","","","",this.enamHSummary,"","","",""];
      }
      this.cashMemoDtoForCustomPrintDataSummaryList =
        ["Total : " + this.truckOwnerDeclarationRptDataList.length,
          "", "", "", "", "", "", this.footerSummaryTotalHireAmt, "", this.footerSummaryTdsAmt
        ];

      //heading logics For Date
      //     this.todayDate = this.datePipe.transform(new Date(), "dd-MM-yyyy");
      this.cashMemoDtoForCustomPrintListHeadingV1 = [];

      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
      //   this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];
      for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
        this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);

      }


      localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
      localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
      localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
      //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
      //localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
      localStorage.setItem('printTitle', "Truck Owner Declaration Report");
      this.viewCustomPrintV1 = true;
      window.addEventListener('afterprint', (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }
}
