import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule, NgbDateStruct, NgbCalendar, ModalDismissReasons, NgbModalRef, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from "@angular/core";

//for datatable starts
import { Subject, Subscription, Observable, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";

//from the particular local folder starts
//import { LrDispatchBookingReportService } from './lr-dispatch-booking-report-service';
//from the particular local folder ends

//from the particular global folder starts
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { MasterReadService } from 'src/app/dataService/masterread-service';

//from the particular global folder ends
//for datatable ends
//for modal starts
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';
import *  as moment from 'moment';
const my = new Date();
import { DatePipe } from "@angular/common";
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { HireSlipDto } from 'src/app/dto/HireSlip-dto';
import { ReportService } from 'src/app/dataService/report-service';
import { MemoReport } from 'src/app/dataService/memo-report';
import { UserDataDto } from 'src/app/dto/UserData-dto';
import { StockService } from 'src/app/dataService/stock-service';
export interface DialogData {
	animal: string;
	name: string;
}
//for modal ends
@Component({
	selector: 'app-consignee-multiple-mobilenumber-details',
	templateUrl: './consignee-multiple-mobilenumber-details.component.html',
	styleUrls: ['./consignee-multiple-mobilenumber-details.component.css']
})
export class ConsigneeMultipleMobileNumberDetailsComponent implements OnInit {

	gridConsigneeMobileNoList: any;
	onDestroyUnsubscribtionHamaliDetails: Subscription;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerConsigneeMultipleMobileNo: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsConsigneeMultipleMobileNo: any;

	pageId="cmmdc";
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	address: any;

	showSpinnerForAction = false;

	model: NgbDateStruct;
	model2;

	loadingIndicator = true;

	popUpOpenConsigneeMobileNoData: any;
	popUpOpenConsigneeMobileNoListLocalStorage: any;

	private newAttributeSetConsigneeMobileNoOnLoadSet: any = {};

	private newAttributeSetConsigneeMobileNo: any = {};




	constructor(private memoLessRpt: ReportService, private router: Router,
		private masterReadService: MasterReadService, private memoReport: MemoReport,
		private stockService: StockService,
		private modalService: NgbModal, public dialogRef: MatDialogRef<ConsigneeMultipleMobileNumberDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef : ChangeDetectorRef) {
		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			console.log(data);
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;
			this.popUpOpenConsigneeMobileNoData = JSON.parse(localStorage.getItem('ConsigneeMobileNo'));
			this.popUpOpenConsigneeMobileNoListLocalStorage = JSON.parse(localStorage.getItem('ConsigneeMobileNoLocalStorage'));
			localStorage.clear();
			if (this.popUpOpenConsigneeMobileNoData != null &&
				this.popUpOpenConsigneeMobileNoData != undefined &&
				this.popUpOpenConsigneeMobileNoData == "ConsigneeMobileNo") {
				if (this.popUpOpenConsigneeMobileNoListLocalStorage != null &&
					this.popUpOpenConsigneeMobileNoListLocalStorage != undefined) {
					this.setMobileNoDataTable();
				}
			}
		}
	}

	logInPage() {
		this.router.navigate(['/authentication/login']);
	}
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
			dtElement.dtInstance.then((dtInstance: any) => {
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {
		var companyAddressDetls = this.address;
		this.dtOptionsConsigneeMultipleMobileNo = {
			dom: 'Bfrtip',
			buttons: [],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			searching: false,
			processing: true,
			//scroll in datatable starts
			responsive: true,
			"scrollX": true,
			"scrollY": 200,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};
				// computing column Total of the complete result 
				var discAmt = api.column(1).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
			},
		}
	}


	ngOnDestroy(): void {
		//this.dtTriggerConsigneeMultipleMobileNo.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.dtTriggerConsigneeMultipleMobileNo.next();
	}

	setMobileNoDataTable() {
		var mobileNoList = null;
		this.gridConsigneeMobileNoList = [];
		$("#"+this.pageId+"consigneeMobileNoTableId").DataTable().destroy();
		console.log(this.popUpOpenConsigneeMobileNoListLocalStorage);
		if (this.popUpOpenConsigneeMobileNoListLocalStorage != null &&
			this.popUpOpenConsigneeMobileNoListLocalStorage != undefined &&
			this.popUpOpenConsigneeMobileNoListLocalStorage != "") {

			mobileNoList = this.popUpOpenConsigneeMobileNoListLocalStorage.split("#");
			console.log(mobileNoList);
			if (mobileNoList.length > 0) {
				for (let i = 0; i < mobileNoList.length; i++) {
					this.newAttributeSetConsigneeMobileNoOnLoadSet = {};
					this.newAttributeSetConsigneeMobileNoOnLoadSet.mobileNo = mobileNoList[i];
					this.gridConsigneeMobileNoList.push(this.newAttributeSetConsigneeMobileNoOnLoadSet);
				}
				console.log(this.gridConsigneeMobileNoList);
				this.dtTriggerConsigneeMultipleMobileNo.next();
			}
		}else{
			this.dtTriggerConsigneeMultipleMobileNo.next();
		}
	}

	addConsigneeMobileNoBtn() {
		if ($("#"+this.pageId+"multipleMobileNoConsignee").val() == null ||
			$("#"+this.pageId+"multipleMobileNoConsignee").val() == undefined ||
			$("#"+this.pageId+"multipleMobileNoConsignee").val() == "") {
			swal("Mandatory Field", "Please enter the Mobile Number", "warning");
			return false;
		} else {
			this.reconfigureAddMultiConsignorMobileNo();
		}

	}

	reconfigureAddMultiConsignorMobileNo() {
		this.newAttributeSetConsigneeMobileNo.mobileNo = $("#"+this.pageId+"multipleMobileNoConsignee").val();
		if (this.gridConsigneeMobileNoList == null) {
			this.gridConsigneeMobileNoList = [];
		}
		if (this.gridConsigneeMobileNoList.length == 0) {
			$("#"+this.pageId+"consigneeMobileNoTableId").DataTable().destroy();
			this.gridConsigneeMobileNoList.push(this.newAttributeSetConsigneeMobileNo);
			this.dtTriggerConsigneeMultipleMobileNo.next();

			this.newAttributeSetConsigneeMobileNo = {};
			$("#"+this.pageId+"multipleMobileNoConsignee").val('');
		} else {
			const result = this.gridConsigneeMobileNoList.filter(gridConsigneeMobileNo =>
				gridConsigneeMobileNo.mobileNo === this.newAttributeSetConsigneeMobileNo.mobileNo);
			console.log(result);
			if (result.length > 0) {
				swal({
					title: "Duplicate Entry",
					text: "Mobile Number " + this.newAttributeSetConsigneeMobileNo.mobileNo + " Already Exist",
					icon: "warning",
					closeOnClickOutside: false,
					closeOnEsc: false,
				});
			} else {
				$("#"+this.pageId+"consigneeMobileNoTableId").DataTable().destroy();
				this.gridConsigneeMobileNoList.push(this.newAttributeSetConsigneeMobileNo);
				this.dtTriggerConsigneeMultipleMobileNo.next();

				this.newAttributeSetConsigneeMobileNo = {};
				$("#"+this.pageId+"multipleMobileNoConsignee").val('');
			}
		}
	}

	deleteRowSelectedMobileNo(gridConsigneeMobileNo, index) {
		this.gridConsigneeMobileNoList.splice(index, 1);
		$("#"+this.pageId+"multipleMobileNoConsignee").val('');
		$("#"+this.pageId+"consigneeMobileNoTableId").DataTable().destroy();
		this.dtTriggerConsigneeMultipleMobileNo.next();
	}

	clearFieldBtn() {
		$("#"+this.pageId+"multipleMobileNoConsignee").val('');
	}

	onCancelClick(): void {
		if (this.gridConsigneeMobileNoList != null &&
			this.gridConsigneeMobileNoList != undefined &&
			this.gridConsigneeMobileNoList.length > 0) {
			this.dialogRef.close(JSON.stringify(this.gridConsigneeMobileNoList));
		} else {
			this.gridConsigneeMobileNoList = [];
			this.dialogRef.close(JSON.stringify(this.gridConsigneeMobileNoList));
		}
	}

	onOkClickBtn(): void {
		if (this.gridConsigneeMobileNoList != null &&
			this.gridConsigneeMobileNoList != undefined &&
			this.gridConsigneeMobileNoList.length > 0) {
			this.dialogRef.close(JSON.stringify(this.gridConsigneeMobileNoList));
		} else {
			this.gridConsigneeMobileNoList = [];
			this.dialogRef.close(JSON.stringify(this.gridConsigneeMobileNoList));
		}
	}


}

