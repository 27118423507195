import { Component, OnInit, OnDestroy, QueryList, ViewChildren, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgModule, ViewChild } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ReportService } from 'src/app/dataService/report-service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { TripSheetDto } from 'src/app/dto/TripSheet-dto';
const my = new Date();
import { DataTableDirective } from "angular-datatables";
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { LRDto } from 'src/app/dto/LR-dto';
import { Subject, Subscription, merge } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MasterReadService } from "src/app/dataService/masterread-service";

@Component( {
    selector: 'app-tempo-performance-report',
    templateUrl: './tempo-performance-report.component.html',
    styleUrls: ['./tempo-performance-report.component.css']
} )
export class TempoPerformanceReportComponent implements OnInit {

    isLoggedIn = true;
    userDataDtoReturnSession: any;

    tripDetailsDataList: any;
    tempoEntriesDataList: any;
    commonEntriesDataList: any;
    tripSheetDtoGetHeadsDesc : TripSheetDto = new TripSheetDto();
    tripSheetDtoAddDed: TripSheetDto = new TripSheetDto();
    tripSheetDtosDescOptionsGet: TripSheetDto[];
    tripSheetDtoDescAddNew: TripSheetDto = new TripSheetDto();
    tripSheetDtoGetTempoLocalTrip : TripSheetDto = new TripSheetDto();
    enteredDate;any;
    enteredDesc: any;
    enteredValue: any;
    descIsSelectedAdd = false;
    selectedIndexAddTempTable: any;
    //summaryTable:any;
    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends
    additionTable = false
    loadingIndicator = true;

    viewTempoAddNew = false;
    viewCommonAddNew = false;
    showSpinnerForAction = false;
    tempoEntriesTable = false
    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    //@ViewChildren(DataTableDirective)
    //dtElements: QueryList<DataTableDirective>;

    @ViewChildren( DataTableDirective ) public dtElements: QueryList<DataTableDirective>;
    //@ViewChild(DialogComponent) dialogComponent: DialogComponent;
    viewFinishBtn:boolean = false;

    viewModel: any;

    dtTriggerTripDetails: Subject<any> = new Subject();
    dtTriggerTempoEntries: Subject<any> = new Subject();
    dtTriggerCommonEntries: Subject<any> = new Subject();

    //

    public modelTempoDescription: any;
    // need to see for the dto type in old srd ws
    tempoDescriptionTA: Array<TripSheetDto> = [];
    focusTempoDescriptionTA$ = new Subject<string>();
    searchTempoDescription = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusTempoDescriptionTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.tempoDescriptionTA
                : this.tempoDescriptionTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterTempoDescription = (x: { source: string }) => x.source;


    public modelCommonDescription: any;
    // need to see for the dto type in old srd ws
    commonDescriptionTA: Array<TripSheetDto> = [];
    focusCommonDescriptionTA$ = new Subject<string>();
    searchCommonDescription = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const inputFocus$ = this.focusCommonDescriptionTA$;
        return merge(debouncedText$, inputFocus$).pipe(
            map(term => (term === '' ? this.commonDescriptionTA
                : this.commonDescriptionTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
        );
    }
    formatterCommonDescription = (x: { source: string }) => x.source;




    // @ViewChild('dataTable') table: ElementRef;
    dataTable: any;

    dtOptionsTripDetails: any;
    dtOptionTempoEntries: any;
    dtOptionCommonEntries: any;
    //firstDtOptions: DataTables.Settings = {};


    //secondDtOptions: DataTables.Settings = {};
	pageId="tprpt";
    constructor( private tempoPerformanceRpt: ReportService, private router: Router, private masterReadService: MasterReadService, public changeDetectorRef : ChangeDetectorRef ) {
        if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
            //sweet alert ends
        }
        if (this.isLoggedIn) {
            // this.rateMasterDtoSelectedData = new RateMasterDto();
            // this.rateMasterDtoSelectedData = JSON.parse(localStorage.getItem('bookingAgentStatementDataLocalStorage'));
            this.tempoEntriesDataList = [];
            // this.additionDatatableDataList = this.rateMasterDtoSelectedData.rateMasterDtos;
            // this.deductionDatatableDataList = [];
            // this.deductionDatatableDataList = this.rateMasterDtoSelectedData.rateMasterDtos1;
            // this.toBePaidAmt = this.rateMasterDtoSelectedData.toBePaidAmt;
            // userdata get through from login starts
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));

            if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
                for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
                    //if(this.enableMemoPrintAgain ="cashmemo enable memo print again"){}
                        if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
                            && this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] ==
                            "localTempoPerfRptAdmin") {
                            // this.isAdmin = true;
                            this.viewFinishBtn = true;
                        }
                }
            }

            this.setHeadValues();
            
        }
        
    }

    rerender(): void {
        this.dtElements.forEach(( dtElement: DataTableDirective ) => {
            dtElement.dtInstance.then(( dtInstance: DataTables.Api ) => {
                // Do your stuff
                dtInstance.destroy();
            } );
        } );
    }



    ngOnInit(): void {
        this.dtOptionsTripDetails = {
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="fas fa-file-excel"> Excel</i>',
                    titleAttr: 'Excel',
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            processing: true,
            responsive: true,
            "scrollX": true,
            "scrollY": 380,
            "scrollCollapse": true,
            "paging": false,
            "info": false,
            "footerCallback": function (row, data, start, end, display) {
                var api = this.api(), data;
                // converting to interger to find total
                var intVal = function (i) {
                    return typeof i === 'string' ?
                        +i.replace(/[\$,]/g, '') * 1 :
                        typeof i === 'number' ?
                            i : 0;
                };
                var totalTrips = api.column(2).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);
                var totalLrs = api.column(3).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalArticles = api.column(4).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                var totalActweight = api.column(5).data().reduce(
                    function (a, b) {
                        return intVal(a) + intVal(b);
                    }, 0);
                $(api.column(0).footer()).html('Total : ' + data.length);
                $(api.column(2).footer()).html(totalTrips);
                $(api.column(3).footer()).html(totalLrs);
                $(api.column(4).footer()).html(totalArticles);
                $(api.column(5).footer()).html(totalActweight);
            }
        },
 
            this.dtOptionTempoEntries = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 120,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
                "footerCallback": function (row, data, start, end, display) {
                    var api = this.api(), data;
                    // converting to interger to find total
                    var intVal = function (i) {
                        return typeof i === 'string' ?
                            +i.replace(/[\$,]/g, '') * 1 :
                            typeof i === 'number' ?
                                i : 0;
                    };
                    var total = api.column(1).data().reduce(
                        function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0);

                    $(api.column(0).footer()).html('Total : ' + data.length);
                    $(api.column(1).footer()).html(total);
                }
            },

            this.dtOptionCommonEntries = {
                dom: 'Bfrtip',
                buttons: [],
                language: {
                    search: "_INPUT_",
                    searchPlaceholder: "Search..."
                },
                processing: true,
                responsive: true,
                "scrollX": true,
                "scrollY": 120,
                "scrollCollapse": true,
                "paging": false,
                "info": false,
            }
        
    }

    ngOnDestroy(): void {
        this.dtTriggerTripDetails.unsubscribe();
        this.dtTriggerTempoEntries.unsubscribe();
        this.dtTriggerCommonEntries.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTriggerTripDetails.next();
        this.dtTriggerTempoEntries.next();
        this.dtTriggerCommonEntries.next();
    }

    tempoDescriptionMode( tempoDescription: any ) {
        if ( tempoDescription === 'ADD NEW' ) {
            this.viewTempoAddNew = true;

        } else {
            this.viewTempoAddNew = false;

        }
    }

    commonDescriptionMode( commonDescription: any ) {
        if ( commonDescription === 'ADD NEW' ) {
            this.viewCommonAddNew = true;

        } else {
            this.viewCommonAddNew = false;

        }
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker
// for enter key presss btn for input starts
    kmEntryFocus(e) {
        if (e.keyCode == 13) {
            $("#"+this.pageId+"endKm").focus();
        }
    }

    endKmFocus(e) {
        if (e.keyCode == 13) {
            $("#"+this.pageId+"kmRun").focus();
        }
    }
    kmRunFocus(e) {
        if (e.keyCode == 13) {
            $("#"+this.pageId+"tempoDescription").focus();
        }
    }

    tempoAddNewDescFocus(e) {
        if (e.keyCode == 13) {
            $("#"+this.pageId+"tempoValue").focus();
        }
    }
    tempoValueFocus(e) {
        if (e.keyCode == 13) {
            $("#"+this.pageId+"addInTableTempo").focus();
        }
    }
    commonAddNewFocus(e) {
        if (e.keyCode == 13) {
            $("#"+this.pageId+"commonValue").focus();
        }
    }
    commonValueFocus(e) {
        if (e.keyCode == 13) {
            $("#"+this.pageId+"addInTableCommon").focus();
        }
    }
// for enter key presss btn for input starts
    addInTable() {
        if (($("#"+this.pageId+"tempoAddNewDesc").val() == null) || ($("#"+this.pageId+"tempoAddNewDesc").val() == undefined) || ($("#"+this.pageId+"tempoAddNewDesc").val() == "") ||
            ($("#"+this.pageId+"tempoValue").val() == null) || ($("#"+this.pageId+"tempoValue").val() == undefined) || ($("#"+this.pageId+"tempoValue").val() == "")) {
            swal("Mandatory Field", "Please enter the details to proceed!", "warning");
            return false;
        } else {
                this.addInTableAddition();
            
        }
    }

    addInTableAddition() {
        this.tripSheetDtoAddDed = new TripSheetDto();
        //need to check the dropdown code if the dropdown having value means need to get value from dropdown or else from add new desc
        this.enteredDesc = $("#"+this.pageId+"tempoAddNewDesc").val();
        this.enteredValue = $("#"+this.pageId+"tempoValue").val();
        if (this.descIsSelectedAdd == false) {
            for (let i = 0; i < this.tempoEntriesDataList.length; i++) {
                if (this.enteredDesc.toUpperCase() == this.tempoEntriesDataList[i].description.toUpperCase()) {
                    swal("Not Allowed", "Entered description already exists!", "info");
                    return;
                }
            }
            this.tripSheetDtoAddDed.headDesc = this.enteredDesc;
            // this.tripSheetDtoAddDed.totalAmount = this.enteredValue;
            this.tempoEntriesDataList.push(this.tripSheetDtoAddDed);

            $("#"+this.pageId+"additionTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerTempoEntries.next();
                this.totalAmtToBePaid();
                this.clearAddDedFields();
            }, 500);
        } else {
            this.tempoEntriesDataList.splice(this.selectedIndexAddTempTable, 1);
            // this.rateMasterDtoAddDed.description = this.enteredDesc;
            // this.rateMasterDtoAddDed.totalAmount = this.enteredValue;
            // this.tempoEntriesDataList.push(this.rateMasterDtoAddDed);

            $("#"+this.pageId+"additionTableId").DataTable().destroy();
            setTimeout(() => {
                this.dtTriggerTempoEntries.next();
                this.totalAmtToBePaid();
                this.clearAddDedFields();
            }, 500);
        }
    }
    totalAmtToBePaid() {
        // this.totAddition = 0;
        // this.totDed = 0;
        // for (let i = 0; i < this.additionDatatableDataList.length; i++) {
        //     this.totAddition += +this.additionDatatableDataList[i].totalAmount;
        // }
        // for (let i = 0; i < this.deductionDatatableDataList.length; i++) {
        //     this.totDed += +this.deductionDatatableDataList[i].totalAmount;
        // }
        // console.log(this.totAddition + -this.totDed);
        // this.toBePaidAmt = this.totAddition + -this.totDed;
    }

    clearAddDedFields() {
        // this.enteredDesc = null;
        // this.enteredValue = null;
        // $("#"+this.pageId+"tempoAddNewDesc").val("");
        // $("#"+this.pageId+"tempoValue").val("");
        // $("#"+this.pageId+"commonDescription").val("Addition");
        // this.descReadOnly = false;
        // this.descIsSelectedAdd = false;
        // this.selectedIndexAddTempTable = null;
        // this.descIsSelectedDed = false;
        // this.selectedIndexDed = null;
        // this.totAddition = 0;
        // this.totDed = 0;
    }

    setHeadValuesDetails(){
        this.tripSheetDtoGetHeadsDesc = new TripSheetDto();
        this.tripSheetDtoGetHeadsDesc.isActive=true;
        this.tripSheetDtoGetHeadsDesc.headCategory="Tempo Rpt"
    }

    setHeadValues = () => {
            this.showSpinnerForAction = true;
            this.setHeadValuesDetails();
            this.masterReadService.getDesService(this.tripSheetDtoGetHeadsDesc).subscribe(
                (response) => {
                    this.showSpinnerForAction = false;
                    this.tempoDescriptionTA = [];
                    this.commonDescriptionTA = [];
                    this.tripSheetDtosDescOptionsGet = [];
                    this.tripSheetDtosDescOptionsGet = response;
                    this.tripSheetDtoDescAddNew.headDesc = "ADD NEW";
                    this.tempoDescriptionTA.push(this.tripSheetDtoDescAddNew);
                    this.commonDescriptionTA.push(this.tripSheetDtoDescAddNew);
                    
                    for (let i = 0; i < this.tripSheetDtosDescOptionsGet.length; i++) {
                        this.tempoDescriptionTA.push(this.tripSheetDtosDescOptionsGet[i]);
                        this.commonDescriptionTA.push(this.tripSheetDtosDescOptionsGet[i]);

                    }
					this.changeDetectorRef.detectChanges();
                }), (error) => {
                    this.showSpinnerForAction = false;
                    swal("Error", "Server Error While Getting Description", "warning");
                },
                () => console.log('done');
    }


    getRptBtn(){
        if (($("#"+this.pageId+"reportDate").val() == null) || ($("#"+this.pageId+"reportDate").val() == undefined) || ($("#"+this.pageId+"reportDate").val() == "")) {
            swal("Mandatory Field", "Please select the date to proceed!", "warning");
            return false;
        } else {
                this.addInTempoTripDetailsDataTable();
            
        }
    }
    setValuesDtlsForGetOnDate(){
        this.tripSheetDtoGetTempoLocalTrip = new TripSheetDto();
        this.enteredDate=$("#"+this.pageId+"reportDate").val();
        this.tripSheetDtoGetTempoLocalTrip.fromdate=this.enteredDate;
        this.tripSheetDtoGetTempoLocalTrip.todate=this.enteredDate;
        this.tripSheetDtoGetTempoLocalTrip.mainStation=this.userDataDtoReturnSession.mainStation
    }
    
    addInTempoTripDetailsDataTable() {
    	this.showSpinnerForAction = true;
        this.setValuesDtlsForGetOnDate();
        this.masterReadService.getLocalTempoTripDtlsService(this.tripSheetDtoGetTempoLocalTrip).subscribe(
            (response) => {
                $("#"+this.pageId+"tempoPerfTableId").DataTable().destroy();
                this.tripDetailsDataList = [];
                if (response.length > 0) {
                    console.log(response);
                    this.tripDetailsDataList = response;
                    this.showSpinnerForAction = false;
                } else {
                    this.tripDetailsDataList = [];
                    this.showSpinnerForAction = false;
                    swal("No Records", "No Records found for this search", "info");
                }
                this.dtTriggerTripDetails.next();
				this.changeDetectorRef.detectChanges();
            }), (error) => {
                this.showSpinnerForAction = false;
                swal("Error", "Server Problem Occurred While getting the Details", "info");
            }, () => console.log('done');
    }

    // (click)="getRptBtn()"
    clearFields() {
        this.enteredDesc = null;
        this.enteredValue = null;
        $("#"+this.pageId+"tempoAddNewDesc").val("");
        $("#"+this.pageId+"tempoValue").val("");
		// groupingStoreTrip.removeAll();
		// storeAdditionCommon.removeAll();
		// storeAdditionVehicleSpecific.removeAll();
		// dfRptDate.clear(); - default clear in old ws
		// cbHeads.clear();
		// cbHeadsCommon.clear();
		// txtOtherCommonDesc.clear();
		// txtValueOtherCommonDesc.clear();
		// txtOtherVehicleDesc.clear();
		// txtValueOtherVehicleDesc.clear();
		// modelDupVehicle = null;
		// rowSelectedVehicle = false;
		// rowSelectedCommon = false;
		// modelDupCommon = null;
		// fsAdditionVehicleSpecific.setHeading("Tempo Specific Entries");
		// vehicleEntryId = 0;
		// commonEntryId = 0;
		// hashMapPrint = new HashMap<String, List<TripSheetDto>>();
		// btnPrint.setVisible(false);
		// btnExport.setVisible(false);
		// nfEndKM.clear();
		// nfRunKM.clear();
		// nfStartKM.clear();
	}
    
    

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }

}
