<div class="page row system_responsive" id="{{pageId}}printPageHideShow">
	<div class="col-md-12">
		<div>
			<div>
				<table width='100%' border='0' cellspacing='0' cellpadding='0'>
					<tr>
						<td width='40%' style="padding-top: 10px;" valign='top'>
							<img src="assets/images/srdLogisticPrintLogo.png" alt="SRDLogo">
							<br>
							{{address}}
						</td>

						<td width='60%' valign='top'>
							<table width='100%' border='0' cellspacing='0' cellpadding='0'>
								<tr>
									<td>
										<table width='100%' border='0' cellspacing='0' cellpadding='0'>
											<tr>
												<td width='40%'>
													<strong>Truck No : </strong>
												</td>
												<td width='60%' style='border-bottom:1px solid #000;'>
													{{vehicleNumber}}
												</td>
											</tr>
										</table>
									</td>
								</tr>
								<tr>
									<td>&nbsp;</td>
								</tr>

								<tr>
									<td>
										<table width='100%' border='0' cellspacing='0' cellpadding='0'>
											<tr>
												<td width='40%'><strong>Invoice No : </strong></td>
												<td width='60%' style='border-bottom:1px solid #000;'>
													<strong>{{invoiceNumber}} </strong></td>
											</tr>
										</table>
									</td>
								</tr>

								<tr>
									<td>
										<table width='100%' border='0' cellspacing='0' cellpadding='0'>
											<tr>
												<td width='20%'><strong>From : </strong></td>
												<td width='30%' style='border-bottom:1px solid #000;'>
													<strong>{{fromStation}} </strong></td>
												<td width='20%'><strong>To : </strong></td>
												<td width='30%' style='border-bottom:1px solid #000;'>
													<strong>{{toStation}} </strong></td>
											</tr>
										</table>
									</td>
								</tr>

								<tr>
									<td>
										<table width='100%' border='0' cellspacing='0' cellpadding='0'>
											<tr>
												<td width='40%'><strong>Dated : </strong></td>
												<td width='60%' style='border-bottom:1px solid #000;'>
													<strong>{{invoiceDate}} </strong></td>
											</tr>
										</table>
									</td>
								</tr>

							</table>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-md-12">
				<h6 style='text-align: center;font-size: large;'>
					<strong><u>{{toStation}} Loading Sheet</u></strong>
				</h6>
			</div>
		</div>
		<br>
		<!--Second  row starts-->
		<!--<div class="container border">-->
			<table width='100%' border='0' cellspacing='0' cellpadding='1'>
				<tr>
					<td width='14%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;border-left: 1px solid #000;'
						valign='top' type='text'><strong> Station </strong></td>
					<td width='6%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='number'><strong> LRs</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='number'><strong> Article</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='number'><strong> Actual Weight</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Charged Weight</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> To Pay</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong> Paid </strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong>F O V</strong></td>
					<td width='10%' align='center'
						style='border-right: 1px solid #000;border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;'
						valign='top' type='text'><strong>Discount</strong></td>
					<td width='10%' align='center' style='border-bottom: 1px solid #000; word-wrap: break-word;border-top: 1px solid #000;border-right: 1px solid #000;'
						valign='top' type='text'><strong> Goods Value</strong></td>
				</tr>
				<tr *ngFor="let listOfInvoiceSummaryData of listOfInvoiceSummaryDetailsData">
					<!--<tr>-->
					<td width='14%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-left: 1px solid #000;'
						valign='top' type='text' id='{{pageId}}lrDtoBookingDate'>
						<strong>{{listOfInvoiceSummaryData.destination}}</strong></td>
					<td width='6%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='number' id='{{pageId}}lrDtoLrNo'>
						<strong>{{listOfInvoiceSummaryData.lrNumber}} </strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='number' id='{{pageId}}appAmountPrint'>
						<strong> {{listOfInvoiceSummaryData.actualArt}}</strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}lrDtoTotArt'>
						<strong>{{listOfInvoiceSummaryData.actualWeight}} </strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}lrDtoAgentName'>
						<strong>{{listOfInvoiceSummaryData.chargedWeight}} </strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}leDtoActWgt'>
						<strong>{{listOfInvoiceSummaryData.toPay}}</strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}lrDtoChgWgt'>
						<strong>{{listOfInvoiceSummaryData.paid}}</strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}lrDtoPrivateMarks'>
						<strong>{{listOfInvoiceSummaryData.riskCharge}} </strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}lrDtoPrivateMarks'>
						<strong>{{listOfInvoiceSummaryData.discountAmount}} </strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}lrDtoPrivateMarks'>
						<strong>{{listOfInvoiceSummaryData.goodsValue}} </strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}lrDtoRemarks'>
						<strong></strong></td>
				</tr>
				<tr>
					<td width='14%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;border-left: 1px solid #000;'
						valign='top' type='text' id='{{pageId}}lrDtoBookingDate'>
						<strong>Total&nbsp;:&nbsp;{{totalCounts}}</strong></td>
					<td width='6%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='number' id='{{pageId}}lrDtoLrNo'>
						<strong>{{totalLrs}} </strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='number' id='{{pageId}}appAmountPrint'>
						<strong> {{articles}}</strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}lrDtoTotArt'>
						<strong>{{actWt}} </strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}lrDtoAgentName'>
						<strong>{{chgWt}} </strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}leDtoActWgt'>
						<strong>{{toPay}}</strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}lrDtoChgWgt'>
						<strong>{{paid}}</strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}lrDtoPrivateMarks'>
						<strong>{{riskChg}} </strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}lrDtoPrivateMarks'>
						<strong>{{lessAmt}} </strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-right: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}lrDtoPrivateMarks'>
						<strong>{{goodsValue}} </strong></td>
					<td width='10%' align='center'
						style='border-top: 1px solid #000; border-bottom: 1px solid #000; word-wrap: break-word;'
						valign='top' type='text' id='{{pageId}}lrDtoRemarks'>
						<strong></strong></td>
				</tr>
			</table>
		<!--</div>-->

		<br>


		<div class="row">
			<div class="col-md-4">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">

						</div>
					</div>
				</div>
			</div>
			<div class="col-md-2">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="row">
					<div class="col-md-12">
						<div class="col-sm-12 col-md-12">
							<h6 style='margin-right: 20px; font-size: 17px; padding-top: 30px;'>
								<strong>Loaded By : </strong>
								<strong>
									<u>
										{{loadedBy}}</u>
								</strong>
							</h6>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</div>