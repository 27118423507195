import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { NgbDateStruct, ModalDismissReasons, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, Observable, merge } from 'rxjs';
import { DataTableDirective } from "angular-datatables";
import { DashboardService } from 'src/app/dataService/dashboard-service';
import { MasterReadService } from 'src/app/dataService/masterread-service';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LRDto } from "src/app/dto/LR-dto";
import swal from 'sweetalert';
const my = new Date();
import { DatePipe } from "@angular/common";
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { HireslipService } from 'src/app/dataService/hireslip-service';

export interface DialogData {
	animal: string;
	name: string;
}

@Component({
	selector: 'app-trip-delivery-confirmation-details',
	templateUrl: './trip-delivery-confirmation-details.component.html',
	styleUrls: ['./trip-delivery-confirmation-details.component.css']
})
export class TripDeliveryConfirmationDetailsComponent implements OnInit {
	closeResultDatatable: string;
	scheduleTripDeliverySummaryDataList: any;
	@ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
	dtTriggerScheduleTripDelivery: Subject<any> = new Subject();
	dataTable: any;
	dtOptionsScheduleTripDelivery: any;
	model: NgbDateStruct;
	isLoggedIn = true;
	userDataDtoReturnSession: any;
	showSpinnerForAction = false;
	showSpinnerForActionReasonPopup = false;
	address: any;
	viewType = '';
	lrDtoForLocalTripListForLocalStorage: any;
	deliveryDatesModal: any;
	enteredLrNumber: any;
	validateEnterLRNumber: any;
	validateEnterDeliveryDate: any;
	validateEnterReason: any;
	lrDtoForDelivered: LRDto = new LRDto();
	lrDtoForUnDelivered: LRDto = new LRDto();
	lrDtoForTripCancel: LRDto = new LRDto();
	lrDtoForTripFinish: LRDto = new LRDto();
	listOfLrs: string[];
	listOfLrsReturn: string[];
	tripCancelForListOfLrs: string[];

	modalRefferenceTripDeliveryConfirmPopUp: NgbModalRef;
	modalRefferenceTripDeliveryConfirmClosePopUp: string;
	@ViewChild('tripDeliveryLrsDetailsPopUpTemplate') private tripDeliveryLrsDetailsPopUpTemplate;

	viewconfirmCancelPopUpBtn = false;
	viewOkPopUpBtn = false;
	viewNewReasonField = false;

	reasonNameOptions: LRDto[];
	lrDtoReasonName: LRDto = new LRDto();
	lrDtoReasonNameAddNew: LRDto = new LRDto();
	public modelReasonName: any;
	reasonNameTA: Array<LRDto> = [];
	focusReasonNameTA$ = new Subject<string>();
	searchReasonName = (text$: Observable<string>) => {
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const inputFocus$ = this.focusReasonNameTA$;

		return merge(debouncedText$, inputFocus$).pipe(
			map(term => (term === '' ? this.reasonNameTA
				: this.reasonNameTA.filter(v => v.reason.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
		);
	}
	formatterReasonName = (x: { reason: string }) => x.reason;

	returnMessageFinish: any;
	pageId = "tdcdc";
	lrDtoSelectedLr: LRDto = new LRDto();
	podReq: boolean = false;
	podUrl = null;
	podExempUser: boolean = false;
	pendingLrsDataForSearchSelectedList: Array<any> = [];
	pendingLrsDataForSearchSelectedIndex: any;
	multipleLRDeliveredLRDtos: Array<LRDto> = [];
	lrDtoForLocalTripSelectedRow: LRDto = new LRDto();

	constructor(private dashboardService: DashboardService, private router: Router, private modalService: NgbModal,
		private masterReadService: MasterReadService, public dialogRef: MatDialogRef<TripDeliveryConfirmationDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef,
		private hireslipService: HireslipService) {

		if (sessionStorage.length == 0) {
			this.isLoggedIn = false;
			swal({
				title: "Session Expired",
				text: "Please relogin to access the application!",
				icon: "error",
				closeOnClickOutside: false,
				closeOnEsc: false,
			}).then(() => {
				this.logInPage();
			})
		}
		if (this.isLoggedIn) {
			this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
			this.address = this.userDataDtoReturnSession.addressId == null ? '' : this.userDataDtoReturnSession.addressId;

			this.setReasonNameDetailsList();
			this.selectTodayDate();

			this.lrDtoForLocalTripListForLocalStorage = JSON.parse(localStorage.getItem('localTripsheetDashboardForPopup'))
			localStorage.clear();
			console.log(this.lrDtoForLocalTripListForLocalStorage);

			if (this.lrDtoForLocalTripListForLocalStorage != null &&
				this.lrDtoForLocalTripListForLocalStorage != undefined &&
				this.lrDtoForLocalTripListForLocalStorage != "") {
				this.viewType = "Local Trip - " + this.lrDtoForLocalTripListForLocalStorage.deliverygodown;
				$("#" + this.pageId + "showHeader").text(this.viewType);

				this.scheduleTripDeliverySummaryDataList = [];
				$("#" + this.pageId + "scheduleTripDeliveryTableId").DataTable().destroy();
				this.scheduleTripDeliverySummaryDataList = this.lrDtoForLocalTripListForLocalStorage.lrDtoList;
				this.dtTriggerScheduleTripDelivery.next();
				console.log(this.scheduleTripDeliverySummaryDataList);
			}
			if (this.userDataDtoReturnSession.isPodMandForDelvy != null && this.userDataDtoReturnSession.isPodMandForDelvy) {
				this.podReq = true;
			}

			if (this.userDataDtoReturnSession.sortedMapFeatures.Rights != null) {
				for (let i = 0; i < this.userDataDtoReturnSession.sortedMapFeatures.Rights.length; i++) {
					if (this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] != null
						&& this.userDataDtoReturnSession.sortedMapFeatures.Rights[i] == "LR Delv POD Exemption") {
						this.podExempUser = true;
					}
				}
			}
		}

	}
	rerender(): void {
		this.dtElements.forEach((dtElement: DataTableDirective) => {
			dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
				dtInstance.destroy();
			});
		});
	}

	ngOnInit(): void {
		this.dtOptionsScheduleTripDelivery = {
			dom: 'Bfrtip',
			buttons: [],
			language: {
				search: "_INPUT_",
				searchPlaceholder: "Search..."
			},
			searching: false,
			pagingType: 'full_numbers',
			processing: true,
			"scrollX": true,
			"scrollY": 200,
			"scrollCollapse": true,
			"paging": false,
			"info": false,
			"footerCallback": function (row, data, start, end, display) {
				var api = this.api(), data;
				// converting to interger to find total
				var intVal = function (i) {
					return typeof i === 'string' ?
						+i.replace(/[\$,]/g, '') * 1 :
						typeof i === 'number' ?
							i : 0;
				};

				var totArt = api.column(4).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var gd = api.column(5).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var dd = api.column(6).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var actWgt = api.column(7).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var cgdWgt = api.column(8).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);
				var totAmt = api.column(9).data().reduce(
					function (a, b) {
						return intVal(a) + intVal(b);
					}, 0);

				$(api.column(0).footer()).html('Total : ' + data.length);
				$(api.column(4).footer()).html(totArt);
				$(api.column(5).footer()).html(gd);
				$(api.column(6).footer()).html(dd);
				$(api.column(7).footer()).html(actWgt);
				$(api.column(8).footer()).html(cgdWgt);
				$(api.column(9).footer()).html(totAmt);
			}
		}
	}

	ngOnDestroy(): void {
		this.dtTriggerScheduleTripDelivery.unsubscribe();

	}
	ngAfterViewInit(): void {
		this.dtTriggerScheduleTripDelivery.next();

	}

	//for modal starts
	onCancelClick(): void {
		this.dialogRef.close(this.returnMessageFinish);
		this.viewOkPopUpBtn = false;
		this.viewconfirmCancelPopUpBtn = false;
		this.viewNewReasonField = false;
	}
	//for modal ends
	logInPage() {
		this.router.navigate(['/authentication/login']);
	}

	//for datepicker
	selectToday() {
		this.model = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
	}

	selectTodayDate() {
		this.deliveryDatesModal = {
			year: my.getFullYear(),
			month: my.getMonth() + 1,
			day: my.getDate()
		};
		$("#" + this.pageId + "deliveryDates").val(this.deliveryDatesModal);
	}
	transform(items: any[], searchTerm: string, labelKey?: string): any {
		if (!items || !searchTerm) {
			return items;
		}
		return items.filter(item =>
			item[labelKey || 'label'].toLowerCase().includes(searchTerm.toLowerCase()) === true);
	}
	//for datePicker
	//popup 
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
	//pop up

	/*	onKeyPressNewReasonNameInputField(event) {
			if (event.charCode == 13) {
				if (this.viewOkPopUpBtn == true) {
					$("#"+this.pageId+"okPopupBtnId").focus();
				} else if (this.viewconfirmCancelPopUpBtn == true) {
					$("#"+this.pageId+"confirmCancelPopupBtnId").focus();
				}
			}
		}*/

	slectedRowForTripDeliveryConfirm(scheduleTripDeliverySummaryData) {
		this.podUrl = null;
		//console.log(scheduleTripDeliverySummaryData);
		this.lrDtoSelectedLr = new LRDto();
		this.lrDtoSelectedLr = scheduleTripDeliverySummaryData;
		this.podUrl = this.lrDtoSelectedLr.viewPodLrCopy;
		if (scheduleTripDeliverySummaryData.lrNumber != null) {
			$("#" + this.pageId + "lrNumber").val(scheduleTripDeliverySummaryData.lrNumber);
			$("#" + this.pageId + "lrNumber").focus();
		}
	}

	onKeyPressReasonNameDropDown(event) {
		if (event.charCode == 13) {
			if ($("#" + this.pageId + "reasonNameId").val() == "Add New") {
				this.viewNewReasonField = true;
				$("#" + this.pageId + "newReasonNameId").val('');
				$("#" + this.pageId + "newReasonNameId").focus();
			} else {
				this.viewNewReasonField = false;
			}
		}
	}

	clickListnerForReasonName(event) {
		this.modelReasonName = event.item;
		$("#" + this.pageId + "reasonNameId").val(this.modelReasonName.reason);
		if ($("#" + this.pageId + "reasonNameId").val() == "Add New") {
			this.viewNewReasonField = true;
		} else {
			this.viewNewReasonField = false;
		}
	}

	getReasonNameDetails() {
		this.lrDtoReasonName = new LRDto();
		this.lrDtoReasonName.companyId = this.userDataDtoReturnSession.companyId;
	}

	setReasonNameDetailsList() {
		this.showSpinnerForAction = true;
		this.getReasonNameDetails();
		this.masterReadService.getReasonsDetails(this.lrDtoReasonName).subscribe(
			(response) => {
				if (response.length > 0) {
					this.reasonNameTA = [];
					this.reasonNameOptions = [];
					this.reasonNameOptions = response;
					this.lrDtoReasonNameAddNew = new LRDto();
					this.lrDtoReasonNameAddNew.reason = "Add New";
					this.reasonNameTA.push(this.lrDtoReasonNameAddNew);
					for (let i = 0; i < this.reasonNameOptions.length; i++) {
						this.reasonNameTA.push(this.reasonNameOptions[i]);
					}
					this.showSpinnerForAction = false;
				} else {
					this.showSpinnerForAction = false;
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Server Error", "Problem occur while getting Reason Details", "error");
			},
			() => console.log('done');
	};

	onKeyPressListnerForLRNumber(event) {
		if (event.charCode == 13) {
			if ($("#" + this.pageId + "lrNumber").val() == null || $("#" + this.pageId + "lrNumber").val() == undefined ||
				$("#" + this.pageId + "lrNumber").val() == "") {
				swal("Mandatory Filed", "Please enter the LR Number", "warning");
				return false;
			} else {
				this.checkingLRNumberInDatatTable();
			}
		}
	}

	checkingLRNumberInDatatTable() {
		this.enteredLrNumber = null;
		this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
		this.enteredLrNumber = this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();

		var validateLrNumber = this.findLRNumber(this.scheduleTripDeliverySummaryDataList, this.enteredLrNumber);
		console.log(validateLrNumber);
		if (this.podReq) {
			this.podUrl = this.validatePodCopy(this.scheduleTripDeliverySummaryDataList, this.enteredLrNumber);
		}
		console.log('pod validation');
		console.log(this.podUrl);
		if (validateLrNumber == null || validateLrNumber == undefined) {
			swal("Record Not Found", "Lr Number : " + $("#" + this.pageId + "lrNumber").val() + "Not Found", "warning");
			$("#" + this.pageId + "lrNumber").focus();
		} else if (this.podReq && !this.podExempUser && (this.podUrl == null || this.podUrl == undefined || this.podUrl == '')) {
			swal("Not Allowed", "POD not yet completed for the LR Number : " + $("#" + this.pageId + "lrNumber").val() + ". Please complete the POD process and try again!", "warning");
			$("#" + this.pageId + "lrNumber").focus();
		} else {
			$("#" + this.pageId + "deliveryBtnId").focus();
		}
	}


	findLRNumber(findRemaingLrs, lrNumber) {
		var checkingLrNumber = null;
		var remainLrNumberReturn = null;
		for (let i = 0; i < findRemaingLrs.length; i++) {
			checkingLrNumber = findRemaingLrs[i].lrNumber;
			if (checkingLrNumber == lrNumber) {
				remainLrNumberReturn = checkingLrNumber;
				break;
			} else {
				remainLrNumberReturn = null;
			}
		}
		return remainLrNumberReturn;
	}

	validatePodCopy(findRemaingLrs, lrNumber) {
		var checkingLrNumber = null;
		var podCopy = null;
		for (let i = 0; i < findRemaingLrs.length; i++) {
			checkingLrNumber = findRemaingLrs[i].lrNumber;
			if (checkingLrNumber == lrNumber) {
				console.log('validatePodCopy 1');
				podCopy = findRemaingLrs[i].viewPodLrCopy;
				console.log(podCopy);
				break;
			} else {
				podCopy = null;
			}
		}
		return podCopy;
	}

	validateDeliveredLrsBtn() {
		this.enteredLrNumber = null;
		this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
		this.enteredLrNumber = this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();

		var validateLrNumber = this.findLRNumber(this.scheduleTripDeliverySummaryDataList, this.enteredLrNumber);
		console.log(validateLrNumber);
		if (validateLrNumber == null || validateLrNumber == undefined) {
			swal("Record Not Found", "Lr Number : " + $("#" + this.pageId + "lrNumber").val() + "Not Found", "warning");
			$("#" + this.pageId + "lrNumber").focus();
		} else if (this.podReq && !this.podExempUser && (this.podUrl == null || this.podUrl == undefined || this.podUrl == '')) {
			swal("Not Allowed", "POD not yet completed for the Lr Number : " + $("#" + this.pageId + "lrNumber").val() + ". Please complete the POD process and try again!", "warning");
			$("#" + this.pageId + "lrNumber").focus();
		} else {
			if ($("#" + this.pageId + "deliveryDates").val() == null || $("#" + this.pageId + "deliveryDates").val() == undefined ||
				$("#" + this.pageId + "deliveryDates").val() == "") {
				swal("Mandatory Field", "Please select the Delivery Date", "warning");
				return false;
			} else {
				this.updateDeliveredLRDetails();
			}

		}
	}

	updateDeliveredLRDetails() {
		this.lrDtoForDelivered = new LRDto();
		this.validateEnterLRNumber = null;
		this.validateEnterDeliveryDate = null;
		this.validateEnterReason = null;

		this.validateEnterLRNumber = $("#" + this.pageId + "lrNumber").val();
		this.validateEnterDeliveryDate = $("#" + this.pageId + "deliveryDates").val();

		this.lrDtoForDelivered.lrNumber = this.validateEnterLRNumber;
		this.lrDtoForDelivered.deliveryDate = this.validateEnterDeliveryDate;
		this.lrDtoForDelivered.lastUpdatedBy = this.userDataDtoReturnSession.userId;
		this.lrDtoForDelivered.branch = this.userDataDtoReturnSession.office;
		this.lrDtoForDelivered.tripId = this.lrDtoForLocalTripListForLocalStorage.tripId;
		this.lrDtoForDelivered.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.lrDtoForDelivered);
		this.showSpinnerForAction = true;
		//this.showSpinnerForAction = false;
		this.dashboardService.updateLRStatusDeliveryAsDelivered(this.lrDtoForDelivered).subscribe(
			(response) => {
				console.log(response);
				if (response.status != null && response.status == "Success") {
					this.enteredLrNumber = null;
					this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
					this.enteredLrNumber = this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();

					var validateLrNumber = this.findLRNumber(this.scheduleTripDeliverySummaryDataList, this.enteredLrNumber);
					console.log(validateLrNumber);
					if (validateLrNumber != null || validateLrNumber != undefined) {
						const deletedContrat = this.scheduleTripDeliverySummaryDataList.find(x => x.lrNumber === $("#" + this.pageId + "lrNumber").val());
						this.scheduleTripDeliverySummaryDataList.splice(this.scheduleTripDeliverySummaryDataList.indexOf(deletedContrat), 1);
						$("#" + this.pageId + "scheduleTripDeliveryTableId").DataTable().destroy();
						this.dtTriggerScheduleTripDelivery.next();
					}
					$("#" + this.pageId + "lrNumber").val('');
					$("#" + this.pageId + "lrNumber").focus();
					if (this.scheduleTripDeliverySummaryDataList.length == 0) {
						this.tripFinishFn();
					}
				} else {
					swal("Failed", "Failed To Update LR Status Delivery As Delivered", "warning");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting Update LR Status Delivery As Delivered Details", "warning");
			},
			() => console.log('done');
	};

	tripFinishFn() {
		this.lrDtoForTripFinish = new LRDto();
		this.listOfLrsReturn = [];
		this.listOfLrsReturn = this.getLrsList();
		this.lrDtoForTripFinish.tripId = this.lrDtoForLocalTripListForLocalStorage.tripId;
		this.lrDtoForTripFinish.lastUpdatedBy = this.userDataDtoReturnSession.userId;
		this.lrDtoForTripFinish.list = this.listOfLrsReturn;
		this.lrDtoForTripFinish.branch = this.userDataDtoReturnSession.office;
		this.lrDtoForTripFinish.companyId = this.userDataDtoReturnSession.companyId;
		console.log(this.lrDtoForTripFinish);
		this.showSpinnerForAction = true
		this.dashboardService.updateLocalTrips(this.lrDtoForTripFinish).subscribe(
			(response) => {
				this.showSpinnerForAction = false;
				if (response.status != null && response.status == "Success") {
					swal("Success", "Updated Local Trip Scuccesfully", "success");
					this.returnMessageFinish = null;
					this.returnMessageFinish = "No Trip";
					this.dialogRef.close(this.returnMessageFinish);
				} else if (response.status != null && response.status == "Notexist") {
					swal("Not Exist", "No Trip Found", "warning");
				} else {
					swal("Failed", "Failed to update Local Trip", "error");
				}
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting Update Local Trips Details", "warning");
			},
			() => console.log('done');
	};

	getLrsList() {
		this.listOfLrs = [];
		for (let i = 0; i < this.scheduleTripDeliverySummaryDataList.length; i++) {
			this.listOfLrs.push(this.scheduleTripDeliverySummaryDataList[i].lrNumber);
		}
		return this.listOfLrs;
	}

	//undelievred
	validateUnDeliveredLrsBtn(tripDeliveryLrsDetailsPopUpTemplate) {
		this.enteredLrNumber = null;
		this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
		this.enteredLrNumber = this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();

		var validateLrNumber = this.findLRNumber(this.scheduleTripDeliverySummaryDataList, this.enteredLrNumber);
		console.log(validateLrNumber);
		if (validateLrNumber == null || validateLrNumber == undefined) {
			swal("Record Not Found", "Lr Number : " + $("#" + this.pageId + "lrNumber").val() + "Not Found", "warning");
			$("#" + this.pageId + "lrNumber").focus();
		} else {
			this.viewOkPopUpBtn = true;
			this.viewconfirmCancelPopUpBtn = false;
			this.viewNewReasonField = false;
			this.openReasonPopUp(tripDeliveryLrsDetailsPopUpTemplate);
		}
	}

	openReasonPopUp(tripDeliveryLrsDetailsPopUpTemplate) {
		this.modalRefferenceTripDeliveryConfirmPopUp = this.modalService.open(tripDeliveryLrsDetailsPopUpTemplate,
			{ centered: true, size: "sm" });
		this.modalRefferenceTripDeliveryConfirmPopUp.result.then((result) => {
			this.modalRefferenceTripDeliveryConfirmClosePopUp = `Closed with: ${result}`;
		}, reason => {
			this.modalRefferenceTripDeliveryConfirmClosePopUp = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	closePopUp() {
		this.modalRefferenceTripDeliveryConfirmPopUp.close();
	}

	okBtnClickReasonPopUp() {
		if (($("#" + this.pageId + "reasonNameId").val() == null) || ($("#" + this.pageId + "reasonNameId").val() == undefined) ||
			($("#" + this.pageId + "reasonNameId").val() == "") || ($("#" + this.pageId + "reasonNameId").val() == "Add New") &&
			(($("#" + this.pageId + "newReasonNameId").val() == null) || ($("#" + this.pageId + "newReasonNameId").val() == undefined) ||
				($("#" + this.pageId + "newReasonNameId").val() == ""))) {
			swal("Mandatory Field", "Please select the mandatory field", "warning");
			return false;
		} else {
			this.updateUnDeliveredLRDetails();
		}
	}

	updateUnDeliveredLRDetails() {
		this.lrDtoForUnDelivered = new LRDto();
		this.validateEnterLRNumber = null;
		this.validateEnterDeliveryDate = null;

		this.validateEnterLRNumber = $("#" + this.pageId + "lrNumber").val();
		this.validateEnterDeliveryDate = $("#" + this.pageId + "deliveryDates").val();

		this.lrDtoForUnDelivered.lrNumber = this.validateEnterLRNumber;
		this.lrDtoForUnDelivered.lastUpdatedBy = this.userDataDtoReturnSession.userId;
		this.lrDtoForUnDelivered.branch = this.userDataDtoReturnSession.office;
		this.lrDtoForUnDelivered.tripId = this.lrDtoForLocalTripListForLocalStorage.tripId;
		this.lrDtoForUnDelivered.companyId = this.userDataDtoReturnSession.companyId;
		if ($("#" + this.pageId + "reasonNameId").val() != null && $("#" + this.pageId + "reasonNameId").val() == "Add New") {
			this.validateEnterReason = $("#" + this.pageId + "newReasonNameId").val();
			this.lrDtoForUnDelivered.reason = this.validateEnterReason;
			this.lrDtoForUnDelivered.additional = "NewReasonAdded";
		} else {
			this.validateEnterReason = $("#" + this.pageId + "reasonNameId").val();
			this.lrDtoForUnDelivered.reason = this.validateEnterReason;
			this.lrDtoForUnDelivered.additional = "ExistingReason";
		}
		console.log(this.lrDtoForUnDelivered);
		this.showSpinnerForActionReasonPopup = true;
		this.dashboardService.updateLRStatusDeliveryAsUnDelivered(this.lrDtoForUnDelivered).subscribe(
			(response) => {
				console.log(response);
				if (response.status != null && response.status == "Success") {
					this.enteredLrNumber = null;
					this.enteredLrNumber = $("#" + this.pageId + "lrNumber").val();
					this.enteredLrNumber = this.enteredLrNumber == null ? null : this.enteredLrNumber.toUpperCase();

					var validateLrNumber = this.findLRNumber(this.scheduleTripDeliverySummaryDataList, this.enteredLrNumber);
					console.log(validateLrNumber);
					if (validateLrNumber != null || validateLrNumber != undefined) {
						const deletedContrat = this.scheduleTripDeliverySummaryDataList.find(x => x.lrNumber === $("#" + this.pageId + "lrNumber").val());
						this.scheduleTripDeliverySummaryDataList.splice(this.scheduleTripDeliverySummaryDataList.indexOf(deletedContrat), 1);
						$("#" + this.pageId + "scheduleTripDeliveryTableId").DataTable().destroy();
						this.dtTriggerScheduleTripDelivery.next();
					}
					this.commonClearField();
					if (this.scheduleTripDeliverySummaryDataList.length == 0) {
						this.tripFinishFn();
					}
				} else {
					swal("Failed", "Failed To Update LR Status Delivery As UnDelivered", "warning");
				}
				this.showSpinnerForActionReasonPopup = false;
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.showSpinnerForActionReasonPopup = false;
				swal("Error", "Server Error While Getting Update LR Status Delivery As UnDelivered Details", "warning");
			},
			() => console.log('done');
	};
	commonClearField() {
		$("#" + this.pageId + "lrNumber").val('');
		$("#" + this.pageId + "lrNumber").focus();
		this.modalRefferenceTripDeliveryConfirmPopUp.close();
		this.modelReasonName = null;
		$("#" + this.pageId + "reasonNameId").val('');
		$("#" + this.pageId + "newReasonNameId").val('');
		this.viewNewReasonField = false;
		this.viewOkPopUpBtn = false;
		this.viewconfirmCancelPopUpBtn = false;
	}

	//third trip cancel
	validateTripCancelBtn(tripDeliveryLrsDetailsPopUpTemplate) {
		swal({
			title: "Trip Cancellation",
			text: "Are U Sure want to Cancel this Trip",
			icon: "info",
			closeOnClickOutside: false,
			closeOnEsc: false,
			buttons: ["No", "Yes"],
		}).then((yesTripCancelBtn) => {
			if (yesTripCancelBtn) {
				this.viewconfirmCancelPopUpBtn = true;
				this.viewOkPopUpBtn = false;
				this.viewNewReasonField = false;
				this.openReasonPopUp(tripDeliveryLrsDetailsPopUpTemplate);
			}
		});
	}

	confirmCancelBtnClickReasonPopUp() {
		if (($("#" + this.pageId + "reasonNameId").val() == null) || ($("#" + this.pageId + "reasonNameId").val() == undefined) ||
			($("#" + this.pageId + "reasonNameId").val() == "") || ($("#" + this.pageId + "reasonNameId").val() == "Add New") &&
			(($("#" + this.pageId + "newReasonNameId").val() == null) || ($("#" + this.pageId + "newReasonNameId").val() == undefined) ||
				($("#" + this.pageId + "newReasonNameId").val() == ""))) {
			swal("Mandatory Field", "Please select the mandatory field", "warning");
			return false;
		} else {
			this.updateTripCancelOfLRDetails();
		}
	}

	updateTripCancelOfLRDetails() {
		this.lrDtoForTripCancel = new LRDto();
		this.validateEnterReason = null;
		this.tripCancelForListOfLrs = [];
		for (let i = 0; i < this.scheduleTripDeliverySummaryDataList.length; i++) {
			this.tripCancelForListOfLrs.push(this.scheduleTripDeliverySummaryDataList[i].lrNumber);
		}

		this.lrDtoForTripCancel.list = this.tripCancelForListOfLrs;
		this.lrDtoForTripCancel.lastUpdatedBy = this.userDataDtoReturnSession.userId;
		this.lrDtoForTripCancel.branch = this.userDataDtoReturnSession.office;
		this.lrDtoForTripCancel.tripId = this.lrDtoForLocalTripListForLocalStorage.tripId;
		this.lrDtoForTripCancel.companyId = this.userDataDtoReturnSession.companyId;
		if ($("#" + this.pageId + "reasonNameId").val() != null && $("#" + this.pageId + "reasonNameId").val() == "Add New") {
			this.validateEnterReason = $("#" + this.pageId + "newReasonNameId").val();
			this.lrDtoForTripCancel.reason = this.validateEnterReason;
			this.lrDtoForTripCancel.additional = "NewReasonAdded";
		} else {
			this.validateEnterReason = $("#" + this.pageId + "reasonNameId").val();
			this.lrDtoForTripCancel.reason = this.validateEnterReason;
			this.lrDtoForTripCancel.additional = "ExistingReason";
		}
		console.log(this.lrDtoForTripCancel);
		this.showSpinnerForActionReasonPopup = true;
		this.dashboardService.updateLRStatusDeliveryAsTripCancelled(this.lrDtoForTripCancel).subscribe(
			(response) => {
				console.log(response);
				if (response.status != null && response.status == "Success") {
					this.returnMessageFinish = null;
					this.commonClearField();
					this.returnMessageFinish = "No Trip";
					this.dialogRef.close(this.returnMessageFinish);
				} else {
					swal("Failed", "Failed To Update LR Status Delivery As Trip Cancelled", "warning");
				}
				this.showSpinnerForActionReasonPopup = false;
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.showSpinnerForActionReasonPopup = false;
				swal("Error", "Server Error While Getting Update LR Status Delivery As Trip Cancelled", "warning");
			},
			() => console.log('done');
	};

	getLrPodCopy(podCoryUrl) {
		if (podCoryUrl != null && podCoryUrl != undefined && podCoryUrl != '' && podCoryUrl != 'NA') {
			window.open(podCoryUrl);
		} else {
			swal("No POD", "No POD found for this LR!", "info");
		}
	}

	rowCheckBoxChecked(e, pendingLrsDataForSearch, index) {
		if (e.currentTarget.checked) {
			if (this.pendingLrsDataForSearchSelectedList.length == 0) {
				this.pendingLrsDataForSearchSelectedList = [];
				this.pendingLrsDataForSearchSelectedIndex = [];
				this.pendingLrsDataForSearchSelectedList.push(pendingLrsDataForSearch);
				this.pendingLrsDataForSearchSelectedIndex.push(index);
			} else {
				this.pendingLrsDataForSearchSelectedList.push(pendingLrsDataForSearch);
				this.pendingLrsDataForSearchSelectedIndex.push(index);
			}
		} else {
			let index = this.pendingLrsDataForSearchSelectedList.indexOf(pendingLrsDataForSearch);
			if (index > -1) {
				this.pendingLrsDataForSearchSelectedList.splice(index, 1);
				this.pendingLrsDataForSearchSelectedIndex.splice(index, 1);
			}
		}

		console.log(this.pendingLrsDataForSearchSelectedList);
	}

	validateDeliveredMultipleLrsBtn() {

		if (this.podReq) {
			this.podUrl = this.validatePodCopyMultipleLRs();
		}
		if (this.podReq && !this.podExempUser && (this.podUrl == null || this.podUrl == undefined || this.podUrl == '')) {
			swal("Not Allowed", "POD not yet completed for the some of the selected LR. Please complete the POD process and try again!", "warning");
		} else if ($("#" + this.pageId + "deliveryDates").val() == null || $("#" + this.pageId + "deliveryDates").val() == undefined ||
			$("#" + this.pageId + "deliveryDates").val() == "") {
			swal("Mandatory Field", "Please select the Delivery Date", "warning");
			return false;
		} else {
			swal({
				title: "Confirm Delivered",
				text: "Sure you want to mark the selected LRs as Delivered?",
				icon: "info",
				closeOnClickOutside: false,
				closeOnEsc: false,
				buttons: ["No", "Yes"],
			}).then((yesTripCancelBtn) => {
				if (yesTripCancelBtn) {
					this.updateDeliveredMultipleLRDetails();
				}
			});
		}
	}

	updateDeliveredMultipleLRDetails() {
		this.showSpinnerForAction = true;
		this.multipleLRDeliveredLRDtos = [];
		for (let i = 0; i < this.pendingLrsDataForSearchSelectedList.length; i++) {
			this.lrDtoForDelivered = new LRDto();
			this.validateEnterDeliveryDate = null;
			this.validateEnterDeliveryDate = $("#" + this.pageId + "deliveryDates").val();

			this.lrDtoForDelivered.lrNumber = this.pendingLrsDataForSearchSelectedList[i].lrNumber;
			this.lrDtoForDelivered.viewPodLrCopy = this.pendingLrsDataForSearchSelectedList[i].viewPodLrCopy;
			this.lrDtoForDelivered.deliveryDate = this.validateEnterDeliveryDate;
			this.lrDtoForDelivered.lastUpdatedBy = this.userDataDtoReturnSession.userId;
			this.lrDtoForDelivered.branch = this.userDataDtoReturnSession.office;
			this.lrDtoForDelivered.tripId = this.lrDtoForLocalTripListForLocalStorage.tripId;
			this.lrDtoForDelivered.companyId = this.userDataDtoReturnSession.companyId;
			console.log(this.lrDtoForDelivered);
			this.multipleLRDeliveredLRDtos.push(this.lrDtoForDelivered);
		}
		console.log(this.multipleLRDeliveredLRDtos);
		this.dashboardService.updateMultipleLRStatusDelivered(this.multipleLRDeliveredLRDtos).subscribe(
			(response) => {
				console.log(response);
				if (response.status != null && response.status == "Success") {
					swal("Success", "Selected LRs marked as Delivered!", "info");

					this.getPendingLRsDetailsData();
				} else {
					swal("Failed", "Failed To Update LR Status Delivery As Delivered", "warning");
				}
				this.showSpinnerForAction = false;
				this.changeDetectorRef.detectChanges();
			}),
			(error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Error While Getting Update LR Status Delivery As Delivered Details", "warning");
			},
			() => console.log('done');
	}

	getPendingLRsDetailsData() {
		this.lrDtoForLocalTripSelectedRow = new LRDto();
		this.lrDtoForLocalTripSelectedRow.tripId = this.lrDtoForLocalTripListForLocalStorage.tripId;
		this.lrDtoForLocalTripSelectedRow.status = "Pending";
		this.lrDtoForLocalTripSelectedRow.godownName = this.userDataDtoReturnSession.office;
		this.lrDtoForLocalTripSelectedRow.companyId = this.userDataDtoReturnSession.companyId;
		this.lrDtoForLocalTripSelectedRow.deliverygodown = this.lrDtoForLocalTripListForLocalStorage.deliverygodown;
		this.setLocalTripSheetDetails();
	}

	setLocalTripSheetDetails() {
		this.showSpinnerForAction = true;
		this.hireslipService.getLocalTripsLrsDetails(this.lrDtoForLocalTripSelectedRow).subscribe(
			(response) => {
				console.log(response);
				this.showSpinnerForAction = false;
				this.scheduleTripDeliverySummaryDataList = [];
				$("#" + this.pageId + "scheduleTripDeliveryTableId").DataTable().destroy();
				this.scheduleTripDeliverySummaryDataList = response;
				this.dtTriggerScheduleTripDelivery.next();
				if (this.scheduleTripDeliverySummaryDataList.length == 0) {
					this.tripFinishFn();
				}
				this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Server Error", "Problem occur while getting local trip lrs Details ", "error");
			},
			() => console.log('done');
	}

	validatePodCopyMultipleLRs() {
		console.log('validatePodCopyMultipleLRs');
		var podCopy = null;
		for (let i = 0; i < this.multipleLRDeliveredLRDtos.length; i++) {
			podCopy = this.multipleLRDeliveredLRDtos[i].viewPodLrCopy;
			if (podCopy == null) {
				break;
			}
		}
		console.log(podCopy);
		return podCopy;
	}
}
