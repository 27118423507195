import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

//for datepicker starts
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

/*for popup*/import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//for datepicker ends

//for datepicker starts
const my = new Date();
//for datepicker ends
//for the select option with filter starts
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

//for the select option with filter ends
@Component( {
    selector: 'app-hireslip-advance',
    templateUrl: './hireslip-advance.component.html',
    styleUrls: ['./hireslip-advance.component.css']
} )
export class HireslipAdvanceComponent implements OnInit {

    //for datepicker starts
    model: NgbDateStruct;
    model2;
    //for datepicker ends

    loadingIndicator = true;


    //for datePicker starts
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    closeResult: string;
    //for datepicker ends

    //for the select option with filter starts


    departureTimeOptions = [
        { id: 1, label: '00:00' },
        { id: 2, label: '00:15' },
        { id: 3, label: '00:30' },
        { id: 4, label: '00:45' },
        { id: 5, label: '01:00' },
        { id: 6, label: '01:15' }
    ];
    scheduledTimeOptions = [
        { id: 1, label: '00:00' },
        { id: 2, label: '00:15' },
        { id: 3, label: '00:30' },
        { id: 4, label: '00:45' },
        { id: 5, label: '01:00' },
        { id: 6, label: '01:15' }
    ];
    control = new FormControl();
    pageId="hdc";
    //for the select option with filter ends
    constructor( public changeDetectorRef : ChangeDetectorRef) { }

    ngOnInit() {
    }

    //for datepicker
    // the selectToday is the method for  selecting todays'z date
    selectToday() {
        this.model = {
            year: my.getFullYear(),
            month: my.getMonth() + 1,
            day: my.getDate()
        };
    }

    //for datePicker
    //for the select option with filter starts

    transform( items: any[], searchTerm: string, labelKey?: string ): any {
        if ( !items || !searchTerm ) {
            return items;
        }

        return items.filter(
            item =>
                item[labelKey || 'label']
                    .toLowerCase()
                    .includes( searchTerm.toLowerCase() ) === true
        );
    }

    //for the select option with filter ends
}
