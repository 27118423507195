import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
const my = new Date();
import { LRDto } from "src/app/dto/LR-dto";
import { DatePipe } from "@angular/common";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { Subject, merge } from "rxjs";
import { MasterReadService } from "src/app/dataService/masterread-service";
import swal from "sweetalert";
import { Router } from "@angular/router";
import *  as moment from 'moment';
import { CashMemoDto } from 'src/app/dto/CashMemo-dto';
import { HireslipService } from "src/app/dataService/hireslip-service";

@Component({
  selector: 'app-transhipment-trip',
  templateUrl: './transhipment-trip.component.html',
  styleUrls: ['./transhipment-trip.component.css']
})
export class TranshipmentTripComponent implements OnInit {

  transhipmentTripRptDataList: any;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  @ViewChildren(DataTableDirective) public dtElements: QueryList<DataTableDirective>;
  dtTriggerTranshipmentTripRpt: Subject<any> = new Subject();
  dtOptionsTranshipmentTripRpt: any;
  viewCustomPrintV1 = false;
  selectedFromDate: any;
  selectedToDate: any;
  userDataDtoReturnSession: any;
  isLoggedIn = true;
  selectedDestination: any;
  setTodayDateOnToDate: any;
  setTodayDateOnFromDate: any;
  selectedSource: any;

  grandActWt = 0;
  grandChWt = 0;
  grandTotalArt = 0;

  grandGoodsVal = 0;
  //For Custom Print
  cashMemoDtoForCustomPrint: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintList: any;
  cashMemoDtoForCustomPrintListColumnNames: Array<any> = [];
  cashMemoDtoForCustomPrintListColumnWidths: Array<any> = [];
  cashMemoDtoForCustomPrintDataList: any;
  cashMemoDtoForCustomPrintData: CashMemoDto = new CashMemoDto();
  cashMemoDtoForCustomPrintListColumnValues: Array<any> = [];
  cashMemoDtoForCustomPrintDataSummaryList: any;

  fromDatePrint: any;
  toDatePrint: any;
  cashMemoDtoForCustomPrintListHeadingV1: any;
  cashMemoDtoForCustomPrintListHeadingV2: any;
  cashMemoDtoForCustomPrintListHeadingNamesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV1: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingNamesV2: Array<any> = [];
  cashMemoDtoForCustomPrintListHeadingValuesV2: Array<any> = [];

  destinationDtoForAll: LRDto = new LRDto();
  destinationList: Array<any> = [];
  showSpinnerForAction = false;

  lrDtoFromStn: LRDto = new LRDto();
  lrDtoFromStnAll: LRDto = new LRDto();

  lrDtosFromStnOptionsGet: LRDto[];
  //For Sorce Main Stations
  lrDtoSourceStationAllOption: LRDto = new LRDto();
  sourceStationOptions: LRDto[];
  lrDtoSourceStation: LRDto = new LRDto();

  public modelSource: any;
  sourceSubStationNameTA: Array<LRDto> = [];
  focusSourceTA$ = new Subject<string>();
  searchSource = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusSourceTA$;
    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.sourceSubStationNameTA
        : this.sourceSubStationNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterSource = (x: { source: string }) => x.source;

  lrDtoToStn: LRDto = new LRDto();
  lrDtoToStnAll: LRDto = new LRDto();
  lrDtosToStnOptionsGet: LRDto[];
  //For Sorce Main Stations
  lrDtoDestinationStationAllOption: LRDto = new LRDto();
  destinationStationOptions: LRDto[];
  lrDtoDestinationStation: LRDto = new LRDto();

  public modelDestination: any;
  destinationSubStationNameTA: Array<LRDto> = [];
  focusDestinationTA$ = new Subject<string>();
  searchDestination = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusDestinationTA$;
    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.destinationSubStationNameTA
        : this.destinationSubStationNameTA.filter(v => v.source.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 200))
    );
  }
  formatterDestination = (x: { source: string }) => x.source;
  lrDtoForSearch: LRDto = new LRDto();
  pageId = "tstrc";

  constructor(private router: Router, private masterReadService: MasterReadService, private hireslipService: HireslipService,
    private datePipe: DatePipe, public changeDetectorRef: ChangeDetectorRef) {
    if (sessionStorage.length == 0) {
      this.isLoggedIn = false;
      swal({
        title: "Session Expired",
        text: "Please relogin to access the application!",
        icon: "error",
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(() => {
        this.logInPage();
      })
    }

    if (this.isLoggedIn) {
      this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
      this.gettingToStationList();
      this.selectTodayDate();
      this.gettingFromStationList();
    }
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
    });
  }

  selectTodayDate() {
    this.setTodayDateOnToDate = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };
    this.setTodayDateOnFromDate = {
      year: my.getFullYear(),
      month: my.getMonth() + 1,
      day: my.getDate()
    };
    $("#" + this.pageId + "fromDate").val(this.setTodayDateOnFromDate);
    $("#" + this.pageId + "toDate").val(this.setTodayDateOnToDate);
  }

  ngOnInit(): void {
    this.dtOptionsTranshipmentTripRpt = {
      dom: 'Bfrtip',
      buttons: [{
        extend: 'excel',
        text: '<i class="fas fa-file-excel"> Excel</i>',
        titleAttr: 'Excel',
        footer: true,
        title: function () {
          var returExtendHistroyDate = null;
          returExtendHistroyDate = "Lr Dispatched Details  " +
            " From Date : " + moment($("#tstrcfromDate").val()).format('DD-MM-YYYY') + " -  " +
            "To Date : " + moment($("#tstrctoDate").val()).format('DD-MM-YYYY') + " From  : " + $("#tstrcsource").val() + " To: " + $("#tstrcdestination").val();
          return returExtendHistroyDate;
        }
      }],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search..."
      },
      processing: true,
      responsive: true,
      "scrollX": true,
      "scrollY": 320,
      "scrollCollapse": true,
      "paging": false,
      "info": false,
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api(), data;
        // converting to interger to find total
        var intVal = function (i) {
          return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };
        var totArt = api.column(5).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var actWgt = api.column(6).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        var chgWgt = api.column(7).data().reduce(
          function (a, b) {
            return intVal(a) + intVal(b);
          }, 0);
        $(api.column(0).footer()).html('Total : ' + data.length);
        $(api.column(5).footer()).html(totArt);
        $(api.column(6).footer()).html(actWgt);
        $(api.column(7).footer()).html(chgWgt);
      }
    }
  }

  ngOnDestroy(): void {
    this.dtTriggerTranshipmentTripRpt.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTriggerTranshipmentTripRpt.next();
  }

  logInPage() {
    this.router.navigate(['/authentication/login']);
  }

  gettingFromStationDetails() {
    this.lrDtoFromStn.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoFromStn.mode = "notStmtInvRpt";
  }

  gettingFromStationList = () => {
    this.showSpinnerForAction = true;
    this.gettingFromStationDetails();
    this.masterReadService.getFromStationService(this.lrDtoFromStn).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Source records found!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {

          this.sourceSubStationNameTA = [];
          this.lrDtosFromStnOptionsGet = [];
          this.lrDtosFromStnOptionsGet = response;
          for (let i = 0; i < this.lrDtosFromStnOptionsGet.length; i++) {
            this.sourceSubStationNameTA.push(this.lrDtosFromStnOptionsGet[i]);
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Error While Getting Source Details", "warning");
      },
      () => console.log('done');
  };

  gettingToStationDetails() {
    this.lrDtoToStn.companyId = this.userDataDtoReturnSession.companyId;
    this.lrDtoToStn.mode = "trnsTripRptDest";
  }
  gettingToStationList = () => {
    this.showSpinnerForAction = true;
    this.gettingToStationDetails();
    this.masterReadService.getFromStationService(this.lrDtoToStn).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Source records found!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          this.destinationSubStationNameTA = [];
          this.lrDtosToStnOptionsGet = [];
          this.lrDtosToStnOptionsGet = response;
          this.lrDtoToStnAll.source = "ALL";
          this.destinationSubStationNameTA.push(this.lrDtoToStnAll);
          for (let i = 0; i < this.lrDtosToStnOptionsGet.length; i++) {
            this.destinationSubStationNameTA.push(this.lrDtosToStnOptionsGet[i]);
          }
        }
        this.changeDetectorRef.detectChanges();
      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Error While Getting Destination Details", "warning");
      },
      () => console.log('done');
  };

  getSearchMethod() {
    this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
    this.selectedToDate = $("#" + this.pageId + "toDate").val();
    if (this.selectedFromDate == null || this.selectedFromDate == '' || this.selectedToDate == null || this.selectedToDate == '') {
      swal({
        title: "Mandatory Field",
        text: "Please select From Date and To Date to get the  details!",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      this.searchMethodForTranshipmentTripTable();
    }

  }

  searchMethodForTranshipmentTripTable() {
    this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
    this.selectedToDate = $("#" + this.pageId + "toDate").val();
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedSource = $("#" + this.pageId + "source").val();
    this.lrDtoForSearch = new LRDto();
    this.lrDtoForSearch.fromStation = this.selectedSource;
    this.lrDtoForSearch.toStation = this.selectedDestination;
    this.lrDtoForSearch.fromDate = this.selectedFromDate;
    this.lrDtoForSearch.toDate = this.selectedToDate;
    this.lrDtoForSearch.companyId = this.userDataDtoReturnSession.companyId;
    this.searchMethodForTranshipmentTripDetails();
  }

  searchMethodForTranshipmentTripDetails = () => {
    this.showSpinnerForAction = true;
    this.hireslipService.getTransshipmentTripLrDetails(this.lrDtoForSearch).subscribe(
      (response) => {
        this.showSpinnerForAction = false;
        $("#" + this.pageId + "transhipmentTripRptId").DataTable().destroy();
        this.transhipmentTripRptDataList = [];
        if (response.length == 0) {
          swal({
            title: "Warning",
            text: "No Records found for this search!",
            icon: "warning",
            closeOnClickOutside: false,
            closeOnEsc: false,
          });
        } else {
          this.transhipmentTripRptDataList = response;
        }
        this.dtTriggerTranshipmentTripRpt.next();
        this.changeDetectorRef.detectChanges();

      }), (error) => {
        this.showSpinnerForAction = false;
        swal("Error", "Server Problem Occurred While getting the Transhipment Trip  Report ", "info");
      }, () => console.log('done');
  };

  printMethodForExtensionHistoryWise() {
    this.selectedFromDate = $("#" + this.pageId + "fromDate").val();
    this.selectedFromDate = this.datePipe.transform(this.selectedFromDate, "dd-MM-yyyy");
    this.selectedToDate = $("#" + this.pageId + "toDate").val();
    this.selectedToDate = this.datePipe.transform(this.selectedToDate, "dd-MM-yyyy");
    this.selectedDestination = $("#" + this.pageId + "destination").val();
    this.selectedSource = $("#" + this.pageId + "source").val();
    if (this.transhipmentTripRptDataList.length == 0) {
      swal({
        title: "No records found to print",
        icon: "warning",
        closeOnClickOutside: false,
        closeOnEsc: false,
      });
    } else {
      localStorage.clear();
      this.cashMemoDtoForCustomPrintList = [];
      this.cashMemoDtoForCustomPrintListColumnNames = ["LR Number", "Source", "Destination", "Consignee Name", "Art", "A.W", "C.W", "Booking Date", "Unloading Date", "Loading Dat", "Dispatch With-In"];
      this.cashMemoDtoForCustomPrintListColumnWidths = [10, 10, 10, 17, 4, 7, 7, 10, 10, 10, 5];
      for (let i = 0; i < this.cashMemoDtoForCustomPrintListColumnNames.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.columnName = this.cashMemoDtoForCustomPrintListColumnNames[i];
        this.cashMemoDtoForCustomPrint.columnWidth = this.cashMemoDtoForCustomPrintListColumnWidths[i];
        this.cashMemoDtoForCustomPrintList.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintDataList = [];
      this.cashMemoDtoForCustomPrintDataSummaryList = [];
      this.grandActWt = 0;
      this.grandChWt = 0;
      this.grandTotalArt = 0;

      for (let i = 0; i < this.transhipmentTripRptDataList.length; i++) {
        this.cashMemoDtoForCustomPrintData = new CashMemoDto();
        this.cashMemoDtoForCustomPrintListColumnValues = [this.transhipmentTripRptDataList[i].lrNumber, this.transhipmentTripRptDataList[i].source, this.transhipmentTripRptDataList[i].destination, this.transhipmentTripRptDataList[i].consigneeName, this.transhipmentTripRptDataList[i].totalArticles, this.transhipmentTripRptDataList[i].actualWeight, this.transhipmentTripRptDataList[i].chargedWeight, this.transhipmentTripRptDataList[i].bookingDateStr, this.transhipmentTripRptDataList[i].unloadingDateStr, this.transhipmentTripRptDataList[i].hireslipDateStr, this.transhipmentTripRptDataList[i].dispatchedwithin];
        this.cashMemoDtoForCustomPrintData.cashMemoDtoForCustomPrintListColumnValues = this.cashMemoDtoForCustomPrintListColumnValues;
        this.cashMemoDtoForCustomPrintDataList.push(this.cashMemoDtoForCustomPrintData);
        this.grandActWt = +this.grandActWt + +this.transhipmentTripRptDataList[i].actualWeight;
        this.grandChWt = +this.grandChWt + +this.transhipmentTripRptDataList[i].chargedWeight;
        this.grandTotalArt = +this.grandTotalArt + +this.transhipmentTripRptDataList[i].totalArticles;
        this.cashMemoDtoForCustomPrintDataSummaryList = ["Total : " + this.transhipmentTripRptDataList.length, "", "", "", this.grandTotalArt, this.grandActWt, this.grandChWt, "", "", "", ""];
      }
      //heading logics For Date
      this.cashMemoDtoForCustomPrintListHeadingV1 = [];
      this.cashMemoDtoForCustomPrintListHeadingNamesV1 = ["From Dt", "To Dt"];
      this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [this.selectedFromDate, this.selectedToDate];
      for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV1.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV1[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV1[i];
        this.cashMemoDtoForCustomPrintListHeadingV1.push(this.cashMemoDtoForCustomPrint);
      }
      this.cashMemoDtoForCustomPrintListHeadingV2 = [];
      // this.cashMemoDtoForCustomPrintListHeadingNamesV2 = ["Source", "Destination"];
      //   this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [this.selectedSource, this.selectedDestination];

      for (let i = 0; i < this.cashMemoDtoForCustomPrintListHeadingNamesV2.length; i++) {
        this.cashMemoDtoForCustomPrint = new CashMemoDto();
        this.cashMemoDtoForCustomPrint.printHeadingName = this.cashMemoDtoForCustomPrintListHeadingNamesV2[i];
        this.cashMemoDtoForCustomPrint.printHeadingValue = this.cashMemoDtoForCustomPrintListHeadingValuesV2[i];
        this.cashMemoDtoForCustomPrintListHeadingV2.push(this.cashMemoDtoForCustomPrint);
      }
      localStorage.setItem('printCashMemoDtoForCustomPrintList', JSON.stringify(this.cashMemoDtoForCustomPrintList));
      localStorage.setItem('printCashMemoDtoForCustomPrintDataList', JSON.stringify(this.cashMemoDtoForCustomPrintDataList));
      localStorage.setItem('printcashMemoDtoForCustomPrintDataSummaryList', JSON.stringify(this.cashMemoDtoForCustomPrintDataSummaryList));
      localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV1', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV1));
      //  localStorage.setItem('printcashMemoDtoForCustomPrintListHeadingV2', JSON.stringify(this.cashMemoDtoForCustomPrintListHeadingV2));
      localStorage.setItem('printTitle', "Lr Dispatched Details From " + this.selectedSource + " To " + this.selectedDestination);
      this.viewCustomPrintV1 = true;
      window.addEventListener('afterprint', (onclick) => {
        if (this.viewCustomPrintV1) {
          this.viewCustomPrintV1 = false;
          localStorage.clear();
        }
      });
    }
  }

  clearAll() {
    this.selectedFromDate = '';
    this.selectedToDate = '';
    this.selectedDestination = '';
    $("#" + this.pageId + "destination").val('');
    this.selectTodayDate();
    this.selectedSource = '';
    $("#" + this.pageId + "source").val('');
    this.grandActWt = 0;
    this.grandChWt = 0;
    this.grandTotalArt = 0;
    this.showSpinnerForAction = false;
    $("#" + this.pageId + "transhipmentTripRptId").DataTable().destroy();
    this.transhipmentTripRptDataList = [];
    this.cashMemoDtoForCustomPrint = new CashMemoDto();
    this.cashMemoDtoForCustomPrintList = [];
    this.cashMemoDtoForCustomPrintListColumnNames = [];
    this.cashMemoDtoForCustomPrintListColumnWidths = [];
    this.cashMemoDtoForCustomPrintDataList = [];
    this.cashMemoDtoForCustomPrintData = new CashMemoDto();
    this.cashMemoDtoForCustomPrintListColumnValues = [];
    this.cashMemoDtoForCustomPrintDataSummaryList = [];
    this.viewCustomPrintV1 = false;
    this.cashMemoDtoForCustomPrintListHeadingV1 = [];
    this.cashMemoDtoForCustomPrintListHeadingV2 = [];
    this.cashMemoDtoForCustomPrintListHeadingNamesV1 = [];
    this.cashMemoDtoForCustomPrintListHeadingValuesV1 = [];
    this.cashMemoDtoForCustomPrintListHeadingNamesV2 = [];
    this.cashMemoDtoForCustomPrintListHeadingValuesV2 = [];
    this.lrDtoForSearch = new LRDto();
    this.dtTriggerTranshipmentTripRpt.next();
  }

}
