import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DestStmtReport } from 'src/app/dataService/dest-stmt-report';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { RateMasterDto } from "src/app/dto/RateMaster-dto";
import { FortNightStmtDto } from 'src/app/dto/FortNightStmt-dto';

@Component( {
    selector: 'app-unpaid-booking-agent-statement',
    templateUrl: './unpaid-booking-agent-statement.component.html',
    styleUrls: ['./unpaid-booking-agent-statement.component.css']
} )
export class UnpaidBookingAgentStatementComponent implements  OnInit {

    unpaidBookingAgentStatementDataList: any;
    onDestroyUnsubscribtionUnpaidBookingAgentStatement: Subscription;
    dtTriggerUnpaidBookingAgentStatement: Subject<any> = new Subject();
    rateMasterDtoUserValue: RateMasterDto = new RateMasterDto();
    fortNightStmtDtoForSearch: FortNightStmtDto = new FortNightStmtDto();

    dtOptionsUnpaidBookingAgentStatement: any;
    isLoggedIn = true;
    userDataDtoReturnSession: any;
    showSpinnerForAction=false;
pageId="upbac";
    
    constructor( private destStmtReport: DestStmtReport, private router: Router, public changeDetectorRef : ChangeDetectorRef) {
    	if (sessionStorage.length == 0) {
            this.isLoggedIn = false;
            swal({
                title: "Session Expired",
                text: "Please relogin to access the application!",
                icon: "error",
                closeOnClickOutside: false,
                closeOnEsc: false,
            }).then(() => {
                this.logInPage();
            })
        }
        if (this.isLoggedIn) {
            this.userDataDtoReturnSession = JSON.parse(sessionStorage.getItem('SRDWeb'));
        }

    }

    ngOnInit(): void {
        this.dtOptionsUnpaidBookingAgentStatement = {
        		dom: 'Bfrtip',
                // language: {
                //     search: "_INPUT_",
                //     searchPlaceholder: "Search..."
                // },
                buttons: [
      			],
                searching: false,
                pagingType: 'full_numbers',
                processing: true,
                "scrollX": true,
                "scrollY": 170,
                "scrollCollapse": true,
                "paging": false,
                "info": true
                // destroy:true
        }
    }
    
    getUserValues() {
		this.rateMasterDtoUserValue = new RateMasterDto();
		this.rateMasterDtoUserValue.mainStation = this.userDataDtoReturnSession.mainStation;
	}
    getDetails = () => {
		this.showSpinnerForAction = true;
		this.getUserValues();
		this.destStmtReport.getBkgAgtStmtDetails(this.rateMasterDtoUserValue).subscribe(
			(response) => {
				//$("#"+this.pageId+"unpaidBkgAgentStmtTableId").DataTable().destroy();
				this.unpaidBookingAgentStatementDataList = [];
				if (response.length == 0) {
					swal({
						title: "Unpaid Booking Agent Statement",
						text: "No Details found!",
						icon: "info",
						closeOnClickOutside: false,
						closeOnEsc: false,
					});

				} else {
					this.unpaidBookingAgentStatementDataList = response;
				}
				// setTimeout(() => {
				// 	this.dtTriggerUnpaidBookingAgentStatement.next();
	            // }, 1000);
				this.showSpinnerForAction = false;
                this.changeDetectorRef.detectChanges();
			}), (error) => {
				this.showSpinnerForAction = false;
				swal("Error", "Server Problem Occurred While getting the details", "info");
			}, () => console.log('done');
	};

    // ngOnDestroy(): void {
    //     this.dtTriggerUnpaidBookingAgentStatement.unsubscribe();
    // }
    
    ngAfterViewInit(): void {
        this.dtTriggerUnpaidBookingAgentStatement.next();
    }

    logInPage() {
        this.router.navigate(['/authentication/login']);
    }
    //for redirect to other page starts
    goToAgentStatementPage(unpaidBookingAgentStatementData) {
       	 this.fortNightStmtDtoForSearch = new FortNightStmtDto();
       	 this.fortNightStmtDtoForSearch.mode = "STMT";
       	this.fortNightStmtDtoForSearch.reportMode = "DB";
       	 this.fortNightStmtDtoForSearch.mainBranch = this.userDataDtoReturnSession.mainStation;
       	 this.fortNightStmtDtoForSearch.stmtNo = unpaidBookingAgentStatementData.stmtId;
    	localStorage.setItem('bkgStmtFromDBData', JSON.stringify(this.fortNightStmtDtoForSearch));
        this.router.navigate( ['/report/bookings/agentStatement'] );
    }
    //for redirect to other page starts
}
