<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
	<div class="col-lg-12">

		<div class="card " style="border: 1px solid darkcyan !important;">
			<div class="card-header bg-info"
				style="background-color: orange !important; padding: 5px;">
				<h6 class="card-title text-white">To Be Billed Lrs Search</h6>
			</div>
			<div class="row system_responsive" style="margin-bottom: 10px;">
				<div class="col-md-12">
					<div class="card-body">
						<div class="row">
							<div class="col-md-3">
								<!-- <h6 class="card-title">Tempo Details</h6> -->
								<div class="row">
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>View Type</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class="fas fa-question"></i>
													</span>
												</div>
												<select class="custom-select col-12" id="{{pageId}}viewType"
													name="viewType" #viewType
													(change)="viewTypeMode(viewType.value)">
													<option selected value="dateWise">Date Wise</option>
													<option value="partyWise">Party Wise</option>
												</select>
											</div>
										</div>
									</div>
									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}fromPeriod">
												<label>From Period</label> <input class="form-control"
													placeholder="dd-mm-yyyy" name="fromPeriods" ngbDatepicker
													#fromPeriods="ngbDatepicker" required>
												<div class="input-group-append"
													(click)="fromPeriods.toggle()">
													<span class="input-group-text"> <i
														class="fa fa-calendar"></i>
													</span>
												</div>
											</div>
										</div>
									</div>

									<div class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group">
												<label>To Period</label> <input id="{{pageId}}toPeriod"
													class="form-control" placeholder="dd-mm-yyyy"
													name="toPeriods" ngbDatepicker #toPeriods="ngbDatepicker">
												<div class="input-group-append" (click)="toPeriods.toggle()">
													<span class="input-group-text"> <i
														class="fa fa-calendar"></i>
													</span>
												</div>
											</div>
										</div>
									</div>
									<div *ngIf="viewTypeSourceWise" class="col-sm-12 col-md-12">
										<div class="control">
											<div class="form-group">
												<div class="input-group">
													<label>Source</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-user"></i>
														</span>
													</div>
													<input id="{{pageId}}source"
														class="auto_selectOption input is-medium"
														placeholder="Select Source"
														[formControl]="controlMainSource" autocomplete="off"
														[appAutocomplete]="autocompleteSource"
														[(ngModel)]="lrDto.source">
												</div>
											</div>
										</div>

										<app-autocomplete #autocompleteSource="appAutocomplete">
										<ng-template appAutocompleteContent> <ng-container
											*ngIf="(lrDtosSourceOptionsGet | filterFrmSource: controlMainSource.value) as resultSource">
										<app-option *ngFor="let option of resultSource"
											[value]="option.subStations"> {{
										option.subStations }} </app-option> <app-option class="no-result"
											*ngIf="!resultSource.length">No result</app-option> </ng-container> </ng-template> </app-autocomplete>
									</div>

									<div *ngIf="viewTypePartyWise" class="col-sm-12 col-md-12">
										<div class="control">
											<div class="form-group">
												<div class="input-group">
													<label>Consignor Index</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-user"></i>
														</span>
													</div>
													<input class="auto_selectOption input is-medium"
														placeholder="Select Consignor Index"
														[formControl]="controlConsingorIndex"
														[appAutocomplete]="autocompleteConsingorIndex"
														#consignorIndex id="{{pageId}}consignorIndex">
												</div>
											</div>
										</div>

										<app-autocomplete
											#autocompleteConsingorIndex="appAutocomplete">
										<ng-template appAutocompleteContent> <ng-container
											*ngIf="(consignorIndexOptionsGet | filter: controlConsingorIndex.value) as resultConsingorIndex">
										<app-option *ngFor="let option of resultConsingorIndex"
											[value]="option.label"> {{ option.label }} </app-option> <app-option
											class="no-result" *ngIf="!resultConsingorIndex.length">No
										result</app-option> </ng-container> </ng-template> </app-autocomplete>
									</div>

									<div *ngIf="viewTypePartyWise" class="col-sm-12 col-md-12">
										<div class="form-group">
											<div class="input-group" id="{{pageId}}gstNo">
												<label>GST No</label>
												<div class="input-group-prepend">
													<span class="input-group-text"> <i
														class=" fas fa-hashtag"></i>
													</span>
												</div>
												<input #consignorGstNumber type="text" class="form-control"
													aria-describedby="basic-addon11"
													[(ngModel)]="lrDto.consignorTinNo" id="{{pageId}}consignorGstNumber">
											</div>
										</div>
									</div>

									<div *ngIf="viewTypePartyWise" class="col-sm-12 col-md-12">
										<div class="control">
											<div class="form-group">
												<div class="input-group">
													<label>Consignor Name</label>
													<div class="input-group-prepend">
														<span class="input-group-text"> <i
															class="fas fa-user"></i>
														</span>
													</div>
													<input #consignorName id="{{pageId}}consignorName"
														class="auto_selectOption input is-medium"
														placeholder="Select Consignor Name"
														[formControl]="controlConsignorName"
														[appAutocomplete]="autocompleteConsignorName"
														[(ngModel)]="lrDto.consignorName">
												</div>
											</div>
										</div>

										<app-autocomplete #autocompleteConsignorName="appAutocomplete">
										<ng-template appAutocompleteContent> <ng-container
											*ngIf="(partyDetailsOptionsGet | filterConsignorName: controlConsignorName.value) as resultConsignorName">
										<app-option *ngFor="let option of resultConsignorName"
											[value]="option.consignorName"> {{
										option.consignorName }} </app-option> <app-option class="no-result"
											*ngIf="!resultConsignorName.length">No result</app-option> </ng-container> </ng-template>
										</app-autocomplete>
									</div>

								</div>
								<hr style="width: 80%; border-top: none; margin: 3px;">
								<div class="col-md-12" style="text-align: center;">
									<button type="submit" class="btn btn-success m-r-10"
										id="{{pageId}}searchBtn" (click)="validateBtnSearch()">Search</button>
									<button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn"
										(click)="clearAll()">Clear</button>
								</div>
							</div>
							<!-- 							<div class="col-md-9 vl"> -->
							<!-- 								<div class="box-body"> -->
							<!-- 																		<h6 class="card-title border_bottom">Paid Report 1</h6> -->
							<!-- 									<table datatable -->
							<!-- 										class="table table-striped table-bordered row-border hover" -->
							<!-- 										[dtOptions]="dtOptionsToBeBilledPartyRpt" -->
							<!-- 										[dtTrigger]="dtTriggerToBeBilledPartyRpt"> -->

							<!-- 										<thead> -->
							<!-- 											<tr> -->
							<!-- 												<th>LR No</th> -->
							<!-- 												<th>Act Wt</th> -->
							<!-- 												<th>Chg Wt</th> -->
							<!-- 												<th>Consignor</th> -->
							<!-- 												<th>Book Date</th> -->
							<!-- 												<th>Paid</th> -->
							<!-- 												<th>Memo Number</th> -->
							<!-- 												<th>Is Print</th> -->
							<!-- 											</tr> -->
							<!-- 										</thead> -->
							<!-- 										<tbody> -->
							<!-- 											<tr -->
							<!-- 												*ngFor="let toBeBilledPartyRptData of toBeBilledPartyRptDataList "> -->
							<!-- 												<td>{{ toBeBilledPartyRptData.cashmemo }}</td> -->
							<!-- 												<td>{{ toBeBilledPartyRptData.lrNumber }}</td> -->
							<!-- 												<td>{{ toBeBilledPartyRptData.date }}</td> -->
							<!-- 												<td>{{ toBeBilledPartyRptData.fromPeriod }}</td> -->
							<!-- 												<td>{{ toBeBilledPartyRptData.totalAmount }}</td> -->
							<!-- 												<td>{{ toBeBilledPartyRptData.totalAmount }}</td> -->
							<!-- 												<td>{{ toBeBilledPartyRptData.totalAmount }}</td> -->
							<!-- 												<td>{{ toBeBilledPartyRptData.totalAmount }}</td> -->
							<!-- 											</tr> -->
							<!-- 										</tbody> -->
							<!-- 									</table> -->
							<!-- 								</div> -->
							<!-- 							</div> -->
							<div class="col-md-9 vl">
								<div class="row">
									<!-- column -->
									<div class="col-md-12">
										<div class="card">
											<div class="card-body">
												<div class="row p-t-10">
													<div class="col-md-8">
														<h6 class="card-title">To Be Billed Lrs Details</h6>
													</div>
													<div *ngIf="showGenerateMemo" class="col-md-4">
														<button type="submit" class="btn btn-primary m-r-10"
															style="padding: 1px 4px;" id="{{pageId}}generateMemo"
															(click)="generateMemo()">
															<i class="fas fa-file-alt"></i> Generate Memo
														</button>
													</div>
													<div class="col-md-4" style="display: none;">
														<div class="form-group">
															<div class="input-group">

																<input #hiddenLrNumber type="text" id="{{pageId}}hiddenLrNumber"
																	name="hiddenLrNumber" class="form-control"
																	aria-describedby="basic-addon11">&nbsp;
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-12">
														<div class="box-body">

															<table datatable
																class="table table-striped table-bordered row-border hover"
																[dtOptions]="dtOptionsToBeBilledPartyRpt"
																[dtTrigger]="dtTriggerToBeBilledPartyRpt">

																<thead>
																	<tr>
																		<th>LR No</th>
																		<th>Actual Weight</th>
																		<th>Charged Weight</th>
																		<th>Consignor Name</th>
																		<th>Booking Date</th>
																		<!-- 																		<th>Service Tax</th> -->
																		<th>Paid</th>
																	</tr>
																</thead>
																<tbody>
																	<tr
																		*ngFor="let toBeBilledPartyRptData of toBeBilledPartyRptDataList"
																		(click)="rowSelectedToGenrtMemoParty(toBeBilledPartyRptData);">
																		<td>{{ toBeBilledPartyRptData.lrNumber }}</td>
																		<td>{{ toBeBilledPartyRptData.actualWeight }}</td>
																		<td>{{ toBeBilledPartyRptData.chargedWeight }}</td>
																		<td>{{ toBeBilledPartyRptData.consignorName }}</td>
																		<td>{{ toBeBilledPartyRptData.bookingDate }}</td>
																		<!-- 																		<td>{{ toBeBilledPartyRptData.serviceTax -->
																		<!-- 																			}}</td> -->
																		<td>{{ toBeBilledPartyRptData.paid }}</td>
																	</tr>
																</tbody>
																<tfoot>
																	<tr>
																		<td></td>
																		<td></td>
																		<td></td>
																		<td></td>
																		<td></td>
																		<!-- 																		<td></td> -->
																		<td></td>
																	</tr>
																</tfoot>
															</table>
														</div>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>





							<!-- jjjj -->
						</div>
					</div>
				</div>
			</div>
			<!-- Row -->
		</div>
	</div>
</div>
