<!-- Row -->
<div class="row" *ngIf="isLoggedIn">
    <div class="col-lg-12">

        <div class="card " style="border: 1px solid darkcyan !important;">
            <div class="card-header bg-info" style="background-color: orange !important; padding: 5px;">
                <h6 class="card-title text-white">Specific Consignee Details</h6>
            </div>
            <div class="row system_responsive" style="margin-bottom: 10px;">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>GST Number*</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-box"></i>
                                                </span>
                                            </div>
                                            <input #gstNoConsignee type="text" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="partyMasterDto.gstNoConsignee" id="{{pageId}}gstNoConsignee" name="{{pageId}}gstNoConsignee" autocomplete="off">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label>Consignee Name*</label>
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"> <i class="fas fa-box"></i>
                                                </span>
                                            </div>
                                            <input #consigneeName type="text" class="form-control" aria-describedby="basic-addon11" [(ngModel)]="partyMasterDto.consigneeName" id="{{pageId}}consigneeName" name="{{pageId}}consigneeName">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12">
                                    <!-- <div class="form-group">
										<div class="input-group">
											<label>Destination*</label>
											<div class="input-group-prepend">
												<span class="input-group-text"> <i class="fas fa-search"></i>
												</span>
											</div>

											<select class="custom-select col-12" aria-describedby="basic-addon11" [(ngModel)]="partyMasterDto.destination" id="{{pageId}}destination"
												name="{{pageId}}destination">
												<option selected value="Ernakulam">Ernakulam</option>
												<option value="Edappally">Edappally</option>
											</select>
										</div>
									</div> -->

                                    <div class="control">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <label>Destination*</label>
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"> <i class="fas fa-road"></i>
                                                    </span>
                                                </div>
                                                <input #destination id="{{pageId}}destination" type="text" class="form-control" (selectItem)="clickListnerForDestinationList($event)" [(ngModel)]="modelDestination" [ngbTypeahead]="searchDestination" [resultFormatter]="formatterDestination" [inputFormatter]="formatterDestination"
                                                    (focus)="focusDestinationTA$.next($any($event).target.value)" autocomplete="off" placeholder="Select Destination.." />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <hr style="width: 80%; border-top: none; margin: 3px;">
                    <div class="col-md-12" style="text-align: center;">
                        <button #saveBtn type="submit" class="btn btn-success m-r-10" (click)="validateSave()" id="{{pageId}}saveBtn">Save/Update</button>
                        <button type="submit" class="btn btn-success m-r-10" id="{{pageId}}deleteBtn" (click)="validateDeleteRow()">Delete</button>
                        <button type="submit" class="btn btn-dark" id="{{pageId}}clearBtn" (click)="clearAll()">Clear</button>
                    </div>
                </div>
                <div class="col-md-8 vl">
                    <div *ngIf="showSpinnerForAction" class="col-md-9 p-t-10">
                        <div class="form-group">
                            <div class="input-group">
                                <mat-progress-bar mode="indeterminate" style="color: green;"></mat-progress-bar>
                                <br>
                                <h6 class="card-title" align='center' style="color: green; margin: auto; font-size: 18px;">
                                    Please Wait Loading Details.....</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="box-body">
                                    <table datatable id="{{pageId}}consigneeDetailsDatatableId" class="table table-striped table-bordered row-border hover" [dtOptions]="dtOptionsSpecificConsigneeDetails" [dtTrigger]="dtTriggerSpecificConsigneeDetails">
                                        <thead>
                                            <tr>
                                                <th>Consignee Name</th>
                                                <th>GST Number</th>
                                                <th>Destination</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let consigneeData of consigneeDataList" (click)="rowSelected(consigneeData);">
                                                <td>{{ consigneeData.consigneeName }}</td>
                                                <td>{{ consigneeData.gstNoConsignee }}</td>
                                                <td>{{ consigneeData.destination }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- Row -->
        </div>
    </div>
</div>